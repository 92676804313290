import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Checkmark } from "react-checkmark";
import { CardMedia, Container, Paper } from '@mui/material';
import { green } from '@mui/material/colors';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessDrawGreen() {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    //navigate("/areasmaps");
    setOpen(false);
    navigate("/areasmaps")
  };

  setTimeout(() => {
    handleButtonClick()
  }, 3000);

  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 1500); // Tempo em milissegundos para a imagem ficar visível antes de desaparecer

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div >
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: green[700],
          },
        }}
      >
        <Container component="main" maxWidth="sm" sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Paper variant="" sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 }, backgroundColor: "#388e3c" }}>
            {showImage && (
              <img
                component="img"
                src="/dist/image/logo192.png"
                height={100}
                alt="Paella dish"
                style={{ opacity: 1, transition: 'opacity 0.5s' }}
              />
            )}
            {!showImage && <Checkmark size={'120'} color={green[700]} />}
          </Paper>
        </Container>
      </Dialog>
    </div>
  );
}