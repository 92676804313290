import { Fragment, useEffect, useState } from "react";
import { TextField, Button, Box, Grid, Typography, InputAdornment, Stack, MenuItem, Autocomplete, Avatar, AvatarGroup, IconButton, Select, FormControl, InputLabel } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { toast } from "react-toastify";
import TitleIcon from "@mui/icons-material/Title";
import SubjectIcon from "@mui/icons-material/Subject";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useStore from "../../../../store/useStore";
import { format } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

const UnitOptions = [
  { label: "sc 30kg", value: "sc 30kg" },
  { label: "sc 40kg", value: "sc 40kg" },
  { label: "sc 50kg", value: "sc 50kg" },
  { label: "sc 60kg", value: "sc 60kg" },
  { label: "t", value: "t" },
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "mg", value: "mg" },
];

const ValueCost = [
  { label: "R$", value: "pt-BR" },
  { label: "$", value: "en-US" },
];

const nameActivityList = [
  "Financiamento",
  "Limpeza de áreas",
  "Preparação de estruturas agrícolas",
  "Preparação de viveiros",
  "Controle de pragas",
  "Controle de pragas e doenças prévias",
  "Preparação de solo para áreas específicas",
  "Licenciamento",
  "regulamentação",
];

export default function FormCostOthersLivestock({ handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log("FourthStepFormInputOthersTabOthers");

  const usestore = useStore();
  const reposActivitiesInputs = usestore.reposActivityInput;

  const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const initHarvests = usestore.reposHarvest.harvestRepos;

  const harvestCurrent = usestore.reposHarvest.getHarvestStore("id", initHarvests.id);

  // objeto que é instanciado para receber o valor de um form
  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: harvestCurrent[0].id_property,
    id_harvest: harvestCurrent[0].id,
    id_cultivation: harvestCurrent[0].id_cultivation,
    id_group: harvestCurrent[0].id_group,
    cultivation_name: harvestCurrent[0].cultivation_name,
    nameActivity: "",
    cost: "",
    valueCost: "pt-BR",
    unitCost: "",
    date_start: "",
    date_prev_end: "",
    measure: "ha",
    // startDate: format(new Date(), 'yyyy-MM-dd'),
    startDate: new Date(),
    isDeleted: 0,
  });

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    console.log(name, value);

    // O index representa o índice do objeto no array selectedOthers que está sendo modificado.
    //em vez de atualizar diretamente o estado data, estamos atualizando o estado selectedOthers
    setSelectedOthers((prevOthers) => {
      //criamos uma cópia do array selectedOthers usando o operador spread ([...prevOthers])
      const updatedOthers = [...prevOthers];
      //tualizamos o campo específico do objeto no índice fornecido (updatedOthers[index][name] = value).
      updatedOthers[index][name] = value;
      //retornamos a nova cópia atualizada do array selectedOthers usando setSelectedOthers
      return updatedOthers;
    });
  };

  // const newData = nameActivityList.map((activity) => ({
  //   ...data,
  //   nameActivity: activity,
  // }));

  // contém o estado dos outros recursos selecionados
  const [selectedOthers, setSelectedOthers] = useState([
    // ...
    { ...data, isExpanded: false },
  ]);

  // Função de inicialização para preencher o selectedOthers com objetos baseados na nameActivityList
  const initializeSelectedOthers = () => {
    const initialOthers = nameActivityList.map((activity) => ({
      ...data,
      nameActivity: activity,
    }));

    setSelectedOthers(initialOthers);
  };

  // Efeito colateral para chamar a função de inicialização ao montar o componente
  useEffect(() => {
    initializeSelectedOthers();
  }, []);

  // é uma função que é chamada quando o usuário clica no botão "Adicionar Outro Recurso".
  // Ela adiciona um novo objeto vazio ao array, apenas se o campo de outros recursos estiver preenchido.
  // const handleCreateForm = () => {
  //   console.log()
  //   if (selectedOthers.length === 0 || selectedOthers[selectedOthers.length - 1].others) {
  //     setSelectedOthers((prev) => [...prev, data]);
  //   } else {
  //     toast.error("Por favor, preencha o campo obrigatório nome do recurso.");
  //   }
  // };
  //Ao adicionar um novo objeto ao selectedOthers, ele é preenchido com os valores do estado data. Em seguida, setData é chamado para redefinir os campos do estado data como vazio.
  const handleCreateForm = () => {
    if (selectedOthers.length === 0 || selectedOthers[selectedOthers.length - 1].nameActivity) {
      setSelectedOthers((prev) => [...prev, data]);
      setData({
        id_user: userCurrent.id,
        id_property: harvestCurrent[0].id_property,
        id_harvest: harvestCurrent[0].id,
        id_cultivation: harvestCurrent[0].id_cultivation,
        id_group: harvestCurrent[0].id_group,
        cultivation_name: harvestCurrent[0].cultivation_name,
        nameActivity: "",
        cost: "",
        valueCost: "pt-BR",
        unitCost: "",
        date_start: "",
        date_prev_end: "",
        measure: "ha",
        startDate: new Date(),
        isDeleted: 0,
      });
    } else {
      toast.error("Por favor, preencha o campo obrigatório nome do recurso.");
    }
  };

  // é uma função que é chamada quando o valor de um outro recurso ou informações é alterado.
  // Ela recebe o índice do objeto modificado, o campo modificado e o novo valor.
  // A função atualiza o estado de selectedOthers.
  // const handleChange = (index, field, value) => {
  //   setSelectedOthers((prevOthers) => {
  //     const updatedOthers = [...prevOthers];
  //     updatedOthers[index][field] = value;
  //     return updatedOthers;
  //   });
  // };

  // é uma função que é chamada quando o usuário clica no botão "Excluir" de um outro recurso.
  // Ela remove o objeto correspondente do array selectedOthers usando splice e atualiza o estado.
  const handleDeleteForm = (index) => {
    setSelectedOthers((prevOthers) => {
      const updatedOthers = [...prevOthers];
      updatedOthers.splice(index, 1);
      return updatedOthers;
    });
  };

  // handleBlur é uma função chamada quando o campo de entrada perde o foco (quando o usuário clica em outro lugar).
  const handleBlur = () => {
    console.log("Data saved:", selectedOthers);
  };

  // Função para remover todos os elementos do selectedOthers
  const handleRemoveAll = () => {
    setSelectedOthers([]);
  };

  // expansaão do icone unidade +
  const [anchorEl, setAnchorEl] = useState(null);

  // F exibir ou ocultar a seleção da unidade
  const handleExpandClick = (index) => {
    setSelectedOthers((prevOthers) => {
      const updatedOthers = [...prevOthers];
      updatedOthers[index].isExpanded = !updatedOthers[index].isExpanded;
      return updatedOthers;
    });
  };

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  console.log("fim");
  console.log(data);
  console.log(selectedOthers);
  // DOM
  // TextField: O valor é atualizado usando o onChange e o handleOthersChange.
  // "Excluir" para remover o outro recurso. Quando clicado, chama handleDeleteForm passando o índice do outro recurso.
  // Botão "Adicionar Outro Recurso" que chama handleCreateForm quando clicado.
  // troca:
  //No campo TextField onde o nome do recurso é inserido, substitua o valor data.nameActivity por selectedOthers[index].nameActivity:
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
        <Grid container spacing={2} sx={{ mt: 4 }}>
          {selectedOthers.map((other, index) => (
            <>
              <Grid container spacing={2} sx={{ mt: 1 }} key={index}>
                <Grid item xs={12} sm={5}>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    {/* <TitleIcon style={{ fontSize: 35 }} sx={{ mr: 1 }} /> */}
                    <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                      <TextField
                        variant="filled"
                        color="success"
                        focused
                        required
                        fullWidth
                        label="Atividade/operação"
                        name="nameActivity"
                        onBlur={handleBlur}
                        onChange={(e) => handleChange(e, index)}
                        value={selectedOthers[index].nameActivity || ""}
                      />
                      <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                        <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                          Insira um recurso desejado
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                      <Select name="valueCost" fullWidth label="Moeda" value={selectedOthers[index].valueCost} onChange={(e) => handleChange(e, index)}>
                        {ValueCost.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="cost"
                    variant="filled"
                    color="success"
                    focused
                    label="Valor da cotação da cultura"
                    type="number"
                    required
                    fullWidth
                    value={selectedOthers[index].cost || ""}
                    onChange={(e) => handleChange(e, index)}
                    error={formError.cost && selectedOthers[index].cost === ""}
                    helperText={formError.cost && selectedOthers[index].cost === "" ? "Por favor, preencha o campo obrigatório!" : "Defina o valor atual da cotação."}
                  />
                </Grid>
                <Grid item xs={12} sm={2} key={index}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                    <IconButton onClick={() => handleExpandClick(index)} aria-expanded={selectedOthers[index].isExpanded} aria-label="Expandir/Recolher">
                      {selectedOthers[index].isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {selectedOthers[index].isExpanded && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                              m: 1,
                              mt: -1,
                              ml: 2,
                              width: 160,
                              height: 120,
                            },
                          }}
                        >
                          <Paper elevation={2}>
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                                <TextField
                                  name="startDate"
                                  label="Etimativa"
                                  type="date"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={selectedOthers[index].startDate || ""}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <FormControl variant="standard" sx={{ m: 1, mt: -2, width: 100 }}>
                                  <InputLabel id={`unit-select-label-${index}`}>Unidade</InputLabel>
                                  <Select name="unitCost" fullWidth label="Unidade" value={selectedOthers[index].unitCost} onChange={(e) => handleChange(e, index)}>
                                    {UnitOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Box>
                    <IconButton variant="contained" color="error" onClick={() => handleDeleteForm(index)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mr: 10 }}>
        <Button variant="contained" color="success" onClick={handleCreateForm}>
          Adicionar Outro Recurso
        </Button>
        <Button sx={{ ml: 2 }} variant="outlined" color="error" onClick={handleRemoveAll}>
          Remover Todos
        </Button>
      </Box>
    </div>
  );
}
