import React, { useContext, useState, useEffect } from "react";
import { Button, Dialog, ListItemText, AppBar, Toolbar, IconButton, Typography, Slide, CircularProgress } from "@mui/material";
import { Box, Card, Container, Fab, ListItemIcon, MenuItem, Paper } from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import { Span } from "../../../../components/Typography";
import { Context } from "../../../../components/Context/AuthContext";
import api from "../../../../services/api";
import FormPropertyEdit from "./FormPropertyEdit";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PropertyButtonEdit({ item, usestore, central }) {
  console.log("PropertyButtonEdit");
  const [open, setOpen] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);
  //console.log(item);
  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false)
  }, [open])

  const propertyActive = usestore.reposProperty.getPropertyStore("id", item.id)[0];
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [property, setProperty] = useState({
    id: propertyActive.id,
    name: propertyActive.name,
    id_user: propertyActive.id_user || userCurrent.id,
    area: propertyActive.area,
    measure: propertyActive.measure || "ha",
    ownerName: propertyActive.ownerName,
    address: propertyActive.address,
    state: propertyActive.state,
    city: propertyActive.city,
    image: propertyActive.image,
    tel: propertyActive.tel,
    description: propertyActive.description,
    isDeleted: 0,
  });
  //console.log(property);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setProperty(propertyActive);
    setFormError({ 'name': '', 'ownerName': propertyActive.ownerName, 'area': propertyActive.area })
    setOpen(false);
  };

  // gaurdar o obj de erros de cada chave
  const [formError, setFormError] = useState({ 'name': '', 'ownerName': property.ownerName, 'area': property.area });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true)
    //console.log(property);
    var formOk = true;

    if (formError.name != '') {
      formOk = false;
    } else if (!property.area) {
      formOk = false;
    } else if (property.ownerName.length < 5) {
      formOk = false;
    }

    if (formOk) {
      const header = { header: { "Content-Type": "application/json", }, };
      const response = await api.put("/property/", property, header).then((response) => {
        if (response.data.isSuccess) {
          toast.success('Propriedade editada com sucesso!');

          usestore.reposProperty.updatePropertyStore(property.id, 'name', property.name);
          usestore.reposProperty.updatePropertyStore(property.id, 'label', property.name);
          usestore.reposProperty.updatePropertyStore(property.id, 'id_user', property.id_user);
          usestore.reposProperty.updatePropertyStore(property.id, 'area', property.area);
          usestore.reposProperty.updatePropertyStore(property.id, 'measure', property.measure);
          usestore.reposProperty.updatePropertyStore(property.id, 'ownerName', property.ownerName);
          usestore.reposProperty.updatePropertyStore(property.id, 'address', property.address);
          usestore.reposProperty.updatePropertyStore(property.id, 'state', property.state);
          usestore.reposProperty.updatePropertyStore(property.id, 'city', property.city);
          usestore.reposProperty.updatePropertyStore(property.id, 'image', property.image);
          usestore.reposProperty.updatePropertyStore(property.id, 'tel', property.tel);
          usestore.reposProperty.updatePropertyStore(property.id, 'description', property.description);
          if (usestore.reposProperty.propertyRepos.id == property.id) {
            usestore.reposProperty.changePropertyRepos({ label: property.name, id: property.id });
          }

          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            handleClose();
          }, 150);
        } else {
          toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
        }
      }).catch((error) => {
        console.log('error');
        console.log(error);
        toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
      })
    } else {
      toast.error("Por favor, preencha os campos obrigatórios");
    }
  };

  return (
    <div>
      {central ?
        <IconButton onClick={handleClickOpen}>
          <EditIcon fontSize="small" />
        </IconButton> :
        <MenuItem onClick={handleClickOpen}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Edição da Propriedade
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* <Card sx={{ m: 2 }}> */}
          <Container component="main" maxWidth="md" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <FormPropertyEdit property={property} setProperty={setProperty} formError={formError} setFormError={setFormError} usestore={usestore} onSubmit={onSubmit} />
            </Paper>
          </Container>
          {/* </Card> */}
          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button sx={{ width: 100, position: 'relative' }} color="success" variant="contained" type="submit" onClick={onSubmit}>
                  {LoadingButton ? <CircularProgress sx={{ mb: 0, }} size="1.5rem" style={{ color: '#fff' }} /> : "Salvar"}
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Dialog>
    </div>
  );
}
