
// data aceita
// data_fim : "14/06/2024"
// data_inicial : "14/06/2023"

function calcularDateProgressBarra(dataInicial, dataFinal) {
  //console.log(dataInicial, dataFinal)
  //29/06/2023
  const [diaInicial, mesInicial, anoInicial] = dataInicial.split("/");
  const [diaFinal, mesFinal, anoFinal] = dataFinal.split("/");

  console.log("calcularDateProgressBarra");
  const dataInicio = new Date(anoInicial, mesInicial - 1, diaInicial);
  const dataFim = new Date(anoFinal, mesFinal - 1, diaFinal);
  const hoje = new Date();
  //console.log(hoje);
  //console.log(dataInicio);

  const totalDias = (Math.ceil((dataFim - dataInicio) / (1000 * 60 * 60 * 24)) ) || 1;
  //console.log(totalDias);
  const diasPassados = Math.ceil((hoje - dataInicio) / (1000 * 60 * 60 * 24));
  //console.log(diasPassados);
  let porcentagem = (diasPassados / totalDias) * 100;
  if (porcentagem > 100) {
    porcentagem = 100;
  }

  return { porcentagem: porcentagem.toFixed(2), totalDias: totalDias, diasPassados: diasPassados };
}

export { calcularDateProgressBarra };



