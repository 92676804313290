import dayjs from 'dayjs'

let listLivestock = []

// Verifica se reposLivestock existe no localStorage
let reposLivestockString = localStorage.getItem('reposLivestock')
if (reposLivestockString) {
  // Converte a string JSON em um objeto JavaScript
  let reposLivestock = JSON.parse(reposLivestockString)
  listLivestock = reposLivestock.state.listLivestock
  console.log('listLivestock')
  console.log(listLivestock)
} else {
  console.log('Nenhum dado encontrado em reposLivestock')
}

console.log(listLivestock)

let listActivitiesLivestock = []

// Verifica se reposLivestock existe no localStorage
let reposActivitiesLivestockString = localStorage.getItem('listActvs')
if (reposActivitiesLivestockString) {
  // Converte a string JSON em um objeto JavaScript
  listActivitiesLivestock = JSON.parse(reposActivitiesLivestockString)
  console.log('listActivitiesHarvest')
  console.log(listActivitiesLivestock)
  // for (const item of listActivitiesHarvest) {
  //   console.log(item)
  //   if (item.areas.length == 0) {
  //     item.areas = item.info_areas
  //   }
  // }
  console.log(listActivitiesLivestock)
} else {
  console.log('Nenhum dado encontrado em reposLivestock')
}

let dataProperty = []

let resposPropertyString = localStorage.getItem('reposProperty')
//console.log(resposPropertyString);
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString)
  dataProperty = resposProperty.state.listProperty[0]
  console.log('imageProperty')
  console.log(dataProperty)
} else {
  console.log('Nenhum dado encontrado em resposProperty')
}

//console.log(dataProperty);
//const base64Image = dataProperty.image;
//console.log(base64Image);

// function loadBase64Image(base64) {
//   return new Promise((resolve, reject) => {
//     const image = new Image();

//     // Evento disparado quando a imagem é carregada com sucesso
//     image.onload = () => {
//       resolve(image);
//     };

//     // Evento disparado em caso de erro ao carregar a imagem
//     image.onerror = () => {
//       reject(new Error("Falha ao carregar a imagem base64."));
//     };

//     // Define a string base64 como fonte da imagem
//     image.src = base64;
//   });
// }
// console.log(dataProperty);
// const base64Image = dataProperty.image;

// loadBase64Image(base64Image)
//   .then((image) => {
//     // A imagem foi carregada com sucesso
//     console.log("Imagem base64 carregada!", image);
//   })
//   .catch((error) => {
//     // Houve um erro ao carregar a imagem
//     console.error(error);
//   });

// Função para converter lotes em string
function formatAreas(areas) {
  console.log(areas)
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return areas.map((area) => `${area.name} (${area.usedArea})\n`)
}

// Função para converter lotes em string
function formatLotes(lotes) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return lotes.map((lote) => `${lote.name} (${lote.total_animals} animais)\n`)
}

function formatData(Inputdata) {
  return !Inputdata ? '' : dayjs(Inputdata).format('DD/MM/YYYY')
}

// Atualizando livestockTableRows
const livestockTableRows = listLivestock.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    { text: item.total_animais || 'Tipo não especificado' }, // Verifica se 'tipo' está disponível
    { text: item.tipo || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.name || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${formatData(item.date_start)} \n Fim:    ${formatData(item.data_fim)}` || 'Data não especificada', fontSize: 7 }, // Converte para data
    { text: formatLotes(item.lotes) || 'Lote não especificado', fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ]
})

console.log(livestockTableRows)

const ActivitiesLivestockTableRows = listActivitiesLivestock.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    // { text: item.cultivation_name || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.activity || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: item?.operation || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${item.date_start_f} \n Fim:    ${item.date_prev_end_f}` || 'Data não especificada', fontSize: 7 }, // Converte para data
    { text: item.nameBatch || 'área não especificada', fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ]
})

const lineBlank = [
  {
    text: '',
    alignment: 'center',
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
]

export const bodyActivitiesLivestockBW = {
  content: [{ text: ' ', style: 'subheader' }],
}

// adicionando a tabela
//adicionando a tabela
bodyActivitiesLivestockBW.content.push(
  { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
  { text: 'Lista', style: 'subheader' },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [20, 40, '*', '*', 60, '*'],
      fillColor: '#eeeeee',
      body: [
        ['#', 'Animais', 'Tipo', 'Projeto(Criação)', 'data', 'Lote'], // Cabeçalhos da tabela
        //{ text: "", italics: true, color: "gray" },
        ...livestockTableRows, // Linhas de dados da tabela
      ],
    },
    fontSize: 10,
    // bold: true,
  }
)

// adicionando texto
bodyActivitiesLivestockBW.content.push({
  width: '*',
  text: `Número de criações: ${dataProperty?.total_livestocks} \n Total de animais: ${dataProperty?.total_animais} \n `,
  fontSize: 10,
  color: '#808080',
  margin: [0, 10, 0, 0],
  alignment: 'right',
})

bodyActivitiesLivestockBW.content.push(
  { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
  { text: 'Lista', style: 'subheader' },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [10, '*', '*', '*', '*'],
      fillColor: '#eeeeee',
      body: [
        [
          { text: '#', style: 'tableHeader' },
          //  { text: 'Tipo', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Tipo Atividade', style: 'tableHeader' },
          { text: 'Operação', style: 'tableHeader' },
          { text: 'Data', style: 'tableHeader' },
          { text: 'Áreas', style: 'tableHeader' },
        ],
        ...ActivitiesLivestockTableRows, // Linhas de dados da tabela
      ],
    },
    fontSize: 10,
  }
)
