import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Loading from "../../../../components/Loader/Loading";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import MultiStepForm from "./form/MultiStepForm";
import ListButtonImageTypeLivestock from "./form/ListButtonImageTypeLivestock";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialogLivestockBeefCattle({ handleCloseTools }) {
  // console.log("1-init-FullScreenDialogLivestockBeefCattle");

  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [data, setData] = useState('');
  const [tipo2, setType] = useState('');

  const handleClickOpen = (event, tipo, tipo2) => {
    // console.log("4-handleClickOpen");
    setData(tipo);
    setType(tipo2);
    setOpen(true);
  };

  //useEffect(() => {
  //  console.log("3-useEffect: reender");
  //  console.log(data);
  //}, [data]);

  const handleClose = () => {
    handleCloseTools();
    setOpen(false);
  };

  //style={{ color: '#2e7d32' }}
  return (
    <Box>
      <ListButtonImageTypeLivestock handleClickOpen={handleClickOpen} />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div">
                  Projeto de {data}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="sm" sx={{ mt: -5 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {false ? <Loading /> :
              <MultiStepForm
                type={data}
                tipo={tipo2}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                handleClose={handleClose} />}
          </Paper>
        </Container>
      </Dialog>
    </Box>
  );
}
