import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppForm from "./AppForm";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { Autocomplete, Box, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { Grid } from "react-loader-spinner";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";

import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Link, NavLink } from "react-router-dom";
import SplitButton from "./SplitButton";
import MenuMsn from "./MenuMsn";
import { useState } from "react";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loading from "../../../../components/Loader/Loading";
import { format } from "date-fns";


const location = [{ label: "Fazenda CPT", id: 1 }];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function CardMsn({ message, setMessage, propertyRepos }) {
  const imageDefault = "/dist/image/solicitacao_default.jpg"

  // para user current
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  //console.log(userCurrent)
  const user = userCurrent;
  //console.log(user)
  //console.log(user.name);

  const name = user.name;

  //const name = message.name_requester
  const charName = name[0].toUpperCase();
  //console.log(charName);

  const date = new Date()
  //post.date = format(date, "MMMM do, yyyy H:mma");
  const dateNow = format(date, "dd/MM/yyyy");

  return (
    <Card>
      {/* <Card sx={{ maxWidth: 345 }}></Card> */}
      <CardHeader
        sx={{ m: -1, ml: -1 }}
        avatar={
          <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
            {charName}
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MenuMsn />
        //   </IconButton>
        // }
        title={user?.nome}
        subheader={dateNow}
      />
      <div style={{ position: "relative" }}>

        <CardMedia component="img" height="125" image={imageDefault} alt="Paella dish" />

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "rgba(0, 128, 0, 0.54)",
            color: "white",
            padding: "63px",
          }}
        >
        </Box>
        {/* <div style={{ position: "absolute", color: "white", bottom: 10, left: "50%", transform: "translateX(-50%)", }}> Some text</div> */}
        <div style={{ position: "absolute", color: "white", bottom: 10, left: "5%" }}>
          <Typography variant="caption" style={{ opacity: 0.7 }}>

          </Typography>

          <Typography variant="h6">Solicitação</Typography>
          <Typography variant="body2">{propertyRepos.label}</Typography>
          {/* <Typography variant="body2">Piquete 1 (4,05 ha)</Typography> */}
        </div>
        <div style={{ position: "absolute", color: "white", bottom: 10, right: "2%" }}>
          {/* <Fab variant="extended" color="success" size="small">
                        <Typography variant="button">Resolver</Typography>
                    </Fab> */}
          <SplitButton />
        </div>
      </div>
      {/* <img className="img-rounded elevation-1" src="/dist/image/bebedouro.png" width={80} style={{ filter: `grayscale(100%)` }} /> */}
      <AppForm message={message} setMessage={setMessage} />
    </Card>
  );
}
