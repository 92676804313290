import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, Grid } from '@mui/material'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useEffect } from 'react'
import api from '../../../../services/api'
import { useContext } from 'react'
import { Context } from '../../../../components/Context/AuthContext'
import { useParams } from 'react-router-dom'
import Typewriter from '../../../../components/Typewriter/Typewriter'
import DeleteIcon from '@mui/icons-material/Delete'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function EditModalFormActivityTrash({ row, usestore }) {
  // console.log('EditModalFormActivityTrash')
  //console.log('row')
  //console.log(row)
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // botao atualizar lixeira
  const [loadingButton, setLoadingButton] = useState(false)

  useEffect(() => {
    setLoadingButton(false)
  }, [])

  const handleClickOpen = () => {
    setLoadingButton(false)
    setOpen(true)
  }

  const handleCloseCancel = () => {
    // console.log('cancelado')
    setTimeout(() => {
      setOpen(false)
      setLoadingButton(false)
    }, 100)
  }

  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context)

  const { idFarm } = useParams()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))

  const handleTrash = async (e) => {
    // console.log('handleTrash')
    e.preventDefault()
    setLoadingButton(true)

    const header = { header: { 'Content-Type': 'application/json' } }
    const status = 'trash'
    let url = ''
    let reposActivity = {}

    if (row.activity == 'ENTRADA') {
      url = `/activityInput/delete/${row.id}/${status}`
      reposActivity = usestore.reposActivityInput
    } else if (row.activity == 'IDENTIFICAÇÃO') {
      url = `/activityIdentify/delete/${row.id}/${status}`
      reposActivity = usestore.reposActivityIdentify
    } else if (row.activity == 'PESAGEM') {
      url = `/activityWeight/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityWeight
    } else if (row.activity == 'MEDICAMENTOS') {
      url = `/activityMedicines/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityMedicines
    } else if (row.activity == 'NUTRICAO') {
      url = `/activityNutrition/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityNutrition
    } else if (row.activity == 'TRANSFERENCIA') {
      url = `/activityTransfer/delete/${row.id}/${status}?param1=${row.nameBatch}&param2=${row.progress}`
      reposActivity = usestore.reposActivityTransferBatch
      //reposActivity = usestore.reposActivityPastureManagement;
    } else if (row.activity == 'PRODUÇÃO') {
      url = `/activityProduction/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityProduction
    }
    //console.log(reposActivity)
    // console.log(url)
    const res = await api
      .delete(url, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          // atualiza o repos
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1)
            toast.success(`Atividade movida para a lixeira com sucesso!`)
            setTimeout(() => {
              setOpen(false)
            }, 200)
          })
          // reposActivityTransferBatch.fetch({ id_farm: property_id }).then((res) => {
          //   console.log("reposActivityTransferBatch - fetch - res");
          //   //console.log(res);
          //   //console.log("reposActivitiesInputs.listActivityInput");
          //   //console.log(reposActivitiesInputs.listActivityInput);
          //   setAuxAdd(aux_add + 1);
          //   // setTimeout(() => {
          //   //   handleClose();
          //   //   //handleNext();
          //   // }, 4000)
          // });
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.error(`Falha ao mover atividade para a lixeira, tente novamente mais tarde!`)
      })
  }

  return (
    <div>
      <BootstrapDialog onClose={handleCloseCancel} aria-labelledby="Lixeira" open={open} title="Lixeira">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseCancel}>
          <DeleteIcon /> Lixeira
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <>
            <Grid sx={{ width: 250, p: 1, m: 1 }}>
              <Box sx={{}}>
                <Typography>A atividade será encaminhada para lixeira.</Typography>
              </Box>
              {row.progress == 'CONCLUÍDA' && (row.activity == 'MEDICAMENTOS' || row.activity == 'NUTRICAO') ? (<>
                <Box sx={{ mt: 2 }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter
                      tam={120}
                      minHeight={0}
                      text="A atividade realizou a baixa no estoque. Se desejar que os produtos voltem ao estoque, será necessário deletar a atividade da lixeira."
                    />
                  </Typography>
                </Box>
              </>) : <></>}
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseCancel} aria-label="Cancelar" title="Cancelar">
            Cancel
          </Button>
          <Button color="success" onClick={handleTrash} aria-label="Lixeira" title="Lixeira">
            Lixeira
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* <span aria-label="Deletar" onClick={handleClickOpen}>
        <DeleteForeverIcon sx={{ color: red[500] }} />
      </span> */}
      <IconButton aria-label="Deletar" onClick={() => handleClickOpen()} title="Deletar">
        <DeleteForeverIcon sx={{ color: red[500] }} />
      </IconButton>
    </div>
  )
}
