import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import Loading from '../../../../components/Loader/Loading';
import { useEffect } from 'react';

export default function ProfileSelect({ isLoading, setIsLoading }) {


    // inicializa com o userCurrent
    const userCurrent = JSON.parse(localStorage.getItem("userCurrent" || undefined));
    console.log('userCurrent')
    console.log(userCurrent)
    //console.log(userCurrent.id_role)

    const [profile, setProfile] = React.useState(userCurrent.id_role);
    const [loading, setLoading] = React.useState(true);

    // atualiza o current do seletor
    //console.log(profile)
    localStorage.setItem("profileCurrent", JSON.stringify(profile));

    const handleChange = (event) => {
        setProfile(event.target.value);
        setIsLoading(true)
    };

    useEffect(() => {
        console.log('atualizou')
        setLoading(false)
    }, [userCurrent])



    return (

        <div>

            {loading && userCurrent ? <Loading /> :

                <Box sx={{ minWidth: 120, mt: 3 }
                } >
                    <FormControl fullWidth size="small">
                        <InputLabel color="success" id="demo-simple-select-label"> Perfil </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={profile}
                            label="Perfil"
                            onChange={handleChange}
                            color="success"
                        //focused={false}
                        >
                            <MenuItem value={1}>Proprietário</MenuItem>
                            <MenuItem value={2}>Gerente</MenuItem>
                            <MenuItem value={3}>Financeiro</MenuItem>
                            <MenuItem value={4}>Operacional</MenuItem>
                        </Select>
                    </FormControl>
                </Box >

            }
        </div>

    );
}