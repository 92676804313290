import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../../../components/Loader/Loading";
import { Button, CircularProgress, Grid, Link } from "@mui/material";
import { Checkmark } from "react-checkmark";
import "./sucess.css";
import { padding } from "@mui/system";
import GroupsIcon from "@mui/icons-material/Groups";
export default function TableProfileTeamEdit({ editUser, newRowsData }) {

  console.log("<TableProfileSolicitacoesEdit/>")
  console.log(newRowsData)

  var newRows = newRowsData?.filter(row => row.id_modulo === 1);
  console.log("newRows")
  console.log(newRows)

  // guarda um linha do módulo
  // const [stateRows, setStateRows] = useState(newRows)
  let stateRows = newRows
  // start da animação do icon check
  //const [loading, setLoading] = useState(true)
  const [loadingView, setLoadingView] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  console.log('1-stateRows')
  console.log(stateRows)

  useEffect(() => {
    //stateRows(newRows)
  }, [stateRows])


  const switchHandler = (event, status, index) => {
    event.preventDefault();
    console.log("switchHandler")
    console.log(event.target)
    //console.log(event.target.parentElement.id)
    //const statusViewEditDelete = event.target.id
    if (event.target.id == "view" || event.target.parentElement.id == "view") {
      //console.log("entrou")
      //console.log(stateRows[index].view)
      stateRows[index].view = !status ? 1 : 0
      // console.log(stateRows[index].view)
      setLoadingView(true)
    }
    if (event.target.id == "edit" || event.target.parentElement.id == "edit") {
      stateRows[index].edit = !status ? 1 : 0
      stateRows[index].create = !status ? 1 : 0
      console.log(stateRows[index].edit)
      setLoadingEdit(true)
    }
    if (event.target.id == "delete" || event.target.parentElement.id == "delete") {
      stateRows[index].delete = !status ? 1 : 0
      setLoadingDelete(true)
    }
    console.log(stateRows)
    //setStateRows(stateRows)
    console.log("newRowsDataXXXXXXXXXXXXXswitchHandler")
    console.log(newRowsData)
    setTimeout(() => {
      console.log("atualizando")
      localStorage.setItem("userPermissionsEdit", JSON.stringify(newRowsData));
      console.log(editUser)
      editUser.permission = newRowsData
      console.log(editUser)
      setLoadingView(false)
      setLoadingEdit(false)
      setLoadingDelete(false)

    }, 2000);
    // console.log(newRowsData)
    // rora para update
  }

  console.log("newRowsDataXXXXXXXXXXXXX final")
  localStorage.setItem("userPermissionsEdit", JSON.stringify(newRowsData));
  console.log(newRowsData)

  return (
    <>
      {false ? <Loading /> : (
        <TableContainer component={Paper}  >
          <Table sx={{ minWidth: 650, p: 0, m: 0 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: 300 }}>
                  <Grid container direction="row" alignItems="center">
                    <GroupsIcon style={{ fontSize: 20 }} /> <strong className="ml-1">Equipe</strong>
                  </Grid>
                </TableCell>
                <TableCell align="center"><VisibilityIcon fontSize="small" color="action" /></TableCell>
                <TableCell align="center"><EditIcon fontSize="small" color="action" /></TableCell>
                <TableCell align="center"><DeleteForeverIcon fontSize="small" color="action" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {stateRows.map((row, index) => (
                <TableRow
                  key={`${row.name} + ${row.profile} `}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left" sx={{ width: 300 }}>
                    Equipe de trabalho Edit(1)
                  </TableCell>
                  <TableCell align="center" onClick={(event) => switchHandler(event, row.view, index)} id="view">{loadingView ? (<div  ><Checkmark size={'24'} /></div>) : (row.view ? <Link href='#' id="view"><CheckCircleOutlineIcon style={{ color: green[500] }} id="view" /> </Link> : <Link href='#'><HighlightOffIcon color="disabled" id="view" /></Link>)}</TableCell>
                  <TableCell align="center" onClick={(event) => switchHandler(event, row.edit, index)} id="edit">{loadingEdit ? (<div  ><Checkmark size={'24'} /></div>) : (row.edit ? <Link href='#'><CheckCircleOutlineIcon style={{ color: green[500] }} id="edit" /> </Link> : <Link href='#'><HighlightOffIcon color="disabled" id="edit" /></Link>)}</TableCell>
                  <TableCell align="center" onClick={(event) => switchHandler(event, row.delete, index)} id="delete">{loadingDelete ? (<div  ><Checkmark size={'24'} /></div>) : (row.delete ? <Link href='#'><CheckCircleOutlineIcon style={{ color: green[500] }} id="delete" /> </Link> : <Link href='#'><HighlightOffIcon color="disabled" id="delete" /></Link>)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
      )}

    </>
  );
}