import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MapId.css";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ReplyIcon from "@mui/icons-material/Reply";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { DrawingManager, GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import LoadScriptOnlyIfNeeded from "../../LoadScript/LoadScriptOnlyIfNeeded";
import useAreasMapReposStore from "../../store/useAreasMapReposStore";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import SaveMapEditButton from "./components/SaveMapEditButton";
import { Avatar, Card, Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { LocationOn } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
// const center = {
//   lat: -20.9223027,
//   lng: -42.7958599,
// };

const libraries = ["drawing"];
const apiKey = "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MapId = () => {
  const navigate = useNavigate();
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxapi do places xxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // 1- carregar repositorio dos dados da api react-query
  // const { data, isFetched, isLoading, error, refetch } = useGetPropertyAreaRepos();
  // console.log("reponse----maps --- Queryxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
  // console.log(data?.data._value);

  // console.log("useGetPropertyAreaRepos");
  // console.log({ isLoading, isFetched });

  const [isLoading, setIsLoading] = useState(false);

  const [map, setMap] = React.useState();
  //Referência do mapa:onload do google para obter a referência do mapa
  // prop onLoad: Essa função onLoad recebe um parâmetro padrão que é a instância atual do mapa .
  // map: google.maps.Map
  // const onLoad = (map) => {
  //   // mapRef.current = map;
  //   setMap(map);
  // };

  //chamada
  //const [state, setState] = useState([]);
  //const [paths] = state

  let btnRef = useRef();

  // componente <MapDraw apikey  center paths point/>
  // paths = {paths}
  //point = {paths=>setState({paths})}
  const point = [];
  // const [point, setPoint] = useState([]);


  // pego o id da area reposAreasMap
  //var property_id = (localStorage.getItem("Property") || 1);
  const { id } = useParams();
  console.log("i------------d");
  console.log(id);

  const [centerInit, setcenterInit] = useState({});
  const [zoom, setZoom] = useState(centerInit.zoom || 15);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //  verificar se exite a latitude da propriedade
  const reposAreasMap = useAreasMapReposStore(state => state);
  //reposProperty.fetch(reposProperty.propertyRepos.id)
  //const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];
  const areasMap_data = reposAreasMap.getAreaMapStore('id', id)[0];
  console.log("areasMap_data[0]");
  console.log(areasMap_data);


  useEffect(() => {
    setcenterInit({
      lat: Number(areasMap_data?.centerInit.lat || '-20.9230016'),
      lng: Number(areasMap_data?.centerInit.lng || '-42.7960554'),
      zoom: Number(areasMap_data?.centerInit.zoom || '14'),
    });
    //setLoading(true);
  }, []);

  console.log("centerInit")
  console.log(centerInit)


  // inicializar area e paths do repos
  const [area, setArea] = useState(0);
  const [path, setPath] = useState([]);

  // pegar o path
  useEffect(() => {
    let areaMapdecoded = JSON.parse(base64_decode(areasMap_data.coordinates));
    setPath(areaMapdecoded);
    console.log("carregou o path");
    console.log(path);
    setArea(areasMap_data.area)
  }, []);

  // poligono

  const [drawingModeEnabled, setDrawingModeEnabled] = useState('polygon');

  const handleEnableDrawingMode = (mode) => {
    setDrawingModeEnabled(mode);

  };


  // definindo referências para instanciar o poligono e listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  //chama setPath para editar novos paths
  //console.log(path);
  //função onEdit usando o useCallback para garantir que a função seja memorizada e atualizada somente quando as dependências mudarem. 
  const onEdit = useCallback(() => {
    console.log(isLoading)
    console.log('onEdit')
    //console.log(polygonRef.current)
    // se não tiver cria
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng()
        }));
      setPath(nextPath);
      console.log("nextPath------")
      console.log(nextPath)
      console.log("calculatePolygonArea - resultado")
      // const areaGoogle = window.google.maps.geometry.spherical.computeArea(path);
      // const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      // setArea(formattedArea)
      console.log()

    }
  }, [polygonRef, setPath]);


  // const onLoadPoly = (polygon) => {
  // if(polygon.editable === false){
  //console.log(polygonRef);
  const onLoadPoly = useCallback(
    (polygon) => {
      console.log('onLoadPoly')
      if (isLoading) {
        console.log("isLoading");
        console.log(isLoading);
        return false;
      }
      //console.log("polygon: ", polygon);
      polygonRef.current = polygon;

      console.log(polygonRef.current)
      const path = polygon.getPath();
      // const areaGoogle = window.google.maps.geometry.spherical.computeArea(path);
      // const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      // console.log("areaGooglexxxxxx")
      // console.log(areaGoogle)
      // setArea(formattedArea)
      console.log(listenersRef.current);
      listenersRef.current.push(path.addListener("set_at", onEdit), path.addListener("insert_at", onEdit), path.addListener("remove_at", onEdit));
      console.log(isLoading);
      //setIsLoading(false)
    },
    [onEdit]
  );
  //}

  // const onUnmount = () => {


  const onUnmount = useCallback(() => {
    console.log('onUnmount')
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 2000);
    console.log("carregou");
  }, []);

  //   // if (btnRef.current) {
  //   //   btnRef.current.setAttribute("disabled", "disabled");
  //   // }

  const optionsDraw = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled
    },
    fillColor: "green",
    fillOpacity: 0.7,
    strokeColor: "#53f444",
    strokeWeight: 2,
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  const renderOverlayContent = () => (
    <Card style={{ position: 'fixed', bottom: '0%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '10px' }}>
      <Box sx={{ p: 1, display: 'flex' }}>
        <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
          {/* <LocationOn sx={{color: grey[500]}} /> */}
          <LocationOn />
        </Avatar>
        <Stack spacing={0} sx={{ ml: 1 }}>
          <Typography fontWeight={700}>Área</Typography>
          <Typography variant="body2" color="text.secondary">
            {area}&nbsp;(ha)
          </Typography>
        </Stack>

      </Box>

    </Card >
  );

  console.log("area")
  console.log(area)

  const handleRemove = () => {
    setPath([]);
    setDrawingModeEnabled('polygon');
    //onEdit()

  };




  //<LoadScript id="scrit-loader" googleMapsApiKey={'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU' || ""} libraries={["places"]}>
  return (
    <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
      {/* <button type="text" placeholder="Last name" onClick={() => (refreshPage())} >Reload</button> */}
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        {/* <FloatingButtoDraw /> */}
        {/* <FloatingButtoDrawGeneral path={path} setPath={setPath} drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
        <LightTooltip title="Retornar" placement="left">
          <Fab sx={{ position: "fixed", top: 1, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={() => navigate("/areasmaps")} aria-label="edit">
            <ReplyIcon />
          </Fab>
        </LightTooltip>
        {/* <LightTooltip title="Apagar área" placement="left">
          <Fab sx={{ position: "fixed", top: 130, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={handleRemove} aria-label="add">
            <HighlightOffIcon fontSize="small" sx={{ mr: 0 }} />
          </Fab>
        </LightTooltip>
        <LightTooltip title="Desenho livre" placement="left">
          <Fab sx={{ position: "fixed", top: 200, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={() => handleEnableDrawingMode('polygon')} aria-label="add">
            <CreateIcon fontSize="small" sx={{ mr: 0 }} />
          </Fab>
        </LightTooltip> */}

      </Box>
      <SaveMapEditButton path={path} area={area} setArea={setArea} areasMap_data={areasMap_data} />
      <GoogleMap
        id="map"
        mapContainerStyle={{
          height: "92vh",
          width: "100vw",
        }}
        center={centerInit}
        zoom={Number(zoom)}
        options={{
          mapTypeId: "satellite", // Define o tipo de mapa padrão como "Satélite"
          //mapTypeControl: true,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: true,
          zoomControlOptions: {
            //style: window.google.maps.ZoomControlStyle.SMALL,
            gestureHandling: "greedy", // Torna o zoom mais sensível
          },
          //zoomControlOptions: {
          //  position: new window.google.maps.ControlPosition.RIGHT_CENTER,
          //  // position: window.google.maps.ControlPosition.TOP_RIGHT, // Posicionando os botões de zoom no canto superior direito
          //  // position: window.google.maps.ControlPosition.BOTTOM_CENTER,
          //  style: new window.google.maps.ZoomControlStyle.SMALL, // Definindo o estilo dos botões de zoom
          //  // Outras opções personalizadas, se necessário
          //},
        }}
      >
        {isLoading && (
          <>
            {renderOverlayContent(area)}
            <Polygon
              //a propriedade paths do objeto Polygon especifica uma matriz
              options={optionsDraw}
              //editable do polígono especifica se os usuários podem editar a forma
              editable
              paths={path}
              onLoad={onLoadPoly}
              onUnmount={onUnmount}
              // Evento usado ao manipular e adicionar pontos
              onMouseUp={onEdit}
              // Evento usado ao arrastar todo o polígono
              onDragend={onEdit}
            //onUnmount={onUnmount}
            //onMouseUp={onEdit}
            //onDragend={onEdit}
            //visible={path.length > 2}
            // propriedade draggable para permitir que os usuários arrastem a forma.
            //draggable
            />
          </>
        )}
      </GoogleMap>
    </LoadScriptOnlyIfNeeded>
  );
};

export default MapId;
