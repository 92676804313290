import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemButton,
  ListItemIcon,
  Drawer,
  Grid,
  Stack,
  MenuItem,
} from '@mui/material';
import { Adjust } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import AdjustIcon from '@mui/icons-material/Adjust'; // Ícone padrão para opções
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Novo ícone para adicionar
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Assignment, Business, Contacts, ExpandMore, FormatAlignJustify, Input, PhoneAndroid, Notes } from '@mui/icons-material';
import PhoneTextField from './PhoneTextField';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useStore from '../../store/useStore';
import './AutocompletGenericList.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutocompletGenericList({ value, options, handleChange, label, isEnabled }) {
  //console.log(label)
  if (!label) {
    label = options.label || 'select';
  }
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const [searchTerm, setSearchTerm] = useState('');

  // Função para atualizar o estado searchTerm baseado na entrada do usuário
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filtro de pesquisa
  let filteredOptions = options.values.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchTerm) ||
      row.type.toLowerCase().includes(searchTerm) ||
      row.document_type.toLowerCase().includes(searchTerm) ||
      row.document.toLowerCase().includes(searchTerm)
      // Adicione aqui mais campos pelos quais deseja permitir a pesquisa
    );
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (value) => {
    setSelectedValue(value); // Atualiza o estado com o valor selecionado
    handleChange(options.field, value); // Atualiza conforme necessário
    handleClose();
  };

  const handleRowClick = (value) => {
    setSelectedValue(value);
    handleChange(options.field, value);
    handleClose();
  };

  // Cabeçalhos da tabela
  const headCells = [
    { id: 'name', align: 'left', label: 'Nome', minWidth: 150 },
    { id: 'tipo', align: 'left', label: 'Tipo' },
    { id: 'documento', align: 'left', label: 'Documento' },
    { id: 'num_doc', align: 'left', label: 'Número doc.' },
    { id: 'Contatos', align: 'center', label: 'Contatos' },
    { id: 'Endereços', align: 'center', label: 'Endereços' },
  ];

  let opt_default = {
    multiple: options.multiple,
    name: options.name || 'generic',
    label: options.label || 'Select',
    values: options.values || [],
    helperText: options.helperText || 'Selecione algo',
    field: options.field || 'generic',
    variant: options.variant || 'outlined',
    listHeight: options.listHeight || 'auto',
    disableCloseOnSelect: options.disableCloseOnSelect || false,
  };

  //console.log("options.values")
  //console.log(options.values)

  // cadastro rapido:
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  // Adiciona manualmente a opção de adicionar novo tipo de patrimônio
  filteredOptions = [{ id: 'new', name: `Incluir novo ${label}` }, ...filteredOptions];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Autocomplete
          onChange={(event, newValue) => {
            if (newValue && newValue.id === 'new') {
              // Abre o diálogo para adicionar um novo tipo de patrimônio
              toggleDialog(true);
            } else {
              setSelectedValue(newValue); // Atualize para usar setSelectedValue
              handleChange(options.field, newValue);
            }
          }}
          sx={{ width: 300 }}
          disableCloseOnSelect={opt_default.disableCloseOnSelect}
          disabled={!isEnabled}
          filterSelectedOptions
          color="success"
          value={selectedValue} // Certifique-se de que selectedValue é atualizado corretamente
          fullWidth
          id={'autocomplete_' + options.name}
          multiple={options.multiple}
          options={filteredOptions} // Use filteredOptions aqui
          ListboxProps={{
            style: { maxHeight: opt_default.listHeight },
          }}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            /*  <li {...props}>
                            {option.icon ? (
                                <span style={{ marginRight: "8px" }}>
                                    {option.icon}
                                </span>
                            ) : (
                                <Adjust
                                    color='success'
                                    sx={{ mr: 1, width: "15px" }}
                                />
                            )}
                            {option.name}
                        </li> */
            <li
              {...props}
              style={
                option.id === 'new'
                  ? {
                      backgroundColor: '#f0f0f0',
                      color: '#007bff',
                      fontWeight: 'bold',
                    }
                  : {}
              }
            >
              {option.id === 'new' ? (
                <>
                  <AddCircleOutlineIcon
                    style={{
                      color: '#2e7d32',
                      marginRight: '8px',
                      fontSize: '15px',
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{
                      color: '#2e7d32',
                    }}
                  >
                    {option.name}
                  </Typography>
                </>
              ) : (
                <>
                  <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                  {option.name}
                </>
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant={opt_default.variant}
              label={opt_default.label}
              color="success"
              InputLabelProps={{ style: { color: '#2e7d32' } }}
              helperText={opt_default.helperText}
              focused
            />
          )}
          noOptionsText="Sem resultados"
        />
        <Box
          sx={{
            border: 2,
            borderColor: 'success.main',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            mt: -3,
          }}
        >
          {/* <Divider
                        sx={{ height: 30, m: 0.2, mt: -2 }}
                        orientation='vertical'
                    /> */}
          <IconButton
            color="success"
            // sx={{ p: "0px", mt: -2, ml: -1 }}
            aria-label="directions"
            onClick={handleClickOpen}
          >
            <SearchIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Box>

        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
            <Toolbar>
              <Typography
                sx={{
                  color: '#000',
                  flexGrow: 1,
                  textAlign: 'center',
                }}
                variant="h6"
                component="div"
              >
                Pesquisar {label}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: '#000' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <TextField
                sx={{ width: '100%', maxWidth: '300px', mb: 4 }}
                id="filled-search"
                label={`Pesquisar ${label}`}
                type="search"
                variant="standard"
                color="success"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.align}
                          style={{
                            minWidth: headCell.minWidth,
                          }}
                        >
                          <strong>{headCell.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOptions.map((row) => (
                      <TableRow key={row.id} hover onClick={() => handleRowClick(row)}>
                        <TableCell align="left" style={{ color: '#2e7d32' }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.document_type}</TableCell>
                        <TableCell align="left">{row.document}</TableCell>
                        <TableCell align="center">{row.Contatos}</TableCell>
                        <TableCell align="center">{row.Endereços}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Container>
        </Dialog>
      </div>
      {/* Diálogo para adicionar um novo tipo de patrimônio */}
      <NewSupplierDialog value={value} dialogOpen={dialogOpen} toggleDialog={toggleDialog} handleChange={handleChange} setSelectedValue={setSelectedValue} type={label} options={options} />
    </>
  );
}

const typePerson = [
  {
    value: 'Física',
    label: 'Física',
  },
  {
    value: 'Jurídica',
    label: 'Jurídica',
  },
  {
    value: 'Outro',
    label: 'Outro',
  },
];
const documentType = [
  {
    value: 'CPF',
    label: 'CPF',
    display: 'Jurídica',
  },
  {
    value: 'CNPJ',
    label: 'CNPJ',
    display: 'Física',
  },
  {
    value: 'RG',
    label: 'RG',
    display: 'todos',
  },
  {
    value: 'Outro',
    label: 'Outro',
    display: 'todos',
  },
];

function NewSupplierDialog({ value, dialogOpen, toggleDialog, handleChange, setSelectedValue, type, options }) {
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const usestore = useStore();

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,

    types: {
      cliente: type && /C|cliente/.test(type) ? true : false,
      fornecedor: type && /F|fornecedor/.test(type) ? true : false,
      transportadora: type && /T|transportadora/.test(type) ? true : false,
    },
    fix_type: type ? false : true,

    typePerson: 'Jurídica',
    document: '',
    document_type: 'CNPJ',

    name: '',
    corporateReason: '', // razão social - company name
    stateRegistration: '',
    municipalRegistration: '',
    simples_nacional: 'nao',
    dateBirth: '',

    contacts: [],
    contact_err: false,
    addresses: [],
    address_err: false,

    observations: '',
  });

  const contact = {
    name: '',
    email: '',
    phoneCell: '',
    phoneCommercial: '',
    office: '', // responsibility
  };

  const [validName, setValidName] = useState([true, '']);
  const validateName = (value) => {
    var name_person = value.trim();
    if (!name_person || name_person.length < 5) {
      setValidName([false, 'Por favor, insira um nome válido para a pessoa!']);
    } else {
      var list_persons = usestore.reposPerson.getPersonStore('name', name_person);
      if (list_persons.length > 0) {
        var compare_person = list_persons.filter((person) => {
          if (person.id != data.id) {
            return person;
          }
        });
        if (compare_person.length > 0) {
          setValidName([false, 'Já existe outra Pessoa com esse nome!']);
        }
      } else {
        setValidName([true, '']);
      }
    }
  };
  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return '* Mínimo de 5 caracteres';
      } else {
        return (
          <p id="NamePersonError" className="form-text text-danger is-invalid">
            {validName[1]}
          </p>
        );
      }
    } else {
      return validName[num];
    }
  };
  const handleChangeNew = (event, blur) => {
    let field = event.target.name;
    let value = event.target.value;

    setData((prevState) => ({ ...prevState, [field]: field == 'name' && blur ? value.trim() : value }));
    if (field == 'typePerson') {
      if (value == 'Jurídica') {
        setData((prevState) => ({ ...prevState, document_type: 'CNPJ' }));
      } else if (value == 'Física') {
        setData((prevState) => ({ ...prevState, document_type: 'CPF' }));
      }
    }
    if (field == 'name') {
      validateName(value);
    }
  };
  const onSubmit = async () => {
    let formOk = !data.name || data.name.length < 5 ? false : true;

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('/person/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            usestore.reposPerson.fetch({ id_property: property_id }).then((res) => {
              //setAuxAdd(aux_add + 1);
              setTimeout(() => {
                var type_inserted = '';
                var person_inserted;
                if (/C|cliente/.test(type)) {
                  toast.success('Cliente registrado com sucesso!');
                  type_inserted = 'client';
                  person_inserted = usestore.reposPerson.getCompostPersonStore([
                    ['type', 'Cliente'],
                    ['name', data.name],
                  ])[0];
                } else if (/F|fornecedor/.test(type)) {
                  toast.success('Fornecedor registrado com sucesso!');
                  type_inserted = 'supplier';
                  person_inserted = usestore.reposPerson.getCompostPersonStore([
                    ['type', 'Fornecedor'],
                    ['name', data.name],
                  ])[0];
                } else if (/T|transportadora/.test(type)) {
                  toast.success('Fornecedor registrado com sucesso!');
                  type_inserted = 'supplier';
                  person_inserted = usestore.reposPerson.getCompostPersonStore([
                    ['type', 'Fornecedor'],
                    ['name', data.name],
                  ])[0];
                }
                toggleDialog(false);
                setSelectedValue(person_inserted);
                handleChange(type_inserted, person_inserted);
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar a Pessoa, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const content = () => (
    <Box
      sx={{ width: 300, zIndex: 1401 }}
      role="presentation"
      // onClick={() => toggleDialog(false)}
      //onKeyDown={() => toggleDialog(false)}
    >
      <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 }, m: 2 }}>
          <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Cadastro rápido:</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0, width: 250 }}>
            {/* Informações gerais */}
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="typePerson"
                  name="typePerson"
                  label="Tipo de pessoa"
                  select
                  fullWidth
                  value={data.typePerson}
                  onChange={handleChangeNew}
                  onBlur={(e) => handleChangeNew(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  color="success"
                >
                  {typePerson.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="document_type"
                  name="document_type"
                  label="Tipo de Documento"
                  select
                  fullWidth
                  autoComplete="document"
                  value={data.document_type}
                  onChange={handleChangeNew}
                  onBlur={(e) => handleChangeNew(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  color="success"
                >
                  {documentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} style={{ display: option.display != data.typePerson ? 'flex' : 'none' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="document"
                  name="document"
                  label="Número do documento"
                  //label={"Número do " + data.document_type}
                  fullWidth
                  autoComplete="document"
                  value={data.document}
                  onChange={handleChangeNew}
                  onBlur={(e) => handleChangeNew(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  color="success"
                />
              </Grid>

              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <TextField
                  required
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="name"
                  name="name"
                  label="Nome"
                  // label={data.typePerson == 'Jurídica' ? "Nome Fantasia" : "Nome"}
                  fullWidth
                  autoComplete="name"
                  variant="standard"
                  color="success"
                  value={data.name}
                  onChange={handleChangeNew}
                  onBlur={(e) => handleChangeNew(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  //error={!data.name || data.name.length < 5}
                  //helperText={"* Mínimo de 5 caracteres"}
                  error={!returnValidName(0)}
                  helperText={returnValidName(1)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                                <PhoneTextField
                                    phone={contact.phoneCell}
                                    // handleChange={(e) =>
                                    //     handleChange(
                                    //         index,
                                    //         "phoneCell",
                                    //         e.target.value
                                    //     )
                                    // }
                                    format={{
                                        name: "phoneCell",
                                        label: "Telefone Celular",
                                        variant: "standard",
                                        icon: <PhoneAndroid />,
                                    }}
                                />
                            </Grid> */}
              <Grid item xs={12} sm={12} sx={{ mt: 4 }}>
                <Button variant="contained" color="success" onClick={onSubmit}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={dialogOpen}
      onClose={() => toggleDialog(false)}
      sx={{ zIndex: 1400 }} // Ajuste do zIndex para sobrepor Dialog
    >
      {content()}
    </Drawer>
  );
}

// const NewSupplierDialog = ({ dialogOpen, toggleDialog }) => (
//     <Dialog open={dialogOpen} onClose={toggleDialog}>
//         <div style={{ padding: "20px" }}>
//             <h2>Adicionar Novo Fornecedor</h2>
//             {/* Formulário de adição do novo fornecedor */}
//             <Button onClick={toggleDialog}>Fechar</Button>
//         </div>
//     </Dialog>

// )

// function NewSupplierDialog({ dialogOpen, toggleDialog }) {
//   const [state, setState] = React.useState({
//     top: false,
//     left: false,
//     bottom: false,
//     right: false,
//   });

//   const toggleDrawer = (anchor, open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor) => (
//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {['All mail', 'Trash', 'Spam'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   return (
//     <div>
//       {['left', 'right', 'top', 'bottom'].map((anchor) => (
//         <React.Fragment key={anchor}>
//           <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
//           <Drawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//           >
//             {list(anchor)}
//           </Drawer>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }
