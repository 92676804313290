import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { green } from "@mui/material/colors";
import { Grid } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";

export default function TableProfileTeam({ newRowsData }) {

  var newRows = newRowsData.filter(row => row.id_modulo === 1);
  console.log("newRows")
  console.log(newRows)

  newRows.map((row) => console.log(row.view))


  return (
    <TableContainer component={Paper}  >
      <Table sx={{ minWidth: 650, p: 0, m: 0 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ width: 300 }}>Funcionalidade</TableCell>
            <TableCell align="center"><VisibilityIcon fontSize="small" color="action" /></TableCell>
            <TableCell align="center"><EditIcon fontSize="small" color="action" /></TableCell>
            <TableCell align="center"><DeleteForeverIcon fontSize="small" color="action" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newRows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ width: 300 }}>
                <Grid container direction="row" alignItems="center">
                  <GroupsIcon style={{ fontSize: 20 }} /> <strong className="ml-1">Usuários</strong>
                </Grid>

              </TableCell>
              <TableCell align="center">{row.view ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
              <TableCell align="center">{row.edit ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
              <TableCell align="center">{row.delete ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}