import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateDrawAreaList from '../../../areasMaps/components/tutorial/CreateDrawAreaList';
import { Box, ButtonBase, List, ListItem, ListItemText, Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import SpaIcon from '@mui/icons-material/Spa';
import CreateDrawPropertyList from '../../../livestock/components/addLivestock/tutorial/CreateDrawPropertyList';
import { green, grey } from '@mui/material/colors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ActivitiesInputList from './ActivitiesInputList';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function TutoriaActivitiesSoilPreparationAccordion() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClickLivestock = () => {
    navigate('/livestock');
  };

  return (
    <div>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" fontWeight={300}>
          As atividades de manejo na pecuária pode incluir uma série de etapas, tais como a entrada de animais na propriedade, identificação individual dos animais, contagem do rebanho, definição de
          lotes e criação de áreas de produção.
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700}>Preparação de solo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ActivitiesInputList />
          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography fontWeight={700}>Identificações dos animais</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography fontWeight={700}>Contagem dos animais</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography fontWeight={700}>Definição de Lotes</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography fontWeight={700}>Criação das áreas</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
    </div>
  );
}
