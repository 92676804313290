import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function ToolipBoostrap({ width, text, longText }) {
  return (
    <div>
      {/* <Tooltip title={longText}>
        <Button sx={{ m: 1 }}>{text}</Button>
      </Tooltip> */}
      <BootstrapTooltip title={longText}>
        <Button color="success">{text}</Button>
      </BootstrapTooltip>
    </div>
  );
}
