import { SvgIcon } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import IconsLivestock from './IconsLivestock'
import { IconGeneral } from './unit/IconGeneral'
import { AddLocationAlt, Balance, TrendingUp, WrongLocation } from '@mui/icons-material'
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import CampaignIcon from '@mui/icons-material/Campaign'
import FoodBankIcon from '@mui/icons-material/FoodBank'

export default function IconsLivestockActivities({ activity, type, widthStyles, fill }, props) {
  //width={200} height={200} style={{ width: "100%", height: "auto" }}
  // console.log('IconsLivestockActivities')
  //console.log(activity, type);
  // console.log(activity)
  switch (activity) {
    case 'ENTRADA':
      return <IconsLivestock type={type} widthStyles={widthStyles} fill={fill} />
    case 'IDENTIFICAÇÃO':
      return <FormatListBulletedIcon />
    case 'ESP. LOTES':
      return <MapIcon />
    case 'CONTAGEM':
      return <FormatListNumberedRtlIcon />
    case 'TRANSFERENCIA':
      return <MultipleStopIcon />
    case 'PESAGEM':
      return <Balance />
    case 'NOVO LOTE':
      return <CropOriginalIcon widthStyles={widthStyles} />
    case 'VINCULAÇÃO DE ÁREAS':
      return <AddLocationAlt />
    case 'MARKETING':
      return <CampaignIcon />
    case 'DESVINCULAÇÃO DE ÁREAS':
      return <WrongLocation />
    case 'MEDICAMENTOS':
      return <VaccinesIcon />
    case 'NUTRICAO':
      return <FoodBankIcon />
    case 'PRODUÇÃO':
      return <TrendingUp />
    default:
      return <IconGeneral widthStyles={widthStyles} fill={fill} />
    // console.log("Desculpe, estamos sem nenhuma " + activity + ".");
    // return <p>E</p>;
  }
}
