import * as React from 'react'
import Box from '@mui/material/Box'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Divider, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PublicIcon from '@mui/icons-material/Public'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import MapIcon from '@mui/icons-material/Map'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useNavigate } from 'react-router-dom'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'

export default function Understanding({ listPropertyRepos }) {
  const navigate = useNavigate()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent
  console.log(user)
  const name = user.name
  const charName = name[0].toUpperCase()
  console.log(charName)

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ ml: 1, display: 'flex' }}>{/* <Typography fontWeight={700}>Produção por área</Typography> */}</Box>

        <Card>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={12} sx={{ m: 2 }}>
              <Typography fontWeight={700}>Seja bem-vindo!</Typography>
              <Typography>O Fazenda Premium tem como principal objetivo ajudar você a ter um gerenciamento de sucesso.</Typography>
              <Typography>Para que esse sucesso aconteça, precisamos entender como o Fazenda Premium poderá ajudar.</Typography>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <div style={{ transform: 'rotate(90deg)' }}>
                    <AccountTreeIcon style={{ fontSize: 45 }} />
                  </div>
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Divisão em níveis ou camadas:</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> O Fazenda premium apresenta 2 níveis de gerenciamento.
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>No primeiro nível administramos a nossa propriedade.</Typography>
                  <Typography>No segundo nível gerenciamos a nossa safra e ou criação.</Typography>
                  <Typography>Temos também a área do usuário que é o local para acessar e gerenciar todas as configurações e informações do sistema.</Typography>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>
                      <img sx={{ width: '100%' }} src="/dist/image/niveisdegestao.png" title="atividades" />
                    </Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <MapsHomeWorkIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Nível 1: Propriedade</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Resumo ilustrativo das principais funcionalidades do gerenciamento da propriedade
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>
                      <img sx={{ width: '100%' }} src="/dist/image/PropriedadeFuncionalidades.png" title="atividades" />
                    </Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <ImageSearchIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Nível 2: Safra</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Resumo ilustrativo das principais funcionalidades do gerenciamento da safra
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>
                      <img sx={{ width: '100%' }} src="/dist/image/bar_harvest.png" title="atividades" />
                    </Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <ImageSearchIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Nível 2: Criação</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Resumo ilustrativo das principais funcionalidades do gerenciamento da safra
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>
                      <img sx={{ width: '100%' }} src="/dist/image/bar_creation.png" title="atividades" />
                    </Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <ImageSearchIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Área do Usuário</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Resumo ilustrativo das principais funcionalidades da área do usuário
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>{/* <img sx={{ width: '100%' }} src="/dist/image/bar_creation.png" title="atividades" /> */}</Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <Card>
                <Box sx={{ p: 2, display: 'flex' }}>
                  <AirlineStopsIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Próximos passos</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Nosso próximo passo é realizar as configurações iniciais
                    </Typography>
                  </Stack>
                </Box>
                <Divider />

                <List>
                  <ListItem>
                    <ListItemText primary="1-Registrar o nome da propriedade" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="2-Registrar a localização da propriedade" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="3-Definir as área produtivas" s />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="4-Criar o seu primeiro projeto agrícola ou pecuário" s />
                  </ListItem>
                </List>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                  <Typography>Na aba superior, acesse "PRIMEIRO ACESSO" e realize as configurações inciais do sistema.</Typography>
                </Stack>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <Card>
                <Box sx={{ p: 2, display: 'flex' }}>
                  <AirlineStopsIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Próximos passos</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Planejar e gerenciar as atividades de produção.
                    </Typography>
                  </Stack>
                </Box>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                  <Typography>Na aba superior, acesse "PRIMEIRO ACESSO" e realize as configurações inciais do sistema.</Typography>
                </Stack>
              </Card>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Card>
                <Box sx={{ p: 2, display: 'flex' }}>
                  <AirlineStopsIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Próximos passos</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Familiarizar com as funcionalidades do Fazenda Premium
                    </Typography>
                  </Stack>
                </Box>
                <Divider />

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                  <Typography>Na aba superior, acesse "Tour na Propriedade" e realize uma passeio nas funcionalidades do Fazenda Premium.</Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  )
}

//https://aegro.com.br/termos-de-uso/
