import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardHeader, IconButton, Stack } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { base64ToBlob } from '../../../../../components/Image/base64ToBlob';

export default function FormImageMarketing({ page, setPage }) {
  console.log('FormImageMarketing');
  const [selectedImage, setSelectedImage] = useState(page.image);
  const [uploadedImage, setUploadedImage] = useState(null);
  console.log(page);
  // const handleChange = (event) => {
  //   event.persist();
  //   console.log(event.target.name);
  //   setProperty({ ...property, [event.target.name]: event.target.value });
  // };

  const handleImageUpload = async (event) => {
    // Lógica para lidar com o upload da imagem
    console.log('compress imagem');
    const file = event.target.files[0];
    console.log(file);
    // setSelectedImage(URL.createObjectURL(file));
    console.log('file');
    setPage({ ...page, file: file });
    // setPage((prevState) => ({
    //   ...prevState,
    //   file: file,
    // }));

    // setPage({ ...page, image: file });

    // Defina as opções de compressão desejadas
    const options = {
      maxSizeMB: 1, // Tamanho máximo desejado em megabytes
      // 800, a largura ou altura do arquivo comprimido não excederá 800 pixels, mantendo a proporção original da imagem.
      maxWidthOrHeight: 800, // Largura ou altura máxima desejada em pixels
      useWebWorker: true, // Utilizar um worker para melhor desempenho (opcional)
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log('Tamanho do arquivo comprimido:', compressedFile.size);
      //Tamanho do arquivo comprimido: 60316 = 60.316 bytes
      console.log(compressedFile);
      // Enviar o arquivo comprimido para onde você precisa
      // Exemplo: enviar para a função handleImageUploadServer
      //blob
      handleImageUploadServer(compressedFile);
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
    }
  };

  const handleImageUploadServer = async (file) => {
    console.log('Upload de imagem ');
    console.log(file);

    //setPage({ ...page, imageI: file });

    const reader = new FileReader();
    reader.onloadend = async (event) => {
      // console.log(event);
      //console.log("reader.result");
      //console.log(reader.result);
      //console.log(event.target);
      const compressedImageData = event.target.result;
      // console.log(compressedImageData);
      //const compressedImagebase64 = LZString.compressToBase64(reader.result);

      const blobAreaTranferImage = base64ToBlob(compressedImageData, 'image/jpeg');
      console.log(blobAreaTranferImage); // O objeto Blob resultante

      setPage((prevState) => ({
        ...prevState,
        imageBlob: blobAreaTranferImage,
      }));

      setPage({ ...page, image: compressedImageData, imageBlob: blobAreaTranferImage });

      const base64String = compressedImageData.split(',')[1]; // Obtém a parte da string base64 após a vírgula
      setSelectedImage(compressedImageData);
      // Aqui você pode fazer o que for necessário com a string base64, como salvá-la no banco de dados
      //console.log("String base64:", base64String);

      // Exemplo de uso: atribuir a string base64 à propriedade 'preview' do objeto uploadedFile
      // var uploadedFile = {
      //   file: event.target.files[0],
      //   id: 1,
      //   name: event.target.files[0].name,
      //   size: event.target.files[0].size,
      //   type: event.target.files[0].type,
      //   preview: base64String, // Atribuir a string base64 à propriedade 'preview'
      //   progress: 0,
      //   uploaded: false,
      //   error: false,
      // };
      var uploadedFile = {
        //file: event.target.files[0],
        id: 1,
        name: file.name,
        size: file.size,
        type: file.type,
        preview: base64String, // Atribuir a string base64 à propriedade 'preview'
        progress: 0,
        uploaded: false,
        error: false,
      };
      setUploadedImage(uploadedFile);

      console.log(uploadedFile);
    };

    reader.readAsDataURL(file);
  };

  const handleEditProperty = () => {
    // Lógica para editar as informações da propriedade
    console.log('Editar propriedade');
  };

  // function displayString() {
  //   console.log("Base64String about to be printed");
  //   alert(selectedImage);
  // }

  const getBase64Image = () => {
    // Verifique se a propriedade 'preview' contém a string base64
    if (selectedImage) {
      return selectedImage;
    }
    if (uploadedImage && uploadedImage.preview) {
      const imageFile = `data:image/jpeg;base64, ${uploadedImage.preview}`;
      console.log('imageFile');
      console.log(imageFile);
      return imageFile;
    }
    return ''; // Retorna uma string vazia caso não haja base64 disponível
  };
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Insira uma imagem da sua criação.
        </Typography>
      </Box>

      <Card>
        <CardActionArea>
          {selectedImage ? (
            <>
              <CardMedia component="img" height="194" image={getBase64Image()} alt="" />
            </>
          ) : (
            <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
              <IconButton color="success" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                <PhotoCamera />
              </IconButton>
              <span>Clique aqui para inserir a imagem da sua criação</span>
            </Box>
          )}

          <CardHeader
            action={
              <>
                {uploadedImage && (
                  <IconButton color="success" aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                    <PhotoCamera />
                  </IconButton>
                )}
              </>
            }
          />
        </CardActionArea>
      </Card>
    </>
  );
}
