import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { AppBar, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper, Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { Span } from '../../../../components/Typography'
import Typewriter from '../../../../components/Typewriter/Typewriter'
import ModalSelectCultivation from './ModalSelectCultivation'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function SelectHarvest({ isDelete, openModalProject, handleClose }) {
  const [openMP, setOpenMP] = useState(openModalProject || false)
  const [open, setOpen] = useState(openMP)

  const handleClickOpen = () => {
    // console.log('handleClickOpen')
    // console.log(open)
    setOpen(true)
    setOpenMP(true)
  }

  useEffect(() => {
    setOpen(openMP)
  }, [openMP])

  const handleCloseSelect = () => {
    if (handleClose) {
      handleClose(false)
    }
    setOpenMP(false)
    setOpen(false)
  }

  if (open != openMP) {
    setOpen(openMP)
  }

  return (
    <>
      <div>
        {isDelete ? (
          ''
        ) : (
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>
            Criar&nbsp;Projeto (Safra)
          </Button>
        )}

        <Dialog open={open} onClose={handleCloseSelect} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'}>
          <DialogTitle>
            <div className="d-lg-none">
              <Toolbar sx={{ height: 50 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} alignItems="center">
                    <Typewriter text="Escolha o tipo de Cultura para sua Safra!" variant={'subtitle1'} color={'#2e7d32'} />
                  </Grid>
                </Grid>
              </Toolbar>
            </div>
            <div className="d-none d-lg-block">
              <Toolbar>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} alignItems="center">
                    <Typewriter text="Escolha o tipo de Cultura para sua Safra!" variant={'h6'} color={'#2e7d32'} />
                  </Grid>
                </Grid>
              </Toolbar>
            </div>
          </DialogTitle>

          <Divider />
          <DialogContent sx={{ mb: 8, textAlign: 'center' }}>
            <ModalSelectCultivation handleCloseTools={handleCloseSelect} />
          </DialogContent>
          <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleCloseSelect}>
                  <Span>Fechar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Dialog>
      </div>
      {/* )} */}
    </>
  )
}
