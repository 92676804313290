import React, { createContext, useState, useEffect } from "react";
import useAuth from "./hooks/useAuth";

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

const Context = createContext(null);

function AuthProvider({ children }) {
  const { authenticated, handleLogin, handleLogout, setAuthenticated, setIsLoading, isLoading, handleRepos } = useAuth();

  // atualizar de acordo com a adição de itens
  const [aux_add, setAuxAdd] = useState(0);

  // atualizar de acordo com o menu superiot
  const [menuContext, setMenuContext] = useState(0);

  return (
    <Context.Provider
      value={{ authenticated, handleLogin, handleLogout, setAuthenticated, setIsLoading, isLoading, handleRepos, 
        aux_add, setAuxAdd,
        menuContext, setMenuContext,
        isMobile,
    }}
    >
      {children}
    </Context.Provider>
  );
}

// exporta o context para usar as informações em outro componentes 
// Qual contexto quero pegar os dados = Context
// AuthProvider para envolver a aplicação
export { Context, AuthProvider };
