import api from "../../services/api";

const sendQuestionReceiveAnswer = async (pergunta) => {
  const headers = {
    header: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await api.post("http://localhost:5001/api/v1/chat/", { prompt: pergunta }, headers);
    const responseData = response.data;
    return responseData.res;
  } catch (error) {
    console.error(error);
    return "Opss.. Algo deu errado. Tente novamente.";
  }
};

export default sendQuestionReceiveAnswer;
