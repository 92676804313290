// Fora do escopo do componente
export function createNotificationPropertySetting(property) {
  // Substituir os textos no objeto property
  const statusMapping = {
    label: 'Nome da propriedade',
    address: 'Localização no Mapa',
    area: 'Área total da propriedade',
    tel: 'Telefone',
    image: 'Imagem',
  };

  const formattedStatus = Object.entries(property.status).reduce((acc, [key, value]) => {
    acc[statusMapping[key]] = value;
    return acc;
  }, {});

  // Separar registros completos e incompletos
  const complete = [];
  const incomplete = [];

  Object.entries(formattedStatus).forEach(([key, value]) => {
    if (value === 'Ok') {
      complete.push(key);
    } else {
      incomplete.push(key);
    }
  });

  const titleText = 'O cadastro da propriedades consta os registros:';
  const completeText = complete.length > 0 ? complete.join(', ') : 'Nenhum registro completo';
  const incompleteText = incomplete.length > 0 ? incomplete.join(', ') : 'Nenhum registro incompleto';

  const titleNotification = `. Sugestão: assim que tiver tempo realize o cadastro completo, restam: ${incompleteText}.`;
  const titleAction = `\n Clicando aqui que será encaminado para a edição da propriedade.`;

  const title = `${titleText}\n${completeText}\n${titleNotification}\n${titleAction}`;

  const notification = {
    group: 'Geral',
    type: 'info',
    title: 'Informação',
    text: title,
    link: '/property',
  };

  return { notification, hasIncomplete: incomplete.length > 0 };
}
