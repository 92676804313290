import { useState } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import InputIcon from "@mui/icons-material/Input";
import FormInputFooter from "./FormInputFooter";
import ThreeDotsSpinner from "../../../../../components/Loader/ThreeDotsSpinner";
// import DialogRegisterArea from "../../../../areasMaps/components/DialogRegisterArea";
import FormInputStep3AreasMapsAreasMapsRegister from "./FormInputStep3AreasMapsAreasMapsRegister";

export default function FormInputStep3AreasMaps({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  const [showText, setShowText] = useState(false);
  // const [addArea, setAddArea] = useState(0);
  const addArea = 0;

  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  // useEffect(() => {
  //   data.areas = [];
  // }, []);

  // para validar o button continuar
  const isError = () => {
    // console.log(data.areas);
    if (data.areas.length >= 1) {
      return false;
    }
    return true;
  };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Entradas dos animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 120 }}>
            <img style={{ maxHeight: 120 }} src="/dist/image/lotedeanimaisnaarea.png" alt="Lotes - Área" class="img-fluid" />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área da Criação</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={12} style={{ height: 20 }}>
            {showText ? (
              <>
                <div className="d-lg-none">
                  <Typography gutterBottom style={{ color: "#2e7d32" }}>
                    <Typewriter tam={20} minHeight={0} text="É hora de informar onde o seu lote se encontra." />
                  </Typography>
                </div>

                <div className="d-none d-lg-block">
                  <Typography gutterBottom style={{ color: "#2e7d32" }}>
                    <Typewriter tam={0} minHeight={0} text="É hora de informar onde o seu lote se encontra." />
                  </Typography>
                </div>
              </>
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                {" "}
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container alignItems="center" justifyContent="center" xs={12} sx={{ mt: 8, p: 1, borderRadius: 1, border: '1px solid #ddd', borderColor: 'grey.200', }} >
                <Grid item xs={12} md={6}  >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography fontWeight={700} >
                            Adicione uma nova área
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DialogRegisterArea setAddArea={setAddArea} />
                       
                    </Box>

                </Grid>
            </Grid> */}

      <Grid item xs={12} alignItems="center" sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} alignItems="center">
            <Typography fontWeight={700} gutterBottom>
              Selecionar a área para o lote
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <FormInputStep3AreasMapsAreasMapsRegister data={data} setData={setData} newArea={addArea} isError={isError} />
          </Grid>
        </Grid>
      </Grid>

      <FormInputFooter
        data={data}
        setData={setData}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        steps={steps}
        isError={isError}
        handleClose={handleClose}
        returnError={returnError}
      />
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 4.8 }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                    Retornar
                </Button>
                <Button variant="contained" color="success" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
                    {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
                </Button>
            </Box> */}
    </>
  );
}
