import { SvgIcon } from '@mui/material';
import React from 'react';

export default function BancoDoBrasilIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 192.756 192.756">
      {/* Conteúdo do seu SVG aqui */}
      <g fillRule="evenodd" clipRule="evenodd">
        {/* <path fill="#fff" d="M0 0h192.756v192.756H0V0z" /> */}
        <path
          fill="#33348e"
          d="M96.396 16.925l-78.85 52.85 32.819 21.311L89.577 64.66 74.023 54.443l21.305-14.274 46.231 30.679-75.845 50.918 16.405 10.865 94.379-62.848-.213-.213-79.889-52.645zM175.027 16.947l-32.808 21.731 16.404 11.078 16.404-11.078V16.947zM17.005 175.832l32.809-21.73-16.404-11.079-16.405 11.079v21.73z"
        />
        <path
          fill="#33348e"
          d="M96.36 175.787l78.849-52.851-32.818-21.311-39.211 26.426 15.552 10.217-21.304 14.275-46.231-30.68 75.844-50.917-16.404-10.866-94.38 62.848.213.215 79.89 52.644z"
        />
      </g>
    </SvgIcon>
  );
}



