import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';

// create the store
const useListImagesStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listImages: [],
        // Instacia a listImages ||| item: {id: '', tipo: '', id_farm: ''}
        fetch: async (item) => {
          if (!item) {
            return [];
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('upload/list', item || {}, header);
          const data = await response.data;
          // console.log('upload');
          // console.log(data);
          await set({
            listImages: data._value.map((image) => ({
              // img: `https://cpt-soft.s3.us-east-1.amazonaws.com/${image.key_image}`,
              img: image.url,
              id_property: image.id_property,
              title: image.name,
              image: image.key_image,
              size: image.size,
              folder: image.type,
              tipo: image.id_user ? 'user' : image.id_livestock ? 'livestock' : image.id_harvest ? 'harvest' : image.id_area ? 'area' : image.id_animal ? 'animal' : image.id_lote ? 'lote' : 'farm',
              id_item: image.id_user || image.id_livestock || image.id_harvest || image.id_area || image.id_animal || image.id_lote,
              isDeleted: image.isDeleted,
              date: image.created_at,
            })),
          });
          return data;
        },
        // pega a(s) imagem(ns) da listImages de acordo com o tipo e/ou id informados
        getImageStore: (tipo, id) => {
          return get().listImages.filter((img) => {
            if (img.tipo == tipo) {
              if (!id || img.id_item == id) {
                return img;
              }
            }
          });
        },
        getImageStoreKey: (key, val) => {
          return get().listImages.filter((image) => {
            if (image[key] == val) {
              return image
            }
          })
        },
        // Adiciona uma nova imagem à listImages
        addImageStore: (image) => {
          set((state) => ({
            listImages: [...state.listImages, image],
          }));
        },
        // Gera uma nova listImages sem a imagem informada
        delImageStore: (key_image) => {
          set({
            listImages: get().listImages.filter((img) => {
              if (img.image != key_image) {
                return img;
              }
            }),
          });
        },
        // atualiza listImages de acordo com o key_image, key e val informados
        updateImageStore: (key_image, key, val) => {
          set({
            listImages: get().listImages.map((img) => {
              if (img.image === key_image) {
                img[key] = val;
              }
              return img;
            }),
          });
        },
      }),
      { name: 'listImages' }
    )
  )
);

export default useListImagesStore;
