import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const CustomRadioGroup = ({ labelText, value, onChange }) => {
  return (
    <FormControl>
      <FormLabel
        sx={{
          color: 'success.main', // Cor do label usando a cor success do tema
          '&.Mui-focused': {
            color: 'success.main', // Cor do label quando focado
          },
        }}
        id="custom-radio-label"
      >
        {labelText}
      </FormLabel>

      {/* RadioGroup personalizado com a cor success */}
      <RadioGroup
        row // Adiciona os elementos em linha
        aria-labelledby="custom-radio-label"
        name="custom-radio"
        value={value}
        onChange={onChange} // Função de mudança recebida como prop
      >
        <FormControlLabel
          value="Não"
          control={<Radio color="success" />} // Usando a cor success
          label="Não"
        />
        <FormControlLabel
          value="Sim"
          control={<Radio color="success" />} // Usando a cor success
          label="Sim"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;
