import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, CardMedia, DialogActions, DialogContent, DialogTitle, Skeleton, Slide, Typography } from '@mui/material';
import { Box, Button, Dialog, Grid, IconButton, Stack } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { Context } from '../../../components/Context/AuthContext';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import useStore from '../../../store/useStore';
import Loading from '../../../components/Loader/Loading';
import UploadFunction from '../../../components/Upload/UploadFunction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardProperty() {
  const usestore = useStore();
  const property_id = localStorage.getItem('Property') || 1;
  const resposProperty = usestore.reposProperty;

  const { aux_add, setAuxAdd } = useContext(Context);
  const propertyRepos = resposProperty.getPropertyStore('id', property_id);
  const [imageDecode, setImageDecode] = useState(propertyRepos[0].image);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingImage(false);
    }, 1500);
  }, []);

  // useEffect(() => {
  //   const propertyRepos = resposProperty.getPropertyStore('id', property_id);
  //   setImageDecode(propertyRepos[0].image);
  // }, [loadingImage]);

  // modal da imagem
  const [openM, setOpenM] = useState(false);
  const openModal = () => {
    setOpenM(true);
  };

  const closeModal = () => {
    setOpenM(false);
  };

  // Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // refatorando:
  const [item, setItem] = useState({
    id: 1,
    tipo_item: 'albumProperty',
    id_farm: 1,
  });

  const callBack = (imageKey) => {
    console.log('Callback received image key:', imageKey);
    //setImageDecode(imageKey); // Atualiza a imagem decodificada após o upload
  };

  const callBackHook = {
    addImageStore: async (image) => {
      console.log('Adding image to store:', image);

      // Salvar o link da imagem no banco de dados
      const header = {
        header: {
          'Content-Type': 'application/json',
        },
      };
      const fileData = { id: propertyRepos[0].id, image: image.image, item_type: item.tipo_item }; // Usar a propriedade preview para o link da imagem
      await api
        .put('/property/image', fileData, header)
        .then((response) => {
          // console.log('response');
          // console.log(response);
          if (response.data.isSuccess) {
            resposProperty.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
            });
            // console.log('ok');
            setTimeout(() => {
              setLoadingImage(false);
              toast.success('Imagem salva com sucesso');
              setImageDecode(response.data._value[0].image || '');
            }, 1000);
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
        });

      //setImageDecode(image.img); // Atualiza a imagem decodificada após o upload
    },
    delImageStore: (id) => {
      console.log('Deleting image from store with id:', id);
    },
  };

  return (
    <>
      <Card sx={{ height: 240 }}>
        {loadingImage ? (
          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
            <Loading />
          </Box>
        ) : (
          imageDecode ? 
            <CardMedia>{isLoadingImage ? 
              <Skeleton variant="rect" width="100%" height={140} /> : 
              <CardMedia onClick={openModal} component="img" height="140" image={imageDecode} alt="" />}
            </CardMedia>
          :
            <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <IconButton onClick={handleClickOpen}>
                  <PhotoCamera color="success" style={{ fontSize: 88 }} sx={{ mr: 1, width: '40px', height: '40px' }} />
                </IconButton>
              </Box>
              <span>Clique no ícone para adicionar a foto da sua fazenda</span>
            </Box>
        )}

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography fontWeight={700} sx={{ ml: 0 }}>
                {propertyRepos[0].name}
              </Typography>
              {propertyRepos[0].city && (
                <Stack spacing={0.5} sx={{ ml: -1, mt: 0.5 }}>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: 'grey.500' }} /> {propertyRepos[0].city}, {propertyRepos[0].state}
                  </Typography>
                </Stack>
              )}
            </Grid>

            <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {imageDecode && (
                <IconButton onClick={handleClickOpen}>
                  <PhotoCamera color="success" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog open={openM} onClose={closeModal}>
        <CardMedia component="img" image={imageDecode} alt="" />
      </Dialog>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Insira uma imagem da sua Fazenda'}</DialogTitle>
        <DialogContent>
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <UploadFunction item={item} callBack={callBack} callBackHook={callBackHook} />
            </Box>
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
