import { SvgIcon } from '@mui/material';
import React from 'react';

export default function CaixaEconomicaIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 923 766">
            <g>
                <path style={{ opacity: 0.999 }} fill="#006fae" d="M 115.5,-0.5 C 215.833,-0.5 316.167,-0.5 416.5,-0.5C 442.442,45.4299 468.775,91.0966 495.5,136.5C 495.351,137.552 495.517,138.552 496,139.5C 537.431,210.697 578.598,282.031 619.5,353.5C 520.338,355.166 421.004,356.166 321.5,356.5C 252.079,237.998 183.412,118.998 115.5,-0.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.997 }} fill="#f5822a" d="M 922.5,4.5 C 922.5,4.83333 922.5,5.16667 922.5,5.5C 829.364,122.146 736.364,238.979 643.5,356C 636.174,356.5 628.841,356.666 621.5,356.5C 621.068,355.29 620.401,354.29 619.5,353.5C 578.598,282.031 537.431,210.697 496,139.5C 495.517,138.552 495.351,137.552 495.5,136.5C 529.572,92.2541 564.238,48.4208 599.5,5C 707.166,4.5 814.833,4.33333 922.5,4.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.839 }} fill="#467588" d="M 619.5,353.5 C 620.401,354.29 621.068,355.29 621.5,356.5C 521.5,356.5 421.5,356.5 321.5,356.5C 421.004,356.166 520.338,355.166 619.5,353.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.998 }} fill="#f5822a" d="M 600.5,410.5 C 544.445,480.915 488.612,551.582 433,622.5C 429.918,626.734 427.084,631.067 424.5,635.5C 390.149,678.522 356.149,721.856 322.5,765.5C 214.833,765.5 107.167,765.5 -0.5,765.5C -0.5,765.167 -0.5,764.833 -0.5,764.5C 92.4936,646.83 185.16,528.83 277.5,410.5C 385.333,409.168 492.999,409.168 600.5,410.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.999 }} fill="#006fae" d="M 600.5,410.5 C 671.6,527.853 742.6,645.353 813.5,763C 709.165,763.833 604.832,763.667 500.5,762.5C 475.112,720.132 449.779,677.799 424.5,635.5C 427.084,631.067 429.918,626.734 433,622.5C 488.612,551.582 544.445,480.915 600.5,410.5 Z" />
            </g>
        </SvgIcon>
    );
}
