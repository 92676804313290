import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Adjust, Assignment, HelpOutline, Queue } from '@mui/icons-material';
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric';
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField';
import { green } from '@mui/material/colors';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useEffect } from 'react';

const default_produto = {
  name: '',
  id_unit: 1,
  unit_name: 'Unidade',
  id_packaging: 1,
  packaging_name: 'Unidade',
  unit_sigla: 'un',
  id_category: null,
  category_name: '',
  category: { id: null, name: '' },
  description: '',
  cod_barra: '',
  // qr_code: "",
  brand: '',
  observacoes: '',
  image: null,
  valor_produto: 0,
  stock_quanty: 0,
  stock_cost: 0,
  stock_quanty_min: 1,
  stock_quanty_max: 0,
  volume_pkg: 0,
  altura: 0,
  largura: 0,
  profundidade: 0,
  volume: 0,
  peso_liquido: 0,
  peso_bruto: 0,
  activeList: true,
  id_location: null,
  new_prod: 0,
};
const tipo_produto = [
  { id: 0, label: 'Produto cadastrado' },
  { id: 1, label: 'Novo Produto' },
];

export default function FormAddProductBuy({ data, obj_fn, handleClose }) {
  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.listProducts));
  const [selectedProduct, setSelectedProduct] = useState(default_produto);
  const [location_selected, setLocation] = useState(selectedProduct?.id_location || null);

  //verificar isso aqui gustavo
  useEffect(() => {
    data.product_selected = null;
  }, []);

  const options_category = obj_fn.usestore.reposProducts.listCategory.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const options_packaging = obj_fn.usestore.reposProducts.listPackaging;
  const handlePackagingChange = (event, value) => {
    setSelectedProduct((prevState) => ({
      ...prevState,
      id_packaging: value?.id || 1,
      packaging_name: value?.name || 'Unidade',
    }));
  };
  const options_unit = obj_fn.usestore.reposProducts.listUnits;
  const handleUnitChange = (event, value) => {
    setSelectedProduct((prevState) => ({
      ...prevState,
      id_unit: value?.id || 1,
      unit_name: value?.name || 'Unidade',
      unit_sigla: value?.sigla || 'un',
    }));
  };

  const handleChange = (event, blur) => {
    let field = event.target.name;
    let value = event.target.value;

    if (field === 'name') {
      setSelectedProduct((prevState) => ({ ...prevState, [field]: !blur ? value : value.trim() }));
    } else {
      setSelectedProduct((prevState) => ({ ...prevState, [field]: parseFloat(value) }));
    }
  };
  const handleChangeNew = (event, blur) => {
    const reposPatrimonyFilter = obj_fn.usestore.reposPatrimony.listPatrimony.filter((item) => {
      return item.group === 'Unidade Armazenadora' || item.group === 'Estruturas e Construções';
    });
    let aux_prod = default_produto;
    aux_prod.location = reposPatrimonyFilter;
    aux_prod.new_prod = parseFloat(event.target.value);

    setSelectedProduct(aux_prod);
    if (aux_prod.new_prod) {
      handleChangeLocal('', reposPatrimonyFilter[0]);
    }
  };

  const handleChangeUnit = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };
  const handleCategoryChange = (event, value) => {
    setSelectedProduct((prevState) => ({
      ...prevState,
      id_category: value?.id || null,
      category_name: value?.name || '',
      category: { id: value?.id || null, name: value?.name || '' },
    }));
  };

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.products));
    selectedProduct.id_location = location_selected.id;
    selectedProduct.packaging_unit = data.packaging_unit;
    selectedProduct.discount = 0;
    aux_add.push(selectedProduct);

    obj_fn.setData((prevState) => ({ ...prevState, product_selected: null, packaging_unit: 'medida' }));
    setSelectedProduct(default_produto);
    setLocation(null);

    var val_total = 0;
    var aux_val = 0;
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value);
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val;
      aux_add[i].value_end = aux_val;
    }
    var aux_value = data.value - data.products_value + val_total;
    var aux_value_end = data.value_end - data.products_value + val_total;

    obj_fn.setData((prevState) => ({ ...prevState, products_value: val_total, products: aux_add, value: aux_value, value_end: aux_value_end }));
    handleClose();
  };

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    let aux_prod = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    if (aux_prod) {
      let aux_location = aux_prod.location.length > 0 ? aux_prod.location[0] : null;
      aux_prod.id_patrimony = aux_location?.id_patrimony;
      aux_prod.id_location = aux_location;
      setLocation(aux_location);
    } else {
      setLocation(null);
    }
    setSelectedProduct(aux_prod);
    obj_fn.setData((prevState) => ({ ...prevState, product_selected: aux_prod }));
  };

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: 'Produto para: ' + data.operationType,
    values: optionsProducts,
    helperText: (
      <>
        Selecione o produto desejado
        <br />
        Valor unitário: {selectedProduct?.stock_cost || '-'}
      </>
    ),
    listHeight: 225,
    disableClearable: true,
  };

  var text_location = '';
  if (!selectedProduct?.new_prod) {
    text_location +=
      'Quantidade em estoque: ' +
      (!location_selected?.quanty ? '-' : location_selected?.quanty * (data.packaging_unit === 'unidade' ? 1 : selectedProduct?.volume_pkg || 1)) +
      (data.packaging_unit === 'unidade'
        ? selectedProduct?.packaging_sigla
          ? ' (' + selectedProduct?.packaging_sigla + ')'
          : ''
        : selectedProduct?.unit_sigla
        ? ' (' + selectedProduct?.unit_sigla + ')'
        : '');
  }
  const opt_products_local = {
    multiple: false,
    name: 'product_location_' + selectedProduct?.id,
    field: 'product_location',
    label: 'Local de estoque ' + (data.operationType === 'Venda' ? '(origem)' : '(destino)'),
    values: selectedProduct?.location || [],
    helperText: (
      <>
        Local onde o produto {data.operationType === 'Venda' ? 'está' : 'será'} armazenado!
        <br />
        {text_location}
      </>
    ),
    listHeight: 225,
    disabled: !data.product_selected?.id && !selectedProduct?.new_prod,
    disableClearable: true,
  };
  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal')
    setSelectedProduct((prevState) => ({ ...prevState, id_patrimony: newValue?.id, id_location: newValue || null }));
    let aux_local = newValue ? JSON.parse(JSON.stringify(newValue)) : null;
    setLocation(aux_local);
  };

  // const para validar os campos e o button add ao carrinho
  const validFields = (type) => {
    let text = '';
    var mensagem = <></>;
    let error = '';
    let is_error = false;

    if (type === 'name' || type === 'isError') {
      if (selectedProduct?.new_prod) {
        const loteExists = obj_fn.usestore.reposProducts.getProductsStore('name', selectedProduct?.name);
        text = 'Informe o nome do produto';

        if (loteExists.length > 0) {
          error = 'Já existente um produto com este nome';
          is_error = true;
        } else if (selectedProduct?.name?.length < 5) {
          error = 'Nome do produto deve ter no mínimo 5 caracteres';
          is_error = true;
        }
      }
    }
    if (type === 'quantidade' || type === 'isError') {
      let qtd = selectedProduct?.quant;
      let qtd_estoq = location_selected?.quanty * (selectedProduct?.new_prod ? selectedProduct?.volume_pkg : data.packaging_unit === 'unidade' ? 1 : selectedProduct?.volume_pkg || 1);
      text = 'Quantidade de produtos para: ' + data?.operationType;

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0';
        is_error = true;
      } else if (data.operationType === 'Venda' && qtd > qtd_estoq) {
        error = 'Não pode ser maior do que a Qtd em estoque';
        is_error = true;
      }
    }
    if (type === 'valor' || type === 'isError') {
      let valor = selectedProduct?.value;
      text = 'Valor total dos produtos';

      if (!valor) {
        error = 'Valor deve ser maior que 0';
        is_error = true;
      }
    }

    if (type === 'isError') {
      if (!selectedProduct?.id_category) {
        is_error = true;
      }
      if (selectedProduct?.new_prod) {
        return is_error;
      } else {
        return selectedProduct?.id ? is_error : true;
      }
    }

    if (error && selectedProduct?.id) {
      mensagem = (
        <>
          <br />
          <span style={{ color: 'red' }}>{error}</span>
        </>
      );
    }

    return (
      <>
        {text}
        {mensagem}
      </>
    );
  };

  var sm_size = selectedProduct?.new_prod ? 4 : 4;

  console.log(selectedProduct);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
          <Box sx={{ minHeight: 35 }}>
            <Typography style={{ color: '#2e7d32' }}>
              <div className="d-lg-none">
                <Typewriter tam={30} minHeight={0} text="Você deseja incluir um produto já cadastro ou um produto novo." />
              </div>
              <div className="d-none d-lg-block">
                <Typewriter tam={30} minHeight={0} text="Você deseja incluir um produto já cadastro ou um produto novo." />
              </div>
            </Typography>
          </Box>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, ml: 1, mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
              <RadioGroup
                label="seleção tipo do produto"
                aria-label="seleção tipo do produto"
                name="produto_radio_group"
                value={selectedProduct?.new_prod}
                onChange={handleChangeNew}
                style={{ display: 'flex', flexDirection: 'row' }}
                defaultValue={0}
              >
                {tipo_produto.map((prog) => (
                  <FormControlLabel key={prog.id} value={prog.id} name="new_prod" control={<Radio color="success" />} label={prog.label} />
                ))}
              </RadioGroup>
            </Grid>

            {/* Novo produto cadastrado */}
            {selectedProduct?.new_prod ? (
              <>
                <Grid item xs={12} sm={12} sx={{ mb: -1, mt: -3 }}>
                  <Box sx={{ minHeight: 35 }}>
                    <Typography style={{ color: '#2e7d32' }}>
                      <div className="d-lg-none">
                        <Typewriter tam={30} minHeight={0} text="Vamos cadastrar um novo produto no sistema." />
                      </div>
                      <div className="d-none d-lg-block">
                        <Typewriter tam={30} minHeight={0} text="Vamos cadastrar um novo produto no sistema." />
                      </div>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={4} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Nome do Produto"
                        name="name"
                        id="name"
                        focused
                        color="success"
                        variant="outlined"
                        fullWidth
                        value={selectedProduct?.name}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e, true)}
                        FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                        helperText={validFields('name')}
                      />
                    </Grid>
                    {/* embalagem */}
                    {selectedProduct?.name && (
                      <>
                        <Grid item xs={12} sm={12} sx={{ mb: -1, mt: -3 }}>
                          <Box sx={{ minHeight: 50 }}>
                            <Typography style={{ color: '#2e7d32' }}>
                              <div className="d-lg-none">
                                <Typewriter tam={30} minHeight={0} text="Para definir a quantidade, precisamos definir se o registro será em unidade ou embalagem." />
                              </div>
                              <div className="d-none d-lg-block">
                                <Typewriter tam={30} minHeight={0} text="Para definir a quantidade, precisamos definir se o registro será em unidade ou embalagem." />
                              </div>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Autocomplete
                            onChange={(event, newValue) => handlePackagingChange(event, newValue)}
                            id="id_packaging"
                            options={options_packaging}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={options_packaging.find((option) => option.name === selectedProduct.packaging_name) || null}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Unidade/Embalagem"
                                variant="outlined"
                                color="success"
                                InputLabelProps={{ style: { color: green[800] } }}
                                FormHelperTextProps={{ style: { color: green[800] } }}
                                helperText={
                                  <>
                                    {`O registro do produto será em unidade ou embalagem?`}
                                    <Tooltip
                                      title={
                                        `Unidade: Selecione 'unidade' se você estiver registrando a quantidade de itens individuais, como uma única peça ou item." \n` +
                                        `Embalagens: Selecione 'embalagem' se você estiver registrando a quantidade de itens que estão agrupados ou vendidos juntos em uma única embalagem.".\n`
                                      }
                                    >
                                      <HelpOutline sx={{ color: '#808080' }} />
                                    </Tooltip>
                                  </>
                                }
                                fullWidth
                              />
                            )}
                            ListboxProps={{ style: { maxHeight: 225 } }}
                            noOptionsText="Sem resultados"
                            disableClearable={true}
                          />
                        </Grid>
                        {selectedProduct.packaging_name !== 'Unidade' && (
                          <Grid item xs={6} sm={4}>
                            <GenericValueField
                              handleChange={handleChange}
                              options={{
                                // required: true,
                                id: 'volume_pkg',
                                name: 'volume_pkg',
                                label: 'Volume da embalagem',
                                value: selectedProduct.volume_pkg,
                                helperText: 'Volume do produto presente na embalagem',
                                variant: 'outlined',
                                endAdornment: true,
                                prefix: <Assignment />,
                                disabled: selectedProduct.packaging_name === 'Unidade',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={6} md={4}>
                          <Autocomplete
                            onChange={(event, newValue) => handleUnitChange(event, newValue)}
                            id="id_unit"
                            options={options_unit}
                            getOptionLabel={(option) => `${option.name} (${option.sigla})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={options_unit.find((option) => option.name === selectedProduct.unit_name) || null}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Medida"
                                variant="outlined"
                                color="success"
                                InputLabelProps={{ style: { color: green[800] } }}
                                FormHelperTextProps={{ style: { color: green[800] } }}
                                helperText={`Selecione a unidade de medida padrão para o seu produto`}
                                fullWidth
                              />
                            )}
                            ListboxProps={{ style: { maxHeight: 225 } }}
                            noOptionsText="Sem resultados"
                            disableClearable={true}
                            disabled={!data.product_selected && !selectedProduct?.new_prod}
                          />
                        </Grid>
                        {/* quantidade e valor */}
                        <Grid item xs={6} sm={6}>
                          <GenericValueField
                            handleChange={handleChange}
                            options={{
                              required: true,
                              id: 'quant',
                              name: 'quant',
                              label: 'Quantidade',
                              value: selectedProduct?.quant,
                              variant: 'outlined',
                              helperText: validFields('quantidade'),
                              disabled: !data.product_selected && !selectedProduct?.new_prod,
                              endAdornment: true,
                              prefix:
                                data.packaging_unit === 'unidade'
                                  ? selectedProduct?.packaging_sigla
                                    ? ' (' + selectedProduct?.packaging_sigla + ') '
                                    : ''
                                  : selectedProduct?.unit_sigla
                                  ? ' (' + selectedProduct?.unit_sigla + ') '
                                  : '',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <GenericValueField
                            handleChange={handleChange}
                            options={{
                              required: true,
                              id: 'value',
                              name: 'value',
                              label: 'Valor total',
                              value: selectedProduct?.value,
                              variant: 'outlined',
                              helperText: validFields('valor'),
                              disabled: !data.product_selected && !selectedProduct?.new_prod,
                              endAdornment: true,
                              prefix: ' (R$) ',
                            }}
                          />
                        </Grid>
                        {/* Armazenamento */}

                        {/* CATEGORIA */}
                        {selectedProduct?.value && (
                          <>
                            <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                              <Box sx={{ minHeight: 35 }}>
                                <Typography style={{ color: '#2e7d32' }}>
                                  <div className="d-lg-none">
                                    <Typewriter tam={30} minHeight={0} text="Vamos organizar definindo um local de armazenamento e uma categoria para o produto." />
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <Typewriter tam={30} minHeight={0} text="Vamos organizar definindo um local de armazenamento e uma categoria para o produto." />
                                  </div>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sm={sm_size} sx={{ mt: obj_fn.ismobile ? -1 : 0 }}>
                              <AutocompleteGeneric value={location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
                            </Grid>

                            {/* CATEGORIA */}
                            <Grid item xs={12} md={6}>
                              <Autocomplete
                                name="category_name"
                                onChange={(event, newValue) => {
                                  handleCategoryChange(event, newValue);
                                }}
                                //onChange={handleCategoryChange}
                                disableCloseOnSelect={false}
                                filterSelectedOptions
                                color="success"
                                id={'id_category'}
                                value={selectedProduct.category}
                                multiple={false}
                                options={options_category}
                                ListboxProps={{ style: { maxHeight: 225 } }}
                                isOptionEqualToValue={(option, val) => option.id === val.id || selectedProduct.id_category === option.id}
                                // value={data.id_category}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                      <Adjust color="success" sx={{ mr: 1, width: '15px' }} />
                                      {option.name}
                                      <Typography variant="body2" sx={{ marginLeft: 'auto', color: '#d3d3d3' }}>
                                        ({option.group_category})
                                      </Typography>
                                    </Stack>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    // required
                                    variant={'outlined'}
                                    label={'Categoria'}
                                    color="success"
                                    InputLabelProps={{ style: { color: green[800] } }}
                                    FormHelperTextProps={{ style: { color: green[800] } }} // Definindo a cor do helperText como verde
                                    helperText={'Selecione uma categoria (tipo) para o produto.'}
                                    focused
                                    fullWidth
                                  />
                                )}
                                noOptionsText="Sem resultados"
                                disableClearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                      <strong>
                        {selectedProduct.stock_quanty} {selectedProduct.packaging_name}s
                      </strong>
                    </Typography>
                    <Card>
                      <CardContent>
                        <Grid container spacing={4} sx={{}}>
                          <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                              {selectedProduct?.category_name ? selectedProduct?.category_name.toUpperCase() : 'PRODUTO'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                              <strong>{selectedProduct?.name ? selectedProduct?.name.toUpperCase() : 'Nome do produto'}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                            <Typography variant="body2" style={{ textAlign: 'center' }}>
                              {selectedProduct?.packaging_name === 'Unidade'
                                ? `${selectedProduct?.unit_name} (${selectedProduct?.unit_sigla})`
                                : `${selectedProduct?.packaging_name} de ${selectedProduct?.volume_pkg} ${selectedProduct?.unit_sigla}`}
                            </Typography>
                            <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                              <strong>{selectedProduct?.brand ? selectedProduct?.brand : 'marca'}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} sx={{ mb: -1, mt: -3 }}>
                  <Box sx={{ minHeight: 35 }}>
                    <Typography style={{ color: '#2e7d32' }}>
                      <div className="d-lg-none">
                        Vamos selecionar um produto já existe no estoque
                        {/* <Typewriter tam={30} minHeight={0} text="Vamos selecionar um produto já existe no estoque." /> */}
                      </div>
                      <div className="d-none d-lg-block">
                        Vamos selecionar um produto já existe no estoque
                        {/* <Typewriter tam={30} minHeight={0} text="Vamos selecionar um produto já existe no estoque." /> */}
                      </div>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={4} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12}>
                      <AutocompleteGeneric value={data.product_selected} options={opt_products} handleChange={handleChangeSelect} />
                    </Grid>

                    {selectedProduct?.name && (
                      <>
                        <Grid item xs={12} sm={12} sx={{ mb: -1, mt: -3 }}>
                          <Box sx={{ minHeight: 50 }}>
                            <Typography style={{ color: '#2e7d32' }}>
                              <div className="d-lg-none">
                                <Typewriter tam={30} minHeight={0} text="Para definir a quantidade, precisamos vizualizar a unidade de medida já registrada do produto." />
                              </div>
                              <div className="d-none d-lg-block">
                                <Typewriter tam={30} minHeight={0} text="Para definir a quantidade, precisamos vizualizar a unidade de medida já registrada do produto." />
                              </div>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="packaging_unit" color="success">
                              Embalagem ou Medida
                            </InputLabel>
                            <Select
                              labelId="packaging_unit"
                              id="selectPackagingUnit"
                              label="Embalagem ou Medida"
                              name="packaging_unit"
                              value={data.packaging_unit}
                              onChange={(e) => handleChangeUnit(e)}
                              disabled={!selectedProduct?.volume}
                              color="success"
                              MenuProps={{
                                sx: {
                                  '&& .Mui-selected': {
                                    backgroundColor: green[50],
                                  },
                                  '&& .MuiMenuItem-root': {
                                    '&:hover': {
                                      backgroundColor: green[100],
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem key={'medida'} value={'medida'}>
                                Medida ({selectedProduct?.unit_name})
                              </MenuItem>
                              <MenuItem key={'unidade'} value={'unidade'}>
                                Unidade/Embalagem ({selectedProduct?.packaging_name})
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              Unidade/Embalagem escolhida no cadastro do produto
                              {/* <br />O produto será utlilizado por Unidade/Embalagem ou por sua medida (conteúdo)? */}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {selectedProduct.packaging_name !== 'Unidade' && (
                          <Grid item xs={6} sm={4}>
                            <GenericValueField
                              handleChange={handleChange}
                              options={{
                                // required: true,
                                id: 'volume_pkg',
                                name: 'volume_pkg',
                                label: 'Volume da embalagem',
                                value: selectedProduct.volume_pkg,
                                helperText: 'Volume do produto presente na embalagem',
                                variant: 'outlined',
                                endAdornment: true,
                                prefix: <Assignment />,
                                disabled: selectedProduct.packaging_name === 'Unidade',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={6} md={4}>
                          <Autocomplete
                            onChange={(event, newValue) => handleUnitChange(event, newValue)}
                            id="id_unit"
                            options={options_unit}
                            getOptionLabel={(option) => `${option.name} (${option.sigla})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={options_unit.find((option) => option.name === selectedProduct.unit_name) || null}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Medida"
                                variant="outlined"
                                color="success"
                                InputLabelProps={{ style: { color: green[800] } }}
                                FormHelperTextProps={{ style: { color: green[800] } }}
                                helperText={`Selecione a unidade de medida padrão para o seu produto`}
                                fullWidth
                              />
                            )}
                            ListboxProps={{ style: { maxHeight: 225 } }}
                            noOptionsText="Sem resultados"
                            disableClearable={true}
                            disabled={!data.product_selected && !selectedProduct?.new_prod}
                          />
                        </Grid>
                        {/* quantidade e valor */}
                        <Grid item xs={6} sm={6}>
                          <GenericValueField
                            handleChange={handleChange}
                            options={{
                              required: true,
                              id: 'quant',
                              name: 'quant',
                              label: 'Quantidade',
                              value: selectedProduct?.quant,
                              variant: 'outlined',
                              helperText: validFields('quantidade'),
                              disabled: !data.product_selected && !selectedProduct?.new_prod,
                              endAdornment: true,
                              prefix:
                                data.packaging_unit === 'unidade'
                                  ? selectedProduct?.packaging_sigla
                                    ? ' (' + selectedProduct?.packaging_sigla + ') '
                                    : ''
                                  : selectedProduct?.unit_sigla
                                  ? ' (' + selectedProduct?.unit_sigla + ') '
                                  : '',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <GenericValueField
                            handleChange={handleChange}
                            options={{
                              required: true,
                              id: 'value',
                              name: 'value',
                              label: 'Valor total',
                              value: selectedProduct?.value,
                              variant: 'outlined',
                              helperText: validFields('valor'),
                              disabled: !data.product_selected && !selectedProduct?.new_prod,
                              endAdornment: true,
                              prefix: ' (R$) ',
                            }}
                          />
                        </Grid>
                        {/* Armazenamento */}

                        {/* CATEGORIA */}
                        {selectedProduct?.value && (
                          <>
                            <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                              <Box sx={{ minHeight: 35 }}>
                                <Typography style={{ color: '#2e7d32' }}>
                                  <div className="d-lg-none">
                                    <Typewriter tam={30} minHeight={0} text="Vamos organizar definindo um local de armazenamento" />
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <Typewriter tam={30} minHeight={0} text="Vamos organizar definindo um local de armazenamento " />
                                  </div>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sm={sm_size} sx={{ mt: obj_fn.ismobile ? -1 : 0 }}>
                              <AutocompleteGeneric value={location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
                            </Grid>

                            {/* CATEGORIA */}
                            {/* <Grid item xs={12} md={6}>
                              <Autocomplete
                                name="category_name"
                                onChange={(event, newValue) => {
                                  handleCategoryChange(event, newValue);
                                }}
                                //onChange={handleCategoryChange}
                                disableCloseOnSelect={false}
                                filterSelectedOptions
                                color="success"
                                id={'id_category'}
                                value={selectedProduct.category}
                                multiple={false}
                                options={options_category}
                                ListboxProps={{ style: { maxHeight: 225 } }}
                                isOptionEqualToValue={(option, val) => option.id === val.id || selectedProduct.id_category === option.id}
                                // value={data.id_category}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                      <Adjust color="success" sx={{ mr: 1, width: '15px' }} />
                                      {option.name}
                                      <Typography variant="body2" sx={{ marginLeft: 'auto', color: '#d3d3d3' }}>
                                        ({option.group_category})
                                      </Typography>
                                    </Stack>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    // required
                                    variant={'outlined'}
                                    label={'Categoria'}
                                    color="success"
                                    InputLabelProps={{ style: { color: green[800] } }}
                                    FormHelperTextProps={{ style: { color: green[800] } }} // Definindo a cor do helperText como verde
                                    helperText={'Selecione uma categoria (tipo) para o produto.'}
                                    focused
                                    fullWidth
                                  />
                                )}
                                noOptionsText="Sem resultados"
                                disableClearable={true}
                              />
                            </Grid> */}
                            <Grid item xs={12} md={6}></Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                {/*  */}
                <Grid item xs={12} sm={3}>
                  <div>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                      <strong>
                        {selectedProduct.stock_quanty} {selectedProduct.packaging_name}s
                      </strong>
                    </Typography>
                    <Card>
                      <CardContent>
                        <Grid container spacing={4} sx={{}}>
                          <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                              {selectedProduct?.category_name ? selectedProduct?.category_name.toUpperCase() : 'PRODUTO'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                              <strong>{selectedProduct?.name ? selectedProduct?.name.toUpperCase() : 'Nome do produto'}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                            <Typography variant="body2" style={{ textAlign: 'center' }}>
                              {selectedProduct?.packaging_name === 'Unidade'
                                ? `${selectedProduct?.unit_name} (${selectedProduct?.unit_sigla})`
                                : `${selectedProduct?.packaging_name} de ${selectedProduct?.volume_pkg} ${selectedProduct?.unit_sigla}`}
                            </Typography>
                            <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                              <strong>{selectedProduct?.brand ? selectedProduct?.brand : 'marca'}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <Button variant="contained" color="success" onClick={handleAdd} disabled={validFields('isError')} sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite` }}>
                <Queue sx={{ mr: 1 }} /> Adicionar ao carrinho
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
