import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { Span } from '../../../../components/Typography';
import MenuPlan from '../../../signature/components/MenuPlan';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import AddActivities from '../../../activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import ModalPreparationAgriculture from './ModalPreparationAgriculture';




export default function PlanningHarvestCostEmpty({ setIsLoandigSavePlan }) {

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingText, setIsLoadingText] = useState(false);
    // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    // console.log('userCurrent')
    // console.log(userCurrent)
    // const user = userCurrent;
    // console.log(user)
    // const name = user.name;
    // const charName = name[0].toUpperCase();
    // console.log(charName);
    // function stringAvatar(name) {
    //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    // }
    setTimeout(() => {
        setIsLoading(false)
    }, 1000);

    setTimeout(() => {
        setIsLoadingText(true)
    }, 3000);


    return (
        <>
            {isLoading === true ? (
                <>
                    <Skeleton variant="rectangular" width="100%" height={600} />
                </>) : (
                <>
                    <Card sx={{ m: 2 }}>
                        <CardHeader
                            title={
                                <>
                                    <div className="d-lg-none">
                                        <Box sx={{ minHeight: 140 }}>
                                            <Typography style={{ color: '#2e7d32' }}>
                                                {isLoadingText &&
                                                    <Typewriter tam={130} minHeight={0} text="Realize uma análise detalhada dos custos específicos de produção." />
                                                }
                                            </Typography>
                                        </Box>
                                    </div>

                                    <div className="d-none d-lg-block">

                                        <Box sx={{ minHeight: 35 }}>
                                            <Typography style={{ color: '#2e7d32' }}>
                                                {isLoadingText &&
                                                    <Typewriter tam={30} minHeight={0} text="Realize uma análise detalhada dos custos específicos de produção." />
                                                }
                                            </Typography>
                                        </Box>
                                    </div>
                                </>
                            }
                        >
                        </CardHeader>

                        <Divider />

                    </Card >
                    <Card sx={{ m: 0 }}>
                        <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                            <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <Grid container xs={12} md={12} sx={{}} item alignItems="center" justifyContent="center">
                                        <Box sx={{ width: '100%', maxWidth: '200px', ml: 3 }}>
                                            <img src="/dist/image/cost_preparation_agriculture.png" alt="" style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Grid>
                                    {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                                    <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                                        <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                                            Gestão incial
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Os principais custos antes do pré-plantio na agricultura incluem preparação do solo, correção do solo, controle de pragas e doenças, preparação de mudas ou sementes, infraestrutura e trabalho manual, podendo haver outros custos específicos de cada situação agrícola.
                                        </Typography>
                                        <ModalPreparationAgriculture setIsLoandigSavePlan={setIsLoandigSavePlan} />
                                    </Grid>
                                </Box>
                            </Paper>
                        </Container>
                    </Card >
                </>
            )}
        </>
    );
}

//https://aegro.com.br/termos-de-uso/