import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Checkbox, TextField } from "@mui/material";
import { green } from "@mui/material/colors";
import LoadActivityListLivestock from "../LoadActivityList/LoadActivityListLivestock";

export default function AutocompletActivities({ item, handleChange, multiple }) {
  const loadActivityListLivestock = LoadActivityListLivestock();
  const filters = {
    'table_active': "realized",
    'status': "edit",
  };
  // filtrar caso seja harvest
  let rows = [];
  if (item.tipo_repos == "Criações") {
    rows = loadActivityListLivestock.loadList(item, filters);
  }

  const coloumns_aux = {};
  const optionsColoumns = [];

  rows.map(activityItem => {
    if (!coloumns_aux[activityItem.activity]) {
      coloumns_aux[activityItem.activity] = activityItem.activity;
      optionsColoumns.push({
        id: (optionsColoumns.length +1),
        title: activityItem.activity,
        icon: activityItem.icon,
      })
    }
  });

  return (
    <Autocomplete
      multiple={multiple}
      id="autocomplete_users"
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      //value={user || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={optionsColoumns}
      disableCloseOnSelect
      filterSelectedOptions
      color="success"
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Avatar sx={{ bgcolor: green[500], mr: 2 }}>{option.icon}</Avatar>
          {option.title}
        </li>
      )}
      renderInput={(params) => <TextField {...params} variant="standard" color="success" label="Tipos de atividade" />}
      noOptionsText="Sem resultados"
    />
  );
}