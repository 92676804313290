import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { Close, DeleteForever } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { green, red, grey } from "@mui/material/colors";
import api from "../../../../../services/api";
import { Context } from "../../../../../components/Context/AuthContext";
import Typewriter from "../../../../../components/Typewriter/Typewriter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteBank({ row_data, usestore }) {
  const [open, setOpen] = useState(false);
  const property_id = (localStorage.getItem("Property") || 1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseCancel = () => {
    setOpen(false);
  };

  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context);

  const handleTrash = async (e) => {
    e.preventDefault();
    const data = {
      id: row_data.id,
      property_id: property_id,
      account_id: row_data.id_account,
      isDeleted: row_data.isDeleted,
      status: row_data.isDeleted == 0 ? "trash" : "delete",
    }

    const header = { header: { "Content-Type": "application/json" } };
    const res = await api.put(`/bank/delete/`, data, header).then((response) => {
      if (response.data.isSuccess) {
        // atualiza o repos
        usestore.reposBank.fetch().then((res) => {
          setAuxAdd(aux_add + 1);
          if (row_data.isDeleted > 0) {
            toast.success(`Conta bancária excluída com sucesso!`);
          } else {
            toast.success(`Conta bancária movida para a lixeira com sucesso!`);
          }
          setTimeout(() => {
            setOpen(false);
          }, 500);
        });
      }
    }).catch((error) => {
      console.log("error");
      console.log(error);
      if (row_data.isDeleted > 0) {
        toast.error(`Falha ao excluir a conta bancária, tente novamente mais tarde!`);
      } else {
        toast.error(`Falha ao mover a conta bancária para a lixeira, tente novamente mais tarde!`);
      }
    });
  };
  var color_trash = (row_data.isDeleted > 0 ? red[600] : grey[600]);

  return ( <>
    <div>
      <BootstrapDialog onClose={handleCloseCancel} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseCancel}>
          <DeleteForever /> Lixeira
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid sx={{ width: 270, p: 1, m: 1 }}>
            <Box sx={{}}>
              {row_data.isDeleted > 0 ? <>
                <Alert variant="filled" severity="error">
                  A conta bancária será excluída do software
                </Alert>
                <Typography sx={{ mt: 2 }} gutterBottom style={{ color: red[600] }}>
                  <Typewriter tam={80} minHeight={0} text="Esse procedimento é irreversível!" />
                </Typography> </> : <>
                <Typography style={{ color: red[600] }}>
                  <Typewriter tam={80} minHeight={0} text="A conta bancária será encaminhada para lixeira." />
                </Typography>
              </>}
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCancel} style={{ color: "#8a8a8a" }}>
            Cancelar
          </Button>
          <Button autoFocus onClick={handleTrash} variant="outlined" color="error" sx={{ width: 200, height: 50 }}>
            {row_data.isDeleted > 0 ? "Excluir" : "Enviar para lixeira"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <IconButton aria-label="delete" onClick={row_data.noDelet > 0 ? () => null : () => handleClickOpen()} title="Excluir" disabled={row_data.noDelet > 0}>
        <DeleteForever sx={{ color: color_trash }} />{" "}
      </IconButton>
    </div>
  </>);
}
