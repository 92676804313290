import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Pets, Category, AddBox, LocalDrink, PregnantWoman, LocalMall, ProductionQuantityLimits, DoneAll } from "@mui/icons-material";
import useActivityInputReposStore from "../../../../../store/Activities/useActivityInputReposStore";
import { useParams } from "react-router-dom";

const transfer = [
    "Mover Lotes entre Áreas",
    "Mover Animais entre Lotes"
];

export default function FormTransferStep1SelectRadioButton({ data, setData }) {
    const [selectedFase, setSelectedFase] = useState("");

    const reposActivitiesInputs = useActivityInputReposStore((state) => state);

    const { idFarm, id } = useParams();

    useEffect(() => {
        data.areaTranferId = { id_area: data?.id_area || "", name: data?.name || "", image: data?.image || "" }
    }, [])



    const handleFaseChange = (event) => {

        setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));

    };

    const faseStyle = (fase) => ({
        backgroundColor: data.stage === fase ? "#5cb85c" : "#fff",
        color: data.stage === fase ? "#fff" : "#333",
        marginRight: "5px",
        borderRadius: "5px",
        padding: "2px",
        //padding: "10px",
        cursor: "pointer",
    });

    return (
        <RadioGroup
            aria-label="fases"
            name="fases"
            value={data.stage}
            onChange={handleFaseChange}
            style={{ display: "flex", flexDirection: "row" }}
        >
            {transfer.map((fase) => (
                <FormControlLabel
                    key={fase}
                    value={fase}
                    name="stage"
                    control={<Radio color="success" />}
                    label={
                        <div style={faseStyle(fase)}>
                            {/* {fase === "cria" && <Pets fontSize="large" />}
                            {fase === "recria" && <Category fontSize="large" />}
                            {fase === "recria/engorda" && <AddBox fontSize="large" />}
                            {fase === "engorda/terminação" && <LocalDrink fontSize="large" />}
                            {fase === "produção/reprodução" && <PregnantWoman fontSize="large" />}
                            {fase === "reprodução" && <LocalMall fontSize="large" />}
                            {fase === "produção" && <ProductionQuantityLimits fontSize="large" />}
                            {fase === "completo" && <DoneAll fontSize="large" />} */}
                            {` ${fase.charAt(0).toUpperCase() + fase.slice(1)}`}
                        </div>
                    }
                />
            ))}
        </RadioGroup>
    );
}
