import React, { useState } from "react";
import { Box, Card, CardMedia, Grid, ListItemIcon, Typography, ListItem, ListItemText, Divider } from "@mui/material";
import FormBatchHeader from "./FormBatchHeader";
import FormBatchFooter from "./FormBatchFooter";

export default function FormBatchStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // valida o button continuar
  const isError = () => {
    return false;
  };
  // valida o botao retornar
  const returnError = () => {
    return false;
  };

  const text_title = "Confirmação dos dados";
  const txt_msn = "Pronto! Agora basta conferir os dados e clicar em confirmar!";

  return ( <>
    <FormBatchHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} imagem={"/dist/image/entrada.png"} />

    <Grid container spacing={2} sx={{}}>
      <Grid item xs={6} md={6} sx={{mb: 1}}>
        <ListItem>
          <ListItemText primary="Nome do Lote" secondary={data.nameBatch} />
        </ListItem>
      </Grid>
      <Grid item xs={6} md={6} sx={{mb: 1}}>
        <ListItem>
          <ListItemText primary="Fase de manejo" secondary={data.stage} />
        </ListItem>
      </Grid>
    </Grid>
    <Divider />
    <Grid container spacing={3} >
      <Grid item xs={12} md={12} sx={{mb: 1}}>
        <Typography fontWeight="500" variant="h6">
          Localização do lote
        </Typography>
      </Grid>
      {[data.area].map((item) => (<>
        <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }} >
          <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }} >
            <ListItemIcon key={'li_' + item.id}>
              <div key={'dv1_' + item.id} style={{ position: "relative" }}>
                <CardMedia
                  key={'cm_' + item.id}
                  component="img"
                  height="150"
                  image={item.imageDecode}
                  alt="Imagem da área"
                />
                <Box
                  key={'b_' + item.id}
                  sx={{
                    position: "absolute",
                    top: "95px",
                    left: 0,
                    width: "100%",
                    bgcolor: "rgba(0, 0, 0, 0.45)",
                    color: "white",
                    padding: "27px",
                  }}
                >
                  <div key={'dv2_' + item.id} style={{ position: "absolute", color: "white", bottom: 3, left: "5%" }}>
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography variant="body2" >
                      {item.area || item.area_used} {item.measure || 'ha'}
                    </Typography>
                  </div>
                </Box>
              </div>
            </ListItemIcon>
          </Card>
        </Grid>
      </>))}
    </Grid>

    <FormBatchFooter
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </> );
}
