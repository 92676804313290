import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { ExpandMore } from '@mui/icons-material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

export default function StartActivityLivestock() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <LocalLibraryIcon />
      </IconButton>
      <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Gerenciamento Pecuário
              </Typography>
              <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="Inicia-se agora o gerenciamento das atividades e dos custos de produção" />
              </Typography>
            </Grid>
            {/* <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 0 }}>
                <Typography paragraph variant="subtitle1" fontWeight={300}>
                  Planeje, organize, dirija e controle seu projeto para o sucesso!!!
                </Typography>
              </Box>
            </Grid> */}
            <Grid item sm={12} xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <strong>Fase de Aquisição</strong>
                </AccordionSummary>
                <AccordionDetails>A fase de aquisição envolve a compra de animais , a entrada dos animais na propriedade e a compra de insumos que serão utilizados na próxima fase.</AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/atividades_aquisicao.png" alt="Gestão" />
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <strong>Fase de Manejos</strong>
                </AccordionSummary>
                <AccordionDetails>
                  O objetivo da Fase de Manejo na pecuária é otimizar a saúde, o bem-estar e o desempenho dos animais para maximizar a produtividade e a eficiência da produção. Essa fase envolve a
                  implementação de práticas e procedimentos que garantam que os animais sejam bem alimentados, saudáveis, e que cresçam de maneira consistente e sustentável.
                </AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/atividades_manejo.png" alt="Gestão Agrícola" />
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
