import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import Breadcrumb from "../../../../components/Breadcrumb";
import SimpleCard from "../../../../components/SimpleCard";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";
import { AccountCircle } from "@mui/icons-material";
import TitleIcon from "@mui/icons-material/Title";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import Upload from "../../../../components/Upload/Upload";
import MapIcon from "@mui/icons-material/Map";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import BasicDatePicker from "./BasicDatePicker";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ImageIcon from "@mui/icons-material/Image";
import RoomIcon from "@mui/icons-material/Room";
import { MapFormObservation } from "./MapFormObservation";
import { MapFormObservationEdit } from "./MapFormObservationEdit";
import useStore from "../../../../store/useStore";
const location = [{ label: "Fazenda CPT", id: 1 }];

export default function AppFormEdit({ message, setMessage }) {
  //https://blog.logrocket.com/using-material-ui-with-react-hook-form/
  const usestore = useStore();
  const reposProperty = usestore.reposProperty;

  console.log("AppFormEdit");
  console.log(message);

  const handleChangeInput = (event) => {
    event.persist();
    setMessage({ ...message, [event.target.name]: event.target.value });
  };

  //3.2 - inicializar o value
  const [value, setValue] = useState(reposProperty.propertyRepos);
  const [inputValue, setInputValue] = useState("");
  const [customValue, setCustomValue] = useState("");

  return (
    <form className="container-fluid " style={{ maxWidth: 500 }}>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 1 }}>
        <TitleIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
        <TextField
          onChange={handleChangeInput}
          value={message.title}
          fullWidth
          id="title"
          name="title"
          size="small"
          InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
          label="Título da Solicitação*"
          variant="standard"
        />
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: "auto",
        }}
      >
        <TextField
          onChange={handleChangeInput}
          value={message.description}
          sx={{ width: 300 }}
          id="description"
          name="description"
          label="Descrição"
          multiline
          // InputProps={{ style: { fontSize: 40 } }}
          InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
          rows={3}
          variant="outlined"
        />
      </Box>

      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          m: 2,
        }}
      ></Box>

      <div>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <CalendarMonthIcon sx={{ color: "action.active", mr: 0.5 }} />
            <Typography>Data </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <BasicDatePicker dateMsn={message.date} />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <ImageIcon sx={{ color: "action.active", mr: 0.5 }} />
            <Typography>Anexar imagem </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Upload />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <RoomIcon sx={{ color: "action.active", mr: 0.5 }} /> <Typography>Localização</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Autocomplete disablePortal id="combo-box-demo" options={location} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Adicionar Localização" />} noOptionsText="Sem resultados"/>

              </Box> */}
              <Box
                sx={{
                  mt: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MapFormObservationEdit message={message} />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
      </div>


    </form>
  );
}
