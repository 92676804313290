// Função para gerar o cabeçalho
// function header(currentPage, pageCount) {
//   return {
//     text: currentPage.toString() + " de " + pageCount,
//     alignment: "right",
//     margin: [0, 20, 20, 0], // [left, top, right, bottom]
//     fontSize: 10,
//   };
// }

export function headerbasic(currentPage, pageCount) {
  return {
    margin: [24, 12, 24, 0], // Margens para o cabeçalho completo
    layout: "noBorders", // Sem bordas para a tabela
    table: {
      widths: ["*", "auto"], // Largura para o nome da fazenda e paginação
      body: [
        // Primeira linha: Nome da fazenda à esquerda e paginação à direita
        [
          { text: "Relatório", alignment: "left" },
          { text: currentPage.toString() + " de " + pageCount, alignment: "right" },
        ],
      ],
    },
    fontSize: 7,
  };
}
