import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Typography } from '@mui/material';
import { Category, AutoAwesomeMotion } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import PropTypes from 'prop-types';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import FormAddProductBuy from './FormAddProductBuy';
import FormAddProductSales from './FormAddProductSales';
import FormAddLotesBuy from './FormAddLotesBuy';
import FormAddLotesSales from './FormAddLotesSales';
import FormAddAnimalsBuy from './FormAddAnimalsBuy';
import FormAddAnimalsSale from './FormAddAnimalsSale';
// import { smoothScrollToBottom } from '../../../../utils/functions';

function TabPanel(props) {
  const { children, value, index, label, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`select-buysale-item-${index}`} aria-labelledby={`${label}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.any,
};

// function a11yProps(index) {
//   return {
//     id: `formTableAddItens-tab-${index}`,
//     "aria-controls": `formTableAddItens-tabpanel-${index}`,
//   };
// }

export default function FormSelectAddItens({ data, obj_fn }) {
  const [value, setValue] = useState(0); // controla a aba selecionada
  const [open, setOpen] = useState(false); // controla a visibilidade do modal
  const [btnAdd, setbtnAdd] = useState(false); // controla qual botão add está ativo
  const [isButtonDisabled, setButtonDisabled] = useState(true); // controla estado de habilitação do botão add
  obj_fn.open = open;
  obj_fn.setbtnAdd = setbtnAdd;
  obj_fn.btnAdd = btnAdd;
  obj_fn.setButtonDisabled = setButtonDisabled;
  obj_fn.isButtonDisabled = isButtonDisabled;

  // Função para abrir o modal
  const handleOpen = (index) => {
    setValue(index); // Define a aba selecionada
    setOpen(true); // Abre o modal

    obj_fn.setData((prevState) => ({
      ...prevState,
      product_selected: null,
      lote_selected: null,
      animal_selected: null,
    }));
  };

  const handleClose = () => {
    setOpen(false); // Fecha o modal
    setbtnAdd(false);
  };

  // Referência ao conteúdo do modal
  const modalContentRef = useRef(null);
  // useEffect(() => {
  //   smoothScrollToBottom(modalContentRef, 1500);
  // }, [modalContentRef?.current?.scrollHeight]);

  return (
    <>
      <Grid container spacing={6} sx={{ ml: -2, mb: 0, }}>
        <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ ml: -3, mt: -5, mb: 5 }}>
          <Typography style={{ color: green[800] }}>
            <Typewriter tam={0} minHeight={0} text={`Adicione no carrinho os tipo de itens desejados para a ${data.operationType}`} />
          </Typography>
        </Grid>
        {/* Botões de seleção */}
        <Box sx={{ width: '100%' }}>
          <FormControl display="flex" sx={{ m: 1, mb: 3 }}>
            <Grid container spacing={6} justifyContent="center" alignItems="center" sx={{ ml: -2, mb: 0, mt: 2 }}>
              <Grid item xs={12} md={4}>
                <Button
                  variant={value === 0 ? 'contained' : 'outlined'}
                  color="success"
                  onClick={() => handleOpen(0)} // Abre o modal e define a aba 0
                  sx={{ mx: 1 }}
                >
                  <Category sx={{ mr: 0.5 }} />
                  <Badge
                    badgeContent={data.products.length}
                    color="success"
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: 'white', // Fundo branco
                        color: 'black', // Texto preto
                        //transform: 'translateX(20px)', // Deslocamento para a direita
                        //mr: 0.5,
                      },
                    }}
                  >
                    Adicionar Produto&nbsp;&nbsp;
                  </Badge>
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant={value === 1 ? 'contained' : 'outlined'}
                  color="success"
                  onClick={() => handleOpen(1)} // Abre o modal e define a aba 1
                  sx={{ mx: 1 }}
                >
                  <AutoAwesomeMotion sx={{ mr: 0.5 }} />
                  <Badge
                    badgeContent={data.lotes.length}
                    color="success"
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: 'white', // Fundo branco
                        color: 'black', // Texto preto
                        //transform: 'translateX(20px)', // Deslocamento para a direita
                        //mr: 0.5,
                      },
                    }}
                  >
                    Adicionar Lote de Animais&nbsp;&nbsp;
                  </Badge>
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant={value === 2 ? 'contained' : 'outlined'}
                  color="success"
                  onClick={() => handleOpen(2)} // Abre o modal e define a aba 2
                  sx={{ mx: 1 }}
                >
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'28px'} fill={'#1f2d3d'} />
                  <Badge
                    badgeContent={data.animals.length}
                    color="success"
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: 'white', // Fundo branco
                        color: 'black', // Texto preto
                        //transform: 'translateX(20px)', // Deslocamento para a direita
                        //mr: 0.5,
                      },
                    }}
                  >
                    Adicionar Animal Individual&nbsp;&nbsp;
                  </Badge>
                </Button>
              </Grid>
            </Grid>
          </FormControl>

          {/* Modal que será aberto quando o item for selecionado */}
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
              <>
                {value === 0 ? (
                  <Category sx={{ mr: 0.5 }} />
                ) : value === 1 ? (
                  <AutoAwesomeMotion sx={{ mr: 0.5 }} />
                ) : (
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'28px'} fill={'#1f2d3d'} />
                )}{' '}
                Adicionar {value === 0 ? 'Produto' : value === 1 ? 'Lote' : 'Animal individual'}
              </>
            </DialogTitle>
            <DialogContent ref={modalContentRef}>
              {/* Exibe o conteúdo do TabPanel com base no valor selecionado */}
              <TabPanel value={value} index={0} label={'Add Produto'}>
                {data.operationType === 'Compra' ? (
                  <FormAddProductBuy data={data} obj_fn={obj_fn} handleClose={handleClose} />
                ) : (
                  <FormAddProductSales data={data} obj_fn={obj_fn} handleClose={handleClose} />
                )}
              </TabPanel>
              <TabPanel value={value} index={1} label={'Add Lote'}>
                {data.operationType === 'Compra' ? (
                  <FormAddLotesBuy data={data} obj_fn={obj_fn} handleClose={handleClose} />
                ) : (
                  <FormAddLotesSales data={data} obj_fn={obj_fn} handleClose={handleClose} />
                )}
              </TabPanel>
              <TabPanel value={value} index={2} label={'Add Animal individual'}>
                {data.operationType === 'Compra' ? (
                  <FormAddAnimalsBuy data={data} obj_fn={obj_fn} handleClose={handleClose} />
                ) : (
                  <FormAddAnimalsSale data={data} obj_fn={obj_fn} handleClose={handleClose} />
                )}
              </TabPanel>
            </DialogContent>
            <DialogActions >
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                {btnAdd}
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleClose} color="success" variant="outlined" style={{ marginRight: '20px' }}>
                  Fechar
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          {/* <TabPanel value={value} index={3}>
            <FormAddPatrimonio data={data} obj_fn={obj_fn} />
          </TabPanel> */}
          {/* <TabPanel value={value} index={4}>
            <FormAddServico data={data} obj_fn={obj_fn} />
          </TabPanel> */}
        </Box>
      </Grid>
    </>
  );
}
