import React, { useState } from 'react';
import { Popover, Paper, Button } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { WidgetForm } from './WidgetForm';

export function Widget() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showText, setShowText] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setShowText(!showText);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowText(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="success"
        sx={{
          position: 'fixed',
          bottom: 95,
          right: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1300, // Ajuste o zIndex para garantir que o botão fique acima dos outros componentes
        }}
        onClick={handleClick}
      >
        <ChatBubbleOutlineIcon sx={{ marginRight: 1 }} />
        {showText ? 'Feedback' : ''}
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            padding: 0,
            marginTop: '-100px', // Adicionar margem negativa para mover 120px para cima
            zIndex: 1300, // Ajuste o zIndex para garantir que o popover fique acima dos outros componentes
          },
        }}
      >
        <Paper sx={{ p: 0.5, mr: 1, backgroundColor: 'transparent' }}>
          <WidgetForm handleClose={handleClose} />
        </Paper>
      </Popover>
    </>
  );
}
