import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

function formatNumber(numero) {
  return numero.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
}

// create the store
const usePlanLivestockReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPlanLivestock: [],
        planningRepos: { label: '', id: 0, id_property: 0, tipo: 'PlanLivestock' },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          console.log('usePlanLivestockReposStorexx');
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.get('/plan/planLivestockList');
          const data = await response.data;
          console.log('usePlanLivestockReposStorexx');
          console.log(data);
          await set({
            listPlanLivestock: data._value.map((planning) => ({
              id: planning.id,
              id_user: planning.id_user,
              id_property: planning.id_property,
              id_livestock: planning.id_livestock,
              typePlanning: planning.typePlanning,
              expectedProduction: planning.expectedProduction,
              unit: planning.unit,
              saleUnitValue: formatNumber(planning.saleUnitValue),
              valueSale: planning.valueSale,
              totalSale: formatNumber(planning.saleUnitValue * planning.expectedProduction),
              unitSale: planning.unitSale,
              percentageProfit: planning.percentageProfit,
              profitUnitValue: formatNumber((planning.saleUnitValue * planning.percentageProfit) / 100),
              totalProfit: formatNumber(((planning.saleUnitValue * planning.percentageProfit) / 100) * planning.expectedProduction),
              unitProfit: planning.unitProfit,
              productionCost: formatNumber(planning.saleUnitValue - (planning.saleUnitValue * planning.percentageProfit) / 100),
              totalProductionCost: formatNumber((planning.saleUnitValue - (planning.saleUnitValue * planning.percentageProfit) / 100) * planning.expectedProduction),
              valueCost: planning.valueSale,
              unitCost: planning.unitSale,
              area: planning.area,
              measure: planning.measure,
              observation: planning.observation,
              isDeleted: planning.isDeleted,
              isActiveTargetLivestock: planning.isActiveTargetLivestock,
              tipo_repos: 'PlanLivestock',
            })),
          });
          set({
            planningRepos:
              !data || data._value.length == 0
                ? { label: '', id: 0, id_property: 0, tipo_repos: 'PlanLivestock', typePlanning: '' }
                : { label: data._value[0].name, id: data._value[0].id, id_property: data._value[0].id_property, tipo_repos: 'PlanLivestock', typePlanning: data._value[0].typePlanning },
          });
          return data;
        },
        changePlanningReposRepos: (planningRepos) => set((state) => ({ planningRepos })),
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getPlanningStore: (key, val) => {
          return get().listPlanLivestock.filter((planning) => {
            if (planning[key] == val) {
              return planning;
            }
          });
        },
        // Adiciona uma nova livestock à listLivestock
        addPlanningStore: (planning) => {
          set((state) => ({
            listPlanLivestock: [...state.listPlanLivestock, planning],
          }));
        },
        // Gera uma nova listLivestock sem a livestock informada
        delPlanningStore: (id) => {
          set({
            listPlanLivestock: get().listPlanLivestock.filter((planning) => {
              if (planning.id != id) {
                return planning;
              }
            }),
          });
        },
        // atualiza listLivestock de acordo com id, key e val informados
        updatePlanningStore: (id, key, val) => {
          set({
            listPlanLivestock: get().listPlanLivestock.map((planning) => {
              if (planning.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  planning = val;
                } else {
                  planning[key] = val;
                }
              }
              return planning;
            }),
          });
          return get().listPlanLivestock;
        },
      }),
      { name: 'reposPlanLivestock' }
    )
  )
);

export default usePlanLivestockReposStore;
