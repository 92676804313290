import React from "react";

// 1	proprietario
// 2	gerente
// 3	financeiro
// 4	operacional
// 5	consultor
// 6	agronomo
// 7	veterinario
// 8	estoque

export default function RolePermission(id_role) {
    //console.log(id_role)

    let role = ''
    switch (id_role) {
        case 1:
            role = "Proprietário"
            break;
        case 2:
            role = "Gerente"
            break;
        case 3:
            role = "Financeiro"
            break;
        case 4:
            role = "Operacional"
            break;
        case 5:
            role = "Consultor"
            break;
        case 6:
            role = "Agrônomo"
            break;
        case 7:
            role = "Veterinário"
            break;
        case 8:
            role = "Estoque"
            break;
        default:
            console.log(`Error`);
    }

    return role

};
