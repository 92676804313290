import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import api from '../../services/api'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'

function processData(activityNutrition) {
  return {
    id: activityNutrition.id,
    id_property: activityNutrition.id_property,
    id_user: activityNutrition.id_user,
    name_user: activityNutrition.name_user,
    id_livestock: activityNutrition.id_livestock,
    lotes: JSON.parse(activityNutrition.info),
    // lote_products: activityNutrition.lote_products,
    lote_products: JSON.parse(activityNutrition.lote_products || '[]'),
    animal_products: JSON.parse(activityNutrition.animal_products || '[]'),
    num_animals: activityNutrition.num_animals,
    num_females: activityNutrition.num_females,
    num_males: activityNutrition.num_males,
    total_animals: activityNutrition.total_animals,

    date_start: dayjs(activityNutrition.date_start).format('YYYY-MM-DD HH:mm:ss'),
    date_start_f: dayjs(activityNutrition.date_start).format('DD/MM/YYYY'),
    date_prev_end: dayjs(activityNutrition.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end_f: dayjs(activityNutrition.date_prev_end).format('DD/MM/YYYY'),
    date_end: activityNutrition.date_end ? dayjs(activityNutrition.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
    date_end_f: activityNutrition.date_end ? dayjs(activityNutrition.date_end).format('DD/MM/YYYY') : '',
    description: activityNutrition.description,
    isDeleted: activityNutrition.isDeleted,
    info: JSON.parse(activityNutrition.info),
    id_transaction: activityNutrition.id_transaction,
    info_transaction: activityNutrition.info_transaction ? JSON.parse(activityNutrition.info_transaction) : '',
    record: activityNutrition.record ? JSON.parse(activityNutrition.record) : '',

    type: 'activity',
    activity: activityNutrition.name || 'MEDICAMENTOS',
    name_activity: activityNutrition.name || 'MEDICAMENTOS',
    progress: activityNutrition.progress || 'CONCLUÍDA',
    planning: activityNutrition.planning || 'realized',
    tipo_repos: 'NUTRICAO',
  }
}

// create the store
const useActivityNutritionReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listLotes: [],
        listAnimals: [],
        listActivityNutrition: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!item) {
            item = { id_property: id_property }
          } else if (!item.id_property) {
            item.id_property = id_property
          }
          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityNutrition/list', item, header)
          // console.log('activityNutrition')
          // console.log(data)
          const data = await response.data
          const data_v = data._value || []

          let list_lotes = []
          let list_animals = []
          await set({
            listActivityNutrition: data_v.map((activityNutrition) => {
              // list_lotes = list_lotes.concat(activityNutrition.lote_products)
              // list_animals = list_animals.concat(activityNutrition.animal_products)
              list_lotes = list_lotes.concat(JSON.parse(activityNutrition.lote_products || '[]'))
              list_animals = list_animals.concat(JSON.parse(activityNutrition.animal_products || '[]'))
              return processData(activityNutrition)
            }),
          })

          set({
            listLotes: list_lotes,
            listAnimals: list_animals,
          })

          const lista = JSON.parse(localStorage.getItem('listActvs'))
          const lista_i = get().listActivityNutrition

          let array_aux = lista.filter((activity) => {
            if (!/NUTRICAO/.test(activity.activity)) {
              return activity
            }
          })
          const res = array_aux.concat(lista_i)
          localStorage.setItem('listActvs', JSON.stringify(res))

          return data
        },
        // pega a(s) produção(ões) da listActivityNutrition de acordo com o [key e val] informados
        getActivityNutritionStore: (key, val) => {
          return get().listActivityNutrition.filter((activityNutrition) => {
            if (activityNutrition[key] == val) {
              return activityNutrition
            }
          })
        },
        // Gera uma nova listActivityNutrition sem a activity informada
        delNutritionStore: (id) => {
          set({
            listActivityNutrition: get().listActivityNutrition.filter((activity) => {
              if (activity.id != id) {
                return activity
              }
            }),
          })
        },
        // atualiza listActivityNutrition de acordo com id, key e val informados
        updateActivityNutritionStore: (id, key, val) => {
          set({
            listActivityNutrition: get().listActivityNutrition.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val
                } else {
                  activity[key] = val
                }
              }
              return activity
            }),
          })
          return get().listActivityNutrition
        },
        // consulta os lançamentos do ano informado
        fetchFiltered: async (filters) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!filters) {
            filters = {}
          }
          if (!filters.filter) {
            filters.filter = true
          }
          if (!filters.id_property) {
            filters.id_property = id_property
          }
          // carrega dados do ano
          if (!filters.between) {
            filters.date = 'ap.date_start'
            filters.dateI = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss')
            filters.dateF = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss')
          }

          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityNutrition/list', filters, header)
          const data = await response.data
          const data_v = data._value || []
          // console.log('listActivityNutrition')
          // console.log(data_v)

          var listActivityNutrition = data_v.map((activityNutrition) => {
            return processData(activityNutrition)
          })

          return listActivityNutrition
        },
      }),
      { name: 'reposActivityNutrition' }
    )
  )
)

export default useActivityNutritionReposStore
