// Função para converter uma string base64 em Blob
export const base64ToBlob = (base64String, contentType) => {
  // Separe o tipo do conteúdo da string base64 (por exemplo, 'data:image/jpeg;base64')
  const parts = base64String.split(';base64,')
  const type = parts[0].split(':')[1]

  // Decode a string base64 para um array de bytes
  const byteCharacters = atob(parts[1])
  const byteArrays = []

  // Converta os bytes em uma matriz de bytes
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  // Crie um Blob a partir da matriz de bytes e retorne
  return new Blob(byteArrays, { type: contentType || type })
}

// Exemplo de uso: supondo que 'areaTranferImage' seja sua string base64
// const blob = base64ToBlob(areaTranferImage, 'image/jpeg');
// console.log(blob); // O objeto Blob resultante
