import * as React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormatListBulleted } from "@mui/icons-material";

import Typewriter from "../../../../../components/Typewriter/Typewriter";
import ThreeDotsSpinner from "../../../../../components/Loader/ThreeDotsSpinner";

export default function FormWeightHeader({ text_title, txt_msn, height, showTextFase, imagem }) {

  return ( <>
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Stack spacing={0.5} sx={{ mt: -0.5 }}>
          <Typography variant="h6" fontWeight={500}>
            <FormatListBulleted color="success" sx={{ mr: 1, width: '32px', height: '32px' }} /> Identificação dos animais
          </Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid item container alignItems="center" justifyContent="center">
      <Grid item sx={{ mt: 1, height: 120 }}>
        <img style={{ maxHeight: 120 }} src={imagem || "/dist/image/lotedeanimaisnaareadata.png"} className="img-fluid" />
      </Grid>
    </Grid>
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>{text_title}</Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid container spacing={1} sx={{ mt: 2, minHeight: (height || 'auto') }} >
      <div className="d-lg-none">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center" >
            {showTextFase ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={txt_msn} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center" >
            {showTextFase ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={txt_msn} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>

  </> );
}
