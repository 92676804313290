import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Collapse, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, List, ListItem, ListItemText, ListSubheader, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import useStore from "../../store/useStore";
import { useContext } from "react";
import { Context } from "../../components/Context/AuthContext";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterDialogModalDelete({ open, handleDelete, handleClose, listSubcategoriesDelete, categories, setCategories, categoryNameDefault }) {
  // const [open, setOpen] = useState(false);
  // console.log(categoryNameDefault);
  const [categoryName, setCategoryName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(categoryNameDefault);

  const usestore = useStore();
  const reposAccount = usestore.reposAccount
  var farm_id = localStorage.getItem("Property");
  const { aux_add, setAuxAdd } = useContext(Context);

  // const handleClickOpen = () => {
  //   //setOpen(true);
  // };

  // const handleClose = () => {
  //   //setOpen(false);
  // };


  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleSave = async (e) => {
    console.log("categoryInfo")
    console.log(categoryInfo)
    //handleClose()
  }

  // Função recursiva para gerar opções hierárquicas
  const generateCategoryOptions = (categoryList, level = 0) => {
    const options = [];

    categoryList.forEach((category) => {
      options.push(
        <MenuItem key={category.id} value={category.name}>
          {`${"\u00A0".repeat(level * 2)}${category.name}`}
        </MenuItem>
      );

      if (category.subcategories.length > 0) {
        options.push(...generateCategoryOptions(category.subcategories, level + 1));
      }
    });

    return options;
  };

  // function collectCategoryInfo(category) {
  //   let result = [];

  //   // Adiciona a categoria atual e sua subcategoria, se disponível
  //   if (category?.infoParse && category?.infoParse[2].startsWith('subcategory')) {
  //     result.push({
  //       id: category.id,
  //       name: category.name,
  //       subcategory: category.infoParse[2]
  //     });
  //   }

  //   // Percorre as subcategorias
  //   if (category?.subcategories && category?.subcategories.length > 0) {
  //     category.subcategories.forEach(subcategory => {
  //       // Concatena os resultados das subcategorias
  //       result = result.concat(collectCategoryInfo(subcategory));
  //     });
  //   }

  //   return result;;
  // }

  // // categorias e subcategorias que serão deletdas
  // console.log("listSubcategoriesDelete");
  // console.log(listSubcategoriesDelete);

  // // array de cate e sub para exibir no modal
  // const categoryInfo = collectCategoryInfo(listSubcategoriesDelete);

  // console.log("categoryInfo");
  // console.log(categoryInfo)

  function extractCategoriesAndSubcategories(data) {
    let result = [];

    // Função para processar cada categoria ou subcategoria
    function processCategory(category, parentName = null, isSubcategory = false) {
      // Constrói um objeto com as informações da categoria ou subcategoria
      let categoryObj = {
        id: category.id,
        name: category.name,
        category: parentName ? parentName : category.infoParse[2], // Usa parentName para subcategorias
        type: isSubcategory ? "subcategory" : "category" // Diferencia categoria de subcategoria
      };
      result.push(categoryObj);

      // Processa subcategorias recursivamente, se houver
      if (category.subcategories && category.subcategories.length > 0) {
        category.subcategories.forEach(subcategory => {
          processCategory(subcategory, category.name, true); // Passa true para isSubcategory
        });
      }
    }

    // Inicia o processamento pela categoria principal
    processCategory(data);

    return result;
  }
  let categoryInfo = []
  if (listSubcategoriesDelete) {
    categoryInfo = extractCategoriesAndSubcategories(listSubcategoriesDelete);
  }

  console.log(categoryInfo);

  //const [openClick, setOpenclick] = useState({});

  // const handleClick = (id) => {
  //   setOpenclick(prevOpen => ({
  //     ...prevOpen,
  //     [id]: !prevOpen[id]
  //   }));
  // };
  categoryInfo.map(category => console.log(category))

  return (
    <div>
      {/* <Button variant="outlined" color="success" onClick={handleClickOpen}>
          Categoria
        </Button> */}

      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        {/* <DialogTitle>Cadastrar Categoria</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h6" component="div">
                Deletar Centro de Custo
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: -2, mb: 2, borderColor: "green" }} />
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom component="div">
                Tem certeza que deseja deletar o centro de custo <strong>{categoryInfo[0]?.name}</strong>?
              </Typography>
            </Grid>
            {categoryInfo?.length == 1 ? null :
              <Typography gutterBottom component="div">
                Confirmando, você estará deletando <strong>{categoryInfo[0]?.name}</strong> e os centros de custos (sucategorias) a ele relacionado.
              </Typography>
            }
          </Grid>

          <Grid item xs={12} md={12}>
          </Grid>
          <List>
            {categoryInfo?.map(category => (
              <div key={category.id}>
                {/* <ListItem  onClick={() => handleClick(category.id)}> */}
                <ListItem  >
                  {category?.type == "category" && <ListItemText primary={<Typography style={{ color: "black", fontWeight: "bold" }}>{category.name.toUpperCase()} </Typography>} />}
                </ListItem>
                <ListItem sx={{ ml: 3, mt: -3 }} >
                  {category?.type == "subcategory" && <ListItemText primary={<Typography style={{ color: "green" }}>{category.name.toUpperCase()} </Typography>} />}
                </ListItem>

              </div>
            ))}
          </List>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={() => handleDelete(listSubcategoriesDelete.id)} variant="contained" color="success">
            Confirmar
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
