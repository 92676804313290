import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Span } from '../../../../components/Typography';
import MenuPlan from '../../../signature/components/MenuPlan';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import AddActivities from '../../../activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import TablesCreationsCardProjectManagement from './TablesCreationsCardProjectManagement';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StartProject from './StartProject';
import { useRef } from 'react';
export default function TablesCreationsCardEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 6000);

  const accordionDetailsRef = useRef(null);

  const handleAccordionChange = (event, isExpanded) => {
    if (isExpanded && accordionDetailsRef.current) {
      // Rola para o AccordionDetails quando expandido
      accordionDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <TablesCreationsCardProjectManagement />

          <StartProject />

          <Card sx={{ m: 2 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    {' '}
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Ao criar um projeto pecuário, incia-se a gestão pecuária.</Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos cadastrar um projeto para a sua criação para iniciarmos o gerenciamento da produção?</Typography>
                    </Box>
                    <Box sx={{ minHeight: 140 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter
                            tam={130}
                            minHeight={0}
                            text="Para cadastrar uma criação é necessário criar um projeto pecuário. Para isso, você pode clicar no botão verde (+ CRIAR PROJETO) na parte superior da tela."
                          />
                        )}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Ao criar um projeto pecuário, incia-se a gestão pecuária.</Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Vamos cadastrar um projeto para a sua criação para iniciarmos o gerenciamento da produção?
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 50 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter
                            tam={30}
                            minHeight={0}
                            text="Para cadastrar uma criação é necessário criar um projeto pecuário. Para isso, você pode clicar no botão verde (+ CRIAR PROJETO) na parte superior da tela."
                          />
                        )}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>

            <Divider />
          </Card>
          <Card sx={{ m: 0 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Grid container xs={12} md={12} sx={{ m: -2 }} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/atividades.png" name=""></img>
                  </Grid>
                  {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                  <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                    <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                      Gestão das suas Criações
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Através de uma projeto pecuário criado, é possível gerenciar com facilidade todas as atividades de produção, garantindo controle financeiro específico e eficiente alocação de
                      recursos.
                    </Typography>
                    <Accordion onChange={handleAccordionChange}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Porque registrar um projeto pecuário?</Typography>
                      </AccordionSummary>
                      <AccordionDetails ref={accordionDetailsRef}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Ao dar um nome para um projeto é importante para a organização e identificação. Ao definir prazo, você poderá avaliar os resultados com precisão e tomar decisões estratégicas
                          para atingir os objetivos dentro do prazo estabelecido.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion onChange={handleAccordionChange}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>O que é gestão pecuária? (para proprietários e gerentes)</Typography>
                      </AccordionSummary>
                      <AccordionDetails ref={accordionDetailsRef}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          É o planejamento estratégico e administração de todas as atividades relacionadas à criação de gado, incluindo decisões sobre negócios, finanças, e estratégias de crescimento.
                          Na fazenda, normalmente a gestão pecuária geralmente é realizada por gestores ou proprietários responsáveis pela tomada de decisões estratégicas e administrativas. Eles
                          planejam as atividades, gerenciam recursos financeiros, implementam políticas de sustentabilidade e bem-estar animal, e definem as estratégias de mercado.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion onChange={handleAccordionChange}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>O que é gerenciamento da pecuária? (para o gerente)</Typography>
                      </AccordionSummary>
                      <AccordionDetails ref={accordionDetailsRef}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Refere-se à operação diária e prática da criação de gado, incluindo alimentação, cuidados de saúde, reprodução, e gestão de recursos humanos envolvidos diretamente na fazenda
                          ou propriedade pecuária. Por outro lado, o gerenciamento pecuário é conduzido por profissionais que lidam diretamente com as operações diárias da fazenda. Isso inclui
                          vaqueiros, veterinários e outros trabalhadores rurais que cuidam da alimentação do gado, manejo de pastagens, saúde animal, reprodução e outras atividades práticas
                          relacionadas à criação e manejo dos animais.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
