import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { Input } from '@mui/icons-material';
import dayjs from 'dayjs';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../../components/Fields/SingleDateField';
// import CurrencyTextField from '../../../../../components/Fields/CurrencyTextField';
// import FormApportionment from '../../Forms/FormApportionment';
// import FormAccountSelection from '../../Forms/FormAccountSelection';
import AutocompletGenericList from '../../../../../components/Fields/AutocompletGenericList';
import GenericValueField from '../../../../../components/Fields/GenericValueField';
import { green } from '@mui/material/colors';

const text_title = {
  'Conta a receber': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Registrar Receita': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Conta a pagar': 'Por favor, defina as saídas previstas (acertos com fornecedores ou quitação de contas de consumo)',
  'Registrar Despesa': 'Por favor, registre as informações essenciais da despesas',
  Compra: '',
  Venda: '',
};

export default function FormEntryStep2({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === 'value') {
      obj_fn.setData((prevState) => ({
        ...prevState,
        value_end: event.target.value,
      }));
    }
  };

  // const para validar o button continuar
  const isError = () => {
    if (!data.value || data.value === '0.00' || data.error_date || error) {
      return true;
    }
    return false;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de cliente ou fornecedor
  let optionsPerson = [];
  let aux_receita = /.*(receber|receita).*/.test(data.operationType.toLowerCase());
  if (aux_receita) {
    optionsPerson = obj_fn.usestore.reposPerson.listCliente;
  } else {
    let aux_f = obj_fn.usestore.reposPerson.listFornecedor;
    let aux_t = obj_fn.usestore.reposPerson.listTransportadora;
    optionsPerson = [...aux_f, ...aux_t];
  }
  const opt_person = {
    multiple: false,
    name: aux_receita ? 'client' : 'supplier',
    label: aux_receita ? 'Cliente' : 'Fornecedor',
    values: optionsPerson,
    field: aux_receita ? 'client' : 'supplier',
    helperText: '',
    listHeight: 225,
    reposPerson: obj_fn.usestore.reposPerson,
  };
  opt_person.helperText = data.operationType + ' de: ' + opt_person.label;

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    if (field === 'client' || field === 'supplier') {
      obj_fn.setData((prevState) => ({
        ...prevState,
        person: newValue,
        [field]: newValue?.id,
      }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
    }
  };

  // Monta o obj e função para o campo de data do lançamento
  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      date_release: dayjs(value),
      date_release_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      date_release_fc: dayjs(value).format('DD/MM/YYYY'),

      dueDate: dayjs(value),
      dueDate_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      dueDate_fc: dayjs(value).format('DD/MM/YYYY'),
    }));
  };

  let obj_date = {
    label: 'Data do lançamento',
    field_data: 'date_release',
    text_helper: 'Data do registro', //'Dia de execução da competência',
    max: dayjs(),
    // min: data.date_release,
    handleChange: handleChangeDate,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  };

  //botao radio
  const [isEnabled, setIsEnabled] = useState(data?.person || false); // Estado para habilitar/desabilitar o Autocomplete

  const handleCheckboxChange = (event) => {
    setIsEnabled(event.target.checked); // Atualiza o estado baseado no checkbox
  };

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography style={{ color: '#2e7d32' }}>
                <div className="d-lg-none">
                  <Typewriter tam={110} minHeight={0} text={text_title[data.operationType]} />
                </div>
                <div className="d-none d-lg-block">
                  <Typewriter tam={60} minHeight={0} text={text_title[data.operationType]} />
                </div>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mt: 2, zIndex: 100 }}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Input sx={{ mr: 1 }} />
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações do lançamento: {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={6} sm={3} sx={{ mt: -3 }}>
            <SingleDateField data={data} obj_date={obj_date} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <div className="d-lg-none">
              <TextField
                fullWidth
                color="success"
                focused
                InputProps={{
                  style: {
                    borderColor: green[500], // Definir a cor da borda quando o campo está ativo
                  },
                }}
                sx={{ mt: -7 }}
                id="outlined-basic"
                name="descriptionEntry"
                label="Descrição"
                value={data.descriptionEntry}
                onChange={handleChange}
                //onBlur={(e) => handleChange(e, true)}
                variant="outlined"
                //helperText=""
              />
            </div>
            <div className="d-none d-lg-block">
              <TextField
                fullWidth
                color="success"
                focused
                InputProps={{
                  style: {
                    borderColor: green[500], // Definir a cor da borda quando o campo está ativo
                  },
                }}
                id="outlined-basic"
                name="descriptionEntry"
                label="Descrição"
                value={data.descriptionEntry}
                onChange={handleChange}
                //onBlur={(e) => handleChange(e, true)}
                variant="outlined"
                //helperText=""
              />
            </div>
          </Grid>
          <Grid item xs={6} sm={4} sx={{ mb: 5 }}>
            {/* <CurrencyTextField required id="value" name="value" label="Valor Total*" color="success" value={data.value} onChange={handleChange} helperText="Valor total do Lançamento" /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: 'value',
                name: 'value',
                label: 'Valor Total',
                value: data.value,
                helperText: 'Valor total do Lançamento',
                endAdornment: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: -4, mb: 20 }}>
          <Grid item xs={12} sm={6} container alignItems="center" sx={{ height: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEnabled}
                  onChange={handleCheckboxChange}
                  color="success" // Define a cor do checkbox como verde
                />
              }
              label="Deseja adicionar o Fornecedor? (opcional)"
            />
            {isEnabled && (
              <Grid container sx={{ p: 2 }}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography variant="subtitle2" style={{ color: '#808080' }}>
                      Para pesquisar um fornecedor existente, clique na caixa Fornecedor e digite o nome desejado.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography variant="subtitle2" style={{ color: '#808080' }}>
                      Para registrar um novo fornecedor, clique na caixa Fornecedor e depois em "Incluir novo Fornecedor".
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* Exibe o Autocomplete somente se isEnabled for verdadeiro */}
          <Grid item xs={12} sm={6} sx={{ display: isEnabled ? 'block' : 'none', height: '100%' }}>
            <AutocompletGenericList value={data.person} options={opt_person} handleChange={handleChangeSelect} label={opt_person.label} isEnabled={isEnabled} />
          </Grid>
        </Grid>

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
