import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/Loader/Loading";
import api from "../../../../services/api";
import CardChatKanban from "./CardChatKanban";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { blue } from "@mui/material/colors";
import useUserReposStore from "../../../../store/useUserReposStore";


const status = {
    NotStarted: 'Not Started',
    Started: 'Started',
    InProgress: 'In Progress',
    Finished: 'Finished',
}

export default function BoardMessenger() {
    let { id } = useParams();
    const [board, setBoard] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [msn, setMsn] = useState([]);
    const [msnToDo, setMsnToDo] = useState([]);
    const [msnDoing, setMsnDoing] = useState([]);
    const [msnStopped, setMsnStopped] = useState([]);
    const [isLoadingCard, setIsLoadingCard] = useState(true);

    const property_id = (localStorage.getItem("Property") || 1);
    const reposUser = useUserReposStore(state => state);

    const loadPosts = async () => {
        console.log("-------result------------:");
        //carregar repositorio dos users
        const optionsEquipe = reposUser.listUser;
        console.log('optionsEquipe');

        const response = await api.get("/messenger/posts");
        console.log(response.data._value);
        const itemData = [];
        const itemDataStatusToDo = [];
        const itemDataStatusDoing = [];
        const itemDataStatusStopped = [];
        const itemDataStatusDone = [];

        for (const info of response.data._value) {
            //console.log(info.id_property)
            //console.log(property_id)
            if (info.isDeleted === 0 && info.id_property == property_id) {
                //console.log("entrou")
                // const name = iterator.name_requester;
                // console.log(name)
                // const charName = name[0].toUpperCase();
                // console.log(charName);
                // iterator.char = charName

                info.name_receiver = optionsEquipe.filter(item => {
                    if (item.id == info.id_receiver) {
                        // console.log(item.name);
                        return item.name;
                    }
                })
                // console.log('name_receiver');
                info.name_receiver = (info.name_receiver[0] ? info.name_receiver[0].name : '');
                //console.log(info.name_receiver);

                for (const iterator of optionsEquipe) {
                    console.log('iterator ')
                    console.log(iterator)
                    if (iterator.id === info.id_requester) {
                        info.name = iterator.name
                        const name = info.name
                        //console.log(name)
                        const charName = name[0].toUpperCase();
                        //console.log(charName)
                        info.charName = charName
                    }
                }
                console.log('info')
                console.log(info)
                itemData.push(info);

                if (info.status == "0") {
                    itemDataStatusToDo.push(info)
                } else if (info.status == "1") {
                    itemDataStatusDoing.push(info)
                } else if (info.status == "2") {
                    itemDataStatusStopped.push(info)
                }


            }

        }
        console.log('itemDataStatusToDo')
        console.log(itemDataStatusToDo)
        console.log(itemDataStatusDoing)
        console.log(itemDataStatusStopped)
        //ordernar as data
        itemData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setMsn(itemData);
        itemDataStatusToDo.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setMsnToDo(itemDataStatusToDo);
        itemDataStatusDoing.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setMsnDoing(itemDataStatusDoing);
        itemDataStatusStopped.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setMsnStopped(itemDataStatusStopped);
        setIsLoadingCard(false)
        setIsLoading(false)

    };


    useEffect(() => {
        loadPosts();
    }, []);



    useEffect(() => {
        loadPosts();
        console.log('setMsn')
        console.log(msn)
    }, [isLoading]);



    const [lists, setLists] = useState('');

    // return <>{!board ? <BoardArea board={board} /> : <Typography>Board not found</Typography>}</>;

    const columns = [
        {
            id: 1,
            title: 'A fazer',
            color: 'success'
        },
        {
            id: 2,
            title: 'Em progresso',
            color: 'primary'
        },
        {
            id: 3,
            title: 'Parado',
            color: 'secondary'
        },
        {
            id: 4,
            title: 'Concluída',
            color: 'secondary'
        }
    ]

    // area do board
    return (
        <>
            {/* {lists.map((list, index) => <List key={list.title} index={index} data={list} />)} */}
            <Box sx={{ ml: 0 }}>
                <div className="content-wrapper kanban " style={{ backgroundColor: "#ecf1f8", font: "14px", collor: "#333" }}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <span className="breadcrumb-item active">Kanban Board</span>
                                </div>

                            </div>
                        </div>
                    </section>
                    {isLoading === true ? (
                        <>
                            <Loading />
                        </>
                    ) : (
                        <>
                            <section className="content pb-3 ">
                                <div className="container-fluid h-100">
                                    <div className={`card card-row `} >
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <PlayCircleOutlineIcon sx={{ mr: 0.5, mt: -0.4, color: blue[700] }} />  Resolver - A fazer
                                            </h3>
                                        </div>
                                        <div className="card-body">

                                            {
                                                msnToDo?.map((post) => (

                                                    <CardChatKanban post={post} setIsLoading={setIsLoading} />

                                                ))
                                            }
                                        </div>
                                    </div>
                                    {/* <div className={`card card-row `} >
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Fazendo
                                    </h3>
                                </div>
                                <div className="card-body">
                                    
                                    <p>Em contrução</p>
                                </div>
                            </div> */}
                                    <div className={`card card-row `} >
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <DoDisturbIcon sx={{ mr: 0.5, mt: -0.4 }} /> Parado

                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            {/* <CardTasksMessenger tasks={msn} /> */}
                                            {
                                                msnStopped?.map((post) => (

                                                    <CardChatKanban post={post} setIsLoading={setIsLoading} />

                                                ))
                                            }


                                        </div>
                                    </div>
                                    <div className={`card card-row card-success`} >
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <CheckCircleOutlineIcon sx={{ mr: 0.5, mt: -0.4 }} />Resolvido - Concluído
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            {
                                                msnDoing?.map((post) => (

                                                    <CardChatKanban post={post} setIsLoading={setIsLoading} />

                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}

                </div>
            </Box>
        </>

    );


}

// {data.map((col) => (
//     <div className={`card card-row card-${col.color}`} >
//         <div className="card-header">
//             <h3 className="card-title">
//                 {col.name}
//             </h3>
//         </div>
//         <div className="card-body">
//             {/* <CardTasksMessenger tasks={msn} /> */}
//             {
//                 msn?.map((post) => (

//                     <CardChatKanban post={post} setIsLoading={setIsLoading} />

//                 ))
//             }
//         </div>
//     </div>
// ))}