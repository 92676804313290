import React, { useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Category, HighlightOff, Queue } from '@mui/icons-material';
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric';
import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal';
import ProductRegistrar from '../../../products/components/ProductRegistrar/ProductRegistrar';
import GenericValueField from '../../../../components/Fields/GenericValueField';

const getHelperText = (cost) => {
  return (
    <>
      Selecione o produto desejado
      <br />
      Valor unitário: {cost || '-'}
    </>
  )
};
export default function CardProductPatrimony({ data, obj_fn }) {
  const optionsProducts = obj_fn.usestore.reposProducts.listProducts;

  const [optionsLocations, setLocations] = useState(data.product?.location || []);
  const [location_selected, setLocation] = useState(data.product?.location_selected || null);

  // Inicializar data.products como um array vazio se não estiver definido
  const products = data.products || [];

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: 'Produto *',
    values: optionsProducts,
    variant: 'standard',
    helperText: '',
    listHeight: 225,
  };

  const opt_products_local = {
    multiple: false,
    name: 'product_location_',
    field: 'product_location',
    label: 'Local de estoque',
    values: optionsLocations,
    variant: 'standard',
    helperText: (
      <>
        Local onde o produto se encontra
        <br />
        Qtd. em estoque: {location_selected?.quanty || '-'} {data.product?.unit_sigla ? ' (' + data.product?.unit_sigla + ')' : ''}
      </>
    ),
    listHeight: 225,
    disabled: !data.product?.id,
    disableClearable: true,
  };

  const handleAdd = () => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      products: [
        ...(prevState.products || []),
        {
          id: prevState.products ? prevState.products.length + 1 : 1,
          product: { id: null, location: [], stock_cost: '', unit_sigla: '' },
          location_selected: { id: null, quanty: '', quant_utilizada: '', value: '' },
        },
      ],
    }));
  };

  const handleChangeSelect = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.products || [])];
      if (newValue) {
        updatedProducts[index].product = newValue;
        updatedProducts[index].location_selected = newValue.location.length > 0 ? newValue.location[0] : { id: null, quanty: '', quant_utilizada: '', value: '', };
      } else {
        updatedProducts[index].product = { id: null, location: [], stock_cost: '', unit_sigla: '' };
        updatedProducts[index].location_selected = { id: null, quanty: '', quant_utilizada: '', value: '' };
      }

      return { ...prevState, products: updatedProducts, };
    });
  };

  const handleChangeLocal = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.products || [])];
      updatedProducts[index].location_selected = newValue;

      return { ...prevState, products: updatedProducts, };
    });
  };

  const handleChangeQtd = (index, event) => {
    const newValue = parseFloat(event.target.value || '0');
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.products || [])];
      const selectedProduct = updatedProducts[index];
      const aux_local = { ...selectedProduct.location_selected, quant_utilizada: newValue, value: newValue * (selectedProduct.product.stock_cost || 0) };
      selectedProduct.location_selected = aux_local;

      return { ...prevState, products: updatedProducts, };
    });
  };

  const handleRmv = (index) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      products: prevState.products.filter((_, i) => i !== index),
    }));
  };

  const validQtd = (product, type) => {
    const valid = !product.location_selected || product.location_selected.quanty >= (product.location_selected.quant_utilizada || 0);
    if (type === 'color') {
      return valid ? 'success' : 'error';
    } else {
      return (
        <>
          Qtd. a ser utilizada
          {!valid && (
            <>
              <br />
              <span style={{ color: 'red' }}>Não pode ser maior do que a Quantidade em estoque</span>
            </>
          )}
        </>
      );
    }
  };

  const isError = () => {
    return false;
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <ProductRegistrar />
        </Box>
      </Grid>
      {products.map((product, index) => (
        <Card sx={{ p: 1, mt: 2 }} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Box sx={{ ml: 3, display: 'flex' }}>
                  <Category />
                  <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                    <Typography fontWeight={700}>Insumo {index + 1}</Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton variant="contained" color="error" onClick={() => handleRmv(index)} title="Remover">
                  <HighlightOff />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <AutocompleteGeneric value={product.product.id ? product.product : null} 
                options={{ ...opt_products, helperText: getHelperText(product.product.stock_cost) }}
                // options={opt_products} 
                handleChange={(field, newValue) => handleChangeSelect(index, newValue)} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 0 }}>
              <AutocompleteGeneric
                value={product.location_selected.id ? product.location_selected : null}
                options={{ ...opt_products_local, values: product.product.location }}
                handleChange={(field, newValue) => handleChangeLocal(index, newValue)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
            <Grid item xs={6} sm={4}>
              <CurrencyInputReal
                name={`quant_stq_${product.product.id}_${product.location_selected.id}`}
                label="Qtd. em estoque"
                variant="outlined"
                size="small"
                value={product.location_selected.quanty || '-'}
                adornment="quantidade"
                endAdornment={product.product.unit_sigla ? ` (${product.product.unit_sigla})` : ''}
                helperText="Disponível no estoque"
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              {/* <CurrencyInputReal
                name={`quant_uti_${product.product.id}_${product.location_selected.id}`}
                label="Quantidade utilizada"
                variant="outlined"
                value={product.location_selected.quant_utilizada}
                adornment="quantidade"
                endAdornment={product.product.unit_sigla ? ` (${product.product.unit_sigla})` : ''}
                onChange={(event) => handleChangeQtd(index, event)}
                helperText={validQtd(product, 'helperText')}
                color={validQtd(product, 'color')}
                disabled={!product.location_selected.id}
              /> */}
              <GenericValueField
                handleChange={(event) => handleChangeQtd(index, event)}
                options={{
                  required: true,
                  id: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                  name: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                  label: 'Quantidade utilizada',
                  value: product.location_selected.quant_utilizada,
                  variant: 'outlined',
                  helperText: validQtd(product, 'helperText'),
                  color: validQtd(product, 'color'),
                  endAdornment: true,
                  prefix: product.product.unit_sigla ? ` (${product.product.unit_sigla})` : '',
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <CurrencyInputReal
                name={`prod_value_${product.product.id}_${product.location_selected.id}`}
                label="Custo"
                variant="outlined"
                size="small"
                value={product.location_selected.value || '-'}
                helperText="Custo do produto"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Card>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="success" onClick={handleAdd} disabled={isError()}>
            <Queue sx={{ mr: 1 }} /> Adicionar uso de insumo (produto)
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
