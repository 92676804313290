import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import HarvestContent from "./HarvestContent";
import Footer from "../../components/Footer/Footer";

export default function Harvest() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <HarvestContent />
      <Footer />
    </>
  );
}
