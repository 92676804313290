import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FlightIcon from '@mui/icons-material/Flight';

export default function StartProjectLivestock() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        <Typography variant="subtitle2" fontWeight={500}>
          Como planejar, organizar, dirigir e controlar um projeto
        </Typography>
      </Button>
      <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Gestão Pecuária
              </Typography>
              <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="Inicia-se agora o gerenciamento das atividades e dos custos de produção" />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <CardMedia component="img" width="200" image="/dist/image/podc.png" alt="Gestão Agrícola" />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 0 }}>
                <Typography paragraph variant="subtitle1" fontWeight={300}>
                  Planeje, organize, dirija e controle seu projeto para o sucesso!!!
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <strong>Planejar</strong>
                </AccordionSummary>
                <AccordionDetails>"Planejar é traçar o caminho que guia nossas ações, transformando sonhos em metas e incertezas em oportunidades."</AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/podc_planejar.png" alt="Gestão Agrícola" />
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <strong>Organizar</strong>
                </AccordionSummary>
                <AccordionDetails>"Organizar é criar a estrutura que sustenta nossas ações, permitindo que recursos sejam utilizados de forma eficaz e as metas se tornem realidade."</AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/podc_organizar.png" alt="Gestão Agrícola" />
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <strong>Dirigir</strong>
                </AccordionSummary>
                <AccordionDetails>
                  "Dirigir é liderar com propósito, orientando a equipe e tomando decisões estratégicas que garantam o alinhamento das ações com os objetivos estabelecidos."
                </AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/podc_dirigir.png" alt="Gestão Agrícola" />
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <strong>Controlar</strong>
                </AccordionSummary>
                <AccordionDetails>
                  "Controlar é monitorar continuamente o progresso, assegurando que as atividades se desenrolem conforme o planejado, corrigindo desvios e ajustando estratégias para alcançar os
                  resultados desejados."
                </AccordionDetails>
                <CardMedia component="img" width="200" image="/dist/image/podc_controlar.png" alt="Gestão Agrícola" />
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
