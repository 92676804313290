import React, { useState } from 'react'
import { Grid } from '@mui/material'
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric'
import CurrencyInputReal from '../../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../../components/Fields/GenericValueField'

export default function CardProduct({ data, obj_fn, area }) {
  const optionsProducts = JSON.parse(
    JSON.stringify(
      obj_fn.usestore.reposProducts.getProductsStoreMultiple([
        ['category_name', 'Inseticida'],
        ['category_name', 'Herbicida'],
        ['category_name', 'Fungicida'],
        ['category_name', 'Defensivos'],
      ])
    )
  )

  // console.log(optionsProducts)
  const [optionsLocations, setLocations] = useState(area.product?.location || [])
  const [location_selected, setLocation] = useState(area.product?.location_selected || null)

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: 'Produto (Defensivos)',
    values: optionsProducts,
    variant: 'standard',
    helperText: (
      <>
        Selecione o produto desejado
        <br />
        Valor unitário: {area.product?.stock_cost || '-'}
      </>
    ),
    listHeight: 225,
  }

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        /*if (area_prev.id === area.id) {
          if (newValue) {
            newValue.location_selected = newValue.location.length > 0 ? newValue.location[0] : null;
            setLocation(newValue.location_selected);
            setLocations(newValue.location || []);
          }
          return { ...area_prev, product: newValue };
        }*/
        if (area_prev.id === area.id) {
          if (newValue) {
            let aux_prod = JSON.parse(JSON.stringify(newValue))
            aux_prod.location_selected = aux_prod.location.length > 0 ? aux_prod.location[0] : null
            setLocation(aux_prod.location_selected)
            setLocations(aux_prod.location || [])
            return { ...area_prev, product: aux_prod }
          } else {
            setLocation(null)
            setLocations([])
            return { ...area_prev, product: newValue }
          }
        }
        return area_prev
      }),
    }))
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1)
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }))
    }
  }

  const opt_products_local = {
    multiple: false,
    name: 'product_location_' + area.id,
    field: 'product_location',
    label: 'Local de estoque',
    values: optionsLocations,
    variant: 'standard',
    helperText: (
      <>
        Local onde o produto se encontra
        <br />
        Qtd. em estoque: {location_selected?.quanty || '-'} {area.product?.unit_sigla ? ' (' + area.product?.unit_sigla + ')' : ''}
      </>
    ),
    listHeight: 225,
    disabled: !area.product?.id,
    disableClearable: true,
  }
  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal')
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_local = JSON.parse(JSON.stringify(newValue))
          setLocation(aux_local)
          return {
            ...area_prev,
            product: {
              ...area_prev.product,
              location_selected: aux_local,
            },
          }
        }
        return area_prev
      }),
    }))
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1)
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }))
    }
  }

  const handleChangeQtd = (event) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_qtd = parseFloat(event.target.value || '0')
          let aux_local = JSON.parse(JSON.stringify(area_prev.product.location_selected))
          aux_local.quant_utilizada = aux_qtd
          aux_local.value = aux_qtd * (area_prev.product.stock_cost || 0)
          setLocation(aux_local)
          return {
            ...area_prev,
            product: {
              ...area_prev.product,
              location_selected: aux_local,
            },
          }
        }
        return area_prev
      }),
    }))
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1)
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }))
    }
  }

  const validQtd = (type) => {
    let valid = !area.product?.location_selected || area.product?.location_selected?.quanty >= (area.product?.location_selected?.quant_utilizada || 0)
    if (type == 'color') {
      return valid ? 'success' : 'error'
    } else {
      return (
        <>
          Qtd. a ser utilizada
          {valid ? (
            <></>
          ) : (
            <>
              <br />
              <span style={{ color: 'red' }}>Não pode ser maior do que a Quantidade em estoque</span>
            </>
          )}{' '}
        </>
      )
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <AutocompleteGeneric value={area.product} options={opt_products} handleChange={handleChangeSelect} />
        </Grid>

        <Grid item xs={12} sm={4} sx={{ mt: obj_fn.isMobile ? -2 : 0 }}>
          <AutocompleteGeneric value={location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
        <Grid item xs={6} sm={4}>
          <CurrencyInputReal
            name={'quant_stq_' + area.product?.id + '_' + location_selected?.id}
            label="Qtd. em estoque"
            variant="outlined"
            size="small"
            //value={area.product?.location_selected?.quanty || '-'}
            value={location_selected?.quanty || '-'}
            adornment="quantidade"
            endAdornment={area.product?.unit_sigla ? ' (' + area.product?.unit_sigla + ')' : ''}
            helperText={'Disponível no estoque'}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          {/* <CurrencyInputReal
            name={'quant_uti_' + area.product?.id + '_' + location_selected?.id}
            label="Quantidade utilizada"
            variant="outlined"
            //value={area.product?.location_selected?.quant_utilizada}
            value={location_selected?.quant_utilizada}
            adornment="quantidade"
            endAdornment={area.product?.unit_sigla ? ' (' + area.product?.unit_sigla + ')' : ''}
            onChange={handleChangeQtd}
            helperText={validQtd('helperText')}
            color={validQtd('color')}
            //disabled={!area.product?.location_selected?.id}
            disabled={!location_selected?.id}
          /> */}
          <GenericValueField
            handleChange={handleChangeQtd}
            options={{
              required: true,
              id: 'quant_uti_' + area.product?.id + '_' + location_selected?.id,
              name: 'quant_uti_' + area.product?.id + '_' + location_selected?.id,
              label: 'Quantidade utilizada',
              value: location_selected?.quant_utilizada,
              helperText: validQtd('helperText'),
              color: validQtd('color'),
              disabled: !location_selected?.id,
              endAdornment: true,
              prefix: area.product?.unit_sigla ? ' (' + area.product?.unit_sigla + ')' : '',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <CurrencyInputReal
            name={'prod_value_' + area.product?.id + '_' + location_selected?.id}
            label="Custo"
            variant="outlined"
            size="small"
            //value={area.product?.location_selected?.value || '-'}
            value={location_selected?.value || '-'}
            helperText={'Custo do plantio'}
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  )
}
