import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { AutoAwesomeMotion, ExpandMore, Female, Input, Male, Notes } from '@mui/icons-material';
import dayjs from "dayjs";
import FooterModal from '../../../../../components/Footer/FooterModal';
import TableProductionAnimals from './TableProductionAnimals';

export default function FormProductionStep2({ data, obj_fn }) {
  const [errorr, setError] = useState(false);

  // const para validar o button continuar
  const isError = () => {
    return errorr || false;
  };

  const returnError = () => {
    return false;
  };

  const calcQuantidade = (lote, total) => {
    let aux_total = 0;
    lote.list_animals.map((animal) => {
      if (lote.table_selected[animal.id]) {
        aux_total += animal.production;
      }
      return animal;
    });
    //return total ? aux_total : (aux_total / (Object.keys(lote.table_selected).length || 1)).toFixed(2);
    return total ? aux_total : (aux_total / (lote.num_selecteds || 1)).toFixed(2);
  }

  return ( <>
    <Grid container sx={{ mb: 4 }}>
      {/* dados gerais */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
        <Input />
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Datas e progresso da produção</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3} sx={{ mb: 0, mt: -3, ml: -5}}>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format("DD/MM/YYYY")} />
          </ListItem>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Data de término" secondary={dayjs(data.date_end ? data.date_end : data.date_prev_end).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Progresso" secondary={data.progress} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Lote(s) para a produção */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
        <AutoAwesomeMotion />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Lotes para a produção</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
        {data.lotes?.map((lote, index) => (<>
          <Accordion expanded={true} sx={{width: '100%'}}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="lote-products-content" id={"acordion_" + lote?.id + "_" + index}>
              <Typography>
                <AutoAwesomeMotion color="success" sx={{ mr: 1 }} /> Lote: {lote.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{mb: '-24px'}}>
              <Grid container spacing={4} sx={{mt: -6, mb: 1}}>
                <Grid item xs={7.5} sm={3.5}>
                  <ListItem>
                    <ListItemText primary="Produto (Medicamento)" secondary={lote?.product?.name} />
                  </ListItem>
                </Grid>
                <Grid item xs={4.5} sm={2.5} style={{paddingLeft: obj_fn.isMobile ? 1 : 4}}>
                  <ListItem>
                    <ListItemText primary="Qtd. media /Ca" secondary={calcQuantidade(lote) + (
                      lote.product?.packaging_unit === 'unidade' && lote?.product?.packaging_name !== 'Unidade' ?
                      lote.product?.packaging_sigla ? " (" + lote.product?.packaging_sigla + ") " : '' :
                      lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ") " : ''
                    )} />
                  </ListItem>
                </Grid>
                <Grid item xs={7.5} sm={3.5} style={{paddingTop: obj_fn.isMobile ? 1 : 4}}>
                  <ListItem>
                    <ListItemText primary="Local de estoque (Retirada)" secondary={lote?.product?.location_selected?.name} />
                  </ListItem>
                </Grid>
                <Grid item xs={4.5} sm={2.5} style={{paddingLeft: obj_fn.isMobile ? 1 : 4, paddingTop: obj_fn.isMobile ? 1 : 4}}>
                  <ListItem>
                    <ListItemText primary="Quantidade total" secondary={calcQuantidade(lote, true) + (
                      lote.product?.packaging_unit === 'unidade' && lote?.product?.packaging_name !== 'Unidade' ?
                      lote.product?.packaging_sigla ? " (" + lote.product?.packaging_sigla + ") " : '' :
                      lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ") " : ''
                    )} />
                  </ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={4} sx={{mt: 1, mb: 1, ml: '-15px'}} >
                <Card sx={{ display: 'flex', padding: 1, width: '100%' }} id={"card_lot_" + lote.id}>
                  {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                  <Grid item justifyContent="left" xs={3} sm={3} >
                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                      Total de animais:
                      <br />
                      {lote?.total_animals || 0}
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                      Fase de Manejo:
                      <br />
                      {lote?.fase_name || ''}
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                      Fêmeas: 
                      <br />
                      <Female color="secondary" /> {lote?.num_femeas || 0}
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                      Machos: 
                      <br />
                      <Male color="primary" /> {lote?.num_machos || 0}
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }} title={'Não identificados'}>
                      Não identif.:
                      <br />
                      {lote?.num_animals || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={9} sx={{mb: '-30px', height: '100%'}}>
                    <TableProductionAnimals data_l={lote} obj_fn={obj_fn} setError={setError} confirm={true}/>
                  </Grid>
                </Card>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>))}
      </Grid>

      {/* Descrições */}
      <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Notes sx={{mr: 1}}/>Descrição geral da produção</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} sm={12}>
          <ListItem>
            <ListItemText primary="" secondary={data.description} />
          </ListItem>
        </Grid>
      </Grid>
    </Grid >

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}