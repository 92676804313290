import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import FormattedInputs from '../../maps/components/Form/FormattedInputs';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

export default function FromProfile({ userCurrent }) {
  // Inicializar o estado com as informações do userCurrent
  const [profile, setProfile] = useState({
    firstName: userCurrent.name.split(' ')[0] || '',
    lastName: userCurrent.name.split(' ')[1] || '',
    email: userCurrent.email || '',
    celular: userCurrent.phone || '310000000',
    address: '',
    permission: userCurrent.role || '',
  });

  const handleChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('Dados enviados:', profile);
  };

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <PermContactCalendarIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Informações pessoais</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="firstName"
            name="firstName"
            label="Nome"
            fullWidth
            required
            variant="standard"
            color="success"
            value={profile.firstName}
            onChange={handleChange}
            helperText="Nome"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="lastName"
            name="lastName"
            label="Sobrenome"
            fullWidth
            required
            variant="standard"
            color="success"
            value={profile.lastName}
            onChange={handleChange}
            helperText="Sobrenome"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="email"
            name="email"
            label="Email"
            fullWidth
            required
            variant="standard"
            color="success"
            value={profile.email}
            onChange={handleChange}
            helperText="Email"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
        <LocalPostOfficeIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Complementares</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="permission"
            name="permission"
            label="Nível de Permissão"
            fullWidth
            //required
            variant="standard"
            color="success"
            value={profile.permission}
            onChange={handleChange}
            helperText="Endereço"
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="address"
            name="address"
            label="Endereço"
            fullWidth
            required
            variant="standard"
            color="success"
            value={profile.address}
            onChange={handleChange}
            helperText="Endereço"
          />
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button autoFocus onClick={onSubmit} variant="contained" color="success">
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid> */}
    </Box>
  );
}
