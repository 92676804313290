import * as React from 'react'
import Card from '@mui/material/Card'
import { CardActionArea, CardHeader, Stack, Typography, TextField, Box, Button, Grid, Checkbox } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { useState } from 'react'
import ColorPicker from './ColorPicker'

export default function MainCreation({ page, setPage }) {
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#ffffff')
  const [headerTextColor, setHeaderTextColor] = useState('#000000')
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')
  const [showButton, setShowButton] = useState(true) // Estado para controlar a visibilidade do botão

  const handleBackgroundColorChange = (color) => {
    setHeaderBackgroundColor(color)
    setPage({
      ...page,
      backgroundColorMainCreation: color, // Atualiza apenas o endereço no estado
    })
  }

  const handleTextColorChange = (color) => {
    setHeaderTextColor(color)
    setPage({
      ...page,
      textColorMainCreation: color, // Atualiza apenas o endereço no estado
    })
  }

  const handleButtonColorChange = (color) => {
    setPage({
      ...page,
      buttonColorMainCreation: color, // Atualiza apenas o endereço no estado
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setTitle(event.target.value)
    setPage({
      ...page,
      [name]: value, // Atualiza apenas o título no estado
    })
  }

  const handleCheckboxChange = () => {
    setShowButton(!showButton) // Alterna entre true e false
    setPage({
      ...page,
      showButton: !showButton, // Atualiza apenas o endereço no estado
    })
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Insira um título e uma descrição atraente para a sua oferta
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Defina se deseja inserir um valor para sua oferta
        </Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 0.8 }} color="text.secondary">
            Cor do Texto:{''}
          </Typography>
          <ColorPicker value={headerTextColor} onChange={handleTextColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Cor do Fundo:
          </Typography>
          <ColorPicker value={headerBackgroundColor} onChange={handleBackgroundColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 0.5 }} color="text.secondary">
            Cor do botão:
          </Typography>
          <ColorPicker value={page.buttonColorMainCreation} onChange={handleButtonColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 0.5, mt: 1.5 }} color="text.secondary">
            Mostrar botão:
          </Typography>
          <Checkbox color="success" checked={showButton} onChange={handleCheckboxChange} />
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={6} sx={{ padding: 0 }}>
          <Grid container spacing={2} sx={{ marginTop: 0 }}>
            <Grid item xs={12} md={12} sx={{ padding: 0 }}>
              <TextField
                sx={{ ml: 2 }}
                id="outlined-basic"
                variant="standard"
                name="titleCreation"
                type="text"
                size="small"
                color="success"
                value={page.titleCreation}
                onChange={handleChange} // Chamada da função corrigida para o título
                //inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700 }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: 0 }}>
              <TextField
                sx={{ ml: 2, width: 400 }}
                id="outlined-basic"
                variant="standard"
                name="descriptionCreation"
                type="text"
                size="small"
                color="success"
                value={page.descriptionCreation}
                onChange={handleChange} // Chamada da função corrigida para o título
                //inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700 }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: 0 }}>
              <TextField
                sx={{ ml: 2 }}
                id="outlined-basic"
                variant="standard"
                name="amountCreation"
                type="text"
                size="small"
                color="success"
                value={page.amountCreation}
                onChange={handleChange} // Chamada da função corrigida para o título
                //inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700 }}
              />
            </Grid>
            {showButton && (
              <Grid item xs={12} md={12} sx={{ padding: 0 }}>
                <TextField
                  sx={{ ml: 2 }}
                  id="outlined-basic"
                  variant="standard"
                  name="buttonCreation"
                  type="text"
                  size="small"
                  color="success"
                  value={page.buttonCreation}
                  onChange={handleChange}
                  style={{ fontSize: '3rem', fontWeight: 700 }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} sx={{ padding: 0 }}>
              <TextField
                sx={{ ml: 2, width: 400 }}
                id="outlined-basic"
                variant="standard"
                name="paymentTextCreation"
                type="text"
                size="small"
                color="success"
                value={page.paymentTextCreation}
                onChange={handleChange} // Chamada da função corrigida para o título
                //inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700 }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: 0 }}>
              <TextField
                sx={{ ml: 2, width: 400 }}
                id="outlined-basic"
                variant="standard"
                name="paymentCreation"
                type="text"
                size="small"
                color="success"
                value={page.paymentCreation}
                onChange={handleChange} // Chamada da função corrigida para o título
                // inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: 1, background: page.backgroundColorMainCreation, paddingBottom: '5px' }}>
          <Typography sx={{ color: page.textColorMainCreation }} variant="h5">
            {page.titleCreation}{' '}
          </Typography>
          <Typography sx={{ mt: 2, color: page.textColorMainCreation }} variant="body1">
            {page.descriptionCreation}
          </Typography>
          <Typography sx={{ color: page.textColorMainCreation }} variant="body1">
            Valor: R$ {page.amountCreation}
          </Typography>
          {showButton && (
            <Button variant="contained" color="success" sx={{ bgcolor: page.buttonColorMainCreation, color: 'white', mt: 2 }}>
              {page.buttonCreation}
            </Button>
          )}
          <Typography variant="body2" sx={{ mt: 2, color: page.textColorMainCreation }}>
            {page.paymentTextCreation}
          </Typography>
          <Button disabled variant="outlined" sx={{ mt: 1, color: page.textColorMainCreation }}>
            {page.paymentCreation}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
