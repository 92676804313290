import React, { useState } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Stack, Button } from '@mui/material';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Header from './Header';
import FormIndividualMarketingFooter from './FormIndividualMarketingFooter';
import ModalLandpage from './ModalLandpage';
import MainCreation from './MainCreation';
import DescriptionCreation from './DescriptionCreation';
import ColorPicker from './ColorPicker';
import FormImageMarketing from './FormImageMarketing';
import FormMarketingHeader from './FormMarketingHeader';

export default function FormIndividualMarketingtStep2({ data, obj_fn, page, setPage, handleRestoreDefault }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // Valida o button continuar
  const isError = () => {
    return false;
  };
  // Valida o button retornar
  const returnError = () => {
    return false;
  };
  function formatData(Inputdata) {
    if (!Inputdata) {
      return '';
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, ['new_weight']: event }));
  // };

  //const img_pesagem = '/dist/image/pesagem_bovino.png'

  const [backgroundPageColor, setBackgroundPageColor] = useState('#ffffff');

  //handleBackgroundPageColorChange
  const handleBackgroundPageColorChange = (color) => {
    setBackgroundPageColor(color);
    setPage({
      ...page,
      backgroundPageColor: color, // Atualiza apenas o endereço no estado
    });
  };

  const text_title = 'Vamos começar?';
  const txt_msn = 'Crie seu marketing logo abaixo.';

  return (
    <>
      <FormMarketingHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} height={'60px'} />
      <Grid container>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12} md={12} sx={{}}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6} sx={{}}>
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5} sx={{ mt: -0.5 }}>
                    <Typography variant="h6" fontWeight={500}>
                      <RequestPageIcon color="success" sx={{ mr: 1, width: '32px', height: '32px' }} /> Marketing online: Card
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{}}>
                <div>
                  <Button color="success" variant="outlined" onClick={handleRestoreDefault}>
                    Restaurar Design Inicial
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
              <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <ModalLandpage page={page} />
              </Grid>
            </Grid> */}

            {/* Primeiro bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* Bloco esquerda */}
              <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
                <InsertPhotoIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Cabeçalho</Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
                  <Header page={page} setPage={setPage} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
            </Grid>

            {/* Segundo bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ mt: 1, mr: 2 }} fontWeight={700}>
                  Veja como está ficando:{' '}
                </Typography>
                <ModalLandpage page={page} />
              </Grid>
              {/* Bloco esquerda */}

              <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
                <InsertPhotoIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Imagem da criação</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
                  <FormImageMarketing page={page} setPage={setPage} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
            </Grid>

            {/* terceiro bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ mt: 1, mr: 2 }} fontWeight={700}>
                  Veja como está ficando:{' '}
                </Typography>
                <ModalLandpage page={page} />
              </Grid>
              {/* Bloco esquerda */}
              <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
                <InsertPhotoIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Descrição da oferta</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
                  <MainCreation page={page} setPage={setPage} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
            </Grid>

            {/* quarto bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ mt: 1, mr: 2 }} fontWeight={700}>
                  Veja como está ficando:{' '}
                </Typography>
                <ModalLandpage page={page} />
              </Grid>
              {/* Bloco esquerda */}
              <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
                <InsertPhotoIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Descrições adicionais</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
                  <DescriptionCreation page={page} setPage={setPage} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
            </Grid>

            {/* quinto bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ mt: 1, mr: 2 }} fontWeight={700}>
                  Veja como está ficando:{' '}
                </Typography>
                <ModalLandpage page={page} />
              </Grid>
              {/* Bloco esquerda */}
              <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
                <InsertPhotoIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Definir a cor de fundo da página</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body2" sx={{ mr: 0.8 }} color="text.secondary">
                        Cor de fundo da landpage
                      </Typography>
                      <ColorPicker value={backgroundPageColor} onChange={handleBackgroundPageColorChange} />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
            </Grid>
            {/* fim do quinto bloco */}
          </Grid>
        </Grid>
      </Grid>

      <FormIndividualMarketingFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
