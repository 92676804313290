import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import useStore from "../../store/useStore";
import { useContext } from "react";
import { Context } from "../../components/Context/AuthContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterCostCenterDialogModalCategory({ conta, categories, categoryNameDefault }) {
  const [open, setOpen] = useState(false);
  // console.log(categoryNameDefault);
  const [categoryName, setCategoryName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(categoryNameDefault);



  const usestore = useStore();
  const reposAccount = usestore.reposAccount
  var farm_id = localStorage.getItem("Property");
  const { aux_add, setAuxAdd } = useContext(Context);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handleSave");
    console.log(categoryName, selectedGroup);


    const categoryGroup = reposAccount.getAccountStore("name", selectedGroup)
    console.log(categoryGroup)
    const coutnHierarchy = categoryGroup[0].hierarchy.split('.').length
    console.log(coutnHierarchy)


    const newSubcategory = {
      id: Date.now(),
      id_property: 1,
      name: categoryName,
      nature: conta == "expense" ? "CREDORA" : "DEVEDORA",
      mother: selectedGroup,
      //mother: "receita teste03",
      info: JSON.stringify(["centro_custo", "", `subcategory${coutnHierarchy - 1}`]),
      //infoParse: `[\"centro_custo\",\"\",\"subcategory1${coutnHierarchy - 1}"]`,
      subcategories: [],
    };

    console.log(newSubcategory)
    /* function countNumbersInHierarchy(obj) {
      const hierarchy = obj.hierarchy;
      let count = 0;
  
      for (let i = 0; i < hierarchy.length; i++) {
          if (!isNaN(hierarchy[i]) && hierarchy[i] !== ' ') {
              count++;
          }
      }
  
      return count;
  }
 
  console.log(countNumbersInHierarchy(categoryGroup));  */

    // let newSubcategory = {}

    // if (categoryName && selectedGroup) {
    //   setCategories((prevCategories) => {
    //     console.log(selectedGroup)
    //     return prevCategories.map((category) => {
    //       if (category.name === selectedGroup) {
    //         newSubcategory = {
    //           id: Date.now(),
    //           id_property: 1,
    //           name: categoryName,
    //           nature: "DEVEDORA",
    //           mother: selectedGroup,
    //           //mother: "receita teste03",
    //           info: JSON.stringify(["centro_custo", "", `subcategory${coutnHierarchy - 1}`]),
    //           infoParse: `[\"centro_custo\",\"\",\"subcategory1${coutnHierarchy - 1}"]`,
    //           subcategories: [],
    //         };
    //         category.subcategories.push(newSubcategory);
    //       }
    //       return category;
    //     });
    //   });
    // };


    if (true) {
      const header = {
        header: {
          "Content-Type": "application/json",
        },
      };
      const res = await api.post("/finances/account", newSubcategory, header).then((response) => {
        console.log(response)
        console.log(response.data.isSuccess)
        if (response.data.isSuccess) {
          //setIsLoading(true);
          // console.log(isLoading)
          // setIsLoading(true)
          toast.success("Categoria criada com sucesso");

          reposAccount.fetch({ 'id_farm': farm_id }).then((res) => {
            setAuxAdd(aux_add + 1);
            setCategoryName("")
          });
          //   // setTimeout(() => {
          //   //   handleClose();
          //   // }, 2000);
          // }
        } else {
          toast.error(response.data);

          //return false;
        }
      }).catch((error) => {
        console.log("error");
        //console.log(error);
        toast.error(error.response.data);
      });


      //const response = await api.post("/finances/account", newSubcategory, header);

      // console.log(response);
      // console.log(response.data.isSuccess);
      //console.log("response");

      // Após salvar, você pode fechar o diálogo.
      setOpen(false);
      // const isValid = false;
      // if (isValid) {
      //   handleClose();
      // }
      //setOpen(false);
      //setCategoryName("");
      //setSelectedGroup("");
    };
  }

  // console.log("categories")
  // console.log(categories)

  // categories?.filter(item => item.nature == (conta == "expense" ? "CREDORA" : "DEVEDORA"))

  // console.log("categories")
  // console.log(categories)

  // Função recursiva para gerar opções hierárquicas
  const generateCategoryOptions = (categoryList, level = 0) => {
    const options = [];

    console.log("categoryList")
    console.log(categoryList)

    const newCategoryList = categoryList.filter(item => item.nature === (conta === "expense" ? "CREDORA" : "DEVEDORA"))

    newCategoryList.forEach((category) => {
      const style = level === 0 ? { color: 'gray', textTransform: 'uppercase' } : {};
      options.push(
        <MenuItem key={category.id} value={category.name} style={style}>
          {`${"\u00A0".repeat(level * 2)}${category.name}`}
        </MenuItem>
      );

      if (category.subcategories.length > 0) {
        options.push(...generateCategoryOptions(category.subcategories, level + 1));
      }
    });

    return options;
  };



  return (
    <div>
      {categoryNameDefault == "" ? (
        <Button variant="outlined" color={conta == "expense" ? "error" : "success"} onClick={handleClickOpen}>
          Categoria
        </Button>
      ) : (
        <IconButton variant="outlined" color="success" onClick={handleClickOpen}>
          <AddBoxIcon />
        </IconButton>
      )}

      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        {/* <DialogTitle>Cadastrar Categoria</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h6" component="div">
                Categoria
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: -2, mb: 2, borderColor: "green" }} />
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom component="div">
                Defina uma nova categoria (centro de custo) para uma gestão precisa das suas transações financeiras.
              </Typography>
            </Grid>
          </Grid>
          <TextField color="success" label="Nome da Categoria" variant="outlined" fullWidth value={categoryName} onChange={handleCategoryNameChange} />
          <>
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom component="div">
                  Aparecer dentro de:
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth variant="outlined" style={{ marginTop: "16px" }}>
              <InputLabel>Grupo/Categoria</InputLabel>
              {categoryNameDefault == "" ? (
                <Select value={selectedGroup} onChange={handleGroupChange} label="Grupo/Categoria">
                  {generateCategoryOptions(categories)}
                </Select>
              ) : (
                <Select disabled value={selectedGroup} onChange={handleGroupChange} label="Grupo/Categoria">
                  {generateCategoryOptions(categories)}
                </Select>
              )}
            </FormControl>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={handleSave} variant="contained" color="success">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
