import React, { useState } from 'react';
import { Grid, Divider } from '@mui/material';
import dayjs from "dayjs";

import FormPaymentInstallments from '../../Forms/FormPaymentInstallments';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormPaymentCondition from '../../Forms/FormPaymentCondition';
import FormPaymentForecast from '../../Forms/FormPaymentForecast';

/*
  CONDIÇÕES PAGAMENTO
    Data de Vencimento === "Data lançamento + "intervalo" (dias)
    Conta de Pagamento: Vem marcado com o banco "standard"

  PREVISÃO DE PAGAMENTO:
    Juros, Multa e Desconto: devem ser um input editaval. Quando alterados, mudam o valor final

  PARCELAS: 
    Data de cada parcela deve ser um incremental de: "Vencimento" + "intervalo" + "num parcela"
    Ao alterar o valor da parcela 1, o valor restanto deve ser rateado para as outras parcelas
*/
export default function FormBuySalesStep5({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  const isError = () => {
    if (!data.value || data.error_date || error) {
      return true;
    }
    return false;
  };
  const returnError = () => {
    return false;
  };

  const handleChange = (event, blur) => {
    if (blur === 'checkbox') {
      obj_fn.setData(prevState => ({ ...prevState, payed: event.target.checked }));
    } else {
      var field = event.target.name;
      var value = event.target.value;
      obj_fn.setData(prevState => ({ ...prevState, [field]: value }));
      if (field === 'fee' || field === 'penalty' || field === 'discount') {
        var aux_val = parseFloat(data.value);
        // porcentagens do valor total
        var fee = aux_val * parseFloat(field === 'fee' ? value : data.fee) / 100
        var penalty = aux_val * parseFloat(field === 'penalty' ? value : data.penalty) / 100
        var discount = aux_val * parseFloat(field === 'discount' ? value : data.discount) / 100

        var value_aux = aux_val + fee + penalty - discount;
        obj_fn.setData(prevState => ({ ...prevState, value_end: value_aux }));
      }
    }
  };

  // Monta o obj e função para o campo de data de vencimento
  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({ ...prevState, dueDate: dayjs(value) }));
    obj_fn.setData((prevState) => ({ ...prevState, dueDate_f: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, dueDate_fc: dayjs(value).format("DD/MM/YYYY") }));
  };
  let obj_date = {
    label: 'Vencimento',
    field_data: 'dueDate',
    //text_helper: '',
    min: data.date_release,
    handleChange: handleChangeDate,
    maxWidth: '250px',
  }

  return ( <>
    <Grid container sx={{ mb: 4 }}>
      {/* Condições pagamento */}
      <Grid container spacing={3} sx={{ mt: 0, mb: 1 }}>
        <FormPaymentCondition data={data} handleChange={handleChange} obj_date={obj_date}/>
      </Grid>

      {/* Previsão de pagamento */}
      <Grid container spacing={3} sx={{ mt: 0, mb: 1 }}>
        <FormPaymentForecast data={data} handleChange={handleChange}/>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 3, mt: 3 }}>
        <Divider sx={{ mt: 2 }} />
      </Grid>
      {/* Parcelas do pagamento */}
      {data.installment !== "a_vista" &&
        <Grid container spacing={3} sx={{ mt: 0, mb: 1 }}>
          <FormPaymentInstallments data={data} obj_fn={obj_fn} />
        </Grid>
      }

      <FooterModal
        data={data}
        obj_fn={obj_fn}
        isError={isError}
        returnError={returnError}
      />
    </Grid >
  </> );
}