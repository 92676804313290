import React, { useState } from 'react';
import { Box, Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import { Notes } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormApportChoice from '../../Forms/FormApportChoice';

export default function FormBuySalesStep3({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  const handleChange = (event) => {
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    if (event.target.name === 'value') {
      obj_fn.setData(prevState => ({ ...prevState, value_end: event.target.value }));
    }
  };
  // const para validar o button continuar
  const isError = () => {
    let iserror = false

    if (!data.products.length && !data.lotes.length && !data.animals.length) {
      iserror = true
    } else if (!data.value || data.error_date || error) {
      iserror = true
    }
    return iserror;
  };

  const returnError = () => {
    return false;
  };

  return ( <>
    <Grid container sx={{ mb: 4 }}>
      <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ mt: -5 }}>
        <Typography style={{ color: green[800] }}>
          <Typewriter tam={0} minHeight={0} text={`Rateie o valor para Criações e/ou Safras.`} />
        </Typography>
      </Grid>

      {/* Rateio de custos */}
      <FormApportChoice data={data} obj_fn={obj_fn} />

      {/* Descrição */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 3 }}>
        <Notes />
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700} style={{color: green[800]}}> Descrição geral do Lançamento</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
        <Grid item xs={12} sm={12} >
          <TextareaAutosize
            minRows={3} // Customize the initial number of rows
            maxRows={10} // Customize the maximum number of rows
            id="description"
            name="description"
            placeholder="Descreva sobre do que se trata o Lançamento"
            value={data.description}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            style={{ width: '100%', padding: '8px' }}
          />
        </Grid>
      </Grid>

      <FooterModal
        data={data}
        obj_fn={obj_fn}
        isError={isError}
        returnError={returnError}
      />
    </Grid >
  </> );
}


