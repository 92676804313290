import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useLivestockTargetReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPlanning: [],
        planningRepos: { label: "", id: 0, id_property: 0, tipo: "Planning", name: "" },
        fetch: async (item) => {
          var farm_id = localStorage.getItem("Property") || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.get("/planning/targetLivestockList");
          const data = await response.data;
          await set({
            listPlanning: data._value.map((planning) => ({
              id: planning.id,
              id_user: planning.id_user,
              id_property: planning.id_property,
              id_livestock: planning.id_livestock,
              tipo: planning.tipo,
              id_group: planning.id_group,
              name: planning.name,
              typePlanning: planning.typePlanning,
              date_start: dayjs(planning.date_start).format("YYYY-MM-DD HH:mm:ss"),
              date_prev_end: dayjs(planning.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
              expectedYield: planning.expectedYield || "",
              matriz: planning.matriz,
              bezerros: planning.bezerros,
              birthRate: planning.birthRate,
              births: planning.births,
              unitWeight: planning.unitWeight,
              unit: planning.unit,
              unitTime: planning.unitTime,
              animalInput: planning.animalInput,
              animalInputunitWeight: planning.animalInputunitWeight,
              animalInputUnit: planning.animalInputUnit,
              cost: planning.cost,
              valueCost: planning.valueCost,
              unitCost: planning.unitCost,
              area: planning.area,
              measure: planning.measure,
              observation: planning.observation,
              isDeleted: planning.isDeleted,
              isActiveTargetLivestock: planning.isActiveTargetLivestock,
              tipo_repos: "Planning",
            })),
          });
          set({
            planningRepos:
              !data || data._value.length == 0
                ? { label: "", id: 0, id_property: 0, tipo_repos: "Planning", name: "" }
                : { label: data._value[0].name, id: data._value[0].id, id_property: data._value[0].id_property, tipo_repos: "Planning", name: data._value[0].name },
          });
          return data;
        },
        changePlanningReposRepos: (planningRepos) => set((state) => ({ planningRepos })),
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getPlanningStore: (key, val) => {
          return get().listPlanning.filter((planning) => {
            if (planning[key] == val) {
              return planning;
            }
          });
        },
        // Adiciona uma nova livestock à listLivestock
        addPlanningStore: (planning) => {
          set((state) => ({
            listPlanning: [...state.listPlanning, planning],
          }));
        },
        // Gera uma nova listLivestock sem a livestock informada
        delPlanningStore: (id) => {
          set({
            listPlanning: get().listPlanning.filter((planning) => {
              if (planning.id != id) {
                return planning;
              }
            }),
          });
        },
        // atualiza listLivestock de acordo com id, key e val informados
        updatePlanningStore: (id, key, val) => {
          set({
            listPlanning: get().listPlanning.map((planning) => {
              if (planning.id === id) {
                if (key == "all") {
                  // atualiza todos os dados
                  planning = val;
                } else {
                  planning[key] = val;
                }
              }
              return planning;
            }),
          });
          return get().listPlanning;
        },
      }),
      { name: "reposLivestockTarget" }
    )
  )
);

export default useLivestockTargetReposStore;
