import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

// create the store
const useActivityMarketingLivestockReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityMarketing: [],
        asyncList: [],
        loading: true,
        //ActivityMarketingRepos: { label: "", id: 0, id_property: 0, tipo: 'ENTRADA DE ANIMAIS' },
        fetch: async (item) => {
          // Pego o id current da proriedade
          console.log('fetch:useActivityMarketingReposStore  ');
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          // lista as atividade
          const response = await api.post('activityMarketingLivestock/list', item, header);
          const data = await response.data;
          console.log('activityMarketingLivestock');
          console.log(data);
          await set({
            listActivityMarketing: data._value.map((activityMarketing) => ({
              id: activityMarketing.id,
              id_user: activityMarketing.id_user,
              id_property: activityMarketing.id_property,
              id_livestock: activityMarketing.id_livestock,
              name: activityMarketing.name || '',
              characteristics: JSON.parse(activityMarketing.characteristics) || '',
              number: activityMarketing.number || '',
              newMessageWhatsapp: activityMarketing.newMessageWhatsapp || '',
              title: activityMarketing.title || '',
              address: activityMarketing.address || '',
              image: activityMarketing.image || '',
              file: activityMarketing.file || '',
              city: activityMarketing.city || '',
              state: activityMarketing.state || '',
              backgroundColorHeader: activityMarketing.backgroundColorHeader || '',
              textColorHeader: activityMarketing.textColorHeader || '',
              titleCreation: activityMarketing.titleCreation || '',
              descriptionCreation: activityMarketing.descriptionCreation || '',
              amountCreation: activityMarketing.amountCreation || '',
              buttonCreation: activityMarketing.buttonCreation || '',
              showButton: activityMarketing.showButton,
              paymentTextCreation: activityMarketing.paymentTextCreation || '',
              paymentCreation: activityMarketing.paymentCreation || '',
              textColorMainCreation: activityMarketing.textColorMainCreation || '',
              backgroundColorMainCreation: activityMarketing.backgroundColorMainCreation || '',
              backgroundColorTableDesciptionCreation: activityMarketing.backgroundColorTableDesciptionCreation || '',
              buttonColorMainCreation: activityMarketing.buttonColorMainCreation || '',
              titleTable: activityMarketing.titleTable || '',
              textColorTableDescriptionCreation: activityMarketing.textColorTableDescriptionCreation || '',
              backgroundColorTableDescriptionCreation: activityMarketing.backgroundColorTableDescriptionCreation || '',
              backgroundPageColor: activityMarketing.backgroundPageColor || '',
              description: activityMarketing.description || '',
              date_start: dayjs(activityMarketing.date_start).format('YYYY-MM-DD HH:mm:ss'),
              date_prev_end: dayjs(activityMarketing.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
              date_end: dayjs(activityMarketing.date_end).format('YYYY-MM-DD HH:mm:ss'),
              date_update: dayjs(activityMarketing.date_update).format('YYYY-MM-DD HH:mm:ss'),
              isDeleted: activityMarketing.isDeleted,
              progress: activityMarketing.progress,
              planning: activityMarketing.planning,
              tipo_repos: 'MARKETING',
              activity: 'MARKETING',
              type: 'activity',
            })),
          });

          // const lista = JSON.parse(localStorage.getItem('listActvs'))
          // const lista_i = get().listActivityMarketing

          // let array_aux = lista.filter((activity) => {
          //   if (activity.activity != 'MARKETING') {
          //     return activity
          //   }
          // })
          // const res = array_aux.concat(lista_i)
          // localStorage.setItem('listActvs', JSON.stringify(res))

          return data;
        },
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getActivityMarketingStore: (key, val) => {
          return get().listActivityMarketing.filter((activityMarketing) => {
            if (activityMarketing[key] == val) {
              return activityMarketing;
            }
          });
        },
        // // Adiciona uma nova livestock à listLivestock
        addActivityMarketingStore: (livestock) => {
          set((state) => ({
            listLivestock: [...state.listLivestock, livestock],
          }));
        },
      }),

      { name: 'reposMarketingLivestock' }
    )
  )
);

export default useActivityMarketingLivestockReposStore;
