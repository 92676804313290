import * as React from 'react';
import { Box, Container, Grid, Typography, CardContent, Card, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ChatSidebar from './ChatSidebar/ChatSidebar';
import Extract from './Extract';
// import ChartLineVFinancialDay from "../../../components/Charts/ChartLineVFinancialDay"
// import ChartBarVFinancial from "../../../components/Charts/ChartBarVFinancial"

export default function FinancialAccounts({ obj_fn, data, year }) {
  const dados_finance = obj_fn.usestore.reposFinances.financesRepos;

  return (
    <Box sx={{ display: 'flex' }}>
      <ChatSidebar />
      <Container maxWidth="" sx={{ mb: 3 }}>
        {/* Cards Receitas */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Efetivados no ano" id="financeiro_geral">
                <Typography variant="h5" fontWeight="bold">
                  Efetivados no ano de {year}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {/* Card de Receitas */}
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: '100%', backgroundColor: 'green.50', borderLeft: 4, borderColor: 'success.main' }}>
                      <CardContent>
                        <Typography variant="h6" color="success.dark" fontWeight="bold">
                          Receitas
                        </Typography>
                        <Typography variant="h4" color="success.main" fontWeight="bold">
                          {obj_fn.formatValue(dados_finance.entradas, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Card de Despesas */}
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: '100%', backgroundColor: 'red.50', borderLeft: 4, borderColor: 'error.main' }}>
                      <CardContent>
                        <Typography variant="h6" color="error.dark" fontWeight="bold">
                          Despesas
                        </Typography>
                        <Typography variant="h4" color="error.main" fontWeight="bold">
                          {obj_fn.formatValue(dados_finance.saidas, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Card de Saldo */}
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: '100%', backgroundColor: 'blue.50', borderLeft: 4, borderColor: 'primary.main' }}>
                      <CardContent>
                        <Typography variant="h6" color="primary.dark" fontWeight="bold">
                          Saldo
                        </Typography>
                        <Typography variant="h4" color="primary.main" fontWeight="bold">
                          {obj_fn.formatValue(dados_finance.saldo, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="A Receber" id="tag_recebimentos">
                <Typography>A Receber</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Hoje">
                      <CardContent>
                        <Typography variant="subtitle1">Valor a receber hoje</Typography>
                        <Typography variant="h5" color="success.main">
                          {obj_fn.formatValue(dados_finance.receber_hoje, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Este Mês">
                      <CardContent>
                        <Typography variant="subtitle1">Valor a receber este mês</Typography>
                        <Typography variant="h5" color="success.main">
                          {obj_fn.formatValue(dados_finance.receber_mes, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Em atraso">
                      <CardContent>
                        <Typography variant="subtitle1">Valor a receber em atraso</Typography>
                        <Typography variant="h5" color="primary">
                          {obj_fn.formatValue(dados_finance.receber_atrasado, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Futuros">
                      <CardContent>
                        <Typography variant="subtitle1">Valor a receber futuros</Typography>
                        <Typography variant="h5" color="secondary.dark">
                          {obj_fn.formatValue(dados_finance.receber_futuro, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Cards Despesas */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="A pagar" id="tag_pagamentos">
                <Typography>A Pagar</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Hoje">
                      <CardContent>
                        <Typography variant="subtitle1">Contas a pagar hoje</Typography>
                        <Typography variant="h5" color="error.main">
                          {obj_fn.formatValue(dados_finance.pagar_hoje, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Este Mês">
                      <CardContent>
                        <Typography variant="subtitle1">Contas a pagar este mês</Typography>
                        <Typography variant="h5" color="error.main">
                          {obj_fn.formatValue(dados_finance.pagar_mes, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Em atraso">
                      <CardContent>
                        <Typography variant="subtitle1">Contas a pagar em atraso</Typography>
                        <Typography variant="h5" color="primary">
                          {obj_fn.formatValue(dados_finance.pagar_atrasado, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: '100%' }} aria-controls="Futuros">
                      <CardContent>
                        <Typography variant="subtitle1">Contas a pagar futuros</Typography>
                        <Typography variant="h5" color="secondary.dark">
                          {obj_fn.formatValue(dados_finance.pagar_futuro, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Efetivados no ano" id="financeiro_geral">
                <Typography>Efetivados no mês ({data})</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Extract obj_fn={obj_fn} iconsBank={true} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Recent Orders */}
        {/* <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='Fluxo de Caixa' id='fluxo_caixa_p' >
                <TimelineIcon sx={{ mr: 1 }} />
                <Typography>Fluxo de Caixa</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ChartLineVFinancialDay financas={financas} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='Gráfico de Vendas' id='grafico_vendas_p' >
                <BarChart sx={{ mr: 1 }} />
                <Typography>
                  Gráfico de Vendas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ChartBarVFinancial financas={financas} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
}
