import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { H6, Span } from '../../../../components/Typography';
import { cyan, green, red } from '@mui/material/colors';
import CardProfileModel from '../CardProfileModel';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Alert, Grid } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Fade from '@mui/material/Fade';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EditModalFormTeamDelete({ editUser, handleChangeDelete, setLoading }) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    console.log(editUser)

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleCloseCancel = () => {
        console.log("cancelado")
        setOpen(false);

    };

    const handleDelete = (e) => {
        e.preventDefault()
        setIsLoading(false)
        setTimeout(() => {
            setOpen(false);
            handleChangeDelete(e)
        }, 1000);
        setTimeout(() => {
            setIsLoading(true)
        }, 1500);
    };

    return (
        <div>
            {/* <Button variant="outlined" >
                Open dialog
            </Button> */}
            <Button color="error" variant="outlined" type="submit" onClick={handleClickOpen} >
                <DeleteForeverIcon /> <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="button" component="div"> Excluir Usuário</Typography>
            </Button>
            <BootstrapDialog
                onClose={handleCloseCancel}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{ backgroundColor: red[500] }}
            >
                <BootstrapDialogTitle sx={{ color: '#fff', backgroundColor: red[800] }} id="customized-dialog-title" onClose={handleCloseCancel} color="success">
                    Excluindo da equipe
                </BootstrapDialogTitle>

                <DialogContent dividers>

                    {isLoading ?
                        <>
                            <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                                <Alert variant="filled" severity="error">
                                    O integrante da equipe será excluído do software
                                </Alert>
                                <Typography sx={{ mt: 2 }} gutterBottom>
                                    Tem certeza que deseja excluir o <strong>{editUser.name.toUpperCase()}</strong> do software?
                                </Typography>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                                <Grid container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                //style={{ minHeight: '100vh' }}
                                >

                                    <PersonOffIcon style={{ fontSize: 80 }} />
                                    <p>Usuário excluído!!!</p>

                                </Grid>

                            </Grid>
                        </>

                    }

                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleCloseCancel} style={{ color: "#8a8a8a" }} >
                        Cancelar
                    </Button>
                    <Button autoFocus onClick={handleDelete} variant="contained" color="error">
                        Excluir usuário
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
}