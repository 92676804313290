import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconSeedTreatment = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 258.000000 299.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,299.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M1229 2862 c-33 -15 -641 -493 -635 -499 7 -8 161 -34 286 -49 102
-12 221 -17 400 -18 293 0 420 11 645 55 l56 11 -105 81 c-210 165 -399 313
-431 337 -18 14 -48 37 -66 51 -29 23 -89 50 -108 49 -3 0 -22 -8 -42 -18z"
          />
          <path
            d="M591 2545 c-127 -28 -221 -66 -221 -89 0 -8 26 -26 57 -41 62 -29 74
-27 124 19 13 12 52 43 87 69 34 27 62 50 62 53 0 7 -43 3 -109 -11z"
          />
          <path
            d="M1871 2547 c8 -7 59 -45 112 -86 l96 -73 43 17 c97 37 100 62 11 95
-65 24 -215 60 -253 59 -22 0 -23 -1 -9 -12z"
          />
          <path
            d="M337 2362 c-3 -4 -25 -70 -50 -147 -38 -116 -44 -147 -38 -180 11
-59 35 -98 86 -143 54 -48 119 -71 290 -107 246 -50 350 -60 660 -60 338 0
484 16 750 81 124 30 174 54 220 106 45 49 65 95 65 148 0 31 -42 178 -85 298
-3 9 -15 7 -47 -9 -174 -89 -545 -135 -1007 -126 -370 7 -649 50 -789 121 -28
14 -53 22 -55 18z"
          />
          <path
            d="M346 1763 c-12 -39 -56 -295 -70 -408 -51 -405 -44 -674 24 -846 27
-68 21 -100 -31 -156 -50 -54 -49 -82 1 -99 19 -7 145 -17 280 -23 135 -6 351
-16 481 -22 236 -11 375 -8 984 22 257 12 262 13 289 32 26 19 15 57 -29 98
-47 45 -51 74 -22 156 43 121 52 193 51 413 -1 203 -21 407 -59 620 -8 47 -20
116 -27 153 -10 62 -13 68 -32 63 -454 -130 -954 -160 -1416 -85 -162 26 -348
67 -382 85 -34 18 -36 18 -42 -3z m1364 -458 c0 -75 -55 -125 -135 -125 -37 0
-38 1 -32 33 14 84 58 127 130 127 36 0 37 -1 37 -35z m-225 -21 c19 -46 19
-87 0 -124 -13 -26 -64 -70 -80 -70 -11 0 -35 68 -35 100 0 43 25 87 63 110
18 11 33 20 34 20 2 0 10 -16 18 -36z m-161 -102 c23 -44 29 -97 16 -136 -11
-35 -82 -106 -106 -106 -6 0 -19 18 -28 40 -33 84 -18 154 46 204 42 33 54 33
72 -2z m323 -69 c37 -17 35 -30 -12 -73 -22 -21 -37 -25 -84 -25 -38 0 -66 6
-84 18 l-28 18 23 28 c48 60 107 71 185 34z m-514 -55 c23 -51 30 -78 29 -125
-1 -54 -19 -87 -76 -142 -54 -53 -64 -49 -96 35 -26 70 -25 117 1 163 21 36
94 101 115 101 7 0 18 -15 27 -32z m378 -83 c46 -25 47 -42 2 -87 -50 -49
-108 -60 -177 -34 -67 26 -73 38 -37 79 60 67 134 82 212 42z m-180 -160 c24
-9 55 -24 68 -34 l25 -19 -28 -33 c-84 -101 -177 -115 -294 -44 -17 10 -32 23
-32 27 0 17 64 78 103 97 50 25 97 27 158 6z"
          />
        </g>
      </SvgIcon>
    </>
  );
};
