import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function CardInfoCentral({ data_cards }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Total de Criações
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                  {data_cards.property.total_livestocks || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Total de Animais
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                  {data_cards.property.total_animais || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Total de Safras
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                  {data_cards.property.total_safras || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Total de culturas
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                  {data_cards.property.total_cultivation || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton sx={{ ml: "auto" }}>
          <NavLink to="/livestock" className="nav-link">
            <Typography variant="body2" color="text.secondary" component="div">
              Ver todas as criações ...
            </Typography>
          </NavLink>
        </IconButton>
      </CardActions> */}
    </Card>
  );
}
