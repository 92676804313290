export default function extractValueEstimate(response) {
  console.log("extractValueEstimate");
  console.log(response);

  const extractValueEstimate = (response) => {
    console.log(response);

    const textResponse = response.trim();

    // Supondo que a resposta esteja no formato: "Valor estimado: O lucro estimado da safra seria de R$ 120.000,00."
    const padraoRegex = /R\$\s?([\d.,]+)/;
    const resultRegex = padraoRegex.exec(textResponse);
    console.log(resultRegex);
    if (resultRegex && resultRegex.length >= 2) {
      const valueString = resultRegex[1].replace(/[.,]/g, ''); // Remover vírgulas e pontos
      const valueNumber = parseFloat(valueString);
      console.log(valueNumber)
      return valueNumber; // O valor estimado convertido para um número
    }

    return null; // Retornar null se não for possível extrair o valor
  };

  const valueEstimate = extractValueEstimate(response);
  console.log(valueEstimate);
  return valueEstimate;
}
