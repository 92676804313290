import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { Context } from '../../../../../components/Context/AuthContext';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import FormBuySalesStep1 from './FormBuySalesStep1';
import FormBuySalesStep2 from './FormBuySalesStep2';
import FormBuySalesStep3 from './FormBuySalesStep3';
import FormBuySalesStep5 from './FormBuySalesStep5';
import FormMainConfirm from '../FormMainConfirm';
import MultiStepFormEntry from '../FormPayments/MultiStepFormEntry';

const operationType = {
  sales: 'Venda',
  buy: 'Compra',
  '': '',
};

//https://ajuda.contaazul.com/hc/pt-br/articles/8932395646605
//https://ajuda.contaazul.com/hc/pt-br/sections/115002247788-Gest%C3%A3o-de-compras
//https://www.youtube.com/watch?v=W9Mv6KgBL5U&t=18s

export default function MultiStepFormBuySales({ type, handleClose, retorno }) {
  // console.log("MultiStepFormBuySales");
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const bankRepos = usestore.reposBank.bankRepos;

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    operationType: operationType[type],
    operationName: operationType[type],

    //step1
    //  Informações do lançamento
    date_release: dayjs(),
    date_release_f: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    date_release_fc: dayjs(new Date()).format('DD/MM/YYYY HH:mm'),
    date_delivery: dayjs(),
    date_delivery_f: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    date_delivery_fc: dayjs(new Date()).format('DD/MM/YYYY HH:mm'),
    supplier: null,
    client: null,
    person: null,
    //  produtos adicionados
    products: [],
    product_selected: null,
    products_value: 0,
    packaging_unit: 'unidade',

    //  lotes adicionados
    lotes: [],
    lote_selected: null,
    lotes_value: 0,
    //  animais adicionados
    animals: [],
    animal_selected: null,
    animals_value: 0,

    //  patrimonios adicionados
    // patrimonys: [],
    // patrimony_selected: null,
    // patrimonys_value: 0,

    //  serviços adicionados
    // services: [],
    // service_selected: null,
    // services_value: 0,

    //  Vincular com e descrição
    projects: [],
    projects_apport: [],
    description: '',

    //step2
    //  Condições pagamento
    dueDate: dayjs(),
    dueDate_f: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    dueDate_fc: dayjs(new Date()).format('DD/MM/YYYY'),
    installment: 'a_vista',
    installmentInterval: 30,
    formPayment: 'dinheiro',
    bankAccount: bankRepos.id,
    payed: false,
    //  Previsão de pagamento
    fee: 0,
    penalty: 0,
    discount: 0,
    value: 0,
    value_end: 0,
    //  Parcelas do pagamento
    arr_installment: [],

    error_date: false,
  });
  const [activeStep, setActiveStep] = useState(0);

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const [track, setTrack] = useState(new Date().getTime());
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };

  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const validate = () => {
    let valid = true;

    if (!data.products.length && !data.lotes.length && !data.animals.length) {
      valid = false;
    } else if (!data.value || !data.formPayment || !data.bankAccount || data.error_date) {
      valid = false;
    }
    return valid;
  };

  const onSubmit = async () => {
    let formOk = validate() || false;
    // console.log(data);

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post(`/${type}/save`, data, header) // buy ou sales
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success(data.operationType + ' registrada com sucesso!');
            let reposFetch;
            if (type === 'buy') {
              reposFetch = usestore.reposBuys;
            } else {
              reposFetch = usestore.reposSales;
            }
            usestore.reposBill.fetch();
            usestore.reposFinances.fetch({ id_property: property_id });
            usestore.reposFinances.fetchT({ id_property: property_id });
            usestore.reposLivestock.fetch();
            usestore.reposActivityWeight.fetch();
            usestore.reposProducts.fetch();
            usestore.reposCashFlow.fetch();
            usestore.reposCostProduction.fetch();
            usestore.reposActivityResources.fetch();

            reposFetch.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar ' + data.operationType + ', tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const handleCloseBuySale = () => {
    // remove os lotes que ficaram pendentes para serem adicionados
    usestore.reposLivestock.delLoteStore('new_lote', 1);
    handleClose();
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleCloseBuySale,
      onSubmit: onSubmit,
      ismobile: isMobile.any(),
      retorno: retorno,
    };

    switch (step) {
      // Retorna para selecção de tipo de lançamento
      case -1:
        return <MultiStepFormEntry type={''} handleClose={handleClose} />;
      case 0:
        // Informações do lançamento: data
        return <FormBuySalesStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // Carrinho do lançamento: produto + lotes + animais
        return <FormBuySalesStep2 data={data} obj_fn={obj_fn} />;
      case 2:
        // rateio ?
        return <FormBuySalesStep3 data={data} obj_fn={obj_fn} />;
      case 3:
        // CONDIÇÕES PAGAMENTO + PREVISÃO DE PAGAMENTO + PARCELAS
        return <FormBuySalesStep5 data={data} obj_fn={obj_fn} />;
      case 4:
        // Confirmação dos dados do lançamento
        return <FormMainConfirm data={data} obj_fn={obj_fn} />;
      case 5:
        const data_success = {
          distination: type,
          name: data.operationType,
          text_success: data.operationName + ' registrada no financeiro.\nVerifique seu estoque e atualize o pedido da ' + data.operationName,
          distination_title: data.operationName,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>{getStepContent(activeStep)}</Box>
    </>
  );
}
