import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext } from "react";
//import { ThemeContext } from "../../components/context-api/ThemeContext";
import { Context } from "../../components/Context/AuthContext";
import Loading from "../../components/Loader/Loading";
import { toast } from "react-toastify";
import useStore from "../../store/useStore";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

export default function LivestockListSpecificAreaContent() {
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  // instancia os repos
  const usestore = useStore();
  const listAcitivy = usestore.reposLivestock.getLivestockStore('id', id);

  const [dataActivityLivestock, setDataActivityLivestock] = useState(listAcitivy[0]);
  const [dataListAnimal, setDataListAnimal] = useState(listAcitivy[0].animais);

  useEffect(() => {
    listActivyLivestock();
  }, [loading]);

  const listActivyLivestock = (e) => {
    setDataActivityLivestock(listAcitivy[0]);
    setDataListAnimal(dataActivityLivestock.animais);

    setIsLoading(false);
    setLoading(false);
  };

  function formatData(Inputdata) {
    if (!Inputdata) {
      return "";
    }
    return dayjs(Inputdata).format('DD/MM/YYYY');
  }

  function print() {
    window.print();
  }

  return (
    <>
      {isLoading === true ? (
        <Loading />
      ) : (
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">Gestão Pecuária</div>
          </div>
          {loading === true ? (
            <Loading />
          ) : (
            <div className="content ">
              <div className="container-fluid">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <p className="d-flex flex-column">
                        <h3 className="text-bold text-success text-uppercase">{dataActivityLivestock.nome}</h3>
                        <span>Tipo de atividade: {dataActivityLivestock.tipo}</span>
                      </p>

                      <p className=" ml-auto d-flex flex-column text-right">
                        <span className="text-success d-none d-lg-block ">
                          <i className="fas fa-arrow-up" /> Data inicial: {formatData(dataActivityLivestock.data_inicial)}
                        </span>
                        <span className="d-none d-lg-block  text-success">
                          <i className="fas fa-arrow-up" /> Previsão de fim: {formatData(dataActivityLivestock.data_fim)}
                        </span>
                        <span className="d-none d-lg-block  text-success">
                          <i className="fas fa-arrow-up" /> Situação: {dataActivityLivestock.situacao}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header no-print">
                    <h3 className="card-title">Lista dos animais</h3>
                    <div className="no-print">
                      <button onClick={print} id="btnPrint" className="btn btn-default float-right">
                        <i className="fas fa-print " /> Imprimir
                      </button>
                    </div>
                  </div>

                  <div className="card-body table-responsive ">
                    <table id="example1" className="table table-bordered table-striped ">
                      <thead>
                        <tr key={2}>
                          <th> </th>
                          <th>Data de nascimento</th>
                          <th>Código/Nome</th>
                          <th>Sexo</th>
                          <th>Peso (kg)</th>
                          <th>Valor (R$)</th>
                          <th>Lote</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataListAnimal?.map((item) => (
                          <tr key={item.id}>
                            <th style={{ width: 60 }} className="">
                              {" "}
                              {dataActivityLivestock.tipo != "Bovinocultura de Corte" ? (
                                <img src="" width="30"></img>
                              ) : item.sexo == "macho" ? (
                                <img src="/dist/img_fp/boi.png" width="30"></img>
                              ) : (
                                <img src="/dist/img_fp/vaca.png" className="img-fluid" width="40"></img>
                              )}{" "}
                            </th>
                            <td>{formatData(item.date_birth)}</td>
                            <td>{item.codigo}</td>
                            <td>{item.sexo}</td>
                            <td>{item.peso}</td>
                            <td>{item.valor}</td>
                            <td>{item.lote}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="no-print">
                        <tr>
                          <th> </th>
                          <th>Data de nascimento</th>
                          <th>Código/Nome</th>
                          <th>Sexo</th>
                          <th>Peso (kg)</th>
                          <th>Valor (R$)</th>
                          <th>Lote</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}