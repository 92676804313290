import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';

export default function TableManintenanceEspecific({ obj_fn }) {
  // console.log('TableManintenanceEspecific');
  const listMaintenance = obj_fn.usestore.reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id_patrimony', obj_fn.patrimonyData?.id);

  const currentMonth = dayjs().format('YYYY-MM');
  var aux_data, row;
  var aux_total = 0;
  var aux_month = 0;
  // Preparar as linhas para a tabela
  const rows = [];
  listMaintenance.map((item) => {
    aux_data = dayjs(item.date_start).format('YYYY-MM');
    aux_total += item.value || 0;
    row = {
      date: dayjs(item.date_start).format('DD/MM/YYYY HH:mm'), // Data de início formatada
      services: item.services.map((service) => service.name_expense_sub || service.name_expense).join(', ') || <Close />,
      products: item.products.length > 0 ? item.products.map((product) => product.name_product).join(', ') : <Close />,
      total: item.value || 0, // Valor total do patrimônio
    }

    if (obj_fn.tabActive > 0) { // total
      rows.push(row);
    } else if (dayjs(currentMonth).isSame(aux_data)) {
      aux_month += item.value;
      rows.push(row);
    }
    return item;
  });

  obj_fn.setValuetotalMaintenace(aux_total);
  obj_fn.setValuetotalMaintenaceMonth(aux_month);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Serviços</TableCell>
            <TableCell>Produtos</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row, index) => (
              <TableRow
                key={index} // Idealmente, use um ID único
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                <TableCell align="">{row.services}</TableCell>
                <TableCell align="">{row.products}</TableCell>
                <TableCell align="right">{row.total ? row.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                Nenhuma manutenção encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
