import React, { useState, useEffect } from "react";
import CardPainel from "./components/CardPainel";

export default function ControlPanelCreationsContent() {
  return (
    <div className="content-wrapper bg-white ">
      <section className="">
        <div className="">
          <CardPainel />
        </div>
      </section>
    </div>
  );
}
