import React, { useEffect, useState, useContext } from 'react';
import { Box, ListItem, ListItemText, Divider, Button, ListItemIcon, Card, CardMedia, CardHeader } from '@mui/material';
import { AppBar, Grid, Stack, Toolbar, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Context } from '../../../../../components/Context/AuthContext';
import { makeStyles } from '@mui/styles';
import FormAssignAreaHeader from './FormAssignAreaHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  content: {
    width: '100%',
    maxWidth: 'none',
  },
}));

export default function FormAssignAreaStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const classes = useStyles();
  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  const handleSubmit = () => {
    console.log('onSubmit');
    obj_fn.onSubmitAdd();
  };

  const getUsed = (area) => {
    const last_used = area.areas_used.lenght - 1;
    let text_used = (
      <>
        {area.areas_used.map((area_used, index) => (
          <>
            <Typography key={'ty_' + area_used.id} variant="subtitle2" gutterBottom title={'Tipo: ' + (area_used.tipo == 'livestock' ? area_used.tipo_livestock : area_used.tipo_harvest)}>
              {area_used.name}
            </Typography>
            {index > 0 && index < last_used ? <br /> : <></>}
          </>
        ))}
      </>
    );
    return text_used;
  };

  const title = 'Vincular Áreas à criação';
  const text_title = 'Confirmação dos dados';
  const txt_msn = 'Pronto! Agora basta conferir os dados e clicar em confirmar!';

  return (
    <>
      <FormAssignAreaHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} imagem={'/dist/image/entrada.png'} title={title} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography fontWeight="500" variant="h6">
            Áreas a serem vinculadas à criação
          </Typography>
        </Grid>
        {data.new_area.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }}>
                <ListItemIcon key={'li_' + item.id}>
                  <div style={{ position: 'relative' }}>
                    <CardMedia key={'cm_' + item.id} component="img" height="150" image={item.url || item.imageDecode} alt="Imagem da área" />
                    <Box
                      key={'b_' + item.id}
                      sx={{
                        position: 'absolute',
                        top: '95px',
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.45)',
                        color: 'white',
                        padding: '27px',
                      }}
                    >
                      <div style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="body2">
                          {item.area} {item.measure || 'ha'}
                        </Typography>
                      </div>
                    </Box>
                    <CardHeader key={'ch_' + item.id} sx={{ maxHeight: '250px', overflow: 'auto' }} subheader={<Box sx={{}}>{getUsed(item)}</Box>} />
                  </div>
                </ListItemIcon>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>

      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button color="success" variant="contained" type="submit" onClick={handleSubmit}>
              Confirmar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
