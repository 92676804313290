import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import api from '../../services/api';
import dayjs from 'dayjs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

const columns = [
  { id: 'folderIcon', label: '', minWidth: 100 },
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'date', label: 'Data', minWidth: 100 },
  { id: 'link', label: 'Link', minWidth: 100 },
  { id: 'delete', label: 'Deletar', minWidth: 100 },
];

// function createData(folderIcon, date, title, link) {
//   return { folderIcon, date, title, link };
// }

// const rows = [];

export default function TableListDocFile({ data, handleDelete }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log('data2');
  console.log(data);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.id_item}>
                  <TableCell>
                    {item.fileExtension === '.pdf' ? (
                      <PictureAsPdfIcon />
                    ) : item.fileExtension === '.jpg' || item.fileExtension === '.png' || item.fileExtension === '.jpeg' ? (
                      <ImageIcon />
                    ) : (
                      <DocumentScannerIcon />
                    )}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{dayjs(item.date).format('DD/MM/YYYY')} </TableCell>

                  <TableCell>
                    <a href={item.img} target="_blank">
                      <InsertLinkIcon color="success" />
                    </a>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleDelete(e, item.id_item, item.img)} color="error">
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
