import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

export default function PreparationAgricultureTopicList({ topics, setTopics, initialTopics }) {

  const [newTopic, setNewTopic] = useState('');

  const handleRemoveTopic = (index) => {
    const updatedTopics = [...topics];
    updatedTopics.splice(index, 1);
    setTopics(updatedTopics);
  };

  const handleAddTopic = () => {
    if (newTopic.trim() !== '') {
      setTopics([...topics, newTopic]);
      setNewTopic('');
    }
  };

  const handleRestoreList = () => {
    setTopics(initialTopics);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedTopics = [...topics];
    const [reorderedItem] = updatedTopics.splice(result.source.index, 1);
    updatedTopics.splice(result.destination.index, 0, reorderedItem);

    setTopics(updatedTopics);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="topics">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {topics.map((topic, index) => (
              <Draggable key={index} draggableId={`topic-${index}`} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1} {...provided.dragHandleProps}>
                        <PlaylistAddCheckIcon />
                      </Box>
                      <Typography variant="body1">{topic}</Typography>
                      <Box mr={1}>
                        <IconButton size="small" onClick={() => handleRemoveTopic(index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Box mt={2}>
        <IconButton onClick={handleRestoreList}>
          <SettingsBackupRestoreIcon />
          <Typography sx={{ ml: 1 }} variant="body1">Excluir a lista e restaurar as atividades iniciais</Typography>
        </IconButton>
      </Box>
    </DragDropContext>
  );
}