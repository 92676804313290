import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import LivestockCreationsContent from "./LivestockCreationsContent";
import Footer from "../../components/Footer/Footer";

export default function LivestockCreations() {
  return (
    <>
      {/* <NavSide />
      <Nav />
      <NavBotton /> */}
      <LivestockCreationsContent />
      <Footer />
    </>
  );
}
