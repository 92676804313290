import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconBeefCattle = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 801.000000 501.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,501.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M6370 4593 c-32 -12 -100 -98 -123 -157 -59 -148 -80 -340 -53 -492
        19 -114 71 -285 129 -429 35 -88 49 -111 68 -116 17 -5 19 -7 6 -8 -20 -1 -17
        -21 9 -73 16 -31 17 -38 5 -38 -11 0 -102 215 -152 360 -85 244 -109 398 -90
        560 12 91 60 260 83 287 23 28 -5 26 -60 -4 -33 -18 -111 -45 -185 -64 -94
        -23 -127 -35 -123 -45 14 -36 69 -343 82 -463 16 -137 14 -334 -13 -1075 -5
        -147 -4 -178 7 -174 18 6 45 87 74 228 13 65 37 147 53 182 29 65 93 168 93
        149 0 -5 30 1 68 16 41 16 101 29 154 33 67 6 98 14 132 34 25 14 87 40 138
        57 51 16 103 37 115 45 20 13 30 12 89 -2 53 -14 72 -15 105 -5 21 6 39 16 39
        21 0 6 15 10 34 10 112 0 196 80 181 172 -10 60 -48 98 -98 100 l-42 1 38 4
        c20 2 37 8 37 12 0 4 -14 20 -31 37 -17 16 -74 90 -127 164 -53 74 -116 160
        -140 190 -25 30 -53 74 -63 97 -19 44 -230 271 -281 303 -15 9 -38 32 -51 51
        -18 27 -30 34 -57 36 -19 1 -42 -1 -50 -4z"
          />
          <path
            d="M5708 4375 c-161 -18 -159 -16 -111 -68 72 -78 85 -137 91 -392 10
        -508 -51 -1152 -146 -1547 -11 -43 -17 -78 -14 -78 3 0 35 31 71 69 80 83 165
        140 266 176 70 26 75 30 81 62 4 21 2 44 -6 58 -10 18 -10 91 -1 352 28 853
        28 876 -42 1218 -28 141 -34 160 -52 162 -11 0 -73 -5 -137 -12z"
          />
          <path
            d="M5470 4333 c-62 -22 -150 -69 -155 -82 -4 -13 -14 -16 -40 -13 -40 5
        -69 -11 -141 -77 -28 -27 -43 -46 -35 -49 7 -2 45 -26 85 -53 175 -117 281
        -279 421 -643 34 -88 39 -96 47 -75 5 13 12 184 15 379 5 305 3 365 -11 423
        -35 148 -104 219 -186 190z"
          />
          <path
            d="M1662 4099 c-133 -52 -272 -280 -342 -565 -53 -212 -64 -331 -60
        -619 7 -572 7 -571 -23 -656 -31 -86 -34 -151 -12 -236 18 -69 19 -146 3 -176
        -6 -12 -12 -49 -12 -82 0 -68 26 -129 70 -164 25 -20 28 -20 46 -5 10 10 18
        28 18 41 0 12 5 22 12 21 17 -4 47 105 60 217 6 55 17 114 24 132 28 64 16
        136 -46 273 -63 138 -70 178 -70 410 0 359 38 645 112 853 25 69 33 71 13 2
        -8 -26 -14 -49 -13 -51 6 -5 1219 -86 1223 -82 3 2 2 77 -2 165 l-6 161 -62 6
        c-156 16 -278 68 -354 152 -48 52 -121 164 -121 185 0 5 -1 9 -2 9 -2 0 -66 7
        -143 15 -185 20 -248 18 -313 -6z"
          />
          <path
            d="M5040 4111 c-40 -24 -237 -62 -440 -86 l-55 -7 -48 -146 c-64 -198
        -87 -317 -93 -497 -6 -153 3 -240 41 -411 l16 -71 242 -14 c232 -13 892 -17
        905 -5 3 3 14 88 24 188 l19 183 -57 150 c-72 193 -181 414 -238 487 -55 70
        -159 157 -232 195 -30 15 -54 31 -54 36 0 9 -11 9 -30 -2z"
          />
          <path
            d="M2140 4077 c0 -22 97 -162 135 -196 71 -62 182 -103 315 -117 l66 -6
        -4 108 c-2 60 -7 125 -11 144 l-7 35 -229 22 c-280 26 -265 26 -265 10z"
          />
          <path
            d="M2668 4033 c-3 -4 0 -113 5 -240 l11 -233 108 0 108 0 0 229 0 229
        -52 5 c-148 16 -176 18 -180 10z"
          />
          <path
            d="M2920 3790 l0 -230 100 0 100 0 0 143 c0 79 -3 178 -6 220 l-7 76
        -66 6 c-36 3 -78 8 -93 10 l-28 5 0 -230z"
          />
          <path
            d="M4136 3984 c-181 -13 -332 -27 -335 -30 -8 -8 -8 -911 0 -919 7 -7
        601 -27 607 -20 2 2 -4 56 -13 121 -22 144 -16 331 15 484 18 88 85 309 114
        373 10 21 37 22 -388 -9z"
          />
          <path
            d="M3137 3983 c-3 -5 -1 -147 4 -318 4 -170 8 -378 8 -462 l1 -152 43
        -5 c23 -3 164 -8 312 -11 l270 -6 0 465 0 465 -60 5 c-71 7 -471 26 -532 26
        -23 0 -44 -3 -46 -7z"
          />
          <path
            d="M2685 3301 l0 -238 104 -7 c57 -3 157 -6 223 -6 l118 0 0 184 c0 100
        -3 211 -6 245 l-7 61 -216 0 -216 0 0 -239z"
          />
          <path
            d="M1436 3453 c-3 -10 -8 -44 -11 -75 -7 -56 -7 -56 21 -61 29 -4 29 -4
        3 -6 -31 -1 -39 -21 -39 -98 0 -51 2 -55 86 -155 109 -130 176 -231 204 -305
        32 -85 58 -227 71 -397 l13 -149 97 6 c53 3 106 10 117 16 16 9 25 39 51 173
        55 289 62 350 68 523 4 158 -7 489 -17 498 -4 4 -599 48 -642 47 -9 0 -19 -8
        -22 -17z"
          />
          <path
            d="M2126 3383 c4 -20 7 -165 8 -322 1 -307 2 -298 -65 -649 -17 -90 -29
        -165 -26 -168 3 -4 56 9 117 26 93 28 123 42 183 86 40 30 97 67 128 84 l56
        30 36 127 c77 273 107 454 107 661 l0 132 -27 0 c-16 0 -118 7 -228 15 -110 8
        -222 15 -248 15 l-48 0 7 -37z"
          />
          <path
            d="M1413 3019 c3 -60 30 -231 62 -404 31 -165 59 -317 62 -337 5 -35 8
        -37 55 -47 27 -6 76 -11 108 -11 59 0 60 1 60 28 0 73 -23 278 -41 365 -25
        126 -49 184 -110 272 -58 84 -176 228 -191 234 -8 2 -9 -28 -5 -100z"
          />
          <path
            d="M2675 3004 c-20 -113 -59 -284 -90 -394 -19 -68 -33 -125 -32 -127 2
        -1 30 6 63 16 127 40 419 71 665 71 l139 0 0 118 c0 112 11 250 25 304 l7 25
        -244 7 c-134 3 -307 9 -385 12 l-142 6 -6 -38z"
          />
          <path
            d="M3465 3001 c-12 -40 -25 -192 -25 -308 l0 -123 138 0 c166 0 482 -15
        761 -36 112 -8 205 -14 206 -12 1 2 -8 39 -22 83 -13 44 -42 147 -63 229 -22
        82 -41 150 -43 153 -3 2 -155 8 -338 13 -184 5 -396 12 -471 15 -128 5 -137 4
        -143 -14z"
          />
          <path
            d="M4474 2844 c4 -16 26 -94 49 -174 l43 -145 491 0 c270 0 494 3 497 6
        5 5 32 153 50 277 l7 44 -258 -7 c-152 -4 -332 -2 -438 4 -99 5 -240 13 -314
        17 l-133 7 6 -29z"
          />
          <path
            d="M2990 2539 c-302 -22 -443 -63 -605 -172 -68 -46 -203 -180 -202
        -199 3 -28 -222 -448 -239 -448 -3 0 11 29 31 65 20 35 38 79 40 97 9 68 101
        236 188 341 21 26 34 47 28 47 -6 0 -57 -13 -113 -29 -190 -55 -241 -58 -499
        -35 l-66 6 -7 -218 c-3 -121 -9 -236 -12 -257 l-6 -38 213 1 c118 1 295 8 394
        16 99 8 182 14 186 14 3 0 9 113 12 252 4 138 8 253 11 255 2 2 57 7 122 10
        143 7 202 -6 331 -70 48 -25 138 -66 198 -92 164 -71 245 -113 293 -152 46
        -38 127 -73 166 -73 16 0 32 11 45 29 14 19 40 36 74 47 62 20 59 22 -64 33
        l-94 8 96 2 97 1 -55 167 c-51 154 -56 177 -61 285 l-5 118 -196 -1 c-108 -1
        -243 -5 -301 -10z"
          />
          <path
            d="M3510 2464 c0 -49 7 -120 15 -159 15 -72 88 -299 104 -324 16 -25
        208 2 476 65 288 68 377 76 505 46 47 -11 86 -19 88 -17 2 1 -21 72 -51 156
        -30 85 -63 179 -72 210 l-18 57 -66 6 c-152 14 -532 36 -748 42 l-233 7 0 -89z"
          />
          <path
            d="M5875 2520 c-109 -37 -159 -71 -265 -179 -103 -104 -106 -108 -132
        -191 -14 -47 -38 -111 -52 -144 l-27 -58 63 7 c172 20 300 122 389 310 40 86
        82 224 77 254 -3 17 -5 17 -53 1z"
          />
          <path
            d="M5023 2503 c-244 -2 -443 -7 -443 -11 -1 -21 136 -405 145 -408 5 -2
        162 -18 348 -37 l337 -33 31 79 c24 64 28 82 17 88 -10 7 -9 9 3 9 10 0 19 11
        22 28 3 15 19 79 36 142 41 153 41 151 -11 148 -24 -2 -242 -4 -485 -5z"
          />
          <path
            d="M4710 1976 c0 -73 19 -300 36 -424 l5 -42 242 2 242 3 42 167 c29
        120 52 188 79 238 21 38 36 71 33 74 -4 4 -620 66 -656 66 -22 0 -23 -4 -23
        -84z"
          />
          <path
            d="M2102 1695 c-85 -8 -156 -15 -158 -18 -2 -2 31 -87 75 -188 62 -143
        83 -184 98 -184 10 0 76 4 146 9 124 8 128 9 121 30 -27 83 -36 136 -41 244
        l-6 122 -41 -1 c-23 -1 -110 -7 -194 -14z"
          />
          <path
            d="M1507 1583 c-10 -54 -21 -144 -25 -200 l-7 -101 40 -6 c22 -3 92 -8
        156 -12 l116 -6 7 47 c14 89 45 190 85 277 23 47 41 89 41 92 0 3 -89 6 -198
        6 l-197 0 -18 -97z"
          />
          <path
            d="M4750 1485 c0 -2 9 -28 21 -57 25 -63 24 -83 -16 -334 -15 -100 -25
        -189 -21 -199 4 -13 14 -16 36 -13 34 5 34 5 20 -42 -6 -22 -6 -38 0 -44 6 -6
        10 -22 10 -36 0 -15 9 -35 19 -44 17 -15 33 -17 135 -10 114 7 115 6 146 -20
        29 -24 38 -26 131 -26 93 0 183 13 202 30 4 4 -22 39 -58 77 -36 39 -65 75
        -65 81 0 5 -13 25 -28 45 -25 32 -32 57 -61 229 -35 217 -37 253 -16 305 28
        66 39 63 -220 63 -129 0 -235 -2 -235 -5z"
          />
          <path
            d="M2258 1296 c-75 -6 -139 -14 -143 -17 -10 -10 30 -105 62 -146 64
        -83 73 -104 60 -151 -15 -57 -2 -78 45 -74 34 2 36 0 56 -48 12 -29 26 -49 32
        -45 6 4 8 -4 4 -22 -9 -46 32 -76 111 -81 98 -6 235 7 242 24 7 19 -86 134
        -122 150 -18 8 -23 13 -12 14 24 0 22 5 -27 68 -29 38 -59 98 -91 179 -25 68
        -54 131 -63 141 -16 17 -26 17 -154 8z"
          />
          <path
            d="M1474 1184 c5 -44 11 -110 15 -147 5 -52 3 -71 -7 -80 -20 -16 -34
        -58 -28 -83 5 -19 10 -21 40 -14 43 9 56 -2 56 -50 1 -57 18 -114 40 -132 32
        -26 116 -32 229 -15 56 8 105 17 107 20 9 9 -16 49 -72 115 -30 35 -54 73 -54
        86 0 12 -7 32 -16 45 -20 27 -23 108 -10 227 l8 81 -98 7 c-55 4 -126 9 -158
        12 l-59 6 7 -78z"
          />
        </g>
      </SvgIcon>
    </>
  );
};