import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Container, Paper } from "@mui/material";
import MultiStepFormPerson from "./MultiStepFormPerson";

//https://napratica.contaazul.com/gravados/primeiros-passos/cadastro-unico-cliente-e-fornecedor

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterPerson({ type }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const text_title = type ? type : 'Clientes/Fornecedores'

  return (
    <div>
      <Button variant="text" color="success" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>
        <Typography variant="button" sx={{ fontSize: '0.75rem' }}>
          Cadastrar: {text_title}
        </Typography>
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box id="focus_top_modal">
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Novo Cadastro de {text_title}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="lg" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <MultiStepFormPerson handleClose={handleClose} type={type}/>
            </Paper>
          </Container>
          {/* <FormGoalTarget handleClose={handleClose} /> */}
        </Box>
      </Dialog>
    </div>
  );
}
