import React, { useState, useEffect, useContext } from "react";
import { Button, Dialog, AppBar, Container, DialogContent, Divider, Grid, IconButton, Paper, Slide, Toolbar, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import FormEditLivestock from "./FormEditLivestock"
import FormEditSituation from "./FormEditSituation"
import { Span } from "../../../../components/Typography";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import LoopIcon from "@mui/icons-material/Loop";
import { blue, green } from "@mui/material/colors";
import { Context } from "../../../../components/Context/AuthContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// fillDialog normal que chama uma lista de botões de atividade
// cada botão chama o componete de atividades ... MultiStepFormInput 
export default function EditLivestock({ id, usestore, tab_active }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openSituation, setOpenSituation] = useState(false);

  const [data, setData] = useState({});
  const [data_situacao, setDataSituacao] = useState({});

  const { aux_add, setAuxAdd } = useContext(Context);
  const header = { header: { "Content-Type": "application/json" } };

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const property_id = (localStorage.getItem("Property") || 1);
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  // ------ inicicia os repos ------ 
  const reposAreasMap = usestore.reposAreas;
  const reposLivestocks = usestore.reposLivestock;
  const areaMapList = window.jQuery.extend(true, [], reposAreasMap.listAreasMap);

  // pega a livestock em edição
  const livestock = window.jQuery.extend(true, [], reposLivestocks.getLivestockStore('id', id));
  useEffect(() => {
    if (!livestock || livestock.length == 0) {
      return;
    }
    let this_livestock = window.jQuery.extend(true, [], livestock[0]);
    setData(this_livestock);
    setDataSituacao({
      'id': this_livestock.id,
      'situation': this_livestock.situation,
      'date': (new Date()),
      'date_start': this_livestock.date_start
    });
  }, []);

  const setDadosData = () => {
    console.log("setDadosData");
    let this_livestock = window.jQuery.extend(true, [], livestock[0]);

    // pega as áreas da livestock em edição
    var this_livestock_areas = [];
    if ( this_livestock.areas.length > 0 ) {
      this_livestock_areas = areaMapList.filter(repos_area => this_livestock.areas.some(area => (area.id == repos_area.id)));
      // seta usedArea
      this_livestock_areas.map(repos_area => {
        this_livestock.areas.map(area => {
          if (area.id == repos_area.id) {
            repos_area.usedArea = area.usedArea;
            return area;
          }
        })
      });
    }
    this_livestock.areas = window.jQuery.extend(true, [], this_livestock_areas);

    setData(this_livestock);
    setDataSituacao({
      'id': this_livestock.id,
      'situation': this_livestock.situation,
      'date': (new Date()),
      'date_start': this_livestock.date_start
    });
  };

  const handleClickOpen = () => {
    console.log("handleClickOpen");
    setDadosData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setFormError({});
    let errors = {};
    let formOk = true;
    console.log(data);

    if (data.name.length < 5) {
      errors.nameLivestock = true;
      formOk = false;
      // } else if (property.address.length < 3) {
    } else if (data.areas.length === 0) {
      errors.area = true;
      formOk = false;
    }

    if (formOk) {
      const areas_bkp = window.jQuery.extend(true, [], data.areas);
      data.areas = data.areas.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea }));
      data.id_user = userCurrent.id;
      data.label = data.name;

      const res = await api.put("/livestock/update", data, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Projeto editado com sucesso!');
            data.areas = areas_bkp;
            reposAreasMap.fetch({ 'id_farm': property_id });
            reposLivestocks.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            })
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
        })
      data.areas = areas_bkp;
    } else {
      // escreve mensagem de erro para cliente
      toast.error("Por favor, preencha os campos obrigatórios");
      setFormError(errors);
      //return false;
    }
  };

  const handleSituation = async (tipo) => {
    console.log('status: ' + data_situacao.id);
    // tipo: ativa - finalizada - inativa - excluida
    data_situacao.situation = tipo;
    data_situacao.status = false;
    data_situacao.delete = false;

    if (tipo == 'ativa' || tipo == 'excluida') {
      data_situacao.delete = true;
      data_situacao.isDeleted = (tipo == 'ativa' ? 0 : 1);
      data_situacao.livestock = [ {'id': data_situacao.id} ];
    } else {
      data_situacao.status = true;
      data_situacao.isDeleted = 0;
    }

    const res = await api.put("/livestock/update", data_situacao, header)
      .then((response) => {
        toast.success(`Criação ${data_situacao.situation} com sucesso!`);
        reposAreasMap.fetch({ 'id_farm': property_id });
        reposLivestocks.fetch().then((res) => {
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            handleClose();
          }, 200);
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error("Erro ao alterar a situação da Criação!");
      });
  };

  const handleCloseSituation = () => {
    setOpenSituation(false);
  };

  if (!livestock || livestock.length == 0) {
    return (<></>);
  }
  return (
    <>
      {/* {isLoading ? <Loading /> : ( */}
      <div>
        {/*<Button variant="text" color="success" title="Editar" startIcon={<EditIcon fontSize="small" sx={{ ml: 1 }} />} onClick={handleClickOpen} />*/}
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> */}
          <AppBar sx={{ position: "relative" }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    Edição do Projeto
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <DialogContent sx={{ mb: 8 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 1 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
                <FormEditLivestock data={data} setData={setData} reposLivestocks={reposLivestocks}/>
              </Paper>
            </Container>
          </DialogContent>
          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                  <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Dialog>

        {tab_active == 'excluida' ? <></> :
        <IconButton aria-label="edicao" onClick={() => handleClickOpen()} title="Editar">
          <EditIcon sx={{ color: green[600] }} />{" "}
        </IconButton>}
        <IconButton aria-label="edicao" style={{float: (tab_active == 'excluida' ? 'left' : '')}} onClick={() => setOpenSituation(true)} title="Situações">
          <LoopIcon sx={{ color: blue[600] }} />{" "}
        </IconButton>
        <FormEditSituation data={data_situacao} setData={setDataSituacao} handleSituation={handleSituation} openSituation={openSituation} handleClose={handleCloseSituation}/>
      </div>
    </>
  );
}
