import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useActivityBatchReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityBatch: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem("Property") || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("activityBatch/list", item, header);
          const data = await response.data;

          await set({
            listActivityBatch:
              data._value.map((activityBatch) => {
                return {
                  id: activityBatch.id,
                  id_property: activityBatch.id_property,
                  id_user: activityBatch.id_user,
                  name_user: activityBatch.name_user,
                  id_livestock: activityBatch.id_livestock,
                  name: activityBatch.name,
                  name_nf: activityBatch.name.toLowerCase(),
                  id_group: activityBatch.id_group,
                  stage: activityBatch.stage,
                  nameBatch: activityBatch.nameBatch,
                  id_areamap: activityBatch.id_areamap,
                  nameArea: activityBatch.nameArea,
                  date_entry: dayjs(activityBatch.date_entry).format("YYYY-MM-DD HH:mm:ss"),
                  date_entry_f: dayjs(activityBatch.date_entry).format("DD/MM/YYYY"),
                  date_start: dayjs(activityBatch.date_start).format("YYYY-MM-DD HH:mm:ss"),
                  date_start_f: dayjs(activityBatch.date_start).format("DD/MM/YYYY"),
                  date_prev_end: dayjs(activityBatch.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
                  date_prev_end_f: dayjs(activityBatch.date_prev_end).format("DD/MM/YYYY"),
                  date_end: activityBatch.date_end ? dayjs(activityBatch.date_end).format("YYYY-MM-DD HH:mm:ss") : "",
                  date_end_f: activityBatch.date_end ? dayjs(activityBatch.date_end).format("DD/MM/YYYY") : "",
                  observation: activityBatch.observation,
                  isDeleted: activityBatch.isDeleted,
                  progress: activityBatch.progress,
                  planning: activityBatch.planning,
                  tipo_repos: activityBatch.name,
                  activity: activityBatch.name,
                  type: "activity",
                }
              })
          });

          const lista = JSON.parse(localStorage.getItem("listActvs"));
          const lista_i = get().listActivityBatch;

          let array_aux = lista.filter((activity) => {
            if (!/NOVO LOTE/.test(activity.activity)) {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem("listActvs", JSON.stringify(res));

          return data;
        },
        // pega a(s) livestock(s) da listActivityBatch de acordo com o [key e val] informados
        getActivityBatchStore: (key, val) => {
          return get().listActivityBatch.filter((activityBatch) => {
            if (activityBatch[key] == val) {
              return activityBatch;
            }
          });
        },
        // // Adiciona uma nova livestock à listActivityBatch
        addActivityBatchStore: (livestock) => {
          set((state) => ({
            listActivityBatch: [...state.listActivityBatch, livestock],
          }));
        },
        // Gera uma nova listActivityBatch sem a activity informada
        delBatchStore: (id) => {
          set({
            listActivityBatch: get().listActivityBatch.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
      }),
      { name: "reposActivityBatch" }
    )
  )
);

export default useActivityBatchReposStore;
