import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { Link, NavLink } from 'react-router-dom';



export default function MenuMsnMin() {

    return (
        <div className="nav-item dropdown ">
            <NavLink to="#" className="nav-link" data-toggle="dropdown" role="button">
                < MoreVertIcon sx={{ color: "#808080" }} />
                {/* <Avatar sx={{ width: 32, height: 32, bgcolor: "#07bc0c" }}></Avatar> */}
            </NavLink>
            <div className="dropdown-menu  dropdown-menu-right">
                <Link to="#" className="dropdown-item">
                    <i className="fas fa-edit mr-2" /> Editar
                </Link>
                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item">
                    <i className="fas fa-check mr-2" /> Resolvido
                </Link>
                <Link to="#" className="dropdown-item">
                    <i className="fas fa-exclamation-triangle mr-2" /> Não Resolvido
                </Link>
                <Link to="#" className="dropdown-item">
                    <i className="fas fa-circle mr-2" /> Parado
                </Link>
                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item">
                    <i className="fas fa-times mr-2" /> Excluir
                </Link>

                {/* <Link to="#" className="dropdown-item dropdown-footer">
                    Fechar modal
                </Link> */}
            </div>
        </div>
    )
}
