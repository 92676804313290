//*********Corpo do documento **********/

import { bodyListHarvest } from '../bodyHarvest/bodyListHarvest';
import { bodyListCreation } from '../bodyLivestock/bodyListCreation';
import { footerBasic } from '../footer/footerBasic';
import { headerReportDate } from '../header/headerReportDate';
import { titleProperty } from '../title/titleProperty';

// let dataProperty = [];

// let resposPropertyString = localStorage.getItem('reposProperty');
// console.log(resposPropertyString);
// if (resposPropertyString) {
//   // Converte a string JSON em um objeto JavaScript
//   let resposProperty = JSON.parse(resposPropertyString);
//   dataProperty = resposProperty.state.listProperty[0];
//   console.log('imageProperty1');
//   console.log(dataProperty);
// } else {
//   console.log('Nenhum dado encontrado em resposProperty');
// }
// console.log('dataProperty');
// console.log(dataProperty);

// Função para carregar dados do localStorage
const loadFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

// Carregar dados da propriedade
const reposProperty = loadFromLocalStorage('reposProperty');
const dataProperty = reposProperty?.state?.listProperty?.[0] || {};

if (!dataProperty || Object.keys(dataProperty).length === 0) {
  console.log('Nenhum dado encontrado em reposProperty');
} else {
  console.log('Dados da propriedade carregados:', dataProperty);
}

export const documento = {
  pageSize: 'A4',
  //pageOrientation: 'lOutrosandscape',
  header: headerReportDate,
  footer: footerBasic,
  content: [
    ...titleProperty(dataProperty),
    // lineHeader,
    //bodyListCreationHarvest.content,
    ...bodyListCreation.content,
    ...bodyListHarvest.content,
    // ... outras partes do conteúdo, se necessário
  ],
  styles: {
    //lineStyle: lineStyle,
    //reportNameStyle: reportNameStyle,
    //reportDateStyle: reportDateStyle,
  },
  // ... outras configurações do documento
};

//tutorial
//http://pdfmake.org/playground.html
//CriaCorpoDocumento
//https://dev.to/taikio/criando-documentos-pdf-com-reactjs-4lkl
//https://www.papodigital.net.br/blog/criando-documentos-pdf-com-reactjs
