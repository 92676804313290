import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Paper from "@mui/material/Paper";

import RowsPermissions from '../RowsPermissions';
import RowsPermissionsEdit from '../RowsPermissionsEdit';
import RolePermissions from '../RolePermissions';

import Loading from '../../../../components/Loader/Loading';
//import ProfileAccordionTabletProfile from "./ProfileAccordionTabletProfile";

import TableProfileMap from '../ConsultUserProfile/tableProfile/TableProfileMap';
import TableProfileSolicitacoes from "../ConsultUserProfile/tableProfile/TableProfileSolicitacoes";
import TableProfileMapEdit from './TableProfileMapEdit';
import TableProfileSolicitacoesEdit from "./TableProfileSolicitacoesEdit";
import TableProfileTeamEdit from "./TableProfileTeamEdit"
import TableProfileUploadEdit from "./TableProfileUploadEdit";

export default function ProfileAccordion({ editUser, state }) {

    console.log("<ProfileAccordion />")
    console.log(editUser)

    let newRowsData = []

    console.log("ProfileAccordion")
    // pego o value da role do select - profileCurrent = 1 ou 2 ...
    //console.log('id_role do seletor', editUser.id_role)
    console.log('state do seletor', state)

    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    //console.log('userCurrent do id admin', userCurrent.id)
    console.log('userCurrent da role admin', userCurrent.id_role)

    console.log(editUser.permission)
    console.log(JSON.parse(userCurrent.permission))

    // se o usuário tem permissão de editar o usuário: 
    const isPermissionEdit = JSON.parse(userCurrent.permission).find((item) => item.id_modulo == 1 && item.edit == 1)
    console.log("isPermissionEdit")
    if (isPermissionEdit) {
        console.log("permissão de edição")
    } else {
        console.log("não tem permissão de edição")
        return false
    }
    //console.log(isPermissionEdit)
    // qual é a role dele cadastrado no banco
    console.log(userCurrent.id_role)
    // qual é a role dele no formulário
    console.log(editUser.id_role)
    // qual e role atual do edit radio perfil
    console.log(state)
    // carrego as permissões de acordo com: 
    // permissões atuais do role ou permissões default
    //permissões atuais: recebo do userCurrent.permisssion
    //permissões default: userPermissionsRolesDefault e filtro de acordo com estado

    if (state == editUser.id_role) {
        console.log('edit')
        //setIsEdit(true)
        //console.log(id_role)
        //lista todas as permissões editadas
        //newRowsData = JSON.parse(localStorage.getItem("userPermissions"));
        newRowsData = editUser.permission
        console.log("newRowsData-edit")
        console.log(newRowsData)
        //setRowState(newRowsData)
        // isLoading(false)
        //setLoading(false)

    }
    if (state != editUser.id_role) {
        console.log("trocar role")
        //setIsEdit(false)
        const rowsData = JSON.parse(localStorage.getItem("userPermissionsRolesDefault"));
        console.log(rowsData)
        // pode ser o state
        const profileCurrentRadio = JSON.parse(localStorage.getItem("profileCurrentRadio"));
        console.log('profileCurrentRadio edit')
        console.log(profileCurrentRadio)
        // tenho o profileCurrent
        // filtro todas as permissões de um role(profileCurrent)  do selector
        newRowsData = rowsData.filter(row => row.id_role == profileCurrentRadio);
        console.log("newRowsData-trocar role")
        console.log(newRowsData)
        //setLoading(false)
        //isLoading(false)
    }

    //console.log(editUser)

    // useEffect(() => {
    //     console.log("atualizou-rowState")
    //     // console.log(rowState)
    // }, [])

    console.log("newRowsDataXXXXXXXXXXXXXXXXXXXXXXXXProfileAccordion")
    console.log(newRowsData)

    return (
        <div>
            {false ? (<Loading />) : (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell /> */}

                                    <TableCell align="left" sx={{ width: 300 }} >Editar permissões</TableCell>
                                    <TableCell align="center"><Grid container direction="column" alignItems="center">
                                        <VisibilityIcon fontSize="small" /> Visualizar
                                    </Grid></TableCell>
                                    <TableCell align="center"><Grid container direction="column" alignItems="center">
                                        <EditIcon fontSize="small" /> Criar/Editar
                                    </Grid></TableCell>
                                    <TableCell align="center"><Grid container direction="column" alignItems="center">
                                        <DeleteForeverIcon fontSize="small" /> Excluir
                                    </Grid></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* {state == userCurrent?.id_role ? <TableProfileMapEdit state={state} editUser={editUser} newRowsData={newRowsData} setLoading={setLoading} /> : <TableProfileMapEdit newRowsData={newRowsData} setLoading={setLoading} />} */}
                    <TableProfileMapEdit editUser={editUser} newRowsData={newRowsData} />
                    <TableProfileTeamEdit editUser={editUser} newRowsData={newRowsData} />
                    <TableProfileSolicitacoesEdit editUser={editUser} newRowsData={newRowsData} />
                    <TableProfileUploadEdit editUser={editUser} newRowsData={newRowsData} />



                </>
            )}
        </div>
    );
}

