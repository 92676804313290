//*********Corpo do documento **********/
import { bodyListCreationBW } from '../bodyLivestock/bodyListCreationBW'
import { footerBasic } from '../footer/footerBasic'
import { headerbasic } from '../header/headerbasic'
import { titleSection } from '../title/titleBasic'

export const documento = {
  pageSize: 'A4',
  //pageOrientation: 'landscape',
  header: headerbasic,
  footer: footerBasic,
  content: [
    titleSection('Lista das Criações'),
    // lineHeader,
    bodyListCreationBW.content,
    // ... outras partes do conteúdo, se necessário
  ],
  styles: {
    //lineStyle: lineStyle,
    //reportNameStyle: reportNameStyle,
    //reportDateStyle: reportDateStyle,
  },
  // ... outras configurações do documento
}

//tutorial
//http://pdfmake.org/playground.html
//CriaCorpoDocumento
//https://dev.to/taikio/criando-documentos-pdf-com-reactjs-4lkl
//https://www.papodigital.net.br/blog/criando-documentos-pdf-com-reactjs
