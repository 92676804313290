import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Stack, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Loading from '../../../components/Loader/Loading';
import { red } from '@mui/material/colors';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import api from '../../../services/api';
import useListImagesStore from '../../../store/useListImagesStore';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Context } from '../../../components/Context/AuthContext';
import { useContext } from 'react';
import useStore from '../../../store/useStore';

export default function ImageListCmptrash({}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const useImagesRepos = useListImagesStore((state) => state);
  const useImagesListRepos = useListImagesStore((state) => state.listImages);
  const [data1, setData1] = useState(0);
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  const property_id = localStorage.getItem('Property') || 1;
  const display_demo = JSON.parse(localStorage.getItem('display'));

  const groupByMonth = (items) => {
    return items.reduce((groups, item) => {
      const month = dayjs(item.date).format('MMMM YYYY');
      if (!groups[month]) {
        groups[month] = [];
      }
      groups[month].push(item);
      return groups;
    }, {});
  };

  const handleGetList = async () => {
    const itemData = useImagesListRepos.filter((img) => img.isDeleted === 1 && img.id_property == property_id);
    const groupedData = groupByMonth(itemData);
    setData(groupedData);
    setIsLoading(true);
  };

  useEffect(() => {
    handleGetList();
  }, [data1, update, aux_add]);

  useEffect(() => {
    if (data1 != property_id) {
      setTimeout(() => {
        setData1(property_id);
      }, 500);
    }
  }, [property_id, data1]);

  const handleDelete = async (id) => {
    console.log(id);
    console.log('deletou');
    const res = await api
      .delete(`/upload/${id}/1`)
      .then((response) => {
        useImagesRepos.delImageStore(id);
        toast.success('Imagem deletada definitivamente!');
        usestore.reposImages.fetch({ id_farm: property_id }).then((res) => {
          setAuxAdd(aux_add + 1);
        });
        setTimeout(() => {
          setUpdate(new Date().getTime());
        }, 20);
      })
      .catch(() => {
        toast.error('Erro ao deletar a imagem definitivamente!');
      });
  };

  const handleBack = async (id) => {
    console.log(id);
    console.log('restaurou');
    const res = await api
      .delete(`/upload/${id}/-1`)
      .then((response) => {
        useImagesRepos.updateImageStore(id, 'isDeleted', 0);
        toast.success('Imagem restaurada com sucesso!');
        setTimeout(() => {
          handleGetList();
        }, 20);
      })
      .catch(() => {
        toast.error('Erro ao restaurar a imagem!');
      });
  };

  const formatSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  return (
    <>
      {isLoading === false ? (
        <Loading />
      ) : (
        Object.keys(data).map((month) => (
          <div key={month}>
            <ImageListItem key="Subheader" cols={2}>
              <ListSubheader component="div">{month}</ListSubheader>
            </ImageListItem>
            <Grid container spacing={3}>
              {data[month].map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.image}>
                  <Card>
                    <CardHeader
                      // action={
                      //   <>
                      //     <IconButton aria-label="settings">
                      //       <a href={item.img} target="_blank" rel="noopener noreferrer">
                      //         <InsertLinkIcon color="success" />
                      //       </a>
                      //     </IconButton>
                      //     <IconButton aria-label="download" onClick={() => handleDownload(item.img, item.image)}>
                      //       <GetAppIcon color="primary" />
                      //     </IconButton>
                      //   </>
                      // }
                      title={
                        <Typography variant="subtitle1" style={{ color: '#888888' }} component="div">
                          {item.title}
                        </Typography>
                      }
                      subheader={
                        <Typography variant="subtitle1" component="div" style={{ color: '#888888' }}>
                          {dayjs(item.date).format('DD/MM/YYYY')}
                        </Typography>
                      }
                    />
                    <ImageListItem>
                      <CardMedia component="img" width="100%" height="250" image={item.img} alt={item.title} style={{ filter: 'grayscale(100%)' }} />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" style={{ color: '#888888' }}>
                          Tamanho: {formatSize(item.size)}
                        </Typography>
                      </CardContent>
                    </ImageListItem>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                      {display_demo ? (
                        <div></div>
                      ) : (
                        <>
                          <IconButton aria-label="delete" onClick={() => handleDelete(item.image)}>
                            <DeleteForeverIcon sx={{ color: red[500] }} />
                            <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div">
                              Excluir
                            </Typography>
                          </IconButton>
                          <IconButton aria-label="restore" onClick={() => handleBack(item.image)}>
                            <CloudSyncIcon color="success" />
                            <Typography color="success" sx={{ mt: 1, ml: 0.5 }} gutterBottom variant="subtitle1" component="div">
                              Recuperar
                            </Typography>
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ))
      )}
    </>
  );
}

// const itemData = [
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//     author: "@bkristastucchio",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//     author: "@nolanissac",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//     author: "@hjrc33",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//     author: "@arwinneil",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//     author: "@katie_wasserman",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//     author: "@silverdalex",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//     author: "@shelleypauls",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
//     title: "Sea star",
//     author: "@peterlaster",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
//     title: "Bike",
//     author: "@southside_customs",
//   },
// ];
