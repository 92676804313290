import { useState } from "react";
import { TextField, Box, Grid, Typography, InputAdornment, Stack } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import TitleIcon from "@mui/icons-material/Title";
import InputIcon from "@mui/icons-material/Input";
import FormInputFooter from "./FormInputFooter";
import ThreeDotsSpinner from "../../../../../components/Loader/ThreeDotsSpinner";
import useActivityInputReposStore from "../../../../../store/Activities/useActivityInputReposStore";
import ToolipBoostrap from "../../../../../components/Tooltip/ToolipBoostrap";

export default function FormInputStep2Batch({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  //1-texto para acionar o botao radio
  const [showTextBatch, setShowTextBatch] = useState(false);
  setTimeout(() => {
    setShowTextBatch(true);
  }, 1000);

  const [validateNameBatch, setValidateNameBatch] = useState(false);

  // acessar o repos
  const reposActivitiesInputs = useActivityInputReposStore((state) => state);
  // console.log(reposActivitiesInputs.listActivityInput);

  // const nameAtivity = JSON.parse(localStorage.getItem("creationHarvestRepos"));
  // console.log(nameAtivity);

  //abreviação das maiusculas (Pecuária de Leite 2023/2024 -> PL)
  // let abbreviation = (nameAtivity.label).replace(/[^A-Z]/g, "").trim();
  // console.log(abbreviation);

  // função para receber os valores digitados do formulário
  const handleChangeInput = (event, blur) => {
    // console.log("handleChangeInput");
    let value = event.target.value;
    if (blur) {
      value = value.trim()
      validateName(value)
    }
    setData((prevState) => ({ ...prevState, [event.target.name]: value }));
  };

  // valida o nome digitado
  const validateName = (name) => {
    // console.log("validateName");
    for (const activity of reposActivitiesInputs.listActivityInput) {
      if (activity.nameBatch === data.nameBatch || activity.nameBatch === name) {
        setValidateNameBatch(true);
        return true;
      } else {
        setValidateNameBatch(false);
      }
    }
  };

  // const para validar o button continuar
  const isError = () => {
    if (!data.nameBatch || validateNameBatch) {
      return true;
    }

    return false;
  };

  // botão retorna
  const returnError = () => {
    return false;
  };

  const longText =
    "Descubra a poderosa estratégia de criar lotes de animais de forma organizada e eficiente. Essa prática permitirá um gerenciamento preciso das características, cuidados e resultados de cada grupo, resultando em maior sucesso e produtividade na sua atividade pecuária. Aproveite todo o potencial dessa abordagem para maximizar o desempenho do seu rebanho e alcançar resultados excepcionais em sua criação de animais.";
  const text = "Importância de separar os animais em lotes.";

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Entradas dos animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 140 }}>
            <img style={{ maxHeight: 140 }} src="/dist/image/entradadeanimais_lote.png" alt="Entrada animais" class="img-fluid" />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações Essenciais</Typography>
            </Stack>
          </Box>
        </Grid>

        {/* data.stage Acionando quando o botao radio é selecionado */}
        <Grid container spacing={1} style={{ overflow: "auto" }}>
          {data.stage !== "" && (
            <>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} style={{ height: 50 }}>
                  {showTextBatch ? (
                    <>
                      <div className="d-lg-none">
                        <Grid item xs={12} sm={12} sx={{ mt: -2, height: 60 }}>
                          <Typography gutterBottom style={{ color: "#2e7d32" }}>
                            <Typewriter tam={70} minHeight={0} text="Sugerimos um nome para o seu lote, mas sinta-se a vontade de personalizá-lo. " />
                          </Typography>
                        </Grid>
                      </div>

                      <div className="d-none d-lg-block">
                        <Grid item xs={12} sm={12} sx={{ mt: -1, height: 40 }}>
                          <Typography gutterBottom style={{ color: "#2e7d32" }}>
                            <Typewriter minHeight={0} text="Sugerimos um nome para o seu lote, mas sinta-se a vontade de personalizá-lo. " />
                          </Typography>
                        </Grid>
                      </div>
                      <></>
                    </>
                  ) : (
                    <Box display="flex" alignItems="center" style={{ height: 30 }}>
                      <ThreeDotsSpinner />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} sx={{}}>
                  <TextField
                    required
                    color="success"
                    label="Nome do Lote"
                    id="nameBatch"
                    name="nameBatch"
                    size="small"
                    value={data.nameBatch}
                    //{`Lote${capitalize(data.stage)}23/01`}
                    //onChange={handleChangeInput}
                    onChange={handleChangeInput}
                    onBlur={(e) => handleChangeInput(e, true)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TitleIcon sx={{ color: "action.active" }} />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ style: { color: "#808080" } }}
                    variant="standard"
                    // error={formError.area && property.area === ""}
                    // helperText={formError.area && property.area === "" ? "Por favor, preencha o campo obrigatório!" : "texto "}
                    error={validateNameBatch || data.nameBatch === ""}
                    helperText={
                      (validateNameBatch || data.nameBatch === "") && (
                        <p id="NameLivestock" className="form-text text-danger is-invalid">
                          {validateNameBatch ?
                          "Nome de lote existente. Por favor, insira um outro nome."
                          : "Nome do lote é um campo obrigatório."}
                          
                        </p>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ mt: -1 }}>
                  <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                    Ex.: Lote | fase de manejo | quantidade de lotes
                  </Typography>
                  {/* <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                    *{abbreviation}: abreviação da fase de manejo:{nameAtivity.label}
                  </Typography> */}
                </Grid>
                <Grid item xs={12} sm={12} sx={{ mt: -1 }}>
                  <ToolipBoostrap text={text} longText={longText} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12} sx={{ mt: 5 }}>
                    <TextField
                        id="num-animals"
                        label="Número de Animais"
                        type="number"
                        value={numAnimals}
                        //onChange={handleNumAnimalsChange}
                        fullWidth
                        margin="normal"
                        variant="standard"
                        error={animalCountError}
                        helperText={animalCountError ? "Campo obrigatório" : ""}
                    />
                    <TextField
                        id="lot-name"
                        label="Nome do Lote"
                        value={lotName}
                        //onChange={handleLotNameChange}
                        fullWidth
                        margin="normal"
                        //variant="outlined"
                        variant="standard"
                        error={lotNameError}
                        helperText={lotNameError ? "Campo obrigatório" : ""}
                    />
*/}
        <FormInputFooter
          data={data}
          setData={setData}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          isError={isError}
          handleClose={handleClose}
          returnError={returnError}
        />
      </Grid>
    </>
  );
}
