import { useContext, useState } from 'react';
import { Box, Container, Grid, Input, Paper, Stack, Typography } from '@mui/material';
import FormInputStep1NumAnimal from './FormInputStep1NumAnimal';
import FormInputStep3AreasMaps from './FormInputStep3AreasMaps';
import FormInputStep4Period from './FormInputStep4Period';
import SuccessInput from './SuccessInput';
import FormInputStep6Confirm from './FormInputStep6Confirm';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import useStore from '../../../../../store/useStore';
import FormInputStep2Batch from './FormInputStep2Batch';
import { Context } from '../../../../../components/Context/AuthContext';
import ResourcesStepFormMainTab from '../../../../activitiesResources/Resources/ResourcesStepFormMainTab';
import InputIcon from '@mui/icons-material/Input';

export default function MultiStepFormInput({ handleClose }) {
  // console.log("MultiStepFormInput");

  const usestore = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controle de submissão

  // pegar o livestock current
  const initCreation = usestore.reposLivestock.getLivestockStore('id', usestore.reposLivestock.livestockRepos.id)[0];
  //const initCreation = usestore.reposLivestock.livestockRepos;
  // console.log("initCreation");
  // console.log(initCreation);
  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  // atualizar o localstorage

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_livestock: initCreation.id,
    id_group: initCreation.id_group,
    //id_areamap: "",
    type_activity: initCreation.activity,
    name_activity: 'ENTRADA',
    stage: '',
    stageData: '',
    numberAnimals: 0,
    nameArea: '',
    nameBatch: '',
    date_start: '',
    date_prev_exit: '',
    areas: [],
    isDeleted: false,
    id_tipe: initCreation.tipe,
    planning: '',
    progress: 'CONCLUÍDA',
    progress_radio: 1,
    machine: [],
  });

  const [activeStep, setActiveStep] = useState(0);
  // const [date, setDate] = useState('');
  // const [text, setText] = useState('');
  // const [selection, setSelection] = useState('');

  const [track, setTrack] = useState(new Date().getTime());

  const handleNext = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // if (aux_time > 500) {
    //   setTrack(currentTime);
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleDateChange = (event) => {
  //   setDate(event.target.value);
  // };

  // const handleTextChange = (event) => {
  //   setText(event.target.value);
  // };

  // const handleSelectionChange = (event) => {
  //   setSelection(event.target.value);
  // };

  const handleReposUpdate = (event) => {
    // atualizar property
    usestore.reposProperty.fetch();
    // atualizar areaMap
    usestore.reposAreas.fetch({ id_farm: property_id });
    // atualizar o livestock
    usestore.reposLivestock.fetch({ id_farm: property_id });
    //atualizar o manejo d pastagem
    usestore.reposActivityPastureManagement.fetch({ id_farm: property_id });
    // atualizar a atividade
    usestore.reposActivityInput.fetch({ id_farm: property_id }).then((res) => {
      // console.log("reposActivitiesInputs - fetch - res");
      //console.log(res);
      setAuxAdd(aux_add + 1);
      handleNext();
      // setTimeout(() => {
      //   //handleClose();
      //   // handleNext();
      // }, 200);
    });
  };

  const { aux_add, setAuxAdd, isMobile } = useContext(Context);

  //controla a qunatidade de passos e o botão confirmar e salvar
  // const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7', 'Step 7'];

  const handleUpdateProgress = async (id, item) => {
    // console.log("handleUpdateProgress")
    // Aqui você pode adicionar a lógica para atualizar o progresso da tarefa
    // com o novo status selecionado (selectedStatus)
    const header = { header: { 'Content-Type': 'application/json' } };

    //console.log(selectedStatus)
    // let data = {};
    let url = '';
    //let reposActivity = {};

    const dataProgress = {
      id_user: item.id_user,
      id_activity: id,
      name_activity: item.activity,
      progress: item.progress,
      oldProgress: item.progress,
      nameBatch: item.nameBatch,
      // planning: "realized",
      //updated_at: new Date(),
    };
    url = `activityInput/updateprogress`;
    //reposActivity = usestore.reposActivityInput;

    if (true) {
      // console.log("dataProgress")
      // console.log(dataProgress)
      // const res = await api
      await api
        .put(url, dataProgress, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Progresso da atividade atualizado com sucesso!');
            setTimeout(() => {
              handleReposUpdate();
            }, 200);
            // handleClose();

            // atualizar property
            // atualizar areaMap
            //usestore.reposAreas.fetch({ 'id_farm': idFarm });
            // atualizar o livestock
            //usestore.reposLivestock.fetch({ 'id_farm': idFarm });
            // reposActivity.fetch().then((res) => {
            //   setAuxAdd(aux_add + 1);

            //   // if (data.progress == "CONCLUÍDA") {
            //   //   setSuccess(true);
            //   // } else {
            //   //   setSuccess(false);
            //   // }
            //   setTimeout(() => {
            //     handleClose();
            //   }, 200)
            // });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro em atualizar o progresso da atividade. Tente novamente mais tarde.');

          //handleNext();
        });
    } else {
      //return false;
    }
    //handleClose();
  };

  const onSubmit = async () => {
    // console.log("entrou no submit - MultiStepFormInput");
    setIsSubmitting(true); // Desabilita o botão ao iniciar a submissão

    if (true) {
      const header = {
        header: {
          'Content-Type': 'application/json',
        },
      };
      //const propertyId = JSON.parse(localStorage.getItem("FarmIdcurrent"));
      // message.id_property = propertyId;
      // console.log(data);
      data.areas[0].coordinates = '';
      data.areas[0].image = '';
      // const response = await api
      await api
        .post('/activityInput', data, header)
        .then((res) => {
          //const res = true
          // console.log("res");
          // console.log(res.data);

          if (res.data.isSuccess) {
            //console.log("true");
            toast.success('Entrada salva com sucesso!');
            //fetchTodos();

            if (data.progress == 'CONCLUÍDA') {
              // console.log("CONCLUÍDA")
              // console.log(res.data._value.insertId)
              setTimeout(() => {
                handleUpdateProgress(res.data._value.insertId, data);
              }, 200);
            } else {
              handleReposUpdate();
            }
          } else {
            toast.error(`Opss.. Ocorreu algum erro ao registrar a entrada de animais,por favor, tente novamente.`);
            console.log('erro');
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar a entrada de animais, tente novamente mais tarde!');
          setIsSubmitting(false); // Reabilita o botão em caso de erro
        });
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      isMobile: isMobile.any(),
    };
    console.log(step);
    switch (step) {
      case 0:
        // return <FourthStepFormInputOthers data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
        return <FormInputStep1NumAnimal data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 1:
        return <FormInputStep2Batch data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 2:
        return <FormInputStep3AreasMaps data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 3:
        return <FormInputStep4Period data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      // case 4:
      //   return <FormInputStep5Others data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 4:
        // recursos
        return (
          <>
            <Grid container>
              <Grid container spacing={3} sx={{ mt: 3 }}>
                <InputIcon />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5} sx={{ mt: -0.5 }}>
                    <Typography variant="h6" fontWeight={500}>
                      Entradas dos animais
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item sx={{ mt: 1, height: 120 }}>
                  <img style={{ maxHeight: 120 }} src="/dist/image/lotedeanimaisnaareadata.png" alt="image" class="img-fluid" />
                </Grid>
              </Grid>
              {/* <Grid container spacing={3} sx={{ mt: 1 }}>
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Essa atividade será realizada:</Typography>
                  </Stack>
                </Box>
              </Grid> */}
            </Grid>
            <ResourcesStepFormMainTab data={data} obj_fn={obj_fn} />
          </>
        );
      case 5:
        return (
          <FormInputStep6Confirm
            data={data}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            setData={setData}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 6:
        return <SuccessInput handleClose={handleClose} data={data} />;
      default:
        return 'Não existente';
    }
  };

  // console.log("2-RENDER");
  console.log(activeStep === steps.length);
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {getStepContent(activeStep)}
        {/* {activeStep === steps.length ? (
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessInput handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        ) : (
          <>
      
            {getStepContent(activeStep)}
          </>
        )} */}
      </Box>
    </>
  );
}
