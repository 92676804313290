import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit, LocationOn } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green, grey } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CardPropertyProducts from '../../property/components/CardPropertyProducts';
import { Span } from '../../../components/Typography';
import MenuPlan from './MenuPlan';
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

export default function AccessLivestockHarvest({ listPropertyRepos }) {
    const navigate = useNavigate();
    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>
            <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Listas das atividades agrícolas</Typography>

            </Box>
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/activity_harvest.png"
                        title="atividades"
                    />
                </Card>
            </Box>


        </>
    );
}

//https://aegro.com.br/termos-de-uso/