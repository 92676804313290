import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Card, Container, Fab, Paper, Button, Dialog } from '@mui/material';
import { Divider, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import api from '../../../../services/api';
import useStore from '../../../../store/useStore';
import { Span } from '../../../../components/Typography';
import { Context } from '../../../../components/Context/AuthContext';
import SuccessCreateProperty from '../successProperty/SuccessCreateProperty';
import FormProperty from './FormProperty';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PropertyButton() {
  const [open, setOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);

  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;
  const propertyActive = usestore.reposProperty.getPropertyStore('id', propertyRepos.id)[0];
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const [property, setProperty] = useState({
    name: 'Fazenda ' + (usestore.reposProperty.listProperty.length + 1),
    id_user: userCurrent.id,
    area: propertyActive.area || '',
    measure: propertyActive.measure || 'ha',
    ownerName: propertyActive.ownerName,
    address: '',
    state: '',
    city: '',
    image: '',
    tel: '',
    description: '',
    isDeleted: 0,
  });
  //console.log(property);

  const handleClickOpen = () => {
    setIsCreated(false);
    setOpen(true);
  };

  const handleClose = () => {
    setProperty({
      name: 'Fazenda ' + (usestore.reposProperty.listProperty.length + 1),
      id_user: userCurrent.id,
      area: '',
      measure: 'ha',
      ownerName: propertyActive.ownerName,
      address: '',
      state: '',
      city: '',
      image: '',
      tel: '',
      description: '',
      isDeleted: 0,
    });
    setFormError({ name: '', ownerName: propertyActive.ownerName, area: propertyActive.area });
    setIsCreated(false);
    setOpen(false);
  };

  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({ name: '', ownerName: propertyActive.ownerName, area: propertyActive.area });

  const onSubmit = async (e) => {
    e.preventDefault();
    //console.log(property);
    let formOk = true;

    if (formError.name != '') {
      formOk = false;
    } else if (!property.area) {
      formOk = false;
    } else if (property.ownerName.length < 5) {
      formOk = false;
    }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const response = await api
        .post('/property', property, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('Propriedade salva com sucesso!');
            usestore.reposProperty.fetch().then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
                handleClose();
              }, 200);
            });
          } else {
            toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios');
    }
  };

  return (
    <div>
      <Button variant="outlined" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Propriedade
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {isCreated && <SuccessCreateProperty />}
        <Box>
          <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
            <Toolbar>
              <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                Registrar uma Nova Propriedade
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: '#000' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* <Card sx={{ m: 2 }}> */}
          <Container component="main" maxWidth="md" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <FormProperty property={property} setProperty={setProperty} formError={formError} setFormError={setFormError} usestore={usestore} onSubmit={onSubmit} />
            </Paper>
          </Container>
          {/* </Card> */}
          <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                  <Span>Enviar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Dialog>
    </div>
  );
}
