import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export function CloseButton({ handleClose }) {
  return (
    <IconButton variant="contained" onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );
}
