import * as React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography } from '@mui/material'
import api from '../../../../services/api'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { Context } from '../../../../components/Context/AuthContext'
import { useParams } from 'react-router-dom'
import AnimalsPasture from '../success/AnimalsPasture'

export default function ColorChips({ size, row_data, usestore }) {
  // console.log('ColorChips ')
  const status = row_data.progress

  let color
  let variant
  switch (status) {
    case 'A FAZER':
      color = 'success'
      variant = 'outlined'
      break
    case 'EM PROGRESSO':
      color = 'primary'
      break
    case 'CONCLUÍDA':
      color = 'success'
      break
    case 'REVISAR':
      color = 'default'
      variant = 'outlined'
      break
    case 'PLANEJAMENTO':
      color = 'success'
      break
    default:
      color = 'default'
      break
  }

  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(status)
  const [success, setSuccess] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus)
  }

  const { aux_add, setAuxAdd } = useContext(Context)
  const { idFarm } = useParams()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  //const fetchAreas = useAreasMapReposStore(state => state);
  //const fetchLivestock = useLivestockReposStore(state => state);

  const handleUpdateProgress = async () => {
    // Aqui você pode adicionar a lógica para atualizar o progresso da tarefa
    // com o novo status selecionado (selectedStatus)
    const header = { header: { 'Content-Type': 'application/json' } }

    let data = {}
    let url = ''
    let reposActivity = {}

    if (row_data.activity == 'ENTRADA') {
      data = {
        //id_user: "1",
        id_activity: row_data.id,
        name_activity: row_data.activity,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        nameBatch: row_data.nameBatch,
        // planning: "realized",
        //updated_at: new Date(),
      }
      url = `activityInput/updateprogress`
      reposActivity = usestore.reposActivityInput
    } else if (row_data.activity == 'IDENTIFICAÇÃO') {
      reposActivity = usestore.reposActivityIdentify
      data = {
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: reposActivity.getActivityIdentifyStore('id', row_data.id)[0],
      }
      url = `/activityIdentify/update`
    } else if (row_data.activity == 'PESAGEM') {
      reposActivity = usestore.reposActivityWeight
      data = {
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        id_user: userCurrent.id,
        activityData: reposActivity.getActivityWeightStore('id', row_data.id)[0],
      }
      url = `/activityWeight/update`
    } else if (row_data.activity == 'MEDICAMENTOS') {
      reposActivity = usestore.reposActivityMedicines
      data = {
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        id_user: userCurrent.id,
        activityData: reposActivity.getActivityMedicinesStore('id', row_data.id)[0],
      }
      url = `/activityMedicines/update`
    } else if (row_data.activity == 'TRANSFERENCIA') {
      reposActivity = usestore.reposActivityTransferBatch
      //        //id_user: string,
      //   id_activity: string,
      //   name_activity: string,
      //   progress: string,
      //   nameBatch: string,
      //   oldProgress: string,
      //   //planning: string,
      //   updated_at: Date,
      // }
      const dataAtual = new Date()
      data = {
        id_activity: row_data.id,
        progress: selectedStatus,
        //name_activity: row_data.name_activity,
        oldProgress: row_data.progress,
        nameBatch: row_data.nameBatch,
        change_progress: true,
        id_user: userCurrent.id,
        updated_at: dataAtual.toISOString().slice(0, 19).replace('T', ' '),
        //activityData: reposActivity.getActivityTransferBatchStore('id', row_data.id)[0]
      }
      url = `/activityTransfer/updateprogress`
    } else if (row_data.activity == 'PRODUÇÃO') {
      reposActivity = usestore.reposActivityProduction;
      data = {
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        id_user: userCurrent.id,
        activityData: reposActivity.getActivityProductionStore('id', row_data.id)[0],
      }
      url = `/activityProduction/update`;
    }

    if (true) {
      //console.log(data)
      const res = await api
        .put(url, data, header)
        .then((response) => {
          //console.log(response.data)
          if (response.data.isSuccess) {
            toast.success('Progresso da atividade atualizado com sucesso!')
            // atualizar property
            usestore.reposProperty.fetch()
            // atualizar areaMap
            usestore.reposAreas.fetch({ id_farm: idFarm })
            // atualizar o livestock
            usestore.reposLivestock.fetch({ id_farm: idFarm })
            reposActivity.fetch().then((res) => {
              setAuxAdd(aux_add + 1)

              if (data.progress == 'CONCLUÍDA') {
                setSuccess(true)
              } else {
                setSuccess(false)
              }
              setTimeout(() => {
                handleClose()
              }, 200)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error(error.response.data)
          setTimeout(() => {
            handleClose()
          }, 200)
        })
    } else {
      //return false;
    }
    //handleClose();
  }

  return (
    <div>
      {success && <AnimalsPasture />}
      <Chip label={status} color={color} variant={variant} size={size} onClick={handleOpen} disabled={selectedStatus === 'CONCLUÍDA'}/>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        {/* <DialogTitle>Atualizar Progresso</DialogTitle> */}
        <DialogContent>
          {/* <p>SITUAÇÃO: {status}</p> */}
          <Typography variant="subtitle1" sx={{ mt: 0, mb: 2 }}>
            Atualizar atividade:
          </Typography>
          <Grid container spacing={1}>
            <Grid item sx={{ ml: 1 }}>
              <Box sx={{ border: '0px solid #ccc', borderRadius: '4px' }}>
                <Stack direction="column" spacing={1}>
                  <Chip label="A FAZER" color={selectedStatus === 'A FAZER' ? 'success' : 'default'} onClick={() => handleStatusChange('A FAZER')} />
                  <Chip label="EM PROGRESSO" color={selectedStatus === 'EM PROGRESSO' ? 'primary' : 'default'} onClick={() => handleStatusChange('EM PROGRESSO')} />
                  <Chip label="CONCLUÍDA" color={selectedStatus === 'CONCLUÍDA' ? 'success' : 'default'} onClick={() => handleStatusChange('CONCLUÍDA')} />
                </Stack>
              </Box>
            </Grid>
            {/* <Grid item>
              <Box sx={{ border: "0px solid #ccc", borderRadius: "4px" }}>
                <Stack direction="column" spacing={1}>
                  <Chip variant="outlined" label="REVISAR" color={selectedStatus === "REVISAR" ? "default" : "default"} onClick={() => handleStatusChange("REVISAR")} />
                  <Chip label="PLANEJAMENTO" color={selectedStatus === "PLANEJAMENTO" ? "success" : "default"} onClick={() => handleStatusChange("PLANEJAMENTO")} />
                </Stack>
              </Box>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="success" onClick={handleClose}>
            Cancelar
          </Button>
          <Button size="small" variant="contained" color="success" onClick={handleUpdateProgress}>
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
