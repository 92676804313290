import ResetPasswordContent from "./ResetPasswordContent";

function ResetPassword() {
    return (
        <>

            <ResetPasswordContent />

        </>
    );
}

export default ResetPassword;

