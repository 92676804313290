import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';
import { object } from "yup";

const formatTypes = (types) => {
  let aux_types = {
    cliente: (/C|cliente/.test(types) ? true : false),
    fornecedor: (/F|fornecedor/.test(types) ? true : false),
    transportadora: (/T|transportadora/.test(types) ? true : false),
  };

  return aux_types;
};
// create the store
const usePersonReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPerson: [],
        listCliente: [],
        listFornecedor: [],
        listTransportadora: [],
        personRepos: { id_property: 0, id: 0, type: '', name: '',  typePerson: '', document_type: '', document: ''},
        fetch: async (item) => {
          var id_property = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_property': id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("person/list", (item), header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          let list_cliente = [];
          let list_fornecedor = [];
          let list_transportadora = [];
          let aux;
          await set({
            listPerson:
              data_map.map((person) => {
                aux = {
                  id: person.id,
                  id_property: person.id_property,
                  id_account: person.id_account,
                  id_accounts: person.id_accounts,
                  type: person.type,
                  types: formatTypes(person.types) || '',
                  types_s: person.types_s,
                  name: person.name,
                  typePerson: person.typePerson,
                  document_type: person.document_type,
                  document: person.document,
                  corporateReason: person.corporateReason,
                  stateRegistration: person.stateRegistration,
                  municipalRegistration: person.municipalRegistration,
                  simples_nacional: person.simples_nacional,
                  dateBirth: !person.dateBirth ? '' : dayjs(person.dateBirth).format('YYYY-MM-DD HH:mm:ss'),
                  dateBirth_f: !person.dateBirth ? '' : dayjs(person.dateBirth).format('DD/MM/YYYY'),
                  observations: person.observations,
                  date_update: dayjs(person.date_update).format('YYYY-MM-DD HH:mm:ss'),
                  date_update_f: dayjs(person.date_update).format('DD/MM/YYYY'),
                  isDeleted: person.isDeleted,
                  contacts: person.contacts,
                  addresses: person.addresses,
                }
                if (aux.type == 'Cliente') {
                  list_cliente = list_cliente.concat(aux);
                }
                if (aux.type == 'Fornecedor') {
                  list_fornecedor = list_fornecedor.concat(aux);
                }
                if (aux.type == 'Transportadora') {
                  list_transportadora = list_transportadora.concat(aux);
                }
                return aux;
              })
          })
          set({
            personRepos: (!data_map || data_map.length == 0 ? 
              { id_property: 0, id: 0, type: '', name: '',  typePerson: '', document_type: '', document: ''} : 
              { id_property: data_map[0].id_property, id: data_map[0].id, type: data_map[0].type, name: data_map[0].name,  typePerson: data_map[0].typePerson, document_type: data_map[0].document_type, document: data_map[0].document})
          })
          set({
            listCliente: (list_cliente),
            listFornecedor: (list_fornecedor),
            listTransportadora: (list_transportadora),
          });

          return data;
        },
        changePersonRepos: (personRepos) => set((state) => ({ personRepos })),
        // pega as pessoas da listPerson de acordo com a chave passada
        getPersonStore: (key, val) => {
          return get().listPerson.filter((person) => {
            if (person[key] == val) {
              if (typeof person.types != 'object') {
                person.types = formatTypes(person.types_s);
              }
              return person;
            }
          })
        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex1: [['id', 1], ['type', 'Cliente']] -> pessoa de id = 1 do tipo Cliente ... lembrando que 1 pessoa pode ter multiplos tipos
        //Ex2: [['type', 'Fornecedor'], ['typePerson', 'Jurídica']] -> todos os fornecedores do tipo Jurídico
        getCompostPersonStore: (keys) => {
          return get().listPerson.filter((person) => {
            let count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (person[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            if (count == keys.length) {
              return person;
            }
          })
        },
        countPersons: (key, val) => {
          let count = 0;
          get().listPerson.filter((person) => {
            if (person[key] == val) {
              count++;
            }
          });
          return count;
        },

        // Adiciona um novo banco à listPerson
        addPersonStore: (person) => {
          set(state => ({
            listPerson: [...state.listPerson, person]
          }))
        },
        // Gera uma nova listPerson sem a person informada
        delPersonStore: (id) => {
          set({
            listPerson:
              get().listPerson.filter((person) => {
                if (person.id != id) {
                  return person;
                }
              })
          })
        },
        // atualiza listPerson de acordo com id, key e val informados
        updatePersonStore: (id, key, val) => {
          set({
            listPerson:
              get().listPerson.map((person) => {
                if (person.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    person = val;
                  } else {
                    person[key] = val;
                  }
                }
                return person;
              })
          })
        },
      }),
      { name: "reposPerson" }
    )
  )
);

export default usePersonReposStore;