import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';

const repos_default = {
  entradas: 0,
  saidas: 0,
  saldo: 0,
  receber_hoje: 0,
  receber_mes: 0,
  pagar_hoje: 0,
  pagar_mes: 0,
  receber_atrasado: 0,
  pagar_atrasado: 0,
  receber_futuro: 0,
  pagar_futuro: 0,
};

// create the store
const useFinancesReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listFinance: [],
        financesRepos: repos_default,
        fetch: async (item) => {
          const header = { header: { 'Content-Type': 'application/json' } };
          var id_property = localStorage.getItem('Property') || 1;
          var ano_atual = new Date().getFullYear();

          if (!item) {
            item = { id_property: id_property, ano: ano_atual };
          } else {
            if (!item.id_property) {
              item.id_property = id_property;
            }
            if (!item.ano) {
              item.ano = ano_atual;
            }
          }
          let data_map = [repos_default];

          await api.post('property/financas', item, header)
            .then((response) => {
              const data = response.data;
              // console.log('financas');
              // console.log(data._value);
              data_map = data.isSuccess ? data._value : [];
            })
            .catch((error) => {
              console.log('error: property/financas');
              console.log(error);
            });

          await set({
            listFinance: data_map.map((finance) => {
              repos_default.entradas = finance.entradas;
              repos_default.saidas = finance.saidas;
              repos_default.saldo = finance.saldo;
              repos_default.receber_hoje = finance.receber_hoje;
              repos_default.receber_mes = finance.receber_mes;
              repos_default.pagar_hoje = finance.pagar_hoje;
              repos_default.pagar_mes = finance.pagar_mes;
              repos_default.receber_atrasado = finance.receber_atrasado;
              repos_default.pagar_atrasado = finance.pagar_atrasado;
              repos_default.receber_futuro = finance.receber_futuro;
              repos_default.pagar_futuro = finance.pagar_futuro;

              return repos_default;
            }),
          });

          set({
            financesRepos: repos_default,
          });

          return data_map;
        },
        changeFinanceRepos: (financesRepos) => {
          set((state) => ({ financesRepos }));
        },
        // pega a(s) finance(s) da listFinance de acordo com o [key e val] informados
        getFinanceStore: (key, val) => {
          return get().listFinance.filter((finance) => {
            return finance[key] === val;
          });
        },
        // Adiciona uma nova finance à listFinance
        addFinanceStore: (finance) => {
          set((state) => ({
            listFinance: [...state.listFinance, finance],
          }));
        },
        // Gera uma nova listFinance sem a finance informada
        delFinanceStore: (id) => {
          set({
            listFinance: get().listFinance.filter((finance) => {
              return finance.id !== id;
            }),
          });
        },
        // atualiza listFinance de acordo com id, key e val informados
        updateFinanceStore: (id, key, val) => {
          set({
            listFinance: get().listFinance.map((finance) => {
              if (finance.id === id) {
                if (key === 'all') {
                  // atualiza todos os dados
                  finance = val;
                } else {
                  finance[key] = val;
                }
              }
              return finance;
            }),
          });
        },
        listTransactions: [],
        fetchT: async (item) => {
          const header = { header: { 'Content-Type': 'application/json' } };
          var id_property = localStorage.getItem('Property') || 1;

          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const response = await api.post('finances/list', item, header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);

          await set({
            listTransactions: data_map.map((trans) => ({
              id: trans.id,
              id_property: trans.id_property,
              id_user: trans.id_user,
              id_harvest: trans.id_harvest,
              id_livestock: trans.id_livestock,
              description: trans.description,
              date_entry: trans.date_entry,
              date_update: trans.date_update,
              quanty: trans.quanty,
              value: trans.value,
              type: trans.type,
              record: JSON.parse(trans.record),
              info: JSON.parse(trans.info),
              fkys: JSON.parse(trans.fkys),
              //  id: trans.id,
              //  id_transaction: trans.id_transaction,
              //  id_account: trans.id_account,
              //  id_person: trans.id_person,
              //  id_bank: trans.id_bank,
              //  id_product: trans.id_product,
              //  id_product_location: trans.id_product_location,
              //  id_credit_card: trans.id_credit_card,
              //  id_patrimony: trans.id_patrimony,
              //  id_bills_pay: trans.id_bills_pay,
              //  id_bills_receive: trans.id_bills_receive,
              credit: trans.credit,
              debit: trans.debit,
            })),
          });
          return data_map;
        },
        // pega a(s) transação(õess) da listTransactions de acordo com o [key e val] informados
        getTransactionStore: (key, val) => {
          return get().listTransactions.filter((finance) => {
            return finance[key] === val;
          });
        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex1: [['id', 1], ['type', 'Cliente']] -> pessoa de id = 1 do tipo Cliente ... lembrando que 1 pessoa pode ter multiplos tipos
        //Ex2: [['type', 'Fornecedor'], ['typePerson', 'Jurídica']] -> todos os fornecedores do tipo Jurídico
        getCompostTransactionStore: (keys) => {
          return get().listTransactions.filter((trans) => {
            let count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (trans[keys[i][0]] === keys[i][1]) {
                count++;
              }
            }
            return count === keys.length;
          });
        },
      }),
      { name: 'reposFinances' }
    )
  )
);

export default useFinancesReposStore;
