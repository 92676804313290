import * as React from 'react';
import List from '@mui/material/List';
import { Box, ButtonBase, Paper, Stack, Typography } from '@mui/material';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import IconsLivestockActivities from '../../../components/Icons/IconsLivestockActivities';
import SpaIcon from '@mui/icons-material/Spa';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function CreateProjectList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClickLivestock = () => {
    navigate('/livestock');
  };
  const handleButtonClickHarvest = () => {
    navigate('/harvest');
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {/* <div className="d-lg-none">
        <Box>
          <Typography gutterBottom sx={{ mt: 0, mb: 1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
            <Typewriter tam={70} text="Criado a área de produção (Piquete, Talhão...) é hora de seguir para o passo 3." />
          </Typography>
        </Box>
      </div>
      <div className="d-none d-lg-block">
        <Box>
          <Typography gutterBottom sx={{ mt: 0, mb: 1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
            <Typewriter tam={40} text="Criado a área de produção (Piquete, Talhão...) é hora de seguir para o passo 3." />
          </Typography>
        </Box>
      </div> */}
      {/* <ListItem
        key={null}
        disableGutters
        secondaryAction={
          <IconButton aria-label="comment">
            <CropOriginalIcon style={{ color: '#2e7d32' }} />
          </IconButton>
        }
      >
        <ListItemText primary={<strong>Passo 3:</strong>} secondary="Registrar sua primeira safra ou criação." />
      </ListItem> */}
      <Typography paragraph variant="subtitile1" fontWeight={500} sx={{ mt: 1, mb: 2 }}>
        Clique no botão "Criações" para acessar a página onde você poderá iniciar a criação de um projeto pecuário para seus animais ou cliquem em "Safras" para iniciar a criação de um projeto
        agrícola .
      </Typography>
      <Box style={{ display: 'flex' }}>
        <ButtonBase
          component={Paper}
          elevation={2}
          sx={{ p: 2, borderRadius: 1, margin: '0 10px', animation: `${expandAndFade} 2s linear infinite` }}
          onClick={() => handleButtonClickLivestock()}
          className={classes.button}
        >
          <Stack direction="column" alignItems="center">
            <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              Criações
            </Typography>
          </Stack>
        </ButtonBase>
        <ButtonBase
          component={Paper}
          elevation={2}
          sx={{ p: 2, borderRadius: 1, margin: '0 10px', animation: `${expandAndFade} 2s linear infinite` }}
          onClick={() => handleButtonClickHarvest()}
          className={classes.button}
        >
          <Stack direction="column" alignItems="center">
            <SpaIcon />
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              Safras
            </Typography>
          </Stack>
        </ButtonBase>
      </Box>
      {/* <Box sx={{ mt: 2 }}>
        <Typography paragraph variant="subtitile2" fontWeight={300}>
          Com um projeto personalizado, você terá total controle para gerenciar insumos, custos de produção, e monitorar cada animal ou cultura de forma detalhada e eficiente. Vamos lá?
        </Typography>
      </Box> */}
    </List>
    // <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
    //   <Box>
    //     <ListItem
    //       key={null}
    //       disableGutters
    //       secondaryAction={
    //         <span aria-label="comment">
    //           <SpeedDialIcon style={{ color: '#2e7d32' }} />
    //         </span>
    //       }
    //     >
    //       <ListItemText
    //         primary={`Passo 1: Acessar o botão para criação do projeto.`}
    //         secondary={`Para criar um projeto de safra ou de criação, clique no botão verde localizado no canto inferior direito da tela do mapa da propriedade.`}
    //       />
    //     </ListItem>
    //     <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
    //       <img alt="Imagem" src="/dist/image/map_buttonGreen.png" style={{ maxWidth: '100%' }} />
    //     </ListItem>
    //   </Box>

    //   <Box>
    //     <ListItem
    //       key={null}
    //       disableGutters
    //       secondaryAction={
    //         <span aria-label="comment">
    //           <AccountTreeIcon />
    //         </span>
    //       }
    //     >
    //       <ListItemText primary={`Passo 2: Para criar um projeto.`} secondary={` Com a tela aberta, clique na opção "Projeto" para criar uma projeto no Fazenda Premium.`} />
    //     </ListItem>
    //     <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
    //       <img alt="Imagem" src="/dist/image/map_project.png" style={{ maxWidth: '100%' }} />
    //     </ListItem>
    //   </Box>
    // </List>
  );
}
