import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, Grid, TextField, MenuItem, CircularProgress, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import useStore from '../../../../store/useStore';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';

export default function FormPropertyArea({ map, center, setCenter, handleClose, setIsLoadingMap, address }) {
  const navigate = useNavigate();
  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;
  const property = usestore.reposProperty.getPropertyStore('id', propertyRepos.id)[0];
  const display_demo = JSON.parse(localStorage.getItem('display'));

  const refreshPage = () => {
    navigate(0);
  };

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});
  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false);
  }, []);

  const [propertyArea, setPropertyArea] = useState({
    id: property.id,
    name: property.label,
    id_property: property.id,
    lat: center.lat || '',
    lng: center.lng || '',
    zoom: center.zoom || 14.8,
    address: property.address || center.address,
    description: '',
    area: property.area || '',
    measure: property.measure || '',
    city: address?.city || property.city || '',
    state: address?.state || property.state || '',
  });
  const [isEditable, setIsEditable] = useState(false);

  // input do formulário
  const handleChange = (event, tipo) => {
    event.persist();
    let value = event.target.value.trim();
    if (tipo === 'area') {
      value = parseFloat(event.target.value);
    }
    setPropertyArea({ ...propertyArea, [event.target.name]: value });

    if (tipo === 'name') {
      validatePropertyName(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('entrou no submit');
    setLoadingButton(true);

    setFormError({});
    let errors = {};
    let formOk = true;

    // if (propertyArea.area == '' || isNaN(propertyArea.area)) {
    //   errors.area = true;
    //   formOk = false;
    // }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .put('/property/local', propertyArea, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success(`${propertyArea.name} se encontra no GOOGLE MAPS`);
            usestore.reposProperty.changePropertyRepos({ label: propertyArea.name, id: property.id });
            usestore.reposProperty.fetch().then((resp) => {
              // setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
        });
    } else {
      // escreve mensagem de erro para cliente
      setLoadingButton(false);
      toast.error('Por favor, preencha os campos obrigatórios');
      setFormError(errors);
    }
  };

  const [validName, setValidName] = useState([true, '']);

  // para validar o input
  const validatePropertyName = (value) => {
    var name_property = value.trim();
    if (!name_property || name_property.length < 5) {
      setValidName([false, 'Por favor, insira um nome válido para sua propriedade!']);
    } else {
      var list_propertys = usestore.reposProperty.getPropertyStore('name', name_property);
      if (list_propertys.length > 0) {
        var compare_property = list_propertys.filter((property) => {
          if (property.id != propertyArea.id) {
            return property;
          }
        });
        if (compare_property.length > 0) {
          setValidName([false, 'Já existe outra Propriedade com esse nome!']);
        }
      } else {
        setValidName([true, '']);
      }
    }
  };
  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return <span id="NamePropertyError">*Mínimo 5 letras</span>;
      } else {
        return (
          <span id="NamePropertyError" className="form-text text-danger is-invalid">
            {validName[1]}
          </span>
        );
      }
    } else {
      return validName[num];
    }
  };

  const currencies = [
    {
      value: 'ha',
      label: 'hectare (ha)',
    },
    {
      value: 'm2',
      label: 'metros (m²)',
    },
  ];

  console.log(propertyArea.area);
  console.log(isNaN(propertyArea.area));
  return (
    <Box onSubmit={(e) => onSubmit(e)} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: -1, mb: -2 }}>
          <FormControlLabel control={<Checkbox checked={isEditable} onChange={() => setIsEditable(!isEditable)} color="success" />} label="Editar Conteúdo" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            required
            id="name"
            name="name"
            label="Nome da Propriedade"
            variant="standard"
            color="success"
            value={propertyArea.name || ''}
            onChange={(e) => handleChange(e, 'name')}
            onBlur={(e) => handleChange(e, 'name')}
            error={!returnValidName(0)}
            helperText={returnValidName(1)}
            disabled={!isEditable}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="address"
            name="address"
            label="Endereço"
            fullWidth
            variant="standard"
            color="success"
            value={propertyArea.address || ''}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="lat"
            name="lat"
            label="Latitude"
            fullWidth
            variant="standard"
            color="success"
            size="small"
            value={propertyArea.lat || ''}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="lng"
            name="lng"
            label="Longitude"
            fullWidth
            variant="standard"
            color="success"
            size="small"
            value={propertyArea.lng || ''}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="caption" color="text.secondary" component="div">
            Ao editar a latitude e longitude, o mapa mudará de posição.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{
              sx: {
                color: '#2e7d32',
              },
            }}
            required
            id="area"
            name="area"
            label="Área total"
            variant="standard"
            type="number"
            color="success"
            size="small"
            value={propertyArea.area || ''}
            onChange={(e) => handleChange(e, 'area')}
            disabled={!isEditable}
            helperText={isNaN(propertyArea.area) ? 'Preencha a área total' : 'Campo obrigatório'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="measure"
            name="measure"
            label="Padrão de medida"
            select
            value={propertyArea.measure}
            onChange={handleChange}
            variant="standard"
            color="success"
            disabled={!isEditable}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          {display_demo ? (
            <div></div>
          ) : (
            <>
              <Button sx={{ width: 100, position: 'relative', animation: `${expandAndFade} 2s linear infinite` }} color="success" variant="contained" type="submit" onClick={onSubmit}>
                {LoadingButton ? <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} /> : 'Salvar'}
              </Button>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>
                  Clique em "SALVAR" para registrar o mapa e atualizar as informações no sistema.
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
