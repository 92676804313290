import { AddCircleOutline, AddLocationAlt, Agriculture, Campaign, Grass, LocationSearching, PestControl, WrongLocation } from '@mui/icons-material'
import { IconSoilPreparation } from './unit/IconSoilPreparation'
import { IconSeedTreatment } from './unit/IconSeedTreatment'
import { IconPlanting } from './unit/IconPlanting'
import { IconFertilization } from './unit/IconFertilization'
import { IconHarvesting } from './unit/IconHarvesting'
import RadarIcon from '@mui/icons-material/Radar'

export default function IconsHarvestActivities({ activity, widthStyles, fill }, props) {
  //console.log("IconsHarvestActivities");
  //console.log(activity)
  const width = widthStyles || '32px'
  switch (activity) {
    case 'PREPARAÇÃO DO SOLO':
      return <IconSoilPreparation widthStyles={width} fill={fill} />
    case 'TRATAMENTO DE SEMENTE':
      return <IconSeedTreatment widthStyles={width} fill={fill} />
    case 'PLANTIO':
      return <IconPlanting widthStyles={width} fill={fill} />

    case 'FERTILIZAÇÃO':
      return <IconFertilization widthStyles={width} fill={fill} />
    case 'COLHEITA':
      return <IconHarvesting widthStyles={width} fill={fill} />
    case 'VINCULAÇÃO DE ÁREAS':
      return <AddLocationAlt widthStyles={width} />
    case 'DESVINCULAÇÃO DE ÁREAS':
      return <WrongLocation widthStyles={width} />
    case 'MÁQUINAS':
      return <Agriculture widthStyles={width} />
    case 'APLICAÇÃO':
      return <LocationSearching widthStyles={width} />
    case 'MONITORING':
      return <RadarIcon widthStyles={width} />
    case 'MARKETING':
      return <Campaign widthStyles={width} />
    case 'ADICIONAR OUTRA':
      return <AddCircleOutline widthStyles={width} />
    default:
      return <Grass widthStyles={width} />
  }
}
