import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Calculator from "../../components/Calculator/Calculator";
import { useContext } from "react";
//import { ThemeContext } from "../../components/context-api/ThemeContext";
//import { modelPecuariaCorte } from "../../bd/modelPecuariaCorte";
import { Context } from "../../components/Context/AuthContext";
import Loading from "../../components/Loader/Loading";
import { toast } from "react-toastify";
import Dialog from "../../components/Dialog/Dialog";

export default function ControlPanelSpecificAreaContent() {
  return (
    //<div className="content-wrapper bg-white" style={{ marginTop: 180 }}>
    <div className="content-wrapper bg-white ">
      {/* <div className="content-header" style={{ marginTop: 60 }}>
      </div> */}

      <section className="">
        <div className="">
          {/* <StatCards /> */}
          {/* <Dialog /> */}
          {/* <CardPainelSpecificArea />  */}
        </div>
      </section>
    </div>
  );
}
