import dayjs from 'dayjs'
import iconFazenda from '../../../../../assets/jpg/iconfazenda.jpg'
import cocho from '../../../../../assets/jpg/cocho.jpeg'

let listLivestock = []

// Verifica se reposLivestock existe no localStorage
let reposLivestockString = localStorage.getItem('reposLivestock')
if (reposLivestockString) {
  // Converte a string JSON em um objeto JavaScript
  let reposLivestock = JSON.parse(reposLivestockString)
  listLivestock = reposLivestock.state.listLivestock
  console.log('listLivestock')
  console.log(listLivestock)
} else {
  console.log('Nenhum dado encontrado em reposLivestock')
}

console.log(listLivestock)

let dataProperty = []

let resposPropertyString = localStorage.getItem('reposProperty')
//console.log(resposPropertyString);
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString)
  dataProperty = resposProperty.state.listProperty[0]
  console.log('imageProperty')
  console.log(dataProperty)
} else {
  console.log('Nenhum dado encontrado em resposProperty')
}

console.log(dataProperty)

// Função para converter lotes em string
function formatLotes(lotes) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return lotes.map((lote) => `${lote.name} (${lote.total_animals} animais)\n`)
}

function formatData(Inputdata) {
  return !Inputdata ? '' : dayjs(Inputdata).format('DD/MM/YYYY')
}

// Atualizando livestockTableRows
const livestockTableRows = listLivestock.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    { text: item.total_animais || 'Tipo não especificado' }, // Verifica se 'tipo' está disponível
    { text: item.tipo || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.name || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${formatData(item.data_inicial)} \n Fim:    ${formatData(item.data_fim)}` || 'Data não especificada', fontSize: 7 }, // Converte para data
    { text: formatLotes(item.lotes) || 'Lote não especificado', fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ]
})

console.log(livestockTableRows)

const lineBlank = [
  {
    text: '',
    alignment: 'center',
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
]

export const bodyListCreationBW = {
  content: [{ text: ' ', style: 'subheader' }],
}

//adicionando a tabela
bodyListCreationBW.content.push(
  { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
  { text: 'Lista', style: 'subheader' },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [20, 40, '*', '*', 60, '*'],
      fillColor: '#eeeeee',
      body: [
        ['#', 'Animais', 'Tipo', 'Projeto(Criação)', 'data', 'Lote'], // Cabeçalhos da tabela
        //{ text: "", italics: true, color: "gray" },
        ...livestockTableRows, // Linhas de dados da tabela
      ],
    },
    fontSize: 10,
    // bold: true,
  }
)

// adicionando texto
bodyListCreationBW.content.push({
  width: '*',
  text: `Número de criações: ${dataProperty?.total_livestocks} \n Total de animais: ${dataProperty?.total_animais} \n `,
  fontSize: 10,
  color: '#808080',
  margin: [0, 10, 0, 0],
  alignment: 'right',
})
