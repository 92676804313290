import { Badge, Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import useStore from '../../../store/useStore';
import { Context } from '../../Context/AuthContext';
import { useParams } from 'react-router-dom';

function Check() {
  // Estado para verificar se está online
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [open, setOpen] = useState(false); // Estado para abrir/fechar o diálogo
  const [reconnected, setReconnected] = useState(false); // Para exibir "Conexão restabelecida"
  const usestore = useStore();
  const { aux_add } = useContext(Context);
  const { idFarm, id } = useParams();

  useEffect(() => {
    // Função para verificar o status de conexão com a internet
    const checkOnlineStatus = async () => {
      try {
        const online = await fetch('https://www.google.com', { method: 'HEAD', mode: 'no-cors' });
        setIsOnline(true);
      } catch (err) {
        setIsOnline(false);
      }
    };

    // Função para lidar com eventos de status online/offline
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Adiciona os eventos de escuta para mudanças no status online/offline
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    // Polling para verificar o status de conexão a cada 3 segundos
    const intervalId = setInterval(checkOnlineStatus, 3000);
    // Verifica o status da conexão ao mudar aux_add, idFarm ou id
    // checkOnlineStatus();

    // Cleanup: Remove os event listeners e o polling ao desmontar o componente
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
      clearInterval(intervalId);
    };
  }, [aux_add, idFarm, id]);
  //}, [aux_add, idFarm, id]);
  // Exibe o diálogo se o usuário estiver offline
  useEffect(() => {
    if (!isOnline) {
      setOpen(true); // Mostra o diálogo quando estiver offline
      setReconnected(false); // Resetar a mensagem de "conexão restabelecida"
    } else if (open) {
      // Se a conexão foi restabelecida, exibe a mensagem e fecha o diálogo após alguns segundos
      setReconnected(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000); // Fecha o diálogo após 3 segundos
    }
  }, [isOnline, open]);

  return (
    <div className="mr-1">
      {/* Componente que exibe o estado de conexão */}
      {isOnline ? <Badge badgeContent={'online'} color="success" /> : <Badge badgeContent={'offline'} color="primary" />}

      {/* Diálogo que bloqueia o sistema se estiver offline */}
      <Dialog open={open} aria-labelledby="offline-dialog-title" fullScreen>
        <DialogTitle id="offline-dialog-title">
          <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
            <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
              <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
              <Box sx={{ ml: 1 }}>
                <Stack spacing={0.5} sx={{ mt: 0 }}>
                  <Typography variant="h6" fontWeight={500} align="center">
                    Fazenda Premium
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h6" align="center">
            {reconnected ? 'Conexão Restabelecida' : 'Conexão Perdida'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h6" gutterBottom align="center">
              {reconnected ? 'Sua conexão foi restabelecida. Aguarde alguns segundos...' : 'Você está offline. Por favor, verifique sua conexão com a internet.'}
            </Typography>
            <Box sx={{ p: 2, mb: 2 }}>
              <Badge badgeContent={isOnline ? 'online' : 'offline'} color={isOnline ? 'success' : 'error'} />
            </Box>

            {!reconnected && (
              <>
                <Typography variant="body1" gutterBottom align="center">
                  Enquanto você estiver desconectado, o sistema estará bloqueado.
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => window.location.reload()} // Tenta recarregar a página
                  style={{ marginTop: '20px' }}
                >
                  Tentar Reconectar
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Check;
