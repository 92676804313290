import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../../components/Context/AuthContext";
import Loading from "../../components/Loader/Loading";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import SimpleTable from "../../components/Tables/SimpleTable";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function ErrorContent() {
  const [isLoading, setIsLoading] = useState(false)

  if (isLoading) {
    return <Loading />;
  }
  const primary = green[500];
  return (
    <div className="content-wrapper ">
      <div className="content-header">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: primary,
            p: 5
          }}
        >
          <Typography variant="h3" style={{ color: 'white', }}>
            Ops! Desculpe.
          </Typography>
          <Typography variant="h5" style={{ color: 'white', marginTop: 10 }}>
            Dados não encontrado no servidor da Amazon.
          </Typography>
          <Typography variant="subtitle-1" style={{ color: 'white', maxWidth: 900 }}>
            Quando um software se encontra sem acesso ao servidor, possivelmente os banco de dados nas nuvens
            pode estar com algum problema. Entre em contato com o suporte, clicando no botão abaixo.
          </Typography>

          <section className="">
            <div className=" ">
              <div className="card-body pb-0">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div className="card bg-light d-flex flex-fill">
                      <div className="card-header text-muted border-bottom-0">Suporte</div>
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-7">
                            <h2 className="lead">
                              <b>André</b>
                            </h2>
                            <p className="text-muted text-sm">
                              <b>Colaborador </b>
                            </p>
                            <ul className="ml-4 mb-0 fa-ul text-muted">

                              <li className="small">
                                <span className="fa-li">
                                  <i className="fas fa-lg fa-phone" />
                                </span>{" "}
                                Telefone #: +55 31 3899-7027
                              </li>
                            </ul>
                          </div>
                          <div className="col-5 text-center">
                            <img src="../../dist/img/AvatarAndre.png" alt="user-avatar" className="img-circle img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="text-right">
                          {/* <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments" />
                      </a> */}
                          <a href="https://api.whatsapp.com/send?phone=553138997027&text=Deixe%20sua%20mensagem%20%20que%20responderei%20em%20breve." target="_blank" className="btn btn-sm btn-success" rel="noreferrer">
                            <WhatsAppIcon /> Chamada

                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div className="card bg-light d-flex flex-fill">
                      <div className="card-header text-muted border-bottom-0">Suporte</div>
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-7">
                            <h2 className="lead">
                              <b>Pedro Henrique</b>
                            </h2>
                            <p className="text-muted text-sm">
                              <b>Colaborador </b>
                            </p>
                            <ul className="ml-4 mb-0 fa-ul text-muted">

                              <li className="small">
                                <span className="fa-li">
                                  <i className="fas fa-lg fa-phone" />
                                </span>{" "}
                                Telefone #: +55 31 3899-7610
                              </li>
                            </ul>
                          </div>
                          <div className="col-5 text-center">
                            <img src="../../dist/img/avatar2.png" alt="user-avatar" className="img-circle img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="text-right">
                          {/* <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments" />
                      </a> */}
                          <a href="https://api.whatsapp.com/send?phone=553138997610&text=Deixe%20sua%20mensagem%20%20que%20responderei%20em%20breve." target="_blank" className="btn btn-sm btn-success" rel="noreferrer">
                            <WhatsAppIcon /> Chamada

                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Box>
      </div>
    </div >
  );
}
