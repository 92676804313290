import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';
import { Context } from '../../../../../components/Context/AuthContext';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import dayjs from 'dayjs';
import FormProgressSelect from '../FormProgress/FormProgressSelect';
import HeaderModal from '../../../../../components/Header/HeaderModal';
import FormSoilPreparationStep2 from './FormSoilPreparationStep2';
import FormSoilPreparationStep1 from './FormSoilPreparationStep1';
import { IconSoilPreparation } from '../../../../../components/Icons/harvest/unit/IconSoilPreparation';
// import FormSoilPreparationStep3 from './FormSoilPreparationStep3'
import FormSoilPreparationStep4 from './FormSoilPreparationStep4';
import { PendingActions } from '@mui/icons-material';
import ResourcesStepFormMainTab from '../../../../activitiesResources/Resources/ResourcesStepFormMainTab';

export default function MultiStepFormSoilPreparation({ handleClose, type }) {
  // console.log('MultiStepFormPlanting')
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const harvestRepos = usestore.reposHarvest.harvestRepos;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_harvest: harvestRepos.id,
    operationType: 'Preparo do solo',
    selectedOperation: '',
    listOperations: JSON.parse(JSON.stringify(usestore.reposActivitySoilPreparation.listOperations)),

    areas: [],
    /* produto utilizado
      areas.map((area) => (
        area.product
        area.product.location
        area.product.location_selected.quanty
        area.product.location_selected.quant_utilizada
        area.product.location_selected.value
    */

    description: '',
    error_date: false,

    isDeleted: 0,
    progress_radio: 1,
    planning: 'realized',
    progress: 'CONCLUÍDA',
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    // date_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_end: null,
  });

  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState(new Date().getTime());
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    // Evita clique duplo: só permite continuar se já passou mais de 0.5 segundos
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    // Evita clique duplo: só permite continuar se já passou mais de 0.5 segundos
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const validate = () => {
    var valid = false;
    if (data.areas.length) {
      valid = true;
    }
    console.log(valid);
    return valid;
    // if (!data.areas.length) {
    //   valid = false
    // } else {
    //   data.areas?.map((area) => {
    //     if (!area.product?.location_selected?.quant_utilizada) {
    //       valid = false
    //     } else if (area.product?.location_selected?.quanty < area.product?.location_selected?.quant_utilizada) {
    //       valid = false
    //     }
    //   })
    // }
    //return valid
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormSoilPreparationStep1', 'FormSoilPreparationStep2', 'FormProgressSelect', 'ResourcesStepFormOthersTab', 'FormSoilPreparationStep4'];
  const steps_text = [
    'Vamos criar as condições ideias para recebermos as sementes.',
    'Selecione uma ou mais áreas específica onde o preparo do solo será realizado.',
    'Informe como se encontra o progresso do preparo do solo',
    'Registre os custos de produção vinculados a atividade de entrada de animais.',
    'Pronto! Agora basta conferir as informações do preparo do solo e clicar em confirmar!',
  ];

  const onSubmit = async () => {
    console.log('onSubmit');
    let formOk = validate() || false;

    // console.log(data);
    // console.log(formOk);

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api
        .post('/activitySoilPreparation/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Preparo de solo registrado com sucesso!');
            usestore.reposHarvest.fetch({ id_farm: property_id });
            usestore.reposProducts.fetch({ id_farm: property_id });
            //usestore.reposPatrimony.fetch({ id_farm: property_id })
            if (data.resources?.products?.length > 0 || data.resources?.expenses?.length > 0) {
              usestore.reposActivityResources.fetch();
              usestore.reposCashFlow.fetch();
              usestore.reposCostProduction.fetch();
              usestore.reposFinances.fetch();
              usestore.reposFinances.fetchT();
            }
            usestore.reposActivitySoilPreparation.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar o Preparo de solo, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  console.log(data);

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      isMobile: isMobile.any(),
    };

    switch (step) {
      case 0:
        //
        return <FormSoilPreparationStep1 data={data} obj_fn={obj_fn} options={options} />;
      case 1:
        //
        return <FormSoilPreparationStep2 data={data} obj_fn={obj_fn} />;
      case 2:
        //
        return <FormProgressSelect data={data} obj_fn={obj_fn} />;
      case 3:
        // recursos
        return <ResourcesStepFormMainTab data={data} obj_fn={obj_fn} />;
      case 4:
        // confirmação dos dados
        return <FormSoilPreparationStep4 data={data} obj_fn={obj_fn} />;
      case 5:
        const data_success = {
          distination: 'activitiesHarvest/' + property_id + '/' + harvestRepos.id,
          name: 'Atividade de preparo do solo',
          text_success: 'Acesse a lista de suas atividades de campo para monitorar os manejos agrícolas.',
          distination_title: 'Atividades de campo',
          icon: <PendingActions color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  const options = {
    title: 'Preparo do Solo',
    txt_msn: steps_text[activeStep],
    icon: <IconSoilPreparation widthStyles={'55'} />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep < 5 && <HeaderModal options={options} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
