export default function livestockProgress(livestock) {
  // console.log(livestock);
  // console.log(livestock);
  const initialLabel = '';
  const initialAreas = 0;
  const initialAnimais = '';
  const fields = ['label', 'areas', 'animais'];

  if (!livestock || livestock.length === 0) {
    return {
      progress: 0,
      status: {
        label: '?',
        areas: '?',
        animais: '?',
      },
    };
  }

  const current = livestock[0];
  let progress = 0;
  const status = {};

  if (current.label !== initialLabel) {
    progress += 25;
    status.label = 'Ok';
  } else {
    status.label = '?';
  }

  if (current.areas !== initialAreas) {
    progress += 25;
    status.areas = 'Ok';
  } else {
    status.areas = '?';
  }

  if (current.animais !== initialAnimais) {
    progress += 50;
    status.animais = 'Ok';
  } else {
    status.animais = '?';
  }

  return { progress, status };
}
