import React, { useEffect } from 'react';
import { Grid, Box, Button, AppBar, Chip, Paper, Toolbar, Typography } from '@mui/material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { Span } from '../../../../../components/Typography';
import AreaSelectDialog from '../AreaSelectDialog';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function ThirdStep({ data, setData, formError, handleClose, handleNext, handleBack, activeStep, steps }) {
  // console.log("ThirdStep -> áreas");

  const isError = () => {
    if (data.areas.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // console.info('data.areas.');
  }, [data.areas]);

  const handleDelete = (e, chipToDelete) => {
    // console.info('Delete area.');
    const newdataArea = data.areas.filter((chip) => chip.id !== chipToDelete.id);
    setData((prevState) => ({ ...prevState, areas: newdataArea }));
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ my: 1, height: 161 }}>
        <Grid container spacing={2} sx={{ height: 65 }}>
          <Grid item xs={1} md={1}>
            <LocationOnIcon sx={{ color: 'action.active', mr: 0.5 }} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography gutterBottom style={{ color: '#2e7d32' }}>
              <Typewriter text="Defina a(s) área(s) onde sua safra estará localizada." />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} alignItems="center" sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} alignItems="center">
              <Typography variant="h6" gutterBottom>
                Áreas da safra
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} alignItems="center">
              <AreaSelectDialog data={data} setData={setData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Paper sx={{ padding: 0.5 }} component="ul">
            {data.areas &&
              data.areas.map((dataItem) => {
                return (
                  <>
                    <Chip
                      key={dataItem.id}
                      variant="outlined"
                      label={
                        <Typography>
                          {' '}
                          {dataItem.label || dataItem.name} ({dataItem.usedArea || dataItem.area} {dataItem.measure}){' '}
                        </Typography>
                      }
                      onDelete={(e) => handleDelete(e, dataItem)}
                      sx={{ padding: 0.5, ml: 1 }}
                    />
                  </>
                );
              })}
          </Paper>
          {formError.area && (
            <small id="NameHarvest" className="form-text text-danger is-invalid">
              Defina uma área para a sua safra
            </small>
          )}
        </Grid>
        {data.areas.length > 0 && (
          <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
            <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
              Clique abaixo em continuar...
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 9.6 }}>
      <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }}>
        Retornar
      </Button>
      <Button variant="contained" color="success" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
        {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
      </Button>
    </Box> */}
      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>Cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} variant="text" color="inherit" sx={{ marginRight: 2 }} disabled={returnError()}>
              Retornar
            </Button>
            <Button sx={{ my: 2 }} variant="text" color="inherit" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
              {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
