import React from "react";
import FooterMaps from "../../components/Footer/FooterMaps";
import MapsAreasDrawContent from "./MapsAreasDrawContent";
export default function MapsAreasDraw() {
  // const [data, setData] = useState([]);
  return (
    <>
      <MapsAreasDrawContent />
      <FooterMaps />
    </>
  );
}
