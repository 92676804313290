import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { expandAndFade, expandAndFadeRed } from '../../../../components/Animations/animationKeyFrames';
import RegisterBuyDialog from '../../../inventory/RegisterBuyDialog';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RegisterNewReleaseDialog from '../outhers/RegisterNewReleaseDialog';
import RegisterPerson from '../../../person/components/addPerson/RegisterPerson';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// Estilo comum para os botões
const buttonStyle = {
  width: 300, // Largura fixa para todos os botões, ajuste conforme necessário
  margin: '0 5px', // Margem para separar os botões, ajuste conforme necessário
};

export default function CustomizedMenusFinancial() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [buttonType, setButtonType] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Button
        sx={{ fontSize: '0.75rem', animation: `${expandAndFade} 2s linear infinite` }}
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="success"
      >
        Lançamentos
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} disableRipple>
          <ProductRegistrar />
        </MenuItem> */}
        {/* <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterBuyDialog type={'buy'} />
        </span> */}

        {/* <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterNewReleaseDialog type={'expense'} setButtonType={setButtonType} handleCloseDialog={handleCloseDialog} />
        </span>
        <Divider sx={{ my: 0.5 }} />
        <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterNewReleaseDialog type={'income'} setButtonType={setButtonType} handleCloseDialog={handleCloseDialog} />
        </span>
        <Divider sx={{ my: 0.5 }} />
        <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterBuyDialog type={'buy'} buy={true} />
        </span>
        <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterBuyDialog type={'sales'} buy={true} />
        </span> */}
        <RegisterNewReleaseDialog setButtonType={setButtonType} openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        <Divider sx={{ my: 0.5 }} />
        <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <RegisterPerson />
        </span>
        <Divider sx={{ my: 0.5 }} />
        {/* <span onClick={(event) => event.stopPropagation()} disableFocusRipple disableRipple>
          <Button sx={{ fontSize: '0.75rem' }} color={'inherit'} variant="text" startIcon={<AddBoxIcon sx={{ color: '#000' }} />}>
            Serviços
          </Button>
        </span> */}

        {/* <span disableRipple>
          <RegisterPerson />
        </span> */}
        {/* <span disableRipple>
          <RegisterNewStorageLocations />
        </span> */}
        {/*  <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        */}
      </StyledMenu>
    </div>
  );
}
