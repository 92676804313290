import React, { useState, useRef } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
// import { IMaskInput } from "react-imask";

// Converte o valor numérico para uma string formatada
const formatValue = (num) => {
  // Verifica se num é um número; caso contrário, usa 0
  const number = isNaN(num) || num === '' ? 0 : Number(num)
  let str = number.toFixed(2) // Formata para 2 decimais
  str = str.replace('.', ',') // Substitui ponto por vírgula
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Adiciona separadores de milhar
}

const GenericValueField = ({ handleChange, options }) => {
  const inputRef = useRef(null);
  const [error, setError] = useState(false);

  const opt_default = {
    required: options?.required || false,
    label: options?.label || '',
    name: options?.name || 'currency',
    id: options?.id || 'currency_',
    value: options?.value || '0,00',
    color: options?.color || 'success',
    helperText: options?.helperText || '',
    helperTextError: options?.helperTextError || 'Campo de preenchimento obrigatório',
    variant: options?.variant || 'outlined',
    max: options?.max || 100000000,
    disabled: options?.disabled || false,
    width: options?.width || '100%',
    height: options?.height || '56px',
    prefix: !options?.prefix && options?.prefix !== '' ? " (R$) " : options?.prefix,
    startAdornment: options?.startAdornment || false,
    endAdornment: options?.endAdornment || false,
    inputlabelprops: options.inputlabelprops || {},
    formhelpertextprops: options.formhelpertextprops || {},
  };

  const handleFocus = (event) => {
    // Adiciona um pequeno atraso para garantir que a seleção ocorra após o campo receber foco
    setTimeout(() => {
      if (inputRef.current) {
        const length = event.target.value.length;
        event.target.setSelectionRange(length, length); // Posiciona o cursor no final do texto
      }
    }, 5);
  }
  const handleChangeI = (event) => {
    event.preventDefault()

    const key = ''
    // const key = event.code.replace(/\D/g, '') // onKeyUp
    const input = event.target.value.replace(/\D/g, '')
    const numberValue = (input + key).padStart(3, '0')
    let floatValue = parseInt(numberValue, 10) / 100

    if (!floatValue) {
      floatValue = floatValue.toFixed(2)
    } else if (floatValue > opt_default.max) {
      floatValue = opt_default.max
    }

    handleChange({ target: { id: opt_default.id, name: opt_default.name, label: opt_default.label, value: floatValue } })

    if (opt_default.required && !parseFloat(floatValue)) {
      setError(true)
    } else if (error) {
      setError(false)
    }
  }

  // const stringValue = opt_default.value ? String(opt_default.value) : '';
  return (
    <TextField
      inputRef={inputRef}
      required={opt_default.required}
      id={opt_default.id}
      name={opt_default.name}
      autoComplete={opt_default.id}
      label={opt_default.label}
      color={opt_default.color}
      variant={opt_default.variant}
      disabled={opt_default.disabled}
      fullWidth
      sx={{ width: "100%",
        "& .MuiInputBase-input": {
          textAlign: 'right',
        },
      }}
      style={{ width: opt_default.width }}
      InputProps={{
        // inputComponent: IMaskInput,  // Usando IMaskInput para o campo
        inputProps: {
          mask: Number,
          radix: ',',
          // mapToRadix: ['.'],
          // thousandsSeparator: '.',
          maptoradix: ['.'],
          thousandsseparator: '.',
          scale: 2,
          // padFractionalZeros: true,
          // normalizeZeros: true,
          padfractionalzeros: 'true',
          normalizezeros: 'true',
          signed: "false",
          min: 0,
          max: opt_default.max,
          // onAccept: handleChangeI,
          onChange: handleChangeI,
          // onKeyUp: handleChangeI
        },
        style: {
          height: opt_default.height,
        },
        startAdornment: (!opt_default.startAdornment ? <></> : 
          <InputAdornment position="start" style={{ pointerEvents: 'none' }} disablePointerEvents={true}>
            <Box sx={{ cursor: 'none' }}>
              <Typography variant="subtitle1" component="div">
                {opt_default.prefix}
              </Typography>
            </Box>
          </InputAdornment >
        ),
        endAdornment: (!opt_default.endAdornment ? <></> : 
          <InputAdornment position="end" style={{ pointerEvents: 'none' }} disablePointerEvents={true}>
            <Box sx={{ cursor: 'none' }}>
              <Typography variant="subtitle1" component="div">
                {opt_default.prefix}
              </Typography>
            </Box>
          </InputAdornment >
        ),
      }}
      value={formatValue(opt_default.value)}
      // value={stringValue}
      onFocus={handleFocus}
      helperText={(<>
        {opt_default.helperText}
        {error ? (<><br/> {opt_default.helperTextError}</>) : <></>}
      </>)}
      error={error}
      InputLabelProps={opt_default.inputlabelprops}
      FormHelperTextProps={opt_default.formhelpertextprops}
    />
  );
};

export default GenericValueField;
