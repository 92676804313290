import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Dialog, AppBar, Container, DialogContent, Divider, Grid, IconButton, Paper, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import useAreasMapReposStore from "../../../../store/useAreasMapReposStore";
import { Context } from "../../../../components/Context/AuthContext";
import FormEditActivities from "./FormEditActivities";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// fillDialog normal que chama uma lista de botões de atividade
// cada botão chama o componete de atividades ... MultiStepFormInput
export default function EditActivitiesCreations({ id, tab_active, reposActivitiesInputs, reposLivestocks }) {
  // console.log("EditActivitiesCreations");
  // console.log(reposActivitiesInputs);
  // console.log(id);
  const [open, setOpen] = useState(false);
  const [openSituation, setOpenSituation] = useState(false);

  const [data, setData] = useState({});
  const [data_situacao, setDataSituacao] = useState({});

  const header = { header: { "Content-Type": "application/json" } };

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // ------ inicicia o repos das Areas ------
  const fetchAreas = useAreasMapReposStore((state) => state);
  const areaMapList = window.jQuery.extend(true, [], fetchAreas.listAreasMap);
  // console.log("areaMapList");
  // console.log(areaMapList);
  // pega a reposActivitiesInputs em edição
  const activity = window.jQuery.extend(true, [], reposActivitiesInputs.getActivityInputStore("id", id));
  // console.log("activity edit");
  // console.log(activity);
  // preciso atualizar os dados passados dos componentes:
  useEffect(() => {
    if (!activity || activity.length == 0) {
      return;
    }
    let this_activity = window.jQuery.extend(true, [], activity[0]);
    setData(this_activity);
  }, []);

  const setDadosData = () => {
    // console.log("setDadosData");
    let this_activity = window.jQuery.extend(true, [], activity[0]);
    // console.log(this_activity);
    setData(this_activity);
    this_activity.id = id;
    // pega as áreas da atividade em edição
    // var this_livestock_areas = [];
    var this_activity_areas = areaMapList.filter((repos_area) => repos_area.id == this_activity.id_areamap);
    // console.log("this_activity_areas");
    // console.log(this_activity_areas);
    setData((prevState) => ({ ...prevState, areas: this_activity_areas }));
  };

  const handleClickOpen = () => {
    // console.log("handleClickOpen");
    setDadosData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context);

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(onSubmit);
    // console.log(data);
    setFormError({});
    let errors = {};
    let formOk = true;
    // console.log(data);

    if (data.nameBatch.length < 5) {
      errors.nameBatch = true;
      formOk = false;
      // } else if (property.address.length < 3) {
    } else if (data.areas.length === 0) {
      errors.area = true;
      formOk = false;
    }

    if (formOk) {
      const areas_bkp = window.jQuery.extend(true, [], data.areas);
      data.areas = data.areas.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea }));
      data.id_user = userCurrent.id;
      data.label = data.name;
      // console.log(data);
      const res = await api
        .put("/activityInput/update", data, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Projeto editado com sucesso!");
            data.areas = areas_bkp;
            // var updt_lv = reposLivestocks.updateLivestockStore(id, "all", data);
            reposLivestocks.fetch()
            // setTimeout(() => {
            //   //setAuxAdd(aux_add + 1);
            //   /// handleClose();
            // }, updt_lv.length);

            // atualiza o repos
            reposActivitiesInputs.fetch({ id_farm: id }).then((res) => {
              // console.log("reposActivitiesInputs - fetch - res");
              // console.log(res);
              // console.log("reposActivitiesInputs.listActivityInput");
              // console.log(reposActivitiesInputs.listActivityInput);
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error("Por favor, preencha os campos obrigatórios");
      setFormError(errors);
      //return false;
    }
  };

  const handleCloseSituation = () => {
    setOpenSituation(false);
  };

  if (!activity || activity.length == 0) {
    return <></>;
  }

  // console.log("fim");
  // console.log(data);

  return (
    <>
      <div>
        {/*<Button variant="text" color="success" title="Editar" startIcon={<EditIcon fontSize="small" sx={{ ml: 1 }} />} onClick={handleClickOpen} />*/}
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> */}
          <AppBar sx={{ position: "relative" }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    Edição
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar" title="Fechar">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <DialogContent sx={{ mb: 8 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
              <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
                <FormEditActivities data={data} setData={setData} reposActivitiesInputs={reposActivitiesInputs} handleClose={handleClose} onSubmit={onSubmit} />
              </Paper>
            </Container>
          </DialogContent>
        </Dialog>
        <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
          <EditIcon sx={{ color: green[500] }} />
        </IconButton>
      </div>
    </>
  );
}
