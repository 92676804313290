import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

export default function ProfileOperacional() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return (
        <Box sx={{ width: 500 }}>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                            <Typography sx={{ p: 2, width: 300 }}>O perfil operacional possui apenas algumas permissões para criar ou editar
                                informações, entretanto, pode visualizar as informações das atividades realizadas na criação e na safras da propriedade.</Typography>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Grid container justifyContent="left">
                <Grid item>
                    {/* <Button color="success" onClick={handleClick('bottom-start')}> */}
                    <Typography fontWeight="500" variant="subtitle1" component="h2">
                        Operacional: nível operacional
                    </Typography>
                    {/* </Button> */}
                </Grid>
            </Grid>
        </Box>
    );
}