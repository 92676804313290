import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SelectHarvest from './components/addHarvest/SelectHarvest';
import CardHarvests from './components/outros/CardHarvests';
import TutorialAddProjectHarvest from './components/addHarvest/tutorial/TutorialAddProjectHarvest';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

export default function HarvestContent() {
  const objText = {
    icon: <img src="/dist/img_fp/icon-button/icon-agricolaV1.png" className="img-fluid" width="40" alt="Imagem responsiva" />, // Substitua pelo ícone desejado
    title: 'Safras', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Com o projeto agrícola podemos planejar e gerenciar de forma eficiente as atividades agrícolas e os custos em uma propriedade rural.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Criar um projeto Agrícola', '3- Visualizar a lista de projetos agrícolas'], // Texto complementar ou final
    linkButton: ['openProjectHarvestRegistrarModal', '/harvest'], // O primeiro agora vai abrir o modal
    //textButton: ['1- Conhecimentos de Gestão', '2- Cria projeto Pecuário', '3- Visualizar a lista de projetos agrícolas'], // Texto complementar ou final
    //linkButton: ['openGPRegistrarModal', 'openProjectHarvestRegistrarModal', '/harvest'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <Box className="content-wrapper" sx={{ backgroundColor: 'white' }}>
      <Box className="content-header" sx={{ marginTop: 0 }}>
        <Box className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ height: 40, width: 40 }}>
                  <img src="/dist/img_fp/icon-button/icon-agricolaV1.png" className="img-fluid" width="40" alt="Imagem responsiva" />
                </Box>
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Safras
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Registrar&nbsp;da&nbsp;safra
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <SelectHarvest />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialAddProjectHarvest />
              </Box>
            </Grid>

            {/* <Grid item xs={2} md={2}>
                 
                </Grid> */}
            {/* <Grid item xs={1} md={1}>
                  <StartProjectLivestock />
                </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Box className="content">
        <Box className="container-fluid">
          <Box className="card">
            <CardHarvests />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
