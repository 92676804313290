import * as React from "react";
import json from '../../exemplo_roles_permission.json'

function createData(permission, name, view, edit, trash) {
    return { permission, name, view, edit, trash };
}


export default function RolePermissions() {

    // pego o value do select
    const profileCurrent = JSON.parse(localStorage.getItem("profileCurrent"));
    console.log('profileCurrent')
    console.log(profileCurrent)

    let role = ''
    switch (profileCurrent) {
        case 1:
            role = "proprietario"
            break;
        case 2:
            role = "gerente"
            break;
        case 3:
            role = "financeiro"
            break;
        case 4:
            role = "operacional"
            break;
        default:
            console.log(`Error`);
    }


    return role

};
