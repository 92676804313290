import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Grid, Typography, InputAdornment, Stack, Card, CardMedia, CardHeader, Divider, ListItemIcon, Checkbox } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import FormAssignAreaHeader from './FormAssignAreaHeader';
import FormAssignAreaFooter from './FormAssignAreaFooter';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    overflow: 'auto',
  },
});

export default function FormAssignAreaStep1({ data, obj_fn }) {
  const classes = useStyles();

  //show texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    return !data.new_area.length;
  };

  // valida o botao retornar
  const returnError = () => {
    return true;
  };

  const selectedIds = {};
  data.new_area?.forEach((area) => (selectedIds[area.id] = area.id));
  // Marca áreas selecionadas
  const [selectedId, setSelectedId] = useState(selectedIds);

  const handleChange = (e, area) => {
    console.log('handleChange');
    selectedIds[area.id] = !selectedIds[area.id];
    setSelectedId({ ...selectedIds });

    if (selectedIds[area.id]) {
      obj_fn.setData((prevState) => ({ ...prevState, new_area: [...prevState.new_area, area] }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, new_area: prevState.new_area.filter((area_prev) => area_prev.id !== area.id) }));
    }
  };

  const getUsed = (area) => {
    const last_used = area.areas_used.lenght - 1;
    let text_used = (
      <>
        {area.areas_used.map((area_used, index) => (
          <>
            <Typography variant="subtitle2" gutterBottom title={'Tipo: ' + area_used.tipo_livestock}>
              {area_used.name}
            </Typography>
            {index > 0 && index < last_used ? <br /> : <></>}
          </>
        ))}
      </>
    );
    return text_used;
  };

  const title = 'Vincular Áreas à criação';
  const text_title = 'Seleção de áreas';
  const txt_msn = 'Selecione as novas áreas da propriedade a serem vinculadas a essa criação!';
  console.log('data.areas_used');
  console.log(data.areas_used);

  return (
    <>
      <FormAssignAreaHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} title={title} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography fontWeight="500" variant="h6">
            Áreas atuais da Criação ({data.item_data.name})
          </Typography>
        </Grid>
        {data.areas_used.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 240, width: 250, overflow: 'auto' }}>
                <div style={{ position: 'relative' }}>
                  <CardMedia key={'cm_' + item.id} component="img" height="150" image={item.url || item.imageDecode} alt="Imagem da área" />
                  <Box
                    key={'b_' + item.id}
                    sx={{
                      position: 'absolute',
                      bottom: '35px',
                      left: 0,
                      width: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.45)',
                      color: 'white',
                      padding: '27px',
                    }}
                  >
                    <div style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography variant="body2">
                        {item.usedArea} {item.measure || 'ha'}
                      </Typography>
                    </div>
                  </Box>
                  <CardHeader
                    key={'ch_' + item.id}
                    sx={{ maxHeight: '35px' }}
                    subheader={
                      <Box key={'b_' + item.id} sx={{}}>
                        <Typography variant="body2" color="text.secondary">
                          <LocationOn color="success" /> Total animais: {item.num_animais || '-'} ca
                        </Typography>
                      </Box>
                    }
                  />
                </div>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
      <Divider sx={{ mt: 1, mb: 1, ml: -3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography fontWeight="500" variant="h6">
            Áreas da propriedade
          </Typography>
        </Grid>
        {data.areas_property.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }} className={selectedId[item.id] ? classes.selected : null}>
                <ListItemIcon key={'li_' + item.id}>
                  <Checkbox key={'cb_' + item.id} sx={{ height: '150px' }} checked={selectedId[item.id] ? true : false} onChange={(e) => handleChange(e, item)} color="success" />
                  <div style={{ position: 'relative' }}>
                    <CardMedia key={'cm_' + item.id} component="img" height="150" image={item.url || item.imageDecode} alt="Paella dish" />
                    <Box
                      key={'b_' + item.id}
                      sx={{
                        position: 'absolute',
                        top: '95px',
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.45)',
                        color: 'white',
                        padding: '27px',
                      }}
                    >
                      <div style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="body2">
                          {item.area} {item.measure || 'ha'}
                        </Typography>
                      </div>
                    </Box>
                    <CardHeader key={'ch_' + item.id} sx={{ maxHeight: '250px', marginLeft: '-15%', overflow: 'auto' }} subheader={<Box sx={{}}>{getUsed(item)}</Box>} />
                  </div>
                </ListItemIcon>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
      <Divider sx={{ mt: 1, mb: 1, ml: -3 }} />

      <FormAssignAreaFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
