import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconFertilization = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 248.000000 282.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M1293 2669 c-58 -11 -127 -43 -173 -79 -71 -56 -130 -179 -130 -273
0 -29 -7 -45 -29 -68 -34 -34 -68 -37 -104 -9 -23 19 -40 20 -241 20 l-216 0
0 -24 c0 -16 -22 -46 -68 -92 -73 -74 -112 -142 -131 -232 -15 -74 -15 -1420
0 -1494 17 -77 50 -137 106 -190 118 -110 101 -108 948 -108 853 0 841 -1 958
108 58 55 91 116 107 197 8 43 10 328 8 1015 l-3 955 -27 57 c-31 67 -96 140
-154 174 -78 46 -151 54 -494 53 -173 -1 -334 -5 -357 -10z m764 -228 c67 -48
73 -77 73 -331 0 -207 -1 -221 -20 -240 -11 -11 -29 -20 -39 -20 -22 0 -23 2
-236 184 -196 168 -209 179 -295 256 -40 36 -81 71 -91 78 -22 15 -26 71 -7
90 9 9 88 12 293 12 l282 0 40 -29z m-117 -843 c26 -14 57 -41 75 -66 l30 -44
3 -361 c2 -255 0 -374 -8 -401 -17 -56 -54 -102 -104 -125 -41 -21 -58 -21
-678 -21 l-635 0 -44 23 c-24 13 -57 43 -74 66 l-30 43 0 388 0 388 30 44 c18
25 49 52 75 66 l44 22 636 0 636 0 44 -22z"
          />
          <path
            d="M610 1480 c-19 -19 -20 -33 -20 -378 l0 -359 23 -21 23 -22 625 0
626 0 21 23 c22 23 22 28 22 378 0 344 -1 356 -20 377 l-21 22 -629 0 c-617 0
-630 0 -650 -20z m270 -277 c36 -51 75 -106 86 -123 l22 -32 1 118 c1 64 4
121 7 126 10 16 63 8 74 -11 6 -11 10 -88 10 -178 0 -127 -3 -163 -16 -181
-20 -29 -68 -29 -92 -1 -9 12 -21 26 -27 33 -5 6 -37 52 -70 101 l-60 90 -3
-110 c-1 -60 -8 -115 -14 -122 -14 -17 -53 -17 -67 1 -16 20 -14 373 2 380 7
3 28 4 47 3 32 -2 40 -10 100 -94z m494 83 c50 -21 76 -60 76 -115 0 -92 -59
-141 -169 -141 l-61 0 0 -53 c0 -29 -5 -58 -12 -65 -18 -18 -56 -14 -68 7 -6
11 -10 89 -10 181 0 92 4 170 10 181 9 17 22 19 106 19 58 0 107 -5 128 -14z
m184 2 c7 -7 12 -39 12 -74 l0 -62 72 75 c63 64 76 74 103 71 24 -2 31 -8 33
-28 2 -19 -10 -38 -53 -80 -30 -30 -55 -57 -55 -61 0 -4 29 -44 65 -90 36 -45
65 -90 65 -99 0 -22 -20 -40 -45 -40 -23 0 -34 11 -104 103 -24 31 -46 57 -50
57 -20 0 -31 -30 -31 -80 0 -60 -11 -80 -45 -80 -42 0 -45 15 -45 200 0 185 3
200 45 200 12 0 26 -5 33 -12z"
          />
          <path
            d="M1220 1165 l0 -45 58 0 c62 0 82 11 82 45 0 34 -20 45 -82 45 l-58 0
0 -45z"
          />
          <path
            d="M363 2626 c-27 -23 -28 -29 -28 -109 0 -77 3 -88 24 -111 l24 -26
228 0 228 0 20 26 c18 22 21 41 21 111 0 76 -2 86 -25 108 l-24 25 -220 0
c-218 0 -220 0 -248 -24z"
          />
        </g>
      </SvgIcon>
    </>
  );
};
