import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Divider, Grid, IconButton, List, Stack, Switch, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MenuPlan from './MenuPlan';
import CardPropertyProducts from './CardPropertyProducts';
import { Span } from '../../../components/Typography';
import useStore from '../../../store/useStore';
import PropertyButtonEdit from './formEdit/PropertyButtonEdit';
import Typewriter from '../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../components/Loader/ThreeDotsSpinner';

export default function CardProperty() {
  console.log('CardProperty');
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const user = userCurrent;
  const name = user.name;
  const charName = name[0].toUpperCase();

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  const usestore = useStore();
  const listPropertyRepos = usestore.reposProperty.listProperty;

  return (
    <>
      <Grid container spacing={0} sx={{ mt: 3, mb: 3 }}>
        <HomeWorkIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Listas das propriedades</Typography>
          </Stack>
        </Box>
      </Grid>

      {listPropertyRepos.map((item) => (
        <>
          <Card sx={{ mt: 2, border: `1px solid ${green[500]}` }}>
            {/* <Box sx={{ p: 2, display: 'flex' }}>
                            <Stack spacing={0.5}>
                                <Typography fontWeight={700}>Índice de experiência</Typography>
                            </Stack>
                        </Box> */}
            <CardHeader
              sx={{}}
              avatar={
                <Avatar variant="rounded" sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
                  <HomeWorkIcon />
                </Avatar>
              }
              action={
                <Grid sx={{ mb: -4, mr: 2 }}>
                  {/* <MenuPlan item={item} /> */}
                  <PropertyButtonEdit item={item} usestore={usestore} />
                </Grid>
              }
              title={item.label}
              subheader={user.name}
            ></CardHeader>

            <Divider />
            <CardContent>
              <Grid container spacing={3} sx={{ mt: 3, ml: 2, mb: 3 }}>
                {/* <HomeWorkIcon /> */}
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={400}>Plano atual da fazenda:</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid>
                <Grid xs={12}>
                  <CardPropertyProducts />
                  {/* <Accordion expanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Planos e Módulos</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ overflow: 'auto' }}>

                                            <CardPropertyProducts />


                                        </AccordionDetails>

                                    </Accordion> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ))}
    </>
  );
}
