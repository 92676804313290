import React from 'react'
import { useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import WidgetsIcon from '@mui/icons-material/Widgets'
import api from '../../../../services/api'
import { toast } from 'react-toastify'
import useStore from '../../../../store/useStore'
import { useContext } from 'react'
import { Context } from '../../../../components/Context/AuthContext'
import AdjustIcon from '@mui/icons-material/Adjust' // Ícone padrão para opções
import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore'

const option = [{ label: 'Entrada' }, { label: 'Saída' }, { label: 'Balanço' }]
const options = ['Entrada', 'Saída', 'Balanço']

export default function LocationStock({ open, handleClose, dataStock, setDataStock }) {
  console.log('LocationStock')
  console.log(dataStock)

  // const validate = () => {
  //   let valid = true
  //   console.log(dataStock.value_cost)
  //   if (dataStock.value_cost == '0,00') {
  //     valid = false
  //   }
  //   if (dataStock.quanty == 0 || dataStock.quanty == '') {
  //     valid = false
  //   }
  //   return valid
  // }

  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const property_id = localStorage.getItem('Property') || 1

  // cadastro rapido location:
  const [dialogLocationOpen, setDialogLocationOpen] = useState(false)

  const toggleDialogLocation = () => {
    setDialogLocationOpen(!dialogLocationOpen)
  }

  const [selectedValueLocation, setSelectedValueLocation] = useState(null)
  //const [showAddNewLocation, setShowAddNewLocation] = useState(true)

  console.log('setSelectedValueLocation')
  console.log(selectedValueLocation)

  const handleChangePatrimony = (event, value) => {
    console.log('handleChangePatrimony')
    console.log(value)
    if (value) {
      const setSelectedValueLocation = value
      console.log(setSelectedValueLocation)
      // obj_fn.setData((prevState) => ({
      //   ...prevState,
      //   location: setSelectedValueLocation.id,
      // }))
    }
  }

  const reposPatrimony = usePatrimonyReposStore((state) => state)
  console.log(reposPatrimony)
  console.log(reposPatrimony.listPatrimony)

  const reposPatrimonyFilter = reposPatrimony.listPatrimony.filter((item) => item.id_property == property_id)
  console.log(reposPatrimonyFilter)

  const [listStockLocation, setListStockLocation] = useState(reposPatrimonyFilter)

  console.log('listStockLocation ')
  console.log(listStockLocation)
  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  // const patrimonyWithNewOption = [
  //   { id: 'new', name: 'Adicionar novo patrimônio' }, // Item especial para abrir o modal
  //   ...listStockLocation,
  // ]

  const handleSubmit = async () => {
    console.log('handleSubmit -StockEntry')
    //let formOk = validate() || false

    console.log(dataStock)
    const updateLocation = {
      // id_property: dataStock.id_property,
      id_patrimony: selectedValueLocation.id,
      id_product: dataStock.id_product,
      quanty: 0,
    }
    console.log(updateLocation)

    if (true) {
      const header = { header: { 'Content-Type': 'application/json' } }
      const res = await api
        .post('products/createLocation', updateLocation, header)
        .then((response) => {
          console.log('response.dataxxx')
          console.log(response.data)
          console.log(response.data.isSuccess)
          if (response.data.isSuccess) {
            toast.success('Controle de quantidade de estoque atualizado')

            //resetForm()
            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao atualizar o estoque, tente novamente mais tarde!')
        })
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!')
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle>Local de armazenamento do produto</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContentText>
              {/* Aqui você pode adicionar as informações do pedido */}
              {/* Exemplo: Pedido Número: {selectedRow?.id} */}
            </DialogContentText>
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* NOME */}
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <WidgetsIcon sx={{ fontSize: 20, mr: 1 }} color="text.secondary" />
                    <Stack spacing={0.5}>{dataStock?.name && <Typography fontWeight={700}>{dataStock?.name.toUpperCase()}</Typography>}</Stack>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    name="location"
                    onChange={(event, newValue) => {
                      setSelectedValueLocation(newValue) // Atualize para usar setSelectedValue
                      handleChangePatrimony(true, newValue)
                    }}
                    // onChange={(event, newValue) => {
                    //   if (newValue && newValue.id === 'new') {
                    //     // Abre o diálogo para adicionar um novo tipo de patrimônio
                    //     toggleDialogLocation(true)
                    //   } else {
                    //     console.log(event, newValue)
                    //     setSelectedValueLocation(newValue) // Atualize para usar setSelectedValue
                    //     handleChangePatrimony(true, newValue)
                    //   }
                    // }}
                    disableCloseOnSelect={false}
                    filterSelectedOptions
                    color="success"
                    value={selectedValueLocation}
                    multiple={false}
                    options={listStockLocation}
                    ListboxProps={{ style: { maxHeight: 225 } }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <MenuItem {...props}>
                        {/* {option.id === 'new' ? (
                          <>
                            <AddCircleOutlineIcon style={{ color: '#2e7d32', marginRight: '8px', fontSize: '15px' }} />
                            <Typography variant="subtitle2" component="div" style={{ color: '#2e7d32' }}>
                              Adicionar novo local
                            </Typography>
                          </>
                        ) : (
                          <>
                            <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                            {option.name}
                          </> 
                          
                        )}*/}
                        <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                        {option.name}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Local de armazenamento"
                        color="success"
                        InputLabelProps={{ style: { color: '#2e7d32' } }}
                        helperText="Defina o local de armazenamento do produto"
                        focused
                        fullWidth
                      />
                    )}
                    noOptionsText="Sem resultados"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
      {/* Adicione botões de ação se necessário */}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={handleSubmit} variant="contained" color="success">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
