import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListPatrimony from './ListPatrimony';
import CardPatrimonyType from './CardPatrimonyType';
import CustomizedMenusMobile from './CustomizedMenusMobile';
import TablesPatrimonyCardEmpty from './TablesPatrimonyCardEmpty';

export default function CardPatrimony() {
  const [patrimonyData, setPatrimonyData] = React.useState({});

  return (
    <>
      <div className="">
        <Grid container spacing={2} sx={{ mt: 1, p: 1 }}>
          <Grid className="d-lg-none" item xs={12} md={3} sx={{ mt: -2 }}>
            <CustomizedMenusMobile setPatrimonyData={setPatrimonyData} />
          </Grid>
          <Grid className="d-none d-lg-block" item xs={12} md={3}>
            <Typography sx={{ mt: 0, mb: 2 }} variant="h6" component="div">
              Lista de Patrimônios
            </Typography>
            <ListPatrimony setPatrimonyData={setPatrimonyData} />
          </Grid>
          <Grid item xs={12} md={9} sx={{ mt: 4 }}>
            {Object.keys(patrimonyData).length > 0 ? (
              <CardPatrimonyType patrimonyData={patrimonyData} setPatrimonyData={setPatrimonyData} />
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                <TablesPatrimonyCardEmpty />
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
