import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardHeader, Divider, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import Typewriter from '../../../../components/Typewriter/Typewriter'
import FooterModal from '../../../../components/Footer/FooterModal'
import { Input, Notes } from '@mui/icons-material'
import LocalSeeIcon from '@mui/icons-material/LocalSee'
import InventoryIcon from '@mui/icons-material/Inventory'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner'

export default function UpdateFormProductStep2({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTextFase(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  const isError = () => false

  const returnError = () => false

  console.log(data)

  const stockCostFormatted = data.stock_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Confirmação dos dados</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={'Pronto! Agora basta conferir os dados e clicar em confirmar!'} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={'Pronto! Agora basta conferir os dados e clicar em confirmar!'} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Divider sx={{ mt: 1, mb: 5, ml: -3, borderColor: '#388e3c' }} />

      <Grid container spacing={3} sx={{ mb: 2, backgroundColor: '#F6F6F6', py: 2, borderRadius: '6px' }}>
        {/* Informações gerais */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <Input sx={{ mr: 1 }} /> Dados gerais
                </Typography>
              </Stack>
            </Box>
          </Grid>

          {/* NOME */}
          <Grid item xs={6} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Nome do produto'}</Typography>} secondary={data.name} />
            </ListItem>
          </Grid>

          {/* UNIDADE */}
          <Grid item xs={6} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Unidade'}</Typography>} secondary={data.unit_name} />
            </ListItem>
          </Grid>

          {/* VALOR */}
          {/* <Grid item xs={6} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Valor'}</Typography>} secondary={stockCostFormatted} />
            </ListItem>
          </Grid> */}

          {/* CATEGORIA */}
          <Grid item xs={12} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Categoria'}</Typography>} secondary={data.id_category} />
            </ListItem>
          </Grid>

          {/* MARCA */}
          <Grid item xs={6} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Marca'}</Typography>} secondary={data.brand} />
            </ListItem>
          </Grid>

          {/* Código de barra */}
          <Grid item xs={12} md={4}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Código de barra'}</Typography>} secondary={data.cod_barra} />
            </ListItem>
          </Grid>

          {/* DESCRIÇAO */}
          <Grid item xs={12} md={4}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Descrição'}</Typography>} secondary={data.observacoes} />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>

      {/* Observações */}
      {data.observacoes !== '' && (
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
            mt: 0,
            backgroundColor: '#F6F6F6',
            py: 2,
            borderRadius: '6px',
          }}
        >
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Observações gerais
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.observacoes} />
            </ListItem>
          </Grid>
        </Grid>
      )}

      {/* FOTOS */}
      {data.image == '' ? (
        <Grid item xs={12} sm={12}>
          <ListItem>
            <ListItemText primary="" secondary={'Nenhuma imagem foi selecionada.'} />
          </ListItem>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
            mt: 0,
            backgroundColor: '#F6F6F6',
            py: 2,
            borderRadius: '6px',
          }}
        >
          <Box sx={{ mb: 2, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <LocalSeeIcon sx={{ mr: 1 }} />
                Fotos
              </Typography>
            </Stack>
          </Box>
          {/* TODO: EXIBIR AS IMAGENS ADICIONADAS PELO USUÁRIO */}
          <Card sx={{ mt: 4 }}>
            <Box
              height="80px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="1px dashed gray"
              borderRadius="4px"
              padding="8px"
            >
              <CardHeader
                avatar={<Avatar aria-label="image" src={`data:image/jpeg;base64,${data.image}`}></Avatar>}
                title={data?.name || ''}
                subheader={data?.group || ''}
              />
            </Box>
          </Card>
          {/* <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.image === '' ? 'Nenhuma imagem foi selecionada.' : data.image} />
            </ListItem>
          </Grid> */}
        </Grid>
      )}

      {/* ESTOQUE */}
      <Grid
        container
        spacing={3}
        sx={{
          mb: 2,
          mt: 0,
          backgroundColor: '#F6F6F6',
          py: 2,
          borderRadius: '6px',
        }}
      >
        <Grid xs={12} md={12} sx={{ mb: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <InventoryIcon sx={{ mr: 1 }} />
                Estoque
              </Typography>
            </Stack>
          </Box>
        </Grid>

        {/* Quantidade disponível */}
        <Grid xs={6} sm={6}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Quantidade disponível'}</Typography>} secondary={data.stock_quanty} />
          </ListItem>
        </Grid>

        {/* Custo Médio */}
        <Grid xs={6} sm={6}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Custo médio'}</Typography>} secondary={stockCostFormatted} />
          </ListItem>
        </Grid>

        {/* Estoque mínimo */}
        <Grid xs={6} sm={6}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Estoque mínimo'}</Typography>} secondary={data.stock_quanty_min} />
          </ListItem>
        </Grid>

        {/* Estoque máximo */}
        <Grid xs={6} sm={6}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Estoque máximo'}</Typography>} secondary={data.stock_quanty_max} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Pesos e Dimensões */}
      <Grid
        container
        spacing={3}
        sx={{
          mb: 2,
          mt: 0,
          backgroundColor: '#F6F6F6',
          py: 2,
          borderRadius: '6px',
        }}
      >
        <Grid xs={12} md={12} sx={{ mb: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <HighlightAltIcon sx={{ mr: 1 }} />
                Pesos e Dimensões
              </Typography>
            </Stack>
          </Box>
        </Grid>

        {/* Altura */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Altura'}</Typography>} secondary={`${data.altura} cm`} />
          </ListItem>
        </Grid>

        {/* Largura */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Largura'}</Typography>} secondary={`${data.largura} cm`} />
          </ListItem>
        </Grid>

        {/* Profundidade */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Profundidade'}</Typography>} secondary={`${data.profundidade} cm`} />
          </ListItem>
        </Grid>

        {/* Volume */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Volume'}</Typography>} secondary={data.volume} />
          </ListItem>
        </Grid>

        {/* Peso Líquido */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Peso Líquido'}</Typography>} secondary={`${data.peso_liquido} KG`} />
          </ListItem>
        </Grid>

        {/* Peso Bruto */}
        <Grid xs={6} sm={4}>
          <ListItem>
            <ListItemText primary={<Typography fontWeight="bold">{'Peso Bruto'}</Typography>} secondary={`${data.peso_bruto} KG`} />
          </ListItem>
        </Grid>
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  )
}
