import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardContent, Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import TimelineHorizActivity from "../GraphPlan/TimelineHorizActivity";
import FormCostOthers from "./FormCostOthers";
import PlanningHarvestCostEmpty from "./PlanningHarvestCostEmpty";
import { useState } from "react";

export default function PlanningHarvestCost() {

  const [isLoandigSavePlan, setIsLoandigSavePlan] = useState(false)

  return (
    <>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={12}>
            <Box mt={2}>
              <Box sx={{ display: "flex" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight="400" variant="h6">
                    Custo de Produção
                  </Typography>
                </Stack>
              </Box>
              {/* card1  */}
              <Box mt={2}>
                {false ?
                  <Card>
                    <CardContent sx={{ mb: 1 }}>
                      <Grid container spacing={2} sx={{}}>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <PriceChangeIcon />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="caption" color="text.secondary" component="div">
                                Custo Esperado
                              </Typography>
                              <Typography variant="body2" component="div">
                                R$ 500,00 /saco de 60kg
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={1}>
                            <Grid item xs>
                              <Typography variant="caption" color="text.secondary" component="div">
                                Custo da produção / produção esperada
                              </Typography>

                              <Typography variant="body2" component="div">
                                R$ 500,00 /saco de 60kg
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  : (
                    <Box height="88px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>

                      </Box>
                      <span>Defina abaixo o custo de produção</span>
                    </Box>
                  )}
              </Box>
              {/* fim card1  */}
              {/* card1  */}
              <Box mt={2}>
                <Card>
                  <CardContent sx={{}}>
                    <Box sx={{ display: "flex" }}>
                      <Stack spacing={0.5}>
                        <Typography fontWeight="400" variant="subtitle1">
                          Custo de Produção:
                        </Typography>
                      </Stack>
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography fontWeight="700" variant="subtitle1">
                              Preparação para a Agricultura
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TimelineHorizActivity />
                            {!isLoandigSavePlan && <PlanningHarvestCostEmpty setIsLoandigSavePlan={setIsLoandigSavePlan} />}
                            {isLoandigSavePlan &&
                              <Box sx={{ m: 2, ml: 2 }}>
                                <FormCostOthers />
                              </Box>
                            }
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography fontWeight="700" variant="subtitle1">
                              Pré-plantio
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography fontWeight="700" variant="subtitle1">
                              Plantio
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography fontWeight="700" variant="subtitle1">
                              Colheita
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              {/* fim card1  */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
