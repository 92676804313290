import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import { Button, Divider } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useState } from 'react'
import useLivestockReposStore from '../../../store/useLivestockReposStore'
import useLivestockTargetReposStore from '../../../store/useLivestockTargetReposStore'
import { calcularDateProgressBarra } from '../../../components/Calculations/calcularDateProgressBarra'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import usePlanLivestockReposStore from '../../../store/usePlanLivestockReposStore'
import SignpostIcon from '@mui/icons-material/Signpost'
import { useEffect } from 'react'
import { expandAndFade } from '../../../components/Animations/animationKeyFrames'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function CardMetaCost() {
  const navigate = useNavigate()
  const { idFarm, id } = useParams()

  const [value, setValue] = React.useState(0)

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }
  // <div className="row" style={{ height: 500, overflowY: "scroll" }}>

  //geral da criação(tipo,areas,lotes...)
  const livestockRepos = useLivestockReposStore((state) => state)
  //console.log(livestockRepos);
  //console.log(livestockRepos.livestockRepos);

  // definindo o livestock current
  const listLivestock = livestockRepos.getLivestockStore('id', id)

  const [dataLivestock, setDataLivestock] = useState(listLivestock[0])
  console.log('livestockRepos')
  console.log(dataLivestock)

  // planejando a meta da criaçao (target)
  // preciso verificar quantos planejamentos tem essa livestock...
  // instancia os repos planning
  const reposPlanning = useLivestockTargetReposStore((state) => state)
  console.log(reposPlanning)
  console.log(reposPlanning.listPlanning)
  //console.log(reposPlanning.planningRepos);
  // preciso verificar quantos planejamentos essa livestock tem...
  // vou filtrar a planejamento ativo
  const planActive = reposPlanning.listPlanning.filter((plan) => plan.isActiveTargetLivestock === 1)[0]
  console.log(planActive)

  //calculo produtividade livestock
  const date_start = planActive?.date_start
  const date_prev_end = planActive?.date_prev_end
  console.log(date_start)
  const date_start_format = dayjs(date_start).format('DD/MM/YYYY')
  const date_prev_end_format = dayjs(date_prev_end).format('DD/MM/YYYY')

  const valueProgress = calcularDateProgressBarra(date_start_format, date_prev_end_format)
  //console.log(valueProgress);
  // console.log(valueProgress.porcentagem)
  // //GMD = peso atual - peso anterior / número do intervalo da pesagem.
  const dateDiff = valueProgress.totalDias
  // console.log("dateDiff")
  // console.log(valueProgress.totalDias)
  const valueProductivity = (planActive?.expectedYield - planActive?.animalInput) / dateDiff
  //console.log(valueProductivity);
  const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2))

  //calculo produtividade meta específica
  const [production, setProduction] = useState(planActive?.expectedYield || 0)
  const [timeUnit, setTimeUnit] = useState(planActive?.unitTime) // 'semana', '15 dias', 'mês', 'ano'
  const [productivityOther, setProductivityOther] = useState(0)

  console.log(production)

  const calculateProductivity = () => {
    let days
    switch (timeUnit) {
      case 'dia':
        days = 1
        break
      case 'semana':
        days = 7
        break
      case '15 dias':
        days = 15
        break
      case 'mês':
        days = 30
        break
      case 'ano':
        days = 365
        break
      default:
        days = 1
    }

    const dailyProductivity = production / days
    console.log('dailyProductivity')
    console.log(dailyProductivity)
    setProductivityOther(dailyProductivity.toFixed(2))
  }

  useEffect(() => {
    calculateProductivity()
  }, [])

  console.log('productivityOther')
  console.log(productivityOther)

  //verificar
  const [plan, setPlan] = React.useState(planActive)
  console.log('reposPlanning- meta -plan')
  console.log(plan)

  // planejamento esperado:
  const reposPlanLivestock = usePlanLivestockReposStore((state) => state)
  //console.log(reposPlanLivestock);
  //console.log(reposPlanLivestock.listPlanLivestock);
  //console.log(reposPlanLivestock.planningRepos);
  console.log(reposPlanLivestock.planningRepos.id)
  const planLivestockRepos = reposPlanLivestock.getPlanningStore('id', reposPlanLivestock.planningRepos.id)[0]
  //console.log(planLivestockRepos);

  const [values, setValues] = React.useState(planLivestockRepos)
  console.log('reposPlanLivestock#-lanejamento esperado')
  console.log(values)

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="success" scrollButtons={true} TabIndicatorProps={{ style: { background: 'green' } }} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Esperada" {...a11yProps(0)} />
            <Tab label="Realizado" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                Visão: meta {plan?.typePlanning == 'Cria' && <Typography variant="caption">Cria</Typography>}
                {plan?.typePlanning == 'Corte' && <Typography variant="caption">Corte</Typography>}
                {plan?.typePlanning == 'Outra' && <Typography variant="caption">específica</Typography>}
              </Grid>
            </Grid>
            {plan ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Produção Esperada</Typography>
                  <Typography variant="subtitle2">
                    {plan?.typePlanning == 'Corte' && (
                      <>
                        <strong>{plan?.expectedYield}</strong>{' '}
                        <span style={{ fontSize: '0.8em' }}>
                          {plan?.unitWeight}/{plan?.unit}
                        </span>
                      </>
                    )}
                    {plan?.typePlanning == 'Cria' && (
                      <>
                        <strong>{plan?.bezerros}</strong> <span style={{ fontSize: '0.8em' }}>{plan?.unit}</span>
                      </>
                    )}
                    {plan?.typePlanning == 'Outra' && (
                      <>
                        <strong>{plan?.expectedYield}</strong>{' '}
                        <span style={{ fontSize: '0.8em' }}>
                          {plan?.unit}/{plan?.unitTime}
                        </span>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Produtividade Esperada</Typography>
                  <Typography variant="subtitle2">
                    {' '}
                    {plan?.typePlanning == 'Outra' ? (
                      <>
                        <strong>{productivityOther}</strong> <span style={{ fontSize: '0.8em' }}>{plan?.unit}/por dia</span>
                      </>
                    ) : (
                      <>
                        <strong>{productivity}</strong> <span style={{ fontSize: '0.8em' }}>{plan?.unitWeight}/dia</span>
                      </>
                    )}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid container spacing={2} sx={{ mb: -1 }}>
                <Grid item xs={12} sm={12}>
                  <Box height="80px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                      <Button
                        sx={{ animation: `${expandAndFade} 2s linear infinite` }}
                        variant="outlined"
                        color="success"
                        startIcon={<i className="fa fa-trophy text-success mr-1" />}
                        onClick={() => navigate('/production/' + idFarm + '/' + id)}
                      >
                        Meta de Produção
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {/* <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: custo</Typography>
              </Grid>
            </Grid>
            {values ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <PriceChangeIcon />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo Esperado
                      </Typography>
                      <Typography variant="body2" component="div">
                        <strong>R$ {values?.productionCost}</strong> <span style={{ fontSize: "0.8em" }}>{values?.unitSale}</span>{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo total da produção
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="div">
                        R$ {values?.totalProductionCost}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                  <Button variant="outlined" color="success" startIcon={<SignpostIcon />} onClick={() => navigate("/planning/" + idFarm + "/" + id)}>
                    Planejamento financeiro
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid> */}
          <Divider sx={{ mt: 2, mb: 2 }} />
          {/* <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: venda</Typography>
              </Grid>
            </Grid>
            {values ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption" sx={{ color: "#28a745" }} color="success" component="div">
                        Valor esperado para venda
                      </Typography>

                      <Typography variant="body2" sx={{ color: "#28a745" }} component="div">
                        R$ {values?.saleUnitValue} {values?.unitSale}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Lucro esperado sobre a venda:
                      </Typography>
                      <Typography variant="body2" component="div">
                        {values?.percentageProfit} %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                  <Button variant="outlined" color="success" startIcon={<SignpostIcon />} onClick={() => navigate("/planning/" + idFarm + "/" + id)}>
                    Planejamento financeiro
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid> */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: cenário atual</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Produção Realizada</Typography>
              <Typography variant="subtitle2">
                <strong>0</strong> <span style={{ fontSize: '0.8em' }}>/ca</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Produtividade Realizada</Typography>
              <Typography variant="subtitle2">
                {' '}
                <strong>0</strong> <span style={{ fontSize: '0.8em' }}>kg/dia</span>{' '}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: custo atual</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <PriceChangeIcon />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Custo Atual
                  </Typography>
                  <Typography variant="body2" component="div">
                    R$ 0 /ca
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Custo da produção / Número de animais
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="div">
                    R$ 0 /ca
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: projeção da venda</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Valor para venda
                  </Typography>
                  <Typography variant="h6" component="div">
                    R$ 0 <span style={{ fontSize: '0.8em' }}>/ca</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Lucro atual
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#28a745' }} component="div">
                    R$ 0 <span style={{ fontSize: '0.8em' }}>/ca</span>
                    <Typography variant="caption" sx={{ color: '#28a745' }} color="text.secondary" component="div">
                      (+0%)
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  )
}
