import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { AccountBalance, CurrencyExchange, HomeWork, LocalAtm, MonetizationOn, PointOfSale, Savings } from '@mui/icons-material';

import BancoDoBrasilIcon from './BancoDoBrasilIcon';
import BanckItauIcon from './BanckItauIcon';
import BradescoIcon from './BradescoIcon';
import CaixaEconomicaIcon from './CaixaEconomicaIcon';
import NubankIcon from './NubankIcon';
import SantanderIcon from './SantanderIcon';
import SicoobIcon from './SicoobIcon';
import BancoInterIcon from './BancoInterIcon';

function TypesBankList() {
  return [
    { title: 'Conta corrente', name: 'ContaCorrente', icon: <AccountBalance style={{ fontSize: 50 }} /> },
    { title: 'Caixinha', name: 'Caixinha', icon: <PointOfSale style={{ fontSize: 50 }} /> },
    // { title: "Cartão de Crédito", name: 'CartaoCredito', icon: <Payments style={{ fontSize: 50 }} /> },
    { title: 'Poupança', name: 'Poupanca', icon: <Savings style={{ fontSize: 50 }} /> },
    { title: 'Investimento', name: 'Investimento', icon: <CurrencyExchange style={{ fontSize: 50 }} /> },
    { title: 'Outras contas', name: 'OutrasContas', icon: <MonetizationOn style={{ fontSize: 50 }} /> },
    // { title: "Carteira",          name: 'carteira',      icon: <AccountBalanceWallet style={{ fontSize: 50 }} /> },
    // { title: "Outras",            name: 'outhers',       icon: <LocalAtm style={{ fontSize: 50 }} /> },
  ];
}

function BankList() {
  return [
    { label: 'Caixa da Propriedade', bank: 'caixa', icon: <HomeWork style={{ fontSize: '1.4rem', color: '#2e7d32' }} />, color: '#2e7d32', backgroundColor: '#e5ebe9' },
    { label: 'Banco do Brasil', bank: 'bancobrasil', icon: <BancoDoBrasilIcon style={{ fontSize: '1.4rem' }} />, color: '#f9e900', backgroundColor: '#f9e900' },
    { label: 'Bradesco', bank: 'bradesco', icon: <BradescoIcon style={{ fontSize: '1.6rem' }} />, color: '#dd3333', backgroundColor: '#dd3333' },
    { label: 'Caixa Econômica', bank: 'caixaEconomica', icon: <CaixaEconomicaIcon style={{ fontSize: '1.4rem' }} />, color: '#004987', backgroundColor: '#004987' },
    { label: 'Itaú', bank: 'itau', icon: <BanckItauIcon style={{ fontSize: '1.4rem' }} />, color: '#ec7600', backgroundColor: '#ec7600' },
    { label: 'Santander', bank: 'santander', icon: <SantanderIcon style={{ fontSize: '1.4rem' }} />, color: '#e60000', backgroundColor: '#e60000' },
    // { label: "BTG Pactual", bank: "btgpactual", icon: <BTGPactualIcon style={{ fontSize: "1.4rem" }} />, color: "#0066cc", backgroundColor: "#0066cc" },
    { label: 'Nubank', bank: 'nubank', icon: <NubankIcon style={{ fontSize: '1.4rem' }} />, color: '#820ad1', backgroundColor: '#820ad1' },
    { label: 'Banco Inter', bank: 'bancointer', icon: <BancoInterIcon style={{ fontSize: '1.4rem' }} />, color: '#0000ff', backgroundColor: '#0000ff' },
    // { label: "Banco Pan", bank: "bancoPan", icon: <BancoPanIcon style={{ fontSize: "1.4rem" }} />, color: "#ff0000", backgroundColor: "#ff0000" },
    { label: 'Sicoob', bank: 'sicoob', icon: <SicoobIcon style={{ fontSize: '1.4rem' }} />, color: '#018f05', backgroundColor: '#018f05' },
    { label: 'Outras', bank: 'outhers', icon: <LocalAtm style={{ fontSize: '1.4rem' }} /> },
  ];
}

function SelectBanks({ value, options, setValue, widthx }) {
  console.log(value);

  if (!options) {
    options = BankList();
  }

  return (
    <Autocomplete
      disableClearable
      freeSolo
      //size="small"
      filterSelectedOptions
      value={value}
      getOptionLabel={(option) => (option && option.label ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.bank === value.bank}
      options={options}
      onChange={(event, newValue) => {
        setValue('selectBanks', newValue);
      }}
      sx={{ width: widthx || '100%' }}
      id="selectBanks"
      name="selectBanks"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Bancos"
          color="success"
          placeholder="Ex.: Banco do Brasil"
          focused
          helperText={value.label === 'Padrão' ? 'Conta financeira básica pré-configurada para facilitar o controle financeiro.' : 'Selecione o nome do banco.'}
          InputProps={{
            ...params.InputProps,
            startAdornment: <strong> </strong>,
          }}
        />
      )}
      noOptionsText="Sem resultados"
    />
  );
}
export { BankList, SelectBanks, TypesBankList };
