import React, { useState } from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
const stage = [
    { id: 1, label: 'Hoje' },
    { id: 2, label: 'Foi realizada' },
    { id: 3, label: 'Será planejada' }
];

export default function FormInputStep4PeriodRadioButton({ data, setData }) {

    const handleFaseChange = (event) => {
        setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    };

    //const dateNow = new Date()

    // const date1 = dayjs(dateNow).format('DD/MM/YYYY')

    // function formatDate(inputDate) {
    //     const date = new Date(inputDate);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript começam em 0
    //     const year = date.getFullYear();
    //     return `${day}/${month}/${year}`;
    // }

    // const date2 = formatDate(data.date_start);

    // useEffect(() => {
    //     console.log("useEffect")
    //     if (data.stageData == 2) {
    //         setData((prevState) => ({ ...prevState, progress: 'CONCLUÍDA' }));
    //         //console.log("1")
    //     } else if (data.stageData == 3) {
    //         console.log(date1 == date2)
    //         if (date1 == date2) {
    //             setData((prevState) => ({ ...prevState, progress: 'EM PROGRESSO' }));
    //         } else {
    //             setData((prevState) => ({ ...prevState, progress: 'A FAZER' }));
    //         }

    //     }

    // }, [])


    return (
        <RadioGroup
            aria-label="fases"
            name="fases"
            value={data.stageData}
            onChange={handleFaseChange}
            style={{ display: "flex", flexDirection: "row" }}
        >
            {stage.map((fase) => (
                <FormControlLabel
                    key={fase.id} // Use o id como chave única
                    value={fase.id.toString()} // Use o label como valor para a seleção
                    name="stageData"
                    control={<Radio color="success" />}
                    label={fase.label} // Exiba o rótulo do item
                />
            ))}
        </RadioGroup>
    );
}






