
import { Box, Chip, Paper, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EditBasicDatePickerHarvest from "./EditBasicDatePickerHarvest";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TitleIcon from "@mui/icons-material/Title";
import AreaSelectDialog from "../addHarvest/AreaSelectDialog";

export default function FormEditHarvest({ data, setData, reposHarvests }) {
  const [isLoading, setIsLoading] = useState(false);
  const [validName, setValidName] = useState([true, '']);

  // para validar o input
  const validateProjectName = (value) => {
    var name_harvest = value.trim();
    if (!name_harvest || name_harvest.length < 5) {
      setValidName([false, 'Por favor, insira um nome válido para seu projeto!']);
    } else {
      var list_harvests = reposHarvests.getHarvestStore('name', name_harvest);
      if (list_harvests.length > 0) {
        var compare_harvest = list_harvests.filter((harvest) => { if (harvest.id != data.id) { return harvest; } });
        if (compare_harvest.length > 0) {
          setValidName([false, 'Já existe outro Projeto com esse nome!']);
        }
      } else {
        setValidName([true, '']);
      }
    }
  };
  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return ( <></>);
      } else {
        return ( <p id="NameHarvestError" className="form-text text-danger is-invalid">{validName[1]}</p> );
      }
    } else {
      return validName[num];
    }
  };

  const handleChangeInput = (event, tipo) => {
    var value = event.target.value;
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    //setData({ ...data, [event.target.name]: event.target.value });
    setData(prevState => ({ ...prevState, [event.target.name]: (tipo ? value.trim() : value) }));
    if (tipo) {
      validateProjectName(value);
    }
  };

  useEffect(() => {
    // console.log("RENDER - validateProjectName");
    validateProjectName(data.name);
  }, []);

  useEffect(() => {
    // console.info('render data.areas.');
  }, [data.areas])

  const handleDelete = (e, chipToDelete) => {
    // console.info('Delete area.');
    const newdataArea = data.areas.filter((chip) => chip.id !== chipToDelete.id);
    setData(prevState => ({ ...prevState, "areas": newdataArea }));
  };

  return ( <>
    <Box >
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <CalendarMonthIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Data de início e término do projeto</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3}>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={12} sm={12} >
            <EditBasicDatePickerHarvest data={data} setData={setData} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
        <TitleIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Nome do Projeto</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 5 }}>
        <TextField
          onChange={handleChangeInput}
          onBlur={(e) => handleChangeInput(e, 'name')}
          value={data.name}
          fullWidth
          color="success"
          id="NameHarvest"
          name="name"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon sx={{ color: "action.active" }} />
              </InputAdornment>
            )
          }}
          InputLabelProps={{ style: { color: "#808080" } }}
          label="Nome do Projeto"
          variant="standard"
          error={ !returnValidName(0) }
          helperText={ returnValidName(1) }
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
        <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
          Tipo da cultura ano/ano <br /> Ex.: Milho 23/24
        </Typography>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
        <TitleIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Áreas do projeto</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} alignItems="center" sx={{ my: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} alignItems="center">
            <AreaSelectDialog data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ display: "flex", alignItems: "flex-end" }}>
          <Paper sx={{ padding: 0.5 }} component="ul">
            {data.areas && data.areas.map((dataItem) => {
              return ( <>
                <Chip
                  key={dataItem.id}
                  variant="outlined"
                  label={(
                    <Typography > {dataItem.label || dataItem.name} ({dataItem.usedArea || dataItem.area} {dataItem.measure}) </Typography>
                  )}
                  onDelete={e => handleDelete(e, dataItem)}
                  sx={{ padding: 0.5, ml: 1 }}
                />
              </> );
            })}
          </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </> );
}
