import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, CardMedia, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
// import CreateDrawAreaList from '../../../../areasMaps/components/tutorial/CreateDrawAreaList';
// import IconsLivestockActivities from '../../../../../components/Icons/IconsLivestockActivities';
import CreateDrawPropertyList from './CreateDrawPropertyList';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     width: '100%',
//     height: '100%',
//   },
// }));

export default function TutorialAddProjectAccordion() {
  // const classes = useStyles();
  // const navigate = useNavigate();

  // const handleButtonClickLivestock = () => {
  //   navigate('/livestock');
  // };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Criar o projeto das Criações
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateDrawPropertyList />
          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" sx={{ color: grey[700] }} fontWeight={500}>
          Caso já tenha o projeto da criação, é hora de inciarmos o gerenciamento das atividades de campo e o gerenciamento da produção.
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Gerenciando o projeto pecuário</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Registre todas as atividades do dia a dia, planeje com antecedência as tarefas futuras e obtenha ideias (insights) valiosos para impulsionar sua produtividade.
            </Typography>

            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
                  </span>
                }
              >
                <ListItemText
                  primary={<strong>Gerenciamento das atividades de campo</strong>}
                  secondary="Para acessar, clique sobre o nome do projeto pecuário desejado e dentro do projeto, clique em no botão Atividades de Campo."
                />
              </ListItem>
            </Box>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
                  </span>
                }
              >
                <ListItemText
                  primary={<strong>Gerenciamento da produção</strong>}
                  secondary="Para acessar, clique sobre o nome do projeto pecuário desejado e dentro do projeto, clique em no botão Recursos ou Custo de Produção ou Produção"
                />
              </ListItem>
            </Box>

            <Box>
              {/* <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Criações</strong>} secondary="Clique sobre o nome do projeto desejado, na lista de projetos da sua propriedade." />
              </ListItem> */}
              <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item sm={12} xs={12}>
                  <CardMedia component="img" width="200" image="/dist/image/gestaopecuaria2.png" alt="Gestão Pecuária" />
                </Grid>
              </ListItem>
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Registrar a realização de uma atividade</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Caso já tenha o projeto da criação criado, é hora de inciarmos o gerenciamento das criações através dos registros das atividades.
            </Typography>
            <div style={{ display: 'flex' }}>
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, margin: '0 10px' }}
                onClick={() => handleButtonClickLivestock()}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Atividades
                  </Typography>
                </Stack>
              </ButtonBase>
            </div>
          </List>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </div>
  );
}
