import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { blue } from "@mui/material/colors";
import { Context } from "../../Context/AuthContext";
import Loading from "../../Loader/Loading";
import useStore from "../../../store/useStore";
import LoadActivityListLivestock from "../../LoadActivityList/LoadActivityListLivestock";
import LoadActivityListHarvest from "../../LoadActivityList/LoadActivityListHarvest";
import CardChatKanban from "../CardChatKanban";

export default function BoardTypes({ type}) {
  const { aux_add } = useContext(Context);
  const { idFarm, id } = useParams();

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredCollune, setColoumns] = useState([]);

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();

  const usestore = useStore();

  const handleRows = () => {
    let filters = {
      'table_active': "realized",
      'status': "edit",
    }

    let rows_atv;
    if (type == 'harvest') {
      const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
      rows_atv = loadActivityListHarvest.loadList(currentHarvest, filters);
    } else {
      const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
      rows_atv = loadActivityListLivestock.loadList(currentLivestok, filters);
    }

    setRows(rows_atv);
    let coloumns_aux = {};
    let coloumns = [];

    rows_atv.map(activityItem => {
      if (!coloumns_aux[activityItem.activity]) {
        coloumns_aux[activityItem.activity] = activityItem.activity;
        coloumns.push({
          id: (coloumns.length +1),
          title: activityItem.activity,
        })
      }
    });

    setTimeout(() => {
      setIsLoadingTable(false);
      setColoumns(coloumns);
    }, 500);
  };

  useEffect(() => {
    console.log('refresh BoardTypes');
    handleRows();
  }, [aux_add]);

  // area do board
  return ( <>
    <Box sx={{ ml: -3 }}>
      <div className="content-wrapper kanban p-0 m-0" style={{ backgroundColor: "#fff", font: "14px", color: "#333" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </section>
        {isLoadingTable ? 
          ( <> <Loading /> </>) : 
          filteredCollune.length == 0 ? (<>
            <div className="d-flex justify-content-center p-5">
              <p>Nenhuma atividade de campo a ser monitorada</p>
            </div>
          </>) : ( <>
          <section className="content pb-3 ">
            <div className="container-fluid h-100">
              {filteredCollune.map((activity) => (
                <div className={`card card-row `}>
                  <div className="card-header">
                    <h3 className="card-title">
                      <PlayCircleOutlineIcon sx={{ mr: 0.5, mt: -0.4, color: blue[700] }} /> {activity.title}
                    </h3>
                  </div>
                  <div className="card-body">
                    {rows
                      ?.filter((filteredPost) => filteredPost.activity === activity.title)
                      .map((post) => (
                        <CardChatKanban post={post} type={'tipos'} isLoading={isLoadingTable}/>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </>)}
      </div>
    </Box>
  </> );
}
