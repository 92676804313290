import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, ButtonBase, List, Paper, Stack, Typography } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

import WidgetsIcon from '@mui/icons-material/Widgets';
import TutorialFinancialAccordion from './TutorialFinancialAccordion';

export default function TutorialFinancial({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  return (
    <div>
      <Button variant="text" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </Button>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WidgetsIcon sx={{ mr: 1 }} /> Financeira
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão das Finanças
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mt: 2, mb: 2 }} id="alert-dialog-description">
            O módulo financeiro é o local onde você insere e visualiza todas as suas contas, despesas e receitas, essenciais para o controle financeiro da sua fazenda.
          </DialogContentText>
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Veja a seguir como é fácil registrar e gerenciar suas movimentações financeiras:
            </Typography>
          </Box> */}
          <TutorialFinancialAccordion />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
