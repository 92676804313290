import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ControlPanelSpecificAreaContent from "./ControlPanelSpecificAreaContent ";
import Footer from "../../components/Footer/Footer";

function ControlPanelSpecificArea() {
  return (
    <>
      <NavSide />
      <Nav />
      <NavBotton />
      <ControlPanelSpecificAreaContent />
      <Footer />
    </>
  );
}

export default ControlPanelSpecificArea;
