import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';

// create the store
const useActivityPastureManagementReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPastureManagement: [],
        pastureManagementRepos: { label: '', id: 0, id_property: 0 },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          //const data = []
          //const response = await api.post("activityPastureManagement/id", (item), header);
          //const data = await response.data_value;
          const response = await api.get('activityPastureManagement/list', header);
          const data = await response.data._value;
          // console.log('useActivityPastureManagementReposStore')
          // console.log(response.data)
          const getDateExit = (value) => {
            if (value == null) {
              return null;
            } else {
              return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
            }
          };
          set({
            listPastureManagement: data.map((pasture) => ({
              id: pasture.id,
              id_areamap: pasture.id_areamap,
              id_animal: pasture.id_animal,
              id_lote: pasture.id_lote,
              id_livestock: pasture.id_livestock,
              name_activity: pasture.name_activity,
              number_animals: pasture.number_animals,
              areamap_use: pasture.areamap_use,
              date_start: dayjs(pasture.date_start).format('YYYY-MM-DD HH:mm:ss'),
              date_prev_exit: dayjs(pasture.date_prev_exit).format('YYYY-MM-DD HH:mm:ss'),
              date_exit: getDateExit(pasture.date_exit),
              observation: pasture.areamap_use,
              name_nf: pasture.name_nf,
            })),
          });
          // set({
          //   pastureManagementRepos: (!data || data.length == 0 ? { label: "", id: 0, id_property: 0 } : { label: data[0].name, id: data[0].id, id_property: data[0].id_property })
          // })
          return data;
        },
      }),
      { name: 'reposPastureManagement' }
    )
  )
);

export default useActivityPastureManagementReposStore;
