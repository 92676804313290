import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, CardHeader, Container, Divider, Grid, Typography } from '@mui/material'
import { Context } from '../../../../components/Context/AuthContext'
import useStore from '../../../../store/useStore'
import CardIndicatorInventory from '../CardIndicatorInventory'
import RegisterNewStorageLocations from './RegisterNewStorageLocations'

export default function StorageLocations() {
  // console.log('StorageLocations')
  const usestore = useStore()
  const { aux_add } = useContext(Context)
  const [listStock, setListStock] = useState(usestore.reposPatrimony.listPatrimony)

  useEffect(() => {
    // console.log('refresh StorageLocations')
    setListStock(usestore.reposPatrimony.listPatrimony)
  }, [aux_add])

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Locais de estoque: </strong>Tenha apenas um estoque ou crie e visualize os seus produtos em locais diferentes.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
      <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} sx={{ mt: -3 }}>
              <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="flex-start">
                  <Typography fontWeight="500" sx={{ mt: 2 }} variant="subtitle1">
                    Lista dos locais de estoque
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
                  <RegisterNewStorageLocations />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={3}>
        {listStock.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            {/* <CardIndicator title={data.title} text={data.text} icon={data.icon} /> */}
            <CardIndicatorInventory patrimony={item} usestore={usestore}/>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
