export function getPolygonCenter(coordinates) {
    const numPoints = coordinates.length;
    let x = 0;
    let y = 0;

    coordinates.forEach((coord) => {
        x += coord.lat;
        y += coord.lng;
    });

    return {
        lat: x / numPoints,
        lng: y / numPoints,
    };
}