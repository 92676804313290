import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AppBar, Button, Card, CardHeader, Container, Dialog, Divider, Grid, List, ListItem, ListItemText, Slide, Stack, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import api from '../../services/api'
import useStore from '../../store/useStore'
import { Context } from '../../components/Context/AuthContext'
import RestaureModalProduct from '../inventory/components/RestaureModalProduct'
import MultiStepUpdateFormProduct from './components/productUpdate/MultiStepUpdateFormProduct'

const BASE_API_URL = 'http://localhost:5001'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Row({ data, handleDelete, handleClickOpen, setSelectedProduct }) {
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => setOpen(!open)}
            style={{ textDecoration: 'line-through', color: 'lightgrey' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ textDecoration: 'line-through', color: 'lightgrey' }}>
          {data.name}
        </TableCell>
        <TableCell align="right" style={{ textDecoration: 'line-through', color: 'lightgrey' }}>
          {data.id_category}
        </TableCell>
        <TableCell align="right" style={{ textDecoration: 'line-through', color: 'lightgrey' }}>
          {data.brand}
        </TableCell>
        <TableCell align="right" style={{ textDecoration: 'line-through', color: 'lightgrey' }}>
          {data.qnt_disponivel}
        </TableCell>
        <TableCell align="right">
          {/* <IconButton aria-label="expand row" size="small" onClick={() => handleDelete(data.id)}> */}
          <RestaureModalProduct id={data.id} />
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ textDecoration: 'line-through', color: 'lightgrey' }}
            // onClick={() => {
            //   setSelectedProduct(data)
            //   handleClickOpen()
            // }}
          >
            <BorderColorIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Informações
              </Typography>

              <Typography variant="p" sx={{ fontWeight: 'bold' }} gutterBottom component="div">
                Estoque
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Quantidade disponível</TableCell>
                    <TableCell>Custo médio (R$)</TableCell>
                    <TableCell align="right">Estoque mínimo</TableCell>
                    <TableCell align="right">Estoque máximo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {data.qnt_disponivel}
                    </TableCell>
                    <TableCell>{data.custo_medio}</TableCell>
                    <TableCell align="right">{data.estoque_min}</TableCell>
                    <TableCell align="right">{data.estoque_max}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Typography variant="p" sx={{ fontWeight: 'bold', mt: 3 }} gutterBottom component="div">
                Pesos e Dimensões
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Altura (cm)</TableCell>
                    <TableCell>Largura (cm)</TableCell>
                    <TableCell align="right">Profundidade (cm)</TableCell>
                    <TableCell align="right">Volume</TableCell>
                    <TableCell align="right">Peso Líquido</TableCell>
                    <TableCell align="right">Peso Bruto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {data.altura}
                    </TableCell>
                    <TableCell>{data.largura}</TableCell>
                    <TableCell align="right">{data.profundidade}</TableCell>
                    <TableCell align="right">{data.volume}</TableCell>
                    <TableCell align="right">{data.peso_liquido}</TableCell>
                    <TableCell align="right">{data.peso_bruto}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function ListProductInactive() {
  const usestore = useStore()
  const { aux_add } = React.useContext(Context)
  console.log(usestore.reposProducts.listProducts)
  const [productData, setProductData] = React.useState([])
  const [isProductDeleted, setIsProductDeleted] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState([])

  console.log('PRODUTO PARA EDITAR:\n')
  console.log(selectedProduct)

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setSelectedProduct([])
  }

  // useEffect(() => {
  //   console.log('refresh BoardTypes')
  // }, [aux_add])

  const handleDelete = async (id) => {
    const userConfirm = window.confirm('Tem certeza de que deseja excluir este produto?')
    const status = 0
    if (userConfirm) {
      try {
        const header = { header: { 'Content-Type': 'application/json' } }
        await api.delete(`products/list/${id}/${status}`, header)
        toast.success('Produto inativado com sucesso!')
        setIsProductDeleted(true)
      } catch (error) {
        console.error('Erro ao deletar produto:', error)
        toast.error('Erro ao deletar produto.')
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setProductData(usestore.reposProducts.listProducts)
      /*try {
        const header = { header: { "Content-Type": "application/json" } };

        const response = await api.get(`products/list`, header);
        if (response.data.isSuccess) {
          console.log("\n--- [InventoryList] productData ---\n");
          console.log(response.data._value);
          setProductData(response.data._value);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }*/
    }
    fetchData()
  }, [isProductDeleted, aux_add])

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <b>Nome do produto</b>
              </TableCell>
              <TableCell align="right">
                <b>Categoria</b>
              </TableCell>
              <TableCell align="right">
                <b>Marca</b>
              </TableCell>
              <TableCell align="right">
                <b>Quantidade</b>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productData?.map(
              (data) => data.isDeleted === 1 && <Row key={data.id} data={data} handleDelete={handleDelete} handleClickOpen={handleClickOpen} setSelectedProduct={setSelectedProduct} />
            )}
          </TableBody>
        </Table>
        <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
          <Box id="focus_top_modal">
            <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
              <Toolbar>
                <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                  Atualizar Produto <b>{selectedProduct.name}</b>
                </Typography>
                <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
                <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon sx={{ color: '#000' }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="lg" sx={{}}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <MultiStepUpdateFormProduct dataToUpdate={selectedProduct} handleClose={handleClose} />
              </Paper>
            </Container>
          </Box>
        </Dialog>
      </TableContainer>
    </>
  )
}
