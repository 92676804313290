const ProgressBar = (props) => {
  const { bgcolor, completed, days } = props;

  const msn = (bgcolor == "#ef6c00" ? 'ATRASADA' : completed + '%');

  const containerStyles = {
    position: "relative",
    height: 15,
    width: "100%",
    backgroundColor: "#e0e0de",
    //borderRadius: 50,
    marginTop: 5,
    marginBottom: 5,
    right: 5,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    //borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    position: "relative",
    bottom: 4,
    padding: 5,
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles} className="">{`${msn}  -  ${days}d`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
