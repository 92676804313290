import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useActivityAssignAreaReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityAssignArea: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem("Property") || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("activityAssignArea/list", item, header);
          const data = await response.data;

          await set({
            listActivityAssignArea:
              data._value.map((activityAssignArea) => {
                return {
                  id: activityAssignArea.id,
                  id_property: activityAssignArea.id_property,
                  id_user: activityAssignArea.id_user,
                  name_user: activityAssignArea.name_user,
                  id_livestock: activityAssignArea.id_livestock,
                  id_harvest: activityAssignArea.id_harvest,
                  tipo_item: (!activityAssignArea.id_harvest ? 'livestock' : 'harvest'),
                  name: activityAssignArea.name,
                  name_nf: activityAssignArea.name.toLowerCase(),
                  ids_areamap: activityAssignArea.ids_areamap,
                  ids_array: (activityAssignArea.ids_areamap).split(','),
                  tipo_assign: activityAssignArea.tipo_assign,
                  date_start: dayjs(activityAssignArea.date_start).format("YYYY-MM-DD HH:mm:ss"),
                  date_start_f: dayjs(activityAssignArea.date_start).format("DD/MM/YYYY"),
                  date_prev_end: dayjs(activityAssignArea.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
                  date_prev_end_f: dayjs(activityAssignArea.date_prev_end).format("DD/MM/YYYY"),
                  date_end: activityAssignArea.date_end ? dayjs(activityAssignArea.date_end).format("YYYY-MM-DD HH:mm:ss") : "",
                  date_end_f: activityAssignArea.date_end ? dayjs(activityAssignArea.date_end).format("DD/MM/YYYY") : "",
                  observation: activityAssignArea.observation,
                  isDeleted: activityAssignArea.isDeleted,
                  progress: activityAssignArea.progress,
                  planning: activityAssignArea.planning,
                  tipo_repos: activityAssignArea.name,
                  activity: activityAssignArea.name,
                  type: "activity",
                  name_activity: activityAssignArea.name,
                }
              })
          });

          const lista = JSON.parse(localStorage.getItem("listActvs"));
          const lista_i = get().listActivityAssignArea;

          let array_aux = lista.filter((activity) => {
            if (!/VINCULAR.*/.test(activity.activity)) {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem("listActvs", JSON.stringify(res));

          return data;
        },
        // pega a(s) livestock(s) da listActivityAssignArea de acordo com o [key e val] informados
        getActivityAssignAreaStore: (key, val) => {
          return get().listActivityAssignArea.filter((activityAssignArea) => {
            if (activityAssignArea[key] == val) {
              return activityAssignArea;
            }
          });
        },
        // // Adiciona uma nova livestock à listActivityAssignArea
        addActivityAssignAreaStore: (livestock) => {
          set((state) => ({
            listActivityAssignArea: [...state.listActivityAssignArea, livestock],
          }));
        },
        // Gera uma nova listActivityAssignArea sem a activity informada
        delAssignAreaStore: (id) => {
          set({
            listActivityAssignArea: get().listActivityAssignArea.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
      }),
      { name: "reposActivityAssignArea" }
    )
  )
);

export default useActivityAssignAreaReposStore;
