import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, Grid, MenuItem, Stack, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AccountBalance, Payment } from '@mui/icons-material';
import dayjs from "dayjs";
import useStore from "../../../../store/useStore";
// import CurrencyTextField from "../../../../components/Fields/CurrencyTextField";
import SingleDateField from '../../../../components/Fields/SingleDateField';
import GenericValueField from '../../../../components/Fields/GenericValueField';
import { formatValue } from '../../../../utils/functions';

const formPayments = [
  {
    value: "dinheiro",
    label: "Dinheiro",
  },
  {
    value: "boleto",
    label: "Boleto",
  },
  {
    value: "cartao",
    label: "Cartão",
  },
  {
    value: "pix",
    label: "Pix",
  }
];


// PARCELAS DO PAGAMENTO
export default function FormPaymentInstallments({ data, obj_fn }) {
  // console.log("FormPaymentInstallments");
  const [installmentValues, setInstallmentValues] = useState({
    installment_value: 0,
    installmentInterval: 0,
    installment: 0,
    value_end: data.value_end,
    formPayment: data.formPayment,
  });

  const usestore = useStore();
  const bankList = usestore.reposBank.listBank;

  const default_installment = {
    dueDate: data.dueDate,
    dueDate_f: dayjs(data.dueDate).format("YYYY-MM-DD HH:mm:ss"),
    formPayment: data.formPayment,
    bankAccount: data.bankAccount,
    valueTotal: parseFloat((parseFloat(data.value_end) / data.installment).toFixed(2)),
    portion: 0,
    description: '',
    payed: data.payed || false,
    error_date: false,
  }
  // contém o estado inicial das parcelas
  const [installments, setInstallments] = useState(data.arr_installment);

  const handlePortions = (type) => {
    let initialInstallment = [];
    if (data.installment !== 'a_vista') {
      // Cria um array com objetos iniciais'
      var aux_val = data.value_end;
      let index = 0;
      let new_date = data.dueDate;
      if (type === 'new') {
        initialInstallment = Array.from({ length: data.installment }, () => {
          index++;
          let aux_ = JSON.parse(JSON.stringify(default_installment));
          // let aux_ = Object.assign({}, default_installment);
          aux_.dueDate = (index === 1 ? new_date : dayjs(new_date).add(data.installmentInterval, 'day'));
          aux_.dueDate_f = dayjs(aux_.dueDate).format("YYYY-MM-DD HH:mm:ss");
          new_date = aux_.dueDate;
          aux_.description = (data.descriptionEntry || "Parcela") + " " + index + "/" + data.installment;
          aux_.portion = index;
          aux_val -= aux_.valueTotal;
          return aux_;
        } );
        setInstallmentValues(prevState => ({
          ...prevState,
          value_end: data.value_end,
          installment: data.installment,
          installmentInterval: data.installmentInterval
        }));

      } else if (type === 'interval') {
        // atualiza apenas a data das parcelas
        initialInstallment = installments.map((portion) => {
          index++;
          portion.dueDate = (index === 1 ? new_date : dayjs(new_date).add(data.installmentInterval, 'day'));
          new_date = portion.dueDate;
          portion.dueDate_f = dayjs(portion.dueDate).format("YYYY-MM-DD HH:mm:ss");
          aux_val -= portion.valueTotal;

          return portion;
        });
        setInstallmentValues(prevState => ({ ...prevState, installmentInterval: data.installmentInterval }));
      } else if (type === 'formPayment') {
        initialInstallment = installments.map((portion) => {
          index++;
          portion.formPayment = data.formPayment;
          aux_val -= portion.valueTotal;

          return portion;
        });
        setInstallmentValues(prevState => ({ ...prevState, formPayment: data.formPayment }));
      }
    }
    // garante que todo valor foi distribuido
    aux_val = parseFloat((aux_val).toFixed(2))
    if (aux_val > 0 && initialInstallment.length > 0) {
      initialInstallment[initialInstallment.length-1].valueTotal = parseFloat((initialInstallment[initialInstallment.length-1].valueTotal + aux_val).toFixed(2))
    }
    // seta array das parcelas
    setInstallments(initialInstallment);
    obj_fn.setData(prevState => ({ ...prevState, arr_installment: initialInstallment }));
  };

  useEffect(() => {
    if (installmentValues.installment !== data.installment || installmentValues.value_end !== data.value_end) {
      handlePortions('new');
    } else if (installmentValues.installmentInterval !== data.installmentInterval) {
      handlePortions('interval');
    } else if (installmentValues.formPayment !== data.formPayment) {
      handlePortions('formPayment');
    }
  }, [data.installmentInterval, data.installment, data.value_end, data.formPayment]);

  // é uma função que é chamada quando o valor de um outro recurso ou informações é alterado. 
  // Ela recebe o índice do objeto modificado, o campo modificado e o novo valor. 
  // A função atualiza o estado de installments.
  const handleInstallmentChange = (index, field, value) => {
    let aux_installments = JSON.parse(JSON.stringify(installments));
    if (!value && field !== 'payed' && field !== 'description') {

      let aux_field, aux_index, aux_value, aux_split;
      if (!field) { // CurrencyTextField -> valueTotal
        aux_split = (index.target.name).split('_');
        aux_field = aux_split[0];
        aux_index = aux_split[1];
        aux_value = parseFloat(index.target.value);
        let aux_value_t = parseFloat(data.value_end) - aux_value;

        // AJUSTAR valueTotal DAS DEMAIS PARCELAS
        aux_installments = aux_installments.map((portion, i_p) => {
          if (i_p === aux_index) {
            aux_installments[aux_index][aux_field] = aux_value;
          } else {
            portion[aux_field] = parseFloat((aux_value_t / (data.installment -1)).toFixed(2));
          }
          return portion;
        });

      } else { // SingleDateField -> dueDate
        aux_split = (field).split('_');
        aux_field = aux_split[0];
        aux_index = aux_split[1];
        aux_value = index;
        aux_installments[aux_index]['dueDate'] = aux_value;
        aux_installments[aux_index]['dueDate_f'] = dayjs(aux_value).format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      aux_installments[index][field] = value;
    }
    setInstallments(aux_installments);
    obj_fn.setData(prevState => ({ ...prevState, arr_installment: aux_installments }));
  };

  // A função atualiza o valor da parcela; 
  // Quando a primeira parcela é alterada, altera o valor das demais; 
  const handleInstallmentChangeValue = (event) => {
    let aux_installments = JSON.parse(JSON.stringify(installments));
    
    var err_value = data.value_end
    var aux_split = (event.target.name).split('_');
    // var aux_field = aux_split[0];
    var aux_index = Number(aux_split[1]);
    var aux_value = parseFloat(event.target.value);
    
    let val_portion, aux_value_t;
    aux_installments = aux_installments.map((portion, i_p) => {
      if (i_p === aux_index) {
        val_portion = aux_value;
      } else if (aux_index === 0 && i_p !== aux_index) {
        aux_value_t = parseFloat(data.value_end) - aux_value;
        val_portion = parseFloat((aux_value_t / (data.installment -1)).toFixed(2));
      } else {
        val_portion = portion.valueTotal;
      }
      err_value -= val_portion
      portion.valueTotal = val_portion

      return portion;
    });

    err_value = parseFloat(err_value.toFixed(2))
    if (err_value !== 0) {
      obj_fn.setError(true)
    } else {
      obj_fn.setError(false)
    }
    setInstallmentValues(prevState => ({ ...prevState, installment_value: err_value }));

    setInstallments(aux_installments);
    obj_fn.setData(prevState => ({ ...prevState, arr_installment: aux_installments }));
  };

  // é uma função que é chamada quando o usuário clica no botão "Adicionar Parcela". 
  // Ela adiciona um novo objeto vazio ao array, apenas se o campo de outros recursos estiver preenchido.
  // const handleCreateForm = () => {
  //   if (installments.length > 0 && installments[installments.length - 1].valueTotal) {
  //     setInstallments((prev) => [...prev, default_installment]);
  //   } else {
  //     toast.error("Por favor, preencha o campo de valor.");
  //   }
  // };
  // é uma função que é chamada quando o usuário clica no botão "Excluir" de um outro recurso. 
  // Ela remove o objeto correspondente do array installments usando splice e atualiza o estado.
  // const handleDeleteForm = (index) => {
  //   setInstallments((prevInstallment) => {
  //     const updatedInstallment = [...prevInstallment];
  //     updatedInstallment.splice(index, 1);
  //     return updatedInstallment;
  //   });
  // };

  return (<>
    <Grid container spacing={3} >
      <Grid item xs={12} sm={12}>
        <Box sx={{ ml: 1, display: "flex" }}>
          <Payment sx={{ mr: 1}}/>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Parcelas</Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid container spacing={2}>
        {installments.map((portion, index) => (<>
          <Grid item xs={12} sm={12} key={'g_' + index}>
            <Accordion key={'ac_' + index} expanded={true}>
              <AccordionSummary key={'as_' + index} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`}>
                <Grid key={'g2_' + index} container spacing={1} >
                  <Grid item xs={0.5} sm={0.5} style={{marginTop: '55px', marginBottom: '-30px'}}>
                    <Stack spacing={0.5} sx={{mr: 4}}>
                      <Typography fontWeight={700}> {(index+1) + "/" + data.installment}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={11.5} sm={5.5} >
                    <Stack spacing={0.5} sx={{ flexGrow: 1, mr: 5, '& .MuiInputBase-root': { height: '50px', } }}>
                      <TextField
                        required
                        fullWidth
                        color="success"
                        size="small"
                        label="Descrição"
                        variant="outlined"
                        onChange={(e) => handleInstallmentChange(index, "description", e.target.value)}
                        //value={portion.description || (data.descriptionEntry + " " + (index+1) + "/" + data.installment)}
                        value={portion.description}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={11.5} sm={5.5} sx={{ '& .MuiInputBase-root': { height: '56px', } }}>
                    <TextField
                      InputLabelProps={{ style: { color: "#2e7d32" } }}
                      id="bankAccount"
                      name="bankAccount"
                      label={("Conta de " + (data.operationType === "Conta a receber" ? "Recebimento" : "Pagamento"))}
                      select
                      size="small"
                      fullWidth
                      value={portion.bankAccount}
                      onBlur={(e) => handleInstallmentChange(index, "bankAccount", e.target.value)}
                      onChange={(e) => handleInstallmentChange(index, "bankAccount", e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <AccountBalance />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      color="success"
                      //helperText="Defina a conta bancária"
                    >
                      {bankList.map((option) => (
                        <MenuItem key={'bank_' + option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={2} sm={2} >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={portion.payed}
                          color="success"
                          onChange={(e) => handleInstallmentChange(index, 'payed', e.target.checked)}
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                      }
                      label={<Typography>Pago</Typography>}
                      labelPlacement="end"
                    />
                  </Grid> */}
                </Grid>
              </AccordionSummary>
              <AccordionDetails key={'ad_' + index}>
                <Grid key={'g3_' + index} container spacing={2} sx={{mt: '-15px', maxHeight: '115px'}}>
                  <Grid item xs={0.5} sm={0.5} >
                    <Stack spacing={0.5} sx={{mr: 4}}>
                      <Typography fontWeight={700}> {" "}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={11.5} sm={3.5} sx={{mt: '-25px'}} >
                    <SingleDateField data={portion} 
                      obj_date={{
                        label: 'Data de Vencimento',
                        id: 'dueDate_' + index,
                        name: 'dueDate_' + index,
                        field_data: 'dueDate',
                        text_helper: 'Data que o pagamento será efetuado',
                        min: data.dueDate,
                        handleChange: handleInstallmentChange,
                        setData: obj_fn.setData,
                        maxWidth: '250px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={11.5} sm={3.5}>
                    {/* <CurrencyTextField
                      required
                      id={"valueTotal_" + index}
                      name={"valueTotal_" + index}
                      label="Valor Parcela*"
                      color="success"
                      value={portion.valueTotal}
                      onChange={handleInstallmentChange}
                      helperText="Defina o valor da Parcela"
                    /> */}
                    <GenericValueField
                      handleChange={handleInstallmentChangeValue}
                      options={{
                        required: true,
                        id:"valueTotal_" + index,
                        name:"valueTotal_" + index,
                        label: 'Valor Parcela',
                        value: portion.valueTotal,
                        variant: 'outlined',
                        helperText: "Defina o valor da Parcela",
                        endAdornment: true,
                        prefix: " (R$) ",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={3} sm={3} > */}
                  <Grid item xs={11.5} sm={3.5} sx={{ '& .MuiInputBase-root': { height: '56px', } }} >
                    <TextField
                      select
                      fullWidth
                      InputLabelProps={{ style: { color: "#2e7d32" } }}
                      id="formPayment"
                      name="formPayment"
                      label={("Forma de " + (data.operationType === "Conta a receber" ? "Recebimento" : "Pagamento"))}
                      size="small"
                      value={portion.formPayment}
                      onBlur={(e) => handleInstallmentChange(index, "formPayment", e.target.value)}
                      onChange={(e) => handleInstallmentChange(index, "formPayment", e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Payment />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      color="success"
                      //helperText="Defina a Forma bancária"
                    >
                      {formPayments.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>))}
      </Grid>
      {/* <Box sx={{ ml: 2, display: (installmentValues.installment_value !== 0 ? 'flex' : 'none') }} > */}
      <Box sx={{ width: '90%', justifyContent: 'flex-end', display: (installmentValues.installment_value !== 0 ? 'flex' : 'none') }} >
        <Typography variant="subtitle1" color={"error"}>
          {installmentValues.installment_value < 0 ? "Valor das parcelas ultrapassa o valor total em: " + (installmentValues.installment_value*-1):
          "Valor a ser distribuido nas parcelas: " + formatValue(installmentValues.installment_value, true) }
          <br/>
          Ajuste os valores para continuar!
        </Typography>
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mr: 10 }}>
        <Button variant="outlined" color="success" onClick={handleCreateForm}>
          Adicionar Parcela
        </Button>
      </Box> */}
    </Grid>
  </>);
}