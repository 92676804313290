import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Span } from '../../../../components/Typography';
import MenuPlan from '../../../signature/components/MenuPlan';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import AddActivities from '../../../activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function TablesHarvestCardProjectManagement({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  //cores:
  //#45bc82

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Gerenciamento de projetos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontWeight={400} style={{ color: '#2e7d32' }}>
            "Liderar é bater metas consistentemente, com o time fazendo certo." Vicent Falconi
          </Typography>
          <Card sx={{ m: 2, backgroundColor: '#217346' }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    {' '}
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }} style={{ color: 'white' }}>
                        Gerenciamento de projetos pecuários:
                      </Typography>
                    </Box> */}
                    <Box sx={{ minHeight: 80 }}>
                      <Typography style={{ color: 'white' }}>
                        {isLoadingText && <Typewriter tam={80} minHeight={0} text="Para se tornar um ótimo gestor de projetos, seguiremos as seguintes fases:" />}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" style={{ color: 'white' }}>
                        Gerenciamento de projetos pecuários:
                      </Typography>
                    </Box> */}
                    <Box sx={{ minHeight: 35 }}>
                      <Typography style={{ color: 'white' }}>
                        {isLoadingText && <Typewriter tam={30} minHeight={0} text="Para se tornar um ótimo gestor de projetos, seguiremos as seguintes fases:" />}
                      </Typography>
                    </Box>
                  </div>
                  <List sx={{ width: '100%', bgcolor: '#217346' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        {/* <Avatar alt="fases" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar sx={{ bgcolor: 'white' }}>
                          <img src="/dist/img_fp/icon-button/todas_pecuarias.png" className="img-fluid" width="40" alt="Imagem responsiva"></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary="FASE: INÍCIO DO PROJETO"
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                              Registro do projeto pecuário:
                            </Typography>
                            <span style={{ color: 'white' }}>{' Criaremos um projeto pecuário organizado, personalizado e de sucesso!'}</span>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <EmojiEventsIcon />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <PendingActionsIcon style={{ fontSize: 24 }} />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <ListAltIcon style={{ fontSize: 24 }} />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: PLANEJAMENTO E EXECUÇÃO</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Meta:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Estabelecer metas claras e resultados esperados.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Caderno de campo:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Elaborar um cronograma de atividades, alocando tarefas e responsabilidades para cada fase do projeto.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Recursos:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Alocar os recursos necessários (insumos, mão de obra, equipamentos, financeiros) para cada atividade do projeto.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Liderança da equipe:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Gerenciar a equipe, promovendo cooperação e motivação.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Implementação de práticas e tecnologias:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Aplicar métodos, melhores práticas e tecnologias produtivas.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Supervisão das atividades:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Garantir que as tarefas sejam realizadas conforme o planejado.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <DashboardIcon />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} fill={'white'} />
                        </Avatar>
                        <Avatar sx={{ mt: 2, bgcolor: '#37a96e' }}>
                          <ShoppingCartIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: MONITORAMENTO E CONTROLE</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Monitoramento do projeto:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Acompanhar o progresso das atividades, da produção e dos custos em relação às metas.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Monitoramento da safra:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Monitore regularmente a saúde e o desempenho das plantações.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Correção de desvios e ajuste de planos:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Monitorar e controlar os custos de produção das atividades envolvidas no projeto.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Relatórios de progresso e desafios:
                              </Typography>
                              <span style={{ color: 'white' }}> {' Fornecer atualizações regulares e comunicar problemas ao proprietário.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Análise de riscos:
                              </Typography>
                              <span style={{ color: 'white' }}> {' Avaliar continuamente os riscos e implementar ações corretivas quando necessário.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <EmojiEventsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: ENCERRAMENTO</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Avaliação:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Analisar o desempenho do projeto em relação aos objetivos estabelecidos.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Documentação de lições aprendidas:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Registrar o que funcionou bem (melhores práticas) e o que precisa ser melhorado.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Conclusão:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Celebrar as conquistas e reconhecer o esforço da equipe envolvida.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
