import * as React from 'react';
import { Autocomplete, Avatar, Box, FormControl, Grid, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
// import { PrecisionManufacturing, Warehouse } from '@mui/icons-material';
import * as MuiIcons from '@mui/icons-material';
import dayjs from 'dayjs';
import FooterModal from '../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../components/Fields/SingleDateField';

const size = 50; // Define o tamanho desejado
// const iconMap = {
//   WarehouseIcon: <Warehouse sx={{ fontSize: size, mt: 0 }} />,
//   PrecisionManufacturingIcon: <PrecisionManufacturing sx={{ fontSize: size, mt: 0 }} />,
// };

// Obtenha o ícone dinamicamente usando o nome
function IconComponent({ iconName }) {
  let aux_icon = iconName.replace(/Icon$/, '');
  const Icon = MuiIcons[aux_icon];

  // Verifique se o ícone existe
  if (!Icon) {
    return <span>-</span>;
  }
  return <Icon sx={{ fontSize: size }} />;
}

export default function FormMaintenancenStep1({ data, obj_fn }) {
  // console.log('FormMaintenancenStep1');
  // console.log(data);

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    if (!data?.name) {
      iserror = true;
    }
    return iserror;
  };

  const returnError = () => {
    return true;
  };

  // form date
  const handleChangeDate = (date) => {
    obj_fn.setData({
      ...data,
      date_start: dayjs(date),
      date_start_f: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      date_start_fc: dayjs(date).format('DD/MM/YYYY'),
      dueDate: dayjs(date),
      dueDate_f: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      dueDate_fc: dayjs(date).format('DD/MM/YYYY'),
    });
  };
  let obj_date = {
    label: 'Data de registro',
    field_data: 'date_start',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    setData: obj_fn.setData,
  };

  const handleAutocompleteChange = (event, newValue) => {
    // console.log(newValue);
    if (!newValue) {
      obj_fn.setData((prevState) => ({
        ...prevState,
        patrimony: null,
        id_patrimony: null,
        name: '',
        type: '',
        group: '',
      }));
    } else {
      obj_fn.setData((prevState) => ({
        ...prevState,
        patrimony: newValue,
        id_patrimony: newValue.id,
        name: newValue.name,
        type: newValue.type,
        group: newValue.group,
      }));
    }
  };

  // form horimetro
  const handleChangeLabel = (event) => {
    console.log('handleChangeInput');
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  // console.log(obj_fn.usestore.reposPatrimony.listPatrimony);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 2, display: 'flex' }}>
            <ChecklistIcon />
            <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
              <Typography fontWeight={700}>Informações essenciais</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4}>
          <SingleDateField data={data} obj_date={obj_date} />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid item xs={12} md={12} sx={{ my: 3 }}>
            <Autocomplete
              color="success"
              value={data.patrimony}
              options={obj_fn.usestore.reposPatrimony.listPatrimony}
              groupBy={(option) => option.group}
              isOptionEqualToValue={(option, val) => option.id === val.id}
              getOptionLabel={(option) => `${option.type}: ${option.name}`}
              onChange={handleAutocompleteChange}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box
                    elevation={0}
                    sx={{
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 0,
                      marginBottom: 0,
                    }}
                  >
                    {option.image ? (
                      <Avatar
                        sx={{
                          height: size,
                          width: size,
                          marginRight: 2, // Espaço entre o Avatar e o conteúdo
                        }}
                        aria-label="image"
                        src={`data:image/jpeg;base64,${option.image}`}
                      />
                    ) : (
                      <Box
                        sx={{
                          height: size,
                          width: size,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: 2, // Espaço entre o ícone e o conteúdo
                        }}
                      >
                        {/* {iconMap[option.patrimony_group_icon]} */}
                        <IconComponent iconName={option.patrimony_group_icon} />
                      </Box>
                    )}
                    <div>
                      {/* Adicione mais conteúdo aqui se necessário */}
                      {/* <Typography variant="h6">{option.name}</Typography> */}
                    </div>
                  </Box>

                  <Stack direction="column" alignItems="center" spacing={0} useFlexGap>
                    <div style={{ width: '100%' }}>
                      <Typography color="text.primary" fontWeight="semiBold" sx={{ textAlign: 'left', ml: 0.5 }}>
                        {option.name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" fontWeight="medium" sx={{ ml: 1, textAlign: 'left', display: 'block', width: '100%' }}>
                        {option.type}
                      </Typography>
                    </div>
                  </Stack>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} color="success" label="Selecione o patrimônio" helperText="Selecione o patrimônio desejado para a manutenção" />}
            />
          </Grid>
        </Grid>
        {data.group === 'Maquinário' && (
          <Grid item xs={12} sm={3} sx={{ mt: 3 }}>
            <Typography color="text.primary" fontWeight="semiBold" sx={{ textAlign: 'left', ml: 0.5 }}>
              {data.type === 'Veículo' ? 'Atualize o Orímetro:' : 'Atualize o Horímetro:'}
            </Typography>
            <Typography variant="caption" fontWeight="semiBold" sx={{ textAlign: 'left', ml: 0.5 }}>
              {data.type === 'Veículo' ? 'anterior(500)' : 'anterior(1000)'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
          {data.group === 'Maquinário' && (
            <>
              {data.type === 'Veículo' ? (
                <FormControl color="success" fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel color="success" htmlFor="horimetro_current">
                    Orímetro atual (h)
                  </InputLabel>
                  <Input id="horimetro" name="horimetro" value={data.horimetro_current} onChange={handleChangeLabel} startAdornment={<InputAdornment position="start">(h)</InputAdornment>} />
                </FormControl>
              ) : (
                <FormControl color="success" fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel color="success" htmlFor="horimetro_current">
                    Horímetro atual (h)
                  </InputLabel>
                  <Input id="Orímetro" name="horimetro" value={data.horimetro_current} onChange={handleChangeLabel} startAdornment={<InputAdornment position="start">(h)</InputAdornment>} />
                </FormControl>
              )}
            </>
          )}
        </Grid>
        {data.name && (
          <Grid item sm={12} xs={12}>
            <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
              Clique em continuar...
            </Typography>
          </Grid>
        )}
      </Grid>
      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
