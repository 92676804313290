import dayjs from "dayjs";

function calculaIdadeCompleta(data_nascimento) {
  var obj = { 'anos': 0, 'meses': 0, 'dias': 0 };
  var d1 = new Date(data_nascimento);
  var d2 = new Date();
  obj.dias = d2.getDate() - d1.getDate();
  if (d1) {
    if (obj.dias < 0) {
      d2.setMonth(d2.getMonth() - 1);
      obj.mes_inicio = new Date(d2.getFullYear(), d2.getMonth(), 1);
      obj.mes_fim = new Date(d2.getFullYear(), d2.getMonth() + 1, 1);
      obj.dias += (obj.mes_fim - obj.mes_inicio) / (1000 * 60 * 60 * 24);
    }
    //--------------------------------------------------------------
    obj.meses = d2.getMonth() - d1.getMonth();
    if (obj.meses < 0) {
      d2.setFullYear(d2.getFullYear() - 1);
      obj.meses += 12;
    }
    //--------------------------------------------------------------
    obj.anos = d2.getFullYear() - d1.getFullYear();
  }
  if (obj.anos === 0 && obj.meses === 0 && obj.dias === 0) {
    obj.texto = '0 dias';
  } else {
    obj.texto = obj.anos > 0 ? obj.anos + ' ' + (obj.anos == 1 ? 'ano' : 'anos') : '';
    obj.texto += obj.meses > 0 ? (obj.anos > 0 ? ', ' : '') + obj.meses + ' ' + (obj.meses == 1 ? 'mes' : 'meses') : '';
    obj.texto += obj.dias > 0 ? (obj.anos > 0 || obj.meses > 0 ? ' e ' : '') + obj.dias + ' ' + (obj.dias == 1 ? 'dia' : 'dias') : '';
  }
  return obj.texto;
}

function calculaIdade(data, tipo, txt) {
  let data_start = dayjs(data).format('YYYY-MM-DD');
  if (!data_start) {
    return 0;
  }
  data_start = dayjs(data_start);
  const hoje = dayjs();

  let idade = '';
  let idade_d = hoje.diff(data_start, 'day');
  let idade_m = hoje.diff(data_start, 'month');
  let idade_y = hoje.diff(data_start, 'year');

  if (tipo == 'dia') {
    idade = idade_d + (txt ? ' Dias' : '');

  } else if (tipo == 'mes') {
    idade = idade_m + (txt ? ' Meses' : '');

  } else if (tipo == 'ano') {
    idade = idade_y + (txt ? ' Anos' : '');

  } else if (tipo == 'completa') {
    idade = calculaIdadeCompleta(data);
  }

  return idade;
}

export { calculaIdade, calculaIdadeCompleta };