import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'
import RegisterNewStorageLocations from '../RegisterNewStorage/RegisterNewStorageLocations'
import RegisterBuyDialog from '../../RegisterBuyDialog'
import ProductRegistrar from '../../../products/components/ProductRegistrar/ProductRegistrar'
import RegisterPerson from '../../../person/components/addPerson/RegisterPerson'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export default function CustomizedMenusBuy() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getMenuItem = (component) => {
    return (
      <span onClick={(event) => event.stopPropagation()} disablefocusripple disableripple
        onKeyDown={(event) => {
          if (/P|p/.test(event.key) || event.key === 'Tab' || event.key === 'Home' || event.key === 'End') {
            event.stopPropagation()
          }
        }}>
        {component}
      </span>
    )
  }
  return (
    <div>
      <Button
        sx={{ fontSize: '0.75rem', animation: `${expandAndFade} 2s linear infinite` }}
        id="registros-estoque-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="success"
      >
        Registros
      </Button>
      <StyledMenu
        id="registros-estoque-menu"
        MenuListProps={{
          'aria-labelledby': 'registros-estoque-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {getMenuItem(<RegisterBuyDialog type={'sales'} color1={'#2e7d32'}/>)}
        <Divider sx={{ my: 0.5 }} />
        {getMenuItem(<RegisterBuyDialog type={'buy'} color1={'#2e7d32'}/>)}
        <Divider sx={{ my: 0.5 }} />
        {getMenuItem(<ProductRegistrar />)}
        <Divider sx={{ my: 0.5 }} />
        {getMenuItem(<RegisterPerson />)}
        <Divider sx={{ my: 0.5 }} />
        {getMenuItem(<RegisterNewStorageLocations />)}
        {/*  <MenuItem onClick={handleClose} disableripple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        */}
      </StyledMenu>
    </div>
  )
}
