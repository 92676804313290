import React, { useContext, useEffect, useState } from 'react'
import { Button, DialogActions, Container, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import { green } from '@mui/material/colors'
import dayjs from "dayjs"
import FormMainConfirm from '../financial/components/FormMain/FormMainConfirm'
import { Context } from '../../components/Context/AuthContext'

const getValue = (row, type) => {
  var valor = 0;
  row[type].map((item) => {
    valor += item.value_end;
  });
  return valor;
}
export default function TableBuyContent({ row, usestore, openExtern, handleCloseExtern, show_btn }) {
  const { isMobile } = useContext(Context)
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"))

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})

  const obj_fn = {
    usestore: usestore,
    ismobile: isMobile.any(),
  }

  const handleClickOpen = () => {
    setOpen(true);
    handleData();
  }
  
  const handleData = () => {
    let aux_person = null;
    if (row.id_person) {
      aux_person = usestore.reposPerson.getCompostPersonStore([
        ['id', row.id_person], ['type', 'Fornecedor']
      ])[0]
    }
    if (row.id_trans_pay) {
      row.arr_installment = usestore.reposBill.getBillStore('id_transaction', row.id_trans_pay);
    }
    setData({
      id: row.id,
      id_user: row.info_entry?.id_user || userCurrent.id,
      id_property: row.id_property,
      operationType: "Compra",
      operationName: "Compra",
  
      //step1
      //  Informações do lançamento
      date_release: dayjs(row.date_entry),
      date_release_f: dayjs(row.date_entry).format("YYYY-MM-DD HH:mm:ss"),
      date_release_fc: dayjs(row.date_entry).format("DD/MM/YYYY HH:mm"),
      date_delivery: dayjs(row.date_payment),
      date_delivery_f: dayjs(row.date_payment).format("YYYY-MM-DD HH:mm:ss"),
      date_delivery_fc: dayjs(row.date_payment).format("DD/MM/YYYY HH:mm"),
      supplier: row.id_person,
      client: null,
      person: aux_person,
      //  produtos adicionados
      products: row.products,
      product_selected: null,
      products_value: getValue(row, 'products'),
      packaging_unit: 'medida',
  
      //  lotes adicionados
      lotes: row.lotes,
      lote_selected: null,
      lotes_value: getValue(row, 'lotes'),
      //  animais adicionados
      animals: row.animals,
      animal_selected: null,
      animals_value: getValue(row, 'animals'),
  
      //  patrimonios adicionados
      // patrimonys: [],
      // patrimony_selected: null,
      // patrimonys_value: 0,
  
      //  serviços adicionados
      // services: [],
      // service_selected: null,
      // services_value: 0,
  
      //  Vincular com e descrição
      projects: [],
      projects_apport: row.info_entry?.aport || [],
      description: row.description,
  
      //step2
      //  Condições pagamento
      dueDate: dayjs(row.date_payment),
      dueDate_f: dayjs(row.date_payment).format("YYYY-MM-DD HH:mm:ss"),
      dueDate_fc: dayjs(row.date_payment).format("DD/MM/YYYY"),
  
      installment: row.info_entry?.installment || 'a_vista',
      installmentInterval: 30,
      formPayment: row.info_entry?.formPayment,
      bankAccount: row.info_entry?.id_bank,
      payed: false,
      //  Previsão de pagamento
      fee: row.info_entry?.fee || 0,
      penalty: row.info_entry?.penalty || 0,
      discount: row.info_entry?.discount || 0,
      value: row.value,
      value_end: row.value,
      //  Parcelas do pagamento
      arr_installment: row.arr_installment || [],
  
      error_date: false,
    })
  }
  const handleClose = () => {
    setOpen(false);
    if (handleCloseExtern) {
      handleCloseExtern();
    }
  }
  useEffect(() => {
    if (openExtern) {
      handleData();
    }
  }, [openExtern]);

  return (
    <div>
      {!show_btn ? <></> :
        <IconButton aria-label="Visualizar" onClick={() => handleClickOpen()} title="Visualizar">
          <Search sx={{ color: green[500] }} />
        </IconButton>
      }

      <Dialog fullWidth={true} maxWidth={'md'} open={open || openExtern} onClose={handleClose}>
        <DialogTitle sx={{ color: green[700] }}>
          Pedido de {"Compra #" + data?.id}
        </DialogTitle>

        <DialogContent>
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            {data?.id &&
              <FormMainConfirm data={data} obj_fn={obj_fn} visualize={true}/>
            }
          </Container>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
