import { useQuery } from "react-query";
import api from "../services/api";

export const apiGetUserByEmail = async (emailCurrent) => {
  const header = { header: { "Content-Type": "application/json" } };
  const email = { email: emailCurrent }
  const response = await api.post("/users/useremail", email, header);
  //console.log(response.data.isSuccess);
  if (response.data.isSuccess) {
    localStorage.setItem("userCurrent", JSON.stringify(response.data._value[0]));
    return response.data._value[0];
  }
};


// com esse hook, adicionamos cache em cima da função apiGetUserByEmail
export default function useGetUserByEmailRepos() {

  const onSuccess = () => {
    console.log('Sucesso: Executar o efeito após buscar os dados')
  }

  const onError = (error) => {
    console.log('Erro: Executar o efeito após encontrar o erro', error)
  }

  return useQuery(['useGetUserByEmailRepos'], apiGetUserByEmail,
    {
      onSuccess,
      onError
    })

}
