import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
} from '@mui/material';
import { grey, green, red } from '@mui/material/colors';
import { Close } from '@mui/icons-material';

export default function CashFlowTableDialog({ data, obj_fn }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [open, setOpen] = useState(false); // Estado para controlar o Dialog

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = data?.columns || [];
  const rows = data?.rows || [];

  const TableContent = ({ valueHeight }) => (
    <TableContainer sx={{ maxHeight: valueHeight, overflowX: 'auto' }}>
      {' '}
      {/* Habilita rolagem horizontal */}
      <Table stickyHeader id={'cashFlowCategory'}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={'TRC_' + column.id}
                align={column.align}
                sx={{
                  minWidth: column.minWidth || 150, // Definindo uma largura mínima
                  fontWeight: 'bold',
                  backgroundColor: grey[200],
                  position: 'sticky', // Mantém o cabeçalho fixo
                  top: 0, // Faz com que o cabeçalho fique no topo ao rolar
                  left: index === 0 || index === 1 ? 0 : 'auto', // adiiconado Fixa as duas primeiras colunas
                  zIndex: index === 0 || index === 1 ? 2 : 1, // adiiconado Define a prioridade de sobreposição
                  //zIndex: 1, // Garante que o cabeçalho fique sobre o conteúdo
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={'TR_' + row.id} hover>
              <TableCell
                colSpan={2}
                key={'TC_' + row.conta + '_cat_subcat'}
                align={'left'}
                sx={{
                  minWidth: 200,
                  textAlign: row.category == 'category' ? 'left' : 'right',
                  position: 'sticky', // adicionado:Fixa a coluna de "Categoria/Subcategoria"
                  left: 0, // adicionado:Posição fixa à esquerda
                  zIndex: 1,
                  backgroundColor: grey[50], // adicionado:Garante que o fundo seja visível
                }}
              >
                {row.conta}
              </TableCell>
              <TableCell
                key={'TC_' + row.conta + '_saldo'}
                align={'right'}
                sx={{
                  minWidth: 110,
                  position: 'sticky', // adicionado:Fixa a coluna de "Saldo do Mês"
                  left: 300, // adicionado:Ajuste para a posição correta (após a coluna de categoria)
                  zIndex: 1,
                  '&:not(:hover)': {
                    backgroundColor: row.tipo == 'Receita' ? green[50] : red[50],
                  },
                }}
              >
                {obj_fn.formatValue(row.saldo * (row.tipo == 'Receita' ? 1 : -1))}
              </TableCell>
              {row.saldo_dia.map((saldo, index) => (
                <TableCell
                  key={'TC_' + row.conta + '_dia_' + index}
                  align={'right'}
                  sx={{
                    minWidth: 100,
                    '&:not(:hover)': {
                      backgroundColor: saldo == 0 ? '#FFF' : row.tipo == 'Receita' ? green[50] : red[50],
                    },
                  }}
                >
                  {obj_fn.formatValue(saldo * (row.tipo == 'Receita' ? 1 : -1))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ flex: '1 1 100%', mb: 1 }} variant="h6" id="cashFlowCategoryTitle" component="div">
            Fluxo de Caixa por Categoria
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* Botão para abrir o Dialog */}
          <Button color="success" variant="contained" onClick={handleClickOpen}>
            Visualizar em Tela Cheia
          </Button>
        </Grid>
      </Grid>

      <TableContent valueHeight={500} />

      <TablePagination
        labelRowsPerPage={'Linhas por página'}
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog para exibir em tela cheia */}
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar color="inherit" sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Fluxo de Caixa - Tela Cheia
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <TableContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
