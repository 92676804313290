import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit, LocationOn } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green, grey } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CardPropertyProducts from '../../property/components/CardPropertyProducts';
import { Span } from '../../../components/Typography';
import MenuPlan from './MenuPlan';
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

export default function AccessHarvestProduction({ listPropertyRepos }) {
    const navigate = useNavigate();
    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>
            <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Produção por área</Typography>

            </Box>
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/plan_production_harvest.png"
                        title="atividades"
                    />
                </Card>
            </Box>
            <Card >
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 1: Meta de produção por área</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar no planejamento a meta de produção de cada área.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Para visualizar a produção, primeiramente é necessário ter registrado a meta de produção por área na tela planejamento.</Typography>

                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 2: Meta de Produção x Produção Realizada</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar a produção realizada por área.
                                    </Typography>
                                </Stack>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Com o registro da realização da produção agrícola por aárea em um software, é possível obter um controle detalhado das atividades, monitorar o desempenho produtivo e tomar decisões embasadas em dados precisos para otimizar a eficiência e maximizar os resultados na agricultura. </Typography>

                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 3: Meta de Produtividade x Produtividade Realizada</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar a produtividade realizada por área.
                                    </Typography>
                                </Stack>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Com o registro da realização da produção agrícola por área em um software, nos permitir a avaliação do desempenho e o monitoramento dos resultados obtidos. Ao estabelecer metas claras, é possível identificar oportunidades de melhoria, comparar o desempenho real com as expectativas e tomar ações corretivas para maximizar a eficiência, a rentabilidade e o sucesso da produção agrícola.</Typography>

                            </Stack>

                        </Card>
                    </Grid>
                </Grid>

            </Card >

        </>
    );
}

//https://aegro.com.br/termos-de-uso/