import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, InputAdornment, TextField, Button, Stack, MenuItem, } from "@mui/material";
import { DeleteForever, ExpandMore, Home, Input, MailOutline, Pin, Place, Public } from "@mui/icons-material";

const address_default = {
  name: "Endereço ",
  country: "Brasil",
  cep: "",
  address: "",
  number: "",
  state: "",
  city: "",
  neighborhood: "",
  complement: "",
};

const typeCountry = [
  {
    value: "Brasil",
    label: "Brasil",
  },
  {
    value: "Outro",
    label: "Outro",
  }
];

const AddNewAddress = ({ addresses, setData }) => {
  const isError = () => {
    if (!addresses[addresses.length -1].cep && !addresses[addresses.length -1].address) {
      return true;
    }
    return false;
  }

  /*
  // alguma api ou consulta no banco de dados para retornar as informações de cep
  const findCep = (value, adress) => {
    let response = buscaCep(value);
    adress.address = response.address;
    adress.number = response.number;
    adress.state = response.state;
    adress.city = response.city;
    return adress;
  }
  */

  const getDefaultAddres = () => {
    let address_default_aux = JSON.parse(JSON.stringify(address_default));
    address_default_aux.name = address_default.name + (addresses.length + 1);
    return address_default_aux;
  };

  const handleChange = (index, field, value) => {
    setData((prevState) => {
      const updatedAdresses = [...prevState.addresses];
      updatedAdresses[index][field] = value;
      //if (field == 'cep') {
      //  findCep(value, updatedAdresses);
      //}
      return { ...prevState, addresses: updatedAdresses };
    });
  };

  const handleAddAddress = () => {
    setData((prevState) => ({ ...prevState, addresses: [...prevState.addresses, getDefaultAddres()] }));
  };

  const handleDeleteAddress = (index) => {
    setData((prevState) => ({
      ...prevState,
      addresses: prevState.addresses.filter((_, i) => i !== index)
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {addresses.map((address, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`} >
              <Box sx={{ ml: 1, display: "flex" }}>
                <Stack spacing={0.5}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32", fontWeight:700 } }}
                    id="name"
                    name="name"
                    fullWidth
                    autoComplete="name"
                    variant="standard"
                    color="success"
                    value={address.name}
                    onChange={(e) => handleChange(index, "name", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Input />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={2}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="country"
                    name="country"
                    label="País"
                    select
                    fullWidth
                    value={address.country}
                    onChange={(e) => handleChange(index, "country", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Public />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    color="success"
                    //helperText={""}
                  >
                    {typeCountry.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="cep"
                    name="cep"
                    label="Cep"
                    fullWidth
                    autoComplete="cep"
                    variant="standard"
                    color="success"
                    value={address.cep}
                    onChange={(e) => handleChange(index, "cep", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <MailOutline />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={4.5}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="address"
                    name="address"
                    label="Endereço"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    value={address.address}
                    onChange={(e) => handleChange(index, "address", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Home />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="number"
                    name="number"
                    label="Número"
                    fullWidth
                    autoComplete="number"
                    variant="standard"
                    color="success"
                    value={address.number}
                    onChange={(e) => handleChange(index, "number", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Pin />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton variant="contained" color="error" onClick={() => handleDeleteAddress(index)} >
                    <DeleteForever />
                  </IconButton>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="state"
                    name="state"
                    label="Estado"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    value={address.state}
                    onChange={(e) => handleChange(index, "state", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Place />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="city"
                    name="city"
                    label="Cidade"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    value={address.city}
                    onChange={(e) => handleChange(index, "city", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Place />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="neighborhood"
                    name="neighborhood"
                    label="Bairro"
                    fullWidth
                    autoComplete="neighborhood"
                    variant="standard"
                    color="success"
                    value={address.neighborhood}
                    onChange={(e) => handleChange(index, "neighborhood", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Place />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="complement"
                    name="complement"
                    label="Complemento"
                    fullWidth
                    autoComplete="complement"
                    variant="standard"
                    color="success"
                    value={address.complement}
                    onChange={(e) => handleChange(index, "complement", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Place />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //helperText={""}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Button variant="contained" color="success" sx={{ ml: 2, mt: 1, fontSize: '12px' }} onClick={isError() ? () => null : handleAddAddress} disabled={isError()}>
          Adicionar Outro Endereço
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddNewAddress;