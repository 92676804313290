import React from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import SignatureContent from "./SignatureContent";
import Footer from "../../components/Footer/Footer";

export default function Signature() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton tipo={'user'} />
      <SignatureContent />
      <Footer />
    </>
  );
}
