import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { Dialog, Container, DialogContent, IconButton, Stack, Slide } from "@mui/material";
import { AppBar, Box, Button, Divider, Grid, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { Close, Edit, Vaccines } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { createTheme } from "@mui/material/styles";
import api from "../../../../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

import { Context } from "../../../../../components/Context/AuthContext";
import HeaderModal from "../../../../../components/Header/HeaderModal";
import FormPlantingStep1 from "../../FormActivities/Medicines/FormMedicinesStep1";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme(
  { palette: { primary: { main: "#1976d2" }, }, },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };

// fillDialog normal que chama uma lista de botões de atividade
export default function EditActivitiesMedicines({ row_data, usestore }) {
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);

  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(0);

  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const property_id = localStorage.getItem("Property") || 1;

  const handleActivity = () => {
    let this_activity = JSON.parse(JSON.stringify(usestore.reposActivityMedicines.getActivityMedicinesStore('id', row_data.id)[0]))
    this_activity.edit_prod = false
    var animal

    this_activity.lotes.map((lote) => {
      let aux_lote_prod = JSON.parse(JSON.stringify(lote))

      lote.product = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', aux_lote_prod.product.id)[0]))
      lote.product.location_selected = lote.product.location.filter((local) => {
        if (local.id_patrimony  === aux_lote_prod.product.location_selected.id_patrimony) {
          local.quanty_edit = 0
          return local
        }
      })[0];
      lote.product.location_selected.quanty_edit = 0

      for (var a = 0, tama = lote.list_animals.length; a < tama; a++) {
        animal = lote.list_animals[a]
        if (!lote.table_selected[animal.id]) {
          continue
        }
        lote.product.location_selected.quanty_edit += animal.medicine || 0
      }

      lote.product.medicine = aux_lote_prod.product.medicine
      lote.product.medicine_total = aux_lote_prod.product.medicine_total
    });

    return this_activity;
  };

  let this_activity = handleActivity();
  const [data, setData] = useState(this_activity);

  const handleClickOpen = () => {
    setUpdated(0);
    setOpen(true);
  };

  const handleClose = () => {
    //reseta informações
    let this_activity = handleActivity();
    setData(this_activity);
    setUpdated(0);
    setOpen(false);
  };

  // const para validar o button continuar
  const isError = () => {
    return !updated || !dayjs(data.date_start) || !dayjs(data.date_prev_end);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formOk = !isError();

    if (formOk) {
      const header = { header: { "Content-Type": "application/json" } };
      data.id_user_current = userCurrent.id;
      data.change_data = true;

      const res = await api.put("/activityMedicines/update", data, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success("Manejo Sanitário: Medicamentos editado com sucesso!");
            // atualiza o repos
            usestore.reposLivestock.fetch({ 'id_farm': property_id });
            usestore.reposProducts.fetch({ 'id_farm': property_id });
            usestore.reposPatrimony.fetch({ 'id_farm': property_id });

            usestore.reposActivityMedicines.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao editar atividade. Tente novamente mais tarde!");
        });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios");
    }
  };

  const options_header = {
    title: 'Editando: Manejo Sanitário de Medicamentos',
    txt_msn: 'Altere os dados desejados, confira e clique em confirmar',
    icon: <Vaccines color="success" sx={{ mr: 1, width: '32px', height: '32px' }} />,
    img: <img style={{ maxHeight: 120 }} src={'/dist/image/medicines.png'} alt="image" className="img-fluid" />
  };

  const obj_fn = {
    usestore: usestore,
    setData: setData,
    setUpdated: setUpdated,
    no_footer: true,
    isMobile: isMobile.any()
  };

  const [value, setValue] = useState(dayjs(data?.date_start));
  const [valueEnd, setValueEnd] = useState(dayjs(data?.date_prev_end));

  return ( <>
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div"> Edição da atividade</Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar" title="Fechar">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ mb: 8, mt: -4 }}>
          <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
            <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
              <Box>
                <HeaderModal options={options_header}/>

                <Grid container spacing={2} sx={{ mt: -4, mb: 3, height: 110 }}>
                  <Grid item xs={12} md={12} sx={{ mt: 2, height: 110 }}>
                    <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                      <div className="d-lg-none">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(1, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!value.$D}
                                    helperText={(<>
                                      <p id="date_start_helper" className={"form-text " + (!value.$D ? 'text-danger' : '') + " is-invalid"}>
                                        {!value.$D ? 'Por favor, insira uma data válida.' : 'Data em que a atividade iniciou'}
                                      </p>
                                    </>)}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!valueEnd.$D}
                                    helperText={(<>
                                      <p id="date_prev_end_helper" className={"form-text " + (!value.$D ? 'text-danger' : '') + " is-invalid"}>
                                        {!valueEnd.$D ? 'Por favor, insira uma data válida.' : 'Data prevista para finalizar a atividade'}
                                      </p>
                                    </>)}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      <div className="d-none d-lg-block">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(1, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!value.$D}
                                    helperText={(<>
                                      <p id="date_start_helper" className={"form-text " + (!value.$D ? 'text-danger' : '') + " is-invalid"}>
                                        {!value.$D ? 'Por favor, insira uma data válida.' : 'Data em que a atividade iniciou'}
                                      </p>
                                    </>)}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                color="success"
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!valueEnd.$D}
                                    helperText={(<>
                                      <p id="date_prev_end_helper" className={"form-text " + (!value.$D ? 'text-danger' : '') + " is-invalid"}>
                                        {!valueEnd.$D ? 'Por favor, insira uma data válida.' : 'Data prevista para finalizar a atividade'}
                                      </p>
                                    </>)}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      {/* <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: "#808080" }}>
                        Data prevista para início da atividade.
                      </Typography> */}
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, mb: 1 }}/>

                <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
                  <FormPlantingStep1 data={data} obj_fn={obj_fn}/>
                </Grid>

                <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
                  <Toolbar>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                        cancelar
                      </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Button color="success" variant="contained" type="submit" onClick={!updated ? () => null : onSubmit} disabled={isError()}>
                        Salvar
                      </Button>
                    </Box>
                  </Toolbar>
                </AppBar>
              </Box>
            </Paper>
          </Container>
        </DialogContent>
      </Dialog>
      <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
        <Edit sx={{ color: green[500] }} />
      </IconButton>
    </div>
  </>);
}
