import React, { useCallback, useEffect, useRef, useState } from "react";
/* global google */
//import { DrawingManager, Marker, Polygon } from "@react-google-maps/api";
import { DrawingManager, Polygon } from "@react-google-maps/api";
//import Loading from "../../../components/Loader/Loading";

// function calculatePolygonArea(paths) {
//   const area = google.maps.geometry.spherical.computeArea(paths);
//   console.log('Área do polígono:', area);
// }

const DrawingCmp = ({ drawingManagerRef, setIsButtonDisabled, setCircle, setArea, center, map, isLoading, setIsLoading, onPolygonComplete, path, setPath, drawingModeEnabled, isEdit, noDrawControl }) => {
  // console.log('DrawingCmp');
  const [state, setState] = useState(0);

  // definindo referências para instanciar o poligono e listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  //chama setPath para editar novos paths
  //função onEdit usando o useCallback para garantir que a função seja memorizada e atualizada somente quando as dependências mudarem. 
  const onEdit = useCallback(() => {
    // console.log('onEdit');
    //console.log(polygonRef.current)
    // se não tiver cria
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng()
        }));

      setPath(nextPath);
      // console.log("nextPath------");
      const areaGoogle = window.google.maps.geometry.spherical.computeArea(nextPath);
      //const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      setArea(areaGoogle);

      //(polygon) => calculatePolygonArea(polygon.getPath())
      // Criar um objeto Polygon
      //var polygon = new google.maps.Polygon({ paths: path });
      //console.log(polygon)

      // Calcular a área do polígono
      // var area = google.maps.geometry.spherical.computeArea(polygon.getPath());

      // Exibir a área no console
      //console.log("Área do polígono:", area);

    }
  }, [polygonRef, setPath]);

  // const onLoadPoly = (polygon) => {
  // if(polygon.editable === false){
  //console.log(polygonRef);
  const onLoadPoly = useCallback(
    (polygon) => {
      // console.log('onLoadPoly')
      if (isLoading) {
        // console.log("isLoading");
        //console.log(isLoading);
        return false;
      }
      //console.log("polygon: ", polygon);
      polygonRef.current = polygon;

      const path = polygon.getPath();
      const areaGoogle = window.google.maps.geometry.spherical.computeArea(path);
      //const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      setArea(areaGoogle);
      listenersRef.current.push(path.addListener("set_at", onEdit), path.addListener("insert_at", onEdit), path.addListener("remove_at", onEdit));
      //setIsLoading(false)
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    // console.log('onUnmount')
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  // useEffect(() => {
  //   console.log("atualizou o path");
  //   // setIsLoading(true)
  // }, [path]);

  useEffect(() => {
    // console.log('RenderGeral->useEffect')
    setState(state + 1)
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(true);
  //   }, 2000);
  //   console.log("carregou");
  // }, []);

  const optionsDraw = {
    // drawingControl: (noDrawControl ? false : true),
    drawingControl: false,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled
    },
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  return (
    <>
      {isLoading && path.length === 0 ? (
        <DrawingManager
          //ref={drawingManagerRef}
          // draw
          // onLoad={onLoad}
          drawingMode={drawingModeEnabled}
          //drawingMode={state.drawingMode}
          options={optionsDraw}
          onPolygonComplete={onPolygonComplete}
          onCircleComplete={(circle) => {
            setCircle(circle);
            setIsButtonDisabled(true);
          }}
        //editable
        //draggable
        //onMouseUp={onEdit}
        //onDragend={onEdit}
        />
      ) : (
        <>
          {path.length !== 0 &&
            <Polygon
              //a propriedade paths do objeto Polygon especifica uma matriz
              key={path.length}
              options={{
                fillColor: "green",
                fillOpacity: 0.7,
                strokeColor: "#53f444",
                strokeWeight: 2,
                //clickable: true,
                //draggable: false,
                //editable: true,
                // geodesic: true,
                zIndex: 1
              }}
              //editable do polígono especifica se os usuários podem editar a forma
              editable
              paths={path}
              onLoad={onLoadPoly}
              onUnmount={onUnmount}
              // Evento usado ao manipular e adicionar pontos
              onMouseUp={onEdit}
              // Evento usado ao arrastar todo o polígono
              onDragend={onEdit}
            //key={1}
            //visible={path.length > 0}
            // propriedade draggable para permitir que os usuários arrastem a forma.
            //draggable
            />
          }
          {/* <Marker position={center} /> */}
        </>
      )}
    </>
  );
};

export default React.memo(DrawingCmp)
