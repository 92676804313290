import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Guide from './Guide';
import { Card } from '@mui/material';
import AccessHarvest from './AccessHarvest';
import AccessLivestockHarvest from './AccessLivestockHarvest';
import AccessHarvestPlan from './AccessHarvestPlan';
import AccessHarvestProduction from './AccessHarvestProduction';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GuideTabsProjectHarvest() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>

            {/* <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Barra de navegação: nível criação</Typography>

            </Box> */}
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/bar_harvest.png"
                        title="green iguana"
                    />
                </Card>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Fluxo de trabalho" {...a11yProps(0)} />
                    <Tab label="Planejamento" {...a11yProps(1)} />
                    <Tab label="Atividades" {...a11yProps(2)} />
                    <Tab label="Produção" {...a11yProps(3)} />
                    {/*
                    <Tab label="Painel de controle" {...a11yProps(2)} />
                    <Tab label="Áreas" {...a11yProps(3)} />
                    <Tab label="Animais" {...a11yProps(4)} />
                    <Tab label="Planejamento" {...a11yProps(5)} />
                    <Tab label="Atividade" {...a11yProps(6)} />
                    <Tab label="Recurso" {...a11yProps(7)} />
                    <Tab label="Produção" {...a11yProps(8)} />
                    <Tab label="Custo de Produção" {...a11yProps(9)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AccessHarvest />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AccessHarvestPlan />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AccessLivestockHarvest />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AccessHarvestProduction />
            </TabPanel>
            {/*
            <TabPanel value={value} index={3}>
            </TabPanel>
            <TabPanel value={value} index={4}>
            </TabPanel>
            <TabPanel value={value} index={5}>
            </TabPanel>
            <TabPanel value={value} index={6}>
            </TabPanel>
            <TabPanel value={value} index={7}>
            </TabPanel>
            <TabPanel value={value} index={8}>
            </TabPanel> */}


        </Box>
    );
}
