import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { AppBar, CircularProgress, Grid, Toolbar, Typography } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import ConfirmListAreasMapsLivestockImagens from '../ConfirmListAreasMapsLivestockImagens';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import { LocationOn } from '@mui/icons-material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import CheckIcon from '@mui/icons-material/Check';

export default function Confirm({ data, onSubmit, isSubmitting, handleNext, handleBack, handleClose }) {
  const { firstName, lastName, email, gender, date, city, phone } = [];
  //console.log('Confirm');
  //console.log(isSubmitting);

  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  // const handleSubmit = () => {
  //   handleNext();
  // };

  return (
    <>
      <Grid container spacing={2} sx={{ height: 65 }}>
        {/* <Grid item xs={1} md={1}>
          <CheckIcon sx={{ color: 'action.active', mr: 0.5 }} />
        </Grid> */}
        <Grid item xs={11} md={11}>
          <Typography gutterBottom style={{ color: '#2e7d32' }}>
            <Typewriter text="Pronto!! Confirme as informações e clique em SALVAR." />
          </Typography>
        </Grid>
      </Grid>
      <List disablePadding sx={{ mt: -1 }}>
        <ListItem>
          <ListItemText primary="Nome do projeto" secondary={data.nameLivestock} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Data de Início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
          <ListItemText primary="Data de Término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
        </ListItem>
        <Divider />
        <ListItemText primary="Área(s) da criação" />
        <ConfirmListAreasMapsLivestockImagens data={data} />
      </List>

      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>CANCELAR</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} variant="text" color="inherit" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            <Button sx={{ my: 2, width: '100px', animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" color="inherit" onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} /> : <>SALVAR</>}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <div className="d-none d-lg-block">
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </div>
          </Box>

          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button sx={{ mr: 1 }} color="success" onClick={handleBack}>
              Retornar
            </Button>
            <Button variant="contained" color="success" onClick={onSubmit}>
              Confirmar e continuar
            </Button>
          </Box>
        </Toolbar>
      </AppBar> */}
    </>
  );
}
