import axios from 'axios';

// não utilizados
const api = axios.create({
  //baseURL: "http://localhost:5000/api/v1/",
  //baseURL: process.env.REACT_APP_PORT ? "http://localhost:5001/api/v1/" : "https://apifp.cptsoftwares.com.br/api/v1/"
  // computador homeoffice-leo
  //baseURL: 'http://192.168.1.27:5001/api/v1/',
  baseURL: process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : process.env.REACT_APP_PORT ? 'http://localhost:5001/api/v1/' : 'https://apifp.cptsoftwares.com.br/api/v1/',

  //baseURL: "http://apifazendapremium.cptsoftwares.com.br/api/v1/",
  //baseURL: process.env.REACT_APP_API_URL
});
api.defaults.headers.common['token'] = localStorage.getItem('token');

/*
tratamento de resposta do axios
api.interceptors.response.use(
  function (response) {
    if (response.data) {
      // return success
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      // reject errors & warnings
      return Promise.reject(response);
    }
    // default fallback
    return Promise.reject(response);
  },
  function (error) {
    // if the server throws an error (404, 500 etc.)
    //console.log(error);
    if (error.response && error.response.data) {
      return error.response;
    }
    return Promise.reject(error);
  }
);
*/
export default api;
