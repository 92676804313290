import { useState } from "react";
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import ChatPlan from "../ChatPlan";
import { Span } from "../../../../../components/Typography";
import IAIcon from "../../../../../components/Icons/chat/IAIcon";
import ActivitySelection from "../ActivitySelection";
import FormPlanFooter from "../FormPlanFooter";

export default function FirstStepFormCostSelectProduction({ data, setData, formError, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const [open, setOpen] = useState(false);

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (!data.startDate || data.startDate.trim() === "") {
    //   return true;
    // }
    // if (!data.expectedYield || data.expectedYield.trim() === "") {
    //   return true;
    // }
    // if (!data.area || data.area.trim() === "") {
    //   return true;
    // }
    return false;
  };

  const returnError = () => {
    return false;
  };
  const color = "#2e7d32";

  console.log("activeStep");
  console.log(activeStep);
  return (
    <>
      <Container maxWidth="sm">
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          <Grid container sx={{ mt: 1, mb: 6 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }}>
              <Typography style={{ color: "#2e7d32" }}>
                <Typewriter tam={0} minHeight={0} text="É hora de estimar o custo de produção. Embora seja uma tarefa difícil, estarei aqui para lhe ajudar." />
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2, mb: 1 }}>
            <Grid item xs={12} md={12} alignItems="center">
              <Typography variant="subtitile">Selecione as principais atividades e oprações que serão utilizadas na safra:</Typography>
            </Grid>
          </Grid>
          <Box>
            <ActivitySelection data={data} setData={setData} />
            {data && (
              <FormPlanFooter
                data={data}
                setData={setData}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps}
                isError={isError}
                handleClose={handleClose}
                returnError={returnError}
              />
            )}
          </Box>
        </Paper>
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
    </>
  );
}
