import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import MultiStepFormPlan from "../components/FormPlan/MultiStepFormPlan";
import FormGoalTarget from "./FormGoalTarget";
import FormGoalPlanHarvest from "./FormGoalPlanHarvest";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormGoalButtonPlanHarvest({ areaActive, target_active, planTargetReposFilterIdAreaMap }) {
  const [open, setOpen] = React.useState(false);

  console.log("areaActive, target_active, planTargetReposFilterIdAreaMap")
  console.log(areaActive, target_active, planTargetReposFilterIdAreaMap)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="success" startIcon={<i className="fa fa-trophy text-success mr-1" />} onClick={handleClickOpen}>
        Estime a lucratividade da safra
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Planejamento Financeiro Essencial
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <FormGoalPlanHarvest handleClose={handleClose} areaActive={areaActive} target_active={target_active} />
        </Box>
      </Dialog>
    </div>
  );
}
