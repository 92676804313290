import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Divider, Button, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { Card, CardContent, CardMedia, Grid, ListItemIcon, Typography } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';

export default function Confirm({ data, onSubmit, isSubmitting, handleNext, handleBack, handleClose }) {
  const [areas, setAreas] = useState(data.areas);
  // console.log('Confirm');
  // console.log(areas);

  return (
    <>
      <Grid container spacing={2} sx={{ height: 65 }}>
        <Grid item xs={11} md={11}>
          <Typography gutterBottom style={{ color: '#2e7d32' }}>
            <Typewriter text="Pronto! Confirme as informações e clique em SALVAR." />
          </Typography>
        </Grid>
      </Grid>
      <List disablePadding sx={{ mt: -1 }}>
        <ListItem>
          <ListItemText primary="Nome do projeto" secondary={data.name} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Data de Início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
          <ListItemText primary="Data de Término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
        </ListItem>
        <Divider />
        <ListItemText primary="Área(s) da cultura" />
        <div className="row">
          <div className="col">
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              {areas?.map((area) => (
                <Grid key={area.id} item container alignItems="center" justifyContent="center">
                  <Card sx={{ display: 'flex', width: '310px' }}>
                    <ListItemIcon>
                      <CardMedia component="img" sx={{ width: 100 }} image={area?.url || area?.imageDecode} alt={area.name} />
                      <CardContent sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Área: {area.name}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            <i className="fa fa-map text-success" /> {area.usedArea || area.area} ({area.measure})
                          </Typography>
                        </Box>
                      </CardContent>
                    </ListItemIcon>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </List>
      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>Cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} variant="text" color="inherit" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            <Button sx={{ my: 2, width: '100px', animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" color="inherit" onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} /> : <>SALVAR</>}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
