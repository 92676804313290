import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from "@mui/icons-material/Directions";
import { Avatar, Box, ButtonBase, ListItemAvatar, Paper, Typography, Stack } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        height: '100%',
    },
}));


export default function CreateDrawAreaList() {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/areasmaps");
    };

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box className="d-lg-none">
                <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                        <span aria-label="comment">
                            <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                        </span>
                    }
                >
                    <ListItemText primary={<strong>Passo 1:</strong>} secondary={`Clique no botão verde "ÁREA", no canto superior da tela.`} />

                </ListItem>

            </Box>
            <Box className="d-none d-lg-block">
                <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                        <span aria-label="comment">
                            <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                        </span>
                    }
                >
                    <ListItemText primary={<strong>Passo 1:</strong>} secondary={`Clique no botão verde "ÁREA", no canto superior direito da tela.`} />

                </ListItem>

            </Box>
            <Box>
                <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                        <span aria-label="comment">
                            <CropOriginalIcon style={{ color: "#2e7d32" }} />
                        </span>
                    }
                >
                    <ListItemText primary={<strong>Passo 2:</strong>} secondary="Na tela de desenho da área, o ponteiro do mouse se transforma em uma cruz, e você pode clicar no mapa para definir os pontos de sua área." />

                </ListItem>
                <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt="Imagem" src="/dist/image/area_draw.png" style={{ maxWidth: '100%' }} />
                </ListItem>
            </Box>
            <Box>
                <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                        <span aria-label="comment">
                            <CropOriginalIcon style={{ color: "#2e7d32" }} />
                        </span>
                    }
                >
                    <ListItemText primary={<strong>Passo 3:</strong>} secondary="Após terminar o desenho, basta clicar no botão ver SALVAR, no canto superior direito da tela." />
                </ListItem>
                <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt="Imagem" src="/dist/image/map_area.png" style={{ maxWidth: '100%' }} />
                </ListItem>
            </Box>
            <ListItem
                key={null}
                disableGutters
                secondaryAction={
                    <span aria-label="comment">
                        <CropOriginalIcon style={{ color: "#2e7d32" }} />
                    </span>
                }
            >
                <ListItemText primary={<strong>Passo 4:</strong>} secondary="Ao salvar, abrirá uma tela para você inserir o nome da área e a dimensão. Depois clique em salvar. Pronto! A área está adicionada." />
            </ListItem>





        </List>
    );
}