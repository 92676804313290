import React, { useEffect, useState } from "react";
import { Box, Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { HowToReg, Notes } from "@mui/icons-material";
import ProgressBar from "./ProgressBar";
import Loading from "../Loader/Loading";
import IconsLivestockActivities from "../Icons/IconsLivestockActivities";
import IconsHarvestActivities from "../Icons/harvest/IconsHarvestActivities";
import { calcularDateProgressBarra } from "../Calculations/calcularDateProgressBarra";

export default function CardChatKanban({ post, type, isLoading, painel }) {
  const progress = calcularDateProgressBarra(post.startDate, post.endDate);

  let bgcolor = "#07bc0c";
  if (post.progress != "CONCLUÍDA" && progress.porcentagem >= 100) {
    bgcolor = "#ef6c00";
  }

  return ( <>
    {isLoading ? 
      ( <Loading /> ) : (
      <Card sx={{ mt: 1 }}>
        <CardHeader
          sx={{ m: -2, ml: -1 }}
          avatar={ painel ? <></> : <>
            {post.tipo_repos == 'livestock' ?
              <IconsLivestockActivities activity={post.activity} type={post.type} widthStyles={"42"} /> :
              <IconsHarvestActivities activity={post.activity} />
            }
          </> }
          action={<div aria-label="settings"></div>}
          title={
            <Typography variant="button" display="block" gutterBottom>
              <strong>{post.activity}</strong> 
            </Typography>
          }
          subheader={
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {post.nameBatch}
            </Typography>
          }
        />
        <CardContent sx={{ m: -2, ml: -1 }}>
          {post.tipo_repos == 'harvest' ? <></> :
            <Grid container direction="row" alignItems="flex-start" gap={1}>
              <Grid item sx={{ mr: 2.5 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <Notes sx={{ ml: 1 }} /> {post.numberAnimals} animais
                </Typography>
              </Grid>
            </Grid>
          }
          <Grid container direction="row" alignItems="flex-end" gap={1}>
            <Grid item sx={{ ml: "auto", mr: 2.5 }}>
              <Typography sx={{ fontSize: 14 }} color="text.success" gutterBottom>
                <HowToReg sx={{ fontSize: 22 }} color="success" /> {post.team}
              </Typography>
            </Grid>
          </Grid>

          {type == 'progress' ? ( <></> ) : (<>
            <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {post.startDate}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              </Box>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {post.endDate}
              </Typography>
            </CardActions>

            <ProgressBar key={1} bgcolor={bgcolor} completed={progress.porcentagem} days={progress.totalDias} />
          </> )}
        </CardContent>
      </Card>
    )}
  </> );
}
