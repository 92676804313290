import { Avatar, Box, Button, Card, CardMedia, Chip, CircularProgress, Divider, IconButton, Stack, Switch, Typography } from '@mui/material';
import React from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit, LocationOn } from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';
import { filesize } from 'filesize';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
// https://www.youtube.com/watch?v=G5UZmvkLWSQ&t=36s
// lembrar da biblioteca yarn add react-dropzone para arrastar as imagens
export default function DocFilePreview({ file, onDelete, fileUploadHandler }) {
  //handleUpload
  console.log('FilePreview');
  console.log(file);
  console.log(file.progress);

  return (
    <div>
      <Card>
        <Box sx={{ p: 2, display: 'flex' }}>
          <Avatar sx={{ bgcolor: green[500] }}>
            <AssignmentIcon />
          </Avatar>
          <Stack spacing={0.5}>
            <Typography sx={{ ml: 2 }} variant="subtitle1" color="text.secondary" component="div">
              {file.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <LocationOn sx={{ color: grey[500], ml: 1 }} /> {filesize(file.size)}
            </Typography>
          </Stack>

          {/* <IconButton>
            <Edit sx={{ fontSize: 14 }} />
          </IconButton> */}
        </Box>
        <Divider />
        {/* se o file não foi feito o upload */}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
          {/* {!file.uploaded && (
            <Button variant="contained" onClick={fileUploadHandler()} component="label">
              Enviar
            </Button>
          )} */}
          {!!file.url && (
            <IconButton aria-label="delete" onClick={() => onDelete(file.id)}>
              <DeleteIcon />
              <Typography variant="subtitle2" color="text.secondary" component="div">
                Excluir
              </Typography>
            </IconButton>
          )}
          {!file.uploaded && !file.error && <CircularProgress variant="determinate" value={file.progress} />}
          {file.uploaded && (
            <>
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 30 }} />

              <img width="50" src="/dist/img_fp/aws01.png" alt="aws" loading="lazy" />
            </>
          )}
        </Stack>

        {file.error && console.log('erro no upload')}
      </Card>
    </div>
  );
}
