import React, { useState } from 'react';
import { ButtonBase, Grid, Typography, Box, Stack, Modal, Paper, Input } from '@mui/material';
import { Payments, PointOfSale } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '120px',
  },
}));

export default function FormEntryStep1({ data, obj_fn }) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState('');
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  //1-texto para acionar o botao radio
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
    // exibe os botões após começar a escrever
    setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, 400);
  }, 500);

  const handleOpenModal = (operation) => {
    setSelectedOperation(operation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalChoice = (hasStockMovement) => {
    const operationType = hasStockMovement ? (selectedOperation === 'Despesa' ? 'Compra' : 'Venda') : selectedOperation === 'Despesa' ? 'Gasto' : 'Entradas Financeiras';

    obj_fn.setData((prevState) => ({
      ...prevState,
      operationType,
      operationName: selectedOperation,
      category: operationType === 'Compra' || operationType === 'Venda' ? 'Estoque' : 'Financeiro',
    }));

    obj_fn.handleNext();
    handleCloseModal();
  };

  const renderButton = (title, name, icon) => (
    <Grid item xs={6} md={6} key={name}>
      <ButtonBase component={Paper} elevation={2} onClick={() => handleOpenModal(name)} className={classes.button}>
        <Stack direction="column" alignItems="center">
          <Box height={50}>{React.cloneElement(icon, { style: { fontSize: 50, color: name === 'Despesa' ? 'red' : 'green' } })}</Box>
          <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
            {title}
          </Typography>
        </Stack>
      </ButtonBase>
    </Grid>
  );

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lançamento: {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 2 }}>
          <Typography style={{ color: green[800] }}>
            {!showText ? <ThreeDotsSpinner /> : <Typewriter text="Por favor, defina o tipo de lançamento desejado." variant={'subtitle1'} color={green[800]} />}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {renderButton('Gastos ou Compra (Despesas)', 'Despesa', <Payments />)}
        {renderButton('Entradas Financeiras ou Venda (Receita)', 'Receita', <PointOfSale />)}
      </Grid>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, textAlign: 'center', mx: 'auto', mt: '20%' }}>
          <Typography variant="h6" gutterBottom>
            {`Operação sem ou com movimentação de estoque ou animais?`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 2 }}>
                <ButtonBase onClick={() => handleModalChoice(false)} component={Paper} elevation={2} className={classes.button} sx={{ p: 2, borderRadius: 1, flexDirection: 'column' }}>
                  <Typography>Sem Movimentação</Typography>
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    {selectedOperation === 'Despesa' ? (
                      <>
                        <strong style={{ color: 'red' }}>(Gasto)</strong>
                      </>
                    ) : (
                      <>
                        <strong style={{ color: 'green' }}>(Entradas Financeiras)</strong>
                      </>
                    )}
                  </Typography>
                  {selectedOperation === 'Despesa' ? (
                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                      Apenas registra no financeiro, sem impactar o estoque ou animais.
                    </Typography>
                  ) : (
                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                      Apenas registra no financeiro, sem impactar o estoque ou animais.
                    </Typography>
                  )}
                </ButtonBase>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 2 }}>
                <ButtonBase onClick={() => handleModalChoice(true)} component={Paper} elevation={2} className={classes.button} sx={{ p: 2, borderRadius: 1, flexDirection: 'column' }}>
                  <Typography>Com Movimentação</Typography>
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    {selectedOperation === 'Despesa' ? (
                      <>
                        <strong style={{ color: 'red' }}>(Compra)</strong>
                      </>
                    ) : (
                      <>
                        <strong style={{ color: 'green' }}>(Venda)</strong>
                      </>
                    )}
                  </Typography>
                  {selectedOperation === 'Despesa' ? (
                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                      Registra no financeiro e envolve a entrada no estoque ou entrada de animais.
                    </Typography>
                  ) : (
                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                      Registra no financeiro e envolve a saída no estoque ou saída de animais.
                    </Typography>
                  )}
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      {/* <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, textAlign: 'center', mx: 'auto', mt: '20%' }}>
          <Typography variant="h6" gutterBottom>
            {`Operação sem ou com movimentação de estoque ou animais?`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 2 }}>
                <ButtonBase onClick={() => handleModalChoice(false)} sx={{ p: 2, bgcolor: 'lightcoral', borderRadius: 1, flexDirection: 'column' }}>
                  <Typography>Sem Movimentação</Typography>
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    Apenas registra no financeiro, sem impactar o estoque ou animais.
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 2 }}>
                <ButtonBase onClick={() => handleModalChoice(true)} sx={{ p: 2, bgcolor: 'lightgreen', borderRadius: 1, flexDirection: 'column' }}>
                  <Typography>Com Movimentação</Typography>
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    Registra no financeiro e envolve entrada ou saída no estoque de animais.
                  </Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
}
