import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import IconCheckboxes from "../../Messenger/components/IconCheckboxes";
import FullScreenDialogEdit from "../../Messenger/DialogMessenger/FullScreenDialogEdit";
import MenuCard from "../../Messenger/components/MenuCard";
import { format } from "date-fns";
import moment from "moment";
import Badge from "@mui/material/Badge";
import Loading from "../../../components/Loader/Loading";
import { PollTwoTone } from "@mui/icons-material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import NotesIcon from "@mui/icons-material/Notes";
import MenuCardTimeLine from "./MenuCardTimeLine";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StartIcon from "@mui/icons-material/Start";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgressMobileStepper from "./ProgressMobileStepper";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  //marginLeft: 'auto',
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardTimeLine({ setIsLoading }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [farm, setFarm] = useState("");
  const [isLoadingFarm, setIsLoadingFarm] = useState(true);

  console.log("post-CardChat ");
  //console.log(post);
  const [isLoadingDate, setIsLoadingDate] = useState(false);

  // const [date, setDate] = useState(post?.date)

  // function formatDate() {
  //   var formatDate = moment(post.date).format('DD/MM/YYYY');
  //   console.log(formatDate)
  //   setDate(formatDate)
  // }

  useEffect(() => {
    //formatDate()
    setIsLoadingDate(true);
  }, [isLoadingDate]);

  //['Resolver'->0, 'Resolvido'->1, 'Não Resolvido'->2, 'Parado'->3];

  const status = { 0: "Fazer", 1: "Em Progresso", 2: "Revisar", 3: "Concluída" };
  const statuscolor = { 0: "primary", 1: "default", 2: "default", 3: "success" };

  return (
    <>
      <Card>
        {/* <div style={{ position: "relative" }}>

          <CardMedia component="img" height="125" image="/dist/image/colheitamilho.jpg" alt="Paella dish" />

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              bgcolor: "rgba(0, 0, 0, 0.54)",
              color: "white",
              padding: "63px",
            }}
          >
            <Typography variant="h5">Lizard</Typography>
            <Typography variant="body2">Subtitle</Typography>
          </Box>
         
          <div style={{ position: "absolute", color: "white", bottom: 10, left: "5%" }}>
            <Typography variant="caption" style={{ opacity: 0.7 }}>

            </Typography>

            <Typography variant="h6">Solicitação</Typography>
            <Typography variant="body2">Fazenda</Typography>
            <Typography variant="body2">Piquete 1 (4,05 ha)</Typography>
          </div>
        </div> */}
        {/* <Card sx={{ maxWidth: 345 }}></Card> */}
        <CardHeader
          sx={{ ml: -1 }}
          // avatar={
          //   <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
          //     L
          //   </Avatar>
          // }
          action={
            <>
              <IconButton aria-label="settings">
                {/* <MenuCard id={post.id} setIsLoading={setIsLoading} /> */}
                <MenuCardTimeLine id={1} />
              </IconButton>
            </>
          }
          title={
            <>
              <StartIcon sx={{ color: green[500], height: 24, width: 24, mt: 0.1 }} />
              <Typography sx={{ ml: 0.5 }} variant="button">
                <strong>Colheita e entrega</strong>
              </Typography>
            </>
          }
          subheader={
            <>
              <Typography sx={{ fontSize: 12, ml: 3.5 }} color="text.secondary">
                Fazenda CPT
              </Typography>
              <Typography sx={{ fontSize: 12, ml: 3.5 }} color="text.secondary">
                Talhão 01: Safra PLANTAÇÃO DE MILHO 22/23
              </Typography>
            </>
          }
        />
        <CardContent sx={{ m: -2, ml: -1 }}>
          <Grid container direction="row" alignItems="flex-start" gap={1}>
            <Grid item sx={{ mr: 2.5 }}>
              <Typography sx={{ fontSize: 14, ml: 2.5 }} color="text.secondary" gutterBottom>
                <NotesIcon sx={{ ml: 1 }} /> Descrição
              </Typography>
            </Grid>
          </Grid>
          {true && (
            <>
              {/* <Grid container direction="row" alignItems="flex-end" gap={1}>

               <Grid item sx={{ mr: 2.5 }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 280 }}
                    className="img-rounded elevation-1"
                    image="/dist/image/colheitamilho.jpg"
                    alt="Live from space album cover"
                  />
                </Grid> 
                <Grid item sx={{ mr: 2.5 }}>
                  <Typography sx={{ fontSize: 14 }} color="text.success" gutterBottom>
                    <HowToRegIcon sx={{ fontSize: 22 }} color="success" /> Leonardo
                  </Typography>
                </Grid>

                <Grid item sx={{ ml: "auto", mr: 2.5 }}>
                  <Typography sx={{ fontSize: 14 }} color="text.success" gutterBottom>
                    <HowToRegIcon sx={{ fontSize: 22 }} color="success" /> Gustavo
                  </Typography>
                </Grid>
              </Grid> */}
            </>
          )}
          {/* <ProgressMobileStepper /> */}
        </CardContent>

        <CardActions disableSpacing>
          <Avatar sx={{ bgcolor: green[500], height: 24, width: 24 }} aria-label="recipe">
            L
          </Avatar>

          <Badge sx={{ ml: "auto", mr: 6 }} badgeContent={status[0]} color={statuscolor[0]} />
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {/* {isLoadingDate && date} */}
            12 de janeiro de 2023
          </Typography>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <Typography paragraph>Atividade</Typography> */}
            <HorizontalNonLinearStepper />
            {/* <div className="mt-4" style={{ position: "relative" }}>

              <CardMedia component="img" height="125" image="/dist/image/colheitamilho.jpg" alt="Paella dish" />

              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  bgcolor: "rgba(0, 0, 0, 0.54)",
                  color: "white",
                  padding: "63px",
                }}
              >
              
              </Box>

              <div style={{ position: "absolute", color: "white", bottom: 10, left: "5%" }}>
                <Typography variant="caption" style={{ opacity: 0.7 }}>

                </Typography>

                <Typography variant="h6">Solicitação</Typography>
                <Typography variant="body2">Fazenda</Typography>
                <Typography variant="body2">Piquete 1 (4,05 ha)</Typography>
              </div>
            </div> */}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
