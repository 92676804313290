import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import hexSha1 from "hex-sha1";
import ProfileSelect from "../ConsultUserProfile/ProfileSelect";

const FromRegisterUserProfile = ({ setValue }) => {
  //const { token } = useContext(AuthContext);
  // modulo não utilizado: caso for utilizar, mudar o repos para:
  const repos = 'usestore.reposUser';

  const [add_user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    profile: ""
  });

  const onInputChange = e => {
    setUser({ ...add_user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("entrou no submit");
    console.log(add_user)
    if (!add_user.name) {
      document.getElementById("register_name").focus();
      return toast.error(`O campo nome deve ser informado!`);
    } else if (!add_user.email) {
      document.getElementById("register_email").focus();
      return toast.error(`O campo email deve ser informado!`);
    } else if (!add_user.password || add_user.password.length < 6 || !/\d/.test(add_user.password)) {
      document.getElementById("register_password").focus();
      return toast.error(`O campo senha deve conter no mínimo 5 caracteres e 1 número!`);
    }

    const header = { header: { "Content-Type": "application/json" } };
    add_user.password = hexSha1(add_user.password);
    // const res = await api.post("/users/createUser", add_user, header);
    const res = ""
    res.data.isSuccess = true
    console.log(res);

    if (res.data.isSuccess) {
      repos.refetch();
      setValue(0);
    } else {
      // escreve mensagem de erro para cliente
      toast.error(res.data);
    }
  };

  return (
    <div className="content" >
      <div className="content-fluid" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="card " style={{ width: 320 }}>
          {/* <div className="card-header">
            <h3 className="card-title">Cadastrando usuários</h3>
          </div> */}
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="form-group">
                <label for="register_name">Nome</label>
                <input id="register_name" type="text" className="form-control " placeholder="Digite um nome" name="name" value={add_user.nome} onChange={e => onInputChange(e)} />
              </div>
              <div className="form-group">
                <label for="register_email">Email</label>
                <input id="register_email" type="email" className="form-control " placeholder="Digite um email" name="email" value={add_user.email} onChange={e => onInputChange(e)} />
              </div>
              <div className="form-group">
                <label for="register_password">Senha</label>
                <input id="register_password" type="password" className="form-control " placeholder="Defina uma senha" name="password" value={add_user.password} onChange={e => onInputChange(e)} />
              </div>
              <div className="form-group">
                {/* <label for="register_password">Perfil</label> */}
                {/* <input id="register_perfil" type="perfil" className="form-control " placeholder="Defina um perfil" name="perfil" value={add_user.profile} onChange={e => onInputChange(e)} /> */}
                <ProfileSelect />
              </div>
              <button className="btn btn-success btn-block">Adicionar usuário</button>
            </form>
          </div>
          {/* {status.type === 'error' ?<Alert key="danger" variant="danger">{status.mensagem}</Alert> : ""}
        {status.type === 'success' ? <Alert key="success" variant="success">{status.mensagem}</Alert> : ""} */}
        </div>
      </div>
    </div>
  );
};

export default FromRegisterUserProfile;
