import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Checkmark } from 'react-checkmark';
import { AppBar, Box, Button, ButtonBase, CardMedia, Container, Grid, Paper, Stack, Toolbar } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import RedirectMap from './RedirectMap';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import { PendingActions } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Context } from '../../../../../components/Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useStore from '../../../../../store/useStore';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function SuccesGreen({ handleClose }) {
  const classes = useStyles();
  const [isLoadingText, setIsLoadingText] = useState(false);

  const navigate = useNavigate();
  const usestore = useStore();
  // pegar o livestock current
  const reposLivestockList = usestore.reposLivestock.listLivestock;
  // console.log(reposLivestockList);

  reposLivestockList.sort((a, b) => b.id - a.id);
  const idFarm = reposLivestockList[0]?.id_property;

  //const { setMenuContext } = React.useContext(Context);

  const handleButtonClick = (destino) => {
    handleClose();
    var criation = {
      label: reposLivestockList[0]?.name,
      id: reposLivestockList[0]?.id,
      id_property: reposLivestockList[0]?.id_property,
      tipo_repos: 'Criações',
      tipe: reposLivestockList[0]?.id_tipe,
      activity: reposLivestockList[0]?.tipo,
    };
    localStorage.setItem('creationHarvestRepos', JSON.stringify(criation));
    usestore.reposLivestock.changeLivestockRepos(criation);

    let button = '';
    if (destino === 'mapa') {
      button = 'Mapa';
    } else if (destino === 'production') {
      button = 'Produção';
    } else if (destino === 'activities') {
      button = 'Atividades';
    }
    localStorage.setItem('ButtonActive', button);

    //setMenuContext(new Date().getTime());

    navigate(`/activities/${idFarm}/${criation.id}`);
  };
  useEffect(() => {
    setIsLoadingText(false);
  }, []);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 2000);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Pronto! Projeto Pecuário criado com sucesso.
            </Typography>
            <Typography style={{ color: '#2e7d32' }} variant="h6">
              Bem Vindo a Gestão Pecuária!
            </Typography>

            {/* <Grid item sm={12} xs={12}>
              <CardMedia component="img" width="200" image="/dist/image/gestaopecuaria2.png" alt="Gestão Pecuária" />
            </Grid> */}

            {/* <Typography variant="subtitle1" sx={{ mt: 4 }} >
              Agora com um projeto criado, podemos começar o planejamento ou  gerenciamento da sua criação.
            </Typography> */}

            <>
              <div className="d-lg-none">
                <Box sx={{ flexGrow: 1, height: 80 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && (
                      <Typewriter
                        tam={110}
                        //text="Com o projeto pecuário criado, é hora de iniciar com um planejamento da meta de produção ou seguir direto para gerenciamento das atividades de campo."
                        text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo."
                      />
                    )}
                  </Typography>
                </Box>
              </div>
              <div className="d-none d-lg-block">
                <Box sx={{ flexGrow: 1, mb: 2, height: 60 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && <Typewriter tam={90} text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo." />}
                  </Typography>
                </Box>
              </div>
            </>
            {idFarm && (
              <>
                <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
                  Clique no botão abaixo.
                </Typography>
                <ButtonBase
                  component={Paper}
                  elevation={2}
                  sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }}
                  onClick={() => handleButtonClick('Mapa')}
                  className={classes.button}
                >
                  <Stack direction="column" alignItems="center">
                    <PendingActions />
                    {/* <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" class="img-fluid" /> */}
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      Atividades de Campo
                    </Typography>
                  </Stack>
                </ButtonBase>
              </>
            )}
          </div>
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>

            <Button color="success" variant="contained" type="submit" >
              <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
            </Button>

          </Box> */}
        </Toolbar>
      </AppBar>
    </>
  );
}
