
import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box, Card, CardActions, CardContent, CardHeader, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import Skeleton from '@mui/material/Skeleton';

import HowToRegIcon from '@mui/icons-material/HowToReg';
import NotesIcon from '@mui/icons-material/Notes';
import Loading from "../../../../components/Loader/Loading";
import moment from "moment";
import useStore from "../../../../store/useStore";

export default function CardChatModel({ title }) {
  const [isLoading, setIsLoading] = useState(true)
  const [date, setDate] = useState("")
  const status = { 0: "Resolver", 1: "Resolvido", 2: "Parado" }
  const statuscolor = { 0: "primary", 1: "success", 2: "default" }

  function formatDate() {
    var formatDate = moment(new Date()).format('DD/MM/YYYY');
    console.log(formatDate)
    setDate(formatDate)
  }

  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;

  useEffect(() => {
    formatDate()
    console.log(date)
    setIsLoading(false)
  }, [date])

  return (
    <>
      {isLoading ? <Loading /> :
        <Card sx={{ mt: 0 }}>

          <CardHeader
            sx={{ m: -2, ml: -1 }}
            avatar={
              <>
                <Skeleton variant="circular" width={40} height={40} />
              </>

            }
            action={
              <IconButton aria-label="settings">
                {/* <MenuCard id={post.id} setIsLoading={setIsLoading} /> */}
              </IconButton>
            }
            title={
              <Typography variant="button">
                <strong>{title}</strong>
              </Typography>
            }
            subheader={
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {propertyRepos.label}
              </Typography>
            }
          />
          <CardContent sx={{ m: -2, ml: -1 }}>
            <Grid container direction="row" alignItems="flex-start" gap={1}>
              <Grid item sx={{ mr: 2.5 }}>

                <Typography sx={{ fontSize: 14, }} color="text.secondary" gutterBottom>
                  <NotesIcon sx={{ ml: 1 }} />
                </Typography>

              </Grid>
              <Grid item sx={{ mr: 2.5 }}>
                <Box sx={{ width: 300 }}>
                  <Skeleton animation="wave" />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <IconButton aria-label="add to favorites">
              {/* <FullScreenDialogEdit /> */}
            </IconButton>

            {true && status &&
              <Badge sx={{ ml: "auto", mr: 5 }} badgeContent={"Resolver"} color={statuscolor[0]} />
            }

            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {date}
            </Typography>

          </CardActions>
        </Card>
      }
    </>
  );
}
