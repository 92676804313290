import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import WidgetsIcon from '@mui/icons-material/Widgets'
import api from '../../../../services/api'
import { toast } from 'react-toastify'
import useStore from '../../../../store/useStore'
import { Context } from '../../../../components/Context/AuthContext'
import GenericValueField from '../../../../components/Fields/GenericValueField'

const option = [{ label: 'Entrada' }, { label: 'Saída' }, { label: 'Balanço' }]
const options = ['Entrada', 'Saída', 'Balanço']

export default function StockCost({ open, handleClose, dataStock, setDataStock }) {
  // console.log('StockCost')
  // console.log(dataStock)

  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const property_id = localStorage.getItem('Property') || 1

  // cadastro rapido location:
  const [dialogLocationOpen, setDialogLocationOpen] = useState(false)

  const toggleDialogLocation = () => {
    setDialogLocationOpen(!dialogLocationOpen)
  }

  // const [selectedValueLocation, setSelectedValueLocation] = useState(null)
  // //const [showAddNewLocation, setShowAddNewLocation] = useState(true)

  // console.log('setSelectedValueLocation')
  // console.log(selectedValueLocation)

  // const handleChangePatrimony = (event, value) => {
  //   console.log('handleChangePatrimony')
  //   console.log(value)
  //   if (value) {
  //     const setSelectedValueLocation = value
  //     console.log(setSelectedValueLocation)
  //     // obj_fn.setData((prevState) => ({
  //     //   ...prevState,
  //     //   location: setSelectedValueLocation.id,
  //     // }))
  //   }
  // }

  const handleChange = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    setDataStock((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    console.log('handleSubmit -StockEntry')
    //let formOk = validate() || false

    // console.log(dataStock)
    const updateValueCost = {
      id_property: dataStock.id_property,
      id_product: dataStock.id_product,
      value_cost: dataStock.value_cost,
    }
    // console.log(updateValueCost)

    if (true) {
      const header = { header: { 'Content-Type': 'application/json' } }
      const res = await api
        .put('products/valueCost', updateValueCost, header)
        .then((response) => {
          // console.log('response.dataxxx')
          // console.log(response.data)
          // console.log(response.data.isSuccess)
          if (response.data.isSuccess) {
            toast.success('Controle de quantidade de estoque atualizado')

            //resetForm()
            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao atualizar o estoque, tente novamente mais tarde!')
        })
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!')
    }
  }

  // console.log('dataStock')
  // console.log(dataStock)

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle>Defina o valor médio do seus produtos</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContentText>
              {/* Aqui você pode adicionar as informações do pedido */}
              {/* Exemplo: Pedido Número: {selectedRow?.id} */}
            </DialogContentText>
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* NOME */}
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <WidgetsIcon sx={{ fontSize: 20, mr: 1 }} color="text.secondary" />
                    <Stack spacing={0.5}>{dataStock?.name && <Typography fontWeight={700}>{dataStock?.name.toUpperCase()}</Typography>}</Stack>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid item xs={4} md={4}>
                    {/* <CurrencyTextField
                      value={dataStock.value_cost}
                      label="Valor unitário"
                      name="value_cost"
                      onChange={handleChange}
                      // error={lotNameError}
                      //helperText={validate() ? `` : 'Campo obrigatório'}
                      // helperText={`Defina o valor do produto ${data.name}`}
                    /> */}
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"value_cost",
                        name:"value_cost",
                        label: 'Valor unitário',
                        value: dataStock.value_cost,
                        endAdornment: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
      {/* Adicione botões de ação se necessário */}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={handleSubmit} variant="contained" color="success">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
