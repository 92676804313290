import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CardActions, Stack } from '@mui/material';
import { AccessAlarms, CurrencyExchange } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, type) {
  return {
    id: `abas-custos-patrimônio-${index}`,
    'aria-controls': `Aba: ${type}`,
  };
}

export default function CardPatrimonyTabs({ setTab, valuetotalMaintenace, valuetotalMaintenaceMonth }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} onChange={handleChange} aria-label="Abas custos patrimônio">
          <Tab label="Custo no mês atual" {...a11yProps(0, "Custo no mês atual")} />
          <Tab label="Custos totais" {...a11yProps(1, "Custos totais")} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CardActions disableSpacing>
          {/* {patrimonyData.group === 'Maquinário' && ( */}
          {false && (
            <Stack spacing={0.5}>
              <Typography variant="body2" color="text.secondary">
                <AccessAlarms style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Trabalhados(15 horas)
              </Typography>
              <Typography fontWeight={700} variant="h6">
                ? ha
              </Typography>
            </Stack>
          )}

          <Stack spacing={0.5} sx={{ ml: 'auto' }}>
            <Typography variant="body2" color="text.secondary">
              <CurrencyExchange style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Custos totais
            </Typography>
            <Typography fontWeight={700} variant="h6">
              {valuetotalMaintenaceMonth.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </Typography>
          </Stack>
        </CardActions>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CardActions disableSpacing>
          {/* {patrimonyData.group === 'Maquinário' && ( */}
          {false && (
            <Stack spacing={0.5}>
              <Typography variant="body2" color="text.secondary">
                <AccessAlarms style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Trabalhados(15 horas)
              </Typography>
              <Typography fontWeight={700} variant="h6">
                ? ha
              </Typography>
            </Stack>
          )}
          <Stack spacing={0.5} sx={{ ml: 'auto' }}>
            <Typography variant="body2" color="text.secondary">
              <CurrencyExchange style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Custos totais
            </Typography>
            <Typography fontWeight={700} variant="h6">
              {valuetotalMaintenace.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </Typography>
          </Stack>
        </CardActions>
      </TabPanel>
    </Box>
  );
}
