import React, { useState } from 'react';
import WidgetComponent from './WidgetComponent';

function ParentComponent() {
    const [widgetValue, setWidgetValue] = useState(null);

    const handleValueChange = (value) => {
        setWidgetValue(value); // Atualizando o valor no estado do ParentComponent
    };

    return (
        <div>
            <WidgetComponent onValueChange={handleValueChange} />
            <p>Valor extraído: {widgetValue}</p> {/* Exibindo o valor no ParentComponent */}
        </div>
    );
}

export default ParentComponent;
