import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import { Handyman, PendingActions } from '@mui/icons-material';
import dayjs from 'dayjs';
import api from '../../../../services/api';
import useStore from '../../../../store/useStore';
import SuccessDialog from '../../../../components/Dialog/SuccessDialog';
import { Context } from '../../../../components/Context/AuthContext';
import HeaderModalNew from '../../../../components/Header/HeaderModalNew';
import FormMaintenancenStep1 from './FormMaintenancenStep1';
import FormMaintenancenStep2 from './FormMaintenancenStep2';
import FormMaintenancenStep3 from './FormMaintenancenStep3';
import FormMaintenancenStep4 from './FormMaintenancenStep4';
import FormMaintenancenStep5 from './FormMaintenancenStep5';

export default function MultiStepFormMaintenance({ handleClose, type }) {
  // console.log('MultiStepFormMaintenance');
  const [error, setError] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const bankRepos = usestore.reposBank.bankRepos;
  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: Number(property_id),
    // step 1
    patrimony: null,
    id_patrimony: null,
    name: '',
    type: '',
    group: '',
    date_start: dayjs(),
    date_start_f: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_start_fc: dayjs().format("DD/MM/YYYY"),
    date_prev_end: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_end: dayjs().format("YYYY-MM-DD HH:mm:ss"),

    // step 2
    products: [],
    services: [],
    value: 0,
    totalQuantity: 0,

    // step 3
    //  Vincular com e descrição
    projects: [],
    projects_apport: [],
    description: "",

    // step 4
    //  Condições pagamento
    installment: 'a_vista',
    installmentInterval: 30,
    formPayment: "dinheiro",
    bankAccount: bankRepos.id,
    payed: false,
    dueDate: dayjs(),
    dueDate_f: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    dueDate_fc: dayjs().format('DD/MM/YYYY'),
    //  Previsão de pagamento
    fee: 0,
    penalty: 0,
    discount: 0,
    value_end: 0,
    //  Parcelas do pagamento
    arr_installment: [],
    isDeleted: 0,
  });

  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState((new Date().getTime()));
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  const validate = () => {
    let valid = true;

    if (!data.name || error) {
      valid = false;
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormMaintenancenStep1', 'FormMaintenancenStep2', 'FormMaintenancenStep3', 'FormMaintenancenStep4', 'FormMaintenancenStep5'];
  const steps_text = [
    'Defina a data do registro da manutenção e selecione o patrimônio.',
    "Vamos agora informar as manutenções e os insumos (produtos) utilizados.\nInforme pelo menos uma manutenção ou produto para a manutenção",
    "É hora de apropriar o custo da manutenção e inserir alguma observação se desejar.",
    "Defina as condições e previsões do pagamento e suas parcelas.",
    "Pronto! Agora basta conferir as informações da manutenção, clicar em confirmar e salvar.",
  ];

  const onSubmit = async () => {
    // console.log('onSubmit');
    let formOk = validate()

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post('/patrimony/maintenance', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Registro da manutenção realizado com sucesso!');
            usestore.reposProducts.fetch({ id_farm: property_id });
            usestore.reposFinances.fetch({ id_property: property_id });
            usestore.reposFinances.fetchT({ id_property: property_id });
            usestore.reposBill.fetch();

            usestore.reposPatrimonyMaintenance.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar a Manutenção, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      setError: setError,
    };

    switch (step) {
      case 0:
        // date e select
        return <FormMaintenancenStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        //Manutenção
        return <FormMaintenancenStep2 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormMaintenancenStep3 data={data} obj_fn={obj_fn} />;
      case 3:
        //CONDIÇÕES PAGAMENTO + PREVISÃO DE PAGAMENTO + PARCELAS
        return <FormMaintenancenStep4 data={data} obj_fn={obj_fn} />;
      case 4:
        // confirmação dos dados
        return <FormMaintenancenStep5 data={data} obj_fn={obj_fn} />;
      case 5:
        const data_success = {
          distination: 'patrimony#maintenance',
          name: 'Manutenção Patrimonial:',
          text_success: 'Acesse sua lista de patrimônios e realize sua próxima manutenção.',
          distination_title: 'Manutenção',
          icon: <PendingActions color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  const optionsIcon = {
    title: 'Manutenção Patrimonial:',
    txt_msn: steps_text[activeStep],
    icon: <Handyman color="success" />,
  };

  const optionsImage = {
    title: 'Progresso',
    txt_msn: steps_text[activeStep],
    icon: <Handyman style={{ fontSize: 80 }} />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep !== steps.length && <HeaderModalNew optionsIcon={optionsIcon} optionsImage={optionsImage} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
