import * as React from "react";
import Button from "@mui/material/Button";
import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";

export default function FormAssignAreaFooter({ data, obj_fn, isError, returnError }) {

  return ( <>
    <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
            Cancelar
          </Button>
        </Box>
        {data &&
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button color="success" variant="contained" type="submit" onClick={!isError() ? obj_fn.handleNext : () => null} disabled={isError()}>
              {obj_fn.activeStep === obj_fn.steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
            </Button>
          </Box>
        }
      </Toolbar>
    </AppBar>
  </> );
}
