import { CardActions, Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import api from '../../../services/api';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { toast } from 'react-toastify';
import useAreasMapReposStore from '../../../store/useAreasMapReposStore';
import useStore from '../../../store/useStore';

const currencies = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

export default function FormSaveArea({ handleClose, path, image_area, setAddArea }) {
  const usestore = useStore();
  const fetchAreas = usestore.reposAreas;

  let encoded = base64_encode(JSON.stringify(path));
  const property_id = localStorage.getItem('Property') || 1;

  const [areaMap, setAreaMap] = useState({
    id_property: property_id,
    name: '',
    area: '',
    measure: 'ha',
    coordinates: encoded,
    zoom: 15,
    address: '',
    description: '',
    image: image_area,
  });

  const handleChange = (event, tipo) => {
    event.persist();
    var value = event.target.value;
    setAreaMap({ ...areaMap, [event.target.name]: tipo ? value.trim() : value });
  };

  const validadeForm = (data) => {
    var ok = false;
    var msg = '';
    var list_areas = fetchAreas.getAreaMapStore('name', data.name);
    if (!data.name || data.name.length < 5) {
      msg = 'Informe um nome válido para a área!';
    } else if (list_areas.length > 0) {
      msg = 'Já existe uma área cadastrada com o nome informado! Informe outro nome';
    } else if (!data.coordinates) {
      msg = 'Informe as coordenadas da área! Desenhe no mínimo 3 pontos!';
    } else if (!data.area) {
      msg = 'Informe a dimensão da área';
    } else {
      ok = true;
    }

    return [ok, msg];
  };

  const onSubmit = async () => {
    save();
  };
  async function save() {
    const formOk = validadeForm(areaMap);
    if (formOk[0]) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api.post('/areamap/saveMaparea', areaMap, header);

      if (res.statusText == 'OK') {
        toast.success('Área salva com sucesso!');
        usestore.reposProperty.fetchAreasProperty();

        await fetchAreas.fetch({ id_farm: property_id }).then((res) => {
          setTimeout(() => {
            setAddArea(fetchAreas.listAreasMap.length + 1);
            handleClose();
          }, 500);
        });
      }
    } else {
      toast.error(formOk[1]);
    }
  }

  return (
    <Box onSubmit={(e) => onSubmit(e)} sx={{ p: 2, mt: 2 }}>
      <Card>
        <CardMedia component="img" height="140" image={image_area} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                required
                id="name"
                name="name"
                label="Nome da área"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
                value={areaMap.name}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, 'name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                id="area"
                name="area"
                label="Dimensão"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
                value={areaMap.area}
                onChange={handleChange}
                helperText="Tamanho da área"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                id="measure"
                name="measure"
                label="Medida"
                select
                variant="standard"
                color="success"
                value={areaMap.measure}
                onChange={handleChange}
                helperText="Unidade de medida"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button sx={{ ml: 1, ml: 'auto' }} color="success" variant="contained" onClick={onSubmit}>
            <SaveAltIcon sx={{ mr: 1 }} />
            Salvar
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
