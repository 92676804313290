import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import LZString from 'lz-string';
import api from '../../../services/api';
import { Context } from '../../../components/Context/AuthContext';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { Span } from '../../../components/Typography';
import { Box, Card, CardActionArea, CardActions, CardMedia, Divider, Menu, MenuItem, Modal, Tooltip, Typography } from '@mui/material';
import { AppBar, Button, Dialog, DialogContent, DialogTitle, Paper, IconButton, Toolbar, Grid, Container } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export function CardArea({ areaMap, usestore, tab_active }) {
  const navigate = useNavigate();
  const { aux_add, setAuxAdd } = useContext(Context);
  const property_id = localStorage.getItem('Property') || 1;
  const display_demo = JSON.parse(localStorage.getItem('display'));
  //const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDel, setOpenDel] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDel = () => {
    setOpenDel(false);
  };

  //const imageData = LZString.decompressFromBase64(areaMap.image);
  const imageData = areaMap.url;

  const handleEdit = () => {
    navigate(`/areamap/${areaMap.id}`);
  };

  const handleDelete = async (tipo) => {
    console.log('deletando: ');
    //console.log(data);
    const header = { header: { 'Content-Type': 'application/json' } };
    const data = {
      areaMap: [{ id: areaMap.id }],
      isDeleted: areaMap.isDeleted,
      delete: false,
      status: false,
    };
    var msg_ok = '';
    var msg_err = '';

    if (tipo == 'excluir' && areaMap.isDeleted == '1') {
      data.delete = true;
      msg_ok = 'Área excluida com sucesso!';
      msg_err = 'Erro ao deletar a Área!';
    } else {
      // lixeira - reativar
      data.status = true;
      msg_ok = areaMap.isDeleted == '1' ? 'Área removida da lixeira com sucesso!' : 'Área movida para a lixeira com sucesso!';
      msg_err = areaMap.isDeleted == '1' ? 'Erro ao remover Área da lixeira!' : 'Erro ao mover Área para a lixeira!';
    }
    const res = await api
      .put('/areamap/delete', data, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error(msg_err);
        }
        toast.success(msg_ok);

        usestore.reposAreas.fetch({ id_farm: property_id }).then((res) => {
          setAuxAdd(aux_add + 1)
          setTimeout(() => {
            handleCloseDel();
            handleClose();
            usestore.reposProperty.fetch();
          }, 100)
        })
      })
      .catch((err) => {
        console.log(err);
        toast.error(msg_err);
      });
  };

  const [openD, setOpenD] = useState(false);

  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);

  return (
    <>
      <Card>
        <div style={{ position: 'relative' }}>
          <CardMedia onClick={handleOpenD} component="img" height="250" image={imageData} alt="Imagem" />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.45)',
              color: 'white',
              padding: '35px',
            }}
          ></Box>
          <div style={{ position: 'absolute', color: 'white', bottom: 10, left: '5%' }}>
            <Typography variant="h6">{areaMap.name}</Typography>
            <Typography variant="body2">
              {areaMap.area || '-'} {areaMap.measure || 'ha'}
            </Typography>
          </div>
          <div style={{ position: 'absolute', color: 'white', bottom: 10, right: '2%' }}></div>
        </div>
        <CardActionArea>
          <CardActions>
            <Box sx={{ ml: 'auto' }}>
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                  <MoreVertIcon sx={{ color: '#808080' }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {tab_active == 'ativa' ? (
                <>
                  <MenuItem onClick={() => handleEdit()}>
                    {' '}
                    <EditIcon sx={{ color: green[600], mr: '5px' }} /> Editar
                  </MenuItem>
                  {display_demo || areaMap.areas_used.length > 0 ? (
                    <div></div>
                  ) : (
                    <>
                      <Divider />
                      <MenuItem onClick={() => handleDelete('excluir')}>
                        {' '}
                        <DeleteForeverIcon sx={{ color: red[600], mr: '5px' }} /> Excluir
                      </MenuItem>
                    </>
                  )}
                </>
              ) : (
                <>
                  <MenuItem onClick={() => handleDelete('reativar')}>
                    {' '}
                    <LoopIcon sx={{ color: blue[600], mr: '5px' }} /> Reativar
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => setOpenDel(true)}>
                    {' '}
                    <DeleteForeverIcon sx={{ color: red[600], mr: '5px' }} /> Excluir
                  </MenuItem>
                </>
              )}
            </Menu>
            <Dialog open={openDel} onClose={handleCloseDel} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'sm'}>
              <DialogTitle>
                <Toolbar sx={{ height: 50 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} alignItems="center">
                      <Typography sx={{ flex: 1 }} variant="h6" component="div" color={red[800]}>
                        A Área será excluida definitivamente!
                      </Typography>
                      <Typography sx={{ flex: 1 }} variant="h6" component="div" color={red[800]}>
                        Deseja continuar?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography sx={{ flex: 1 }} variant="h6" component="div" color={red[800]}></Typography>
                  <IconButton edge="start" color="inherit" onClick={handleCloseDel} aria-label="close">
                    {' '}
                    <CloseIcon />{' '}
                  </IconButton>
                </Toolbar>
              </DialogTitle>
              <Divider />
              <DialogContent sx={{ mb: 6, mt: -3, textAlign: 'center' }}>
                <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
                  <Grid container spacing={1} sx={{ mb: 1.1 }}>
                    <Grid item xs={12} sm={12} key={areaMap.id}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography fontWeight="500" variant="h6">
                            {areaMap.name}
                          </Typography>
                          <Typography variant="body2" component="div">
                            {areaMap.area} ({areaMap.measure})
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </DialogContent>
              <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Button color="info" variant="outlined" type="submit" onClick={handleCloseDel}>
                      <Span>fechar</Span>
                    </Button>
                  </Box>
                  <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Button variant="outlined" color="error" onClick={() => handleDelete('excluir')}>
                      <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div">
                        {' '}
                        deletar{' '}
                      </Typography>
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </Dialog>
          </CardActions>
        </CardActionArea>
      </Card>
      <Modal
        open={openD}
        onClose={handleCloseD}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            position: 'relative',
            //  width: '60%',
            //  height: '60%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={imageData} alt="Imagem ampliada" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      </Modal>
    </>
  );
}
