import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextAddProjectStepper from "./TextAddProjectStepper";
import FlagIcon from "@mui/icons-material/Flag";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import TutorialMapaAccordion from "./TutorialMapaAccordion";


export default function TutorialMaps({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = useState(openModal);
  console.log("open", open);
  console.log("openModal", openModal);

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
  };

  useEffect(() => {
    if (openModal === false) {
      console.log("useEffect false");
      setOpen(false);
    } else if (openModal === true) {
      console.log("useEffect true");
      setOpen(true);
    }
    //setOpen(true)
  }, [openModal]);

  return (
    <div>
      {/* <Button variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
                <HelpOutlineIcon />
            </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {
            <>
              <AccountTreeIcon /> Registro no Mapa{" "}
            </>
          }
        </DialogTitle>
        <DialogContent>
          {/* <TextAddProjectStepper /> */}
          <Card>
            <Box sx={{ width: "100%" }}>
              <Paper square elevation={0} sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ mt: 1 }} direction="row" alignItems="center" justifyContent="center">
                  <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/areamapa.png" width="130" name="Avicultura"></img>
                  </Grid>
                  <Grid container xs={12} md={12} sx={{ mt: 3 }} item alignItems="center" justifyContent="center">
                    <Typography style={{ fontSize: 16 }}>
                      {" "}
                      Olá, produtor rural! Seja bem-vindo ao Mapa da sua fazenda, o espaço ideal para o controle e monitoramento eficiente em tempo real da sua propriedade. Com o mapa, você poderá
                      visualizar a localização do rebanho, o número de animais por área e receber informações precisas sobre as atividades realizadas na propriedade, para tomar decisões mais assertivas
                      e aumentar a produtividade da sua fazenda.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Card>
          <TutorialMapaAccordion />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
