import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { blue, green } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MenuPlan from './MenuPlan';
import { Span } from '../../../components/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuPlanInfor from './MenuPlanInfor';

export default function CardPropertyProducts() {
  console.log('CardPropertyProducts');
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const user = userCurrent;
  const name = user.name;
  const charName = name[0].toUpperCase();

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  return (
    <Card sx={{ m: 0, border: `1px solid ${blue[500]}`, overflow: 'auto' }}>
      {/* <Box sx={{ p: 2, display: 'flex' }}>
                            <Stack spacing={0.5}>
                                <Typography fontWeight={700}>Índice de experiência</Typography>
                            </Stack>
                        </Box> */}
      {/* <CardHeader
                sx={{}}

                avatar={
                    <Avatar variant="rounded" sx={{ bgcolor: blue[500], ml: 4 }} aria-label="recipe">
                        E
                    </Avatar>
                }
                action={
                    <>
                        <Grid sx={{ mb: -4, mr: 2 }}>
                            <MenuPlan />
                        </Grid>
                    </>
                }
                title="Plano Essencial"
                subheader={`Reponsável: ${user.name}`}
            /> */}
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
        <Avatar variant="rounded" sx={{ bgcolor: blue[500], marginLeft: 2 }} aria-label="recipe">
          LC
        </Avatar>
        <Stack spacing={0.5} sx={{ marginLeft: 1 }}>
          <Typography fontWeight={700}>Plano Lucro Certo</Typography>
        </Stack>
        <Typography sx={{ marginLeft: 6, flex: 1 }} variant="h6" component="div"></Typography>
        <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
          <Button style={{ borderColor: blue[500] }} variant="contained" type="submit">
            <HomeWorkIcon />
            <Span sx={{ paddingLeft: 1, textTransform: 'capitalize' }}>Assinar</Span>
          </Button>
        </Box>
        <Box sx={{ textAlign: 'right', marginLeft: 4 }}>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              <AccessTimeIcon /> Tempo restante
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              Responsável: {user.name}
            </Typography>
          </Box>
        </Box>
        <IconButton>
          <MenuPlanInfor />
        </IconButton>
      </Box>
      <Divider />
      <Divider />
    </Card>
  );
}
