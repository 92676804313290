import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Grid, ImageListItem, LinearProgress, ListSubheader, Stack } from '@mui/material';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { Context } from '../../components/Context/AuthContext';
import { useContext } from 'react';
import useDocFilesReposStore from '../../store/useDocFilesReposStore';
import FolderIcon from '@mui/icons-material/Folder';
import TableListDocFile from './TableListDocFile';
import { useEffect } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import useStore from '../../store/useStore';
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color="success" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DynamicTabsDocFile() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [storage, setStorage] = useState(0);

  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  const [data, setData] = useState([]);
  const useDocFilesRepos = useDocFilesReposStore((state) => state);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const property_id = localStorage.getItem('Property') || 1;

  console.log('useDocFilesRepos1');
  console.log(useDocFilesRepos.listDocFiles);

  const folders = [...new Set(useDocFilesRepos.listDocFiles.map((item) => item.folder))];
  console.log(folders);

  const groupedData = folders.reduce((acc, folder) => {
    acc[folder] = useDocFilesRepos.listDocFiles.filter((item) => item.folder === folder);
    return acc;
  }, {});

  function getMonthFromDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long' });
  }

  const translateFolderName = (folderName) => {
    switch (folderName) {
      case 'documentManagement':
        return 'Documentos';
      // Adicione outros casos conforme necessário

      default:
        return folderName;
    }
  };

  const translatedFolders = folders.map(translateFolderName);

  console.log(groupedData);

  const groupImagesByMonth = (data) => {
    return data.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  };

  // Função para calcular o total de tamanho em MB
  const calcularTotalSizeMB = (data) => {
    let totalSizeBytes = 0;
    data.forEach((item) => {
      totalSizeBytes += parseInt(item.size);
    });
    const totalSizeMB = totalSizeBytes / (1024 * 1024); // Convertendo bytes para MB
    return totalSizeMB.toFixed(2); // Arredondando para duas casas decimais
  };

  const handleGetList = async () => {
    const itemData = useDocFilesRepos.listDocFiles.filter((img) => img.isDeleted === 0 && img.id_property == property_id);
    console.log(itemData);
    const totalSizeMB = calcularTotalSizeMB(itemData);
    console.log('Total de tamanho em MB:', totalSizeMB);
    setStorage(totalSizeMB);
    console.log(itemData);
    const sortedImages = itemData.sort((a, b) => new Date(b.date) - new Date(a.date));
    const groupedData = groupImagesByMonth(sortedImages);
    setData(groupedData);
    setTimeout(() => {
      setIsLoadingImage(false);
    }, 2500);
  };

  const calcularProgressoArmazenamento = (storageEmMB) => {
    console.log(storageEmMB);
    const totalArmazenamentoGB = 5;
    const totalArmazenamentoMB = totalArmazenamentoGB * 1024; // Convertendo GB para MB
    const progresso = (storageEmMB / totalArmazenamentoMB) * 100;
    console.log(progresso);
    console.log(Math.min(progresso, 100));
    return Math.min(progresso, 100); // Limitando o progresso máximo a 100%
  };

  useEffect(() => {
    console.log('ImageListCmp - handleGetList()');
    handleGetList();
  }, []);

  const handleDelete = async (e, id, img) => {
    console.log(`Deleting item with id: ${id}`);
    console.log(`Deleting item with img: ${img}`);

    const key = img.split('https://cpt-soft.s3.us-east-1.amazonaws.com/')[1];
    console.log(`Extracted key: ${key}`);

    console.log(key);

    try {
      const res = await api.post(`/uploadFile/delete`, {
        id: id,
        isDeleted: 1,
        key: key,
      });

      console.log('passou');
      useDocFilesRepos.delImageStore(id);
      toast.success('Arquivo deletado definitivamente!');
      usestore.reposDocFiles.fetch({ id_farm: property_id }).then((res) => {
        setAuxAdd(aux_add + 1);
      });
    } catch (error) {
      toast.error('Erro ao deletar o arquivo definitivamente!');
    }

    // console.log('deletou');
    // const res = await api
    //   .delete(`/uploadFile/${id}/1/${key}`)
    //   .then((response) => {
    //     console.log('passou');
    //     useDocFilesRepos.delImageStore(id);
    //     toast.success('Arquivo deletado definitivamente!');
    //     usestore.reposDocFiles.fetch({ id_farm: property_id }).then((res) => {
    //       setAuxAdd(aux_add + 1);
    //     });
    //     // setTimeout(() => {
    //     //   setUpdate(new Date().getTime());
    //     // }, 20);
    //   })
    //   .catch(() => {
    //     toast.error('Erro ao deletar o arquivo definitivamente!');
    //   });
  };

  console.log(folders);
  console.log(folders);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ mt: -2 }}>
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <FilterDramaIcon /> <strong>Armazenamento</strong>
            </Typography>
            <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
              {storage && (
                <Box sx={{ width: '100%' }}>
                  {' '}
                  <LinearProgressWithLabel value={calcularProgressoArmazenamento(storage)} />
                </Box>
              )}
              {storage} MB de 5000 MB (5GB)
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          textColor="success"
          TabIndicatorProps={{ style: { background: 'green' } }}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '.Mui-selected': {
              color: '#2e7d32 !important',
              fontWeight: 'bold',
            },
          }}
        >
          {translatedFolders.map((folder, index) => (
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FolderIcon style={{ marginRight: 8 }} /> {/* Ícone do Folder */}
                  {folder} {/* Texto do rótulo da guia */}
                </div>
              }
              key={folder}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      {folders.map((folder, index) => (
        <TabPanel value={value} index={index} key={folder}>
          <TableListDocFile data={groupedData[folder]} handleDelete={handleDelete} />
        </TabPanel>
      ))}
    </Box>
  );
}
