import "./ChangeMapyTypeMobile.css"
import mapToogle from '../../../../assets/png/Google_Maps_icon_.png'
import { Button } from "@mui/material"

const ChangeMapyTypeMobile = ({ handleMapToggle }) => {

    //const { handleMapToggle } = props

    const handleMapToggle1 = () => {
        console.log('teste2')
    }


    return (
        <div className="changeMapTypeContainerM">
            <Button color="success" onClick={handleMapToggle}>
                <img className="containerToggleM" src={mapToogle} alt="Google maps" >
                </img>
            </Button>
        </div>
    )
}

export default ChangeMapyTypeMobile;