import dayjs from 'dayjs'
import IconsLivestockActivities from '../Icons/IconsLivestockActivities'

function createData(id, numberAnimals, activity, type_activity, progress, type, team, startDate, endDate, nameBatch, areaName, ha, isDeleted, planning, startDate_nf, endDate_nf, tipo_repos, icon) {
  return {
    id,
    numberAnimals,
    activity,
    type_activity,
    progress,
    type,
    team,
    startDate,
    endDate,
    nameBatch,
    areaName,
    ha,
    isDeleted,
    planning,
    startDate_nf,
    endDate_nf,
    tipo_repos,
    icon,
  }
}

// Retorna a lista de lotes da produção
function getLotesData(lotes, tipo) {
  let text = ''
  let animals = 0
  lotes.map((lote, index) => {
    text += (index > 0 ? '\n' : '') + lote.label
    animals += lote.num_selecteds
  })
  return tipo == 'lote' ? text : animals
}

// formatar a data dentro da função handleRows
function formatData(Inputdata) {
  return !Inputdata ? '' : dayjs(Inputdata).format('DD/MM/YYYY')
}

// Custom date comparison function considering both date and time
function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1)
  const date2 = new Date(dateStr2)
  return date1 - date2
}

function Loadlist(currentLivestok, filters) {
  const id = currentLivestok.id
  let rows_lv = []
  let data

  // carrega toda lista de atividades
  // console.log('lista - carrega toda lista de atividade')
  const listActvs = JSON.parse(localStorage.getItem('listActvs'))
  // console.log(listActvs)
  let listActivitys = []

  if (filters) {
    if (filters.table_active && filters.table_active == 'realized') {
      listActivitys = listActvs.filter((item) => item.id_livestock == id && item.planning == filters.table_active && (filters.status == 'edit' ? item.isDeleted == 0 : item.isDeleted == 1))
    } else if (filters.table_active && filters.table_active == 'planning') {
      listActivitys = listActvs.filter((item) => item.id_livestock == id && item.planning == filters.table_active && (filters.status == 'trash' ? item.isDeleted == 0 : item.isDeleted == 1))
    }

    if (filters.date_i && filters.date_f) {
      listActivitys = listActivitys.filter((item) => {
        if (dayjs(item.startDate_nf).isBetween(filters.date_i, filters.date_f, 'day') || dayjs(item.endDate_nf).isBetween(filters.date_i, filters.date_f, 'day')) {
          return item
        }
      })
    }

    if (filters.user && filters.user?.length > 0) {
      listActivitys = listActivitys.filter((item) => {
        let is_in = filters.user.filter((user_f) => {
          if (user_f.id == item.id_user) {
            return user_f
          }
        })
        if (is_in.length > 0) {
          return item
        }
      })
    }
  } else {
    listActivitys = listActvs
  }

  //console.log(activityList)
  for (const activityList of listActivitys) {
    if (activityList.activity === 'ENTRADA') {
      // console.log('ENTRADA')
      data = createData(
        activityList.id,
        activityList.numberAnimals,
        activityList.name_activity,
        currentLivestok.tipo,
        //activityList.type_activity,
        activityList?.progress,
        activityList.activity,
        activityList.user_name,
        formatData(activityList.date_start),
        formatData(activityList.date_prev_exit),
        activityList.nameBatch,
        activityList.nameArea,
        activityList.areamap_use,
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_prev_exit
      )
      //rows_lv.push(data);
    }
    if (activityList.activity === 'MEDICAMENTOS') {
      // console.log('MEDICAMENTOS')
      data = createData(
        activityList.id,
        activityList.total_animals,
        activityList.activity,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        getLotesData(activityList.lotes, 'lote'),
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
    }
    if (activityList.activity === 'NUTRICAO') {
      // console.log('NUTRICAO')
      data = createData(
        activityList.id,
        activityList.total_animals,
        activityList.activity,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        getLotesData(activityList.lotes, 'lote'),
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
    }
    if (activityList.activity == 'IDENTIFICAÇÃO') {
      // console.log('IDENTIFICAÇÃO')
      data = createData(
        activityList.id,
        activityList.num_animals,
        activityList.name,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        activityList.lote.name,
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
      //rows_lv.push(data);
    }
    if (/TRANSFERENCIA.*/.test(activityList.activity)) {
      //console.log("TRANSFERENCIA")
      data = createData(
        activityList.id,
        activityList.numberAnimals,
        activityList.name_activity,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        formatData(activityList.date_start),
        formatData(activityList.data_prev_end),
        activityList.nameBatch,
        activityList.nameArea,
        activityList.areamap_use,
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.data_prev_end
      )
      //rows_lv.push(data);
    }
    if (/PESAGEM.*/.test(activityList.activity)) {
      //console.log("PESAGEM")
      data = createData(
        activityList.id,
        activityList.total_weights,
        activityList.name,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        activityList.lote.name,
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
      //rows_lv.push(data);
    }
    if (/.*VINCULA.*/.test(activityList.activity)) {
      //console.log("VINCULA")
      data = createData(
        activityList.id,
        activityList.ids_array.length,
        activityList.name,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        '-',
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
      //rows_lv.push(data);
    }
    if (/NOVO LOTE/.test(activityList.activity)) {
      //console.log("NOVO LOTE")
      data = createData(
        activityList.id,
        1,
        activityList.name,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        activityList.nameBatch,
        activityList.nameArea,
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
      //rows_lv.push(data);
    }

    if (/PRODUÇÃO/.test(activityList.activity)) {
      //console.log("PRODUÇÃO")
      data = createData(
        activityList.id,
        getLotesData(activityList.lotes, 'animais'),
        activityList.name_activity,
        currentLivestok.tipo,
        activityList.progress,
        activityList.activity,
        activityList.name_user,
        activityList.date_start_f,
        activityList.date_end_f ? activityList.date_end_f : activityList.date_prev_end_f,
        getLotesData(activityList.lotes, 'lote'),
        '-',
        '-',
        activityList.isDeleted,
        activityList.planning,
        activityList.date_start,
        activityList.date_end ? activityList.date_end : activityList.date_prev_end
      )
    }
    data.icon = <IconsLivestockActivities activity={data.activity} type={data.type} widthStyles={'42'} />
    data.tipo_repos = 'livestock'
    rows_lv.push(data)
  }

  //ordenação
  rows_lv.sort((a, b) => compareDates(b.startDate_nf, a.startDate_nf))
  //rows_lv.sort((a, b) => a.id - b.id);

  return rows_lv
}

function LoadActivityListLivestock() {
  return {
    loadList: Loadlist,
  }
}

export default LoadActivityListLivestock
//export { loadActivityListLivestock };
