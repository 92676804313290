import * as React from 'react';
import { Box, Card, Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import FooterModal from '../../../../components/Footer/FooterModal';
import FormApportionment from '../../../financial/components/Forms/FormApportionment';
import MaintenanceCostCalculator from './MaintenanceCostCalculator';

export default function FormMaintenancenStep3({ data, obj_fn }) {
  // console.log('FormMaintenancenStep3');

  // const para validar o button continuar
  const isError = () => {
    // if (!data.value || !data.category || data.error_date || error) {
    //   return true;
    // }
    return false;
  };
  const returnError = () => {
    return false;
  };

  const handleChange = (event, blur) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      [event.target.name]: blur ? (event.target.value).trim() : event.target.value,
    }));
    if (event.target.name == 'value') {
      obj_fn.setData((prevState) => ({
        ...prevState,
        ['value_end']: event.target.value,
      }));
    }
  };

  return (
    <>
      <Box sx={{ mt: 1, mb: 8 }}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Valor total da manutenção:</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <MaintenanceCostCalculator data={data} obj_fn={obj_fn} />
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {/* Rateio de custos */}
          <Grid item xs={12} sm={12}>
            <FormApportionment data={data} obj_fn={obj_fn} />
          </Grid>
          <Grid item xs={12} sm={12}>

          <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Anotações/Observacoes gerais"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
              variant="standard"
            />
          </Grid>
        </Grid>
        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Box>
    </>
  );
}
