import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { Icon, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, styled, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { AccountCircle } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormattedInputs from './FormattedInputs';
import FormImage from './FormImage';

const currencies = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

export default function FormProperty({ property, setProperty, formError, setFormError, usestore, onSubmit }) {
  // input do formulário
  const handleChange = (event, trim) => {
    event.persist();
    var value = trim ? event.target.value.trim() : event.target.value;
    if (event.target.name == 'name') {
      validatePropertyName(value);
    } else if (event.target.name == 'area') {
      value = parseFloat(event.target.value);
      setFormError({ ...formError, [event.target.name]: value });
    }

    setProperty({ ...property, [event.target.name]: value });
  };

  // para validar o input
  const validatePropertyName = (value) => {
    var name_property = value.trim();
    if (!name_property || name_property.length < 5) {
      setFormError({ ...formError, ['name']: 'Por favor, insira um nome válido para sua propriedade!' });
    } else {
      var list_propertys = usestore.reposProperty.getPropertyStore('name', name_property);
      if (list_propertys.length > 0) {
        var compare_property = list_propertys.filter((property_repos) => {
          if (property_repos.id != property.id) {
            return property;
          }
        });
        if (compare_property.length > 0) {
          setFormError({ ...formError, ['name']: 'Já existe outra Propriedade com esse nome!' });
        }
      } else {
        setFormError({ ...formError, ['name']: '' });
      }
    }
  };

  return (
    <Box onSubmit={(e) => onSubmit(e)}>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <HomeWorkIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Informações essenciais</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            //InputLabelProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            //disabled
            required
            id="name"
            name="name"
            label="Nome da Propriedade"
            fullWidth
            autoComplete="shipping property-line1"
            variant="standard"
            color="success"
            value={property.name}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            error={formError.name != ''}
            helperText={formError.name != '' ? formError.name + ' Mínimo 5 letras!' : '*Insira o nome da propriedade'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            //InputLabelProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            required
            id="area"
            name="area"
            label="Qual área total?"
            //fullWidth
            //autoComplete="shipping property-line1"
            type="number"
            color="success"
            variant="standard"
            value={property.area}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            error={!formError.area}
            helperText={!formError.area ? 'Por favor, preencha o campo obrigatório da área!' : 'Área total da propriedade'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="measure"
            name="measure"
            label="Padrão de medida"
            select
            value={property.measure}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            variant="standard"
            color="success"
            helperText="Defina o padrão de medida"
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
        <InsertPhotoIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Imagem da propriedade</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
          <FormImage property={property} setProperty={setProperty} />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
        <LocationOnIcon />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Complementares</Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            //InputLabelProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="city"
            name="city"
            label="Cidade"
            fullWidth
            //required
            autoComplete="shipping property-line1"
            variant="standard"
            color="success"
            value={property.city}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            helperText="Insira o nome da Cidade"
            // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            //InputLabelProps={{ style: { fontSize: 12 } }}
            //required
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="state"
            name="state"
            label="Estado"
            fullWidth
            autoComplete="shipping property-line1"
            variant="standard"
            color="success"
            value={property.state}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            helperText="Insira o estado"
            // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            //helperText="Insira o endereço da propriedade para que possamos encontrar sua propriedade no mapa"
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            //required
            id="address"
            name="address"
            label="Endereço"
            fullWidth
            autoComplete="shipping property-line1"
            variant="standard"
            color="success"
            value={property.address}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            helperText={'Insira o endereço da propriedade'}
          />
        </Grid>
      </Grid>
      <br />
      <div>
        <Accordion sx={{ my: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Informações do administrador da propriedade</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <TextField
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                id="ownerName"
                name="ownerName"
                label="Nome do Proprietário da Fazenda"
                fullWidth
                autoComplete="shipping property-line1"
                variant="standard"
                color="success"
                value={property.ownerName}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, true)}
                error={property.ownerName.length < 5}
                helperText={property.ownerName.length < 5 ? 'Por favor, preencha o campo obrigatório! Mínimo 5 letras!' : 'Insira o nome do proprietário da fazenda'}
              />
              <br />
              <FormattedInputs property={property} setProperty={setProperty} />
              <br />
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto' }}>
                <TextField
                  sx={{ width: 300 }}
                  id="description"
                  name="description"
                  label="Possui alguma observação? Descreva aqui"
                  multiline
                  // InputProps={{ style: { fontSize: 40 } }}
                  InputLabelProps={{ style: { fontSize: 12, color: '#808080' } }}
                  rows={3}
                  variant="outlined"
                  value={property.description}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                />
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <br />
      <br />
      <br />
    </Box>
  );
}
