import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default function ChartBarHGroup() {
  var state = {
    series: [
      {
        data: [200.0, 30.121, 54.361, 1.151, 1.223],
      },
      {
        data: [200.0, 15.121, 25.361, 7.151, 0.623],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 50,
        style: {
          fontSize: "12px",
          colors: ["#b5b5b5"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["Sementes", "Fertilizantes", "Defensivos", "Máquinas", "Outros custos"],
      },
    },
  };

  return (
    <div style={{ width: "100%" }} className="bg-white w-full">
      <ReactApexChart options={state.options} series={state.series} type="bar" height={430} />
    </div>
  );
}
