import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import LivestockContent from "./LivestockContent";
import Footer from "../../components/Footer/Footer";

// livestock  é a lista das criações (atividades)
export default function Livestock() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <LivestockContent />
      <Footer />
    </>
  );
}
