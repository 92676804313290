import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import MapsContent from "./MapsContent";
import FooterMaps from "../../components/Footer/FooterMaps";
import MessengerMapBottom from "./components/Messenger/MessengerMapBottom";
import { useEffect } from "react";
import Loading from "../../components/Loader/Loading";
import { Grid, Skeleton } from "@mui/material";
import LinearIndeterminate from "../../components/Loader/LoagingLinear";

import Nav from "../../components/Nav/Nav";

export default function Maps() {
  const [data, setData] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  useEffect(() => {
    console.log('atualiza a página do mapa ')
    console.log(isLoadingPage)
    if (isLoadingPage === true) {
      setTimeout(() => {
        setIsLoadingPage(false)
      }, 4000);

    }
  }, [isLoadingPage]);

  //isLoadingPage
  return (
    <>
      {/* <Nav setIsLoadingPage={setIsLoadingPage} /> */}

      {isLoadingPage === true ?
        (
          <>
            <div className=" m-0 p-0">
              <div className=""></div>
              <div className="">
                <div className="" style={{ height: "100%", position: "relative", width: "100%", left: 0, right: 0, bottom: 0, top: 0 }}>
                  <LinearIndeterminate />
                </div>
                <div className="" style={{ height: "100%", position: "fixed", width: "100%", left: 0, right: 0, bottom: 0, top: 80, overflow: "hidden" }}>
                  <Grid container spacing={1}   >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={"100%"} height={800} />
                    </Grid>
                    <Grid item xs={12} >
                      <Skeleton variant="rectangular" width={"100%"} height={80} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            {/* <div className="preloader flex-column justify-content-center align-items-center">
              <img className="animation__shake" src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="FazendaPremium" height="60" width="60" />
            </div> */}
          </>
        ) :
        (
          <MapsContent isLoadingPage={isLoadingPage} setIsLoadingPage={setIsLoadingPage} />
        )}

      {/* <FooterMaps /> */}
    </>
  );
}
