import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import LivestockListSpecificAreaContent from "./LivestockListSpecificAreaContent";
import Footer from "../../components/Footer/Footer";

export default function LivestockListSpecificArea() {
  return (
    <>
      {/* <NavSide />
      <Nav />
      <NavBotton /> */}
      <LivestockListSpecificAreaContent />
      <Footer />
    </>
  );
}
