import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import GroupsIcon from '@mui/icons-material/Groups';
import { NavLink } from "react-router-dom";
import { AvatarGroup } from "@mui/material";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function CardTeam({ data_cards }) {
  const optionsEquipe = Object.assign([], data_cards.equipe);
  console.log('optionsEquipe');

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}
              alignItems="center"
              justifyContent="center">
              <Typography variant="body2" component="div">
                Equipe: {data_cards.equipe.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}
              alignItems="center"
              justifyContent="center">
              <AvatarGroup total={data_cards.equipe.length}>
                {optionsEquipe.map((item) => (
                  <Avatar key={item.id} sx={{ bgcolor: "#28a745" }} alt={item.name}>{item.name[0].toUpperCase()}</Avatar>
                ))}
              </AvatarGroup>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton sx={{ ml: "auto" }}>
          <NavLink to="/team" className="nav-link">
            <Typography variant="body2" color="text.secondary" component="div">
              Ver a equipe
            </Typography>
          </NavLink>
        </IconButton>
      </CardActions>
    </Card>
  );
}
