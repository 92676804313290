import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardHeader, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { Context } from '../../components/Context/AuthContext'
import InventoryListTable from './components/InventoryListTable'
import ListStock from './ListStock'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function InventorySltockListContent({ productList, setProductList }) {
  const theme = useTheme()
  const [value, setValue] = useState(0)

  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //console.log(productList)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Estoque: </strong>com os produtos cadastrados, tenha visibilidade dos produtos e disponíveis no seu estoque
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label="lista Simples"
            {...a11yProps(0)}
            sx={{
              '&.Mui-selected': { color: theme.palette.success.main }, // ou { color: 'green' } para cor verde direta
            }}
          />
          {/* <Tab
            label="Lista Agrupada"
            {...a11yProps(1)}
            sx={{
              '&.Mui-selected': { color: theme.palette.success.main }, // ou { color: 'green' } para cor verde direta
            }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} sx={{ mt: 1, p: 1 }}>
          <Grid item xs={12} md={2} sx={{ mt: -2, display: ismobile ? 'none' : 'block' }}>
            <ListStock productList={productList} setProductList={setProductList} />
          </Grid>
          <Grid item xs={12} md={10} sx={{ mb: 5 }}>
            <InventoryListTable productList={productList} setProductList={setProductList} />
          </Grid>
        </Grid>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <InventoryList />
      </TabPanel> */}
    </Box>
  )
}
