import { useContext, useState } from 'react'
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton, InputAdornment } from '@mui/material'
import Typewriter from '../../../components/Typewriter/Typewriter'
import ChatPlan from '../components/FormPlan/ChatPlan'
import { Span } from '../../../components/Typography'
import IAIcon from '../../../components/Icons/chat/IAIcon'
import { toast } from 'react-toastify'
import useStore from '../../../store/useStore'
import { format } from 'date-fns'
import SuccessTarget from './SuccessTarget/SuccessTarget'
import api from '../../../services/api'
import { Context } from '../../../components/Context/AuthContext'
import { useParams } from 'react-router-dom'
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore'
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore'

const UnitOptions = [
  { label: 'sc 30kg', value: 'sc 30kg' },
  { label: 'sc 40kg', value: 'sc 40kg' },
  { label: 'sc 50kg', value: 'sc 50kg' },
  { label: 'sc 60kg', value: 'sc 60kg' },
  { label: 't', value: 't' },
  { label: 'kg', value: 'kg' },
  { label: 'g', value: 'g' },
  { label: 'mg', value: 'mg' },
]

const ValueCost = [
  { label: 'R$', value: 'pt-BR' },
  { label: '$', value: 'en-US' },
]

const MeasureOptions = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
]

export default function FormGoalPlanHarvest({ handleClose, areaActive, target_active }) {
  console.log('FormGoalPlanHarvest1')
  console.log(areaActive)
  console.log(target_active)

  const { idFarm, id } = useParams()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { aux_add, setAuxAdd } = useContext(Context)

  //para recuperar a produção estimada se houver
  const reposPlanning = useHarvestTargetReposStore((state) => state)
  console.log('reposPlanning')
  console.log(reposPlanning)
  const planRepos = reposPlanning.getPlanningStore('id', target_active?.id)[0]
  console.log('produção estimada se houver')
  console.log(planRepos)
  const reposProductionCostHarvest = useHarvestTargetCostReposStore((state) => state)

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({})
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({})

  const handleOpenChat = () => {
    setOpen(true)
  }

  const handleCloseChat = () => {
    setOpen(false)
  }

  const usestore = useStore()
  // const reposActivitiesInputs = usestore.reposActivityInput;

  // const initCreation = usestore.reposLivestock.livestockRepos;

  // const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))

  // seta as opções de safras
  const optionsHarvests = usestore.reposHarvest.listHarvest

  const initHarvests = usestore.reposHarvest.harvestRepos
  //console.log(initHarvests)
  const harvestCurrent = usestore.reposHarvest.getHarvestStore('id', initHarvests.id)
  console.log('harvestCurrent')
  console.log(harvestCurrent[0])

  // // atualizar o localstorage
  // const fetchTodos = useActivityInputReposStore((state) => state.fetch);
  // const fetchAreas = useAreasMapReposStore((state) => state);
  // const fetchLivestock = useLivestockReposStore((state) => state);

  function calcularSomatorioUsedArea(array) {
    //console.log(array)
    let somatorio = 0

    for (let i = 0; i < array.length; i++) {
      const usedArea = parseFloat(array[i].usedArea.replace(',', '.'))
      somatorio += usedArea
    }

    return somatorio
  }

  function formatNumber(number) {
    const formattedNumber = parseFloat(number).toFixed(2)
    return formattedNumber
  }

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: harvestCurrent[0].id_property,
    id_harvest: harvestCurrent[0].id,
    id_target: target_active.id,
    expectedProduction: planRepos.expectedYield || '',
    unit: planRepos.unit || 'sc 60kg',
    saleUnitValue: formatNumber(planRepos.cost) || '',
    valueSale: 'pt-BR',
    unitSale: 'sc 60kg',
    percentageProfit: '',
    // area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
    area: areaActive.area,
    measure: areaActive.measure,
    id_areamap: areaActive.data.id,
    observation: '',
    isDeleted: 0,
  })

  // const [data, setData] = useState({
  //   id_user: userCurrent.id,
  //   id_property: harvestCurrent[0].id_property,
  //   id_harvest: harvestCurrent[0].id,
  //   expectedProduction: planRepos.expectedYield || "",
  //   unit: planRepos.unit || "sc 60kg",
  //   saleUnitValue: formatNumber(planRepos.cost) || "",
  //   valueSale: "pt-BR",
  //   unitSale: "sc 60kg",
  //   percentageProfit: "",
  //   // area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
  //   area: areaActive.area,
  //   measure: areaActive.measure,
  //   id_areamap: areaActive.data.id,
  //   observation: "",
  //   isDeleted: 0,
  // });

  console.log(data)

  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {
    const { name, value } = event.target
    console.log(name, value)
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.startDate || data.startDate.trim() === '') {
      return true
    }
    if (!data.expectedYield || data.expectedYield.trim() === '') {
      return true
    }
    if (!data.area || data.area.trim() === '') {
      return true
    }
    return false
  }

  const returnError = () => {
    return true
  }
  const color = '#2e7d32'

  const reposPlanHarvest = useHarvestTargetCostReposStore((state) => state)
  const reposHarvestTargetCost = useHarvestTargetCostReposStore((state) => state)
  const reposHarvestTarget = useHarvestTargetReposStore((state) => state)

  const onSubmit = async (e) => {
    e.preventDefault()
    // console.log('onSubmit')
    // console.log(data)
    setFormError({})
    let errors = {}
    let formOk = true

    if (data.expectedProduction === '') {
      errors.nameBatch = true
      formOk = false
      // } else if (property.address.length < 3) {
    } else if (data.saleUnitValue === '') {
      errors.area = true
      formOk = false
    } else if (data.percentageProfit === '') {
      errors.area = true
      formOk = false
    }

    //planHarvest
    // console.log(data)

    const header = { header: { 'Content-Type': 'application/json' } }

    if (false) {
      // console.log(data)
      const res = await api
        .post('/plan/planHarvest', data, header)
        .then((response) => {
          // console.log(response.data)
          if (response.data.isSuccess) {
            //setIsLoading(true);
            toast.success('Meta criada com sucesso!')
            reposPlanHarvest.fetch({ id_farm: idFarm }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
            reposHarvestTarget.fetch({ id_farm: idFarm }).then((res) => {
              //setAuxAdd(aux_add + 1);
              // setTimeout(() => {
              //   handleClose()
              // }, 200);
            })
            reposHarvestTargetCost.fetch({ id_farm: idFarm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1)
                // handleClose();
              }, 200)
            })
            //const reposProductionCostHarvest = useHarvestTargetCostReposStore((state) => state)
            reposProductionCostHarvest.fetch({ id_farm: idFarm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1)
                // handleClose();
              }, 200)
            })
            // var updt_lv = reposLivestocks.updateLivestockStore(id, "all", data);
            // setTimeout(() => {
            //   setAuxAdd(aux_add + 1);
            //   handleClose();
            // }, updt_lv.length);
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
        })
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios')
      setFormError(errors)
      //return false;
    }
  }

  return (
    <>
      {/* {isLoading && <SuccessTarget handleClose={handleClose} />} */}
      <Container maxWidth="sm">
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}

          <Grid container sx={{ mt: 1, mb: 6 }} className="d-lg-none">
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 80 }}>
              <Typography style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} minHeight={0} text="Defina o valor do lucro desejado sobre a venda." />
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1, mb: 6 }} className="d-none d-lg-block">
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }}>
              <Typography style={{ color: '#2e7d32' }}>
                <Typewriter tam={0} minHeight={0} text="Defina o valor do lucro desejado sobre a venda." />
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center', mt: 0.5 }} variant="subtitile1" component="div">
                  Qual o valor do lucro desejado sobre a venda em porcentagem?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="percentageProfit"
                  label="Porcentagem de lucro"
                  type="number"
                  color="success"
                  focused
                  name="percentageProfit"
                  value={data.percentageProfit}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ mt: 3 }} position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="expectedProduction"
                  variant="filled"
                  disabled
                  color="success"
                  focused
                  label="Produção Total Esperada"
                  type="number"
                  required
                  fullWidth
                  value={data.expectedProduction}
                  onChange={handleChange}
                  error={formError.expectedProduction || data.expectedProduction === ''}
                  helperText={formError.expectedProduction && data.expectedProduction === '' ? 'Por favor, preencha o campo obrigatório!' : 'Estime o tamanho da sua produção.'}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select disabled name="unit" fullWidth label="Unidade" value={data.unit} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                    <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                    <Select disabled name="valueSale" fullWidth label="Moeda" value={data.valueSale} onChange={handleChange}>
                      {ValueCost.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="saleUnitValue"
                  variant="filled"
                  color="success"
                  focused
                  disabled
                  label="Valor esperado de venda por unidade1"
                  type="number"
                  required
                  fullWidth
                  value={data.saleUnitValue}
                  onChange={handleChange}
                  error={formError.saleUnitValue || data.saleUnitValue == ''}
                  helperText={formError.saleUnitValue && data.saleUnitValue === '' ? 'Por favor, preencha o campo obrigatório!' : 'Estime o valor desejado de venda.'}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select disabled name="unitSale" fullWidth label="Unidade" value={data.unitSale} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  name="area"
                  variant="standard"
                  label="Área"
                  type="number"
                  required
                  disabled
                  fullWidth
                  value={data.area}
                  onChange={handleChange}
                  error={formError.area && data.area === ''}
                  helperText={formError.area && data.area === '' ? 'Por favor, preencha o campo obrigatório!' : 'Estime a área da sua produção.'}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Medida</InputLabel>
                    <Select disabled autoFocus name="measure" fullWidth label="Unidade" value={data.measure} onChange={handleChange}>
                      {MeasureOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                Ex.: Produção de 2.000 sacas de 60 kg em uma área de 50 hectares de cultivo.
              </Typography>
            </Grid> */}
            {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              Gerar Planejamento
            </Button> */}
            <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                    <Span>cancelar</Span>
                  </Button>
                </Box>
                {data && (
                  <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                      Salvar
                    </Button>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
          </Box>
        </Paper>
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
    </>
  )
}
// banco:
//https://www.cepea.esalq.usp.br/br/consultas-ao-banco-de-dados-do-site.aspx
//https://cepea.esalq.usp.br/br/widget.aspx
// site com grafico
//https://web.graodireto.com.br/#/quotation
//https://www.graodireto.com.br/#who-buys
