import React from 'react';
import SimpleTable from '../../components/Tables/SimpleTable';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Avatar, Box, Button, Card, CardHeader, Container, Divider, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { blue, green } from '@mui/material/colors';
import { Span } from '../../components/Typography';
import { Widget } from '../../components/Widget/Help/Widget';
export default function SuporteContent() {
  return (
    <>
      <div className="content-wrapper bg-white ">
        <div className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h5" component="div">
                  Aprendizado e Suporte
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Widget />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container-fluid">
          <Card sx={{ mb: 1 }}>
            <Box sx={{ mt: 1, mb: 1, ml: 5, display: 'flex' }}>
              <Avatar variant="rounded" sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
                <SchoolIcon />
              </Avatar>
              <Stack spacing={0.5} sx={{ ml: 1 }}>
                <Typography fontWeight={700}>Aprendizado</Typography>
                <Typography fontWeight={400}>Veja como utilizar o CPT Fazenda Premium de forma simples e rápida.</Typography>
              </Stack>
            </Box>
            <Divider />
            <Container component="main" maxWidth="sm" sx={{ mb: 1, mt: -3, mb: -2 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                    Acesse os nossos tutoriais
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Button href="https://fp.cptsoftwares.com.br/suporte-fazenda-premium/" target="_blank" color="success" variant="outlined" type="submit">
                    <Span sx={{ textTransform: 'capitalize' }}>tutoriais</Span>
                  </Button>
                </Box>
              </Paper>
            </Container>
          </Card>
        </div>
        <div className="container-fluid">
          <div className="content-wrapper bg-white">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h4>Equipe de Suporte</h4>
                  </div>
                  <div className="col-sm-6">
                    {/* <ol className=" float-sm-right">
                      <a href="#">CPT Softwares</a>
                    </ol> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="card card-solid">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                      <div className="card bg-light d-flex flex-fill">
                        <div className="card-header text-muted border-bottom-0">Suporte</div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-7">
                              <h2 className="lead">
                                <b>André</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Colaborador </b>
                              </p>
                              <ul className="ml-4 mb-0 fa-ul text-muted">
                                <li className="small">
                                  <span className="fa-li">
                                    <i className="fas fa-lg fa-building" />
                                  </span>{' '}
                                  Endereço: Rua Padre Serafim, 30 Loja 6 Box N – Bairro Centro, Viçosa – MG
                                </li>
                                <li className="small">
                                  <span className="fa-li">
                                    <i className="fas fa-lg fa-phone" />
                                  </span>{' '}
                                  Telefone #: +55 31 3899-7027
                                </li>
                              </ul>
                            </div>
                            <div className="col-5 text-center">
                              <img src="../../dist/img/AvatarAndre.png" alt="user-avatar" className="img-circle img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="text-right">
                            {/* <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments" />
                      </a> */}
                            <a
                              href="https://api.whatsapp.com/send?phone=553138997027&text=Deixe%20sua%20mensagem%20%20que%20responderei%20em%20breve."
                              target="_blank"
                              className="btn btn-sm btn-success"
                              rel="noreferrer"
                            >
                              <WhatsAppIcon /> Chamada
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                      <div className="card bg-light d-flex flex-fill">
                        <div className="card-header text-muted border-bottom-0">Suporte</div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-7">
                              <h2 className="lead">
                                <b>Pedro Henrique</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Colaborador </b>
                              </p>
                              <ul className="ml-4 mb-0 fa-ul text-muted">
                                <li className="small">
                                  <span className="fa-li">
                                    <i className="fas fa-lg fa-building" />
                                  </span>{' '}
                                  Endereço: Rua Padre Serafim, 30 Loja 6 Box N – Bairro Centro, Viçosa – MG
                                </li>
                                <li className="small">
                                  <span className="fa-li">
                                    <i className="fas fa-lg fa-phone" />
                                  </span>{' '}
                                  Telefone #: +55 31 3899-7610
                                </li>
                              </ul>
                            </div>
                            <div className="col-5 text-center">
                              <img src="../../dist/img/avatar2.png" alt="user-avatar" className="img-circle img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="text-right">
                            {/* <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments" />
                      </a> */}
                            <a
                              href="https://api.whatsapp.com/send?phone=553138997610&text=Deixe%20sua%20mensagem%20%20que%20responderei%20em%20breve."
                              target="_blank"
                              className="btn btn-sm btn-success"
                              rel="noreferrer"
                            >
                              <WhatsAppIcon /> Chamada
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
