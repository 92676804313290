import { useContext, useEffect, useState } from "react";
import { Box, Container, Paper } from "@mui/material";
import SuccessInput from "../../../activities/components/FormActivities/Input/SuccessInput";
import useStore from "../../../../store/useStore";
import useActivityInputReposStore from "../../../../store/Activities/useActivityInputReposStore";
import { Context } from "../../../../components/Context/AuthContext";
import useAreasMapReposStore from "../../../../store/useAreasMapReposStore";
import useLivestockReposStore from "../../../../store/useLivestockReposStore";
import FirstStepFormExpectedProduction from "./FirstStepFormExpectedProduction";
import FirstStepFormCostProduction from "./lixo/FirstStepFormCostProduction";
import FirstStepFormCostSelectProduction from "./lixo/FirstStepFormCostSelectProduction";
import FirstStepFormProfitability from "./lixo/FirstStepFormProfitability";
import FirstStepFormConfirmEstimative from "./FirstStepFormConfirmEstimative";
import { format } from 'date-fns';
import SuccessPlanTarget from "./SuccessPlanTarget";

export default function MultiStepFormPlan({ handleClose }) {
  const usestore = useStore();
  const reposActivitiesInputs = usestore.reposActivityInput;
  // pegar o livestock current
  const initCreation = usestore.reposLivestock.livestockRepos;
  //console.log("initCreation");
  //console.log(initCreation);
  const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  // seta as opções de safras
  const optionsHarvests = usestore.reposHarvest.listHarvest;
  //console.log("lista de safras");
  //console.log(optionsHarvests);


  //const harvest = usestore.reposHarvest;
  //console.log(harvest)
  //console.log(optionsHarvests);
  // seta a safra atual
  const initHarvests = usestore.reposHarvest.harvestRepos;
  console.log(initHarvests)
  const currentHarvests = usestore.reposHarvest.harvestRepos;
  const harvestCurrent = usestore.reposHarvest.getHarvestStore('id', initHarvests.id)
  console.log(harvestCurrent[0])

  // atualizar o localstorage
  const fetchTodos = useActivityInputReposStore((state) => state.fetch);
  const fetchAreas = useAreasMapReposStore((state) => state);
  const fetchLivestock = useLivestockReposStore((state) => state);

  function calcularSomatorioUsedArea(array) {
    //console.log(array)
    let somatorio = 0;

    for (let i = 0; i < array.length; i++) {
      const usedArea = parseFloat(array[i].usedArea.replace(',', '.'));
      somatorio += usedArea;
    }

    return somatorio;
  }

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: harvestCurrent[0].id_property,
    id_harvest: harvestCurrent[0].id,
    id_cultivation: harvestCurrent[0].id_cultivation,
    id_group: harvestCurrent[0].id_group,
    group_name: harvestCurrent[0].group_name,
    //id_areamap: "",
    cultivation_name: harvestCurrent[0].cultivation_name,
    date_start: format(new Date(harvestCurrent[0].date_start), 'yyyy-MM-dd'),
    date_prev_end: format(new Date(harvestCurrent[0].date_prev_end), 'yyyy-MM-dd'),
    //date_start: harvestCurrent.date_start,
    //date_prev_exit: harvestCurrent.date_prev_end,
    expectedYield: "",
    unit: "sc 60kg",
    cost: "",
    valueCost: "pt-BR",
    unitCost: "sc 60kg",
    area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
    measure: "ha",
    seedsCost: "7000",
    fertilizersAndPesticidesCost: "25000",
    laborCost: "30000",
    fuelCost: "6000",
    electricityCost: "2000",
    machineryMaintenanceCost: "3000",
    landRentCost: "20000",
    stage: "",
    // numberAnimals: "",
    isDeleted: false,
    // id_tipe: initCreation.tipe,
    planning: "",
    activitiesPlan: [],
  });

  //const [cropData, setCropData] = useState(initialCropData);
  const [plan, setPlan] = useState(null);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setCropData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Fazer o processamento e criação do planejamento aqui
    const { startDate, endDate, expectedYield } = data;
    const newdata = {
      startDate,
      endDate,
      expectedYield,
      seedsCost: data.seedsCost,
      fertilizersAndPesticidesCost: data.fertilizersAndPesticidesCost,
      laborCost: data.laborCost,
      fuelCost: data.fuelCost,
      electricityCost: data.electricityCost,
      machineryMaintenanceCost: data.machineryMaintenanceCost,
      landRentCost: data.landRentCost,
    };

    setData(newdata);
    const totalCost =
      Number(data.seedsCost) +
      Number(data.fertilizersAndPesticidesCost) +
      Number(data.laborCost) +
      Number(data.fuelCost) +
      Number(data.electricityCost) +
      Number(data.machineryMaintenanceCost) +
      Number(data.landRentCost);

    const costPerHectare = totalCost / 50; // Altere o valor "50" para a área cultivada real

    console.log("Custo Total de Produção:", totalCost);
    console.log("Custo por Hectare:", costPerHectare);
  };

  //console.log(data);

  console.log("1-init-MultiStepForm");

  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState("");
  const [text, setText] = useState("");
  const [selection, setSelection] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  const { aux_add, setAuxAdd } = useContext(Context);

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["Step 1", "Step 2"];

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const onSubmit = async () => { };

  const getStepContent = (step) => {
    console.log("step");
    console.log(step);
    switch (step) {
      case 0:
        return (
          <FirstStepFormExpectedProduction
            data={data}
            setData={setData}
            formError={formError}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 1:
        return (
          <FirstStepFormConfirmEstimative
            data={data}
            setData={setData}
            formError={formError}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <FirstStepFormCostSelectProduction
            data={data}
            setData={setData}
            formError={formError}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 3:
        return (
          <FirstStepFormCostProduction
            data={data}
            setData={setData}
            formError={formError}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );

      case 4:
        return (
          <FirstStepFormProfitability
            data={data}
            setData={setData}
            formError={formError}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 5:
        return;
      case 6:
        return;
      default:
        return "Não existente";
    }
  };
  console.log("activeStep");
  console.log(activeStep);
  console.log("2-RENDER");
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {/* // se o activeStep que inci-ase com zero === [0,1,2,3] array de step*/}
        {activeStep === steps.length ? (
          // Chamada do componente final do step (activeStep) para animação
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessPlanTarget handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        ) : (
          <>
            {/* // Chamada dos componentes setp 1, setp 2....  */}
            {getStepContent(activeStep)}
          </>
        )}
      </Box>
    </>
  );
}
