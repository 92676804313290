import React, { useEffect, useState, useContext } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, timelineItemClasses } from '@mui/lab';

import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { AccessTime, ArrowBackIosNew, FilterList, Person } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';

//import LoadMsnListComponent from "../LoadMsnList/LoadMsnListComponent";

import Loading from '../Loader/Loading';
import LoadActivityListLivestock from '../LoadActivityList/LoadActivityListLivestock';
import LoadActivityListHarvest from '../LoadActivityList/LoadActivityListHarvest';
import CardChatKanban from '../Boards/CardChatKanban';
import AutocompleteUsers from '../Fields/AutocompletUsers';
import AutocompletActivities from '../Fields/AutocompletActivities';

function formatLondDate(data) {
  const dataFormatada = dayjs(data).locale('pt-br').format('D [de] MMMM [de] YYYY');
  return dataFormatada;
}
function formatLocaleDate(data) {
  const dataFormatada = data.toLocaleDateString('default', {
    month: 'short',
    year: 'numeric',
  });
  return dataFormatada;
}

const colors_dot = {
  'A FAZER': 'success',
  'EM PROGRESSO': 'info',
  CONCLUÍDA: 'success',
  REVISAR: 'grey',
};

export default function CardTimeLinePainel({ item }) {
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState([]);

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();

  // Incorporar as mensagens quando for possível especificar a livestock ou harvest
  //const listMsn = LoadMsnListComponent();

  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [filters, setFilters] = useState({
    table_active: 'realized',
    status: 'edit',
    user: [],
    activits: [],
    date_i: dayjs().endOf('month'),
    date_f: dayjs().startOf('month'),
  });

  const handleRows = () => {
    // filtrar caso seja harvest
    let rows_actvs = [];
    if (item.tipo_repos == 'Criações') {
      rows_actvs = loadActivityListLivestock.loadList(item, filters);
    } else {
      rows_actvs = loadActivityListHarvest.loadList(item, filters);
    }
    let list_rows = rows_actvs;

    if (filters.activits && filters.activits?.length > 0) {
      list_rows = rows_actvs.filter((row) => {
        let is_in = filters.activits.filter((atividade) => {
          if (atividade.title == row.activity) {
            return atividade;
          }
        });
        if (is_in.length > 0) {
          return row;
        }
      });
    }

    setRows(list_rows);

    setTimeout(() => {
      setIsLoadingTable(false);
    }, 500);
  };

  useEffect(() => {
    console.log('refresh CardTimeLine');
    handleRows();
  }, [refresh]);

  const handleMonth = (tipo) => {
    let month = '';
    if (tipo == 'next') {
      month = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1);
    } else {
      month = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1);
    }
    filters.date_i = dayjs(month).startOf('month');
    filters.date_f = dayjs(month).endOf('month');

    setSelectedMonth(month);
    setFilters(filters);

    setRefresh(new Date().getTime());
  };

  const handleChangeUser = (value) => {
    filters.user = value;
    setFilters(filters);
    setRefresh(new Date().getTime());
  };
  const handleChangeActivit = (value) => {
    filters.activits = value;
    setFilters(filters);
    setRefresh(new Date().getTime());
  };

  console.log(rows);

  // mudando a cor do icone:
  const colors_dot = {
    CONCLUÍDA: 'inherit',
    REVISAR: 'secondary',
  };

  const IconComponent = ({ icon }) => {
    return <div>{icon}</div>;
  };

  return (
    <>
      {isLoadingTable ? (
        <>
          {' '}
          <Loading />{' '}
        </>
      ) : (
        <>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} sx={{ mt: -3 }}>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton aria-label="Previous Month" onClick={() => handleMonth('previous')}>
                        <ArrowBackIosNew color="success" sx={{ fontSize: 30 }} />
                      </IconButton>
                      <Box sx={{ width: 150 }}>
                        <Typography variant="h6">{formatLocaleDate(selectedMonth)}</Typography>
                      </Box>
                      <IconButton aria-label="Next Month" onClick={() => handleMonth('next')}>
                        <ArrowBackIosNew color="success" sx={{ transform: 'rotate(180deg)', fontSize: 30 }} />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: -2 }}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FilterList style={{ fontSize: 40 }} sx={{ mr: 1, mt: -1 }} />
                    <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                      <AutocompletActivities item={item} handleChange={handleChangeActivit} multiple={true} />
                      <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                        <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                          Filtre por tipo de atividade
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Person style={{ fontSize: 40 }} sx={{ mr: 1, mt: -1 }} />
                    <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                      <AutocompleteUsers handleChange={handleChangeUser} multiple={true} />
                      <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                        <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                          Filtre por pessoas
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflowY: 'scroll', height: '1000px' }}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem sx={{ py: '8px', ml: -0.3 }}>
                <TimelineSeparator>
                  <AccessTime sx={{ fontSize: 40, color: grey[500] }} />
                </TimelineSeparator>
                <TimelineContent>{formatLondDate(filters.date_f)}</TimelineContent>
              </TimelineItem>
              {rows.map((post) => (
                <>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color={colors_dot[post.progress || 'REVISAR']}>
                        <IconComponent icon={post.icon} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '10px', px: 2 }}>
                      {formatLondDate(post.startDate_nf)}
                      {/* <Box sx={{ mb: 3 }}>{item.type == "msn" ? <CardTimeLineMsn /> : <CardTimeLineActivity post={item.activity} />}</Box> */}
                      <CardChatKanban post={post} type={'tipos'} isLoading={isLoadingTable} painel={true} />
                    </TimelineContent>
                  </TimelineItem>
                </>
              ))}
              <TimelineItem sx={{ py: '8px', ml: -0.3 }}>
                <TimelineSeparator>
                  <AccessTime sx={{ fontSize: 40, color: grey[500] }} />
                </TimelineSeparator>
                <TimelineContent>{formatLondDate(filters.date_i)}</TimelineContent>
              </TimelineItem>
            </Timeline>
          </Box>
        </>
      )}
    </>
  );
}
