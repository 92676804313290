import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Zoom } from "react-toastify";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CardWeather from "./WeatherForecast/CardWeather";
import CardWeatherMap from "./WeatherForecast/CardWeatherMap";


export const WeatherForecastButtonVertical = ({ idFarm }) => {
  // const getWeatherForecast = async () => {
  //   const weatherForecastData = await httpClient
  //     .get(`https://api.hgbrasil.com/weather?key=c8c520be&city_name=Fortaleza,CE`)
  //     .then(res => res.data.results)
  //
  //   console.log(weatherForecastData)
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //sx={{ position: 'relative', top: 210, left: -46, backgroundColor: "#fff", }}
  // sx={{ position: "relative", top: 181, left: -156, backgroundColor: "#fff" }}

  var style = {top: 35, backgroundColor: "#fff", color: '#555'};
  if (window.innerWidth < 400) {
    style = {top: 35, backgroundColor: "#fff", color: '#555', width: 40, height: 40};
  }
  return (
    <>
      <Fab
        sx={style}
        //aria-controls={open ? "Termostat-menu" : undefined}
        aria-haspopup="true"
        //aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        aria-label="add"
      >
        <ThermostatIcon sx={{ color: "#555" }} />
      </Fab>
      <Menu
        id="Termostat-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ml: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 20,
              right: 318,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "right" }}
      // PaperProps={{
      //     elevation: 0,
      //     sx: {
      //         overflow: 'visible',
      //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      //         mt: 1.5,
      //         mr: 1,
      //         '& .MuiAvatar-root': {
      //             width: 32,
      //             height: 32,
      //             ml: -0.5,
      //             mr: 1,
      //         },
      //         '&:before': {
      //             content: '""',
      //             display: 'block',
      //             position: 'absolute',
      //             top: 0,
      //             right: 22,
      //             width: 10,
      //             height: 10,
      //             bgcolor: 'background.paper',
      //             transform: 'translateY(-50%) rotate(45deg)',
      //             zIndex: 0,
      //         },
      //     },
      // }}
      // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <ListItemText sx={{ ml: 1 }}>Previsão do tempo</ListItemText>
        <Divider />
        <MenuItem sx={{ ml: -1 }} onClick={handleClose}>
          {/* <CardWeatherMapTabs idFarm={idFarm} /> */}
          <CardWeatherMap idFarm={idFarm} />
          {/* <CardWeather idFarm={idFarm} /> */}
        </MenuItem>
      </Menu>
    </>
  );
};
