import { Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Zoom } from "react-toastify";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { ContentCopy } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import Loading from "../../../components/Loader/Loading";
import IconsLivestockActivities from "../../../components/Icons/IconsLivestockActivities";
import useStore from "../../../store/useStore";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  width: 50
});

export const LivestockButtonVertical = () => {
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const usestore = useStore();
  const livestockRepos = usestore.reposLivestock.livestockRepos;
  const changeLivestockRepos = usestore.reposLivestock.changeLivestockRepos;

  const handleChange = (e) => {
    var criation = JSON.parse( window.jQuery(e.currentTarget).attr('value') );
    localStorage.setItem("creationHarvestRepos", JSON.stringify(criation));
    changeLivestockRepos(criation);
  };
  const handleClick = (event) => {
    if (event.currentTarget != anchorEl) {
      console.log(event.currentTarget)
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    console.log(null)
    setAnchorEl(null);
  };

  var style = {top: 15, backgroundColor: "#fff", color: '#555'};
  if (window.innerWidth < 400) {
    style = {top: 15, backgroundColor: "#fff", color: '#555', width: 40, height: 40};
  }

  return (
    <>
      {loading === false ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Fab sx={style} aria-label="add" onClick={handleClick} >
            <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'}/>
          </Fab>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                ml: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 20,
                  right: 202,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "right" }}
          >
            <Box sx={{ ml: 2 }}>
              <ListItemText>Criações</ListItemText>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ mb: 1.1, mt: 1 }}>
              <Grid item xs={12} sm={12}>
                <NavLink to={{ pathname: !livestockRepos || !livestockRepos.id ? "/livestock" : "/controlpanelcreations/" + livestockRepos.id_property + "/" + livestockRepos.id}} className="text-success"
                  onClick={(e) => { handleChange(e); }} value={JSON.stringify(livestockRepos)}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4}>
                      <IconsLivestockActivities activity={'Geral'} widthStyles={'50px'} />
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Typography variant="body2" component="div">
                        {livestockRepos.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </NavLink>
              </Grid>
            </Grid>
            <Divider />
            <MenuItem sx={{ mb: -1 }}>
              <ListItemText>
                <NavLink to="/livestock" className="">
                  <a className="text-secondary">Ver todas as criações ...</a>
                </NavLink>
              </ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};
