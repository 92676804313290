import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useEffect } from "react";

const dreItens = [
  "(+) Receitas Operacionais (Receita Operacional Bruta)",
  "(=) Receita Bruta de Vendas",
  "(-) Deduções da Receita Bruta (Deduções e Abatimentos)",
  "(=) Receita Líquida de Vendas",
  "(-) Custos Operacionais",
  "(=) Lucro Bruto",
  "(-) Despesas Operacionais",
  "(=) Lucro/Prejuízo Operacional",
  "(-/+) Receitas e Despesas Financeiras",
  "(-/+) Outras Receitas e Despesas Não Operacionais",
  "(=) Lucro/Prejuízo Líquido",
  "Despesas com Investimentos e Empréstimos",
  "(=) Lucro/Prejuízo Final",
];

function findCategoryById(categories, categoryIdToFind) {
  for (const category of categories) {
    if (category.id === categoryIdToFind) {
      return category; // Encontrou o objeto no nível atual
    }

    if (category.subcategories && category.subcategories.length > 0) {
      // Procura recursivamente nas subcategorias
      const subcategoryResult = findCategoryById(category.subcategories, categoryIdToFind);
      if (subcategoryResult) {
        return subcategoryResult; // Encontrou o objeto em uma subcategoria
      }
    }
  }

  return null; // Não encontrou o objeto
}

export default function RegisterCostCenterDialogModalDRE({ categories, setCategories, categoryId }) {
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryItems, setCategoryItems] = useState([]);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(null); // Estado para armazenar a categoria selecionada

  useEffect(() => {
    const categoryTransfer = findCategoryById(categories, categoryId);
    setSelectedCategoryItem(categoryTransfer);
  }, [categoryId, categories]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (selectedCategory && selectedGroup) {
      // Adicione a categoria selecionada ao grupo selecionado
      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        const groupIndex = updatedCategories.findIndex((group) => group.name === selectedGroup);
        if (groupIndex !== -1) {
          updatedCategories[groupIndex].subcategories.push({ name: selectedCategory });
        }
        return updatedCategories;
      });
      setSelectedCategory(""); // Limpe a categoria selecionada
    }

    setOpen(false);
  };

  const handleGroupClick = (groupName) => {
    setSelectedGroup(groupName);
    // Filtrar as categorias correspondentes ao grupo selecionado
    const groupCategories = categoryItems.filter((category) => category.mother === groupName);
    setCategoryItems(groupCategories);
  };

  console.log(selectedCategory);
  console.log(categoryItems);

  return (
    <div>
      <Button onClick={handleClickOpen} variant="outlined" size="small" color="success" sx={{ mt: 0.5, padding: "2px 3px" }}>
        <Typography variant="button">DRE</Typography>
      </Button>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>Demonstrativo de Resultado Gerencial</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h6" component="div">
                Grupo do DRE Gerencial
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom component="div">
                Categoria: <strong>{selectedCategoryItem ? selectedCategoryItem.name : ""}</strong>
              </Typography>
              {selectedCategoryItem && (
                <div style={{ marginTop: "16px" }}>
                  <Typography variant="h6" component="div">
                    Categoria Selecionada
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      borderRadius: "4px",
                      backgroundColor: "lightgray",
                      marginTop: "8px",
                    }}
                  >
                    {selectedCategoryItem.name}
                  </div>
                </div>
              )}
              {dreItens.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleGroupClick(item)}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #ccc",
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor: selectedGroup === item ? "lightgray" : "white",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {item}
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={handleSave} variant="contained" color="success">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
