import { SvgIcon } from '@mui/material';
import React from 'react';

export default function BancoInterIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 225 225">
            <g>
                <path style={{ opacity: 1 }} fill="#ff7a00" d="M -0.5,-0.5 C 74.5,-0.5 149.5,-0.5 224.5,-0.5C 224.5,74.5 224.5,149.5 224.5,224.5C 149.5,224.5 74.5,224.5 -0.5,224.5C -0.5,149.5 -0.5,74.5 -0.5,-0.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffbf8" d="M 37.5,82.5 C 45.5052,81.3421 48.3385,84.6754 46,92.5C 37.4779,96.6563 34.1446,93.9896 36,84.5C 36.7172,83.9558 37.2172,83.2891 37.5,82.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffdfc" d="M 108.5,99.5 C 111.625,100.479 114.958,100.813 118.5,100.5C 118.5,103.167 118.5,105.833 118.5,108.5C 115.167,108.5 111.833,108.5 108.5,108.5C 108.334,115.841 108.5,123.174 109,130.5C 111.926,133.785 115.426,134.452 119.5,132.5C 119.988,135.711 119.321,138.544 117.5,141C 110.755,142.702 104.922,141.202 100,136.5C 98.5437,119.903 98.0437,103.237 98.5,86.5C 101.833,86.5 105.167,86.5 108.5,86.5C 108.5,90.8333 108.5,95.1667 108.5,99.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffcfb" d="M 54.5,99.5 C 57.8333,99.5 61.1667,99.5 64.5,99.5C 64.3379,101.527 64.5045,103.527 65,105.5C 69.84,99.7768 76.0067,97.9434 83.5,100C 87.0579,101.892 89.5579,104.725 91,108.5C 91.4998,119.161 91.6665,129.828 91.5,140.5C 88.1667,140.5 84.8333,140.5 81.5,140.5C 81.6665,131.161 81.4998,121.827 81,112.5C 77.9867,107.465 73.8201,106.298 68.5,109C 66.683,110.8 65.5163,112.966 65,115.5C 64.5003,123.827 64.3336,132.16 64.5,140.5C 61.1667,140.5 57.8333,140.5 54.5,140.5C 54.5,126.833 54.5,113.167 54.5,99.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffbf8" d="M 136.5,99.5 C 154.468,96.8087 163.468,104.475 163.5,122.5C 153.478,122.168 143.478,122.501 133.5,123.5C 135.564,132.367 140.898,135.2 149.5,132C 152.843,128.08 157.177,126.58 162.5,127.5C 161.137,134.699 156.803,139.199 149.5,141C 128.989,142.554 120.489,133.054 124,112.5C 126.353,106.315 130.52,101.982 136.5,99.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffaf7" d="M 170.5,140.5 C 170.5,127.167 170.5,113.833 170.5,100.5C 173.5,100.5 176.5,100.5 179.5,100.5C 179.337,102.857 179.503,105.19 180,107.5C 182.817,101.339 187.65,98.672 194.5,99.5C 194.5,102.5 194.5,105.5 194.5,108.5C 188.41,107.137 183.91,109.137 181,114.5C 180.5,123.16 180.334,131.827 180.5,140.5C 177.167,140.5 173.833,140.5 170.5,140.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fff6ef" d="M 29.5,99.5 C 35.1667,99.5 40.8333,99.5 46.5,99.5C 46.5,113.167 46.5,126.833 46.5,140.5C 42.8333,140.5 39.1667,140.5 35.5,140.5C 35.5,129.833 35.5,119.167 35.5,108.5C 33.5,108.5 31.5,108.5 29.5,108.5C 29.5,105.5 29.5,102.5 29.5,99.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#ffd6b8" d="M 108.5,99.5 C 112.167,99.5 115.833,99.5 119.5,99.5C 119.81,102.713 119.477,105.713 118.5,108.5C 118.5,105.833 118.5,103.167 118.5,100.5C 114.958,100.813 111.625,100.479 108.5,99.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#ffd4b4" d="M 179.5,100.5 C 176.5,100.5 173.5,100.5 170.5,100.5C 170.5,113.833 170.5,127.167 170.5,140.5C 169.504,127.01 169.171,113.344 169.5,99.5C 173.042,99.1872 176.375,99.5206 179.5,100.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#ff7803" d="M 138.5,107.5 C 141.85,107.335 145.183,107.502 148.5,108C 151.024,109.883 152.358,112.383 152.5,115.5C 146.5,115.5 140.5,115.5 134.5,115.5C 134.776,112.281 136.109,109.615 138.5,107.5 Z" />
            </g>
        </SvgIcon>
    );
}
