import React, { useState } from 'react'
import { Card, CardActions, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PollIcon from '@mui/icons-material/Poll';
import * as MuiIcons from '@mui/icons-material';

// Obtenha o ícone dinamicamente usando o nome
function IconComponent({ iconName }) {
  let aux_icon = iconName.replace(/Icon$/, '')
  const Icon = MuiIcons[aux_icon];
  
  // Verifique se o ícone existe
  if (!Icon) {
    return <span>-</span>;
  }
  return (
    <Icon sx={{ fontSize: 40 }}/>
  );
}

export default function CardIndicatorInventory({ patrimony, usestore }) {
  const [openModal, setOpenModal] = useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const productList = usestore.reposProducts.listProducts
  // Função para converter id_patrimony e stock_quanty_array em arrays de números
  const convertDataToArray = (productList) => {
    return productList.map((product) => {
      const idPatrimony = product.id_patrimony?.split(',').map((id) => parseInt(id.trim()))
      const stockQuantyArray = product.stock_quanty_array?.split(',').map((qty) => parseFloat(qty.trim()))

      return {
        ...product,
        id_patrimony: idPatrimony,
        stock_quanty_array: stockQuantyArray,
      }
    })
  }

  // Convertendo id_patrimony em arrays de números
  const productListWithArrays = convertDataToArray(productList)

  // Função para agrupar produtos por id_patrimony
  const groupProductsByPatrimonyId = (productList) => {
    const groupedProducts = {}

    productList?.forEach((product) => {
      product?.id_patrimony?.forEach((patrimonyId, index) => {
        if (!groupedProducts[patrimonyId]) {
          groupedProducts[patrimonyId] = {
            id: patrimonyId,
            products: [],
          }
        }
        groupedProducts[patrimonyId].products.push({
          productName: product.name,
          stockQuanty: product.stock_quanty_array[index],
          unit: product.unit_sigla || product.packaging_sigla,
        })
      })
    })

    return Object.values(groupedProducts)
  }

  // Filtrando os produtos com id_patrimony 1
  const groupedProducts = groupProductsByPatrimonyId(productListWithArrays, patrimony.id)

  const filteredProducts = groupedProducts.filter((item) => item.id == patrimony.id)
  // console.log(filteredProducts)

  return (
    <>
      {patrimony && (
        <Card sx={{ maxWidth: 345 }}>
          <CardHeader action={(<IconComponent iconName={patrimony.patrimony_group_icon} />)} title={patrimony.name} />

          <CardContent sx={{ height: 50 }}>
            <Typography variant="body2" color="text.secondary">
              {patrimony.group}
            </Typography>
            {patrimony.observation &&
              <Typography variant="body2" color="text.secondary">
                {patrimony.observation}
              </Typography>
            }
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites" onClick={handleModalOpen}>
              <PollIcon />
              <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1" component="div">
                Visualizar Produtos
              </Typography>
            </IconButton>
          </CardActions>
        </Card>
      )}
      <Dialog fullWidth={true} maxWidth={'sm'} open={openModal} onClose={handleModalClose}>
        <DialogTitle>Lista de Produtos</DialogTitle>
        <DialogContent>
          {filteredProducts.length == 0 ? (
            <Typography variant="subtitle1" component="div" gutterBottom>
              Não existe produtos cadastrados neste local.
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome do Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((group) => (
                    <React.Fragment key={group.id}>
                      {group.products.map((product, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {product.productName}
                          </TableCell>
                          <TableCell align="right">
                            {product.stockQuanty + " " + product.unit}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
