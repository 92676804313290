import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Category, CenterFocusStrong, Close, CurrencyExchange, PermIdentity, ShoppingCart } from '@mui/icons-material';
import FooterModal from '../../../components/Footer/FooterModal';
import CustomRadioGroup from '../../../components/Fields/CustomRadioGroup';
import FormApportChoice from '../../financial/components/Forms/FormApportChoice';
import ResourcesStepFormTabGeneralExpenses from '../Resources/ResourcesStepFormTabGeneralExpenses';
import ExpensesCostCalculator from '../Resources/ExpensesCostCalculator';
import ResourcesStepFormTabInputs from '../Resources/ResourcesStepFormTabInputs';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { green } from '@mui/material/colors';
import { useState } from 'react';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useStore from '../../../store/useStore';
import { useContext } from 'react';
import { Context } from '../../../components/Context/AuthContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ResourcesStepFormMainTabEdit({ data, obj_fn }) {
  console.log('ResourcesStepFormMainTabEdit');
  console.log(data);
  const { aux_add, setAuxAdd } = useContext(Context);
  const { idFarm } = useParams();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  //const usestore = useStore();
  //const property_id = localStorage.getItem('Property') || 1;

  const [value, setValue] = React.useState(0);
  const [showTabs, setShowTabs] = React.useState(false); // Estado para mostrar ou ocultar os tabs
  const [showApportionment, setShowApportionment] = React.useState(false); // Novo estado para controlar exibição de FormApportionment

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (showTabs == false) {
      obj_fn.setData((prevData) => ({
        ...prevData,
        resources: {
          ...prevData.resources,
          expenses: prevData.resources?.expenses || [], // Inicializa expenses se undefined
          products: prevData.resources?.products || [], // Inicializa products se undefined
        },
      }));
    }
  }, []);

  // useEffect(() => {
  //   console.log(data.resources.products.length);
  //   if (data.resources.products.length > 0 || data.resources.expenses.length > 0) {
  //     localStorage.setItem('showTabs', false);
  //   }
  //   const savedShowTabs = localStorage.getItem('showTabs');
  //   console.log(savedShowTabs);
  //   //setShowTabs(savedShowTabs === 'true');
  //   if (savedShowTabs !== null) {
  //     //console.log(savedShowTabs);
  //     setShowTabs(savedShowTabs === 'true');
  //   }
  // }, [data.resources.products, data.resources.expenses]);

  // // Função chamada para o segundo RadioGroup (mostrar FormApportionment)
  // const handleApportionmentChange = (event) => {
  //   const newValue = event.target.value === 'Sim';
  //   var project = [];

  //   if (newValue) {
  //     let aux_project = [];
  //     var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
  //     if (creationHarvestRepos.tipo_repos === 'Safras') {
  //       aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
  //     } else {
  //       aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
  //     }

  //     if (aux_project?.id) {
  //       project.push({
  //         id: aux_project.id,
  //         name: aux_project.name,
  //         id_account: aux_project.id_account,
  //         tipo_repos: aux_project.tipo_repos,
  //         val: 100,
  //       });
  //     }
  //   }
  //   obj_fn.setData((prevState) => ({
  //     ...prevState,
  //     projects_apport: project,
  //     resources: {
  //       ...prevState.resources,
  //       projects_apport: project,
  //     },
  //   }));
  //   setShowApportionment(newValue);
  // };

  const bankRepos = obj_fn.usestore.reposBank.bankRepos;

  // useEffect para monitorar mudanças em data.projects_apport
  useEffect(() => {
    var project = data?.projects_apport || data?.resources?.projects_apport || [];

    obj_fn.setData((prevState) => ({
      ...prevState, // Mantém o estado anterior
      projects_apport: project, // Atualiza projects_apport
      resources: {
        ...prevState.resources, // Preserva outros valores de resources
        projects_apport: project, // Atualiza projects_apport
        bankAccount: bankRepos,
      },
    }));
  }, [data?.projects_apport]);

  // Função chamada ao mudar a seleção (Sim/Não)
  const handleSelectChange = (event) => {
    const newValue = event.target.value === 'Sim';
    // console.log(newValue);

    setShowTabs(newValue); // Atualiza o estado de 'showTabs'
    localStorage.setItem('showTabs', newValue); // Salva a escolha no localStorage

    // var project = [];
    // if (!newValue) {
    //   // Limpa os dados de expenses, products, e projects_apport quando a escolha é "Não"
    //   obj_fn.setData((prevState) => ({
    //     ...prevState,
    //     projects_apport: project, // atualiza apport do data
    //     resources: {
    //       expenses: [],
    //       products: [],
    //       projects_apport: project,
    //     },
    //   }));
    // }
  };

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    // if (true) {
    //   iserror = true;
    // }
    return iserror;
  };

  const returnError = () => {
    return false;
  };
  // console.log('data');
  // console.log(data);

  //const [value, setValue] = useState(0); // controla a aba selecionada
  const [openDialog2, setOpenDialog2] = useState(false); // controla a visibilidade do modal

  // Função para abrir o modal
  const handleOpen = (index) => {
    setValue(index); // Define a aba selecionada
    setOpenDialog2(true); // Abre o modal
  };

  const handleClose = () => {
    setOpenDialog2(false); // Fecha o modal
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenModal = () => {
    console.log('foi');
    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleReposUpdate = (reposActivity) => {
    // atualiza os repos envolvidos com o recursos
    let creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos'));
    if (creationHarvestRepos.tipo_repos === 'Safras') {
      obj_fn.usestore.reposHarvest.fetch();
    } else {
      obj_fn.usestore.reposHarvest.fetch();
    }
    obj_fn.usestore.reposProducts.fetch();
    obj_fn.usestore.reposPatrimony.fetch();
    obj_fn.usestore.reposFinances.fetch();
    obj_fn.usestore.reposFinances.fetchT();
    obj_fn.usestore.reposCashFlow.fetch();
    obj_fn.usestore.reposCostProduction.fetch();

    obj_fn.usestore.reposActivityResources.fetch();

    let aux_repos;
    // remove o recursos do repos da atividade em questão
    if (data.activity === 'PLANTIO') {
      aux_repos = obj_fn.usestore.reposActivityPlanting.updateActivityPlanting;
    } else if (data.activity === 'APLICAÇÃO') {
      aux_repos = obj_fn.usestore.reposActivityApplications.updateActivityApplications;
    } else if (data.activity === 'COLHEITA') {
      aux_repos = obj_fn.usestore.reposActivityHarvesting.updateActivityHarvesting;
    } else if (data.activity === 'PREPARAÇÃO DO SOLO') {
      aux_repos = obj_fn.usestore.reposActivitySoilPreparation.updateActivitySoilPreparationStore;
    } else if (data.activity === 'MONITORING') {
      aux_repos = obj_fn.usestore.reposActivityMonitoring.updateActivityMonitoring;
    }
    aux_repos(data.id, 'resources', {});

    toast.success(`Recursos deletados com sucesso!`);
    setAuxAdd(aux_add + 1);
    setTimeout(() => {
      obj_fn.setData((prevData) => ({
        ...prevData,
        resources: {
          ...prevData.resources,
          expenses: [],
          products: [],
        },
      }));
      handleCloseModal();
    }, 200);
  };

  const handleConfirmDelete = async (e) => {
    // Função para deletar os custos de produção
    console.log('Deletando custos de produção');
    e.preventDefault();
    const status = 'delete';

    const header = { header: { 'Content-Type': 'application/json' } };
    let url = `/activityResources/delete/${data?.resources.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`;
    await api
      .delete(url, header)
      .then((response) => {
        if (response.data.isSuccess) {
          setTimeout(() => {
            // atualiza o repos
            handleReposUpdate();
          }, 200);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error(error);
      });
  };

  const isCompleteButtonEnabled = () => {
    // Verifica se todos os itens em `data.resources.expenses` possuem uma categoria
    return data.resources.expenses.every((expense) => expense.category);
  };
  console.log(isCompleteButtonEnabled());

  console.log(data);
  console.log('fim-ResourcesStepFormMainTabEdit');
  return (
    <Box sx={{ width: '100%' }}>
      {/* Pergunta com combobox Sim/Não */}
      {/* Usando o componente CustomRadioGroup */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 5 }}>
        <CurrencyExchange />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Editar os registros dos custos de produção:</Typography>
          </Stack>
        </Box>
      </Grid>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <CustomRadioGroup labelText="Você gostaria de editar as despesas (custo operacional) para esta atividade?" value={showTabs ? 'Sim' : 'Não'} onChange={handleSelectChange} />
      </FormControl>

      {showTabs && (
        <>
          {/* <Grid container sx={{ mt: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" color="textSecondary" fontWeight={500}>
                Ao optar por 'Não', todas as despesas abaixo serão apagadas.
              </Typography>
            </Grid>
          </Grid> */}
          <Divider sx={{}} />
          <Grid container spacing={3} sx={{ mb: 3, mt: 4 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <ShoppingCart sx={{ mr: 1 }} />
                  Despesas (Custos Operacionais)
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid container spacing={6} sx={{ ml: -2, mb: 0, mt: 2 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ ml: -3, mt: -5, mb: 5 }}>
              <Typography style={{ color: green[800] }}>
                <Typewriter tam={0} minHeight={0} text={`Adicione no carrinho os tipo de itens desejados para a ${data.activity}`} />
              </Typography>
            </Grid>
            {/* Botões de seleção */}
            <Box sx={{ width: '100%' }}>
              <FormControl display="flex" sx={{ m: 0, mb: 3, mt: -2 }}>
                {/* <Typography variant="h6" color="success">
              Itens para: {data.operationType}
            </Typography> */}
                <Grid container spacing={6} justifyContent="center" alignItems="center" sx={{ ml: -2, mb: 0, mt: 0 }}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant={value === 0 ? 'contained' : 'outlined'}
                      color="success"
                      onClick={() => handleOpen(0)} // Abre o modal e define a aba 0
                      sx={{ mx: 1 }}
                    >
                      <Category sx={{ mr: 0.5 }} />

                      <Badge
                        badgeContent={data.resources?.expenses?.length}
                        color="success"
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: 'white', // Fundo branco
                            color: 'black', // Texto preto
                            //transform: 'translateX(20px)', // Deslocamento para a direita
                            //mr: 0.5,
                          },
                        }}
                      >
                        Adicionar Depesas&nbsp;&nbsp;
                      </Badge>
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant={value === 1 ? 'contained' : 'outlined'}
                      color="success"
                      onClick={() => handleOpen(1)} // Abre o modal e define a aba 1
                      sx={{ mx: 1 }}
                    >
                      <Category sx={{ mr: 0.5 }} />
                      <Badge
                        badgeContent={data.resources?.products?.length}
                        color="success"
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: 'white', // Fundo branco
                            color: 'black', // Texto preto
                            //transform: 'translateX(20px)', // Deslocamento para a direita
                            //mr: 0.5,
                          },
                        }}
                      >
                        Adicionar Uso de Insumos&nbsp;&nbsp;
                      </Badge>
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
              <div style={{ border: '1px solid #2e7d32', padding: '16px', borderRadius: '8px' }}>
                <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
              </div>

              {/* Modal que será aberto quando o item for selecionado */}
              <Dialog open={openDialog2} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                  <>
                    {value === 0 ? <Category sx={{ mr: 0.5 }} /> : value === 1 ? <Category sx={{ mr: 0.5 }} /> : <></>} Adicionar {value === 0 ? 'Depesas' : value === 1 ? 'uso de Insumos' : ''}
                  </>
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <Close />
                </IconButton>
                <DialogContent>
                  {/* Exibe o conteúdo do TabPanel com base no valor selecionado */}
                  <TabPanel value={value} index={0} label={'Add Despesas'}>
                    <ResourcesStepFormTabGeneralExpenses data={data} obj_fn={obj_fn} />
                  </TabPanel>
                  <TabPanel value={value} index={1} label={'Add Insumos'}>
                    <ResourcesStepFormTabInputs data={data} obj_fn={obj_fn} />
                  </TabPanel>
                </DialogContent>
                <DialogActions>
                  {value == 0 && (
                    <Button onClick={handleClose} disabled={!data.resources.expenses.length || !data.resources.expenses.every((expense) => expense.category)} color="success">
                      Concluir
                    </Button>
                  )}
                  {value == 1 && (
                    <Button onClick={handleClose} disabled={!data.resources.products.length || !data.resources.products.every((product) => product.location_selected.value)} color="success">
                      Concluir
                    </Button>
                  )}
                </DialogActions>
              </Dialog>

              {/* <Dialog open={openDeleteModal} onClose={handleCloseModal} sx={{ zIndex: 100 }}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                  <DialogContentText>Tem certeza de que deseja deletar os custos de produção? Esta ação não pode ser desfeita.</DialogContentText>
                
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal} color="success">
                    Cancelar
                  </Button>
                  <Button onClick={handleConfirmDelete} color="error" autoFocus>
                    Deletar
                  </Button>
                </DialogActions>
              </Dialog> */}
            </Box>
          </Grid>

          {/* <Divider sx={{ mt: 4 }} />
          {(data.resources?.expenses?.length > 0 || data.resources?.products?.length > 0) && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <CustomRadioGroup
                labelText={
                  <>
                    Você deseja atribuir as despesas à propriedade ou distribuí-las (rateá-las)?
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 0 }}>
                      Caso opte por 'Não', a despesa será automaticamente registrada na safra atual ({data?.projects_apport[0]?.label}).
                    </Typography>
                  </>
                }
                value={showApportionment ? 'Sim' : 'Não'}
                onChange={handleApportionmentChange}
              />
            </FormControl>
          )}
          {showApportionment && (
            <Grid item xs={12} sm={12} sx={{ mt: 0, mb: 3 }}>
              <FormApportChoice data={data} obj_fn={obj_fn} />
            </Grid>
          )}
          <Divider sx={{}} /> */}
          {/* <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Optando por inserir despesas é necessário inserir as informações para continuar.
              </Typography>
            </Grid>
          </Grid> */}
        </>
      )}
      {/* Pergunta e botão para deletar os custos de produção */}
      {!showTabs && (
        <>
          <div style={{ border: '1px solid #2e7d32', padding: '16px', borderRadius: '8px' }}>
            <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
          </div>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontWeight={700}>Deseja deletar os custos de produção para esta atividade?</Typography>
            <Button variant="outlined" color="error" onClick={handleOpenModal} disabled={!data?.resources?.expenses.length && !data?.resources?.products.length}>
              Deletar Custos de Produção
            </Button>
          </Box>
        </>
      )}

      {/* {!showTabs && (
        <>
          <Grid container sx={{ mt: 3 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Se você optar por não registrar as despesas, clique em 'CONTINUAR' para prosseguir...
              </Typography>
            </Grid>
          </Grid>
        </>
      )} */}
      {/* {!openDialog2 && <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />} */}
      {/* Modal de confirmação do delete resources */}
      <Dialog open={openDeleteModal} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>Tem certeza de que deseja deletar os custos de produção? Esta ação não pode ser desfeita.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="success">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
