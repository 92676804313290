import { Box, Grid, Typography, Stack } from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import FormInputFooter from "./FormInputFooter";
import FormInputStep4PeriodBasicDatePickerLivestock from "./FormInputStep4PeriodBasicDatePickerLivestock";
import FormInputStep4PeriodRadioButton from "./FormInputStep4PeriodRadioButton";
import FormInputStep4PeriodBasicDatePickerNowLivestock from "./FormInputStep4PeriodBasicDatePickerNowLivestock";
import FormInputStep4PeriodBasicDatePickerRealizedLivestock from "./FormInputStep4PeriodBasicDatePickerRealizedLivestock";

export default function FormInputStep4Period({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  // const [showText, setShowText] = useState(false);
  // //1.1texto é acionado quando o botão radio é ativado
  // setTimeout(() => {
  //   setShowText(true);
  // }, 500);

  // para validar o button se existem as 2 validações
  const isError = () => {
    if (data.stageData != "") {
      return false;
    }
    return true;
  };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Entradas dos animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 120 }}>
            <img style={{ maxHeight: 120 }} src="/dist/image/lotedeanimaisnaareadata.png" alt="image" class="img-fluid" />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Essa atividade será realizada:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 2 }} >
          <FormInputStep4PeriodRadioButton data={data} setData={setData} />
        </Grid>
        {data.stageData == "1" && <FormInputStep4PeriodBasicDatePickerNowLivestock data={data} setData={setData} />}
        {data.stageData == "2" && <FormInputStep4PeriodBasicDatePickerRealizedLivestock data={data} setData={setData} />}
        {data.stageData == "3" && <FormInputStep4PeriodBasicDatePickerLivestock data={data} setData={setData} />}
      </Grid>
      <FormInputFooter
        data={data}
        setData={setData}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        steps={steps}
        isError={isError}
        handleClose={handleClose}
        returnError={returnError}
      />
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 4.8 }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                    Retornar
                </Button>
                <Button variant="contained" color="success" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
                    {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
                </Button>
            </Box> */}
    </>
  );
}
