import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextAddProjectStepper from "./TextAddProjectStepper";
import FlagIcon from "@mui/icons-material/Flag";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function TutorialAddProject({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  //handleClickOpen()

  return (
    <div>
      <Button variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </Button>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {
            <>
              <AccountTreeIcon /> Criação do Projeto{" "}
            </>
          }
        </DialogTitle>
        <DialogContent>
          <TextAddProjectStepper />
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
