import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Grid, Typography, Stack } from "@mui/material";

import FormWeightHeader from "./FormWeightHeader";
import FormWeightFooter from "./FormWeightFooter";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);

export default function FormWeightStep1({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    return (
      !value.$D ||
      !valueEnd.$D
    );
  };

  // aciona o botao retornar
  const returnError = () => {
    return true;
  };

  const [value, setValue] = useState(dayjs(data.date_start));
  const [valueEnd, setValueEnd] = useState(dayjs(data.date_prev_end));

  // valor para inicializar o data
  useEffect(() => {
    obj_fn.setData((prevState) => ({ ...prevState, date_start: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
  }, [value]);

  useEffect(() => {
    obj_fn.setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
  }, [valueEnd]);

  const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };
  const text_title = "Prazo para realização da atividade";
  const txt_msn = "A partir da data de hoje, sugerimos 3 dias para realização desta atividade. Se preferir, é possível personalizar.\n" +
  "Caso a Data de Término seja igual à Data de Início, a atividade será considerada como CONCLUÍDA!";

  return ( <>
    <FormWeightHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} height={'60px'} />

    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ my: 3, height: 125 }}>
          <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="d-lg-none">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Início*"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        const newValueEnd = dayjs(newValue).add(3, "day");
                        // console.log("------");
                        setValueEnd(newValueEnd);
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      renderInput={(params) => <TextField {...params} color="success" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Término*"
                      value={valueEnd}
                      onChange={(newValue) => {
                        setValueEnd(newValue);
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={new Date(value)}
                      renderInput={(params) => <TextField {...params} color="success" />}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <div className="d-none d-lg-block">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Início*"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        const newValueEnd = dayjs(newValue).add(3, "day");
                        // console.log("------");
                        // console.log(newValueEnd);
                        setValueEnd(newValueEnd);
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="success"
                          error={!value.$D}
                          helperText={
                            !value.$D && (
                              <p id="NameLivestock" className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Término*"
                      value={valueEnd}
                      onChange={(newValue) => {
                        setValueEnd(newValue);
                      }}
                      color="success"
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={new Date(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="success"
                          error={!valueEnd.$D}
                          helperText={
                            !valueEnd.$D && (
                              <p id="NameLivestock" className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: "#808080" }}>
              Data prevista para início da atividade.
            </Typography>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>

    <FormWeightFooter
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </> );
}