import React, { useState } from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import AreaMapContent from "./AreaMapContent";
import Footer from "../../components/Footer/Footer";

export default function AreaMap() {
  //const [data, setData] = useState([]);
  return (
    <>
      <AreaMapContent />
      {/* <Footer /> */}
    </>
  );
}
