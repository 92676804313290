import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Box, Container, Paper } from "@mui/material";

import FormPersonStep1 from "./FormPersonStep1";
import FormPersonStep2 from "./FormPersonStep2";
import SuccessDialog from "../../../../components/Dialog/SuccessDialog";

import { Context } from "../../../../components/Context/AuthContext";
import useStore from "../../../../store/useStore";
import api from "../../../../services/api";
// import dayjs from "dayjs";

//https://ajuda.contaazul.com/hc/pt-br/articles/8932395646605
//https://ajuda.contaazul.com/hc/pt-br/sections/115002247788-Gest%C3%A3o-de-compras

// https://www.youtube.com/watch?v=y13lB-kBbro

const address_default = {
  name: "Endereço 1",
  country: "Brasil",
  cep: "",
  address: "",
  number: "",
  state: "",
  city: "",
  neighborhood: "",
  complement: "",
};

const contact_default = {
  name: '',
  email: '',
  phoneCell: '',
  phoneCommercial: '',
  office: '', // responsibility
}

const data_success = {
  distination: "person",
  name: "Cliente/Fornecedor",
  text_success: "Acesse a lista de pessoas para verificar os Clientes/Fornecedores cadastrados.",
  distination_title: "Clientes/Fornecedores",
}

export default function MultiStepFormPerson({ handleClose, type }) {
  // console.log("MultiStepFormPerson");
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const property_id = localStorage.getItem("Property") || 1;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,

    types: {
      cliente: (type && /C|cliente/.test(type) ? true : false),
      fornecedor: (type && /F|fornecedor/.test(type) ? true : false),
      transportadora: (type && /T|transportadora/.test(type) ? true : false),
    },
    fix_type: type ? false : true,

    typePerson: "Jurídica",
    document: "",
    document_type: "CNPJ",

    name: "",
    corporateReason: "", // razão social - company name
    stateRegistration: "",
    municipalRegistration: "",
    simples_nacional: "nao",
    dateBirth: "",

    contacts: [],
    contact_err: false,
    addresses: [],
    address_err: false,

    observations: '',
  });

  if (!data.contacts.length) {
    data.contacts.push(JSON.parse(JSON.stringify(contact_default)));
  }
  if (!data.addresses.length) {
    data.addresses.push(JSON.parse(JSON.stringify(address_default)));
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (submit) => {
    if (submit !== 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  const validate = () => {
    let valid = true;
    if (!data.types.cliente && !data.types.fornecedor && !data.types.transportadora) {
      valid = false;
    } else if (!data.name || data.name.length < 5) {
      valid = false;
    } else if (data.contact_err || data.address_err) {
      valid = false;
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["FormPersonStep1", "FormPersonStep2"];

  const onSubmit = async () => {
    let formOk = validate() || false;

    if (formOk) {
      const header = { header: { "Content-Type": "application/json" } };
      await api.post("/person/save", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Pessoa registrada com sucesso!");
            usestore.reposPerson.fetch({ 'id_property': property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao cadastrar a Pessoa, tente novamente mais tarde!");
        });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
    };

    switch (step) {
      case 0:
        // identificadores da pessoa, contatos e endereços
        return <FormPersonStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // confirmação dos dados
        return <FormPersonStep2 data={data} obj_fn={obj_fn} />;
      case 2:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return "Não existente";
    }
  };

  return ( <>
    <Box sx={{ width: "100%" }}>
      {getStepContent(activeStep)}
    </Box>
  </> );
}
