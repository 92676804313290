import { useContext, useState } from 'react';
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import ChatPlan from '../components/FormPlan/ChatPlan';
import { Span } from '../../../components/Typography';
import IAIcon from '../../../components/Icons/chat/IAIcon';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import SuccessTarget from './SuccessTarget/SuccessTarget';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore';
import { Context } from '../../../components/Context/AuthContext';
import FormGoalTargetEditCost from './FormGoalTargetEditCost';
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore';
import SimpleDateProvider from '../../../components/Fields/SimpleDateProvider';
import SimpleDateProviderData from '../../../components/Fields/SimpleDateProviderData';

const UnitOptions = [
  { label: 'sc 30kg', value: 'sc 30kg' },
  { label: 'sc 40kg', value: 'sc 40kg' },
  { label: 'sc 50kg', value: 'sc 50kg' },
  { label: 'sc 60kg', value: 'sc 60kg' },
  { label: 't', value: 't' },
  { label: 'kg', value: 'kg' },
  { label: 'g', value: 'g' },
  { label: 'mg', value: 'mg' },
  { label: 'un', value: 'unidade' },
];

const ValueCost = [
  { label: 'R$', value: 'pt-BR' },
  { label: '$', value: 'en-US' },
];

const MeasureOptions = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

export default function FormGoalTargetEdit({ handleClose, target, area }) {
  const { idFarm, id } = useParams();
  // instancia os repos planning
  // console.log(planTargetReposFilterIdAreaMap)
  // const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  // console.log(reposTargetPlanning);
  // const planTargetRepos = reposTargetPlanning.getPlanningStore("id", planTargetReposFilterIdAreaMap.id);
  // console.log(planTargetRepos[0]);
  // const valueProductivity = planTargetRepos[0].expectedYield / planTargetRepos[0].area;
  const valueProductivity = 0;
  const [plan, setPlan] = useState(target);
  console.log('pan-area');
  console.log(plan);
  console.log(area);
  // conferir
  const activeDataCost = area?.dataCost.filter((cost) => cost.id_target == target.id);
  console.log('activeDataCost');
  console.log(activeDataCost);
  const [cost, setCost] = useState(activeDataCost.length > 0 ? area?.dataCost[0] : '');
  const [productivity, setProductivity] = useState(valueProductivity.toFixed(2));

  console.log('FormGoalTarget');
  console.log(plan);
  console.log(cost);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  const reposPlanHarvest = useHarvestTargetCostReposStore((state) => state);

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  // const dateObject = new Date(plan.date_start);
  // const formattedDate_date_start = format(dateObject, "yyyy-MM-dd");
  // const newdateObject = new Date(plan.date_prev_end);
  // const formattedDate_date_prev_end = format(newdateObject, "yyyy-MM-dd");

  const [data, setData] = useState({
    id: target.id,
    id_user: userCurrent.id,
    id_property: target.id_property,
    id_harvest: target.id_harvest,
    cultivation_name: target.cultivation_name,
    label: target.cultivation_name,
    date_start: format(new Date(target.date_start), 'yyyy-MM-dd'),
    date_prev_end: format(new Date(target.date_prev_end), 'yyyy-MM-dd'),
    //date_start: formattedDate_date_start,
    // date_prev_end: formattedDate_date_prev_end,
    expectedYield: target.expectedYield,
    unit: target.unit,
    cost: target.cost,
    valueCost: target.valueCost,
    unitCost: target.unitCost,
    area: target.area,
    measure: target.measure,
    observation: target.observation,
    isDeleted: target.isDeleted,
    data_cost: {
      id: cost.id,
      insert: true,
      id_user: userCurrent.id,
      id_property: cost.id_property,
      id_harvest: cost.id,
      id_target: cost.id_target,
      expectedProduction: target.expectedYield,
      unit: cost.unit,
      //saleUnitValue: formatNumber(planRepos.cost) || "",
      saleUnitValue: target.cost,
      valueSale: target.valueSale,
      unitSale: target.unitSale,
      percentageProfit: parseInt(cost.percentageProfit),
      // area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
      area: target.area || '',
      measure: target.measure,
      id_areamap: target.id,
      observation: cost.observation,
      isDeleted: cost.isDeleted,
    },
  });

  console.log(data);

  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate, field) => {
    console.log(newDate);
    setData({ ...data, [field]: newDate });
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.startDate || data.startDate.trim() === '') {
      return true;
    }
    if (!data.expectedYield || data.expectedYield.trim() === '') {
      return true;
    }
    if (!data.area || data.area.trim() === '') {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return true;
  };
  const color = '#2e7d32';

  const { aux_add, setAuxAdd } = useContext(Context);

  const onSubmit = async (e) => {
    //e.preventDefault();
    // console.log('onSubmit');
    // console.log(data);
    setFormError({});
    let errors = {};
    let formOk = true;

    // if (data.nameBatch.length < 5) {
    //   errors.nameBatch = true;
    //   formOk = false;
    //   // } else if (property.address.length < 3) {
    // } else if (data.areas.length === 0) {
    //   errors.area = true;
    //   formOk = false;
    // }

    const header = { header: { 'Content-Type': 'application/json' } };

    if (formOk) {
      // console.log('onSubmit-formOk');
      const res = await api
        .put('/planning/updatetarget', data, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            setIsLoading(true);
            toast.success('Meta criada com sucesso!');
            reposTargetPlanning.fetch({ id_farm: idFarm }).then((res) => {
              // setTimeout(() => {
              // }, 200);
            });
            reposPlanHarvest.fetch({ id_farm: idFarm }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios');
      setFormError(errors);
      //return false;
    }
  };

  return (
    <>
      {isLoading && <SuccessTarget handleClose={handleClose} plan={data} />}
      <Container maxWidth="sm">
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}
          <Grid container sx={{ mt: 1, mb: 6 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }}>
              <Typography style={{ color: '#2e7d32' }}>
                <Typewriter tam={0} minHeight={0} text="Vamos realizar a edição da meta de produção." />
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SimpleDateProviderData onChangeDate={handleDateChange} label={'Data de Início'} date={data.date_start} field={'date_start'} />
                {/* <TextField name="date_start" id="date_start" label="Data de Início" type="date" required fullWidth value={data.date_start} onChange={handleChange} /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SimpleDateProviderData onChangeDate={handleDateChange} label={'Data de Término'} date={data.date_prev_end} field={'date_prev_end'} />
                {/* <TextField name="date_prev_end" id="date_prev_end" label="Data de Término" type="date" required fullWidth value={data.date_prev_end} onChange={handleChange} /> */}
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="expectedYield"
                  variant="filled"
                  color="success"
                  focused
                  label="Produção Esperada"
                  type="number"
                  required
                  fullWidth
                  value={data.expectedYield}
                  onChange={handleChange}
                  // error={formError.expectedYield && data.expectedYield === ""}
                  //helperText={formError.expectedYield && data.expectedYield === "" ? "Por favor, preencha o campo obrigatório!" : "Estime o tamanho da sua produção."}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unit" fullWidth label="Unidade" value={data.unit} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                    <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                    <Select name="valueCost" fullWidth label="Moeda" value={data.valueCost} onChange={handleChange}>
                      {ValueCost.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="cost"
                  variant="filled"
                  color="success"
                  focused
                  label="Valor da cotação da cultura"
                  type="number"
                  required
                  fullWidth
                  value={data.cost}
                  onChange={handleChange}
                  error={formError.cost && data.cost === ''}
                  helperText={formError.cost && data.cost === '' ? 'Por favor, preencha o campo obrigatório!' : 'Defina o valor atual da cotação da cultura. Ex.: R$ 54,00 sc 60kg '}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unitCost" fullWidth label="Unidade" value={data.unitCost} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  name="area"
                  variant="standard"
                  label="Área"
                  type="number"
                  disabled
                  required
                  fullWidth
                  value={data.area}
                  onChange={handleChange}
                  error={formError.area && data.area === ''}
                  helperText={formError.area && data.area === '' ? 'Por favor, preencha o campo obrigatório!' : 'Estime a área da sua produção.'}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: 'center' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Medida</InputLabel>
                    <Select disabled autoFocus name="measure" fullWidth label="Unidade" value={data.measure} onChange={handleChange}>
                      {MeasureOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            {activeDataCost.length > 0 && (
              <Grid item xs={12} sm={12} sx={{ mt: 4 }}>
                <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                  Custo da produção
                </Typography>
                <FormGoalTargetEditCost handleClose={handleClose} target={target} data={data} setData={setData} />
              </Grid>
            )}
            {/* <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                Ex.: Produção de 2.000 sacas de 60 kg em uma área de 50 hectares de cultivo.
              </Typography>
            </Grid> */}
            {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              Gerar Planejamento
            </Button> */}
            <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                    <Span>cancelar</Span>
                  </Button>
                </Box>
                {data && (
                  <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                      Salvar
                    </Button>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
          </Box>
        </Paper>
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
      {/* <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=77"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=2"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
// banco:
//https://www.cepea.esalq.usp.br/br/consultas-ao-banco-de-dados-do-site.aspx
//https://cepea.esalq.usp.br/br/widget.aspx
// site com grafico
//https://web.graodireto.com.br/#/quotation
//https://www.graodireto.com.br/#who-buys
