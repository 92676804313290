import { Box, Container, LinearProgress, Paper } from "@mui/material";
import LinearDeterminate from "../../../components/Loader/LinearDeterminate";
import LoagingLinear from "../../../components/Loader/LoagingLinear";

export default function LoadingBdLogin() {
    //INICIANDO O SISTEMA
    return (
        <>
            <div>
                <div style={{ marginBottom: '-10px' }}>
                    <LoagingLinear />
                </div>
                <Box sx={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                        <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img
                                    style={{ height: 80 }}
                                    src="/dist/image/icone-fazenda-premium-start.png"
                                    alt="CPT"
                                    className="brand-image center animation__fadeIn"
                                />
                                <Box sx={{ mt: 6, fontSize: '1.2rem' }} >
                                    INICIANDO O SISTEMA
                                    {/* <CircularProgressWithLabel value={progress} /> */}
                                    {/* <LinearWithValueLabel value={progress} /> */}
                                </Box>

                            </Box>
                            {/* <Box sx={{ margin: '0 auto', width: '80%', mt: 2 }}>
                                <LinearProgress color="success" />
                            </Box> */}
                        </Paper>
                    </Container>
                </Box>

            </div>
        </>
    );
}
