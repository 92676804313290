//*********Corpo do documento **********/

import { bodyListPatrimonyProperty } from '../bodyProperty/bodyListPatrimonyProperty';
import { footerBasic } from '../footer/footerBasic';
import { headerReportDate } from '../header/headerReportDate';
import { titleProperty } from '../title/titleProperty';

let dataProperty = [];

// Carregar a propriedade do localStorage
let resposPropertyString = localStorage.getItem('reposProperty');
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString);
  dataProperty = resposProperty.state.listProperty[0];
  console.log('imageProperty1');
  console.log(dataProperty);
} else {
  console.log('Nenhum dado encontrado em resposProperty');
}

export const documento = {
  pageSize: 'A4',
  //pageOrientation: 'landscape',
  header: headerReportDate,
  footer: footerBasic,
  content: [
    ...titleProperty(dataProperty),
    // lineHeader,
    //bodyListCreationHarvest.content,
    ...bodyListPatrimonyProperty.content,

    // ... outras partes do conteúdo, se necessário
  ],
  styles: {
    //lineStyle: lineStyle,
    //reportNameStyle: reportNameStyle,
    //reportDateStyle: reportDateStyle,
  },
  // ... outras configurações do documento
};

//tutorial
//http://pdfmake.org/playground.html
//CriaCorpoDocumento
//https://dev.to/taikio/criando-documentos-pdf-com-reactjs-4lkl
//https://www.papodigital.net.br/blog/criando-documentos-pdf-com-reactjs
