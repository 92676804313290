import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import { AppBar, Divider, Toolbar, Box, Fab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapViewMonitoring from './MapViewMonitoring';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import html2canvas from 'html2canvas';
import { base64ToBlob } from '../../../../../../components/Image/base64ToBlob';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function ButtonMapViewMonitoring({ data, setData, setImage, setLoading, areaId, inputId }) {
  const [open, setOpen] = useState(false);
  // const [path, setPath] = useState([]);

  const [LoadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setLoadingButton(false);
  }, []);

  // // controla o estado de desenho ou formSave
  // const handleSelection = (e) => {
  //   setLoading(true)
  //   console.log('save')
  //   console.log(data)
  //   setLoadingButton(true)
  //   console.log(data)
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setPath([]);
    setOpen(false);
    // local parar salvar area
    //data.areaTranferId = { id_area: '', name: '', image: '' }
    // setDrawM(true);
    // setDrawF(false);
    //setImage('')
    // setTextButton('Próximo');
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("entrou no submit");
  // }

  const captureImage = () => {
    // console.log('captureImage');
    var element = document.getElementById('map');
    // console.log(element);
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      var base64image = canvas.toDataURL('image/jpeg');
      //setDrawM(false);
      setImage(base64image);

      const blobAreaTranferImage = base64ToBlob(base64image, 'image/jpeg');
      // console.log(blobAreaTranferImage); // O objeto Blob resultante

      setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, areaTranferImageBob: blobAreaTranferImage } : area)),
      }));

      // setData({ ...data, areaTranferImage: base64image }) // Atualiza o estado data com a imagem carregada
      setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, areaTranferImage: base64image } : area)),
      }));
      // setDrawF(true);
    });
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  // controla o estado de desenho ou formSave
  const handleSelection = (e) => {
    //setLoading(true)
    // console.log('save');
    //console.log(data)
    setLoadingButton(true);
    setTimeout(() => {
      captureImage();
    }, 500);

    // console.log(data)
  };

  return (
    <div className="row" style={{ textAlign: 'center' }}>
      <div className="col">
        <Button size="small" variant="contained" color="success" onClick={handleClickOpen}>
          Registrar monitoramento
          {/* <SwapHorizIcon sx={{ ml: 1 }} /> */}
        </Button>
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'}>
          <section className="content " style={{ overflowY: 'hidden', paddingBottom: 80 }}>
            <div className="container-fluid ">
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Monitoramento
              </DialogTitle>

              <Fab sx={{ position: 'fixed', top: 5, right: 10, backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                <CloseIcon sx={{ mr: 0 }} />
              </Fab>
              <Divider />
              <DialogContent style={{ padding: '2px' }}>
                {/* {!open ? <></> : <MapView data={data} setData={setData} container={handleClose} setImage={setImage} />} */}
                {!open ? <></> : <MapViewMonitoring areaId={areaId} data={data} setData={setData} container={handleClose} setImage={setImage} inputId={inputId} />}
              </DialogContent>
              <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Fab sx={{ backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                      <CloseIcon sx={{ mr: 1 }} /> Cancelar
                    </Fab>
                  </Box>
                  <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <Grid item xs={12} md={12}>
                      <div style={{ position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 10, borderRadius: 5 }}>
                        <Grid container spacing={1}>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'rgba(0, 255, 0, 0.8)', marginRight: 5 }}></div>
                            <span style={{ fontSize: 12, color: 'black' }}>0 - Ausente</span>
                          </Grid>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 255, 0, 0.8)', marginRight: 5 }}></div>
                            <span style={{ fontSize: 12, color: 'black' }}>1 - Baixo</span>
                          </Grid>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 165, 0, 0.8)', marginRight: 5 }}></div>
                            <span style={{ fontSize: 12, color: 'black' }}>2 - Médio</span>
                          </Grid>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 105, 97, 0.8)', marginRight: 5 }}></div>
                            <span style={{ fontSize: 12, color: 'black' }}>3 - Alto</span>
                          </Grid>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 0, 0, 0.8)', marginRight: 5 }}></div>
                            <span style={{ fontSize: 12, color: 'black' }}>4 - Muito alto</span>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Box>
                  <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <Button sx={{ width: 120, ml: 1, ml: 'auto' }} color="success" variant="contained" type="submit" onClick={(e) => handleSelection(e)}>
                      {LoadingButton ? (
                        <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
                      ) : (
                        <>
                          <SaveAltIcon sx={{ mr: 1 }} />
                          Salvar
                        </>
                      )}
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </div>
          </section>
        </Dialog>
      </div>
    </div>
  );
}
