import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppBar, Avatar, Card, CardActionArea, CardActions, CardMedia, Grid, Paper, Stack, Toolbar, Typography } from "@mui/material";
import { Span } from "../../../../../components/Typography";
import ConfirmListAreasMapsLivestockImagens from "../../../../livestock/components/addLivestock/ConfirmListAreasMapsLivestockImagens";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import FormInputFooter from "../Input/FormInputFooter";
import { useEffect } from "react";
import { green, orange } from "@mui/material/colors";
import FormInputStep6ConfirmRadioButtonProgress from '../Input/FormInputStep6ConfirmRadioButtonProgress'
import { LocationOn } from "@mui/icons-material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function ConfirmStepTransfer({ onSubmit, data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {


  const filterArealist = data.areas.filter(item => item.id === data.id_areamap);
  console.log(filterArealist)

  const handleSubmit = () => {
    console.log("onSubmit")
    onSubmit()
    //handleNext();
  };

  //**user_name: activityInput.user_name,**

  useEffect(() => {
    console.log(data)
    const dto = {
      id_user: data.id_user,
      id_property: data.id_property,
      id_livestock: data.id_livestock,
      id_group: data.id_group,
      type_activity: data.type_activity,
      id_activity_mp: data.lote.activity.id,
      id_areamap: data.id_areamap,
      id_animal: "",
      id_lote: data.lote.activity.id_lote,
      numberAnimals: data.lote.activity.number_animals,
      name_activity: "TRANSFERENCIA",
      // corrigir 
      areamap_use: filterArealist[0].area,
      nameArea: data.areaTranferId.name,
      nameBatch: data.lote.label,
      progress: data.progress,
      planning: data.planning,
      isDeleted: data.isDeleted,
      // data inicial da trasnferência
      date_start: data.date_start,
      // data final da trasnferência
      data_prev_end: data.date_prev_exit,
      // a data date_end será a data exit do último manejo de pastagem
      // data que o usuário definiu que foi realizado a transfência.. que pode ser a data_prev_end
      date_end: "",
      // a data date_end será a data exit do último manejo e será a data start do novo manejo de pastagem
      // a date_end 
      data_prev_exit_mp: "",
      //data_prev_exit: data.lote.activity.date_prev_exit,
      //date_start: data.lote.activity.date_start,
      //date_start: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      //date_exit: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    }
    setData((prevState) => ({ ...prevState, dto: dto }));
  }, [])


  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  const isError = () => {

    return false;
  };

  console.log(data)


  return (
    <>
      <Grid item container alignItems="center" justifyContent="center" >
        <Grid item sx={{ mt: 1, height: 120 }} >
          <img
            style={{ maxHeight: 120 }}
            src="/dist/image/entrada.png"
            alt="image"
            class="img-fluid"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{}} >
        <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
          <FormInputStep6ConfirmRadioButtonProgress data={data} setData={setData} />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} md={12}>
          <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
            <ListItemText primary="Categoria:" secondary={
              <Paper sx={{ p: 1, backgroundColor: green[50] }}>
                <Typography noWrap={false} sx={{ wordBreak: 'break-all' }} variant='h6'>
                  {data.lote.fase_name.toUpperCase()}
                </Typography>
              </Paper>}
            />
          </ListItem>

        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Nome do Lote" secondary={data.lote.label} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Número de Animais" secondary={data.lote.total_animals} />
          </ListItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <ListItem>

            <ListItemText primary="Data da entrada no lote" secondary={dayjs(data.lote.date_entry).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <ListItem>
            <ListItemText primary="Data de Término" secondary={dayjs(data.date_prev_exit).format('DD/MM/YYYY')} />
          </ListItem> */}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} sx={{ mt: 1, mb: 4 }}>
        {/* <Grid item xs={12} md={12}>
          <ListItemText primary="Transferência dos animais" />
           <ConfirmListAreasMapsLivestockImagens data={data} />
      </Grid> */}
        <Grid container spacing={3}>
          {/* {data.areas.map((area) => */}
          <Grid item xs={12} sm={6} md={6}>
            <Stack spacing={0} sx={{ ml: 1, alignItems: 'center', width: 400 }}>
              <Grid container spacing={1} sx={{ width: 400 }}>
                <Grid item>
                  <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
                    <LocationOn />
                  </Avatar>
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography fontWeight={700}>Tranferência do lote</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Origem: <strong>{data.lote.area.name}</strong> <ArrowRightIcon sx={{ mt: -0.2, color: green[500] }} /> Destino: <strong>{data.areaTranferId.name == "" ? "Clique na área desejada" : data.areaTranferId.name}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ m: 1 }}>
              <CardMedia component="img" height="250" image={data.areaTranferId.image} alt="Imagem" />
              <CardActionArea>
                <CardActions >
                  <p>Área atual do lote: <strong>{data.areaTranferId.name}</strong> </p>
                  {/* <p>Área utilizada:</p> */}
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
          {/* )} */}
        </Grid>

      </Grid >


      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button color="success" variant="contained" type="submit" onClick={handleSubmit}>
              Confirmar
            </Button>
          </Box>

        </Toolbar>
      </AppBar>

    </>
  );
}
