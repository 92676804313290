import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import CardReports from './CardReports';

export default function ListReportsPropertyCard() {
  //const data = [1, 2, 3, 4, 5]
  const data = [
    {
      type: 'property',
      group: 'Geral',
      doc: 'ListCreationHarvest',
      title: 'Lista <span style="font-size:16px;">das Criações e Safras</span>',
      text: 'Lista de todas as suas <b>criações e safras</b>.',
      icon: 'WidgetsIcon',
    },

    // { type: 'property', group: 'Geral', doc: 'SolicitaçõesProperty', title: 'Solicitações', text: 'Lista de todas as <b>Solicitações</b>.', icon: 'WidgetsIcon' },
    //{ type: 'property', group: 'Financeiro', doc: 'ContasProperty', title: 'Contas', text: 'Lista de todas as <b>contas a pagar</b>.', icon: 'WidgetsIcon' },
    // {
    //   type: 'property',
    //   group: 'Financeiro',
    //   doc: 'CustosProperty',
    //   title: 'Custos <span style="font-size:16px;">de produção</span>',
    //   text: 'Lista de <b>custos</b> agrupadas em categoria e recursos.',
    //   icon: 'WidgetsIcon',
    // },
    //{ type: 'property', group: 'Financeiro', doc: 'RentabilidadeProperty', title: 'Rentabilidade', text: 'Lista toda a produção, custos e lucro da propriedade', icon: 'WidgetsIcon' },

    {
      type: 'property',
      group: 'Estoque',
      doc: 'StockProperty',
      title: 'Estoque',
      text: 'Lista de todos <b>os itens</b> em seus locais de estoque com as respectivas quantidades e valores.',
      icon: 'WidgetsIcon',
    },
    { type: 'property', group: 'Estoque', doc: 'PatrimonyProperty', title: 'Patrimônio', text: 'Lista todos os <b>patrimônios</b> da propriedade.', icon: 'WidgetsIcon' },
  ];

  // Agrupando os dados por 'group'
  const groupedData = data.reduce((groups, item) => {
    if (!groups[item.group]) {
      groups[item.group] = [];
    }
    groups[item.group].push(item);
    return groups;
  }, {});

  console.log(groupedData);

  return (
    <>
      <div>
        {Object.keys(groupedData).map((group) => (
          <div key={group}>
            <Typography sx={{ mt: 2, ml: 1 }} color="#808080" gutterBottom variant="subtitle1" component="div">
              {group}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {groupedData[group].map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.doc}>
                  <CardReports type={item.type} doc={item.doc} title={item.title} text={item.text} icon={item.icon} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
}
