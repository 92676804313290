import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { checkv3 } from './archive/index'
import { useEffect } from 'react';
import { Box } from '@mui/material';

const Checkv3 = () => {
    const [animationOptions, setAnimationOptions] = useState({});

    useEffect(() => {
        setAnimationOptions({
            animationData: checkv3,
            loop: false,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            },
            // Controla a velocidade da animação
            // Quanto menor o valor, mais lenta será a animação
            speed: 0.5

        });
    }, []);

    return (
        <Box>
            <Lottie options={animationOptions} height={300} width={300} />
        </Box>
    );
};

export default Checkv3;
