import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { Checkbox, Skeleton, Box, Typography, Paper } from "@mui/material";
import { Female, Male} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import GenericValueField from "../../../../../components/Fields/GenericValueField";

//função descendingComparator() para ajudar na classificação descendente das linhas da tabela
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//função getComparator() retorna uma função de comparação com base na direção da classificação.
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// cabeçalhos
const headCells = [
  {
    id: "animalIdentifier",
    align: "center",
    disablePadding: true,
    label: "Animais",
  },
  {
    id: "sexoAnimal",
    align: "center",
    disablePadding: false,
    label: "Sexo",
  },
  {
    id: "currentWeight",
    align: "center",
    disablePadding: false,
    label: "Peso atual",
  },
  {
    id: "medicine",
    align: "center",
    disablePadding: false,
    label: "Medicamento",
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, confirm } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={'MedicinesAnimals'}>
        {confirm ? <></> : 
        <TableCell padding="checkbox" key={'tc_head_cb'} >
          <Checkbox
            key={'c_head_cb'}
            color="success"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Selecionar todos",
            }} 
          />
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell key={'h_' + headCell.id} align={headCell.align} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} >
            <TableSortLabel 
              key={'hs_' + headCell.id}
              active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} 
              onClick={createSortHandler(headCell.id)}>
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  confirm: PropTypes.any
};

export default function TableMedicinesAnimals({ data_l, obj_fn, confirm, edicao, setError }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("animalIdentifier");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [changed, setChanged] = useState(new Date().getTime());
  // variavel para atualizar a tabela após edição dos dados da atividade

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRowsMedicinesAnimals] = useState([]);

  const handleRows = () => {
    let rows_aw = [];
    let rows_selected = [];
    let aux_table = data_l.list_animals;
    let data_row;
    let firs_handle = Object.keys(data_l.table_selected).length;

    if (confirm || edicao) {
      data_l.table_confirm = data_l.list_animals.filter((animal) => {
        if (data_l.table_selected[animal.id]) {
          return animal;
        }
      });

      aux_table = data_l.table_confirm;
    }
    for (const animal of aux_table) {
      data_row = { 
        id: animal.id,
        id_lote: data_l.id,
        identifier: animal.identifier,
        sexo: animal.sexo,
        weight: animal.weight || 0,
        medicine: animal.medicine || 0,
      }
      rows_aw.push(data_row);

      // primeiro carregamento da tabela - seleciona todos os animais
      if (!firs_handle) {
        data_l.table_selected[animal.id] = data_row;
        data_l.num_selecteds += (animal.id === 0 ? data_l.num_animals : 1);
      }

      if (data_l.table_selected[animal.id]) {
        rows_selected.push(animal.id);
      }
    }

    setRowsMedicinesAnimals(rows_aw);
    setSelected(rows_selected);
    setIsLoadingTable(false);
  };

  useEffect(() => {
    console.log('refresh TableMedicinesAnimals');
    setIsLoadingTable(true);
    setSelected([]);
    setTimeout(() => {
      handleRows();
    }, 200);

  }, [data_l.list_animals]);

  const handleChange = (event) => {
    console.log("handleChange");
    let tar_list = Number(event.target.id.replace(/.*medicine_(.*)/, '$1')) + (page * rowsPerPage);
    let medicine = parseFloat(event.target.value);

    if (medicine !== data_l.list_animals[tar_list].medicine) {
      data_l.list_animals[tar_list].medicine = medicine;
      rows[tar_list].medicine = medicine;
      if (data_l.table_selected[rows[tar_list].id]) {
        data_l.table_selected[rows[tar_list].id].medicine = medicine;
      }
      setChanged(new Date().getTime());

      if (edicao) {
        edicao(new Date().getTime());
      }
      if (obj_fn.setUpdated) {
        obj_fn.setUpdated(1);
        obj_fn.setData(prevState => ({ ...prevState, edit_prod: true }));
      }
    }
    //obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => {
        data_l.num_selecteds += (row.id === 0 ? data_l.num_animals : 1);

        data_l.table_selected[row.id] = { 
          id: row.id,
          id_lote: row.id_lote,
          identifier: row.identifier,
          sexo: row.sexo,
          weight: row.weight || 0,
          medicine: row.medicine || 0,
        }
        return row;
      });
      setSelected(newSelected);
      handleRows();
    } else {
      data_l.table_selected = {};
      data_l.num_selecteds = 0;
      setSelected([]);
    }
    setError(Object.keys(data_l.table_selected).length === 0);
    if (edicao) {
      edicao(new Date().getTime());
    }

    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, edit_prod: true }));
    }
  };

  const handleClick = (event, row) => {
    var target_parent_name = window.jQuery(event.target).parentsUntil('table').parent().eq(0).attr('name') || '';
    if (target_parent_name !== "tableMedicinesAnimals" || event.target.type !== 'checkbox') {
      return;
    }

    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);

    if (selectedIndex === -1) {
      data_l.table_selected[row.id] = row;
      data_l.num_selecteds += (row.id === 0 ? data_l.num_animals : 1);

    } else {
      delete data_l.table_selected[row.id];
      data_l.num_selecteds -= (row.id === 0 ? data_l.num_animals : 1);
    }

    setError(Object.keys(data_l.table_selected).length === 0);
    if (edicao) {
      edicao(new Date().getTime());
    }
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, edit_prod: true }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (<>
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table  aria-labelledby="Tabela Medicamentos dos Animais" size={dense ? "small" : "medium"} name="tableMedicinesAnimals">
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} confirm={(confirm)} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage + (confirm || edicao ? rows.length : 0))
                .map((row, index) => {
                  const isItemSelected = (confirm ? false : isSelected(row.id));
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //data_l.table_selected[row.id] = { 'id': row.id, 'identifier': row.identifier, 'sexo': row.sexo };
                  return ( <>
                    {isLoadingTable === true ? (<>
                      <TableRow key={"void"}>
                        <TableCell key={'tc_void_1'} padding="checkbox" height={67}><Skeleton variant="rectangular" height={33} /></TableCell>
                        <TableCell key={'tc_void_2'} component="th" id={labelId} scope="row" padding="none" height={67}><Skeleton variant="rectangular" height={34} /></TableCell>
                        <TableCell key={'tc_void_3'} align="right" height={67}><Skeleton variant="rectangular" height={34} /></TableCell>
                        <TableCell key={'tc_void_4'} align="right" height={67}><Skeleton variant="rectangular" height={34} /></TableCell>
                        <TableCell key={'tc_void_5'} align="right" height={67}><Skeleton variant="rectangular" height={34} /></TableCell>
                      </TableRow>
                    </> ) : ( <>
                      <TableRow
                        key={row.identifier + "_medicine_" + index}
                        hover
                        onClick={(event) => handleClick(event, row )}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={index}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        {confirm ? <></> : 
                        <TableCell padding="checkbox" key={'cb_table_' + row.id}>
                          <Checkbox
                            key={'cbx_' + row.id}
                            color="success"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>}
                        <TableCell key={'tc_identifier_' + row.id} align="left">{row.identifier}</TableCell>
                        <TableCell key={'tc_sexo_' + row.id} align="center">{row.sexo !== '-' ? row.sexo === 'Macho' ? <Male color="primary" /> : <Female color="secondary" /> : <></>} {row.sexo}</TableCell>
                        <TableCell key={'tc_weight_' + row.id} align="center">{row.weight} ({row.weight_unit || "kg"})</TableCell>
                        {confirm ? <TableCell key={'tc_medicine_' + row.id} align="center">{row.medicine} ({row.product?.unit_sigla || "un"})</TableCell> : 
                          <TableCell key={'tc_w_' + row.id} align="right" width={150}>
                            {/* <TextField
                              key={row.id + "_medicine_" + index}
                              id={row.id + "_medicine_" + index}
                              name={row.id + "_medicine"}
                              type="number"
                              color="success"
                              variant="standard"
                              fullWidth
                              value={row.medicine}
                              onChange={handleChange}
                              inputProps={{
                                style: { textAlign: "right" },
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                maxLength: 12,
                                min: 0,
                              }}
                            /> */}
                            <GenericValueField
                              handleChange={handleChange}
                              options={{
                                key: row.id + '_medicine_' + index,
                                id: row.id + '_medicine_' + index,
                                name: row.id + '_medicine',
                                value: row.medicine,
                                variant:"standard",
                                height: '30px',
                              }}
                            />
                          </TableCell>
                        }
                      </TableRow>
                    </> )}
                  </> );
                })}
              {emptyRows > 0 && (
                <TableRow key={"emptyRows"} style={{ height: (dense ? 33 : 53) * emptyRows }} >
                  <TableCell key={"emptyRows_tc"} colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {confirm || edicao ? <></> : <TablePagination
          labelRowsPerPage={"Linhas por página"}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> }
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" /> */}

      <Typography variant="standard" display="block" color="error" sx={{ mt: 1, ml: 1, mb: 3, display: ( Object.keys(data_l.table_selected).length === 0 ? 'flex' : 'none' ) }} >
        * Selecione pelo menos 1 animal! 
      </Typography>
    </Box>
  </> );
}
