import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AccountBalance } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Context } from "../../components/Context/AuthContext";
import usePatrimonyReposStore from "../../store/usePatrimonyReposStore";

export default function RegisterTypePatrimony({
  isOpenType,
  setIsOpenType,
  patrimonyGroupName,
  patrimonyGroupID,
  setUpdateCounter,
}) {
  const [data, setData] = useState({
    name: "",
    name_nf: "",
    patrimony_group_id: patrimonyGroupID,
  });

  const { aux_add, setAuxAdd } = React.useContext(Context);
  const reposPatrimony = usePatrimonyReposStore((state) => state);
  var id_farm = localStorage.getItem("Property") || 1;

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    const name_nf = value
      .replace(/[^\w\s]/gi, "")
      .toLowerCase()
      .replace(/\s+/g, "_");

    setData((prevData) => ({
      ...prevData,
      [name]: value,
      name_nf: name_nf,
    }));
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpenType(open);
  };

  const validate = () => {
    var valid = true;
    var msg = 'Por favor, preencha os campos obrigatórios!';

    if (!data.name) {
      valid = false;
      msg = 'Informe o nome do patrimônio';
    } else if (data.name.length < 5) {
      valid = false;
      msg = 'Nome do patrimônio deve ter no mínimo 5 letras';
    } else {
      var aux_name = reposPatrimony.getPatrimonyGroupStore('name', data.name);
      if (aux_name.length > 0) {
        valid = false;
        msg = 'Já existe um grupo de patrimônio com o nome informado';
      }
    }

    return [valid, msg];
  };

  const handleSubmitType = async () => {
    const dataToSubmit = {
      name: data.name,
      name_nf: data.name_nf,
      patrimony_group_id: patrimonyGroupID,
    };

    let formOk = validate();

    if (formOk[0]) {
      const header = { header: { "Content-Type": "application/json" } };
      await api.post("patrimony/patrimony-type", dataToSubmit, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success("Novo tipo de patrimônio criado com sucesso!");
            //setUpdateCounter((prevCounter) => prevCounter + 1);
            reposPatrimony.fetch({ id_farm: id_farm }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                setIsOpenType(false);
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao cadastrar novo tipo de patrimônio, tente novamente mais tarde.");
        });
    } else {
      toast.error(formOk[1]);
    }
  };

  const handleSubmit = () => {
    handleSubmitType();
  };

  const drawerContent = (
    <Box
      sx={{
        width: {
          xs: 330, // Largura em dispositivos móveis
          sm: 500, // Largura em desktops
        },
      }}
      role="presentation"
    >
      <Container component="main" maxWidth="sm">
        <Paper variant="" sx={{ my: { xs: 2, md: 3 }, p: { xs: 0, md: 1 } }}>
          {/* <Typography variant="h6">Tipo de Patrimônio</Typography> */}

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                fullWidth
                color="success"
                variant="standard"
                label="Tipo de Patrimônio"
                name="name"
                id="name"
                value={patrimonyGroupName}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <AccountBalance />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Typography sx={{ mt: 5 }} variant="h6">
            Cadastro Novo Tipo para {patrimonyGroupName}
          </Typography>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                fullWidth
                color="success"
                variant="standard"
                label={`Tipo de ${patrimonyGroupName}`}
                name="name"
                id="name"
                value={data.name}
                onChange={handleTypeChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <AccountBalance />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid
          item
          xs={12}
          sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => {
              setIsOpenType(false);
            }}
            color="success"
            sx={{ mr: 1 }}
          >
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="success">
            Salvar
          </Button>
        </Grid>
      </Container>
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={isOpenType}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        style={{ zIndex: 1300 }}
      >
        {drawerContent}
      </SwipeableDrawer>
    </div>
  );
}
