import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

// https://apexcharts.com/docs/chart-types/pie-donut/
export default function ChartDonutGeneric({ chart_data }) {
  let defaul_chart = {
    title: (chart_data?.title || "None"),
    series: (chart_data?.series || [0]),
    labels: (chart_data?.labels || ["None"]),
    height: chart_data?.height || 450,
    width: chart_data?.width || 450,
    style: chart_data?.style || { width: "100%" }
  }

  var state = {
    series: defaul_chart.series,
    options: {
      title: {
        text: defaul_chart.title,
        align: "left",
      },
      chart: {
        height: defaul_chart.height,
        type: "donut",
        zoom: {
          enabled: false,
        },
      },
      labels: defaul_chart.labels,
      plotOptions: {
        pie: {
          expandOnclick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                // fontSize: "24px",
                color: "#28a745",
                formatter: function (w) {
                  let result = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  return Math.round(result * 100) / 100 + " %" // * 100 for 2 decimal numbers
                }
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' %'
          },
        },
      },
      responsive: [
        {
          breakpoint: 451,
          options: {
            chart: {
              width: defaul_chart.width,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div style={defaul_chart.style} className="bg-white w-full" id={defaul_chart.title}>
      <ReactApexChart 
        type="donut" 
        options={state.options} 
        series={state.series} 
        height={defaul_chart.height} 
        width={defaul_chart.width}
      />
    </div>
  );
}
