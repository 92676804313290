import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import { AutoAwesomeMotion, ExpandMore, Female, Male, Notes } from '@mui/icons-material';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
import TableMedicinesAnimals from './TableMedicinesAnimals';
// import CurrencyInputReal from '../../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../../components/Fields/GenericValueField';

export default function FormMedicinesStep1({ data, obj_fn }) {
  const [errorr, setError] = useState(false);

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    if (!data.lotes.length) {
      iserror = true;
    } else {
      data.lotes?.map((lote) => {
        // let aux_product = (lote.product?.medicine || 0);
        let aux_product = 0;
        lote.list_animals.map((animal) => {
          if (lote.table_selected[animal.id]) {
            aux_product += animal?.medicine || 0;
          }
        });
        if (aux_product === 0) {
          iserror = true;
        } else if (aux_product > lote.product?.location_selected?.quanty + (lote.product?.location_selected?.quanty_edit || 0)) {
          iserror = true;
        }
      });
    }
    return iserror || errorr;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de lotes
  const opt_lotes = {
    multiple: true,
    name: 'lotes',
    label: 'Lotes da Criação',
    values: data.opt_lotes,
    field: 'lotes',
    variant: 'standard',
    helperText: 'Selecione o(s) lote(s) a serem medicados',
    listHeight: 225,
  };

  const handleChangeLote = (field, newValue, selectOption, option) => {
    // console.log('handleChangeLote');
    if (newValue && newValue.length > 0) {
      newValue.map((lote) => {
        if (option && option.id === Number(lote.id)) {
          lote.product = null;

          lote.list_animals.map((animal) => {
            animal.medicine = 0;
            return animal;
          });
          lote.table_selected = {};
          lote.table_confirm = [];
        }

        return lote;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  // Handle PRODUTOS ------
  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.getProductsStoreMultiple([['group_category', 'Medicamentos']])));
  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        let aux_idx = field.split('_');
        if (idx === Number(aux_idx[2])) {
          let aux_prod = JSON.parse(JSON.stringify(newValue));
          aux_prod.location_selected = aux_prod.location.length > 0 ? aux_prod.location[0] : null;

          return { ...lote, product: aux_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal');
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        let aux_idx = field.split('_');
        if (idx === Number(aux_idx[3])) {
          let aux_lote_prod = [...lote.product];
          let aux_local = JSON.parse(JSON.stringify(newValue));

          aux_lote_prod[aux_idx[3]].location_selected = aux_local;
          return { ...lote, product: aux_lote_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleChangeQtd = (event, product, index, total) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        if (idx === index) {
          let aux_lote_prod = { ...lote.product };
          let aux_qtd = parseFloat(event.target.value || '0');
          let aux_qtd_anim = lote.num_selecteds || 1;

          if (total) {
            aux_lote_prod.medicine = aux_qtd / aux_qtd_anim;
            aux_lote_prod.medicine_total = aux_qtd;
          } else {
            aux_lote_prod.medicine = aux_qtd;
            aux_lote_prod.medicine_total = aux_qtd * aux_qtd_anim;
          }

          lote.list_animals = lote.list_animals.map((animal) => {
            if (lote.table_selected[animal.id]) {
              if (Number(animal.id) === 0) {
                // não identificados
                animal.medicine = aux_lote_prod.medicine * lote.num_animals;
              } else {
                animal.medicine = aux_lote_prod.medicine;
              }
            }
            return animal;
          });

          return { ...lote, product: aux_lote_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleProducts = (product, index) => {
    const opt_products = {
      multiple: false,
      name: 'product_' + product?.id + '_' + index,
      field: 'product_' + product?.id + '_' + index,
      label: 'Produto (Medicamento)',
      values: optionsProducts,
      variant: 'outlined',
      helperText: (
        <>
          Selecione o produto desejado
          <br />
          Valor unitário: {product?.stock_cost || ''}
        </>
      ),
      listHeight: 225,
      disableClearable: true,
    };

    return (
      <>
        <AutocompleteGeneric value={product || null} options={opt_products} handleChange={handleChangeSelect} />
      </>
    );
  };

  const handleProductsLocation = (product, index) => {
    const opt_products_local = {
      multiple: false,
      name: 'product_location_' + product?.id + '_' + index,
      field: 'product_location_' + product?.id + '_' + index,
      label: 'Local de estoque (Retirada)',
      values: product?.location || [],
      variant: 'standard',
      helperText: (
        <>
          Local onde o produto se encontra
          <br />
          <span style={{ color: 'green' }}>Qtd. em estoque:</span> {product?.location_selected?.quanty || '-'}
          {product?.unit_sigla ? ' (' + product?.unit_sigla + ')' : ''}
          {product?.location_selected?.quanty_edit ? (
            <>
              <br />
              Qtd. em edição: {product?.location_selected?.quanty_edit}
              {product?.unit_sigla ? ' (' + product?.unit_sigla + ')' : ''}
            </>
          ) : (
            ''
          )}
        </>
      ),
      listHeight: 225,
      disabled: !product?.id,
      disableClearable: true,
    };

    return (
      <>
        <AutocompleteGeneric value={product?.location_selected || null} options={opt_products_local} handleChange={handleChangeLocal} />
      </>
    );
  };

  const validQtd = (type, product) => {
    let valid = product?.medicine > 0;
    let qtd_prod = product?.location_selected?.quanty + (product?.location_selected?.quanty_edit || 0) || 0;
    if (type === 'color') {
      return valid || !product?.id ? 'success' : 'error';
    } else {
      var mensagem = <></>;
      if (!product?.id) {
        mensagem = <></>;
      } else if (!valid) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'red' }}>Deve ser maior que 0</span>
          </>
        );
      } else if (qtd_prod < (product?.medicine || 0)) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'red' }}>Não pode ser maior do que a Qtd. em estoque</span>
          </>
        );
      } else if (type === 'total' && qtd_prod < (product?.medicine_total || 0)) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'red' }}>Não pode ser maior do que a Qtd. em estoque</span>
          </>
        );
      }

      return (
        <>
          Quantidade: {type}
          {mensagem}
        </>
      );
    }
  };

  const filters_prods = [['group_category', 'Medicamentos']];
  return (
    <>
      <Grid container sx={{ mb: 4, ml: 1 }}>
        <Grid item xs={12} md={12}>
          <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
            <ProductRegistrar filters={filters_prods} />
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: obj_fn.isMobile ? 1 : -3 }}>
          <AutoAwesomeMotion />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lotes para manejo de Medicamentos</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={7} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.lotes} options={opt_lotes} handleChange={handleChangeLote} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid sx={{ mt: obj_fn.isMobile ? -2 : 1, ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} align="center">
                <Typography fontWeight={700} style={{ color: '#ff9800' }}>
                  {!data.lotes.length ? 'Selecione pelo menos um lote da criação para a aplicar a medicação!' : 'Selecione o produto para realizar sua aplicação no(s) lote(s).'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.lotes?.map((lote, index) => (
            <>
              <Accordion defaultExpanded={true} sx={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="lote-products-content" id={'acordion_' + lote?.id + '_' + index}>
                  <Typography>
                    <AutoAwesomeMotion color="success" sx={{ mr: 1 }} /> Lote: {lote.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mb: '-24px' }}>
                  <Grid container spacing={4} sx={{ mt: -4, mb: 1 }}>
                    <Grid item xs={12} sm={4}>
                      {handleProducts(lote.product, index)}
                    </Grid>
                    <Grid item xs={12} sm={3.5} sx={{ mt: obj_fn.isMobile ? -4 : -1 }}>
                      {handleProductsLocation(lote.product, index)}
                    </Grid>
                    <Grid item xs={6} sm={2.25}>
                      {/* <CurrencyInputReal
                    name={"medicine_" + lote?.id + "_" + index}
                    label="Qtd. média/Ca*"
                    variant="standard"
                    value={lote?.product?.medicine}
                    adornment="quantidade"
                    endAdornment={lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ")" : ''}
                    onChange={(e) => handleChangeQtd(e, lote.product, index)}
                    helperText={validQtd('média por cabeça', lote.product, index)}
                    color={validQtd('color', lote.product,)}
                    disabled={!lote.product?.id}
                  /> */}
                      <GenericValueField
                        handleChange={(e) => handleChangeQtd(e, lote.product, index)}
                        options={{
                          id: 'medicine_' + lote?.id + '_' + index,
                          name: 'medicine_' + lote?.id + '_' + index,
                          label: 'Qtd. média/Ca*',
                          value: lote?.product?.medicine,
                          variant: 'standard',
                          helperText: validQtd('média por cabeça', lote.product, index),
                          color: validQtd('color', lote.product),
                          height: '30px',
                          disabled: !lote.product?.id,
                          endAdornment: true,
                          prefix: lote.product?.unit_sigla ? ' (' + lote.product?.unit_sigla + ')' : '',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2.25}>
                      {/* <CurrencyInputReal
                    name={"medicine_total_" + lote?.id + "_" + index}
                    label="Qtd. total*"
                    variant="standard"
                    value={lote?.product?.medicine_total}
                    adornment="quantidade"
                    endAdornment={lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ")" : ''}
                    onChange={(e) => handleChangeQtd(e, lote.product, index, 'total')}
                    helperText={validQtd('total', lote.product, index)}
                    color={validQtd('color', lote.product,)}
                    disabled={!lote.product?.id}
                  /> */}
                      <GenericValueField
                        handleChange={(e) => handleChangeQtd(e, lote.product, index, 'total')}
                        options={{
                          id: 'medicine_total_' + lote?.id + '_' + index,
                          name: 'medicine_total_' + lote?.id + '_' + index,
                          label: 'Qtd. total*',
                          value: lote?.product?.medicine_total,
                          variant: 'standard',
                          helperText: validQtd('total', lote.product, index),
                          color: validQtd('color', lote.product),
                          height: '30px',
                          disabled: !lote.product?.id,
                          endAdornment: true,
                          prefix: lote.product?.unit_sigla ? ' (' + lote.product?.unit_sigla + ')' : '',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} sx={{ mt: 1, mb: 1, ml: '-15px' }}>
                    <Card sx={{ display: 'flex', padding: 1, width: '100%', overflow: 'auto' }} id={'card_lot_' + lote.id}>
                      {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                      <Grid item justifyContent="left" xs={12} sm={3} sx={{ mr: 1 }}>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Total de animais:
                          <br />
                          {lote?.total_animals || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fase de Manejo:
                          <br />
                          {lote?.fase_name || ''}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fêmeas:
                          <br />
                          <Female color="secondary" /> {lote?.num_femeas || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Machos:
                          <br />
                          <Male color="primary" /> {lote?.num_machos || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }} title={'Não identificados'}>
                          Não identif.:
                          <br />
                          {lote?.num_animals || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TableMedicinesAnimals data_l={lote} obj_fn={obj_fn} setError={setError} />
                      </Grid>
                    </Card>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </Grid>

        {/* Descrição */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 3, ml: -4 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral do manejo sanitário{' '}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0, ml: -4 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares do manejo sanitário"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid>

        {obj_fn.no_footer ? (
          <></>
        ) : (
          <>
            <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
          </>
        )}
      </Grid>
    </>
  );
}
