import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useActivityTransferBatchReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        asyncList: [],
        loading: true,
        listActivityTransferBatch: [],
        pastureTransferBatch: { label: "", id: 0, id_property: 0 },
        fetch: async (item) => {
          var farm_id = (localStorage.getItem("Property") || 1);
          if (!item) {
            item = { 'id_farm': farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { "Content-Type": "application/json" } };
          //const data = []
          const response = await api.get("activityTransfer/list", header);
          const data = await response.data._value;
          console.log('useActivityTransferBatch')
          console.log(response.data)
          const getDateExit = (value) => {
            if (value == null) {
              return null
            } else {
              return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
            }
          }
          set({
            listActivityTransferBatch:
              data.map((transfer) => ({
                id: transfer.id,
                id_user: transfer.id_user,
                id_property: transfer.id_property,
                id_livestock: transfer.id_livestock,
                id_group: transfer.id_group,
                type_activity: transfer.type_activity,
                id_activity_mp: transfer.id_activity_mp,
                name_user: transfer.name,
                id_areamap: transfer.id_areamap,
                nameArea: transfer.nameArea,
                id_animal: transfer.id_animal,
                id_lote: transfer.id_lote,
                nameBatch: transfer.nameBatch,
                name_activity: "TRANSFERENCIA",
                numberAnimals: transfer.numberAnimals,
                areamap_use: transfer.areamap_use,
                progress: transfer.progress,
                planning: transfer.planning,
                date_start: dayjs(transfer.date_start).format('YYYY-MM-DD HH:mm:ss'),
                data_prev_end: dayjs(transfer.data_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                //date_exit: getDateExit(transfer.date_exit),
                isDeleted: transfer.isDeleted,
                planning: "realized",
                observation: "",
                tipo_repos: "TRANSFERENCIA",
                activity: "TRANSFERENCIA",
                type: "activity",
              }))
          })

          const lista = JSON.parse(localStorage.getItem("listActvs"));
          const lista_i = get().listActivityTransferBatch;

          let array_aux = lista.filter((activity) => {
            if (activity.activity != "TRANSFERENCIA") {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem("listActvs", JSON.stringify(res));

          return data;
        },
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getActivityTransferBatchStore: (key, val) => {
          return get().listActivityTransferBatch.filter((activityTransferBatch) => {
            if (activityTransferBatch[key] == val) {
              return activityTransferBatch;
            }
          });
        },
        // addLivestockStore: (livestock) => {
        //   set((state) => ({
        //     listLivestock: [...state.listLivestock, livestock],
        //   }));
        // },
        getActivityStore: (key, val) => {
          return get().listActivityTransferBatch.filter((activity) => {
            if (activity[key] == val) {
              return activity;
            }
          });
        },
      }),
      { name: "reposActivityTransferBatch" }
    )
  )
);

export default useActivityTransferBatchReposStore;
