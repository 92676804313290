import React, { useEffect, useState } from 'react';
import { Box, Grid, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { CropLandscape, MonetizationOn } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import FormApportionment from './FormApportionment';

export default function FormApportChoice({ data, obj_fn }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      var projects_apport = [], rateio_apport = null;

      const navMenuActive = localStorage.getItem('navMenuActive') || 'selectProperty';
      if (navMenuActive !== 'selectProperty') {
        rateio_apport = 'direto';
        let aux_project = [];
        // busca dados do projeto ativo
        var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
        if (creationHarvestRepos.tipo_repos === 'Safras') {
          aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
        } else {
          aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
        }
    
        if (aux_project?.id) {
          projects_apport.push({
            id: aux_project.id,
            name: aux_project.name,
            id_account: aux_project.id_account,
            tipo_repos: aux_project.tipo_repos,
            val: 100,
          });
        }
      }

      obj_fn.setData((prevData) => ({
        ...prevData,
        rateio_apport: rateio_apport,
        projects_apport: projects_apport
      }));
      setLoaded(true);
    }
  }, []);
  
  const handleChangeRadion = (event) => {
    var project = [];
    if (/.*direto.*/.test(event.target.value)) {
      let aux_project = [];
      // busca dados do projeto ativo
      var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
      if (creationHarvestRepos.tipo_repos === 'Safras') {
        aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
      } else {
        aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
      }

      if (aux_project?.id) {
        project.push({
          id: aux_project.id,
          name: aux_project.name,
          id_account: aux_project.id_account,
          tipo_repos: aux_project.tipo_repos,
          val: 100,
        });
      }
    }

    obj_fn.setData((prevState) => ({
      ...prevState,
      projects_apport: project,
      rateio_apport: event.target.value,
    }));
  };

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
        <Box sx={{ ml: 0, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>
              <MonetizationOn sx={{ mr: 1 }} />
              Apropriação do Valor
            </Typography>
          </Stack>
        </Box>
        <Grid item xs={12} md={12}>
          <Typography fontWeight={500}>
            Selecione como deseja ratear (dividir) o valor do lançamento
          </Typography>
        </Grid>
      </Grid>
      <FormControl component="fieldset">
        {!data.rateio_apport ? <></> :
          <RadioGroup
            row // Isso posiciona os botões de rádio lado a lado
            aria-label="Apropriação do valor"
            name="rateio_apport"
            value={data.rateio_apport || "rateado"}
            onChange={handleChangeRadion}
          >
            <FormControlLabel value="direto" control={<Radio color="success" />} label="Valor direto para projeto atual" />
            <FormControlLabel value="rateado" control={<Radio color="success" />} label="Valor rateado" />
          </RadioGroup>
        }
        {data.rateio_apport === 'direto' ? (
          <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700} style={{ color: green[800] }}>
                  <CropLandscape sx={{ mr: 1 }} />
                  100% do valor será rateado para o projeto atual:
                </Typography>
                <Typography fontWeight={500} style={{ marginTop: 1, color: green[800] }}>
                  {data?.projects_apport[0]?.tipo_repos}: {data?.projects_apport[0]?.name}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ) : (
          <FormApportionment data={data} obj_fn={obj_fn} />
        )}
      </FormControl>
    </>
  );
}
