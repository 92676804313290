import React from "react";
import Chart from "react-apexcharts";

export default function ChartBarPlanning({ totalSale, totalProfit, totalProductionCost, unit }) {
  const formattedTotalSale = parseFloat(totalSale.replace(".", "").replace(",", "."));
  let formattedtotalProfit = 0
  if (totalProfit) {
    formattedtotalProfit = parseFloat(totalProfit?.replace(".", "").replace(",", "."));
  }

  const formattedtotalProductionCost = parseFloat(totalProductionCost.replace(".", "").replace(",", "."));

  const state = {
    options: {
      toolbar: {
        show: false,
        tools: {
          download: false, // <== linha adicionada
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return val.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#28a745", "#F40000", "#FFC222"],
      xaxis: {
        categories: ["Venda", "Custo", "Lucro"],
        labels: {
          show: false,
        },
      },
    },
    series: [
      {
        data: [formattedTotalSale, formattedtotalProductionCost, formattedtotalProfit],
      },
    ],
  };

  return (
    <div className="app">
      <div className="row">
        <div style={{ width: "100%" }} className="bg-white w-full">
          <Chart options={state.options} series={state.series} type="bar" height="120" />
        </div>
      </div>
    </div>
  );
}
