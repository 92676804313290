import dayjs from "dayjs";

let listLivestock = [];

// Verifica se reposLivestock existe no localStorage
let reposLivestockString = localStorage.getItem("reposLivestock");
if (reposLivestockString) {
  // Converte a string JSON em um objeto JavaScript
  let reposLivestock = JSON.parse(reposLivestockString);
  listLivestock = reposLivestock.state.listLivestock;
  console.log("listLivestock");
  console.log(listLivestock);
} else {
  console.log("Nenhum dado encontrado em reposLivestock");
}

let dataProperty = [];

let resposPropertyString = localStorage.getItem("reposProperty");
//console.log(resposPropertyString);
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString);
  dataProperty = resposProperty.state.listProperty[0];
  console.log("imageProperty");
  console.log(dataProperty);
} else {
  console.log("Nenhum dado encontrado em resposProperty");
}

//console.log(dataProperty);
//const base64Image = dataProperty.image;
//console.log(base64Image);

// function loadBase64Image(base64) {
//   return new Promise((resolve, reject) => {
//     const image = new Image();

//     // Evento disparado quando a imagem é carregada com sucesso
//     image.onload = () => {
//       resolve(image);
//     };

//     // Evento disparado em caso de erro ao carregar a imagem
//     image.onerror = () => {
//       reject(new Error("Falha ao carregar a imagem base64."));
//     };

//     // Define a string base64 como fonte da imagem
//     image.src = base64;
//   });
// }
// console.log(dataProperty);
// const base64Image = dataProperty.image;

// loadBase64Image(base64Image)
//   .then((image) => {
//     // A imagem foi carregada com sucesso
//     console.log("Imagem base64 carregada!", image);
//   })
//   .catch((error) => {
//     // Houve um erro ao carregar a imagem
//     console.error(error);
//   });

// Função para converter lotes em string
function formatLotes(lotes) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return lotes.map((lote) => `${lote.name} (${lote.total_animals} animais)\n`);
}

function formatData(Inputdata) {
  return !Inputdata ? "" : dayjs(Inputdata).format("DD/MM/YYYY");
}

// Atualizando livestockTableRows
const livestockTableRows = listLivestock.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    //{ text: item.total_animais || "Tipo não especificado" }, // Verifica se 'tipo' está disponível
    { text: item.tipo || "Tipo não especificado", fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.name || "Nome não especificado", fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${formatData(item.data_inicial)} \n Fim:    ${formatData(item.data_fim)}` || "Data não especificada", fontSize: 7 }, // Converte para data
    { text: formatLotes(item.lotes) || "Lote não especificado", fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ];
});

console.log(livestockTableRows);

const lineBlank = [
  {
    text: "",
    alignment: "center",
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
];

export const bodyListCreationHarvest = {
  content: [
    { text: "Lista das Criações", fontSize: 10, alignment: "left", margin: [0, 4, 0, 0], color: 'green' },
],
  
};

// // Adicionando a imagem se ela existir
// if (dataProperty) {
//   ListCreationHarvest.content.push({
//     alignment: "justify",
//     columns: [
//       // {
//       //   image: base64Image || "",
//       //   width: 100, // Ajuste a largura conforme necessário
//       //   alignment: "center",
//       // },
//       {
//         width: 10,
//         text: " ",
//       },
//       // {
//       //   width: "*",
//       //   text: `latitude: ${dataProperty.latitude}} \n\n longitude: ${dataProperty.longitude}`,
//       // },
//       // [`Propriedade: ${dataProperty.label}\n`, `Latitude: ${dataProperty.latitude} \n Longitude: ${dataProperty.longitude} \n`],
//       // [`Número de criações: ${dataProperty.total_livestocks} \n Total de animais: ${dataProperty.total_animais} \n `],
//       {
//         width: "*",
//         text: `${dataProperty.label}\n ${dataProperty.area} ${dataProperty.measure}\n Latitude: ${dataProperty.latitude} \n Longitude: ${dataProperty.longitude} \n`,
//         fontSize: 10,
//         margin: [4, 0, 0, 0],
//       },
//       {
//         width: "*",
//         text: `Número de criações: ${dataProperty.total_livestocks} \n Total de animais: ${dataProperty.total_animais} \n `,
//         fontSize: 10,
//         margin: [4, 0, 0, 0],
//       },
//     ],
//   });
// }

// adicionando a tabela
bodyListCreationHarvest.content.push(
  {text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0]},
		{
			style: 'tableExample',
			table: {
				headerRows: 1,
        widths: [10, "*","*", "*", "*"],
        fillColor: '#eeeeee',
				body: [
					[{text: '#', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Tipo', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Projeto(Criação)', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Data', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Lote', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}],
					...livestockTableRows, // Linhas de dados da tabela
			
				]
			},
			layout: 'lightHorizontalLines'
		},
);

// adicionando texto
bodyListCreationHarvest.content.push(
  {
            width: "*",
            text: `Número de criações: ${dataProperty?.total_livestocks} \n Total de animais: ${dataProperty?.total_animais} \n `,
            fontSize: 10,
            color: '#808080',
            margin: [0, 10, 0, 0],
            alignment: "right",
          },
);


// // adicionando a tabela
// ListCreationHarvest.content.push(
//   { text: " ", style: "subheader" },
//   { text: "Lista", style: "subheader" },
//   {
//     style: "tableExample",
//     table: {
//       widths: [20, 40, "*", "*", 60, "*"],
//       body: [
//         ["#", "Animais", "Tipo", "Projeto(Criação)", "data", "Lote"], // Cabeçalhos da tabela
//         //{ text: "", italics: true, color: "gray" },
//         ...livestockTableRows, // Linhas de dados da tabela
//       ],
//     },
//     fontSize: 10,
//    // bold: true,
//   }
// );

let listHarvest = [];

// Verifica se reposLivestock existe no localStorage
let reposHarvestString = localStorage.getItem("reposHarvest");
if (reposHarvestString) {
  // Converte a string JSON em um objeto JavaScript
  let reposHarvest = JSON.parse(reposHarvestString);
  listHarvest = reposHarvest.state.listHarvest;
  console.log("listHarvest");
  console.log(listHarvest);
} else {
  console.log("Nenhum dado encontrado em reposLivestock");
}

// Função para converter lotes em string
function formatAreas(areas) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return areas.map((area) => `${area.name} (${area.usedArea})\n`);
}

// Atualizando livestockTableRows
const HarvestTableRows = listHarvest.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    { text: item.cultivation_name || "Tipo não especificado", fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.name || "Nome não especificado", fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${item.data_inicial} \n Fim:    ${item.data_fim}` || "Data não especificada", fontSize: 7 }, // Converte para data
    { text: formatAreas(item.areas) || "Lote não especificado", fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ];
});

console.log(HarvestTableRows);


bodyListCreationHarvest.content.push(
  {
    text: "___________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
    alignment: "center",
    fontSize: 5,
    colSpan: 0,
    color: 'green',
  },
  { text: "Lista das Safras", fontSize: 10, alignment: "left", margin: [0, 4, 0, 0], color: 'green' }
);

// adicionando a tabela
bodyListCreationHarvest.content.push(
  {text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0]},
		{
			style: 'tableExample',
			table: {
				headerRows: 1,
        widths: [10, "*","*", "*", "*"],
        fillColor: '#eeeeee',
				body: [
					[{text: '#', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Tipo', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Projeto(Safra)', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Data', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}, {text: 'Lote', style: 'tableHeader', fillColor: '#42ab49', color: 'white'}],
					...HarvestTableRows, // Linhas de dados da tabela
			
				]
			},
			layout: 'lightHorizontalLines'
		},
);

// adicionando texto
bodyListCreationHarvest.content.push(
  {
            width: "*",
            text: `Número de safras: ${dataProperty?.total_safras} \n Total de cultivares: ${dataProperty?.total_cultivation} \n `,
            fontSize: 10,
            color: '#808080',
            margin: [0, 10, 0, 0],
            alignment: "right",
          },
);