import React from 'react';
//import BackgroundMaps from "./../../assets/png/MapaPropriedade.png";
export default function AreasContent() {
  return (
    <div className="content-wrapper">
      <div className="content">
        <div className="container-fluid">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10112.908828591511!2d-42.79903138396917!3d-20.919153077492897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa34125e406564d%3A0xe7cc42daf7218fde!2sUnidade%20de%20Ensino%20Fazenda%20CPT!5e1!3m2!1spt-BR!2sbr!4v1666294153119!5m2!1spt-BR!2sbr" width="100%" height={1000} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
          {/* <div style={{ backgroundImage: `url(${BackgroundMaps})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className=" login-page "></div> */}
        </div>
      </div>
    </div>
  );
}
