import React, { useState } from 'react';
import { Box, ButtonBase, Grid, Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import { TypesBankList } from '../../../../../components/Icons/bank/BankList';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '120px',
  },
}));

export default function FormBankStep1({ data, obj_fn }) {
  const classes = useStyles();

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.type) {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return true;
  };

  const [selectedButton, setSelectedButton] = useState(data.type || null);

  const handleClickOpen = (e, title, name) => {
    obj_fn.setData((prevState) => ({ ...prevState, type: name }));
    obj_fn.setData((prevState) => ({ ...prevState, type_title: title }));
    setSelectedButton(name);
  };

  // const [listTypesBank, setListBank] = useState(TypesBankList());
  const listTypesBank = TypesBankList();

  const geraButtonGeral = function (obj) {
    const isSelected = selectedButton === obj.name;
    const iconStyle = {
      fontSize: 50,
      color: isSelected ? 'black' : 'lightgray',
      filter: isSelected ? 'brightness(100%)' : 'brightness(70%)',
    };

    return (
      <Grid item xs={6} md={4} key={'g_' + obj.name}>
        <ButtonBase
          key={'b_' + obj.name}
          component={Paper}
          elevation={2}
          sx={{
            p: 2,
            borderRadius: 1,
            border: isSelected ? '2px solid #2e7d32' : '2px solid transparent',
          }}
          onClick={(e) => handleClickOpen(e, obj.title, obj.name)}
          className={classes.button}
        >
          <Stack direction="column" alignItems="center" key={'s_' + obj.name}>
            <Box height={50}>{React.cloneElement(obj.icon, { style: iconStyle })}</Box>
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              {obj.title}
            </Typography>
          </Stack>
        </ButtonBase>
      </Grid>
    );
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container sx={{ mb: 4 }}>
          <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 2 }} >
            <Typography fontWeight={400}>
              O seu Fazenda Premium já inclui uma "conta caixinha" por padrão (o caixa da propriedade), que serve como uma conta financeira básica pré-configurada para facilitar o controle financeiro
              das movimentações internas da sua fazenda, ajudando a registrar e gerenciar transações e gastos de forma mais organizada e eficiente.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 0 }}>
            <Typography style={{ color: green[800] }}>
              <Typewriter
                tam={0}
                minHeight={0}
                text="Agora, você pode cadastrar um novo tipo de conta financeira, como uma conta bancária ou carteira digital, para melhorar o controle e o acompanhamento das suas finanças."
              />
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ ml: 1, mt: 2, mb: 2, display: 'flex', flexDirection: 'column', }} >
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Contas bancárias e contas de movimentação </Typography>
          </Stack>
        </Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2, mt: 1 }}>
          {listTypesBank.map((type_bank) => {
            return <>{geraButtonGeral(type_bank)}</>;
          })}
        </Grid>
        {data.type !== '' && (
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {data.type === 'ContaCorrente' && (
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
                <Typography style={{ color: green[800] }}>
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={
                      `A conta corrente é o tipo de conta que é utilizanda para controlar sua conta corrente tanto de pessoas físicas como a jurídica. ` +
                      `Clique em continuar para cadastrar a sua ${data.type_title}.`
                    }
                  />
                </Typography>
              </Grid>
            )}
            {data.type === 'Caixinha' && (
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
                <Typography style={{ color: green[800] }}>
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={
                      `A conta caixinha é usada para controlar valores em espécie dentro da propriedade. Ex.: caixas do estabelecimento. ` +
                      `Clique em continuar para cadastrar a sua ${data.type_title}.`
                    }
                  />
                </Typography>
              </Grid>
            )}
            {data.type === 'Poupanca' && (
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
                <Typography style={{ color: green[800] }}>
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={
                      `A conta poupança é o tipo de conta que é utilizanda para controlar uma poupança de pessoas físicas e ou jurídica. ` +
                      `Clique em continuar para cadastrar a sua ${data.type_title}.`
                    }
                  />
                </Typography>
              </Grid>
            )}
            {data.type === 'Investimento' && (
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
                <Typography style={{ color: green[800] }}>
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={
                      `A conta investimento é utilizada para controlar os investimentos que a empresa possui registrada no banco. Ex.: CDB, CDI... ` +
                      `Clique em continuar para cadastrar a sua ${data.type_title}.`
                    }
                  />
                </Typography>
              </Grid>
            )}
            {data.type === 'OutrasContas' && (
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
                <Typography style={{ color: green[800] }}>
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={
                      `Outras contas pode ser selecionada caso tenha necessidade de controlar outras movimentações financeiras. Ex.: Empréstimo do sócio que a empresa terá que devolver. Adiantamento recebidos...` +
                      `Clique em continuar para cadastrar a sua ${data.type_title}.`
                    }
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
