import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Box } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";
// import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
//import CardTimeLineMsn from "../../ControlPanelCreations/components/timeLine/CardTimeLineMsn";
import MediaCard from "./MediaCard";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import dayjs from "dayjs";

/*const array = [
  { type: "msn", name: "score", value: false, title: "Contagens ", icon: <ConnectWithoutContactIcon />, color: "primary" },
  {
    type: "activity",
    name: "input",
    value: false,
    title: "colheita ",
    icon: <LinkedCameraIcon />,
    image: "https://blog.climatefieldview.com.br/hubfs/planta%C3%A7%C3%A3o%20de%20milho.webp",
  },
  {
    type: "activity",
    name: "identification",
    value: false,
    title: "Crescimento ",
    icon: <LinkedCameraIcon />,
    color: "success",
    image: "https://i0.wp.com/agroemdia.com.br/wp-content/uploads/2019/04/milho-embrapa-5-4-19.jpg?resize=800%2C447&ssl=1",
  },
  {
    type: "activity",
    name: "score",
    value: false,
    title: "Muda ",
    icon: <LinkedCameraIcon />,
    color: "success",
    image: "https://blog.aegro.com.br/wp-content/uploads/2019/10/herbicidas-pr%C3%A9-emergente-para-milho-1024x683.jpg",
  },
  {
    type: "msn",
    name: "score",
    value: false,
    title: "Contagens ",
    icon: <ConnectWithoutContactIcon />,
    color: "primary",
    image: "",
  },
];*/

function formatLondDate(data) {
  const dataFormatada = dayjs(data).locale("pt-br").format("D [de] MMMM [de] YYYY");
  return dataFormatada;
}

export default function CardTimeLinePainelImage({ dataProgress, date_start }) {
  // console.log(dataProgress)


  const date_init = formatLondDate(date_start);
  // console.log(date_init)

  function createData(id, type, value, title, description, icon, color, image, date, sortDate) {
    return {
      id, type, value, title, description, icon, color, image, date, sortDate
    };
  }

  const arrayProgress = []
  for (const item of dataProgress) {
    const date_start = formatLondDate(item.date_start);
    // console.log(item)
    const date = dayjs(item.date_start).format('YYYY-MM-DD HH:mm:ss')
    const data = createData(item.id, "activity", false, item.title, item.description, <LinkedCameraIcon />, "success", item.key_image, date_start, date)
    arrayProgress.push(data)
  }

  arrayProgress.sort((a, b) => new Date(b.id) - new Date(a.id))
  arrayProgress.sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate));

  // console.log(arrayProgress)
  // for (const item of arrayProgress) {
  //   console.log(item.sortDate)
  // }

  return (
    <>
      <Box>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {arrayProgress.map((item) => (
            <>
              <TimelineItem>
                <TimelineSeparator sx={{ px: 1.5 }}>
                  <TimelineDot variant="outlined" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{item.date}</TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color={item.color}>{item.icon}</TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "10px", px: 2 }}>
                  {/* <Box sx={{ mb: 3 }}>{item.type == "msn" ? <CardTimeLineMsn /> : <MediaCard item={item} />}</Box> */}
                  <Box sx={{ mb: 3 }}>{<MediaCard item={item} />}</Box>
                </TimelineContent>
              </TimelineItem>
            </>
          ))}
          <TimelineItem sx={{ py: "8px", ml: -0.3 }}>
            <TimelineSeparator>
              <AccessTimeIcon sx={{ fontSize: 40, color: grey[500] }} />
            </TimelineSeparator>
            <TimelineContent>{date_init}</TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </>
  );
}
