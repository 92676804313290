import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
// import dayjs from 'dayjs';
// import 'dayjs/locale/pt';
// import usePatrimonyReposStore from './usePatrimonyReposStore';

// create the store
const useProductsReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listProducts: [],
        listCategory: [],
        listUnits: [],
        listLocationProducts: [],
        listPackaging: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }

          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('products/list', item, header);

          const data = await response.data;
          // console.log('-list-product');
          // console.log(data);

          await set({
            listProducts: data._value.map((product) => ({
              id: product.id,
              id_account: product.id_account,
              name: product.name,
              id_unit: product.id_unit,
              unit_name: product.unit_name,
              unit_sigla: product.unit_sigla,
              id_packaging: product.id_packaging,
              stock_quanty: product.stock_quanty,
              stock_quanty_array: product.quanty_array,
              volume_stock_array: product.volume_stock_array,
              stock_cost: product.stock_cost,
              stock_quanty_min: product.stock_quanty_min,
              stock_quanty_max: product.stock_quanty_max,
              stockPercentage: Math.max(0, Math.min(100, (product.stock_quanty / product.stock_quanty_max) * 100)),
              // location = id_patrimony
              id_patrimony: product.id_patrimony_array,
              id_product_location_array: product.id_product_location_array,
              id_category: product.id_category,
              category_name: product.category_name,
              group_category: product.group_category,
              category_icon: product.category_icon,
              category_project: product.category_project,
              description: product.description,
              cod_barra: product.cod_barra,
              brand: product.brand,
              observacoes: product.observacoes,
              image: product.image,
              url: product.url,
              //valor_produto: product.valor_produto,
              //qnt_disponivel: product.qnt_disponivel,
              //custo_medio: product.value_cost,
              //estoque_min: product.estoque_min,
              //estoque_max: product.estoque_max,
              packaging_name: product.packaging_name,
              packaging_sigla: product.packaging_sigla,
              volume_pkg: product.volume_pkg,
              altura: product.altura,
              largura: product.largura,
              profundidade: product.profundidade,
              volume: product.volume,
              peso_liquido: product.peso_liquido,
              peso_bruto: product.peso_bruto,
              isDeleted: product.isDeleted,
              location: JSON.parse(product.location),
            })),
          });

          return data;
        },
        // pega o(s) product(s) da listProducts de acordo com o [key e val] informados
        getProductsStore: (key, val) => {
          return get().listProducts.filter((products) => {
            return products[key] == val;
          });
        },
        // pega o(s) product(s) da listProducts de acordo com as chaves [keys] informadas
        //Ex1: [['category_name', 'Sementes'], ['category_name', 'Mudas']] -> retorna os produtos de categoria Sementes e Mudas
        getProductsStoreMultiple: (keys) => {
          return get().listProducts.filter((product) => {
            var valid = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (product[keys[i][0]] == keys[i][1]) {
                // return product;
                valid++;
              }
            }
            return valid;
          });
        },
        getCategoryStoreMultiple: (keys) => {
          return get().listCategory.filter((category) => {
            var valid = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (category[keys[i][0]] == keys[i][1]) {
                // return category;
                valid++;
              }
            }
            return valid;
          });
        },
        // Adiciona um novo product à listProducts
        addProductStore: (product) => {
          set((state) => ({
            listProducts: [...state.listProducts, product],
          }));
        },
        // Gera uma nova listProducts sem a product informada
        delProductStore: (id) => {
          set({
            listProducts: get().listProducts.filter((product) => {
              return product.id !== id;
            }),
          });
        },
        // atualiza listProducts de acordo com id, key e val informados
        updateProductStore: (id, key, val) => {
          set({
            listProducts: get().listProducts.map((products) => {
              if (products.id === id) {
                if (key === 'all') {
                  // atualiza todos os dados
                  products = val;
                } else {
                  products[key] = val;
                }
              }
              return products;
            }),
          });
          return get().listProducts;
        },
        fetchCategory: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          // const response = await api.post("products/productCategory/list", (item), header);
          const response = await api.post('products/product-category', item, header);
          const data = await response.data;
          // console.log('dataXXXXXXX');
          // console.log(data);
          await set({
            listCategory: data._value.map((category) => ({
              id: category.id,
              //id_property: products.id_property,
              description: category.description,
              icon: category.icon,
              id_account: category.id_account,
              nameAccount: category.nameAccount,
              group_category: category.group_category,
              name: category.name,
            })),
          });

          return data;
        },
        getCategoryStore: (key, val) => {
          return get().listCategory.filter((category) => {
            return category[key] == val;
          });
        },
        delCategoryStore: (id) => {
          set({
            listCategory: get().listCategory.filter((category) => {
              return category.id !== id;
            }),
          });
        },
        updateCategoryStore: (id, key, val) => {
          set({
            listCategory: get().listCategory.map((category) => {
              if (category.id === id) {
                if (key === 'all') {
                  // atualiza todos os dados
                  category = val;
                } else {
                  category[key] = val;
                }
              }
              return category;
            }),
          });
          return get().listCategory;
        },
        fetchUnits: async () => {
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.get('products/unit/list', header);
          const data = await response.data;
          await set({
            listUnits: data._value.map((unit) => ({
              id: unit.id,
              name: unit.name,
              sigla: unit.sigla,
              icon: unit.icon,
            })),
          });
          return data;
        },
        getUnitStore: (key, val) => {
          return get().listUnits.filter((unit) => {
            return unit[key] == val;
          });
        },
        fetchPackaging: async () => {
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.get('products/listPackaging', header);
          const data = await response.data;
          // console.log(data);
          await set({
            listPackaging: data._value.map((packaging) => ({
              id: packaging.id,
              name: packaging.name,
              sigla: packaging.sigla,
              //icon: packaging.icon,
            })),
          });
          return data;
        },
        getPackagingStore: (key, val) => {
          return get().listPackaging.filter((packaging) => {
            return packaging[key] == val;
          });
        },
        fetchLocationStock: async () => {
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.get('products/listLocation', header);
          const data = await response.data;
          await set({
            listLocationProducts: data._value.map((location) => ({
              id: location.id,
              id_patrimony: location.id_patrimony,
              id_product: location.id_product,
              quantity: location.quanty,
              date_updated: location.date_updated,
            })),
          });
          return data;
        },
        getLocationStockStore: (key, val) => {
          return get().listLocationProducts.filter((location) => {
            return location[key] == val;
          });
        },
      }),

      { name: 'reposProducts' }
    )
  )
);

export default useProductsReposStore;
