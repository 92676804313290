import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Checkbox, TextField } from "@mui/material";
import { green } from "@mui/material/colors";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import useStore from "../../store/useStore";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function AutocompleteUsers({ handleChange, multiple, condition }) {
  const usestore = useStore();
  const listUsers = usestore.reposUser.listUser;
  const optionsPessoa = [];
  listUsers.map((user) => {
    if (condition) {
      if (condition(user)) {
        optionsPessoa.push({ 'id': user.id, 'name': user.name, 'phone': user.phone, charName: user.charName });
      }
    } else {
      optionsPessoa.push({ 'id': user.id, 'name': user.name, 'phone': user.phone, charName: user.charName });
    }
  });

  return (
    <Autocomplete
      multiple={multiple}
      id="autocomplete_users"
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={optionsPessoa}
      disableCloseOnSelect
      filterSelectedOptions
      color="success"
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {/*<Checkbox icon={icon} color="success" checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
          <Avatar sx={{ bgcolor: green[500], mr: 2 }}>{option.charName}</Avatar>
          {option.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} variant="standard" color="success" label="Pessoas" />}
      noOptionsText="Sem resultados"
    />
  );
}