import * as React from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';

const PercentTextField = ({ handleChange, options }) => {
  let opt_default = {
    required: options.required || false,
    label: options.label || '',
    name: options.name || 'percent',
    id: options.id || 'percent_',
    value: options.value,
    color: options.color || "success",
    helperText: options.helperText || '',
    width: options.width || '110px',
    variant: options.variant || "standard",
  }

  return (
    <TextField
      required={opt_default.required}
      label={opt_default.label}
      name={opt_default.name}
      id={opt_default.id}
      color={opt_default.color}
      type="number"
      variant={opt_default.variant}
      fullWidth
      sx={{ m: 1, width: "100%",
        "& .MuiInputBase-input": {
          textAlign: 'left',
        },
      }}
      style={{ width: opt_default.width }}
      InputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        maxLength: 5,
        min: 0,
        max: 100,
        style: {
          height: '35px',
        },
        endAdornment: (
          <InputAdornment position="end">
            <Box sx={{ width: '15px', mr: '-5px' }}>
              <Typography variant="subtitle1" component="div"> % </Typography>
            </Box>
          </InputAdornment >
        ),
      }}
      value={opt_default.value}
      onChange={handleChange}
      onBlur={(e) => handleChange(e, true)}
      helperText={opt_default.helperText}
    />
  );
};

export default PercentTextField;
