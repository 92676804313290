import React, { useState } from 'react';
import { FeedbackTypeStep } from './Steps/FeedbackTypeStep';
import { FeedbackContentStep } from './Steps/FeedbackContentStep';
import { FeedbackSuccessStep } from './Steps/FeedbackSuccessStep';

export function WidgetForm({ handleClose }) {
  const [feedbackType, setFeedbackType] = useState(null);
  const [feedbackSent, setFeedbackSent] = useState(false);

  function handleRestartFeedback() {
    setFeedbackSent(false);
    setFeedbackType(null);
  }

  return (
    <div style={{ backgroundColor: '#28a745', padding: 16, borderRadius: 16, marginBottom: 16, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      {feedbackSent ? (
        <FeedbackSuccessStep onFeedbackRestartRequested={handleRestartFeedback} handleClose={handleClose} />
      ) : (
        <>
          {!feedbackType ? (
            <FeedbackTypeStep onFeedbackTypeChanged={setFeedbackType} handleClose={handleClose} />
          ) : (
            <FeedbackContentStep handleClose={handleClose} feedbackType={feedbackType} onFeedbackRestartRequested={handleRestartFeedback} onFeedbackSent={() => setFeedbackSent(true)} />
          )}
        </>
      )}
      <footer style={{ fontSize: '0.75rem', color: 'white' }}>Compartilhe seus pensamentos com nossa Equipe.</footer>
    </div>
  );
}
