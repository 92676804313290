import React, { useEffect, useState, useContext } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Edit } from '@mui/icons-material';
import { AppBar, Card, CardContent, Container, Typography, Slide, Grid, IconButton, Toolbar } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, useTheme } from '@mui/system';
import { H6, Span } from '../../../../components/Typography';
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import EditFormTeam from './EditFormTeam';
import useGetUserByPermissionRepos from '../../../../queries/apiGetPermission';
import Loading from '../../../../components/Loader/Loading';
import EditModalFormTeamSave from './EditModalFormTeamSave'
import hexSha1 from "hex-sha1";
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from "react-query";
import { Context } from "../../../../components/Context/AuthContext";
import useStore from "../../../../store/useStore";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditProfileDraggableDialog({ edit_user, setLoading }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const handleClickOpen = () => {
    setDadosUserEdit();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("userPermissionsEdit");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const header = { header: { "Content-Type": "application/json" } };
    editUser.password = hexSha1(editUser.password);
    editUser.farm_list = editUser.farms;
    console.log("EditProfile submit");
    //console.log(editUser);

    const res = await api.post("/users/updateuser", editUser, header)
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success('Membro da equipe editado com sucesso!');
          setLoading(true);
          usestore.reposUser.fetch().then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
              handleClose();
            }, 200);
          });
        }
      })
      .catch((error) => {
        toast.error('Erro ao editar o membro da equipe, Tente novamente mais tarde!\n Caso o erro persista, contate o suporte.');
      })
  }

  // criar o objeto de edições
  const [editUser, setEditUser] = useState({});

  // pega a harvest em edição
  useEffect(() => {
    setDadosUserEdit();
  }, []);

  const setDadosUserEdit = () => {
    const this_user = window.jQuery.extend(true, [], usestore.reposUser.getUserStore('id', edit_user.id))[0];
    setEditUser({
      id: this_user.id,
      name: this_user.name,
      email: this_user.email,
      phone: this_user.phone,
      password: "*******",
      id_role: this_user.id_role,
      permission: this_user.permission,
      isDeleted: this_user.isDeleted,
      farms: this_user.farms
    });
  };

  return (
    <div  >
      <IconButton onClick={handleClickOpen}>
        <Edit sx={{ fontSize: 18 }} />
      </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} aria-labelledby="draggable-dialog-title" >
        <AppBar sx={{ position: "relative" }} color="success" id="draggable-dialog-title">
          <div className="">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item >
                  <Typography variant="h6" component="div">
                    Edição da equipe
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
              </Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </div>
        </AppBar>
        <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <EditFormTeam editUser={editUser} setEditUser={setEditUser} id_role={edit_user.id_role} handleClose={handleClose} setLoading={setLoading}/>
          </Paper>
        </Container>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <EditModalFormTeamSave onSubmit={onSubmit} editUser={editUser}/>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div >
  );
}