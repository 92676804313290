import React from "react";
import NavBotton from "../../components/Nav/NavBotton";
import SettingsContent from "./SettingsContent";
import Footer from "../../components/Footer/Footer";

export default function SettingsUser() {
  return (
    <>
      <NavBotton tipo={'user'} />
      <SettingsContent />
      <Footer />
    </>
  );
}
