import dayjs from "dayjs";

const lineHeader = [
  {
    text: "___________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
    alignment: "center",
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
];

const date = new Date();

function formatData(Inputdata) {
  //return !Inputdata ? "" : dayjs(Inputdata).format("DD/MM/YYYY");
  return !Inputdata ? "" : `${dayjs(Inputdata).format("DD/MM/YYYY")}`;
}

export const titleSection = (title) => [
  [
    {
      //margin: [24, 12, 24, 0],
      layout: "noBorders", // Sem bordas para a tabela
      table: {
        widths: ["*", "auto"], // Largura para o nome da fazenda e paginação
        body: [
          // Primeira linha: Nome da fazenda à esquerda e paginação à direita
          [
            { text: title, bold: true, fontSize: 12, alignment: "center" },
            { text: " ", bold: true, fontSize: 6, alignment: "right" },
          ],
        ],
      },
    },
    lineHeader,
    { text: `relatório: ${formatData(date)}`, bold: true, fontSize: 6, alignment: "right" },
    // Segunda linha horizontal
    lineHeader,
  ],
  // Título
  // [{ text: "RELATÓRIO DE VENDAS", style: "reportNameStyle", colSpan: 0, alignment: "center" }],
  // [{ text: "01/01/2024", style: "reportDateStyle", colSpan: 0, alignment: "right" }],
];
