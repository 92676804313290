import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';

// create the store
const useSalesReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listSale: [],
        fetch: async (item) => {
          const header = { header: { "Content-Type": "application/json" } };
          var id_property = (localStorage.getItem("Property") || 1);
          var ano_atual = (new Date()).getFullYear();

          if (!item) {
            item = { 'id_property': id_property, 'ano': ano_atual };
          } else {
            if (!item.id_property) {
              item.id_property = id_property;
            }
            if (!item.ano) {
              item.ano = ano_atual;
            }
          }
          const response = await api.post("sales/list", (item), header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          await set({
            listSale:
              data_map.map((sale) => ({
                id: sale.id,
                id_property: sale.id_property,
                id_person: sale.id_person,
                person: sale.person,
                value: sale.value,
                status: sale.status,
                id_trans_entry: sale.id_trans_entry,
                date_entry: dayjs(sale.date_entry).format('YYYY-MM-DD HH:mm:ss'),
                date_entry_f: dayjs(sale.date_entry).format('DD/MM/YYYY'),
                id_trans_pay: sale.id_trans_pay,
                date_payment: sale.date_payment ? dayjs(sale.date_payment).format('YYYY-MM-DD HH:mm:ss') : '',
                date_payment_f: sale.date_payment ? dayjs(sale.date_payment).format('DD/MM/YYYY') : '',
                id_trans_inv: sale.id_trans_inv,
                date_inventory: sale.date_inventory ? dayjs(sale.date_inventory).format('YYYY-MM-DD HH:mm:ss') : '',
                date_inventory_f: sale.date_inventory ? dayjs(sale.date_inventory).format('DD/MM/YYYY') : '',
                description: sale.description,
                isDeleted: sale.isDeleted,
                info_entry: JSON.parse(sale.info_entry || '{}'),
                info_pay: JSON.parse(sale.info_pay || '{}'),
                info_inv: JSON.parse(sale.info_inv || '{}'),
                record_entry: JSON.parse(sale.record_entry || '{}'),
                record_pay: JSON.parse(sale.record_pay || '{}'),
                record_inv: JSON.parse(sale.record_inv || '{}'),
                products: JSON.parse(sale.products || '[]'),
                products_value: sale.products_value,
                lotes: JSON.parse(sale.lotes || '[]'),
                lotes_value: sale.lotes_value,
                animals: JSON.parse(sale.animals || '[]'),
                animals_value: sale.animals_value,
                patrimony: JSON.parse(sale.patrimony || '[]'),
                services: JSON.parse(sale.services || '[]'),
              }))
          })

          return data;
        },
        // pega a(s) sale(s) da listSale de acordo com o [key e val] informados
        getSaleStore: (key, val) => {
          return get().listSale.filter((sale) => {
            return sale[key] === val;
          })
        },
        // Adiciona uma nova sale à listSale
        addSaleStore: (sale) => {
          set(state => ({
            listSale: [...state.listSale, sale]
          }))
        },
        // Gera uma nova listSale sem a sale informada
        delSaleStore: (id) => {
          set({
            listSale:
              get().listSale.filter((sale) => {
                return sale.id !== id;
              })
          })
        },
        // atualiza listSale de acordo com id, key e val informados
        updateSaleStore: (id, key, val) => {
          set({
            listSale:
              get().listSale.map((sale) => {
                if (sale.id === id) {
                  if (key === 'all') { // atualiza todos os dados
                    sale = val;
                  } else {
                    sale[key] = val;
                  }
                }
                return sale;
              })
          })
        },
      }),
      { name: "reposSales" }
    )
  )
);

export default useSalesReposStore;