import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateDrawAreaList from '../../../areasMaps/components/tutorial/CreateDrawAreaList';
import { Box, ButtonBase, List, ListItem, ListItemText, Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import SpaIcon from "@mui/icons-material/Spa";
import CreateDrawPropertyList from '../../../livestock/components/addLivestock/tutorial/CreateDrawPropertyList';
import { green, grey } from '@mui/material/colors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FirstAcessMapList from '../../tutorial/FirstAcessMapList';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function TutorialMapaAccordion() {

  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClickLivestock = () => {
    navigate("/livestock");
  };



  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}>Registrar a área da propriedade no mapa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FirstAcessMapList />
          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" sx={{ color: grey[700] }} fontWeight={500}>
          O que mais o mapa da propriedade poderá oferecer:
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Botão circular azul "+"</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Botões de acesso rápido as principais funcionalidades:</strong>} secondary={`Acesse o menu de registros de projetos de criação e safras, clicando no botão azul "+" no canto inferior direito.`} />

              </ListItem>
              {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/listaprojetos.png" style={{ maxWidth: '100%' }} />
              </ListItem> */}
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Criações e Safras</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Botões de acesso rápido às criações ou safras:</strong>} secondary="Acesse o painel de controle das criações e safras, clicando no botão circular correspondente no canto superior esquerdo." />

              </ListItem>
              {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/listaprojetos.png" style={{ maxWidth: '100%' }} />
              </ListItem> */}
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Clima da propriedade</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Botões de acesso rápido ao clima da propriedade</strong>} secondary="De acordo com as coordenadas geográficas definidas no mapa, podemo verificar o clima da propriedade. Acesse o clima, clicando no botão circular clima no canto superior esquerdo." />

              </ListItem>
              {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/listaprojetos.png" style={{ maxWidth: '100%' }} />
              </ListItem> */}
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Modo de visualização do mapa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Botão modo de visualização</strong>} secondary="O Mapa oferece algumas visualizações diferentes para mapas: padrão (visualização de mapa simplificada), satélite e terreno.Acesse o filtro áreas, clicando no botão circular no canto superior esquerdo." />

              </ListItem>
              {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/listaprojetos.png" style={{ maxWidth: '100%' }} />
              </ListItem> */}
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Filtro de visualização das áreas de produção</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <CheckBoxOutlineBlankIcon style={{ color: "#2e7d32" }} />
                  </span>
                }
              >
                <ListItemText primary={<strong>Botão modo de visualização das áreas</strong>} secondary="O Mapa oferece um filtro de aŕeas para facilitar a visualizações. Clicando no botão quadrado  no canto inferior esquerdo." />

              </ListItem>
              {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/listaprojetos.png" style={{ maxWidth: '100%' }} />
              </ListItem> */}
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Registrar a realização de uma atividade</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Caso já tenha o projeto da criação criado, é hora de inciarmos o gerenciamento das criações através dos registros das atividades.
            </Typography>
            <div style={{ display: 'flex' }}>
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, margin: '0 10px' }}
                onClick={() => handleButtonClickLivestock()}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Atividades
                  </Typography>
                </Stack>
              </ButtonBase>
            </div>
          </List>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </div>
  );
}