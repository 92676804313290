import React, { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../../../components/Loader/Loading';
import api from '../../../../services/api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Checkbox, Grid, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';

export default function ConfirmListAreasMapsLivestockImagens({ data, setData, isLoading, setIsLoading }) {
  const [areas, setAreas] = useState(data.areas);
  console.log('Confirm');
  console.log(areas);

  return (
    <div className="row">
      <div className="col">
        {isLoading ? (
          <>
            {' '}
            <Loading />{' '}
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              {areas?.map((area) => (
                <Grid key={area.id} item container alignItems="center" justifyContent="center">
                  <Card sx={{ display: 'flex', width: '310px' }}>
                    <ListItemIcon>
                      <CardMedia component="img" sx={{ width: 100 }} image={area?.url || area?.imageDecode} alt={area.name} />
                      <CardContent sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Área: {area.name}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            <i className="fa fa-map text-success" /> {area.usedArea || area.area} ({area.measure})
                          </Typography>
                        </Box>
                      </CardContent>
                    </ListItemIcon>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}
