import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { Close, DeleteForever, PersonOff } from '@mui/icons-material'
import { red } from '@mui/material/colors'
import { toast } from 'react-toastify'
import { Context } from '../../../../components/Context/AuthContext'
import api from '../../../../services/api'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Fechar"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function DeleteActivitiesHarvests({ row, usestore }) {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseCancel = () => {
    setOpen(false)
  }

  const handleDelete = (e) => {
    e.preventDefault()
    handleChangeDelete(e)
  }

  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context)
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const property_id = localStorage.getItem('Property') || 1

  const handleChangeDelete = async (e) => {
    e.preventDefault()
    console.log('handleChangeDelete')

    const header = { header: { 'Content-Type': 'application/json' } }
    const status = 'delete'
    let url = ''
    let reposActivity = {}

    if (row.activity === 'PLANTIO') {
      url = `/activityPlanting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityPlanting
    } else if (row.activity === 'COLHEITA') {
      url = `/activityHarvesting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityHarvesting
    } else if (row.activity === 'MONITORING') {
      url = `/activityMonitoring/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityMonitoring
    } else if (row.activity === 'PREPARAÇÃO DO SOLO') {
      url = `/activitySoilPreparation/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivitySoilPreparation
    } else if (row.activity === 'APLICAÇÃO') {
      url = `/activityApplications/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityApplications
    }

    // const res = await api
    await api.delete(url, header)
      .then((response) => {
        //console.log(response.data);
        if (response.data.isSuccess) {
          // atualiza o repos
          setIsLoading(false)
          reposActivity.fetch().then((res) => {
            toast.success(`Atividade deletada com sucesso!`)
            setAuxAdd(aux_add + 1)
            setTimeout(() => {
              setIsLoading(true)
              setOpen(false)
            }, 200)
            setTimeout(() => {
              usestore.reposHarvest.fetch({ id_farm: property_id });
              usestore.reposProducts.fetch({ id_farm: property_id });
              usestore.reposFinances.fetch({ id_property: property_id });
              usestore.reposFinances.fetchT({ id_property: property_id });
              usestore.reposCostProduction.fetch();
            }, 400);
          })
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.success(error)
      })
  }

  return (
    <div>
      <IconButton color="error" type="submit" onClick={handleClickOpen} aria-label="Deletar" title="Deletar">
        <DeleteForever />
      </IconButton>
      <BootstrapDialog onClose={handleCloseCancel} aria-labelledby="Excluindo atividade" open={open} sx={{ backgroundColor: red[500] }}>
        <BootstrapDialogTitle sx={{ color: '#fff', backgroundColor: red[800] }} id="customized-dialog-title" onClose={handleCloseCancel} color="success">
          Excluindo atividade
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <>
              <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                <Alert variant="filled" severity="error">
                  A atividade será excluída do software
                </Alert>
                <Typography sx={{ mt: 2 }} gutterBottom>
                  Esse procedimento é irreversível.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                  <PersonOff style={{ fontSize: 80 }} />
                  <p>Atividade excluída com sucesso!!!</p>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCancel} style={{ color: '#8a8a8a' }} aria-label="Cancelar" title="Cancelar">
            Cancelar
          </Button>
          <Button autoFocus onClick={handleDelete} variant="contained" color="error" aria-label="Excluir" title="Excluir">
            Excluir atividade
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
