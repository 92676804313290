import * as React from "react";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuMsn from "../DialogMessenger/forms/MenuMsn";
import MenuMsnMin from "../DialogMessenger/forms/MenuMsnMin";
import { green, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconCheckboxes from "./IconCheckboxes";
import GradingIcon from "@mui/icons-material/Grading";
import CardChat from "./CardChat";
import CardChatImage from "./CardChatImage";
import { useState } from "react";
import Loading from "../../../components/Loader/Loading";
import api from "../../../services/api";
import { useEffect } from "react";

export default function SidebarChatCompactMin() {
  /* <div className="d-lg-none">Esconde em telas maiores que lg</div>
<div className="d-none d-lg-block">Esconde em telas menores que lg</div> */
  const [msn, setMsn] = useState([]);
  const [isLoading, setIsLoading] = useState(true);




  // para user current
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent;
  console.log(user)




  const loadPosts = async () => {
    console.log("-------result---d---------:");
    const response = await api.get("/messenger/posts");
    console.log(response.data._value);
    const itemData = [];
    for (const iterator of response.data._value) {
      if (iterator.isDeleted === 0 && iterator.id_requester === user.id) {
        const name = user.name;
        const charName = name[0].toUpperCase();
        console.log(charName);
        iterator.char = charName
        itemData.push(iterator);
      }

    }
    setMsn(itemData);
    setIsLoading(false)
  };

  useEffect(() => {
    loadPosts();
    console.log('setMsn')
    console.log(msn)
  }, [isLoading]);

  return (
    <div className="container-fluid " style={{ maxWidth: 900 }}>
      <ul className="contacts-list ">
        {isLoading === true ? (
          <>
            <Loading />
          </>
        ) :
          (
            // {msn.length === 0 && <span>Sem cards no momento</span> }
            msn?.map((post) => (
              <li>
                <CardChat post={post} setIsLoading={setIsLoading} />
              </li>
            ))
          )}
        {/* <li>
          <CardChatImage />
        </li> */}
      </ul>
    </div>
  );
}
