//*********Corpo do documento **********/

import { bodyListHarvestBW } from '../bodyHarvest/bodyListHarvestBW'
import { bodyListCreationBW } from '../bodyLivestock/bodyListCreationBW'
import { bodyListCreationHarvest } from '../bodyProperty/bodyListCreationHarvest'
import { bodyListCreationHarvestBW } from '../bodyProperty/bodyListCreationHarvestBW'
import { footerBasic } from '../footer/footerBasic'
import { headerReportDate } from '../header/headerReportDate'
import { titleSection } from '../title/titleBasic'
import { titleProperty } from '../title/titleProperty'

let dataProperty = []

let resposPropertyString = localStorage.getItem('reposProperty')
//console.log(resposPropertyString);
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString)
  dataProperty = resposProperty.state.listProperty[0]
  console.log('imageProperty1')
  console.log(dataProperty)
} else {
  console.log('Nenhum dado encontrado em resposProperty')
}

export const documento = {
  pageSize: 'A4',
  //pageOrientation: 'landscape',
  header: headerReportDate,
  footer: footerBasic,
  content: [
    titleSection('Lista das Criações e das Safras'),
    //titleProperty(dataProperty),
    // lineHeader,
    bodyListCreationBW.content,
    bodyListHarvestBW.content,
    // ... outras partes do conteúdo, se necessário
  ],
  styles: {
    //lineStyle: lineStyle,
    //reportNameStyle: reportNameStyle,
    //reportDateStyle: reportDateStyle,
  },
  // ... outras configurações do documento
}

//tutorial
//http://pdfmake.org/playground.html
//CriaCorpoDocumento
//https://dev.to/taikio/criando-documentos-pdf-com-reactjs-4lkl
//https://www.papodigital.net.br/blog/criando-documentos-pdf-com-reactjs
