import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { CircularProgress } from "@mui/material";
import hexSha1 from "hex-sha1";
import { useParams } from "react-router-dom";
import LogoNameWhite from "../../../assets/png/logo-name-white.png";
import ModalResetPassword from "./ModalResetPassword";


export default function ResetPasswordContent() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { token } = useParams();
  //console.log("------token-------");
  // console.log(token);


  const onSubmit = async (e) => {
    setLoading(true)
    const header = { header: { "Content-Type": "application/json" } };

    // console.log(password)
    //console.log(confirmPassword)
    if (password === password_confirmation) {

      if (password == '') {
        toast.error("Ops...Por favor, insira uma senha válida.");
        setLoading(false)
        return false
      }

      await api.post("password/reset", { password, password_confirmation, token }, header)
        .then((response) => {
          // aqui acessamos o corpo da resposta:
          // console.log(response.data)
          if (response.data.isSuccess) {
            //handleLogin();
            // toast.success('Sucesso');
            handleOpen()
            setTimeout(() => {
              navigate("/");
            }, 2000);
            setLoading(false)
          }
        })
        .catch((error) => {
          // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
          console.log('error');
          toast.error(error.response.data);
        })
    } else {
      toast.error("Ops...A senha não é igual a de confirmação. Por favor, digite novamente.");
      setLoading(false)
      return false
    }

  };

  return (
    <>

      <div style={{ background: "#1db954" }} className=" login-page ">

        <div className="login-box">
          <div className="login-logo " style={{ width: "340px", height: "100px" }}>
            {/* <img style={{ height: 70 }} src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="CPT" className="brand-image center" />
                        <h5 className="font-weight-normal mt-3 text-white"  >CPT Fazenda Premium</h5> */}
            <img className="m-3" src={LogoNameWhite} style={{ width: "290px" }} alt="CPT" />

          </div>
          <div>

            <div className="card-body ">
              <h4 className="login-box-msg text-white">Redefinir a senha</h4>
              <p className="login-box-msg text-white small">Você está a apenas um passo de sua nova senha, recupere a sua senha agora.</p>
              {/* temos um state: false e  de formulario null */}
              <form action="../../index3.html" method="post">
                <div className="input-group mb-3">
                  {/* <input type="password" onChange={e => setSenha(window.hex_sha1(e.target.value))} className="form-control" placeholder="Digite sua senha" /> */}
                  <input type="password" onChange={(e) => setPassword(hexSha1(e.target.value))} className="form-control" placeholder="Digite uma nova senha" required />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  {/* <input type="password" onChange={e => setSenha(window.hex_sha1(e.target.value))} className="form-control" placeholder="Digite sua senha" /> */}
                  <input type="password" onChange={(e) => setpassword_confirmation(hexSha1(e.target.value))} className="form-control" placeholder="Confirmar a nova senha" required />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>


                <div className=" text-center mt-2 mb-1">
                  <NavLink to="#">
                    <button type="submit" onClick={onSubmit} className="btn btn-success  btn-block">
                      {loading ? <CircularProgress sx={{ mb: -0.6 }} size="1.3rem" style={{ color: '#fff' }} /> : "Mudar a senha"}
                    </button>
                  </NavLink>
                </div>
                <p className="mb-1 mt-3  ">
                  <Link to="/" className=" text-white ">
                    Ir para o Login
                  </Link>
                </p>
              </form>
              <ModalResetPassword handleOpen={handleOpen} open={open} handleClose={handleClose} />
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.login-box */}
      </div>
    </>
  );
}

