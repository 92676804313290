import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { AppBar, Card, CardActionArea, CardActions, CardMedia, CircularProgress, Grid, Paper, Toolbar, Typography } from '@mui/material';
import { Span } from '../../../../../components/Typography';
// import ConfirmListAreasMapsLivestockImagens from '../../../../livestock/components/addLivestock/ConfirmListAreasMapsLivestockImagens';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { green } from '@mui/material/colors';
import FormInputStep6ConfirmRadioButtonProgress from './FormInputStep6ConfirmRadioButtonProgress';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';

export default function FormInputStep6Confirm({ onSubmit, data, setData, isSubmitting, handleNext, handleBack, activeStep, steps, handleClose }) {
  // const handleSubmit = () => {
  //   // console.log('onSubmit');
  //   onSubmit();
  //   //handleNext();
  // };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  // const isError = () => {
  //   return false;
  // };

  // useEffect(() => {
  //   // retirando a imagem da área para realizar o post
  //   console.log('console.log(data.areas)');
  //   console.log(data.areas);
  //   // for (const area of data.areas) {
  //   //   console.log(area)
  //   //   area.image = ""
  //   // }
  // }, [data]);

  return (
    <>
      <Grid item container alignItems="center" justifyContent="center">
        <Grid item sx={{ mt: 1, height: 120 }}>
          <img style={{ maxHeight: 120 }} src="/dist/image/entrada.png" alt="image" class="img-fluid" />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
          <FormInputStep6ConfirmRadioButtonProgress data={data} setData={setData} />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} md={12}>
          <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
            <ListItemText
              primary="Categoria:"
              secondary={
                <Paper sx={{ p: 1, backgroundColor: green[50] }}>
                  <Typography noWrap={false} sx={{ wordBreak: 'break-all' }} variant="h6">
                    {data.stage.toUpperCase()}
                  </Typography>
                </Paper>
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Nome do Lote" secondary={data.nameBatch} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Número de Animais" secondary={data.numberAnimals} />
          </ListItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Data de Início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText primary="Data de Término" secondary={dayjs(data.date_prev_exit).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} sx={{ mt: 1, mb: 4 }}>
        <Grid item xs={12} md={12}>
          <ListItemText primary="Localização dos animais" />
          {/* <ConfirmListAreasMapsLivestockImagens data={data} /> */}
        </Grid>
        <Grid container spacing={3}>
          {data.areas.map((area) => (
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ m: 1 }}>
                <CardMedia component="img" height="250" image={navigator.onLine ? area.url : '/dist/image/areamapa.png'} alt="Área selecionada" />
                <CardActionArea>
                  <CardActions>
                    <p>{area.label} </p>
                    <p>
                      Área utilizada: {area.usedArea ? area.usedArea : area.area} {area.measure}
                    </p>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button sx={{ my: 2, width: '100px', animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} /> : <>Confirmar</>}
            </Button>
            {/* <Button sx={{ animation: `${expandAndFade} 2s linear infinite`, marginRight: 2 }} color="success" variant="contained" type="submit" onClick={onSubmit}>
              Confirmar
            </Button> */}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
