import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Card, Stack } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { grey } from "@mui/material/colors";
import StepperHarvest from "./StepperHarvest";
import { Grid } from "@mui/material";

export default function TextAddProjectStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 5;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography><strong>Criação do projeto</strong></Typography>
            </Paper> */}
      {activeStep == "0" && (
        <Card>
          <Box sx={{ height: 380, maxWidth: 415, overflowY: "scroll" }}>
            <Paper square elevation={0} sx={{ p: 2 }}>
              <Grid container spacing={2} sx={{ mt: 1 }} direction="row" alignItems="center" justifyContent="center">
                <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                  <img src="/dist/image/project.png" width="120" name="Avicultura"></img>
                </Grid>
                <Grid container xs={12} md={12} sx={{ mt: 3 }} item alignItems="center" justifyContent="center">
                  <Typography style={{ fontSize: 16 }}>
                    {" "}
                    Veja como é fácil criar um projeto bem estruturado para que você possa iniciar o gerenciamento das atividades e dos custos da sua safra!
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Card>
      )}
      {activeStep == "1" && (
        <Card>
          <Box sx={{ height: 380, width: "100%", overflowY: "scroll" }}>
            <StepperHarvest number={0} />
          </Box>
        </Card>
      )}
      {activeStep == "2" && (
        <Card>
          <Box sx={{ height: 380, width: "100%", overflowY: "scroll" }}>
            <StepperHarvest number={1} />
          </Box>
        </Card>
      )}
      {activeStep == "3" && (
        <Card>
          <Box sx={{ height: 380, width: "100%", overflowY: "scroll" }}>
            <StepperHarvest number={2} />
          </Box>
        </Card>
      )}
      {activeStep == "4" && (
        <Card>
          <Box sx={{ height: 380, width: "100%", overflowY: "scroll" }}>
            <StepperHarvest number={3} />
          </Box>
        </Card>
      )}

      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button color="success" size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Próximo
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" color="success" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}
