import React from "react";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Nav/Nav";
import PlanningHarvestContent from "./PlanningHarvestContent";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";

// livestock  é a lista das criações (atividades)
export default function PlanningHarvest() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <PlanningHarvestContent />
      <Footer />
    </>
  );
}
