import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, IconButton, Grid, Paper, Typography, Menu, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import dayjs from 'dayjs';
import ChatSidebar from './ChatSidebar/ChatSidebar';
import ModalVisualizeBill from './outhers/ModalVisualizeBill';
import TableBuyContent from '../../buy/TableBuyContent';
import TableSaleContent from '../../sales/TableSaleContent';
import ViewMaintenance from '../../patrimony/components/componentsMaintenance/ViewMaintenance';
import ModalVisualizePayment from './outhers/ModalVisualizePayment';
import TableBuyDelete from '../../buy/TableBuyDelete';
import TableSaleDelete from '../../sales/components/TableSaleDelete';
import DeleteMaintenance from '../../patrimony/components/componentsMaintenance/DeleteMaintenance';
import ModalDeleteBill from './outhers/ModalDeleteBill';
import { Context } from '../../../components/Context/AuthContext';

function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1 - date2;
}

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(item, type, id, date_expire, date_payment, description, person, installment, status, date_nf, info) {
  return { item, type, id, date_expire, date_payment, description, person, installment, status, date_nf, info };
}

const columns = [
  {
    id: 'type',
    label: 'Tipo',
    minWidth: 100,
  },
  // {
  //   id: 'id',
  //   label: 'ID',
  //   minWidth: 50
  // },
  {
    id: 'date_payment',
    label: 'Data',
    minWidth: 150,
  },
  {
    id: 'description',
    label: 'Descrição',
    minWidth: 170,
    //align: 'right',
    //format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'person',
    label: 'Cliente / Fornecedor',
    minWidth: 100,
    //align: 'right',
    //format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'installment',
    label: 'Total da Parcela (R$)',
    minWidth: 120,
    align: 'right',
    //format: (value) => value.toFixed(2),
  },
  {
    id: 'actions',
    label: 'Ações',
    minWidth: 100,
    align: 'center',
  },
];

const buttons = [
  { id: 0, label: 'Receitas do período', color: 'green' },
  { id: 1, label: 'Depesas do período', color: 'red' },
  { id: 2, label: 'Total do período', color: 'blue' },
];

export default function Extract({ obj_fn, iconsBank }) {
  const { aux_add, setAuxAdd } = useContext(Context);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  const [activeButton, setActiveButton] = useState(null);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openShow, setOpenShow] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  // Gerenciamento do estado do menu
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedRowId, setSelectedRowId] = useState(null);
  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const [obj_fn2, setObjFun] = useState({
    usestore: obj_fn.usestore,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    userCurrent: obj_fn.userCurrent,
    isMobile: obj_fn.isMobile,
    isOpen: 0,
    show_btn: false,
  });
  const handleMenuClose = (e, type, idBill) => {
    setAnchorEl(null);
    if (type === 'show') {
      setOpenShow(true);
    } else if (type === 'delete') {
      setOpenDel(true);
    }
    setObjFun((prevState) => ({ ...prevState, isOpen: idBill }));
  };
  const handleCloseShow = () => {
    setOpenShow(false);
    setObjFun((prevState) => ({ ...prevState, isOpen: 0 }));
    setSelectedRowId(null);
  };
  const handleCloseDel = () => {
    setOpenDel(false);
    setObjFun((prevState) => ({ ...prevState, isOpen: 0 }));
    setSelectedRowId(null);
  };

  function getVizualize(row, open, type) {
    var dialog = <></>;
    if (!open && selectedRowId === row.id) {
      let aux_tipo = row.type.toLowerCase();
      let item;
      if (/compra/.test(aux_tipo)) {
        if (type === 'show') {
          item = obj_fn.usestore.reposBuys.getBuyStore('id_trans_pay', row.id)[0];
          dialog = <TableBuyContent row={item} usestore={obj_fn.usestore} openExtern={openShow} handleCloseExtern={handleCloseShow} />;
        } else {
          //if (type === 'delete') {
          dialog = <TableBuyDelete row={row} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn2} />;
        }
      } else if (/venda.*/.test(aux_tipo)) {
        item = obj_fn.usestore.reposSales.getSaleStore('id_trans_pay', row.id)[0];
        if (item && item?.id) {
          if (type === 'show') {
            dialog = <TableSaleContent row={item} usestore={obj_fn.usestore} openExtern={openShow} handleCloseExtern={handleCloseShow} />;
          } else {
            //if (type === 'delete') {
            dialog = <TableSaleDelete row={row} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} />;
          }
        }
      } else if (/manutenção.*/.test(aux_tipo)) {
        item = obj_fn.usestore.reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id_trans_pay', row.id)[0];
        if (item && item?.id) {
          if (type === 'show') {
            dialog = <ViewMaintenance row={item} obj_fn={obj_fn} openExtern={openShow} handleCloseExtern={handleCloseShow} />;
          } else {
            //if (type === 'delete') {
            obj_fn2.isOpen = item.id;
            dialog = <DeleteMaintenance row={item} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn2} setObjFun={setObjFun} />;
          }
        }
      } else if (/recursos.*/.test(aux_tipo)) {
        // implementar para cada atividade ??
      } else {
        if (type === 'show') {
          if (row.item.id_transaction) {
            dialog = <ModalVisualizeBill bill={row.item} open={openShow} handleClose={handleCloseShow} obj_fn={obj_fn2} />;
          } else {
            dialog = <ModalVisualizePayment transaction={row.item} open={openShow} handleClose={handleCloseShow} obj_fn={obj_fn2} />;
          }
        } else {
          //if (type === 'delete') {
          dialog = <ModalDeleteBill bill={row.item} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn2} />;
        }
      }
    }

    return dialog;
  }

  // retorna o nome da pessoa
  function getNamePerson(id_person) {
    const person = obj_fn.usestore.reposPerson.getPersonStore('id', id_person)[0];
    return person.name;
  }

  const [buttons_val, setBtnVal] = useState([0, 0, 0]);

  useEffect(() => {
    const aux_list = [];
    var aux_trans = {};
    var aux_tipo = 0;
    let aux_btn_val = [0, 0, 0];

    let listBills = obj_fn.usestore.reposBill.listBills;
    listBills.filter((bill) => {
      if (bill.date_payment) {
        if (dayjs(dayjs(bill.date_payment).format('YYYY-MM')).isSame(dayjs(dayjs(obj_fn.selectedDate).format('YYYY-MM')))) {
          aux_trans[bill.id_trans_pay] = bill.id_trans_pay;
          aux_trans[bill.id_transaction] = bill.id_transaction;

          aux_tipo = bill.type_bill === 'pay' ? 1 : 0;
          aux_btn_val[aux_tipo] += bill.value;
          aux_btn_val[2] += bill.value * (aux_tipo > 0 ? -1 : 1);

          aux_list.push(
            createDataRow(
              bill,
              bill.type_bill === 'pay' ? 'Despesa' : 'Receita',
              bill.id,
              dayjs(bill.date_expire).format('DD/MM/YYYY HH:mm'),
              bill.date_payment ? dayjs(bill.date_payment).format('DD/MM/YYYY HH:mm') : '',
              bill.description ? bill.description : ' - Parcela ' + bill.portion,
              bill.info.id_person ? getNamePerson(bill.info.id_person) : '',
              obj_fn.formatValue(bill.value),
              bill.type_bill === 'pay' ? 'Pago' : 'Recebido',
              dayjs(bill.date_payment ? bill.date_payment : bill.date_expire).format('YYYY-MM-DD HH:mm:ss'),
              bill.info
            )
          );
        }
      }
      return bill;
    });

    let listTrans = obj_fn.usestore.reposFinances.listTransactions;
    listTrans.filter((trans) => {
      aux_tipo = trans.type.toLowerCase();
      if (trans.record.payed && !aux_trans[trans.id] && !/.*recursos.*/.test(aux_tipo)) {
        if (dayjs(dayjs(trans.date_entry).format('YYYY-MM')).isSame(dayjs(dayjs(obj_fn.selectedDate).format('YYYY-MM')))) {
          aux_trans[trans.id] = trans.id;

          aux_tipo = trans.type === 'Compra' || trans.type === 'Despesa' || /Manutenção.*/.test(trans.type) ? 1 : 0;
          aux_btn_val[aux_tipo] += trans.value;
          aux_btn_val[2] += trans.value * (aux_tipo > 0 ? -1 : 1);

          aux_list.push(
            createDataRow(
              trans,
              trans.type,
              trans.id,
              dayjs(trans.date_entry).format('DD/MM/YYYY HH:mm'),
              dayjs(trans.date_entry).format('DD/MM/YYYY HH:mm'),
              trans.description || '',
              trans.fkys[0].id_person ? getNamePerson(trans.fkys[0].id_person) : '',
              obj_fn.formatValue(trans.value),
              aux_tipo > 0 ? 'Pago' : 'Recebido',
              dayjs(trans.date_entry).format('YYYY-MM-DD HH:mm:ss'),
              trans.info
            )
          );
        }
      }
      return trans;
    });

    let listResources = obj_fn.usestore.reposActivityResources.listActivityResources;
    listResources.filter((resource) => {
      if (!aux_trans[resource.id_trans_entry] || !aux_trans[resource.id_trans_pay] || !aux_trans[resource.id_trans_inv]) {
        if (dayjs(dayjs(resource.date_entry).format('YYYY-MM')).isSame(dayjs(dayjs(obj_fn.selectedDate).format('YYYY-MM')))) {
          aux_trans[resource.id_trans_entry] = resource.id_trans_entry;
          aux_trans[resource.id_trans_pay] = resource.id_trans_pay;
          aux_trans[resource.id_trans_inv] = resource.id_trans_inv;

          aux_btn_val[1] += resource.value;
          aux_btn_val[2] += resource.value * -1;

          aux_list.push(
            createDataRow(
              resource,
              resource.type,
              resource.id,
              dayjs(resource.date_entry).format('DD/MM/YYYY HH:mm'),
              dayjs(resource.date_entry).format('DD/MM/YYYY HH:mm'),
              resource.description || '',
              resource.fkys[0]?.id_person ? getNamePerson(resource.fkys[0]?.id_person) : '',
              obj_fn.formatValue(resource.value),
              'Pago',
              dayjs(resource.date_entry).format('YYYY-MM-DD HH:mm:ss'),
              resource.info
            )
          );
        }
      }
      return resource;
    });

    aux_list.sort((a, b) => compareDates(b.date_nf, a.date_nf));
    setRows(aux_list);
    setBtnVal(aux_btn_val);
  }, [aux_add, obj_fn.selectedDate]);

  return (
    <Box sx={{ display: 'flex' }}>
      {!iconsBank && <ChatSidebar />}
      <Container maxWidth="" sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid container spacing={0} sx={{ mb: 1 }} justifyContent="center">
            {buttons.map((btn, index) => (
              <Grid item key={index} xs={6} md={2}>
                <div
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    padding: '10px',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                  onClick={() => handleButtonClick(index)}
                >
                  <Typography>{btn.label}</Typography>
                  <Typography component="p" variant="h6" style={{ color: btn.color }}>
                    {obj_fn.formatValue(buttons_val[index])}
                  </Typography>
                  {activeButton === index && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        borderBottom: '3px solid green',
                      }}
                    />
                  )}
                </div>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="Tabela de Extratos">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === 'actions') {
                              let aux_tipo = row.type.toLowerCase();
                              if (/recursos.*/.test(aux_tipo)) {
                                return <TableCell key={column.id} align={column.align}></TableCell>;
                              }
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <IconButton aria-controls="Ações" aria-haspopup="true" onClick={(e) => handleMenuClick(e, row.id)}>
                                    <MoreVert />
                                  </IconButton>
                                  <Menu id={`acoes-${row.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl) && selectedRowId === row.id} onClose={handleMenuClose}>
                                    {/* <MenuItem onClick={(e) => handleMenuClose(e, 'edit', row.id)}>Editar</MenuItem> */}
                                    <MenuItem onClick={(e) => handleMenuClose(e, 'show', row.id)}>Visualizar</MenuItem>
                                    <MenuItem onClick={(e) => handleMenuClose(e, 'delete', row.id)}>Deletar</MenuItem>
                                  </Menu>
                                  {!openShow ? <></> : getVizualize(row, Boolean(anchorEl), 'show')}
                                  {!openDel ? <></> : getVizualize(row, Boolean(anchorEl), 'delete')}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage={'Linhas por página'}
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
