import { Card, CardContent, CardHeader, IconButton, ListItemText, Skeleton, Typography } from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import { blue, red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useFetchWeatherForecast } from "./useFetchWeatherForecast";
import { useState } from "react";
import Loading from "../../../../components/Loader/Loading";
import { useEffect } from "react";
import { DateAsText } from "./DateAsText";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import OpacityIcon from "@mui/icons-material/Opacity";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import AirIcon from "@mui/icons-material/Air";

export default function CardWeather({ idFarm }) {
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxCardWeatherxxxxxxxxxxxxxxxxxxxxx");
  console.log(idFarm)
  const { dataWeatherForecast, loadingWeatherForecast } = useFetchWeatherForecast(idFarm);
  const [dataForecast, setDataForecast] = useState([]);
  const [weatherForecast, setWeatherForecast] = useState([]);
  const [forecast, setForecast] = useState([]);

  console.log("dataWeatherForecast");
  console.log(dataWeatherForecast);
  console.log(loadingWeatherForecast);

  // useEffect(() => {
  //   if (!dataWeatherForecast) return
  //   const forecastList = new Map([])
  //   dataWeatherForecast.list.forEach(row => {
  //     const forecastDate = new Date(row.dt_txt)
  //     const key = `${forecastDate.getFullYear()}${forecastDate.getMonth() + 1}${forecastDate.getDate()}`
  //     if (!forecastList.has(key)) {
  //       forecastList.set(key, row)
  //     }
  //   })
  //   setDataForecast(Array.from(forecastList))
  // }, [dataWeatherForecast])

  useEffect(() => {
    console.log("entrou");
    getdataForecast();
  }, [dataWeatherForecast]);

  function getdataForecast() {
    //setForecast(dataWeatherForecast)

    const forecastList = new Map([]);
    console.log("getdataForecast");
    //console.log(forecastList)
    if (Object.entries(dataWeatherForecast).length) {
      console.log(dataWeatherForecast.list);
      const result = dataWeatherForecast.list.map((item) => {
        const forecastDate = new Date(item.dt_txt);
        //console.log(forecastDate);
        const key = `${forecastDate.getFullYear()}${forecastDate.getMonth() + 1}${forecastDate.getDate()}`;
        //console.log(key);
        if (!forecastList.has(key)) {
          forecastList.set(key, item);
        }
      });
      setDataForecast(Array.from(forecastList));
    }
  }

  console.log("Final:   dataForecast ");
  // console.log(dataForecast);

  useEffect(() => {
    if (!dataForecast) return;
    const weatherForecast = dataForecast.map((i) => i[1]);
    setWeatherForecast(weatherForecast.slice(0, 3));
  }, [dataForecast]);

  const convertWindSpeed = (wind) => {
    return 3.6 * wind;
  };
  const convertRainDay = (rain) => {
    if (!rain) {
      return '0.0';
    }
    const rain_key = parseInt(Object.keys(rain)[0].replace('h', ''));
    const rain_val = rain[Object.keys(rain)];
    return ((rain_val / rain_key) * 24).toFixed(1);
  };
  const weather_styles = {
    'temp': { 'paddingLeft': '5px', 'borderLeft': '4px solid #de675a' },
    'rain': { 'paddingLeft': '5px', 'borderLeft': '4px solid #b7b6b6' },
    'wind': { 'paddingLeft': '5px', 'borderLeft': '4px solid #c5d262' },
    'sun': { 'paddingLeft': '5px', 'borderLeft': '4px solid #fedd78' },
    'moon': { 'paddingLeft': '5px', 'borderLeft': '4px solid #c1cbcf' },
    'humidity': { 'paddingLeft': '5px', 'borderLeft': '4px solid #90cbf8' }
  };

  //https://openweathermap.org/weather-data
  //<div sx={{ maxWidth: 345 }}>
  //if (loadingWeatherForecast) return <Skeleton variant="rectangular" width={"100%"} height={80} />

  if (loadingWeatherForecast) return <Skeleton sx={{ m: 3 }} variant="rectangular" width={"100%"} height={280} />
  return (
    <div style={{ 'width': '100%', 'marginLeft': '2%' }}>
      <ListItemText>Localização: {dataWeatherForecast?.city.name}</ListItemText>
      {weatherForecast?.map(({ dt_txt: date, main: { temp_max, temp_min, humidity }, wind: { speed }, weather, rain }, index) => (
        <div key={date} style={{ 'paddingTop': '5px' }}>
          <table className="w-full" style={{ 'width': '100%' }}>
            <thead>
              <tr className="text-sm text-gray-700">
                <th className="font-normal" style={{ 'width': '10%' }}>Dia</th>
                <th className="font-normal" style={{ 'width': '10%' }}>Prev</th>
                <th className="font-normal" style={{ 'width': '20%' }}>Temp</th>
                <th className='font-normal' style={{ 'width': '20%' }}>Chuva</th>
                <th className="font-normal" style={{ 'width': '20%' }}>Vento</th>
                <th className="font-normal" style={{ 'width': '20%' }}>Umidade</th>
              </tr>
            </thead>
            <tbody className="text-sm m-auto text-gray-500">
              <tr className="border-b">
                <td>
                  {/* <div className="flex justify-center items-center weather_data"> */}
                  <div className="flex justify-center items-center ">
                    <strong><DateAsText date={date} /></strong>
                  </div>
                </td>
                <td>
                  <div className="flex justify-center items-center">
                    <img src={`https://openweathermap.org/img/wn/${weather[0].icon}.png`} title={weather[0].description} />
                  </div>
                </td>
                <td style={weather_styles.temp}>
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex justify-center items-center">
                      <ArrowUpwardIcon style={{ color: red[500] }} />
                      {temp_max}º
                    </div>
                    <div className="flex justify-center items-center">
                      <ArrowUpwardIcon style={{ color: blue[500], transform: "rotate(180deg)" }} />
                      {temp_min}º
                    </div>
                  </div>
                </td>
                <td style={weather_styles.rain}>
                  <div className='flex flex-col justify-center items-center'>
                    <div className="flex justify-center items-center">
                      <UmbrellaIcon className='text-lg' style={{ color: blue[500] }} />
                      {convertRainDay(rain, 'value')} mm/d
                    </div>
                  </div>
                </td>
                <td style={weather_styles.wind}>
                  <div className="flex flex-col justify-center items-center">
                    <AirIcon className="text-lg" />
                    <span>{convertWindSpeed(parseInt(speed))} km/h</span>
                  </div>
                </td>
                <td style={weather_styles.humidity}>
                  <div className="flex flex-col justify-center items-center">
                    <OpacityIcon className="text-lg" />
                    <span>{humidity}%</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}



// {
//   "cod":"200","message":0.0045,
//   "city":{"id":1851632,"name":"Shuzenji",
//   "coord":{"lon":138.933334,"lat":34.966671},
//   "country":"JP"},
//   "cnt":38,
//   "list":[{
//           "dt":1406106000,
//           "main":{
//               "temp":298.77,
//               "temp_min":298.77,
//               "temp_max":298.774,
//               "pressure":1005.93,
//               "sea_level":1018.18,
//               "grnd_level":1005.93,
//               "humidity":87},
//           "weather":[{"id":804,"main":"Clouds",
//           "description":"overcast clouds","icon":"04d"}],
//           "clouds":{"all":88},
//           "wind":{"speed":5.71,"deg":229.501},
//           "sys":{"pod":"d"},
//           "dt_txt":"2014-07-23 09:00:00"}
//           ]
// }
