import * as React from "react";
import Button from "@mui/material/Button";
import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { Span } from "../../../../components/Typography";

export default function FormEditInputFooter({ data, setData, handleClose, isError, onSubmit }) {
  return (
    <>
      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>cancelar</Span>
            </Button>
          </Box>
          {data && (
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button color="success" variant="contained" type="submit" onClick={onSubmit} disabled={isError()}>
                Salvar
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
