import * as React from "react";
import json from '../../exemplo_roles_permission.json'

function createData(permission, name, view, edit, trash) {
    return { permission, name, view, edit, trash };
}

function newCreateData(id_role, id_modulo, view, create, trash) {
    return { id_role, id_modulo, view, create, trash };
}

export default function RowsPermissions(role) {

    console.log(role)
    const rowsData = JSON.parse(localStorage.getItem("userPermissionsRolesDefault"));
    console.log(rowsData)
    console.log(rowsData[0])
    console.log(rowsData[1])

    for (let index = 0; index < rowsData.length; index++) {
        const element = rowsData[index];
        // console.log(element)

    }

    // pego o value da role do select - profileCurrent = 1 ou 2 ...
    const profileCurrent = JSON.parse(localStorage.getItem("profileCurrent"));
    console.log('profileCurrent')
    console.log(profileCurrent)

    console.log("newRowsData")

    console.log(profileCurrent)
    var newRowsData = rowsData.filter(row => row.id_role === profileCurrent);
    console.log(newRowsData)

    for (let index = 0; index < newRowsData.length; index++) {
        const element = newRowsData[index];
        // console.log(element)
    }


    console.log("map")

    const maps = newRowsData.map(row => {
        console.log(row)
        let id_role = row.id_role
        let id_modulo = row.id_modulo
        let view = row.view
        let create = row.edit
        let trash = row.delete
        let data = newCreateData(id_role, id_modulo, view, create, trash)
        return data
    }
    )

    const id_role = 'property'
    const id_modulo = 'Mapa propriedade'
    const view = json[role].property.view
    const create = json[role].property.edit
    const trash = json[role].property.delete

    // cria o array de cada módulo
    const newRows = [
        newCreateData(id_role, id_modulo, view, create, trash),
    ];

    console.log(newRows)


    //console.log(role)
    //console.log(json)
    //console.log(json[role])
    //console.log(json[role].property)

    // Em cada módulo contruo variáveis para serem inseridas na criação das linhas
    const permissionProperty = 'property'
    const nameProperty = 'Mapa propriedade'
    const viewProperty = json[role].property.view
    const editProperty = json[role].property.edit
    const trashProperty = json[role].property.delete

    const permissionUsers = 'users'
    const nameUsers = 'Usuários'
    const viewUsers = json[role].users.view
    const editUsers = json[role].users.edit
    const trashUsers = json[role].users.delete

    const permissionMessenger = 'messenger'
    const nameMessenger = 'Mensagens'
    const viewMessenger = json[role].messenger.view
    const editMessenger = json[role].messenger.edit
    const trashMessenger = json[role].messenger.delete

    const permissionUpload = 'upload'
    const nameUpload = 'Upload'
    const viewUpload = json[role].upload.view
    const editUpload = json[role].upload.edit
    const trashUpload = json[role].upload.delete


    // cria o array de cada módulo
    const rows = [
        createData(permissionProperty, nameProperty, viewProperty, editProperty, trashProperty),
        createData(permissionUsers, nameUsers, viewUsers, editUsers, trashUsers),
        createData(permissionMessenger, nameMessenger, viewMessenger, editMessenger, trashMessenger),
        createData(permissionUpload, nameUpload, viewUpload, editUpload, trashUpload),
    ];

    console.log(rows)

    return rows

};

// SELECT r.name AS nome_role, m.nome AS nome_odulo, rp.* 
// FROM   roles_permission AS rp
//   JOIN roles AS r on r.id = rp.id_role 
//   JOIN modulos AS m on m.id = rp.id_modulo  
// ORDER BY r.id ASC