import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function TutorialPatrimony() {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //handleClickOpen()

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountTreeIcon sx={{ mr: 1 }} /> Patrimônio
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão do Patrimônio
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Em Patrimônios, você terá acesso a informações detalhadas sobre os patrimônios da propriedade. Isso inclui:
            </Typography>
          </Box>
          <List component="div" disablePadding>
            <ListItem>
              <Typography>
                <strong>Detalhes precisos</strong> de todos os ativos, como maquinários, equipamentos e instalações.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Registros de manutenção</strong> e condições atuais dos bens.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Localização e utilização</strong> dos recursos
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Histórico de aquisição </strong>e valores de depreciação.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Controle de inventário</strong> e avaliação de ativos.
              </Typography>
            </ListItem>
          </List>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Com essas informações centralizadas, você poderá gerenciar seus recursos de forma mais eficiente e precisa, o que é essencial para otimizar o uso dos bens e maximizar os resultados da
              fazenda.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
