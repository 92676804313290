import React, { useEffect, useState, useContext } from 'react';
import Loading from '../../../../components/Loader/Loading';
import { Card, CardContent, CardMedia, Checkbox, Grid, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useAreasMapReposStore from '../../../../store/useAreasMapReposStore';
import { Context } from '../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { green } from '@mui/material/colors';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    overflow: 'auto',
  },
});

export default function ListAreasMapsHarvest({ data, setData }) {
  const classes = useStyles();
  let file = '';
  const [loading, setLoading] = useState(true);
  const [imageNew, setImageNew] = useState();
  const { aux_add } = useContext(Context);

  // ------ inicicia os repos das Areas ------
  const fetchAreas = useAreasMapReposStore((state) => state);
  // const areaMapList = window.jQuery.extend(true, [], fetchAreas.listAreasMap);
  const areaMapList = JSON.parse(JSON.stringify(fetchAreas.listAreasMap)).reverse();
  const [areas, setAreas] = useState([]);

  //data.areas = []
  // console.log(data);
  // todas as areas da lista
  // console.log(areas);

  // seleciona os ids e adiciona em data.areas
  const selectedIds = {};
  data.areas?.forEach((area) => (selectedIds[area.id] = area.id));
  // Marca áreas selecionadas
  const [selectedId, setSelectedId] = useState(selectedIds);
  // console.log('selectedIds');
  // console.log(selectedIds);

  useEffect(() => {}, []);

  const handleChange = (e, area) => {
    // entra o checkbox selecionado
    // console.log('handleChange');
    // console.log(area);
    selectedIds[area.id] = !selectedIds[area.id];
    setSelectedId({ ...selectedIds });

    if (selectedIds[area.id]) {
      setData((prevState) => ({
        ...prevState,
        areas: [...prevState.areas, area],
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.filter((area_prev) => area_prev.id !== area.id),
      }));
    }

    //setIsLoading(true);
  };

  const handleChangeInput = (e, area) => {
    // console.log('handleChangeInput');
    // console.log(e.target.value, area);
    const usedArea = parseFloat(e.target.value || '0');

    setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, usedArea };
        }
        return area_prev;
      }),
    }));

    const aux_areas = areas.map((area_prev) => {
      if (area_prev.id === area.id) {
        return { ...area_prev, usedArea };
      }
      return area_prev;
    });
    setAreas(aux_areas);
  };

  function imageBuffer() {
    const image = areaMapList.length > 0 ? areaMapList[0].image : '';
    file = image;
    setImageNew(file);
  }

  const setMapAreas = async () => {
    imageBuffer();
    setAreas(areaMapList);
    setLoading(false);
  };

  // useEffect(() => {
  //   if (loading) {
  //     setMapAreas();
  //   }
  // }, []);

  useEffect(() => {
    setMapAreas();

    data.areas?.forEach((area) => (selectedIds[area.id] = area.id));
    setSelectedId(selectedIds);
  }, [aux_add]);

  return (
    <div className="row">
      <div className="col">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: -1 }}>
              <Grid item xs={12} md={12} alignItems="center">
                {!areas.length ? (
                  <>
                    <Typography style={{ color: green[800] }}>
                      <Typewriter tam={30} minHeight={0} text="Desenhe uma área de produção no Google Maps para alocar a sua safra." />
                    </Typography>
                    <Box>
                      <ListItem
                        key={null}
                        disableGutters
                        secondaryAction={
                          <span aria-label="comment">
                            <CheckBoxOutlineBlank style={{ color: green[800] }} />
                          </span>
                        }
                      >
                        <ListItemText
                          // primary={<strong>Como desenhar a área que ficará a cultura no mapa?</strong>}
                          secondary={`Clique em "DESENHAR NOVA ÁREA" e depois clique no botão "SAIBA COMO DESENHAR" para assitir o vídeo explicativo. `}
                        />
                      </ListItem>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography style={{ color: green[800] }}>
                      <Typewriter tam={60} minHeight={0} text="Selecione as áreas desejadas, clicando no quadro ao lado esquerdo da imagem da área e defina a área útil de produção." />
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: -2 }} alignItems="center" justifyContent="center">
              {areas?.map((area, index) => (
                // <Grid key={area.id} item container alignItems="center" justifyContent="center">
                <Grid key={'box_' + area.id} item xs="auto">
                  <Card sx={{ display: 'flex' }} className={selectedId[area.id] ? classes.selected : null}>
                    <ListItemIcon>
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': {
                            // Aplica os estilos ao ícone do Checkbox
                            animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao ícone
                          },
                        }}
                        checked={selectedId[area.id] ? true : false}
                        onChange={(e) => handleChange(e, area)}
                        color="success"
                      />
                      <CardMedia component="img" sx={{ width: 100 }} image={area?.url || area?.imageDecode} alt={area.name} />
                      <CardContent sx={{ flex: 1, minWidth: 175 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Área: {area.name}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            <i className="fa fa-map text-success">
                              {' '}
                              {area.area} ({area.measure})
                            </i>
                          </Typography>
                        </Box>
                        {/* <TextField
                          label={'Área utilizada:'}
                          disabled={!selectedId[area.id] ? true : false}
                          sx={{ marginRight: 2 }}
                          vaGridriant="standard"
                          size="small"
                          onChange={(e) => handleChangeInput(e, area)}
                          id={area.id + ''}
                          name={area.name}
                          max={area.area}
                          defaultValue={selectedIds[area.id] || ''}
                        /> */}
                        <TextField
                          label={'Área utilizada:'}
                          disabled={!selectedId[area.id] ? true : false}
                          sx={{ minWidth: '125px' }}
                          variant="standard"
                          color="success"
                          size="small"
                          type="number" // Permite apenas entrada de números
                          onChange={(e) => {
                            // Converte o valor de entrada em número
                            let value = parseFloat(e.target.value || '0');
                            // Valida para não ultrapassar o valor máximo de area.area
                            if (value > area.area) {
                              value = area.area;
                            }
                            // Chama a função handleChangeInput com o valor ajustado
                            handleChangeInput({ ...e, target: { ...e.target, value: value } }, area);
                          }}
                          id={area.id + '_' + index}
                          name={area.name}
                          inputProps={{ max: area.area, min: 0 }} // Limita o valor máximo a area.area
                          value={area.usedArea || ''}
                        />
                      </CardContent>
                    </ListItemIcon>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}
