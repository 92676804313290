import { useQuery } from "react-query";
import api from "../services/api";

const apiGetPermission = async (id) => {
  const header = { header: { "Content-Type": "application/json" } };
  console.log('id')
  console.log(id)
  const id_user = { id_user: id }
  const response = await api.post("/permissions/user/permission", id_user, header);
  console.log('apiGetPermission');
  console.log(response);
  if (response.data.isSuccess) {
    // localStorage.setItem("userPermissions", JSON.stringify(response.data._value));
    return response.data._value[0];
  }
};

// api.post("/permissions/user/permission", id_user, header).then((response) => {
//   console.log('apiGetPermission')
//   console.log(response.data)
//   localStorage.setItem("userPermissions", JSON.stringify(response.data._value));
//   return response.data._value;
// }

// com esse hook, adicionamos cache em cima da função apiGetPermission
export default function useGetUserByPermissionRepos(id) {

  const onSuccess = () => {
    console.log('Sucesso: Executar o efeito após buscar os dados')
  }

  const onError = (error) => {
    console.log('Erro: Executar o efeito após encontrar o erro', error)
  }

  return useQuery(['useGetUserByPermissionRepos'], apiGetPermission(id))
}
