import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { useEffect } from 'react'
import { useState } from 'react'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#14db22' : '#4fb028',
  },
}))

export default function IEProgressBars({ percentage }) {
  console.log('IEProgressBars ')
  console.log(percentage)
  //console.log(newData.length)
  const [color, setColor] = useState(undefined)
  let col = undefined

  // useEffect(() => {
  //   if (newData?.length > 0) {
  //     console.log(newData[0]?.product.quantity)
  //     console.log(newData[0]?.product.porcentagemMin)
  //     const col = newData[0]?.product.quantity < newData[0]?.product.porcentagemMin ? red[500] : undefined
  //     console.log(col)
  //     setColor(col)
  //   }
  // }, [])

  // //const color = newData[0]?.product.quantity < newData[0]?.porcentagemMin ? red[500] : undefined
  // console.log(color)
  // //console.log(bar)
  // console.log(newData)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <br />
      <BorderLinearProgress
        variant="determinate"
        value={percentage}
        aria-label="stock-progress"
        sx={{ '& .MuiLinearProgress-bar': { backgroundColor: color } }}
      />
      <Typography variant="caption" component="div" color="text.secondary">
        {`${Math.round(percentage)}%`}
      </Typography>
    </Box>
  )
}
