import * as React from 'react'
import Box from '@mui/material/Box'
import { ButtonBase, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import Typewriter from '../../components/Typewriter/Typewriter'
import { useState } from 'react'
import InputIcon from '@mui/icons-material/Input'
import { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import CardMeatProductionCreation from '../planning/components/MeatProduction/CardMeatProductionCreation'
import CardMeatProductionCut from '../planning/components/MeatProduction/CardMeatProductionCut'
import { IconBeefCattle } from '../../components/Icons/unit/IconBeefCattle'
import CardMeatProductionArea from './MeatProduction/CardMeatProductionArea'
import useStore from '../../store/useStore'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function PlanningHarvestContentchoice({ area, index }) {
  console.log('PlanningHarvestContentchoice')
  console.log(area)
  //const classes = useStyles();
  //const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(true)
  //const usestore = useStore();

  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 1000);

  // setTimeout(() => {
  //   setIsLoadingText(true);
  // }, 3000);

  // const property_id = localStorage.getItem("Property") || 1;
  //const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  //const reposHarvest = usestore.reposHarvest.getHarvestStore("id", usestore.reposHarvest.harvestRepos.id)[0];
  //console.log("reposHarvest")
  //console.log(reposHarvest)

  //const listAreas = usestore.reposAreas.listAreasMap;
  //console.log("listAreas1")
  //console.log(listAreas)

  // Crie um array com os IDs das áreas
  //const areaIds = reposHarvest.areas.map((area) => area.id);

  // Use o método filter() para filtrar os elementos de listAreas com IDs presentes em areaIds
  //const listAreasHarvest = listAreas.filter((area) => areaIds.includes(area.id));

  //console.log(listAreasHarvest);

  // const [showComponents, setShowComponents] = useState([]);
  // const [selectedTab, setSelectedTab] = useState(0);

  // const arrayButtons = [];
  // for (const area of listAreasHarvest) {
  //   const item = { name: area.name, active: false, title: area.name, measure: area.measure, area: area.area, image: area.imageDecode, data: area, dataHarvest: reposHarvest };
  //   arrayButtons.push(item);
  // }
  // //console.log(arrayButtons)

  // // como sei quais são as aŕeas usadas na havesrt
  // const [buttons, setButtons] = useState(arrayButtons);

  // console.log(arrayButtons)

  // const handleButtonClick = (buttonName, buttonIndex) => {
  //   // console.log();
  //   const updatedButtons = buttons.map((button, index) => {
  //     if (button.name === buttonName) {
  //       button.active = !button.active;
  //     } else {
  //       button.active = false;
  //     }
  //     return button;
  //   });

  //   setButtons(updatedButtons);

  //   const button = buttons.find((button) => button.name === buttonName);

  //   if (button) {
  //     if (button.active) {
  //       setShowComponents([button.name]);
  //       setSelectedTab(buttonIndex);
  //     } else {
  //       setShowComponents([]);
  //       setSelectedTab(0);
  //     }
  //   }
  // };

  //console.log(showComponents);
  //console.log("buttons")
  //console.log(area)
  //console.log(buttons)

  console.log(area.target)
  const target_active = (area?.target).filter((item) => {
    if (item.isActiveTargetHarvest) {
      return item
    }
  })[0]

  console.log(target_active)

  console.log(area)
  console.log(area.dataCost)
  const cost_active = (area?.dataCost).filter((item) => {
    if (item?.id_target == target_active?.id) {
      return item
    }
  })[0]

  console.log('cost_active1s')
  console.log(cost_active)

  return (
    <>
      {false === true ? (
        <>
          <Card sx={{ mt: 0 }}>
            <Skeleton variant="rectangular" width="100%" height={110} />
          </Card>
          <Card sx={{ mt: 0.5 }}>
            <Skeleton variant="rectangular" width="100%" height={155} />
          </Card>
        </>
      ) : (
        <>
          {area && (
            <>
              <Card sx={{ mt: 1 }}>
                <CardHeader
                  title={
                    <>
                      <div className="d-lg-none">
                        <Box sx={{ display: 'flex', mb: 1 }}>
                          <Typography sx={{ fontSize: 18 }}>Meta a Produzir</Typography>
                        </Box>
                        <Box sx={{ minHeight: 110 }}>
                          <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={110} minHeight={0} text="Meta de produção ativa para produção." />}</Typography>
                        </Box>
                      </div>
                      <div className="d-none d-lg-block">
                        <Box sx={{ display: 'flex', mb: 1 }}>
                          <Typography variant="h6" color="textPrimary">
                            Meta a Produzir
                          </Typography>
                        </Box>
                        <Box sx={{ minHeight: 30 }}>
                          <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={30} minHeight={0} text="Meta de produção ativa para produção." />}</Typography>
                        </Box>
                      </div>
                    </>
                  }
                ></CardHeader>
                <Divider />
              </Card>
              <Card sx={{ mt: 1, mb: 10 }}>
                {/* <Container component="main" maxWidth="" sx={{ mb: 0, maxHeight: '260px', overflowY: 'auto' }}>
                  <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6} md={4} key={area.name}>
                        <ButtonBase
                          sx={{
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: area.active ? '2px solid green' : '2px solid transparent',
                            width: '100%', // Certifica-se de que o botão ocupe toda a largura do Grid item
                          }}
                          onClick={() => handleButtonClick(area.name, index)}
                        >
                          <Card sx={{ width: '100%' }}>
                            <CardMedia
                              component="img"
                              height="140" // Ajuste a altura proporcionalmente à largura desejada
                              image={area.image}
                              alt="imagem"
                            />
                            <CardContent>
                              <Typography align="center" fontWeight={400} variant="body1">
                                {area.title} - {area.area} {area.measure}
                              </Typography>
                            </CardContent>
                          </Card>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Paper>
                </Container> */}

                <div key={area.name}>
                  <div>{area && <CardMeatProductionArea area={area} target_active={target_active} cost_active={cost_active} />}</div>
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  )
}

//https://aegro.com.br/termos-de-uso/
