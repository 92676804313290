import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextAddProjectStepper from '../../../livestock/components/addLivestock/tutorial/TextAddProjectStepper';
import FlagIcon from '@mui/icons-material/Flag';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import TutorialaddActivitiesAccordion from './TutorialaddActivitiesAccordion';

export default function TutorialaddActivities({ openModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //handleClickOpen()

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountTreeIcon sx={{ mr: 1 }} /> Criação das atividades de manejo
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão Agrícola
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              As atividades de manejo na agricultura são essenciais para o gerenciamento da fazenda, pois ajudam a garantir o controle, a saúde e o bem-estar das culturas, otimizam a produtividade,
              permitem o planejamento estratégico e apoiam a tomada de decisões fundamentadas.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={300}>
              Aqui você poderá definir os tipos de atividades que deseja realizar.
            </Typography>
          </Box>
          {/* <DialogContentText id="alert-dialog-description">
            Vamos aqui registrar o projeto da sua criação para posteriormente iniciarmos o gerenciamento das atividades e dos custos da sua lavoura!
          </DialogContentText> */}
          <TutorialaddActivitiesAccordion />
          {/* <TextAddProjectStepper /> */}
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
