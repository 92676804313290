import React, { useState } from 'react';
import { IconButton, Badge, Menu, MenuItem, Box, Stack, Typography, Alert, AlertTitle, Divider, Card, CardHeader, ListItemIcon, Paper } from '@mui/material';
import {
  Notifications as NotificationsIcon,
  NotificationsActive as NotificationsActiveIcon,
  Logout as LogoutIcon,
  PendingActions as PendingActionsIcon,
  Start as StartIcon,
} from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import ChatPlanModal from '../../../pages/chat/ChatPlanModal';
import IAIcon from '../../Icons/chat/IAIcon';
import IAIconWhite from '../../Icons/chat/IAIconWhite';
import NotificationsAlertCardEmpty from './NotificationsAlertCardEmpty';
import useStore from '../../../store/useStore';
import propertySettingsProgress from '../../../pages/indexExperience/components/propertySettingsProgress';
import propertyProgress from '../../../pages/indexExperience/components/propertyProgress';
import livestockProgress from '../../../pages/indexExperience/components/livestockProgress';
import { createNotificationPropertySetting } from './alertList/createNotificationPropertySetting';
import { createNotificationLivestockCreation } from './alertList/createNotificationLivestockCreation';
import harvestProgress from '../../../pages/indexExperience/components/harvestProgress';
import { createNotificationHarvest } from './alertList/createNotificationHarvest';

// Componente para uma notificação
// const NotificationItem = ({ item }) => {
//   const getIcon = () => {
//     switch (item.type) {
//       case 'activity':
//         return <PendingActionsIcon style={{ fontSize: 24 }} />;
//       default:
//         return null;
//     }
//   };

//   const getSeverity = () => {
//     switch (item.type) {
//       case 'info':
//         return 'info';
//       case 'warning':
//         return 'warning';
//       case 'error':
//         return 'error';
//       default:
//         return 'info';
//     }
//   };

//   return (
//     <>
//       {item.type === 'activity' ? (
//         <Card sx={{ ml: 2, mr: 2, mt: 1 }}>
//           <CardHeader
//             sx={{ m: -1, ml: -1 }}
//             avatar={getIcon()}
//             title={<Typography fontWeight={700}>{item.title}</Typography>}
//             subheader={item.text}
//             action={
//               <IconButton aria-label="settings">
//                 <StartIcon />
//               </IconButton>
//             }
//           />
//         </Card>
//       ) : (
//         <MenuItem>
//           <Alert severity={getSeverity()} sx={{ width: '450px' }}>
//             <AlertTitle>{item.title}</AlertTitle>
//             <Typography component="div" variant="subtitle2" sx={{ whiteSpace: 'normal' }}>
//               {item.text}
//             </Typography>
//           </Alert>
//         </MenuItem>
//       )}
//     </>
//   );
// };

// Componente principal de notificação
const NotificationsAlert = () => {
  const usestore = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openChat, setOpenChat] = useState(false);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setTimeout(() => {
    //   handleLeft();
    // }, 10);
  };

  // const handleLeft = () => {
  //   window.jQuery('#account_menu_user').find('.MuiPaper-root').css({ left: '', right: '13%' });
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const listNotifications = [
  //   { group: 'Geral', type: 'info', title: 'Informação', text: 'Existem animais para serem identificados Lote cria 1' },
  //   { group: 'Geral', type: 'warning', title: 'Alerta', text: 'A data de término da atividade de entrada do lote Recria 6 encontra-se finalizada.' },
  //   { group: 'Geral', type: 'error', title: 'Importante', text: 'Verifique o manejo sanitário de seus animais.', icon: <PendingActionsIcon style={{ fontSize: 24 }} />, color: 'success' },
  //   {
  //     group: 'Criação',
  //     type: 'activity',
  //     title: 'Entrada',
  //     text: 'A data de término da atividade de entrada do lote Recria 6 encontra-se finalizada.',
  //     icon: <PendingActionsIcon style={{ fontSize: 24 }} />,
  //     color: 'success',
  //   },
  //   { group: 'Harvest', type: 'activity', title: 'identification', text: 'texto', icon: <PendingActionsIcon style={{ fontSize: 24 }} />, color: 'success' },
  // ];

  //const listNotifications = [];

  const listNotifications = [];

  // primeira notificação: configuraçẽos da propriedade
  const property = usestore.reposProperty.listProperty;
  const propertyProg = propertyProgress(property);
  const { notification, hasIncomplete } = createNotificationPropertySetting(propertyProg);

  if (hasIncomplete) {
    listNotifications.push(notification);
  }
  // fim primeira notificação

  // noticação da criação

  const livestock = usestore.reposLivestock.listLivestock;
  //console.log(livestock);
  const livestockProg = livestockProgress(livestock);
  //console.log('livestockProg');
  //console.log(livestockProg);
  const { notificationLivestock, hasIncompleteLivestock } = createNotificationLivestockCreation(livestockProg);
  //console.log('notificationLivestock, hasIncompleteLivestock');
  //console.log(notificationLivestock, hasIncompleteLivestock);

  if (hasIncompleteLivestock) {
    listNotifications.push(notificationLivestock);
  }
  // noticação da criação

  // noticação da safra

  const harvest = usestore.reposHarvest.listHarvest;
  // console.log(harvest);
  // console.log(harvest);
  const harvestProg = harvestProgress(harvest);
  // console.log('harvestProg');
  // console.log(harvestProg);
  const { notificationHarvest, hasIncompleteHarvest } = createNotificationHarvest(harvestProg);
  // console.log('notificationLivestock, hasIncompleteLivestock');
  // console.log(notificationHarvest, hasIncompleteHarvest);

  if (hasIncompleteHarvest) {
    listNotifications.push(notificationHarvest);
  }
  // noticação da safra

  const groupedNotifications = listNotifications.reduce((acc, notification) => {
    (acc[notification.group] = acc[notification.group] || []).push(notification);
    return acc;
  }, {});
  // console.log('groupedNotifications');
  // console.log(groupedNotifications);

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ mt: -1 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
        <Badge badgeContent={listNotifications.length} style={{ color: 'black' }}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account_menu_user"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 430,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 200,
              height: 100,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            <NotificationsActiveIcon />
            <Typography sx={{ ml: 1, fontWeight: 'bold' }}>Avisos inteligentes</Typography>
          </Box>
        </Box>
        <Divider />
        {Object.keys(groupedNotifications).length === 0 ? (
          <Box sx={{ height: '600px', overflowY: 'auto' }}>
            <NotificationsAlertCardEmpty />
          </Box>
        ) : (
          <Box sx={{ height: '600px', overflowY: 'auto' }}>
            {Object.keys(groupedNotifications).map((group) => (
              <React.Fragment key={group}>
                <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>
                  {group}
                </Typography>
                {groupedNotifications[group].map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type === 'activity' && (
                      <Card sx={{ ml: 2, mr: 2, mt: 1 }}>
                        <CardHeader
                          avatar={item.icon}
                          title={<Typography fontWeight={700}>{item.title}</Typography>}
                          subheader={item.text}
                          action={
                            <NavLink to={item.link} className="nav-link">
                              <IconButton aria-label="settings" onClick={() => handleClose()}>
                                <StartIcon />
                              </IconButton>
                            </NavLink>
                          }
                        />
                      </Card>
                    )}
                    {item.type === 'warning' && (
                      <MenuItem>
                        <NavLink to={item.link} className="nav-link">
                          <Alert severity="warning" sx={{ width: '100%' }}>
                            <AlertTitle>Alerta</AlertTitle>
                            <Typography variant="subtitle2" sx={{ whiteSpace: 'normal' }}>
                              {item.text}
                            </Typography>
                          </Alert>
                        </NavLink>
                      </MenuItem>
                    )}
                    {item.type === 'info' && (
                      <MenuItem onClick={() => handleClose()}>
                        <NavLink to={item.link} className="nav-link">
                          <Alert severity="info" sx={{ width: '100%' }}>
                            <AlertTitle>Informação</AlertTitle>
                            <Typography variant="subtitle2" sx={{ whiteSpace: 'normal' }}>
                              {item.text}
                            </Typography>
                          </Alert>
                        </NavLink>
                      </MenuItem>
                    )}
                    {item.type === 'error' && (
                      <MenuItem>
                        <Alert severity="error" sx={{ width: '100%' }}>
                          <AlertTitle>Importante</AlertTitle>
                          <Typography variant="subtitle2" sx={{ whiteSpace: 'normal' }}>
                            {item.text}
                          </Typography>
                        </Alert>
                      </MenuItem>
                    )}
                  </React.Fragment>
                ))}
                <Divider sx={{ mt: 2 }} />
              </React.Fragment>
            ))}
          </Box>
        )}
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Fechar
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotificationsAlert;
