import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField, Grid, Stack } from '@mui/material';

/* Exemple:
  let obj_date = {
    label: 'Data inicial do saldo',
    field_data: 'balance_date',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    min: new Date(),
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
  }
*/
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' };

export default function DateFieldEditActivities({ data, obj_date }) {
  const [date_start, setValueStart] = useState(dayjs(data.date_start));
  const [date_prev_end, setValuePrevEnd] = useState(dayjs(data.date_prev_end));
  const [date_end, setValueEnd] = useState(data.date_end ? dayjs(data.date_end) : null);

  const isErrorDate = (tipo, cor) => {
    let iserror = false;
    if (tipo === 'all' || tipo === 'inicio') {
      if (!date_start.$D) {
        iserror = true;
      } else if (obj_date.min && dayjs(date_start).isBefore(dayjs(obj_date.min), 'day')) {
        iserror = true;
      }
    }
    if (tipo === 'all' || tipo === 'fim') {
      if (data.progress === 'CONCLUÍDA' && date_end) {
        if (!date_end.$D) {
          iserror = true;
        }
        if (obj_date.max) {
          iserror = dayjs(date_end).isAfter(dayjs(obj_date.max), 'day');
        }
      } else {
        if (!date_prev_end.$D) {
          iserror = true;
        }
        if (obj_date.max) {
          iserror = dayjs(date_prev_end).isAfter(dayjs(obj_date.max), 'day');
        }
      }
    }

    if (cor) {
      return iserror ? 'error' : 'success';
    }
    return iserror;
  };

  useEffect(() => {
    let valid = isErrorDate('all');
    if (valid !== data.error_date) {
      obj_date.setData((prevState) => ({
        ...prevState,
        error_date: valid,
      }));
    }
  }, [date_start, date_prev_end, date_end]);

  const handleChangeDate = (field, value) => {
    obj_date.setData((prevState) => ({
      ...prevState,
      [field]: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
    }));

    obj_date.setUpdated(1);
  };

  return (
    <>
      <Grid container style={{ maxWidth: obj_date.maxWidth || '100%' }}>
        <Grid item xs={12} md={12} sx={{ my: 2 }}>
          <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="d-lg-none">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      label={'Data de Início'}
                      value={date_start}
                      onChange={(newValue) => {
                        handleChangeDate('date_start', newValue);
                        setValueStart(dayjs(newValue));
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={obj_date.min || null}
                      maxDate={date_end || date_prev_end || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color={isErrorDate('inicio', 'color')}
                          error={isErrorDate('inicio')}
                          helperText={
                            isErrorDate('inicio') ? <>
                              <p className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            </> : <>
                              <p className="form-text text-succes is-valid">
                                Data de Início da atividade
                              </p>
                            </>
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      label="Data de Término*"
                      value={data.progress === 'CONCLUÍDA' ? date_end : date_prev_end}
                      onChange={(newValue) => {
                        if (data.progress === 'CONCLUÍDA') {
                          handleChangeDate('date_end' , newValue);
                          setValueEnd(dayjs(newValue));
                        } else {
                          handleChangeDate('date_prev_end', newValue);
                          setValuePrevEnd(dayjs(newValue));
                        }
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={date_start || null}
                      maxDate={data.progress === 'CONCLUÍDA' ? new Date() : (obj_date.max || null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color={isErrorDate('fim', 'color')}
                          error={isErrorDate('fim')}
                          helperText={
                            isErrorDate('fim') ? <>
                              <p className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            </> : <>
                              <p className="form-text text-succes is-valid">
                                Data de Término da atividade
                              </p>
                            </>
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <div className="d-none d-lg-block">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      label={'Data de Início'}
                      value={date_start}
                      onChange={(newValue) => {
                        handleChangeDate('date_start', newValue);
                        setValueStart(dayjs(newValue));
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={obj_date.min || null}
                      maxDate={date_end || date_prev_end || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color={isErrorDate('inicio', 'color')}
                          error={isErrorDate('inicio')}
                          helperText={
                            isErrorDate('inicio') ? <>
                              <p className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            </> : <>
                              <p className="form-text text-succes is-valid">
                                Data de Início da atividade
                              </p>
                            </>
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      label="Data de Término*"
                      value={data.progress === 'CONCLUÍDA' ? date_end : date_prev_end}
                      onChange={(newValue) => {
                        if (data.progress === 'CONCLUÍDA') {
                          handleChangeDate('date_end' , newValue);
                          setValueEnd(dayjs(newValue));
                        } else {
                          handleChangeDate('date_prev_end', newValue);
                          setValuePrevEnd(dayjs(newValue));
                        }
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={date_start || null}
                      maxDate={data.progress === 'CONCLUÍDA' ? new Date() : (obj_date.max || null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color={isErrorDate('fim', 'color')}
                          error={isErrorDate('fim')}
                          helperText={
                            isErrorDate('fim') ? <>
                              <p className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            </> : <>
                              <p className="form-text text-succes is-valid">
                                Data de Término da atividade
                              </p>
                            </>
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
}
