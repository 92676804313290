import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import api from '../../services/api'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'


// create the store
const useActivitySoilPreparationReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivitySoilPreparation: [],
        listOperations: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!item) {
            item = { id_property: id_property }
          } else if (!item.id_property) {
            item.id_property = id_property
          }
          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activitySoilPreparation/list', item, header)
          const data = await response.data
          // console.log('activitySoilPreparation')
          // console.log(data)
          var aux_op
          var defalt_operations = ['Aração', 'Gradagem', 'Subsolagem', 'Rotação de culturas', 'Calagem', 'Adubação']

          await set({
            listActivitySoilPreparation: data._value.map((activitySoilPreparation) => {
              aux_op = activitySoilPreparation.operation
              if (aux_op && !defalt_operations.includes(aux_op)) {
                defalt_operations.push(aux_op)
              }
              return {
                id: activitySoilPreparation.id,
                id_property: activitySoilPreparation.id_property,
                id_user: activitySoilPreparation.id_user,
                name_user: activitySoilPreparation.name_user,
                id_harvest: activitySoilPreparation.id_harvest,
                areas: activitySoilPreparation.areas ? JSON.parse(activitySoilPreparation.areas) : JSON.parse(activitySoilPreparation.info),
                operation: aux_op,
                /* produto utilizado por área
                  areas.map((area) => (
                    area.id_area
                    area.id_product
                    area.patrimony
                    area.quant
                    area.value
                    area.usedArea
                */

                date_start: dayjs(activitySoilPreparation.date_start).format('YYYY-MM-DD HH:mm:ss'),
                date_start_f: dayjs(activitySoilPreparation.date_start).format('DD/MM/YYYY'),
                date_prev_end: dayjs(activitySoilPreparation.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                date_prev_end_f: dayjs(activitySoilPreparation.date_prev_end).format('DD/MM/YYYY'),
                date_end: activitySoilPreparation.date_end ? dayjs(activitySoilPreparation.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
                date_end_f: activitySoilPreparation.date_end ? dayjs(activitySoilPreparation.date_end).format('DD/MM/YYYY') : '',
                description: activitySoilPreparation.description,
                isDeleted: activitySoilPreparation.isDeleted,
                info_areas: JSON.parse(activitySoilPreparation.info),

                //id_transaction: activitySoilPreparation.id_transaction,
                //info_transaction: activitySoilPreparation.info_transaction ? JSON.parse(activitySoilPreparation.info_transaction) : '',
                //record: activitySoilPreparation.record ? JSON.parse(activitySoilPreparation.record) : '',

                type: 'activity',
                activity: activitySoilPreparation.name,
                name_activity: activitySoilPreparation.name,
                progress: activitySoilPreparation.progress || 'CONCLUÍDA',
                planning: activitySoilPreparation.planning || 'realized',
                tipo_repos: 'PREPARAÇÃO DO SOLO',

                resources: JSON.parse(activitySoilPreparation.resources || '[]'),
              }
            }),
          })

          set({
            listOperations: defalt_operations
          });

          const lista = JSON.parse(localStorage.getItem('listActvsH'))
          const lista_i = get().listActivitySoilPreparation

          let array_aux = lista.filter((activity) => {
            if (!/PREPARAÇÃO DO SOLO.*/.test(activity.activity)) {
              return activity
            }
          })
          const res = array_aux.concat(lista_i)
          localStorage.setItem('listActvsH', JSON.stringify(res))

          return data
        },
        // pega o(s) plantio(s) da listActivityPlanting de acordo com o [key e val] informados
        getActivitySoilPreparationStore: (key, val) => {
          return get().listActivitySoilPreparation.filter((activitySoilPreparation) => {
            if (activitySoilPreparation[key] == val) {
              return activitySoilPreparation
            }
          })
        },
        // // Adiciona um nov plantio à listActivitySoilPreparation
        addActivitySoilPreparationStore: (plantio) => {
          set((state) => ({
            listActivitySoilPreparation: [...state.listActivitySoilPreparation, plantio],
          }))
        },
        // Gera uma nova listActivityPlanting sem a activity informada
        delActivitySoilPreparationStore: (id) => {
          set({
            listActivitySoilPreparation: get().listActivitySoilPreparation.filter((activity) => {
              if (activity.id != id) {
                return activity
              }
            }),
          })
        },
        // atualiza listActivityPlanting de acordo com id, key e val informados
        updateActivitySoilPreparationStore: (id, key, val) => {
          set({
            listActivitySoilPreparation: get().listActivitySoilPreparation.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val
                } else {
                  activity[key] = val
                }
              }
              return activity
            }),
          })
          return get().listActivitySoilPreparation
        },
      }),
      { name: 'reposActivitySoilPreparation' }
    )
  )
)

export default useActivitySoilPreparationReposStore
