import * as React from 'react'
import { Accordion, Box, Card, CardMedia, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Input, Map } from '@mui/icons-material';
import dayjs from 'dayjs';
import FooterModal from '../../../../../components/Footer/FooterModal';
import RoomIcon from '@mui/icons-material/Room';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

export default function FormMonitoringStep2({ data, obj_fn }) {
  // console.log(data);
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas progresso do monitoramento</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Área(s) de plantio */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) de monitoramento</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12}>
                <Card key={'C1_' + area.id} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, padding: 1 }} id="testando_card">
                  <Grid item xs={12} md={4}>
                    {/* Área da imagem do mapa */}
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                      <Grid item xs={10}>
                        <CardMedia key={'CM_' + area.id} component="img" sx={{ height: { xs: '100%', md: 100 } }} image={area.url} alt={area.name} />
                      </Grid>
                      {/* Área dos textos e inputs */}
                      <Grid item key={'G2_' + area.id} xs={12} sx={{ ml: 1.5 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          {area.name}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          <i className="fa fa-map text-success">
                            {' '}
                            {area.area} ({area.measure})
                          </i>
                        </Typography>
                        <ListItem>
                          <ListItemText primary="Área utilizada" secondary={area.usedArea || area.area} />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item key={'G3_' + area.id} xs={12} md={8} sx={{ mt: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <ListItem>
                          <ListItemText primary="Insetos/Doenças/Erva Daninha" secondary={area.monitoringName} />
                        </ListItem>
                      </Grid>
                      {area.areaTranferImage && (
                        <Grid item xs={12}>
                          <Accordion>
                            <Grid container spacing={2} sx={{ mt: 0 }}>
                              <Grid item>
                                <RoomIcon sx={{ color: 'action.active', ml: 2 }} />
                              </Grid>
                              <Grid item>
                                <Typography sx={{ ml: -1 }}>Monitoramento</Typography>
                              </Grid>
                              <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                                <CardMedia component="img" height="140" image={area.areaTranferImage} alt="Área" />
                              </Grid>
                            </Grid>
                          </Accordion>
                        </Grid>
                      )}
                      {area.imagePhoto && (
                        <Grid item xs={12}>
                          <Accordion>
                            <Grid container spacing={2} sx={{ mt: 0 }}>
                              <Grid item>
                                <WallpaperIcon sx={{ color: 'action.active', ml: 2 }} />
                              </Grid>
                              <Grid item>
                                <Typography sx={{ ml: -1 }}>Imagem</Typography>
                              </Grid>
                              <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                                <CardMedia component="img" height="140" image={area.imagePhoto} alt="Área" />
                              </Grid>
                            </Grid>
                          </Accordion>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrições */}
        {/* <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid> */}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
