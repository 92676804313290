import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { red } from "@mui/material/colors";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Context } from "../../../../components/Context/AuthContext";
import { useContext } from "react";

function DeleteModalPlanLivestock({ planId, reposPlanning, idFarm, plan }) {
  const [open, setOpen] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmDelete = () => {
    handleDelete(planId);
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #FF0000",
    boxShadow: 24,
    p: 4,
  };

  const handleDelete = async (planId) => {
    // console.log("deletando: ");
    // console.log(planId);
    const header = { header: { "Content-Type": "application/json" } };
    const deleteData = { id: plan.id, id_livestock: plan.id_livestock, id_property: plan.id_property };
    // console.log(deleteData);
    const res = await api
      .post("/planning/deleteTargetLivestockController", deleteData, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error("Erro ao deletar a Meta!");
        }
        if (response.data.isSuccess) {
          toast.success("Meta deletada com sucesso!");
          reposPlanning.fetch({ id_farm: idFarm }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
            }, 200);
          });
          // setTimeout(() => {
          //   setIsLoading(true);
          // }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao deletar a Meta!");
      });

    // console.log(id);
    // console.log("deletou");
    // const isDeleted = "1";
    // const res = await api.delete(`/messenger/posts/${id}/${isDeleted}`);
    // console.log(res.data);
    //setIsLoading(true)
    //console.log(this.state.selectedFile);
  };

  return (
    <>
      <IconButton variant="outlined" color="success" onClick={handleOpen}>
        <DeleteForeverIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: red[500] }} />
      </IconButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmar a exclusão
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja realmente excluir essa meta?
          </Typography>
          <Button onClick={handleConfirmDelete} color="error">
            Excluir
          </Button>
          <Button onClick={handleClose} color="success">
            Não excluir
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteModalPlanLivestock;
