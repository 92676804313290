import React, { useState } from 'react';
import { Button, IconButton, Paper, TextareaAutosize, Typography } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
//import { api } from '../../../lib/api';
import { CloseButton } from './CloseButton';

import { ScreenshotButton } from './ScreenshotButton';
import { feedbackTypes } from './feedbackTypes';

export function FeedbackContentStep({ feedbackType, onFeedbackRestartRequested, onFeedbackSent, handleClose }) {
  const [screenshot, setScreenshot] = useState(null);
  const [comment, setComment] = useState('');
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  const feedbackTypeInfo = feedbackTypes[feedbackType];

  async function handleSubmitFeedback(event) {
    event.preventDefault();

    setIsSendingFeedback(true);

    const data = {
      type: feedbackType,
      comment,
      screenshot,
    };

    console.log(data);

    // await api.post('/feedbacks', {
    //   type: feedbackType,
    //   comment,
    //   screenshot,
    // });

    setIsSendingFeedback(false);
    onFeedbackSent();
  }

  return (
    <>
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton style={{ color: 'white' }} onClick={onFeedbackRestartRequested} title="Voltar">
          <ArrowLeft style={{ width: 30, height: 30 }} />
        </IconButton>
        <span className="text-xl leading-6 flex items-center gap-2">
          <Typography style={{ color: 'white' }} variant="h6">
            <img src={feedbackTypeInfo.image.source} alt={feedbackTypeInfo.image.alt} style={{ width: 24, height: 24 }} />
            {feedbackTypeInfo.title}
          </Typography>
        </span>
        <CloseButton handleClose={handleClose} />
      </header>

      <form className="my-4 w-full" onSubmit={handleSubmitFeedback}>
        <TextareaAutosize
          style={{
            minWidth: 304,
            width: '100%',
            minHeight: 112,
            fontSize: '0.875rem',
            color: 'black',
            border: '1px solid #2e7d32',
            borderRadius: 4,
            padding: 8,
            resize: 'none',
            backgroundColor: 'white',
            scrollbarWidth: 'thin',
          }}
          placeholder="Conte com detalhes o que está acontecendo..."
          onChange={(event) => setComment(event.target.value)}
        />

        <footer style={{ display: 'flex', gap: 2, marginTop: 8 }}>
          <ScreenshotButton screenshot={screenshot} onScreenshotTaken={setScreenshot} />
          <Button
            type="submit"
            disabled={comment.length === 0 || isSendingFeedback}
            variant="contained"
            color="success"
            sx={{ width: '100%' }}
            // style={{
            //   flex: 1,
            //   backgroundColor: '#6D28D9',
            //   color: '#FFFFFF',
            //   padding: '8px',
            //   borderRadius: 4,
            //   fontSize: '0.875rem',
            //   textTransform: 'none',
            //   '&:hover': { backgroundColor: '#7C3AED' },
            //   '&:focus': { outline: 'none' },
            //   '&:disabled': { opacity: 0.5 },
            // }}
          >
            {isSendingFeedback ? <p>loading</p> : 'Enviar feedback'}
          </Button>
        </footer>
      </form>
    </>
  );
}
