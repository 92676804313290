import { SvgIcon } from '@mui/material';
import React from 'react';

export default function SicoobIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 225 225">
            <g>
                <path style={{ opacity: 1 }} fill="#fefefe" d="M -0.5,-0.5 C 74.5,-0.5 149.5,-0.5 224.5,-0.5C 224.5,74.5 224.5,149.5 224.5,224.5C 149.5,224.5 74.5,224.5 -0.5,224.5C -0.5,149.5 -0.5,74.5 -0.5,-0.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#4fbb07" d="M 97.5,55.5 C 102.643,63.9587 107.309,72.6253 111.5,81.5C 104.108,93.5716 97.1079,105.905 90.5,118.5C 89.8333,118.5 89.5,118.833 89.5,119.5C 84.263,127.632 79.263,135.965 74.5,144.5C 59.6935,118.896 44.8602,93.2295 30,67.5C 28.7402,62.1862 30.5736,58.3529 35.5,56C 56.164,55.5 76.8306,55.3334 97.5,55.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#0ca396" d="M 97.5,55.5 C 127.502,55.3334 157.502,55.5 187.5,56C 192.345,57.86 194.512,61.36 194,66.5C 184.036,84.9284 173.536,102.928 162.5,120.5C 153.167,120.5 143.833,120.5 134.5,120.5C 127.586,106.998 119.919,93.9979 111.5,81.5C 107.309,72.6253 102.643,63.9587 97.5,55.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fcfefc" d="M 111.5,81.5 C 119.919,93.9979 127.586,106.998 134.5,120.5C 134.167,120.5 133.833,120.5 133.5,120.5C 119.167,119.833 104.833,119.167 90.5,118.5C 97.1079,105.905 104.108,93.5716 111.5,81.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#f2f6c0" d="M 90.5,118.5 C 104.833,119.167 119.167,119.833 133.5,120.5C 118.657,120.829 103.99,120.496 89.5,119.5C 89.5,118.833 89.8333,118.5 90.5,118.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#c4d405" d="M 89.5,119.5 C 103.99,120.496 118.657,120.829 133.5,120.5C 133.833,120.5 134.167,120.5 134.5,120.5C 143.833,120.5 153.167,120.5 162.5,120.5C 148.53,146.436 134.03,172.103 119,197.5C 113.643,201.787 108.643,201.453 104,196.5C 93.8897,179.274 84.0564,161.941 74.5,144.5C 79.263,135.965 84.263,127.632 89.5,119.5 Z" />
            </g>
        </SvgIcon>
    );
}
