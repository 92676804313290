import React, { useState, useEffect, useRef } from 'react'
import { Autocomplete, TextField, Grid, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export default function AutocompleteWithAddOption({ obj_fn, operations, setOperations, operation }) {
  const [value, setValue] = useState(operation || '')
  //const [value, setValue] = useState(operations[1])
  const [inputValue, setInputValue] = useState('')
  const [showTooltip, setShowTooltip] = useState(false)

  const autocompleteRef = useRef(null)

  const handleAddOption = () => {
    if (inputValue && !operations.includes(inputValue)) {
      setOperations([...operations, inputValue])
      setValue(inputValue)
      obj_fn.setData((prevState) => ({ ...prevState, selectedOperation: inputValue }))
      setShowTooltip(false)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    obj_fn.setData((prevState) => ({ ...prevState, selectedOperation: newValue }))
    setShowTooltip(false)
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    if (newInputValue && !operations.includes(newInputValue)) {
      setShowTooltip(true)
    } else {
      setShowTooltip(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setShowTooltip(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [autocompleteRef])

  return (
    <Grid item xs={12} sm={12}>
      <div ref={autocompleteRef}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={operations}
          getOptionLabel={(option) => option}
          value={value}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: { color: '#2e7d32' },
              }}
              color="success"
              variant="standard"
              label=" Método de Preparo (nome do trabalho a campo)"
              placeholder="Favorites"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <Tooltip title="Clique para adicionar a nova operação" open={showTooltip} placement="top">
                      <IconButton onClick={handleAddOption} color="success">
                        <AddIcon /> <span style={{fontSize: 'medium'}}>Add.</span>
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
          )}
          noOptionsText="Sem resultados"
        />
      </div>
    </Grid>
  )
}
