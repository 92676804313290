import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { blue, green } from '@mui/material/colors';


export default function GroupAvatarsMaps({ msn, setIsLoading }) {
  console.log('GroupAvatarsMaps')
  //console.log(msn)
  //msn?.map((post) => console.log(post))

  if (!msn) {
    setIsLoading(true)
  }

  return (
    <AvatarGroup max={4}>
      {msn?.map((post, index) =>
        <Avatar key={index} sx={{ bgcolor: blue[700] }} aria-label="recipe">
          {post.charName}
        </Avatar>
      )}
      {/* <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
        {charName}
      </Avatar> */}
      {/* <Avatar alt="Miguel" src="/static/images/avatar/2.jpg" />
      <Avatar alt="Juliana" src="/static/images/avatar/3.jpg" />
      <Avatar alt="Pedro" src="/static/images/avatar/4.jpg" />
      <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" /> */}
    </AvatarGroup>
  );
}