import React from 'react'
import Typography from '@mui/material/Typography'
import { Checkmark } from "react-checkmark";
import { AppBar, Box, Button, ButtonBase, Container, Grid, List, ListItem, ListItemText, Paper, Stack, Toolbar } from '@mui/material';
import { Span } from '../../../../components/Typography';
import FormInputFooter from '../../../activities/components/FormActivities/Input/FormInputFooter';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import useActivityInputReposStore from '../../../../store/Activities/useActivityInputReposStore';
import { useEffect } from 'react';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));


export default function SuccessPlanTarget({ handleClose, data }) {
  const classes = useStyles();
  const navigate = useNavigate();
  // para validar o button se existem as 2 validações
  const isError = () => {

    return false;
  };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  // atualizar o localstorage
  //const fetchTodos = useActivityInputReposStore((state) => state.fetch);

  // useEffect(() => {
  //   fetchTodos()
  // }, [])



  const handleButtonClick = () => {
    navigate(`/mapa/${data.id_property}/${data.id_livestock}`);
  };

  return (
    <>

      <Container component="main" maxWidth="sm" >
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <div className="sucess">
            <Checkmark size={'196'} />
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }} >
              Pronto! Meta definida com sucesso.
            </Typography>
            {/* <Typography style={{ color: '#2e7d32' }}>
              <Typewriter tam={60} text=" Acessando o mapa, você poderá verificar que temos os números de animais para entrarem na propriedade." />
            </Typography> */}

            <ListItem
              key={null}
              disableGutters
              secondaryAction={
                <sapn aria-label="comment">
                  <ShareLocationIcon style={{ color: "#2e7d32" }} />
                </sapn>
              }
            >
              <ListItemText primary={`Para aprimorar ainda mais o seu planejamento, é altamente recomendado inserir os custos de forma mais específica para cada atividade da sua produção. Ao detalhar esses custos, você obterá uma visão mais precisa da lucratividade e terá a capacidade de tomar decisões mais assertivas. Sinta-se à vontade para clicar em "Fechar" e, se desejar, inserir os custos agora ou posteriormente.`} />

            </ListItem>


            {/* <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Imagem" src="/dist/image/map_activityinput.png" style={{ maxWidth: '100%' }} />
              </ListItem>
            </List> */}
          </div>

        </Paper>
      </Container>



      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

    </>
  )
}
