import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { green } from '@mui/material/colors'
import { Accordion, AppBar, Box, Card, CardContent, CardMedia, Grid, IconButton, ListItem, ListItemText, Stack, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import { Input, Map } from '@mui/icons-material'
import dayjs from 'dayjs'
import WallpaperIcon from '@mui/icons-material/Wallpaper'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { useParams } from 'react-router-dom'
import useActivityMonitoringReposStore from '../../../../../store/Activities/useActivityMonitoringReposStore'
import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function ViewListActivitiesMonitoring() {
  const [open, setOpen] = React.useState(false)
  // const { idFarm, id } = useParams()

  //const activityMonitoring = obj_fn.usestore.reposActivityMonitoring.getActivityMonitoringStore('id', 1)[0]

  const reposActivities = useActivityMonitoringReposStore((state) => state)

  const listActivityMonitoring = reposActivities.listActivityMonitoring

  const todos = listActivityMonitoring.filter((item) => item.progress === 'A FAZER')
  const inProgress = listActivityMonitoring.filter((item) => item.progress === 'EM PROGRESSO')
  const completed = listActivityMonitoring.filter((item) => item.progress === 'CONCLUÍDA')

  // console.log(listActivityMonitoring)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const renderActivities = (activities) =>
    activities?.map((activityMonitoring) => (
      <Card sx={{ mt: 1 }}>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <LocationSearchingIcon sx={{ color: 'action.active', ml: 0, mr: 1 }} />
                      <Stack spacing={0.5}>
                        <Typography component="div" variant="h5">
                          <strong>{activityMonitoring.monitoringName}</strong>{' '}
                        </Typography>
                      </Stack>
                    </Box>

                    <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Início" secondary={dayjs(activityMonitoring.date_start).format('DD/MM/YYYY')} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Término" secondary={dayjs(activityMonitoring.date_prev_end).format('DD/MM/YYYY')} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography component="div" variant="caption">
                          {activityMonitoring.progress}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Grid>
              <>
                {activityMonitoring.image ? (
                  <Grid item xs={12} sm={3}>
                    <Accordion>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                            <WallpaperIcon sx={{ color: 'action.active', ml: 1, mr: 1 }} />
                            <Stack spacing={0.5}>
                              <Typography>Imagem</Typography>
                            </Stack>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <CardMedia component="img" height="150" image={activityMonitoring.image} alt="Área" />
                        </Grid>
                      </Grid>
                    </Accordion>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={1}>
                    <Typography sx={{ ml: -1 }}>Sem imagem</Typography>
                  </Grid>
                )}
              </>
              {activityMonitoring.areaTranferImage ? (
                <Grid item xs={12} sm={activityMonitoring.image ? 4 : 6}>
                  <Accordion>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                          <Map sx={{ color: 'action.active', ml: 1, mr: 1 }} />
                          <Stack spacing={0.5}>
                            <Typography>Área</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <CardMedia component="img" height="150" image={activityMonitoring.areaTranferImage} alt={activityMonitoring.name} />
                      </Grid>
                    </Grid>
                  </Accordion>
                </Grid>
              ) : (
                <Grid item xs={12} sm={1}>
                  <Typography sx={{ ml: -1 }}>Sem imagem</Typography>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
                  <Grid item xs={4} sm={4}>
                    <ListItem>
                      <ListItemText primary="Raio:" secondary={`${activityMonitoring.heatmapRadius} metros`} />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <ListItem>
                      <ListItemText primary="Intensidade" secondary={activityMonitoring.heatmapWeight} />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <div style={{ marginLeft: 'auto', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 10, borderRadius: 5 }}>
                  <Grid container spacing={1}>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(0, 255, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>0 - Ausente</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 255, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>1 - Baixo</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 165, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>2 - Médio</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 105, 97, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>3 - Alto</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 0, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>4 - Muito alto</span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    ))

  return (
    <React.Fragment>
      {/* <Button variant="contained" color="error" onClick={() => handleClickOpen()} title="Visualização">
        Lista de monitoramento
      </Button> */}
      <IconButton aria-label="Visualização" onClick={() => handleClickOpen()} title="Visualização">
        <LocationSearchingIcon sx={{ color: green[500] }} />
      </IconButton>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }} color="inherit">
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Lista de monitoramentos
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle>
          <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
            <Input sx={{ mr: 1 }} />
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lista de monitoramentos</Typography>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '700px' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                scrollButtons="auto"
                fullWidth={true}
                textColor="success"
                TabIndicatorProps={{ style: { background: 'green' } }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Em progresso" {...a11yProps(0)} />
                <Tab label="A Fazer" {...a11yProps(1)} />
                <Tab label="Concluído" {...a11yProps(2)} />
                {/* <Tab label="Movimentações" {...a11yProps(1)} />
                <Tab label="Histórico do produto" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {renderActivities(inProgress)}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderActivities(todos)}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {renderActivities(completed)}
            </TabPanel>
          </Box>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
