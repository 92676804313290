import Footer from "../../components/Footer/Footer";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import ActivitiesHarvestContent from "./ActivitiesHarvestContent";

function ActivitiesHarvest() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <ActivitiesHarvestContent />
      <Footer />
    </>
  );
}

export default ActivitiesHarvest;
