import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Calculator from '../../components/Calculator/Calculator';
import { Context } from '../../components/Context/AuthContext';
import Loading from '../../components/Loader/Loading';
import { toast } from 'react-toastify';
import CardGeral from '../main/components/CardGeral';
import { Autocomplete, Box, Button, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import TelegramIcon from '@mui/icons-material/Telegram';
import MessengerTabs from './components/MessengerTabs';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SpeechRecognitionButton from './components/SpeechRecognitionText/SpeechRecognitionButton';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TutorialMessenger from './components/tutorial/TutorialMessenger';
import ButtonFullScreenDialog from './DialogMessenger/ButtonFullScreenDialog';
import AddIcon from '@mui/icons-material/Add';
import MicIcon from '@mui/icons-material/Mic';
import RttIcon from '@mui/icons-material/Rtt';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  },
};

export default function MessengerContent({ isLoading, setIsLoading }) {
  const defaultProps = {
    options: [{ title: 'Mensagens' }, { title: 'Atividades' }],
    getOptionLabel: (option) => option.title,
  };

  const [openModal, setOpenM] = useState(false);
  const [openMAudio, setOpenMAudio] = useState(false);

  const handleClickOpen = () => {
    console.log('handleClickOpen');
    setOpenM(true);
  };
  const handleClickOpenAudio = () => {
    console.log('handleClickOpenAudio');
    setOpenMAudio(true);
  };

  const objText = {
    icon: <ConnectWithoutContactIcon sx={{ fontSize: 35 }} color="text.secondary" />,
    title: 'Solicitações', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Registre solicitações, tarefas, anotações ou observações para serem visualizadas pela equipe de trabalho.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar uma Solicitação', '2- Monitorar a lista de solicitações'], // Texto complementar ou final
    linkButton: ['/messenger', `/messenger`], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <>
      <SpeechRecognitionButton isLoading={isLoading} setIsLoading={setIsLoading} setOpenModal={setOpenMAudio} openModal={openMAudio} />
      <ButtonFullScreenDialog openModal={openModal} setOpenModal={setOpenM} />

      <div className="content-wrapper bg-white">
        <div className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex' }}>
                  <ConnectWithoutContactIcon sx={{ fontSize: 35 }} color="text.secondary" />
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                    <Typography fontWeight="500" variant="h6">
                      Solicitações
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                      Registro&nbsp;das&nbsp;informações&nbsp;da&nbsp;propriedade
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" startIcon={<RttIcon />} onClick={handleClickOpen}>
                  Texto
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                  <TutorialStepGeral objText={objText} />
                  <TutorialMessenger />
                </Box>
              </Grid>

              {/* {isMobile.iOS() ? (
                    <></>
                  ) : (
                    <Grid item xs={5} md={3}>
                      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" startIcon={<MicIcon />} onClick={handleClickOpenAudio}>
                        Áudio
                      </Button>
                    </Grid>
                  )} */}

              <Grid item xs={12} md={12} alignItems="center">
                <Card sx={{ p: 3 }}>
                  {true ? (
                    <Typewriter text={`Clique em "TEXTO", e registre as informações, lembretes e tarefas de trabalho da propriedade.`} variant={'subtitle1'} color={'#2e7d32'} />
                  ) : (
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                      {' '}
                      <ThreeDotsSpinner />
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="table-responsive p-0">
          <MessengerTabs isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>
      </div>
    </>
  );
}
