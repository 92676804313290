import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Box, ButtonBase, ListItemAvatar, Paper, Typography, Stack } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function CreateAddCatalogProdctList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/areasmaps');
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', mt: -2 }}>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 1:</strong>} secondary="Clique no botão verde CADASTRO DE PRODUTOS, no canto superior direito da tela." />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText
            primary={<strong>Passo 2:</strong>}
            secondary={
              <React.Fragment>
                <span>Escolha a melhor forma de registrar o seu produto</span>
                <br />
                <span>
                  <strong>Rápida:</strong> Escolha ideal para quem deseja cadastrar somente os campos: nome do produto, sua unidade de medida, quantidade, valor, categoria de controle.
                </span>
                <br />
                <span>
                  <strong>Completa:</strong> Escolha aprimorada para quem deseja realizar um cadastro mais completo: envolve informações adicionais como registro do fornecedor do produto, locais de
                  armazenamento, foto, estoque, pesos e dimensões...{' '}
                </span>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/registerCatalogProduct.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 3:</strong>} secondary="Na tela posterior você será encaminhado para inserir as infromações para o registro do produto no estoque." />
        </ListItem>
      </Box>
      {/* <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 4:</strong>} secondary="Depois desenhe e selecione a área que a cultura ficará." />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/area-2.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box> */}
      {/* <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 5:</strong>} secondary="Por fim, basta conferir os dados e salvar as informações do projeto agrícola." />
        </ListItem>
      </Box> */}
    </List>
  );
}
