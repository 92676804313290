import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModalPreparationAgricultureToDo from './PreparationAgricultureTopicList';
import TimelineHorizActivityPrepAgr from '../GraphPlan/TimelineHorizActivityPrepAgr';
import { useState } from 'react';
import PreparationAgricultureTopicList from './PreparationAgricultureTopicList';
import { Divider, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Span } from '../../../../components/Typography';


export default function ModalPreparationAgriculture({ setIsLoandigSavePlan }) {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const initialTopics = [
        'Financiamento',
        'Limpeza de áreas',
        'Preparação de estruturas agrícolas',
        'Preparação de viveiros',
        'Controle de pragas',
        'Controle de pragas e doenças prévias',
        'Preparação de solo para áreas específicas',
        'Licenciamento',
        'regulamentação',
    ];

    const [topics, setTopics] = useState(initialTopics);
    const [newTopic, setNewTopic] = useState('');

    const handleAddTopic = () => {
        if (newTopic.trim() !== '') {
            setTopics([...topics, newTopic]);
            setNewTopic('');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAddTopic();
        }
    };

    const onSubmit = (event) => {
        console.log("Submit")
        console.log(topics)
        setIsLoandigSavePlan(true)
        handleClose()
    };

    return (
        <React.Fragment>
            <Button color="success" variant="outlined" onClick={handleClickOpen}>
                Custos iniciais
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Custos iniciais</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Defina as principais atividades e operações que geram custos nesta fase incial, antes do pré-plantio:
                    </DialogContentText>
                    <div className="d-lg-none" style={{ overflowX: 'auto' }}><TimelineHorizActivityPrepAgr topics={topics} /></div>

                    <div className="d-none d-lg-block"><TimelineHorizActivityPrepAgr topics={topics} /></div>


                    <Box maxWidth="500px" mx="auto" mt={4}>
                        <PreparationAgricultureTopicList topics={topics} setTopics={setTopics} initialTopics={initialTopics} />
                        <Box display="flex" mt={2}>
                            <TextField
                                value={newTopic}
                                color="success"
                                onChange={(e) => setNewTopic(e.target.value)}
                                onKeyDown={handleKeyDown} // Adicionando evento onKeyDown
                                placeholder="Adicionar Nova Atividade"
                                fullWidth
                                size="small"
                            />
                            <IconButton onClick={handleAddTopic}>
                                <AddCircleIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >


                    </Box>
                </DialogContent>
                <Divider sx={{ mt: 2 }} />
                <DialogActions>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                        <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                            <Span>cancelar</Span>
                        </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>

                        <Button sx={{ width: 100, position: 'relative' }} color="success" variant="contained" type="submit" onClick={onSubmit}>

                            {/* {LoadingButton ? <CircularProgress sx={{ mb: 0, }} size="1.5rem" style={{ color: '#fff' }} /> : <> <SendIcon /> */}
                            <Span >Salvar</Span>
                        </Button>

                    </Box>
                    {/* <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={onSubmit}>Salvar</Button> */}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}