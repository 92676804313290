export default function harvestProgress(harvest) {
  // console.log(harvest);
  const initialLabel = '';
  const initialAreas = 0;
  const fields = ['label', 'areas'];

  if (!harvest || harvest.length === 0) {
    return {
      progress: 0,
      status: {
        label: '?',
        areas: '?',
      },
    };
  }

  const current = harvest[0];
  let progress = 0;
  const status = {};

  if (current.label !== initialLabel) {
    progress += 50;
    status.label = 'Ok';
  } else {
    status.label = '?';
  }

  if (current.areas !== initialAreas) {
    progress += 50;
    status.areas = 'Ok';
  } else {
    status.areas = '?';
  }

  return { progress, status };
}
