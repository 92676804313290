import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';

function WidgetComponent({ onValueChange }) {
    const [widgetValue, setWidgetValue] = useState(null);

    useEffect(() => {
        const extractValue = () => {
            const iframe = document.getElementById('widget-iframe');
            const iframeContent = iframe.contentDocument || iframe.contentWindow.document;
            const valueElement = iframeContent.querySelector('.value-element');
            if (valueElement) {
                const value = valueElement.textContent;
                setWidgetValue(value);
                onValueChange(value);
            }
        };

        extractValue();
    }, []);

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container sx={{ mt: 2 }}>
                <iframe
                    id="widget-iframe"
                    title="Widget"
                    src="https://www.cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=dbd6b2&cortexto=333333&corlinha=ede7bf&id_indicador%5B%5D=77"
                    style={{ width: '100%', height: '200px', border: '0' }}
                ></iframe>
            </Grid>
            <p>Valor extraído: {widgetValue}</p>
        </Container>
    );
}

export default WidgetComponent;
