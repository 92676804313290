import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Alert, Grid } from '@mui/material'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Context } from '../../../../components/Context/AuthContext'
import { toast } from 'react-toastify'
import api from '../../../../services/api'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Fechar"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function EditModalFormActivityDelete({ data, usestore }) {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  // console.log('EditModalFormActivityDelete')
  // console.log('data')
  // console.log(data)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleCloseCancel = () => {
    // console.log('cancelado')
    setOpen(false)
  }

  const handleDelete = (e) => {
    e.preventDefault()
    setIsLoading(false)
    setTimeout(() => {
      setOpen(false)
      handleChangeDelete(e)
    }, 1000)
    setTimeout(() => {
      setIsLoading(true)
    }, 3000)
  }

  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context)

  const { idFarm } = useParams()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  var property_id = localStorage.getItem('Property') || 1

  const handleReposUpdate = (reposActivity) => {
    // atualizar o produto
    usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposProducts')
    })
    // atualizar as finanças
    usestore.reposFinances.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances')
    })
    // atualizar a transação
    usestore.reposFinances.fetchT({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances - transactions')
    })
    usestore.reposLivestock.fetch();

    reposActivity.fetch().then((res) => {
      // console.log('reposActivitiesInputs - fetch - res')
      setAuxAdd(aux_add + 1)
      setTimeout(() => {
        toast.success(`Atividade deletada com sucesso!`)
      }, 200)
    })
  }

  const handleChangeDelete = async (e) => {
    e.preventDefault()
    // console.log('handleChangeDelete')

    const header = { header: { 'Content-Type': 'application/json' } }
    const status = 'delete'
    //console.log(data.id, status);
    //data.nameBatch
    //const value1 = data.nameBatch;
    //const value2 = data.progress
    //console.log(data.id, status, value1);
    let url = ''
    let reposActivity = {}

    // console.log(data.activity)

    if (data.activity === 'ENTRADA') {
      url = `/activityInput/delete/${data.id}/${status}?param1=${data.nameBatch}&param2=${data.progress}`
      reposActivity = usestore.reposActivityInput
    } else if (data.activity === 'IDENTIFICAÇÃO') {
      url = `/activityIdentify/delete/${data.id}/${status}`
      reposActivity = usestore.reposActivityIdentify
    } else if (data.activity === 'PESAGEM') {
      url = `/activityWeight/delete/${data.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityWeight
    } else if (data.activity === 'MEDICAMENTOS') {
      url = `/activityMedicines/delete/${data.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityMedicines
    } else if (data.activity === 'NUTRICAO') {
      url = `/activityNutrition/delete/${data.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityNutrition
    } else if (data.activity === 'TRANSFERENCIA') {
      url = `/activityTransfer/delete/${data.id}/${status}?param1=${data.nameBatch}&param2=${data.progress}`
      reposActivity = usestore.reposActivityTransferBatch
    } else if (data.activity === 'PRODUÇÃO') {
      url = `/activityProduction/delete/${data.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`
      reposActivity = usestore.reposActivityProduction
    }

    // console.log(url)
    // const res = await api
    await api.delete(url, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          // atualiza o repos
          setTimeout(() => {
            handleReposUpdate(reposActivity)
          }, 200)
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.success(error)
      })
  }

  return (
    <div>
      <IconButton color="error" type="submit" onClick={handleClickOpen} title="Deletar">
        <DeleteForeverIcon />
      </IconButton>
      <BootstrapDialog onClose={handleCloseCancel} aria-labelledby="Excluindo atividade" open={open} sx={{ backgroundColor: red[500] }}>
        <BootstrapDialogTitle sx={{ color: '#fff', backgroundColor: red[800] }} id="customized-dialog-title" onClose={handleCloseCancel} color="success">
          Excluindo atividade
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <>
              <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                <Alert variant="filled" severity="error">
                  A atividade será excluída do software
                </Alert>
                <Typography sx={{ mt: 2 }} gutterBottom>
                  Esse procedimento é irreversível.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid sx={{ width: 270, height: 150, p: 1, m: 1 }}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  //style={{ minHeight: '100vh' }}
                >
                  <PersonOffIcon style={{ fontSize: 80 }} />
                  <p>Atividade excluída!!!</p>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCancel} style={{ color: '#8a8a8a' }}>
            Cancelar
          </Button>
          <Button autoFocus onClick={handleDelete} variant="contained" color="error">
            Excluir atividade
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
