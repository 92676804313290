import * as React from 'react'
import { useTheme } from '@mui/material/styles'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { Box, CardHeader, Grid, ImageList, ImageListItem, Skeleton, Stack, Typography } from '@mui/material'
import { LocationOn } from '@mui/icons-material'

export default function CardAreaBath({ listAreas, livestock }) {
  // console.log(livestock)
  // console.log(listAreas)
  //const nameBatch = []
  for (const item of livestock.lotes) {
    let batch = { name: item.name, id: item.id }
    //nameBatch.push(batch)
    for (const area of listAreas) {
      console.log(area)
      if (area.id == item.id) {
        area.num_animais = item.num_animais
      }
    }
  }

  console.log('listAreas1')
  console.log(listAreas)

  return (
    <>
      {!listAreas ? (
        <Skeleton variant="rectangular" height={550} />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mx: -2 }}>
            {listAreas.map((item) => (
              <Grid key={item.id} item xs={12} sm={6} md={6} sx={{ mx: 'auto' }}>
                <Card sx={{ maxHeight: 550, width: 345, overflow: 'auto', mx: 'auto' }}>
                  <CardMedia component="img" height="120" image={item.imageDecode} alt="Paella dish" />
                  <CardHeader
                    subheader={
                      <Box>
                        <Typography variant="body1">
                          <strong>{item.name}:</strong> {item.num_animais} ca
                        </Typography>
                        <Stack spacing={0.5} sx={{ ml: -1 }}>
                          <Typography variant="body2" color="text.secondary">
                            <LocationOn sx={{ color: 'green.500' }} /> {item.usedArea} ({item.measure})
                          </Typography>
                        </Stack>
                      </Box>
                    }
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}
