import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListIcon from "@mui/icons-material/List";
import { Grid } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import CardMeatProductionCut from "./MeatProduction/CardMeatProductionCut";
import CardMeatProductionCreation from "./MeatProduction/CardMeatProductionCreation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function CreationsTabsPlanning({ type }) {
  const [value, setValue] = React.useState(0);

  console.log(type)

  React.useEffect(() => {
    const selectedIndex = getSelectedIndex(type);
    setValue(selectedIndex);
  }, [type]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSelectedIndex = (type) => {
    switch (type) {
      case 'Cria':
        return 0;
      case 'Corte':
        return 1;
      case 'Leite':
        return 2;
      case 'Derivados':
        return 3;
      default:
        return 0;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} value={value} onChange={handleChange} aria-label="basic tabs example">
          {type === 'Cria' && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  Cria
                </Grid>
              }
              {...a11yProps(0)}
            />
          )}
          {type === 'Corte' && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <ViewColumnIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  Corte
                </Grid>
              }
              {...a11yProps(1)}
            />
          )}
          {type === 'Leite' && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <ViewColumnIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  Leite
                </Grid>
              }
              {...a11yProps(2)}
            />
          )}
          {type === 'Derivados' && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <ViewColumnIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  Derivados
                </Grid>
              }
              {...a11yProps(3)}
            />
          )}
        </Tabs>
      </Box>
      {type === 'Cria' && (
        <TabPanel value={value} index={0} key="tab-cria">
          <CardMeatProductionCreation type={type} />
        </TabPanel>
      )}
      {type === 'Corte' && (
        <TabPanel value={value} index={1} key="tab-corte">
          <CardMeatProductionCut type={type} />
        </TabPanel>
      )}
      {type === 'Leite' && (
        <TabPanel value={value} index={2} key="tab-leite">
          <div className="content">Em construção</div>
        </TabPanel>
      )}
      {type === 'Derivados' && (
        <TabPanel value={value} index={3} key="tab-derivados">
          <div className="content">Em construção</div>
        </TabPanel>
      )}
    </Box>
  );
}