import Footer from "../../components/Footer/Footer";
import MainContent from "./MainContent";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";

function Main() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <MainContent />
      <Footer />
    </>
  );
}

export default Main;
