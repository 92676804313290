import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormTableItens from '../../Forms/FormTableItens';
import FormSelectAddItens from '../../Forms/FormSelectAddItens';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
export default function FormBuySalesStep2({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  // const para validar o button continuar
  const isError = () => {
    let iserror = false;
    console.log(data.products.length || data.lotes.length || data.animals.length);
    // if (data.products.length || data.lotes.length || data.animals.length) {
    //   iserror = false;
    // } else if (!data.value || error) {
    //   iserror = true;
    // }
    if (!data.products.length && !data.lotes.length && !data.animals.length) {
      iserror = true;
    } else if (!data.value || error) {
      iserror = true;
    }
    return iserror;
  };
  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* {!data.product_selected == null && <FormTableItens data={data} obj_fn={obj_fn} />} */}

        {/* <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ mt: -5 }}> */}
        {/* <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ mt: -5 }}>
          <Typography style={{ color: green[800] }}>
            <Typewriter tam={0} minHeight={0} text={`Defina os itens desejados para a ${data.operationType}`} />
          </Typography>
        </Grid> */}
        {/* Seleção: produto + lotes + animais */}
        <FormSelectAddItens data={data} obj_fn={obj_fn} />

        {!data.products.length && !data.lotes.length && !data.animals.length ? (
          <Box sx={{ mt: 4, ml: 1, display: 'flex' }}>
            <ShoppingCartCheckoutIcon color="error" />
            <Stack spacing={0.5} sx={{ ml: 1 }}>
              <Typography fontWeight={700} style={{ color: red[500] }}>
                Carrinho de {data.operationType} encontra-se vazio!
              </Typography>
            </Stack>
          </Box>
        ) : (
          <>
            <FormTableItens data={data} obj_fn={obj_fn} />
            {isError && (
              <Grid container sx={{ mt: 1 }}>
                <Grid item sm={6} xs={6}>
                  <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                    Adicione novos itens ou clique em continuar...
                  </Typography>
                </Grid>
                {/* <Grid item sm={6} xs={6} sx={{ textAlign: 'right', mt: -3 }}>
                    <Bot text={text} />
                  </Grid> */}
              </Grid>
            )}
          </>
        )}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
