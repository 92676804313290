import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Edit } from '@mui/icons-material';
import { AppBar, Card, CardContent, CardHeader, Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, useTheme } from '@mui/system';
import { H6, Span } from '../../../../components/Typography';
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import ProfileSelect from './ProfileSelect';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TableProfile from './tableProfile/TableProfile';


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ConsultUserProfileDraggableDialog() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("entrou no submit");
    //setIsLoading(true)
  }

  React.useEffect(() => {
    console.log('atualiza')
    setIsLoading(false)
  }, [isLoading])


  return (
    <>
      {/* {isLoading ? <Loading /> : ( */}
      <div  >
        <Button size="small" variant="outlined" color="success" onClick={handleClickOpen}>
          <PermContactCalendarIcon /> Consultar
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth={'lg'}
        >
          <AppBar sx={{ cursor: 'move', position: "relative" }} color="success" id="draggable-dialog-title">
            <div className="d-none d-lg-block">
              <Toolbar>
                {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>cancelar</Span>
            </Button> */}
                {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton> */}
                <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0">
                  {" "}
                </H6>

                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>

              </Toolbar>
            </div>
          </AppBar>
          <section className="" style={{ padding: 0, overflowY: "scroll", paddingBottom: 60 }}>
            <div className="" >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography fontWeight="500" variant="h6" component="h2">
                  Consultar Permissões
                </Typography>

              </DialogTitle>
              <Divider />
              <DialogContent >
                {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText> */}


                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Card >
                      <CardHeader

                        title={<PersonSearchIcon />}

                      />
                      <Divider />
                      <CardContent>
                        <div >
                          <ProfileSelect isLoading={isLoading} setIsLoading={setIsLoading} />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ p: 0, m: 0 }}>
                    <Card >
                      <CardHeader
                        title={
                          <Typography fontWeight="500" variant="subtitle1" component="h2">
                            Permissões do perfil
                          </Typography>}
                      />
                      <Divider />
                      <CardContent sx={{ m: -2 }}>
                        {/* <TabPanelActivity /> */}
                        <TableProfile isLoading={isLoading} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </DialogContent>
              <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                    <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                      <Span>fechar</Span>
                    </Button>
                  </Box>
                  {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>

                    <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                      <SendIcon />
                      <Span sx={{ pl: 1, textTransform: "capitalize" }}>Enviar</Span>
                    </Button>

                  </Box> */}
                </Toolbar>
              </AppBar>
            </div>

          </section>




          {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
        </Dialog>

      </div >
      {/* )} */}
    </>
  );
}