import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { AppBar, Divider, Grid, Toolbar, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Span } from '../../Typography';
import Typewriter from '../../Typewriter/Typewriter';
import SpaIcon from "@mui/icons-material/Spa";
import SelectLivestock from '../../../pages/livestock/components/addLivestock/SelectLivestock';
import SelectHarvest from "../../../pages/harvest/components/addHarvest/SelectHarvest";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SelectProject({ openModal, setOpenModal }) {
  const theme = useTheme();
  const [open, setOpen] = useState(openModal);
  const [status, setStatus] = useState();
  const [openModalLivestock, setOpenModalLivestock] = useState(false);
  const [openModalHarvest, setOpenModalHarvest] = useState(false);

  const handleClickOpen = (e, project) => {
    console.log("handleClickOpen");
    if (project === "Criações") {
      setStatus(1);
      setOpenModalLivestock(true);
    } else {
      setStatus(2);
      setOpenModalHarvest(true);
    }
  };

  const handleClose = () => {
    setOpenModalLivestock(false);
    setOpenModalHarvest(false);
    setOpenModal(false);
    setStatus(0);
  };

  useEffect(() => {
    setOpen(openModal)
  }, [openModal]);

  if (open != openModal) {
    setOpen(openModal)
  }
  return ( <>
    {status === 1 && <SelectLivestock isDelete={true} openModalProject={openModalLivestock} handleClose={handleClose} />}
    {status === 2 && <SelectHarvest isDelete={true} openModalProject={openModalHarvest} handleClose={handleClose} />}
    <div >
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'} >
        <DialogTitle >
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2} sx={{ ml: -3 }}>
                <Grid item xs={12} md={12} alignItems="center"  >
                  <Typography sx={{ textAlign: "start" }} variant="subtitle1" component="div">Olá produtor!</Typography>
                  <Typewriter text="Escolha o tipo de projeto que iremos trabalhar:" variant={"subtitle1"} color={'#2e7d32'} />
                </Grid>
              </Grid>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center"  >
                  <Typewriter text="Olá produtor! Escolha o tipo de projeto que iremos trabalhar:" variant={"h6"} color={'#2e7d32'} />
                </Grid>
              </Grid>
            </Toolbar>
          </div>
        </DialogTitle>

        <Divider />
        <DialogContent sx={{ mb: 8 }}>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh" >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh" >
                  <Button variant="outlined" color="success" style={{ minHeight: "20vh", minWidth: "25vh" }} onClick={(e) => handleClickOpen(e, 'Criações')}>
                    <div className="nav-link" style={{ color: '#2e7d32' }}>
                      <div className="justify-content-center" >
                        <i> <img src="/dist/img_fp/icon-button/todas_pecuarias.png" width="80"></img> </i>
                      </div>
                      <div styles={{ textTransform: "capitalize", }} className=".text-success ">Criações</div>
                    </div>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh" >
                  <Button variant="outlined" color="success" style={{ minHeight: "20vh", minWidth: "25vh" }} onClick={(e) => handleClickOpen(e, 'Safras')}>
                    <div className="nav-link" style={{ color: '#2e7d32' }}>
                      <div className="justify-content-center mb-2" >
                        <SpaIcon style={{ fontSize: 60 }} />
                      </div>
                      <div styles={{ textTransform: "capitalize", mt: 2 }} className=".text-success">Safras</div>
                    </div>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>fechar</Span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div >
  </> );
}