import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardHeader, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { List, ListAlt, ViewModule, DeleteForever } from '@mui/icons-material';
import TablesActivitiesHarvest from '../ListActivities/TablesActivitiesHarvest';
import BoardTypes from '../../../../components/Boards/BoardsActivities/BoardTypes';
import BoardProgress from '../../../../components/Boards/BoardsActivities/BoardProgress';
import BoardTimeLine from '../../../../components/Boards/BoardsActivities/BoardTimeLine';
import BoardTimeLineGraph from '../../../../components/Boards/BoardsActivities/BoardTimeLineGraph';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, mt: 1, ml: 0, mr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CardActivitiesHarvests({ isLoadingActivities, setIsLoadingActivities }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ mt: 1 }}>
        {/* <CardHeader
          title={
            <>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ display: 'flex' }}>
              
                    <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      <Typography fontWeight="700" variant="h6" style={{ color: '#2e7d32' }}>
                       
                        Caderno de campo
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                          Gestão das Atividades de Produção
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          Crie, execute e acompanhe o cronograma de atividades para a safra e o progresso do trabalho.
                        </Typography>
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box display="flex" justifyContent="flex-start">
                   
                  </Box>
                </Grid>
              </Grid>
            </>
          }
        ></CardHeader> */}
        <Divider />
      </Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <List sx={{ mr: 0.5, mb: 0.2 }} />
                Lista
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListAlt sx={{ mr: 0.5, mb: 0.2 }} />
                cronogama
              </Grid>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListAlt sx={{ mr: 0.5, mb: 0.2 }} />
                Tipos
              </Grid>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ViewModule sx={{ mr: 0.5, mb: 0.2 }} />
                Progresso
              </Grid>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ViewModule sx={{ mr: 0.5, mb: 0.2 }} />
                Tempo
              </Grid>
            }
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <DeleteForever sx={{ mr: 0.5, mb: 0.2 }} />
                Lixeira
              </Grid>
            }
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TablesActivitiesHarvest isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} table_active={'realized'} status={'edit'} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BoardTimeLineGraph type={'harvest'} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BoardTypes type={'harvest'} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BoardProgress type={'harvest'} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BoardTimeLine type={'harvest'} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TablesActivitiesHarvest isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} table_active={'realized'} status={'trash'} />
      </TabPanel>
    </Box>
  );
}
