import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, ButtonBase, Container, Paper, Stack, Typography } from '@mui/material';
import { Checkmark } from 'react-checkmark';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function AnimalsPasture() {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const { idFarm, id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    navigate(`/livestock/${idFarm}/${id}`);
  };

  let text_msg = 'É hora de monitorar seus lotes e animais!\nUtilize as melhores práticas de manejos para o sucesso dos negócios';

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
        <DialogContent sx={{ width: 300 }} className="d-lg-none">
          <DialogContentText id="alert-dialog-description">
            <div className="success">
              <Checkmark size={'128'} />
              <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
                Excelente Manejo!
              </Typography>
              {/* <Typography variant="h6" sx={{ mt: 1 }}>
                    Seus animais se encontram na propriedade.
                  </Typography> */}
              <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => handleButtonClick()} className={classes.button}>
                <Stack direction="column" alignItems="center">
                  {/* <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" alt="image" className="img-fluid" /> */}
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} fill={'#1f2d3d'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Lista de Animais
                  </Typography>
                </Stack>
              </ButtonBase>
              <Box sx={{ display: 'flex', height: 200, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography style={{ color: '#2e7d32', marginTop: 10, textAlign: 'center' }}>
                  <Typewriter tam={100} text={text_msg} />
                </Typography>
              </Box>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogContent sx={{ width: 600 }} className="d-none d-lg-block">
          <DialogContentText id="alert-dialog-description">
            <div className="success">
              <Checkmark size={'128'} />
              <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
                Excelente Manejo!
              </Typography>
              {/* <Typography variant="h6" sx={{ mt: 1 }}>
                    Seus animais se encontram na propriedade.
                  </Typography> */}
              <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => handleButtonClick()} className={classes.button}>
                <Stack direction="column" alignItems="center">
                  {/* <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" alt="image" className="img-fluid" /> */}
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'100px'} fill={'#1f2d3d'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Lista de Animais
                  </Typography>
                </Stack>
              </ButtonBase>
              <Box sx={{ display: 'flex', height: 200, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography style={{ color: '#2e7d32', marginTop: 10, textAlign: 'center' }}>
                  <Typewriter tam={100} text={text_msg} />
                </Typography>
              </Box>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
