import { Card, CardContent, CardHeader, IconButton, ListItemText, Skeleton, Typography } from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import { blue, red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useFetchWeatherForecast } from "./useFetchWeatherForecast";
import { useState } from "react";
import Loading from "../../../../components/Loader/Loading";
import { useEffect } from "react";
import { DateAsText } from "./DateAsText";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import OpacityIcon from "@mui/icons-material/Opacity";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import AirIcon from "@mui/icons-material/Air";

export default function CardWeatherMap({ idFarm }) {
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxCardWeatherxxxxxxxxxxxxxxxxxxxxx");
  console.log(idFarm)
  const { loadingWeatherForecast, apiData } = useFetchWeatherForecast(idFarm);
  const [dataForecast, setDataForecast] = useState([]);
  const [weatherForecast, setWeatherForecast] = useState([]);
  const [forecast, setForecast] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("dataWeatherForecast");
  console.log(loadingWeatherForecast);
  console.log(apiData);

  // useEffect(() => {
  //   if (!dataWeatherForecast) return
  //   const forecastList = new Map([])
  //   dataWeatherForecast.list.forEach(row => {
  //     const forecastDate = new Date(row.dt_txt)
  //     const key = `${forecastDate.getFullYear()}${forecastDate.getMonth() + 1}${forecastDate.getDate()}`
  //     if (!forecastList.has(key)) {
  //       forecastList.set(key, row)
  //     }
  //   })
  //   setDataForecast(Array.from(forecastList))
  // }, [dataWeatherForecast])

  useEffect(() => {
    console.log("entrou");
    setLoading(true)
  }, [apiData]);

  //https://openweathermap.org/weather-data
  //<div sx={{ maxWidth: 345 }}>

  //if (loadingWeatherForecast) return <Skeleton variant="rectangular" width={"100%"} height={80} />

  const kelvinToFarenheit = (k) => {
    return (k - 273.15).toFixed(2);
  };

  //if (loadingWeatherForecast) return <Skeleton sx={{ m: 3 }} variant="rectangular" width={"100%"} height={280} />
  return (
    <div className="mt-2">
      <header className="d-flex justify-content-center align-items-center">
        <h6>Clima hoje</h6>
      </header>
      <div className="">

        <div className="card mx-auto" >
          {apiData.main && loading ? (
            <div class="card-body text-center mb-3" style={{ width: 280, height: 200 }}>
              <img
                src={`http://openweathermap.org/img/w/${apiData.weather[0].icon}.png`}
                alt="weather status icon"
                className="weather-icon"
                height={80}
              />

              <p className="h4">
                {kelvinToFarenheit(apiData.main.temp)}&deg; C
              </p>

              <p className="h5">
                <i className="fas fa-map-marker-alt"></i>{' '}
                <strong>{apiData.name}</strong>
              </p>

              {/* <div className="row mt-4">
                <div className="col-md-6">
                  <p>
                    <i class="fas fa-temperature-low "></i>{' '}
                    <strong>
                      {kelvinToFarenheit(apiData.main.temp_min)}&deg; C
                    </strong>
                  </p>
                  <p>
                    <i className="fas fa-temperature-high"></i>{' '}
                    <strong>
                      {kelvinToFarenheit(apiData.main.temp_max)}&deg; C
                    </strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    {' '}
                    <strong>{apiData.weather[0].main}</strong>
                  </p>
                  <p>
                    <strong>
                      {' '}
                      Brasil
                    </strong>
                  </p>
                </div>
              </div> */}
            </div>
          ) : (
            <>
              <div class="card-body text-center mb-3" style={{ width: 280, height: 200 }}>

                <Skeleton variant="rectangular" width={235} height={170} />

              </div>
            </>
          )}
        </div>
      </div>
      {/* <footer className="footer">
        <code>
          Created by{' '}
          <a href="https://github.com/imshines" target="none">
            imshines
          </a>{' '}
          using React
        </code>
      </footer> */}
    </div>
  );
}
//https://www.tempo.com/getwid/3a353511264e58361f398cf96e34df4d

// {
//   "cod":"200","message":0.0045,
//   "city":{"id":1851632,"name":"Shuzenji",
//   "coord":{"lon":138.933334,"lat":34.966671},
//   "country":"JP"},
//   "cnt":38,
//   "list":[{
//           "dt":1406106000,
//           "main":{
//               "temp":298.77,
//               "temp_min":298.77,
//               "temp_max":298.774,
//               "pressure":1005.93,
//               "sea_level":1018.18,
//               "grnd_level":1005.93,
//               "humidity":87},
//           "weather":[{"id":804,"main":"Clouds",
//           "description":"overcast clouds","icon":"04d"}],
//           "clouds":{"all":88},
//           "wind":{"speed":5.71,"deg":229.501},
//           "sys":{"pod":"d"},
//           "dt_txt":"2014-07-23 09:00:00"}
//           ]
// }
