import Footer from '../../components/Footer/Footer'
// import Nav from '../../components/Nav/Nav'
// import NavSide from '../../components/Nav/NavSide'
// import NavBotton from '../../components/Nav/NavBotton'
import InputsContent from './InputsContent'

function Inputs() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <InputsContent />
      <Footer />
    </>
  )
}

export default Inputs
