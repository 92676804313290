import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardHeader, Divider, Grid, Paper, Typography } from '@mui/material';
import ModalAddBank from './ModalAddBank';
import TableBank from './TableBank';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BankAccountComponent = () => {
  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);
  return (
    <>
      <Grid item xs={12} md={12} sx={{ mt: 0 }}>
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          Conta Padrão, por que preciso dela?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          Por que preciso de uma Conta Padrão?
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>
                              O seu Fazenda Premium já vem com uma conta financeira padrão, chamada "Banco padrão", que facilita seus lançamentos e o controle das despesas da fazenda,
                              centralizando todas as transações, como compras, vendas e pagamentos, de forma organizada.
                            </span>
                          </>
                        </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          Posso usar apenas a conta padrão "Banco padrão" para todo o controle financeiro no Fazenda Premium?
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>
                              Sim, você pode usar a conta padrão "Banco padrão" para gerenciar todas as transações financeiras da sua fazenda, como compras, recebimentos e pagamentos diários. No
                              entanto, para uma gestão mais detalhada e organizada, utilize a conta padrão "Banco padrão" para transações internas, como compras e pagamentos diários, e adicione
                              uma conta bancária para controlar transações externas, como recebimentos de vendas e pagamentos a fornecedores. Isso proporciona um controle financeiro mais claro e
                              eficiente.
                            </span>
                          </>
                        </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          Dica:
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>A conta padrão é a que você mais utiliza para realizar pagamentos e recebimentos registrados no Fazenda Premium.</span>
                          </>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          Conta Bancária. O que é?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          O que é uma conta bancária no Fazenda Premium?
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>
                              No Fazenda Premium, uma Conta Bancária não é uma conta real, mas uma ferramenta para simular e gerenciar as finanças externas da sua fazenda. Você pode usá-la para
                              registrar recebimentos de vendas e pagamentos de fornecedores. Além disso, ela permite conferir manualmente as transações registradas no sistema com as do seu banco
                              (também conhecida como conciliação bancária), garantindo que tudo esteja em ordem. Ter uma Conta Bancária registrada aqui facilita a gestão do fluxo de caixa,
                              proporcionando um controle mais claro das entradas e saídas de recursos e proporcionando uma gestão financeira mais eficiente.
                            </span>
                          </>
                        </Typography>
                      </AccordionDetails>

                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          Por que precisaria criar mais uma conta?
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>
                              Criar mais uma conta no Fazenda Premium ajuda a separar suas finanças, melhorar o controle, planejar recursos, e conferir as transações de forma mais organizada.
                            </span>
                          </>
                        </Typography>
                        {/* <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>Se necessário, você pode adicionar uma conta bancária e configurá-la como a nova conta padrão, ampliando o controle das suas finanças.</span>
                          </>
                        </Typography> */}
                      </AccordionDetails>

                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2">
                          Por que você precisaria ter uma conta "Caixa da Fazenda" e uma conta bancária no Fazenda Premium?
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <span>
                              Manter uma conta "Caixa da Fazenda" e uma conta bancária separadas permite que você diferencie as transações internas da fazenda, como pequenas compras e pagamentos
                              diários, das operações financeiras externas, como recebimentos e pagamentos para fornecedores. Isso melhora o controle financeiro, facilita o planejamento e ajuda a
                              manter suas finanças organizadas.
                            </span>
                          </>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="d-lg-none">
                      <Box sx={{ minHeight: 80 }}>
                        <Typography style={{ color: '#2e7d32' }}>
                          {isLoadingText && (
                            <Typewriter tam={30} minHeight={0} text="Para adicionar uma nova conta que pode ser bancária ou não, você pode clicar no botão CADASTRAR BANCO logo abaixo." />
                          )}
                        </Typography>
                      </Box>
                    </div>
                    <div className="d-none d-lg-block">
                      <Box sx={{ minHeight: 30 }}>
                        <Typography style={{ color: '#2e7d32' }}>
                          {isLoadingText && (
                            <Typewriter tam={30} minHeight={0} text="Para adicionar uma nova conta que pode ser bancária ou não, você pode clicar no botão CADASTRAR BANCO logo abaixo." />
                          )}
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                          <>
                            <strong>Cresça seu negócio controlando tudo que entra e sai do estoque</strong>
                          </>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <strong>Controle do estoque: </strong>
                            <span>você pode controlar o estoque da propriedade e dos fornecedores através de entradas, saídas e movimentações dos produtos</span>
                          </>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid> */}
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
        <Grid item sx={{ ml: 3, mt: 1 }}>
          <ModalAddBank />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <TableBank type={'income'} />
        </Paper>
      </Grid>
    </>
  );
};

export default BankAccountComponent;
