import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Female, Male } from '@mui/icons-material';
import ExcelExportListLivestock from './ExcelExportListLivestock';
import FullDialogCardAnimal from './FullDialogCardAnimal';

// function createData(identification, name, sex, dateBirth, earring, sisbov, weight, batch, breed, ageMonths) {
//   return { identification, name, sex, dateBirth, earring, sisbov, weight, batch, breed, ageMonths };
// }

const LivestockListTable = ({ livestock }) => {
  const [rows, setRows] = useState(livestock.animais);

  //importação
  // const handleImport = (data) => {
  //   const importedRows = data.map((row) => createData(...row));
  //   setRows(importedRows);
  // };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <DownloadButton filename="PlanilhaExemplo.xlsx" fileUrl="https://example.com/planilha.xlsx" /> */}
        {/* <DownloadButton filename="PlanilhaExemplo.xlsx" /> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: 'auto' }}>
          {/* <ExcelImport onImport={handleImport} /> */}
          {/* <ExcelExport data={rows} headers={['name', 'calories', 'fat', 'carbs', 'protein']} filename="data" /> */}
          {/* headers={["IDENIFICAÇÃO", "NOME", "SEXO", "DATA DE NASCIMENTO", "ID BRINCO", "ID SISBOV", "PESO", "LOTE", "RAÇA", "IDADE EM MESES"]} */}
          <ExcelExportListLivestock data={rows} filename="Planilha_FazendaPremium_Criacao" />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}> </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                IDENIFICAÇÃO
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                NOME
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center" title="Fêmea / Macho">
                SEXO (F/M)
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center" title="Data de Nascimento">
                DT. NASCIMENTO
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                ID BRINCO
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                ID SISBOV
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                PESO
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                LOTE
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                RAÇA
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                IDADE (MESES)
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="center">
                AÇÕES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length == 0 ? (
              <TableRow key={'emptyRows'} style={{ height: 53 }}>
                <TableCell colSpan={13}>
                  {' '}
                  <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
                    . . . Nenhum Animal identificado . . .
                  </Typography>{' '}
                  <Typography variant="subtitle1" sx={{ mt: 4, textAlign: 'left' }}>
                    Caso necessite de Informações individuais de cada animal (peso, nutrição e medicamentos) será necessário realizar a identicação dos animais no lote. Para isso, clique no botão
                    acima "Atividades de campo" e na próxima tela, clique no botão verde (+ NOVA ATIVIDADE) para criar uma atividade de identificação de animais.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {' '}
                    {row.icon_animal}{' '}
                  </TableCell>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{row.identifier}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="center" title={row.sexo}>
                    {row.sexo == 'Macho' ? <Male color="primary" /> : <Female color="secondary" />} {row.sexo.slice(0, 1)}
                  </TableCell>
                  <TableCell align="center">{row.date_birth_f}</TableCell>
                  <TableCell align="left">{row.brinco}</TableCell>
                  <TableCell align="left">{row.sisbov}</TableCell>
                  <TableCell align="left">{row.weight > 0 ? row.weight + ' (' + row.weight_unit + ')' : '-'}</TableCell>
                  <TableCell align="left">{row.lote}</TableCell>
                  <TableCell align="left">{row.raca}</TableCell>
                  <TableCell align="center">{row.ageMonths}</TableCell>
                  <TableCell align="center">
                    <FullDialogCardAnimal animal={row} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LivestockListTable;
