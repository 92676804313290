import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, ButtonBase, List, Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import SpaIcon from '@mui/icons-material/Spa'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function TutorialStockAccordion() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClickLivestock = () => {
    navigate('/livestock')
  }
  const handleButtonClickHarvest = () => {
    navigate('/harvest')
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            1-Cadastro de Produtos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            O Cadastro de Produtos (Insumos) é uma funcionalidade que permite a inclusão sistemática de todos os produtos ou insumos dentro do sistema
            de controle de estoque.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Este cadastro detalhado é crucial para o gerenciamento efetivo pois oferece uma visão clara do que está disponível, em que quantidade e em
            que condições.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            2-Cadastro de Fornecedores
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            O cadastro de fornecedores permite uma análise cuidadosa e a seleção de parceiros comerciais que ofereçam os melhores termos de qualidade,
            preço e prazo de entrega.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" fontWeight={500}>
          Com os produtos e fornecedores registrados, agora podemos lançar uma compra em pedidos de compra.
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            3-Pedido de compra
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            Um Pedido de Compra é a etapa inicial no processo de aquisição de produtos ou serviços, servindo como uma formalização do compromisso de
            compra entre sua empresa e o fornecedor.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Realizando um pedido de compra você poderá optar em:
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            - Registrar diretamente sua compra e lançar ou não no estoque e no financeiro.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            - Registrar a autorização de uma compra e ter o acompanhamento eficaz desde a solicitação até a entrega final.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Um Pedido de Compra não só facilita a gestão financeira ao integrar compras, controle de estoque e contas a pagar, mas também impulsiona o
            crescimento do seu negócio ao manter uma operação organizada e financeiramente saudável.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" fontWeight={500}>
          Formalizado o pedido de compra, você terá posse de um sistema eficiente e gerenciamento preciso e automatizado das entradas e saídas de
          produtos. Agorá basta monitorar...
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            4-Controle do estoque
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            O Controle de Estoque é um pilar fundamental na gestão eficiente de qualquer negócio, permitindo um acompanhamento preciso das mercadorias
            disponíveis.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Esse controle envolve o monitoramento de entradas, saídas e movimentações de produtos, assegurando que haja sempre um equilíbrio entre a
            oferta e a demanda. Uma gestão de estoque eficaz evita tanto a escassez quanto o excesso de produtos, contribuindo para a sustentabilidade
            financeira e operacional da empresa.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Inserir animais ou culturas </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Com a área criada, clique no botão criações para inserir os animais ou safras para inserir a cultura.
            </Typography>
            <div style={{ display: 'flex' }}>
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, margin: '0 10px' }}
                onClick={() => handleButtonClickLivestock()}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Criações
                  </Typography>
                </Stack>
              </ButtonBase>
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, margin: '0 10px' }}
                onClick={() => handleButtonClickHarvest()}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  <SpaIcon />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Safras
                  </Typography>
                </Stack>
              </ButtonBase>
            </div>
            <Box sx={{ mt: 2 }}>
              <Typography paragraph variant="subtitile1" fontWeight={500}>
                Caso queira inserir novas áreas, clique em fechar e depois em "CRIAR ÁREAS".
              </Typography>
            </Box>
          </List>
        </AccordionDetails>
      </Accordion> */}
    </div>
  )
}
