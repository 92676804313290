import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Loop } from "@mui/icons-material";
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';

export default function RestoreActivitiesCreationsRealized({ data, usestore }) {
  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context);
  const { idFarm } = useParams();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  //   id: string,
  //   isDeleted: boolean,
  //   planning: string,
  //   nome_bd: string,
  // }

  //console.log(data)

  const handleClick = async () => {
    // console.log('handleClickOpen')
    const header = { header: { 'Content-Type': 'application/json' } };

    const dataProgress = {
      id: data.id,
      isDeleted: data.isDeleted,
      planning: 'realized',
      change_planning: 'realized',
      id_property: idFarm,
      id_user: userCurrent.id,
    };

    let url = '';
    let reposActivity = {};

    if (data.activity === 'ENTRADA') {
      url = `/activityInput/updateplanning`;
      reposActivity = usestore.reposActivityInput;
    } else if (data.activity === 'IDENTIFICAÇÃO') {
      url = `/activityIdentify/update`;
      reposActivity = usestore.reposActivityIdentify;
    } else if (data.activity === 'MEDICAMENTOS') {
      url = `/activityMedicines/update`;
      reposActivity = usestore.reposActivityIdentify;
    } else if (data.activity === 'PESAGEM') {
      url = `/activityWeight/update`;
      reposActivity = usestore.reposActivityWeight;
    } else if (data.activity === 'PRODUÇÃO') {
      url = `/activityProduction/update`;
      reposActivity = usestore.reposActivityProduction;
    }

    //console.log(dataProgress)
    await api.put(url, dataProgress, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          toast.success(`Atividade restaurada com sucesso!`)
          // atualiza o repos
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
          })
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
      })
  }

  return (<>
    <IconButton color="info" aria-label="Restaurar" onClick={handleClick} title="Restaurar">
      <Loop />
    </IconButton>
  </>)
}
