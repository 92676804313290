import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
//import { ThemeContext } from "../../components/context-api/ThemeContext";
import { Context } from '../../components/Context/AuthContext';
import Loading from '../../components/Loader/Loading';
import { toast } from 'react-toastify';
import MessengerTabsAgricultutalList from './components/MessengerTabsAgricultutalList';
import useHarvestReposStore from '../../store/useHarvestReposStore';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { Alert, Box, Card, CardHeader, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import CardHarvestImage from './components/CardHarvestImage';
import CardImageList from './components/CardImageList';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CardTimeLinePainelImage from './components/CardTimeLinePainelImage';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import AddActivities from '../activitiesHarvest/components/AddButtonActivities/AddActivities';

export default function HarvestListContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const { idFarm, id } = useParams();
  useEffect(() => {
    //console.log("useEffect=ActivitiesHarvestContent atualiza a tabela se true ");
    //console.log(isLoadingActivities);
    if (isLoadingActivities === true) {
      setIsLoadingActivities(false);
    }
  }, [isLoadingActivities]);

  // instancia os repos
  const reposHarvest = useHarvestReposStore((state) => state);
  const listHarvests = reposHarvest.getHarvestStore('id', id);

  const [dataHarvest, setDataHarvest] = useState(listHarvests[0]);

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
      setIsLoading(false);
    }
  }, []);

  function print() {
    window.print();
  }

  // useEffect(() => {

  // }, []);

  const { aux_add, setAuxAdd } = useContext(Context);

  useEffect(() => {
    setDataHarvest(listHarvests[0]);
  }, [aux_add]);

  // setTimeout(() => {
  //   //setAuxAdd(aux_add + 1);
  // }, 500);

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const [isLoadingRegister, setIsLoadingRegister] = useState(false);

  setTimeout(() => {
    setIsLoadingRegister(false);
  }, 4000);

  const objText = {
    icon: <img src="/dist/img_fp/icon-button/icon-agricolaV1.png" width="45"></img>,
    title: 'Monitoramento a Cultura em Produção', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Com o projeto agrícola podemos planejar e gerenciar de forma eficiente as atividades agrícolas.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registre a evolução da cultura por meio de imagens', '2- Gerenciar atividades de campo'], // Texto complementar ou final
    linkButton: [`/harvest/${idFarm}/${id}`, `/activitiesHarvest/${idFarm}/${id}`], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 500);
  let txt_msn = 'A seguir, em "Registrar safra", capture imagens da evolução da cultura e, em seguida, planeje as práticas de manejo diretamente na tela de Atividades de Campo!';

  return (
    <>
      {isLoading === true ? (
        <Loading />
      ) : (
        <div className="content-wrapper bg-white">
          <div className="content-header" style={{ marginTop: 0 }}>
            <div className="container-fluid">
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ display: 'flex' }}>
                    <img src="/dist/img_fp/icon-button/icon-agricolaV1.png" width="45"></img>
                    <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      <Typography fontWeight="500" variant="h6">
                        Safra
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                        Cultura&nbsp;em&nbsp;produção
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                    <Box display="flex" justifyContent="flex-start">
                      <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                    </Box>
                    <TutorialStepGeral objText={objText} />
                    {/* <TutorialaddActivities /> */}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ mt: 1 }}>
                    <CardHeader
                      title={
                        <>
                          <Grid container spacing={2} sx={{ mb: 1 }}>
                            <Grid item xs={12} md={12}>
                              <Box sx={{ display: 'flex' }}>
                                {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                                  <Typography fontWeight="500" variant="h6">
                                    {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                                    Acompanhamento a cultura em produção
                                    <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                      Monitore regularmente o estado de saúde da cultura e avalie o seu desempenho.
                                    </Typography>
                                  </Typography>
                                </Stack>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} alignItems="center">
                              {showText ? (
                                <Box display="flex" alignItems="center">
                                  <Typewriter text={txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  <ThreeDotsSpinner />
                                </Box>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box display="flex" justifyContent="flex-start">
                                {/* <ButtonRegisterProductionTargetHarvest areas={areas} /> */}
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      }
                    ></CardHeader>
                    <Divider />
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
          <>
            <Box className="content">
              <Box className="container-fluid">
                <Box className="card">
                  <Box className="card-body">
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img alt="complex" height={50} src={dataHarvest.cultivation_icon} style={{ marginRight: '8px' }} />
                        <Box sx={{ flex: '1', flexDirection: 'column' }}>
                          <Typography component="span">Cultura da safra: {dataHarvest.cultivation_name}</Typography>
                          <Typography component="h3" variant="h6" sx={{ fontWeight: 'bold', color: 'success.main', textTransform: 'uppercase' }}>
                            {dataHarvest.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ ml: 'auto', flex: '1', flexDirection: 'column', textAlign: 'right' }}>
                        <Typography component="span" variant="subtitle1" className="text-success d-none d-lg-block">
                          <i className="fas fa-arrow-up" /> Data inicial: {dataHarvest.data_inicial}
                        </Typography>
                        <Typography component="span" variant="subtitle1" className="d-none d-lg-block text-success">
                          <i className="fas fa-arrow-up" /> Previsão de fim: {dataHarvest.data_fim ? dataHarvest.data_fim : ''}
                        </Typography>
                        <Typography component="span" variant="subtitle1" className="d-none d-lg-block text-success">
                          <i className="fas fa-arrow-up" /> Situação: {dataHarvest.situation}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12}>
                    <Accordion expanded={expanded} onChange={handleChange}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <AddAPhotoIcon sx={{ mr: 1 }} />
                        <Typography>Registrar safra</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardHarvestImage reposHarvest={reposHarvest} expanded={expanded} setExpanded={setExpanded} setIsLoadingRegister={setIsLoadingRegister} />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sx={{ height: 50 }}>
                    {isLoadingRegister && (
                      <Alert variant="filled" severity="success">
                        Registrado com sucesso.
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Container>
            <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12}>
                    <CardTimeLinePainelImage dataProgress={dataHarvest.progress} date_start={dataHarvest.date_start} />
                  </Grid>
                </Grid>
              </Paper>
            </Container>

            {/* <div className="content-wrapper bg-white">
              <div className="content-header" style={{ marginTop: 40 }}></div>
              <div className="table-responsive p-0">
                <MessengerTabsAgricultutalList />
              </div>
            </div> */}
          </>
        </div>
      )}
    </>
  );
}
