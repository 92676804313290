import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SidebarChatCompactMin from "./SidebarChatCompactMin";
import CardTimeLineMessenger from "./CardTimeLineMessenger";
import SidebarChatCompactMinTrash from "./SidebarChatCompactMinTrash";
import SidebarChatCompactGeneral from "./SidebarChatCompactGeneral";
import ListIcon from '@mui/icons-material/List';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid } from "@mui/material";
import BoardMessenger from "./Boards/BoardMessenger";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MessengerTabs({ isLoading, setIsLoading }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} >
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} value={value} onChange={handleChange} aria-label="basic tabs example"
          sx={{
            ".Mui-selected": {
              'color': "#2e7d32 !important",
              'fontWeight': 'bold',
            },
          }} >
          {/* <Tab label="Encaminhadas" {...a11yProps(0)} /> */}
          <Tab label={
            <Grid container direction="row" alignItems="center">
              <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />Lista
            </Grid>
          } {...a11yProps(0)} />
          <Tab label={
            <Grid container direction="row" alignItems="center">
              <ViewColumnIcon sx={{ mr: 0.5, mb: 0.2 }} />Progresso
            </Grid>
          } {...a11yProps(1)} />
          <Tab label={
            <Grid container direction="row" alignItems="center">
              <DeleteOutlineIcon sx={{ mr: 0.5, mb: 0.2 }} />Lixeira
            </Grid>
          } {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SidebarChatCompactGeneral isLoading={isLoading} setIsLoading={setIsLoading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ ml: -1 }} style={{ backgroundColor: "#ecf1f8", font: "14px", collor: "#333" }}>
          <BoardMessenger />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SidebarChatCompactMinTrash />
      </TabPanel>
    </Box>
  );
}
