import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../../components/Context/AuthContext";
import Loading from "../../components/Loader/Loading";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import useLivestockReposStore from "../../store/useLivestockReposStore";

export default function LivestockCreationsContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const property_id = (localStorage.getItem("Property") || 1);

  // instancia os repos
  const reposLivestocks = useLivestockReposStore((state) => state);
  const listLivestocks = reposLivestocks.listLivestock;

  const [dataLivestock, setDataLivestock] = useState(listLivestocks);

  useEffect(() => {
    if (isLoading) {
      setLoadingList(false);
      setIsLoading(false);
    }
  }, []);

  function formatData(Inputdata) {
    return (!Inputdata ? "" : dayjs(Inputdata).format('DD/MM/YYYY'));
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 60 }}></div>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="">
              <div className="row ">
                <div className="col-8 col-md-6">
                  <img src="/dist/img_fp/icon-button/todas_pecuarias.png" className="img-fluid" width="80" height="50" alt="Imagem responsiva"></img>
                  <span className="align-middle ml-3 text-uppercase">Criações</span>
                </div>
                {/* <div className="col-lg-3"><h3 className="card-title">Listas das atividades pecuária no sistema</h3></div> */}
                <div className="col-4 col-md-6"></div>
              </div>
            </div>
            <div className=" ">
              <div className="">
                {/* <CardCreations /> */}
                <div className="card card-success card-tabs">
                  <div className="card-header p-0 pt-1">
                    <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                      {/* <li className="pt-2 px-3">
                        <h3 className="card-title">Criações</h3>
                      </li> */}
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-two-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-two-home"
                          role="tab"
                          aria-controls="custom-tabs-two-home"
                          aria-selected="true"
                        >
                          Ativas
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-two-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-two-profile"
                          role="tab"
                          aria-controls="custom-tabs-two-profile"
                          aria-selected="false"
                        >
                          Finalizadas
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-two-messages-tab"
                          data-toggle="pill"
                          href="#custom-tabs-two-messages"
                          role="tab"
                          aria-controls="custom-tabs-two-messages"
                          aria-selected="false"
                        >
                          Deletadas
                        </a>
                      </li> */}
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-two-settings-tab"
                          data-toggle="pill"
                          href="#custom-tabs-two-settings"
                          role="tab"
                          aria-controls="custom-tabs-two-settings"
                          aria-selected="false"
                        >
                          Settings
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="mt-4">
                    <div className="tab-content" id="custom-tabs-two-tabContent">
                      <div className="tab-pane fade show active" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                        {/* Listas das atividades no sistema*/}
                        <div className="content mt-1">
                          <div className="container-fluid">
                            <div className="row ">
                              <div className="col-lg-12">
                                <div className="card">
                                  <div className="card-body table-responsive p-0">
                                    {loadingList == true ? (
                                      <Loading />
                                    ) : (
                                      <table id="example1" className="table table-head-fixed ">
                                        <thead>
                                          <tr>
                                            <th>Nome da atividade</th>
                                            <th>Situação</th>
                                            <th>Tipo de atividade</th>
                                            <th>Data inicial</th>
                                            <th>Previsão de fim</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {dataLivestock
                                            .filter((item) => item.situation == "ativa")
                                            .map((item) => (
                                              <tr key={item.id}>
                                                <td className="text-uppercase">
                                                  <NavLink to={{ pathname: "/livestock/" + property_id + "/" + item.id }} className="small-box-footer text-success">
                                                    {item.name}
                                                  </NavLink>
                                                </td>
                                                <td>{item.situation}</td>
                                                <td>{item.tipo}</td>
                                                <td> {formatData(item.data_inicial)}</td>
                                                <td>{item.data_fim ? formatData(item.data_fim) : ""}</td>
                                                <td>
                                                  {/* <button className="btn btn-info btn-sm mr-1 mb-1"  ><i style={{ color: "white" }} className="fa fa-list fa-fw" aria-hidden="true"></i></button> */}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* FIM Listas das atividades no sistema*/}
                      </div>
                      <div className="tab-pane fade" id="custom-tabs-two-profile" role="tabpanel" aria-labelledby="custom-tabs-two-profile-tab">
                        <div className="card-body table-responsive p-0">
                          {isLoading == true ? (
                            <Loading />
                          ) : (
                            <table id="example1" className="table table-head-fixed ">
                              <thead>
                                <tr>
                                  <th>Nome da atividade</th>
                                  <th>Situação</th>
                                  <th>Tipo de atividade</th>
                                  <th>Data inicial</th>
                                  <th>Previsão de fim</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataLivestock
                                  .filter((item) => item.situation == "finalizada")
                                  .map((item) => (
                                    <tr key={item.id}>
                                      <td className="text-uppercase">
                                        <NavLink to={{ pathname: "/livestock/" + property_id + "/" + item.id }} className="small-box-footer text-success">
                                          {item.nome}
                                        </NavLink>
                                      </td>
                                      <td>{item.situation}</td>
                                      <td>{item.tipo}</td>
                                      <td> {formatData(item.data_inicial)}</td>
                                      <td>{item.data_fim ? formatData(item.data_fim) : ''}</td>
                                      <td>{/* <button className="btn btn-info btn-sm mr-1 mb-1"  ><i style={{ color: "white" }} className="fa fa-list fa-fw" aria-hidden="true"></i></button> */}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="custom-tabs-two-messages" role="tabpanel" aria-labelledby="custom-tabs-two-messages-tab">
                        Em construção
                      </div>
                      {/* <div className="tab-pane fade" id="custom-tabs-two-settings" role="tabpanel" aria-labelledby="custom-tabs-two-settings-tab">
                        Pellentesque vestibulum commodo nibh nec blandit. Maecenas neque magna, iaculis tempus turpis ac, ornare sodales tellus. Mauris eget blandit dolor. Quisque tincidunt venenatis
                        vulputate. Morbi euismod molestie tristique. Vestibulum consectetur dolor a vestibulum pharetra. Donec interdum placerat urna nec pharetra. Etiam eget dapibus orci, eget
                        aliquet urna. Nunc at consequat diam. Nunc et felis ut nisl commodo dignissim. In hac habitasse platea dictumst. Praesent imperdiet accumsan ex sit amet facilisis.
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
