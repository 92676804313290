import { SvgIcon } from "@mui/material";

// icon suino
export const IconPork = ({ widthStyles }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} style={{ width: widthStyles, height: "auto", transform: 'scaleX(-1)' }} viewBox="0 0 392.000000 210.000000" preserveAspectRatio="xMidYMid meet">
        <path
          transform="translate(0.000000,210.000000) scale(0.100000,-0.100000) "
          d="M1860 1954 c-91 -10 -192 -27 -263 -45 -125 -32 -448 -153 -542 -204
-61 -34 -92 -45 -110 -41 -13 3 -67 11 -120 16 -54 6 -117 19 -141 29 l-44 20
0 -22 c1 -12 7 -33 15 -47 26 -46 19 -50 -42 -29 -31 10 -57 18 -59 17 -1 -2
8 -29 21 -60 28 -69 90 -165 116 -179 11 -5 19 -18 19 -29 0 -40 -44 -135 -82
-176 -44 -48 -127 -94 -169 -94 -26 0 -27 -1 -18 -37 5 -21 9 -56 9 -79 0 -36
4 -42 38 -62 22 -12 63 -27 93 -33 30 -6 74 -22 99 -35 83 -44 313 -87 430
-81 34 2 35 1 43 -48 4 -27 9 -102 10 -165 2 -112 1 -117 -32 -188 -40 -88
-35 -97 39 -85 50 8 55 11 81 58 21 38 28 64 28 109 1 58 18 126 32 126 10 0
17 -35 25 -120 6 -70 4 -80 -21 -128 -36 -68 -30 -81 32 -75 26 3 54 9 62 13
21 11 40 93 45 185 4 86 39 252 60 280 11 15 18 15 86 1 120 -24 383 -48 540
-48 160 -1 274 17 456 72 55 16 102 30 105 30 8 0 45 -85 75 -174 16 -45 24
-92 24 -134 0 -58 -4 -71 -40 -128 -22 -35 -40 -66 -40 -69 0 -3 31 -5 69 -5
l69 0 12 34 c6 19 17 40 25 46 8 7 17 28 21 49 3 20 17 76 31 124 14 49 23
100 20 113 -5 29 -6 29 42 4 67 -34 77 -56 86 -180 l7 -111 -36 -52 c-20 -29
-36 -56 -36 -60 0 -4 27 -7 60 -7 54 0 61 2 76 27 9 16 26 41 37 57 14 21 18
38 13 66 -4 27 3 65 25 136 28 93 29 103 18 162 -15 82 -8 120 35 190 53 87
92 195 115 315 25 130 26 195 5 288 -15 66 -15 72 2 90 9 10 25 19 35 19 27 0
77 50 85 85 l6 30 -32 -37 c-17 -21 -37 -38 -43 -38 -8 0 -7 4 1 12 17 17 15
74 -4 92 -21 22 -67 20 -87 -2 -21 -23 -22 -85 -2 -102 8 -7 12 -17 9 -22 -3
-5 -29 16 -56 46 -116 131 -277 212 -530 268 -84 19 -132 21 -498 23 -223 2
-421 1 -440 -1z"
        />
      </SvgIcon>
    </>
  );
};
