import * as React from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { Balance } from '@mui/icons-material'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import Typewriter from '../../../../../components/Typewriter/Typewriter'
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner'
import CampaignIcon from '@mui/icons-material/Campaign'
import marketingGreen from '../../../../../assets/jpg/marketingGreen.jpg'

export default function FormMarketingHeader({ text_title, txt_msn, height, showTextFase, imagem }) {
  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5} sx={{ mt: -0.5 }}>
            <Typography variant="h6" fontWeight={500}>
              <CampaignIcon color="success" sx={{ mr: 1, width: '32px', height: '32px' }} /> Marketing
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item container alignItems="center" justifyContent="center">
        <Grid item sx={{ mt: 1, height: 120 }}>
          <img style={{ maxHeight: 140 }} src={marketingGreen} alt="image" className="img-fluid" />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>{text_title}</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2, minHeight: height || 'auto' }}>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Divider sx={{ mt: 1, mb: 1, ml: -3, borderColor: '#388e3c' }} />
    </>
  )
}
