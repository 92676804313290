import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Skeleton, Toolbar, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import LetterAvatarsFade from '../../../components/Avatar/LetterAvatarsFade';
import { formatValue } from '../../../utils/functions';
import ViewMaintenance from './componentsMaintenance/ViewMaintenance';
import dayjs from 'dayjs';
import DeleteMaintenance from './componentsMaintenance/DeleteMaintenance';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Data',
  },
  {
    id: 'team',
    align: 'center',
    disablePadding: false,
    label: 'Equipe',
  },
  {
    id: 'patrimony',
    align: 'center',
    disablePadding: false,
    label: 'Patrimonio',
  },
  {
    id: 'services',
    align: 'center',
    disablePadding: false,
    label: 'Serviço',
  },
  {
    id: 'products',
    align: 'center',
    disablePadding: false,
    label: 'Produto',
  },
  {
    id: 'value',
    align: 'right',
    disablePadding: false,
    label: 'Valor (R$)',
  },
  {
    id: 'acoes',
    align: 'right',
    disablePadding: false,
    label: 'Ações',
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={rowCount}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {'ordenada: ' + (order === 'desc' ? 'descendente' : 'ascendente')}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
function createData(id, date, team, patrimony, services = [], products = [], value, maintenance) {
  return { id, date, team, patrimony, services, products, value, maintenance };
}

export default function ListMaintenance() {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  //const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  // start repos
  const usestore = useStore();
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);

  //criado o state para o rows
  const [rows, setRowsActivities] = useState([]);

  //criado o handle para listar as manutenções
  const handleRows = () => {
    const listMaintenance = usestore.reposPatrimonyMaintenance.listPatrimonyMaintenance;
    let rows = [];
    let aux_patrim;

    for (const maintenance of listMaintenance) {
      // console.log(maintenance);
      aux_patrim = usestore.reposPatrimony.getPatrimonyStore('id', maintenance.id_patrimony)[0];
      rows.push(
        createData(
          maintenance.id,
          dayjs(maintenance.date_start).format('DD/MM/YYYY HH:mm'),
          maintenance.name_user,
          aux_patrim.name,
          maintenance.services.map((service) => service.name_expense) || '',
          maintenance.products.map((product) => product.name_product) || '',
          formatValue(maintenance.value),
          maintenance
        )
      );
    }

    setRowsActivities(rows);
    setIsLoadingTable(false);
  };

  useEffect(() => {
    // console.log('refresh TablesActivitiesHarvests')
    setTimeout(() => {
      handleRows();
    }, 200);
  }, [aux_add]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked)
  // }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [obj_fn, setObjFun] = useState({
    usestore: usestore,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    isMobile: isMobile,
    isOpen: 0,
    show_btn: true,
  });

  const getTableSkeleton = () => (
    <TableRow>
      {headCells.map((_, index) => (
        <TableCell key={`skeleton_${index}`} align="center">
          <Skeleton variant="rectangular" height={34} width={27} />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableMaintenance">
              Gerenciar Manutenções
            </Typography>
          </Toolbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Tabela Manutenções" size="medium">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {isLoadingTable
                  ? getTableSkeleton()
                  : stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow key={`${row.id}_${index}`} hover role="checkbox" tabIndex={-1} sx={{ cursor: 'pointer' }}>
                          <TableCell align="center">{row.date}</TableCell>
                          <TableCell align="center">
                            <LetterAvatarsFade name={row.team} textoTeam={`${row.team} registrou a manutenção na data: ${row.date}.`} />
                          </TableCell>
                          <TableCell align="center">{row.patrimony}</TableCell>
                          <TableCell align="center">{row.services.join(', ')}</TableCell>
                          <TableCell align="center">{row.products.join(', ')}</TableCell>
                          <TableCell align="right">{row.value ? row.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : row.value}</TableCell>
                          <TableCell align="right" sx={{ width: '100px' }}>
                            <Box sx={{ p: 0, display: 'flex' }}>
                              <ViewMaintenance row={row} obj_fn={obj_fn} />
                              <DeleteMaintenance row={row} obj_fn={obj_fn} setObjFun={setObjFun} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Linhas por página"
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
