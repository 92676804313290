import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Button, AppBar, InputAdornment, Toolbar, Typography } from '@mui/material';
import TitleIcon from '@mui/icons-material/Title';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { Span } from '../../../../../components/Typography';
import useLivestockReposStore from '../../../../../store/useLivestockReposStore';
import ToolipBoostrap from '../../../../../components/Tooltip/ToolipBoostrap';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';

export default function SecondStep({ data, setData, formError, handleClose, handleNext, handleBack, activeStep, steps }) {
  // console.log('SecondStep');
  const listLivestocks = useLivestockReposStore((state) => state);
  const [validName, setValidName] = useState([true, '']);

  // para validar o input
  const validateProjectName = (value) => {
    var name_livestock = value.trim();
    if (!name_livestock || name_livestock.length < 5) {
      setValidName([false, 'Por favor, insira um nome válido para seu projeto!']);
    } else {
      var list_livestocks = listLivestocks.getLivestockStore('name', name_livestock);
      if (list_livestocks.length > 0) {
        setValidName([false, 'Já existe um Projeto cadastrado com esse nome!']);
      } else {
        setValidName([true, '']);
      }
    }
  };

  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return <></>;
      } else {
        return (
          <p id="NameLivestockError" className="form-text text-danger is-invalid">
            {validName[1]}
          </p>
        );
      }
    } else {
      return validName[num];
    }
  };

  const handleChangeInput = (event, tipo) => {
    var value = event.target.value;
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setData((prevState) => ({ ...prevState, [event.target.name]: tipo ? value.trim() : value }));
    validateProjectName(value);
  };
  useEffect(() => {
    // console.log('RENDER - validateProjectName');
    validateProjectName(data.nameLivestock);
  }, []);

  const longText =
    'Dar um nome ao projeto é importante para identificação, comunicação e para motivar e engajar a equipe para um ideal. Além disso, contribui para a sua organização, o seu reconhecimento e registro adequado.';
  const text = 'Importância de dar um nome ao projeto.';

  return (
    <>
      <Grid container spacing={2} sx={{ my: 1, height: 200 }}>
        <Grid container spacing={2} sx={{ height: 65 }}>
          <Grid item xs={1} md={1}>
            <ContentPasteIcon sx={{ color: 'action.active', mr: 0.5 }} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography gutterBottom style={{ color: '#2e7d32' }}>
              <Typewriter text="Sugerimos um um nome para o seu projeto, caso queira mudar, sinta-se a vontade" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
          <TextField
            onChange={handleChangeInput}
            onBlur={(e) => handleChangeInput(e, 'name')}
            value={data.nameLivestock}
            fullWidth
            color="success"
            id="NameLivestock"
            name="nameLivestock"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TitleIcon sx={{ color: 'action.active' }} />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { color: '#808080' } }}
            label="Nome do Projeto"
            variant="standard"
            error={!returnValidName(0)}
            helperText={returnValidName(1)}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: -1 }}>
          <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
            Tipo de criação raça ano/ano <br /> Ex.: Pecuária de Corte Nelore 22/23
          </Typography>
        </Grid>
      </Grid>
      <ToolipBoostrap text={text} longText={longText} />
      <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
          Clique abaixo em continuar...
        </Typography>
      </Grid>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 4.8 }}>
        <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }}>
          Retornar
        </Button>
        <Button variant="contained" color="success" onClick={returnValidName(0) ? handleNext : () => null} disabled={!returnValidName(0)}>
          {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
        </Button>
      </Box> */}
      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>Cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} variant="text" color="inherit" disabled={true} sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            <Button
              sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite` }}
              variant="text"
              color="inherit"
              onClick={returnValidName(0) ? handleNext : () => null}
              disabled={!returnValidName(0)}
            >
              {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
