import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { AppBar, Box, Button, Checkbox, Container, Dialog, DialogContent, Divider, FormControlLabel, Grid } from "@mui/material";
import { IconButton, InputAdornment, Paper, Slide, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { AccountBalance, Close, Edit, PriceChange } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import api from "../../../../../services/api";
import { Context } from "../../../../../components/Context/AuthContext";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import { SelectBanks } from "../../../../../components/Icons/bank/BankList";
import SingleDateField from "../../../../../components/Fields/SingleDateField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// fillDialog normal que chama uma lista de botões de atividade
export default function EditBank({ row_data, usestore }) {
  const { aux_add, setAuxAdd } = useContext(Context);

  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(0);

  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const property_id = localStorage.getItem("Property") || 1;

  const bank_data = usestore.reposBank.getBankStore('id', row_data.id)[0];
  bank_data.balance_date = dayjs(bank_data.balance_entry);
  if (!bank_data.bank || !bank_data.bank.icon || !bank_data.bank.icon.$$typeof) {
    bank_data.bank = usestore.reposBank.getBankIcon('id', row_data.id, 'bank');
  }
  if (!bank_data.icon || !bank_data.icon.$$typeof) {
    bank_data.icon = usestore.reposBank.getBankIcon('id', row_data.id, 'icon');
  }
  if (!bank_data.type_icon || !bank_data.type_icon.$$typeof) {
    bank_data.icon = usestore.reposBank.getBankIcon('id', row_data.id, 'type_icon');
  }
  const [data, setData] = useState(bank_data);

  const isErrorDate = () => {
    if (!data.name_bank || !data.balance_date.$D || dayjs(data.balance_date).isAfter(dayjs(new Date())) ) {
      return true;
    }
    return false;
  };

  const handleChangeBank = (id, value) => {
    setData((prevState) => ({ ...prevState, ['bank']: value }));
    setData((prevState) => ({ ...prevState, ['name_bank']: (value?.bank || 'caixa') }));
    setUpdated(updated+1);
  };
  const handleChangeDate = (value) => {
    setData((prevState) => ({ ...prevState, balance_date: dayjs(value) }));
    setData((prevState) => ({ ...prevState, balance_entry: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    setData((prevState) => ({ ...prevState, balance_entry_f: dayjs(value).format("DD/MM/YYYY") }));
    setUpdated(updated+1);
  };
  const handleChange = (event, value) => {
    var field = event.target.name;
    var value = event.target.value;

    if (field == 'balance_opening') {
      value = parseFloat(value || "0");
    } else if (field == 'standard') {
      value = event.target.checked;
    }
    setData((prevState) => ({ ...prevState, [field]: value }));
    setUpdated(updated+1);
  };

  const isError = () => {
    if (!data.name || isErrorDate()) {
      return true;
    }
    return false;
  };

  const handleClickOpen = () => {
    setData(row_data);
    setUpdated(0);
    setOpen(true);
  };

  const handleClose = () => {
    setUpdated(0);
    setOpen(false);
  };

  const onSubmit = async (e) => {
    let formOk = true;

    if (!data.name || (!data.balance_date.$D || dayjs(data.balance_date).isAfter(dayjs(new Date())) )) {
      formOk = false;
    }

    if (formOk) {
      let bank_bkp = window.jQuery.extend(true, {}, data);

      data.standard = (data.standard ? 1 : 0);
      data.bank = '';
      data.icon = '';
      data.type_icon = '';
      data.balance_date = '';
      data.id_user = userCurrent.id;

      const header = { header: { "Content-Type": "application/json" } };
      const res = await api.put("/bank/update", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Conta bancária registrada com sucesso!");
            usestore.reposBank.fetch({ 'id_property': property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao cadastrar a Conta bancária, tente novamente mais tarde!");
        });

      // retorna dados para o data
      setTimeout(() => {
        //data = bank_bkp;
        data.standard = bank_bkp.standard;
        data.bank = bank_bkp.bank;
        data.icon = bank_bkp.icon;
        data.type_icon = bank_bkp.type_icon;
        data.balance_date = bank_bkp.balance_date;
      }, 150);
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  let obj_date = {
    label: 'Data inicial do saldo',
    field_data: 'balance_date',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    //error_date: error_date,
    setData: setData,
  }

  return ( <>
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> */}
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div">
                  Edição do Projeto
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ mb: 8 }}>
          <Container component="main" maxWidth="md" sx={{ mt: 1 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <Box >
                <Grid container spacing={1} sx={{}}>
                  <Grid item xs={12} md={12} style={{ justifyContent: "center" }}>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                      <Grid item className="d-none d-lg-block">
                        <Typography sx={{ color: "#2e7d32", textAlign: "center" }}>
                          <Typewriter tam={30} text="Altere os dados desejados, confira e clique em confirmar." />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} className="d-lg-none">
                        <Typography sx={{ color: "#2e7d32", textAlign: "center" }}>
                          <Typewriter tam={60} text="Altere os dados desejados, confira e clique em confirmar." />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, mb: 2, ml: -3, borderColor: '#388e3c' }}/>
                
                <Grid container sx={{ mb: 4 }}>
                  <Box sx={{ ml: 1, mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}>Dados da conta: ({data.type_title})</Typography>
                    </Stack>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={1} style={{maxWidth: '30px'}}>
                      <Box sx={{ mt: 2}}>
                        {(data.icon || '')}
                      </Box>
                    </Grid>
                    <Grid item xs={5} md={5} style={{minWidth: '220px'}}>
                      <Box sx={{ mt: 1 }}>
                        <SelectBanks value={data.bank} setValue={handleChangeBank}/>
                      </Box>
                    </Grid>

                    <Grid item xs={6} md={6} style={{minWidth: '220px'}}>
                      <TextField
                        required
                        fullWidth
                        color="success"
                        variant="standard"
                        label="Nome da conta financeira/bancária"
                        name="name"
                        value={data.name}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <AccountBalance />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        //error={!data.name || data.name.length < 5}
                        helperText="Identificador da conta para facilitar sua busca de informações."
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 3}}>
                    <Grid item xs={12} md={12}>
                      <Box sx={{ ml: 1, mb: 1, display: "flex", flexDirection: "column" }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Defina o saldo inicial </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={5} md={5} sx={{mt: -3}}>
                      <SingleDateField data={data} obj_date={obj_date} />
                    </Grid>
                    <Grid item xs={7} md={7}>
                      <TextField
                        fullWidth
                        color="success"
                        variant="standard"
                        label="Saldo inicial"
                        type="number"
                        name="balance_opening"
                        value={data.balance_opening}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e)}
                        helperText="Defina o saldo na data inicial selecionada"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <PriceChange />
                              </IconButton>
                            </InputAdornment>
                          ),
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 12,
                          min: 0,
                        }}
                      />
                    </Grid>
                  </Grid>

                  {(data.type == "ContaCorrente" || data.type == "Poupanca") && <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
                          <Stack spacing={0.5}>
                            <Typography fontWeight={700}>Dados da conta bancária</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          color="success"
                          variant="standard"
                          label="Agência"
                          name="agency"
                          value={data.agency}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          color="success"
                          variant="standard"
                          label="Número da conta"
                          name="account_number"
                          value={data.account_number}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          color="success"
                          variant="standard"
                          label="País"
                          name="country"
                          value={data.country}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="success"
                              name="standard"
                              checked={(data.standard > 0 ? true : false)}
                              onChange={handleChange}
                              onBlur={(e) => handleChange(e)}
                            />
                          }
                          label="Definir como Padrão"
                        />
                      </Grid>
                      <Typography variant="caption" sx={{ ml: 1, mt: 2 }}>
                        O Fazenda Premium preza por total segurança da sua conta! Não informe suas senhas!
                      </Typography>
                    </Grid>
                  </>}
                </Grid >
              </Box>
            </Paper>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <span>cancelar</span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="contained" type="submit" onClick={!isError() ? onSubmit : () => null} disabled={isError()} >
                <span sx={{ textTransform: "capitalize" }}>Salvar</span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>

      <IconButton aria-label="edicao" onClick={() => handleClickOpen()} title="Editar">
        <Edit sx={{ color: green[600] }} />{" "}
      </IconButton>
    </div>
  </>);
}
