import * as React from 'react';
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, ListItem, ListItemText } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Close, } from '@mui/icons-material';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ModalAddres({ data, open, handleClose }) {

  return ( <>
    <Dialog open={open} onClose={() => handleClose(data.id)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} 
      sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", maxWidth: "650px" } } }}>
      <DialogTitle>
        <div className="d-lg-none">
          <Toolbar sx={{ height: 50 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text={"Endereços disponíveis para: " + data.name} variant={"title"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close"> <Close /> </IconButton>
          </Toolbar>
        </div>
        <div className="d-none d-lg-block">
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text={"Endereços disponíveis para: " + data.name} variant={"h6"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close"> <Close /> </IconButton>
          </Toolbar>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ mb: 8, textAlign: "center" }}>
        <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }} style={{marginTop: '0px'}} >
          <Grid item container alignItems="center" sx={{ my: 1 }}>
            {data.addresses.map((addres, index) => (
              <Grid key={index + '_' + addres.id + '_' + data.type} container spacing={3} style={{padding: '5px'}}>
                <Card style={{border: '1px solid #2e7d32'}} >
                  <CardHeader title={addres.name} />
                  <CardContent sx={{ mt: '-30px' }} >
                    <Grid container spacing={4} >
                      <Grid item xs={4.5} sm={4.5}>
                        <ListItem>
                          <ListItemText primary="Endereço:" secondary={addres.address} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <ListItem>
                          <ListItemText primary="Número:" secondary={addres.number} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <ListItem>
                          <ListItemText primary="Complemento:" secondary={addres.complement} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={2.5} sm={2.5}>
                        <ListItem>
                          <ListItemText primary="Cep:" secondary={addres.cep} />
                        </ListItem>
                      </Grid>
                    </Grid >
                    <Grid container spacing={4}>
                      <Grid item xs={4} sm={4}>
                        <ListItem>
                          <ListItemText primary="Cidade:" secondary={addres.city} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <ListItem>
                          <ListItemText primary="Bairro:" secondary={addres.neighborhood} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <ListItem>
                          <ListItemText primary="Estado:" secondary={addres.state} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <ListItem>
                          <ListItemText primary="País:" secondary={addres.country} />
                        </ListItem>
                      </Grid>
                    </Grid >
                  </CardContent>
                  {/* <CardActions disableSpacing>
                    <IconButton aria-label="IMprimir">
                      <Print />
                      <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1" component="div">
                        Imprimir
                      </Typography>
                    </IconButton>
                  </CardActions> */}
                </Card >
              </Grid>
            ))}
          </Grid>
        </Container>
      </DialogContent>
      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={() => handleClose(data.id)}>
              <span>fechar</span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Dialog>
  </> );
}
