import usePropertyReposStore from './usePropertyReposStore';
import useLivestockReposStore from './useLivestockReposStore';
import useHarvestReposStore from './useHarvestReposStore';
import useAreasMapReposStore from './useAreasMapReposStore';
import useListImagesStore from './useListImagesStore';
import useFinancesReposStore from './useFinancesReposStore';
import useMessengerReposStore from './useMessengerReposStore';
import useUserReposStore from './useUserReposStore';
import useLivestockTargetReposStore from './useLivestockTargetReposStore';
// import usePlanLivestockReposStore from './usePlanLivestockReposStore';
import useActivityInputReposStore from './Activities/useActivityInputReposStore';
import useActivityPastureManagementReposStore from './Activities/useActivityPastureManagementReposStore';
import useActivityIdentifyReposStore from './Activities/useActivityIdentifyReposStore';
import useActivityWeightReposStore from './Activities/useActivityWeightReposStore';
import useActivityTransferBatchReposStore from './Activities/useActivityTransferBatchReposStore';
import useActivityAssignAreaReposStore from './Activities/useActivityAssignAreaReposStore';
import useActivityBatchReposStore from './Activities/useActivityBatchReposStore';
import useActivityPlantingReposStore from './Activities/useActivityPlantingReposStore';
import useActivityHarvestingReposStore from './Activities/useActivityHarvestingReposStore';
import useHarvestTargetCostReposStore from './useHarvestTargetCostReposStore';
import useHarvestTargetReposStore from './useHarvestTargetReposStore';
import useBankReposStore from './useBankReposStore';
import usePersonReposStore from './usePersonReposStore';
import useAccountReposStore from './useAccountReposStore';
import useBillsReposStore from './useBillsReposStore';
import useProductsReposStore from './useProductsReposStore';
import useBuysReposStore from './useBuysReposStore';
import useSalesReposStore from './useSalesReposStore';
import usePatrimonyReposStore from './usePatrimonyReposStore';
import useActivityMedicinesReposStore from './Activities/useActivityMedicinesReposStore';
import useActivityProductionReposStore from './Activities/useActivityProduction';
import useActivityMonitoringReposStore from './Activities/useActivityMonitoringReposStore';
import useActivityMarketingLivestockReposStore from './Activities/useActivityMarketingLivestockReposStore';
import useActivityNutritionReposStore from './Activities/useActivityNutritionReposStore';
import useActivityApplicationsReposStore from './Activities/useActivityApplicationsReposStore';
import useActivitySoilPreparationReposStore from './Activities/useActivitySoilPreparationReposStore';
import useDocFilesReposStore from './useDocFilesReposStore';
import useCashFlowReposStore from './Reports/useCashFlowReposStore';
import useCostProductionReposStore from './Reports/useCostProductionReposStore';
import usePatrimonyMaintenanceReposStore from './usePatrimonyMaintenanceReposStore';
import useActivityResourcesReposStore from './Activities/useActivityResourcesReposStore';

function useStore() {
  // ------ inicicia os repos das Propriedades ------
  const reposProperty = usePropertyReposStore((state) => state);
  // ------ inicicia os repos das livestocks (criações) ------
  const reposLivestock = useLivestockReposStore((state) => state);
  // ------ inicicia os repos das livestocks (criações) ------
  const reposHarvest = useHarvestReposStore((state) => state);
  // ------ inicicia os repos das Images ------
  const reposImages = useListImagesStore((state) => state);
  // ------ inicicia os repos dos files ------
  const reposDocFiles = useDocFilesReposStore((state) => state);
  // ------ inicicia os repos das Areas ------
  const reposAreas = useAreasMapReposStore((state) => state);
  // ------ inicicia os repos das solicitações ------
  const reposMessenger = useMessengerReposStore((state) => state);
  // ------ inicicia os repos dos usuários ------
  const reposUser = useUserReposStore((state) => state);

  // ------ repos ACTIVITIES LIVESTOCK
  // ------ inicicia os repos das ActivityInput (atividades de Entrada) ------
  const reposActivityInput = useActivityInputReposStore((state) => state);
  // ------ inicicia os repos das ctivityPastureManagement (atividades de manejo de pastagem) ------
  const reposActivityPastureManagement = useActivityPastureManagementReposStore((state) => state);
  // ------ inicicia os repos das identificações dos animais activityIdentify ------
  const reposActivityIdentify = useActivityIdentifyReposStore((state) => state);
  // ------ inicicia os repos das pesagens dos animais activityWeight ------
  const reposActivityWeight = useActivityWeightReposStore((state) => state);
  // ------ inicicia os repos das vinculações de áreas em harvest/livestock ------
  const reposActivityAssignArea = useActivityAssignAreaReposStore((state) => state);
  // ------ inicicia os repos das atividades de transferencia de lotes entre áreas ------
  const reposActivityTransferBatch = useActivityTransferBatchReposStore((state) => state);
  // ------ inicicia os repos das atividades de novo lote ------
  const reposActivityBatch = useActivityBatchReposStore((state) => state);
  // ------ inicicia os repos das atividades de mediacmentos ------
  const reposActivityMedicines = useActivityMedicinesReposStore((state) => state);
  // ------ inicicia os repos das atividades de NUTRIÇÃO ------
  const reposActivityNutrition = useActivityNutritionReposStore((state) => state);

  // ------ inicicia os repos das atividades de produções animais ------
  const reposActivityProduction = useActivityProductionReposStore((state) => state);
  // ------ inicicia o repos atividade de marketong livestock ------
  const reposMarketingLivestock = useActivityMarketingLivestockReposStore((state) => state);

  // ------ repos ACTIVITIES HARVEST
  const reposActivityPlanting = useActivityPlantingReposStore((state) => state);
  const reposActivityHarvesting = useActivityHarvestingReposStore((state) => state);
  const reposActivityMonitoring = useActivityMonitoringReposStore((state) => state);
  const reposActivityApplications = useActivityApplicationsReposStore((state) => state);
  const reposActivitySoilPreparation = useActivitySoilPreparationReposStore((state) => state);
  // ------ inicicia os repos das Finanças ------
  const reposFinances = useFinancesReposStore((state) => state);
  // ------ inicicia os repos do Planejamento ------
  const reposTargetHarvest = useHarvestTargetReposStore((state) => state);
  const reposTargetLivestock = useLivestockTargetReposStore((state) => state);
  const reposHarvestTargetCost = useHarvestTargetCostReposStore((state) => state);
  // corrigir e refatorar usePlanLivestock para reposPlanLivestock
  //const reposPlanLivestock = usePlanLivestockReposStore((state) => state);

  // ------ inicicia os repos de Recursos utilizados nas atividades ------
  const reposActivityResources = useActivityResourcesReposStore((state) => state);

  // ------ inicicia os repos do Banco ------
  const reposBank = useBankReposStore((state) => state);
  // ------ inicicia os repos das pessoas ------
  const reposPerson = usePersonReposStore((state) => state);
  // ------ inicicia os repos das contas ------
  const reposAccount = useAccountReposStore((state) => state);
  // ------ inicicia os repos das contas a pagar/receber ------
  const reposBill = useBillsReposStore((state) => state);
  // ------ inicicia os repos dos produtos ------
  const reposProducts = useProductsReposStore((state) => state);
  // ------ inicicia os repos das compras ------
  const reposBuys = useBuysReposStore((state) => state);
  // ------ inicicia os repos das vendas ------
  const reposSales = useSalesReposStore((state) => state);
  // ------ inicicia o repos patrimônios ------
  const reposPatrimony = usePatrimonyReposStore((state) => state);
  // ------ inicicia o repos manutenção patrimônios ------
  const reposPatrimonyMaintenance = usePatrimonyMaintenanceReposStore((state) => state);
  //console.log('reposPatrimonyMaintenance');
  //console.log(reposPatrimonyMaintenance);
  // ------ repos RELATÓRIOS
  // ------ repos Fluxo de Caixa
  const reposCashFlow = useCashFlowReposStore((state) => state);
  // ------ repos Custos de Produção
  const reposCostProduction = useCostProductionReposStore((state) => state);

  const display_demo = JSON.parse(localStorage.getItem('display')) || false;

  return {
    display_demo: display_demo,
    reposProperty: reposProperty,
    reposLivestock: reposLivestock,
    reposHarvest: reposHarvest,
    reposImages: reposImages,
    reposDocFiles: reposDocFiles,
    reposAreas: reposAreas,
    reposMessenger: reposMessenger,
    reposFinances: reposFinances,
    reposUser: reposUser,
    // activities livestock
    reposActivityInput: reposActivityInput,
    reposMarketingLivestock: reposMarketingLivestock,
    reposActivityPastureManagement: reposActivityPastureManagement,
    reposActivityTransferBatch: reposActivityTransferBatch,
    reposActivityIdentify: reposActivityIdentify,
    reposActivityWeight: reposActivityWeight,
    reposActivityAssignArea: reposActivityAssignArea,
    reposActivityBatch: reposActivityBatch,
    reposActivityMedicines: reposActivityMedicines,
    reposActivityNutrition: reposActivityNutrition,
    reposActivityProduction: reposActivityProduction,
    // activities harvest
    reposActivityPlanting: reposActivityPlanting,
    reposActivitySoilPreparation: reposActivitySoilPreparation,
    reposActivityHarvesting: reposActivityHarvesting,
    reposActivityMonitoring: reposActivityMonitoring,
    reposActivityApplications: reposActivityApplications,
    reposTargetHarvest: reposTargetHarvest,
    reposTargetLivestock: reposTargetLivestock,
    reposHarvestTargetCost: reposHarvestTargetCost,
    // /reposPlanLivestock: reposPlanLivestock,
    reposActivityResources: reposActivityResources,
    reposBank: reposBank,
    reposPerson: reposPerson,
    reposAccount: reposAccount,
    reposBill: reposBill,
    reposProducts: reposProducts,
    reposBuys: reposBuys,
    reposSales: reposSales,
    reposPatrimony: reposPatrimony,
    reposPatrimonyMaintenance: reposPatrimonyMaintenance,
    // repost de relatórios
    reposCashFlow: reposCashFlow,
    reposCostProduction: reposCostProduction,
  };
}

export default useStore;
