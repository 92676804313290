import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ControlPanelHarvestContent from "./ControlPanelHarvestContent";
import Footer from "../../components/Footer/Footer";

function ControlPanelHarvest() {
  return (
    <>
      {/* <Nav /> */}
      <NavSide />
      <NavBotton />
      <ControlPanelHarvestContent />
      <Footer />
    </>
  );
}

export default ControlPanelHarvest;
