import React, { useState, useContext } from 'react'
import { Dialog, Container, DialogContent, IconButton, Stack, Slide } from '@mui/material'
import { AppBar, Grid, Paper, Toolbar, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { Box, Button, ListItem, ListItemText, Divider, Select, MenuItem, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { deDE } from '@mui/x-date-pickers/locales'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { toast } from 'react-toastify'
import { green } from '@mui/material/colors'
import api from '../../../../../services/api'
import { Context } from '../../../../../components/Context/AuthContext'
import Typewriter from '../../../../../components/Typewriter/Typewriter'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { createTheme } from '@mui/material/styles'
import TableWeightAnimals from '../../FormActivities/Weight/TableWeightAnimals'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  content: {
    width: '100%',
    maxWidth: 'none',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const theme = createTheme(
  { palette: { primary: { main: '#1976d2' } } },
  deDE // use 'de' locale for UI texts (start, next month, ...)
)

const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' }

// fillDialog normal que chama uma lista de botões de atividade
export default function EditActivityWeight({ row_data, usestore }) {
  const { aux_add, setAuxAdd, isMobile } = useContext(Context)
  const ismobile = isMobile.any()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [updated, setUpdated] = useState(0)

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const property_id = localStorage.getItem('Property') || 1

  const handleActivity = () => {
    //seta infos para data
    let this_activity = JSON.parse(JSON.stringify(usestore.reposActivityWeight.getActivityWeightStore('id', row_data.id)[0]))
    this_activity.table_animals = []
    this_activity.table_confirm = []
    this_activity.table_selected = {}

    if (this_activity.weight_lotes.length > 0) {
      this_activity.weight_lotes.map((row) => {
        this_activity.table_selected['0'] = { id: 0, identifier: 'Não identificados (' + row.num_animals + ')', sexo: '-' }
        // para add na primeira posição: unshift
        this_activity.table_animals.push({
          id: 0,
          identifier: 'Não identificados (' + row.num_animals + ')',
          sexo: '-',
          weight: row.weight,
          new_weight: row.weight,
        })
      })
    }
    if (this_activity.weight_animals.length > 0) {
      this_activity.weight_animals.map((row) => {
        this_activity.table_selected[row.id] = { id: row.id, identifier: row.identifier, sexo: row.sexo }
        this_activity.table_animals.push({
          id: row.id,
          identifier: row.identifier,
          sexo: row.sexo,
          weight: row.weight || 0,
          new_weight: row.weight || 0,
        })
      })
    }

    return this_activity
  }

  let this_activity = handleActivity()
  const [data, setData] = useState(this_activity)

  const handleClickOpen = () => {
    setUpdated(0)
    setOpen(true)
  }

  const handleClose = () => {
    //reseta informações
    let this_activity = handleActivity()
    setData(this_activity)
    setUpdated(0)
    setOpen(false)
  }

  const [errorr, setError] = useState(false)
  // const para validar o button continuar
  const isError = () => {
    return !updated || !value.$D || !valueEnd.$D || errorr
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let formOk = !isError()

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } }
      data.id_user_current = userCurrent.id

      data.table_edit = data.table_animals.filter((animal) => {
        if (data.table_selected[animal.id]) {
          return animal
        }
      })

      const res = await api
        .put('/activityWeight/update', data, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('Atividade editada com sucesso!')
            // atualiza o repos
            usestore.reposLivestock.fetch({ id_farm: property_id })
            usestore.reposActivityWeight.fetch().then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 100)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao editar atividade. Tente novamente mais tarde!')
        })
    } else {
      toast.error('Por favor, preencha os campos obrigatórios')
    }
  }

  // validations first step start -----
  const [value, setValue] = useState(dayjs(data.date_start))
  const [valueEnd, setValueEnd] = useState(dayjs(data.date_prev_end))

  /*const handleChangePlan = (plan, value) => {
    console.log("handleChange planning");
    setData((prevState) => ({ ...prevState, 'planning': value }));
  };*/

  const getTableAnimals = () => {
    const tabelaAnimals = <TableWeightAnimals data={data} edicao={setUpdated} setError={setError} />
    return (
      <>
        {!ismobile ? (
          <Grid item xs={12} md={12} className={classes.container} sx={{ mt: 1, mb: 5 }}>
            {tabelaAnimals}
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ width: '100%', overflow: 'auto' }}>
            {tabelaAnimals}
          </Grid>
        )}
      </>
    )
  }

  return (
    <>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    {' '}
                    Edição da Pesagem
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar" title="Fechar">
                {' '}
                <CloseIcon />{' '}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <DialogContent sx={{ mb: 8 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
              <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
                <Box>
                  <Grid item container alignItems="center" justifyContent="center">
                    <Grid item sx={{ mt: 1, height: 120 }}>
                      <img style={{ maxHeight: 120 }} src="/dist/image/entrada.png" alt="image" className="img-fluid" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{}}>
                    <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
                      <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item className="d-none d-lg-block">
                          <Typography sx={{ color: '#2e7d32', textAlign: 'center' }}>
                            <Typewriter tam={30} text="Altere os dados desejados, confira e clique em confirmar." />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} className="d-lg-none">
                          <Typography sx={{ color: '#2e7d32', textAlign: 'center' }}>
                            <Typewriter tam={60} text="Altere os dados desejados, confira e clique em confirmar." />
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <RadioGroup sx={{ mt: 1 }} aria-label="planning" name="planning" value={data.planning} onChange={(e) => handleChangePlan("planning", e.target.value)} defaultValue="" style={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel value="realized" name="planning" control={<Radio color="success" />} label={<div style={faseStyle("realized")}>Realização</div>} />
                      <FormControlLabel value="planning" name="planning" control={<Radio color="success" />} label={<div style={faseStyle("planning")}>Planejamento</div>} />
                    </RadioGroup> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ mt: 2, height: 110 }}>
                      <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                        <div className="d-lg-none">
                          <Stack spacing={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <MobileDatePicker
                                  label="Data de Início*"
                                  value={dayjs(data.date_start)}
                                  onChange={(newValue) => {
                                    setValue(newValue)
                                    const newValueEnd = dayjs(newValue).add(3, 'day')
                                    setValueEnd(newValueEnd)

                                    setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }))
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format('YYYY-MM-DD HH:mm:ss') }))
                                    setUpdated(updated + 1)
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  renderInput={(params) => <TextField {...params} color="success" />}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MobileDatePicker
                                  label="Data de Término*"
                                  value={dayjs(data.date_prev_end)}
                                  onChange={(newValue) => {
                                    setValueEnd(newValue)
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }))
                                    setUpdated(updated + 1)
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  minDate={value}
                                  renderInput={(params) => <TextField {...params} color="success" />}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </div>
                        <div className="d-none d-lg-block">
                          <Stack spacing={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <DesktopDatePicker
                                  label="Data de Início*"
                                  value={dayjs(data.date_start)}
                                  onChange={(newValue) => {
                                    setValue(newValue)
                                    const newValueEnd = dayjs(newValue).add(3, 'day')
                                    setValueEnd(newValueEnd)

                                    setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }))
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format('YYYY-MM-DD HH:mm:ss') }))
                                    setUpdated(updated + 1)
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      color="success"
                                      error={!value.$D}
                                      helperText={
                                        !value.$D && (
                                          <p id="date_start_helper" className="form-text text-danger is-invalid">
                                            {' '}
                                            Por favor, insira uma data válida.{' '}
                                          </p>
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <DesktopDatePicker
                                  label="Data de Término*"
                                  value={dayjs(data.date_prev_end)}
                                  onChange={(newValue) => {
                                    setValueEnd(newValue)
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }))
                                    setUpdated(updated + 1)
                                  }}
                                  color="success"
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  minDate={value}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      color="success"
                                      error={!valueEnd.$D}
                                      helperText={
                                        !valueEnd.$D && (
                                          <p id="date_prev_end_helper" className="form-text text-danger is-invalid">
                                            {' '}
                                            Por favor, insira uma data válida.{' '}
                                          </p>
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </div>
                        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: '#808080' }}>
                          Data prevista para início da atividade.
                        </Typography>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container spacing={2} sx={{}}>
                    <Grid item xs={6} md={6}>
                      <ListItem>
                        <ListItemText primary="Nome do Lote" secondary={data.lote.name} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <ListItem>
                        <ListItemText primary="Fase de manejo" secondary={data.lote.fase_name} />
                      </ListItem>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={3} sx={{}}>
                    <Grid item xs={4} md={4}>
                      <ListItem sx={{ textAlign: 'center' }}>
                        <ListItemText primary="Não identificados" secondary={data.num_animals} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <ListItem sx={{ textAlign: 'center' }}>
                        <ListItemText primary="Fêmeas" secondary={data.num_females} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <ListItem sx={{ textAlign: 'center' }}>
                        <ListItemText primary="Machos" secondary={data.num_males} />
                      </ListItem>
                    </Grid>
                  </Grid>
                  <Divider />

                  <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}> Tabela de pesagem dos animais </Typography>
                    </Stack>
                    {getTableAnimals()}
                  </Grid>

                  <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                    <Toolbar>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                          Cancelar
                        </Button>
                      </Box>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Button color="success" variant="contained" type="submit" onClick={!updated ? () => null : onSubmit} disabled={isError()}>
                          Salvar
                        </Button>
                      </Box>
                    </Toolbar>
                  </AppBar>
                </Box>
              </Paper>
            </Container>
          </DialogContent>
        </Dialog>
        <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
          <EditIcon sx={{ color: green[500] }} />
        </IconButton>
      </div>
    </>
  )
}
