import React from 'react'
import Typography from '@mui/material/Typography'
import { Checkmark } from "react-checkmark";
import { AppBar, Box, Button, ButtonBase, Container, Paper, Stack, Toolbar } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function SuccessIdentification({ handleClose, data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/livestock/${data.id_property}/${data.id_livestock}`);
  };

  let text_msg = "Exelente!\nAcesse a tela de lista de Animais para visualizar os animais identificados."

  return (
    <>
      <Container component="main" maxWidth="lg" >
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }} >
              Identificação dos animais {data.progress}!
            </Typography>
            <div className="d-lg-none"><Box sx={{ flexGrow: 1, }}>
              <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                <Typewriter tam={130} text={text_msg} />
              </Typography>
            </Box></div>
            <div className="d-none d-lg-block"><Box sx={{ flexGrow: 1, mb: 2 }}>
              <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                <Typewriter tam={50} text={text_msg} />
              </Typography>
            </Box></div>
            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{ p: 2, borderRadius: 1 }}
              onClick={() => handleButtonClick()}
              className={classes.button}
            >
              <Stack direction="column" alignItems="center">
                <img
                  style={{ maxHeight: 100 }}
                  src="/dist/image/entrada.png"
                  class="img-fluid"
                  alt="Lista de Animais"
                />
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  Lista de Animais (Criação)
                </Typography>
              </Stack>
            </ButtonBase>
          </div>
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
