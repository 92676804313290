import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Camera } from '@mui/icons-material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import html2canvas from 'html2canvas';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
export function ScreenshotButton({ onScreenshotTaken, screenshot }) {
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);

  async function handleTakeScreenshot() {
    setIsTakingScreenshot(true);

    const canvas = await html2canvas(document.querySelector('html'));
    const base64image = canvas.toDataURL('image/png');

    console.log(base64image);

    onScreenshotTaken(base64image);
    setIsTakingScreenshot(false);
  }

  if (screenshot) {
    return (
      <Button
        onClick={() => onScreenshotTaken(null)}
        variant="outlined"
        style={{
          padding: 2,
          margin: 0,
          width: 140,
          height: 60,
          //borderRadius: '50%',
          backgroundColor: 'white',
          //backgroundImage: `url(${screenshot})`,
          backgroundPosition: 'right bottom',
          //   /backgroundSize: 250,
        }}
        startIcon={<img src={screenshot} alt="Screenshot" style={{ width: 50, height: 50 }} />}
      >
        <DeleteForeverIcon style={{ color: 'green' }} />
      </Button>
    );
  }
  return (
    <Button
      onClick={handleTakeScreenshot}
      disabled={isTakingScreenshot}
      variant="contained"
      style={{
        padding: 16,
        width: 140,
        backgroundColor: '#4B5563',
        color: '#F3F4F6',
        borderRadius: 4,
        '&:hover': {
          backgroundColor: '#374151',
        },
      }}
    >
      {isTakingScreenshot ? <CircularProgress color="success" /> : <AddAPhotoIcon />}
    </Button>
  );
}
