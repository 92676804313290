import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Adjust } from '@mui/icons-material';

export default function AutocompleteGeneric({ value, options, handleChange }) {
  let opt_default = {
    multiple: options.multiple || false,
    name: options.name || 'generic',
    label: options.label || 'Select',
    values: options.values || [],
    helperText: options.helperText || 'Selecione algo',
    field: options.field || 'generic',
    variant: options.variant || 'outlined',
    listHeight: options.listHeight || 'auto',
    disableCloseOnSelect: options.disableCloseOnSelect || false,
    disabled: options.disabled || false,
    disableClearable: options.disableClearable || false,
  };

  // Certifique-se de que value seja único, não uma matriz
  if (opt_default.multiple === false && Array.isArray(value)) {
    value = value.length > 0 ? value[0] : null;
  }

  const handleSelectChange = (event, newValue, selectOption, option) => {
    // Se houver um novo valor selecionado, chama o handleChange para atualizar o valor
    if (newValue) {
      handleChange(options.field, [newValue], selectOption, option?.option); // Envia um array com o novo valor para manter a consistência com data.areas
    }
  };

  return (
    <Autocomplete
      onChange={handleSelectChange}
      disableCloseOnSelect={opt_default.disableCloseOnSelect}
      disableClearable={opt_default.disableClearable}
      disabled={opt_default.disabled}
      filterSelectedOptions
      color="success"
      value={value}
      id={'autocomplete_' + options.name}
      multiple={opt_default.multiple}
      options={opt_default.values}
      ListboxProps={{ style: { maxHeight: opt_default.listHeight } }}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      getOptionLabel={(option) => {
        return option.name || option.nome;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.icon ? <span style={{ marginRight: '8px' }}>{option.icon}</span> : <Adjust color="success" sx={{ mr: 1, width: '15px' }} />}
          {option.name || option.nome}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant={opt_default.variant} label={opt_default.label} color="success" InputLabelProps={{ style: { color: '#2e7d32' } }} helperText={opt_default.helperText} focused />
      )}
      noOptionsText="Sem resultados"
    />
  );
}
