import React, { useContext, useState } from 'react'
import { Grid, List, ListItem, ListItemText, IconButton, Typography, Box, TextField, Container, Paper, DialogActions, Button } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import RegisterCostCenterDialogModalCategory from './RegisterCostCenterDialogModalCategory'
import RegisterCostCenterDialogModalGroupCategory from './RegisterCostCenterDialogModalGroupCategory'
import RegisterCostCenterDialogModalTransferCategory from './RegisterCostCenterDialogModalTransferCategory'
import RegisterCostCenterDialogModalDRE from './RegisterCostCenterDialogModalDRE'
import useStore from '../../store/useStore'
import { useEffect } from 'react'
import { Context } from '../../components/Context/AuthContext'
import api from '../../services/api'
import { toast } from 'react-toastify'
import RegisterDialogModalDelete from './RegisterDialogModalDelete'
import CancelIcon from '@mui/icons-material/Cancel'

export default function RegisterCostCenterDialog({ conta, setButtonType, openDialog, handleCloseDialog }) {
  const [openList, setOpenList] = useState({})
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null)
  const [editCategoryId, setEditCategoryId] = useState(null)
  const [editSubcategoryId, setEditSubcategoryId] = useState(null)

  const [editedName, setEditedName] = useState('')

  const usestore = useStore()
  //usestore.reposAccount.fetch();
  const reposAccount = usestore.reposAccount.listAccount
  console.log('RegisterCostCenterDialog')
  console.log(reposAccount)
  const { aux_add, setAuxAdd } = useContext(Context)
  var farm_id = localStorage.getItem('Property')

  // Filtrar para encontrar objetos onde info é um array
  const filteredElements = reposAccount.filter((item) => Array.isArray(item.infoParse))

  //console.log(filteredElements)

  // unir a categorias e subcategorias
  function buildCategoryTree(data) {
    // Mapeia cada item pelo seu ID para acesso rápido
    const itemsById = data.reduce((map, item) => {
      map[item.id] = { ...item, subcategories: [] }
      return map
    }, {})

    // Constrói a árvore de categorias
    const tree = []
    data.forEach((item) => {
      if (item.mother in itemsById) {
        // Adiciona o item como subcategoria do seu 'mother'
        itemsById[item.mother].subcategories.push(itemsById[item.id])
      } else {
        // Se não tiver 'mother', é um item de nível superior
        tree.push(itemsById[item.id])
      }
    })

    return tree
  }

  const categoryTree = buildCategoryTree(filteredElements)
  console.log('lista de categorias filtrada')
  console.log(categoryTree)

  const [categories, setCategories] = useState(categoryTree)

  useEffect(() => {
    console.log()
    setCategories(categoryTree)
  }, [aux_add])

  const handleToggle = (categoryName) => {
    setOpenList((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }))
  }

  const handleSelectButtonEditDelete = (categoryId) => {
    setSelectedSubCategoryId(null)

    if (selectedCategoryId === categoryId) {
      console.log('fechou')
      setSelectedCategoryId(null)
      setEditCategoryId(null)
    } else {
      console.log('abriu')
      setSelectedCategoryId(categoryId)
    }
  }

  const handleSelectButtonEditDeleteSub = (subcategoryId) => {
    setSelectedSubCategoryId(subcategoryId)
    setSelectedCategoryId(null)
  }

  // ativa o input para edição
  // salva em handleSave
  const handleEditCategory = (categoryId, categoryName) => {
    console.log('handleEditCategory')
    console.log(categoryId, categoryName)
    // controla o edit do input
    setEditCategoryId(categoryId)
    setEditedName(categoryName)
  }

  const cancelEdit = () => {
    console.log('cancelEdit')
    setEditCategoryId(null) // Reset subcategory edit state
    setEditSubcategoryId(null)
  }

  // botão para salvar a edição
  const handleEditCategorySave = async (categoryId, categoryName) => {
    console.log('handleEditCategorySave')
    console.log(categoryId, categoryName)

    const updateCategoryAccount = {
      id: categoryId,
      name: categoryName,
    }

    const header = { header: { 'Content-Type': 'application/json' } }
    console.log('res')
    const res = await api
      .put('/finances/updateAccount', updateCategoryAccount, header)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success('Categoria atualizada com sucesso')

          usestore.reposAccount.fetch({ id_property: farm_id }).then((res) => {
            console.log('reposAccount')
            console.log(res)
            setAuxAdd(aux_add + 1)
            // controla o edit do input
            setEditCategoryId(null)
            // controla o fechamneto e o abrir do menu horizontal 3 pontinhos
            setSelectedCategoryId(null)
            //setAuxAdd(aux_add + 1);
            // setTimeout(() => {
            // }, 1000);
            //handleClose();
            //navigate("/areasmaps");
          })

          // handleClose();
          // navigate("/areasmaps");
        }
      })
      .catch((err) => {
        console.log('errrs')
        console.log(err)
        toast.error(err.response.data)
      })
  }

  const handleEditSubcategory = (subcategoryId, subcategoryName) => {
    setEditSubcategoryId(subcategoryId)
    setEditedName(subcategoryName)
  }

  const updateCategoryName = (categories, categoryId, categoryName) => {
    return categories.map((category) => {
      if (category.id === categoryId) {
        return { ...category, name: categoryName }
      } else if (category.subcategories && category.subcategories.length > 0) {
        return {
          ...category,
          subcategories: updateCategoryName(category.subcategories, categoryId, categoryName),
        }
      }
      return category
    })
  }

  // editar a categoria
  const handleSave = (categoryId, categoryName) => {
    console.log('handleSave')
    console.log(categoryId, categoryName)

    // Crie uma cópia profunda da matriz de categorias
    const updatedCategories = JSON.parse(JSON.stringify(categories))

    // Atualize o nome da categoria com o categoryId correspondente
    const updatedCategoriesWithUpdatedName = updateCategoryName(updatedCategories, categoryId, categoryName)
    console.log(updatedCategoriesWithUpdatedName)
    //console.log(updatedCategoriesWithUpdatedName);
    setCategories(updatedCategoriesWithUpdatedName)
    setEditCategoryId(null)
    setEditedName('')
    console.log('fechou')
    setSelectedCategoryId(null)
    // Aqui você salvaria as mudanças no backend
    // chaveamento: editCategoryId fecha o textfield
    setEditCategoryId(null)
    setEditSubcategoryId(null)
  }

  //encontrar um objeto com um ID específico (no caso, o ID 87)
  //em uma estrutura de dados aninhada como a fornecida, você pode usar uma função recursiva em JavaScript.
  function findObjectById(array, id) {
    for (let obj of array) {
      if (obj.id === id) {
        return obj
      }
      if (obj.subcategories && obj.subcategories.length > 0) {
        let found = findObjectById(obj.subcategories, id)
        if (found) return found
      }
    }
    return null // Retornar null se o ID não for encontrado
  }

  const [modalOpenDelete, setModalOpenDelete] = useState(false)

  const handleCloseDeleteModal = () => {
    setModalOpenDelete(false)
  }

  const [listSubcategoriesDelete, setListSubcategoriesDelete] = useState(false)

  const handleDelete = async (categoryId, subcategoryId) => {
    console.log('handleDelete')
    console.log(categoryId, subcategoryId)
    // fecha o MoreHorizIcon
    //setSelectedCategoryId(null);
    //setSelectedSubCategoryId(null);
    const header = { header: { 'Content-Type': 'application/json' } }
    const categoryAccount = { id: categoryId }

    try {
      // arvore de categorias com suas subcategorias
      console.log(categoryTree)
      const result = findObjectById(categoryTree, categoryId)
      // salvando a categoria mãe do id = categoryId
      setListSubcategoriesDelete(result)
      console.log('result')
      console.log(result)
      //const arrayNameCategory = []
      if (!modalOpenDelete && result.subcategories.length > 0) {
        // se tiver subcategoria, apreça o modal
        console.log(result.subcategories)
        setModalOpenDelete(true)
        //arrayNameCategory.push(result.subcategories) =
        //console.log(arrayNameCategory)
      } else if (!modalOpenDelete && result.subcategories.length == 0) {
        console.log(result)
        setModalOpenDelete(true)
      } else {
        const res = await api.post('/finances/deleteAccount', categoryAccount, header)

        console.log(res)
        console.log(res.data.isSuccess)

        if (res.data.isSuccess) {
          toast.success('Conta deletada com sucesso')

          usestore.reposAccount.fetch({ id_farm: farm_id }).then((res) => {
            setAuxAdd(aux_add + 1)
            handleCloseDeleteModal()
            //navigate("/areasmaps");
          })

          // handleClose();
          // navigate("/areasmaps");
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Erro de comunicação com o  servidor')
      //toast.error("Erro na comunicação: " + error.response.data);
    }
    // Após salvar, você pode fechar o diálogo.
    //setOpen(false);
  }

  const renderCategory = (category, level) => (
    <ListItem key={category.id}>
      {/* {console.log(category)} */}
      {/* {console.log(category)} */}
      <Grid container spacing={1} justifyContent="space-between" style={{ borderBottom: '1px solid #dcdcdc', padding: '2px' }}>
        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="center" marginLeft={`${level * 60}px`}>
            {/* {category.info[2] == "category" ? <ExpandMoreIcon /> : null} */}

            <ListItemText
              primary={
                <>
                  {editCategoryId === category.id ? (
                    <TextField
                      // fullWidth
                      sx={{ mt: -1, ml: -1 }}
                      size="small"
                      color="success"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      //onBlur={() => handleSave(category.id, editedName)}
                      // onBlur={() => handleEditCategorySave(category.id, editedName)}
                    />
                  ) : (
                    <>
                      {/* {category?.infoParse[2] == "category" ? ( */}
                      {category?.infoParse[2] == 'category' ? (
                        <>
                          <Typography
                            style={{
                              color: 'gray',
                              fontWeight: 'bold',
                            }}
                          >
                            {category.name.toUpperCase()} (Grupo)
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            style={{
                              color: conta == 'expense' ? 'red' : 'green',
                              fontWeight: 'bold',
                            }}
                          >
                            {category.name}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => handleSelectButtonEditDelete(category.id)}>
                <MoreHorizIcon color="success" />
              </IconButton>
              {selectedCategoryId === category.id && (
                <>
                  {editCategoryId === category.id ? (
                    <>
                      <IconButton onClick={() => handleEditCategorySave(category.id, editedName)}>
                        <SaveIcon color="success" />
                      </IconButton>
                      <IconButton onClick={() => cancelEdit()}>
                        <CancelIcon style={{ color: 'red' }} />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <RegisterCostCenterDialogModalCategory conta={conta} categories={categories} setCategories={setCategories} categoryNameDefault={category.name} />
                      <IconButton onClick={() => handleEditCategory(category.id, category.name)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(category.id)}>
                        <DeleteIcon />
                      </IconButton>
                      {category.infoParse[2] == 'category' && <RegisterCostCenterDialogModalDRE categories={categories} setCategories={setCategories} categoryId={category.id} />}
                      {/* {category.infoParse[2] == "category" && <RegisterCostCenterDialogModalDRE categories={categories} setCategories={setCategories} categoryId={category.id} />} */}
                    </>
                  )}
                </>
              )}
              {/* {category.infoParse[2] == "category" ? null : <RegisterCostCenterDialogModalTransferCategory categories={categories} setCategories={setCategories} categoryId={category.id} />} */}
              {category.infoParse[2] == 'category' ? null : <RegisterCostCenterDialogModalTransferCategory categories={categories} setCategories={setCategories} categoryId={category.id} />}
            </Box>
          </>
        </Grid>
      </Grid>
    </ListItem>
  )

  // cahamada para arenderizar a dom
  // defini se é income ou expense
  const renderCategoriesRecursively = (categories, level = 0) => {
    //console.log("categories1")
    //console.log(categories)
    categories = categories.filter((item) => item.nature == (conta == 'expense' ? 'CREDORA' : 'DEVEDORA'))
    //console.log(categories)
    //console.log("------")
    return categories.map((category) => (
      <>
        {/* {console.log("->", category)} */}
        <React.Fragment key={category.id}>
          {renderCategory(category, level)}
          <List component="div" disablePadding>
            {renderCategoriesRecursively(category.subcategories, level + 1)}
          </List>
        </React.Fragment>
      </>
    ))
  }

  console.log('categories')
  console.log(categories)

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 0 }}>
      <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
        <Grid container spacing={3}>
          <Grid container spacing={0} sx={{ mt: 5, mb: -2 }}>
            <Grid item xs={6} md={6}>
              <Typography gutterBottom variant="h5" component="div">
                Categorização
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <RegisterCostCenterDialogModalGroupCategory conta={conta} categories={categories} setCategories={setCategories} />
            </Grid>
            <Grid item xs={6} md={3}>
              <RegisterCostCenterDialogModalCategory conta={conta} categories={categories} setCategories={setCategories} categoryNameDefault={''} />
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
        <ButtonIncomeFullScreenDialog />
      </Grid> */}
          <Grid item xs={12}>
            <RegisterDialogModalDelete
              open={modalOpenDelete}
              handleDelete={handleDelete}
              handleClose={handleCloseDeleteModal}
              listSubcategoriesDelete={listSubcategoriesDelete}
              categories={categories}
              setCategories={setCategories}
            />
          </Grid>

          <Grid item xs={12}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>{renderCategoriesRecursively(categories)}</List>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
