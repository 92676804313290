import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import ModalColorChipsUpdateDate from '../../pages/activities/components/ListActivities/ModalColorChipsUpdateDate';

const series = [
  {
    name: 'Bob',
    data: [
      {
        x: 'Entrada',
        y: [new Date('2019-03-05').getTime(), new Date('2019-03-08').getTime()],
      },
      {
        x: 'Novo ,lote',
        y: [new Date('2019-03-10').getTime(), new Date('2019-03-11').getTime()],
      },
      {
        x: 'MEDICAMENTOS',
        y: [new Date('2019-03-13').getTime(), new Date('2019-03-16').getTime()],
      },
    ],
  },
  {
    name: 'Joe',
    data: [
      {
        x: 'Vinculação',
        y: [new Date('2019-03-02').getTime(), new Date('2019-03-05').getTime()],
      },
      {
        x: 'pesagem',
        y: [new Date('2019-03-06').getTime(), new Date('2019-03-09').getTime()],
      },
      {
        x: 'MEDICAMENTOS',
        y: [new Date('2019-03-10').getTime(), new Date('2019-03-19').getTime()],
      },
    ],
  },
  {
    name: 'mig',
    data: [
      {
        x: 'Vinculação',
        y: [new Date('2019-05-01').getTime(), new Date('2019-05-02').getTime()],
      },
      {
        x: 'pesagem',
        y: [new Date('2019-05-05').getTime(), new Date('2019-05-06').getTime()],
      },
      {
        x: 'MEDICAMENTOS',
        y: [new Date('2019-05-10').getTime(), new Date('2019-05-15').getTime()],
      },
    ],
  },
];

export default function CharTimeActivityProduction({ rows }) {
  //console.log(rows);

  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar a abertura do modal

  const handleBarClick = (event, chartContext, config) => {
    // Aqui você pode lidar com o clique na barra do gráfico
    console.log(config); // Exibe os detalhes da barra clicada
    setModalOpen(true); // Abre o modal ao clicar na barra
  };

  function addHoursToTimestamp(timestamp, hours) {
    // Cria um novo objeto Date a partir do timestamp
    const date = new Date(timestamp);

    // Adiciona o número de horas à data
    date.setHours(date.getHours() + hours);

    // Retorna o timestamp da data modificada
    return date.getTime();
  }

  function getNextColor(colors, currentIndex) {
    return colors[currentIndex % colors.length];
  }
  function getNextColorForTeam(teamIndex) {
    return colors[teamIndex % colors.length];
  }

  //const colors = ['#7277D5', '#4C74E6', '#268EC3', '#36B37E']
  const colors = ['#2fc582', '#1ba466', '#077d3f', '#115c34', '#113e91', '#195ac0', '#297dd3', '#42a4ef', '#038288', '#1a9ba1', '#37c5cf'];

  const groupedData = {};
  rows.forEach((row) => {
    if (!groupedData[row.team]) {
      groupedData[row.team] = [];
    }
    groupedData[row.team].push(row);
  });
  console.log(groupedData);
  const [dataActivity, setDataActivity] = useState(() => {
    return Object.entries(groupedData).map(([name, data], teamIndex) => ({
      name: name,
      data: data.map((row, index) => ({
        x: row.activity,
        y: [new Date(row.startDate_nf).getTime(), addHoursToTimestamp(new Date(row.endDate_nf).getTime(), 24)],
        fillColor: getNextColorForTeam(teamIndex),
      })),
    }));
  });

  console.log('dataActivity ');
  console.log(dataActivity);
  //console.log(series);

  const options = {
    chart: {
      height: 450,
      type: 'rangeBar',
      events: {
        click: handleBarClick, // Adiciona um evento de clique nas barras
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        console.log('Data point  index:', opts.dataPointIndex);
        console.log('Labels:', opts.w.globals.labels);
        // var label = opts.w.globals.labels[opts.dataPointIndex] // Use o índice para acessar o rótulo correspondente
        // var label = opts.dataPoint.x // Usando diretamente o nome do ponto de dados
        var a = moment(val[0]);
        var b = moment(val[1]);
        var diff = b.diff(a, 'days');
        // return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
        return diff + (diff > 1 ? ' days' : ' day');
      },
      style: {
        colors: ['#f3f4f5', '#fff'],
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.8,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'light',
    //     type: 'vertical',
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [50, 0, 100, 100],
    //   },
    // },
    xaxis: {
      type: 'datetime',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    // grid: {
    //   row: {
    //     colors: ['#f3f4f5', '#fff'],
    //     opacity: 1,
    //   },
    // },
    series: [
      {
        data: dataActivity,
      },
    ],
  };

  return (
    <div>
      <div id="chart">
        <Chart options={options} series={dataActivity} type="rangeBar" height={350} />
      </div>
      {/* <div id="html-dist"></div>
      <ModalColorChipsUpdateDate open={modalOpen} handleClose={() => setModalOpen(false)} /> */}
    </div>
  );
}
