import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Stack, Typography } from "@mui/material";
import SignpostIcon from "@mui/icons-material/Signpost";
import FormGoalButtonPlanning from "./formGoalPlanning/FormGoalButtonPlanning";
import ChartBarP from "../../components/Charts/ChartBarP";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormGoalButtonTarget from "./formGoalTarget/FormGoalButtonTarget";
import TimelineHorizPhase from "./components/GraphPlan/TimelineHorizPhase";
import PlanningHarvestCost from "./components/CostProduction/PlanningHarvestCost";
import { useParams } from "react-router-dom";
import useHarvestReposStore from "../../store/useHarvestReposStore";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import useHarvestTargetReposStore from "../../store/useHarvestTargetReposStore";
import FormGoalButtonTargetEdit from "./formGoalTarget/FormGoalButtonTargetEdit";
import { Context } from "../../components/Context/AuthContext";
import { calcularDateProgressBarra } from "../../components/Calculations/calcularDateProgressBarra";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ChartBarPlanning from "../../components/Charts/ChartBarPlanning";
import FormGoalButtonPlanHarvest from "./formGoalTarget/FormGoalButtonPlanHarvest";
import PlanningHarvestContentchoice from "./PlanningHarvestContentchoice";
import useHarvestTargetCostReposStore from "../../store/useHarvestTargetCostReposStore";

export default function PlanningHarvestContent() {
  const { id, idFarm } = useParams();

  // instancia os repos
  const reposHarvest = useHarvestReposStore((state) => state);
  const listHarvests = reposHarvest.getHarvestStore("id", id);

  const [dataHarvest, setDataHarvest] = useState(listHarvests[0]);

  const date_start = dataHarvest.data_inicial;
  const date_prev_end = dataHarvest.data_fim;
  console.log(date_start);
  const valueProgress = calcularDateProgressBarra(date_start, date_prev_end);
  console.log(valueProgress);

  // instancia os repos planning
  const reposPlanning = useHarvestTargetReposStore((state) => state);
  //console.log(reposPlanning);
  //console.log(reposPlanning.listPlanning);
  //console.log(reposPlanning.planningRepos);
  const planRepos = reposPlanning.getPlanningStore("id", reposPlanning.planningRepos.id);
  console.log(planRepos[0]);
  const valueProductivity = planRepos[0]?.expectedYield / planRepos[0]?.area;
  console.log(valueProductivity);
  const [plan, setPlan] = React.useState(planRepos[0]);
  const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2));
  useEffect(() => {
    setProductivity(valueProductivity.toFixed(2));
  }, [plan]);

  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [value, setValue] = React.useState(0);

  console.log("dataHarvest#");
  console.log(dataHarvest);

  function calcularSomatorioUsedArea(array) {
    //console.log(array)
    let somatorio = 0;

    for (let i = 0; i < array.length; i++) {
      const usedArea = parseFloat(array[i].usedArea.replace(",", "."));
      somatorio += usedArea;
    }

    return somatorio;
  }

  function formatNumber(numero) {
    return numero.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  }

  const reposPlanHarvest = useHarvestTargetCostReposStore((state) => state);
  console.log("reposPlanHarvest#");
  console.log(reposPlanHarvest);

  console.log(reposPlanHarvest.listPlanHarvest);
  console.log(reposPlanHarvest.planningRepos);
  console.log(reposPlanHarvest.planningRepos.id);
  const planHarvestRepos = reposPlanHarvest.getPlanningStore("id", reposPlanHarvest.planningRepos.id)[0];
  console.log(planHarvestRepos);

  // const [planValues, setPlanValues] = React.useState({
  //   expectedProduction: 2000,
  //   saleUnitValue: 40.0,
  //   totalSale: 8000.0,
  //   valueSale: "pt-BR",
  //   unitSale: "sc 60kg",
  //   percentageProfit: 50,
  //   profitUnitValue: 20.0,
  //   totalProfit: 4000.0,
  //   unitProfit: "sc 60kg",
  //   productionCost: 20.0,
  //   totalProductionCost: 40000.0,
  //   valueCost: "pt-BR",
  //   unitCost: "sc 60kg",
  //   area: calcularSomatorioUsedArea(dataHarvest.areas),
  //   measure: "ha",
  //   isDeleted: 0,
  // });

  const [values, setValues] = React.useState(planHarvestRepos);

  // const [values, setValues] = React.useState({
  //   id_user: planHarvestRepos.id,
  //   id_property: planHarvestRepos.id_property,
  //   id_harvest: planHarvestRepos.id,
  //   expectedProduction: planHarvestRepos?.expectedProduction,
  //   saleUnitValue: planHarvestRepos?.saleUnitValue,
  //   totalSale: planHarvestRepos?.totalSale,
  //   valueSale: "pt-BR",
  //   unitSale: "sc 60kg",
  //   percentageProfit: planHarvestRepos?.percentageProfit,
  //   profitUnitValue: planHarvestRepos?.profitUnitValue,
  //   totalProfit: planHarvestRepos?.totalProfit,
  //   unitProfit: "sc 60kg",
  //   productionCost: planHarvestRepos?.productionCost,
  //   totalProductionCost: planHarvestRepos?.totalProductionCost,
  //   valueCost: "pt-BR",
  //   unitCost: "sc 60kg",
  //   area: planHarvestRepos?.area,
  //   measure: "ha",
  //   isDeleted: 0,
  // });

  console.log("values");
  console.log(values);

  // const [data, setData] = useState({
  //   id_user: userCurrent.id,
  //   id_property: harvestCurrent[0].id_property,
  //   id_harvest: harvestCurrent[0].id,
  //   id_cultivation: harvestCurrent[0].id_cultivation,
  //   id_group: harvestCurrent[0].id_group,
  //   // group_name: harvestCurrent[0].group_name,
  //   //id_areamap: "",
  //   cultivation_name: harvestCurrent[0].cultivation_name,
  //   //startDate: harvestCurrent[0].data_inicial,
  //   // endDate: harvestCurrent.data_fim,
  //   date_start: format(new Date(harvestCurrent[0].date_start), "yyyy-MM-dd"),
  //   date_prev_end: format(new Date(harvestCurrent[0].date_prev_end), "yyyy-MM-dd"),
  //   expectedYield: "",
  //   unit: "sc 60kg",
  //   cost: "",
  //   valueCost: "pt-BR",
  //   unitCost: "sc 60kg",
  //   area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
  //   measure: "ha",
  //   observation: "",
  //   isDeleted: 0,
  // });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  //console.log("plan");
  //console.log(plan);

  const { aux_add } = useContext(Context);

  useEffect(() => {
    console.log("refresh ");
    setPlan(planRepos[0]);
    setValues(planHarvestRepos);
  }, [aux_add]);

  return (
    <Box className="content-wrapper bg-white">
      <Box className="content-header" style={{ marginTop: 10 }}>
        <Box className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
                <SignpostIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Planejamento da Colheita
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {/* <Grid item xs={6} md={4}>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <FormGoalButtonPlanning />
              </Box>
            </Grid> */}
            {/* <Grid item xs={6} md={1}>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <FormGoalButtonTarget />
              </Box>
            </Grid> */}
          </Grid>

          <div className="content">
            <div className="container-fluid">
              <div className="card">
                {/* <PlanningHarvestContentchoice /> */}
                {/* <CreationsTabsPlanning /> */}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
