import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageListCmp from './ImageListCmp';
import ImageListCmptrash from './ImageListCmptrash';
import { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../../../components/Context/AuthContext';
import useListImagesStore from '../../../store/useListImagesStore';
import { Grid, LinearProgress, Stack } from '@mui/material';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import DynamicTabs from './DynamicTabs';
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color="success" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ImageLisTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const [favoriteImages, setFavoriteImages] = useState([]);

  const { aux_add } = useContext(Context);
  const [data, setData] = useState([]);
  const [storage, setStorage] = useState(0);
  const useImagesListRepos = useListImagesStore((state) => state.listImages);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const useImagesRepos = useListImagesStore((state) => state);

  const property_id = localStorage.getItem('Property') || 1;
  const display_demo = JSON.parse(localStorage.getItem('display'));

  const groupImagesByMonth = (data) => {
    return data.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  };

  // Função para calcular o total de tamanho em MB
  const calcularTotalSizeMB = (data) => {
    let totalSizeBytes = 0;
    data.forEach((item) => {
      totalSizeBytes += parseInt(item.size);
    });
    const totalSizeMB = totalSizeBytes / (1024 * 1024); // Convertendo bytes para MB
    return totalSizeMB.toFixed(2); // Arredondando para duas casas decimais
  };

  const handleGetList = async () => {
    const itemData = useImagesListRepos.filter((img) => img.isDeleted === 0 && img.id_property == property_id);
    console.log(itemData);
    const totalSizeMB = calcularTotalSizeMB(itemData);
    console.log('Total de tamanho em MB:', totalSizeMB);
    setStorage(totalSizeMB);
    console.log(itemData);
    const sortedImages = itemData.sort((a, b) => new Date(b.date) - new Date(a.date));
    const groupedData = groupImagesByMonth(sortedImages);
    setData(groupedData);
    setTimeout(() => {
      setIsLoadingImage(false);
    }, 2500);
  };

  const calcularProgressoArmazenamento = (storageEmMB) => {
    console.log(storageEmMB);
    const totalArmazenamentoGB = 5;
    const totalArmazenamentoMB = totalArmazenamentoGB * 1024; // Convertendo GB para MB
    const progresso = (storageEmMB / totalArmazenamentoMB) * 100;
    console.log(progresso);
    console.log(Math.min(progresso, 100));
    return Math.min(progresso, 100); // Limitando o progresso máximo a 100%
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ mt: -2 }}>
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <FilterDramaIcon /> <strong>Armazenamento</strong>
            </Typography>
            <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
              {storage && (
                <Box sx={{ width: '100%' }}>
                  {' '}
                  <LinearProgressWithLabel value={calcularProgressoArmazenamento(storage)} />
                </Box>
              )}
              {storage} MB de 5000 MB (5GB)
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          textColor="success"
          TabIndicatorProps={{ style: { background: 'green' } }}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '.Mui-selected': {
              color: '#2e7d32 !important',
              fontWeight: 'bold',
            },
          }}
        >
          <Tab label="Imagens" {...a11yProps(0)} />
          <Tab label="Lixeira" {...a11yProps(1)} />
          {/* <Tab label="Favoritos" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ImageListCmp data={data} handleGetList={handleGetList} isLoadingImage={isLoadingImage} storage={storage} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImageListCmptrash data={data} handleGetList={handleGetList} isLoadingImage={isLoadingImage} storage={storage} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <ImageListFavorites favoriteImages={favoriteImages} setFavoriteImages={setFavoriteImages} />
      </TabPanel> */}
    </Box>
  );
}
