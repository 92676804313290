import React, { useEffect, useState, useContext, useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Edit } from '@mui/icons-material';
import { AppBar, Card, CardContent, CardHeader, Divider, Grid, IconButton, Toolbar } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, useTheme } from '@mui/system';
import { H6, Span } from '../../../../components/Typography';
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import RoomIcon from "@mui/icons-material/Room";
import FromRegisterUserProfile from '../../../team/components/outros/FromRegisterUserProfile';
import { MapFormObservation } from './MapFormObservation';
import usePropertyReposStore from "../../../../store/usePropertyReposStore";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function MapFormObservationDialog() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reposProperty = usePropertyReposStore(state => state);
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];

  const centerInit = {
    lat: Number(property_data?.latitude || '-20.9230016'),
    lng: Number(property_data?.longitude || '-42.7960554'),
    address: property_data?.address || property_data?.address_area || "",
    zoom: Number(property_data?.zoom || '14'),
  };

  return (
    <div  >
      <Button size="small" variant="contained" color="success" onClick={handleClickOpen}>
        adicionar marcador
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <AppBar sx={{ cursor: 'move', position: "relative" }} color="success" id="draggable-dialog-title">
          <div className="d-none d-lg-block">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0"> </H6>
              <Button color="inherit" onClick={handleClose}>
                Enviar
              </Button>
            </Toolbar>
          </div>
        </AppBar>
        <section className="content " style={{ overflowY: "scroll", paddingBottom: 80 }}>
          <div className="container-fluid " >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Localização
            </DialogTitle>
            <Divider />
            <DialogContent >
              <MapFormObservation centerInit={centerInit}/>
            </DialogContent>
            <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                    <Span>cancelar</Span>
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Button color="success" variant="contained" type="submit" onClick={handleClose}>
                    {/* <SendIcon /> */}
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Enviar</Span>
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
          </div>
        </section>
      </Dialog>
    </div >
  );
}