import React, { useContext, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FilePreview from './FilePreview';
import api from '../../services/api';
import { toast } from 'react-toastify';
// import useListImagesStore from '../../store/useListImagesStore';
import { Context } from '../../components/Context/AuthContext';
import useStore from '../../store/useStore';

const UploadFunction = ({ item, callBack, callBackHook }) => {
  // console.log('UploadFunction');
  const { aux_add, setAuxAdd } = useContext(Context);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const usestore = useStore();
  // const [fileKey, setFileKey] = useState(0);

  const fileSelectedHandler = (event) => {
    const file = event.target.files[0];
    const uploadedFile = {
      file: file,
      name: file.name.replace(/\.[^/.]+$/, ''), // remove a extensão do nome do arquivo
      size: file.size,
      type: file.type,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };

    setSelectedFile(uploadedFile);
    setFileName(file.name.replace(/\.[^/.]+$/, '')); // set initial file name without extension
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const close = () => {
    // console.log('teste');
    setSelectedFile(null);
    setFileName('');
  };

  // const useImagesRepos = useListImagesStore((state) => state);

  const fileUploadHandler = () => {
    const fd = new FormData();

    // passando o item
    if (item) {
      let tipo_item = item.tipo_item;
      if (/creation/.test(tipo_item)) {
        tipo_item = 'livestock';
      }

      fd.append('item_type', tipo_item || '');
      fd.append('item_id', item.id || 0);
      fd.append('id_farm', item.id_farm || 1);
    } else {
      //fd.append('id_farm', id_farm || 1);
      let tipo_item = item.tipo_item;
    }

    fd.append('image', selectedFile.file, `${fileName}.png`); // append file with the custom name and add back the extension

    api
      .post('upload', fd, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          setSelectedFile((prevFile) => ({
            ...prevFile,
            progress: progress,
          }));
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          var farm_id = localStorage.getItem('Property');
          // console.log(response.data._value);

          usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
            setAuxAdd(aux_add + 1);
          });
        }

        if (callBack) {
          setSelectedFile(null);
          setFileName('');
          toast.success('Imagem enviada com sucesso!');

          callBackHook.addImageStore({
            img: `https://cpt-soft.s3.us-east-1.amazonaws.com/${response.data._value.key_image}`,
            id_property: item.id_farm,
            title: fileName, // use custom file name here
            image: response.data._value.key_image,
            size: selectedFile.size,
            tipo: item.tipo_item ? item.tipo_item : 'farm',
            id_item: item.id || 0,
            isDeleted: 0,
          });

          setTimeout(() => {
            callBack(response.data._value.key_image);
          }, 5);
        } else {
          localStorage.setItem('image', JSON.stringify(response.data._value.url));
          setSelectedFile((prevFile) => ({
            ...prevFile,
            id: response.data._value.key_image,
            url: response.data._value.url,
            uploaded: true,
          }));
        }
      })
      .catch(() => {
        toast.error('Erro ao salvar na Amazon');
      });
  };

  const handleDelete = async (id) => {
    if (!id) {
      id = selectedFile.id;
    }
    const res = await api.delete(`/upload/${id}/1`);
    if (callBackHook) {
      callBackHook.delImageStore(id);
    }
    setSelectedFile(null);
    setFileName('');
  };

  const display_demo = JSON.parse(localStorage.getItem('display'));

  // console.log(setSelectedFile);

  return (
    <>
      {display_demo ? (
        <div></div>
      ) : (
        <div>
          <Box border="1px dashed gray" borderRadius="4px" padding="16px" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>Escolha um arquivo</Typography>
            <IconButton color="success" aria-label="upload picture" component="label">
              <input hidden accept="image/*" type="file" onChange={fileSelectedHandler} />
              <PhotoCamera />
            </IconButton>
          </Box>
          {!!selectedFile && <FilePreview file={selectedFile} onDelete={handleDelete} />}
          {!!selectedFile && !selectedFile.uploaded && (
            <>
              <Box sx={{ mt: 2 }}>
                <TextField id="fileInput" color="success" value={fileName} onChange={handleFileNameChange} label="Nome do Arquivo" />
              </Box>
              <Button sx={{ mt: 1 }} variant="contained" onClick={fileUploadHandler} color="success">
                Enviar
              </Button>
            </>
          )}
          {!!selectedFile && (
            <Button sx={{ mt: 1, ml: 2 }} onClick={close} color="success">
              Limpar
            </Button>
          )}
        </div>
      )}
    </>
  );
};
export default UploadFunction;
