import React from 'react';
import ReportsTabs from './components/ReportsTabs';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TutorialReports from './components/TutorialReports';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import Typewriter from '../../components/Typewriter/Typewriter';

export default function ReportsContent() {
  const objText = {
    icon: <ReceiptLongIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Relatórios', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Os relatórios proporcionam uma visão detalhada e organizada da gestão da propriedade, das criações e das safras, facilitando a análise e a tomada de decisões.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['Acessar a impressão dos relátórios'], // Texto complementar ou final
    linkButton: ['/reports'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <ReceiptLongIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Relatórios
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Informações&nbsp;detalhadas&nbsp;de&nbsp;toda&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialReports />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Box sx={{ minHeight: 35 }}>
              <Typography style={{ color: '#2e7d32' }}>
                <div className="d-lg-none">
                  <Typewriter tam={30} minHeight={0} text="Bem-vindo a central de relatórios da propriedade." />
                </div>
                <div className="d-none d-lg-block">
                  <Typewriter tam={30} minHeight={0} text=" Bem-vindo a central de relatórios da propriedade." />
                </div>
              </Typography>
            </Box>
          </Grid>
        </div>
      </div>

      <div className="content mt-1">
        <div className="container-fluid">
          <ReportsTabs />
        </div>
      </div>
    </div>
  );
}
