import dayjs from "dayjs";

const lineHeader = [
  {
    text: "___________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
    alignment: "center",
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
];

const lineHeaderGreen = [
  {
    text: "___________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
    alignment: "center",
    fontSize: 5,
    colSpan: 0,
    color: 'green',
  },
  {},
  {},
];

const lineHeaderCanvasGreen = [
  {
      canvas: [
          {
              type: 'line',
              x1: 0, y1: 0,
              x2: 595 - 2 * 40, // 595 é a largura de uma página A4, subtraia as margens se necessário
              y2: 0,
              lineWidth: 2, // Defina a espessura da linha aqui
              lineColor: 'green'
          }
      ]
  }
];



const lineCanvas = [
  {
    canvas: [
        {
            type: 'line',
            x1: 0, y1: 5,
            x2: 515, y2: 5, // Coordenadas fixas
            lineWidth: 1,
            lineColor: 'green'
        }
    ],
    // Adicione margens se necessário
    margin: [0, 0, 0, 0] // Margem: [esquerda, superior, direita, inferior]
}
]

const lineTable = [
  {
    layout: 'noBorders', // Isso remove as bordas da tabela
    table: {
        widths: ['*'], // Isso faz com que a célula da tabela se estenda por toda a largura da página
        body: [
            [{ text: '', border: [false, true, false, false], margin: [0, 1, 0, 0] }] // Isso cria uma célula com borda superior
        ]
    },
    fillColor: 'green', // Define a cor da linha
}
]


const date = new Date();

function formatData(Inputdata) {
  //return !Inputdata ? "" : dayjs(Inputdata).format("DD/MM/YYYY");
  return !Inputdata ? "" : `${dayjs(Inputdata).format("DD/MM/YYYY")}`;
}

function extrairEndereco(address) {
  var partes = address?.split(' - '); // Divide a string no caractere ' - '
  return partes?.slice(0, 1).join(' - '); // Retorna a primeira parte da string dividida
}


export const titleProperty = (dataProperty, title) => [
  [
    {
      //margin: [24, 12, 24, 0],
      layout: "noBorders", // Sem bordas para a tabela
      table: {
        widths: ["*"], // Largura para o nome da fazenda e paginação
        body: [
          // Primeira linha: Nome da fazenda à esquerda e paginação à direita
          [
            { text: dataProperty?.label , bold: true, fontSize: 18, alignment: "left", margin: [0, 30, 0, 0], color: 'green' },
            // { text: " ", bold: true, fontSize: 6, alignment: "right" },
          ],
        ],
      },
    },
   // lineHeader,
    
    { text: `${dataProperty?.city}, ${dataProperty?.state}`, fontSize: 10, alignment: "left", margin: [0, 5, 0, 2], color: '#808080' },
    { text: `${dataProperty?.area} ${dataProperty?.measure}`, fontSize: 10, alignment: "left", margin: [0, 0, 0, 2], color: '#808080' },
    { text: extrairEndereco(dataProperty?.address), fontSize: 10, alignment: "left", margin: [0, 0, 0, 30], color: '#808080' },
    // Segunda linha horizontal
    lineHeaderGreen
  ],
  // Título
  // [{ text: "RELATÓRIO DE VENDAS", style: "reportNameStyle", colSpan: 0, alignment: "center" }],
  // [{ text: "01/01/2024", style: "reportDateStyle", colSpan: 0, alignment: "right" }],
];
