import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { deepOrange, deepPurple } from '@mui/material/colors'
import { Tooltip, keyframes, styled, tooltipClasses } from '@mui/material'
import Typewriter from '../Typewriter/Typewriter'
import { expandAndFade } from '../Animations/animationKeyFrames'

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

export default function LetterAvatarsFade({ name, textoTeam }) {
  //   {/* <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar> */ }
  const charName = name ? name[0].toUpperCase() : '-'
  // console.log(charName)
  return (
    <Stack spacing={1} alignItems="center">
      <LightTooltip title={<Typewriter variant={'caption'} color={'#2e7d32'} minHeight={0} text={textoTeam} />}>
        <Avatar sx={{ width: 32, height: 32, bgcolor: '#28a745', animation: `${expandAndFade} 2s linear infinite` }}>{charName}</Avatar>
      </LightTooltip>
    </Stack>
  )
}
