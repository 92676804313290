import React from "react";
// import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ChangePasswordContent from "./ChangePasswordContent";
import Footer from "../../components/Footer/Footer";

export default function ChangePassword() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton tipo={'user'} />
      <ChangePasswordContent />
      <Footer />
    </>
  );
}
