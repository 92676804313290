import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Divider, Container, Grid, Toolbar, Typography } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import { Close, DeleteForever } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { Context } from '../../../../components/Context/AuthContext';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Span } from "../../../../components/Typography";
import api from "../../../../services/api";
import dayjs from 'dayjs';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DeleteMaintenance({ row, open, setOpen, handleClose, obj_fn, setObjFun }) {
  // console.log('DeleteMaintenance');
  const [openD, setOpenD] = useState(open || false);
  const { aux_add, setAuxAdd } = useContext(Context);

  const handleOpen = () => {
    setOpenD(true);
    if (setOpen) {
      setOpen(true);
    }
    if (setObjFun) {
      setObjFun(prevState => ({ ...prevState, isOpen: row.id }));
    }
  }
  const handleCloseD = () => {
    setOpenD(false);
    if (setOpen) {
      setOpen(false);
    }
    if (setObjFun) {
      setObjFun(prevState => ({ ...prevState, isOpen: 0 }));
    }
    if (handleClose) {
      handleClose();
    }
  }

  const handleDelete = async () => {
    // console.log('handleDelete: ');
    if (row.id !== obj_fn.isOpen) {
      return;
    }
    const bankRepos = obj_fn.usestore.reposBank.bankRepos;

    let this_maintenance = obj_fn.show_btn ? JSON.parse(JSON.stringify(obj_fn.usestore.reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id', row.id)[0])) : row;
    let aux_patrimony = obj_fn.usestore.reposPatrimony.getPatrimonyStore('id', this_maintenance.id_patrimony)[0];

    const data = {
      id: this_maintenance.id,
      id_user: this_maintenance.id_user,
      id_property: this_maintenance.id_property,
      id_trans_inv: this_maintenance.id_trans_inv,
      id_trans_pay: this_maintenance.id_trans_pay,
      // step 1
      patrimony: aux_patrimony,
      id_patrimony: this_maintenance.id_patrimony,
      name: aux_patrimony.name,
      type: aux_patrimony.type,
      group: aux_patrimony.group,

      date_start: dayjs(this_maintenance.date_start),
      date_start_f: dayjs(this_maintenance.date_start).format("YYYY-MM-DD HH:mm:ss"),
      date_start_fc: dayjs(this_maintenance.date_start).format("DD/MM/YYYY"),
      date_prev_end: dayjs(this_maintenance.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
      date_end: dayjs(this_maintenance.date_end).format("YYYY-MM-DD HH:mm:ss"),

      // step 2
      // products: getProds(this_maintenance, obj_fn),
      // services: getServs(this_maintenance),
      products: this_maintenance.products || [],
      services: this_maintenance.services || [],
      value: this_maintenance.value,
      totalQuantity: this_maintenance.info_pay.quanty,

      // step 3
      //  Vincular com e descrição
      projects: this_maintenance.info_pay.projects || [],
      projects_apport: this_maintenance.info_pay.projects_apport || [],
      description: this_maintenance.description || "",

      // step 4
      //  Condições pagamento
      installment: this_maintenance.info_pay.installment || 'a_vista',
      installmentInterval: 30,
      formPayment: this_maintenance.info_pay.formPayment || "dinheiro",
      bankAccount: this_maintenance.info_pay.id_bank || bankRepos.id,
      payed: false,
      dueDate: dayjs(this_maintenance.date_start),
      dueDate_f: dayjs(this_maintenance.date_start).format('YYYY-MM-DD HH:mm:ss'),
      dueDate_fc: dayjs(this_maintenance.date_start).format('DD/MM/YYYY'),
      //  Previsão de pagamento
      fee: this_maintenance.info_pay.fee || 0,
      penalty: this_maintenance.penalty || 0,
      discount: this_maintenance.discount || 0,
      value_end: this_maintenance.value + (this_maintenance.info_pay.fee || 0) + (this_maintenance.penalty || 0) - (this_maintenance.discount || 0),
      //  Parcelas do pagamento
      arr_installment: obj_fn.usestore.reposBill.getBillStore('id_transaction', this_maintenance.id_trans_pay),
      isDeleted: 0,
    }

    const header = { header: { "Content-Type": "application/json" } };
    
    await api.post("/patrimony/maintenance/delete", data, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error("Erro ao deletar a Manutenção!");
        }
        toast.success(`Manutenção excluida com sucesso!`);
        obj_fn.usestore.reposPatrimonyMaintenance.fetch().then((res) => {
          setAuxAdd(aux_add + 1);

          setTimeout(() => {
            handleCloseD();
          }, 200);
          setTimeout(() => {
            obj_fn.usestore.reposProperty.fetch();
            obj_fn.usestore.reposFinances.fetch();
            obj_fn.usestore.reposFinances.fetchT();
            obj_fn.usestore.reposCashFlow.fetch();
            obj_fn.usestore.reposCostProduction.fetch();
            obj_fn.usestore.reposProducts.fetch();
            obj_fn.usestore.reposBill.fetch();
          }, 400);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao deletar a Manutenção!");
      });
  }

  return (
    <div>
      {!obj_fn.show_btn ? <></> :
        <IconButton aria-label="Excluir" onClick={() => handleOpen()} title="Excluir">
          <DeleteForever sx={{ color: red[600] }} />{" "}
        </IconButton>
      }

      <Dialog open={obj_fn.show_btn ? openD : open} onClose={handleCloseD} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"title"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleCloseD} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"h6"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleCloseD} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ mb: 6, mt: -3, textAlign: "center" }}>
          <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} sm={12} sx={{ mb: 1, color: red[700] }}>
                <Typography variant="h5" component="div">
                  Deseja excluir definitivamento a Manutenção Patrimonial?
                </Typography>
                <Typography variant="h6" component="div">
                  {row.description}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography variant="body1" component="div">
                  Esta ação irá deletar todas as parcelas envolvendo este lançamento.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleCloseD}>
                <Span>Cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button variant="outlined" color="error" onClick={handleDelete}>
                <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div">Deletar </Typography>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
}
