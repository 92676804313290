import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

const setHarvestRepos = (list) => {
  let harvest_repos = { label: "", id: 0, id_property: 0, id_cultivation: 0, cultivation_icon: '', tipo_repos: 'Safras' };

  if (list && list.length > 0) {
    let creationHarvestRepos_aux = localStorage.getItem("creationHarvestRepos");
    let creationHarvestRepos = creationHarvestRepos_aux ? JSON.parse(creationHarvestRepos_aux) : { label: "", id: 0, tipo_repos: "" };

    if (creationHarvestRepos.id && creationHarvestRepos.tipo_repos == 'Safras') {
      harvest_repos = creationHarvestRepos;

    } else {
      let userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
      let stgs = userCurrent.settings.safra;

      let element = list[0];
      if (stgs && stgs.first != 0) {
        element = list.filter(item => item.id == stgs.first)[0];
      }
      if (!element && stgs.second != 0) {
        element = list.filter(item => item.id == stgs.second)[0];
      }
      if (!element && stgs.third != 0) {
        element = list.filter(item => item.id == stgs.third)[0];
      }
      if (!element) {
        element = list[0];
      }

      harvest_repos = {
        label: element.name, 
        id: element.id, 
        id_property: element.id_property, 
        id_cultivation: element.id_cultivation, 
        cultivation_icon: "/dist/image/icon_cultures/" + element.cultivation_icon, 
        tipo_repos: 'Safras'
      };
    }
  }

  return harvest_repos;
};

// create the store
const useHarvestReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listcultivations: [],
        listHarvest: [],
        harvestRepos: { label: "", id: 0, id_property: 0, tipo: 'Safras' },
        fetch: async (item) => {
          var farm_id = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_farm': farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("harvest/list", (item), header);
          const data = await response.data;

          await set({
            listHarvest:
              data._value.map((harvest) => ({
                id: harvest.id,
                id_property: harvest.id_property,
                name: harvest.name,
                label: harvest.name,
                id_account: harvest.id_account,
                date_start: dayjs(harvest.date_start).format('YYYY-MM-DD HH:mm:ss'),
                date_prev_end: dayjs(harvest.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                date_end: (harvest.date_end ? dayjs(harvest.date_end).format('YYYY-MM-DD HH:mm:ss') : ''),
                data_inicial: dayjs(harvest.date_start).format('DD/MM/YYYY'),
                data_fim: dayjs(harvest.date_end || harvest.date_prev_end).format('DD/MM/YYYY'),
                situation: harvest.situation,
                observation: harvest.observation,
                isDeleted: harvest.isDeleted,
                areas: JSON.parse(harvest.areas),
                images: [],
                tipo_repos: 'Safras',
                id_cultivation: harvest.id_cultivation,
                cultivation_name: harvest.cultivation_name,
                cultivation_icon: "/dist/image/icon_cultures/" + harvest.cultivation_icon,
                sazonal: harvest.sazonal,
                id_group: harvest.id_group,
                group_name: harvest.group_name,
                group_icon: "/dist/image/icon_cultures/" + harvest.group_icon,
                grow: harvest.grow,
                grow_icon: "/dist/image/icon_cultures/" + harvest.grow_icon,
                grow_observation: harvest.grow_observation,
                progress: harvest.progress,
              }))
          })

          const harvest_repos = setHarvestRepos(data._value);
          get().changeHarvestRepos(harvest_repos, true);
          //set({
          //  harvestRepos: harvest_repos
          //})
          return data;
        },
        changeHarvestRepos: (harvestRepos, init) => {
          let userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
          const stgs_sf = userCurrent.settings.safra;
          // {"first":0,"second":0,"third":0}
          if (userCurrent.settings.safra.first != harvestRepos.id) {
            userCurrent.settings.safra.third = stgs_sf.second;
            userCurrent.settings.safra.second = stgs_sf.first;
            userCurrent.settings.safra.first = harvestRepos.id;

            if (!init) {
              userCurrent.settings.has_update = true;
              userCurrent.settings.geral.creationHarvest = 'Safras';
            }

            localStorage.setItem("userCurrent", JSON.stringify(userCurrent));
          }

          set((state) => ({ harvestRepos }))
        },
        // pega a(s) harvest(s) da listHarvest de acordo com o [key e val] informados
        getHarvestStore: (key, val) => {
          return get().listHarvest.filter((harvest) => {
            if (harvest[key] == val) {
              return harvest;
            }
          })
        },
        // Adiciona uma nova harvest à listHarvest
        addHarvestStore: (harvest) => {
          set(state => ({
            listHarvest: [...state.listHarvest, harvest]
          }))
        },
        // Gera uma nova listHarvest sem a harvest informada
        delHarvestStore: (id) => {
          set({
            listHarvest:
              get().listHarvest.filter((harvest) => {
                if (harvest.id != id) {
                  return harvest;
                }
              })
          })
        },
        // atualiza listHarvest de acordo com id, key e val informados
        updateHarvestStore: (id, key, val) => {
          set({
            listHarvest:
              get().listHarvest.map((harvest) => {
                if (harvest.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    harvest = val;
                  } else {
                    harvest[key] = val;
                  }
                }
                return harvest;
              })
          })
          return get().listHarvest;
        },
        fetchCultivation: async (item) => {
          var farm_id = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_farm': farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("harvest/cultivations", (item), header);
          const data = await response.data;
          await set({
            listcultivations:
              data._value.map((culture) => ({
                id_cultivation: culture.id_cultivation,
                cultivation_name: culture.cultivation_name,
                label: culture.cultivation_name,
                cultivation_name_nf: culture.cultivation_name_nf,
                cultivation_icon: "/dist/image/icon_cultures/" + culture.cultivation_icon,
                sazonal: culture.sazonal,
                id_group: culture.id_group,
                group_name: culture.group_name,
                group_name_nf: culture.group_name_nf,
                group_icon: "/dist/image/icon_cultures/" + culture.group_icon
              }))
          })
          return data;
        },
        getCultivationStore: (key, val) => {
          return get().listcultivations.filter((cultivation) => {
            if (cultivation[key] == val) {
              return cultivation;
            }
          })
        },
        // Gera uma nova listcultivations sem a cultura informada
        delCultivationStore: (id) => {
          set({
            listCultivation:
              get().listCultivation.filter((cultivation) => {
                if (cultivation.id != id) {
                  return cultivation;
                }
              })
          })
        },
        // atualiza listcultivations de acordo com id, key e val informados
        updateCultivationStore: (id, key, val) => {
          set({
            listcultivations:
              get().listcultivations.map((cultivation) => {
                if (cultivation.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    cultivation = val;
                  } else {
                    cultivation[key] = val;
                  }
                }
                return cultivation;
              })
          })
          return get().listcultivations;
        },
      }),
      { name: "reposHarvest" }
    )
  )
);

export default useHarvestReposStore;