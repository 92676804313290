import { NavLink, useParams } from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Dashboard, FormatListBulleted } from '@mui/icons-material';
import useStore from '../../../store/useStore';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import TutorialPainelCentralHarvest from './tutorial/TutorialPainelCentralHarvest';
import CardPeriod from '../../../components/Cards/CardPeriod';
import CardAreas from '../../../components/Cards/CardAreas';
import CardTimeLinePainel from '../../../components/Cards/CardTimeLinePainel';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CardCostHarvest from './CardCostHarvest';
import CardMetaCostHarvest from './CardMetaCostHarvest';
import Typewriter from '../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../components/Loader/ThreeDotsSpinner';
import TutorialStepGeral from '../../../components/Tutorial/TutorialStepGeral';
import StartProjectHarvest from './StartProjectHarvest';
//import ChartBarVPE from "../../../components/Charts/ChartBarVPE";

export default function CardPainelHarvest() {
  // instancia os repos
  const usestore = useStore();
  const { idFarm, id } = useParams();

  const reposUser = usestore.reposUser;
  // console.log(reposUser.userRepos);

  const propertyRepos = usestore.reposProperty.getPropertyStore('id', idFarm);
  const areaTotalProperty = propertyRepos[0].area;
  const harvest = usestore.reposHarvest.getHarvestStore('id', id)[0];

  const planTargetReposFilterHarvest = usestore.reposTargetHarvest.getPlanningStore('id_harvest', id);
  const activePlanTargetReposHarvest = planTargetReposFilterHarvest?.filter((item) => item.isActiveTargetHarvest === 1)[0];
  //console.log('activePlanTargetReposHarvest')
  //console.log(activePlanTargetReposHarvest)

  const planHarvestActiveRepos = usestore.reposHarvestTargetCost.getPlanningStore('id_target', activePlanTargetReposHarvest?.id)[0];
  //console.log('planHarvestActiveRepos');
  //console.log(planHarvestActiveRepos);

  const objText = {
    icon: <Dashboard style={{ fontSize: 35 }} />, // Substitua pelo ícone desejado
    title: 'Painel de Controle da Safra', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'O Painel de Controle é o local ideal para gestores visualizar as informações vitais sobre a sua cultura..', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: [
      '1- Informações gerais sobre o projeto (Painel)',
      '2- Visualização das cultura na área de produção (Mapa)',
      '3- Visualizar as áreas de produção (Áreas)',
      '4- Visualizar e evolução da cultura por meio de imagens',
      '5- Gerenciar as Atividades de campo (Atividades)',
      '6- Visualizar a lista de insumos utilizandos (Recursos)',
      '7- Gerenciar o custo de produção (Custo Produção)',
    ], // Texto complementar ou final
    linkButton: [
      `/controlpanelharvest/${idFarm}/${id}`,
      `/mapa/${idFarm}/${id}`,
      `/areasmaps/${idFarm}`,
      `/harvest/${idFarm}/${id}`,
      `/activitiesHarvest/${idFarm}/${id}`,
      `/resources/${idFarm}/${id}`,
      `/productioncost/${idFarm}/${id}`,
    ], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 0 }} className="content-header">
          <Box className="container-fluid">
            <StartProjectHarvest type={harvest.name} />
            <Grid container spacing={2} sx={{ mb: 1, justifyContent: 'space-between' }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex' }}>
                  <Dashboard sx={{ fontSize: 35 }} color="text.secondary" />
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                    <Typography fontWeight="500" variant="h6">
                      Painel de Controle da Safra
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                      Painel&nbsp;de&nbsp;informações&nbsp;vitais&nbsp;da&nbsp;safra
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                  <TutorialStepGeral objText={objText} />
                  <TutorialPainelCentralHarvest />
                </Box>
              </Grid>

              <Grid item xs={12} md={12} alignItems="center">
                {true ? (
                  <Typewriter text={`Seja bem vindo a gestão agrícola, ${reposUser.userRepos.name.toUpperCase()}!`} variant={'subtitle1'} color={'#2e7d32'} />
                ) : (
                  <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                    {' '}
                    <ThreeDotsSpinner />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="content-header">
          <Box className="container-fluid">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="complex" height={50} src={harvest.cultivation_icon} style={{ marginRight: '8px' }} />
                  <Typography fontWeight="500" color="#28a745" variant="h6">
                    {harvest.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box className="container-fluid">
            <Grid container spacing={2}>
              {/* card 1 coluna 1*/}
              <Grid item xs={12} lg={6} sx={{ mt: -1 }}>
                <Box mt={4}>
                  <CardPeriod item={harvest} />
                </Box>
                <Box mt={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Áreas da Safra
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardAreas item={harvest} areaTotalProperty={areaTotalProperty} />
                  </Box>
                </Box>
                {/* <Box mt={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Produção e Produtividade
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardMetaCostHarvest activePlanTargetReposHarvest={activePlanTargetReposHarvest} planTargetReposFilterHarvest={planTargetReposFilterHarvest} />
                  </Box>
                </Box> */}
                {/* <Box mt={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Custo Esperado x Custo Realizado
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardCostHarvest planHarvestActiveRepos={planHarvestActiveRepos} />
                  </Box>
                </Box> */}
              </Grid>
              {/* card 2 coluna 2 */}
              <Grid item xs={12} md={6} mt={2} sx={{ mb: 2, mt: 0 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <NavLink to={{ pathname: '/activitiesHarvest/' + idFarm + '/' + id }} className="nav-link">
                          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" size="medium" color="success" startIcon={<PendingActionsIcon />}>
                            Atividades
                          </Button>
                        </NavLink>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <FormatListBulleted style={{ color: '#2e7d32', width: '40px', height: '40px' }} />
                        <Typography fontWeight="400" variant="h6">
                          Linha do tempo
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Box>
                    <CardTimeLinePainel item={harvest} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* card nova linha */}
            <Grid container spacing={2}>
              <Grid item xs={12} mt={4}>
                {/* <Box>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Venda Projetada x Custo Projetado
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <ChartBarVPE />
                  </Box>df
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
