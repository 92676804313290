import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import PatrimonyContent from "./PatrimonyContent";
import Footer from "../../components/Footer/Footer";

//https://www.youtube.com/watch?v=dpZmsXgGGHY
export default function Patrimony() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <PatrimonyContent />
      <Footer />
    </>
  );
}
