import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { feedbackTypes } from './feedbackTypes';
import { CloseButton } from './CloseButton';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
export function FeedbackTypeStep({ onFeedbackTypeChanged, handleClose }) {
  return (
    <>
      <Box style={{ height: '70px' }}>
        <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ChatBubbleOutlineIcon style={{ color: 'white' }} />
          <Typography style={{ color: 'white' }} variant="h6">
            Deixe seu feedback
          </Typography>
          <CloseButton handleClose={handleClose} />
        </header>
      </Box>
      <Box sx={{ display: 'flex', py: 2, gap: 2, marginBottom: '50px' }}>
        {Object.entries(feedbackTypes).map(([key, value]) => {
          return (
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#2e7d32',
                borderRadius: 2,
                py: 2,
                width: 96,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                border: '1px solid transparent',
                '&:hover': {
                  borderColor: '#2e7d32',
                  backgroundColor: '#2e7d32',
                },
                '&:focus': {
                  borderColor: '#2e7d32',
                  outline: 'none',
                },
              }}
              onClick={() => onFeedbackTypeChanged(key)}
              key={key}
            >
              <img src={value.image.source} alt={value.image.alt} style={{ width: '100%' }} />
              <Typography variant="body2" color="white">
                {value.title}
              </Typography>
            </Button>
          );
        })}
      </Box>
    </>
  );
}
