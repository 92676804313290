import React, { useState } from "react";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SelectProject from './components/SelectProject';
import TutorialMaps from '../../pages/maps/components/tutorial/TutorialMaps';
import ButtonFullScreenDialog from '../../pages/Messenger/DialogMessenger/ButtonFullScreenDialog';
import AddActivities from '../../pages/activities/components/AddButtonActivities/AddActivities';
import MicIcon from "@mui/icons-material/Mic";
import SpeechRecognitionButton from "../../pages/Messenger/components/SpeechRecognitionText/SpeechRecognitionButton";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

// IA ----
import IAIcon from "../Icons/chat/IAIcon";
import ChatPlan from "../../pages/planningHarvest/components/FormPlan/ChatPlan";
//Tutoriais -- inicio
import TutorialCentral from "../../pages/main/components/tutorial/TutorialCentral";
import TutorialPainelCentral from "../../pages/ControlPanelCreations/components/tutorial/TutorialPainelCentral";
import TutorialPainelCentralHarvest from "../../pages/ControlPanelHarvest/components/tutorial/TutorialPainelCentralHarvest";
import TutorialAddArea from "../../pages/areasMaps/components/tutorial/TutorialAddArea";
import TutorialaddActivities from "../../pages/activities/components/tutorial/TutorialaddActivities";
import { default as TutorialHarvest } from "../../pages/harvest/components/addHarvest/tutorial/TutorialAddProject";
import { default as TutorialLivestock } from "../../pages/livestock/components/addLivestock/tutorial/TutorialAddProject";
import TutorialMessenger from "../../pages/Messenger/components/tutorial/TutorialMessenger";
//Tutoriais -- fim

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  },
  ok: function () {
    return null;
  }
};

const actions = [
  { id: 1, name: 'Solicitações', modulo: ['todos'], icon: <ConnectWithoutContactIcon /> },
  { id: 2, name: 'Solct. Áudio', modulo: ['Solicitações'], icon: <MicIcon />, noMobile: 'iOS' },
  { id: 3, name: 'Projeto', modulo: ['Mapa', "Central", "Criações", "Safras"], icon: <AccountTreeIcon /> },
  { id: 4, name: 'Atividades', modulo: ['Atividades'], icon: <PendingActionsIcon /> },
  // BOTÕES DO TOOLSMENU: (modulos)
  /*{ id: 4, name: 'Mapa', modulo: ['Mapa'], icon: <HelpOutlineIcon /> },
  { id: 4, name: 'Central', modulo: ['Central'], icon: <HelpOutlineIcon /> },
  { id: 5, name: 'Painel', modulo: ['Painel'], icon: <HelpOutlineIcon /> },
  { id: 6, name: 'Áreas', modulo: ['Áreas'], icon: <HelpOutlineIcon /> },
  { id: 7, name: 'Tutorial', modulo: ['Solicitações'], icon: <HelpOutlineIcon /> },
  { id: 8, name: 'Criações', modulo: ['Criações'], icon: <HelpOutlineIcon /> },
  { id: 9, name: 'Safras', modulo: ['Safras'], icon: <HelpOutlineIcon /> },
  { id: 10, name: 'Equipe', modulo: ['Equipe'], icon: <HelpOutlineIcon /> },
  { id: 11, name: 'Financeiro', modulo: ['Financeiro'], icon: <HelpOutlineIcon /> },
  { id: 12, name: 'Custo Prod', modulo: ['Custo Prod'], icon: <HelpOutlineIcon /> },
  { id: 13, name: 'Produção', modulo: ['Produção'], icon: <HelpOutlineIcon /> },
  { id: 14, name: 'Estoque', modulo: ['Estoque'], icon: <HelpOutlineIcon /> },
  { id: 15, name: 'Venda', modulo: ['Venda'], icon: <HelpOutlineIcon /> },
  { id: 16, name: 'Patrimônio', modulo: ['Patrimônio'], icon: <HelpOutlineIcon /> },
  { id: 17, name: 'Indicadores', modulo: ['Indicadores'], icon: <HelpOutlineIcon /> },
  { id: 18, name: 'Relatórios', modulo: ['Relatórios'], icon: <HelpOutlineIcon /> },*/
  { id: 665, name: 'IA CPT', modulo: ['todos'], icon: <IAIcon size={25} /> },
  { id: 666, name: 'Guia', modulo: ['todos'], icon: <HelpOutlineIcon /> },
];

const getTutorialActive = function (openModal, setOpenModal) {
  const aux_chr = localStorage.getItem("creationHarvestRepos");
  const creationHarvestRepos = aux_chr ? JSON.parse(aux_chr) : { label: "", id: 0, tipo_repos: "" };
  // selectProperty - selectCreationHarvest - selectAreaMap
  //const navMenuActive = localStorage.getItem("navMenuActive") || "selectProperty";
  const ButtonActive = localStorage.getItem('ButtonActive');
  var contexto;
  switch (ButtonActive) {
    case "Mapa":
      contexto = (<TutorialMaps openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Central":
      contexto = (<TutorialCentral openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Áreas":
      //if (navMenuActive == "selectProperty") {
      contexto = (<TutorialAddArea openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Equipe":
      contexto = (<></>);
      break;
    case "Solicitações":
      contexto = (<TutorialMessenger openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Criações":
      contexto = (<TutorialLivestock openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Safras":
      contexto = (<TutorialHarvest openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Estoque":
      contexto = (<></>);
      break;
    case "Patrimônio":
      contexto = (<></>);
      break;
    case "Venda":
      contexto = (<></>);
      break;
    case "Financeiro":
      contexto = (<></>);
      break;
    case "Indicadores":
      contexto = (<></>);
      break;
    case "Relatórios":
      contexto = (<></>);
      break;
    case "Painel":
      if (creationHarvestRepos.tipo_repos == "Safras") {
        contexto = (<TutorialPainelCentralHarvest openModal={openModal} setOpenModal={setOpenModal} />);
      } else {
        contexto = (<TutorialPainelCentral openModal={openModal} setOpenModal={setOpenModal} />);
      }
      break;
    case "Culturas":
      contexto = (<></>);
      break;
    case "Planejamentos":
      contexto = (<></>);
      break;
    case "Atividades":
      contexto = (<TutorialaddActivities openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case "Produção":
      contexto = (<></>);
      break;
    case "Custo Prod.":
      contexto = (<></>);
      break;
    default:
      contexto = (<></>);
  }
  return contexto;
};

const getModalActive = function (modal, openModal, setOpenModal) {
  var contexto;
  switch (modal) {
    case 666:
      // tutoriais
      contexto = getTutorialActive(openModal, setOpenModal);
      break;
    case 665:
      // IA -> chatgpt 
      contexto = (<ChatPlan open={openModal} onClose={setOpenModal} />);
      break;
    // ações dos módulos:
    case 1:
      contexto = (<ButtonFullScreenDialog openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case 2:
      contexto = (<SpeechRecognitionButton openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case 3:
      // livestock ou safra
      contexto = (<SelectProject openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    case 4:
      contexto = (<AddActivities openModal={openModal} setOpenModal={setOpenModal} />);
      break;
    default:
      contexto = (<></>);
  }
  return contexto;
};

export default function FloatingButtonGeneral() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  let eventListener = false;

  const modulo = localStorage.getItem('ButtonActive');
  const tipoMenu = localStorage.getItem("navMenuActive") || 'selectProperty';
  const options = actions.filter(action => action.modulo.some(item => (!(isMobile[action.noMobile || 'ok'])() && (item == 'todos' || modulo == item))));

  const handleOpen = (e) => {
    if (/click/.test(e.type)) {
      console.log("handleOpen");
      setOpen(true);
      if (!eventListener) {
        eventListener = true;
        document.addEventListener("click", handleClose);
      }
    }
  }

  const handleClose = (e, id) => {
    if (id > 0) {
      setStatus(id);
      setOpenModal(true);
    }
    if (/click/.test(e.type)) {
      if (eventListener) {
        eventListener = false;
      } else {
        console.log("handleClose");
        // removeEventListener repetido para evitar multiplos addEventListener
        document.removeEventListener("click", handleClose);
        document.removeEventListener("click", handleClose);
        document.removeEventListener("click", handleClose);
        document.removeEventListener("click", handleClose);
        setOpen(false);
      }
    }
  }

  const getMarginBotton = function () {
    const activeButton = localStorage.getItem('ButtonActive');
    var margin = 68;
    if (activeButton == 'Mapa' || window.innerWidth < 400) {
      margin = 68;
    } else if (tipoMenu == 'selectProperty' && (window.innerWidth < 1100)) {
      margin = 81;
    } else if (tipoMenu == 'selectCreationHarvest' && window.innerWidth < 800) {
      margin = 81;
    } else if (tipoMenu == 'selectAreaMap' && window.innerWidth < 500) {
      margin = 81;
    }
    return margin;
  };
  const navMenuActive = localStorage.getItem("navMenuActive");

  return (
    <>
      {getModalActive(status, openModal, setOpenModal)}
      < SpeedDial
        id="FloatingButtonGeneral"
        title="Mais opções"
        ariaLabel="SpeedDial tooltip example"
        color="secondary"
        sx={{ position: "fixed", bottom: getMarginBotton(), left: "0.8%" }}
        icon={< SpeedDialIcon />}
        onClose={(e) => handleClose(e)}
        onOpen={(e) => handleOpen(e)}
        open={open}
        FabProps={{
          sx: {
            width: window.innerWidth < 400 ? '35px' : '45px',
            height: window.innerWidth < 400 ? '35px' : '45px',
            bgcolor: 'success.main',
            '&:hover': {
              bgcolor: 'success.main',
            }
          }
        }}
      >
        {
          options.map((action) => (
            ((modulo == "Mapa" && action.name == "Solicitações")) ? <></> :
              <SpeedDialAction style={{ 'whiteSpace': 'nowrap' }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                tooltipPlacement={'right'}
                onClick={(e) => handleClose(e, action.id)}
              />
          ))
        }
      </SpeedDial >
    </>
  );
}