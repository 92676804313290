import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Avatar, Box, ListItemAvatar, Typography } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';

export default function FirstAcessMapList() {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <DirectionsIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText
            primary={
              <Typography gutterBottom fontWeight={500}>
                Passo 1: Digite o endereço da sua propriedade na barra de pesquisa que se encontra no canto superior direito.
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/map_search.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <ListItem
        key={null}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <DirectionsIcon style={{ color: '#2e7d32' }} />
          </span>
        }
      >
        <ListItemText
          primary={
            <Typography gutterBottom fontWeight={500}>
              Passo 2: Selecione o endereço sugerido pela barra de pesquisa.
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        key={null}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <PanToolIcon />
          </span>
        }
      >
        <ListItemText
          primary={
            <Typography gutterBottom fontWeight={500}>
              Passo 3: Uma vez encontrado o endereço no mapa, você pode ajustar (enquadrar) a visualização da sua propriedade.
            </Typography>
          }
        />
      </ListItem>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <IconButton aria-label="comment">
              <HighlightAltIcon />
            </IconButton>
          }
        >
          <ListItemText
            primary={
              <Typography gutterBottom fontWeight={500}>
                Passo 4: Para salvar ou atualizar a nova localização no mapa, clique no botão verde "ÁREA DA PROPRIEDADE" e depois em "SALVAR"
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/areadapropriedade.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
    </List>
  );
}
