import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import { Context } from '../../../components/Context/AuthContext';
import usePatrimonyReposStore from '../../../store/usePatrimonyReposStore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import QuestionMark from '@mui/icons-material/QuestionMark';
import { IconButton, Collapse, Box, Typography, Avatar, Tooltip } from '@mui/material';
import { getIconComponent } from './getIconComponent';
import { formatValue } from '../../../utils/functions';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${TableCell.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${TableCell.body}`]: {
//     fontSize: 14,
//   },
// }));

const Row = ({ row, patrimonyType, listPatrimony }) => {
  // console.log(row, patrimonyType, listPatrimony);
  const [open, setOpen] = useState(false);
  const types = patrimonyType.filter((type) => type.patrimony_group_id === row.id);
  // const patrimonies = listPatrimony.filter(
  //   (patrimony) => patrimony.patrimony_group_id === row.id
  // );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Box display="flex" alignItems="center">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{ color: '#2e7d32' }}>
              {open ? <KeyboardArrowUpIcon color="#2e7d32" /> : <KeyboardArrowDownIcon color="#2e7d32" />}
            </IconButton>
            <Typography variant="subtitle1" color="#2e7d32" gutterBottom component="div">
              {row.name}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} sx={{ mb: 8 }}>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom component="div">
                Patrimônios
              </Typography>
              <Table size="small" aria-label="patrimonies">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell align="right">Ano de Fabricação</TableCell>
                    <TableCell align="right">Valor</TableCell>
                    <TableCell align="right">Valor da compra</TableCell>
                    <TableCell align="right">Vida útil (anos)</TableCell>
                    <TableCell align="right">Vida útil (mês)</TableCell>
                    <TableCell align="right">Vida útil (horas)</TableCell>
                    <TableCell align="right">Taxa de depreciação (anual)</TableCell>
                    <TableCell align="right">Taxa de depreciação (mensal)</TableCell>
                    <TableCell align="right">Taxa de depreciação (horária)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listPatrimony
                    .filter((p) => p.patrimony_group_id === row.id)
                    .map((patrimonyItem) => {
                      const vidaUtilAnos = (patrimonyItem.lifespan || 0); // Vida útil em anos
                      const vidaUtilMeses = vidaUtilAnos * 12; // Vida útil em meses
                      const vidaUtilHoras = vidaUtilAnos * 365 * 24; // Vida útil em horas, assumindo 24 horas por dia

                      // Cálculo das taxas de depreciação
                      const taxaDepreciacaoAnual = vidaUtilAnos ? 1 / vidaUtilAnos : 0;
                      const taxaDepreciacaoMensal = taxaDepreciacaoAnual / 12;
                      const taxaDepreciacaoHoraria = vidaUtilHoras ? taxaDepreciacaoAnual / vidaUtilHoras : 0;

                      return (
                        <TableRow key={patrimonyItem.id}>
                          <TableCell>
                            <strong>
                              {patrimonyItem.image ? <Avatar aria-label="Imagem do patrimônio" src={`data:image/jpeg;base64,${patrimonyItem.image}`}></Avatar> : getIconComponent(patrimonyItem.patrimony_group_icon)}{' '}
                              {capitalizeFirstLetter(patrimonyItem.name)}
                            </strong>
                          </TableCell>
                          <TableCell>
                            {types.find((type) => type.id === patrimonyItem.id_patrimony_type)?.name || (
                              <Tooltip title="Acesse a aba 'Lista de Patrimônio', selecione o item desejado e edite o campo 'Tipo de patrimônio' .">
                                <IconButton>
                                  <QuestionMark />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="right">{patrimonyItem.manufacturing_year}</TableCell>
                          <TableCell align="right">{formatValue(patrimonyItem.value, true)}</TableCell>
                          <TableCell align="right">{formatValue(patrimonyItem.value, true)}</TableCell>
                          <TableCell align="right">{vidaUtilAnos}</TableCell>
                          <TableCell align="right">{vidaUtilMeses}</TableCell>
                          <TableCell align="right">{vidaUtilHoras}</TableCell>
                          <TableCell align="right">
                            {patrimonyItem.lifespan !== 0 || (patrimonyItem.lifespan !== null && !isNaN(patrimonyItem.lifespan)) ? (
                              <>
                                {`${formatValue(taxaDepreciacaoAnual * 100)}%`}
                                <br />
                                {formatValue((patrimonyItem.value * taxaDepreciacaoAnual), true)}
                              </>
                            ) : (
                              <Tooltip title="Acesse a aba 'Lista de Patrimônio', selecione o item desejado e edite os campos 'Ano de Fabricação' e 'Vida Útil'.">
                                <IconButton>
                                  <QuestionMark />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {patrimonyItem.lifespan !== 0 ? (
                              <>
                                {`${formatValue(taxaDepreciacaoMensal * 100)}%`}
                                <br />
                                {formatValue((patrimonyItem.value * taxaDepreciacaoMensal), true)}
                              </>
                            ) : (
                              <Tooltip title="Acesse a aba 'Lista de Patrimônio', selecione o item desejado e edite os campos 'Ano de Fabricação' e 'Vida Útil'.">
                                <IconButton>
                                  <QuestionMark />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {patrimonyItem.lifespan !== 0 ? (
                              <>
                                {`${formatValue(taxaDepreciacaoHoraria * 100)}%`}
                                <br />
                                {formatValue((patrimonyItem.value * taxaDepreciacaoHoraria), true)}
                              </>
                            ) : (
                              <Tooltip title="Acesse a aba 'Lista de Patrimônio', selecione o item desejado e edite os campos 'Ano de Fabricação' e 'Vida Útil'.">
                                <IconButton>
                                  <QuestionMark />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function TableDepreciation() {
  // const { aux_add, setAuxAdd } = useContext(Context);
  // var id_farm = localStorage.getItem('Property') || 1;

  const reposPatrimony = usePatrimonyReposStore((state) => state);
  // console.log('TableDepreciation');
  // console.log(reposPatrimony.listPatrimony);
  //console.log(reposPatrimony.patrimonyGroup);
  //console.log(reposPatrimony.patrimonyType);

  // Filtra os grupos de patrimônios para incluir apenas os que estão presentes em listPatrimony
  const filteredPatrimonyGroups = reposPatrimony.patrimonyGroup.filter((group) => reposPatrimony.listPatrimony.some((patrimony) => patrimony.patrimony_group_id === group.id));

  // Função para agregar os dados
  // const aggregateData = () => {
  //   return reposPatrimony.listPatrimony.map((patrimony) => {
  //     const group = reposPatrimony.patrimonyGroup.find(
  //       (group) => group.id === patrimony.patrimony_group_id
  //     );
  //     const type = reposPatrimony.patrimonyType.find(
  //       (type) => type.id === patrimony.id_patrimony_type
  //     );
  //     return {
  //       ...patrimony,
  //       groupName: group ? group.name : "Grupo não encontrado",
  //       typeName: type ? type.name : "Tipo não encontrado",
  //     };
  //   });
  // };

  //const data = aggregateData();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              {' '}
              <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                Tipos de patrimônios
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {reposPatrimony.patrimonyGroup.map((row) => ( */}
          {filteredPatrimonyGroups.map((row) => (
            <Row key={row.id} row={row} patrimonyType={reposPatrimony.patrimonyType} listPatrimony={reposPatrimony.listPatrimony} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
