import * as React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import LivestockListTable from "./LivestockListTable";
import LivestockListBathTable from "./LivestockListBathTable";
import LivestockListTableCategory from "./LivestockListTableCategory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, item) {
  return {
    id: `mapa_rebanho-${index}`,
    "aria-controls": `Mapa do Rebanho: ${item}`,
  };
}

export default function LivestockListTableTab({ livestock }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", mb: 10 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} variant="scrollable" value={value} onChange={handleChange} aria-label="Tabela Mapa do Rebanho"
          sx={{
            ".Mui-selected": {
              'color': "#2e7d32 !important",
              'fontWeight': 'bold',
            },
          }} >
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <CenterFocusWeakIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Lote
              </Grid>
            }
            {...a11yProps(0, 'Lote')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">

                <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Identificados
              </Grid>
            }
            {...a11yProps(1, 'Identificados')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <CenterFocusWeakIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Idade
              </Grid>
            }
            {...a11yProps(2, 'Idade')}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LivestockListBathTable livestock={livestock} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LivestockListTable livestock={livestock} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LivestockListTableCategory livestock={livestock} />
      </TabPanel>
    </Box>
  );
}
