import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Grid, Typography, Stack } from "@mui/material";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);

export default function FormIdentification2Dates({ data, obj_fn, dates }) {
  const [stageData, setStageData] = useState(dates.stageData);

  const [value, setValue] = useState(dayjs(dates.start));
  const [valueEnd, setValueEnd] = useState(dayjs(dates.end));

  if (dates.stageData !== stageData) {
    setValue(dayjs(dates.start));
    setValueEnd(dayjs(dates.end));
    setStageData(dates.stageData);
  }

  // valor para inicializar o data
  useEffect(() => {
    if (dates.show) {
      obj_fn.setData((prevState) => ({ ...prevState, date_start: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
      obj_fn.setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
    }
  }, [value]);

  useEffect(() => {
    if (dates.show) {
      obj_fn.setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
    }
  }, [valueEnd]);

  const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };

  return ( <>
    <Grid container >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ my: 3, height: 125 }}>
          <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="d-lg-none">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Início*"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        if (data.stageData === '3') {
                          const newValueEnd = dayjs(newValue).add(3, "day");
                          // console.log("------");
                          setValueEnd(newValueEnd);
                        }
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={dates.min_i}
                      maxDate={valueEnd}
                      renderInput={(params) => <TextField {...params} color="success" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MobileDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Término*"
                      value={valueEnd}
                      onChange={(newValue) => {
                        setValueEnd(newValue);
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={value}
                      maxDate={dates.max_e}
                      renderInput={(params) => <TextField {...params} color="success" />}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <div className="d-none d-lg-block">
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Início*"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        if (data.stageData === '3') {
                          const newValueEnd = dayjs(newValue).add(3, "day");
                          // console.log("------");
                          // console.log(newValueEnd);
                          setValueEnd(newValueEnd);
                        }
                      }}
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={dates.min_i}
                      maxDate={valueEnd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="success"
                          error={!value.$D}
                          helperText={
                            !value.$D && (
                              <p id="data_inicio_valid" className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      //views={['day', 'month', 'year']}
                      label="Data de Término*"
                      value={valueEnd}
                      onChange={(newValue) => {
                        setValueEnd(newValue);
                      }}
                      color="success"
                      adapterLocale="pt"
                      inputFormat="DD/MM/YYYY"
                      dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                      minDate={value}
                      maxDate={dates.max_e}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="success"
                          error={!valueEnd.$D}
                          helperText={
                            !valueEnd.$D && (
                              <p id="data_termino_valid" className="form-text text-danger is-invalid">
                                Por favor, insira uma data válida.
                              </p>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: "#808080" }}>
              Data prevista para início da atividade.
            </Typography>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  </> );
}