import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';


// function parsedElementsList(listAccount) {
//   const parsedElements = listAccount.map(item => {
//     try {
//       // Verifica se info é uma string e se parece com um JSON
//       if (typeof item.info === "string" && item.info.startsWith('[') && item.info.endsWith(']')) {
//         return { ...item, info: JSON.parse(item.info) };
//       }
//       return item;
//     } catch (error) {
//       console.error('Erro ao analisar JSON:', error);
//       return item;
//     }
//   });
//   return parsedElements
// }


function parsedElementsList(item) {
  let parsedElements = item;
  try {
    // Verifica se info é uma string e se parece com um JSON
    if (typeof item === "string" && item.startsWith('[') && item.endsWith(']')) {
      parsedElements = JSON.parse(item);
    }
    //return item;
  } catch (error) {
    console.error('Erro ao analisar JSON:', error);
    //return item ;
  }
  return parsedElements
}


// create the store
const useAccountReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listAccount: [],
        fetch: async (item) => {
          var id_property = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_property': id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("finances/accountBy", (item), header);

          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          await set({
            listAccount:
              data_map.map((account) => ({
                id: account.id,
                id_property: account.id_property,
                name: account.name,
                nature: account.nature,
                mother: account.mother,
                hierarchy: account.hierarchy,
                info: account.info,
                infoParse: parsedElementsList(account.info),
              }))
          })
          return data;
        },
        // pega a(s) account(s) da listAccount de acordo com o [key e val] informados
        getAccountStore: (key, val) => {
          return get().listAccount.filter((account) => {
            if (account[key] == val) {
              return account;
            }
          })
        },
        // pega a(s) account(s) da listAccount de acordo com o [key e val] informados
        getAccountStoreRegex: (key, regex) => {
          return get().listAccount.filter((account) => {
            if (regex.test(account[key])) {
              return account;
            }
          })
        },
        // Adiciona uma nova account à listAccount
        addAccountStore: (account) => {
          set(state => ({
            listAccount: [...state.listAccount, account]
          }))
        },
        // Gera uma nova listAccount sem a account informada
        delAccountStore: (id) => {
          set({
            listAccount:
              get().listAccount.filter((account) => {
                if (account.id != id) {
                  return account;
                }
              })
          })
        },
        // atualiza listAccount de acordo com id, key e val informados
        updateAccountStore: (id, key, val) => {
          set({
            listAccount:
              get().listAccount.map((account) => {
                if (account.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    account = val;
                  } else {
                    account[key] = val;
                  }
                }
                return account;
              })
          })
        },
        // Adiciona uma nova account à listAccount
        // getParse: (lista) => {
        //   return parsedElementsList(lista);
        // },
        // // Adiciona uma nova account à listAccount
        // getParseAccountStore: () => {
        //   return parsedElementsList(get().listAccount);
        // },
      }),
      { name: "reposAccount" }
    )
  )
);

export default useAccountReposStore;