import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Fab, IconButton, Typography } from '@mui/material'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import FormPropertyArea from './FormPropertyArea'
import CloseIcon from '@mui/icons-material/Close'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'

export default function PropertyAreaButton({ map, center, setCenter, setIsLoadingMap, address }) {
  const [state, setState] = React.useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    toggleDrawer('right', true)
  }
  function handleClose() {
    setAnchorEl(null)
    toggleDrawer('right', false)
  }

  return (
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Fab
            sx={{
              position: 'fixed',
              top: 143,
              right: '3%',
              // width: window.innerWidth < 400 ? '70px' : '100px',
              height: window.innerWidth < 400 ? '30px' : '40px',
              fontSize: window.innerWidth < 400 ? '12px' : '0.875rem',
              animation: `${expandAndFade} 2s linear infinite`,
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            //onClick={toggleDrawer(anchor, true)}
            onClick={handleClick}
            color="success"
            aria-label="add"
            variant="extended"
          >
            <HighlightAltIcon sx={{ mr: 0.5, width: window.innerWidth < 400 ? '18px' : '25px', height: window.innerWidth < 400 ? '18px' : '25px' }} />
            Área da Propriedade
          </Fab>

          <Drawer anchor={anchor} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <Typography sx={{ p: 2 }} variant="BUTTON TEXT">
                Mapa da Propriedade
              </Typography>
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Divider />
              <FormPropertyArea map={map} center={center} setCenter={setCenter} handleClose={handleClose} setIsLoadingMap={setIsLoadingMap} address={address} />
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  )
}
