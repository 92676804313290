import React, { useEffect, useState } from 'react';
import { Chip, Stack } from '@mui/material';

const stage = [
  { id: 1, label: 'A FAZER' },
  { id: 2, label: 'EM PROGRESSO' },
  { id: 3, label: 'CONCLUÍDA' },
];

export default function FormInputStep4PeriodBasicDatePickerNowLivestockProgressRadioButton({ data, setData }) {
  const [selectedFase, setSelectedFase] = useState('CONCLUÍDA');
  useEffect(() => {
    setData((prevState) => ({ ...prevState, progress: 'CONCLUÍDA' }));
  }, []);

  const handleFaseChange = (faseLabel) => {
    setSelectedFase(faseLabel);
    setData((prevState) => ({ ...prevState, progress: faseLabel }));
  };

  return (
    <Stack direction="row" spacing={1}>
      {stage.map((fase) => (
        <Chip key={fase.id} label={fase.label} color={selectedFase === fase.label ? 'success' : 'default'} onClick={() => handleFaseChange(fase.label)} clickable />
      ))}
    </Stack>
  );
}
