import { SvgIcon } from "@mui/material";

// icon geral
export const IconGeneral = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} style={{ width: widthStyles, height: "auto" }} viewBox="0 0 233.000000 182.000000" preserveAspectRatio="xMidYMid meet">
        <path
          transform="translate(0.000000,182.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"} stroke="none"
          d="M1705 1696 c-11 -7 -42 -25 -70 -41 -113 -62 -143 -81 -198 -124 -39
            -31 -65 -62 -81 -96 -14 -28 -33 -58 -43 -67 -71 -62 -213 -88 -390 -70 -65 6
            -174 14 -241 18 -145 7 -401 -4 -381 -17 8 -5 32 -9 54 -9 119 0 500 -79 630
            -130 100 -39 99 -39 168 27 85 82 99 88 126 59 24 -26 51 -111 51 -166 0 -32
            5 -38 53 -65 57 -33 121 -117 131 -171 4 -16 22 -45 40 -64 34 -32 36 -33 95
            -26 59 7 61 6 61 -16 0 -93 -51 -144 -159 -161 -80 -12 -84 -27 -8 -27 l52 0
            -25 -19 c-26 -21 -136 -55 -209 -66 -24 -4 -62 -20 -85 -35 -22 -15 -52 -31
            -66 -35 -23 -7 -22 -5 12 33 66 74 86 135 67 217 -12 53 -16 63 -67 150 -18
            31 -35 69 -39 86 -5 25 -2 31 16 36 13 3 27 17 32 30 7 18 14 23 28 18 26 -8
            37 12 17 34 -9 10 -16 25 -16 33 0 9 -11 23 -25 32 -26 17 -75 22 -75 7 0 -5
            -19 -19 -43 -30 -66 -32 -92 -88 -112 -244 -10 -73 -38 -97 -121 -103 -87 -6
            -119 7 -146 60 -28 57 -87 116 -115 116 -26 0 -93 -62 -75 -68 19 -6 14 -77
            -6 -95 -10 -10 -22 -14 -26 -10 -4 5 -6 -5 -4 -22 3 -26 8 -31 43 -38 53 -10
            60 -17 134 -127 66 -98 79 -114 120 -143 28 -19 21 -23 -51 -30 l-48 -5 0 43
            c0 41 -21 75 -47 75 -32 0 -54 -61 -39 -107 6 -19 4 -23 -13 -23 -17 0 -20 5
            -15 30 13 64 -59 111 -84 55 -15 -33 -15 -68 -1 -82 9 -9 2 -13 -27 -18 -155
            -24 -512 -125 -466 -133 13 -2 58 3 100 12 117 23 410 63 567 77 180 16 778
            16 960 0 154 -13 446 -53 568 -77 195 -39 68 22 -168 80 -164 40 -500 86 -634
            86 -25 0 -57 3 -69 7 -46 12 1 23 99 23 96 0 218 18 313 46 64 18 120 18 157
            -2 52 -26 120 -29 170 -6 55 24 83 51 110 104 21 41 21 42 3 100 -16 50 -23
            60 -46 65 -30 6 -60 33 -37 33 7 0 -4 18 -26 39 -21 22 -42 39 -47 38 -6 -1
            -15 7 -21 18 -7 11 -28 40 -48 65 -54 69 -104 144 -129 195 -13 27 -67 90
            -131 155 -60 60 -109 117 -109 127 0 30 41 77 133 148 64 50 100 87 125 128
            48 79 33 94 -40 39 -73 -54 -148 -96 -148 -83 0 6 4 11 9 11 12 0 71 80 71 97
            0 17 -20 17 -45 -1z"
        />
      </SvgIcon>
    </>
  );
};
