import { SvgIcon } from '@mui/material';
import React from 'react';

export default function SantanderIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <g>
                <path style={{ opacity: 0.995 }} fill="#eb0000" d="M 231.5,-0.5 C 247.5,-0.5 263.5,-0.5 279.5,-0.5C 309.386,2.80712 338.052,10.8071 365.5,23.5C 365.5,60.8333 365.5,98.1667 365.5,135.5C 377.833,135.5 390.167,135.5 402.5,135.5C 414.5,135.5 426.5,135.5 438.5,135.5C 438.5,115.833 438.5,96.1667 438.5,76.5C 480.657,119.575 504.99,171.241 511.5,231.5C 511.5,247.5 511.5,263.5 511.5,279.5C 499.824,369.387 454.824,436.887 376.5,482C 345.887,497.897 313.553,507.731 279.5,511.5C 263.5,511.5 247.5,511.5 231.5,511.5C 141.613,499.824 74.1133,454.824 29,376.5C 13.1027,345.887 3.26938,313.553 -0.5,279.5C -0.5,263.5 -0.5,247.5 -0.5,231.5C 12.9136,132.264 64.9136,61.431 155.5,19C 180.073,9.02417 205.406,2.52417 231.5,-0.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.994 }} fill="#fae330" d="M 365.5,23.5 C 378.378,30.0722 390.711,37.4055 402.5,45.5C 402.5,75.5 402.5,105.5 402.5,135.5C 390.167,135.5 377.833,135.5 365.5,135.5C 365.5,98.1667 365.5,60.8333 365.5,23.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 0.992 }} fill="#3fa93a" d="M 402.5,45.5 C 415.704,54.3665 427.704,64.6998 438.5,76.5C 438.5,96.1667 438.5,115.833 438.5,135.5C 426.5,135.5 414.5,135.5 402.5,135.5C 402.5,105.5 402.5,75.5 402.5,45.5 Z" />
            </g>
            <g>
                <path style={{ opacity: 1 }} fill="#fffdfd" d="M 257.5,126.5 C 259.202,133.47 260.369,140.803 261,148.5C 262.115,152.729 263.781,156.729 266,160.5C 275.224,175.057 285.224,189.057 296,202.5C 302.864,211.895 307.864,222.229 311,233.5C 311.259,238.926 311.426,244.26 311.5,249.5C 334.262,254.135 354.929,263.302 373.5,277C 382.795,284.621 389.295,294.121 393,305.5C 394.903,318.452 391.903,330.119 384,340.5C 368.496,357.181 349.663,368.681 327.5,375C 284.916,386.947 241.916,388.613 198.5,380C 176.198,375.241 155.865,366.241 137.5,353C 108.366,325.795 109.699,300.128 141.5,276C 158.77,264.533 177.436,256.033 197.5,250.5C 202.027,258.529 206.86,266.529 212,274.5C 223.721,289.887 235.054,305.554 246,321.5C 250.758,328.441 253.092,336.108 253,344.5C 260.671,337.02 264.171,327.854 263.5,317C 262.653,304.806 258.819,293.639 252,283.5C 240.932,269.697 230.598,255.363 221,240.5C 211.209,221.709 211.209,203.042 221,184.5C 222.69,181.976 224.857,179.976 227.5,178.5C 227.21,189.703 229.377,200.369 234,210.5C 237,215.167 240,219.833 243,224.5C 254.048,237.88 264.715,251.547 275,265.5C 281.145,274.936 283.812,285.27 283,296.5C 287.85,292.973 291.183,288.307 293,282.5C 297.212,264.559 293.879,248.226 283,233.5C 274,221.167 265,208.833 256,196.5C 246.151,180.772 242.818,163.772 246,145.5C 248.009,138.006 251.843,131.673 257.5,126.5 Z" />
            </g>
        </SvgIcon>
    );
}
