import { Card, CardContent, Grid, Typography } from "@mui/material";
import ChartBarHSpecific from "../Charts/ChartBarHSpecific";

export default function CardAreas({ item, areaTotalProperty }) {
  const usedAreas = item.areas.map((area) => parseFloat(area.usedArea));
  // Calcular a soma total das áreas utilizadas
  const totalUsedAreaNotFormat = usedAreas.reduce((total, area) => total + area, 0);
  const totalUsedArea = totalUsedAreaNotFormat.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Número de áreas
                </Typography>
                <Typography variant="caption" color="text.secondary" component="div">
                  <i className="fa fa-map text-success" /> {item.areas.length}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Área total
                </Typography>
                <Typography variant="caption" color="text.secondary" component="div">
                  <i className="fa fa-map text-success" /> {areaTotalProperty} ha
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="">
          <ChartBarHSpecific areaTotalProperty={areaTotalProperty} totalUsedArea={totalUsedArea} />
        </div>
      </CardContent>
    </Card>
  );
}
