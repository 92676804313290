import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { Input } from '@mui/icons-material';
import dayjs from 'dayjs';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../../components/Fields/SingleDateField';
import AutocompletGenericList from '../../../../../components/Fields/AutocompletGenericList';
import { green } from '@mui/material/colors';

export default function FormBuySalesStep1({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  // const para validar o button continuar
  const isError = () => {
    let iserror = false;

    if (data.error_date || error) {
      iserror = true;
    }
    return iserror;
  };
  const returnError = () => {
    return !obj_fn.retorno;
  };

  const aux_venda = /.*Venda.*/.test(data.operationType.toLowerCase());
  // Monta o array e obj para o campo de cliente ou fornecedor
  let optionsPerson = [];
  if (aux_venda) {
    optionsPerson = obj_fn.usestore.reposPerson.listCliente;
  } else {
    let aux_f = obj_fn.usestore.reposPerson.listFornecedor;
    let aux_t = obj_fn.usestore.reposPerson.listTransportadora;
    optionsPerson = [...aux_f, ...aux_t];
  }
  const opt_person = {
    multiple: false,
    name: aux_venda ? 'client' : 'supplier',
    label: aux_venda ? 'Cliente' : 'Fornecedor',
    values: optionsPerson,
    field: aux_venda ? 'client' : 'supplier',
    helperText: 'Selecione um ' + (aux_venda ? 'Cliente' : 'Fornecedor') + ' para a ' + data.operationType,
    listHeight: 225,
    reposPerson: obj_fn.usestore.reposPerson,
  };

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    if (field === 'client' || field === 'supplier') {
      obj_fn.setData((prevState) => ({
        ...prevState,
        person: newValue,
        [field]: newValue?.id,
      }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
    }
  };

  // Monta o obj e função para o campo de data do lançamento
  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      date_release: dayjs(value),
      date_release_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      date_release_fc: dayjs(value).format('DD/MM/YYYY'),

      dueDate: dayjs(value),
      dueDate_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      dueDate_fc: dayjs(value).format('DD/MM/YYYY'),
    }));

    if (dayjs(data.date_delivery).isBefore(dayjs(value), 'day')) {
      obj_fn.setData((prevState) => ({
        ...prevState,
        date_delivery: dayjs(value),
        date_delivery_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
        date_delivery_fc: dayjs(value).format('DD/MM/YYYY'),
      }));
    }
  };
  let obj_date = {
    label: 'Data do pedido',
    field_data: 'date_release',
    text_helper: 'Refere-se ao dia em que a ' + data.operationType + ' foi efetivada.',
    max: dayjs(),
    handleChange: handleChangeDate,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  };
  const handleChangeDateDelivery = (value) => {
    obj_fn.setData((prevState) => ({ ...prevState, date_delivery: dayjs(value), date_delivery_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'), date_delivery_fc: dayjs(value).format('DD/MM/YYYY') }));
  };
  let obj_date_delivery = {
    label: 'Data prevista da entrega',
    field_data: 'date_delivery',
    text_helper: 'Refere-se ao dia previsto para os itens ' + (aux_venda ? 'saírem.' : 'chegarem.'),
    min: data.date_release,
    handleChange: handleChangeDateDelivery,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  };

  //botao radion

  const [isEnabled, setIsEnabled] = useState(false); // Estado para habilitar/desabilitar o Autocomplete

  const handleCheckboxChange = (event) => {
    setIsEnabled(event.target.checked); // Atualiza o estado baseado no checkbox
  };

  let text = 'Defina as datas do pedido e da entrega e, se desejar, o ' + (data.operationType === 'Venda' ? 'Cliente' : 'Fornecedor');
  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ mt: -5 }}>
          <Typography style={{ color: green[800] }}>
            <Typewriter tam={0} minHeight={0} text={text} />
          </Typography>
        </Grid>

        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas do pedido de {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: obj_fn.ismobile ? -2 : -4, mt: -3 }}>
          <Grid item xs={6} sm={4}>
            <SingleDateField data={data} obj_date={obj_date} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SingleDateField data={data} obj_date={obj_date_delivery} />
          </Grid>

          <Grid container spacing={3} sx={{ mt: -4, mb: 20 }}>
            <Grid item xs={12} sm={6} container alignItems="center" sx={{ height: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEnabled}
                    onChange={handleCheckboxChange}
                    color="success" // Define a cor do checkbox como verde
                  />
                }
                label="Deseja adicionar o Fornecedor(opcional)?"
              />
              {isEnabled && (
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Typography variant="subtitle2" style={{ color: '#808080' }}>
                        Para pesquisar um fornecedor existente, clique na caixa Fornecedor e digite o nome desejado.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Typography variant="subtitle2" style={{ color: '#808080' }}>
                        Para registrar um novo fornecedor, clique na caixa Fornecedor e depois em "Incluir novo Fornecedor".
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ mt: obj_fn.ismobile ? -3 : 3 }}> */}
            <Grid item xs={12} sm={6} sx={{ display: isEnabled ? 'block' : 'none', height: '100%' }}>
              <AutocompletGenericList value={data.person} options={opt_person} handleChange={handleChangeSelect} label={opt_person.label} isEnabled={isEnabled} />
              {/* <AutocompletGenericList value={data.person} options={opt_person} handleChange={handleChangeSelect} /> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Grid item sm={6} xs={6}>
            <Typography paragraph variant="subtitile1" style={{ color: green[800] }} fontWeight={500}>
              Clique em continuar...
            </Typography>
          </Grid>
        </Grid>

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
