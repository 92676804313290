import { useState } from "react";
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton } from "@mui/material";
import Typewriter from "../../../../components/Typewriter/Typewriter";
import ChatPlan from "./ChatPlan";
import { Span } from "../../../../components/Typography";
import IAIcon from "../../../../components/Icons/chat/IAIcon";
import FormInputFooter from "../../../activities/components/FormActivities/Input/FormInputFooter";
import FormPlanFooter from "./FormPlanFooter";

const UnitOptions = [
  { label: "sc 30kg", value: "sc 30kg" },
  { label: "sc 40kg", value: "sc 40kg" },
  { label: "sc 50kg", value: "sc 50kg" },
  { label: "sc 60kg", value: "sc 60kg" },
  { label: "t", value: "t" },
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "mg", value: "mg" },
];

const ValueCost = [
  { label: "R$", value: "pt-BR" },
  { label: "$", value: "en-US" },
];

const MeasureOptions = [
  {
    value: "ha",
    label: "hectare (ha)",
  },
  {
    value: "m2",
    label: "metros (m²)",
  },
];

export default function FirstStepFormExpectedProduction({ data, setData, formError, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log("FirstStepFormExpectedProduction");
  console.log(data);
  const [open, setOpen] = useState(false);

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.date_start || data.date_start.trim() === "") {
      return true;
    }
    if (!data.expectedYield || data.expectedYield.trim() === "") {
      return true;
    }
    if (!data.cost || data.cost.trim() === "") {
      return true;
    }
    // if (!data.area || data.area.trim() === "") {
    //   return true;
    // }
    return false;
  };

  const returnError = () => {
    return true;
  };
  const color = "#2e7d32";

  return (
    <>
      <Container maxWidth="sm">
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}
          <Grid container sx={{ mt: 1, mb: 6 }}>
            <div className="d-lg-none"><Grid item xs={12} md={12} alignItems="center" style={{ height: 90 }}>
              <Typography style={{ color: "#2e7d32" }}>

                <Typewriter tam={0} minHeight={0} text="Bem vindo ao planejamento da meta de produção da safra. Por favor, defina a produção esperada e o valor da cotação da cultura atualmente." />
              </Typography>
            </Grid></div>

            <div className="d-none d-lg-block"><Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }}>
              <Typography style={{ color: "#2e7d32" }}>

                <Typewriter tam={0} minHeight={0} text="Bem vindo ao planejamento  da meta de produção da safra. Por favor, defina a produção esperada e o valor da cotação da cultura atualmente." />
              </Typography>
            </Grid></div>

          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField name="startDate" label="Data de Início" type="date" required fullWidth value={data.date_start} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="endDate" label="Data de Término" type="date" required fullWidth value={data.date_prev_end} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="expectedYield"
                  variant="filled"
                  color="success"
                  focused
                  label="Produção Esperada"
                  type="number"
                  required
                  fullWidth
                  value={data.expectedYield}
                  onChange={handleChange}
                  error={formError.expectedYield && data.expectedYield === ""}
                  helperText={formError.expectedYield && data.expectedYield === "" ? "Por favor, preencha o campo obrigatório!" : "Estime o tamanho da sua produção."}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unit" fullWidth label="Unidade" value={data.unit} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                    <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                    <Select name="valueCost" fullWidth label="Moeda" value={data.valueCost} onChange={handleChange}>
                      {ValueCost.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="cost"
                  variant="filled"
                  color="success"
                  focused
                  label="Valor da cotação da cultura"
                  type="number"
                  required
                  fullWidth
                  value={data.cost}
                  onChange={handleChange}
                  error={formError.cost && data.cost === ""}
                  helperText={formError.cost && data.cost === "" ? "Por favor, preencha o campo obrigatório!" : "Defina o valor atual da cotação da cultura."}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unitCost" fullWidth label="Unidade" value={data.unitCost} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="area"
                  variant="standard"
                  label="Área"
                  type="number"
                  required
                  fullWidth
                  value={data.area}
                  onChange={handleChange}
                //error={formError.area && data.area === ""}
                //helperText={formError.area && data.area === "" ? "Por favor, preencha o campo obrigatório!" : "Estime a área da sua produção."}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Medida</InputLabel>
                    <Select autoFocus name="measure" fullWidth label="Unidade" value={data.measure} onChange={handleChange}>
                      {MeasureOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                Ex.: Produção de 2.000 sacas de 60 kg em uma área de 50 hectares de cultivo.
              </Typography>
            </Grid>
            {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              Gerar Planejamento
            </Button> */}

            {data && (
              <FormPlanFooter
                data={data}
                setData={setData}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps}
                isError={isError}
                handleClose={handleClose}
                returnError={returnError}
              />
            )}
          </Box>
        </Paper>
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
    </>
  );
}
