// Função para gerar o rodapé
export function footerBasic(currentPage, pageCount) {
  //   return {
  //     text: "Página " + currentPage.toString() + " de " + pageCount,
  //     alignment: "center",
  //     margin: [0, 0, 0, 20],
  //     fontSize: 10,
  //   };
  return {
    layout: "noBorders",
    margin: [14, 0, 14, 22],
    table: {
      widths: ["auto"],
      body: [
        [
          {
            text: "_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
            alignment: "center",
            fontSize: 5,
          },
        ],
        [
          [
            {
              text: `Página ${currentPage.toString()} de ${pageCount}`,
              fontSize: 7,
              alignment: "right",
              /* horizontal, vertical */
              margin: [3, 0],
            },
            {
              text: "© Fazenda Premium",
              fontSize: 7,
              alignment: "center",
            },
          ],
        ],
      ],
    },
  };
}
