import { Autocomplete, TextField, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { green } from "@mui/material/colors";
import { NavLink } from "react-router-dom";

function SelectAreas({ value, options, setValue, widthx }) {
    if (options.length < 1) {
        return (
            <Button sx={{ ml: -0.5 }} style={{ color: green[700], textTransform: 'initial' }} variant="text">
                <div className="justify-content-center ">
                    {<MapIcon />}
                </div>
                <NavLink to={"/areasmaps"} >
                    <div className="text-dark mt-1" style={{ color: green[700], fontSize: 15, marginLeft: '10px' }}> Cadastre suas Áreas </div>
                </NavLink>
            </Button>
        )
    }
    var option_autocomplete = options.map((repos) => ({ "label": repos.name, "id": repos.id, "id_property": repos.id_property }));
    return (
        <Autocomplete
            disableClearable
            freeSolo
            size="small"
            filterSelectedOptions
            value={value}
            getOptionLabel={(option)=>(option && option.label ? option.label : '')}
            isOptionEqualToValue={(option, value) => option.id === value.id }
            options={option_autocomplete}
            onChange={(event, newValue) => {
                setValue(newValue, 'selectAreaMap');
            }}
            sx={{ width: widthx }}
            id="selectAreaMap"
            name="selectAreaMap"
            renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Áreas"
                color="success"
                placeholder="Talhão/piquete"
                focused
                InputProps={{
                ...params.InputProps,
                startAdornment: <strong> </strong>,
                }}
            />
            )}
            noOptionsText="Sem resultados"
        />
    );
}

export default SelectAreas;