import HomeWork from "@mui/icons-material/HomeWork";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ShowerIcon from "@mui/icons-material/Shower";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import FenceIcon from "@mui/icons-material/Fence";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

function getIconComponent(iconName, height, width) {
  const icons = {
    HomeWork: HomeWork,
    WarehouseIcon: WarehouseIcon,
    HomeRepairServiceIcon: HomeRepairServiceIcon,
    ShowerIcon: ShowerIcon,
    AgricultureIcon: AgricultureIcon,
    LocalShippingIcon: LocalShippingIcon,
    ShareLocationIcon: ShareLocationIcon,
    FenceIcon: FenceIcon,
    CheckBoxOutlineBlankIcon: CheckBoxOutlineBlankIcon,
    // Adicione outros mapeamentos de ícones conforme necessário
  };

  const IconComponent = icons[iconName];
  //const IconComponentOuhter = icons[CheckBoxOutlineBlankIcon];
  return IconComponent ? (
    <IconComponent
      sx={{ mr: 1 }}
      style={{ height: height || "30px", width: width || "30px" }}
    />
  ) : (
    <CheckBoxOutlineBlankIcon
      sx={{ mr: 1 }}
      style={{ height: height || "30px", width: width || "30px" }}
    />
  );
}

export { getIconComponent };
