import React, { useState } from 'react'
import { Grid, Box, Typography, Card, CardContent, TextField, Button } from '@mui/material'
import Chart from 'react-apexcharts'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

const CardMeatProductionAreaProfitability = ({ target_active }) => {
  const [data, setData] = useState({
    expectedProduction: target_active.expectedYield,
    sellingPricePerUnit: target_active.cost,
    sellingPricePerUnitVariation: 3, // Variação do preço de venda por unidade (cotação)
    targetMargin: 20, // Margem de lucro desejada em porcentagem (valor padrão de 20%)
    marginVariation: 1, // Porcentagem da variação da margem de lucro (inicialmente zero)
    targetActive: target_active, // Incluir o estado de target_active
  })
  const [profitability, setProfitability] = useState(null)
  const [totalCost, setTotalCost] = useState(null)

  const valueProductivity = target_active?.expectedYield / target_active?.area
  //console.log(valueProductivity);

  // dados da meta de produção
  const [target, setTarget] = React.useState(target_active)
  // dados da produtividade
  const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2))

  console.log(valueProductivity.toFixed(2))
  console.log(valueProductivity.toFixed(2))

  // Dentro do useState para chartData
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'line', // Alterado para 'line' para um gráfico de linha
        height: 350,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true, // Adicionando rótulos de dados para pontos no gráfico
          },
          enableShades: false,
          lineWidth: 3,
          markers: {
            size: 0,
          },
          stroke: {
            curve: 'smooth', // Suaviza a linha do gráfico
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: ['Preço Atual', 'Preço +10%', 'Preço -10%'],
        title: {
          text: 'Variação da margem de Lucro em porcentagem',
        },
      },
      yaxis: {
        title: {
          text: 'Lucratividade (R$)',
        },
      },
      fill: {
        opacity: 1,
      },
    },
  })

  const calculateProfitability = () => {
    const { expectedProduction, sellingPricePerUnit, targetMargin, marginVariation, targetActive } = data
    console.log(expectedProduction, sellingPricePerUnit, targetMargin, marginVariation, targetActive)
    const totalCost = calculateTotalCost()
    console.log(totalCost)

    // Calcula a lucratividade para o preço atual
    const currentRevenue = expectedProduction * sellingPricePerUnit
    const currentProfitability = currentRevenue - totalCost

    // Calcula a margem de lucro com variação
    const targetMarginWithVariation = targetMargin + marginVariation

    // Calcula os valores intermediários de lucratividade em intervalos de 1%
    const intermediateProfitabilities = []
    for (let i = -marginVariation; i <= marginVariation; i++) {
      const adjustedSellingPrice = sellingPricePerUnit * (1 + i / 100) // Ajusta o preço de venda conforme o valor intermediário
      const adjustedRevenue = expectedProduction * adjustedSellingPrice
      const adjustedProfitability = adjustedRevenue - totalCost
      intermediateProfitabilities.push(adjustedProfitability)
    }

    // Constrói as categorias do eixo x para refletir os valores intermediários de margem de lucro
    const categories = []
    for (let i = -marginVariation; i <= marginVariation; i++) {
      categories.push(`${i}%`)
    }

    setChartData({
      series: [
        {
          name: 'Lucratividade',
          data: intermediateProfitabilities,
        },
      ],
      options: {
        ...chartData.options,
        xaxis: {
          categories: categories,
        },
      },
    })

    // Define a lucratividade atual para exibição
    setProfitability(currentProfitability)
  }

  const calculateTotalCost = () => {
    const { expectedProduction, sellingPricePerUnit, targetMargin, targetActive } = data
    console.log(expectedProduction, sellingPricePerUnit, targetMargin)
    const revenue = expectedProduction * sellingPricePerUnit
    //receita da venda das sacas
    console.log(revenue)
    const desiredProfit = revenue * (targetMargin / 100)
    console.log(desiredProfit)
    const totalCost = revenue - desiredProfit
    setTotalCost(totalCost)
    return totalCost
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setData({ ...data, [name]: parseFloat(value) })
  }

  const [value, setValue] = React.useState('1')

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box mt={2}>
          <Card>
            <CardContent>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Estimativa da Produção :
              </Typography>
              <TextField fullWidth label="Produção Esperada" type="number" name="expectedProduction" value={data.expectedProduction} onChange={handleChange} variant="outlined" margin="normal" />
            </CardContent>
          </Card>
          <Card sx={{ mt: 1, mb: 1 }}>
            <CardContent>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Estimativa do Preço de Venda por Unidade (R$):
              </Typography>
              <TextField
                fullWidth
                label="Preço de Venda por Unidade"
                type="number"
                name="sellingPricePerUnit"
                value={data.sellingPricePerUnit}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
            </CardContent>
          </Card>
          <Card sx={{ mt: 1, mb: 1 }}>
            <CardContent>
              <Grid container spacing={2} sx={{}}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                    Margem de Lucro Desejada (%):
                  </Typography>
                  <TextField fullWidth label="Margem de Lucro Desejada" type="number" name="targetMargin" value={data.targetMargin} onChange={handleChange} variant="outlined" margin="normal" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button variant="contained" color="primary" onClick={calculateProfitability}>
            Calcular a venda da safra
          </Button>
          <Box sx={{ mt: 2 }}>
            {profitability !== null && (
              <>
                <Typography variant="body1" gutterBottom>
                  Com a venda da Safra para a estimativa de lucro de {data.targetMargin}% você receberá R$ {profitability.toFixed(2)}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  O valor de {profitability.toFixed(2)} da venda da safra deverá ser decontado dos custo de produção para encontrar a lucratividade.
                </Typography>
              </>
            )}

            {/* {data.requiredSellingPrice && (
              <Typography variant="body1" gutterBottom>
                Preço de Venda Necessário para alcançar a Margem de Lucro Desejada: R$ {data.requiredSellingPrice.toFixed(2)}
              </Typography>
            )} */}
            {/* {profitability !== null && (
              <Typography variant="body1" gutterBottom>
                Para atingirmos essa lucratividade teríamos como meta o gasto máximo de R$ {totalCost.toFixed(2)}
              </Typography>
            )} */}
            <Typography variant="body1" gutterBottom>
              Veja no gráfico a variação da venda da safra em diferentes cenários futuros:
            </Typography>
          </Box>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                  <Tab label="Variação da Margem de Lucro" value="1" />
                  <Tab label="Meta" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box mt={2}>
                      {/* Seu código existente */}
                      <Chart options={chartData.options} series={chartData.series} height={350} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ mt: 1, mb: 1 }}>
                      <CardContent>
                        <Grid container spacing={2} sx={{}}>
                          <Grid item xs={12} md={6}>
                            <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                              Variação da margem de Lucro no gráfico (%):
                            </Typography>
                            <TextField
                              fullWidth
                              label="Variação da margem de lucro"
                              type="number"
                              name="marginVariation" // Nome do novo campo
                              value={data.marginVariation}
                              onChange={handleChange}
                              variant="outlined"
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sx={{ mt: 5 }}>
                            <Button variant="contained" color="primary" onClick={calculateProfitability}>
                              Atualizar o gráfico
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid item xs={12} md={12}>
                  <Card sx={{ mt: 1, mb: 1 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Sua Meta da Produção
                      </Typography>
                      <Grid container spacing={2} sx={{ mb: 1.1 }}>
                        <Grid item xs={12} sm={12}>
                          <Grid container spacing={1}>
                            <Grid item sx={{ width: 50 }}>
                              <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="caption">Produção Esperada</Typography>
                              <Typography variant="subtitle2">
                                <strong>{target_active?.expectedYield}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}</span>
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="caption">Estimativa do Preço de Venda por Unidade</Typography>
                              <Typography variant="subtitle2">
                                {' '}
                                {/* <strong>{data.sellingPricePerUnit}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}/ha</span>{' '} */}
                                <strong>{data.sellingPricePerUnit}</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Grid container spacing={1}>
                            <Grid item sx={{ width: 50 }}>
                              <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="caption">Faturamento(Receita)</Typography>
                              {profitability !== null && (
                                <Typography variant="subtitle2">
                                  <strong>R$ {profitability?.toFixed(2)}</strong> <span style={{ fontSize: '0.8em' }}>({data.targetMargin}%)</span>
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs>
                              {/* <Typography variant="caption">Gasto máximo com a produção</Typography>
                              {profitability !== null && (
                                <Typography variant="subtitle2">
                                  <strong>R$ {totalCost.toFixed(2)}</strong>
                                </Typography>
                              )} */}
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Adicione mais informações conforme necessário */}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CardMeatProductionAreaProfitability
