import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import Slide from "@mui/material/Slide"
import { Box, DialogActions, Grid } from "@mui/material"
import RegisterCostCenterDialog from "./RegisterCostCenterDialog"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

export default function ButtonIncomeFullScreenDialog() {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                color='success'
                onClick={handleClickOpen}
            >
                Receitas
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }} color='success'>
                    {/* <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Sound
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar> */}
                    <Toolbar>
                        <Grid
                            item
                            container
                            direction='row'
                            alignItems='center'
                            justifyContent='center'
                            style={{ maxWidth: 900 }}
                        >
                            <Grid item>
                                <Typography variant='h6' component='div'>
                                    {" "}
                                    RECEITA
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            sx={{ flex: 1 }}
                            variant='h6'
                            component='div'
                        ></Typography>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleClose}
                            aria-label='close'
                        >
                            {" "}
                            <CloseIcon />{" "}
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid item xs={12} md={12} alignItems='center' sx={{ mt: 0 }}>
                    <Box sx={{ ml: 2 }}>
                        <RegisterCostCenterDialog conta={"income"} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} alignItems='center' sx={{ mt: 0 }}>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleClose}
                            style={{ color: "#8a8a8a" }}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Grid>
            </Dialog>
        </React.Fragment>
    )
}
