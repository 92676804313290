import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Lottie from "react-lottie";
import { botAnimation } from "./archive/index";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import StopIcon from "@mui/icons-material/Stop";
import { Box } from "@mui/system";
import { useSpeechSynthesis } from "react-speech-kit";
import { useRef } from "react";

const Bot = ({ text }) => {
  console.log("bot");
  const { speak, cancel } = useSpeechSynthesis();
  const [isSpeaking, setIsSpeaking] = useState(false);

  const [animationState, setAnimationState] = useState({
    isStopped: true,
    isPaused: false,
    loop: false,
    direction: 1,
  });

  const handleSpeak = () => {
    handlePlay();
    console.log("handleSpeak");
    speak({ text: text });
    setIsSpeaking(true);
  };

  const handleToggleSpeech = () => {
    console.log("handleToggleSpeech");
    //isSpeaking=false
    //isSpeaking=true -> cancelSpeaking();
    console.log(isSpeaking);
    if (isSpeaking) {
      cancelSpeaking();
    } else {
      handleSpeak();
    }
  };

  const cancelSpeaking = () => {
    handleStop();
    console.log("cancelSpeaking");
    cancel();
    setIsSpeaking(false);
  };

  const defaultOptions = {
    //loop: false,
    autoplay: false,
    animationData: botAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handlePlay = () => {
    setAnimationState({
      ...animationState,
      loop: true,
      isStopped: false,
      isPaused: false,
    });
  };

  const handleStop = () => {
    setAnimationState({
      ...animationState,
      isStopped: true,
      isPaused: false,
      loop: false,
    });
  };

  const handleReset = () => {
    setAnimationState({
      ...animationState,
      isStopped: true,
      isPaused: false,
    });
  };

  if (isSpeaking) {
    console.log("isSpeaking");
    console.log(isSpeaking);
  }

  console.log("fim");
  console.log(animationState);
  return (
    <Box>
      <IconButton onClick={handleToggleSpeech}>
        <Lottie options={defaultOptions} height={55} width={55} loop={animationState.loop} isStopped={animationState.isStopped} isPaused={animationState.isPaused} />
      </IconButton>
      {/* <IconButton onClick={handleReset}>
        <ReplayIcon />
      </IconButton> */}
    </Box>
  );
};

export default Bot;
