import React, { useState } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { Input } from '@mui/icons-material'
import ThreeDotsSpinner from '../Loader/ThreeDotsSpinner'
import Typewriter from '../Typewriter/Typewriter'

export default function HeaderModalNew({ optionsIcon, optionsImage }) {
  const [showText, setShowText] = useState(false)
  setTimeout(() => {
    setShowText(true)
  }, 500)

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {optionsIcon.icon ? optionsIcon.icon : <Input />}
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                {optionsIcon.title}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 100 }}>
            {optionsImage.icon ? optionsImage.icon : <Input />}
            {/* <img style={{ maxHeight: 140 }} src="/dist/image/entradadeanimais_lote.png" alt="image" class="img-fluid" /> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container spacing={3} sx={{ mt: 0 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5} sx={{ mt: -0.5 }}>
            <Typography variant="h6" fontWeight={500}>
              {optionsImage.title}
            </Typography>
          </Stack>
        </Box>
      </Grid> */}
      {!optionsIcon.img ? (
        <></>
      ) : (
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 120 }}>
            {optionsIcon.img}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} sx={{ mt: 1, minHeight: optionsIcon.height || 'auto' }}>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showText ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={optionsIcon.txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showText ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={optionsImage.txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Divider sx={{ mt: 1, mb: 3, ml: -3, borderColor: '#388e3c' }} />
    </>
  )
}
