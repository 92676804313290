import { Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { NavLink } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import GridOnIcon from '@mui/icons-material/GridOn';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { grey } from '@mui/material/colors';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  width: 50,
});

export const AreasButtonVerticalDraw = ({ filters, setFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //filters.areas = ''

  const handleMapArea = (e, tipo) => {
    setFilters((prevState) => ({ ...prevState, areas: tipo }));
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var style = { top: 5, backgroundColor: '#fff', color: '#555' };
  if (window.innerWidth < 400) {
    style = { top: 5, backgroundColor: '#fff', color: '#555', width: 40, height: 40 };
  }

  return (
    <>
      <Fab sx={{ ...style, mt: '50px' }} onClick={handleClick} aria-label="add">
        <MapIcon sx={{ color: '#555' }} />
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: 160,
            maxWidth: '100%',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            ml: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 60,
              ml: 1,
            },
            '&:before': {
              content: '""',
              display: 'none',
              position: 'absolute',
              top: 20,
              right: 202,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'right' }}
      >
        <Box sx={{ ml: 2 }}>
          <ListItemText>Exibir Áreas:</ListItemText>
        </Box>
        <Divider />
        <MenuItem
          sx={{ mb: 0, mt: 1 }}
          onClick={(e) => {
            handleMapArea(e, 'todas');
          }}
        >
          <ListItemText>
            <span style={{ color: filters.areas == 'todas' ? '#1a831f' : '#6c757d' }}>Todas</span>
          </ListItemText>
          <GridOnIcon style={{ color: filters.areas == 'todas' ? '#1a831f' : grey[500] }} />
        </MenuItem>
        <MenuItem
          sx={{ mb: 0 }}
          onClick={(e) => {
            handleMapArea(e, 'ocupadas');
          }}
        >
          <ListItemText>
            <span style={{ color: filters.areas == 'ocupadas' ? '#1a831f' : '#6c757d' }}>Ocupadas</span>
          </ListItemText>
          <WallpaperIcon style={{ color: filters.areas == 'ocupadas' ? '#1a831f' : grey[500] }} />
        </MenuItem>
        <MenuItem
          sx={{ mb: 0 }}
          onClick={(e) => {
            handleMapArea(e, 'remover');
          }}
        >
          <ListItemText>
            <span style={{ color: filters.areas == 'remover' ? '#1a831f' : '#6c757d' }}>Remover</span>
          </ListItemText>
          <CropFreeIcon style={{ color: filters.areas == 'remover' ? '#1a831f' : grey[500] }} />
        </MenuItem>
        {/* <MenuItem sx={{ mb: -1 }} onClick={(e) => { handleMapArea(e, 'livestock') }} >
          <ListItemText >
            <span style={{ 'color': (filters.areas == 'livestock' ? '#1a831f' : '#6c757d') }}>Com Criações</span>
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ mb: -1 }} onClick={(e) => { handleMapArea(e, 'harvest') }} >
          <ListItemText >
            <span style={{ 'color': (filters.areas == 'harvest' ? '#1a831f' : '#6c757d') }}>Com Safras</span>
          </ListItemText>
        </MenuItem> */}
      </Menu>
    </>
  );
};
