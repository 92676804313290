import React, { useState } from "react";
import { TextField, Box, Grid, Typography, InputAdornment } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Title } from "@mui/icons-material";

import Typewriter from "../../../../../components/Typewriter/Typewriter";
import ThreeDotsSpinner from "../../../../../components/Loader/ThreeDotsSpinner";

import FormBatchHeader from "./FormBatchHeader";
import FormBatchFooter from "./FormBatchFooter";

const stage = [
  "Cria",
  "Recria",
  "Engorda",
  "Reprodução",
  "Produção",
  // "Completo",
];

export default function FormBatchStep1({ data, obj_fn }) {
  const [validateNameBatch, setValidateNameBatch] = useState(false);
  // const [selectedFase, setSelectedFase] = useState("");
  //show textos
  const [showTextLote, setShowTextLote] = useState(false);
  const [showTextFase, setShowTextFase] = useState(false);

  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const faseStyle = (fase) => ({
    backgroundColor: data.stage === fase ? "#5cb85c" : "#fff",
    color: data.stage === fase ? "#fff" : "#333",
    marginRight: "5px",
    borderRadius: "5px",
    padding: "2px",
    //padding: "10px",
    cursor: "pointer",
  });

  // valida o button continuar
  const isError = () => {
    if (!data.stage || !data.nameBatch.trim() === "") {
      return true;
    }
    if (data.nameBatch) {
      const getLote = obj_fn.usestore.reposLivestock.getLotesStore('name', data.nameBatch);

      if (getLote.length > 0) {
        setValidateNameBatch(true);
        return true;
      } else {
        setValidateNameBatch(false);
      }
    }
    return false;
  };

  // valida o button retornar
  const returnError = () => {
    return true;
  };

  const handleFaseChange = (event) => {
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    obj_fn.setData(prevState => ({ ...prevState, "nameBatch": `Lote ${event.target.value} ${data.contador}` }));

    setTimeout(() => {
      setShowTextLote(true);
    }, 500);
  };
  const handleChange = (event, blur) => {
    console.log("handleChange");
    let value = event.target.value;
    if (blur) {
      value = value.trim();
    }
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: value }));
  };

  const text_title = "Informações essenciais do lote";
  const txt_msn = "Informe a Fase de manejo e o Nome do lote!\n Por favor, especifique a fase da sua criação";
  const txt_lote = "Sugerimos um nome para o seu lote, mas sinta-se a vontade de personalizá-lo!";
  
  return ( <>
    <FormBatchHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} height={'60px'}/>

    <Grid container sx={{ mb: 4 }}>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid container justifyContent="center" sx={{}} spacing={2}>
          <Grid item xs={12} sm={12}>
            <RadioGroup
              aria-label="fases"
              name="fases"
              value={data.stage}
              onChange={handleFaseChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {stage.map((fase) => (
                <FormControlLabel
                  key={fase}
                  value={fase}
                  name="stage"
                  control={<Radio color="success" />}
                  label={
                    <div style={faseStyle(fase)}>
                      {` ${fase.charAt(0).toUpperCase() + fase.slice(1)}`}
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        {!data.stage ? <></> : <>
          <Grid container justifyContent="left" sx={{ mt: 0.5 }} spacing={2} style={{ height: 30 }}>
            {!showTextLote && !data.nameBatch ? 
              <Box display="flex" alignItems="center" style={{ height: 30 }} >
                <ThreeDotsSpinner />
              </Box> :
              <Typography style={{ color: '#2e7d32' }}>
                <Typewriter tam={0} minHeight={0} text={txt_lote} />
              </Typography>
            }
          </Grid>

          <Grid item xs={12} sm={12} sx={{maxWidth: '350px'}}>
            <TextField
              color="success"
              label="Nome do Lote"
              id="nameBatch"
              name="nameBatch"
              size="small"
              value={data.nameBatch}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Title sx={{ color: "action.active" }} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { color: "#808080"} }}
              variant="standard"
              error={validateNameBatch || data.nameBatch === ""}
              helperText={
                (validateNameBatch || data.nameBatch === "") && (
                  <p id="NameLivestock" className="form-text text-danger is-invalid">
                    Nome de lote existente. Por favor, insira um outro nome.
                  </p>
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: -1 }}>
            <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Ex.: Lote/fase de manejo/quantidade de lotes
            </Typography>
          </Grid>
        </> }
      </Grid>
    </Grid >

    <FormBatchFooter
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </> );
}
