import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

export default function CardTraining() {
  const theme = useTheme();

  return (
    <Card sx={{ display: "flex", height: 240 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="subtitle1">
            Aprendizado e Suporte
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" component="div">
            Aprenda como ter maior produtividade com o Fazenda Premium.
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <NavLink to="/suport" className="nav-link">
            <Button variant="text" size="medium" color="success">
              CLIQUE AQUI
            </Button>
          </NavLink>
        </Box>
      </Box>
      <CardMedia component="img" sx={{ width: 200, marginLeft: "auto" }} image="/dist/image/suporte01.png" alt="Live from space album cover" />
    </Card>
  );
}
