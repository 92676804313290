import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Card,
    CardHeader,
    Stack
} from '@mui/material';
import Avatar from "@mui/material/Avatar";
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { green, grey } from '@mui/material/colors';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RolePermission from "../../RolePermission"
import { margin } from '@mui/system';

const ReviewInfo = ({ formik }) => {
    const { values } = formik;
    //console.log(values)
    let charName = "?"

    if (values.name) {
        const name = values.name;
        charName = name[0].toUpperCase();
        //console.log(charName);
    }

    const [showNumberTeller, setshowNumberTeller] = useState(true);
    const handlerShowNumberTeller = () => {
        setshowNumberTeller(!showNumberTeller);
    };

    const roleName = RolePermission(Number(values.id_role))
    //console.log(roleName)
    return (
        <>
            {values.name === '' ? (<Alert severity="success">O nome é um campo obrigatório</Alert>) :
                (<>
                    <Typography variant="h6" >
                        Confirme os seus dados e clique em enviar.
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="Email" secondary={values.email} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Senha" secondary={(showNumberTeller ? "******" : values.password)} />
                            <i className="fa fa-eye-slash" onClick={handlerShowNumberTeller}></i>
                        </ListItem>
                    </List>
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ mb: 5, ml: -3 }} >
                        <div style={{ borderStyle: "dotted", borderWidth: "4px", borderColor: "#dcdcdc", padding: "5px" }}>
                            {values.farm.map((farm) => (
                                <span key={'reviewInfo_' + farm.id}> <strong>{farm.label};</strong> </span>
                            ))}
                            <Card sx={{ width: 280, height: 100, p: 1, m: 1 }}>
                                <CardHeader
                                    sx={{ ml: -1 }}
                                    avatar={
                                        <Avatar variant="rounded" sx={{ bgcolor: green[500] }} aria-label="recipe">
                                            {charName.toUpperCase()}
                                        </Avatar>
                                    }
                                    title={
                                        <Typography variant="button">
                                            <strong>{values.name}</strong>
                                        </Typography>
                                    }
                                    subheader={
                                        <Stack spacing={0.5} >
                                            <Typography variant="body2" color="text.secondary">
                                                <HowToRegIcon sx={{ fontSize: 18, color: grey[500] }} /> <strong style={{ color: "green" }}>{roleName}</strong>
                                            </Typography>
                                            <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                                                <MailOutlineIcon sx={{ fontSize: 18 }} /> {values.email}
                                            </Typography>
                                        </Stack>
                                    }
                                />
                            </Card>
                        </div>
                    </Grid>
                </>)
            }
        </>
    )
}

export default ReviewInfo