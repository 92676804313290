import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField'

const CurrencyInputReal = ({ value, onChange, label, helperText, required, id, name, color, variant, adornment, endAdornment, disabled }) => {
  // const [value, setValue] = useState("0,00")
  const inputRef = useRef(null) // Cria uma ref para acessar o input diretamente

  // Converte o valor numérico para uma string formatada
  const formatValue = (num) => {
    // Verifica se num é um número; caso contrário, usa 0
    const number = isNaN(num) || num === '' ? 0 : Number(num)
    let str = number.toFixed(2) // Formata para 2 decimais
    str = str.replace('.', ',') // Substitui ponto por vírgula
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Adiciona separadores de milhar
  }

  const handleChange = (event) => {
    const input = event.target.value.replace(/\D/g, '') // Remove tudo que não for dígito
    const numberValue = input.padStart(3, '0') // Garante pelo menos 3 dígitos
    const floatValue = parseInt(numberValue, 10) / 100 // Converte para o valor decimal correto
    onChange({ target: { name, value: floatValue } }) // Chama o onChange externo com o valor numérico
  }

  const handleFocus = (event) => {
    const length = event.target.value.length
    event.target.setSelectionRange(length, length) // Posiciona o cursor no final do texto
  }

  return (
    <TextField
      required={required}
      id={id}
      name={name}
      label={label}
      color={color}
      variant={variant || 'standard'}
      disabled={disabled}
      value={formatValue(value)} // Formata o valor para exibição
      onChange={handleChange}
      onFocus={handleFocus}
      helperText={helperText}
      InputProps={{
        startAdornment: adornment == 'quantidade' ? <></> : <span>R$ </span>, // Adiciona o símbolo de Real antes do valor
        endAdornment: !endAdornment ? <></> : endAdornment, // Adiciona o símbolo de Real antes do valor
        inputProps: {
          style: { textAlign: 'right' }, // Alinha o texto à direita no input
          ref: inputRef, // Passa a ref para o input
        },
      }}
    />
  )
}

export default CurrencyInputReal
