import React, { useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconSoilPreparation } from "../../../../components/Icons/harvest/unit/IconSoilPreparation";
import { IconPlanting } from "../../../../components/Icons/harvest/unit/IconPlanting";
import { IconFertilization } from "../../../../components/Icons/harvest/unit/IconFertilization";
import { IconSeedTreatment } from "../../../../components/Icons/harvest/unit/IconSeedTreatment";
import { IconAgriculturalApplications } from "../../../../components/Icons/harvest/unit/IconAgriculturalApplications";
import { IconHarvesting } from "../../../../components/Icons/harvest/unit/IconHarvesting";
import PestControlIcon from "@mui/icons-material/PestControl";
import { Button, Grid, Stack, TextField } from "@mui/material";

const activitiesList = {
  Atividade_Preparo_do_solo: {
    name: "SoilPreparation",
    value: false,
    title: "Preparo de Solo",
    icon: <IconSoilPreparation widthStyles={"32"} />,
    operations: [
      { name: "Arar o solo", value: false },
      { name: "Gradear o solo", value: false },
      { name: "Nivelar o solo", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Tratamento_de_sementes: {
    name: "treatment of semnetes",
    value: false,
    title: "Tratamento de semente ",
    icon: <IconSeedTreatment widthStyles={"32"} />,
    operations: [
      { name: "Preparar as sementes", value: false },
      { name: "Aplicar os tratamentos", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Plantio: {
    name: "Planting",
    value: false,
    title: "Plantio ",
    icon: <IconPlanting widthStyles={"32"} />,
    operations: [
      { name: "Preparar as linhas de plantio", value: false },
      { name: "Depositar as sementes", value: false },
      { name: "Cobrir as sementes", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Fertilizacao: {
    name: "Fertilization",
    value: false,
    title: "Fertilização ",
    icon: <IconFertilization widthStyles={"32"} />,
    operations: [
      { name: "Preparar e calibrar os equipamentos", value: false },
      { name: "Aplicar os fertilizantes", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Aplicacao: {
    name: "SoilPreparation",
    value: false,
    title: "Preparo de Solo",
    icon: <IconSoilPreparation widthStyles={"32"} />,
    operations: [
      { name: "Preparar e calibrar os equipamentos", value: false },
      { name: "Aplicar os defensivos", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Colheita: {
    name: "Harvest",
    value: false,
    title: "Colheita ",
    icon: <IconHarvesting widthStyles={"32"} />,
    operations: [
      { name: "Preparar as máquinas", value: false },
      { name: "Colher o milho", value: false },
      { name: "Outra", value: false },
    ],
  },
  Atividade_Monitoramento: {
    name: "Monitoring",
    value: false,
    title: "Monitoramento ",
    icon: <PestControlIcon style={{ fontSize: 32 }} />,
    operations: [
      { name: "Realizar inspeções", value: false },
      { name: "Analisar o solo", value: false },
      { name: "Monitorar pragas e doenças", value: false },
      { name: "Outra", value: false },
    ],
  },
};

export default function ActivitySelectionTextField({ data, setData }) {
  const [activities, setActivities] = useState(activitiesList);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (activityType, index) => {
    const updatedActivities = { ...activities };
    updatedActivities[activityType].operations[index].value = !updatedActivities[activityType].operations[index].value;
    setActivities(updatedActivities);
  };

  const renderActivityType = (activityType) => {
    const activityList = activities[activityType].operations;
    const icon = activities[activityType].icon;
    return (
      <div key={activityType}>
        <FormControlLabel
          label={
            <React.Fragment>
              {icon} <strong>{activityType}</strong>
            </React.Fragment>
          }
          control={
            <Checkbox
              checked={activityList.every((activity) => activity.value)}
              indeterminate={activityList.some((activity) => activity.value) && !activityList.every((activity) => activity.value)}
              onChange={() => toggleAllActivities(activityType)}
            />
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {activityList.map((activity, index) => (
            <div key={index}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} md={6}>
                  <FormControlLabel control={<Checkbox checked={activity.value} onChange={() => handleCheckboxChange(activityType, index)} />} label={activity.name} />
                </Grid>
                <Grid item xs={6} md={6} sx={{ mt: -2 }}>
                  <TextField
                    //sx={{ maxWidth: 200 }}
                    name={activity.name}
                    variant="standard"
                    label={`Custo de ${activity.name}`}
                    type="number"
                    required7
                    fullWidth
                    value={data[activity.name]}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </Box>
      </div>
    );
  };

  const toggleAllActivities = (activityType) => {
    const updatedActivities = { ...activities };
    const activityList = updatedActivities[activityType].operations;
    const allActivitiesSelected = activityList.every((activity) => activity.value);
    activityList.forEach((activity) => {
      activity.value = !allActivitiesSelected;
    });
    setActivities(updatedActivities);
    setData((prevData) => ({
      ...prevData,
      activitiesPlan: updatedActivities,
    }));
  };

  const handleSelectAll = () => {
    const updatedActivities = { ...activities };
    Object.keys(updatedActivities).forEach((activityType) => {
      updatedActivities[activityType].operations.forEach((activity) => {
        activity.value = true;
      });
    });
    setActivities(updatedActivities);
    setData((prevData) => ({
      ...prevData,
      activitiesPlan: updatedActivities,
    }));
  };

  const handleClearSelection = () => {
    const updatedActivities = { ...activities };
    Object.keys(updatedActivities).forEach((activityType) => {
      updatedActivities[activityType].operations.forEach((activity) => {
        activity.value = false;
      });
    });
    setActivities(updatedActivities);
    setData((prevData) => ({
      ...prevData,
      activitiesPlan: updatedActivities,
    }));
  };

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <Button variant="outlined" color="success" onClick={handleSelectAll} sx={{ mr: 2 }}>
          Selecionar todas atividades
        </Button>
        <Button variant="outlined" color="success" onClick={handleClearSelection} sx={{ ml: 2 }}>
          Limpar seleção
        </Button>
      </Box>
      {Object.keys(activities).map((activityType) => renderActivityType(activityType))}
    </div>
  );
}
