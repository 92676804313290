import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Container, Drawer, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import { DeleteForever, Assignment, ExpandMore, Input } from '@mui/icons-material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';
import { toast } from 'react-toastify';
import AdjustIcon from '@mui/icons-material/Adjust'; // Ícone padrão para opções
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Novo ícone para adicionar
import CategoryIcon from '@mui/icons-material/Category';
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField';
import FooterModalControlled from '../../../../components/Footer/FooterModalControlled';

const products_default = {
  stock_quanty: 0,
  name: '',
  id_unit: 1,
  unit_name: 'Unidade',
  stock_cost: '0,00',
  category: { id: 0, name: '' },
  // category: { id: 32, name: 'Outros' },
};

export default function FormProductStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  const [category, setCategory] = useState(!obj_fn.filters ? obj_fn.usestore.reposProducts.listCategory : obj_fn.usestore.reposProducts.getCategoryStoreMultiple(obj_fn.filters));
  const [unit, setUnit] = useState(obj_fn.usestore.reposProducts.listUnits);

  // console.log(category)
  // console.log(data)
  // console.log(data)

  const { aux_add, setAuxAdd } = React.useContext(Context);

  useEffect(() => {
    // console.log('FormProductStep1')
    setCategory(!obj_fn.filters ? obj_fn.usestore.reposProducts.listCategory : obj_fn.usestore.reposProducts.getCategoryStoreMultiple(obj_fn.filters));
  }, [aux_add]);

  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const handleChange = (index, field, value) => {
    // console.log(index, field, value)
    obj_fn.setData((prevState) => ({
      ...prevState,
      products: prevState.products.map((product, i) => (i === index ? { ...product, [field]: value } : product)),
    }));
    if (field == 'stock_cost') {
      obj_fn.setData((prevState) => ({ ...prevState, stock_cost: parseFloat(value) }));
    }
  };

  // const handleChange = (index, field, value) => {
  //   obj_fn.setData((prevState) => {
  //     const updatedProducts = prevState.products.map((product, i) => {
  //       if (i === index) {
  //         const updatedProduct = { ...product, [field]: value };
  //         if (field === 'stock_quanty' || field === 'stock_cost') {
  //           // Calcula o valor total
  //           const stockQuanty = parseFloat(updatedProduct.stock_quanty) || 0;
  //           const stockCost = parseFloat(updatedProduct.stock_cost) || 0;
  //           updatedProduct.stock_total = (stockQuanty * stockCost).toFixed(2);
  //         }
  //         return updatedProduct;
  //       }
  //       return product;
  //     });

  //     return { ...prevState, products: updatedProducts };
  //   });
  // };

  // cadastro rapido:
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };
  const [selectedValue, setSelectedValue] = useState({
    category: { id: 0, name: '' },
    // category: { id: 33, name: 'Outros' },
  });

  //const [selectedValue, setSelectedValue] = useState(null)
  // Organizar a lista de categorias em ordem alfabética
  const categorySorted = category.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Convertendo para maiúsculas para garantir uma comparação sem distinção entre maiúsculas e minúsculas
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0; // Retorna 0 se os nomes forem iguais
  });

  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  const categoryWithNewOption = [
    { id: 'new', name: 'Adicionar nova categoria' }, // Item especial para abrir o modal
    ...categorySorted,
  ];

  //console.log(categoryWithNewOption)
  //Ajustar o Manipulador onChange:erificar se o item especial foi selecionado

  const handleCategoryChange = (value) => {
    // console.log('handleCategoryChange')
    // console.log(value)
    if (value) {
      const selectedCategory = value;
      // console.log(selectedCategory)
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_category: selectedCategory.id, // Assume que cada categoria tem um ID único
        category_name: selectedCategory.name, // Armazena também o nome para facilitar o acesso
      }));
      setSelectedValue(selectedCategory); // Atualiza o estado local para refletir a seleção
    }
  };

  // useEffect(() => {
  //   handleCategoryChange(true, selectedValue)
  // }, [selectedValue])

  const handleUnitChange = (index, newValue) => {
    // console.log(index)
    // console.log(newValue)
    obj_fn.setData((prevState) => ({
      ...prevState,
      products: prevState.products.map((product, i) => {
        if (i === index) {
          // Atualiza o produto no índice especificado com o novo id_unit e unit_name
          return {
            ...product,
            id_unit: newValue ? newValue.id : '', // Atualiza o id da unidade
            unit_name: newValue ? newValue.name : '', // Adiciona a nova linha para atualizar o nome da unidade
          };
        }
        return product; // Retorna o produto não modificado para os outros índices
      }),
    }));
  };

  // const handleChangeCheck = (name) => (event) => {
  //   console.log('handleChangeCheck')
  //   obj_fn.setData((prevState) => ({
  //     ...prevState,
  //     types: { ...prevState.types, [name]: event.target.checked },
  //   }))
  // }

  const [error, setError] = useState(false);

  // const para validar o button continuar
  const isError = () => {
    //let valid = true
    //console.log(data.products)
    for (const item of data.products) {
      //console.log(item.name == '' && item.id_unit == '')
      //console.log(item.stock_quanty === '')
      if (item.name == '' || item.id_unit == '' || item.stock_quanty < 0 || item.stock_quanty === '' || !item.category?.id) {
        // console.log('entrou')
        setError(true);
        return true;
      }
    }
    setError(false);
    return false;
  };

  useEffect(() => {}, [error]);

  const returnError = () => {
    return false;
  };

  // const fileSlectedHandler = (event) => {
  //   // console.log(event.target.files[0])

  //   var uploadedFile = {
  //     file: event.target.files[0],
  //     id: 1,
  //     name: event.target.files[0].name,
  //     size: event.target.files[0].size,
  //     type: event.target.files[0].type,
  //     preview: URL.createObjectURL(event.target.files[0]),
  //     progress: 0,
  //     uploaded: false,
  //     error: false,
  //     url: null,
  //   }

  //   // console.log(uploadedFile)

  //   // this.setState({
  //   //   selectedFile: uploadedFile,
  //   // });

  //   // console.log(this.selectedFile);

  //   // this.setState({
  //   //   selectedFile: event.target.files[0],
  //   // });
  // }

  useEffect(() => {
    if (selectedValue.id !== 0) {
      setSelectedValue({
        id: data.id_category,
        name: data.category_name,
      });
    }
  }, [aux_add]);

  // TODO: LIMITAR OBSERVAÇÕES E DESCRIÇÃO A 500 CATACTERES

  const handleAddProduct = () => {
    obj_fn.setData((prevState) => ({ ...prevState, products: [...prevState.products, products_default] }));
  };

  // const [isLoaddingDelete, setIsLoaddingDelete] = useState(true)

  const handleDeleteProducts = (index) => {
    // Verifica se há apenas um produto. Se sim, não prossegue com a exclusão.
    obj_fn.setData((prevState) => {
      if (prevState.products.length <= 1) {
        // Possivelmente mostrar uma mensagem ao usuário ou simplesmente não fazer nada
        return prevState; // Retorna o estado anterior sem alterações
      }

      // Se houver mais de um produto, prossegue com a exclusão do produto especificado
      return {
        ...prevState,
        products: prevState.products.filter((_, i) => i !== index),
      };
    });
  };
  // console.log(' fimm ')
  // console.log(data.products)

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12} md={12} alignItems="center" style={{ height: 80 }} sx={{ mt: -4, mb: 2 }}>
          <Box display="flex" alignItems="center">
            {!showTextFase ? (
              <ThreeDotsSpinner />
            ) : (
              <Typewriter
                text="Registre os produtos mais usados na propriedade para automatizar os processos futuros de compra e venda, proporcionando eficiência e praticidade."
                variant={'subtitle1'}
                color={'#2e7d32'}
              />
            )}
          </Box>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Input sx={{ mr: 1 }} />
                Informações do produto simplificado
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid container spacing={1}>
          {data.products.map(
            (
              produto,
              index // Itera sobre os dados fornecidos
            ) => (
              <Grid item xs={12} sm={12} key={'index'}>
                {/* Informações gerais */}
                <AccordionSummary key={`as_${index}`} expandIcon={<ExpandMore />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`}>
                  <CategoryIcon />
                  <Box sx={{ ml: 1, display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}>
                        Produto {index + 1}:{' '}
                        {/* {(produto.name == '' || produto.id_unit == '' || produto.qnt_disponivel < 1) && (
                          <span style={{ color: '#2e7d32' }}>preencha os campos com asterisco* </span>
                        )} */}
                      </Typography>
                    </Stack>
                  </Box>
                </AccordionSummary>
                <Accordion key={`ac_${index}`}>
                  <AccordionDetails key={`ad_${index}`}>
                    <Grid container spacing={1} sx={{ mb: 1, mt: 2 }}>
                      <Grid item xs={1} sm={1}>
                        {/* <TextField
                          required
                          focused
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          id="stock_quanty"
                          name="stock_quanty"
                          label={'Quant.'}
                          fullWidth
                          autoComplete="stock_quanty"
                          variant="standard"
                          color="success"
                          value={produto.stock_quanty}
                          onChange={(e) => handleChange(index, 'stock_quanty', e.target.value)}
                          FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                          helperText={'Quantidade'}
                          //onBlur={(e) => handleChange(e, true)}
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       <IconButton>
                          //         <Assignment />
                          //       </IconButton>
                          //     </InputAdornment>
                          //   ),
                          // }}
                        /> */}
                        <GenericValueField
                          handleChange={(e) => handleChange(index, 'stock_quanty', e.target.value)}
                          options={{
                            id: 'stock_quanty',
                            name: 'stock_quanty',
                            label: <span style={{ color: '#2e7d32' }}>Quant.</span>,
                            value: produto.stock_quanty,
                            variant: 'standard',
                            height: '35px',
                            helperText: <span style={{ color: '#2e7d32' }}>Quantidade</span>,
                            endAdornment: true,
                            prefix: '',
                          }}
                        />
                      </Grid>
                      {/* NOME */}
                      <Grid item xs={2.5} sm={2.5}>
                        <TextField
                          required
                          focused
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          id="name"
                          name="name"
                          label={'Nome do produto'}
                          fullWidth
                          autoComplete="name"
                          variant="standard"
                          color="success"
                          value={produto.name}
                          onChange={(e) => handleChange(index, 'name', e.target.value)}
                          FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                          helperText={'Mínimo de 3 caracteres'}
                          // onBlur={(e) => handleChange(index, 'name', e.target.value)}
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       <IconButton>
                          //         <Assignment />
                          //       </IconButton>
                          //     </InputAdornment>
                          //   ),
                          // }}
                          // error={!data.name || data.name.length < 5}
                          //  / helperText={'* Mínimo de 3 caracteres'}
                        />
                      </Grid>
                      {/* UNIDADE */}
                      <Grid item xs={2} md={2}>
                        <Autocomplete
                          onChange={(event, newValue) => handleUnitChange(index, newValue)}
                          id={`id_unit_${index}`}
                          options={unit}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={unit.find((option) => option.id === produto.id_unit) || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              focused
                              label="Unidade de estoque"
                              variant="standard"
                              color="success"
                              InputLabelProps={{ style: { color: '#2e7d32' } }}
                              FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                              helperText={unit.length === 0 ? 'Erro de conexão com o servidor. Não foi possível exibir as opções de unidade.' : `unidade de medida`}
                              fullWidth
                            />
                          )}
                          ListboxProps={{ style: { maxHeight: 225 } }}
                          noOptionsText="Sem resultados"
                        />
                      </Grid>
                      <Grid item xs={1.5} md={1.5}>
                        {/* <CurrencyInputReal
                          value={produto.stock_cost}
                          label="Valor Unitário do Produto"
                          name="stock_cost"
                          id={`valor_produto_${index}`}
                          onChange={(e) => handleChange(index, 'stock_cost', e.target.value)}
                          helperText={`Defina o valor do produto`}
                          color="success"
                          variant="standard"
                        /> */}
                        <GenericValueField
                          handleChange={(e) => handleChange(index, 'stock_cost', e.target.value)}
                          options={{
                            // required: true,
                            id: 'valor_produto_' + index,
                            name: 'stock_cost',
                            label: <span style={{ color: '#2e7d32' }}>Valor Unitário</span>,
                            value: produto.stock_cost,
                            variant: 'standard',
                            height: '35px',
                            helperText: <span style={{ color: '#2e7d32' }}>Defina o valor do produto</span>,
                            endAdornment: true,
                            prefix: ' (R$) ',
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <GenericValueField
                          handleChange={() => {}}
                          options={{
                            id: 'stock_total_' + index,
                            name: 'stock_total',
                            label: <span style={{ color: '#2e7d32' }}>Valor Total</span>,
                            value: (produto?.stock_cost * produto?.stock_quanty).toFixed(2),
                            variant: 'standard',
                            height: '35px',
                            helperText: <span style={{ color: '#2e7d32' }}>Valor total do produto</span>,
                            endAdornment: true,
                            prefix: ' (R$) ',
                            readOnly: true, // Impede a edição direta
                            disabled: true,
                          }}
                        />
                      </Grid>

                      {/* VALOR DO PRODUTO */}

                      {/* CATEGORIA */}
                      <Grid item xs={2.5} md={2.5}>
                        <Autocomplete
                          key={index}
                          name="category_name"
                          onChange={(event, newValue) => {
                            if (newValue && newValue.id === 'new') {
                              // Abre o diálogo para adicionar uma nova categoria
                              toggleDialog(true);
                            } else {
                              // Atualiza a categoria do produto específico
                              const newProducts = [...data.products];
                              //newProducts[index] = { ...newProducts[index], category: newValue || { id: 33, name: 'Outros' } }
                              newProducts[index] = { ...newProducts[index], category: newValue };
                              obj_fn.setData({ ...data, products: newProducts });
                            }
                          }}
                          //onChange={handleCategoryChange}
                          disableCloseOnSelect={false}
                          filterSelectedOptions
                          color="success"
                          id={produto.id}
                          // value={selectedValue} // Certifique-se de que selectedValue é atualizado corretamente
                          value={produto.category}
                          multiple={false}
                          options={categoryWithNewOption}
                          ListboxProps={{ style: { maxHeight: 225 } }}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // isOptionEqualToValue={(option, val) => option.id === val.id || (data.id_category && data.id_category?.id == option.id)}
                          // value={data.id_category}
                          renderOption={(props, option, { selected }) => (
                            // <li {...props}>
                            //   <Adjust color="success" sx={{ mr: 1, width: '15px' }} /> {option.name}
                            // </li>
                            <li
                              {...props}
                              style={
                                option.id === 'new'
                                  ? {
                                      backgroundColor: '#f0f0f0',
                                      color: '#007bff',
                                      fontWeight: 'bold',
                                    }
                                  : {}
                              }
                            >
                              {option.id === 'new' ? (
                                <>
                                  <AddCircleOutlineIcon
                                    style={{
                                      color: '#2e7d32',
                                      marginRight: '8px',
                                      fontSize: '15px',
                                    }}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    component="div"
                                    style={{
                                      color: '#2e7d32',
                                    }}
                                  >
                                    {option.name}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                                  {option.name}
                                </>
                              )}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              focused
                              variant={'standard'}
                              label={'Categoria'}
                              color="success"
                              InputLabelProps={{ style: { color: '#2e7d32' } }}
                              // error={data.id_category === 0}
                              FormHelperTextProps={{ style: { color: '#2e7d32' } }}
                              helperText={categoryWithNewOption.length <= 1 ? 'Erro de conexão com o servidor. Não foi possível exibir as opções de categoria.' : 'Selecione a categoria'}
                              fullWidth
                            />
                          )}
                          noOptionsText="Sem resultados" // Texto para exibição quando não houver opções válidas
                        />
                      </Grid>
                      <Grid item xs={0.5} sm={0.5}>
                        {/* <IconButton variant="contained" color="error" onClick={() => handleDeleteContact(index)} > */}
                        <IconButton variant="contained" disabled={data.products.length <= 1 ? true : false} color="error" onClick={() => handleDeleteProducts(index)}>
                          <DeleteForever />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          )}
          {/* fim map*/}

          <Button variant="contained" disabled={error} color="success" sx={{ ml: 2, mt: 1, fontSize: '12px' }} onClick={handleAddProduct}>
            Adicionar Outro Produto
          </Button>
        </Grid>
        {!error ? (
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ mt: 2, ml: 2 }}
            style={{
              color: '#2e7d32',
            }}
          >
            Tudo ok para ser enviado! Clique em continuar.
          </Typography>
        ) : (
          <>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ mt: 2, ml: 1 }}
                  style={{
                    color: '#2e7d32',
                  }}
                >
                  O armazenamento dos produtos se encontraram no
                </Typography>
              </Grid> */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ mt: 1, ml: 1 }}
                  style={{
                    color: '#2e7d32',
                  }}
                >
                  Por favor, preencha todos os campos requeridos(*)
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <FooterModalControlled data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} back1={true} />
      <NewSupplierDialog usestore={obj_fn.usestore} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} toggleDialog={toggleDialog} handleCategoryChange={handleCategoryChange} />
    </>
  );
}

function NewSupplierDialog({ usestore, dialogOpen, setDialogOpen, toggleDialog, handleCategoryChange }) {
  // var userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  // const property_id = localStorage.getItem('Property') || 1

  const { aux_add, setAuxAdd } = React.useContext(Context);

  var id_farm = localStorage.getItem('Property') || 1;

  const [data, setData] = useState({
    name: '',
    icon: '',
    description: 'todos',
    id_account: 71,
  });

  const handleChangeNewCategory = (e) => {
    // console.log(handleChangeNewCategory)
    // console.log(e.target)
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
      icon: `${value}.png`,
    }));
    // handleChange()
  };

  const handleSubmit = async () => {
    // console.log('handleSubmit')
    //let formOk = validate() || false
    let formOk = true;
    // console.log(data)
    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      // console.log(data)
      const res = await api
        .post('/productCategory/category', data, header)
        .then((response) => {
          // console.log('response.data')
          // console.log(response.data)
          if (response.data.isSuccess) {
            const newItemCategory = {
              id: response.data._value[1].insertId,
              name: data.name,
            };

            toast.success('Categoria cadastrada com sucesso!');
            handleCategoryChange(true, newItemCategory);
            usestore.reposProducts.fetchCategory({ id_property: id_farm }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                setDialogOpen(false);
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar produto, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  // console.log(data)

  const content = () => (
    <Box
      sx={{ width: 300 }}
      role="presentation"
      // onClick={() => toggleDialog(false)}
      //onKeyDown={() => toggleDialog(false)}
    >
      <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 }, m: 2 }}>
          <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Cadastro rápido:</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0, width: 250 }}>
            {/* Informações gerais */}
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <TextField
                  // required
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="name"
                  name="name"
                  label={'Incluir nova categoria'}
                  fullWidth
                  autoComplete="name"
                  variant="standard"
                  color="success"
                  value={data.name}
                  onChange={handleChangeNewCategory}
                  onBlur={(e) => handleChangeNewCategory(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // error={!data.name || data.name.length < 5}
                  //helperText={'* Mínimo de 5 caracteres'}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                  color="success"
                  sx={{ mr: 1 }}
                >
                  Cancelar
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={dialogOpen}
      onClose={() => toggleDialog(false)}
      sx={{ zIndex: 1400 }} // Ajuste do zIndex para sobrepor Dialog
    >
      {content()}
    </Drawer>
  );
}
