import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState, useEffect } from 'react';
import useStore from '../../../../../store/useStore';

export default function FarmSelect({ formik, setFarmError }) {
    const { values } = formik;
    const usestore = useStore();
    const reposProperty = usestore.reposProperty.propertyRepos;
    const listProperty = usestore.reposProperty.listProperty;

    //1 - inicializar o value
    const [farm, setValueFarm] = useState( (values.farm && values.farm.length > 0 ? values.farm : [reposProperty] ) );
    values.farm = farm;
    var farms_id = farm.map((option) => option.id);
    values.farm_id = farms_id.join();

    useEffect(() => {
        //console.log(value);
    }, [farm]);

    const handleChange=(event, newValue)=>{
        if (!newValue) {
            return;
        }
        event.preventDefault();
        setValueFarm(newValue);
        var farms_id = newValue.map((option) => option.id);
        values.farm_id = farms_id.join();
        if (farms_id.length == 0) {
            formik.errors.farm = "Propriedade é um campo obrigatório";
            setFarmError(formik.errors.farm);
        } else if (farms_id.length > 0 && formik.errors.farm) {
            delete formik.errors.farm;
            setFarmError(false);
        }
    }

    /*return (
        <Autocomplete
            disablePortal
            disableClearable
            freeSolo
            id="combo-box-demo"
            value={value}
            options={options}
            onChange={(event, newValue) => {
                console.log(newValue)
                setValueFarm(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField
                {...params}
                variant="outlined"
                label="Propriedade"
                color="success"
                focused
            />}
            noOptionsText="Sem resultados"
        />
    );*/
    return (
        <Autocomplete
            multiple
            id="combo-box-multiple-farm"
            name="farm"
            options={listProperty}
            getOptionLabel={(option) => option.label}
            value={ values.farm }
            isOptionEqualToValue={(option, value) => option.label == value.label}
            filterSelectedOptions
            onChange = {(event, newFarm) => handleChange(event, newFarm)}
            renderInput={(params) => <TextField
                {...params}
                variant="outlined"
                label="Propriedade"
                color="success"
                focused
                placeholder="Fazendas"
                onChange = {(event, newFarm) => handleChange(event, newFarm)}
            />}
            noOptionsText="Sem resultados"
        />
    );
}
// https://mui.com/material-ui/react-autocomplete/