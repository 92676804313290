import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field, useFormikContext } from "formik";
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../../../components/Loader/Loading'
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfileAccordion from './ProfileAccordion';
import RolePermission from '../RolePermission';


export default function ProfileSelectRadioTeam({ editUser, id_role }) {

  console.log("xxxxProfileSelectRadioTeamxxxx loading")

  const [isLoading, setIsLoading] = useState(false)

  //const [state, setState] = useState(editUser.id_role);
  const [state, setState] = useState(id_role);
  //const [role, setRole] = useState("");

  localStorage.setItem("profileCurrentRadio", JSON.stringify(state));

  const handleRadioChange = (event, state) => {
    console.log(state)
    setState(state);
  }

  editUser.id_role = state
  console.log(editUser)
  console.log(state)
  const role = RolePermission(parseInt(state))
  console.log(role)


  return (
    <>
      {isLoading && role ? (<Loading />) :
        <>
          <FormControl>
            <FormLabel color="success" id="demo-radio-buttons-group-label"><strong>Edite o perfil</strong></FormLabel>
            <RadioGroup
              onChange={handleRadioChange}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={id_role}
              name="radio-buttons-group"
              sx={{
                //"& .MuiRadio-root": { color: "black" },
                "& .MuiRadio-root.Mui-checked": { color: "green" },
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Proprietário" name="roles" />
              <FormControlLabel value="2" control={<Radio />} label="Gerente" name="roles" />
              <FormControlLabel value="3" control={<Radio />} label="Financeiro" name="roles" />
              <FormControlLabel value="4" control={<Radio />} label="Operacional" name="roles" />
            </RadioGroup>
          </FormControl>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1" >
                  Permissões Personalizadas: <strong>{role}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProfileAccordion editUser={editUser} state={state} />
              </AccordionDetails>
            </Accordion>

          </div>
        </>
      }
    </>
  );
}