import React from 'react';
import NavSide from '../../components/Nav/NavSide';
import NavBotton from '../../components/Nav/NavBotton';
import Footer from '../../components/Footer/Footer';
import DocumentManagementcontent from './DocumentManagementcontent';

export default function DocumentManagement() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton tipo={'user'} />
      <DocumentManagementcontent />
      <Footer />
    </>
  );
}
