import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import SelectLivestock from '../../livestock/components/addLivestock/SelectLivestock';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { NavLink, useParams } from 'react-router-dom';
import { PendingActions } from '@mui/icons-material';
import StepIcon from '../../../components/Icons/tutorial/StepIcon';
import Typewriter from '../../../components/Typewriter/Typewriter';

export default function StartProjectCreation({ type }) {
  const [open, setOpen] = React.useState(true);
  const { idFarm, id } = useParams();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="Fazenda Premium Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Projeto Pecuário ({type})
              </Typography>
              <Typography gutterBottom sx={{ mt: 0, mb: 2 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter
                  tam={60}
                  text="A Gestão Pecuária consiste em otimizar a produção animal por meio do planejamento estratégico, monitoramento das atividades, controle de custos e implementação de melhores práticas para garantir a sustentabilidade e a eficiência da propriedade."
                />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} sx={{ mt: 1 }} style={{ textAlign: 'center' }}>
              <img
                src="/dist/image/gestaopecuaria_criacao.png"
                alt="Gestão Pecuária"
                style={{
                  width: 'auto', // Define a largura da imagem
                  height: '300px', // Mantém a proporção da imagem
                  objectFit: 'contain', // Ajusta a imagem para caber no espaço
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 2 }}>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  <strong>Vamos registrar as atividades de campo para assegurar um acompanhamento eficiente do processo de produção.</strong>
                </Typography>
                {/* <Typography paragraph variant="subtitile2" fontWeight={300}>
                  Com um projeto pecuário criado é a hora criar e gerenciar as atividades de campo e gerenciar os custos da sua criação!
                </Typography> */}
              </Box>
            </Grid>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 0 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Clique a seguir no <strong style={{ color: '#2e7d32' }}>botão verde "Atividades"</strong> para registrar as atividade de campo...
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
              <NavLink to={{ pathname: '/activities/' + idFarm + '/' + id }} className="nav-link">
                <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" size="medium" color="success" startIcon={<PendingActions />}>
                  Atividades
                </Button>
              </NavLink>
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                <span style={{ color: '#a82223' }}>Se precisar de ajuda, clique no botão</span>{' '}
                <span style={{ color: '#2e7d32' }}>
                  'Passo a Passo' <StepIcon color={'#2e7d32'} />
                </span>{' '}
                <span style={{ color: '#a82223' }}> para obter orientações.</span>
              </Typography>
            </Box>
          </Grid>
          {/* <DialogContentText>Ao criar um projeto pecuário, incia-se a gestão pecuária.</DialogContentText> */}
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
            }}
            color="success"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
