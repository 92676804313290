import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, } from '@mui/material';
import { ExpandMore, Handyman } from '@mui/icons-material';
import FooterModal from '../../../../components/Footer/FooterModal';
import CardProductPatrimony from '../products/CardProductPatrimony';
import CardServicePatrimony from '../service/CardServicePatrimony';
import MaintenanceCostCalculator from './MaintenanceCostCalculator';

export default function FormMaintenancenStep2({ data, obj_fn }) {
  // console.log('FormMaintenancenStep2');

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    if ((!data.products || !data.products?.length) && (!data.services || !data.services?.length)) {
      iserror = true;
    }
    data.products?.map((prod) => {
      if (!prod.product?.id) {
        iserror = true;
      } else if (!prod?.location_selected.id) {
        iserror = true;
      } else if (!prod?.location_selected.quant_utilizada) {
        iserror = true;
      } else if (!prod?.location_selected.value) {
        iserror = true;
      }
      return prod
    })
    data.services?.map((serv) => {
      if (!serv.category && !serv.subcategory) {
        iserror = true;
      }
      if (!serv.value) {
        iserror = true;
      }
      return serv
    })

    return iserror;
  };

  const returnError = () => {
    return false;
  };

  // form sem estoque:
  const [noConsumed, setNoConsumed] = useState( data.services?.length > 0 ? 'yes' : 'no');

  const handleNoConsumedChange = (event) => {
    setNoConsumed(event.target.value);
  };

  // form estoque: lançamento com consumo no estoque
  const [consumed, setConsumed] = useState( data.products?.length > 0 ? 'yes' : 'no');

  const handleConsumedChange = (event) => {
    setConsumed(event.target.value);
  };

  const [expanded, setExpanded] = React.useState(true);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 4, mb: 10 }}>
        <Grid item xs={12}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Box sx={{ ml: 2, display: 'flex' }}>
              <Handyman />
              <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                <Typography fontWeight={700}>Manutenção: {data.type}</Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Valor total da manutenção:</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <MaintenanceCostCalculator data={data} obj_fn={obj_fn} />
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Box sx={{ ml: 2, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ ml: 1 }}>
                <Typography fontWeight={400}>Realizou alguma manutenção sem utilizar o estoque?</Typography>
                <RadioGroup aria-label="noConsumed" name="noConsumed" value={noConsumed} onChange={handleNoConsumedChange} row>
                  <FormControlLabel value="yes" control={<Radio color="success" />} label="Sim" />
                  <FormControlLabel value="no" control={<Radio color="success" />} label="Não" />
                </RadioGroup>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Box sx={{ ml: 2, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ ml: 1 }}>
                <Typography fontWeight={400}>Consumiu algum insumo ou produto do estoque?</Typography>
                <RadioGroup aria-label="consumed" name="consumed" value={consumed} onChange={handleConsumedChange} row>
                  <FormControlLabel value="yes" control={<Radio color="success" />} label="Sim" />
                  <FormControlLabel value="no" control={<Radio color="success" />} label="Não" />
                </RadioGroup>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        {noConsumed === 'yes' && (
          <>
            <Grid item xs={12} sm={12}>
              <Accordion expanded={expanded} onChange={handleExpansion}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  Informações do lançamento da manutenção sem o uso do estoque
                </AccordionSummary>
                <AccordionDetails>
                  <CardServicePatrimony data={data} obj_fn={obj_fn} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}

        {consumed === 'yes' && (
          <>
            <Grid item xs={12} sm={12}>
              <Accordion expanded={expanded} onChange={handleExpansion}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  Informações do lançamento da manutenção com o uso do estoque
                </AccordionSummary>
                <AccordionDetails>
                  <CardProductPatrimony data={data} obj_fn={obj_fn} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
