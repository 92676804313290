import React, { useState } from 'react';
import { Accordion, Box, Card, CardMedia, Grid, Stack, TextField, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';
import RoomIcon from '@mui/icons-material/Room';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import ButtonMapViewMonitoring from './components/ButtonMapViewMonitoring';
// import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
// import MapFormObservationDialog from '../../../../Messenger/DialogMessenger/forms/MapFormObservationDialog';
// import ImageComponentMonitoring from './components/ImageComponentMonitoring';
// import { grey } from '@mui/material/colors';
// import WallpaperIcon from '@mui/icons-material/Wallpaper';

const samplingOptions = [
  { label: 'Quantidade média', value: 'averageQuantity' },
  { label: 'Incidência(%)', value: 'incidence' },
  { label: 'Escala de 0 a 4', value: 'scale' },
];

export default function FormMonitoringStep1({ data, obj_fn }) {
  // console.log(data);
  const [sampling, setSampling] = useState();
  // const para validar o button continuar
  const isError = () => {
    var valid = false;
    // if (!data.monitoringName) {
    //   valid = true
    // }
    if (!data.areas.length) {
      valid = true;
    }
    data.areas?.map((area) => {
      if (!area.monitoringName) {
        valid = true;
      }
    });

    // if (!data.areas.length) {
    //   valid = true
    // } else {
    //   data.areas?.map((area) => {
    //     if (!area.product?.location_selected?.quant_utilizada) {
    //       valid = true
    //     } else if (area.product?.location_selected?.stock_quanty_array < area.product?.location_selected?.quant_utilizada) {
    //       valid = true
    //     }
    //   })
    // }

    return valid;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  const opt_areas = {
    multiple: true,
    name: 'areas',
    label: 'Áreas da Safra',
    values: optionsAreas,
    field: 'areas',
    variant: 'standard',
    helperText: 'Selecione a(s) área(s) para o monitoramento',
    listHeight: 225,
  };
  // console.log(opt_areas);

  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.product) {
          area.product = null;
        }
        return area;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handleChangeInput = (e, area) => {
    // console.log('handleChangeInput');
    //console.log(e.target.value, area)
    const usedArea = e.target.value ? parseFloat(e.target.value) : '';

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, usedArea };
        }
        return area_prev;
      }),
    }));
  };

  const handleInputChange = (field, value, areaId) => {
    // Aqui você pode ajustar como quer atualizar os estados baseado no nome do campo e valor
    // Suponho que você tenha um estado chamado `data` que armazena todos os valores do formulário
    // obj_fn.setData((prevData) => ({
    //   ...prevData,
    //   [field]: value,
    // }))
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, [field]: value } : area)),
    }));
  };

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  // }

  // const filters_prods = [
  //   ['description', 'agricola'],
  //   ['description', 'todos'],
  // ];

  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);

  // console.log('data');
  // console.log(data);
  // console.log(data.areas);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) de Monitoramento</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.areas} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sx={{ mt: -2 }}>
                <Card key={'C1_' + area.id} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, padding: 1 }} id="testando_card">
                  <Grid item xs={12} md={4}>
                    {/* Área da imagem do mapa */}
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                      <Grid item xs={10}>
                        <CardMedia key={'CM_' + area.id} component="img" sx={{ height: { xs: '100%', md: 100 } }} image={area.url} alt={area.name} />
                      </Grid>
                      {/* Área dos textos e inputs */}
                      <Grid item key={'G2_' + area.id} xs={12} sx={{ ml: 1.5 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          {area.name}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          <i className="fa fa-map text-success">
                            {' '}
                            {area.area} ({area.measure})
                          </i>
                        </Typography>
                        <TextField
                          sx={{ marginRight: 2 }}
                          id={area.id + ''}
                          name={area.name}
                          label={'Área utilizada:'}
                          variant="standard"
                          size="small"
                          value={area.usedArea}
                          onChange={(e) => handleChangeInput(e, area)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Área dos campos de texto */}
                  <Grid item key={'G3_' + area.id} xs={12} md={8} sx={{ mt: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id={area.id + ''}
                          variant="standard"
                          label="Insetos/Doenças/Erva Daninha"
                          value={area.monitoringName}
                          onChange={(e) => handleInputChange('monitoringName', e.target.value, area.id)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          color="success"
                          helperText="Defina o nome da doença ou praga."
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Accordion>
                          <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item>
                              <RoomIcon sx={{ color: 'action.active', ml: 2 }} />
                            </Grid>
                            <Grid item>
                              <Typography sx={{ ml: -1 }}>Localização</Typography>
                            </Grid>
                            <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                              <ButtonMapViewMonitoring data={data} setData={obj_fn.setData} setImage={setImage} areaId={area.id} inputId={`icon-button-file-${area.id}`} />
                            </Grid>
                          </Grid>
                        </Accordion>
                      </Grid>
                      {area.areaTranferImage && (
                        <Grid item xs={12}>
                          <Accordion>
                            <Grid container spacing={2} sx={{ mt: 0 }}>
                              <Grid item>
                                <RoomIcon sx={{ color: 'action.active', ml: 2 }} />
                              </Grid>
                              <Grid item>
                                <Typography sx={{ ml: -1 }}>Monitoramento</Typography>
                              </Grid>
                              <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                                <CardMedia component="img" sx={{ width: 145 }} image={area.areaTranferImage} alt="Área" />
                              </Grid>
                            </Grid>
                          </Accordion>
                        </Grid>
                      )}

                      {/* <Grid item xs={12} key={area.id}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                          <Grid>
                            <WallpaperIcon sx={{ color: 'action.active', ml: 2 }} />
                          </Grid>
                          <Grid item>
                            <Typography sx={{ ml: -1 }}>Imagem</Typography>
                          </Grid>
                          <Grid item key={area.id} sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                            <ImageComponentMonitoring setData={obj_fn.setData} areaId={area.id} name={area.name} inputId={`icon-button-file-${area.id}`} />
                          </Grid>
                          {area.imagePhoto && (
                            <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                              <CardMedia component="img" sx={{ width: 145 }} image={area.imagePhoto} alt="Área" />
                            </Grid>
                          )}
                        </Grid>
                      </Grid> */}

                      {/* {image && (
                        <Card sx={{ maxWidth: 345, mb: 2, mt: 1 }} disabled>
                          <CardActionArea>
                            <CardMedia component="img" height="140" image={image} alt="Área" />
                           {loading ? (
                          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                            <Loading />
                          </Box>
                        ) : (
                          <CardMedia component="img" height="140" image={image} alt="Área" />
                        )} 
                             <CardContent>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item>
                                <Stack spacing={0.5}>
                                  <Typography variant="body2" color="text.secondary">
                                    <LocationOn sx={{ color: grey[500] }} /> name
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item>
                                <img src={data.lote.icon} alt="Icon" style={{ height: '32px', marginLeft: '8px' }} />
                              </Grid>
                            </Grid>
                          </CardContent> 
                          </CardActionArea>
                        </Card>
                      )} */}
                      {/* <Grid item xs={12} sm={6}>
                        <TextField label="Tipo de Esacala" color="success" select fullWidth value={data.typeScale} onChange={(e) => handleInputChange('typeScale', e.target.value)} variant="outlined">
                          {samplingOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {data.typeScale === 'averageQuantity' && (
                          <TextField
                            fullWidth
                            variant="standard"
                            label="Nível de controle"
                            value={data.quantity}
                            onChange={(e) => handleInputChange('valueAverageQuantity', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            color="success"
                            helperText="Digite a quantidade média de encontrada por medição"
                          />
                        )}
                        {data.typeScale === 'incidence' && (
                          <TextField
                            fullWidth
                            variant="standard"
                            label="Nível de controle"
                            value={data.quantity}
                            onChange={(e) => handleInputChange('valueIncidence', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            color="success"
                            helperText="Incidência em porcentagem"
                            type="number"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        )}
                        {data.typeScale === 'scale' && (
                          <TextField
                            select
                            fullWidth
                            variant="standard"
                            label="Nível de controle"
                            value={data.quantity}
                            onChange={(e) => handleInputChange('valueScale', e.target.value)}
                            helperText="Escala"
                            color="success"
                          >
                            <MenuItem value="0">0 - Ausente</MenuItem>
                            <MenuItem value="1">1 - Baixo</MenuItem>
                            <MenuItem value="2">2 - Médio</MenuItem>
                            <MenuItem value="3">3 - Alto</MenuItem>
                            <MenuItem value="4">4 - Muito alto</MenuItem>
                          </TextField>
                        )}
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 3 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o plantio"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid> */}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
