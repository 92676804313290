import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import ChatContentModal from "./ChatContentModal";
import { Stack, Typography } from "@mui/material";

{
  /* <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem> */
}

export default function ChatPlanModal({ open, onClose }) {
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const handleClose = () => {
    onClose();
  };
  //
  //<Grid item xs={12} sm={12} className="d-lg-none">Esconde em telas maiores que lg</Grid>
  //<Grid item className="d-none d-lg-block">Esconde em telas menores que lg</Grid>
  return (
    <React.Fragment>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle>
          <div className="d-lg-none">
            <Box sx={{ display: "flex", alignItems: "center", ml: 1 }} >
              <img src="/dist/image/logo192.png" alt="" width="40" style={{ marginRight: "4px" }} />
              <Stack spacing={0.5} sx={{ ml: 1, mt: -0.5 }}>
                <Typography fontWeight={700}>Inteligência</Typography>
                <Typography variant="body2" color="text.secondary">
                  Fazenda Premium teste
                </Typography>
              </Stack>
            </Box>
          </div>
          <div className="d-none d-lg-block">

            <Box sx={{ p: 2, display: 'flex', ml: -1 }} >
              <img src="/dist/image/logo192.png" alt="" width="50" style={{ marginRight: "2px" }} />
              <Stack spacing={0.5} sx={{ ml: 1 }}>
                <Typography fontWeight={700}>Inteligência Fazenda Premium</Typography>
                <Typography variant="body2" color="text.secondary">
                  CPT Softwares
                </Typography>
              </Stack>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>You can set my maximum width and whether to adapt or not.</DialogContentText> */}
          <ChatContentModal />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
