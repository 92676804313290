import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import RedirectProjectList from './RedirectProjectList';
import { CardMedia } from '@mui/material';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';

const steps = [1, 2];

export default function StepperArea({ numberArea }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log(steps[activeStep]);
  //area-3.png
  //text={`Organizado a área de produção em ${numberArea} área(s), está na hora de criar as safras ou as criações para depois inserirmos as culturas ou animais nesta área.`}
  return (
    <Box sx={{ flexGrow: 1 }}>
      {steps[activeStep] === 1 && (
        <>
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            {/* <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                        </Paper> */}
            <Box sx={{ height: 450, maxWidth: 400, width: '100%', p: 1 }}>
              <div className="d-lg-none">
                <Box>
                  <Typography gutterBottom sx={{ mt: 0, mb: 3, maxWidth: 400 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={40} text={`Aqui você poderá definir todas as sua áreas de produção. Clique em fechar, para criar novas áreas,`} />
                  </Typography>
                </Box>
              </div>
              <div className="d-none d-lg-block">
                <Box>
                  <Typography gutterBottom sx={{ mt: 1, mb: 3, maxWidth: 400 }} fontWeight={500} style={{ color: '#2e7d32', width: 550 }}>
                    <Typewriter tam={40} text={`Aqui você poderá definir todas as sua áreas de produção. Clique em fechar, para criar as áreas,`} />
                  </Typography>
                </Box>
              </div>
              <CardMedia component="img" sx={{ width: '100%' }} image={'/dist/image/area-3.png'} alt="Live from space album cover" />
              <Typography sx={{ mt: 1, mb: 3 }}>ou após definir a quantidade de área desejada é hora de criar o projeto para a sua criação ou safra. Clique em próximo.</Typography>
            </Box>
          </Box>
        </>
      )}
      {steps[activeStep] === 2 && (
        <>
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <RedirectProjectList />
          </Box>
        </>
      )}

      {/* <Typography>{steps[activeStep].label}</Typography>
            </Paper>
            <Box sx={{ height: 255, width: '100%', p: 2 }}>
                {steps[activeStep].description}
            </Box> */}

      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
            Próximo
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}
