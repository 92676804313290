import React from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Checkmark } from 'react-checkmark'
import { AppBar, Box, Button, ButtonBase, Container, Grid, List, ListItem, ListItemText, Paper, Stack, Toolbar, Typography } from '@mui/material'
import Typewriter from '../../../../../components/Typewriter/Typewriter'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function SuccessMarketing({ handleClose, data }) {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    handleClose()
    //navigate(`/livestock/${data.id_property}/${data.id_livestock}`)
  }

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Atividade Atualizada!
            </Typography>
            <div className="d-lg-none">
              <Box sx={{ flexGrow: 1 }}>
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={130} text="Acesse a atividade de marketing atualizada." />
                </Typography>
              </Box>
            </div>
            <div className="d-none d-lg-block">
              <Box sx={{ flexGrow: 1, mb: 2 }}>
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={50} text="Acesse a atividade de marketing atualizada." />
                </Typography>
              </Box>
            </div>
            <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => handleButtonClick()} className={classes.button}>
              <Stack direction="column" alignItems="center">
                <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" alt="image" class="img-fluid" />
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  Fechar
                </Typography>
              </Stack>
            </ButtonBase>
          </div>
          {/* <TutorialNextStep /> */}
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              Fechar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
