import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from '../pages/main/Main';
import Livestock from '../pages/livestock/Livestock';
import LivestockList from '../pages/livestock-list/LivestockList';
import Maps from '../pages/maps/Maps';
import Areas from '../pages/areas/Areas';
// import LoagingLinear from '../components/Loader/LoagingLinear';
// import CircularProgressWithLabel from '../components/Loader/CircularProgressWithLabel';
// import LinearWithValueLabel from '../components/Loader/LinearProgressWithLabel';
import MapsCreations from '../pages/mapsCreations/MapsCreations';
import Suporte from '../pages/suporte/Suporte';
import { Context } from '../components/Context/AuthContext';
import Messenger from '../pages/Messenger/Messenger';
import ControlPanelCreations from '../pages/ControlPanelCreations/ControlPanelCreations';
import { Box, Container, LinearProgress, Paper } from '@mui/material';
import SpecificArea from '../pages/specificArea/SpecificArea';
import ControlPanelSpecificArea from '../pages/ControlPanelSpecificArea/ControlPanelSpecificArea';
import LivestockListSpecificArea from '../pages/livestockListSpecificArea/LivestockListSpecificArea';
import Financial from '../pages/financial/Financial';
import LivestockCreations from '../pages/livestockCreations/LivestockCreations';
// import MessengerSpecificArea from '../pages/MessengerSpecificArea/MessengerSpecificArea';
// import MessengerCreations from '../pages/MessengerCreations/MessengerCreations';
import AreasMaps from '../pages/areasMaps/AreasMaps';
import MapsAreasDraw from '../pages/mapsAreasDraw/MapsAreasDraw';
import AreaMap from '../pages/areaMap/AreaMap';
import ProductionCost from '../pages/productionCost/ProductionCost';
// import Production from '../pages/production/Production';
import Team from '../pages/team/Team';
import Inventory from '../pages/inventory/Inventory';
import Sales from '../pages/sales/Sales';
import Patrimony from '../pages/patrimony/Patrimony';
import Harvest from '../pages/harvest/Harvest';
import HarvestList from '../pages/harvest-list/HarvestList';
import ControlPanelHarvest from '../pages/ControlPanelHarvest/ControlPanelHarvest';
import Profile from '../pages/profile/Profile';
import ImageList from '../pages/image/ImageList';
// import RegisterNewUser from '../pages/register/RegisterNewUser';
// import FromEditUser from '../pages/register/components/FulldialogEditUser';
import Error from '../pages/error/Error';
import IndexExperience from '../pages/indexExperience/IndexExperience';
import Property from '../pages/property/Property';
import Chat from '../pages/chat/Chat';
import ActivitiesCreations from '../pages/activities/ActivitiesCreations';
import Inputs from '../pages/Inputs/Inputs';
import PlanningLivestock from '../pages/planning/PlanningLivestock';
// import Indicators from '../pages/indicators/Indicators';
import Reports from '../pages/reports/Reports';
import ChangePassword from '../pages/changePassword/ChangePassword';
import ForgotPassword from '../pages/main/ForgotPassword/ForgotPassword';
import Signature from '../pages/signature/Signature';
import LoadingOk from './LoadingOk';
import ActivitiesHarvest from '../pages/activitiesHarvest/ActivitiesHarvest';
import PlanningHarvest from '../pages/planningHarvest/PlanningHarvest';
import QuickGuide from '../pages/quickGuide/QuickGuide';
import SettingsUser from '../pages/settingsUser/SettingsUser';

import Person from '../pages/person/Person';
import Products from '../pages/products/Products';
import Buy from '../pages/buy/Buy';
import DocumentManagement from '../pages/documentManagement/DocumentManagement';

function Router() {
  const { authenticated, isLoading, setMenuContext } = useContext(Context);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [link_ativo, setLinkAtivo] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  setTimeout(() => {
    if (!isLoading) {
      setIsLoading2(false);
    } else {
      // garante que os repos foram carregados
      setTimeout(() => {
        setIsLoading2(false);
      }, 2500);
    }
  }, 2500);

  function onAcess(link) {
    // verifica o link ativo e seta: ButtonActive e navMenuActive
    var link_brok = '';
    if (/localhost/.test(link)) {
      link_brok = link.replace(/.*(3000|5000|5001)\/(.*)/, '/$2');
    } else {
      link_brok = link.replace(/.*(br|app)\/(.*)/, '/$2');
    }
    link_brok = link_brok.replace(/\d+/g, 'num');
    const obj_links = {
      '/mapa': ['selectProperty', 'Mapa'],
      '/mapa/num/num': ['selectCreationHarvest', 'Mapa'],
      '/specificarea/num/num': ['selectCreationHarvest', 'Mapa'],
      '/areas/num/num': ['selectAreaMap', 'Mapa'],
      '/': ['selectProperty', 'Central'],
      '/painel': ['selectProperty', 'Central'],
      '/controlpanelharvest/num/num': ['selectCreationHarvest', 'Painel'],
      '/controlpanelcreations/num/num': ['selectCreationHarvest', 'Painel'],
      '/ControlpanelspecificArea/num/num': ['selectAreaMap', 'Painel'],
      '/areasmaps': ['selectProperty', 'Áreas'],
      '/areasmaps/num': ['selectCreationHarvest', 'Áreas'],
      '/areasmaps/num/num': ['selectAreaMap', 'Áreas'],
      '/messenger': ['selectProperty', 'Solicitações'],
      '/messengercreations/num/num': ['selectCreationHarvest', 'Solicitações'],
      '/messengerspecificarea/num/num': ['selectAreaMap', 'Solicitações'],
      '/livestock': ['selectProperty', 'Criações'],
      '/livestock/num/num': ['selectCreationHarvest', 'Animais'],
      '/livestockcreations/num/num': ['selectCreationHarvest', 'Animais'],
      '/livestockspecificarea/num/num': ['selectCreationHarvest', 'Criações'],
      '/harvest': ['selectProperty', 'Safras'],
      '/harvest/num/num': ['selectCreationHarvest', 'Culturas'],
      '/activities/num/num': ['selectCreationHarvest', 'Atividades'],
      '/activitiesHarvest/num/num': ['selectCreationHarvest', 'Atividades'],
      '/team': ['selectProperty', 'Equipe'],
      '/financial': ['selectProperty', 'Financeiro'],
      '/inventory': ['selectProperty', 'Estoque'],
      '/sales': ['selectProperty', 'Venda'],
      '/indicators': ['selectProperty', 'Indicadores'],
      '/reports': ['selectProperty', 'Relatórios'],
      '/patrimony': ['selectProperty', 'Patrimônio'],
      '/resources/num/num': ['selectCreationHarvest', 'Recursos'],
      '/planning/num/num': ['selectCreationHarvest', 'Planejamentos'],
      '/productioncost/num/num': ['selectCreationHarvest', 'Custo Prod.'],
      '/production/num/num': ['selectCreationHarvest', 'Produção'],
      '/profile': ['', 'Meus Dados'],
      '/property': ['', 'Propriedades'],
      '/signature': ['', 'Assinatura'],
      '/image': ['', 'Imagens'],
      '/documentManagement': ['', 'Documentos'],
      '/indexExperience': ['', 'Ind. Experiência'],
      '/changepassword': ['', 'Mudar senha'],
      '/settingsuser': ['', 'Configurações'],
      //"/forgotpassword": '',
      //"/registeruser/edit/num": '',
      //"/registeruser": '',
      //"/mapsareasdraw": '',
      //"/areamap/num": '',
      //"/suport": '',
      //"/chat": '',
      //"/maps-creations/num/num": '',
    };
    if (obj_links[link_brok]) {
      localStorage.setItem('ButtonActive', obj_links[link_brok][1]);
      if (obj_links[link_brok][0] !== '') {
        localStorage.setItem('navMenuActive', obj_links[link_brok][0]);
      }
      if (link_ativo !== window.location.href) {
        setMenuContext(new Date().getTime());
        setLinkAtivo(window.location.href);
      }
      //if (/num/.test(link_brok)) {
      //  var num_link = link.replace(/.*(\d+$)/g, '$1')
      //  setMenuContext(num_link)
      //var aux_creationHarvestRepos = localStorage.getItem("creationHarvestRepos");
      //var creationHarvestRepos = (aux_creationHarvestRepos ? JSON.parse(aux_creationHarvestRepos) : { label: '', "id": 0, "id_property": 0, tipo_repos: ''});
      //if (num_link != creationHarvestRepos.id) {
      //  localStorage.setItem("creationHarvestRepos", JSON.stringify({ label: '', "id": 0, "id_property": 0, tipo_repos: ''}));
      //  setMenuContext(menuContext+1);
      //}
      //}
    }
    //setLinkAtivo(window.location.href);
    window.scrollTo(0, 0);
  }
  if (authenticated) {
    // && (link_ativo != window.location.href)) {
    onAcess(window.location.href);
  }

  //console.log(isLoading)
  //console.log(isLoading2)
  if (isLoading2 !== true) {
    setTimeout(() => {
      setIsLoading3(false);
    }, 1500);
  }
  // {isLoading || isLoading2 === true ? (
  //isLoading3
  return (
    <>
      {isLoading || isLoading2 === true ? (
        <>
          <div>
            <div style={{ marginBottom: '0' }}>{/* <LoagingLinear /> */}</div>

            <Box sx={{ height: '90vh', mt: -10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img style={{ height: 90 }} src="/dist/image/icone-fazenda-premium-start.png" alt="CPT" className="brand-image center animation__fadeIn" />
                    <Box sx={{ mt: 6, fontSize: '1.2rem' }}>
                      CARREGANDO...
                      {/* <CircularProgressWithLabel value={progress} /> */}
                      {/* <LinearWithValueLabel value={progress} /> */}
                    </Box>
                  </Box>
                  <Box sx={{ margin: '0 auto', width: '80%', mt: 2 }}>
                    <LinearProgress color="success" />
                  </Box>
                </Paper>
              </Container>
            </Box>
          </div>
        </>
      ) : isLoading3 ? (
        <LoadingOk />
      ) : (
        <>
          <Routes>
            <Route path="/mapa" element={<Maps />} />
            <Route path="/mapa/:idFarm/:id" element={<Maps />} />
            <Route path="/maps-creations/:idFarm/:id" element={<MapsCreations />} />

            <Route path="/chat" element={<Chat />} />

            <Route index element={<Main />} />
            <Route path="/painel" element={<Main />} />

            <Route path="/messenger" element={<Messenger />} />
            <Route path="/messengercreations/:idFarm/:id" element={<Messenger />} />
            <Route path="/messengerspecificarea/:idFarm/:id" element={<Messenger />} />
            {/* <Route path="/messengercreations/:idFarm/:id" element={<MessengerCreations />} />
          <Route path="/messengerspecificarea/:idFarm/:id" element={<MessengerSpecificArea />} /> */}

            <Route path="/livestock" element={<Livestock />} />
            <Route path="/livestock/:idFarm/:id" element={<LivestockList />} />
            <Route path="/livestockcreations/:idFarm/:id" element={<LivestockCreations />} />
            <Route path="/livestockspecificarea/:idFarm/:id" element={<LivestockListSpecificArea />} />

            <Route path="/harvest" element={<Harvest />} />
            <Route path="/harvest/:idFarm/:id" element={<HarvestList />} />
            <Route path="/controlpanelharvest/:idFarm/:id" element={<ControlPanelHarvest />} />

            <Route path="/activities/:idFarm/:id" element={<ActivitiesCreations />} />
            <Route path="/activitiesHarvest/:idFarm/:id" element={<ActivitiesHarvest />} />

            <Route path="/team" element={<Team />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/productcatalog/" element={<Products />} />
            <Route path="/inventory/" element={<Inventory />} />
            <Route path="/inventory/:tab" element={<Inventory />} />

            <Route path="/buy" element={<Buy />} />
            <Route path="/sales" element={<Sales />} />

            {/* <Route path="/indicators" element={<Indicators />} /> */}
            <Route path="/reports" element={<Reports />} />
            <Route path="/patrimony" element={<Patrimony />} />
            <Route path="/suport" element={<Suporte />} />
            <Route path="/quickGuide" element={<QuickGuide />} />

            <Route path="/planning/:idFarm/:id" element={<PlanningLivestock />} />
            <Route path="/planningHarvest/:idFarm/:id" element={<PlanningHarvest />} />

            <Route path="/ControlpanelspecificArea/:idFarm/:id" element={<ControlPanelSpecificArea />} />
            <Route path="/controlpanelcreations/:idFarm/:id" element={<ControlPanelCreations />} />

            <Route path="/resources/:idFarm/:id" element={<Inputs />} />
            <Route path="/productioncost/:idFarm/:id" element={<ProductionCost />} />
            {/* <Route path="/production/:idFarm/:id" element={<Production />} /> */}

            <Route path="/areas/:idFarm/:id" element={<Areas />} />
            <Route path="/areamap/:id" element={<AreaMap />} />
            <Route path="/areasmaps" element={<AreasMaps />} />
            <Route path="/areasmaps/:id" element={<AreasMaps />} />
            <Route path="/areasmaps/:idFarm/:id" element={<AreasMaps />} />
            <Route path="/mapsareasdraw" element={<MapsAreasDraw />} />
            <Route path="/specificarea/:idFarm/:id" element={<SpecificArea />} />

            {/* navBotton Menu user */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/property" element={<Property />} />
            <Route path="/indexExperience" element={<IndexExperience />} />
            <Route path="/signature" element={<Signature />} />
            <Route path="/image" element={<ImageList />} />
            <Route path="/documentManagement" element={<DocumentManagement />} />

            {/*<Route path="/registeruser" element={<RegisterNewUser />} />*/}
            {/*<Route path="/registeruser/edit/:id" element={<FromEditUser />} />*/}
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/settingsuser" element={<SettingsUser />} />

            <Route path="/error" element={<Error />} />
            <Route path="*" element={<p>Path not resolved</p>} />

            <Route path="/person" element={<Person />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default Router;

//https://blog.logrocket.com/react-router-v6/
//https://reactrouter.com/docs/en/v6/upgrading/reach
