import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, ButtonBase, List, Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import SpaIcon from '@mui/icons-material/Spa'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function TutorialStockAccordion() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClickLivestock = () => {
    navigate('/livestock')
  }
  const handleButtonClickHarvest = () => {
    navigate('/harvest')
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Registro no Catálogo de Produtos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">Fazer o tutorial</Typography>
          <Typography variant="subtitle2">
            O Cadastro de Produtos (Insumos) é uma funcionalidade que permite a inclusão sistemática de todos os produtos ou insumos dentro do sistema de controle de estoque.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Este cadastro detalhado é crucial para o gerenciamento efetivo pois oferece uma visão clara do que está disponível, em que quantidade e em que condições.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mt: 3 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            O que são insumos?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            <strong>Insumos agrícolas e pecuários</strong> são produtos ou elementos fundamentais utilizados na agricultura e na pecuária para promover o crescimento, saúde e produtividade das plantas
            e animais.
          </Typography>
          <AccordionDetails>
            <Typography variant="subtitle2">
              <strong>Insumos pecuários:</strong> São produtos destinados ao cuidado e manejo dos animais de criação, como alimentos (rações, suplementos minerais), medicamentos veterinários, vacinas,
              equipamentos de manejo (como cercas e bebedouros), instalações adequadas (como estábulos, galinheiros, currais), entre outros. Esses insumos são fundamentais para garantir a saúde,
              bem-estar e produtividade dos animais.
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography variant="subtitle2">
              <strong>Insumos agrícolas:</strong> São produtos utilizados no cultivo de plantas, como sementes, fertilizantes, pesticidas, herbicidas, fungicidas, adubos orgânicos, substratos, entre
              outros. Eles são essenciais para melhorar a qualidade do solo, controlar pragas e doenças, promover o crescimento das plantas e aumentar a produção agrícola.
            </Typography>
          </AccordionDetails>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
