import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SubjectIcon from "@mui/icons-material/Subject";
import { Button, ListItemText } from "@mui/material";
import PropertyButtonEdit from "./formEdit/PropertyButtonEdit";
import { useState } from "react";
import InfoDialogProperty from "./info/InfoDialogProperty";
import { useEffect } from "react";

export default function MenuPlan({ item }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [opendialog, setOpendialog] = useState(false);
  console.log("MenuPlan");
  console.log(item);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    console.log("handleClick ", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log("handleClose", anchorEl);
    setAnchorEl(null);
  };

  const handleClickOpendialog = () => {
    setOpendialog(true);
  };

  const handleDialogClose = () => {
    setOpendialog(false);
  };

  useEffect(() => {
    console.log("useEffect");
    console.log(opendialog);
  }, [opendialog]);

  return (
    <div>
      <React.Fragment>
        <Button id="demo-positioned-button" aria-controls={open ? "demo-positioned-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
          <MoreVertIcon sx={{ color: "#808080" }} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 40,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 28,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <SubjectIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Ver informações</ListItemText>
          </MenuItem> */}
          {/* <InfoDialogProperty open={opendialog} onClose={handleDialogClose} /> */}

          {/* <MenuItem onClick={handleClose}> */}

          {/* <EditIcon fontSize="small" /> */}
          <PropertyButtonEdit setAnchorEl={setAnchorEl} item={item} handleDialogClose={handleDialogClose} />
          {/* <ListItemText>Editar</ListItemText> */}
        </Menu>
      </React.Fragment>
    </div>
  );
}
