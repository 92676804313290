import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { AppBar, Fab, IconButton, Toolbar, Typography, useTheme, Zoom } from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import FormPropertyArea from "../../../maps/components/SearchBox/FormPropertyArea";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import SpeechRecognitionText from "./SpeechRecognitionText";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";
import api from "../../../../services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import SpeechRecognition from "react-speech-recognition";

export default function SpeechRecognitionButton({ setIsLoading, setOpenModal, openModal }) {
  const theme = useTheme();
  const [state, setState] = useState({ right: false });
  const property_id = (localStorage.getItem("Property") || 1);
  // para user current
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  //console.log('userCurrent')
  //console.log(userCurrent)
  const user = userCurrent;
  // console.log(user)

  const [message, setMessage] = useState({
    title: "",
    description: "",
    date: new Date(),
    id_requester: user.id,
    id_receiver: "",
    id_property: property_id,
    id_creation: "",
    id_agricola: "",
    key_image: "",
    area: "",
    coordinates: "",
    zoom: "15",
    status: "0",
    isDeleted: "0",
  });
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = (setOpenModal ? openModal : Boolean(anchorEl));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleDrawer("right", true);
  };

  function handleClose() {
    SpeechRecognition.stopListening();
    setAnchorEl(null);
    toggleDrawer("right", false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  }

  const onSubmit = async () => {
    //e.preventDefault();
    console.log("entrou no submit");

    setFormError({});
    let errors = {};
    let formOk = true;

    if (message.title < 3) {
      errors.title = true;
      formOk = false;
    }

    if (formOk) {
      const header = {
        header: {
          "Content-Type": "application/json",
        },
      };

      message.id_property = property_id;

      const res = await api.post("/messenger", message, header);
      console.log("enviou a mensagem");
      console.log(message);
      //const res = true
      //console.log(res);
      //const res = false
      if (res) {
        //console.log("entrou");
        //localStorage.removeItem("image");
        //localStorage.removeItem("property");
        if (setIsLoading) {
          setIsLoading(true);
        }
        handleClose();
      }
    } else {
      // escreve mensagem de erro para cliente
      toast.error(`Por favor, preencha o campo obrigatório do Título da solicitação`);
      handleClose();
      setFormError(errors);
      console.log(formError);
      //return false;
    }
  };

  function returnDrawer(anchor) {
    return (
      <Drawer anchor={anchor} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <AppBar elevation={1} sx={{ position: "relative", backgroundColor: "#fff" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1, color: "black" }} variant="h6" component="div">
              Solicitação
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "300" : 300 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
          <SpeechRecognitionText setMessage={setMessage} message={message} onSubmit={onSubmit} handleClose={handleClose} />
        </Box>
      </Drawer>
    )
  };

  return ( <> 
    {setOpenModal ? <>{returnDrawer('top')}</> : 
      ["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Zoom in={true} style={{ transitionDelay: "500ms" }}>
            <Fab
              sx={{ position: "fixed", top: 100, right: "2%" }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              //onClick={toggleDrawer(anchor, true)}
              onClick={handleClick}
              // color="success"
              aria-label="add"
              variant="extended"
            >
              <MicIcon />
            </Fab>
          </Zoom>
          {returnDrawer(anchor)}
        </React.Fragment>
      ))
    }
  </> );
}
