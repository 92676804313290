import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import FinancialContentTabs from './FinancialContentTabs';
import BankAccountComponent from './components/BankAccount/BankAccount';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, type) {
  return {
    id: `controle-financeiro-${index}`,
    'aria-controls': `Tabela: ${type}`,
  };
}

export default function FinancialChoiceContentTabs() {
  let link = window.location.href;
  let aux_tab_active = 0;
  const [value, setValue] = useState(aux_tab_active);

  if (/.*#.*/.test(link)) {
    if (/.*#financialcontent/.test(link)) {
      aux_tab_active = 0;
    } else if (/.*#bankaccount/.test(link)) {
      aux_tab_active = 1;
    }
  } else {
    aux_tab_active = value;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="Painéis de controle">
            <Tab label="Controle Financeiro" aria-controls="Controle Financeiro" {...a11yProps(0, 'Controle Financeiro')} />
            <Tab label="Contas Financeiras" aria-controls="Contas Financeiras" {...a11yProps(1, 'Contas Financeiras')} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FinancialContentTabs />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BankAccountComponent />
        </TabPanel>
      </Box>
    </>
  );
}
