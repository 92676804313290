import { Box, Button, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { useState } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

const currencies = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

export default function StartSecondStepForm({ isError, data, setData }) {
  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState();

  // função para receber os valores digitados do formulário
  const handleChange = (event) => {
    console.log('handleChangeInput');
    console.log(event.target.value);
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 0, mt: 0 }}>
        {/* Box para o texto inicial */}
        <Box style={{ height: 'auto', width: '100%', padding: '10px 0' }}>
          <Box className="d-lg-none" sx={{ mb: 0 }}>
            <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 0 }}>
              <Typography gutterBottom style={{ color: '#2e7d32' }}>
                <Typewriter tam={70} text="Como é o seu primeiro acesso, precisaremos realizar as configurações iniciais." />
              </Typography>
            </Grid>
          </Box>
          <Box className="d-none d-lg-block" sx={{ mt: 0 }}>
            <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 0 }}>
              <Typography gutterBottom style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="Como é o seu primeiro acesso, precisaremos realizar as configurações iniciais." />
              </Typography>
            </Grid>
          </Box>
        </Box>

        {/* Box fixo para o ListItem */}
        <Box sx={{ mt: -1, width: '100%' }}>
          <ListItem
            key={null}
            style={{ height: '100px', position: 'relative' }} // Garantindo que o ListItem tenha posição fixa no fluxo
            disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <CropOriginalIcon style={{ color: '#2e7d32' }} />
              </IconButton>
            }
          >
            <ListItemText primary={<strong>Passo 1:</strong>} secondary="Defina o nome e o tamanho da propriedade." />
          </ListItem>
        </Box>
      </Grid>
      {/* <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
          <HomeIcon style={{ fontSize: 30 }} />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0} sx={{ mt: 0.5 }}>
              <Typography fontWeight={700}>Propriedade</Typography>
            </Stack>
          </Box>
        </Grid> */}
      <Grid container spacing={0} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={12}>
          <TextField
            //InputLabelProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{
              sx: {
                color: '#2e7d32',
                animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao rótulo
                borderRadius: '5px', // Adiciona bordas arredondadas
              },
            }}
            id="nameProperty"
            name="nameProperty"
            label="Nome da fazenda"
            fullWidth
            required
            focused
            autoComplete="shipping nameProperty"
            variant="standard"
            color="success"
            value={data?.nameProperty}
            onChange={handleChange}
            error={data?.nameProperty == ''}
            helperText={data?.nameProperty == '' ? 'Por favor, preencha o campo obrigatório do nome!' : 'Insira o nome da propriedade'}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <TextField
            InputLabelProps={{
              sx: {
                color: '#2e7d32',
                animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao rótulo
                borderRadius: '5px', // Adiciona bordas arredondadas
              },
            }}
            required
            id="outlined-size-small"
            defaultValue="Small"
            name="area"
            focused
            label="Área total"
            variant="standard"
            type="number"
            color="success"
            size="small"
            fullWidth
            value={data.area}
            onChange={handleChange}
            // disabled={!isEditable}
            helperText={data?.area == '' ? '' : 'Insira o tamanho da propriedade'}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="outlined-size-small"
            name="measure"
            label="Padrão de medida"
            select
            fullWidth
            value={data.measure}
            onChange={handleChange}
            variant="standard"
            color="success"
            //disabled={!isEditable}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
            <Typography paragraph variant="subtitle1" fontWeight={800}>
              Está na propriedade agora ou em outro lugar?
            </Typography>
            <RadioGroup value={escolha} onChange={handleEscolha}>
              <FormControlLabel value="componente1" control={<Radio />} label="Estou na fazenda" />
              <FormControlLabel value="componente2" control={<Radio />} label="Não estou na fazenda" />
              {escolha === "componente1" && <Componente1 data={data} setData={setData} />}
              {escolha === "componente2" && <Componente2 data={data} setData={setData} />}
            </RadioGroup>
          </Grid> */}
        {/* <LocationSearch data={data} setData={setData} /> */}
      </Grid>
      <br />
    </>
  );
}
