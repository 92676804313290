import FastfoodIcon from "@mui/icons-material/Fastfood";

export default function CardTimeLine() {
  return (
    <>
      <div className="content">
        <section className="content ">
          <div className="container-fluid">
            <div className="row" style={{ height: 500, overflowY: "scroll" }}>
              <div className="col-md-12">
                <div className="timeline">
                  <div className="time-label">
                    <span className="text-secondary">3 Jan. 2022</span>
                  </div>
                  {/* card01 */}
                  <div>
                    <i className="fas fa-user bg-success" />
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fas fa-clock" /> 12:05
                      </span>
                      <h3 className="timeline-header no-border">
                        <small className="text-muted ">Leonardo Solar</small>
                      </h3>

                      <div className="timeline-body">
                        <div className="row ">
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-start align-items-center  ">
                              <p className="d-flex flex-column text-left">
                                <span className="font-weight-bold text-success">
                                  <i className="fa fa-arrow-right text-success" /> Problemas no Cocho
                                </span>
                                <small className="text-muted ml-3">
                                  <a className="text-secondary">Área Piquete 2</a>
                                </small>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-end align-items-center  ">
                              <i className="image">
                                <img className="img-rounded elevation-1" src="/dist/image/cocho.png" width="80"></img>
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* fim do card01 */}
                  <div className="time-label">
                    <span className="text-secondary">1 Jan. 2022</span>
                  </div>
                  <div>
                    <i className="fas fa-user bg-secondary" />
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fas fa-clock " /> 2 dias
                      </span>
                      <h3 className="timeline-header no-border">
                        <small className="text-muted ">Miguel</small>
                      </h3>
                      <div className="timeline-body">
                        <div className="row ">
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-start align-items-center  ">
                              <p className="d-flex flex-column text-left">
                                <span className="font-weight-bold">
                                  <i className="fa fa-check text-secondary" /> Limpeza do bebedouro
                                </span>
                                <small className="text-muted ml-3">
                                  <a className="text-secondary">Área Piquete 2</a>
                                </small>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-end align-items-center  ">
                              <i className="image">
                                <img className="img-rounded elevation-1" src="/dist/image/bebedouro.png" width={80} style={{ filter: `grayscale(100%)` }} />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* card01 */}
                  <div>
                    <i className="fas fa-user bg-secondary" />
                    <div className="timeline-item">
                      <span className="time">
                        <i className="fas fa-clock" /> 12:05
                      </span>
                      <h3 className="timeline-header no-border">
                        <small className="text-muted ">Leonardo Solar</small>
                      </h3>
                      <div className="timeline-body">
                        <div className="row ">
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-start align-items-center  ">
                              <p className="d-flex flex-column text-left">
                                <span className="font-weight-bold text-secondary">
                                  <i className="fa fa-arrow-right text-secondary" /> Problemas no Cocho
                                </span>
                                <small className="text-muted ml-3">
                                  <a className="text-secondary">Área Piquete 2</a>
                                </small>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex justify-content-end align-items-center  ">
                              <i className="image">
                                <img className="img-rounded elevation-1" src="/dist/image/cocho.png" width="80" style={{ filter: `grayscale(100%)` }}></img>
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* fim do card01 */}

                  <div>
                    <i className="fas fa-clock bg-gray" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
