import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import { Delete, PendingActions } from '@mui/icons-material';
import TablesPerson from './TablesPerson';
import TablesCardPeople from './TablesCardPeople';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CardPersons() {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [tab_active, setTabActive] = useState(['Cliente', 'Ativo']);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    var aux_val = ['Cliente', 'Fornecedor', 'Transportadora'];
    var aux_active = [aux_val[newValue], tab_active[1]];
    setTabActive(aux_active);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
    var aux_val = ['Ativo', 'Lixeira'];
    var aux_active = [tab_active[0], aux_val[newValue]];
    setTabActive(aux_active);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TablesCardPeople />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          textColor="success"
          TabIndicatorProps={{ style: { background: 'green' } }}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="tab-tipo-pessoa"
          sx={{
            '.Mui-selected': {
              color: '#2e7d32 !important',
              fontWeight: 'bold',
            },
          }}
        >
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                Clientes
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                Fornecedores
              </Grid>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                Transportadoras
              </Grid>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      {/* <Box sx={{}}>
        <Typography>
          <TablesPerson tab_active={tab_active} />
        </Typography>
      </Box> */}

      <Box sx={{}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor="success"
            TabIndicatorProps={{ style: { background: 'green' } }}
            variant="scrollable"
            value={value2}
            onChange={handleChange2}
            aria-label="tab-situacao"
            sx={{
              '.Mui-selected': {
                color: '#2e7d32 !important',
                fontWeight: 'bold',
              },
            }}
          >
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <PendingActions sx={{ mr: 0.5, mb: 0.2 }} /> Ativo
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <Delete sx={{ mr: 0.5, mb: 0.2 }} /> Lixeira
                </Grid>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <Typography key={'t_' + tab_active[0] + '_' + tab_active[1]}>
          <TablesPerson tab_active={tab_active} />
        </Typography>
      </Box>
    </Box>
  );
}
