import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useActivityInputReposStore from '../../../../store/Activities/useActivityInputReposStore'
import FirstAcessMapList from '../../../maps/tutorial/FirstAcessMapList'
import CreateDrawAreaList from './CreateDrawAreaList'
import RedirectProjectList from './RedirectProjectList'
import { Box, Typography } from '@mui/material'
import Typewriter from '../../../../components/Typewriter/Typewriter'
import StepperArea from './StepperArea'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'

export default function RedirectProject({ numberArea }) {
  const [open, setOpen] = React.useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">
                
                    <div className="d-lg-none">
                        <Box>
                            <Typography gutterBottom sx={{ mt: 0, mb: 3 }} fontWeight={500} style={{ color: "#2e7d32" }}>
                                <Typewriter
                                    tam={110}
                                    text={`Organizado a área de produção em ${numberArea} área(s), está na hora de criar as safras ou as criações para depois inserirmos as culturas ou animais nesta área.`}
                                />
                            </Typography>
                        </Box>
                    </div>
                    <div className="d-none d-lg-block"><Box>

                        <Typography gutterBottom sx={{ mt: 1, mb: 3 }} fontWeight={500} style={{ color: "#2e7d32", width: 550 }}>
                            <Typewriter
                                tam={30}
                                text={`Organizado a área de produção em ${numberArea} área(s), está na hora de criar as safras ou as criações para depois inserirmos as culturas ou animais nesta área.`}
                            />
                        </Typography>

                    </Box>
                    </div>
                </DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
          <StepperArea numberArea={numberArea} />
          {/* <RedirectProjectList /> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} onClick={handleClose} color="success" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
