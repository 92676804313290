import React from 'react'

const ColorPicker = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return <input type="color" value={value} onChange={handleChange} style={{ width: '50px', height: '30px', borderRadius: '4px' }} />
}

export default ColorPicker
