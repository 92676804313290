import * as React from 'react'
import { Button, Grid, IconButton } from "@mui/material";
import { HighlightOff, Queue } from '@mui/icons-material';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import GenericValueField from '../../../../../components/Fields/GenericValueField';
// import CurrencyInputReal from '../../../../../components/Fields/CurrencyInputReal';

export default function CardProduct({ data, obj_fn, area }) {
  //const optionsProducts = JSON.parse(JSON.stringify( obj_fn.usestore.reposProducts.listProducts ));
  const optionsProducts = JSON.parse(JSON.stringify(
    obj_fn.usestore.reposProducts.getProductsStoreMultiple([['category_project', 'agricola'], ['category_project', 'todos']])
  ));

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_idx = field.split('_');
          let aux_area_prods = [...area_prev.products];

          let aux_prod = JSON.parse(JSON.stringify(newValue));
          aux_prod.location_selected = aux_prod.location.length > 0 ? aux_prod.location[0] : null;

          aux_area_prods[aux_idx[2]] = aux_prod;
          return { ...area_prev, products: aux_area_prods };
        }
        return area_prev;
      })
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal');
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_idx = field.split('_');
          let aux_area_prods = [...area_prev.products];
          let aux_local = JSON.parse(JSON.stringify(newValue));

          aux_area_prods[aux_idx[3]].location_selected = aux_local;
          return { ...area_prev, products: aux_area_prods };
        }
        return area_prev;
      })
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleChangeQtd = (event, product, index) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_area_prods = [...area_prev.products];
          let aux_qtd = parseFloat(event.target.value || '0');

          aux_area_prods[index].quant_colhida = aux_qtd;
          return { ...area_prev, products: aux_area_prods };
        }
        return area_prev;
      })
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const validQtd = (type, product) => {
    let valid = product?.quant_colhida > 0;
    if (type == 'color') {
      return valid || !product?.id ? "success" : 'error';
    } else {
      return (<>
      Quantidade a ser colhida
      {valid || !product?.id ? <></> : (<><br/><span style={{color: 'red'}}>* Deve ser maior do que 0!</span></>)} </>);
    }
  };
  // const para validar o button add
  const isError = () => {
    let iserror = false;
    area.products.map((prod, idx) => {
      if (!prod?.id) {
        iserror = true;
      } else if (prod?.quant_colhida <= 0) {
        iserror = true;
      }
    })
    return iserror;
  };
  // const para validar o button remover
  const isErrorRmv = () => {
    return area.products.length == 1;
  };

  const handleAdd = () => {
    let aux_areas_prods = JSON.parse(JSON.stringify(area.products));
    aux_areas_prods.push({id: null, name: '', location: [], quant_colhida: 0, location_selected: null});
    //aux_areas_prods.push(null);

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, products: aux_areas_prods };
        }
        return area_prev;
      })
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleRmv = (e, product, index) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          let aux_area_prods = area_prev.products.filter((prod, idx) => {
            if (prod.id !== product.id || idx !== index) {
              return prod;
            }
          })
          return { ...area_prev, products: aux_area_prods };
        }
        return area_prev;
      })
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData(prevState => ({ ...prevState, ['edit_prod']: true }));
    }
  };

  const handleProducts = (product, index) => {
    const opt_products = {
      multiple: false,
      name: 'product_' + product?.id + "_" + index,
      field: 'product_' + product?.id + "_" + index,
      label: 'Produtos',
      values: optionsProducts,
      variant: 'outlined',
      helperText: 'Selecione o produto desejado',
      listHeight: 225,
      disableClearable: true,
    }

    return (<>
      <AutocompleteGeneric value={product} options={opt_products} handleChange={handleChangeSelect} />
    </>)
  };

  const handleProductsLocation = (product, index) => {
    const opt_products_local = {
      multiple: false,
      name: 'product_location_' + product?.id  + "_" + index,
      field: 'product_location_' + product?.id  + "_" + index,
      label: 'Local para estoque (Destino)',
      values: product?.location || [],
      variant: 'standard',
      helperText: (<>
        Local onde o produto será estocado
        <br/>
        Estoque atual: {product?.location_selected?.quanty || '-'} {product?.unit_sigla ? " (" + product?.unit_sigla + ")" : ''}
      </>),
      listHeight: 225,
      disabled: !product?.id,
      disableClearable: true,
    }

    return (<>
      <AutocompleteGeneric value={product?.location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
    </>)
  };

  return ( <>
    <Grid container spacing={2} sx={{ mt: -1, mb: 1 }}>
      {area.products.map((product, index) => (<>
        <Grid item xs={12} sm={4}>
          {handleProducts(product, index)}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mt: -1, mb: 1 }}>
          {handleProductsLocation(product, index)}
        </Grid>
        <Grid item xs={8} sm={3}>
          {/* <CurrencyInputReal
            name={"quant_colhida_" + product?.id + "_" + index}
            label="Quantidade colhida*"
            variant="outlined"
            value={product?.quant_colhida}
            adornment="quantidade"
            endAdornment={product?.unit_sigla ? " (" + product?.unit_sigla + ")" : ''}
            onChange={(e) => handleChangeQtd(e, product, index)}
            helperText={validQtd('helperText', product, index)}
            color={validQtd('color')}
            disabled={!product?.id}
          /> */}
          <GenericValueField
            handleChange={(e) => handleChangeQtd(e, product, index)}
            options={{
              id: "quant_colhida_" + product?.id + "_" + index,
              name: "quant_colhida_" + product?.id + "_" + index,
              label: 'Quantidade colhida*',
              value: product?.quant_colhida,
              helperText: validQtd('helperText', product, index),
              color: validQtd('color'),
              disabled: !product?.id,
              endAdornment: true,
              prefix: product?.unit_sigla ? " (" + product?.unit_sigla + ")" : '',
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1}>
          <IconButton variant="contained" color="error" onClick={(e) => handleRmv(e, product, index)} title="Remover" disabled={isErrorRmv()}>
            <HighlightOff />
          </IconButton>
        </Grid>
      </>))}

      <Grid item xs={12} sm={12}>
        <Button variant="contained" color="success" onClick={handleAdd} disabled={isError()}>
          <Queue sx={{mr: 1}}/> Add mais produtos
        </Button>
      </Grid>
    </Grid>
  </>);
}