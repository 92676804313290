import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useActivityInputReposStore from '../../../store/Activities/useActivityInputReposStore';
import FirstAcessMapList from './FirstAcessMapList';
import { Box, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import Bot from '../../../components/Animations/Bot';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

export default function FirstAcessMap({ propertyName }) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const text =
    'Bem vindo ao Mapa da Fazenda. É aqui que teremos uma visão geral de cada pedaço da propriedade. Mas para isso, primeiramente precisaremos localizar a propriedade no google maps e registrá-la no Fazenda Premium.';
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">
                    {"Registrando a propriedade no Mapa:"}
                </DialogTitle> */}
        <DialogContent sx={{ mt: 0 }}>
          <ListItem
            key={null}
            disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <CropOriginalIcon style={{ color: '#2e7d32' }} />
              </IconButton>
            }
          >
            <ListItemText primary={<strong>Passo 2:</strong>} secondary="Registrar a propriedade no Google Maps." />
          </ListItem>
          <div className="d-none d-lg-block" sx={{ mt: 0, mb: 1 }}>
            <Box>
              <Typography gutterBottom sx={{ mt: 1, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={50} text={`Bem-vindo ao Mapa da Fazenda.`} />
              </Typography>
            </Box>
          </div>
          <div className="d-lg-none" sx={{ mt: 1, mb: 1 }}>
            <Box>
              <Typography gutterBottom sx={{ mt: 1, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={50} text={`Bem-vindo ao Mapa da Fazenda.`} />
              </Typography>
            </Box>
          </div>
          <Grid container sx={{ mt: 0 }}>
            <Grid item sm={10} xs={12} style={{ color: '#2e7d32' }}>
              {`Agora é hora de inserir a sua propriedade, ${propertyName}, no Google Maps. Veja a seguir como é fácil:`}
            </Grid>
            {/* <Grid item sm={2} xs={12} sx={{ textAlign: 'right', mt: 0 }}>
              <Bot text={text} />
            </Grid> */}
          </Grid>
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
          <FirstAcessMapList />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} onClick={handleClose} color="success" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
