import React, { useState, useEffect, useContext } from "react";
import { Dialog, Container, DialogContent, IconButton, Stack, Slide } from "@mui/material";
import { AppBar, Grid, Paper, Toolbar, Typography } from "@mui/material";
import { Box, Button, ListItem, ListItemText, Divider, Select, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { deDE } from '@mui/x-date-pickers/locales'
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { green } from "@mui/material/colors";
import api from "../../../../../services/api";
import { Context } from "../../../../../components/Context/AuthContext";
import SpreadsheetAnimals from "../../../../../components/Spreadsheet/SpreadsheetAnimals";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import { makeStyles } from "@mui/styles";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { createTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  content: {
    width: "100%",
    maxWidth: "none",
  },
}));

const faseStyle = (fase) => ({
  backgroundColor: "#5cb85c",//data.stage === fase ? "#5cb85c" : "#fff",
  color: "#333",//data.stage === fase ? "#fff" : "#333",
  marginRight: "5px",
  borderRadius: "5px",
  padding: "2px",
  cursor: "pointer",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme(
  { palette: { primary: { main: "#1976d2" }, }, },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);

const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };

const returnDtPickerTable = (mobile, date_birth, handleChange, maxDate) => {
  // https://stackoverflow.com/questions/74890126/material-ui-desktopdatepicker-sx-styling
  return (
    <Stack spacing={0} sx={{ flexGrow: 1, textAlign: "center", marginTop: '-30px', marginBottom: '-30px', maxHeight: '35px', minWidth: '145px' }}>
      <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
        <DesktopDatePicker
          name="date_birth"
          onChange={(newValue) => {
            handleChange("date_birth", newValue);
          }}
          adapterLocale="pt"
          inputFormat="DD/MM/YYYY"
          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
          value={date_birth}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: '35px',
                  '& fieldset': {
                    borderWidth: 0
                  },
                }
              }}
              color="success"
            />
          )}
        /> 
      </LocalizationProvider>
    </Stack>
  )
};

const returnSexoAnimal = (index, value, handleChangeSexo) => {
  return (
    <Stack spacing={0} sx={{ flexGrow: 1, textAlign: "center", marginTop: '-30px', marginBottom: '-30px' }}>
      <Select sx={{ width: 100 }} color="success" fullWidth variant="standard" label="Sexo" name="sexo" value={value + '_' + index} onChange={(e) => handleChangeSexo(e)}>
        <MenuItem key={"Fêmea"} value={"Fêmea_" + index} >
          <FemaleIcon color="secondary" />
          Fêmea
        </MenuItem>
        <MenuItem key={"Macho"} value={"Macho_" + index}>
          <MaleIcon color="primary" />
          Macho
        </MenuItem>
      </Select>
    </Stack>
  )
};

// fillDialog normal que chama uma lista de botões de atividade
// cada botão chama o componete de atividades ... MultiStepFormInput
export default function EditActivityIdentify({ row_data, usestore }) {
  const [open, setOpen] = useState(false);
  const this_activity = window.jQuery.extend(true, [], usestore.reposActivityIdentify.getActivityIdentifyStore('id', row_data.id)[0]);
  this_activity.table_animals = [];
  const [data, setData] = useState(this_activity);
  const [updated, setUpdated] = useState(0);

  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const property_id = localStorage.getItem("Property") || 1;
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const classes = useStyles();
  const ismobile = isMobile.any();

  /*const handleChangePlan = (plan, value) => {
    console.log("handleChange");

    setData((prevState) => ({ ...prevState, 'planning': value }));
  };*/

  const handleChangeSexo = (event) => {
    // console.log("handleChangeSexo");
    var value = (event.target.value).replace(/(.*)_.*/, '$1');
    var index = (event.target.value).replace(/.*_(.*)/, '$1');

    const aux_animals = data.animals;
    aux_animals[index].sexo = value;
    setData((prevState) => ({ ...prevState, animals: aux_animals }));

    if (value === 'Macho') {
      setData((prevState) => ({ ...prevState, num_males:   data.num_males +1 }));
      setData((prevState) => ({ ...prevState, num_females: data.num_females -1 }));
    } else {
      setData((prevState) => ({ ...prevState, num_females: data.num_females +1 }));
      setData((prevState) => ({ ...prevState, num_males:   data.num_males -1 }));
    }
    setUpdated(updated+1);
  };

  const [index_date_birth, setIndexDateBirth] = useState({index: 0});
  const setIndexDate = (row) => {
    if (!window.jQuery.find('.MuiPickersPopper-root:visible')?.length) {
      // console.log('setIndexDateBirth');
      index_date_birth.index = row;
      setIndexDateBirth({index: row});
      window.jQuery(window.jQuery.find('.classRowTable_' + row)[0]).find('button').click();
    }
  };

  const handleChangeBirth = (event, val) => {
    // console.log("handleChangeBirth");
    var value = dayjs(val).format('YYYY-MM-DD HH:mm:ss');

    const aux_animals = data.animals;
    aux_animals[index_date_birth.index].date_birth = value;

    setData((prevState) => ({ ...prevState, animals: aux_animals }));

    setUpdated(updated+1);
  };
  // gera tabela de animais
  const geraTableAnimals = (list_animals) => {
    var list_animals = (list_animals ? list_animals : window.jQuery.extend(true, [], data.animals));
    var aux_list_animals = [];
    var animal = [];
    var aux_a = {};

    for (var i = 0, tam = list_animals.length; i < tam; i++) {
      aux_a = list_animals[i];
      animal = [
        {value: data.lote?.fase_name || '', readOnly: true, className: 'classRowTableDisabled'},
        {value: ( returnSexoAnimal(i, data.animals[i].sexo, handleChangeSexo)
          ), className: 'classRowTable', readOnly: true},
        {value: aux_a.identifier, className: 'classRowTable'},
        {value: aux_a.brinco, className: 'classRowTable'},
        {value: aux_a.sisbov, className: 'classRowTable'},
        {value: aux_a.name, className: 'classRowTable'},
        {value: aux_a.race, className: 'classRowTable'},
        {value: aux_a.weight, className: 'classRowTable'},
        {value: aux_a.unit, readOnly: true, className: 'classRowTableDisabled'},
        {value: ( returnDtPickerTable(ismobile, aux_a.date_birth, handleChangeBirth, new Date(data.lote?.date_entry || data.date_start))
          ), className: 'classRowTable_' + i, readOnly: true}
      ];

      aux_list_animals.push(animal);
    }

    setData((prevState) => ({ ...prevState, ['table_animals']: aux_list_animals }));
    return aux_list_animals;
  };
  // validations table step start -----
  const handleChangeTable = (dataSheet, value) => {
    // console.log("handleChangeTable");
    if (!data.animals.length) {
      return;
    }
    const aux_animals = data.animals;

    dataSheet.map((row, index) => {
      //aux_animals[index].sexo = row[1].value; // sexo
      aux_animals[index].identifier = row[2].value; // identifier
      aux_animals[index].brinco = row[3].value; // brinco
      aux_animals[index].sisbov = row[4].value; // sisbov
      aux_animals[index].name = row[5].value; // nome
      aux_animals[index].race = row[6].value; // raça
      aux_animals[index].weight = row[7].value; // peso
      //aux_animals[index].date_birth = dayjs(row[8].value).format('YYYY-MM-DD HH:mm:ss'); // data nascimento
    });
    setData((prevState) => ({ ...prevState, animals: aux_animals }));
    setData((prevState) => ({ ...prevState, table_animals: dataSheet }));
    setUpdated(updated+1);
  };
  // validations table step end -----

  useEffect(() => {
    geraTableAnimals();
  }, [updated]);

  const handleClickOpen = () => {
    geraTableAnimals();
    setUpdated(0);
    setOpen(true);
  };
  const handleClose = () => {
    //reseta informações
    var this_actv = window.jQuery.extend(true, [], usestore.reposActivityIdentify.getActivityIdentifyStore('id', row_data.id)[0]);
    this_actv.table_animals = [];
    setData(this_actv);
    setUpdated(0);
    setOpen(false);
  };

  // const para validar o button continuar
  const isError = () => {
    return !data.animals?.length || !updated ||
    !value.$D || !valueEnd.$D ;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formOk = !isError();

    if (formOk) {
      const header = { header: { "Content-Type": "application/json" } };

      const res = await api.put("/activityIdentify/update", data, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success("Atividade editada com sucesso!");
            // atualiza o repos
            usestore.reposLivestock.fetch({ 'id_farm': property_id });
            usestore.reposActivityIdentify.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao editar atividade. Tente novamente mais tarde!");
        });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios");
    }
  };

  // validations first step start -----
  const [value, setValue] = useState(dayjs(data.date_start));
  const [valueEnd, setValueEnd] = useState(dayjs(data.date_prev_end));

  /*// valor para inicializar o data
  useEffect(() => {
    setData((prevState) => ({ ...prevState, date_start: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
  }, [value]);
  useEffect(() => {
    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format("YYYY-MM-DD HH:mm:ss") }));
  }, [valueEnd]);*/

  return ( <>
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div"> Edição da Identificação</Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose}  aria-label="Fechar" title="Fechar">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ mb: 8 }}>
          <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
            <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
              <Box>
                <Grid item container alignItems="center" justifyContent="center">
                  <Grid item sx={{ mt: 1, height: 120 }}>
                    <img style={{ maxHeight: 120 }} src="/dist/image/entrada.png" alt="image" className="img-fluid" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{}}>
                  <Grid item xs={12} md={12} style={{ justifyContent: "center" }}>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                      <Grid item className="d-none d-lg-block">
                        <Typography sx={{ color: "#2e7d32", textAlign: "center" }}>
                          <Typewriter tam={30} text="Altere os dados desejados, confira e clique em confirmar." />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} className="d-lg-none">
                        <Typography sx={{ color: "#2e7d32", textAlign: "center" }}>
                          <Typewriter tam={60} text="Altere os dados desejados, confira e clique em confirmar." />
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <RadioGroup sx={{ mt: 1 }} aria-label="planning" name="planning" value={data.planning} onChange={(e) => handleChangePlan("planning", e.target.value)} defaultValue="" style={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel value="realized" name="planning" control={<Radio color="success" />} label={<div style={faseStyle("realized")}>Realização</div>} />
                      <FormControlLabel value="planning" name="planning" control={<Radio color="success" />} label={<div style={faseStyle("planning")}>Planejamento</div>} />
                    </RadioGroup> */}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ mt: 2, height: 110 }}>
                    <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                      <div className="d-lg-none">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(3, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => <TextField {...params} color="success" />}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => <TextField {...params} color="success" />}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      <div className="d-none d-lg-block">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(3, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!value.$D}
                                    helperText={!value.$D && (<p id="date_start_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p>)}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                color="success"
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!valueEnd.$D}
                                    helperText={!valueEnd.$D && (<p id="date_prev_end_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p>)}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: "#808080" }}>
                        Data prevista para início da atividade.
                      </Typography>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1} sx={{}}>
                  <ListItem>
                    <ListItemText primary="Nome do Lote" secondary={data.lote.name} />
                  </ListItem>
                </Grid>
                <Divider />
                <Grid container spacing={1} sx={{}}>
                  <ListItem>
                    <ListItemText primary="Fase de manejo" secondary={data.lote.fase_name} />
                  </ListItem>
                </Grid>
                <Divider />
                <Grid container spacing={3} sx={{}}>
                  <Grid item xs={4} md={4}>
                    <ListItem sx={{ textAlign: 'center' }}>
                      <ListItemText primary="Animais" secondary={data.num_animals} />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <ListItem sx={{ textAlign: 'center' }}>
                      <ListItemText primary="Fêmeas" secondary={data.num_females} />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <ListItem sx={{ textAlign: 'center' }}>
                      <ListItemText primary="Machos" secondary={data.num_males} />
                    </ListItem>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}> Tabela dos animais </Typography>
                  </Stack>
                  {!ismobile ?
                    <Grid item xs={12} md={12} className={classes.container} sx={{ mt: 1, mb: 5 }}>
                      <SpreadsheetAnimals lista={data.table_animals} handleChange={handleChangeTable} setIndex={setIndexDate}/>
                    </Grid> :
                    <Grid item xs={12} sx={{ width: "100%", overflow: "auto" }}>
                      <SpreadsheetAnimals lista={data.table_animals} handleChange={handleChangeTable} setIndex={setIndexDate} />
                    </Grid>
                  }
                </Grid>
                <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
                  <Toolbar>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                        cancelar
                      </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Button color="success" variant="contained" type="submit" onClick={!updated ? () => null : onSubmit} disabled={isError()}>
                        Salvar
                      </Button>
                    </Box>
                  </Toolbar>
                </AppBar>
              </Box>
            </Paper>
          </Container>
        </DialogContent>
      </Dialog>
      <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
        <EditIcon sx={{ color: green[500] }} />
      </IconButton>
    </div>
  </>);
}
