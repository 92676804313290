import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddActivities from './components/AddButtonActivities/AddActivities';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TutorialaddActivities from './components/tutorial/TutorialaddActivities';
import CardActivitiesHarvests from './components/tab/CardActivitiesHarvests';
import ViewListActivitiesMonitoring from './components/FormActivities/Applications/ViewListActivitiesMonitoring';
import ButtonListStock from '../activities/components/Button/ButtonListStock';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import { useParams } from 'react-router-dom';

export default function ActivitiesHarvestContent() {
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const { idFarm, id } = useParams();
  useEffect(() => {
    //console.log("useEffect=ActivitiesHarvestContent atualiza a tabela se true ");
    //console.log(isLoadingActivities);
    if (isLoadingActivities === true) {
      setIsLoadingActivities(false);
    }
  }, [isLoadingActivities]);

  const nameAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'));
  //console.log(nameAtivity.label);
  //console.log(nameAtivity);

  const objText = {
    icon: <PendingActionsIcon sx={{ fontSize: 35 }} color="text.secondary" />,
    title: 'Atividades de Campo', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Com o projeto agrícola podemos planejar e gerenciar de forma eficiente as atividades agrícola.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar uma Atividade de Campo', '2- Monitorar a lista de atividades de campo', '3- Gerenciar insumos utilizandos', '4- Gerenciar custos da produção'], // Texto complementar ou final
    linkButton: ['openAddActivitiesHarvestRegistrarModal', `/activitiesHarvest/${idFarm}/${id}`, `/resources/${idFarm}/${id}`, `/productioncost/${idFarm}/${id}`], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex' }}>
                <PendingActionsIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Atividades de Campo
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Registro&nbsp;da&nbsp;realização&nbsp;das&nbsp;atividades
                  </Typography>
                  <Typography fontWeight="400" style={{ color: 'gray' }} variant="subtitle2">
                    Safra: {nameAtivity.label}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" justifyContent="flex-start">
                <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
              </Box>
            </Grid>
            <Grid item xs={3} sm={1}>
              <ViewListActivitiesMonitoring />
            </Grid>
            <Grid item xs={3} md={1}>
              <ButtonListStock />
            </Grid>
            <Grid item xs={6} md={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                {/* <StartActivityLivestock /> */}
                <TutorialStepGeral objText={objText} />
                <TutorialaddActivities />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <CardActivitiesHarvests />
          </div>
        </div>
      </div>
    </div>
  );
}
