import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, ButtonBase, Card, CardContent, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Checkmark } from "react-checkmark";
import Typewriter from "../../../../components/Typewriter/Typewriter";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import DownloadAnimation from "../../../../components/Animations/DownloadAnimation";
import ThreeDotsSpinner from "../../../../components/Loader/ThreeDotsSpinner";
import Checkv1 from "../../../../components/Animations/Checkv1";
import Checkv2 from "../../../../components/Animations/Checkv2";
import Checkv3 from "../../../../components/Animations/Checkv3";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: "100%",
  },
}));

export default function SuccessTarget({ plan, handleClose }) {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoadingText, setIsLoadingText] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //     setOpen(false);
  // };

  const handleButtonClick = () => {
    //navigate("/areasmaps");
  };

  setTimeout(() => {
    setIsLoadingText(true);
  }, 200);

  // setTimeout(() => {
  //   handleButtonClick()
  //   handleClose();
  // }, 3000);

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ backgroundColor: "transparent" }}>
        {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
        <DialogContent>
          {/* <Checkmark size={'196'} /> */}
          <Checkv1 />
          <Typography variant="h6" style={{ color: "#2e7d32", textAlign: "center" }}>
            Meta de produção definida!!!
          </Typography>
          <Card>
            <CardContent sx={{ mt: -1 }}>
              <Grid container spacing={2} sx={{ mb: 1.1 }}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item sx={{ width: 50 }}>
                      <EmojiEventsIcon sx={{ fontSize: 40, color: "#28a745" }} />
                      {/* <Img alt="complex" src="/dist/img_fp/icon-button/icon-agricolaV1.png" /> */}
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">Produção Esperada</Typography>
                      <Typography variant="subtitle2">
                        <strong>{plan?.expectedYield}</strong> <span style={{ fontSize: "0.8em" }}>{plan?.unitWeight}/{plan?.unit}</span>
                      </Typography>
                    </Grid>
                    {/* <Grid item xs>
                                  <Typography variant="caption">Produtividade Esperada</Typography>
                                  <Typography variant="subtitle2">
                               
                                    <strong>{productivity}</strong> <span style={{ fontSize: "0.8em" }}>{plan?.unitWeight}/dia</span>
                                  </Typography>
                                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
