import React, { useEffect, useState, useContext } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Paper, AppBar, Toolbar, IconButton, Divider, Box, ListItemText, ListItem, Typography, Grid, Step, Card } from '@mui/material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/system';
import { Context } from '../../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import { H6, Span } from '../../../../../components/Typography';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import StepIcon from '../../../../../components/Icons/tutorial/StepIcon';
import MouseIcon from '@mui/icons-material/Mouse';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function MapaTutorial({ data, setData, isLoading, setIsLoading }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [addArea, setAddArea] = useState(0);
  const { aux_add } = useContext(Context);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Logica para nova area adicionada, se necessário
  }, [addArea, aux_add]);

  return (
    <div>
      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} startIcon={<StepIcon />} size="small" variant="outlined" color="success" onClick={handleClickOpen}>
        Saiba como desenhar
      </Button>

      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth maxWidth="lg">
        <AppBar sx={{ cursor: 'move', position: 'relative' }} color="success" id="draggable-dialog-title">
          <Toolbar>
            {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton> */}
            <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }}> </H6>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          <Typography style={{ color: '#2e7d32' }}>
            {true && <Typewriter tam={30} minHeight={0} text="Dê o play no vídeo e veja o passo a passo de como desenhar uma área de produção no mapa" />}
          </Typography>
        </DialogTitle>
        <Divider sx={{ mt: 2 }} />
        <DialogContent>
          <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <video controls style={{ height: '400px', width: '80%' }}>
              <source src="dist/animation/areaVideoTutorial.mp4" type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </Card>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={12} alignItems="center">
              <Grid item xs={12} md={12} alignItems="center"></Grid>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <MouseIcon style={{ color: '#2e7d32' }} />
                  </span>
                }
              >
                {/* <ListItemText primary={<strong>2-Como mover o Mapa do Google:</strong>} secondary="Clique no botão esquerdo do seu mouse em um ponto e com o botão pressionado arraste o mapa." /> */}
                <ListItemText
                  primary={<strong>Passo 1: Mover e dar zoom no mapa</strong>}
                  secondary={
                    <>
                      <ul>
                        <li>Dar o zoom: Pressione Ctrl e com botão de rolagem do seu mouse role a tela para aplicar o zoom.</li>
                        <li>Mover o mapa: Clique no botão esquerdo do seu mouse em um ponto e com o botão pressionado arraste o mapa.</li>
                      </ul>
                    </>
                  }
                />
              </ListItem>
              <ListItem
                key={null}
                disableGutters
                secondaryAction={
                  <span aria-label="comment">
                    <HighlightAltIcon style={{ color: '#2e7d32' }} />
                  </span>
                }
              >
                <ListItemText
                  primary={<strong>Passo 2: Criando os pontos do desenho</strong>}
                  secondary={
                    <>
                      <ul>
                        <li>Criando os pontos: Clique no botão esquerdo do seu mouse nos lugares que deseja desenhar. .</li>
                        <li>Ajustar o desenho: Clique no botão esquerdo do mouse nos vértices do seu desenho da área e com o botão pressionado arraste-o.</li>
                      </ul>
                    </>
                  }
                />
              </ListItem>
            </Grid>
          </Grid>
        </DialogContent>

        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" variant="contained" type="submit" onClick={handleClose}>
                <Span sx={{ textTransform: 'capitalize' }}>Fechar</Span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
}
