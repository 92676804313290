import { Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Zoom } from "react-toastify";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { ContentCopy } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import SpaIcon from "@mui/icons-material/Spa";
import useStore from "../../../store/useStore";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  width: 50
});

export const HarvestButtonVertical = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const usestore = useStore();
  const harvestRepos = usestore.reposHarvest.harvestRepos;
  const changeHarvestRepos = usestore.reposHarvest.changeHarvestRepos;

  const handleChange = (e) => {
    var safra = JSON.parse( window.jQuery(e.currentTarget).attr('value') );
    localStorage.setItem("creationHarvestRepos", JSON.stringify(safra));
    changeHarvestRepos(safra);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var style = {top: 25, backgroundColor: "#fff", color: '#555'};
  if (window.innerWidth < 400) {
    style = {top: 25, backgroundColor: "#fff", color: '#555', width: 40, height: 40};
  }
  return (
    <>
      <Fab sx={style} onClick={handleClick} aria-label="add" >
        <SpaIcon sx={{ color: "#555" }} />
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ml: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: 1,
            },
            "&:before": {
              content: '""',
              display: "none",
              position: "absolute",
              top: 20,
              right: 217,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "right" }}
      >
        <Box sx={{ ml: 2 }}>
          <ListItemText>Safras</ListItemText>
        </Box>
        <Divider />
        <Grid container spacing={2} sx={{ mb: 1.1, mt: 1 }}>
          <Grid item xs={12} sm={12}>
            <NavLink to={{ pathname: !harvestRepos || !harvestRepos.id ? "/harvest" : "/controlpanelharvest/" + harvestRepos.id_property + "/" + harvestRepos.id}} className="text-success"
              onClick={(e) => { handleChange(e); }} value={JSON.stringify(harvestRepos || '')} >
              <Grid container spacing={1}>
                <Grid item xs={4} sm={4}>
                  <Img sx={{ mt: -0.5 }} alt="complex" src={harvestRepos.cultivation_icon || "/dist/img_fp/icon-button/icon-agricolaV1.png"} />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography variant="body2" component="div">
                    {harvestRepos.label}
                  </Typography>
                </Grid>
              </Grid>
            </NavLink>
          </Grid>
        </Grid>
        <Divider />
        <MenuItem sx={{ mb: -1 }}>
          <ListItemText>
            <NavLink to="/harvest" className="">
              <a className="text-secondary">Ver todas as safras ...</a>
            </NavLink>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
