import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, Divider, Grid, ListItem, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import Typewriter from '../../../../components/Typewriter/Typewriter'
// import FooterModal from '../../../../components/Footer/FooterModal'
import { Input, } from '@mui/icons-material'
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner'

export default function FormPersonStep4({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTextFase(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  // const isError = () => false

  const returnError = () => false

  // console.log(data)

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Confirmação dos dados</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 30 }}>
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter
                    tam={60}
                    text={'Pronto! Agora basta conferir os dados da pessoa e clicar em salvar!'}
                    variant={'subtitle1'}
                    color={'#2e7d32'}
                  />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 30 }}>
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter
                    text={'Pronto! Agora basta conferir os dados dos produtos e clicar em salvar!'}
                    variant={'subtitle1'}
                    color={'#2e7d32'}
                  />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Divider sx={{ mt: 1, mb: 5, ml: -3, borderColor: '#388e3c' }} />

      <Grid container spacing={3} sx={{ mb: 2, backgroundColor: '#F6F6F6', py: 2, borderRadius: '6px' }}>
        {/* Informações gerais */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <Input sx={{ mr: 1 }} /> Dados gerais
                </Typography>
              </Stack>
            </Box>
          </Grid>

          {data.products.map((product) => (
            <>
              <Grid container spacing={3} sx={{ ml: 3 }}>
                {/* NOME */}
                <Grid item xs={1} md={1}>
                  <ListItem>
                    <ListItemText primary={<Typography fontWeight="bold">{'Quant.'}</Typography>} secondary={product.stock_quanty} />
                  </ListItem>
                </Grid>

                {/* NOME */}
                <Grid item xs={3} md={3}>
                  <ListItem>
                    <ListItemText primary={<Typography fontWeight="bold">{'Nome do produto'}</Typography>} secondary={product.name} />
                  </ListItem>
                </Grid>

                {/* UNIDADE */}
                <Grid item xs={3} md={3}>
                  <ListItem>
                    <ListItemText primary={<Typography fontWeight="bold">{'Unidade'}</Typography>} secondary={product.unit_name} />
                  </ListItem>
                </Grid>

                {/* VALOR */}
                {data.valor_produto !== 0 && (
                  <Grid item xs={2} md={2}>
                    <ListItem>
                      <ListItemText primary={<Typography fontWeight="bold">{'Valor'}</Typography>} secondary={product.stock_cost} />
                    </ListItem>
                  </Grid>
                )}

                {/* CATEGORIA */}
                <Grid item xs={3} md={3}>
                  <ListItem>
                    <ListItemText primary={<Typography fontWeight="bold">{'Categoria'}</Typography>} secondary={product.category.name} />
                  </ListItem>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>

      {/* <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} /> */}
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Box>
          {data && (
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button onClick={obj_fn.handleBack2} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button color="success" variant="contained" type="submit" onClick={obj_fn.onSubmitList}>
                Salvar
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}
