import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

function ActiveStepContent0() {
  return (
    <StepContent>
      <Typography>
        Para criar um projeto é só clicar no botão "CRIAR PROJETO", localizado no campo superior direito e seguir a sequência de passos.
        Defina o tipo de safra que deseja gerenciar: Feijão, Milho, Soja...
      </Typography>
    </StepContent>
  );
}

function ActiveStepContent1() {
  return (
    <StepContent>
      <Typography>
        Defina as datas do projeto. Para garantir uma execução eficiente e eficaz do projeto, é fundamental que definamos claramente as datas.
        Com um prazo estabelecido, poderemos avaliar os resultados com precisão e tomar decisões estratégicas para atingir nossos objetivos.
      </Typography>
    </StepContent>
  );
}

function ActiveStepContent2() {
  return (
    <StepContent>
      <Typography>
        Defina um ótimo nome para o seu projeto. Dar um nome para o projeto é importante para identificá-lo, comunicá-lo de forma clara e engajar as pessoas.
        Além disso, o nome ajuda na memorização do projeto e facilita a documentação e arquivamento das informações relacionadas a ele.
      </Typography>
    </StepContent>
  );
}

function ActiveStepContent3() {
  return (
    <StepContent>
      <Typography>
      Definir a localização da sua safra no Google Maps é fundamental para planejamento, gerenciamento e monitoramento da safra, auxiliando na tomada de decisões e na identificação de problemas.
      Vamos começar uma safra!
      </Typography>
    </StepContent>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    //"& .MuiStepIcon-active": { color: "green" },
    //"& .MuiStepIcon-completed": { color: "green" },
    "& .MuiStepIcon-root": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#808080" },
    "& .MuiButton-text .MuiButton-textSecondary": { color: "#808080" },
  },
}));

export default function StepperHarvest({ number }) {
  const [activeStep, setActiveStep] = React.useState(number);
  const colorStep = useStyles();
  const steps = ["Definição do tipo de criação", "Data de início e fim do projeto", "Nome do projeto", "Localização da criação"];

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper className={colorStep.root} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={Step + index}>
            <StepLabel>
              <strong style={{ fontSize: 16 }}>{step}</strong>
            </StepLabel>
            {/* <StepContent >
                            <Typography variant="body2" gutterBottom>{step.description}</Typography>
                        </StepContent> */}
            {index == 0 && <ActiveStepContent0 />}
            {index == 1 && <ActiveStepContent1 />}
            {index == 2 && <ActiveStepContent2 />}
            {index == 3 && <ActiveStepContent3 />}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
