import React, { useState } from 'react'
import {  Autocomplete, Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { Female, Male, AutoAwesomeMotion } from '@mui/icons-material'
import FormWeightHeader from './FormWeightHeader'
import FormWeightFooter from './FormWeightFooter'

export default function FormWeightStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false)
  setTimeout(() => {
    setShowTextFase(true)
  }, 500)

  const handleChange = (event, val_input) => {
    // console.log('handleChangeInput')

    obj_fn.setData((prevState) => ({ ...prevState, [event]: val_input }))
    obj_fn.setData((prevState) => ({ ...prevState, ['table_animals']: val_input ? val_input.list_animals : [] }))
  }

  // const para validar o button continuar
  const isError = () => {
    return !data.lote || data.lote?.length == 0
  }

  // aciona o botao retornar
  const returnError = () => {
    return false
  }

  const text_title = 'Informações Essenciais'
  const txt_msn = 'Selecione o lote e os animais desejados para a pesagem.'

  return (
    <>
      <FormWeightHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} />

      <Grid container>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid container spacing={1} sx={{}}>
            <Grid container justifyContent="center" sx={{}} spacing={2}>
              <Grid item xs={6} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AutoAwesomeMotion style={{ fontSize: 35 }} sx={{ mr: 1, mt: -3 }} />
                  <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      name="lote"
                      options={data.opt_lotes}
                      value={data.lote}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <span style={{ marginRight: '8px' }}>
                              <AutoAwesomeMotion />{' '}
                            </span>
                            {option.label}
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          name="lote"
                          color="success"
                          label="Selecione o Lote"
                          variant="standard"
                          value={data.lote}
                        />
                      )}
                      onChange={(e, value) => handleChange('lote', value)}
                      noOptionsText="Sem resultados"
                    />
                    <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                      <Typography variant="caption" display="block" gutterBottom sx={{ ml: 1, color: '#808080' }}>
                        Selecione o lote do qual deseja pesar os animais
                      </Typography>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={2}>
              <Grid item xs={3} md={3}>
                <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                  Total de animais:
                  <br />
                  {data.lote?.total_animals || 0}
                </Typography>
                <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                  Fase de Manejo:
                  <br />
                  {data.lote?.fase_name || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                  <Female color="secondary" /> Fêmeas: {data.lote?.num_femeas || 0}
                </Typography>
                <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                  <Male color="primary" /> Machos: {data.lote?.num_machos || 0}
                </Typography>
                <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                  Não identificados: {data.lote?.num_animals || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FormWeightFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  )
}
