import React, { useState, useContext } from "react";
import { Button, Dialog, AppBar, Container, DialogContent, Divider, Grid, IconButton, Paper, Slide, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import FormEditPerson from "./FormEditPerson"
import { Span } from "../../../../components/Typography";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { green } from "@mui/material/colors";
import { Context } from "../../../../components/Context/AuthContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// fillDialog normal que chama uma lista de botões de atividade
// cada botão chama o componete de atividades ... MultiStepFormInput 
export default function EditPerson({ id, usestore, tab_active }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const { aux_add, setAuxAdd } = useContext(Context);
  const header = { header: { "Content-Type": "application/json" } };

  const id_property = (localStorage.getItem("Property") || 1);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  // ------ inicicia os repos ------ 
  const reposPersons = usestore.reposPerson;

  // pega a person em edição
  const person = JSON.parse(JSON.stringify(reposPersons.getPersonStore('id', id)));

  const setDadosData = () => {
    // console.log("setDadosData");
    let this_person = JSON.parse(JSON.stringify(reposPersons.getPersonStore('id', id)));

    setData(this_person[0]);
  };

  const handleClickOpen = () => {
    // console.log("handleClickOpen");
    setDadosData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let formOk = true;
    // console.log(data);

    if (data.name.length < 5) {
      formOk = false;
    }

    if (formOk) {
      const res = await api.put("/person/update", data, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error("Erro ao editar pessoa!");
        }
        toast.success("Pessoa editada com sucesso!");

        reposPersons.fetch({ 'id_property': id_property }).then((res) => {
          setTimeout(() => {
            setAuxAdd(aux_add + 1);
            handleClose();
          }, 100);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao editar pessoa!");
      });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  if (!person || !person.length) {
    return (<></>);
  }
  return ( <>
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div">
                  Edição da Pessoa
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ mb: 8 }}>
          <Container component="main" maxWidth="lg" sx={{ }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <FormEditPerson data={data} setData={setData} reposPersons={reposPersons}/>
            </Paper>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>

      {tab_active[1] === 'lixeira' ? <></> :
      <IconButton aria-label="edicao" onClick={() => handleClickOpen()} title="Editar">
        <EditIcon sx={{ color: green[600] }} />{" "}
      </IconButton>}
    </div>
  </>);
}
