import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { useParams } from 'react-router-dom';
import AddActivities from '../../activitiesHarvest/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import { useEffect } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';

export default function StartProjectHarvestActivity() {
  const [open, setOpen] = React.useState(true);
  const { idFarm, id } = useParams();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  useEffect(() => {
    //console.log("useEffect=ActivitiesHarvestContent atualiza a tabela se true ");
    //console.log(isLoadingActivities);
    if (isLoadingActivities === true) {
      setIsLoadingActivities(false);
    }
  }, [isLoadingActivities]);

  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false); // Quando a imagem é carregada, o skeleton desaparece
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="Fazenda Premium Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Bem vindo a Atividades de Campo, seu Caderno de Campo digital.
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text={`A seguir, basta criar as atividades de campo e gerenciar os insumos e as depesas!`} />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  height={400} // Você pode ajustar a altura conforme necessário
                  style={{ margin: '0 auto' }} // Centraliza o skeleton também
                />
              )}
              <img
                src="/dist/image/gestaoagricola_activity.png"
                alt="Gestão Pecuária"
                onLoad={handleImageLoad} // Chama quando a imagem terminar de carregar
                style={{
                  width: '90%', // Ajusta a largura da imagem
                  height: 'auto', // Mantém a proporção da altura
                  objectFit: 'contain', // Garante que a imagem não estique
                  display: isLoading ? 'none' : 'block', // Oculta a imagem enquanto está carregando
                  margin: '0 auto', // Centraliza a imagem horizontalmente
                }}
              />
            </Grid>

            {/* <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 2 }}>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  <strong>Como gerenciar as atividades de campo?</strong>
                </Typography>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  Para gerenciar as atividades de campo de forma eficaz, é essencial registrar cada etapa do processo, desde o preparo do solo até a colheita. Com o Fazenda Premium, essa tarefa se
                  torna rápida e simples.
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
          {/* <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Com o projeto criado, basta agora registrar as atividades de campo que o Fazenda Premium organiza as informações.
              </Typography>
            </Box>
          </Grid> */}
          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 3 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Clique a seguir no <strong style={{ color: '#2e7d32' }}>botão verde "NOVA ATIVIDADE DE CAMPO"</strong> e inciaremos os registros das atividades de campo...
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
              <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
            </Stack>
          </Grid>
          {/* <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                <span style={{ color: '#a82223' }}>Se precisar de ajuda, clique no botão</span>{' '}
                <span style={{ color: '#2e7d32' }}>
                  'Passo a Passo' <StepIcon color={'#2e7d32'} />
                </span>{' '}
                <span style={{ color: '#a82223' }}> para obter orientações.</span>
              </Typography>
            </Box>
          </Grid> */}

          {/* <DialogContentText>Ao criar um projeto pecuário, incia-se a gestão pecuária.</DialogContentText> */}
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
            }}
            color="success"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
