import { Box, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';

export default function CardLivestockInfo({ rows, livestok }) {
  const [showText, setShowText] = useState(false);
  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  // console.log('rows ');
  // console.log(rows);
  // console.log(livestok);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                          <>
                            <strong>Planeje, Controle e Monitore as Atividades de Campo</strong>
                          </>
                        </Typography>
                        <Typography fontWeight="400" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            {/* <strong>Atividades: </strong> */}
                            {showText ? (
                              rows.length === 1 ? (
                                <>
                                  <Typewriter
                                    variant={'subtitle2'}
                                    tam={40}
                                    Livestok
                                    text={`Aṕos a entrada dos animais é importante continuar seus manejos. Clique em "Nova atividade de campo"`}
                                    color={'#2e7d32'}
                                  />
                                  {/* <Typewriter
                                    variant={'subtitle2'}
                                    tam={40}
                                    Livestok
                                    text={`Você tem 1 atividade de entrada que contém 1 lote (${rows[0]?.nameBatch}) com ${rows[0]?.numberAnimals} animais na área (${rows[0]?.areaName}).`}
                                    color={'#2e7d32'}
                                  /> */}
                                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                    {`Registre as atividades de campo, clicando no botão verde (+ NOVA ATIVIDADE) na parte superior `}
                                  </Typography>
                                  {rows[0]?.progress !== 'CONCLUÍDA' && (
                                    <>
                                      <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                        {`A Atividade de entrada encontra-se em (${rows[0]?.progress})`}
                                      </Typography>
                                      <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                        {`Atualizando (${rows[0]?.progress}) para concluída, você poderá realizar os outros manejos.`}
                                      </Typography>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typewriter
                                    variant={'subtitle2'}
                                    tam={40}
                                    text={`Você realizou ${rows.length} atividades de campo que contém  ${livestok.lotes?.length} lote(s) com ${livestok?.total_animais} animais em ${livestok.areas?.length} áreas de produção.`}
                                    color={'#2e7d32'}
                                  />
                                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                    Gerencie suas atividades, clicando no botão verde (+ NOVA ATIVIDADE) no canto superior direito
                                  </Typography>
                                </>
                              )
                            ) : (
                              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                {' '}
                                <ThreeDotsSpinner />
                              </Box>
                            )}
                          </>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 2 }} variant="subtitle2">
                      <strong>Movimentações: </strong> Analise o histórico por período de todas as movimentações de entradas e saídas
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Estoque: </strong>com os produtos cadastrados, tenha visibilidade dos produtos e disponíveis no seu estoque
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Histórico do produto: </strong> Analise o histórico de entrada e saída de cada categoria de produto.
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Locais de estoque: </strong>Locais de estoque: Tenha apenas um estoque ou crie e visualize os seus produtos em estoques
                      diferentes.
                    </Typography>
                  </Grid> */}
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
    </>
  );
}
