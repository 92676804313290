import React, { useEffect, useState, useContext } from 'react'
import { Box, ListItem, ListItemText, Divider, Button, TextField } from '@mui/material'
import { AppBar, Grid, Stack, Toolbar, Typography } from '@mui/material'
import { green, orange } from '@mui/material/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import LinkIcon from '@mui/icons-material/Link'
import ShareIcon from '@mui/icons-material/Share'
import ModalLandpageContent from './ModalLandpageContent'

export default function FormIndividualMarketingtStep3({ data, obj_fn, page, onSubmit }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false)
  setTimeout(() => {
    setShowTextFase(true)
  }, 500)

  const immutableUrl = 'http://localhost:3000/landpage/fazendacpt/' // Parte imutável da URL

  // Estado para a parte variável da URL
  const [variableUrl, setVariableUrl] = useState(page.url ? page.url.replace(immutableUrl, '') : '')

  // Função para manipular a mudança na parte variável da URL
  const handleVariableUrlChange = (event) => {
    setVariableUrl(event.target.value)
  }

  // Função para construir a URL completa
  const fullUrl = immutableUrl + variableUrl

  // Valida o button continuar
  const isError = () => {
    return false
  }
  // Valida o button retornar
  const returnError = () => {
    return true
  }

  const handleSubmit = () => {
    console.log('publicar')
    onSubmit()
  }

  const shareUrl = () => {
    // Verifica se o navegador suporta a API de compartilhamento
    if (navigator.share) {
      navigator
        .share({
          title: 'Compartilhar esta URL',
          text: 'Confira esta URL',
          url: fullUrl,
        })
        .then(() => console.log('URL compartilhada com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar URL:', error))
    } else {
      // Caso o navegador não suporte a API de compartilhamento
      console.log('Navegador não suporta a API de compartilhamento.')
      // Aqui você pode implementar outra lógica de compartilhamento, como copiar para a área de transferência
    }
  }

  return (
    <>
      <Divider />

      {/* <Grid container spacing={2} sx={{ mt: 1 }}>
       
        <Grid container spacing={3} sx={{ mt: 5, mb: 3 }}>
          <LinkIcon />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Link da página</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Insira um nome específico para sua página ser visualizada na internet.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
           
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
              <Typography style={{ color: '#2e7d32' }} variant="body1">
                {immutableUrl}
              </Typography>
              <TextField color="success" sx={{ mt: -2 }} id="variableUrl" label="url" variant="standard" fullWidth value={variableUrl} onChange={handleVariableUrlChange} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}></Grid>
      </Grid> */}

      {/* <Divider sx={{ mb: 3 }} />

      <Button color="primary" onClick={shareUrl} startIcon={<ShareIcon />}>
        Compartilhar
      </Button> */}

      <Divider sx={{ mb: 3, mt: 3 }} />

      <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
        <Stack spacing={0.5}>
          <Typography fontWeight={700}> Confirmação do card </Typography>
        </Stack>
      </Grid>

      <ModalLandpageContent page={page} />

      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            <Button color="success" variant="contained" type="submit" onClick={!isError() ? handleSubmit : () => null} disabled={isError()}>
              Salvar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
