import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconAgriculturalApplications = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 179.000000 187.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,187.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M807 1793 c-13 -12 -7 -58 12 -89 15 -24 37 -38 93 -60 40 -15 77
-31 81 -35 5 -3 15 -55 23 -115 20 -156 37 -196 117 -280 57 -61 65 -73 52
-84 -32 -27 -172 129 -200 223 -8 28 -18 82 -22 119 l-6 68 -288 0 c-275 0
-289 -1 -299 -19 -12 -24 -13 -51 0 -71 7 -12 45 -16 197 -20 180 -5 188 -6
188 -25 0 -19 -8 -20 -224 -23 -145 -1 -229 -6 -238 -13 -17 -15 -17 -73 0
-88 9 -7 90 -12 228 -13 206 -3 214 -4 214 -23 0 -19 -8 -20 -205 -25 l-205
-5 -12 -27 c-9 -18 -10 -34 -3 -52 l9 -26 218 -2 c204 -3 218 -4 221 -22 2
-10 -2 -23 -10 -27 -7 -5 -89 -9 -181 -8 -118 0 -172 -3 -182 -11 -18 -15 -20
-61 -3 -78 9 -9 115 -12 415 -12 l404 0 91 30 c72 24 108 30 174 30 l84 0 0
240 0 240 -85 0 c-49 0 -99 6 -118 13 -18 8 -75 59 -127 113 l-95 100 -142 42
c-146 43 -165 47 -176 35z m163 -667 c32 -14 56 -63 43 -85 -12 -20 -55 -26
-82 -11 -25 13 -64 87 -57 106 6 15 51 10 96 -10z"
          />
          <path
            d="M1010 855 c0 -14 9 -34 19 -44 18 -18 90 -45 99 -37 2 2 -2 22 -8 45
-12 45 -33 61 -79 61 -26 0 -31 -4 -31 -25z"
          />
          <path
            d="M904 802 c-15 -10 -34 -47 -34 -66 0 -21 44 -21 64 0 22 22 21 74 -1
74 -10 0 -23 -4 -29 -8z"
          />
          <path
            d="M280 475 l0 -295 635 0 635 0 0 295 0 295 -140 0 -140 0 0 -27 c0
-40 -42 -123 -81 -161 -132 -130 -344 -98 -431 64 -14 27 -29 66 -32 87 l-7
37 -219 0 -220 0 0 -295z"
          />
        </g>
      </SvgIcon>
    </>
  );
};
