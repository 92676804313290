import ForgotPasswordContent from "./ForgotPasswordContent";

function ForgotPassword() {
    return (
        <>

            <ForgotPasswordContent />

        </>
    );
}

export default ForgotPassword;

