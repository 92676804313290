import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from "@mui/material";
import { Queue } from '@mui/icons-material';
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric';
import GenericValueField from '../../../../components/Fields/GenericValueField';
import { formatValue } from '../../../../utils/functions';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import SelectPackagingUnit from '../../../../components/Fields/SelectPackagingUnit';

export default function FormAddProductSales({ data, obj_fn, handleClose }) {
  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.listProducts));
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [location_selected, setLocation] = useState(selectedProduct?.id_location || null);

  const handleChange = (event) => {
    setSelectedProduct(prevState => ({ ...prevState, [event.target.name]: parseFloat(event.target.value) }));
  }
  // const handleChangeUnit = (event) => {
  //   obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  // }

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.products));
    selectedProduct.id_location = location_selected.id;
    selectedProduct.packaging_unit = data.packaging_unit;
    selectedProduct.packaging_sigla = data.packaging_unit === 'unidade' ? 'Un' : selectedProduct.unit_sigla;
    selectedProduct.discount = 0;
    aux_add.push(selectedProduct);

    setSelectedProduct(null);
    setLocation(null);

    var val_total = 0;
    var aux_val = 0;
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value);
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100;

      val_total += aux_val;
      aux_add[i].value_end = aux_val;
    }
    var aux_value = (data.value - data.products_value) + val_total;
    var aux_value_end = (data.value_end - data.products_value) + val_total;

    obj_fn.setData(prevState => ({
      ...prevState, 
      products_value: val_total,
      products: aux_add,
      value: aux_value,
      value_end: aux_value_end,
      product_selected: null,
      packaging_unit: 'unidade'
    }));

    handleClose();
  }

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    let aux_prod = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    if (aux_prod) {
      let aux_location = aux_prod.location.length > 0 ? aux_prod.location[0] : null;
      aux_prod.id_patrimony = aux_location?.id_patrimony;
      aux_prod.id_location = aux_location;
      setLocation(aux_location);
    } else {
      setLocation(null);
    }
    setSelectedProduct(aux_prod);
    obj_fn.setData(prevState => ({ ...prevState, product_selected: aux_prod }));
  }

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: ('Produto para: ' + data.operationType),
    values: optionsProducts,
    helperText: (<>
      Selecione o produto desejado
      <br/>
      Valor unitário: {formatValue((selectedProduct?.stock_cost || 0), true)}
    </>),
    listHeight: 225,
    disableClearable: true,
  }

  const opt_products_local = {
    multiple: false,
    name: 'product_location_' + selectedProduct?.id,
    field: 'product_location',
    label: 'Local de estoque (origem)',
    values: (selectedProduct?.location || []),
    helperText: (<>
      Local onde o produto está armazenado!
      <br/>
      <span style={{ color: 'green' }}>Qtd. em estoque: </span>
      {/* {selectedProduct?.packaging_name === 'Unidade' ? <></> : <>{location_selected?.quanty} {selectedProduct?.packaging_sigla} <br/></>}
      {location_selected?.quanty_volume ? location_selected?.quanty_volume : (location_selected?.quanty || 0) * (data.packaging_unit === 'unidade' ? 1 : selectedProduct?.volume_pkg || 1)} {selectedProduct?.unit_sigla} */}
      {!selectedProduct?.id ? <>-</> :
        (selectedProduct?.packaging_name === 'Unidade' ? '' : 
          formatValue((location_selected?.quanty || 0), '', {prefix: "(" + selectedProduct?.packaging_sigla + ")", end: true}) + ' ou ') +
        (formatValue( ((location_selected?.quanty || 0) * (selectedProduct?.volume_pkg || 1)), '', {prefix: "(" + selectedProduct?.unit_sigla + ")", end: true }) )
      }
    </>),
    listHeight: 225,
    disabled: !data.product_selected?.id,
    disableClearable: true,
  }
  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal')
    setSelectedProduct(prevState => ({
      ...prevState, 
      id_patrimony: newValue?.id,
      id_location: newValue || null
    }));
    let aux_local = JSON.parse(JSON.stringify(newValue));
    setLocation(aux_local);
  }

  // const para validar os campos e o button add ao carrinho
  const validFields = (type) => {
    let text = '';
    var mensagem = (<></>);
    let error = '';
    let is_error = false;

    let qtd = selectedProduct?.quant;
    // let qtd = (data.packaging_unit !== 'unidade' ? selectedProduct?.quant / (selectedProduct?.volume_pkg || 1) : selectedProduct.quant);
    if (type === 'quantidade' || type === 'isError') {
      let qtd_estoq = location_selected?.quanty * (data.packaging_unit === 'unidade' ? 1 : (selectedProduct?.volume_pkg || 1));
      // let qtd_estoq = (location_selected?.quanty || 0) * (selectedProduct?.new_prod ? selectedProduct?.volume_pkg : data.packaging_unit === 'unidade' ? 1 : selectedProduct?.volume_pkg || 1);
      text = "Qtd. de produtos para Venda";

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0!';
        is_error = true;
      } else if (qtd > qtd_estoq) {
        error = 'Não pode ser maior do que a Qtd em estoque';
        is_error = true;
      }
    }
    if (type === 'valor' || type === 'isError') {
      let valor = selectedProduct?.value;
      text = (<>
        Defina o valor total dos produtos
        {selectedProduct?.new_prod ? <></> : <>
          <br />
          Valor estimado: {formatValue(selectedProduct?.stock_cost * qtd, true)}
        </>}
      </>);

      if (!valor) {
        error = 'Valor deve ser maior que 0!';
        is_error = true;
      }
    }
    if (type === 'isError') {
      return selectedProduct?.id ? is_error : true;
    }

    if (error && selectedProduct?.id) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  useEffect(() => {
    // Verifica as condições e define se o botão deve estar desabilitado
    const isDisabled = validFields('isError');
      obj_fn.setbtnAdd(
        <Button
          variant="contained"
          color="success"
          onClick={handleAdd}
          disabled={isDisabled}
          sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite` }}
          id="btn_add_item"
        >
          <Queue sx={{ mr: 1 }} /> Adicionar ao carrinho
        </Button>
      );
  }, [selectedProduct]);

  return ( <>
    <Grid container spacing={3} >
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 3 }}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
            <AutocompleteGeneric value={data.product_selected} options={opt_products} handleChange={handleChangeSelect} />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: obj_fn.ismobile ? -1 : 0 }} >
            <AutocompleteGeneric value={location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <SelectPackagingUnit data={data} obj_fn={obj_fn} product={selectedProduct} tipo={'venda'}/>
          </Grid>
          <Grid item xs={6} sm={3.5}>
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id:"quant",
                name:"quant",
                label: 'Quantidade',
                value: selectedProduct?.quant,
                variant: 'outlined',
                helperText: validFields('quantidade'),
                disabled: !data.product_selected,
                endAdornment: true,
                prefix: (
                  data.packaging_unit === 'unidade' ? 
                  selectedProduct?.packaging_sigla ? " (" + selectedProduct?.packaging_sigla + ") " : '' :
                  selectedProduct?.unit_sigla ? " (" + selectedProduct?.unit_sigla + ") " : ''
                )
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3.5}>
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id:"value",
                name:"value",
                label: 'Valor total',
                value: selectedProduct?.value,
                variant: 'outlined',
                helperText: validFields('valor'),
                disabled: !data.product_selected,
                endAdornment: true,
                prefix: " (R$) ",
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Button variant="contained" color="success" onClick={handleAdd} disabled={validFields('isError')}>
              <Queue sx={{mr: 1}}/> Adicionar ao carrinho
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Grid>
  </>)
}