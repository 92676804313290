import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import MultiStepFormPlan from "../components/FormPlan/MultiStepFormPlan";
import SignpostIcon from "@mui/icons-material/Signpost";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormGoalButtonPlanning() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={handleClickOpen} >
        <SignpostIcon />
      </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Planejamento da safra de Milho
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MultiStepFormPlan handleClose={handleClose} />
        </Box>
      </Dialog>
    </div>
  );
}
