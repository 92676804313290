import React, { useEffect } from 'react';
import { AppBar, Box, Button, Container, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from '@mui/material';
import { AddBox, Close } from '@mui/icons-material';
// import MultiStepFormEntry from '../FormMain/MultiStepFormEntry'
import MultiStepFormEntry from '../FormMain/FormPayments/MultiStepFormEntry';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterNewReleaseDialog({ type, setButtonType, openDialog, handleCloseDialog }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setButtonType('');
    handleCloseDialog(false);
    setOpen(false);
  };

  let color_text = type === 'income' ? '#2e7d32' : type === 'expense' ? '#d32f2f' : '#FFF';
  return (
    <div>
      <Button
        variant="outlined"
        color={type === 'income' ? 'success' : type === 'expense' ? 'error' : 'inherit'}
        style={{ color: color_text, backgroundColor: type != 'income' && type != 'expense' && '#000' }}
        startIcon={<AddBox />}
        onClick={handleClickOpen}
      >
        {type == 'income' ? 'Valor a Receber' : type == 'expense' ? 'Nova Despesa' : 'Novo Lançamento'}
      </Button>
      <Dialog fullScreen open={open || false} onClose={handleClose} TransitionComponent={Transition}>
        <Box id="focus_top_modal">
          <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
            <Toolbar>
              <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                {type == 'income' ? 'Conta a receber' : type == 'expense' ? 'Conta a pagar' : 'Novo Lançamento'}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <Close sx={{ color: '#000' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="lg" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 2, md: 4 }, p: { xs: 1, md: 2 } }}>
              <MultiStepFormEntry type={type} handleClose={handleClose} />
            </Paper>
          </Container>
          {/* <FormGoalTarget handleClose={handleClose} /> */}
        </Box>
      </Dialog>
    </div>
  );
}
