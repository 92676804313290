import React from 'react'
import NavSide from '../../components/Nav/NavSide'
import NavBotton from '../../components/Nav/NavBotton'
import Footer from '../../components/Footer/Footer'
import { useParams } from 'react-router-dom'
import ProductsContentTabs from './ProductsContentTabs'

export default function Products() {
  const { tab } = useParams() // Obtém o parâmetro de rota para determinar qual tab será selecionada

  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <ProductsContentTabs tab={tab} />
      <Footer />
    </>
  )
}

// testar a inserção de dados do José
