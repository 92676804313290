import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Button, Divider } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useHarvestReposStore from "../../../store/useHarvestReposStore";
import { useState } from "react";
import useHarvestTargetReposStore from "../../../store/useHarvestTargetReposStore";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../../components/Context/AuthContext";
import { useEffect } from "react";
import SignpostIcon from "@mui/icons-material/Signpost";
import useHarvestTargetCostReposStore from "../../../store/useHarvestTargetCostReposStore";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CardMetaCostHarvest({activePlanTargetReposHarvest, planTargetReposFilterHarvest}) {
  const navigate = useNavigate();
  const { idFarm, id } = useParams();
  const [value, setValue] = React.useState(0);

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  // <div className="row" style={{ height: 500, overflowY: "scroll" }}>

  // // instancia os repos da safra atual
  // const reposHarvest = useHarvestReposStore((state) => state);
  // const listHarvests = reposHarvest.getHarvestStore("id", id);

  // const [dataHarvest, setDataHarvest] = useState(listHarvests[0]);
  // console.log("1-dataHarvest")
  // console.log(dataHarvest)

  // // instancia os repos do planejamento
  // // instancia os repos planning se já estiver algum planejamento
  // const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  // console.log("2-reposTargetPlanning1");
  // console.log(reposTargetPlanning);
  // console.log(reposTargetPlanning.listPlanning)
  // //console.log(reposPlanning.planningRepos);
  // //filtando a lista dos planejamentos por safra
  // const planTargetReposFilterHarvest = reposTargetPlanning.listPlanning.filter(item => item.id_harvest == id)
  // console.log("planTargetReposFilterHarvest")
  // console.log(planTargetReposFilterHarvest)
  // // tenho agora as listas de metas giltradas
  // // preciso somar o valor do campo expectedYield da lista

  // const activePlanTargetReposHarvest = planTargetReposFilterHarvest?.filter(item => item.isActiveTargetHarvest === 1)[0]
  // console.log(activePlanTargetReposHarvest)

  // meta: produção esperada
  console.log("Visão: meta");
  const [target, setTarget] = React.useState({
    sun: activePlanTargetReposHarvest?.expectedYield,
    unitCost: activePlanTargetReposHarvest?.unitCost
  });
  console.log(target);

  //# meta: produtividade esperada
  const sunExpectedYield = planTargetReposFilterHarvest.map(item => item.expectedYield).reduce((acc, valor) => acc + valor, 0);
  console.log(sunExpectedYield)
  const unitCost = planTargetReposFilterHarvest[0]?.unitCost || 0

  // const sunArea = planTargetReposFilterHarvest.map(item => item.area).reduce((acc, valor) => acc + valor, 0);
  // console.log(sunArea)
  // const planRepos = reposTargetPlanning.getPlanningStore("id", reposTargetPlanning.planningRepos.id);
  // console.log(planRepos[0]);
  // const valueProductivity = sunExpectedYield / sunArea;
  //const valueProductivity = 0

  const valueProductivity = activePlanTargetReposHarvest?.expectedYield / activePlanTargetReposHarvest?.area;
  //console.log(valueProductivity);

  const [productivity, setProductivity] = React.useState(valueProductivity?.toFixed(2) || 0);
  console.log(productivity);


  console.log("Visão: custo1");
  const reposPlanProcutionCostHarvest = useHarvestTargetCostReposStore((state) => state);
  console.log("reposPlanProcutionCostHarvest#1");
  //console.log(reposPlanProcutionCostHarvest);
  //filtando a lista dos custos de planejamento por safra
  const reposPlanProcutionCostFilterHarvest = reposPlanProcutionCostHarvest.listHarvestTargetCost.filter(item => item.id_harvest == id)
  console.log("3-reposPlanProcutionCostFilterHarvest")
  console.log(reposPlanProcutionCostFilterHarvest)
  console.log(reposPlanProcutionCostFilterHarvest.length)


  //activePlanTargetReposHarvest
  console.log(activePlanTargetReposHarvest)
  const ActivePlanProcutionCostFilterHarvest = reposPlanProcutionCostFilterHarvest.filter(item => item.id_target === activePlanTargetReposHarvest?.id)[0]
  console.log("ActivePlanProcutionCostFilterHarvest") 
  console.log(ActivePlanProcutionCostFilterHarvest) 
  //const listCostProduction = []

  // for (const costProduction of reposPlanProcutionCostFilterHarvest) {
  //   console.log(costProduction)
  //   let item = reposPlanProcutionCostHarvest.getPlanningStore("id", costProduction.id)[0];
  //   listCostProduction.push(item)

  // }
  //console.log("listCostProduction")
 // console.log(listCostProduction)

  // function sumCoins(array, field) {
  //   const sum = array.reduce((acc, item) => {
  //     const cleanedValue = parseFloat(item[field].replace('.', ''));
  //     return acc + cleanedValue;
  //   }, 0);

  //   //return sum; // Retorna a soma com duas casas decimais
  //   const formatter = new Intl.NumberFormat('pt-BR', {
  //     style: 'currency',
  //     currency: 'BRL',
  //   });

  //   const formattedSum = formatter.format(sum);

  //   return formattedSum; // Retorna a soma formatada em moeda
  // }


  // somar o custo esperado da lista listCostProduction
  //const sunExpectedCost = listCostProduction.map(item => parseFloat(item.productionCost)).reduce((acc, valor) => acc + valor, 0);
  //const sunExpectedCost = sumCoins(listCostProduction, "productionCost");
  //console.log("sunExpectedCost")
  //console.log(sunExpectedCost)

  //map(item => parseFloat(item.expectedYield.replace(/[,.]/g, ''))).reduce((acc, valor) => acc + valor, 0);
  // somar o custo total da lista listCostProduction
  //const sunTotal = listCostProduction.map(item => parseFloat(item.totalProductionCost.replace(/[.]/g, ''))).reduce((acc, valor) => acc + valor, 0);
  //console.log(sunTotal)
  //const totalProductionCost = sumCoins(listCostProduction, "totalProductionCost");
  //console.log(totalProductionCost)

  //const expectedSalesValue = listCostProduction.map(item => parseFloat(item.saleUnitValue.replace(/[.]/g, ''))).reduce((acc, valor) => acc + valor, 0);
  //console.log(expectedSalesValue)
  //const expectedSalesValue = sumCoins(listCostProduction, "saleUnitValue");
  //console.log(expectedSalesValue)

  //const totalProfit = sumCoins(listCostProduction, "totalProfit");
  //console.log(totalProfit)

  //const planHarvestRepos = reposPlanProcutionCostHarvest.getPlanningStore("id", reposPlanProcutionCostHarvest.planningRepos.id)[0];
  //console.log(planHarvestRepos);

  const [productionValues, setProductionValues] = React.useState({
    sunExpectedCost: ActivePlanProcutionCostFilterHarvest?.productionCost,
    totalProductionCost: ActivePlanProcutionCostFilterHarvest?.totalProductionCost,
    expectedSalesValue: ActivePlanProcutionCostFilterHarvest?.saleUnitValue,
    unitCost: ActivePlanProcutionCostFilterHarvest?.unitCost,
    totalProfit: ActivePlanProcutionCostFilterHarvest?.totalProfit
  });

  console.log(productionValues)

  //const [values, setValues] = React.useState(planHarvestRepos);

  const { aux_add } = useContext(Context);

  // useEffect(() => {
  //   console.log("refresh ");
  //   setValues(planHarvestRepos);
  // }, [aux_add]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} textColor="success" scrollButtons={true} TabIndicatorProps={{ style: { background: "green" } }} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Esperada" {...a11yProps(0)} />
            <Tab label="Realizada" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: meta</Typography>
              </Grid>
            </Grid>
            {activePlanTargetReposHarvest ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Produção Esperada</Typography>
                  <Typography variant="subtitle2">
                    <strong>{target?.sun}</strong> <span style={{ fontSize: "0.8em" }}>{target?.unitCost}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Produtividade Esperada</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    <strong>{productivity}</strong> <span style={{ fontSize: "0.8em" }}>sacos 60kg/ha</span>{" "}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                  <Button variant="outlined" color="success" startIcon={<SignpostIcon />} onClick={() => navigate("/production/" + idFarm + "/" + id)}>
                    Meta de produção
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: custo</Typography>
              </Grid>
            </Grid>
            {productionValues.expectedSalesValue ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <PriceChangeIcon />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo Esperado
                      </Typography>
                      <Typography variant="body2" component="div">
                        <strong>{productionValues?.sunExpectedCost}</strong> <span style={{ fontSize: "0.8em" }}>{productionValues?.unitCost}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo total da produção
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" component="div">
                    R$ 50.000,00 / 100 sacos de 60kg
                  </Typography> */}
                      <Typography variant="body2" component="div">
                        R$ {productionValues?.totalProductionCost}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                  <Button variant="outlined" color="success" startIcon={<SignpostIcon />} onClick={() => navigate("/production/" + idFarm + "/" + id)}>
                    Planejamento financeiro
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: venda</Typography>
              </Grid>
            </Grid>
            {productionValues.expectedSalesValue ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption" sx={{ color: "#28a745" }} color="success" component="div">
                        Valor esperado para venda
                      </Typography>

                      <Typography variant="body2" sx={{ color: "#28a745" }} component="div">
                        R$ {productionValues?.expectedSalesValue} {productionValues?.unitCost} 
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Lucro esperado:
                      </Typography>
                      <Typography variant="body2" component="div">
                       R$ {productionValues?.totalProfit}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                  <Button variant="outlined" color="success" startIcon={<SignpostIcon />} onClick={() => navigate("/production/" + idFarm + "/" + id)}>
                    Planejamento financeiro
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: cenário atual</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Produção Realizada</Typography>
              <Typography variant="subtitle2">
                <strong>0</strong> <span style={{ fontSize: "0.8em" }}>sacos 60kg</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Produtividade Realizada</Typography>
              <Typography variant="subtitle2">
                {" "}
                <strong>0</strong> <span style={{ fontSize: "0.8em" }}>sacos 60kg/ha</span>{" "}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: custo atual</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <PriceChangeIcon />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Custo Atual
                  </Typography>
                  <Typography variant="body2" component="div">
                    R$ 0 /saco de 60kg
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Custo da produção / Número de animais
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="div">
                    R$ 0/saco de 60kg
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <VisibilityIcon sx={{ fontSize: 18 }} />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="caption">Visão: projeção da venda</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Valor para venda
                  </Typography>
                  <Typography variant="h6" component="div">
                    R$ 0 <span style={{ fontSize: "0.8em" }}>/saco 60kg</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography variant="caption" color="text.secondary" component="div">
                    Lucro atual
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#28a745" }} component="div">
                    R$ 0<span style={{ fontSize: "0.8em" }}>/saco 60kg</span>
                    <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div">
                      (+25%)
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
}
