import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SpaIcon from '@mui/icons-material/Spa'
import { Context } from '../../../components/Context/AuthContext'
import { expandAndFade, expandAndFadeLine } from '../../../components/Animations/animationKeyFrames'
import LightTooltip from '../../../components/Tooltip/LightTooltip'
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

//sort list
const sortList = (list) => {
  let userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const stgs_lv = userCurrent.settings.safra
  if (!stgs_lv.first) {
    return list
  }
  const sortedData = [
    ...list.filter((item) => item.id == stgs_lv.first),
    ...list.filter((item) => item.id == stgs_lv.second),
    ...list.filter((item) => item.id == stgs_lv.third),
    ...list.filter((item) => item.id != stgs_lv.first && item.id != stgs_lv.second && item.id != stgs_lv.third),
  ]
  return sortedData
}

export default function CardSafras({ data_cards, changeRepos }) {
  const navigate = useNavigate()
  const safras_nf = Object.assign(
    [],
    data_cards.safras.filter((safra) => safra.situacao != 'finalizada')
  )
  const sortlist = sortList(safras_nf)
  const duas_safras = sortlist.splice(0, 2)

  const { menuContext, setMenuContext } = React.useContext(Context)

  const handleChange = (e) => {
    var harvest = JSON.parse(window.jQuery(e.currentTarget).attr('value'))
    localStorage.setItem('creationHarvestRepos', JSON.stringify(harvest))
    localStorage.setItem('ButtonActive', 'Painel')
    localStorage.setItem('navMenuActive', 'selectCreationHarvest')

    changeRepos(harvest)
    setMenuContext(new Date().getTime())
  }
  function getAreaUsed(areas) {
    var usedArea = 0
    for (var i = 0, tam = areas.length; i < tam; i++) {
      usedArea += parseFloat(areas[i].usedArea)
    }
    return usedArea
  }

  return (
    <Card>
      <CardContent>
        {data_cards.safras.length > 0 ? (
          <Grid container spacing={2}>
            {duas_safras.map((safra) => (
              <Grid item xs={12} sm={12} key={safra.id}>
                <NavLink
                  to={'/controlpanelharvest/' + safra.id_property + '/' + safra.id}
                  className="text-success"
                  onClick={(e) => {
                    handleChange(e)
                  }}
                  value={JSON.stringify({
                    label: safra.name,
                    id: safra.id,
                    id_property: safra.id_property,
                    id_cultivation: safra.id_cultivation,
                    cultivation_icon: safra.cultivation_icon,
                    tipo_repos: 'Safras',
                  })}
                >
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item sx={{ width: 50 }}>
                      <Img alt="complex" src={safra.cultivation_icon || '/dist/img_fp/icon-button/icon-agricolaV1.png'} />
                    </Grid>
                    <Grid item xs={6}>
                      <LightTooltip title="Acesse o projeto agrícola e realize a gestão das atividades de campo.">
                        <Typography sx={{ animation: `${expandAndFadeLine} 2s linear infinite` }} variant="body2" component="div">
                          {safra.name}
                        </Typography>
                      </LightTooltip>
                      <Typography variant="caption" color="text.secondary" component="div">
                        {safra.cultivation_name}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Categoria:&nbsp;{safra.group_name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Áreas: {safra.areas.length}&nbsp;-&nbsp;({getAreaUsed(safra.areas)} ha)
                      </Typography>
                    </Grid>
                  </Grid>
                </NavLink>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
            <IconButton sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" aria-label="upload picture" component="label" onClick={() => navigate('/harvest')}>
              <Grid item sx={{ width: 50 }}>
                <LightTooltip title="Crie um projeto agrícola e realize a gestão das atividades de campo.">
                  <SpaIcon />
                </LightTooltip>
              </Grid>
            </IconButton>
            <span>Clique aqui para registrar a sua safra</span>
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton sx={{ ml: 'auto' }}>
          <NavLink to="/harvest" className="nav-link">
            <Typography variant="body2" color="text.secondary" component="div">
              Ver todas as safras ...
            </Typography>
          </NavLink>
        </IconButton>
      </CardActions>
    </Card>
  )
}
