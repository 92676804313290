import React, { useState } from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import AreasMapsContent from "./AreasMapsContent";
import Footer from "../../components/Footer/Footer";

export default function AreasMaps() {
  const [data, setData] = useState([]);
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <AreasMapsContent />
      <Footer />
    </>
  );
}
