import React, { useEffect, useState, useContext } from "react";
import { Box, ListItem, Button, InputAdornment, Checkbox, LinearProgress } from "@mui/material";
import { AppBar, Grid, Stack, Toolbar, Typography, Select, MenuItem, TextField, Skeleton } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import SpreadsheetAnimals from "../../../../../components/Spreadsheet/SpreadsheetAnimals";
import ExcelImport from "../../../../../components/Spreadsheet/ExcelImport";
import ExcelExportSpreadsheet from "../../../../../components/Spreadsheet/ExcelExportSpreadsheet";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import { deDE } from '@mui/x-date-pickers/locales'
import ScaleIcon from "@mui/icons-material/Scale";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AnimalListName from "./AnimalListName";
import { Context } from "../../../../../components/Context/AuthContext";
import FormIdentifyHeader from "./FormIdentifyHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  content: {
    width: "100%",
    maxWidth: "none",
  },
}));

const theme = createTheme(
  { palette: { primary: { main: "#1976d2" } } },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);

const UnitOptions = [
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "mg", value: "mg" },
];

const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };

const returnAnimalDefault = (disable, read) => {
  const animal_default = [
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // fase
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // sexo
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // identifier
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // brinco
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // sisbov
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // name
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // race
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // weight
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // unit
    { value: "...", className: "classRowTable" + (disable ? "Disabled" : ""), readOnly: read ? true : false }, // date_birth
  ];

  return animal_default;
};

const exportLabels = [[ "Sexo", "Identificador", "Brinco", "SISBOV", "Nome", "Raça", "Peso", "Un. Peso", "Data Nascimento" ]];
const exportValues = [[ { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' } ]];

/* 
  brinco:
  //https://primaza.com.br/brincos-para-gado/
  Cada brinco possui uma numeração de 15 dígitos e esse número pode ser lido como um código de barras por meio de um bastão leitor

  https://www.beefpoint.com.br/dicas-cfm-para-sistema-de-identificacao-dos-animais-53398/#:~:text=Ap%C3%B3s%20preencher%20a%20caderneta%20de,ingressa%20no%20programa%20de%20sele%C3%A7%C3%A3o.
  “sigla da fazenda” + “número do animal” + “safra”
  Portanto, o animal número 458 nascido na Fazenda São Francisco em 2009, possuirá o código SF045809.

  SISBOV: -----------------------
  (agricultura.gov.br)
  https://wikisda.agricultura.gov.br/pt-br/Sa%C3%BAde-Animal/SISBOV_manual_de_procedimentos_usu%C3%A1rio_externo#:~:text=4%C2%B0%2C%20par%C3%A1grafo%20%C3%BAnico%2C%20o,2018%20disp%C3%B5e%2C%20em%20seu%20Art.
  O número SISBOV ou número de cadastro no SISBOV, é a sequência de quinze dígitos que compõe a identificação individual de bovinos ou búfalos;

  País: (076 || 105) + UF(2 dígitos) e o código de 6 dígitos do animal
        
  https://www.legisweb.com.br/legislacao/?id=368158
  CAPÍTULO I DISPOSIÇÕES PRELIMINARES
  Art. 4º A identificação individual de bovinos ou búfalos, citada no art. 5º do Decreto nº 7.623, de 2011, será única em todo o território nacional e utilizará código de quinze dígitos numéricos emitido pela Plataforma de Gestão Agropecuária - PGA, controlada pela Secretaria de Defesa Agropecuária - SDA/MAPA, e terá a seguinte composição:
  I - os três primeiros dígitos serão representados pelo código Brasil - 076; e
  II - doze dígitos subsequentes sequenciais, identificando o bovino ou búfalo.

  Parágrafo único. Entende-se por número SISBOV ou número de cadastro no SISBOV, 
  a sequência de quinze dígitos que compõe a identificação individual de bovinos ou búfalos e, 
  por número de manejo, a sequência do décimo ao décimo quinto dígito do número SISBOV.

  CAPÍTULO VI DISPOSIÇÕES TRANSITÓRIAS
  Art. 20. Enquanto a numeração de que trata o art. 4º não puder ser emitida pela PGA, 
  a emissão do código numérico de identificação individual de bovinos ou búfalos será realizada apenas pela Base Nacional de Dados - BND, 
  sob gestão e manutenção da Secretaria de Defesa Agropecuária.

  § 1º Para numeração emitida pela BND, considera-se número SISBOV ou número de cadastro no SISBOV 
  a sequência de quinze dígitos que compõe a identificação individual de bovinos ou búfalos, 
  sendo os três primeiros o código 105, 
  os dois seguintes o código IBGE da Unidade Federativa, o último o dígito verificador e, 
  entende-se por número de manejo, a sequência do nono ao décimo quarto dígito do número SISBOV.

  --------------------------- 
  https://ideagri.com.br/posts/como-montar-o-numero-completo-do-sisbov-a-partir-do-numero-de-6-digitos
*/
const exportValuesModel = [
  [ { value: 'Fêmea' }, { value: 'CPT-000001' }, { value: 'CPT-2023-000001' }, { value: '076MG2023000001' }, { value: 'Bela' }, { value: 'Holandez' }, { value: '350.50' }, { value: 'KG' }, { value: '01/01/2023' } ],
  [ { value: 'Macho' }, { value: 'CPT-000002' }, { value: 'CPT-2023-000002' }, { value: '076312023000002' }, { value: 'Zeca' }, { value: 'Gir' }, { value: '420.20' }, { value: 'Kg' }, { value: '01-01-2023' } ],
  [ { value: 'F' },     { value: 'CPT-000003' }, { value: 'CPT-2023-000003' }, { value: '105SP2023000003' }, { value: 'Mimosa' }, { value: 'Nelore' }, { value: '480' }, { value: 'kg' }, { value: '2023/01/01' } ],
  [ { value: 'M' },     { value: 'CPT-000004' }, { value: 'CPT-2023-000004' }, { value: '105352023000004' }, { value: 'Valete' }, { value: 'Misto' }, { value: '100.50' }, { value: 'kg' }, { value: '2023-01-01' } ],
  [ { value: 'Macho' }, { value: 'CPT-000005' }, { value: 'CPT-2023-000005' }, { value: '105352023000005' }, { value: 'Zangado' }, { value: 'Angus' }, { value: '150' }, { value: 'kg' }, { value: '01-01-2023 08:00:00' } ],
  [ { value: 'Fêmea' }, { value: 'CPT-000006' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' } ],
];
// https://atendimento.tecnospeed.com.br/hc/pt-br/articles/360021494734-Tabela-de-C%C3%B3digo-de-UF-do-IBGE
const codg_uf = {
  //Região Norte
  'RO':  '11',
  'AC':  '12',
  'AM':  '13',
  'RR':  '14',
  'PA':  '15',
  'AP':  '16',
  'TO':  '17',
  //Região Nordeste
  'MA':  '21',
  'PI':  '22',
  'CE':  '23',
  'RN':  '24',
  'PB':  '25',
  'PE':  '26',
  'AL':  '27',
  'SE':  '28',
  'BA':  '29',
  //Região Sudeste
  'MG':  '31',
  'ES':  '32',
  'RJ':  '33',
  'SP':  '35',
  //Região Sul
  'PR':  '41',
  'SC':  '42',
  'RS':  '43',
  //Região Centro-Oeste
  'MS':  '50',
  'MT':  '51',
  'GO':  '52',
  'DF':  '53',
}

const returnDtPickerTable = (mobile, date_birth, handleChange, maxDate) => {
  // https://stackoverflow.com/questions/74890126/material-ui-desktopdatepicker-sx-styling
  return (
    <Stack spacing={0} sx={{ flexGrow: 1, textAlign: "center", marginTop: '-30px', marginBottom: '-30px', maxHeight: '35px', minWidth: '145px' }}>
      <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
        <DesktopDatePicker
          name="date_birth"
          onChange={(newValue) => {
            handleChange("date_birth", newValue);
          }}
          adapterLocale="pt"
          inputFormat="DD/MM/YYYY"
          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
          value={date_birth}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: '35px',
                  '& fieldset': {
                    borderWidth: 0
                  },
                }
              }}
              color="success"
            />
          )}
        /> 
      </LocalizationProvider>
    </Stack>
  )
};

const returnSexoAnimal = (index, value, handleChangeSexo) => {
  return (
    <Stack spacing={0} sx={{ flexGrow: 1, textAlign: "center", marginTop: '-30px', marginBottom: '-30px' }}>
      <Select sx={{ width: 100, height: 33 }} color="success" fullWidth variant="standard" label="Sexo" name="sexo" value={value + '_' + index} onChange={(e) => handleChangeSexo(e)}>
        <MenuItem key={"Fêmea"} value={"Fêmea_" + index} >
          <FemaleIcon color="secondary" />
          Fêmea
        </MenuItem>
        <MenuItem key={"Macho"} value={"Macho_" + index}>
          <MaleIcon color="primary" />
          Macho
        </MenuItem>
      </Select>
    </Stack>
  )
};

export default function FormIdentification3({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const classes = useStyles();
  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  const [genNames, setGenNames] = useState(true);
  const [listaNameAnimais, setListaNameAnimais] = useState();

  const [updated, setUpdated] = useState(0);
  const [loadAdd, setLoadAdd] = useState(false);

  const handleDirection = () => {
    // console.log("handleDirection");
    setGenNames(true);

    var list_animals = JSON.parse(JSON.stringify(data.animals));
    var aux_list_animals = [];
    var animal = [];
    var aux_a = {};

    for (var i = 0, tam = list_animals.length; i < tam; i++) {
      aux_a = list_animals[i];
      animal = returnAnimalDefault(true, true);
      animal[0].value = data.lote?.fase_name; // fase
      animal[1].value = aux_a.sexo; // sexo
      animal[2].value = aux_a.identifier; // identificador
      animal[3].value = aux_a.brinco; // brinco
      animal[4].value = aux_a.sisbov; // sisbov
      animal[5].value = aux_a.name; // nome
      animal[6].value = aux_a.race; // raça
      animal[7].value = aux_a.weight; // peso
      animal[8].value = aux_a.unit; // unidade peso
      animal[9].value = dayjs(aux_a.date_birth).format("DD/MM/YYYY"); // data nascimento

      aux_list_animals.push(animal);
    }
    obj_fn.setData((prevState) => ({ ...prevState, table_confirm: aux_list_animals }));

    obj_fn.handleNext();
  };
  // const para validar o button continuar
  const isError = () => {
    return formError.num_initial !== "" || formError.num_final !== "" || formError.date_birth !== "" ||
    // validação numero de animais a serem adicionados
    (data.lote?.num_animals - (data.animals.length || 0) < 0) ||
    ((data.lote?.num_animals - (data.animals.length || 0) ) - (parseFloat(data.num_final || "-1") - parseFloat(data.num_initial || "0") + 1) < 0)
  };

  // const para validar o button continuar
  const isErrorConfirm = () => {
    return formError.num_initial !== "" || formError.num_final !== "" || formError.date_birth !== "" || !data.animals?.length;
  };

  const [formError, setFormError] = useState({
    num_initial: !data.num_initial ? "Numeração inicial deve ser maior do que 0!" : "",
    num_final: !data.num_final ? "Numeração final deve ser maior do que 0!" : "",
    date_birth: "",
  });

  const [tableAnimalsP, settableAnimalsP] = useState([]);
  const [cheackNames, setCheckNames] = useState(false);
  const [namesAnimals, setNamesAnimals] = useState({ initial: "A", final: "Z" });

  const handleChange = (event, blur) => {
    // console.log("handleChange");
    if (event === "date_birth") {
      obj_fn.setData((prevState) => ({ ...prevState, [event]: dayjs(blur).format("YYYY-MM-DD HH:mm:ss") }));
    } else {
      var field = event.target.name;
      var value = event.target.value;

      if (/weight_.*/.test(field)) {
        // console.log(field);
      } else if (field === "prefix") {
        value = value.toUpperCase();
        if (blur) {
          value = value.trim();
        }
      } else if (/num/.test(field)) {
        //value = value.replace(/\D+/, ''); // no letters
        //if (!/[0-9]/.test(value)) {
        //  return;
        //}
        value = parseFloat(value || "0");
        if (!value) {
          var aux_txt = field === "num_initial" ? "inicial" : "final";
          setFormError((prevState) => ({ ...prevState, [field]: "Numeração " + aux_txt + " deve ser maior do que 0!" }));
        } else {
          var qtd_animais = field === "num_initial" ? parseFloat(data.num_final) - value : value - parseFloat(data.num_initial);
          setFormError((prevState) => ({ ...prevState, num_initial: "" }));
          setFormError((prevState) => ({ ...prevState, num_final: "" }));

          if (field === "num_initial" && value > data.num_final) {
            setFormError((prevState) => ({ ...prevState, [field]: "Numeração inicial deve ser menor do que a final!" }));
          } else if (field === "num_final" && data.num_initial > value) {
            setFormError((prevState) => ({ ...prevState, [field]: "Numeração final deve ser maior do que a inicial!" }));
          } else if (qtd_animais > data.lote.num_animals) {
            setFormError((prevState) => ({ ...prevState, num_initial: "Somatório das numerações excede o total de animais no lote!" }));
            setFormError((prevState) => ({ ...prevState, num_final: "Somatório das numerações excede o total de animais no lote!" }));
          }
        }
        if (field === "num_initial") {
          geraTableAnimalsP(value, data.num_final, cheackNames);
        } else {
          geraTableAnimalsP(data.num_initial, value, cheackNames);
        }
      }
      obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  const handleChangeSexo = (event) => {
    // console.log("handleChangeSexo");
    var value = (event.target.value).replace(/(.*)_.*/, '$1');
    var index = (event.target.value).replace(/.*_(.*)/, '$1');

    const aux_animals = data.animals;
    aux_animals[index].sexo = value;
    obj_fn.setData((prevState) => ({ ...prevState, animals: aux_animals }));

    if (value === 'Macho') {
      obj_fn.setData((prevState) => ({ ...prevState, num_males:   data.num_males +1 }));
      obj_fn.setData((prevState) => ({ ...prevState, num_females: data.num_females -1 }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, num_females: data.num_females +1 }));
      obj_fn.setData((prevState) => ({ ...prevState, num_males:   data.num_males -1 }));
    }
    setUpdated(updated+1);
  };

  const [index_weight, setIndexWeight] = useState({0: 0});
  const handleChangeWeight = (event, blur) => {
    // console.log("handleChangeWeight");
    var value = event.target.value || '0';
    var index = (event.target.name).replace(/.*_(.*)/, '$1');

    const aux_animals = data.animals;
    if (aux_animals[index]) {
      if (aux_animals[index].weight !== value) {
        aux_animals[index].weight = value;
        obj_fn.setData((prevState) => ({ ...prevState, animals: aux_animals }));
        if (!data.weight) {
          obj_fn.setData((prevState) => ({ ...prevState, weight: value }));
        }

        setUpdated(updated + 1);
        index_weight[index] = value;
      }
    }
  };
  // const getWeight = (index) => {
  //   return index_weight[index];
  // };

  const [index_date_birth, setIndexDateBirth] = useState({index: 0});
  const setIndexDate = (row) => {
    if (!window.jQuery.find('.MuiPickersPopper-root:visible')?.length) {
      // console.log('setIndexDateBirth');
      index_date_birth.index = row;
      setIndexDateBirth({index: row});
      window.jQuery(window.jQuery.find('.classRowTable_' + row)[0]).find('button').click();
    }
  };

  const handletableClick = (index, tipo) => {
    if (tipo === 'data') {
      setIndexDate(index);
    } else {
      //window.jQuery(window.jQuery.find('.classRowTableWeight_' + index)[0]).find('input').focus();
      //setTimeout(() => {
      //  window.jQuery(window.jQuery.find('.classRowTableWeight_' + index)[0]).find('input').focus();
      //  window.jQuery(window.jQuery.find('.classRowTableWeight_' + index)[0]).find('input').click();
      //}, 5);
    }
  };

  const handleChangeBirth = (event, val) => {
    // console.log("handleChangeBirth");
    var value = dayjs(val).format('YYYY-MM-DD HH:mm:ss');

    const aux_animals = data.animals;
    aux_animals[index_date_birth.index].date_birth = value;

    obj_fn.setData((prevState) => ({ ...prevState, animals: aux_animals }));
    setUpdated(updated+1);
  };

  const geraTableAnimalsP = (num_i, num_f, check) => {
    var animal = [];
    var aux_list_animals = [];

    var listNames = check ? AnimalListName({ count: 3, complete: true, letraIniit: namesAnimals.initial, letraEnd: namesAnimals.final, sexo: data.sexo, list: true }) : [];

    for (var i = 0, tam = 3; i < tam; i++) {
      animal = returnAnimalDefault(true, true);
      if (i !== 1) {
        animal[0].value = data.lote.fase_name; // fase
        animal[1].value = data.sexo; // sexo
        animal[2].value = data.prefix + (i === 0 ? num_i : num_f); // identificador
        animal[3].value = ""; // brinco
        animal[4].value = ""; // sisbov
        animal[5].value = listNames.length > 0 ? listNames[i] : ""; // nome
        animal[6].value = data.race; // raça
        animal[7].value = data.weight; // peso
        animal[8].value = data.unit; // unidade peso
        animal[9].value = dayjs(data.date_birth).format("DD/MM/YYYY"); // data nascimento
      }
      aux_list_animals.push(animal);
    }
    //obj_fn.setData((prevState) => ({ ...prevState, table_animals_p: aux_list_animals }));

    settableAnimalsP(aux_list_animals);
  };

  const handleChangeTable = (dataSheet, value) => {
    // console.log("handleChangeTable");
    if (!data.animals.length) {
      return;
    }
    const aux_animals = data.animals;

    dataSheet.map((row, index) => {
      //aux_animals[index].sexo = row[1].value; // sexo
      aux_animals[index].identifier = row[2].value; // identifier
      aux_animals[index].brinco = row[3].value; // brinco
      aux_animals[index].sisbov = row[4].value; // sisbov
      aux_animals[index].name = row[5].value; // nome
      aux_animals[index].race = row[6].value; // raça
      //aux_animals[index].weight = row[7].value; // peso
      //aux_animals[index].date_birth = dayjs(row[8].value).format("YYYY-MM-DD HH:mm:ss"); // data nascimento
    });
    obj_fn.setData((prevState) => ({ ...prevState, animals: aux_animals }));
    obj_fn.setData((prevState) => ({ ...prevState, table_animals: dataSheet }));
    //setUpdated(updated+1);
  };

  const handleAddTable = () => {
    // console.log("handleAddTable");

    var prefixo = data.prefix;
    var prefixo_animal = "";
    var aux_list_animals = [];
    var animal = [];
    var value_prefix = 0;

    var qtd_animais = parseFloat(data.num_final) - parseFloat(data.num_initial) + 1;

    var listNames = cheackNames ? AnimalListName({ count: qtd_animais, complete: true, letraIniit: namesAnimals.initial, letraEnd: namesAnimals.final, sexo: data.sexo, list: true }) : [];

    for (var i = 0; i < qtd_animais; i++) {
      value_prefix = parseFloat(data.num_initial) + i;
      prefixo_animal = prefixo + "" + value_prefix;

      animal = {
        id_fase_manejo: data.lote.fase_id,
        sexo: data.sexo,
        identifier: prefixo_animal,
        brinco: "",
        sisbov: "",
        name: listNames.length > 0 ? listNames[i] : "",
        race: data.race,
        weight: data.weight,
        unit: data.unit,
        date_birth: data.date_birth,
      };

      aux_list_animals.push(animal);
    }
    obj_fn.setData((prevState) => ({ ...prevState, animals: prevState.animals.concat(aux_list_animals) }));

    if (data.sexo === "Fêmea") {
      obj_fn.setData((prevState) => ({ ...prevState, num_females: prevState.num_females + qtd_animais }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, num_males: prevState.num_males + qtd_animais }));
    }
    obj_fn.setData((prevState) => ({ ...prevState, num_animals: prevState.num_animals + qtd_animais }));

    setUpdated(updated+1);
    setTimeout(() => {
      setLoadAdd(false);
    }, 500);
  };

  useEffect(() => {
    if (updated > 0) {
      geraTableAnimals();
    }
  }, [updated]);

  const geraTableAnimals = (lista) => {
    var list_animals = (lista ? lista : window.jQuery.extend(true, [], data.animals));
    if (!list_animals?.length) {
      return [];
    }
    var aux_list_animals = [];
    var animal = [];
    var aux_a = {};

    for (var i = 0, tam = list_animals.length; i < tam; i++) {
      aux_a = list_animals[i];
      const index_w = JSON.stringify(i);
      //const weight_a = parseFloat(aux_a.weight);
      const weight_a = aux_a.weight;
      index_weight[index_w] = weight_a;

      //ideia: https://codesandbox.io/s/github/calebmpeterson/react-spreadsheet/tree/master/?file=/readme.md:0-3090
      const RangeTextField = () => (
        <Grid item sx={{ flexGrow: 1, height: '30px', marginTop: '-10px' }}>
          <TextField
            name={"weight_" + index_w}
            id={"weight_" + index_w}
            type="number"
            color="success"
            variant="standard"
            fullWidth
            sx={{ m: 1, width: "80px", height: '30px', }}
            InputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 12,
              min: 0,
              autoFocus: true,
            }}
            value={weight_a}
            //value={getWeight(index_w)}
            //onClick={handleChangeWeight}
            onKeyDown={(e) => {
              if (e.key === "." || e.key === "-" || e.key === "+") {
                e.preventDefault()
              }
            }}
            onChange={handleChangeWeight}
            onBlur={(e) => handleChangeWeight(e, true)}
          />
        </Grid>
      );
    
      animal = [
        {value: data.lote?.fase_name || '', readOnly: true, className: 'classRowTableDisabled'},
        {value: ( returnSexoAnimal(i, aux_a.sexo, handleChangeSexo) ), className: 'classRowTable', readOnly: true},
        {value: aux_a.identifier, className: 'classRowTable'},
        {value: aux_a.brinco, className: 'classRowTable'},
        {value: aux_a.sisbov, className: 'classRowTable'},
        {value: aux_a.name, className: 'classRowTable'},
        {value: aux_a.race, className: 'classRowTable'},
        //{value: getWeight(index_w),
        {value: weight_a,
          //DataViewer: RangeTextField,
          DataEditor: RangeTextField,
          className: 'classRowWeight classRowTableWeight_' + i,
          //readOnly: true
        },
        {value: aux_a.unit, readOnly: true, className: 'classRowTableDisabled'},
        {value: ( returnDtPickerTable(ismobile, aux_a.date_birth, handleChangeBirth, new Date(data.lote?.date_entry || data.date_start)) ), className: 'classRowTable_' + i, readOnly: true}
      ];

      aux_list_animals.push(animal);
    }

    obj_fn.setData((prevState) => ({ ...prevState, table_animals: aux_list_animals }));
    return aux_list_animals;
  };

  const handleClearTable = () => {
    // console.log("handleClearTable");

    obj_fn.setData((prevState) => ({ ...prevState, num_females: 0 }));
    obj_fn.setData((prevState) => ({ ...prevState, num_males: 0 }));
    obj_fn.setData((prevState) => ({ ...prevState, num_animals: 0 }));

    obj_fn.setData((prevState) => ({ ...prevState, animals: [] }));
    obj_fn.setData((prevState) => ({ ...prevState, table_animals: [] }));
  };

  // const handleChangeListNameAnimal = (event, blur) => {
  //   // console.log("handleChangeListNameAnimal");
  //   var field = event.target.name;
  //   var value = event.target.value.trim().toUpperCase();
  //   value = value.replace(/\d+/, ""); // no numbers

  //   //if (!/[A-Z]/.test(value)) {
  //   //  return;
  //   //}
  //   if (value.length > 1) {
  //     value = value[1] || "";
  //   }
  //   setNamesAnimals((prevState) => ({ ...prevState, [field]: value }));
  //   generateName({
  //     initial: field === "initial" ? value : namesAnimals.initial,
  //     final: field === "final" ? value : namesAnimals.final,
  //   });
  // };

  function generateName(new_name) {
    if (genNames || new_name) {
      if (!new_name) {
        setGenNames(!genNames);
      }
      if (!namesAnimals.initial || !namesAnimals.final) {
        return false;
      } else if (new_name) {
        if (!new_name.initial || !new_name.final) {
          return false;
        }
      }
      setListaNameAnimais(
        AnimalListName({
          count: 3,
          complete: true,
          letraIniit: new_name ? new_name.initial : namesAnimals.initial,
          letraEnd: new_name ? new_name.final : namesAnimals.final,
          sexo: data.sexo,
          list: false,
        })
      );

      geraTableAnimalsP(data.num_initial, data.num_final, cheackNames);
    }
  }
  function returnCheckNames() {
    setCheckNames(!cheackNames);
    geraTableAnimalsP(data.num_initial, data.num_final, !cheackNames);
  }

  generateName();

  function getSexRanges(array) {
    let maleInit, maleEnd, femaleInit, femaleEnd;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.sexo === "Macho") {
        if (!maleInit) {
          maleInit = item.identifier;
        }
        maleEnd = item.identifier;
      } else if (item.sexo === "Fêmea") {
        if (!femaleInit) {
          femaleInit = item.identifier;
        }
        femaleEnd = item.identifier;
      }
    }

    return {
      maleInit: maleInit,
      maleEnd: maleEnd,
      femaleInit: femaleInit,
      femaleEnd: femaleEnd,
    };
  }

  const [sexIDRange, setsexIDRange] = useState({});

  useEffect(() => {
    setsexIDRange(getSexRanges(data.data_livestock.animais));
    geraTableAnimalsP(data.num_initial, data.num_final, cheackNames);
  }, [data]);

  const getTableAnimals = () => {
    const tabelaAnimals = (<SpreadsheetAnimals lista={data.table_animals} handleChange={handleChangeTable} view={false} handletableClick={handletableClick} />);
    return ( <>
      {loadAdd ? <>
        <Box width={window.innerWidth/2} height={120} sx={{mt: 2}}>
          <LinearProgress color="success" />
          <Skeleton variant="rectangular" width={"100%"} height={100} />
        </Box>
      </> : <>
      {!ismobile ? (
        <Grid item xs={12} md={12} className={classes.container} sx={{ mt: 1, mb: 5 }}>
          {tabelaAnimals}
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ width: "100%", overflow: "auto" }}>
          {tabelaAnimals}
        </Grid>
      )}
      </>}
    </>)
  };

  const text_title = "Passo 3: Gerar os Identificadores!";
  const txt_msn = "Defina o Sexo e o Identificador, composto por: Prefixo e numeração: Inicial (De), Final (Até)!\n"+
    "Também é possível definir: Raça, Peso e Data de nascimento para os animais.";

  const sex_imported = {'femeas': 0, 'machos': 0, 'total': 0};

  const getImportSexo = (sexo) => {
    let sexo_animal = '';
    if (/F.*/.test(sexo) || /f.*/.test(sexo)) {
      sexo_animal = 'Fêmea';
      sex_imported.femeas += 1;
    } else {
      sexo_animal = 'Macho';
      sex_imported.machos += 1;
    }
    return sexo_animal;
  }
  const getImportDateNirth = (date_birth) => {
    let aux_format = 'YYYY-MM-DD HH:mm:ss';
    if (!date_birth) {
      return data.date_birth || dayjs().format(aux_format);
    }
    var nascimento = date_birth.replace(/\//g, '-');

    //if (/^\d{4}.*/.test(nascimento)) {
    //  aux_format = 'YYYY-MM-DD HH:mm:ss';
    //} else {
    //  aux_format = 'DD-MM-YYYY HH:mm:ss';
    //}
    nascimento = dayjs(nascimento).format(aux_format);
    if (!nascimento) {
      return data.date_birth || dayjs().format(aux_format);
    } else {
      return nascimento;
    }
  }
  const getImportUnit = (unit) => {
    let unit_animal = (unit.toLowerCase() || 'kg');
    if (/kg/.test(unit_animal) || /kilo.*/.test(unit_animal)) {
      unit_animal = 'kg';
    } else if (/mg/.test(unit_animal) || /mile.*/.test(unit_animal)) {
      unit_animal = 'mg';
    } else if (/grama.*/.test(unit_animal)) {
      unit_animal = 'g';
    } else {
      unit_animal = 'kg';
    }
    return unit_animal;
  }
  const getImportSisbov = (sisbov) => {
    if (!sisbov) {
      return '';
    }
    let sisbov_animal = sisbov;
    
    if (/\w/.test(sisbov)) {
      let aux_codg_uf = (sisbov.toUpperCase()).replace(/\d/g, '');
      if (codg_uf[aux_codg_uf]) {
        sisbov_animal = sisbov.replace(/(\d+)\D+(\d+)/, '$1' + codg_uf[aux_codg_uf] + '$2');;
      }
    }

    return sisbov_animal;
  }

  const handleImport = (jsonData) => {
    sex_imported.femeas = 0;
    sex_imported.machos = 0;

    setLoadAdd(true);
    //const importedData = jsonData.map((row) =>
    //  row.map((cell) => ({ value: cell }))
    //);
    let arra_data = (jsonData.slice(1,jsonData.length));
    let qtd_add = arra_data.length + data.num_animals;
    if (qtd_add > data.lote.num_animals) {
      // reduz os animais a serem adicionados para ficar igual ao limite do lote
      qtd_add = (data.lote.num_animals - data.num_animals);
      arra_data = arra_data.slice(0, qtd_add)
    }

    const importedData = arra_data.map((row, index) => {
      return {
        id_fase_manejo: (data.lote?.fase_id || 1), // fase_manejo
        sexo: getImportSexo(row[0]), // sexo,
        identifier: row[1], // identifier,
        brinco: row[2], // brinco,
        sisbov: getImportSisbov(row[3]), // sisbov,
        name: row[4], // nome,
        race: row[5], // raça,
        weight: row[6] ? parseFloat(row[6]) : '', // peso,
        unit: getImportUnit(row[7]), // unidade_peso,
        date_birth: getImportDateNirth(row[8]), // data_nascimento,,
      }
    });
    sex_imported.total = importedData.length;

    obj_fn.setData((prevState) => ({ ...prevState, animals: prevState.animals.concat(importedData) }));
    
    obj_fn.setData((prevState) => ({ ...prevState, num_females: prevState.num_females + sex_imported.femeas }));
    obj_fn.setData((prevState) => ({ ...prevState, num_males: prevState.num_males + sex_imported.machos }));
    obj_fn.setData((prevState) => ({ ...prevState, num_animals: prevState.num_animals + sex_imported.total }));

    if (!data.num_initial) {
      obj_fn.setData((prevState) => ({ ...prevState, num_initial: 1 }));
      formError.num_initial = '';
    }
    if (!data.num_final) {
      formError.num_final = '';
      obj_fn.setData((prevState) => ({ ...prevState, num_final: sex_imported.total }));
    }

    setUpdated(updated+1);
    setTimeout(() => {
      setLoadAdd(false);
      sex_imported.total = 0;
      sex_imported.femeas = 0;
      sex_imported.machos = 0;
    }, 500);
  };
  return ( <>
    <FormIdentifyHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} height={'70px'} />

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Utilizar Planilhas</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Box sx={{ display: 'flex' }}>
          <span style={{marginRight: '10px'}}>
            <ExcelImport onImport={handleImport}/>
          </span>
          <span style={{marginLeft: '10px'}}>
            <ExcelExportSpreadsheet data={exportValues} headers={exportLabels} filename={"CPT_IDENTIFICAÇÃO_ANIMAIS"} sheetName={"Identificação"} txt={'Download Branco'}/>
          </span>
          <span style={{marginLeft: '10px'}}>
            <ExcelExportSpreadsheet data={exportValuesModel} headers={exportLabels} filename={"CPT_MODELO_IDENTIFICAÇÃO_ANIMAIS"} sheetName={"Identificação"} txt={'Download Modelo'}/>
          </span>
        </Box>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Informações gerais</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{mt: '-15px'}}>
        <Grid container spacing={2} sx={{mb: '10px'}}>
          <Grid item xs={6} md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Lote:</span> {data.lote?.label || ""}
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Fase manejo:</span> {data.lote?.fase_name || ""}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Não Identificados:</span> {data.lote?.num_animals || 0}
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Identificados:</span> {data.lote?.total_identifi || 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} >
          <Grid item xs={2} md={2} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Identificando:</span><br/>
              {data.animals.length || 0} animais
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}>Restando:</span><br/>
              {data.lote?.num_animals - data.animals.length || 0} animais
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}><MaleIcon color="primary" /> Últimos adicionados:</span><br/>
              {sexIDRange.maleInit || ''} - {sexIDRange.maleEnd || ''}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              <span style={{ fontWeight: "bold" }}><FemaleIcon color="secondary" /> Últimas adicionadas:</span><br/>
              {sexIDRange.femaleInit || ''} - {sexIDRange.femaleEnd || ''}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Sexo
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center" colSpan={3}>
              Identificadores
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"identificar"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="right" sx={{ verticalAlign: "baseline" }}>
              <Grid item container>
                <ListItem>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: "center" }}>
                    <Select sx={{ width: 100 }} color="success" fullWidth variant="standard" label="Sexo" name="sexo" value={data.sexo} onChange={(e) => handleChange(e)}>
                      <MenuItem key={"Fêmea"} value={"Fêmea"}>
                        <FemaleIcon color="secondary" />
                        Fêmea
                      </MenuItem>
                      <MenuItem key={"Macho"} value={"Macho"}>
                        <MaleIcon color="primary" />
                        Macho
                      </MenuItem>
                    </Select>
                  </Stack>
                </ListItem>
              </Grid>
            </TableCell>
            <TableCell colSpan={3} sx={{ verticalAlign: "baseline" }}>
              <Grid container spacing={3}>
              <ListItem>
                <Grid item xs={4} md={4} sx={{ height: '92px', '& .MuiFormControl-root': { verticalAlign: 'baseline', } }}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    //required
                    sx={{ width: 100 }}
                    id="prefix"
                    name="prefix"
                    label="Prefixo"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    inputProps={{
                      style: { textTransform: "uppercase", },
                      maxLength: 12,
                    }}
                    value={data.prefix}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    helperText={<> Ex1: CPT- <br /> Ex2: 076 </>}
                  />
                </Grid>
                <Grid item xs={4} md={4} sx={{ height: '92px', '& .MuiFormControl-root': { verticalAlign: 'baseline', } }}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    required
                    sx={{ width: 150 }}
                    id="num_initial"
                    name="num_initial"
                    label="Inicial (De)"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    type="number"
                    inputProps={{
                      verticalalign: "baseline",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 12,
                      min: 0,
                    }}
                    value={data.num_initial}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    error={data.num_initial !== "" && formError.num_initial !== ""}
                    helperText={data.num_initial === "" ? "Ex: 1200" : formError.num_initial !== "" ? formError.num_initial : "Ex: 1200"}
                  />
                </Grid>
                <Grid item xs={4} md={4} sx={{ height: '92px', '& .MuiFormControl-root': { verticalAlign: 'baseline', } }}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    required
                    sx={{ width: 150 }}
                    id="num_final"
                    name="num_final"
                    label="Final (Até)"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    type="number"
                    inputProps={{
                      verticalalign: "baseline",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 12,
                      min: data.num_initial + 1,
                    }}
                    value={data.num_final}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    error={data.num_final !== "" && formError.num_final !== ""}
                    helperText={data.num_final === "" ? "Ex: 1500" : formError.num_final !== "" ? formError.num_final : "Ex: 1500"}
                  />
                </Grid>
              </ListItem>
              </Grid>
            </TableCell>
          </TableRow>

          {/* <TableRow key={"identifiers"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={4} sx={{ verticalAlign: "baseline" }}>
              <Grid container spacing={4}>
                <Grid item xs={3} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                  <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                    <span style={{ fontWeight: "bold" }}>Identificando:</span><br/>
                    {data.animals.length || 0} animais
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                  <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                    <span style={{ fontWeight: "bold" }}>Restando:</span><br/>
                    {data.lote?.num_animals - data.animals.length || 0} animais
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                  <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                    Últimos adicionados <MaleIcon color="primary" />{": "}<br/>
                    {sexIDRange.maleInit || ''} - {sexIDRange.maleEnd || ''}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                  <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                    Últimas adicionadas <FemaleIcon color="secondary" />{": "} <br/>
                    {sexIDRange.femaleInit || ''} - {sexIDRange.femaleEnd || ''}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Raça, Peso e Data de Nascimento</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{mt: '-15px'}}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Raça
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Peso
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center" colSpan={2}>
                  Data de Nascimento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"auxIdent"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left">
                  <TextField
                    label="Raça dos Animais"
                    name="race"
                    id="raceAnimals"
                    color="success"
                    variant="standard"
                    fullWidth
                    sx={{ m: 1, width: "100%", mt: -8 }}
                    value={data.race}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    helperText={ <> Ex1: Angus, Nelore <br /> Ex2: Misto </> }
                  />
                </TableCell>
                <TableCell align="left">
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={8}>
                      <TextField
                        label="Peso médio dos animais"
                        name="weight"
                        id="weight"
                        type="number"
                        color="success"
                        variant="standard"
                        fullWidth
                        sx={{ m: 1, width: "100%" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ScaleIcon />
                            </InputAdornment>
                          ),
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 12,
                          min: 0,
                        }}
                        value={data?.weight}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e, true)}
                        helperText={"Pré definido de acordo com a fase de manejo do lote"}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 3, textAlign: "center", width: '65px' }}>
                        <Select color="success" fullWidth variant="standard" label="Unidade" name="unit" value={data.unit} onChange={(e) => handleChange(e)}>
                          {UnitOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" colSpan={2} >
                  <Grid container spacing={2}>
                  <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <div className="d-lg-none">
                      <Stack spacing={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <MobileDatePicker
                              name="date_birth"
                              label="Data média de nascimento*"
                              onChange={(newValue) => {
                                handleChange("date_birth", newValue);
                                if (!newValue.$D) {
                                  setFormError((prevState) => ({ ...prevState, date_birth: "Insira uma data válida" }));
                                } else {
                                  setFormError((prevState) => ({ ...prevState, date_birth: "" }));
                                }
                              }}
                              adapterLocale="pt"
                              inputFormat="DD/MM/YYYY"
                              dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                              value={data.date_birth}
                              maxDate={new Date(data.lote?.date_entry || data.date_start)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="success"
                                  error={formError.date_birth !== ""}
                                  helperText={ formError.date_birth !== "" && ( <p id="date_birth_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p> ) }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </div>
                    <div className="d-none d-lg-block">
                      <Stack spacing={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <DesktopDatePicker
                              name="date_birth"
                              label="Data média de nascimento*"
                              onChange={(newValue) => {
                                handleChange("date_birth", newValue);
                                if (!newValue.$D) {
                                  setFormError((prevState) => ({ ...prevState, date_birth: "Insira uma data válida" }));
                                } else {
                                  setFormError((prevState) => ({ ...prevState, date_birth: "" }));
                                }
                              }}
                              adapterLocale="pt"
                              inputFormat="DD/MM/YYYY"
                              dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                              value={data.date_birth}
                              maxDate={new Date(data.lote?.date_entry || data.date_start)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="success"
                                  error={formError.date_birth !== ""}
                                  helperText={ formError.date_birth !== "" && ( <p id="date_birth_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p> ) }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </div>
                    <Typography variant="caption" display="block" gutterBottom sx={{ mt: 0.5, ml: 1, color: "#808080" }}>
                      Data de nascimento aproximada dos animais<br/>Pré definida de acordo com a fase de manejo do lote. 
                    </Typography>
                  </LocalizationProvider>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Nomear os animais</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="center" sx={{ mt: -5, mb: 1, justifyContent: "center", textAlign: "left" }} spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography>
              <Checkbox checked={cheackNames} onChange={(e) => returnCheckNames()} color="success" />
              Utilizar nomes aletórios para os animais
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ mb: 1, justifyContent: "center", textAlign: "left" }} spacing={3}>
          <Grid container item xs={8} md={8} spacing={2}>
            <Typography> Exemplos de nomes aletórios para os animais: </Typography>
            <Typography>
              {listaNameAnimais}
              {/*<AnimalListName count={3} complete={false} letraIniit={namesAnimals.initial} letraEnd={namesAnimals.final} sexo={data.sexo} list={false}/>*/}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

    <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
      <Grid item xs={6} md={6}>
        <ListItem>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="contained" type="submit" onClick={!isError() ? () => { setLoadAdd(true); handleAddTable(); } : () => null} disabled={isError()}>
              <span>Adicionar {parseFloat(data.num_final || "-1") - parseFloat(data.num_initial || "0") + 1} animais à tabela</span>
            </Button>
          </Box>
        </ListItem>
      </Grid>
      <Grid item xs={6} md={6}>
        <ListItem>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="info" variant="contained" type="submit" onClick={handleClearTable}>
              <span>Limpar tabela</span>
            </Button>
          </Box>
        </ListItem>
      </Grid>
    </Grid>

    <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
      <Stack spacing={0.5}>
        <Typography fontWeight={700}> Tabela de animais a serem adicionados </Typography>
      </Stack>
      {getTableAnimals()}
    </Grid>

    <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
            cancelar
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }}>
            Retornar
          </Button>
          {/* O botão Confirmar e Salvar, leva para tela successo */}
          <Button color="success" variant="contained" type="submit" onClick={!isErrorConfirm() ? handleDirection : () => null} disabled={isErrorConfirm()}>
            Continuar
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  </> );
}
