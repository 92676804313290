import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Typewriter from '../../components/Typewriter/Typewriter';

export default function ProductGeneralCardEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 6000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Card sx={{ m: 2 }}>
            <CardHeader
              title={
                <>
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={12}>
                      <Box sx={{ display: 'flex' }}>
                        {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                        <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                          <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                            <>
                              <strong>Registre de forma fácil seus produtos (insumos) agrícolas e pecuários?</strong>
                            </>
                          </Typography>
                          {/* <Typography sx={{ mt: 2 }} fontWeight="500" variant="subtitle1">
                          <>
                            <strong>Registre os produtos necessários para a produção agrícola e pecuária no catálogo de produtos.</strong>
                          </>
                        </Typography> */}
                        </Stack>
                        {/* <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      </Stack> */}
                      </Box>
                    </Grid>
                  </Grid>
                  <div className="d-lg-none">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos registrar seus produtos que serão utilizandos para produção agrícola ou pecuária</Typography>
                    </Box>
                    <Box sx={{ minHeight: 110 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter tam={110} minHeight={0} text={`Para registrar um produto, você pode clicar no botão verde (+) no canto superior direito, "CADASTRO DE PRODUTOS" `} />
                        )}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Vamos registrar seus produtos que serão utilizandos para produção agrícola ou pecuária?
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 45 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter tam={30} minHeight={0} text={`Para registrar um produto, você pode clicar no botão verde (+) no canto superior direito, "CADASTRO DE PRODUTOS" `} />
                        )}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>

            <Divider />
          </Card>
          <Card sx={{ m: 0 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Grid container xs={12} md={12} sx={{ m: -2 }} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/produtos.png" name=""></img>
                  </Grid>
                  {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                  <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                    <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                      Cadastro de Produtos
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Registre facilmente seus insumos agrícolas e pecuários. Tenha um catálogo com todos produtos no estoque da propriedade. Acompanhe o estoque e o consumo de cada item, otimizando a
                      gestão de recursos e reduzindo desperdícios para uma operação mais eficiente e sustentável.
                    </Typography>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
