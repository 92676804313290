import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#0) 00000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs({ setTel, tel }) {
  const [valuesTel, setValuesTel] = React.useState({
    textmask: tel ? tel : "(31) 90000-0000",
    numberformat: "1320",
  });

  const handleChange = (event) => {
    //console.log(event.target.value);
    setValuesTel({
      ...valuesTel,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setTel(valuesTel)
  }, [valuesTel])
  return (
    <Box
      sx={{
        "& > :not(style)": {
          mt: 2,
        },
      }}
    >
      <FormControl variant="standard">
        <InputLabel color="success" style={{ color: '#2e7d32' }} htmlFor="formatted-text-mask-input">Telefone</InputLabel>
        <Input value={valuesTel.textmask} color="success" onChange={handleChange} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom} />
      </FormControl>
    </Box>
  );
}
