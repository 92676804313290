import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { Box, Card, Grid, Paper } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SignpostIcon from "@mui/icons-material/Signpost";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { useState } from "react";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[700],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[700],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: green[700],
  }),
  "& .QontoStepIcon-completedIcon": {
    color: green[700],
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <PlaylistAddCheckIcon className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const steps = [
  { label: "", description: "01/01/2023", desc1: "Custo de", desc2: "Produção" },
  { label: "", description: "", desc1: "", desc2: "" },
  { label: "", description: "xx/xx/20xx", desc1: "Custo de", desc2: "Produção" },
];

export default function TimelineHorizActivityPrepLiv({ topics }) {
  console.log("TimelineHorizActivityPrepAgr");
  console.log(topics);
  console.log(topics.length);

  useEffect(() => {
    addEmptyItems();
  }, [topics]);

  const [newStepsList, setNewStepsList] = useState([]);

  const addEmptyItems = () => {
    const newSteps = [...steps]; // Cria uma cópia do array original

    if (topics.length > newSteps.length) {
      const diff = topics.length + 1 - newSteps.length;

      if (!newSteps.some((step) => step.description === "01/01/2023")) {
        newSteps.unshift({ label: "", description: "01/01/2023", desc1: "Custo de", desc2: "Produção" });
        diff--;
      }

      for (let i = 0; i < diff - 1; i++) {
        newSteps.splice(newSteps.length - 1, 0, { label: "", description: "", desc1: "", desc2: "" });
      }

      if (!newSteps.some((step) => step.description === "xx/xx/20xx")) {
        newSteps.push({ label: "", description: "xx/xx/20xx", desc1: "Custo de", desc2: "Produção" });
      }
    }
    setNewStepsList(newSteps);
    console.log(newSteps); // Exibe o novo array com os itens adicionados
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={0}>
        <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={6} md={6}>
            {/* <div style={{ textAlign: "left", paddingLeft: "10%" }}>Onde estou...</div> */}
            <div style={{ textAlign: "left", paddingLeft: "0" }}>
              <strong>Início</strong>{" "}
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            {/* <div style={{ textAlign: "right", paddingRight: "10%" }}>Aonde quero chegar?</div> */}
            <div style={{ textAlign: "right", paddingRight: "0" }}>
              {/* <EmojiEventsIcon /> */}
              <strong>Entrada</strong>
            </div>
          </Grid>
        </Grid>
        <Stepper sx={{ ml: -2 }} alternativeLabel activeStep={topics.length} connector={<QontoConnector />}>
          {newStepsList.map((step, index) => (
            <Step key={step.label + index}>
              <StepLabel StepIconComponent={QontoStepIcon} completed={index !== 0}>
                <div>
                  <div>{step.label}</div>
                  <div style={{ fontSize: 10 }}>{step.description}</div>
                  {/* <Card sx={{ mt: 2 }}>
                    <Box>
                      <div style={{ fontSize: 12 }}>{step.desc1}</div>
                      <div style={{ fontSize: 12 }}>{step.desc2}</div>
                    </Box>
                  </Card> */}
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
}
