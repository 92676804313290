import { Autocomplete, Box, Button, CircularProgress, Grid, LinearProgress, MenuItem, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ExpandMore } from '@mui/icons-material';
import GradingIcon from '@mui/icons-material/Grading';
import { NavLink, useNavigate } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import LZString from 'lz-string';
import api from '../../../../../../../services/api';
import { Context } from '../../../../../../../components/Context/AuthContext';
import SuccessEditDraw from './successDraw/SuccessEditDraw';
import useStore from '../../../../../../../store/useStore';
import Loading from '../../../../../../../components/Loader/Loading';
import { base64ToBlob } from '../../../../../../../components/Image/base64ToBlob';

const currencies = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

export default function FormSaveEditMapButton({ handleClose, path, area, areasMap_data }) {
  const navigate = useNavigate();
  const usestore = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false);
  }, []);

  let encoded = base64_encode(JSON.stringify(path));
  //console.log(encoded);
  //https://developers.google.com/maps/documentation/utilities/polylinealgorithm?hl=pt-br
  // var encodeda = base64.encode(JSON.stringify(path));
  // let decodeda = base64.decode(encodeda);

  const [image, setImage] = useState({ imagem: '' });
  const [file, setFile] = useState({
    name: 'filename.jpg',
  });

  const [imageMap, setImageMap] = useState('');
  const [areaCircle, setAreaCircle] = useState(parseFloat(area).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
  const property_id = localStorage.getItem('Property') || 1;

  const [areaMap, setAreaMap] = useState({
    id: areasMap_data.id,
    id_property: property_id,
    name: areasMap_data.name,
    area: areaCircle,
    measure: areasMap_data.measure,
    coordinates: encoded,
    zoom: 15,
    address: '',
    description: '',
    image: '',
  });

  useEffect(() => {
    console.log('useEffect');
    if (path != '') {
      handlePolygonChange(path);
    }
  }, [areaMap.measure]);

  // const calculatedArea = calculatePolygonArea(path, areaMap?.measure);

  const calculatePolygonArea = (coordinates, measure) => {
    const areaGoogle = window.google.maps.geometry.spherical.computeArea(coordinates);

    if (measure === 'm2') {
      const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedArea;
    } else if (measure === 'ha') {
      const areaHectares = areaGoogle * 0.0001; // 1 hectare = 10.000 metros quadrados
      const formattedAreaEmHectares = areaHectares.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmHectares;
    } else if (measure === 'acres') {
      const areaAcres = areaGoogle * 0.000247105; // 1 acre = 0.000247105 acres
      const formattedAreaEmAcres = areaAcres.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmAcres;
    }
    //console.log(areaGoogle)
    return areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const calculateCircule = (areaCicle, measure) => {
    console.log('calculateCircule');
    console.log(areaCicle);
    const numeroSemPontos = areaCicle.replace(/\./g, '');
    const numeroTransformado = numeroSemPontos.replace(',', '.');
    const areaGoogle = numeroTransformado;
    console.log('areaGoogle');
    console.log(areaGoogle);

    if (measure === 'm2') {
      const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedArea;
    } else if (measure === 'ha') {
      const areaHectares = areaGoogle * 0.0001; // 1 hectare = 10.000 metros quadrados
      const formattedAreaEmHectares = areaHectares.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmHectares;
    } else if (measure === 'acres') {
      const areaAcres = areaGoogle * 0.000247105; // 1 acre = 0.000247105 acres
      const formattedAreaEmAcres = areaAcres.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmAcres;
    }
    //console.log(areaGoogle)
    return areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handlePolygonChange = (path) => {
    console.log('handlePolygonChange');
    const { area, measure } = areaMap;
    const calculatedArea = calculatePolygonArea(path, measure);
    //console.log('entrou')
    //console.log(area)

    setAreaMap((prevState) => ({
      ...prevState,
      area: calculatedArea,
    }));
  };

  const handleChangeMeasure = (event) => {
    console.log('handleChangeMeasure');
    const newMeasure = event.target.value;
    //console.log(newMeasure)
    //console.log(path, areaMap.area)
    let calculatedArea = '';
    if (path.length > 0) {
      calculatedArea = calculatePolygonArea(path, newMeasure);
    } else {
      //console.log(areaCircle)
      calculatedArea = calculateCircule(areaCircle, newMeasure);
    }

    // Atualiza a medida e a área calculada
    setAreaMap((prevState) => ({
      ...prevState,
      measure: newMeasure,
      area: calculatedArea,
    }));
  };

  // id_property varchar(100),
  // coordinates VARCHAR(300),
  // zoom VARCHAR(100),
  // name varchar(100),
  // area varchar(100),
  // measure VARCHAR(100),
  // address VARCHAR(100),
  // description VARCHAR(100),

  const handleChange = (event, tipo) => {
    event.persist();
    var value = tipo ? event.target.value.trim() : event.target.value;

    if (event.target.name == 'name') {
      validateName(value);
    } else if (event.target.name == 'area') {
      value = parseFloat(event.target.value);
      setFormError({ ...formError, [event.target.name]: value });
    }

    setAreaMap({ ...areaMap, [event.target.name]: value });
  };

  // para validar o input name
  const validateName = (value) => {
    var name_area = value.trim();
    if (!name_area || name_area.length < 5) {
      setFormError({ ...formError, name: 'Por favor, insira um nome válido para sua área!' });
    } else {
      var list_areas = usestore.reposAreas.getAreaMapStore('name', name_area);
      if (list_areas.length > 0) {
        var compare_area = list_areas.filter((area_repos) => {
          if (area_repos.id != areaMap.id) {
            return area_repos;
          }
        });
        if (compare_area.length > 0) {
          setFormError({ ...formError, name: 'Já existe outra área com esse nome!' });
        }
      } else {
        setFormError({ ...formError, name: '' });
      }
    }
  };

  // ipload de imagesn https://www.youtube.com/watch?v=kBSckls-Ih4
  //https://makitweb.com/take-screenshot-of-webpage-with-html2canvas/
  //Crie uma imagem a partir de um elemento dom
  //https://medium.com/@minhquocece/use-html2canvas-to-capture-google-map-instead-of-google-static-maps-api-with-angular-7-2ed415a46ba3
  const captureImage = () => {
    //Para renderizar um elementcom html2canvas, basta chamar: html2canvas(element[, options]);
    //Método de chamada  html2canvas()que recebe o seletor como parâmetro e com resposta de then manipulação .canvas
    // função retorna uma promessa contendo o <canvas>elemento.
    //com backgroundColor: null definirá o plano de fundo da tela como transparente;
    //useCORS: verdadeiro. Para aceitar imagens do servidor do Google para desenhar a tela.
    // html2canvas(document.body).then(function (canvas) {
    var element = document.getElementById('map');
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      //var a = document.createElement('a')
      //console.log(a)
      //canvas.toDataURL() método retorna o valor base64 que você pode usar para criar uma fonte de imagem ou um arquivo de imagem.
      var scale = 0.5; // Define a escala desejada (por exemplo, 0.5 para reduzir pela metade)
      var width = canvas.width * scale; // Calcula a nova largura proporcionalmente
      var height = canvas.height * scale; // Calcula a nova altura proporcionalmente
      var scaledCanvas = document.createElement('canvas'); // Cria um novo canvas escalado
      scaledCanvas.width = width;
      scaledCanvas.height = height;

      var scaledContext = scaledCanvas.getContext('2d');
      scaledContext.drawImage(canvas, 0, 0, width, height); // Desenha a imagem original no novo canvas escalado

      var base64image = scaledCanvas.toDataURL('image/jpeg', 0.8);

      const blobAreaTranferImage = base64ToBlob(base64image, 'image/jpeg');
      console.log(blobAreaTranferImage); // O objeto Blob resultante

      setAreaMap((prevState) => ({
        ...prevState,
        imageBlob: blobAreaTranferImage,
      }));
      // Salva a URL da imagem em uma variável
      //limit: 102400
      setImageMap(base64image);
      const compressedImagebase64 = LZString.compressToBase64(base64image);
      setAreaMap((prevState) => ({
        ...prevState,
        image: compressedImagebase64,
      }));
      //console.log("areaMap.image")
      //console.log(areaMap.image)
      setImage({ imagem: areaMap.image });

      //base64image.replace('image/jpeg', 'image/octet-stream');
      //console.log(a)
      //a.href = base64image
      //console.log(a)
      //a.download = file.name
      //console.log(a)
      //a.click()
      //save();

      // Obtém base64URL
      //const formData = { imagem: base64image }
      // enviar para back-end
      // await api.post("/upload-image", formData)

      //document.body.appendChild(canvas) para anexar uma nova tela ao corpo
      // document.body.appendChild(canvas);
    });

    // html2canvas(element).then(function (canvas) {
    //   var image = canvas.toDataURL("image/png");
    //   var pHtml = "<img src=" + image + " />";
    //   return pHtml
    // });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(true);
  //   }, 2000);
  //   console.log("carregou");
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      captureImage();
    }, 100);
  }, []);

  const onSubmit = async () => {
    console.log('entrou no save onSubmit');
    setLoadingButton(true);
    save();
  };

  const { aux_add, setAuxAdd } = useContext(Context);
  //const [formError, setFormError] = useState({});
  const [formError, setFormError] = useState({ name: '', area: areaMap.area });

  async function save() {
    setLoadingButton(true);
    let formOk = true;

    if (!areaMap.name) {
      formOk = false;
    } else if (!areaMap.area) {
      formOk = false;
    }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const response = await api
        .put('/areamap/upload', areaMap, header)
        .then((response) => {
          if (response.data.isSuccess) {
            setIsLoading(true);

            usestore.reposAreas.fetch({ id_farm: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
                navigate('/areasmaps');
              }, 1800);
            });
          } else {
            toast.error('Falha ao salvar Área tente novamente mais tarde! Caso o erro persista, contate o suporte.');
          }
        })
        .catch((error) => {
          toast.error('Falha ao salvar Área tente novamente mais tarde! Caso o erro persista, contate o suporte.');
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios');
    }
  }

  return (
    <>
      {isLoading && <SuccessEditDraw />}
      <Box onSubmit={(e) => onSubmit(e)} sx={{ p: 2, mt: 2 }}>
        <Card>
          {!imageMap ? (
            <>
              <Box height={140}>
                <LinearProgress color="success" />
                <Skeleton variant="rectangular" width={'100%'} height={136} />
              </Box>
            </>
          ) : (
            <CardMedia component="img" height="140" image={imageMap} />
          )}
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  required
                  id="name"
                  name="name"
                  label="Nome da área"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  color="success"
                  value={areaMap.name}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, 'name')}
                  error={formError.name != ''}
                  helperText={formError.name != '' ? formError.name + ' Mínimo 5 letras!' : '*Insira o nome da propriedade'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="area"
                  name="area"
                  label="Dimensão"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  color="success"
                  value={areaMap.area}
                  onChange={handleChange}
                  error={!formError.area}
                  helperText={!formError.area ? 'Por favor, preencha o campo obrigatório de dimensão!' : 'Tamanho da área'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="measure"
                  name="measure"
                  label="Medida"
                  select
                  variant="standard"
                  color="success"
                  value={areaMap.measure}
                  onChange={handleChangeMeasure}
                  helperText="Unidade de medida"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button sx={{ width: 140, ml: 1, ml: 'auto' }} color="success" variant="contained" type="submit" onClick={onSubmit}>
              {LoadingButton ? (
                <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
              ) : (
                <>
                  <SaveAltIcon sx={{ mr: 1 }} />
                  Atualizar
                </>
              )}
            </Button>
            {/* <Button sx={{ ml: 1, ml: "auto" }} color="success" variant="contained" onClick={onSubmit}>
              <SaveAltIcon sx={{ mr: 1 }} />
              Salvar
            </Button> */}
            {/* <Typography sx={{ ml: "auto", fontSize: 12 }} color="text.secondary">
              10/12/2022
            </Typography> */}
            {/* <MoreVertIcon sx={{ ml: "auto" }} /> */}
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
