// import { DatePicker } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";



const currencies = [
  {
    value: "ha",
    label: "hectare (ha)",
  },
  {
    value: "m2",
    label: "metros (m²)",
  },
];

export default function FormGoalPlanning({ handleClose }) {
  const [state, setState] = useState({ date: new Date() });
  const [currency, setCurrency] = useState("hectares");

  const handleChangeArea = (event) => {
    setCurrency(event.target.value);
  };

  const handleSubmit = (event) => {
    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });

  const { username, firstName, creditCard, mobile, password, confirmPassword, gender, date, email } = state;
  //onSubmit={handleSubmit} onError={() => null
  return (
    <section className="content ">
      <div className="container-fluid mt-4 " style={{ maxWidth: 900 }}>
        <Box component="form">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                helperText="Por favor, defina o valor médio da compra de seus animais"
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                required
                id="property"
                name="property"
                label="Valor médio da compra de seus animais"
                fullWidth
                autoComplete="shipping property-line1"
                variant="standard"
                color="success"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                required
                id="firstName"
                name="firstName"
                label="Peso de entrada"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: '#1976d2' } }}
                required
                id="firstName"
                name="firstName"
                label="Estime o Peso de saída desejado"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                required
                id="firstName"
                name="firstName"
                label="Data de entrada"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: '#1976d2' } }}
                required
                id="firstName"
                name="firstName"
                label="Data da Previsão de venda"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
              />
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <TextField
              helperText="Por favor, defina o valor médio da compra de seus animais"
              //InputLabelProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{ style: { color: '#1976d2' } }}
              required
              id="property"
              name="property"
              label="Defina o custo estimado de gasto com um animal"
              fullWidth
              autoComplete="shipping property-line1"
              variant="standard"
              color="success"
            />
          </Grid>

          <br />
        </Box>
      </div>
    </section>
  );
}
