import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import CardChangePassword from './components/CardChangePassword';

export default function ChangePasswordContent() {

  return (
    <>
      <div className="content-wrapper bg-white">
        <section className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                  Atualize&nbsp;a&nbsp;senha&nbsp;quando&nbsp;necessário.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardChangePassword />
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </>
  );
}
