import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

function processData(activityResources) {
  return {
    id_property: activityResources.id_property,
    id_user: activityResources.id_user,
    id_activity: activityResources.id_activity,
    name_activity: activityResources.name_activity,
    id_project: activityResources.id_project,
    tipo_repos: activityResources.tipo_repos,
    value: activityResources.value,
    description: activityResources.description || '',
    id_trans_entry: activityResources.id_trans_entry,
    date_entry: dayjs(activityResources.date_entry).format('YYYY-MM-DD HH:mm:ss'),
    date_entry_f: dayjs(activityResources.date_entry).format('DD/MM/YYYY'),
    id_trans_pay: activityResources.id_trans_pay,
    date_payment: dayjs(activityResources.date_payment).format('YYYY-MM-DD HH:mm:ss'),
    date_payment_f: dayjs(activityResources.date_payment).format('DD/MM/YYYY'),
    id_trans_inv: activityResources.id_trans_inv,
    date_inventory: dayjs(activityResources.date_inventory).format('YYYY-MM-DD HH:mm:ss'),
    date_inventory_f: dayjs(activityResources.date_inventory).format('DD/MM/YYYY'),
    products: activityResources.products == 'string' ? JSON.parse(activityResources.products || '[]') : activityResources.products,
    expenses: activityResources.expenses == 'string' ? JSON.parse(activityResources.expenses || '[]') : activityResources.expenses,
    type: activityResources.type || 'Utilização de recursos',
    // lote: (typeof activityIdentify.lote == 'string' ? JSON.parse(activityIdentify.lote) : activityIdentify.lote),
    fkys: typeof activityResources.fkys == 'string' ? JSON.parse(activityResources.fkys || '[]') : activityResources.fkys,
    //fkys: JSON.parse(activityResources.fkys || '[]'),
    info: typeof activityResources.info == 'string' ? JSON.parse(activityResources.info || '{}') : activityResources.info,
  };
}

// create the store
const useActivityResourcesReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityResources: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityResources/list', item, header);
          const data = await response.data;
          const data_v = data._value || [];
          console.log('activityResources');
          console.log(data);

          await set({
            listActivityResources: data_v.map((activityResources) => {
              return processData(activityResources);
            }),
          });

          return data;
        },
        // pega a(s) produção(ões) da listActivityResources de acordo com o [key e val] informados
        getActivityResourcesStore: (key, val) => {
          return get().listActivityResources.filter((activityResources) => {
            if (activityResources[key] == val) {
              return activityResources;
            }
          });
        },
        // Gera uma nova listActivityResources sem a activity informada
        delResourcesStore: (id) => {
          set({
            listActivityResources: get().listActivityResources.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
        // atualiza listActivityResources de acordo com id, key e val informados
        updateActivityResourcesStore: (id, key, val) => {
          set({
            listActivityResources: get().listActivityResources.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          });
          return get().listActivityResources;
        },
        // consulta os lançamentos do ano informado
        fetchFiltered: async (filters) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!filters) {
            filters = {};
          }
          if (!filters.filter) {
            filters.filter = true;
          }
          if (!filters.id_property) {
            filters.id_property = id_property;
          }
          // carrega dados do ano
          if (!filters.between) {
            filters.date = 'ap.date_start';
            filters.dateI = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss');
            filters.dateF = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss');
          }

          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityResources/list', filters, header);
          const data = await response.data;
          const data_v = data._value || [];
          // console.log('listActivityResource');
          // console.log(data_v);

          var listActivityResource = data_v.map((activityResources) => {
            return processData(activityResources);
          });

          return listActivityResource;
        },
      }),
      { name: 'reposActivityResources' }
    )
  )
);

export default useActivityResourcesReposStore;
