export default function propertyProgress(property) {
  const initialLabel = 'Fazenda';
  const initialAddress = '';
  const initialArea = 0;
  const initialTel = '';
  const initialImage = '';
  const fields = ['label', 'address', 'area', 'tel', 'image'];

  if (!property || property.length === 0) {
    return { progress: 0, status: {} };
  }

  const currentProperty = property[0];
  let progress = 0;
  const status = {};

  if (currentProperty.label !== initialLabel) {
    progress += 20;
    status.label = 'Ok';
  } else {
    status.label = '?';
  }

  if (currentProperty.address !== initialAddress) {
    progress += 20;
    status.address = 'Ok';
  } else {
    status.address = '?';
  }

  if (currentProperty.area !== initialArea) {
    progress += 20;
    status.area = 'Ok';
  } else {
    status.area = '?';
  }

  if (currentProperty.tel !== initialTel) {
    progress += 20;
    status.tel = 'Ok';
  } else {
    status.tel = '?';
  }

  if (currentProperty.image !== initialImage) {
    progress += 20;
    status.image = 'Ok';
  } else {
    status.image = '?';
  }

  return { progress, status };
}
