import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import { Assignment, Business, Contacts, ExpandMore, FormatAlignJustify, Input, Notes } from '@mui/icons-material';

import Typewriter from '../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../components/Footer/FooterModal';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import AddNewContact from './AddNewContact';
import AddNewAddress from './AddNewAddress';

const typePerson = [
  {
    value: "Física",
    label: "Física",
  },
  {
    value: "Jurídica",
    label: "Jurídica",
  },
  {
    value: "Outro",
    label: "Outro",
  }
];

const documentType = [
  {
    value: "CPF",
    label: "CPF",
    display: 'Jurídica',
  },
  {
    value: "CNPJ",
    label: "CNPJ",
    display: 'Física',
  },
  {
    value: "RG",
    label: "RG",
    display: 'todos',
  },
  {
    value: "Outro",
    label: "Outro",
    display: 'todos',
  }
];

export default function FormPersonStep1({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);
  const reposPersons = obj_fn.usestore.reposPerson;

  const [validName, setValidName] = useState([true, '']);

  const validateName = (value) => {
    var name_person = value.trim();
    if (!name_person || name_person.length < 5) {
      setValidName([false, 'Por favor, insira um nome válido para a pessoa!']);
    } else {
      var list_persons = reposPersons.getPersonStore('name', name_person);
      if (list_persons.length > 0) {
        var compare_person = list_persons.filter((person) => { return person.id !== data.id; });
        if (compare_person.length > 0) {
          setValidName([false, 'Já existe outra Pessoa com esse nome!']);
        }
      } else {
        setValidName([true, '']);
      }
    }
  };
  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return "* Mínimo de 5 caracteres";
      } else {
        return ( <p id="NamePersonError" className="form-text text-danger is-invalid">{validName[1]}</p> );
      }
    } else {
      return validName[num];
    }
  };

  const handleChange = (event, blur) => {
    let field = event.target.name;
    let value = event.target.value;

    obj_fn.setData(prevState => ({ ...prevState, [field]: (field === 'name' && blur ? value.trim() : value) }));
    if (field === 'typePerson') {
      if (value === 'Jurídica') {
        obj_fn.setData(prevState => ({ ...prevState, document_type: 'CNPJ' }));
      } else if(value === 'Física') {
        obj_fn.setData(prevState => ({ ...prevState, document_type: 'CPF' }));
      }
    }
    if (field === 'name') {
      validateName(value);
    }
  };
  const handleChangeCheck = (name) => (event) => {
    // console.log('handleChangeCheck');
    obj_fn.setData((prevState) => ({ ...prevState, types: { ...prevState.types, [name]: event.target.checked, } }));
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    let valid = false;
    if (!data.types.cliente && !data.types.fornecedor && !data.types.transportadora) {
      valid = true;
    } else if (!data.name || data.name.length < 5) {
      valid = true;
    } else if (data.contact_err || data.address_err) {
      valid = true;
    }

    return valid;
  };

  const returnError = () => {
    return true;
  };

  return ( <>
    <Grid container sx={{ mb: 4 }}>
      <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }} sx={{ mt: -4, mb: 2 }}>
        <Box display="flex" alignItems="center">
          {!showTextFase ? <ThreeDotsSpinner /> : <Typewriter text="Preencha as informações de identificação." variant={"subtitle1"} color={"#2e7d32"} /> }
        </Box>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Input sx={{mr: 1}}/> Dados gerais</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} sm={12} display={(data.fix_type ? 'flex' : 'none')} >
          <FormControl color="success" component="fieldset">
            <FormLabel sx={{mb: '-5px', color:"#2e7d32"}}>Cadastrar como: *</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox color="success" checked={data.types.cliente} onChange={handleChangeCheck('cliente')} />}
                label="Cliente"
              />
              <FormControlLabel
                control={<Checkbox color="success" checked={data.types.fornecedor} onChange={handleChangeCheck('fornecedor')} />}
                label="Fornecedor"
              />
              <FormControlLabel
                control={<Checkbox color="success" checked={data.types.transportadora} onChange={handleChangeCheck('transportadora')} />}
                label="Transportadora"
              />
            </FormGroup>
            <Typography variant="body2" color="textSecondary" component="div" sx={{mt: -1}}>
              * Selecione pelo menos uma opção!
            </Typography>
          </FormControl>
        </Grid>
      </Grid>

      {/* Informações gerais */}
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={6} sm={2}>
          <TextField
            InputLabelProps={{ style: { color: "#2e7d32" } }}
            id="typePerson"
            name="typePerson"
            label="Tipo de pessoa"
            select
            fullWidth
            value={data.typePerson}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Assignment />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            color="success"
          >
            {typePerson.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            InputLabelProps={{ style: { color: "#2e7d32" } }}
            id="document_type"
            name="document_type"
            label="Tipo de Documento"
            select
            fullWidth
            autoComplete="document"
            value={data.document_type}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Assignment />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            color="success"
            >
            {documentType.map((option) => (
              <MenuItem key={option.value} value={option.value} style={{display:(option.display !== data.typePerson ? 'flex' : 'none')}}>
                {option.label}
              </MenuItem> 
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            InputLabelProps={{ style: { color: "#2e7d32" } }}
            id="document"
            name="document"
            label={"Número do " + data.document_type}
            fullWidth
            autoComplete="document"
            value={data.document}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Assignment />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            color="success"
          />
        </Grid>
        <Grid item xs={6} sm={5}>
          <TextField
            required
            InputLabelProps={{ style: { color: "#2e7d32" } }}
            id="name"
            name="name"
            label={data.typePerson === 'Jurídica' ? "Nome Fantasia" : "Nome"}
            fullWidth
            autoComplete="name"
            variant="standard"
            color="success"
            value={data.name}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Assignment />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            //error={!data.name || data.name.length < 5}
            error={!returnValidName(0)}
            helperText={returnValidName(1)}
          />
        </Grid>
      </Grid>

      {/* Informações fiscais */}
      <Grid container spacing={3} sx={{ mb: 3 }} display={(data.typePerson === 'Jurídica' ? 'flex' : 'none')}>
        <Grid item xs={12} sm={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ ml: 1, display: "flex" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}><Input sx={{mr: 1}}/> Informações fiscais</Typography>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="corporateReason"
                    name="corporateReason"
                    label="Razão social"
                    fullWidth
                    autoComplete="corporateReason"
                    variant="standard"
                    color="success"
                    value={data.corporateReason}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <FormatAlignJustify />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText="Insira a Razão Social"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Optante pelo simples?</FormLabel>
                    <RadioGroup
                      aria-label="Optante pelo simples?"
                      name="simples_nacional"
                      value={data.simples_nacional}
                      onChange={handleChange}
                    >
                      <Grid container direction="row" spacing={2}>
                        <Grid item>
                          <FormControlLabel value="nao" control={<Radio color="success"/>} label="Não" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value="sim" control={<Radio color="success"/>} label="Sim" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="stateregistration"
                    name="stateregistration"
                    label="Inscrição estadual"
                    fullWidth
                    autoComplete="stateregistration"
                    variant="standard"
                    color="success"
                    value={data.stateregistration}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <FormatAlignJustify />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={"Insira a Inscrição Estadual"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="municipalRegistration"
                    name="municipalRegistration"
                    label="Inscrição municipal"
                    fullWidth
                    autoComplete="municipalRegistration"
                    variant="standard"
                    color="success"
                    value={data.municipalRegistration}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <FormatAlignJustify />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={"Insira a Inscrição Municipal"}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {/* Contatos */}
      <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Contacts sx={{mr: 1}}/> Dados para contato</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} sm={12}>
          <AddNewContact contacts={data.contacts} setData={obj_fn.setData}/>
        </Grid>
      </Grid>

      {/* Endereços */}
      <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Business sx={{mr: 1}}/> Dados para localização</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} sm={12}>
          <AddNewAddress addresses={data.addresses} setData={obj_fn.setData}/>
        </Grid>
      </Grid>

      {/* Observações */}
      <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
        <Grid item xs={12} sm={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" >
              <Notes />
              <Box sx={{ ml: 1, display: "flex" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}> Observações gerais</Typography>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <TextareaAutosize
                    minRows={3} // Customize the initial number of rows
                    maxRows={10} // Customize the maximum number of rows
                    name="observations"
                    placeholder="Observações Gerais"
                    value={data.observations}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    style={{ width: '100%', padding: '8px' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid >

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}