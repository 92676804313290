import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, HeatmapLayer, InfoWindow, Marker, OverlayView, Polygon } from '@react-google-maps/api'
import { decode as base64_decode, encode as base64_encode } from 'base-64'
import LoadScriptOnlyIfNeeded from '../../../../../../LoadScript/LoadScriptOnlyIfNeeded'
// import { styled } from '@mui/material/styles'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Avatar, Box, Card, Grid, IconButton, Skeleton, Slider, Stack, Typography } from '@mui/material'
import useStore from '../../../../../../store/useStore'
import './styles.css'

const libraries = ['drawing', 'places', 'visualization']
const apiKey = 'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU'

// const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 0.25,
    label: '1',
  },
  {
    value: 0.5,
    label: '2',
  },
  {
    value: 0.75,
    label: '3',
  },
  {
    value: 1,
    label: '4',
  },
]

function valuetext(value) {
  return `${value}`
}

const MapViewMonitoring = ({ areaId, data, setData }) => {
  // console.log('MapView1 ')

  const usestore = useStore()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  //o estado map representa a instância do mapa do Google Maps.
  //função será usada para atualizar a instância do mapa
  const [map, setMap] = React.useState()

  //id="map"
  // será usado para referenciar o mapa
  // é uma maneira de criar uma referência ao elemento do mapa na DOM para que você possa acessá-lo e manipulá-lo diretamente.
  //O useRef é usado para criar uma referência mutável que persiste durante toda a vida do componente.
  const mapRef = useRef(null)

  // carrego o id da criação
  //carego o id da propriedade
  //const { idFarm, id } = useParams()

  // dados da propriedade
  // mapa: definir o centro da propriedade
  //const [center, setCenter] = useState({ lat: -20.9223027, lng: -42.7958599 });
  const [center, setCenter] = useState({ lat: '', lng: '' })
  const [zoom, setZoom] = useState(center.zoom || 15)

  // livestock, areas: as aŕaeas da livestock, lote: lote especifico selecionado, opt_lotes: lista de lotes com as áreas
  //console.log(data)

  // inseri a área selecionada que está no data
  //const list_areas = data.areas
  // console.log(data.areas)
  // console.log(areaId)
  const list_areas = [data.areas.find((item) => item.id == areaId)]
  // console.log(list_areas)
  // devo criar a lista de áreas ativas do lote
  // for (const area of livestockRepos.areas) {
  //   // pego o id da area
  //   //console.log(area.id)
  //   const areaActive = usestore.reposAreas.getAreaMapStore('id', area.id)
  //   list_areas.push(areaActive[0])
  // }
  //lista de áreas
  // console.log(list_areas)
  // console.log(data.areas)

  function calcularCentroNaExtremidadeInferior(polygonCoords) {
    let minLat = polygonCoords[0].lat // Inicializar com a primeira latitude do polígono
    let centroidX = minLat
    let centroidY = polygonCoords[0].lng

    for (let i = 1; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat
        centroidX = minLat
        centroidY = polygonCoords[i].lng
      }
    }

    // Adicionar o deslocamento
    const deslocamentoLatitude = 0.002
    const deslocamentoLongitude = 0.0001

    centroidX += deslocamentoLatitude
    centroidY += deslocamentoLongitude

    return { lat: centroidX, lng: centroidY }
  }

  // definir a posição do marker ao selecionar a área desejada para transferencia
  const [markerPosition, setMarkerPosition] = useState()

  // const [listMarkerPosition, setListMarkerPosition] = useState()

  // const listAreaBatch = []

  // for (const [index, areaBatch] of listPastureManagementActive.entries()) {
  //   console.log('areaBatch')
  //   console.log(areaBatch)
  //   console.log(listPastureManagementActive)
  //   console.log(listPastureManagementActive[0].area.coordinates)
  //   //console.log(listPastureManagementActive.area.coordinates)
  //   // // criar um array com as coordernadas de cada área da lista para plotar no mapa
  //   let decoded_coordinates = JSON.parse(base64_decode(areaBatch.area.coordinates))
  //   // console.log(decoded_coordinates)
  //   // //const name_coordinates = getPolygonBaseCenter(decoded_coordinates)
  //   // //console.log(name_coordinates)
  //   listAreaBatch.push({
  //     index: index,
  //     name: areaBatch.area.label,
  //     //areaUse: areaActive.areaUse,
  //     //areatotal: areaActive.areatotal,
  //     batch: areaBatch.batchName,
  //     num_animals: areaBatch.number_animals,
  //     // icon: areaBatch.icon,
  //     coordenadas: calcularCentroEntrePoligonosNew(decoded_coordinates, index),
  //   })
  //   console.log('listAreaBatch')
  //   console.log(listAreaBatch)
  //   //console.log(listAreaBatch[index].coordenadas)
  // }
  // useEffect(() => {
  //   setListMarkerPosition(listAreaBatch)
  // }, [])

  //setListMarkerPosition(listAreaBatch)

  // devo criar um array para armazenar a lista de coordenada de cada área
  const [plgn_areas, setPolygons] = useState([])

  var paths_coord = []
  //let markerBatchCoordinates = []
  // lista de áreas com coordenadas decodigicadas para serem plotadas no mapa
  const listAreaActive = []

  // 1- heap: defina os pontos

  // const [infestationData, setInfestationData] = useState([]); // Dados do heatmap
  // const infestationData = [
  //   { lat: -20.90803482728438, lng: -42.79529722248069, weight: 0.5 },
  //   { lat: -34.397, lng: 150.644, weight: 0.5 }, // Verde - nenhuma praga
  //   { lat: -34.39, lng: 150.65, weight: 1.0 }, // Amarelo - dentro do nível de controle
  //   Adicione mais pontos conforme necessário
  // ]
  // esta usando na dom:
  //  <HeatmapLayer
  //   data={infestationData.map((point) => new window.google.maps.LatLng(marker.lat, marker.lng))}
  //ao inves de usar o array, vou pssar o maker

  const [heatmapRadius, setHeatmapRadius] = useState(50) // Valor inicial de 50 pixels
  const [heatmapWeight, setHeatmapWeight] = useState(0.5) // Valor inicial do peso dos pontos de dados
  // const updateHeatmapRadius = (currentZoom) => {
  //   // Calcula o novo raio do heatmap com base no zoom
  //   const newRadius = Math.min(1000, currentZoom * 10) // Pode ajustar o fator multiplicador conforme necessário
  //   setHeatmapRadius(newRadius)
  // }

  // referencia atual do zoom do mapa
  //const onZoomChanged = (value) => {
  //console.log(zoom)
  //console.log(value)
  //console.log(mapRef.current);
  //if (!mapRef.current) return
  //setZoom(mapRef.current?.zoom)
  // console.log(zoom);
  //if (value) setZoom(value)
  //}
  // Callback para atualizar o estado do zoom quando ele mudar
  const onZoomChanged = () => {
    // console.log('zoom')
    // console.log(zoom)
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom()
      setZoom(newZoom)
      // setData((prevData) => ({
      //   ...prevData,
      //   ['zoom']: newZoom,
      // }))
      setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, ['zoom']: newZoom } : area)),
      }))
      // Atualiza o raio do heatmap com base no novo zoom
      updateHeatmapRadius(newZoom)
    }
  }

  // Função para atualizar o raio do heatmap com base no zoom
  const updateHeatmapRadius = (currentZoom) => {
    // Calcula o novo raio com base no zoom
    const newRadius = currentZoom * 20 // Por exemplo, você pode ajustar o multiplicador conforme necessário
    // console.log(newRadius)
    //setHeatmapRadius(newRadius)
  }

  // Função para calcular o raio do HeatmapLayer com base no zoom e na distância em metros
  // const calculateRadius = (zoom, latitude, meters) => {
  //   const earthCircumference = 40075017 // Circunferência da Terra em metros ao redor do equador
  //   const scale = Math.pow(2, zoom) // A escala muda com o zoom
  //   const latitudeRadians = latitude * (Math.PI / 180)
  //   const metersPerPixel = (earthCircumference * Math.cos(latitudeRadians)) / (256 * scale)
  //   return meters / metersPerPixel
  // }

  // // Lógica para ajustar o raio do HeatmapLayer com base no zoom atual
  // useEffect(() => {
  //   // Ajuste aqui o cálculo do raio do heatmap conforme necessário
  //   const meters = heatmapRadius + 300 // Defina a distância em metros
  //   const newRadius = calculateRadius(zoom, center.lat, meters)
  //   setHeatmapRadius(newRadius)
  // }, [zoom, center])

  //   function adjustRadiusBasedOnData() {
  //   const density = calculateDensity(infestationData); // Função fictícia para calcular densidade
  //   setHeatmapRadius(density > 5 ? 100 : 50); // Ajusta o raio com base na densidade
  // }

  // marker de pontos

  //const mark_strg = localStorage.getItem('marker') || ''
  //const [marker, setMarker] = useState(mark_strg ? JSON.parse(mark_strg) : {})
  const [marker, setMarker] = useState({})
  localStorage.setItem('marker', JSON.stringify(marker))
  const [selected, setSelected] = useState(null)
  // carregar a referencia do mapa
  const handleLoad = (map) => {
    mapRef.current = map
    // carrega a redfencia do map e o mapa de calor
    setMap(map)
  }
  const onLoadMarker = (marker) => {
    // console.log('marker: ', marker)
  }

  function handlePolygonClick(e, area) {
    let newSetMarker = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
      time: new Date(),
    }
    setMarker(newSetMarker)
    // setData((prevData) => ({
    //   ...prevData,
    //   ['marker']: newSetMarker,
    // }))
    let encodedMaker = base64_encode(JSON.stringify(newSetMarker))

    setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, ['marker']: encodedMaker } : area)),
    }))
  }

  useEffect(() => {
    // Grava os valores iniciais de heatmapRadius e heatmapWeight em setData quando o componente é montado
    setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area) => ({
        ...area,
        heatmapRadius,
        heatmapWeight,
      })),
    }))
  }, [])

  const handleInputChange = (field, value) => {
    // console.log(field)
    // console.log(value)
    if (field == 'heatmapRadius') {
      setHeatmapRadius(value)
    }

    if (field == 'heatmapWeight') {
      setHeatmapWeight(value)
    }

    // Aqui você pode ajustar como quer atualizar os estados baseado no nome do campo e valor
    // Suponho que você tenha um estado chamado `data` que armazena todos os valores do formulário
    // setData((prevData) => ({
    //   ...prevData,
    //   [field]: value,
    // }))
    setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area) => (area.id === areaId ? { ...area, [field]: value } : area)),
    }))
  }

  const handleValueChange = (field, value) => {
    // Aqui você pode ajustar como quer atualizar os estados baseado no nome do campo e valor
    // Suponho que você tenha um estado chamado `data` que armazena todos os valores do formulário
  }

  // console.log(marker)

  // fim od marker de pontos

  //const [iconMarkerBatch, setIconMarkerBatch] = useState({})
  // const [numberAnimalsBatch, setNumberAnimalsBatch] = useState(data.lote.total_animals.toString())

  async function getId() {
    //console.log("getId: get poligons areaUsed");
    let iconUrl, position
    for (const [index, areaActive] of list_areas.entries()) {
      // console.log("areaActive")
      // criar um array com as coordernadas de cada área da lista para plotar no mapa
      // console.log(areaActive)
      let decoded_coordinates = JSON.parse(base64_decode(areaActive.coordinates))
      // console.log(decoded_coordinates)
      //const name_coordinates = getPolygonBaseCenter(decoded_coordinates)
      //console.log(name_coordinates)
      listAreaActive.push({
        name: areaActive.name,
        //areaUse: areaActive.areaUse,
        //areatotal: areaActive.areatotal,
        coordenadas: decoded_coordinates,
      })
      // console.log("listAreaActive")
      // console.log(listAreaActive)
      // console.log(areaActive.id, id_areamap)

      // pegar as coordendas da área do lote plotará no grafico
      // informações de numeros de animias total em cada área
      // if (areaActive.id === id_areamap) {
      //   //console.log("lote ativo")
      //   // console.log(areaActive)
      //   //console.log(areaActive.total_animais)
      //   //setNumberAnimalsBatch((areaActive.total_animais).toString())
      //   markerBatchCoordinates.push(decoded_coordinates)
      //   // console.log(markerBatchCoordinates[0])
      //   setMarkerBatch(getPolygonCenterbarycenter(markerBatchCoordinates[0]))
      //   setMarkerPosition(getPolygonCenterbarycenter(markerBatchCoordinates[0]))
      //   //console.log(markerBatch)

      // }

      paths_coord.push({
        coordenadas: decoded_coordinates,
        //'marks':
        iconUrl: iconUrl + '_afazer.png',
        name: areaActive.name,
        number_animals: areaActive.total_animais || 0,
        areatotal: areaActive.area,
        id: areaActive.id,
        // 'areaUse': areaUse,
      })
    }
    // setar a lista de coordenadas
    //console.log(paths_coord)
    setPolygons(paths_coord)
  }

  useEffect(() => {
    // getId();
    setTimeout(() => {
      getId()
    }, 1000)
  }, [])

  // calculo do centro do poligono
  // passa a lista de corrdenadas para o calculo do calcularCentroEntrePoligonos
  const listcoordinates = []
  for (const coordinates of plgn_areas) {
    listcoordinates.push(coordinates.coordenadas)
  }

  const calcularCentroEntrePoligonos = (poligonos) => {
    let centroLat = 0
    let centroLng = 0
    let totalVertices = 0

    for (const poligono of poligonos) {
      for (const coordenada of poligono) {
        centroLat += coordenada.lat
        centroLng += coordenada.lng
        totalVertices++
      }
    }

    centroLat /= totalVertices
    centroLng /= totalVertices

    return { lat: centroLat, lng: centroLng }
  }

  // cria o poligono
  const [drawingModeEnabled, setDrawingModeEnabled] = useState('polygon')

  const onLoadPoly = (polygon) => {
    //console.log(listcoordinates)
    const novoCentro = calcularCentroEntrePoligonos(listcoordinates)
    // console.log(novoCentro)
    setCenter(novoCentro)
    // setIsLoading(false)
  }

  const optionsDraw = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled,
    },
    fillColor: 'green',
    fillOpacity: 0,
    strokeColor: '#53f444',
    strokeWeight: 2,
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  }

  //console.log("xxxxx")
  //console.log(data.areaTranferId)

  // const renderOverlayContent = () => (
  //   <Card style={{ position: 'fixed', bottom: '4%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '10px' }}>
  //     <Box sx={{ p: 1, display: 'flex' }}>
  //       <div className="d-none d-lg-block">
  //         <Stack spacing={0} sx={{ ml: 1, alignItems: 'center', width: 400 }}>
  //           <Grid container spacing={1} sx={{ width: 400 }}>
  //             <Grid item>
  //               <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
  //                 <LocationOn />
  //               </Avatar>
  //             </Grid>
  //             <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
  //               <Typography fontWeight={700}>Tranferência do lote</Typography>
  //               <Typography variant="body2" color="text.secondary">
  //                 Origem: <strong>{data.lote.area.name}</strong> <ArrowRightIcon sx={{ mt: -0.2, color: green[500] }} /> Destino:{' '}
  //                 <strong>{data.areaTranferId.name == '' ? 'Clique na área desejada' : data.areaTranferId.name}</strong>
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //       </div>
  //       <div className="d-lg-none">
  //         <Typography variant="body2" fontWeight={700}>
  //           Clique na área
  //         </Typography>
  //       </div>
  //     </Box>
  //   </Card>
  // )

  // OverlayView do name da área
  const [expanded, setExpanded] = useState(false)

  const handleIconClick = () => {
    setExpanded(!expanded)
  }

  const [showInfoWindow, setShowInfoWindow] = useState(true)

  const handleMarkerClick = () => {
    setShowInfoWindow(true)
  }

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false)
  }

  /// Deslocamento vertical de -20 pixels (mover para cima)
  const infoWindowPixelOffset = { y: -20 }

  const infoWindowStyles = `
  background-color: black; // Cor de fundo preto
  color: white; // Cor do texto branco
  padding: 10px; // Espaçamento interno
`

  // console.log('center')
  // console.log(center)
  // console.log(data)
  // console.log(map)
  //{"lat": -20.90803482728438,"lng": -42.79529722248069}

  //crir um dumy

  //console.log(data.lote.id)
  return (
    <>
      {isLoading && center.lat !== '' ? (
        <Skeleton variant="rectangular" width={'75vw'} height={'80vh'} />
      ) : (
        <Box style={{ width: '75vw', height: '80vh' }}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={11} md={5}>
              <Box>
                <Typography id="input-slider" gutterBottom>
                  Raio
                </Typography>
                <Grid container spacing={2} sx={{ p: 1, maxWidth: 300 }} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={heatmapRadius}
                      valueLabelDisplay="off"
                      onChange={(event, newValue) => handleInputChange('heatmapRadius', newValue)}
                      aria-labelledby="continuous-slider"
                      min={0}
                      max={1000}
                    />
                  </Grid>
                  <Grid item>{heatmapRadius}</Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={11} md={5}>
              <Box>
                <Typography id="input-slider" gutterBottom>
                  Intensidade
                </Typography>
                <Grid container spacing={2} sx={{ p: 2, maxWidth: 300 }} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={heatmapWeight}
                      onChange={(event, newValue) => handleInputChange('heatmapWeight', newValue)}
                      aria-labelledby="continuous-slider"
                      marks={marks}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="off"
                      min={0.01}
                      max={1}
                      step={0.25}
                    />
                  </Grid>
                  <Grid item sx={{ ml: 2, width: 100 }}>
                    {heatmapWeight * 100 - 1} %
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
          {/* Sua legenda */}

          <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
            {/* <button type="text" placeholder="Last name" onClick={() => (refreshPage())} >Reload</button> */}
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              {/* <FloatingButtoDraw /> */}
              {/* <FloatingButtoDrawGeneral path={path} setPath={setPath} drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
              {/* <LightTooltip title="Retornar" placement="left">
          <Fab sx={{ position: "fixed", top: 1, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={() => navigate("/areasmaps")} aria-label="edit">
            <ReplyIcon />
          </Fab>
        </LightTooltip> */}
            </Box>
            {/* <SaveMapEditButton path={path} area={area} setArea={setArea} areasMap_data={areasMap_data} /> */}
            <GoogleMap
              id="map"
              mapContainerStyle={{
                height: '65vh',
                width: '65vw',
              }}
              ref={mapRef}
              center={center}
              zoom={Number(zoom)}
              onZoomChanged={onZoomChanged}
              onLoad={handleLoad}
              onClick={(e) => handlePolygonClick(e)}
              // onClick={(e) =>
              //   setMarker({
              //     lat: e.latLng.lat(),
              //     lng: e.latLng.lng(),
              //     time: new Date(),
              //   })
              // }
              options={{
                mapTypeId: 'satellite', // Define o tipo de mapa padrão como "Satélite"
                //mapTypeControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                  //style: window.google.maps.ZoomControlStyle.SMALL,
                  gestureHandling: 'greedy', // Torna o zoom mais sensível
                },
              }}
            >
              {map && marker && marker.lat && (
                <HeatmapLayer
                  // data={infestationData.map((point) => new window.google.maps.LatLng(marker.lat, marker.lng))}
                  data={[{ location: new window.google.maps.LatLng(marker.lat, marker.lng), weight: heatmapWeight }]}
                  options={{
                    gradient: [
                      'rgba(0, 255, 0, 0)', // Verde - transparência para nenhuma praga
                      'rgba(255, 255, 0, 1)', // Amarelo - dentro do nível de controle
                      'rgba(255, 165, 0, 1)', // Laranja
                      'rgba(255, 105, 97, 1)', // Vermelho Claro
                      'rgba(255, 0, 0, 1)', // Vermelho Escuro - infestações acima do nível
                    ],
                    opacity: 0.8,
                    //intensityOfMidpoint: 0.2,
                    dissipating: true, // Especifica se os mapas de calor se dissipam com o zoom.
                    radius: heatmapRadius, // Define o raio do efeito de calor em pixels
                  }}
                />
              )}
              {marker && marker.lat && (
                <Marker
                  key={marker.time}
                  onLoad={onLoadMarker}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  // icon={{
                  //   url: "/dist/image/iconLocation.png",
                  //   scaledSize: new window.google.maps.Size(40, 40),
                  //   //origin: new window.google.maps.Point(0, 0),
                  //   //anchor: new window.google.maps.Point(20, 20),
                  // }}
                  // icon={<PersonPinIcon />}
                  onClick={() => {
                    setSelected(marker)
                  }}
                >
                  {selected ? (
                    <InfoWindow
                      position={{ lat: selected.lat, lng: selected.lng }}
                      onCloseClick={() => {
                        setSelected(null)
                      }}
                    >
                      <div>
                        <h6>Solicitação</h6>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              )}

              {true && (
                <>
                  {/* {listMarkerPosition?.map((item) => (
                    <>
                      <Marker
                        // position={{ lat: item.coordenadas.lat, lng: (item.coordenadas.lng + 0.005) }}
                        position={item.coordenadas}
                        icon={iconMarkerBatch}
                        onClick={handleMarkerClick}
                        label={{
                          text: item.num_animals.toString(),
                          fontSize: '12px',
                          fillOpacity: '0.7',
                          className: 'custom-marker-label',
                        }}
                      />
                    </>
                  ))} */}

                  {/* {markerPosition && (
                <Marker
                  position={markerPosition}
                  icon={iconMarkerBatch}
                  label={{
                    text: numberAnimalsBatch,
                    fontSize: "12px",
                    className: "custom-marker-label"
                  }}
                />

              )} */}
                  {plgn_areas.map((item) => (
                    <>
                      <React.Fragment key={item.id}>
                        <Polygon
                          //a propriedade paths do objeto Polygon especifica uma matriz
                          options={optionsDraw}
                          path={item.coordenadas}
                          onLoad={onLoadPoly}
                          onClick={(e) => handlePolygonClick(e, item)}
                          // onClick={(e) =>
                          //   setMarker({
                          //     lat: e.latLng.lat(),
                          //     lng: e.latLng.lng(),
                          //     time: new Date(),
                          //   })
                          // }
                        />
                      </React.Fragment>
                      <OverlayView
                        mapPaneName={'overlayMouseTarget'}
                        position={calcularCentroNaExtremidadeInferior(item.coordenadas)}
                        // position={getPolygonCenterbarycenter(item.coordenadas)}
                      >
                        <IconButton>
                          <Card sx={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}>
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                              <Avatar variant="rounded" src="/dist/image/icon_verde_location.png" sx={{ width: 21, height: 28, position: 'absolute', left: '0px' }} />
                              <Stack spacing={0.5}>
                                <Typography sx={{ fontSize: 10, color: 'white', ml: '8px' }} fontWeight={500}>
                                  {item.name}
                                </Typography>
                                {/* <Collapse in={expanded}>
                              <Divider />
                              <Typography sx={{ fontSize: 10, color: 'white' }} color="text.secondary">
                                <MapIcon sx={{ color: grey[800], fontSize: 12, color: 'white' }} /> {item.areaUse}/{item.areatotal} ha
                              </Typography>
                            </Collapse> */}
                              </Stack>
                            </Box>
                          </Card>
                        </IconButton>
                      </OverlayView>
                    </>
                  ))}
                </>
              )}
            </GoogleMap>
          </LoadScriptOnlyIfNeeded>
        </Box>
      )}
    </>
  )
}

export default MapViewMonitoring

function calcularCentroide(poligono) {
  let x = 0
  let y = 0
  let ponto
  let fator

  for (let i = 0, j = poligono.length - 1; i < poligono.length; j = i++) {
    ponto = poligono[i]
    let pontoAnt = poligono[j]
    fator = ponto.lat * pontoAnt.lng - pontoAnt.lat * ponto.lng
    x += (ponto.lat + pontoAnt.lat) * fator
    y += (ponto.lng + pontoAnt.lng) * fator
  }

  fator = 1 / (6 * areaPoligono(poligono))
  x *= fator
  y *= fator
  return { lat: x, lng: y }
}

function areaPoligono(poligono) {
  let total = 0
  for (let i = 0, j = poligono.length - 1; i < poligono.length; j = i++) {
    let ponto = poligono[i]
    let pontoAnt = poligono[j]
    total += ponto.lat * pontoAnt.lng
    total -= pontoAnt.lat * ponto.lng
  }
  return Math.abs(total) / 2
}
