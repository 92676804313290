import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container, Typography, Stack, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardMedia } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { LocationOn } from '@mui/icons-material';
//https://fertili.com.br/ofertas/vacas-nelore-2
import html2canvas from 'html2canvas';

export default function ModalLandpageContent({ page, setPage }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getBase64Image = () => {
    // Verifique se a propriedade 'preview' contém a string base64

    if (page.image) {
      // const imageFile = selectedImage,
      // console.log('imageFile')
      // console.log(imageFile)
      return page.image;
    }
    return ''; // Retorna uma string vazia caso não haja base64 disponível
  };

  const sendMessageToWhatsApp = () => {
    const { number, newMessageWhatsapp } = page;
    const url = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(newMessageWhatsapp)}`;
    window.open(url, '_blank');
  };

  const captureContentAsImage = () => {
    const contentElement = document.getElementById('content-to-capture');

    html2canvas(contentElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'nome-da-imagem.png';
      link.href = imgData;
      link.click();
    });
  };

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        {/* Tabs */}
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Modelo Tabs">
          <Tab label="Modelo 1" />
          <Tab label="Modelo 2" />
        </Tabs>

        {/* Conteúdo do Modelo 1 */}
        {selectedTab === 0 && (
          <>
            <Grid container alignItems="center" justifyContent="flex-end" sx={{ padding: '5px', mt: 0 }}>
              <Button color="success" onClick={captureContentAsImage}>
                Download da imagem
              </Button>
            </Grid>
            <Container id="content-to-capture" maxWidth="md">
              {/* Header */}
              <br />
              <br />
              <Box alignItems="center" justifyContent="center" sx={{ background: page?.backgroundPageColor, paddingRight: 1 }}>
                {/* <Box alignItems="center" justifyContent="center" border="1px dashed gray"></Box> */}
                {/* Restante do código do componente */}

                <Grid container alignItems="center" sx={{ background: page?.backgroundColorHeader, padding: '10px' }}>
                  <Grid item xs={12} sm={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <MapsHomeWorkIcon sx={{ color: page.textColorHeader, fontSize: '32px' }} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{ color: page.textColorHeader }} variant="h4" fontWeight={700}>
                          {page.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LocationOn sx={{ color: page.textColorHeader, fontSize: '28px' }} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{ color: page.textColorHeader }} variant="body1">
                          {page.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Restante do código do componente */}

                {/* Main Content */}
                <Grid container spacing={3} sx={{ marginTop: 2, ml: 0 }}>
                  {/* Left Content */}

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // minHeight="calc(100vh - 64px)" // Subtrai a altura da barra de navegação (64px)
                  >
                    {page.image == '' ? (
                      <img src="/dist/img_fp/rebanho-nelore.png" alt="Animal" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                    ) : (
                      <img src={page.image} alt="Animal" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                      // <img src={getBase64Image()} alt="Animal" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                    )}
                  </Box>

                  {/* Right Content */}
                  <Grid item xs={6} md={6} sx={{ marginTop: 1, background: page.backgroundColorMainCreation, paddingBottom: '0px' }}>
                    <Typography sx={{ color: page.textColorMainCreation }} variant="h5">
                      {page.titleCreation}{' '}
                    </Typography>
                    <Typography sx={{ mt: 2, color: page.textColorMainCreation }} variant="body1">
                      {page.descriptionCreation}
                    </Typography>
                    <Typography sx={{ color: page.textColorMainCreation }} variant="body1">
                      Valor: R$ {page.amountCreation}
                    </Typography>
                    {page.showButton && (
                      <Button onClick={sendMessageToWhatsApp} variant="contained" color="success" sx={{ bgcolor: page.buttonColorMainCreation, color: 'white', mt: 2 }}>
                        {page.buttonCreation}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} sx={{ marginTop: 1, background: page.backgroundColorMainCreation, paddingBottom: '0px' }}>
                    <Typography variant="body2" sx={{ mt: 2, color: page.textColorMainCreation }}>
                      {page.paymentTextCreation}
                    </Typography>
                    <Button disabled variant="outlined" sx={{ mt: 1, color: page.textColorMainCreation }}>
                      {page.paymentCreation}
                    </Button>
                  </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} sx={{ background: page.backgroundColorTableDesciptionCreation }}>
                          <Typography variant="h6">CARACTERÍSTICAS</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ background: page.bodyBackgroundColorTableDesciptionCreation }}>
                      {Array.isArray(page.characteristics) &&
                        page.characteristics.map((characteristic, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography sx={{ color: page.textColorTableDescriptionCreation }} variant="body1">
                                {characteristic.title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ color: page.textColorTableDescriptionCreation }} variant="body2">
                                {characteristic.description}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container alignItems="center" sx={{ padding: '5px', mt: 10 }}>
                  <Typography variant="body2">Todos os direitos reservados.</Typography>
                </Grid>
              </Box>
            </Container>
          </>
        )}

        {/* Conteúdo do Modelo 2 */}
        {selectedTab === 1 && (
          <>
            <Grid container alignItems="center" justifyContent="flex-end" sx={{ padding: '5px', mt: 2 }}>
              <Button color="success" onClick={captureContentAsImage}>
                Download da imagem
              </Button>
            </Grid>
            <Container id="content-to-capture" maxWidth="md" sx={{ mt: 4 }}>
              {/* Header */}
              <Box alignItems="center" justifyContent="center" sx={{ background: page.backgroundPageColor }}>
                <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: '10px', mb: 2 }}>
                  <Typography variant="h4" fontWeight={700}>
                    {page.title}
                  </Typography>
                </Grid>
                <Typography variant="body1">{page.address}</Typography>
              </Box>
              {/* Main Content */}
              <Grid container spacing={3} sx={{ marginTop: 2 }}>
                {/* Left Content */}
                <Grid item xs={12} md={6}>
                  {page.image == '' ? (
                    <img src="/dist/img_fp/rebanho-nelore.png" alt="Animal" style={{ width: '100%', height: 'auto' }} />
                  ) : (
                    <img src={getBase64Image()} alt="Animal" style={{ width: '100%', height: 'auto', maxWidth: 1080, maxHeight: 1080 }} />
                  )}
                </Grid>
                {/* Right Content */}
                <Grid item xs={12} md={6} sx={{ marginTop: 1, background: page.backgroundColorMainCreation, padding: '10px' }}>
                  <Typography variant="h5">{page.titleCreation} </Typography>
                  <Typography variant="body1">{page.descriptionCreation}</Typography>
                  <Typography variant="body1">Valor: R$ {page.amountCreation}</Typography>
                  {page.showButton && (
                    <Button onClick={sendMessageToWhatsApp} variant="contained" color="success" sx={{ bgcolor: page.buttonColorMainCreation, color: 'white', mt: 2 }}>
                      {page.buttonCreation}
                    </Button>
                  )}
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {page.paymentTextCreation}
                  </Typography>
                  <Button disabled variant="outlined" sx={{ mt: 1 }}>
                    {page.paymentCreation}
                  </Button>
                </Grid>
              </Grid>
              {/* Characteristics Table */}
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="h6">CARACTERÍSTICAS</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {page.characteristics.map((characteristic, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body1">{characteristic.title}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{characteristic.description}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Footer */}
              <Grid container alignItems="center" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
                <Typography variant="body2">Todos os direitos reservados.</Typography>
              </Grid>
            </Container>
          </>
        )}
      </Container>
    </>
  );
}
