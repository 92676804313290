import React, { useState } from 'react'
import { Container } from '@mui/material'

import ModalLandpageContent from '../../activities/components/FormActivities/Marketing/ModalLandpageContent'

export default function Landpage() {
  const [page, setPage] = useState({
    title: 'FAZENDA CPT',
    address: ' Rodovia Br 120, Km 6, Zona rural s/n, São Geraldo - MG, 36530-000',
    image: '',
    city: '',
    state: '',
    backgroundColorHeader: '',
    textColorHeader: '',
    //mainCreation
    titleCreation: 'Vacas Nelore',
    descriptionCreation: 'Animal de ótima genética, acompanhada de cria e novilhas.',
    amountCreation: '40.000,00 /lote (20 ani.)',
    buttonCreation: 'Negociar',
    paymentTextCreation: 'Formas de Pagamento Aceitas por Esse Vendedor',
    paymentCreation: 'pix, dinheiro e transferência bancária',
    textColorMainCreation: '',
    backgroundColorMainCreation: '',
    buttonColorMainCreation: '#ffa500',
    // Description
    titleTable: 'CARACTERÍSTICAS',
    characteristics: [
      {
        title: 'PUREZA DE SANGUE',
        description: 'Mestiço, 1ª Raça: Nelore[50%], 2ª Raça: Mocho-Nacional[50%]',
      },
      {
        title: 'APTIDÃO',
        description: 'Corte',
      },
      {
        title: 'PESO MÉDIO',
        description: '13 Arrobas',
      },
    ],
    textColorTableDescriptionCreation: '',
    backgroundColorTableDesciptionCreation: '',
    // cor de fundo da landpage
    backgroundPageColor: '#ffffff',

    isDeleted: 0,
  })

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <ModalLandpageContent page={page} />
      </Container>
    </>
  )
}
