import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Grid, Stack, Button, Modal, Avatar } from '@mui/material';
import TableManintenanceEspecific from '../TableManintenanceEspecific';
import CardContent from '@mui/material/CardContent';
import CardPatrimonyTabs from './CardPatrimonyTabs';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import EditPatrimony from '../EditPatrimony';
import { getIconComponent } from './getIconComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
// import usePatrimonyReposStore from '../../../store/usePatrimonyReposStore';
import { Context } from '../../../components/Context/AuthContext';
import { formatToBRL } from '../../../components/Fields/formatToBRL';
// import usePatrimonyMaintenanceReposStore from '../../../store/usePatrimonyMaintenanceReposStore';
import { useState } from 'react';
import useStore from '../../../store/useStore';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export default function CardPatrimonyType({ patrimonyData, setPatrimonyData }) {
  // console.log('CardPatrimonyType');
  const usestore = useStore();
  // console.log(patrimonyData);

  // const reposPatrimonyMaintenance = usePatrimonyMaintenanceReposStore((state) => state);
  // const reposListMaintenance = reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id', patrimonyData.id);
  // console.log(reposListMaintenance);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // const reposPatrimony = usePatrimonyReposStore((state) => state);
  var id_farm = localStorage.getItem('Property') || 1;

  const { aux_add, setAuxAdd } = React.useContext(Context);

  const handleDeletePatrimony = async (id) => {
    // console.log(id);
    await api.delete(`/patrimony/list/${id}`)
      .then((res) => {
        toast.success('Patrimônio deletado com sucesso!');
        usestore.reposPatrimony.fetch({ id_farm: id_farm }).then((res) => {
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            // handleClose();
            setPatrimonyData([]);
          }, 200);
        });
      })
      .catch((err) => {
        console.error('Erro ao deletar patrimônio:', err);
        toast.error('Erro ao deletar patrimônio.');
      });
  };

  const handleEditPatrimony = async (data) => {
    handleEditDialogOpen();
    // console.log('DATA TO EDIT:');
    // console.table(data);
  };
  const height = '50px';
  const width = '50px';

  const icon = getIconComponent(patrimonyData.patrimony_group_icon, height, width);
  //style={{ height: "50px", width: "50px" }}

  const [valuetotalMaintenace, setValuetotalMaintenace] = useState(0);
  const [valuetotalMaintenaceMonth, setValuetotalMaintenaceMonth] = useState(0);

  React.useEffect(() => {
    if (patrimonyData && patrimonyData?.id) {
      let aux_data = usestore.reposPatrimony.getPatrimonyStore('id', patrimonyData?.id)[0];
      setPatrimonyData(aux_data); // Atualize o estado com os dados do patrimônio selecionado
    }
  }, [aux_add]);

  const [tabActive, setTab] = useState(0);
  const obj_fn = {
    patrimonyData: patrimonyData,
    usestore: usestore,
    tabActive: tabActive,
    setValuetotalMaintenace,
    setValuetotalMaintenaceMonth
  };
  return (
    <>
      <Card>
        <CardHeader
          avatar={
            patrimonyData.image ? (
              <Avatar
                style={{
                  height: '50px',
                  width: '50px',
                }}
                aria-label="Imagem do patrimônio"
                src={`data:image/jpeg;base64,${patrimonyData.image}`}
              ></Avatar>
            ) : (
              icon
            )
          }
          action={
            <>
              <IconButton aria-label="settings" onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleEditPatrimony(patrimonyData);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} />
                  Editar
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    // handleDeletePatrimony(data.id);
                    setIsModalOpen(true);
                  }}
                >
                  <DeleteForeverIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} />
                  Excluir
                </MenuItem>
              </Menu>
            </>
          }
          title={
            <>
              <Typography sx={{ textTransform: 'uppercase' }}>{patrimonyData.name}</Typography>
            </>
          }
          subheader={
            <>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'uppercase' }}>
                      {patrimonyData.model}
                    </Typography>
                    {patrimonyData.group === 'Maquinário' && (
                      <Typography variant="body2" color="text.secondary">
                        {patrimonyData.horimetro_current} horas trabalhadas
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography variant="body2" color="text.secondary">
                      Valor da aquisição
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatToBRL(patrimonyData.value)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          }
        />
        <CardContent>
          <CardPatrimonyTabs setTab={setTab} valuetotalMaintenace={valuetotalMaintenace} valuetotalMaintenaceMonth={valuetotalMaintenaceMonth} />
        </CardContent>
      </Card>
      <Box sx={{ mt: 5 }}>
        {/* <TableManintenanceEspecific maintenance={reposListMaintenance} setValuetotalMaintenace={setValuetotalMaintenace} setValuetotalMaintenaceMonth={setValuetotalMaintenaceMonth} /> */}
        <TableManintenanceEspecific obj_fn={obj_fn} />
      </Box>

      <EditPatrimony patrimonyData={patrimonyData} open={editDialogOpen} handleClose={handleEditDialogClose} />

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="div">
            Tem certeza que deseja excluir este patrimônio?
          </Typography>
          <Button
            onClick={() => {
              handleDeletePatrimony(patrimonyData.id);
              setIsModalOpen(false);
            }}
            color="error"
            sx={{ mt: 2 }}
          >
            Confirmar
          </Button>
          <Button color="inherit" onClick={() => setIsModalOpen(false)} sx={{ mt: 2, ml: 2 }}>
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}
