import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkmark } from "react-checkmark";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalChangePassword({ open, setOpen }) {
  // const [open, setOpen] = React.useState(start);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Checkmark size={'196'} />
          <Typography align="center" variant="h6" sx={{ mt: 4 }} >
            Senha atualizada!
          </Typography>
          {/* <p className="mb-1 mt-3  ">
            <Link to="/" className=" text-success ">
              Ir para o Login
            </Link>
          </p> */}
        </Box>
      </Modal>
    </div>
  );
}
