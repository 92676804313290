import React, { useState } from 'react'
import { Box, Typography, TextField, InputAdornment, List, ListItem, ListItemButton, ListItemAvatar, ListItemText, ListSubheader, IconButton, Avatar, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStore from '../../store/useStore'

export default function ListStock({ productList, setProductList }) {
  //const [productList, setProductList] = useState([])
  const [visibleGroups, setVisibleGroups] = useState({})
  const [searchTerm, setSearchTerm] = useState('')

  // Suponha que useStore seja seu hook personalizado para acessar o estado global
  // const { aux_add } = useContext(Context)
  const usestore = useStore()
  const reposProduct = usestore.reposProducts.listProducts

  // useEffect(() => {
  //   setProductList(reposProduct)
  // }, [aux_add, reposProduct])

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    // // Define todos os grupos como visíveis quando há texto no campo de pesquisa
    // setVisibleGroups((prevState) => {
    //   const newVisibleGroups = {}
    //   Object.keys(prevState).forEach((groupName) => {
    //     newVisibleGroups[groupName] = true
    //   })
    //   return newVisibleGroups
    // })
  }

  const toggleGroupVisibility = (groupName) => {
    //console.log(groupName)
    setVisibleGroups((prevState) => ({
      ...prevState,
      [groupName]: !prevState[groupName],
    }))
  }

  const updateSelectedProduct = (data) => {
    //console.log(productList)
    const newData = []
    newData.push(data)
    //console.log(data)
    //console.log(newData)
    setProductList(newData)
  }

  //console.log(productList)
  const filteredAndGroupedProducts = Object.entries(
    productList.reduce((acc, product) => {
      if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        const groupName = product.category_name // Corrigido para usar a propriedade correta
        if (!acc[groupName]) {
          acc[groupName] = []
        }
        acc[groupName].push(product)
      }
      return acc
    }, {})
  )

  // Função para restaurar a lista original de produtos
  const restoreProductList = () => {
    //console.log('teste')
    setProductList(reposProduct)
  }

  // const handleChangeOpen = (e, event) => {
  //   console.log(event)
  // }

  //console.log(filteredAndGroupedProducts)
  return (
    <>
      <TextField
        sx={{ width: '100%', maxWidth: '100%' }}
        id="filled-search"
        label="Pesquisar Produto"
        type="search"
        variant="standard"
        color="primary"
        value={searchTerm}
        onChange={handleSearchChange}
        // onBlur={(e) => handleChangeOpen(e, true)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button color="success" onClick={restoreProductList}>
        Restaurar Lista
      </Button>
      <List
        sx={{
          mt: 2,
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 450,
          '& ul': { padding: 0 },
        }}
      >
        {filteredAndGroupedProducts.map(([groupName, items]) => (
          <li key={`section-${groupName}`}>
            <ul>
              <ListSubheader
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2" sx={{ mt: 2 }}>
                  <strong>{groupName}</strong>
                </Typography>
                <IconButton onClick={() => toggleGroupVisibility(groupName)}>{visibleGroups[groupName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </ListSubheader>
              {visibleGroups[groupName] &&
                items.map((item) => (
                  <ListItem key={item.id} sx={{ ml: -2 }}>
                    <ListItemButton onClick={() => updateSelectedProduct(item)}>
                      <ListItemAvatar>
                        {item.image ? (
                          <Avatar aria-label="image" src={item.image} />
                        ) : (
                          // Placeholder se não houver imagem
                          <Avatar>{item.name.charAt(0)}</Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={item.name} secondary={item.description} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </ul>
          </li>
        ))}
        {filteredAndGroupedProducts.length === 0 && (
          <Box sx={{ ml: 2, textAlign: 'left' }}>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Nenhum produto encontrado.</strong>
            </Typography>
          </Box>
        )}
      </List>
    </>
  )
}
