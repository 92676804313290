import { Avatar, Grid } from '@mui/material';
import React from 'react';
import AvatarFp from '../../components/Avatar/AvatarFp';
import SimpleTable from '../../components/Tables/SimpleTable';
import { CardContent, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { green, red } from '@mui/material/colors';
import { Box } from '@mui/system';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IEProgressBars from './components/IEProgressBars';
import LogoNameWhite from '../../assets/png/logo-name-white.png';
import { useState } from 'react';
import CardIR from './components/CardIR';
import useStore from '../../store/useStore';
import { useEffect } from 'react';
import propertySettingsProgress from './components/propertySettingsProgress';
import propertyProgress from './components/propertyProgress';
import livestockProgress from './components/livestockProgress';
import harvestProgress from './components/harvestProgress';
import CardIRHarvest from './components/CardIRHarvest';
import CardIRProperty from './components/CardIRProperty';
import CardIRLivestock from './components/CardIRLivestock';

export default function IndexExperienceContent() {
  const usestore = useStore();
  const [bar, setBar] = useState(50);

  const property = usestore.reposProperty.listProperty;
  const livestock = usestore.reposLivestock.listLivestock;
  const harvest = usestore.reposHarvest.listHarvest;
  const area_percentage = usestore.reposAreas.listAreasMap.length;
  const products_percentage = usestore.reposProducts.listProducts.length;
  const patrimony_percentage = usestore.reposPatrimony.getPatrimonyStore('id', usestore.reposPatrimony.patrimonyRepos.id).length;

  const calcularPorcentagem = (num, num_max, num_min) => {
    if (num_max <= num_min) {
      throw new Error('O valor máximo deve ser maior que o valor mínimo.');
    }
    const porcentagem = (num / num_max) * 100;
    const porcentagemMin = (num_min / num_max) * 100;

    return {
      num,
      porcentagem,
      porcentagemMin,
    };
  };

  const initialData = [
    { module: 'settings', num: propertySettingsProgress(property).progress, numMin: 0, numMax: 100 },
    { module: 'property', num: propertyProgress(property).progress, numMin: 0, numMax: 100 },
    { module: 'livestock', num: livestockProgress(livestock).progress, numMin: 0, numMax: 100 },
    { module: 'harvest', num: harvestProgress(harvest).progress, numMin: 0, numMax: 100 },
    { module: 'area', num: area_percentage, numMin: 0, numMax: 2 },
    { module: 'product', num: products_percentage, numMin: 6, numMax: 48 },
    { module: 'patrimony', num: patrimony_percentage, numMin: 6, numMax: 48 },
  ];

  const data = initialData.map((item) => {
    const percentageData = calcularPorcentagem(item.num, item.numMax, item.numMin);
    return {
      module: item.module,
      num: percentageData.num,
      porcentagem: Math.round(percentageData.porcentagem),
      porcentagemMin: Math.round(percentageData.porcentagemMin),
    };
  });

  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(data);
  }, []);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom variant="h5" component="div">
                Índice de Experiência
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="container-fluid">
        <CardIR bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRHarvest bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRLivestock bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRProperty bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
    </div>
  );
}
