import React from "react";
import { useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
//import microPhoneIcon from "../../assets/microfne.png";
import MicIcon from '@mui/icons-material/Mic';
import { Alert, Box, Button, Divider, Grid, TextField, Toolbar, Typography, Zoom } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import NotesIcon from '@mui/icons-material/Notes';
import RoomIcon from "@mui/icons-material/Room";
import MapFormObservationDialog from "../../DialogMessenger/forms/MapFormObservationDialog";
import CardChatModel from "./CardChatModel";
import Loading from "../../../../components/Loader/Loading";

const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export default function SpeechRecognitionText({ message, setMessage, onSubmit, handleClose }) {

    const [value, setValue] = useState("");

    const [isLoading, setIsLoading] = useState(false)

    const commands = [
        {
            command: 'lisa limpar tela',
            callback: ({ resetTranscript }) => resetTranscript(),
        },
        {
            command: 'lisa pesquisar sobre * no google',
            callback: (site) => {
                window.open(`https://www.google.com/search?q=${site}`)
            },
        },
    ]

    //transcript retornar a transcrição da fala
    const { transcript, resetTranscript } = useSpeechRecognition({ commands });
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef(null);

    // const {
    //     transcript,
    //     resetTranscript,
    //     browserSupportsSpeechRecognition
    // } = useSpeechRecognition({ commands })

    console.log('teste navegador')
    console.log(!SpeechRecognition.browserSupportsSpeechRecognition())
    // console.log(isMobile)
    //console.log('isMobile')
    console.log(isMobile.iOS())
    //if (isMobile.iOS()) alert('iOS');

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return (
            <div className="">
                Seu navegador não é compativel com SpeechRecognition.
            </div>
        );
    }

    const handleListing = () => {
        setIsListening(true);
        microphoneRef.current.classList.add("Gravando");
        SpeechRecognition.startListening({
            continuous: true,
        });
    };

    const stopHandle = () => {
        setIsListening(false);
        microphoneRef.current.classList.remove("Gravando");
        SpeechRecognition.stopListening();
    };



    const handleReset = () => {
        stopHandle();
        resetTranscript();
        setTimeout(() => {
            resetTranscript();
        }, 1000);

    };

    const reset = () => {
        stopHandle();
        resetTranscript();
        setIsLoading(true)
        onSubmit()


    };

    //form:]

    const handleChangeInput = (event) => {
        console.log()
        event.persist();
        setMessage({ ...message, [event.target.name]: event.target.value });
    };

    return (
        <>
            {isLoading ? <Loading /> :
                <div className=" mt-4">
                    {!SpeechRecognition.browserSupportsSpeechRecognition() && <Alert severity="error">Desculpe! O seu navegador não é compativel com o sistema de voz.</Alert>}
                    {isMobile.iOS() && <Alert severity="error">Desculpe! O sistema operacional IOS do iPhone não é compativel com o sistema de voz.</Alert>}
                    <form className="container-fluid " style={{ maxWidth: 550, marginBottom: 20 }}></form>
                    <form className="container-fluid " style={{ maxWidth: 550, marginBottom: 20 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={8} >
                                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 1 }}>
                                    <TitleIcon sx={{ color: "action.active", mr: 1, my: 3.5 }} />
                                    <TextField
                                        //autoFocus
                                        margin="dense"
                                        onChange={handleChangeInput}
                                        value={message.title = transcript}
                                        //inputValue={inputValue}
                                        fullWidth
                                        id="title"
                                        name="title"
                                        size="small"
                                        color="success"
                                        inputProps={{ style: { fontSize: 20, color: "#207835" } }}
                                        InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
                                        label="Título da Solicitação*"
                                        variant="standard"
                                        helperText="Clique no icone do microfone para iniciar a gravação!"
                                    />
                                </Box>
                                <Box >
                                    {isListening &&
                                        <>
                                            <Zoom
                                                in={true} style={{ transitionDelay: '500ms' }}
                                            >
                                                <Button sx={{ textTransform: "capitalize" }} size="small" variant="" onClick={stopHandle}><StopCircleIcon sx={{ mr: 1 }} />Parar</Button>
                                            </Zoom>
                                        </>

                                    }
                                    {transcript &&
                                        <>
                                            <Zoom
                                                in={true} style={{ transitionDelay: '2000ms' }}
                                            >
                                                <Button sx={{ textTransform: "capitalize" }} size="small" variant="" onClick={handleReset}><FormatClearIcon sx={{ mr: 1 }} />Limpar</Button>
                                            </Zoom>
                                        </>

                                    }
                                </Box>
                            </Grid>

                            <Grid item xs={3} sx={{ mt: 1, mb: 4 }}>
                                <div className="mt-3"></div>
                                {/* <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }}>
                                    <div ref={microphoneRef}
                                        onClick={handleListing}
                                        className="d-flex align-items-center flex-column text-dark" >
                                        <div className="justify-content-center " >
                                            <MicIcon style={{ fontSize: 40 }} />
                                            <div className=""> {isListening ? <><span style={{ fontSize: 12, color: "red" }}>Gravando</span></> : <><span style={{ fontSize: 12, color: "#000" }}>Iniciar</span></>}</div>
                                        </div>
                                    </div>
                                </Button> */}
                                {isListening ?
                                    <Button variant="outlined" style={{ color: 'red', borderColor: 'red' }}>
                                        <div ref={microphoneRef}
                                            onClick={handleListing}
                                            className="d-flex align-items-center flex-column text-dark" >
                                            <div className="justify-content-center" style={{ marginLeft: 1 }} >
                                                <MicIcon style={{ fontSize: 40, color: 'red' }} />
                                                <div className=""> <><span style={{ fontSize: 12, color: "red" }}>Gravando</span></></div>
                                            </div>
                                        </div>
                                    </Button>
                                    :
                                    <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }}>
                                        <div ref={microphoneRef}
                                            onClick={handleListing}
                                            className="d-flex align-items-center flex-column text-dark" >
                                            <div className="justify-content-center " style={{ marginLeft: 12, marginRight: 12 }}>
                                                <MicIcon style={{ fontSize: 40 }} />
                                                <div className=""> <><span style={{ fontSize: 12, color: "#000" }}>Iniciar</span></></div>
                                            </div>
                                        </div>
                                    </Button>

                                }

                            </Grid>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                item xs={11} style={{ marginTop: 5 }}>
                                <CardChatModel title={transcript} />
                            </Grid>

                        </Grid>


                        <Toolbar sx={{ mt: 5 }}>
                            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                                {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button> */}
                            </Box>

                            <Box sx={{ flexGrow: 1, textAlign: "end" }}>
                                <Button color="success" variant="text" onClick={reset} >
                                    <SendIcon />
                                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Enviar</Span>
                                </Button>
                            </Box>
                            <i className="image ml-3">
                                <img className="img-rounded elevation-1" src="/dist/image/ia.png" width={50} />
                            </i>
                        </Toolbar>

                        {/* <Grid container spacing={2} style={{ marginBottom: 40 }}>
                    <Grid item xs={10}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                margin: "auto",
                            }}
                        >
                            <NotesIcon sx={{ color: "action.active", mr: 1, my: 0 }} />
                            <TextField
                                onChange={handleChangeInput}
                                value={message.description}
                                sx={{ maxWidth: 600 }}
                                id="description"
                                name="description"
                                label="Descrição"
                                color="success"
                                multiline
                                // InputProps={{ style: { fontSize: 40 } }}
                                InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
                                rows={3}
                                variant="outlined"
                            />
                        </Box>
                        <Box >
                            {isListening &&
                                <>
                                    <Zoom
                                        in={true} style={{ transitionDelay: '500ms' }}
                                    >
                                        <Button sx={{ textTransform: "capitalize" }} size="small" variant="" onClick={stopHandle}><StopCircleIcon sx={{ mr: 1 }} />Parar</Button>
                                    </Zoom>
                                </>

                            }
                            {transcript &&
                                <>
                                    <Zoom
                                        in={true} style={{ transitionDelay: '2000ms' }}
                                    >
                                        <Button sx={{ textTransform: "capitalize" }} size="small" variant="" onClick={handleReset}><FormatClearIcon sx={{ mr: 1 }} />Limpar</Button>
                                    </Zoom>
                                </>

                            }
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box >
                            <div
                                className="mt-4"
                                ref={microphoneRef}
                                onClick={handleListing}
                            >
                                <MicIcon style={{ fontSize: 40 }} /> {isListening ? <><strong style={{ color: "red" }}>Gravando</strong></> : <><strong >Iniciar</strong></>}
                            </div>

                        </Box>
                    </Grid>
                </Grid> */}

                    </form>
                </div >
            }
        </>

    )
}

// <div className="mircophone-container">
//                 <div
//                     className="microphone-icon-container"
//                     ref={microphoneRef}
//                     onClick={handleListing}
//                 >
//                     <MicIcon style={{ fontSize: 60 }} />
//                 </div>
//                 <div className="microphone-status">
//                     {/* {isListening ? "Listening........." : "Click to start Listening"} */}
//                     {isListening ? "Gravando........." : "Clique para iniciar"}
//                 </div>
//                 {isListening && (
//                     <button className="microphone-stop btn" onClick={stopHandle}>
//                         Parar
//                     </button>
//                 )}
//             </div>