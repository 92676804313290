import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CommentIcon from '@mui/icons-material/Comment'
import IconButton from '@mui/material/IconButton'
import DirectionsIcon from '@mui/icons-material/Directions'
import { Avatar, Box, ButtonBase, ListItemAvatar, Paper, Typography, Stack } from '@mui/material'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import PanToolIcon from '@mui/icons-material/PanTool'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import Typewriter from '../../../components/Typewriter/Typewriter'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { expandAndFade } from '../../../components/Animations/animationKeyFrames'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function CreateAreaList() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/areasmaps')
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', mt: -2 }}>
      <Box>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/map_areas.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <IconButton aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </IconButton>
          }
        >
          <ListItemText
            secondary={`As áreas de produção em uma fazenda são espaços designados para diferentes atividades agrícolas ou pecuárias. Ex.: piquete, gleba, talhão, área de plantio, currais, cercados...`}
          />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <IconButton aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </IconButton>
          }
        >
          <ListItemText secondary={`Nas áreas de produção que iremos inserir os animais e as culturas.`} />
        </ListItem>
      </Box>
      <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
        Clique abaixo no botão áreas.
      </Typography>

      <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }} onClick={() => handleButtonClick()} className={classes.button}>
        <Stack direction="column" alignItems="center">
          <img style={{ maxHeight: 50 }} src="/dist/image/entrada.png" alt="image" class="img-fluid" />
          <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
            Áreas
          </Typography>
        </Stack>
      </ButtonBase>
    </List>
  )
}
