import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dialog, Container, DialogContent, IconButton, Stack, Slide, CardMedia, InputAdornment } from '@mui/material';
import { AppBar, Box, Button, Divider, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme } from '@mui/material/styles';
import api from '../../../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { Context } from '../../../../components/Context/AuthContext';
import HeaderModalNew from '../../../../components/Header/HeaderModalNew';
import { IconSoilPreparation } from '../../../../components/Icons/harvest/unit/IconSoilPreparation';
import AutocompleteWithAddOption from '../../../../components/Fields/AutocompleteWithAddOption';
import ChartBarHActvSoul from '../../../../components/Charts/ChartBarHActvSoul';
import ResourcesStepFormMainTabEdit from '../../../activitiesResources/ResourcesEdit/ResourcesStepFormMainTabEdit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme(
  { palette: { primary: { main: '#1976d2' } } },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' };

// fillDialog normal que chama uma lista de botões de atividade
export default function EditActivitiesSoilPreparation({ row, usestore }) {
  console.log('EditActivitiesSoilPreparation');
  const { aux_add, setAuxAdd } = useContext(Context);

  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(0);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // const handleOpenModal = () => {
  //   setOpenDeleteModal(true);
  // };

  // const handleCloseModal = () => {
  //   setOpenDeleteModal(false);
  // };

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  let editResources = '';

  const handleActivity = () => {
    // Obtém a atividade específica
    let this_activity = JSON.parse(JSON.stringify(usestore.reposActivitySoilPreparation.getActivitySoilPreparationStore('id', row.id)[0]));
    console.log('this_activity');
    console.log(this_activity);
    const editResourcesConst = this_activity.resources;
    editResources = editResourcesConst;
    console.log(this_activity?.resources);
    //console.log(this_activity?.resources.products);
    //const arrayProducts = [];
    // Supomos que `this_activity` e `usestore` estejam disponíveis
    // Mapear `products` de acordo com o novo formato solicitado
    this_activity.resources.products = this_activity.resources.products || [];
    // if (!this_activity?.resources?.products) {
    //   console.error('resources ou products está undefined');
    //   return;
    // }
    console.log('item');
    this_activity.resources.products = this_activity.resources?.products.map((item) => {
      console.log('item');
      console.log(item);
      // console.log(JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', item.id_product)[0].location[0])));
      // const loc = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', item.id_product)[0].location[0]));

      //const loc = JSON.parse(JSON.stringify(usestore.reposPatrimony?.getPatrimonyStore('id', item.id_patrimony)[0]));
      const patrimonyData = usestore.reposPatrimony?.getPatrimonyStore('id', item.id_patrimony)?.[0];

      if (!patrimonyData) {
        console.error(`Patrimony data não encontrado para id_patrimony: ${item.id_patrimony}`);
        return null; // Retorna nulo ou um valor padrão caso o dado não seja encontrado
      }

      // Converte os dados para JSON e de volta, garantindo que `patrimonyData` seja válido
      const loc = JSON.parse(JSON.stringify(patrimonyData));
      // Adiciona as novas propriedades ao loc
      loc.quant_utilizada = item.quant_utilizada ? item.quant_utilizada : item.quant * (item.packaging_unit === 'medida' ? item.volume_pkg : 1);
      loc.value = item.value;

      return {
        ...item,
        quant: item.quant_utilizada ? item.quant_utilizada : item.quant * (item.packaging_unit === 'medida' ? item.volume_pkg : 1),
        value: item.value,
        id: item.id_product,
        location_selected: loc,
        // location_selected: {
        //   id: item.id_location,
        //   id_patrimony: item.id_patrimony,
        //   //name: 'Galpão principal',
        //   quanty: item.volume_pkg,
        //   quanty_volume: item.volume_pkg,
        //   // adicionais para ExpensesCostCalculator

        //   quant_utilizada: item.quant,
        //   value: item.value,
        // },

        product: JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', item.id_product)[0])),
      };
    });
    console.log('resultado');
    console.log(this_activity.resources?.products);

    this_activity.resources.expenses = this_activity.resources.expenses || [];

    // Atualizar `resources.expenses` para substituir `id_expense` por `category`
    this_activity.resources.expenses = this_activity.resources.expenses.map((expense) => {
      return {
        ...expense,
        category: expense?.id_expense, // Renomeia `id_expense` para `category`
        subcategory: expense?.id_expense_sub,
        //id_expense: undefined, // Remove o campo `id_expense` se necessário
      };
    });

    // for (const item of this_activity.resources.products) {
    //   console.log('product');
    //   console.log(item);
    //   console.log('---');
    //   let objectProducts = {
    //     id: item.id_product,
    //     //location_selected
    //     product: JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', item.id_product)[0])),
    //   };
    //   arrayProducts.push(objectProducts);
    // }

    // console.log(arrayProducts);
    //const productList = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', 4)[0]));
    //console.log(productList);
    // for (const product of productList.resources.products) {
    //   console.log('product');
    //   console.log(product);
    // }

    // Mapeia as áreas e atualiza com informações adicionais
    this_activity.areas = this_activity.areas.map((area) => {
      const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0];
      return {
        ...area,
        id: optionsAreas.id,
        imageDecode: optionsAreas.imageDecode,
        area: optionsAreas.area,
        measure: optionsAreas.measure,
      };
    });

    this_activity.edit_prod = false;

    return this_activity;
  };

  const handleChangeInput = (e, area, isPercentage) => {
    const value = e.target.value ? parseFloat(e.target.value) : '';

    setData((prevState) => ({
      ...prevState,
      info_areas: prevState.info_areas.map((area_prev) => {
        if (area_prev.id_area === area.id_area) {
          let calculatedValue;
          if (isPercentage) {
            // Converte a porcentagem para hectares
            calculatedValue = (value / 100) * area_prev.area;
            if (calculatedValue > area_prev.area) {
              calculatedValue = area_prev.area; // Limita ao valor máximo de 100%
            }
          } else {
            // Trabalha diretamente em hectares
            calculatedValue = value > area_prev.area ? area_prev.area : value; // Limita à área total
          }
          const formattedValue = parseFloat(calculatedValue.toFixed(2));

          return {
            ...area_prev,
            usedArea: formattedValue,
          };
        }
        return area_prev;
      }),
    }));

    setUpdated((prev) => prev + 1);
  };

  // const [data, setData] = useState(handleActivity());
  let this_activity = handleActivity();
  const [data, setData] = useState(this_activity);

  // console.log('data1');
  // console.log(data);

  const handleClickOpen = () => {
    setUpdated(0);
    setOpen(true);
  };

  const handleClose = () => {
    //reseta informações
    let this_activity = handleActivity();
    setData(this_activity);
    setUpdated(0);
    setOpen(false);
  };

  console.log(data);

  console.log(data.resources, editResources);
  //console.log(areResourcesDig(data, editResources));

  // const para validar o button continuar
  let arraysResourcesDif = true;

  const isError = () => {
    console.log('isError');
    console.log('(------)');
    console.log(updated, value?.$D, valueEnd?.$D);

    for (const [index, expense] of data.resources.expenses.entries()) {
      console.log('------------------------');
      console.log(expense);
      console.log(editResources.expenses);
      if (
        expense?.value != editResources.expenses[index]?.value ||
        expense?.description != editResources.expenses[index]?.description ||
        expense?.category != editResources.expenses[index]?.category
      ) {
        console.log('entrou');
        arraysResourcesDif = true;
        console.log(arraysResourcesDif);
        return false;
      }
      console.log(expense.length != editResources.expenses.length);
      // if (expense.length != editResources.expenses.length) {
      //   console.log('entrou2');
      //   arraysResourcesDif = true;
      //   return true;
      // }
    }

    // falta validar aqui
    // for (const [index, product] of data.resources.products.entries()) {
    //   console.log(product);
    //   if (product.id != editResources.products[index].id || product.quant != editResources.products[index].quant) {
    //     arraysResourcesDif = true;
    //     return false;
    //   }
    // }

    console.log(updated, value.$D, valueEnd.$D, arraysResourcesDif);
    console.log(!updated || !value.$D || !valueEnd.$D);
    // se for false , valida o botão concluir
    return !updated || !value.$D || !valueEnd.$D;
    //return false;
  };
  console.log(arraysResourcesDif);

  const onSubmit = async (e) => {
    e.preventDefault();
    let formOk = !isError();

    console.log(data);
    data.arraysResourcesDif = arraysResourcesDif;

    // if (data.selectedId) {
    //   data.id_monitoring = data.selectedId
    // }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      data.id_user_current = userCurrent.id;
      data.change_data = true;

      const res = await api
        .put('/activitySoilPreparation/update', data, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('Atividade editada com sucesso!');
            // atualiza o repos
            // toast.success('Aplicação registrado com sucesso!');
            usestore.reposProducts.fetchLocationStock({ id_property: property_id }).then((res) => {
              // console.log('atualizou o LocationStock');
            });
            usestore.reposPatrimony.fetch({ id_property: property_id }).then((res) => {
              // console.log('atualizou o reposPatrimony');
            });
            usestore.reposFinances.fetchT({ id_property: property_id }).then((res) => {
              // console.log('atualizou o reposFinances');
            });

            usestore.reposActivitySoilPreparation.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao editar atividade. Tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios');
    }
  };

  // validations first step start -----
  const [value, setValue] = useState(dayjs(data.date_start));
  const [valueEnd, setValueEnd] = useState(dayjs(data.date_prev_end));

  const optionsIcon = {
    title: 'Preparação do solo',
    txt_msn: 'Altere os dados desejados, confira e clique em salvar',
    icon: <IconSoilPreparation style={{ fontSize: 24 }} />,
  };

  const optionsImage = {
    title: 'Progresso',
    txt_msn: 'Altere os dados desejados, confira e clique em salvar',
    icon: <IconSoilPreparation widthStyles={'100'} />,
  };

  const obj_fn = {
    usestore: usestore,
    setData: setData,
    setUpdated: setUpdated,
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  // Adicionar a imagem correspondente a cada item em info_areas
  const updatedInfoAreas = data.info_areas.map((infoArea) => {
    const correspondingOption = optionsAreas.find((option) => option.id === infoArea.id_area);
    if (correspondingOption) {
      return {
        ...infoArea,
        imageDecode: correspondingOption.imageDecode,
      };
    }
    return infoArea;
  });

  data.info_areas = updatedInfoAreas;

  const [operations, setOperations] = useState(JSON.parse(JSON.stringify(usestore.reposActivitySoilPreparation.listOperations)));

  //console.log(data);
  console.log('fim-EditActivitiesSoilPreparation');

  return (
    <>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    {' '}
                    Edição da atividade
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar">
                {' '}
                <Close />{' '}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <DialogContent sx={{ mb: 8, mt: -4 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
              <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
                <Box>
                  <HeaderModalNew optionsIcon={optionsIcon} optionsImage={optionsImage} />

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ mt: 2, height: 110 }}>
                      <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                        <div className="d-lg-none">
                          <Stack spacing={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <MobileDatePicker
                                  label="Data de Início*"
                                  value={dayjs(data.date_start)}
                                  onChange={(newValue) => {
                                    setValue(newValue);
                                    const newValueEnd = dayjs(newValue).add(3, 'day');
                                    setValueEnd(newValueEnd);

                                    setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format('YYYY-MM-DD HH:mm:ss') }));
                                    setUpdated(updated + 1);
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  renderInput={(params) => <TextField {...params} color="success" />}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MobileDatePicker
                                  label="Data de Término*"
                                  value={dayjs(data.date_prev_end)}
                                  onChange={(newValue) => {
                                    setValueEnd(newValue);
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                                    setUpdated(updated + 1);
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  minDate={value}
                                  renderInput={(params) => <TextField {...params} color="success" />}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </div>
                        <div className="d-none d-lg-block">
                          <Stack spacing={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <DesktopDatePicker
                                  label="Data de Início*"
                                  value={dayjs(data.date_start)}
                                  onChange={(newValue) => {
                                    setValue(newValue);
                                    const newValueEnd = dayjs(newValue).add(3, 'day');
                                    setValueEnd(newValueEnd);

                                    setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format('YYYY-MM-DD HH:mm:ss') }));
                                    setUpdated(updated + 1);
                                  }}
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      color="success"
                                      error={!value.$D}
                                      helperText={
                                        !value.$D && (
                                          <p id="date_start_helper" className="form-text text-danger is-invalid">
                                            {' '}
                                            Por favor, insira uma data válida.{' '}
                                          </p>
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <DesktopDatePicker
                                  label="Data de Término*"
                                  value={dayjs(data.date_prev_end)}
                                  onChange={(newValue) => {
                                    setValueEnd(newValue);
                                    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                                    setUpdated(updated + 1);
                                  }}
                                  color="success"
                                  adapterLocale="pt"
                                  inputFormat="DD/MM/YYYY"
                                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                  minDate={value}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      color="success"
                                      error={!valueEnd.$D}
                                      helperText={
                                        !valueEnd.$D && (
                                          <p id="date_prev_end_helper" className="form-text text-danger is-invalid">
                                            {' '}
                                            Por favor, insira uma data válida.{' '}
                                          </p>
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </div>
                        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: '#808080' }}>
                          Data prevista para início da atividade.
                        </Typography>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                    <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                      <AutocompleteWithAddOption obj_fn={obj_fn} operations={operations} setOperations={setOperations} operation={data.operation} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
                    {data.info_areas?.map((area, index) => (
                      <Grid container spacing={3} sx={{ mb: 0, mt: 0 }} key={'G1_' + area.id + '_' + index}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }} key={'G2_' + area.id + '_' + index}>
                          <CardMedia component="img" sx={{ height: 260 }} image={area.imageDecode} alt={area.name} />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }} key={'G3_' + area.id + '_' + index}>
                          <Grid item xs={12} sm={12} sx={{ ml: 1.5 }}>
                            <Typography variant="subtitle1" gutterBottom>
                              Área: <strong>{area.name}</strong>
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              <i className="fa fa-map text-success">
                                {' '}
                                Totalidade: {area.area} ({area.measure})
                              </i>
                            </Typography>
                            <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                              <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                                <TextField
                                  sx={{ marginRight: 2 }}
                                  id={area.id + '-usedArea-hectares'}
                                  name={area.name}
                                  label={'Área utilizada (ha):'}
                                  variant="standard"
                                  size="small"
                                  value={area.usedArea || ''}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">{area.measure}</InputAdornment>,
                                  }}
                                  onChange={(e) => handleChangeInput(e, area, false)}
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                                <TextField
                                  sx={{ marginRight: 2 }}
                                  disabled
                                  id={area.id + '-usedArea-percentage'}
                                  name={area.name}
                                  label={'Área utilizada (%):'}
                                  variant="standard"
                                  size="small"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  value={((area.usedArea / area.area) * 100).toFixed(2) || ''}
                                  onChange={(e) => handleChangeInput(e, area, true)} // Este campo é apenas para exibir o cálculo
                                />
                              </Grid>

                              <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                                <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <ResourcesStepFormMainTabEdit data={data} obj_fn={obj_fn} />

                  {/* <Grid container spacing={3} sx={{ mb: 3, mt: 4 }}>
                    <Box sx={{ ml: 1, display: 'flex' }}>
                      <Stack spacing={0.5}>
                        <Typography fontWeight={700}>
                          <ShoppingCart sx={{ mr: 1 }} />
                          Despesas (Custos Operacionais)
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>

                  <div style={{ border: '1px solid #2e7d32', padding: '16px', borderRadius: '8px' }}>
                    <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
                  </div> */}

                  {/* Pergunta e botão para deletar os custos de produção */}
                  {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontWeight={700}>Deseja deletar os custos de produção para esta atividade?</Typography>
                    <Button variant="outlined" color="error" onClick={handleOpenModal}>
                      Deletar Custos de Produção
                    </Button>
                  </Box> */}

                  <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                    <Toolbar>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                          cancelar
                        </Button>
                      </Box>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        {/* <Button color="success" variant="contained" type="submit" onClick={!updated ? () => null : onSubmit} disabled={isError()}> */}
                        <Button color="success" variant="contained" type="submit" onClick={onSubmit} disabled={isError()}>
                          Concluir
                        </Button>
                      </Box>
                    </Toolbar>
                  </AppBar>
                </Box>
              </Paper>
            </Container>
          </DialogContent>
        </Dialog>
        <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
          <Edit sx={{ color: green[500] }} />
        </IconButton>
        {/* Modal de confirmação do delete resources */}
      </div>
    </>
  );
}
