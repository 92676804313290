import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import api from "../../../services/api";

export default function MenuCardTrash({ id, setIsLoading }) {
  console.log('MenuCard')
  console.log(id)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const display_demo = JSON.parse(localStorage.getItem("display"));
  const handleDelete = async (id) => {

    // await api.delete(`posts/${id}`);
    console.log(id);
    console.log('deletou')
    const isDeleted = "1"
    const res = await api.delete(`/messenger/posts/${id}/${isDeleted}`);
    console.log(res.data);
    setIsLoading(true)
    //console.log(this.state.selectedFile);


  };


  // const refreshPage = () => {
  //   navigate(0);
  // };

  const handleBack = async (id) => {
    // await api.delete(`posts/${id}`);
    console.log(id);
    console.log("deletou");
    const isDeleted = "-1";
    const res = await api.delete(`/messenger/posts/${id}/${isDeleted}`);
    console.log(res.data);
    setIsLoading(true)
    //console.log(this.state.selectedFile);

  };


  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
            {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
            <MoreVertIcon sx={{ color: "#808080" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >


        <MenuItem onClick={() => handleBack(id)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Recuperar
        </MenuItem>
        <Divider />
        {display_demo ? (<div></div>) : (
          <MenuItem onClick={() => handleDelete(id)}>
            <ListItemIcon >
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            Excluir
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}
