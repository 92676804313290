
import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import IconCheckboxes from "../IconCheckboxes";
import FullScreenDialogEdit from "../../DialogMessenger/FullScreenDialogEdit";
import MenuCard from "../MenuCard";
import { format } from "date-fns";
import moment from 'moment'
import Badge from '@mui/material/Badge';
import Loading from "../../../../components/Loader/Loading";
import { PollTwoTone } from "@mui/icons-material";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NotesIcon from '@mui/icons-material/Notes';
import useStore from "../../../../store/useStore";

export default function CardChatKanban({ post, setIsLoading }) {
  const [farm, setFarm] = useState('')
  const [isLoadingFarm, setIsLoadingFarm] = useState(true)

  console.log('post-CardChat ');
  console.log(post);

  const usestore = useStore();
  const listPropertyRepos = usestore.reposProperty.listProperty;

  useEffect(() => {
    console.log('entrou useefeccts')
    for (const iterator of listPropertyRepos) {
      if (post.id_property == iterator.id) {
        setFarm(iterator.label);
      }
    }
    setIsLoadingFarm(false);
  }, [farm])

  const [isLoadingDate, setIsLoadingDate] = useState(false)
  const [date, setDate] = useState(post?.date)

  function formatDate() {
    var formatDate = moment(post.date).format('DD/MM/YYYY');
    console.log(formatDate)
    setDate(formatDate)
  }

  useEffect(() => {
    formatDate()
    setIsLoadingDate(true)
  }, [isLoadingDate])

  //['Resolver'->0, 'Resolvido'->1, 'Não Resolvido'->2, 'Parado'->3];

  const status = { 0: "Resolver", 1: "Resolvido", 2: "Parado" }
  const statuscolor = { 0: "primary", 1: "success", 2: "default" }

  // const status_color = {
  //   0: ["Resolver", "primary"],
  //   1: ["Resolvido", "success"],
  //   2: ["Parado", "default"],
  // }
  //color={status_color[post.status][1]}

  console.log('farm')
  console.log(farm)

  return (
    <>
      {isLoadingFarm ? <Loading /> :
        <Card sx={{ mt: 1 }}>
          {/* <Card sx={{ maxWidth: 345 }}></Card> */}
          <CardHeader
            sx={{ m: -2, ml: -1 }}
            avatar={
              <>
                {post.status === "0" ?
                  (
                    <Avatar sx={{ backgroundColor: blue[700] }} aria-label="recipe">
                      {post.charName}
                    </Avatar>
                  ) : (post.status === "1" ?
                    <Avatar sx={{ backgroundColor: green[800] }} aria-label="recipe">
                      {post.charName}
                    </Avatar>
                    :
                    <Avatar sx={{ backgroundColor: grey[500] }} aria-label="recipe">
                      {post.charName}
                    </Avatar>
                  )}
              </>

            }
            action={
              <div aria-label="settings">
                <MenuCard id={post.id} setIsLoading={setIsLoading} />
              </div>
            }
            title={
              <Typography variant="button">
                <strong>{post.title}</strong>
              </Typography>
            }
            subheader={
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {farm}
              </Typography>
            }
          />
          <CardContent sx={{ m: -2, ml: -1 }}>
            <Grid container direction="row" alignItems="flex-start" gap={1}>
              <Grid item sx={{ mr: 2.5 }}>
                <Typography sx={{ fontSize: 14, }} color="text.secondary" gutterBottom>
                  <NotesIcon sx={{ ml: 1 }} /> {post.description}
                </Typography>
              </Grid>
            </Grid>
            {post.name_receiver &&
              <>
                <Grid container direction="row" alignItems="flex-end" gap={1}>
                  <Grid item sx={{ ml: "auto", mr: 2.5 }}>
                    <Typography sx={{ fontSize: 14 }} color="text.success" gutterBottom>
                      <HowToRegIcon sx={{ fontSize: 22 }} color="success" /> {post.name_receiver}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          </CardContent>
          <CardActions>
            <IconButton aria-label="add to favorites">
              <FullScreenDialogEdit id={post.id} post={post} setIsLoading={setIsLoading} />
            </IconButton>

            {post.status && statuscolor &&
              <Badge sx={{ ml: "auto", mr: 5 }} badgeContent={status[post.status]} color={statuscolor[post.status]} />
            }
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {isLoadingDate && date}
            </Typography>

          </CardActions>
        </Card>
      }
    </>
  );
}
