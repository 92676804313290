import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormAccountSelection from '../../Forms/FormAccountSelection';
import FormApportChoice from '../../Forms/FormApportChoice';

const text_title = {
  'Conta a receber': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Registrar Receita': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Conta a pagar': 'Por favor, defina as saídas previstas (acertos com fornecedores ou quitação de contas de consumo)',
  'Registrar Despesa': 'Por favor, defina uma categoria para organizarmos a despesa e como será apropriada.',
  Compra: '',
  Venda: '',
};

export default function FormEntryStep2Account({ data, obj_fn }) {
  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  // const para validar o button continuar
  const isError = () => {
    if (!data.category) {
      return true;
    }
    return false;
  };
  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography style={{ color: green[800] }}>
              <div className="d-lg-none">
                <Typewriter tam={110} minHeight={0} text={text_title[data.operationType]} />
              </div>
              <div className="d-none d-lg-block">
                <Typewriter tam={60} minHeight={0} text={text_title[data.operationType]} />
              </div>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        {/* Seleção da conta */}
        <FormAccountSelection data={data} obj_fn={obj_fn} handleChange={handleChange} />
      </Box>

      <Grid item xs={12} sm={12} sx={{ mt: 0, mb: 3 }}>
        <FormApportChoice data={data} obj_fn={obj_fn} />
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
