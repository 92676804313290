import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Grid, Paper, Popover, Stack, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import InputIcon from '@mui/icons-material/Input';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import ScaleIcon from '@mui/icons-material/Scale';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import MultiStepFormInput from '../FormActivities/Input/MultiStepFormInput';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import useActivityInputReposStore from '../../../../store/Activities/useActivityInputReposStore';
import { useParams } from 'react-router-dom';
import { Balance, AddLocationAlt, WrongLocation, ExpandMore } from '@mui/icons-material';

import MultiStepFormIdentification from '../FormActivities/Identification/MultiStepFormIdentification';
import useLivestockReposStore from '../../../../store/useLivestockReposStore';
import MultiStepFormWeight from '../FormActivities/Weight/MultiStepFormWeight';
import MultiStepFormIndividualWeight from '../FormActivities/IndividualWeight/MultiStepFormIndividualWeight';
import MultiStepFormTransferBatch from '../FormActivities/transferBatch/MultiStepFormTransferBatch';
import MultiStepFormAssignArea from '../FormActivities/AssignArea/MultiStepFormAssignArea';
import MultiStepFormBatch from '../FormActivities/Batch/MultiStepFormBatch';
import MultiStepFormMedicines from '../FormActivities/Medicines/MultiStepFormMedicines';
import useAreasMapReposStore from '../../../../store/useAreasMapReposStore';
import MultiStepMarketing from '../FormActivities/Marketing/MultiStepMarketing';
import MultiStepFormProduction from '../FormActivities/Production/MultiStepFormProduction';
import MultiStepFormNutrition from '../FormActivities/Nutrition/MultiStepFormNutrition';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

const buttons = {
  'Entrada dos Animais': [
    {
      name: 'input',
      value: true,
      title: 'Entrada ',
      icon: <InputIcon style={{ fontSize: 50 }} color="disabled" />,
      description: 'A atividade de entrada é responsável por entrar com um número de animais no lote em uma área de produção.',
      image: '/dist/image/entrada_imagem.png',
    },
    {
      name: 'identification',
      value: true,
      title: 'Identificações ',
      icon: <FormatListBulletedIcon style={{ fontSize: 50 }} color="disabled" />,
      description:
        'A atividade de identificação animal é responsável por controle mais detalhado e preciso de cada animal individualmente, incluindo nome, data de nascimento, raça, peso, nutrição, medicamentos, exames e produção.',
      image: '/dist/image/identificacao_imagem.png',
    },
    // { name: 'score', value: true, title: 'Contagens ', icon: <FormatListNumberedRtlIcon style={{ fontSize: 50 }} color="disabled" /> },
    { name: 'batch', value: true, title: 'Novo Lote', icon: <CropOriginalIcon style={{ fontSize: 50 }} color="disabled" />, description: '', image: '/dist/image/branco.png' },
  ],
  Áreas: [
    { name: 'assignArea', value: true, title: 'Vincular Áreas', icon: <AddLocationAlt style={{ fontSize: 50 }} color="disabled" />, description: '', image: '/dist/image/branco.png' },
    { name: 'desassignArea', value: true, title: 'Desvincular Áreas', icon: <WrongLocation style={{ fontSize: 50 }} color="disabled" />, description: '', image: '/dist/image/branco.png' },
    {
      name: 'transferBatch',
      value: true,
      title: 'Transferência de Lotes entre Áreas',
      icon: <MultipleStopIcon style={{ fontSize: 50 }} color="disabled" />,
      description: '',
      image: '/dist/image/branco.png',
    },
    {
      name: 'transferArea',
      value: true,
      title: 'Transferência de Animais entre Lotes',
      icon: <MultipleStopIcon style={{ fontSize: 50 }} color="disabled" />,
      description: '',
      image: '/dist/image/branco.png',
    },
    //{ name: "aside", value: false, title: "Aparte ", icon: <AltRouteIcon style={{ fontSize: 50 }} color="disabled" /> },
  ],
  Manejos: [
    // { name: 'pasture', value: true, title: 'Pastagem', icon: <GrassIcon style={{ fontSize: 50 }} color="disabled" /> },
    {
      name: 'nutrition',
      value: true,
      title: 'Nutrição',
      icon: <FoodBankIcon style={{ fontSize: 50 }} color="disabled" />,
      description: 'Registre a nutrição animal no software para otimizar o crescimento e a saúde dos animais, garantindo maior produtividade e qualidade dos produtos derivados.',
      image: '/dist/image/branco.png',
    },
    {
      name: 'weight',
      value: true,
      title: 'Pesagem',
      icon: <Balance style={{ fontSize: 50 }} color="disabled" />,
      description: 'Registre a pesagem dos lotes e animais para garantir um controle preciso da produção e monitorar o desenvolvimento de cada animal, maximizando a eficiência da criação.',
      image: '/dist/image/branco.png',
    },
    {
      name: 'individualWeight',
      value: true,
      title: 'Pesagem Individual',
      icon: <ScaleIcon style={{ fontSize: 50 }} color="disabled" />,
      description: 'Registre a pesagem individual de cada animal no software para acompanhar o desempenho e evolução, garantindo uma gestão precisa e o crescimento saudável do rebanho.',
      image: '/dist/image/branco.png',
    },
    {
      name: 'medicines',
      value: true,
      title: 'Medicamentos',
      icon: <VaccinesIcon style={{ fontSize: 50 }} color="disabled" />,
      description: 'Registre o uso de medicamentos no software para manter um controle rigoroso da saúde dos animais e assegurar a qualidade dos produtos finais.',
      image: '/dist/image/branco.png',
    },
    // { name: 'exams', value: true, title: 'Exames', icon: <MedicalInformationIcon style={{ fontSize: 50 }} color="disabled" /> },
    // { name: "sanitary", value: false, title: "Sanitário", icon: <MedicalInformationIcon style={{ fontSize: 50 }} /> },
  ],
  Produção: [
    {
      name: 'production',
      value: true,
      title: 'Produção',
      icon: <TrendingUpIcon style={{ fontSize: 50 }} color="disabled" />,
      description: 'Monitore e registre a produção animal no software para ter controle completo sobre a produtividade, auxiliando na tomada de decisões estratégicas e no aumento da rentabilidade.',
      image: '/dist/image/branco.png',
    },
  ],
  // 'Financeiras': [
  //   { name: "production", value: false, title: "Produção", icon: <TrendingUpIcon style={{ fontSize: 50 }} /> },
  //   { name: "buy", value: false, title: "Compra", icon: <PointOfSaleIcon style={{ fontSize: 50 }} /> },
  //   { name: "sales", value: false, title: "Venda", icon: <AttachMoneyIcon style={{ fontSize: 50 }} /> },
  // ],
  Repodução: [
    { name: 'reproduction', value: true, title: 'Reprodução ', icon: <DeviceHubIcon style={{ fontSize: 50 }} color="disabled" /> },
    { name: 'birth', value: true, title: 'Nascimentos ', icon: <AutoAwesomeIcon style={{ fontSize: 50 }} color="disabled" /> },
    { name: 'assessments', value: true, title: 'Avaliações ', icon: <DeviceHubIcon style={{ fontSize: 50 }} color="disabled" /> },
  ],
  // Marketing: [{ name: 'marketing', value: true, title: 'Marketing ', icon: <CampaignIcon style={{ fontSize: 50 }} color="disabled" /> }],
  // Outras: [{ name: 'others', value: true, title: 'Adicionar outra', icon: <AddCircleOutlineIcon style={{ fontSize: 50 }} color="disabled" /> }],
};

// lista os botçoes de cada atividade que chama o bloc (MultiStep) do formulário da atividade ... MultiStepFormInput
export function CardButtonListActivities({ handleClose }) {
  const classes = useStyles();
  const { idFarm, id } = useParams();
  const [showText, setShowText] = useState(false);
  const [buttonStates, setButtonStates] = useState({});
  const [selectedComponent, setSelectedComponent] = useState(null);

  // verificar se exite uma atividade
  const reposActivitiesInputs = useActivityInputReposStore((state) => state);
  //console.log('reposActivitiesInputs')
  //console.log(reposActivitiesInputs)
  // const listActivitiesInputs = reposActivitiesInputs.listActivityInput
  //console.log('listActivitiesInputs')
  //console.log(reposActivitiesInputs)
  // const activity = window.jQuery.extend(true, [], reposActivitiesInputs.getActivityInputStore('id_livestock', id))
  const activity = reposActivitiesInputs.getActivityInputStore('id_livestock', id);
  //console.log(activity.length)

  const reposLivestock = useLivestockReposStore((state) => state);
  const initLivestock = reposLivestock.getLivestockStore('id', id)[0];

  const reposAreasMap = useAreasMapReposStore((state) => state);
  // console.log(reposAreasMap.listAreasMap)
  const areas = reposAreasMap.listAreasMap;

  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 1500);

  const handleButtonClick = (name, value) => {
    setButtonStates({ ...buttonStates });
    setSelectedComponent(name);
  };
  // console.log('initLivestock ')
  // console.log(initLivestock)
  const handleValidButton = (button) => {
    //  console.log(button)
    if (!button || typeof button !== 'object') {
      return false;
    }

    //console.log(initLivestock.areas.length)
    //console.log(initLivestock.lotes.length)
    //console.log(initLivestock.areas.length <= 1 && initLivestock.lotes.length <= 1)
    //&& initLivestock.lotes.length > 1
    return (
      !button.value ||
      (initLivestock.lotes.length == 0 && button.name === 'identification') ||
      ((initLivestock.lotes.length == 0 || areas.length < 2) && button.name === 'assignArea') ||
      ((initLivestock.lotes.length == 0 || areas.length < 2) && button.name === 'desassignArea') ||
      (initLivestock.animais.length == 0 && button.name === 'transfer') ||
      (initLivestock.lotes.length == 0 && button.name === 'weight') ||
      (true && button.name === 'score') ||
      // (initLivestock.animais.length == 0 && button.name === 'score') ||
      (initLivestock.lotes.length == 0 && button.name === 'batch') ||
      (initLivestock.animais.length == 0 && button.name === 'individualWeight') ||
      (true && button.name === 'transferArea') ||
      //  (areas.length < 2 && button.name === 'transferBatch') ||
      ((initLivestock.lotes.length == 0 || areas.length < 2) && button.name === 'transferBatch') ||
      // ((initLivestock.lotes.length == 0 || initLivestock.lotes.length == 1) && button.name === 'transferBatch') ||
      // ((initLivestock.areas.length == 0 || initLivestock.areas.length == 1 || initLivestock.animais.length == 0) && button.name === 'transferArea') ||
      (true && button.name === 'pasture') ||
      // (initLivestock.animais.length == 0 && button.name === 'pasture') ||
      // (initLivestock.animais.length == 0 && button.name === 'nutrition') ||
      (initLivestock.lotes.length == 0 && button.name === 'nutrition') ||
      (initLivestock.lotes.length == 0 && button.name === 'medicines') ||
      (true && button.name === 'exams') ||
      // (initLivestock.animais.length == 0 && button.name === 'exams') ||
      (initLivestock.lotes.length == 0 && button.name === 'production') ||
      (true && button.name === 'reproduction') ||
      (true && button.name === 'birth') ||
      (true && button.name === 'assessments') ||
      (initLivestock.lotes.length == 0 && button.name === 'marketing') ||
      (true && button.name === 'others')
      // (initLivestock.animais.length == 0 && button.name === 'production') ||
      // (initLivestock.animais.length == 0 && button.name === 'reproduction') ||
      // (initLivestock.animais.length == 0 && button.name === 'birth') ||
      // (initLivestock.animais.length == 0 && button.name === 'assessments') ||
      // (initLivestock.animais.length == 0 && button.name === 'marketing') ||
      // (initLivestock.animais.length == 0 && button.name === 'others')
    );
  };

  //  const handleButtonClickAndUpdate = (button) => {
  //   const isValid = handleValidButton(button);

  //   if (isValid) {
  //     const updatedButtons = { ...buttons };
  //     updatedButtons['Entrada dos Animais'] = updatedButtons['Entrada dos Animais'].map(b => {
  //       if (b.name === button.name) {
  //         return { ...b, value: false };
  //       }
  //       return b;
  //     });
  //     //setButtons(updatedButtons);
  //   }

  //   handleButtonClick(button.name, button);
  // };

  // const renderIcon = (button, isValid) => {
  //   const icon = button.value ? button.icon : React.cloneElement(button.icon, { color: isValid ? 'disabled' : 'inherit' });
  //   // console.log(icon)
  //   return icon;
  // };

  useEffect(() => {
    // Set initial button states
    const initialButtonStates = {};
    for (const category in buttons) {
      buttons[category].forEach((button) => {
        initialButtonStates[button.name] = button.value;
      });
    }
    setButtonStates(initialButtonStates);
  }, []);

  //  tooltip com uma imagem e um texto explicativo

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState({ title: '', description: '', image: '' });
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (event, button) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent({
      title: button.title,
      description: button.description,
      image: button.image,
    });
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent({ title: '', description: '', image: '' });
    setOpen(false);
  };

  return selectedComponent === null ? (
    <>
      <Box sx={{ width: '100%' }}>
        <div className="d-lg-none">
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: '30px' }}>
              {showText ? (
                activity.length > 0 ? (
                  <Typewriter text="Selecione uma atividade:" variant={'subtitle1'} color={'#2e7d32'} />
                ) : (
                  <Typewriter text="Crie uma atividade de entrada" variant={'subtitle1'} color={'#2e7d32'} />
                )
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container sx={{ mb: 2 }}>
            {/* <Grid item xs={12} md={12} sx={{ height: 60 }} alignItems="center">
              <Typewriter text="Veja qual tipo de controle é ideal para a sua gestão:" variant={'subtitle1'} color={'#2e7d32'} />
            </Grid> */}
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: '30px' }}>
              {showText ? (
                activity.length > 0 ? (
                  <>
                    <Typewriter text="Selecione uma atividade de campo:" variant={'subtitle1'} color={'#2e7d32'} />
                  </>
                ) : (
                  <>
                    <Typewriter text={`Por favor, clique na atividade "ENTRADA" para dar entrada dos lotes de animais na fazenda.`} variant={'subtitle1'} color={'#2e7d32'} />
                    {/* <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                      <strong>ENTRADA: </strong>A atividade de entrada é responsável por entrar com um número de animais no lote em uma área de produção.
                    </Typography> */}
                  </>
                )
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={12} alignItems="center">
              {activity.length > 0 && (
                <>
                  <Grid item xs={12} md={12} sx={{ mb: 2 }} alignItems="center">
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Tipos de criação">
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                          <strong>Gerencie sua criação de forma eficiente, seja por lotes ou animais individuais. Clique aqui para saber mais.</strong>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12} md={12} sx={{ mb: 2 }} alignItems="center">
                          <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                            <strong>
                              O Fazenda Premium inicia sua gestão padrão com o gerenciamento por lotes. Após criar a atividade de identificação animal, o sistema também permite o gerenciamento
                              individualizado de cada animal.
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mb: 4 }} alignItems="center">
                          <strong>Gerenciamento por Lote:</strong> Tenha um controle ágil e eficaz sobre grupos de animais, com dados organizados por lote, incluindo raça, peso, nutrição,
                          medicamentos, exames e produção.
                          <br />
                          <br />
                          <strong>Gerenciamento por Animais Identificados:</strong> Aprofunde-se no controle individual de cada animal, registrando informações como nome, data de nascimento, raça,
                          peso, nutrição, medicamentos, exames e produção.
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mb: 2 }} alignItems="center">
                          <strong>Se estiver em dúvida,</strong> comece a gestão por lotes e realize apenas a atividade de IDENTIFICAÇÃO. À medida que se familiariza com o Fazenda Premium, você poderá
                          ampliar o controle sobre seus animais.
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {/* <Typography fontWeight="700" variant="subtitle1" style={{ color: '#2e7d32' }}>
                    Selecione uma atividade de campo:
                  </Typography> */}
                  <Grid item xs={12} md={12} alignItems="center">
                    <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                      <strong>Para saber sobre cada atividade, passe o cursor do mouse sobre os botões </strong>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box sx={{ width: '100%', mt: 1 }}>
        {Object.keys(buttons).map((category) => (
          <div key={category}>
            <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }} color="text.secondary">
              {category}
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              {buttons[category].map((button) => (
                <Grid item xs={6} sm={6} md={3} key={button.name}>
                  <Box onMouseEnter={(event) => handlePopoverOpen(event, button)} onMouseLeave={handlePopoverClose}>
                    <ButtonBase
                      component={Paper}
                      elevation={2}
                      sx={{ p: 2, borderRadius: 1, backgroundColor: '#FFF' }}
                      onClick={() => handleButtonClick(button.name, buttonStates[button.name])}
                      className={classes.button}
                      disabled={handleValidButton(button)}
                    >
                      <Stack direction="column" alignItems="center">
                        {/* {button.icon} */}
                        {handleValidButton(button) ? button.icon : React.cloneElement(button.icon, { color: '#d3d3d3' })}
                        <Typography align="center" sx={{ mt: 1, color: handleValidButton(button) ? '#d3d3d3' : '' }} fontWeight={400} variant="body1">
                          {button.title}
                        </Typography>
                        {/*(button.name === "identification" || button.name === "weight") && initLivestock.lotes.length == 0 ?
                        <Typography align="center" sx={{ mt: 1, color: 'red' }} fontWeight={400} variant="body2">
                          Necessário realizar a entrada dos animais primeiro!
                        </Typography> : <></>*/}
                      </Stack>
                    </ButtonBase>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
        <Popover
          sx={{ pointerEvents: 'none', maxWidth: 400 }} // Limita a largura máxima
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box p={2} sx={{ width: '100%', maxWidth: 400 }}>
            {' '}
            {/* Ajusta a largura e define o limite */}
            <Typography variant="h6">{popoverContent.title}</Typography>
            <Typography variant="body1">{popoverContent.description}</Typography>
            {popoverContent.image && <img src={popoverContent.image} alt={popoverContent.title} width={300} />}
          </Box>
        </Popover>
      </Box>
    </>
  ) : selectedComponent === 'input' ? (
    <MultiStepFormInput handleClose={handleClose} />
  ) : selectedComponent === 'identification' ? (
    <MultiStepFormIdentification handleClose={handleClose} />
  ) : selectedComponent === 'batch' ? (
    <MultiStepFormBatch handleClose={handleClose} />
  ) : selectedComponent === 'transferBatch' ? (
    <MultiStepFormTransferBatch handleClose={handleClose} />
  ) : selectedComponent === 'weight' ? (
    <MultiStepFormWeight handleClose={handleClose} />
  ) : selectedComponent === 'individualWeight' ? (
    <MultiStepFormIndividualWeight handleClose={handleClose} />
  ) : selectedComponent === 'marketing' ? (
    <MultiStepMarketing handleClose={handleClose} />
  ) : selectedComponent === 'medicines' ? (
    <MultiStepFormMedicines handleClose={handleClose} />
  ) : selectedComponent === 'nutrition' ? (
    <MultiStepFormNutrition handleClose={handleClose} />
  ) : selectedComponent === 'assignArea' ? (
    <MultiStepFormAssignArea handleClose={handleClose} tipo={'ADIÇÃO'} />
  ) : selectedComponent === 'desassignArea' ? (
    <MultiStepFormAssignArea handleClose={handleClose} tipo={'REMOÇÃO'} />
  ) : selectedComponent === 'production' ? (
    <MultiStepFormProduction handleClose={handleClose} />
  ) : null;
}
