import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import ScaleIcon from '@mui/icons-material/Scale'
import FormWeightHeader from './FormWeightHeader'
import FormWeightFooter from './FormWeightFooter'
import TableWeightAnimals from './TableWeightAnimals'
import GenericValueField from '../../../../../components/Fields/GenericValueField'

export default function FormWeightStep3({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false)
  setTimeout(() => {
    setShowTextFase(true)
  }, 500)

  const [errorr, setError] = useState(false)

  const handleChange = (event) => {
    // console.log('handleChange')
    let value = event.target.value
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: value }))

    let new_list = []
    for (const animal of data.table_animals) {
      if (!animal.id) { // lote
        animal.new_weight = value * Number(animal.identifier.replace(/.*\((.*)\).*/, '$1')) ;
      } else {
        animal.new_weight = value;
      }
      new_list.push(animal);
      /*new_list.push({
        id: animal.id,
        identifier: animal.identifier,
        sexo: animal.sexo,
        weight: animal.weight || 0,
        new_weight: value || 0,
      })*/
    }
    obj_fn.setData((prevState) => ({ ...prevState, table_animals: new_list }))
  }

  // const para validar o button continuar
  const isError = () => {
    return errorr
  }

  // aciona o botao retornar
  const returnError = () => {
    return false
  }

  const text_title = 'Informações Essenciais'
  const txt_msn = 'Estabeleça o peso médio para os animais do lote!\n Sinta-se à vontade para fazer alterações no peso individual de cada animal!'

  return (
    <>
      <FormWeightHeader text_title={text_title} txt_msn={txt_msn} height={'60px'} showTextFase={showTextFase} />

      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3}>
            <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
              Peso médio atual do lote:
              <br />
              {data.lote?.weight || 0} Kg
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            {/* <TextField
              label="Peso médio"
              name="new_weight"
              id="new_weight"
              type="number"
              color="success"
              variant="standard"
              fullWidth
              sx={{ m: 1, width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ScaleIcon />
                  </InputAdornment>
                ),
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 12,
                min: 0,
              }}
              value={data?.new_weight}
              onChange={handleChange}
              helperText={'Peso médio do lote'}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                id: "new_weight",
                name: "new_weight",
                label: 'Peso médio',
                value: data?.new_weight,
                variant:"standard",
                helperText: "Peso médio dos animais no lote",
                height: '35px',
                endAdornment: true,
                prefix: <ScaleIcon />,
              }}
            />
          </Grid>
        </Grid>
        <TableWeightAnimals data={data} obj_fn={obj_fn} setError={setError} />
      </Grid>

      <FormWeightFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  )
}
