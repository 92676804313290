import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(age, male, female, total) {
  return { age, male, female, total };
}

export default function LivestockListTableCategory({ livestock }) {
  const rows = [
    createData('0-8 meses',   0, 0, 0, 0),
    createData('9-12 meses',  0, 0, 0, 0),
    createData('13-24 meses', 0, 0, 0, 0),
    createData('25-36 meses', 0, 0, 0, 0),
    createData('36+ ',        0, 0, 0, 0),
    createData('Total',       0, 0, 0, 0),
  ];

  livestock.animais.map((item) => {
    if (item.ageMonths < 8) {
      rows[0].female += (/F.*/.test(item.sexo) ? 1 : 0);
      rows[0].male += (/M.*/.test(item.sexo) ? 1 : 0);
      rows[0].total += 1;

    } else if (item.ageMonths < 12) {
      rows[1].female += (/F.*/.test(item.sexo) ? 1 : 0);
      rows[1].male += (/M.*/.test(item.sexo) ? 1 : 0);
      rows[1].total += 1;

    } else if (item.ageMonths < 24) {
      rows[2].female += (/F.*/.test(item.sexo) ? 1 : 0);
      rows[2].male += (/M.*/.test(item.sexo) ? 1 : 0);
      rows[2].total += 1;

    } else if (item.ageMonths < 36) {
      rows[3].female += (/F.*/.test(item.sexo) ? 1 : 0);
      rows[3].male += (/M.*/.test(item.sexo) ? 1 : 0);
      rows[3].total += 1;

    } else {
      rows[4].female += (/F.*/.test(item.sexo) ? 1 : 0);
      rows[4].male += (/M.*/.test(item.sexo) ? 1 : 0);
      rows[4].total += 1;
    }
    rows[5].female += (/F.*/.test(item.sexo) ? 1 : 0);
    rows[5].male += (/M.*/.test(item.sexo) ? 1 : 0);
    rows[5].total += 1;
  });

  livestock.lotes.map((item) => {
    if (item.ageMonths < 8) {
      rows[0].total += item.num_animals;

    } else if (item.ageMonths < 12) {
      rows[1].total += item.num_animals;

    } else if (item.ageMonths < 24) {
      rows[2].total += item.num_animals;

    } else if (item.ageMonths < 36) {
      rows[3].total += item.num_animals;

    } else {
      rows[4].total += item.num_animals;
    }
    rows[5].total += item.num_animals;
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }} >IDADE</TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">FÊMEAS</TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">MACHOS</TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">TOTAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row"> {row.age} </TableCell>
              <TableCell align="right">{row.female}</TableCell>
              <TableCell align="right">{row.male}</TableCell>
              <TableCell align="right">{row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}