import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ExpandMore, Notes } from '@mui/icons-material';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HomeWork from '@mui/icons-material/HomeWork';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import BusinessIcon from '@mui/icons-material/Business';
import TitleIcon from '@mui/icons-material/Title';
import AppsIcon from '@mui/icons-material/Apps';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import api from '../../services/api';
import RegisterGroupPatrimony from './RegisterGroupPatrimony';
import RegisterTypePatrimony from './RegisterTypePatrimony';
import { Context } from '../../components/Context/AuthContext';
import { getIconComponent } from './components/getIconComponent';
// import usePatrimonyReposStore from '../../store/usePatrimonyReposStore';
// import dayjs from 'dayjs';
import { format } from 'date-fns';
import SimpleDateProvider from '../../components/Fields/SimpleDateProvider';
import ImageUploadComponent from '../../components/Fields/ImageUploadComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';
// import WarehouseIcon from '@mui/icons-material/Warehouse';
import useStore from '../../store/useStore';
import GenericValueField from '../../components/Fields/GenericValueField';

const default_patrimony = {
  name: '',
  group: 'Maquinário',
  //id_group: selectedGroup,
  model: '',
  type: '',
  manufacturer: '',
  manufacturing_year: '',
  lifespan: '',
  date_prev_end: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  value: '0,00',
  horimetro_current: 0,
  observation: '',
  image: '',
  url: '',
  // id_property: property_id
}

export default function RegisterNewPatrimony() {
  const [open, setOpen] = useState(false);
  // const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState('md');
  const fullWidth = true;
  const maxWidth = 'md';
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenType, setIsOpenType] = useState(false);
  const [patrimonyGroup, setPatrimonyGroup] = useState([]);
  const [patrimonyType, setPatrimonyType] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [updateCounter, setUpdateCounter] = useState(0);

  const usestore = useStore();

  const { aux_add, setAuxAdd } = React.useContext(Context);
  const property_id = Number(localStorage.getItem('Property')) || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  // const reposPatrimony = usePatrimonyReposStore((state) => state);
  const reposPatrimony = usestore.reposPatrimony;
  // console.log(reposPatrimony.patrimonyGroup);
  //console.log(reposPatrimony.patrimonyType);
  //aux_add atualiza o estado o seletor typo
  // const iconMapping = {
  //   "HomeWork": <HomeWork />,
  //   "WarehouseIcon": <WarehouseIcon />,
  //   "AgricultureIcon": <AgricultureIcon />,
  //   "ShowerIcon": <ShowerIcon />,
  //   "PrecisionManufacturingIcon": <PrecisionManufacturingIcon />,
  //   "LocalShippingIcon": <LocalShippingIcon />,
  //   "ShareLocationIcon": <ShareLocationIcon />,
  //   "FenceIcon":  <FenceIcon />,
  //   "CheckBoxOutlineBlankIcon":<CheckBoxOutlineBlankIcon />,
  //   // Adicione outros mapeamentos de ícones conforme necessário
  // };

  useEffect(() => {
    setPatrimonyGroup(reposPatrimony?.patrimonyGroup);
    setPatrimonyType(reposPatrimony?.patrimonyType);
  }, [aux_add, updateCounter]);

  // Função para formatar a data em dia/mês/ano
  // function formatDate(date) {
  //   return dayjs(date).format('DD/MM/YYYY');
  // }
  // const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  const [data, setData] = useState(default_patrimony);

  // console.log('data');
  // console.log(data);
  //console.log(selectedGroup);

  const handleChange = (event, blur) => {
    // console.log('handleChangeInput');
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: blur ? (event.target.value).trim() : event.target.value,
    }));
  };

  const handleChangeValue = (event) => {
    // console.log(event);
    setData({ ...data, value: event.target.value });
  };

  const handleDateChange = (newDate) => {
    // console.log(newDate);
    setData({ ...data, date_prev_end: newDate });
  };

  const filterTypesByGroup = (group, typesFromGroup) => {
    return typesFromGroup.filter((type) => {
      return type.patrimony_group_id === group.id;
    });
  };

  const handleChangeGroup = (event) => {
    // console.log(event.target.value);
    const selectedGroup = patrimonyGroup.find((group) => group.id === event.target.value);
    if (selectedGroup) {
      setSelectedGroup(selectedGroup);
      setData((prevState) => ({ ...prevState, group: selectedGroup.name }));
    }
  };

  const filteredTypes = filterTypesByGroup(selectedGroup, patrimonyType);

  // // import patrimonyGroupData
  // const fetchPatrimonyGroup = async () => {
  //   const patrimonyGroupData = await api
  //     .get("/patrimony/patrimony-group/list")
  //     .then((res) => {
  //       setPatrimonyGroup(res.data._value);
  //     })
  //     .catch((err) => {
  //       console.log("Erro ao recuperar dados da API.");
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   console.log("refresh usePatrimonyReposStore");
  // }, [aux_add]);

  // const fetchPatrimonyType = async () => {
  //   const patrimonyTypeData = await api
  //     .get("/patrimony/patrimony-type/list")
  //     .then((res) => {
  //       setPatrimonyType(res.data._value);
  //     })
  //     .catch((err) => {
  //       console.log("Erro ao recuperar dados da API.");
  //       console.log(err);
  //     });
  // };

  // TODO: Atualizar o componente quando um novo tipo de patrimônio for criado
  // useEffect(() => {
  //   //fetchPatrimonyGroup();
  //  // fetchPatrimonyType();
  // }, [updateCounter]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //setShowRegisterType(!showRegisterType);
    resetForm();
  };

  // const handleMaxWidthChange = (event) => {
  //   setMaxWidth(
  //     // @ts-expect-error autofill of arbitrary value is not handled.
  //     event.target.value
  //   );
  // };

  // const handleFullWidthChange = (event) => {
  //   setFullWidth(event.target.checked);
  // };

  const toggleDrawer = (open) => {
    setIsOpen(true);
  };

  const toggleDrawerType = (open) => {
    setIsOpenType(true);
  };

  const validate = (data_sub) => {
    var valid = true;
    var msg = 'Por favor, preencha os campos obrigatórios!';

    if (!data_sub.patrimony_type) {
      valid = false;
      msg = 'Selecione um tipo para seu patrimônio';
    } else if (!data_sub.name) {
      valid = false;
      msg = 'Informe o nome do patrimônio';
    } else if (data_sub.name.length < 5) {
      valid = false;
      msg = 'Nome do patrimônio deve ter no mínimo 5 letras';
    } else if (!data_sub.value) {
      valid = false;
      msg = 'Defina o valor de compra do patrimônio';
    } else {
      var aux_name = reposPatrimony.getPatrimonyStore('name', data_sub.name || '');
      if (aux_name.length > 0) {
        valid = false;
        msg = 'Já existe um patrimônio com o nome informado';
      }
    }

    return [valid, msg];
  };

  const resetForm = () => {
    setData(default_patrimony)
  };

  const handleSubmit = async () => {
    const selectedType = reposPatrimony.patrimonyType.filter((item) => item.name === data.type)[0];
    // console.log(selectedType);
    const dataToSubmit = {
      id_property: property_id,
      id_user: userCurrent.id,
      id_patrimony_grid: 1,
      id_patrimony_type: selectedType?.id,
      patrimony_group_id: selectedGroup?.id,
      name: data.name,
      patrimony_group: data.group,
      model: data.model,
      patrimony_type: data.type,
      manufacturer: data.manufacturer,
      manufacturing_year: data.manufacturing_year || 0,
      lifespan: data.lifespan || 0,
      date_buy: data.date_prev_end, // date_prev_end -> date_buy
      value: data.value === '0,00' ? 0 : data.value,
      horimetro_current: data.horimetro_current,
      observation: data.observation,
      image: data.image,
    };
    // console.log(data);
    // console.log(dataToSubmit);

    let formOk = validate(dataToSubmit);

    if (formOk[0]) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post('patrimony/', dataToSubmit, header)
        .then((response) => {
          // console.log('response.data');
          // console.log(response.data);
          if (response.data.isSuccess) {
            // console.log(response.data._value.insertId);
            const id = response.data._value.insertId;
            uploadImage(id);
            toast.success('Patrimônio cadastrado com sucesso!');
            // handleClose();

            // setUpdateComponent((prevCounter) => prevCounter + 1);
            reposPatrimony.fetch({ id_farm: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar patrimônio, tente novamente mais tarde!');
        });
    } else {
      toast.error(formOk[1]);
    }
  };

  async function uploadImage(id) {
    // console.log('uploadImage');
    // toast.error(`test ${id}`);
    const fd = new FormData();
    fd.append('image', data.file, `${data.name}.png`);
    //const tipo_item = 'area';
    fd.append('item_type', 'patrimony' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', property_id || 1);

    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        // toast.success(response.data.isSuccess);
        usestore.reposImages.fetch({ id_farm: property_id }).then((res) => {
          reposPatrimony.fetch({ id_farm: property_id }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
              // if (container) {
              //   container();
              // } else {
              //   setIsLoading(true);
              // }
            }, 200);
          });

          setTimeout(() => {
            setAuxAdd(aux_add + 1);
          }, 200);
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
    }
  }

  useEffect(() => {
    setSelectedGroup(reposPatrimony?.patrimonyGroup[4]);
  }, []);

  // imagem
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageDecode, setImageDecode] = useState(null);

  const handleImageUpload = (base64Image) => {
    setLoadingImage(true); // Inicia o indicador de carregamento
    setData((prevData) => ({
      ...prevData,
      image: base64Image,
    }));
    setImageDecode(base64Image);
    setTimeout(() => {
      setLoadingImage(false); // Finaliza o indicador de carregamento após o processamento da imagem
    }, 1000);
  };

  const handleDeleteImage = () => {
    setImageDecode(null); // Isso remove a imagem do estado
  };

  return (
    <>
      <Button sx={{ marginRight: 2, animation: `${expandAndFade} 2s linear infinite` }} variant="contained" startIcon={<HomeWork />} color="success" onClick={handleClickOpen}>
        Novo Patrimônio
      </Button>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle>Registar Novo Patrimônio</DialogTitle>
        <DialogContent>
          <div>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  color="success"
                  variant="standard"
                  label="Nome do patrimônio"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <TitleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="group"
                  name="group"
                  label="Grupo"
                  select
                  required
                  fullWidth
                  value={selectedGroup?.id || ''}
                  onChange={(e) => handleChangeGroup(e)}
                  //onBlur={(e) => handleChange(e, true)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <IconButton>
                  //         <AccountBalance />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                  variant="outlined"
                  color="success"
                  helperText="Defina o grupo do patrimônio"
                >
                  <MenuItem
                    sx={{
                      backgroundColor: 'green', // Define a cor de fundo
                      color: 'white', // Define a cor do texto
                      '&:hover': {
                        backgroundColor: 'darkgreen', // Cor de fundo no hover (opcional)
                      },
                    }}
                    onClick={() => toggleDrawer(true)}
                  >
                    Incluir Novo Grupo de Patrimônio
                    <AddHomeWorkIcon style={{ marginLeft: 'auto' }} />
                  </MenuItem>
                  <Divider />
                  <Typography sx={{ ml: 1, color: 'gray' }} variant="subtitle2">
                    Selecine o tipo de patrimônio
                  </Typography>
                  {patrimonyGroup.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {getIconComponent(group.icon)}
                      {group.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <RegisterGroupPatrimony
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // patrimonyGroup={data.group}
                setUpdateCounter={setUpdateCounter}
              />

              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <TextField
                  variant="standard"
                  fullWidth
                  color="success"
                  label="Modelo"
                  name="model"
                  id="model"
                  value={data.model}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <AppsIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {data.group === '' ? (
                  <TextField label="Tipo de patrimônio" select required fullWidth variant="outlined" helperText="Selecione o grupo do patrimônio primeiro" disabled></TextField>
                ) : (
                  <TextField
                    InputLabelProps={{
                      style: { color: '#2e7d32' },
                    }}
                    id="type"
                    name="type"
                    label={`Tipo de ${data.group}`}
                    select
                    required
                    fullWidth
                    color="success"
                    value={data.type}
                    onChange={handleChange}
                    //onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            {/* TODO: MOSTRAR ICONE DA CATEGORIA */}
                            <CheckBoxOutlineBlankIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    helperText="Defina o tipo do patrimônio"
                  >
                    <MenuItem
                      style={{
                        backgroundColor: 'green', // Define a cor de fundo
                        color: 'white', // Define a cor do texto
                      }}
                      onClick={() => toggleDrawerType(true)}
                    >
                      Incluir Novo Tipo de Patrimônio
                      <AddHomeWorkIcon style={{ marginLeft: 'auto' }} />
                    </MenuItem>
                    <Divider />
                    <Typography sx={{ ml: 1, color: 'gray' }} variant="subtitle2">
                      Selecine o tipo de patrimônio
                    </Typography>
                    {filteredTypes.map((type) => (
                      <MenuItem key={type.name_nf} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>

              <RegisterTypePatrimony isOpenType={isOpenType} setIsOpenType={setIsOpenType} patrimonyGroupName={data.group} patrimonyGroupID={selectedGroup.id} setUpdateCounter={setUpdateCounter} />

              <Grid item xs={12} sm={data.group === 'Maquinário' ? 4 : 6} sx={{ mt: 3 }}>
                {/* <TextField
                  color="success"
                  name="date_prev_end"
                  label="Data da Aquisição"
                  type="date"
                  required
                  fullWidth
                  value={formatDate(data.date_prev_end)}
                  onChange={handleChange}
                /> */}
                <SimpleDateProvider onChangeDate={handleDateChange} label={'Data do registro'} />
              </Grid>
              <Grid item xs={12} sm={data.group === 'Maquinário' ? 4 : 6} sx={{ mt: 3 }}>
                {/* <CurrencyInputReal required id="value" name="value" label="Valor" color="success" value={data.value} onChange={handleChangeValue} helperText="Valor de compra do patrimônio" /> */}
                {/* <CurrencyTextField
                    required
                    id='value'
                    name='value'
                    label='Valor'
                    color='success'
                    value={data.value}
                    onChange={handleChangeValue}
                    helperText='Valor de compra do patrimônio'
                /> */}
                <GenericValueField
                  handleChange={handleChangeValue}
                  options={{
                    required: true,
                    id:"value",
                    name:"value",
                    label: "Valor",
                    value: data.value,
                    variant: 'standard',
                    height: '36px',
                    helperText: "Valor de compra do patrimônio",
                    endAdornment: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
                {data.group === 'Maquinário' && (
                  // <FormControl color="success" fullWidth sx={{ m: 1 }} variant="standard">
                  //   <InputLabel color="success" htmlFor="horimetro_current">
                  //     Horímetro atual (h)
                  //   </InputLabel>
                  //   <Input
                  //     id="horimetro_current"
                  //     name="horimetro_current"
                  //     value={data.horimetro_current}
                  //     onChange={handleChange}
                  //     startAdornment={<InputAdornment position="start">(h)</InputAdornment>}
                  //   />
                  // </FormControl>
                  <GenericValueField
                    handleChange={handleChange}
                    options={{
                      id:"horimetro_current",
                      name:"horimetro_current",
                      label: "Horímetro atual",
                      value: data.horimetro_current,
                      variant: 'standard',
                      height: '36px',
                      helperText: "Total de horas que o maquinário trabalhou",
                      endAdornment: true,
                      prefix: 'H',
                    }}
                  />
                )}
              </Grid>

              <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Foto</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          {!imageDecode ? (
                            <ImageUploadComponent onImageUpload={handleImageUpload} maxWidthOrHeight={'150'} setData={setData} />
                          ) : (
                            <Card>
                              <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                                <CardHeader
                                  avatar={<Avatar aria-label="image" src={`data:image/jpeg;base64,${imageDecode}`}></Avatar>}
                                  title={data?.name || ''}
                                  subheader={data?.group || ''}
                                  action={
                                    <IconButton onClick={handleDeleteImage} aria-label="delete image">
                                      <DeleteForeverIcon />{' '}
                                    </IconButton>
                                  }
                                />
                              </Box>
                            </Card>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Informações complementares para cálculo da depreciação</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            color="success"
                            variant="standard"
                            label="Fabricante (opicional)"
                            name="manufacturer"
                            id="manufacturer"
                            value={data.manufacturer}
                            onChange={handleChange}
                            //onBlur={(e) => handleChange(e)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <BusinessIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            //error={!data.name || data.name.length < 5}
                            //helperText={<>Identificador da conta para facilitar sua busca de informações.{!data.name || data.name.length < 5 ? (<><br /><span>* Mínimo de 5 caracteres</span></>) : ''}</>}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            color="success"
                            variant="standard"
                            label="Ano de fabricação"
                            name="manufacturing_year"
                            id="manufacturing_year"
                            value={data.manufacturing_year}
                            onChange={handleChange}
                            //onBlur={(e) => handleChange(e)}
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <CalendarMonthIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              inputProps: {
                                inputMode: 'numeric',
                                pattern: "[0-9]*",
                                min: 1900,
                                max: 9999,
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            color="success"
                            variant="standard"
                            label="Vida útil em anos"
                            name="lifespan"
                            id="lifespan"
                            value={data.lifespan}
                            onChange={handleChange}
                            //onBlur={(e) => handleChange(e)}
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <ScheduleIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              inputProps: {
                                inputMode: 'numeric',
                                pattern: "[0-9]*",
                                max: 9999,
                              }
                            }}
                            //error={!data.name || data.name.length < 5}
                            helperText={<>A vida útil é o período estimado, em anos, durante o qual o patrimônio permanece funcional e produtivo antes de necessitar substituição.</>}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField variant="standard" fullWidth color="success" label="Observação" name="observation" value={data.observation} onChange={handleChange} />
              </Grid>
            </Grid>
          </div>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          ></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="success" sx={{ marginRight: 2 }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
