import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { downloading } from './archive/index'
import { useEffect } from 'react';

const DownloadAnimation = () => {
    const [animationOptions, setAnimationOptions] = useState({});

    useEffect(() => {
        setAnimationOptions({
            animationData: downloading,
            loop: false,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            },
            // Controla a velocidade da animação
            // Quanto menor o valor, mais lenta será a animação
            speed: 0.5
        });
    }, []);

    return (
        <div>
            <Lottie options={animationOptions} height={200} width={200} />
        </div>
    );
};

export default DownloadAnimation;
