import React from 'react';
import Typography from '@mui/material/Typography';
import { Checkmark } from 'react-checkmark';
import { AppBar, Box, Button, ButtonBase, Container, Paper, Stack, Toolbar } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function SuccessInput({ handleClose, data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/activities/${data.id_property}/${data.id_livestock}`);
    handleClose();
  };

  const dataEnd = format(new Date(data.date_prev_exit), 'dd/MM/yyyy');

  let text =
    data.progress === 'CONCLUÍDA' ? 'Acesse a tela de Atividades de Campo e monitore seus progressos!' : `Entrada defina para: ${dataEnd}. Lembre-se de modificar o progresso na tela ATIVIDADES.`;
  return (
    <>
      <Container component="main" maxWidth="">
        <Paper variant="" sx={{ my: { xs: 3, md: 3 }, p: { xs: 0, md: 0 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Pronto! Atividade de entrada dos animais realizada.
            </Typography>

            <div className="d-lg-none">
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 1,
                  display: 'flex', // Ativa o layout flex
                  justifyContent: 'center', // Centraliza horizontalmente
                  alignItems: 'center', // Centraliza verticalmente
                }}
              >
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={200} text={text} />
                </Typography>
              </Box>
            </div>
            <div className="d-none d-lg-block">
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 1,
                  display: 'flex', // Ativa o layout flex
                  justifyContent: 'center', // Centraliza horizontalmente
                  alignItems: 'center', // Centraliza verticalmente
                }}
              >
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={50} text={text} />
                </Typography>
              </Box>
            </div>
            <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => handleButtonClick()} className={classes.button}>
              <Stack direction="column" alignItems="center">
                <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" alt="image" class="img-fluid" />
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  ATIVIDADES
                </Typography>
              </Stack>
            </ButtonBase>
            {/* <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Acesse a tela de Atividades de Campo e monitore seus progressos!
            </Typography> */}
          </div>
          {/* <TutorialNextStep /> */}
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>

            <Button color="success" variant="contained" type="submit" >
              <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
            </Button>

          </Box> */}
        </Toolbar>
      </AppBar>
      {/* <Container component="main" maxWidth="sm" >
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <div className="sucess">
            <Checkmark size={'196'} />
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }} >
              Pronto! Atividade realizada com sucesso.
            </Typography>
             <Typography style={{ color: '#2e7d32' }}>
              <Typewriter tam={60} text=" Acessando o mapa, você poderá verificar que temos os números de animais para entrarem na propriedade." />
            </Typography> 

            <ListItem
              key={null}
              disableGutters
              secondaryAction={
                <sapn aria-label="comment">
                  <ShareLocationIcon style={{ color: "#2e7d32" }} />
                </sapn>
              }
            >
              <ListItemText primary={`Acesse o mapa para gerenciar a sua propriedade ou clique em fechar para inserir uma nova atividade.`} />

            </ListItem>

            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{ p: 2, borderRadius: 1 }}
              onClick={() => handleButtonClick()}
              className={classes.button}

            >
              <Stack direction="column" alignItems="center">
                <img
                  style={{ maxHeight: 100 }}
                  src="/dist/image/entrada.png"
                  alt="image"
                  class="img-fluid"
                />
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  Mapa
                </Typography>
              </Stack>
            </ButtonBase>
       
          </div>

        </Paper>
      </Container>



      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar> */}
    </>
  );
}
