import React from "react";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ChatContent from "./ChatContent";

export default function Chat() {
  return (
    <>
      <NavSide />
      <Nav />
      <ChatContent />
      <NavBotton />
    </>
  );
}
