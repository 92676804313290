import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField, Button } from '@mui/material';
import { useEffect } from 'react';

function ccyFormat(num) {
  const value = Number(num);
  if (isNaN(value)) {
    return 'R$ 0,00';
  }
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

function createRow(desc, qtd, price, value, unit) {
  return { desc, qtd, value, price, unit };
}

function subtotal(items) {
  return items.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
}

function quantityTotal(items) {
  return items.map(({ qtd }) => qtd).reduce((sum, i) => sum + i, 0);
}

export default function ExpensesCostCalculator({ data, obj_fn }) {
  console.log(data);
  function getNameAccount(id_acc) {
    if (!id_acc) {
      return '';
    }
    const account = obj_fn.usestore.reposAccount.getAccountStore('id', id_acc)[0];
    return account.name;
  }

  const [valueTotal, setInvoiceTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  // Safeguard to ensure data and resources exist
  const resources = data?.resources || {};

  // Generating product rows with fallback if resources or products are undefined
  const productsRows = resources.products
    ? resources.products.map((product) =>
        createRow(
          product.product.name,
          parseFloat(product.location_selected?.quant_utilizada || 0),
          parseFloat(product.product?.stock_cost || 0),
          parseFloat(product.location_selected?.value || 0),
          product.product?.unit_sigla || ''
        )
      )
    : [];

  // Generating expense rows
  const expensesRows = resources.expenses ? resources.expenses.map((expense) => createRow(expense.description, 1, parseFloat(expense.value), parseFloat(expense.value), '')) : [];

  // Calculating subtotal and total quantity
  const invoiceSubtotal = subtotal([...productsRows, ...expensesRows]);
  const totalProductsAndServicesQuantity = quantityTotal([...productsRows, ...expensesRows]);

  useEffect(() => {
    setInvoiceTotal(invoiceSubtotal);
    setTotalQuantity(totalProductsAndServicesQuantity);
  }, [invoiceSubtotal, totalProductsAndServicesQuantity]);

  // Updating the parent object state
  useEffect(() => {
    obj_fn.setData((prevState) => {
      if (prevState.value !== valueTotal || prevState.totalQuantity !== totalQuantity) {
        return {
          ...prevState,
          value: valueTotal,
          value_end: valueTotal,
          totalQuantity: totalQuantity,
        };
      }
      return prevState;
    });
  }, [valueTotal, totalQuantity]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          {/* <TableRow>
            <TableCell align="center" colSpan={3}>
              Detalhes
            </TableCell>
            <TableCell align="right">Preço</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>
              <strong>Descrição</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Qtd.</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Valor Unitário</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Total</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...productsRows, ...expensesRows].map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <strong style={{ color: '#2e7d32' }}>{row.desc || ''}</strong>
              </TableCell>
              <TableCell align="right">{(row.qtd || '0') + (!row.unit ? '' : ' (' + row.unit + ')')}</TableCell>
              <TableCell align="right">{ccyFormat(row.price || 0)}</TableCell>
              <TableCell align="right">{ccyFormat(row.value)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">
              <strong style={{ color: '#2e7d32' }}>{ccyFormat(valueTotal)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
