import * as React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     width: '100%',
//     height: '100%',
//   },
// }));

export default function CreateDrawPropertyList() {
  // const classes = useStyles();
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   navigate('/areasmaps');
  // };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', mt: -2 }}>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 1:</strong>} secondary="Clique no botão verde CRIAR PROJETO, no canto superior direito da tela." />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 2:</strong>} secondary="Defina qual qual tipo de criação deseja gerenciar." />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/escolhacriações.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 3:</strong>} secondary="Defina um nome para o seu projeto pecuário." />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 4:</strong>} secondary="Depois desenhe e selecione a área que o lote de animais se econtram." />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/area-2.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <CropOriginalIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={<strong>Passo 5:</strong>} secondary="Por fim, basta conferir os dados e salvar as informações do projeto pecuário." />
        </ListItem>
      </Box>
    </List>
  );
}
