import React, { useState } from 'react'
import { Box, Button, Card, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { Handyman, HighlightOff, Queue } from '@mui/icons-material'
// import CurrencyTextField from '../../../../components/Fields/CurrencyTextField'
import FormAccountSelection from '../../../financial/components/Forms/FormAccountSelection'
import AutocompletGenericList from '../../../../components/Fields/AutocompletGenericList'
import GenericValueField from '../../../../components/Fields/GenericValueField'

export default function CardServicePatrimony({ data, obj_fn }) {
  // console.log('CardServicePatrimony')
  // Inicializar data.services como um array vazio se não estiver definido
  const services = data.services || []

  // Monta o array e obj para o campo de cliente ou fornecedor
  let aux_f = obj_fn.usestore.reposPerson.listFornecedor
  let aux_t = obj_fn.usestore.reposPerson.listTransportadora
  const optionsPerson = [...aux_f, ...aux_t]

  const opt_person = {
    multiple: false,
    name: 'supplier',
    label: 'Fornecedor',
    values: optionsPerson,
    field: 'supplier',
    helperText: 'Selecione um fornecedor para a manutenção',
    listHeight: 225,
  }

  const handleChangeSelect = (field, newValue, index) => {
    // console.log('handleChangeSelect')
    obj_fn.setData((prevState) => {
      const newServices = [...prevState.services]
      if (field === 'supplier') {
        newServices[index] = {
          ...newServices[index],
          person: newValue,
          [field]: newValue?.id,
        }
      } else {
        newServices[index] = {
          ...newServices[index],
          [field]: newValue,
        }
      }
      return { ...prevState, services: newServices }
    })
  }

  const handleChangePay = (event, index) => {
    obj_fn.setData((prevState) => {
      const newServices = [...prevState.services]
      newServices[index] = {
        ...newServices[index],
        [event.target.name]: event.target.value,
      }
      if (event.target.name === 'value') {
        newServices[index].value_end = parseFloat(event.target.value)
      }
      return { ...prevState, services: newServices }
    })
  }

  const handleAdd = () => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      services: [
        ...(prevState.services || []),
        {
          id: (prevState.services || []).length + 1,
          person: null,
          category: '',
          subcategory: '',
          descriptionEntry: '',
          value: '',
          value_end: '',
        },
      ],
    }))
  }

  const handleRmv = (id) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      services: prevState.services.filter((service) => service.id !== id),
    }))
  }

  // const para validar o button continuar
  const isError = () => {
    let iserror = false
    if (!data?.name) {
      iserror = true
    }
    return iserror
  }

  return (
    <>
      {services.map((service, index) => (
        <Card sx={{ p: 3, mt: 2 }} key={service.id}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Box sx={{ ml: 3, display: 'flex' }}>
                  <Handyman />
                  <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                    <Typography fontWeight={700}>Manutenção {index + 1}</Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton variant="contained" color="error" onClick={() => handleRmv(service.id)} title="Remover">
                  <HighlightOff />
                </IconButton>
              </Box>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} sx={{ mt: '0px' }}>
                <AutocompletGenericList
                  value={service.person}
                  options={opt_person}
                  handleChange={(field, newValue) => handleChangeSelect(field, newValue, index)}
                  label={'Fornecedor'}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: '25px' }}>
                <TextField
                  fullWidth
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="descriptionEntry"
                  name="descriptionEntry"
                  label="Descrição"
                  value={service.descriptionEntry}
                  onChange={(event) => handleChangePay(event, index)}
                  variant="outlined"
                  color="success"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{''}</InputAdornment>,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: '25px' }}>
                {/* <CurrencyTextField
                  required
                  id="value"
                  name="value"
                  label="Valor Total"
                  color="success"
                  value={service.value}
                  onChange={(event) => handleChangePay(event, index)}
                  helperText="Valor total do Lançamento"
                /> */}
                <GenericValueField
                  handleChange={(event) => handleChangePay(event, index)}
                  options={{
                    required: true,
                    id: "value",
                    name: "value",
                    label: 'Valor Total',
                    value: service.value,
                    variant: 'outlined',
                    helperText: "Valor total do Lançamento",
                    endAdornment: true,
                    prefix: " (R$) ",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ ml: 3, mt: 2 }}>
                <FormAccountSelection data={service} obj_fn={obj_fn} handleChange={(event) => handleChangePay(event, index)} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="success" onClick={handleAdd} disabled={isError()}>
            <Queue sx={{ mr: 1 }} /> Adicionar manutenção
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
