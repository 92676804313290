import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardMedia, Grid, IconButton, Skeleton, Slide } from '@mui/material';
import { Image, PhotoCamera } from '@mui/icons-material';
import MenuDeleteImage from './MenuDeleteImage';
import UploadDialog from '../../components/Image/UploadDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardHeard({ image_livestok, item, callBack, callBackHook, handleDelete }) {
  // console.log('CardHeard');
  // console.log(image_livestok);
  const display_demo = JSON.parse(localStorage.getItem('display'));
  const [loadingTable, setLoadingTable] = useState(true);

  // console.log('CardHeard');
  // console.log(item);
  // console.log(callBack);
  // console.log(callBackHook);

  setTimeout(() => {
    setLoadingTable(false);
  }, 500);

  useEffect(() => {
    setOpen(false)
  }, [image_livestok]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /// estado imageLoaded para controlar se a imagem do link foi carregada
  //o evento onLoad na CardMedia para definir quando a imagem foi carregada
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {loadingTable ? (
        <Skeleton variant="rectangular" height={480} />
      ) : (
        <Grid container justifyContent="center">
          <Card>
            <CardMedia
              component="img"
              //height="300"
              //style={{ width: "100%" }} height="100%"
              image={image_livestok}
              alt="Criação"
              onLoad={handleImageLoad}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
            <CardActions className="no-print">
              <Image sx={{ color: 'action.active', mr: 0.5 }} />
              <span size="small" color="primary">
                {/* <Upload item={item} callBack={callBack} callBackHook={callBackHook} /> */}
                {/* <UploadFunction item={item} callBack={callBack} callBackHook={callBackHook} /> */}
                <IconButton variant="contained" color="primary" onClick={handleOpen}>
                  <PhotoCamera color="success" />
                </IconButton>
                <UploadDialog open={open} TransitionComponent={Transition} handleClose={handleClose} item={item} callBack={callBack} callBackHook={callBackHook} />
              </span>
              {/* <IconButton sx={{ mr: 0.5, ml: "auto", }} title="Deletar imagem" aria-label="delete" onClick={() => handleDelete()}>
                        <DeleteSweepIcon /> <Typography sx={{ mt: 1, }} gutterBottom variant="subtitle1" component="div"> Deletar Imagem</Typography>
                    </IconButton> */}
              {display_demo ? <div></div> : <MenuDeleteImage handleDelete={handleDelete} />}
            </CardActions>
          </Card>
        </Grid>
      )}
    </>
  );
}
