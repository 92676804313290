import React, { useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, Box, Grid, Typography, Chip, Stack } from '@mui/material'
import FormIdentifyHeader from './FormIdentifyHeader'
import FormIdentifyFooter from './FormIdentifyFooter'
import ToolipBoostrap from '../../../../../components/Tooltip/ToolipBoostrap'
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner'
import Typewriter from '../../../../../components/Typewriter/Typewriter'
import FormIdentification2Dates from './FormIdentification2Dates'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'

const stage = [
  { id: 1, label: 'Hoje' },
  { id: 2, label: 'Foi realizada' },
  { id: 3, label: 'Será realizada' },
]

const stageProgress = [
  { id: 1, label: 'A FAZER' },
  { id: 2, label: 'EM PROGRESSO' },
  { id: 3, label: 'CONCLUÍDA' },
]

export default function FormIdentification2({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false)
  setTimeout(() => {
    setShowTextFase(true)
  }, 500)
  const [showSubText, setShowSubText] = useState(false)

  // const para validar o button continuar
  const isError = () => {
    return !data.date_start || !data.date_prev_end || !data.stageData
  }
  // valida o botao retornar
  const returnError = () => {
    return false
  }

  const handlePeriodoChange = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, stageData: event.target.value }))
  }

  const [selectedProgress, setSelectedProgress] = useState(data.progress)
  const handleProgressChange = (progressLabel) => {
    setSelectedProgress(progressLabel)
    obj_fn.setData((prevState) => ({ ...prevState, progress: progressLabel }))
  }

  const dates = { start: '', end: '', min: '', max: '' }

  const getPeriodoContent = (step) => {
    dates.show = false
    setTimeout(() => {
      setShowSubText(true)
    }, 500)
    switch (step) {
      case '1':
        if (data.progress !== selectedProgress) {
          obj_fn.setData((prevState) => ({ ...prevState, progress: selectedProgress }))
        }
        return (
          <Stack direction="row" spacing={1}>
            {stageProgress.map((progress) => (
              <Chip key={progress.id} label={progress.label} color={selectedProgress === progress.label ? 'success' : 'default'} onClick={() => handleProgressChange(progress.label)} clickable />
            ))}
          </Stack>
        )
      case '2':
        if (data.progress !== 'CONCLUÍDA') {
          obj_fn.setData((prevState) => ({ ...prevState, progress: 'CONCLUÍDA' }))
        }
        dates.start = dayjs().subtract(3, 'day')
        dates.end = new Date()
        dates.min_i = ''
        dates.max_e = new Date()
        dates.show = true
        dates.stageData = data.stageData
        return <FormIdentification2Dates data={data} obj_fn={obj_fn} dates={dates} />
      case '3':
        if (data.progress !== 'A FAZER') {
          obj_fn.setData((prevState) => ({ ...prevState, progress: 'A FAZER' }))
        }
        dates.start = new Date()
        dates.end = dayjs().add(3, 'day')
        dates.min_i = new Date()
        dates.max_e = ''
        dates.show = true
        dates.stageData = data.stageData
        return <FormIdentification2Dates data={data} obj_fn={obj_fn} dates={dates} />
      default:
        return 'Não existente'
    }
  }

  // FormIdentifyHeader
  const text_title = 'Passo 2: Data de realização.'
  const txt_msn = 'Defina a data de realização desta atividade!'

  // corpo da página
  const subText = {
    1: 'Com a data de hoje registrada, precisamos definir o progresso da atividade.',
    2: 'A partir da data de hoje, registramos 3 dias retroativos como realização desta atividade.\nSe preferir, é possível personalizar.',
    3: 'A partir da data de hoje, registramos 3 dias para realização desta atividade.\nSe preferir, é possível personalizar.',
  }
  const longText =
    'Definir prazos é o caminho que direciona suas ações rumo ao sucesso na atividade pecuária, permitindo que você alcance metas, otimize recursos e garanta o progresso sustentável do seu rebanho. Com prazos definidos, você poderá monitorar o progresso da atividade.'
  const text = 'Importância de definir prazo para atividade.'

  return (
    <>
      <FormIdentifyHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} />

      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <RadioGroup aria-label="periodo_execucao" name="periodo_execucao" value={data.stageData} onChange={handlePeriodoChange} style={{ display: 'flex', flexDirection: 'row' }}>
              {stage.map((fase) => (
                <FormControlLabel key={fase.id} value={fase.id.toString()} name="stageData" control={<Radio color="success" />} label={fase.label} />
              ))}
            </RadioGroup>
          </Grid>

          {!data.stageData ? (
            <></>
          ) : (
            <Grid container spacing={2}>
              <Grid container spacing={1} sx={{ mt: 4 }}>
                <div className="d-none d-lg-block">
                  <Grid container>
                    <Grid item xs={12} md={12} alignItems="center" sx={{ height: 60 }}>
                      {showSubText ? (
                        <>
                          <Typography style={{ color: '#2e7d32' }}>
                            <Typewriter tam={60} text={subText[data.stageData]} />
                          </Typography>
                        </>
                      ) : (
                        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                          {' '}
                          <ThreeDotsSpinner />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="d-lg-none">
                  <Grid container>
                    <Grid item xs={12} md={12} alignItems="center" sx={{ height: 60 }}>
                      {showSubText ? (
                        <>
                          <Typography style={{ color: '#2e7d32' }}>
                            <Typewriter tam={60} text={subText[data.stageData]} />
                          </Typography>
                        </>
                      ) : (
                        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                          {' '}
                          <ThreeDotsSpinner />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {getPeriodoContent(data.stageData)}
            </Grid>
          )}

          <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
            <ToolipBoostrap text={text} longText={longText} />
          </Grid>
        </Grid>
      </Grid>

      <FormIdentifyFooter
        data={data}
        setData={obj_fn.setData}
        handleNext={obj_fn.handleNext}
        handleBack={obj_fn.handleBack}
        activeStep={obj_fn.activeStep}
        steps={obj_fn.steps}
        isError={isError}
        handleClose={obj_fn.handleClose}
        returnError={returnError}
      />
    </>
  )
}
