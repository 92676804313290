import * as React from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useParams } from "react-router-dom";
import useStore from "../../../../../store/useStore";

const stage = [
    "Cria",
    "Recria",
    "Engorda",
    "Reprodução",
    "Produção",
    // "Completo",
];

export default function FormInputStep1NumAnimalRadioButton({ data, setData }) {
    // const [selectedFase, setSelectedFase] = useState("");
    const usestore = useStore();
    const { id } = useParams();

    const handleFaseChange = (event) => {
        const listActivitiesInputs = usestore.reposActivityInput.listActivityInput;
        // console.log(listActivitiesInputs)

        const listActivitiesInputs_id_livestock = listActivitiesInputs.filter((item) => item.id_livestock == id)
        // console.log(listActivitiesInputs_id_livestock.length)

        let numberBatch_id_livestock = listActivitiesInputs_id_livestock.length + 1
        //let numberBatch = listActivitiesInputs.length + 1

        let contador = numberBatch_id_livestock

        setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
        // setData(prevState => ({ ...prevState, "nameBatch": `Lote ${event.target.value} ${abbreviation}${lastTwoDigits} ${contador}` }));
        setData(prevState => ({ ...prevState, "nameBatch": `Lote ${event.target.value} ${contador}` }));
    };

    const faseStyle = (fase) => ({
        backgroundColor: data.stage === fase ? "#5cb85c" : "#fff",
        color: data.stage === fase ? "#fff" : "#333",
        marginRight: "5px",
        borderRadius: "5px",
        padding: "2px",
        //padding: "10px",
        cursor: "pointer",
    });

    return (
        <RadioGroup
            aria-label="fases"
            name="fases"
            value={data.stage}
            onChange={handleFaseChange}
            style={{ display: "flex", flexDirection: "row" }}
        >
            {stage.map((fase) => (
                <FormControlLabel
                    key={fase}
                    value={fase}
                    name="stage"
                    control={<Radio color="success" />}
                    label={
                        <div style={faseStyle(fase)}>
                            {/* {fase === "cria" && <Pets fontSize="large" />}
                            {fase === "recria" && <Category fontSize="large" />}
                            {fase === "recria/engorda" && <AddBox fontSize="large" />}
                            {fase === "engorda/terminação" && <LocalDrink fontSize="large" />}
                            {fase === "produção/reprodução" && <PregnantWoman fontSize="large" />}
                            {fase === "reprodução" && <LocalMall fontSize="large" />}
                            {fase === "produção" && <ProductionQuantityLimits fontSize="large" />}
                            {fase === "completo" && <DoneAll fontSize="large" />} */}
                            {` ${fase.charAt(0).toUpperCase() + fase.slice(1)}`}
                        </div>
                    }
                />
            ))}
        </RadioGroup>
    );
}
