import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ChecklistRtl, Rule } from '@mui/icons-material';
import ExcelExportListLivestock from './ExcelExportListLivestock';
import FullDialogCardLote from './FullDialogCardLote';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';

// function createData(batch, male, female, input, weigthtTotal, weigthtMedium, breed, gmd, area) {
//   return { batch, male, female, input, weigthtTotal, weigthtMedium, breed, gmd, area };
// }

const LivestockListBathTable = ({ livestock }) => {
  const [rows, setRows] = useState(livestock.lotes);

  //importação
  // const handleImport = (data) => {
  //   const importedRows = data.map((row) => createData(...row));
  //   setRows(importedRows);
  // };

  const getTableCellNI = (data) => {
    let style_icon = {
      padding: '2px',
      width: '30px',
      height: '30px',
      border: 'solid 0.5px',
      marginRight: '3px',
    }

    if (data.num_animais_ni > 0) {
      style_icon.color = "#c62828";
      return (
        <TableCell align="left" title='Ainda há animais para identificar'><Rule style={style_icon} />{data.num_animais_ni}</TableCell>
      )
    } else {
      style_icon.color = "#2e7d32";
      return (
        <TableCell align="left" title='Todos os animais foram identificados'><ChecklistRtl style={style_icon} /></TableCell>
      )
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <DownloadButton filename="PlanilhaExemplo.xlsx" fileUrl="https://example.com/planilha.xlsx" /> */}
        {/* <DownloadButton filename="PlanilhaExemplo.xlsx" /> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: 'auto' }}>
          {/* <ExcelImport onImport={handleImport} /> */}
          {/* <ExcelExport data={rows} headers={['name', 'calories', 'fat', 'carbs', 'protein']} filename="data" /> */}
          {/* headers={["IDENIFICAÇÃO", "NOME", "SEXO", "DATA DE NASCIMENTO", "ID BRINCO", "ID SISBOV", "PESO", "LOTE", "RAÇA", "IDADE EM MESES"]} */}
          <ExcelExportListLivestock data={rows} filename="Planilha_FazendaPremium_Criacao" />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}> </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">LOTE</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">TOTAL ANIMAIS</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" title='Animais Não Identificados'>N.I.</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">FEMEAS</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">MACHOS</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">ENTRADA NA ÁREA</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">PESO TOTAL</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">PESO MÉDIO</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                <Typography sx={{ animation: `${expandAndFade} 2s linear infinite` }}> <strong>Gmd médio</strong> </Typography>
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">ÁREA</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">AÇÕES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length == 0 ? (
              <TableRow key={"emptyRows"} style={{ height: 53 }} >
                <TableCell colSpan={12} > . . . Nenhum Lote foi adicionado . . . </TableCell>
              </TableRow> ) :
            rows.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">{row.icon_lote}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.num_animais}</TableCell>
                {getTableCellNI(row)}
                <TableCell align="left">{row.num_femeas}</TableCell>
                <TableCell align="left">{row.num_machos}</TableCell>
                <TableCell align="left">{row.date_created_f}</TableCell>
                <TableCell align="left">{row.weight_total > 0 ? row.weight_total + " (" + row.weight_unit + ")" : '-'}</TableCell>
                <TableCell align="left">{row.weight > 0 ? row.weight + " (" + row.weight_unit + ")" : '-'}</TableCell>
                <TableCell align="left">{row.gmd || 0}</TableCell>
                <TableCell align="left">{row.name_area}</TableCell>
                <TableCell align="left"><FullDialogCardLote lote={row}/></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LivestockListBathTable;
