import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import * as XLSX from 'xlsx';

export default function ExcelImport({ onImport }) {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryData = event.target.result;
            const workbook = XLSX.read(binaryData, { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            onImport(jsonData);
        };

        reader.readAsBinaryString(file);
    };

    return (

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 3 }}>
            <Button color="success" variant="contained" component="label">
                Importar
                <input type="file" accept=".xlsx" onChange={handleFileChange} style={{ display: 'none' }} />
            </Button>
        </Box>
    );
}
