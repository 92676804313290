import { useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Stepper, Step, StepLabel, Grid, FormHelperText, Button } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import AccountDetails from './AccountDetails';
import ReviewInfo from './ReviewInfo';
import SucessInfo from './SucessInfo';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import hexSha1 from "hex-sha1";
import { Span } from '../../../../../components/Typography';
import api from '../../../../../services/api';
import { Context } from '../../../../../components/Context/AuthContext';
import useStore from '../../../../../store/useStore';

// definição de quantos passos terá
const steps = [' Account Details', 'Personal Info', 'Review and Submit', 'SucessInfo'];

const StepperForm = ({ handleClose, setLoading }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const formik = useFormik({
    initialValues: {
      farm: [],
      farm_id: '',
      email: '',
      phone: '',
      password: '',
      //confirmPassword: '',
      name: '',
      id_role: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Nome é um campo obrigatório'),
      email: Yup.string().required('Email é um campo obrigatório').email('Email inválido'),
      password: Yup.string().min(6).required('Senha é um campo obrigatório'),
      farm: activeStep > 0 && Yup.array().min(1, 'Propriedade é um campo obrigatório').required('Propriedade é um campo obrigatório'),
      id_role: activeStep > 0 && Yup.string().required('Selecione um perfil para o novo membro da equipe'),
      //firstName: Yup.string().required('Nome é um campo obrigatório')
      // confirmPassword: Yup.string()
      //   .min(8)
      //   .oneOf([Yup.ref('password')], 'Passwords do not match'),
      //firstName: Yup.string().required('First Name is required'),
      // lastName: Yup.string()
      //   .required('Last Name is required'),
    }),
    onSubmit: (data) => {
      console.log('onSubmit')
      console.log(data)
      if (activeStep === steps.length - 1) {
        console.log(steps.length)
        console.log('last step');
      } else {
        console.log('activeStep')
        console.log(activeStep)
        if (activeStep === 2) {
          console.log('enviar')
          handleSubmitform();
        } else {
          setActiveStep((prevStep) => prevStep + 1);
        }
      }
      //console.log('xxxxxxxxxxxxonSubmit')
      //handleSubmitform()
    }
  });
  //console.log(formik.values.firstName == '')

  const handleSubmitform = async () => {
    console.log("enviando formulário - handleSubmitform")

    const header = { header: { "Content-Type": "application/json" } };
    formik.values.password = hexSha1(formik.values.password);
    //console.log(formik.values)
    const res = await api.post("/users/createUser", formik.values, header)
      .then((response) => {
        console.log(response.data)
        if (response.data.isSuccess) {
          toast.success('Membro da equipe registrado com sucesso!');
          setLoading(true);
          usestore.reposUser.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
            setTimeout(() => {
              setActiveStep((prevStep) => prevStep + 1);
            }, 200);
          });
        }
      })
      .catch((error) => {
        console.log('error');
        toast.error(error || 'Erro ao cadastrar equipe, tente novamente!\n Caso o erro persista, contate o suporte.');
      })
  }

  const formContent = (step) => {
    switch (step) {
      case 0:
        return <AccountDetails formik={formik} />;
      case 1:
        return <PersonalInfo formik={formik} />;
      case 2:
        return <ReviewInfo formik={formik} />;
      case 3:
        return <SucessInfo handleClose={handleClose} formik={formik} />;
      default:
        return <div>404: Not Found</div>
    }
  };
  //console.log(steps.length)

  return (
    <Box sx={{ width: "100%", maxWidth: 900, my: 1 }} >
      {/* <Stepper activeStep={activeStep} orientation="horizontal" >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      <Grid container>
        <Grid item xs={12} sx={{ padding: '20px' }} >
          {formContent(activeStep)}
        </Grid>
        {formik.errors.submit && (
          <Grid item xs={12} >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            {activeStep === 0 || activeStep === 3 ? (
              <Button disabled onClick={handleBack} >
              </Button >
            ) : (
              <Button onClick={handleBack} color="success" >
                retornar
              </Button >
            )}
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            {activeStep < 3 && (
              <Button onClick={formik.handleSubmit} variant="contained" color="success">
                <Span sx={{ textTransform: "capitalize" }}>{(activeStep === 2 ? 'Enviar' : 'continuar')}</Span>
              </Button>
            )}
            {/* {activeStep === steps.length - 1 ? (
              <span variant="contained" color="success" onSubmit={handleSubmitform()} >
              </span>
            ) : formik.values.roles.length == 0 ? (<Alert severity="success">O perfil a é um campo obrigatório</Alert>) : (
              <Button onClick={formik.handleSubmit} variant="contained" color="success">
                <Span sx={{ textTransform: "capitalize" }}>continuar</Span>
              </Button>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </Box >
  )
}

export default StepperForm;