import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListReportsLivestock from './card/ListReportsLivestockCard'
import ListReportsHarvest from './card/ListReportsHarvestCard'
import ListReportsProperty from './card/ListReportsPropertyCard'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function ReportsTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Propriedade" {...a11yProps(0)} />
          <Tab label="Criações" {...a11yProps(0)} />
          <Tab label="Safras" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ListReportsProperty />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListReportsLivestock />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ListReportsHarvest />
      </TabPanel>
    </Box>
  )
}

//Procedimento exemplo para a tab propriedade: <ListReportsProperty />
//1- Arquivo ListReportsProperty.js: Nome do button no pdf no card: Arquivo
//Definir o nome do documento e o typo no array para acionar o button do card. Ex.:  doc: "ListCreationHarvest e o  type: "property",
//2- Arquivo ImportReportComponentPdfMake: temos uma condicional (type...doc..) que irá passar o arquivo para ser criado o pdf (pdfMake.createPdf(document))
//2.1 Na função generatePDF devemos passar a condicional para validar a chamada da criação do documento em pdf
//Ex.: if (type === "property" && doc === "ListCreationHarvest") pdfMake.createPdf("").open({}, window.open("", "_blank"));
//2.2 pdfMake.createPdf(funcao): Passar o nome do arquivo que contem todo o documento do conteúdo:
//import { documento as ListCreationHarvest } from "./docReports/DocReportPropertyListCreationHarvest";
//Ex.: pdfMake.createPdf(ListCreationHarvest).open({}, window.open("", "_blank"));
//*****************Formatação do conteúdo do documento********************** */
//3- DocReportPropertyListCreationHarvest: documento que contém toda a formatação e o conteúdo do pdf
//3.1 - header: headerbasic, footer: footerBasic
//3.2 content: Conteúdo do arquivo em content: [ ...
// Em content: passe um novo título  titleSection("Lista das Criações e Safras"),
// Em content: passe um novo corpo do documento ListCreationHarvest.content,
// lembrar de criar o documento ListCreationHarvest.js na pasta body que contém todo o conteúdo do pdf
// ... outras partes do conteúdo, se necessário
