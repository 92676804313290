import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, IconButton, Slide, TextField, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import dayjs from 'dayjs';
import api from '../../../services/api';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import UploadDialog from '../../../components/Image/UploadDialog';
import SimpleDateProviderData from '../../../components/Fields/SimpleDateProviderData';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardHarvestImage({ reposHarvest, expanded, setExpanded, setIsLoadingRegister }) {
  const usestore = useStore();
  const { idFarm, id } = useParams();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false);
  }, []);

  //const property_id = localStorage.getItem('Property') || 1;
  // const resposProperty = usestore.reposProperty;
  const { aux_add, setAuxAdd } = useContext(Context);
  // const propertyRepos = resposProperty.getPropertyStore('id', property_id);
  const useImagesRepos = usestore.reposImages;

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const [imageKey, setImageKey] = useState('');
  const [dataHarvest, setDataHarvest] = useState({
    id_property: idFarm,
    id_user: userCurrent.id,
    id_harvest: id,
    title: '',
    description: '',
    key_image: imageKey,
    date_start: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    status: '0',
    isDeleted: '0',
  });

  useEffect(() => {
    setDataHarvest({
      id_property: idFarm,
      id_user: userCurrent.id,
      id_harvest: id,
      title: '',
      description: '',
      key_image: imageKey,
      date_start: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      status: '0',
      isDeleted: '0',
    });
    setTimeout(() => {
      // setImageDecode(propertyRepos[0].image);
    }, 200);
  }, [aux_add]);

  const handleChangeInput = (event) => {
    event.preventDefault();
    setDataHarvest({ ...dataHarvest, [event.target.name]: event.target.value });
  };

  const handleDateChange = (newDate, field) => {
    console.log(newDate);
    setDataHarvest({ ...dataHarvest, [field]: newDate });
  };

  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);
    setFormError({});
    let errors = {};
    let formOk = true;

    if (dataHarvest.title < 3) {
      errors.title = true;
      formOk = false;
    } else if (dataHarvest.key_image === '') {
      errors.title = true;
      formOk = false;
    }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api.post('harvest/saveProgressCulture', dataHarvest, header);
      if (res) {
        reposHarvest.fetch().then((res) => {
          setExpanded();
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            setLoadingButton(false);
            handleClose();
          }, 200);
        });

        setTimeout(() => {
          setIsLoadingRegister(true);
        }, 1000);
      }
    } else {
      setLoadingButton(false);
      toast.error(`Por favor, registre o título e imagem para o registro da informação.`);
      setFormError(errors);
    }
  };

  const [item, setItem] = useState({
    id: id,
    tipo_item: 'harvest',
    id_farm: idFarm,
  });

  const callBack = (imageKey) => {
    // const imageUrl = `https://cpt-soft.s3.us-east-1.amazonaws.com/fazenda_premium_demo/harvest/${imageKey}`;
    // setDataHarvest({ ...dataHarvest, key_image: imageUrl });

    const imageUrl = useImagesRepos.getImageStoreKey('image', imageKey)[0];
    setImageKey(imageUrl.img);
  };

  // const callBackHook = {
  //   addImageStore: async (image) => {
  //     console.log('Adding image to store:', image);
  //     const header = {
  //       header: {
  //         'Content-Type': 'application/json',
  //       },
  //     };
  //     const fileData = { id: idFarm, image: image.image, item_type: item.tipo_item };
  //     // Implementar a lógica de salvamento do link da imagem no banco de dados
  //   },
  //   delImageStore: (id) => {
  //     console.log('Deleting image from store with id:', id);
  //   },
  // };

  // const [open, setOpen] = useState(false);

  // const openModal = () => {
  //   setOpen(true);
  // };

  // const closeModal = () => {
  //   setOpen(false);
  // };

  // console.log(dataHarvest);
  // console.log(dataHarvest.key_image);
  // if (!dataHarvest.key_image) {
  //   console.log('vazio');
  //   setOpen(false);
  // }

  return (
    <>
      <Card sx={{ height: 450 }}>
        <CardMedia>
          {dataHarvest.key_image || imageKey ? (
            <CardMedia component="img" height="140" image={dataHarvest.key_image || imageKey} alt="" />
          ) : (
            <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
              <div>
                <IconButton variant="contained" color="primary" onClick={handleOpen}>
                  <PhotoCamera color="success" />
                </IconButton>
                <UploadDialog open={open} TransitionComponent={Transition} handleClose={handleClose} item={item} callBack={callBack} callBackHook={useImagesRepos} />
              </div>
              <span>Clique aqui para inserir a imagem da sua safra</span>
            </Box>
          )}
        </CardMedia>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
            <TextField
              onChange={handleChangeInput}
              value={dataHarvest.title}
              fullWidth
              multiline
              color="success"
              id="title"
              name="title"
              size="small"
              InputLabelProps={{ style: { fontSize: 12, color: '#808080' } }}
              label="Título para o registro*"
              variant="standard"
            />
          </Box>
          <br />
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
            <SimpleDateProviderData onChangeDate={handleDateChange} label={'Data do registro'} date={dataHarvest.date_start} field={'date_start'} />
          </Box>
          <br />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              margin: 'auto',
            }}
          >
            <TextField
              onChange={(e) => {
                handleChangeInput(e);
              }}
              value={dataHarvest.description}
              sx={{}}
              id="description"
              name="description"
              label="Descrição"
              fullWidth
              color="success"
              // InputProps={{ style: { fontSize: 40 } }}
              InputLabelProps={{ style: { fontSize: 12, color: '#808080' } }}
              rows={3}
              variant="outlined"
            />
          </Box>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button color="success" variant="contained" type="submit" onClick={onSubmit} sx={{ width: '200px' }}>
              {LoadingButton ? (
                <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
              ) : (
                <>
                  <SaveAltIcon sx={{ mr: 1 }} />
                  Enviar
                </>
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
      {/* <Dialog open={open} onClose={closeModal}>
        <CardMedia component="img" image={dataHarvest.key_image} alt="" />
      </Dialog> */}
    </>
  );
}
