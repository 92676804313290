import dayjs from "dayjs";

function formatData(Inputdata) {
  return !Inputdata ? "" : dayjs(Inputdata).format("DD/MM/YYYY");
}

export function headerReportDate(currentPage, pageCount) {
  return {
    margin: [24, 12, 24, 0], // Margens para o cabeçalho completo
    layout: "noBorders", // Sem bordas para a tabela
    table: {
      widths: ["*", "auto"], // Largura para o nome da fazenda e paginação
      body: [
        // Primeira linha: Nome da fazenda à esquerda e paginação à direita
        [
          { text: `Relatório: ${formatData(new Date())}`, alignment: "left" },
          { text: currentPage.toString() + " de " + pageCount, alignment: "right" },
        ],
      ],
    },
    fontSize: 7,
  };
}
