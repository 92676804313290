import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, ButtonBase, Container, Paper, Stack, Typography } from '@mui/material';
import { Checkmark } from "react-checkmark";
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import DownloadAnimation from '../../../../components/Animations/DownloadAnimation';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        height: '100%',
    },
}));

export default function SuccessCreateProperty() {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const classes = useStyles();

    const [isLoadingText, setIsLoadingText] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = () => {
        navigate("/areasmaps");
    };

    setTimeout(() => {
        setIsLoadingText(true)
    }, 1000);

    setTimeout(() => {
        // handleButtonClick()
    }, 5000);

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Container component="main" maxWidth="sm" sx={{ textAlign: 'center' }}>
                            <Paper variant="" sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }}>
                                <div className="success" >
                                    <Checkmark size={'120'} />
                                    {/* <DownloadAnimation /> */}
                                    <Box sx={{ width: 270, marginBottom: 5, mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6" style={{ color: '#2e7d32', textAlign: 'center' }}>
                                            Fazenda Registrada
                                            {/* <Typewriter tam={0} variant="h6" text="Salvando a edição da área." /> */}

                                        </Typography>
                                    </Box>
                                    {/* <Box sx={{ width: 270, height: 50, marginBottom: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {isLoadingText &&
                                            <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >   <ThreeDotsSpinner />
                                            </Box>
                                        }
                                    </Box> */}
                                </div>



                            </Paper>

                        </Container>

                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div >
    );
}