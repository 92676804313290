import { Button } from '@mui/material';
import { DrawingManager, GoogleMap, InfoWindow, Marker, Polygon } from '@react-google-maps/api'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LoadScriptOnlyIfNeeded from '../../../../LoadScript/LoadScriptOnlyIfNeeded'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Loading from '../../../../components/Loader/Loading';

const libraries = ["places", "drawing"];
const apiKey = "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU";

export const MapFormObservation = ({centerInit}) => {
  const [isLoading, setLoading] = useState(false);

  const mark_strg = localStorage.getItem('marker') || '';
  const [marker, setMarker] = useState(mark_strg ? JSON.parse(mark_strg) : {});
  localStorage.setItem('marker', JSON.stringify(marker));

  const [selected, setSelected] = useState(null);
  const mapRef = useRef(null);
  const handleLoad = map => (mapRef.current = map)

  const onLoad = drawingManager => {
    console.log(drawingManager)
  }

  const onLoadMarker = (marker) => {
    console.log("marker: ", marker);
  };

  return (
    <>
      {!isLoading ? (
        <LoadScriptOnlyIfNeeded id="scrit-loader2" googleMapsApiKey={apiKey} libraries={libraries}>
          <GoogleMap
            mapContainerStyle={{
              height: "60vh",
              width: "100%",
            }}
            center={centerInit}
            zoom={Number(centerInit.zoom)}
            onLoad={handleLoad}
            options={{
              mapTypeControl: true,
              mapTypeId: "hybrid",
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
            ref={mapRef}
            onClick={e =>
              setMarker({
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                time: new Date()
              })
            }
          >
            {marker && marker.lat && <Marker
              key={marker.time}
              onLoad={onLoadMarker}
              position={{ lat: marker.lat, lng: marker.lng }}
              // icon={{
              //   url: "/dist/image/iconLocation.png",
              //   scaledSize: new window.google.maps.Size(40, 40),
              //   //origin: new window.google.maps.Point(0, 0),
              //   //anchor: new window.google.maps.Point(20, 20),
              // }}
              // icon={<PersonPinIcon />}
              onClick={() => {
                setSelected(marker)
              }}
            >
              {selected ? (
                <InfoWindow
                  position={{ lat: selected.lat, lng: selected.lng }}
                  onCloseClick={() => {
                    setSelected(null)
                  }}
                >
                  <div>
                    <h6>Solicitação</h6>
                  </div>
                </InfoWindow>) : null}
            </Marker>
            }
          </GoogleMap>
        </LoadScriptOnlyIfNeeded>) : <Loading />}
    </>
  )
}
