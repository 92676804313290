import React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers"
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import {
    TextField,
    Button,
    Box,
    Grid,
    Typography,
    InputAdornment,
    Stack,
} from "@mui/material"
import dayjs from "dayjs"
import { format } from "date-fns"

const theme = createTheme(
    {
        palette: {
            primary: { main: "#1976d2" },
        },
    },
    deDE // use 'de' locale for UI texts (start, next month, ...)
)
const weekdays = {
    "2ª": "seg",
    "3ª": "ter",
    "4ª": "qua",
    "5ª": "qui",
    "6ª": "sex",
    Sa: "sáb",
    Do: "dom",
}

function SimpleDateProvider({ onChangeDate, label }) {
    const dateNow = new Date()
    //post.date = format(date, "MMMM do, yyyy H:mma");
    // const dateNow = format(date, "dd-MM-yyyy");
    const [value, setValue] = React.useState(dayjs(dateNow))

    return (
        <Stack>
            <LocalizationProvider
                theme={theme}
                dateAdapter={AdapterDayjs}
                adapterLocale='pt'
            >
                <div className='d-lg-none'>
                    <Stack>
                        <MobileDatePicker
                            //views={['day', 'month', 'year']}
                            label={label}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue)
                                onChangeDate(
                                    format(
                                        new Date(newValue),
                                        "yyyy-MM-dd HH:mm:ss"
                                    )
                                )
                            }}
                            adapterLocale='pt'
                            inputFormat='DD/MM/YYYY'
                            dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                            renderInput={(params) => (
                                <TextField {...params} color='success' />
                            )}
                        />
                    </Stack>
                </div>
                <div className='d-none d-lg-block'>
                    <Stack>
                        <DesktopDatePicker
                            //views={['day', 'month', 'year']}
                            label={label}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue)
                                onChangeDate(
                                    format(
                                        new Date(newValue),
                                        "yyyy-MM-dd HH:mm:ss"
                                    )
                                )
                            }}
                            adapterLocale='pt'
                            inputFormat='DD/MM/YYYY'
                            dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    color='success'
                                    error={!value}
                                    helperText={
                                        !value && (
                                            <p
                                                id='NameLivestock'
                                                className='form-text text-danger is-invalid'
                                            >
                                                Por favor, insira uma data
                                                válida.
                                            </p>
                                        )
                                    }
                                />
                            )}
                            //shouldDisableDate={isFutureDate}
                        />
                    </Stack>
                </div>
            </LocalizationProvider>
        </Stack>
    )
}

export default SimpleDateProvider
