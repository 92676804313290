import * as React from "react";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuMsn from "../DialogMessenger/forms/MenuMsn";
import MenuMsnMin from "../DialogMessenger/forms/MenuMsnMin";
import { green, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconCheckboxes from "./IconCheckboxes";
import GradingIcon from "@mui/icons-material/Grading";
import CardChat from "./CardChat";
import CardChatImage from "./CardChatImage";
import { useState } from "react";
import Loading from "../../../components/Loader/Loading";
import api from "../../../services/api";
import { useEffect } from "react";
import CardChatTrash from "./CardChatTrash";

export default function SidebarChatCompactMinTrash() {
  const [msn, setMsn] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const property_id = (localStorage.getItem("Property") || 1);

  const loadPosts = async () => {
    console.log("-------result------------:");
    const response = await api.get("/messenger/posts");
    console.log(response.data._value);
    const itemData = [];
    for (const iterator of response.data._value) {
      if (iterator.isDeleted === 1 && iterator.id_property == property_id) {
        itemData.push(iterator);
      }
      // if (iterator.isDeleted === 1 && iterator.id_property == property_id) {
      //   itemData.push(iterator);
      // }
    }
    setMsn(itemData);
    setIsLoading(false)
  };

  useEffect(() => {
    loadPosts();
  }, []);

  useEffect(() => {
    loadPosts();
    console.log('setMsn')
    console.log(msn)
  }, [isLoading]);

  return (
    <div className="container-fluid " style={{ maxWidth: 900 }}>
      <ul className="contacts-list ">
        {isLoading === true ? (
          <>
            <Loading />
          </>
        ) : (
          msn?.map((post) => (
            <li>
              <CardChatTrash post={post} setIsLoading={setIsLoading} />
            </li>
          ))
        )}
        {/* <li>
          <CardChatImage />
        </li> */}
      </ul>
    </div>
  );
}
