import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ButtonBase, List, Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SpaIcon from '@mui/icons-material/Spa';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import CreateAddCatalogProdctList from '../../../products/components/tutorial/CreateAddCatalogProdctList';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function TutorialFinancialAccordion() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClickLivestock = () => {
    navigate('/livestock');
  };
  const handleButtonClickHarvest = () => {
    navigate('/harvest');
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Sugestões para criação de contas
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionDetails>
            <Typography variant="subtitle1">
              Sim, você pode usar a conta padrão "Caixa da Fazenda" para gerenciar todas as transações financeiras da sua fazenda. Por exemplo, você pode registrar compras de insumos no valor de
              R$500,00, pagamentos diários de funcionários no valor de R$2.000,00 e pequenos recebimentos, como a venda de produtos, no valor de R$1.000,00. No entanto, para uma gestão mais detalhada
              e organizada, utilize a "Caixa da Fazenda" para transações internas e adicione uma conta bancária para controlar transações externas. Por exemplo, use a conta bancária para registrar
              recebimentos maiores, como R$5.000 provenientes de vendas de safra, e pagamentos a fornecedores, como R$3.000 para compras de equipamentos. Isso ajuda a manter suas finanças mais claras
              e eficientes.
            </Typography>
          </AccordionDetails>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Caixa da Fazenda
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            O "Caixa da Fazenda" desempenha um papel fundamental na gestão financeira de uma fazenda, e sua importância pode ser destacada em vários aspectos:
          </Typography>
          <AccordionDetails>
            <Typography sx={{ mt: 1 }} variant="subtitle1">
              <strong>Centralização de Controle Financeiro:</strong> O Caixa da Fazenda serve como um ponto central para gerenciar todas as transações internas da fazenda, como pequenas compras,
              despesas diárias, ou pagamentos menores que não exigem transferências bancárias. Isso facilita a supervisão das finanças, garantindo que todos os gastos sejam registrados de forma
              organizada.
            </Typography>
            <Typography sx={{ mt: 1 }} variant="subtitle1">
              <strong>Facilidade de Acesso: </strong> Por ser uma conta financeira básica e pré-configurada, a Caixa da Fazenda está sempre disponível para uso imediato, sem a necessidade de abrir
              contas em bancos ou outras instituições financeiras. Isso permite uma resposta rápida às necessidades operacionais diárias.
            </Typography>
            <Typography sx={{ mt: 1 }} variant="subtitle1">
              <strong>Organização e Eficiência: </strong> A conta Caixa da Fazenda ajuda a categorizar e rastrear as movimentações internas, permitindo que o gestor tenha uma visão clara de onde e
              como o dinheiro está sendo gasto. Essa organização é crucial para manter a saúde financeira da propriedade e para tomar decisões informadas sobre investimentos e cortes de custos
            </Typography>
            <Typography sx={{ mt: 1 }} variant="subtitle1">
              <strong>Redução de Complexidade: </strong> Ao centralizar as despesas menores em uma conta específica, o Caixa da Fazenda simplifica o processo de contabilidade, reduzindo a complexidade
              na reconciliação de contas e no fechamento mensal ou anual.
            </Typography>
            <Typography sx={{ mt: 1 }} variant="subtitle1">
              <strong>Transparência: </strong> Com um registro preciso das transações, a conta Caixa da Fazenda promove a transparência nas operações financeiras da fazenda, facilitando auditorias
              internas e a prestação de contas para parceiros ou investidores.
            </Typography>
          </AccordionDetails>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Conta Bancária
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2">
            No Fazenda Premium, você pode adicionar suas contas bancárias para gerenciar transações maiores, como recebimentos de vendas e pagamentos de fornecedores.
          </Typography>
          <AccordionDetails>
            <Typography variant="subtitle1">
              Diferente da Caixa da Fazenda, que é para pequenas despesas internas, a conta bancária permite o controle de movimentações financeiras mais significativas e externas, mantendo suas
              finanças ainda mais organizadas e separadas.
            </Typography>
          </AccordionDetails>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
