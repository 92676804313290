import bugImageUrl from '../assets/solucao.png';
import ideaImageUrl from '../assets/idea.svg';
import thoughtImageUrl from '../assets/thought.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export const feedbackTypes = {
  IDEA: {
    title: 'Ideia',
    image: {
      source: ideaImageUrl,
      alt: 'Lâmpada acesa',
    },
  },
  BUG: {
    title: 'Problema',
    image: {
      source: bugImageUrl,
      alt: 'Ilustração de um inseto roxo',
    },
  },
  OTHER: {
    title: 'Caso de sucesso',
    image: {
      source: thoughtImageUrl,
      alt: 'Núvem de pensamento',
    },
  },
};
