import React from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import Bot from "../Animations/Bot";

function Speech() {
  const { speak, speaking, supported } = useSpeechSynthesis();

  const handleSpeak = () => {
    speak({ text: "Olá, mundo! " });
  };

  return (
    <div>
      <button onClick={handleSpeak} disabled={!supported || speaking}>
        botao
      </button>
    </div>
  );
}

export default Speech;
