import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Modal, Skeleton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../../../components/Loader/Loading';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import api from '../../../services/api';
import useListImagesStore from '../../../store/useListImagesStore';
import { toast } from 'react-toastify';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Context } from '../../../components/Context/AuthContext';
import { useContext } from 'react';
import DynamicTabs from './DynamicTabs';

export default function ImageListCmp({ data, handleGetList, isLoadingImage, storage }) {
  console.log('ImageListCmp');
  const { aux_add } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const useImagesRepos = useListImagesStore((state) => state);
  const [data1, setData1] = useState(0);

  const property_id = localStorage.getItem('Property') || 1;
  const display_demo = JSON.parse(localStorage.getItem('display'));

  useEffect(() => {
    console.log('ImageListCmp - handleGetList()');
    handleGetList();
  }, [data1, aux_add]);

  if (data1 != property_id) {
    setTimeout(() => {
      setData1(property_id);
    }, 500);
  }

  useEffect(() => {
    handleGetList();
    setIsLoading(true);
  }, [isLoading, aux_add]);

  const handleDelete = async (id) => {
    console.log(id);
    console.log(id);
    console.log('deletou');
    const res = await api
      .delete(`/upload/${id}/0`)
      .then((response) => {
        useImagesRepos.updateImageStore(id, 'isDeleted', 1);
        toast.success('Imagem movida para a lixeira com sucesso!');
        setTimeout(() => {
          handleGetList();
        }, 20);
      })
      .catch(() => {
        toast.error('Erro ao mover a imagem para a lixeira!');
      });
  };

  return <>{isLoading === false ? <Loading /> : <DynamicTabs data={data} handleDelete={handleDelete} display_demo={display_demo} isLoadingImage={isLoadingImage} />}</>;
}
