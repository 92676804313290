import * as React from 'react'
import { useState } from 'react'
import { Button, Dialog, DialogContent, DialogActions, Grid, Typography } from '@mui/material'
import SimpleDateProvider from '../../../../components/Fields/SimpleDateProvider'
import { format } from 'date-fns'

export default function ModalColorChipsUpdateDate({ open, handleClose }) {
  //const [open, setOpen] = useState(false)

  // const handleOpen = () => {
  //   setOpen(true)
  // }

  // const handleClose = () => {
  //   setOpen(false)
  // }

  // No componente pai, você passará o estado `open` e a função `handleClose` para controlar a abertura e o fechamento do modal

  const handleUpdateProgress = () => {
    console.log('handleUpdateProgress')
    console.log(data)
    // Lógica para atualizar o progresso aqui
    handleClose() // Fecha o modal após a atualização
  }
  const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

  const [data, setData] = useState({
    name: '',
    date_start: formattedCurrentDate,
    date_prev_end: formattedCurrentDate,

    // id_property: property_id
  })

  const handleDateChangeEnd = (newDate) => {
    console.log(newDate)
    setData({ ...data, date_prev_end: newDate })
  }

  const handleDateChangeStart = (newDate) => {
    console.log(newDate)
    setData({ ...data, date_start: newDate })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        {/* <DialogTitle>Atualizar Progresso</DialogTitle> */}
        <DialogContent>
          {/* <p>SITUAÇÃO: {status}</p> */}
          <Typography variant="subtitle1" sx={{ mt: 0, mb: 2 }}>
            Atualizar a data da atividade:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} alignItems="center" sx={{ height: 60 }}>
              <SimpleDateProvider onChangeDate={handleDateChangeStart} label={'Data do início'} />
            </Grid>
            <Grid item xs={12} md={6} alignItems="center" sx={{ height: 60 }}>
              <SimpleDateProvider onChangeDate={handleDateChangeEnd} label={'Data do término'} />
            </Grid>
            {/* <Grid item>
              <Box sx={{ border: "0px solid #ccc", borderRadius: "4px" }}>
                <Stack direction="column" spacing={1}>
                  <Chip variant="outlined" label="REVISAR" color={selectedStatus === "REVISAR" ? "default" : "default"} onClick={() => handleStatusChange("REVISAR")} />
                  <Chip label="PLANEJAMENTO" color={selectedStatus === "PLANEJAMENTO" ? "success" : "default"} onClick={() => handleStatusChange("PLANEJAMENTO")} />
                </Stack>
              </Box>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="success" onClick={handleClose}>
            Cancelar
          </Button>
          <Button size="small" variant="contained" color="success" onClick={handleUpdateProgress}>
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
