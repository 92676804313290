import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import api from '../../../services/api';
import useHarvestReposStore from '../../../store/useHarvestReposStore';
import { useContext } from 'react';
import { Context } from '../../../components/Context/AuthContext';
import { useState } from 'react';

export default function MediaCard({ item }) {
  const datFormat = dayjs(item.sortDate).format('DD-MM-YYYY-MM HH:mm:ss');

  const [expanded, setExpanded] = useState(false);

  const handleImageClick = () => {
    setExpanded(!expanded);
  };

  const handleDelete = async (id) => {
    // Implement your delete logic here
  };

  return (
    <Card>
      <CardMedia component="img" height={expanded ? 'auto' : 140} image={item.image} alt="green iguana" onClick={handleImageClick} style={{ cursor: 'pointer' }} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" onClick={() => handleDelete(item.id)}>
          Excluir
        </Button>
        <Typography sx={{ fontSize: 12 }} color="text.secondary">
          {datFormat}
        </Typography>
      </CardActions>
    </Card>
  );
}
