import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { green } from "@mui/material/colors";
import { useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import useStore from "../../store/useStore";
import { Context } from "../../components/Context/AuthContext";
import { useContext } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function findCategoryById(categories, categoryIdToFind) {
  for (const category of categories) {
    if (category.id === categoryIdToFind) {
      return category; // Encontrou o objeto no nível atual
    }

    if (category.subcategories && category.subcategories.length > 0) {
      // Procura recursivamente nas subcategorias
      const subcategoryResult = findCategoryById(category.subcategories, categoryIdToFind);
      if (subcategoryResult) {
        return subcategoryResult; // Encontrou o objeto em uma subcategoria
      }
    }
  }

  return null; // Não encontrou o objeto
}

export default function RegisterCostCenterDialogModalTransferCategory({ categories, setCategories, categoryId }) {
  const [open, setOpen] = useState(false);

  const categoryTransfer = findCategoryById(categories, categoryId);

  const [categoryName, setCategoryName] = useState(categoryTransfer.name);
  const [selectedGroup, setSelectedGroup] = useState("");

  const usestore = useStore();
  //usestore.reposAccount.fetch();
  const reposAccount = usestore.reposAccount.listAccount;
  //console.log(reposAccount)
  const { aux_add, setAuxAdd } = useContext(Context);
  var farm_id = localStorage.getItem("Property");

  //console.log(categoryId, categoryTransfer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  //console.log("categoryTransfer");
  //console.log(categoryTransfer)
  //console.log("selectedGroup")
  //console.log(selectedGroup)


  // Filtrar para encontrar objetos onde info é um array
  const filteredElementGroup = reposAccount.filter(item => item.name == selectedGroup);
  //console.log(filteredElementGroup)
  let matches = 0
  const newInfo = []
  //console.log("ifxxxxxxxxxxxxxxxxx")
  if (filteredElementGroup[0]?.infoParse[2] == "category") {
    const info = filteredElementGroup[0]?.infoParse
    console.log("info - cat")
    console.log(info)
    newInfo[0] = 'centro_custo'
    newInfo[1] = ""
    newInfo[2] = "subcategory1"
    // } else if (filteredElementGroup[0]?.infoParse[2].startsWith("subcategory")) {
  } else {
    //console.log("entrou")
    let regex = /\d$/;
    // //let regex = /\d+$/;
    const info = filteredElementGroup[0]?.infoParse
    //console.log("info -sub")
    //console.log(info)
    //console.log(filteredElementGroup[0]?.infoParse[2])
    matches = filteredElementGroup[0]?.infoParse[2].match(regex);
    //console.log(matches)
    // Se existir uma correspondência, converte para número
    let number = matches ? parseInt(matches[0], 10) : null;
    //console.log(number)
    // Soma 1 ao número
    number += 1;
    newInfo[0] = 'centro_custo'
    newInfo[1] = ""
    newInfo[2] = "subcategory" + number;

  }

  //console.log(newInfo)


  const handleSave = async () => {
    console.log(categoryName, selectedGroup);


    // const newSubcategory = {
    //   id: Date.now(),
    //   id_property: 1,
    //   name: categoryName,
    //   nature: "DEVEDORA",
    //   mother: selectedGroup,
    //   info: ["centro_custo", "", "subcategory"],
    //   subcategories: [],
    // };
    // console.log(newSubcategory);

    console.log("handleEditCategorySave");
    console.log(categoryId, categoryName)
    console.log(categoryTransfer.id_property)
    console.log(categoryTransfer.mother)



    const updatetransferCategoryAccount = {
      id: categoryId,
      id_property: filteredElementGroup[0].id_property,
      mother: filteredElementGroup[0].id,
      info: JSON.stringify(newInfo)

    }

    console.log(updatetransferCategoryAccount)
    try {
      const header = { header: { "Content-Type": "application/json", }, };

      const res = await api.put("/finances/updateTransferAccount", updatetransferCategoryAccount, header);
      console.log(res);
      console.log(res.data.isSuccess);

      if (res.data.isSuccess) {
        toast.success("Categoria atualizada com sucesso");

        usestore.reposAccount.fetch({ 'id_property': farm_id }).then((res) => {
          console.log("reposAccount")
          console.log(res)
          setAuxAdd(aux_add + 1);

        });

      }
    } catch (error) {
      console.log("error");
      toast.error("Erro de comunicação com o servidor");
      //toast.error("Erro na comunicação: " + error.response.data);
    }

  };

  // Função recursiva para gerar opções hierárquicas
  const generateCategoryOptions = (categoryList, level = 0) => {
    const options = [];

    categoryList.forEach((category) => {
      options.push(
        <MenuItem key={category.id} value={category.name}>
          {`${"\u00A0".repeat(level * 2)}${category.name}`}
        </MenuItem>
      );

      if (category.subcategories.length > 0) {
        options.push(...generateCategoryOptions(category.subcategories, level + 1));
      }
    });

    return options;
  };

  console.log();

  return (
    <div>
      <IconButton onClick={handleClickOpen} sx={{}}>
        {/* <Chip label={category.name} variant="outlined" /> */}
        <ImportExportIcon />
      </IconButton>
      {/* <Button variant="outlined" color="success" onClick={handleClickOpen}>
        Categoria
      </Button> */}
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>Transferir Categoria</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h6" component="div">
                Categoria
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: -2, mb: 2, borderColor: "green" }} />
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom component="div">
                Defina em qual grupo ou categoria será exibida a categoria <strong>{categoryName}</strong>
              </Typography>
            </Grid>
          </Grid>
          <TextField color="success" disabled label="Categoria" variant="outlined" fullWidth value={categoryName} onChange={handleCategoryNameChange} />
          <>
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom component="div">
                  Aparecer dentro de:
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth variant="outlined" style={{ marginTop: "16px" }}>
              <InputLabel color="success">Grupo/Categoria </InputLabel>
              <Select color="success" value={selectedGroup} onChange={handleGroupChange} label="Grupo/Categoria ">
                {generateCategoryOptions(categories)}
              </Select>
            </FormControl>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={handleSave} variant="contained" color="success">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
