import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit, LocationOn } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green, grey } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CardPropertyProducts from '../../property/components/CardPropertyProducts';
import { Span } from '../../../components/Typography';
import MenuPlan from './MenuPlan';
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

export default function AccessHarvestPlan({ listPropertyRepos }) {
    const navigate = useNavigate();
    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>
            <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Planejamento do Projeto</Typography>

            </Box>
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/plan_harvest.png"
                        title="atividades"
                    />
                </Card>
            </Box>
            <Card >
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 1: Meta de produção por área</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar a meta de produção de cada área de produção.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography> Registrar a meta de produção de cada área para ter um controle preciso e atualizado das metas estabelecidas, permitindo acompanhar o desempenho real em relação às expectativas, identificar desvios e tomar medidas corretivas para maximizar a eficiência e o rendimento na produção agr´icola.</Typography>

                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 2: Planejamento Financeiro por área</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar o planejamento financeiro da área de produção.
                                    </Typography>
                                </Stack>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>O planejamento financeiro de projetos agrícolas é essencial para obter uma visão clara e detalhada dos aspectos econômicos do negócio. Isso permite analisar o desempenho financeiro, monitorar os custos de produção, identificar oportunidades de redução de despesas, estimar o lucro esperado e tomar decisões estratégicas embasadas em dados financeiros confiáveis para otimizar a rentabilidade do empreendimento agrícola.</Typography>

                            </Stack>

                        </Card>
                    </Grid>


                </Grid>

            </Card >

        </>
    );
}

//https://aegro.com.br/termos-de-uso/