import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field, useFormikContext } from "formik";
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../../../../components/Loader/Loading'
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
//  roles
//  1 proprietario
//  2 gerente
//  3 financeiro
//  4 operacional
//  5 consultor
//  6 agronomo
//  7 veterinario
//  8 estoque
export default function ProfileSelectCheckbox({ formik }) {
  const { values } = formik;
  //console.log(values)

  const [isLoading, setIsLoading] = useState(true)

  //const [checked, setChecked] = React.useState(true);
  // const [state, setState] = React.useState({
  //   proprietario: false,
  //   gerente: false,
  //   financeiro: false,
  //   operacional: false
  // });

  // const handleChange = (event) => {
  //   // setChecked(event.target.checked);
  //   console.log(event)
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };
  //const { proprietario, gerente } = state;

  useEffect(() => {
    //values.roles = ["operacional"]
    setIsLoading(false)
  }, [])

  // if (['admin', 'null', 'god'].includes(values.username))
  return (
    <>
      {isLoading === true ? (<Loading />) :
        <>
          <FormControl>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={values.id_role || ""} name="id_role" required >
              <FormControlLabel value="1" control={<Radio />} label="Proprietário" onChange={formik.handleChange} />
              <FormControlLabel value="2" control={<Radio />} label="Gerente" onChange={formik.handleChange} />
              <FormControlLabel value="3" control={<Radio />} label="Financeiro" onChange={formik.handleChange} />
              <FormControlLabel value="4" control={<Radio />} label="Operacional" onChange={formik.handleChange} />
            </RadioGroup>
          </FormControl>
          <FormControl style={{marginLeft: '20px'}}>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={values.id_role || ""} name="id_role" required >
              <FormControlLabel value="5" control={<Radio />} label="Consultor" onChange={formik.handleChange} />
              <FormControlLabel value="6" control={<Radio />} label="Agrônomo" onChange={formik.handleChange} />
              <FormControlLabel value="7" control={<Radio />} label="Veterinário" onChange={formik.handleChange} />
              <FormControlLabel value="8" control={<Radio />} label="Estoque" onChange={formik.handleChange} />
            </RadioGroup>
          </FormControl>
        </>
      }
    </>
  );
}