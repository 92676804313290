import React from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import TeamContent from "./TeamContent";
import Footer from "../../components/Footer/Footer";

export default function Team() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <TeamContent />
      <Footer />
    </>
  );
}
