import React from "react";
import "./sucess.css";
import { Checkmark } from "react-checkmark";
import {
  Typography,
} from '@mui/material';
export default function Success() {
  return (
    <div className="sucess">
      <Checkmark size={'196'} />
      <Typography variant="h6" sx={{ mt: 4 }} >
        Usuário criado com sucesso!
      </Typography>
    </div>
  )
}