import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Grid, Typography, InputAdornment, Stack, Card, CardMedia, CardHeader, Divider, ListItemIcon, Checkbox } from '@mui/material';
import { WrongLocation } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import FormAssignAreaHeader from './FormAssignAreaHeader';
import FormAssignAreaFooter from './FormAssignAreaFooter';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    overflow: 'auto',
  },
  disabled: {
    border: '2px solid red',
    overflow: 'auto',
  },
});

export default function FormDesassignAreaStep1({ data, obj_fn }) {
  const classes = useStyles();

  //show texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    return !data.new_area.length;
  };

  // valida o botao retornar
  const returnError = () => {
    return true;
  };

  const selectedIds = {};
  data.new_area?.forEach((area) => (selectedIds[area.id] = area.id));
  // Marca áreas selecionadas
  const [selectedId, setSelectedId] = useState(selectedIds);

  const handleChange = (e, area) => {
    console.log('handleChange');
    selectedIds[area.id] = !selectedIds[area.id];
    setSelectedId({ ...selectedIds });

    if (selectedIds[area.id]) {
      obj_fn.setData((prevState) => ({ ...prevState, new_area: [...prevState.new_area, area] }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, new_area: prevState.new_area.filter((area_prev) => area_prev.id !== area.id) }));
    }
  };

  const getUsed = () => {
    let cont_animais = 0;
    data.areas_used.map((item) => {
      if (item.num_animais > 0) {
        cont_animais++;
      }
    });
    return cont_animais > 0;
  };

  const title = 'Desvincular Áreas da criação';
  const text_title = 'Seleção de áreas';
  const txt_msn = 'Selecione as áreas da criação que deseja desvincular!\nLembrando que a criação não pode ter animais na área, caso tenha, realize uma transferência!';

  console.log('data.areas_used');
  console.log(data.areas_used);

  return (
    <>
      <FormAssignAreaHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} title={title} height={'60px'} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography fontWeight="500" variant="h6">
            Áreas atuais da Criação
          </Typography>
        </Grid>
        {data.areas_used.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }} className={item.num_animais > 0 ? classes.disabled : selectedId[item.id] ? classes.selected : null}>
                <ListItemIcon key={'li_' + item.id}>
                  <Checkbox
                    key={'cb_' + item.id}
                    sx={{ height: '150px' }}
                    color="success"
                    checked={selectedId[item.id] ? true : false}
                    onChange={(e) => handleChange(e, item)}
                    disabled={item.num_animais > 0}
                  />
                  <div style={{ position: 'relative' }}>
                    <CardMedia key={'cm_' + item.id} component="img" height="150" image={item.url || item.imageDecode} alt="Imagem da área" />
                    <Box
                      key={'b_' + item.id}
                      sx={{
                        position: 'absolute',
                        top: '95px',
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.45)',
                        color: 'white',
                        padding: '27px',
                      }}
                    >
                      <div style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="body2">
                          {item.usedArea || item.area} {item.measure || 'ha'}
                        </Typography>
                      </div>
                    </Box>
                    <CardHeader
                      key={'ch_' + item.id}
                      sx={{ maxHeight: '35px' }}
                      subheader={
                        <Box key={'b_' + item.id} sx={{}}>
                          {item.num_animais == 0 ? (
                            <Typography variant="subtitle2" color="text.secondary">
                              <WrongLocation color="success" /> Apto a desvincular
                            </Typography>
                          ) : (
                            <Typography variant="subtitle2" color="error">
                              {item.num_animais}(ca) **Transfira-os**
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                  </div>
                </ListItemIcon>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>

      {!getUsed() ? (
        <></>
      ) : (
        <>
          <Typography variant="subtitle2" color="error">
            ** É necessário realocar os animais/lotes para outra área antes de desvincular!
            <br />
          </Typography>
          <Typography variant="subtitle2">
            ** Para realocar: clique no botão cancelar abaixo. Na tela Atividade, clique em "NOVA ATIVIDADE DE CAMPO" e escolha "TRANSFERÊNCIA DE ANIMAIS ENTRE LOTES"!
            <br />
          </Typography>
        </>
      )}
      <Divider sx={{ mt: 1, mb: 1, ml: -3 }} />

      <FormAssignAreaFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
