import { Card, CardActions, CardContent, Grid, IconButton, Typography } from "@mui/material";
import ChartBarH from "../../../components/Charts/ChartBarH";
import { NavLink } from "react-router-dom";

export default function CardAreas({ data_cards }) {
  const areas = Object.assign([], data_cards.areas);
  const area_property = Object.assign(
    [],
    areas.filter((area) => area.tipo == "propriedade")
  );
  const areas_cadastradas = Object.assign(
    [],
    areas.filter((area) => area.tipo == "areamap")
  );
  const areas_utilizadas = Object.assign(
    [],
    areas.filter((area) => area.tipo == "utilizadas")
  );

  let area_p = parseFloat(area_property.length > 0 ? area_property[0].area || "0" : "0");
  let area_c = 0;
  let area_u = 0;
  let area_t = area_p;
  areas_cadastradas.map((area) => {
    area_c += parseFloat(area.area || "0");
    //area_t += parseFloat(area.area);
  });
  areas_utilizadas.map((area) => {
    area_u += parseFloat(area.area);
    //area_t += area.area;
  });

  const property_areas = {
    area_p: area_p,
    area_c: area_c,
    area_t: area_t,
    area_u: area_u.toFixed(2),
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    Número de áreas
                  </Typography>
                  <Typography variant="caption" color="text.secondary" component="div">
                    <i className="fa fa-map text-success" /> {areas_cadastradas.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    Área total
                  </Typography>
                  <Typography variant="caption" color="text.secondary" component="div">
                    <i className="fa fa-map text-success" /> {area_t}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="">
            <ChartBarH areas={property_areas} />
          </div>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton sx={{ ml: "auto" }}>
            <NavLink to="/areasmaps" className="nav-link">
              <Typography variant="body2" color="text.secondary" component="div">
                Ver todas as áreas ...
              </Typography>
            </NavLink>
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
}
