import React, { useState } from 'react'
import { Modal, Box, Typography, Button, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { red } from '@mui/material/colors'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { Context } from '../../components/Context/AuthContext'
import api from '../../services/api'
import useStore from '../../store/useStore'
import DeleteIcon from '@mui/icons-material/Delete'

function DeleteModalProduct({ id }) {
  const [open, setOpen] = useState(false)
  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  const property_id = localStorage.getItem('Property') || 1

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDelete = async (id) => {
    if (true) {
      try {
        const status = 1
        const header = { header: { 'Content-Type': 'application/json' } }
        const res = await api.delete(`products/list/${id}/${status}`, header).then((response) => {
          if (!response.data.isSuccess) {
            return toast.error('Erro ao deletar o porduto!')
          }
          if (response.data.isSuccess) {
            toast.success('Produto inativado com sucesso!')

            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
          }
        })
      } catch (error) {
        console.error('Erro ao deletar produto:', error)
        toast.error('Erro ao deletar produto.')
      }
    }
  }

  const handleConfirmDelete = () => {
    handleDelete(id)
    handleClose()
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FF0000',
    boxShadow: 24,
    p: 4,
  }

  // const handleDelete = async (planId) => {
  //   console.log('deletando: ')
  // console.log(planId);
  // const header = { header: { "Content-Type": "application/json" } };
  // const deleteData = { id: plan.id, id_livestock: plan.id_livestock, id_property: plan.id_property };
  // console.log(deleteData);
  // const res = await api
  //   .post("/planning/deleteTargetLivestockController", deleteData, header)
  //   .then((response) => {
  //     if (!response.data.isSuccess) {
  //       return toast.error("Erro ao deletar a Meta!");
  //     }
  //     if (response.data.isSuccess) {
  //       toast.success("Meta deletada com sucesso!");
  //       reposPlanning.fetch({ id_farm: idFarm }).then((res) => {
  //         setTimeout(() => {
  //           setAuxAdd(aux_add + 1);
  //         }, 200);
  //       });
  //       // setTimeout(() => {
  //       //   setIsLoading(true);
  //       // }, 500);
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     toast.error("Erro ao deletar a Meta!");
  //   });

  // console.log(id);
  // console.log("deletou");
  // const isDeleted = "1";
  // const res = await api.delete(`/messenger/posts/${id}/${isDeleted}`);
  // console.log(res.data);
  //setIsLoading(true)
  //console.log(this.state.selectedFile);
  // }

  return (
    <>
      <IconButton variant="outlined" color="success" onClick={handleOpen}>
        <DeleteIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: red[500] }} />
      </IconButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmar a inativação do produto
          </Typography>
          <Typography variant="subtitle2" component="h2">
            Caso seu produto estiver vinculado em compras ou saída em vendas, precisamos manter o histórico. Assim iremos inativar o produto
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja realmente inativar o produto?
          </Typography>
          <Button onClick={handleConfirmDelete} color="error">
            Inativar
          </Button>
          <Button onClick={handleClose} color="inherit">
            cancelar
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default DeleteModalProduct
