import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import Breadcrumb from "../../../../components/Breadcrumb";
import SimpleCard from "../../../../components/SimpleCard";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";
import { AccountCircle } from "@mui/icons-material";
import TitleIcon from "@mui/icons-material/Title";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const location = [{ label: "Fazenda CPT", id: 1 }];

export default function AppForm({ handleClose }) {
  //https://blog.logrocket.com/using-material-ui-with-react-hook-form/

  const options = ["Solcitação", "Não Resolvido", "Resolvido", "Aguardando", "Urgente"];
  const value = options[0];

  const optionsMsn = ["Inserir", "Enviar uma imagem", "Mensagem de texto", "..."];
  const valueMsn = optionsMsn[0];

  const optionsPessoa = ["Definir", "Leonardo", "Pedro", "..."];
  const valuePessoa = optionsPessoa[0];

  const [age, setAge] = useState(valuePessoa);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="container-fluid " style={{ maxWidth: 500 }}>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TitleIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
        <TextField fullWidth id="standard-basic" size="small" InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }} label="Título da Solicitação*" variant="standard" />
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: "auto",
        }}
      >
        <TextField
          sx={{ width: 300 }}
          id="outlined-multiline-static"
          label="Descrição"
          multiline
          // InputProps={{ style: { fontSize: 40 } }}
          InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
          rows={3}
          variant="outlined"
        />
      </Box>

      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button color="success" variant="contained" type="submit" onClick={handleClose}>
          <SendIcon />
          <Span sx={{ pl: 1, textTransform: "capitalize" }}>Enviar</Span>
        </Button>
      </Box>

      <div>
        <Accordion sx={{ my: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Mais informações</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>Insira uma Imagem</Typography>
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <input hidden accept="image/*" type="file" />
                  <PhotoCamera />
                </IconButton>
              </Box>
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Autocomplete disablePortal id="combo-box-demo" options={location} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Adicionar Localização" />} noOptionsText="Sem resultados"/>
              </Box>
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  m: 2,
                }}
              >
                <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Encaminhar para </InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={age} label="Encaminhar para " onChange={handleChange}>
                    <MenuItem value={10}>Leonardo</MenuItem>
                    <MenuItem value={20}>Pedro</MenuItem>
                    <MenuItem value={30}>Gustavo</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      {/* <FormControl>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" />
          <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
          <Button color="primary" variant="contained" type="submit">
            <SendIcon />
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
          </Button>
        </FormControl> */}
      {/* </SimpleCard> */}
    </div>
  );
}
