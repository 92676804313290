import * as React from 'react'
import { Box, Grid, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material'
import { Payment } from '@mui/icons-material'
// import PercentTextField from '../../../../components/Fields/PercentTextField'
import GenericValueField from '../../../../components/Fields/GenericValueField'

// PREVISÃO DE PAGAMENTO
export default function FormPaymentForecast({ data, handleChange }) {
  // console.log('FormPaymentForecast')
  function ccyFormat(num) {
    var aux_num = num
    if (typeof aux_num === 'string') {
      aux_num = parseFloat(num)
    }
    return (aux_num?.toFixed(2)).replace('.', ',')
  }

  // calcula o valor de porcentagem sobre um valor
  function calcValue(percent, value) {
    var aux_value = value
    if (typeof value === 'string') {
      aux_value = parseFloat(value)
    }
    var aux_val = aux_value * (parseFloat(percent || '0') / 100)

    return (aux_val.toFixed(2)).replace('.', ',')
  }

  let title_txt = /receber/.test(data.operationType) ? 'Valor de recebimento' : 'Valor de pagamento'
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Payment sx={{ mr: 1 }} />
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>{title_txt}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer>
            <Table sx={{ minWidth: 400 }} aria-label="table_previsao" size={'medium'}>
              <TableBody>
                <TableRow>
                  <TableCell >Valor Total</TableCell>
                  <TableCell align="right">R$</TableCell>
                  <TableCell align="right">{ccyFormat(data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Juros</TableCell>
                  <TableCell align="right">
                    {/* <PercentTextField
                      handleChange={handleChange}
                      options={{
                        required: false,
                        label: '',
                        name: 'fee',
                        id: 'fee',
                        color: 'success',
                        value: data.fee,
                        variant: 'outlined',
                      }}
                    /> */}
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"fee",
                        name:"fee",
                        label: "",
                        value: data.fee,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.fee, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Multa</TableCell>
                  <TableCell align="right">
                    {/* <PercentTextField
                      handleChange={handleChange}
                      options={{
                        required: false,
                        label: '',
                        name: 'penalty',
                        id: 'penalty',
                        color: 'success',
                        value: data.penalty,
                        variant: 'outlined',
                      }}
                    /> */}
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"penalty",
                        name:"penalty",
                        label: "",
                        value: data.penalty,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.penalty, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Desconto</TableCell>
                  <TableCell align="right">
                    {/* <PercentTextField
                      handleChange={handleChange}
                      options={{
                        required: false,
                        label: '',
                        name: 'discount',
                        id: 'discount',
                        color: 'success',
                        value: data.discount,
                        variant: 'outlined',
                      }}
                    /> */}
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"discount",
                        name:"discount",
                        label: "",
                        value: data.discount,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.discount, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: '20px' }}>
                    Valor final
                  </TableCell>
                  <TableCell style={{ fontSize: '20px' }} align="right">
                    R$
                  </TableCell>
                  <TableCell style={{ fontSize: '20px' }} align="right">
                    <strong>{ccyFormat(data.value_end)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
