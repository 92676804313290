import React from "react";
import "./sucess.css";
import { Checkmark } from "react-checkmark";
import {
  Card,
  Typography,
} from '@mui/material';
export default function SucessSaveUser() {
  return (
    <Card sx={{ width: 270, p: 1, m: 1 }}>
      <Checkmark size={'100'} />
      {/* <Typography variant="h6" sx={{ mt: 1 }} >
        Usuário criado com sucesso!
      </Typography> */}
    </Card>
  )
}