import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Fab, IconButton, Typography } from '@mui/material';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import FormPropertyArea from '../../mapsAreasDraw/components/FormMapId/FormSaveMapButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import FormSaveEditMapButton from './FormSaveEditMapButton';
import { toast } from 'react-toastify';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function SaveMapEditButton({ path, area, setArea, areasMap_data }) {
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!path || path.length < 1) {
      toast.warning('Desenhe uma área de no mínimo 3 lados!');
      return false;
    }
    setAnchorEl(event.currentTarget);
    toggleDrawer('left', true);
  };
  function handleClose() {
    setAnchorEl(null);
    toggleDrawer('left', false);
  }

  return (
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <LightTooltip title="Salvar área" placement="left">
            <Fab
              sx={{ position: 'fixed', top: 65, right: 18 }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              //onClick={toggleDrawer(anchor, true)}
              onClick={handleClick}
              color="success"
              aria-label="add"
              size="medium"
            >
              <SaveIcon fontSize="small" sx={{ mr: 0 }} />

              {/* <HighlightAltIcon sx={{ mr: 0.5 }} />
            Área */}
            </Fab>
          </LightTooltip>

          <Drawer anchor={anchor} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 380 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <Typography sx={{ p: 2 }} variant="BUTTON TEXT">
                Criação da Área1
              </Typography>
              <IconButton sx={{ ml: 'auto' }} color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Divider />
              <FormSaveEditMapButton handleClose={handleClose} path={path} area={area} setArea={setArea} areasMap_data={areasMap_data} />
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}
