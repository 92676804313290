import Footer from "../../components/Footer/Footer";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import ActivitiesCreationsContent from "./ActivitiesCreationsContent";

function ActivitiesCreations() {
  return (
    <>
      {/* <Nav /> */}
      {/* <NavSide /> */}
      {/* <NavBotton /> */}
      <ActivitiesCreationsContent />
      <Footer />
    </>
  );
}

export default ActivitiesCreations;
