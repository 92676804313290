import * as React from 'react';
import { Divider, Grid } from '@mui/material';
import FooterModal from '../../../../components/Footer/FooterModal';
// import dayjs from 'dayjs';
import FormPaymentCondition from '../../../financial/components/Forms/FormPaymentCondition';
import FormPaymentForecast from '../../../financial/components/Forms/FormPaymentForecast';
import FormPaymentInstallments from '../../../financial/components/Forms/FormPaymentInstallments';

export default function FormMaintenancenStep4({ data, obj_fn }) {
  const isError = () => {
    // if (!data.value || data.error_date) {
    //   return true;
    // }
    return false;
  };
  const returnError = () => {
    return false;
  };

  const handleChange = (event, blur) => {
    if (blur == 'checkbox') {
      obj_fn.setData((prevState) => ({ ...prevState, ['payed']: event.target.checked }));
    } else {
      var field = event.target.name;
      var value = event.target.value;
      if (field == 'fee' || field == 'penalty' || field == 'discount') {
        value = parseFloat(value)
        var aux_val = parseFloat(data.value);
        // porcentagens do valor total
        var fee = (aux_val * parseFloat(field == 'fee' ? value : data.fee)) / 100;
        var penalty = (aux_val * parseFloat(field == 'penalty' ? value : data.penalty)) / 100;
        var discount = (aux_val * parseFloat(field == 'discount' ? value : data.discount)) / 100;

        var value_aux = aux_val + fee + penalty - discount;
        obj_fn.setData((prevState) => ({ ...prevState, ['value_end']: value_aux }));
      }

      obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Monta o obj e função para o campo de data do lançamento
  // const handleChangeDate = (value) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, date_start: dayjs(value), }));
  //   obj_fn.setData((prevState) => ({ ...prevState, date_start_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'), }));
  //   obj_fn.setData((prevState) => ({ ...prevState, date_start_fc: dayjs(value).format('DD/MM/YYYY'), }));

  //   obj_fn.setData((prevState) => ({ ...prevState, dueDate: dayjs(value) }));
  //   obj_fn.setData((prevState) => ({ ...prevState, dueDate_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'), }));
  //   obj_fn.setData((prevState) => ({ ...prevState, dueDate_fc: dayjs(value).format('DD/MM/YYYY'), }));
  // };

  // let obj_date = {
  //   label: 'Data do lançamento',
  //   field_data: 'date_start',
  //   text_helper: 'Dia de execução da competencia.',
  //   min: data.date_start,
  //   handleChange: handleChangeDate,
  //   //maxWidth: '250px',
  //   setData: obj_fn.setData,
  // };
  let obj_date = false;

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
        {/* Condições pagamento */}
        <Grid item xs={12} md={12}>
          <FormPaymentCondition data={data} handleChange={handleChange} obj_date={obj_date} />
        </Grid>
        {/* Previsão de pagamento */}
        <Grid item xs={12} md={12}>
          <FormPaymentForecast data={data} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider sx={{ mt: 2 }} />
        </Grid>
        {/* Parcelas do pagamento */}
        {data.installment !== 'a_vista' && (
          <Grid item xs={12} md={12}>
            <FormPaymentInstallments data={data} obj_fn={obj_fn} />
          </Grid>
        )}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
