import React from 'react';
import { Box, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FinancialChoiceContentTabs from './FinancialChoiceContentTabs';
import TutorialFinancial from './components/Tutorial/TutorialFinancial';
import RegisterPerson from '../person/components/addPerson/RegisterPerson';
import Typewriter from '../../components/Typewriter/Typewriter';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import CustomizedMenusFinancial from './components/CustomizedMenus/CustomizedMenusFinancial';
// import RegisterPerson from './components/SingleRegsiter/RegisterPerson';

export default function FinancialContent() {
  // const [isLoadingText, setIsLoadingText] = useState(false);
  // setTimeout(() => {
  //   setIsLoadingText(true);
  // }, 500);

  const objText = {
    icon: <LocalAtmIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Financeiro', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Realize o controle financeiro da sua propriedade, visualizando todas as suas contas, despesas e receitas.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: [
      '1- Registrar clientes/Fornecedores',
      '2- Registrar uma compra e o lançamento no financeiro',
      '3- Registrar uma Venda e o lançamento no financeiro',
      '4- Contas gerais a pagar',
      '5- Contas gerais a receber',
      '6- Criar uma nova conta financeira para controle',
      '6- Gerenciar o finaceiro',
    ], // Texto complementar ou final
    linkButton: ['openPeopleRegistrarModal', 'openBuyRegistrarModal', 'openSalesRegistrarModal', 'openExpenseRegistrarModal', 'openIncomeRegistrarModal', 'openAddBankRegistrarModal', '/financial'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={7}>
              <Box sx={{ display: 'flex' }}>
                <LocalAtmIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Financeiro
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Gerencia&nbsp;o financeiro&nbsp;da&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              {/* <RegisterPerson /> */}
              <CustomizedMenusFinancial />
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-start">
                <CustomizedMenusProductionCost />
              </Box>
            </Grid> */}
            <Grid item xs={12} md={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialFinancial />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Box sx={{ minHeight: 35 }}>
              <Typography style={{ color: '#2e7d32' }}>
                <div className="d-lg-none">
                  <Typewriter tam={30} minHeight={0} text=" Bem-vindo ao controle financeiro." />
                </div>
                <div className="d-none d-lg-block">
                  <Typewriter tam={30} minHeight={0} text="Controle suas contas bancárias, suas contas a pagar e receber, organize seu fluxo de caixa, vendas, e muito mais." />
                </div>
              </Typography>
            </Box>
          </Grid>
        </div>
      </div>

      {/* <Card sx={{ m: 2 }}>
        <CardHeader
          title={
            <>
              <div className="d-lg-none">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 16, color: '#2e7d32' }}>
                    {' '}
                    Bem-vindo ao controle financeiro: controle suas contas bancárias, suas contas a pagar e receber, organize seu fluxo de caixa, vendas, e muito mais.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 14 }}> Centralize suas contas bancárias em um único sistema.</Typography>
                </Box>
                
              </div>

              <div className="d-none d-lg-block">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 16, color: '#2e7d32' }} color="textPrimary">
                    Bem-vindo ao controle financeiro: controle suas contas bancárias, suas contas a pagar e receber, organize seu fluxo de caixa, vendas, e muito mais.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 14 }}> Centralize suas contas bancárias em um único sistema.</Typography>
                </Box>
                
              </div>
            </>
          }
        ></CardHeader>

        <Divider />
      </Card> */}

      <div className="content mt-1">
        <div className="container-fluid">
          <FinancialChoiceContentTabs />
        </div>
      </div>
    </div>
  );
}
