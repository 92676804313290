import { useQuery } from "react-query";
import api from "../services/api";

export const apiGetPermissionRoleDefault = async () => {
  const header = { header: { "Content-Type": "application/json" } };
  const response = await api.get("/permissions/list", header);
  //console.log('apiGetPermissionRoleDefault');
  // console.log(response);
  if (response.data.isSuccess) {
    localStorage.setItem("userPermissionsRolesDefault", JSON.stringify(response.data._value));
    return response.data._value;
  }
};


// com esse hook, adicionamos cache em cima da função apiGetPermissionRoleDefault
export default function useGetPermissionRoleDefaultRepos() {

  const onSuccess = () => {
    console.log('Sucesso: Executar o efeito após buscar os dados')
  }

  const onError = (error) => {
    console.log('Erro: Executar o efeito após encontrar o erro', error)
  }

  return useQuery(['apiGetPermissionRoleDefault'], apiGetPermissionRoleDefault,
    {
      onSuccess,
      onError
    })

}
