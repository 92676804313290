import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Context } from '../components/Context/AuthContext';
import ForgotPasswordContent from '../pages/main/ForgotPassword/ForgotPasswordContent';
import Login from '../pages/main/Login/Login';
import ResetPassword from '../pages/main/ResetPassword/ResetPassword';
import Landpage from '../pages/main/ForgotPassword/Landpage';

function SignRoutes() {
  const { authenticated, handleLogin } = useContext(Context);

  // if (localStorage.getItem("authenticated") == 'true') {
  //     handleLogin();
  // }

  //console.log('SignRoutes')
  //console.log(authenticated)

  // if (authenticated == undefined) {
  //     localStorage.setItem("authenticated", false);
  // }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPasswordContent />} />
      <Route path="/reset/:token" element={<ResetPassword />} />
      <Route path="/landpage" element={<Landpage />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default SignRoutes;
