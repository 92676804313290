import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../../components/Context/AuthContext";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AppBar, Divider, Toolbar, TextField, Container } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import Draggable from 'react-draggable';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Span } from "../../../../components/Typography";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DeleteLivestock({ selected, usestore, tab_active }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const [title, setTitle] = useState('');
  const { aux_add, setAuxAdd } = useContext(Context);
  const property_id = (localStorage.getItem("Property") || 1);
  const reposLivestocks = usestore.reposLivestock;
  const reposAreasMap = usestore.reposAreas;
  //const creationHarvestRepos = JSON.parse(localStorage.getItem("creationHarvestRepos"));
  
  useEffect(() => {
    if (open) {
      var livestocks = Object.keys(selected).map((item) => selected[item]);
      setData(livestocks);
      setTitle(tab_active == 'excluida' ? 'As criações serão excluidas definitivamente!' : "As criações serão movidas para a lixeira.");
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (tipo) => {
    console.log('deletando: ');
    console.log(data);
    const header = { header: { "Content-Type": "application/json" } };
    const data_aux = Object.assign([], data);
    data_aux.map((item) => {
      item.icon = ''
      return item;
    })
    
    if (tab_active == 'excluida') {
      const res = await api.put("/livestock/delete", data, header)
        .then((response) => {
          if (!response.data.isSuccess) {
            return toast.error("Erro ao deletar as Criaçõs!");
          }
          toast.success(`Criações excluidas com sucesso!`);
          usestore.reposUser.rmSettingsLH(data, 'livestock');
          reposAreasMap.fetch({ 'id_farm': property_id });
          usestore.reposProperty.fetch();
          setTimeout(() => {
            reposLivestocks.fetch({ 'id_farm': property_id }).then((response) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
                handleClose();
              }, 200);
            });
          }, 300);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erro ao deletar as Criaçõs!");
        });
    } else {
      var data_situacao = {
        'livestock': data,
        'situation': 'excluida',
        'isDeleted': 1,
        'delete': true,
        'status': false,
        'date': dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }
      console.log(data_situacao);

      const res = await api.put("/livestock/update", data_situacao, header)
        .then((response) => {
          if (!response.data.isSuccess) {
            return toast.error("Erro ao mover Criações para a lixeira!");
          }
          toast.success(`Criações movidas para a lixeira com sucesso!`);
          (data_situacao.livestock).map((item) => {
            reposLivestocks.updateLivestockStore(item.id, 'situation', data_situacao.situation);
            reposLivestocks.updateLivestockStore(item.id, 'data_end', data_situacao.date);
            reposLivestocks.updateLivestockStore(item.id, 'areas', []);
          });
          reposAreasMap.fetch({ 'id_farm': property_id });
          setTimeout(() => {
            setAuxAdd(aux_add + 1);
            handleClose();
          }, 200);
        })
        .catch((err) => {
          console.log(err)
          toast.error("Erro ao mover Criações para a lixeira!");
        });
    }
  };

  return (
    <>
      <div>
        <IconButton aria-label="edicao" onClick={() => setOpen(true)} title="Excluir">
          <DeleteForeverIcon sx={{ color: red[600] }} />{" "}
        </IconButton>

        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={"sm"}>
          <DialogTitle>
            <div className="d-lg-none">
              <Toolbar sx={{ height: 50 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} alignItems="center">
                    <Typewriter text={title} variant={"title"} color={red[800]} />
                  </Grid>
                </Grid>
                <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <CloseIcon /> </IconButton>
              </Toolbar>
            </div>
            <div className="d-none d-lg-block">
              <Toolbar>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} alignItems="center">
                    <Typewriter text={title} variant={"h6"} color={red[800]} />
                  </Grid>
                </Grid>
                <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <CloseIcon /> </IconButton>
              </Toolbar>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ mb: 6, mt: -3, textAlign: "center" }}>
            <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
              <Grid container spacing={1} sx={{ mb: 1.1 }}>
                {data.map((criacao) => (
                  <Grid item xs={12} sm={12} key={criacao.id}>
                    <Grid container spacing={2}>
                      <Grid item sx={{ width: 50 }}>
                        {criacao.icon}
                      </Grid>
                      <Grid item >
                        <Typography variant="body2" component="div">
                          {criacao.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </DialogContent>
          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                <Button color="info" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>fechar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                <Button variant="outlined" color="error" onClick={handleDelete}>
                  <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div"> deletar </Typography>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Dialog>
      </div>
    </>
  );
}
