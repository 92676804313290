import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/system";
import { toast } from "react-toastify";
import moment from "moment";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import { Card, CardContent, CardMedia, Stack, Typography, Container, Grid, Paper } from "@mui/material";
import { ArrowUpward, ArrowDownward, Close, DeleteSweep, Image, Search } from '@mui/icons-material';
import { H6, Span } from "../../components/Typography";
import Upload from "../../components/Upload/Upload";
import Loading from "../../components/Loader/Loading";
import useStore from "../../store/useStore";
import api from '../../services/api';
import CardHeard from "./CardHeard";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const returnArrow = (valor) => {
  if (valor >= 0) {
    return <ArrowUpward style={{ color: '#28a745' }} />;
  } else {
    return <ArrowDownward style={{ color: '#ff0000' }} />;
  }
};

const FullDialogCardAnimal = ({ animal }) => {
  const { id } = useParams();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [animal_images, setAnimalImages] = useState([]);

  const property_id = (localStorage.getItem("Property") || 1);
  animal.tipo_item = 'animal';
  animal.id_farm = property_id;

  // ------ inicicia os repos
  const usestore = useStore();
  // seta opções das Images
  const useImagesRepos = usestore.reposImages;
  const listAcitivy = JSON.parse(JSON.stringify(usestore.reposLivestock.getLivestockStore('id', id)[0]));

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function calcGrowth(index, tipo, arrow) {
    if (!tipo || !animal.weight_list[index + 1]) {
      return arrow ? "" : "-";
    }
    var last_grow = animal.weight_list[index + 1][tipo];
    var atual = animal.weight_list[index][tipo];
    var valor = atual - last_grow;

    if (!arrow) {
      return (valor == 0 ? '-' : valor.toFixed(2)) + ' ';
    } else {
      return valor == 0 ? '' : returnArrow(valor);
    }
  }
  function calculaGmd(index) {
    if (!animal.weight_list[index + 1]) {
      return "-";
    }
    let d1 = new Date(animal.weight_list[index].date);
    let d2 = new Date(animal.weight_list[index + 1].date);
    let dias = d1.getDate() - d2.getDate();

    let gmd_item = animal.weight_list[index].weight - animal.weight_list[index + 1].weight;
    let gmd_item_f = (gmd_item / (dias || 1)).toFixed(2);

    return gmd_item_f;
  }

  function getWeightsAnimal(index, tipo) {
    return (<>
      {animal.weight_list.length > 0 ?
        animal.weight_list.map((pesagem, index) => (
          <tr key={6 + index}>
            <td>{formatData(pesagem.date)}</td>
            <td>{pesagem.weight}</td>
            <td>{calcGrowth(index, 'weight')} {calcGrowth(index, 'weight', true)}</td>
            <td>{calculaGmd(index)}</td>
          </tr>
        )) :
      (<tr key={6 + index} style={{textAlign: 'center'}}>
        <td colSpan={5}>Nenhuma Pesagem realizada</td>
      </tr>)
      }
    </>)
  }

  function formatData(Inputdata) {
    if (!Inputdata) {
      return "";
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  const handleGetImages = async (img_key) => {
    const listImageRepos = useImagesRepos.getImageStore("animal", animal.id);
    const itemData = listImageRepos.filter((img) => {
      if (img_key && img.image != img_key) {
        // deleta a outra imagem do animal
        useImagesRepos.delImageStore(img.image);
        api.delete(`/upload/${img.image}/1`);
      } else if (img.isDeleted == 0) {
        return img.img;
      }
    });
    animal.image = itemData[0] ? itemData[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`;
    //animal.image = itemData[0] ? itemData[0].img : "/dist/img_fp/rebanho-nelore.png";
    setAnimalImages(animal.image);
  };
  const handleDelete = async () => {
    const listImageRepos = useImagesRepos.getImageStore("animal", animal.id);
    if (!listImageRepos[0]) {
      return;
    }
    const res = await api
      .delete(`/upload/${listImageRepos[0].image}/0`)
      .then((response) => {
        useImagesRepos.updateImageStore(listImageRepos[0].image, "isDeleted", 1);
        toast.success("Imagem movida para a lixeira com sucesso!");
        setTimeout(() => {
          handleGetImages();
        }, 20);
      })
      .catch(() => {
        toast.error("Erro ao mover a imagem para a lixeira!");
      });
  };

  useEffect(() => {
    if (open) {
      handleGetImages();
    }
    setIsLoading(true);
  }, [open]);

  function print(e) {
    var content = document.getElementById("root");
    content.classList.add("no-print");
    window.print();
    content.classList.remove('no-print');
  }

  return (
    <Box>
      <IconButton variant="contained" color="success" onClick={handleClickOpen} title="Ficha do Animal">
        <Search />
      </IconButton>
      {isLoading === false ? (<Loading />) : (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }} color="" className="no-print">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <Close />
              </IconButton>
              <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0"> Ficha do Animal{" "} </H6>
              <div className="no-print">
                <button onClick={print} id="btnPrint" className="btn btn-default float-right">
                  <i className="fas fa-print " /> Imprimir
                </button>
              </div>
            </Toolbar>
          </AppBar>

          <section className="content " id={"boxCardAnimal_" + animal.id}>
            <div className="container-fluid " style={{ maxWidth: 900 }}>
              <Card sx={{ display: 'block', textAlign: 'center' }}>
                <CardContent sx={{ flex: '1 0 auto' }} >
                  <Typography component="div" variant="h5"> Ficha do animal </Typography>
                </CardContent>
              </Card>

              <Container component="main" maxWidth="lg" sx={{}}>
                <Paper variant="" sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <CardHeard image_livestok={animal_images || animal.image_livestock} item={animal} callBack={handleGetImages} callBackHook={useImagesRepos} handleDelete={handleDelete} />
                    </Grid>
                  </Grid>
                </Paper>
              </Container>

              <Card sx={{ display: 'flex', width: "100%" }}>
                <div className="card-body table-responsive ">
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={3} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Idenificação</th>
                        <th>Id brinco</th>
                        <th>Id SISBOV</th>
                        <th>Nome</th>
                      </tr>
                      <tr key={4}>
                        <td>{animal.identifier}</td>
                        <td>{animal.brinco}</td>
                        <td>{animal.sisbov}</td>
                        <td>{animal.name}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="22%" style={{ "width": "22%" }} />
                      <col colwidth="22%" style={{ "width": "22%" }} />
                      <col colwidth="35%" style={{ "width": "35%" }} />
                      <col colwidth="22%" style={{ "width": "22%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={1} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Espécie</th>
                        <th>Sexo</th>
                        <th>Raça</th>
                        <th>Nascimento</th>
                      </tr>
                      <tr key={2}>
                        <td>{animal.group_name}</td>
                        <td>{animal.sexo}</td>
                        <td>{animal.raca}</td>
                        <td>{formatData(animal.date_birth)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="35%" style={{ "width": "35%" }} />
                      <col colwidth="18%" style={{ "width": "18%" }} />
                      <col colwidth="18%" style={{ "width": "18%" }} />
                      <col colwidth="29%" style={{ "width": "29%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={3} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Lote</th>
                        <th>Fase de Manejo</th>
                        <th>Peso atual</th>
                        <th>Idade</th>
                      </tr>
                      <tr key={4}>
                        <td>{animal.lote}</td>
                        <td>{animal.fase_name}</td>
                        <td>{animal.weight ? animal.weight + " (" + animal.weight_unit + ")" : '-'}</td>
                        <td>{animal.idade}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
              <Card sx={{ width: "100%", marginBottom: '55px', mt: 2 }}>
                <Typography sx={{ ml: 2.5 }} component="div" variant="h6"> Pesagens: </Typography>
                <div className="card-body table-responsive " >
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={5} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Data</th>
                        <th>Peso ({animal.weight_unit})</th>
                        <th>Crescimento ({animal.weight_unit})</th>
                        <th>GMD ({animal.weight_unit})</th>
                      </tr>
                      {getWeightsAnimal()}
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
            <br />
          </section>

          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }} className="no-print">
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="contained" type="submit" onClick={handleClose}>
                  <Span sx={{ textTransform: "capitalize" }}>Fechar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Dialog>
      )}
    </Box>
  );
};

export default FullDialogCardAnimal;