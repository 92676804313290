import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Box, Menu, MenuItem, Divider, IconButton, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { blue, green, grey, red } from "@mui/material/colors";
import api from "../../../services/api";
import { Context } from "../../../components/Context/AuthContext";
import useMessengerReposStore from "../../../store/useMessengerReposStore";

export default function MenuCard({ id, setIsLoading, setselected }) {
  console.log('MenuCard');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { aux_add, setAuxAdd } = useContext(Context);
  const display_demo = JSON.parse(localStorage.getItem("display"));
  const reposMessenger = useMessengerReposStore(state => state);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id) => {
    console.log('handleDelete');
    const isDeleted = "0";
    const res = await api.delete(`/messenger/posts/${id}/${isDeleted}`)
      .then((response) => {
        toast.success("Solicitação deletada com sucesso!");
        reposMessenger.updateMessengerStore(id, 'isDeleted', '1');
        setTimeout(() => {
          setAuxAdd(aux_add + 1);
          if (setselected) {
            setselected(null);
          }
          setIsLoading(true);
        }, 200);
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error("Erro ao deletar a Solicitação!");
      })
  };

  const handleStatus = async (status) => {
    console.log('handleStatus');
    //localStorage.setItem('statusMessenger', JSON.stringify(status))
    //['Resolver'->0, 'Resolvido'->1, 'Não Resolvido'->2, 'Parado'->3];
    const res = await api.put(`/messenger/posts/${id}/${status}`)
      .then((response) => {

        reposMessenger.updateMessengerStore(id, 'status', JSON.stringify(status));
        setTimeout(() => {
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            toast.success("Solicitação atualizada com sucesso!");
          }, 1200);
          if (setselected) {
            setselected(null);
          }
          setIsLoading(true);
        }, 200);

      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error("Erro ao atualizar a Solicitação!");
      })
  };

  return (
    <>
      {true &&
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
            <Tooltip title="Editar Situação">
              <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                <MoreVertIcon sx={{ color: "#808080" }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleStatus(0)} > <PlayCircleOutlineIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: blue[500] }} />  Resolver</MenuItem>
            <MenuItem onClick={() => handleStatus(1)}><CheckCircleOutlineIcon fontSize="small" sx={{ mr: 0.5, mt: -0.4 }} style={{ color: green[500] }} />Resolvido</MenuItem>
            <MenuItem onClick={() => handleStatus(2)}> <DoDisturbIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: grey[500] }} /> Parado</MenuItem>
            {/* <MenuItem onClick={() => handleStatus(2)}>Não Resolvido</MenuItem> */}


            {display_demo ? (<div></div>) : (
              <>
                <Divider />
                <MenuItem onClick={() => handleDelete(id)}>
                  <DeleteForeverIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: red[500] }} />
                  Lixeira
                </MenuItem>
              </>

            )}
            {/* <MenuItem>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              sair
            </MenuItem> */}
          </Menu>
        </React.Fragment>
      }
    </>
  );
}
