import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

function ActiveStepContent0() {
  return (
    <StepContent>
      <Typography>
        Para registrar a aŕea da propriedade no mapa, pesquise a localização da sua propriedade no canto superior direito, em "DIGITE O ENDEREÇO DESEJADO" e selecione o endereço indicado pela Google.
        Caso a pesquisa da Google não o encontre, insira o endereço de alguma referência perto da sua propriedade.
      </Typography>
    </StepContent>
  );
}

function ActiveStepContent1() {
  return (
    <StepContent>
      <Typography>Localizado à área, basta clicar no botão verde no canto superior direito "ÁREA" e clicar em atualizar, que o software registrará a área da sua propriedade.. </Typography>
    </StepContent>
  );
}

function ActiveStepContent2() {
  return (
    <StepContent>
      <Typography>
        Registrado a área da propriedade é possível visualizar o clima da sua propriedade. Basta clicar no canto superior esquerdo no botão branco circular com imagem de temperatura.
      </Typography>
    </StepContent>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    //"& .MuiStepIcon-active": { color: "green" },
    //"& .MuiStepIcon-completed": { color: "green" },
    "& .MuiStepIcon-root": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#808080" },
    "& .MuiButton-text .MuiButton-textSecondary": { color: "#808080" },
  },
}));

export default function StepperLivestock({ number }) {
  const [activeStep, setActiveStep] = React.useState(number);
  const colorStep = useStyles();
  const steps = ["Pesquisar a área da sua propriedade", "Registrar a área da propriedade", "Visualizar a previsão do tempo da sua área"];

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper className={colorStep.root} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={Step + index}>
            <StepLabel>
              <strong style={{ fontSize: 16 }}>{step}</strong>
            </StepLabel>
            {/* <StepContent >
                            <Typography variant="body2" gutterBottom>{step.description}</Typography>
                        </StepContent> */}
            {index == 0 && <ActiveStepContent0 />}
            {index == 1 && <ActiveStepContent1 />}
            {index == 2 && <ActiveStepContent2 />}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
