import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, CardContent, CardHeader, Divider, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import Typewriter from '../../../../components/Typewriter/Typewriter';

export default function TablesCardPeople({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 750);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 2000);

  const text = "Para registrar novos clientes, fornecedores ou transportadodoras, clique no botão (+ CADASTRAR) no canto superior direito.";

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Card sx={{ m: 2 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    {/* {' '}
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos cadastrar uma safra para iniciarmos o gerenciamento da produção?</Typography>
                    </Box> */}
                    <Box >
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter
                            tam={130}
                            minHeight={0}
                            text={text}
                          />
                        )}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box >
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter
                            tam={30}
                            minHeight={0}
                            text={text}
                          />
                        )}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>
            <CardContent>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  Clique na aba desejada para visualizar a lista de clientes, fornecedores ou transportadoras
                </Typography>
              </Box>
            </CardContent>
            <Divider />
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
