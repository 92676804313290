import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../../components/Context/AuthContext";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AppBar, Divider, Toolbar, TextField, Container } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import Draggable from 'react-draggable';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Span } from "../../../../components/Typography";
import { blue, red } from "@mui/material/colors";
import { AddBox, Close, DeleteForever, IndeterminateCheckBox, Loop } from "@mui/icons-material";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DeletePerson({ selected, usestore, tab_active, local }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const [title, setTitle] = useState('');
  const { aux_add, setAuxAdd } = useContext(Context);
  const id_property = (localStorage.getItem("Property") || 1);
  const reposPersons = usestore.reposPerson;
  
  useEffect(() => {
    if (open) {
      var persons, aux_title = '';
      if (tab_active[1] == 'Lixeira') {
        if (local == 'table') {
          persons = [{'id': selected.id, 'name': selected.name, 'id_account': selected.id_account}];
          aux_title = tab_active[0] + ': será excluido definitivamente!';
        } else { //restaurar
          persons = Object.keys(selected).map((item) => selected[item]);
          aux_title = tab_active[0] + ': será restaurado!';
        }
      } else {
        persons = Object.keys(selected).map((item) => selected[item]);
        aux_title = tab_active[0] + ": será movido para a lixeira.";
      }
      setData(persons);
      setTitle(aux_title);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    console.log('deletando: ');
    const header = { header: { "Content-Type": "application/json" } };
    let msg_sucess = '';
    let msg_erros = '';

    var data_delete = {
      'id_property': id_property,
      'persons': data,
      'status': 'trash',
      'type': tab_active[0],
    }
    
    if (tab_active[1] == 'Lixeira') {
      if (local == 'table') {
        msg_sucess = tab_active[0] + ": excluido com sucesso!";
        msg_erros = "Erro ao deletar " + tab_active[0] + "!\nTente novamente mais tarde";
        data_delete.status = 'delete';
      } else {
        msg_sucess = tab_active[0] + ": restaurado com sucesso!";
        msg_erros = "Erro ao restaurar " + tab_active[0] + "!\nTente novamente mais tarde";
        data_delete.status = 'restore';
      }
    } else {
      msg_sucess = tab_active[0] + ": movido para a lixeira com sucesso!";
      msg_erros = "Erro ao mover " + tab_active[0] + " para a lixeira!\nTente novamente mais tarde";
      data_delete.status = 'trash';
    }

    const res = await api.put("/person/delete", data_delete, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error(msg_erros);
        }
        toast.success(msg_sucess);

        reposPersons.fetch({ 'id_property': id_property }).then((res) => {
          setTimeout(() => {
            setAuxAdd(aux_add + 1);
            handleClose();
          }, 100);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(msg_erros);
      });
  };

  const title_icon = local == 'tooltip' && tab_active[1] == 'Lixeira' ? "Restaurar" : "Excluir";
  const colors = local == 'tooltip' && tab_active[1] == 'Lixeira' ? blue[800] : red[800];
  return ( <>
    <div>
      <IconButton aria-label="edicao" onClick={() => setOpen(true)} title={title_icon}>
        {local == 'tooltip' && tab_active[1] == 'Lixeira' ?
          <Loop sx={{ color: blue[600] }} /> :
          <DeleteForever sx={{ color: red[600] }} />
        }{" "}
      </IconButton>

      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={title} variant={"title"} color={colors} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={title} variant={"h6"} color={colors} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ mb: 6, mt: -3, textAlign: "center" }}>
          <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1.1 }}>
              {data.map((person) => (
                <Grid item xs={12} sm={12} key={person.id}>
                  <Grid container spacing={2}>
                    <Grid item sx={{ width: 30 }}>
                      {local == 'tooltip' && tab_active[1] == 'Lixeira' ?
                        <AddBox color="success" /> :
                        <IndeterminateCheckBox color="error" />
                      }
                    </Grid>
                    <Grid item >
                      <Typography variant="body2" component="div">
                        {person.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button color="info" variant="outlined" type="submit" onClick={handleClose}>
                <Span>fechar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              {local == 'tooltip' && tab_active[1] == 'Lixeira' ?
                <Button variant="outlined" color="success" onClick={handleDelete}>
                  <Typography sx={{ mt: 1, color: blue[800] }} gutterBottom variant="subtitle1" component="div"> restaurar </Typography> :
                </Button> :
                <Button variant="outlined" color="error" onClick={handleDelete}>
                  <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div"> deletar </Typography>
                </Button>
              }
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  </> );
}
