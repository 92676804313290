import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, ButtonBase, Container, Paper, Stack, Typography } from '@mui/material';
import { Checkmark } from "react-checkmark";
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import DownloadAnimation from '../../../../components/Animations/DownloadAnimation';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import Checkv1 from '../../../../components/Animations/Checkv1';
import Checkv2 from '../../../../components/Animations/Checkv2';
import Checkv3 from '../../../../components/Animations/Checkv3';
import SuccessMsnanimation from '../../../../components/Animations/SuccessMsnanimation';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        height: '100%',
    },
}));

export default function SuccessMsn() {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const classes = useStyles();

    const [isLoadingText, setIsLoadingText] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = () => {
        navigate("/areasmaps");
    };

    setTimeout(() => {
        setIsLoadingText(true)
    }, 200);

    setTimeout(() => {
        // handleButtonClick()
    }, 2500);

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };


    return (
        <>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={open}
                onClick={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ backgroundColor: 'transparent' }}
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    {/* <Checkmark size={'196'} /> */}
                    {/* <Checkv1 /> */}
                    <SuccessMsnanimation />
                    <Typography variant="h6" style={{ color: '#2e7d32', textAlign: 'center' }}>
                        Mensagem enviada!!!
                    </Typography>

                </DialogContent>

            </Dialog>
        </ >
    );
}