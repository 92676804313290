import * as React from 'react';
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, ListItem, ListItemText } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Close, } from '@mui/icons-material';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ModalContacts({ data, open, handleClose }) {

  return ( <>
    <Dialog open={open} onClose={() => handleClose(data.id)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} 
      sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", maxWidth: "650px" } } }}>
      <DialogTitle>
        <div className="d-lg-none">
          <Toolbar sx={{ height: 50 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text={"Contatos disponíveis para: " + data.name} variant={"title"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close"> <Close /> </IconButton>
          </Toolbar>
        </div>
        <div className="d-none d-lg-block">
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text={"Contatos disponíveis para: " + data.name} variant={"h6"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close"> <Close /> </IconButton>
          </Toolbar>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ mb: 4, textAlign: "center" }}>
        <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }} style={{marginTop: '0px'}} >
          <Grid item container alignItems="center" sx={{ my: 1 }}>
            {data.contacts.map((contact, index) => (
              <Grid key={index + '_' + contact.id + '_' + data.type} container spacing={3} style={{padding: '5px'}}>
                <Card style={{border: '1px solid #2e7d32'}} >
                  <CardHeader title={contact.name} />
                  <CardContent sx={{ mt: '-30px' }} >
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8}>
                        <ListItem>
                          <ListItemText primary="Email:" secondary={contact.email} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <ListItem>
                          <ListItemText primary="Cargo:" secondary={contact.office} />
                        </ListItem>
                      </Grid>
                    </Grid >
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Telefone Comercial:" secondary={contact.phoneCommercial} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Telefone Celular:" secondary={contact.phoneCell} />
                        </ListItem>
                      </Grid>
                    </Grid >
                  </CardContent>
                  {/* <CardActions disableSpacing>
                    <IconButton aria-label="IMprimir">
                      <Print />
                      <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1" component="div">
                        Imprimir
                      </Typography>
                    </IconButton>
                  </CardActions> */}
                </Card >
              </Grid>
            ))}
          </Grid>
        </Container>
      </DialogContent>
      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={() => handleClose(data.id)}>
              <span>fechar</span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Dialog>
  </> );
}
