import { Box, ButtonBase, Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: "120px",
  },
}));

export default function ListButtonImageTypeLivestock({ handleClickOpen }) {
  const classes = useStyles();
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  const geraButtonGeral = function (obj) {
    obj.color = obj.color || '#2e7d32';
    obj.border = obj.border || '1px solid #2e7d3280';
    setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, 750);

    return (
      // <Grid item style={{ padding: '20px', display: 'inline-block' }}>
      <>
        {isLoadingSkeleton ? (
          <>
            <Grid item xs={6} md={6} key={obj.name}>
              <Stack direction="column" alignItems="center">
                <Skeleton variant="rectangular" width="100%" height={120} />
              </Stack>
            </Grid>
          </>) : (

          <Grid item xs={6} md={6} key={obj.name}>
            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{ p: 2, borderRadius: 1 }}
              onClick={(e) => handleClickOpen(e, obj.title, obj.name)}
              className={classes.button}
            >
              <Stack direction="column" alignItems="center">
                <Box height={50}>
                  {obj.icon}
                </Box>
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  {obj.title}
                </Typography>
              </Stack>
            </ButtonBase>
          </Grid>
        )}
      </>
    );
  };

  return (<>
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      {geraButtonGeral({ title: "Pecuária de Corte", name: 'bovinocultura_de_corte', icon: (<i> <img src="/dist/img_fp/icon-button/icon-pecuaria.png" width="80" ></img> </i>) })}
      {geraButtonGeral({ title: "Pecuária de Leite", name: 'bovinocultura_de_leite', icon: (<i> <img src="/dist/img_fp/icon-button/icon-pecuariadeleite.png" width="80"></img> </i>) })}
      {geraButtonGeral({ title: "Suinocultura", name: 'suinocultura', icon: (<i> <img src="/dist/img_fp/icon-button/icon-suinos.png" width="80" ></img> </i>) })}
      {geraButtonGeral({ title: "Avicultura", name: 'avicultura_de_corte', icon: (<i> <img src="/dist/img_fp/icon-button/icon-galinha.png" width="80" ></img> </i>) })}
      {geraButtonGeral({ title: "Piscicultura", name: 'piscicultura', icon: (<i> <img src="/dist/img_fp/icon-button/icon-peixe.png" width="80" ></img> </i>) })}
      {geraButtonGeral({ title: "Outros", name: 'outras_corte', icon: (<i> <img src="/dist/img_fp/icon-button/icon-lupa-mais.png" width="80" ></img> </i>) })}
    </Grid>
  </>);
}
/*
apicultura
aquicultura
avicultura_de_corte
avicultura_de_postura
bovinocultura_de_corte
bovinocultura_de_leite
caprinocultura_de_corte
caprinocultura_de_leite
carcinocultura
cunicultura
equinocultura
piscicultura
suinocultura
ovinocultura_de_corte
ovinocultura_de_la
ovinocultura_de_leite
outras_corte
outras_producao
trabalho_bovino
trabalho_equino
*/
