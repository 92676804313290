import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
// import CurrencyTextField from '../../../../components/Fields/CurrencyTextField'
import WidgetsIcon from '@mui/icons-material/Widgets';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import useStore from '../../../../store/useStore';
import { useContext } from 'react';
import { Context } from '../../../../components/Context/AuthContext';
import AdjustIcon from '@mui/icons-material/Adjust'; // Ícone padrão para opções
import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore';
import { red } from '@mui/material/colors';
import GenericValueField from '../../../../components/Fields/GenericValueField';

const options = ['Entrada', 'Saída', 'Balanço'];

export default function StockEntryOut({ dataStockList, location_patrimony_array, open, handleClose, dataStock, setDataStock }) {
  console.log('StockEntryOut');
  console.log(dataStock);
  console.log(dataStockList);
  const [value, setValue] = React.useState(dataStock.typeMov == 'in' ? options[0] : options[1]);
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event) => {
    // console.log('handleChange')
    const { name, value } = event.target;
    // console.log(name, value)
    setDataStock((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // console.log(name, value)

    // Limpa o erro relacionado ao campo que está sendo alterado
    setFormError((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  // useEffect(() => {
  //   setDataStock((prevData) => ({
  //     ...prevData,
  //     typeMov: 'in',
  //   }))
  // }, [])

  // useEffect(() => {
  //   setDataStock((prevData) => ({
  //     ...prevData,
  //     id_product: editStockProduct.id,
  //     value_cost: editStockProduct.stock_cost,
  //     id_account: editStockProduct.id_account,
  //   }))
  // }, [editStockProduct])

  const validate = () => {
    // console.log('validate ')
    let valid = true;
    // console.log(dataStock.value_cost)
    // console.log(dataStock.quanty)
    // if (dataStock.value_cost == 0 || dataStock.value_cost == '0,00') {
    //   console.log('entrou')
    //   valid = false
    // }
    // console.log(!locationCurrentFilter)
    if (dataStock.quanty == 0 || dataStock.quanty == '' || dataStock.quanty == null) {
      valid = false;
    }
    //  if (dataStock.quanty == 0 || dataStock.quanty == '' || !locationCurrentFilter || !dataStock.value_cost) {
    //   valid = false
    // }
    return valid;
  };

  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  // armazenar os arays de erros de cada variável desejada
  const [formError, setFormError] = useState({});

  const handleReposUpdate = (event) => {
    // atualizar o produto
    usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposProducts')
    });
    // atualizar as finanças
    usestore.reposFinances.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances')
    });
    // atualizar a transação
    usestore.reposFinances.fetchT({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances - transactions')
    });
    // atualizar as atividades
    usestore.reposPatrimony.fetch({ id_farm: property_id }).then((res) => {
      // console.log('reposPatrimony - ')
      setAuxAdd(aux_add + 1);
      setTimeout(() => {
        handleClose();
      }, 200);
    });
  };

  const handleSubmit = async () => {
    // console.log('handleSubmit -StockEntry')
    // let formOk = validate() || false
    // console.log(formOk)
    // console.log(dataStock)

    // incializa o formulario de erros como vazia
    setFormError({});
    // incializa os erros
    let errors = {};
    // incializa o validador do submiit como true
    let formOk = true;

    if (!dataStock.quanty) {
      errors.quanty = true;
      formOk = false;
    }
    // if (!dataStock.value_cost) {
    //   errors.value_cost = true
    //   formOk = false
    // }
    if (!selectedValueLocation) {
      errors.selectedValueLocation = true;
      formOk = false;
    }

    // coleta os eeros
    setFormError(errors);
    // console.log(dataStock)

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('inventory/control', dataStock, header)
        .then((response) => {
          // console.log('response.data')
          // console.log(response.data)
          if (response.data.isSuccess) {
            toast.success('Estoque atualizado');
            handleReposUpdate();
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao atualizar o estoque, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
      setFormError(errors);
      // console.log(formError)
    }
  };

  //location
  // useEffect(() => {
  //   usestore.reposProducts.fetch({ id_property: property_id })
  //   usestore.reposPatrimony.fetch({ id_property: property_id })
  // }, [])

  const reposPatrimony = usePatrimonyReposStore((state) => state);
  // console.log(reposPatrimony)
  // console.log(reposPatrimony.listPatrimony)

  const reposPatrimonyFilter = reposPatrimony.listPatrimony.filter((item) => item.id_property == property_id);
  // console.log('reposPatrimonyFilter')
  // console.log(reposPatrimonyFilter[0])

  const [listStockLocation, setListStockLocation] = useState(reposPatrimonyFilter);
  // console.log('listStockLocation')
  // console.log(listStockLocation)

  // const locationCurrentFilter = listStockLocation.filter((item) => item.id == dataStock.location)[0]
  // console.log('locationCurrentFilter')
  // console.log(locationCurrentFilter)

  // const initValue = {
  //   id: 1,
  //   name: 'Galpão principal',
  //   id_patrimony_type: 3,
  //   id_property: 1,
  //   patrimony_group_icon: 'WarehouseIcon',
  //   patrimony_group_id: 2,
  //   tipo_repos: 'Patrimony',
  //   type: 'Armazém/Galpão',
  // }

  const [selectedValueLocation, setSelectedValueLocation] = useState(reposPatrimonyFilter[0]);
  //const [showAddNewLocation, setShowAddNewLocation] = useState(true)

  // console.log('setSelectedValueLocation ')
  // console.log(selectedValueLocation)

  useEffect(() => {
    //setSelectedValueLocation(locationCurrentFilter)
    setDataStock((prevData) => ({
      ...prevData,
      id_patrimony: reposPatrimonyFilter[0].id,
    }));
  }, []);

  const handleChangePatrimony = (event, flag, newValue) => {
    // console.log('handleChangePatrimony')
    // console.log(event.target.value)
    // console.log(event.target.name)
    // console.log(newValue)
    // console.log(flag)
    if (newValue) {
      const setSelectedValueLocation = newValue;
      // console.log(setSelectedValueLocation)
      setDataStock((prevData) => ({
        ...prevData,
        id_patrimony: newValue.id,
      }));
    }

    // Limpa o erro relacionado ao campo 'typeMov'
    setFormError((prevErrors) => ({
      ...prevErrors,
      selectedValueLocation: false,
    }));
  };

  console.log(dataStockList);

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      {dataStock.typeMov == 'in' && <DialogTitle>Adicionar produtos ao estoque</DialogTitle>}
      {dataStock.typeMov == 'out' && <DialogTitle>Retirar produtos do estoque</DialogTitle>}

      <DialogContent>
        <Box sx={{ display: 'flex' }}>
          {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
          <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
            {/* <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
              <>
                <strong>Controle tudo que entra e sai do estoque</strong>
              </>
            </Typography> */}
            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
              <>
                <strong>Controle do estoque: </strong>
                <span>
                  Insira a quantidade que deseja {dataStock.typeMov == 'in' ? 'adicionar ao ' : 'retirar do '} estoque, juntamente com o valor médio unitário desses produtos e seu local de
                  armazenamento. O sistema calculará automaticamente a média dos valores existentes no estoque junto com a nova entrada.
                </span>
              </>
            </Typography>
          </Stack>
        </Box>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContentText>
              {/* Aqui você pode adicionar as informações do pedido */}
              {/* Exemplo: Pedido Número: {selectedRow?.id} */}
            </DialogContentText>
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* NOME */}
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex' }}>
                    <WidgetsIcon sx={{ fontSize: 20, mr: 1 }} color="text.secondary" />
                    <Stack spacing={0.5}>
                      {dataStock?.name && <Typography fontWeight={700}>{dataStock?.name.toUpperCase()}</Typography>}
                      {dataStockList.map((item, index) => (
                        <p key={index} style={{ fontSize: '12px', marginBottom: '0px' }}>
                          {item.name}({item.quantity} {dataStock?.packaging_name === 'Unidade' ? dataStock?.unit_sigla : dataStock?.packaging_name})
                        </p>
                      ))}
                      {/* {data.packaging_name === 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume_pkg} ${data?.unit_sigla}`} */}
                      {dataStock?.packaging_name === 'Unidade' ? (
                        <></>
                      ) : (
                        <Typography variant="caption">
                          {dataStock?.packaging_name} {dataStock?.volume_pkg} {dataStock?.unit_sigla}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    value={value}
                    name="typeMov"
                    disabled
                    onChange={(event, newValue) => {
                      handleChange(newValue);
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      //handleChange(newInputValue)
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    //sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Movimentação" />}
                    noOptionsText="Sem resultados"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={5} sm={5}>
                  <TextField
                    required
                    focused
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    id="quanty"
                    name="quanty"
                    label={dataStock?.packaging_name === 'Unidade' ? `Quantidade em ${dataStock.unit_sigla}` : `Quantidade em ${dataStock.packaging_name}`}
                    fullWidth
                    autoFocus
                    //autoComplete="quanty"
                    variant="outlined"
                    color="success"
                    //value={editStockProduct.qnt_disponivel}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <IconButton>
                    //         <Assignment />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    error={formError.quanty}
                    FormHelperTextProps={{ style: { color: formError.quanty ? red[800] : '#2e7d32' } }}
                    helperText={formError.quanty ? 'Campo obrigatório' : 'Quantidade de produtos'}
                  />
                </Grid>
                {/* <Grid item xs={1} sm={1} sx={{ mt: 2 }}>
                  {dataStock?.packaging_name === 'Unidade' ? (
                    ` (${dataStock.unit_sigla})`
                  ) : (
                    <Typography variant="subtitle2">
                      {dataStock?.packaging_name} {dataStock?.volume_pkg} {dataStock?.unit_sigla}
                    </Typography>
                  )}
                </Grid> */}
                <Grid item xs={6} sm={6}>
                  {/* <CurrencyTextField
                    required={true}
                    value={dataStock.value_cost}
                    label="Valor unitário"
                    name="value_cost"
                    onChange={handleChange}
                    error={formError.value_cost}
                    FormHelperTextProps={{ style: { color: formError.quanty ? red[800] : '#2e7d32' } }}
                    helperText={formError.value_cost ? 'Campo obrigatório' : 'Valor de um produto'}
                    // helperText={`Defina o valor do produto ${data.name}`}
                  /> */}
                  <GenericValueField
                    handleChange={handleChange}
                    options={{
                      required: true,
                      id: 'value_cost',
                      name: 'value_cost',
                      label: 'Valor unitário',
                      value: dataStock.value_cost,
                      endAdornment: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    name="id_patrimony"
                    onChange={(event, newValue) => {
                      setSelectedValueLocation(newValue); // Atualize para usar setSelectedValue
                      handleChangePatrimony(event, true, newValue);
                    }}
                    disableCloseOnSelect={false}
                    filterSelectedOptions
                    color="success"
                    value={selectedValueLocation}
                    multiple={false}
                    options={listStockLocation}
                    ListboxProps={{ style: { maxHeight: 225 } }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <MenuItem {...props}>
                        {/* {option.id === 'new' ? (
                          <>
                            <AddCircleOutlineIcon style={{ color: '#2e7d32', marginRight: '8px', fontSize: '15px' }} />
                            <Typography variant="subtitle2" component="div" style={{ color: '#2e7d32' }}>
                              Adicionar novo local
                            </Typography>
                          </>
                        ) : (
                          <>
                            <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                            {option.name}
                          </> 
                          
                        )}*/}
                        <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                        {option.name}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Local de armazenamento"
                        color="success"
                        InputLabelProps={{ style: { color: '#2e7d32' } }}
                        focused
                        fullWidth
                        error={formError.selectedValueLocation}
                        helperText={formError.selectedValueLocation ? 'Campo obrigatório' : 'Local de armazenamento do produto'}
                      />
                    )}
                    noOptionsText="Sem resultados"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={10}
                    name="description"
                    placeholder="Descrição (limite de 500 caracteres)."
                    value={dataStock.description}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    style={{ width: '100%', padding: '8px' }}
                    // style={{ width: '100%', padding: '8px', border: '2px solid red' }}
                    // style={
                    //   editStockProduct.observacoes.length >= 500
                    //     ? { width: '100%', padding: '8px', border: '2px solid red' }
                    //     : { width: '100%', padding: '8px' }
                    // }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
      {/* Adicione botões de ação se necessário */}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={handleSubmit} variant="contained" color="success">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
