import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Accordion, Box, Card, CardContent, CardMedia, Grid, IconButton, ListItem, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Input } from '@mui/icons-material';
import dayjs from 'dayjs';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useParams } from 'react-router-dom';
import useActivityMonitoringReposStore from '../../../../../store/Activities/useActivityMonitoringReposStore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import AddActivities from '../../AddButtonActivities/AddActivities';
import { useContext } from 'react';
import { Context } from '../../../../../components/Context/AuthContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function ViewSpecificActivitiesMonitoring({ data, obj_fn, area }) {
  const [open, setOpen] = React.useState(false);
  const { idFarm, id } = useParams();

  const { aux_add, setAuxAdd } = useContext(Context);

  console.log(data, obj_fn, area);

  //const activityMonitoring = obj_fn.usestore.reposActivityMonitoring.getActivityMonitoringStore('id', 1)[0]

  const reposActivities = useActivityMonitoringReposStore((state) => state);

  //  useEffect(() => {
  //  "console.log("
  // }, [aux_add])

  const listActivityMonitoring = reposActivities.listActivityMonitoring;

  console.log('listActivityMonitoring');
  console.log(listActivityMonitoring);
  console.log(area);
  //console.log(area.areas_used[0].id_areamap)
  //console.log(area.areas_used.id_areamap)

  // /let imageActivityMonitoring = null
  //if (Array.isArray(area.areas)) {
  const imageActivityMonitoring = listActivityMonitoring.filter((item) => item.id_area === area.id);

  const todos = imageActivityMonitoring.filter((item) => item.progress === 'A FAZER');
  const inProgress = imageActivityMonitoring.filter((item) => item.progress === 'EM PROGRESSO');
  const completed = imageActivityMonitoring.filter((item) => item.progress === 'CONCLUÍDA');
  // }

  console.log('imageActivityMonitoring1');
  console.log(imageActivityMonitoring);
  console.log(todos);
  console.log(completed);
  console.log(inProgress);

  //const imageActivityMonitoring = listActivityMonitoring.filter((item) => area.areas.some((areaItem) => areaItem.id === item.id_area))
  //  / console.log(imageActivityMonitoring)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeMonitoring = (activityMonitoring) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      monitoringData: {
        ...prevState.monitoringData,
        [activityMonitoring.id]: {
          image: activityMonitoring.image,
          id: activityMonitoring.id,
          id_area: activityMonitoring.id_area,
          progress: activityMonitoring.progress,
          heatmapRadius: activityMonitoring.heatmapRadius,
          heatmapWeight: activityMonitoring.heatmapWeight,
          monitoringName: activityMonitoring.monitoringName,
        },
      },
    }));
    obj_fn.setData((prevState) => ({ ...prevState, ['selectedId']: activityMonitoring.id }));
    handleClose();
  };

  console.log('data1');
  console.log(data);

  const renderActivities = (activities) =>
    activities.map((activityMonitoring) => (
      <Card sx={{ mt: 1 }}>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <LocationSearchingIcon sx={{ color: 'action.active', ml: 0, mr: 1 }} />
                      <Stack spacing={0.5}>
                        <Typography component="div" variant="h5">
                          <strong>{activityMonitoring.monitoringName}</strong>{' '}
                        </Typography>
                      </Stack>
                    </Box>

                    <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Início" secondary={dayjs(activityMonitoring.date_start).format('DD/MM/YYYY')} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <ListItem>
                          <ListItemText primary="Término" secondary={dayjs(activityMonitoring.date_prev_end).format('DD/MM/YYYY')} />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography component="div" variant="caption">
                          {activityMonitoring.progress}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Grid>
              <>
                {activityMonitoring.image ? (
                  <Grid item xs={12} sm={6}>
                    <Accordion>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                            <WallpaperIcon sx={{ color: 'action.active', ml: 1, mr: 1 }} />
                            <Stack spacing={0.5}>
                              <Typography>Imagem</Typography>
                            </Stack>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <CardMedia component="img" height="150" image={activityMonitoring.image} alt="Área" />
                        </Grid>
                      </Grid>
                    </Accordion>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={1}>
                    <Typography sx={{ ml: -1 }}>Sem imagem</Typography>
                  </Grid>
                )}
              </>
              {/* {activityMonitoring.areaTranferImage ? (
                <Grid item xs={12} sm={activityMonitoring.image ? 4 : 5}>
                  <Accordion>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                          <Map sx={{ color: 'action.active', ml: 1, mr: 1 }} />
                          <Stack spacing={0.5}>
                            <Typography>Área</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <CardMedia component="img" height="150" image={activityMonitoring.areaTranferImage} alt={activityMonitoring.name} />
                      </Grid>
                    </Grid>
                  </Accordion>
                </Grid>
              ) : (
                <Grid item xs={12} sm={1}>
                  <Typography sx={{ ml: -1 }}>Sem imagem</Typography>
                </Grid>
              )} */}
            </Grid>
            <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
                  <Grid item xs={4} sm={4}>
                    <ListItem>
                      <ListItemText primary="Raio:" secondary={`${activityMonitoring.heatmapRadius} metros`} />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <ListItem>
                      <ListItemText primary="Intensidade" secondary={activityMonitoring.heatmapWeight} />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <div style={{ marginLeft: 'auto', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 10, borderRadius: 5 }}>
                  <Grid container spacing={1}>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(0, 255, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>0 - Ausente</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 255, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>1 - Baixo</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 165, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>2 - Médio</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 105, 97, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>3 - Alto</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 0, 0, 0.8)', marginRight: 5 }}></div>
                      <span style={{ fontSize: 12, color: 'black' }}>4 - Muito alto</span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: 'flex' }}>
          <Button variant="contained" fullWidth color="inherit" onClick={() => handleChangeMonitoring(activityMonitoring)} title="Visualização">
            Selecione a área de monitoramento
          </Button>
        </Grid>
      </Card>
    ));

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAuxAdd(aux_add + 1);

    handleClose();
  };

  return (
    <React.Fragment>
      <Button variant="contained" fullWidth color="error" onClick={() => handleClickOpen()} title="Visualização">
        Selecionar monitoramento
      </Button>
      {/* <IconButton aria-label="Visualização" onClick={() => handleClickOpen()} title="Visualização">
        <FullscreenIcon sx={{ color: green[500] }} />
      </IconButton> */}
      <Dialog open={open} fullWidth={true} maxWidth={'lg'} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
            <Input sx={{ mr: 1 }} />
            <Stack spacing={0.5}>
              <Typography fontWeight={700}> Monitoramentos da Área</Typography>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '700px' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                textColor="success"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: 'green' } }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Em progresso" {...a11yProps(0)} />
                <Tab label="A Fazer" {...a11yProps(1)} />
                <Tab label="Concluído" {...a11yProps(2)} />
                {/* <Tab label="Movimentações" {...a11yProps(1)} />
                <Tab label="Histórico do produto" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {renderActivities(inProgress)}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderActivities(todos)}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {renderActivities(completed)}
            </TabPanel>
          </Box>

          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          {/* {listActivityMonitoring.map((activityMonitoring) => ( */}
        </DialogContent>
        <IconButton aria-label="Visualização" onClick={() => handleClickOpenDialog()} title="Visualização">
          <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}> Não encontrei o monitoramento. clique aqui</Typography>
            </Stack>
            <HelpOutlineIcon sx={{ mr: 1 }} />
          </Box>
        </IconButton>
        <InfoDialog open={openDialog} handleClose={handleCloseDialog} /> {/* Renderize o InfoDialog com o estado openDialog */}
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const InfoDialog = ({ open, handleClose }) => {
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Não encontrei o monitoramento.</Typography>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        {' '}
        <Typography variant="body2">Caso você não tenha encontrado o seu monitoramento ou não tenha registrado um, você terá que registar a atividade de monitoramento.</Typography>
        <Typography sx={{ mt: 1 }} variant="body2">
          Clique em "NOVA ATIVIDADE DE CAMPO" e crie a atividade de monitoramento.
        </Typography>
        <Grid item xs={12} md={3} sx={{ mt: 1 }}>
          <Box display="flex" justifyContent="flex-start">
            <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
          </Box>
        </Grid>
        <Typography sx={{ mt: 1 }} variant="body2">
          Depois, basta clicar em fechar e criar a atividade de aplicação.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="success" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
