import React, { useState, useContext } from "react";
import { Box, ListItem, ListItemText, Divider, Button } from "@mui/material";
import { AppBar, Grid, Stack, Toolbar, Typography } from "@mui/material";
import "dayjs/locale/pt";
import { Context } from "../../../../../components/Context/AuthContext";
import { makeStyles } from "@mui/styles";
import TableWeights from "./TableWeights";
import FormIndividualWeightHeader from "./FormIndividualWeightHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  content: {
    width: "100%",
    maxWidth: "none",
  },
}));

export default function FormIndividualWeightStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const classes = useStyles();
  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  const handleSubmit = () => {
    // console.log("onSubmit");
    obj_fn.onSubmit();
  };

  // Valida o button continuar
  const isError = () => {
    return (
      !data.num_animals || !data.table_confirm.length
    );
  };

  const getTableAnimals = () => {
    const tabelaAnimals = (<TableWeights data={data} obj_fn={obj_fn} confirm={true}/>);
    return ( <>
      {!ismobile ?
        <Grid item xs={12} md={12} className={classes.container} sx={{ mt: 1, mb: 5 }}>
          {tabelaAnimals}
        </Grid> :
        <Grid item xs={12} sx={{ width: "100%", overflow: "auto" }}>
          {tabelaAnimals}
        </Grid>
      }
    </>)
  };

  const text_title = "Confirmação dos dados";
  const txt_msn = "Pronto! Agora basta conferir os dados da pesagem e clicar em confirmar!";

  return ( <>
    <FormIndividualWeightHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} imagem={"/dist/image/entrada.png"} />

    <Grid container spacing={2} sx={{}}>
      <Grid item xs={6} md={6}>
        <ListItem>
          <ListItemText primary="Animais Pesados" secondary={data.num_animals} />
        </ListItem>
      </Grid>
      <Grid item xs={6} md={6}>
        <ListItem>
          <ListItemText primary="Peso Médio" secondary={data.weight_medio} />
        </ListItem>
      </Grid>
    </Grid>
    <Divider />

    <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
      <Stack spacing={0.5}>
        <Typography fontWeight={700}> Tabela de pesagem dos animais </Typography>
      </Stack>
      {getTableAnimals()}
    </Grid>

    <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
            Cancelar
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }} >
            Retornar
          </Button>
          <Button color="success" variant="contained" type="submit" onClick={!isError() ? handleSubmit : () => null} disabled={isError()}>
            Confirmar
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  </> );
}
