import React, { useState } from 'react';
import { Box, Button, CardMedia, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Queue } from '@mui/icons-material';
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import { calculaIdade } from '../../../../components/Calculations/CalculaIdade';
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField';

export default function FormAddAnimalsSale({ data, obj_fn, handleClose }) {
  const optionsAnimalsList = JSON.parse(JSON.stringify(obj_fn.usestore.reposLivestock.listAnimals));
  const optionsAnimals = optionsAnimalsList.map((item) => {
    item.idade = calculaIdade(item.date_birth, 'completa');
    item.ageMonths = calculaIdade(item.date_birth, 'mes');

    item.weight_unit = item.weight_unit || 'kg';
    item.peso = item.weight ? item.weight + ' (' + item.weight_unit + ')' : '-';

    if (!item.image) {
      const listImageRepos = obj_fn.usestore.reposImages.getImageStore('animal', item.id);
      const itemData = listImageRepos.filter((img) => {
        return img.img;
      });
      item.image = itemData[0] ? itemData[0].img : null;
    }

    item.nome = item.identifier + (item.name || item.brinco ? ' | ' : '');
    item.nome += item.name + (item.brinco ? ' | ' : '');
    item.nome += item.brinco;
    return item;
  });
  const [selectedAnimal, setSelectedAnimals] = useState(null);

  const handleChange = (event) => {
    setSelectedAnimals((prevState) => ({ ...prevState, [event.target.name]: parseFloat(event.target.value) }));
  };

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.animals));
    selectedAnimal.discount = 0;
    aux_add.push(selectedAnimal);

    var val_total = 0;
    var aux_val = 0;
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value);
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val;
      aux_add[i].value_end = aux_val;
    }
    var aux_value = data.value - data.animals_value + val_total;
    var aux_value_end = data.value_end - data.animals_value + val_total;

    obj_fn.setData((prevState) => ({ ...prevState, animals_value: val_total }));
    obj_fn.setData((prevState) => ({ ...prevState, animals: aux_add }));

    obj_fn.setData((prevState) => ({ ...prevState, value: aux_value }));
    obj_fn.setData((prevState) => ({ ...prevState, value_end: aux_value_end }));

    obj_fn.setData((prevState) => ({ ...prevState, animal_selected: null }));
    setSelectedAnimals(null);
    handleClose();
  };

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    let aux_animal = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    setSelectedAnimals(aux_animal);
    obj_fn.setData((prevState) => ({ ...prevState, animal_selected: aux_animal }));
  };

  const opt_animals = {
    multiple: false,
    name: 'animal',
    field: 'animal',
    label: 'Animal para: ' + data.operationType,
    values: optionsAnimals,
    variant: 'standard',
    helperText: (
      <>
        Selecione o animal desejado
        <br />
        Lote: {selectedAnimal?.lote || '-'}
        <br />
        Idade: {selectedAnimal?.idade || '-'}
      </>
    ),
    listHeight: 225,
    disableClearable: true,
  };

  // const para validar o button add ao carrinho
  const isValidAdd = () => {
    if (!selectedAnimal?.id || !selectedAnimal?.value) {
      return true;
    }
    return false;
  };
  // helperText={"Valor total do animal"}
  // const validQtdVal = (type) => {
  //   let text = ''
  //   var mensagem = (<></>);
  //   let error = '';

  //   let valor = selectedAnimal?.value
  //   text = "Valor total dos animais"
  //   if (!valor) {
  //     error = 'Valor deve ser maior que 0!'
  //   }

  //   if (error && selectedAnimal?.id) {
  //     mensagem = (<>
  //       <br/>
  //       <span style={{ color: 'red' }}>
  //         {error}
  //       </span>
  //     </>);
  //   }

  //   return (<>
  //     {text}
  //     {mensagem}
  //   </>);
  // };

  return (
    <>
      <Grid container spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, ml: 1, mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <AutocompleteGeneric value={selectedAnimal} options={opt_animals} handleChange={handleChangeSelect} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4} sx={{ mt: obj_fn.ismobile ? -5 : -3, mb: -3 }}>
                <Grid item xs={8} sm={8}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary={'Fase de manejo: ' + (selectedAnimal?.fase_name || '-')} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={'Peso: ' + (selectedAnimal?.peso || '-')} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={'Sexo: ' + (selectedAnimal?.sexo || '-')} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} md={4}>
                  {!selectedAnimal?.image ? (
                    <>
                      <IconsLivestockActivities activity={'Geral'} widthStyles={'80px'} fill={'#1f2d3d'} />
                    </>
                  ) : (
                    <CardMedia
                      component="img"
                      image={selectedAnimal?.image}
                      alt="Imagem do animal"
                      sx={{
                        borderRadius: '16px',
                        height: '80px',
                        width: '100px',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              {/* <CurrencyInputReal
              required
              id="value"
              name="value"
              label="Valor (R$)"
              color="success"
              value={selectedAnimal?.value}
              onChange={handleChange}
              disabled={!selectedAnimal?.id}
              helperText={validQtdVal('valor')}
            /> */}
              <GenericValueField
                handleChange={handleChange}
                options={{
                  required: true,
                  id: 'value',
                  name: 'value',
                  label: 'Valor',
                  value: selectedAnimal?.value,
                  helperText: 'Valor total do animal',
                  helperTextError: 'Valor deve ser maior que 0!',
                  disabled: !selectedAnimal?.id,
                  endAdornment: true,
                  width: '80%',
                }}
              />
            </Grid>
            <Grid item xs={8} sm={8} sx={{ mt: 2 }}>
              <Button variant="contained" color="success" onClick={handleAdd} disabled={isValidAdd()}>
                <Queue sx={{ mr: 1 }} /> Adicionar ao carrinho
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
