import { Autocomplete, Box, TextField, Typography, Button } from "@mui/material";
import SpaIcon from "@mui/icons-material/Spa";
import { green } from "@mui/material/colors";
import { NavLink } from "react-router-dom";
import IconsLivestockActivities from "../../Icons/IconsLivestockActivities";

const getStyle = function (index) {
  const style_group = {
    marginTop: index == 0 ? "-8px" : "",
    color: "#2e7d32",
    padding: "4px 10px",
    textAlign: "center",
    backgroundColor: "#d4ffd6",
  };
  return style_group;
};

function SelectCreationHarvest({ value, options, setValue, widthx }) {
  if (options.length < 1) {
    return (
      <div style={{ maxWidth: "250px" }}>
        <Button sx={{ ml: -0.5 }} style={{ color: green[700], textTransform: "initial" }} variant="text">
          <div className="justify-content-center ">{<IconsLivestockActivities activity={"Geral"} widthStyles={"32px"} />}</div>
          <NavLink to={"/livestock"}>
            <div className="text-dark mt-1" style={{ color: green[700], fontSize: 15, marginLeft: "10px" }}>
              {" "}
              Cadastre suas Criações{" "}
            </div>
          </NavLink>
        </Button>
        <Button sx={{ ml: -0.5 }} style={{ color: green[700], textTransform: "initial" }} variant="text">
          <div className="justify-content-center ">{<SpaIcon />}</div>
          <NavLink to={"/harvest"}>
            <div className="text-dark mt-1" style={{ color: green[700], fontSize: 15, marginLeft: "10px" }}>
              {" "}
              Cadastre suas Safras{" "}
            </div>
          </NavLink>
        </Button>
      </div>
    );
  }
  var option_autocomplete = options.map(
    (
      repos //{ "label": repos.name, "id": repos.id, "id_property": repos.id_property, "tipo": repos.tipo_repos }
    ) =>
      repos.tipo_repos == "Safras"
        ? { label: repos.name, id: repos.id, id_property: repos.id_property, tipo_repos: "Safras", id_cultivation: repos.id_cultivation, cultivation_icon: repos.cultivation_icon }
        : { label: repos.name, id: repos.id, id_property: repos.id_property, tipo_repos: "Criações", tipe: repos.id_tipe, activity: repos.tipo }
  );
  return (
    <>
      <Box className="d-lg-none">
        <Autocomplete
          disableClearable
          freeSolo
          size="small"
          filterSelectedOptions
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id && option.tipo_repos === value.tipo_repos}
          options={option_autocomplete}
          onChange={(event, newValue) => {
            localStorage.setItem("creationHarvestRepos", JSON.stringify(newValue));
            setValue(newValue, "selectCreationHarvest");
          }}
          groupBy={(option) => option.tipo_repos}
          sx={{ width: widthx }}
          id="selectCreationHarvest"
          name="selectCreationHarvest"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Criações/Safras"
              color="success"
              placeholder="Criações/Safras"
              focused
              InputProps={{
                ...params.InputProps,
                startAdornment: <strong> </strong>,
              }}
            />
          )}
          renderGroup={(params) => (
            <Box key={params.key}>
              <Typography style={getStyle(params.key)}>
                <strong>{params.group}</strong>
              </Typography>
              {params.children}
            </Box>
          )}
          noOptionsText="Sem resultados"
        />
      </Box>
      <Box className="d-none d-lg-block">
        <Autocomplete
          disableClearable
          freeSolo
          size="small"
          filterSelectedOptions
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id && option.tipo_repos === value.tipo_repos}
          options={option_autocomplete}
          onChange={(event, newValue) => {
            localStorage.setItem("creationHarvestRepos", JSON.stringify(newValue));
            setValue(newValue, "selectCreationHarvest");
          }}
          groupBy={(option) => option.tipo_repos}
          sx={{ width: 265 }}
          id="selectCreationHarvest"
          name="selectCreationHarvest"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Criações/Safras"
              color="success"
              placeholder="Criações/Safras"
              focused
              InputProps={{
                ...params.InputProps,
                startAdornment: <strong> </strong>,
              }}
            />
          )}
          renderGroup={(params) => (
            <Box key={params.key}>
              <Typography style={getStyle(params.key)}>
                <strong>{params.group}</strong>
              </Typography>
              {params.children}
            </Box>
          )}
          noOptionsText="Sem resultados"
        />
      </Box>
    </>
  );
}

export default SelectCreationHarvest;
