import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TableProfileMap from './TableProfileMap';
import TableProfileCentral from './TableProfileCentral';
import TableProfileSolicitacoes from './TableProfileSolicitacoes';
import json from '../../../../exemplo_roles_permission.json'
import TableProfileTeam from './TableProfileTeam';
import TableProfileImage from './TableProfileImage';
import RowsPermissions from '../../RowsPermissions';
import RolePermission from '../../RolePermission';

// modulos
// 1	users
// 2	property
// 3	propertyarea
// 4	areamap
// 5	upload
// 6	messenger
// 7	roles
// 8	permissions

// roles
// 1	proprietario
// 2	gerente
// 3	financeiro
// 4	operacional
// 5	consultor
// 6	agronomo
// 7	veterinario
// 8	estoque

export default function TableProfile() {

  // pego o value da role do select - profileCurrent = 1 ou 2 ...
  const profileCurrent = JSON.parse(localStorage.getItem("profileCurrent"));
  console.log('profileCurrent')
  console.log(profileCurrent)

  // list todas as permissões
  const rowsData = JSON.parse(localStorage.getItem("userPermissionsRolesDefault"));
  console.log(rowsData)
  // tenho o profileCurrent
  // filtro todas as permissões de um role(profileCurrent)  do selector
  var newRowsData = rowsData.filter(row => row.id_role === profileCurrent);
  console.log("newRowsData")
  console.log(newRowsData)

  // const role = RolePermission(profileCurrent)

  // define a role de acordo com o select do dialog consultar
  //const role = RolePermissions()

  // recebe a role e cria o array de permissões
  // const rows = RowsPermissions(role)
  // console.log('1RowsPermissions')
  // console.log(rows)


  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell align="left" sx={{ width: 300 }}>Funcionalidades</TableCell>
              <TableCell align="center"><VisibilityIcon fontSize="small" /> Visualizar</TableCell>
              <TableCell align="center"><EditIcon fontSize="small" />Criar/Editar</TableCell>
              <TableCell align="center"><DeleteForeverIcon fontSize="small" />Excluir</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          </TableBody>
        </Table>
      </TableContainer>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "#28a745" }}>Mapa</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <TableProfileMap newRowsData={newRowsData} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "#28a745" }}>Equipe</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <TableProfileTeam newRowsData={newRowsData} />
        </AccordionDetails>
      </Accordion>

      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: "#28a745" }}>Central</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableProfileCentral />
        </AccordionDetails>
      </Accordion> */}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: "#28a745" }}>Solicitações</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableProfileSolicitacoes newRowsData={newRowsData} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "#28a745" }}>Banco de Imagens</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <TableProfileImage newRowsData={newRowsData} />
        </AccordionDetails>
      </Accordion>

    </div>
  );
}