import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Container, Typography, Grid, Stack, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardMedia } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { LocationOn } from '@mui/icons-material';
import ModalLandpageContent from './ModalLandpageContent';
//https://fertili.com.br/ofertas/vacas-nelore-2
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ModalLandpage({ page, setPage }) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  //const [maxWidth, setMaxWidth] = React.useState('sm');

  //console.log(page);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [selectedImage, setSelectedImage] = React.useState(page?.image || '');

  const getBase64Image = () => {
    // Verifique se a propriedade 'preview' contém a string base64

    if (page.image) {
      // const imageFile = selectedImage,
      // console.log('imageFile')
      // console.log(imageFile)
      return page.image;
    }
    return ''; // Retorna uma string vazia caso não haja base64 disponível
  };

  const handleGeneratePDF = () => {
    const documentDefinition = {
      pageSize: {
        width: 850,
        height: 700,
      },
      footer: {
        text: 'Todos os direitos reservados.',
        alignment: 'center',
        margin: [0, 10],
      },
      content: [
        { text: `${page.title}`, style: 'header' },
        // { text: 'ModalLandpageContent', style: 'subheader' },
        { text: 'Endereço:', style: 'subheader' },
        { text: page.address },
        //  { image: getBase64Image(), width: 200, alignment: 'center' },
        {
          columns: [
            {
              width: '*',
              text: page.titleCreation,
              style: 'subheader',
            },
            {
              width: '*',
              text: `Valor: R$ ${page.amountCreation}`,
              style: 'subheader',
            },
          ],
        },
        { text: page.descriptionCreation },
        { text: page.paymentTextCreation },
        // {
        //   text: page.descriptionCreation,
        //   margin: [0, 10, 0, 0],
        // },
        // {
        //   text: page.paymentTextCreation,
        //   margin: [0, 10, 0, 0],
        // },
        {
          text: 'CARACTERÍSTICAS',
          style: 'subheader',
          margin: [0, 10, 0, 0],
        },
        {
          ul: page.characteristics.map((characteristic) => `${characteristic.title}: ${characteristic.description}`),
          margin: [0, 0, 0, 10],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
    };

    pdfMake.createPdf(documentDefinition).download('nome-do-arquivo.pdf');
  };

  return (
    <React.Fragment>
      <Button color="success" onClick={handleClickOpen} variant="outlined">
        Card
      </Button>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle style={{ color: '#D3D3D3' }}>Pré-visualização da página</DialogTitle>
        <DialogContent>
          <ModalLandpageContent page={page} setPage={setPage} />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleGeneratePDF}>
            Gerar PDF
          </Button>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
