import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import { Edit } from '@mui/icons-material'
import { AppBar, Card, CardContent, CardHeader, Divider, Grid, IconButton, Toolbar } from '@mui/material'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import { Box, useTheme } from '@mui/system'
import { H6, Span } from '../../../../components/Typography'
import SendIcon from '@mui/icons-material/Send'
import CloseIcon from '@mui/icons-material/Close'
import StepperForm from './stepForm/StepperForm'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AddIcon from '@mui/icons-material/Add'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function RegisterUserProfileDialog({ setLoading }) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Adicionar&nbsp;Equipe
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} aria-labelledby="draggable-dialog-title">
        <AppBar sx={{ position: 'relative' }} color="success" id="draggable-dialog-title">
          <div className="">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    Cadastar membro da Equipe
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </div>
        </AppBar>
        <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <StepperForm handleClose={handleClose} setLoading={setLoading} />
          </Paper>
        </Container>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>fechar</Span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  )
}
