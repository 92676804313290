import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';
import { Context } from '../../../../../components/Context/AuthContext';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import dayjs from 'dayjs';
import FormProgressSelect from '../FormProgress/FormProgressSelect';
import HeaderModalNew from '../../../../../components/Header/HeaderModalNew';
import FormMonitoringStep1 from './FormMonitoringStep1';
import FormMonitoringStep2 from './FormMonitoringStep2';
import RadarIcon from '@mui/icons-material/Radar';
import { PendingActions } from '@mui/icons-material';
// import { decode as base64_decode, encode as base64_encode } from 'base-64';
// import { base64ToBlob } from '../../../../../components/Image/base64ToBlob';

export default function MultiStepFormMonitoring({ handleClose, type }) {
  // console.log('MultiStepFormMonitoring');
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const harvestRepos = usestore.reposHarvest.harvestRepos;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_harvest: harvestRepos.id,
    operationType: 'Monitoring',
    areas: [],
    //monitoringName: '',
    //typeScale: 'scale',
    // averageQuantity: '',
    //valueAverageQuantity: '',
    // incidence: '',
    //valueIncidence: '',
    //scale: '',
    //valueScale: '',
    //heatmapRadius: 50,
    //heatmapWeight: 0.5,
    //marker: '',
    //zoom: '15',
    //image: '',
    //areaTranferImage: '',
    description: '',
    error_date: false,
    isDeleted: 0,
    progress_radio: 1,
    planning: 'realized',
    progress: 'CONCLUÍDA',
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    // date_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_end: null,
  });

  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState((new Date().getTime()));
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit != 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  const validate = () => {
    let valid = true;

    // if (!data.areas.length) {
    //   valid = false
    // } else {
    //   data.areas?.map((area) => {
    //     if (!area.product?.location_selected?.quant_utilizada) {
    //       valid = false
    //     } else if (area.product?.location_selected?.stock_quanty_array < area.product?.location_selected?.quant_utilizada) {
    //       valid = false
    //     }
    //   })
    // }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormProgressSelect', 'FormPlantingStep1', 'FormPlantingStep2'];
  const steps_text = [
    'Defina um progresso para essa atividade',
    'Selecione a área que será realizada o monitoramento',
    'Pronto! Agora basta conferir as informações do monitoramento e clicar em confirmar!',
  ];

  const onSubmit = async () => {
    let formOk = validate() || false;
    // console.log('onSubmit');
    // console.log(data);

    //console.log(data.areaTranferImage)
    //console.log(data.image)

    // let encodedMaker = base64_encode(JSON.stringify(data.marker))
    // data.marker = encodedMaker

    // const blobAreaTranferImage = base64ToBlob(data.areaTranferImage, 'image/jpeg')
    //console.log(blobAreaTranferImage) // O objeto Blob resultante

    // let encodedAreaTranferImage = base64_encode(JSON.stringify(data.areaTranferImage))
    // data.areaTranferImage = encodedAreaTranferImage

    // let encodeImage = base64_encode(JSON.stringify(data.image))
    // data.image = encodeImage

    // console.log(data);

    // const fd = new FormData();
    // fd.append('image', data.imageBlobMonitoring, `${nameArea}.png`);
    // //const tipo_item = 'area';
    // fd.append('item_type', 'monitoring' || '');

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('/activityMonitoring/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            // console.log('response.dataok');
            // console.log(response.data);
            // console.log(response.data._value);
            const array_id = response.data._value;
            // console.log(array_id);

            for (const [index, id] of array_id.entries()) {
              // console.log(index);
              uploadImage(id, index);
            }

            toast.success('Monitoramento registrado com sucesso!');
            //usestore.reposHarvest.fetch({ id_farm: property_id })
            // usestore.reposProducts.fetch({ id_farm: property_id })
            // usestore.reposPatrimony.fetch({ id_farm: property_id })
            usestore.reposActivityMonitoring.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar o Monitoramento, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  async function uploadImage(id, index) {
    // console.log('uploadImage');
    // toast.error(`test ${id}`);
    // console.log(index);
    // console.log(data);
    // console.log(data.areas[index]);
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    fd.append('image', data.areas[index].areaTranferImageBob, `${data.areas[index].monitoringName}.png`);
    // if (index == 0) {
    //   console.log('foi');
    //   fd.append('image', data.areas[index].areaTranferImageBob, `${data.areas[index].monitoringName}.png`);
    // }
    // // if (index == 1) {
    //   fd.append('image', data.areas[index].imagePhotoBlob, `${data.areas[index].monitoringName}.png`);
    // }

    //const tipo_item = 'area';
    fd.append('item_type', 'monitoring' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', id_farm || 1);
    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        // console.log('amazon');
        toast.success('Imagem enviada para Amazon');
        var farm_id = localStorage.getItem('Property');
        usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
          usestore.reposActivityMonitoring.fetch({ id_farm: property_id }).then((res) => {
            setTimeout(() => {
              // console.log('testesetTimeout2');
              setAuxAdd(aux_add + 1);
              //setLoadingButton(false);
            }, 200);
          });
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
    }
  }

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
    };

    switch (step) {
      case 0:
        // progresso do plantio
        return <FormProgressSelect data={data} obj_fn={obj_fn} optionsIcon={optionsIcon} optionsImage={optionsImage} />;
      case 1:
        // identificadores do plantio
        return <FormMonitoringStep1 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormMonitoringStep2 data={data} obj_fn={obj_fn} />;
      case 3:
        const data_success = {
          distination: 'activitiesHarvest/' + property_id + '/' + harvestRepos.id,
          name: 'Atividade de Monitoramento',
          text_success: 'Acesse a lista de suas atividades de campo para monitorar os manejos agrícolas.',
          distination_title: 'Atividades de campo',
          icon: <PendingActions color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  const optionsIcon = {
    title: 'Monitoramento de Pragas ou Doenças',
    txt_msn: steps_text[activeStep],
    icon: <RadarIcon style={{ fontSize: 24 }} />,
  };

  const optionsImage = {
    title: 'Progresso',
    txt_msn: steps_text[activeStep],
    icon: <RadarIcon style={{ fontSize: 100 }} />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep != 3 && <HeaderModalNew optionsIcon={optionsIcon} optionsImage={optionsImage} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
