import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit, LocationOn } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green, grey } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CardPropertyProducts from '../../property/components/CardPropertyProducts';
import { Span } from '../../../components/Typography';
import MenuPlan from './MenuPlan';
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

export default function AccessHarvest({ listPropertyRepos }) {
    const navigate = useNavigate();
    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>
            {/* <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Ciclo</Typography>

            </Box> */}
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/cycle_harvest.png"
                        title="atividades"
                    />
                </Card>
            </Box>
            <Card >
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 1: Registrar o Projeto da Safra</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar o Projeto da Safra para inciar o planejamento e o gerenciamento.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Através do registro da safra, você verá como é fácil gerenciar todas as atividades de produção, avaliar os
                                    resultados com precisão e tomar decisões estratégicas para atingir os objetivos.</Typography>

                            </Stack>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                                <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate("/harvest")}>
                                    <Grid item sx={{ width: 50 }}>
                                        <ListAltIcon />
                                    </Grid>
                                    <Typography fontWeight={700}>Clique aqui</Typography>
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 2: Planejamento</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Definir um planejamento de sucesso.
                                    </Typography>
                                </Stack>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>O planejamento de projetos agrícolas é essencial para estabelecer metas claras, identificar oportunidades, otimizar recursos, minimizar riscos e garantir a eficiência e o sucesso das atividades agrícolas.</Typography>

                            </Stack>
                            {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                                <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate("/areasmaps")}>
                                    <Grid item sx={{ width: 50 }}>
                                        <ListAltIcon />
                                    </Grid>
                                    <Typography fontWeight={700}>Clique aqui</Typography>
                                </Button>
                            </Box> */}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 3: Atividade de Entrada</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar o plantio da cultura.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Ao registrar o plantio da cultura garantirá um um controle preciso e organizado das informações relacionadas ao cultivo. Isso permite monitorar o cronograma de plantio, acompanhar o desenvolvimento das plantas, registrar as práticas de manejo adotadas, analisar o desempenho da cultura e tomar decisões informadas para otimizar a produção agrícola, aumentar a eficiência e obter melhores resultados no cultivo da cultura.</Typography>

                            </Stack>
                            {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                                        <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate("/team")}>
                                            <Grid item sx={{ width: 50 }}>
                                                <ListAltIcon />
                                            </Grid>
                                            <Typography fontWeight={700}>Clique aqui</Typography>
                                        </Button>
                                    </Box> */}

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 4: Registrar o ciclo de Atividades</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar as realizações das atividades e operações do campo.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Registar as realizações das atividades e operações do campo é importante para manter um histórico detalhado que permite avaliar o desempenho, identificar oportunidades de melhoria e embasar decisões estratégicas na gestão agrícola. Clique acima em "Atividades Agrícolas" para iniciar o gerenciamento das atividades de produção.</Typography>

                            </Stack>
                            {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                                        <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate("/team")}>
                                            <Grid item sx={{ width: 50 }}>
                                                <ListAltIcon />
                                            </Grid>
                                            <Typography fontWeight={700}>Clique aqui</Typography>
                                        </Button>
                                    </Box> */}

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <ListAltIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Passo 5: Atividade de Saída</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Registrar a atividade de registro da produção por área.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Registrar a realização da produção pecuária por lote em um software é essencial parar obter o desempenho produtivo.</Typography>

                            </Stack>
                            {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                                        <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate("/team")}>
                                            <Grid item sx={{ width: 50 }}>
                                                <ListAltIcon />
                                            </Grid>
                                            <Typography fontWeight={700}>Clique aqui</Typography>
                                        </Button>
                                    </Box> */}

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex' }}>
                                <AirlineStopsIcon style={{ fontSize: 45 }} />
                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography fontWeight={700}>Próximos passos</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <LocationOn sx={{ color: grey[500] }} /> Em contrução.
                                    </Typography>
                                </Stack>

                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                            >
                                <Typography>Em contrução.</Typography>

                            </Stack>
                        </Card>
                    </Grid>
                </Grid>

            </Card >

        </>
    );
}

//https://aegro.com.br/termos-de-uso/