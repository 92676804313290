import * as React from 'react'
import { Box, Card, CardMedia, Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import { Map, Notes } from '@mui/icons-material';

import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import CardProduct from './CardProducts';
import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';

export default function FormHarvestingStep1({ data, obj_fn }) {
  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    if (!data.areas.length) {
      iserror = true;
    } else {
      data.areas?.map((area) => {
        area.products.map((prod, idx) => {
          if (!prod?.id) {
            iserror = true;
          } else if (prod?.quant_colhida <= 0) {
            iserror = true;
          }
        });
      });
    }
    return iserror;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  const opt_areas = {
    multiple: true,
    name: 'areas',
    label: 'Áreas da Safra',
    values: optionsAreas,
    field: 'areas',
    variant: 'standard',
    helperText: 'Selecione a(s) área(s) para a colheita',
    listHeight: 225,
  };
  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.products) {
          area.products = [{ id: null, name: 'Selecione um produto', location: [], quant_colhida: 0, location_selected: null }];
        }
        return area;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const filters_prods = [
    ['description', 'agricola'],
    ['description', 'todos'],
  ];
  // console.log('data');
  // console.log(data);
  // console.log(data.areas);
  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12} md={12}>
          <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
            <ProductRegistrar filters={filters_prods} />
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) para colheita</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={7} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.areas} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid sx={{ mt: obj_fn.isMobile ? -2 : 1, ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} align="center">
                <Typography fontWeight={700} style={{ color: '#ff9800' }}>
                  {!data.areas.length ? 'Adicione pelo menos uma área da safra para a colheita!' : 'Adicione os produto provenientes da colheita na(s) área(s) selecionada(s).'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12} sx={{ mt: -2 }}>
                {obj_fn.isMobile ? (
                  <>
                    <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ mb: 1 }}>
                      <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 90, minWidth: 125 }} image={area.url} alt={'Área: ' + area.name} title={'Área: ' + area.name} />
                    </Grid>
                    <Grid container key={'G3_' + area.id} xs={12} sm={12}>
                      <Typography variant="subtitle1" align="left">
                        Área: {area.name}
                      </Typography>
                      <Typography variant="subtitle2" align="center" style={{ marginLeft: '10px' }}>
                        <i className="fa fa-map text-success">
                          {' '}
                          {area.area} ({area.measure})
                        </i>
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        Área plantada: {area.usedArea} ha
                      </Typography>
                    </Grid>
                    <Grid item key={'G4_' + area.id} xs={12} sm={12} sx={{ mt: 2 }}>
                      <CardProduct data={data} obj_fn={obj_fn} area={area} />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Card key={'C1_' + area.id} sx={{ display: 'flex', padding: 1 }} id={'card_a_' + area.id}>
                      <Grid item xs={4} sm={4} sx={{ ml: 1.5, alignItems: 'center' }}>
                        <CardMedia
                          key={'CM_' + area.id}
                          component="img"
                          image={area.url}
                          alt={'Área: ' + area.name}
                          title={'Área: ' + area.name}
                          sx={{ ml: '20%', width: 150, height: 50, transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.5, 1.5, 1)' } }}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Área: {area.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} sx={{ ml: 1.5 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          <i className="fa fa-map text-success">
                            {' '}
                            {area.area} ({area.measure})
                          </i>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Área plantada: {area.usedArea} ha
                        </Typography>
                      </Grid>
                    </Card>
                    <Card key={'C2_' + area.id} sx={{ display: 'flex', padding: 1 }} id={'card_a_' + area.id}>
                      <Grid item xs={12} sm={12}>
                        <CardProduct data={data} obj_fn={obj_fn} area={area} />
                      </Grid>
                    </Card>
                  </>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrição */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 3 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral da colheita
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para a colheita"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid>

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
