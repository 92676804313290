import React from 'react'
// import Nav from '../../components/Nav/Nav'
// import NavSide from '../../components/Nav/NavSide'
// import NavBotton from '../../components/Nav/NavBotton'
// import InventoryContent from './InventoryContent'
import Footer from '../../components/Footer/Footer'
import InventoryChoiseContentTabs from './InventoryChoiseContentTabs'

export default function Inventory() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <InventoryChoiseContentTabs />
      <Footer />
    </>
  )
}

// testar a inserção de dados do José
