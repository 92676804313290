import { useState } from 'react';
import { TextField, Button, Box, Grid, Typography, InputAdornment, Stack, CardContent, CardMedia, Card, CardActionArea } from '@mui/material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import InputIcon from '@mui/icons-material/Input';
import { useEffect } from 'react';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import useActivityInputReposStore from '../../../../../store/Activities/useActivityInputReposStore';
import FormInputFooter from './FormInputFooter';
import FormTransferStep2MoveBatchDialogTransferArea from './FormTransferStep2MoveBatchDialogTransferArea';
import { LocationOn } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import StartIcon from '@mui/icons-material/Start';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Loading from '../../../../../components/Loader/Loading';

export default function FormTransferStep2MoveBatch({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);
  const [image, setImage] = useState(null);
  //const [loadingImage, setLoadingImage] = useState(true);
  const [loading, setLoading] = useState(false);
  console.log(image);

  useEffect(() => {
    handleInit();
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (image) {
      return false;
    }
    return true;
  };

  // para validar o input
  // const validateProjectName = (value) => {
  //     return value.trim().length > 0;
  // };

  // if (image !== null) {
  //     setTimeout(() => {
  //         setLoadingImage(false)
  //     }, 1000);
  // }

  const returnError = () => {
    return false;
  };
  const color = '#2e7d32';

  console.log(data);
  console.log(data.opt_lotes);
  console.log(data.lote);
  console.log(data.areas);

  const filterArealist = data.areas.filter((item) => item.id !== data.lote.id_areamap);
  //const filterArealist = data.areas
  console.log(filterArealist);

  console.log('image');
  //console.log(image)
  //console.log(data.areaTranferId)
  //console.log(data.areaTranferId.image)

  const handleInit = () => {
    // setPath([]);
    //setOpen(false);
    data.areaTranferId = { id_area: '', name: '', image: '' };

    // setDrawM(true);
    // setDrawF(false);
    setImage('');
    // setTextButton('Próximo');
  };

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Tranferência de animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 120 }}>
            <img style={{ maxHeight: 120 }} src="/dist/image/activity_transfer_animal.png" alt="image" class="img-fluid" />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Movimentação entre Lotes
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1} sx={{}}>
              <Grid item xs={12} md={10}>
                <Box sx={{ ml: 1, mt: 0, display: 'flex' }}>
                  {/* <StartIcon /> */}
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography variant="subtitle1" fontWeight={500}>
                      Lote do local de origem
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ mt: 1 }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="5px">
                  <Card sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <img src={data.lote.icon} alt="Icon" style={{ height: '32px', marginLeft: '8px' }} />
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>{data.lote.label}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            <LocationOn sx={{ color: grey[500] }} /> {data.lote.area.name}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </Box>
                    <CardMedia
                      component="img"
                      // sx={{ width: 100, height: 100, objectFit: "cover" }}
                      sx={{
                        width: 120,
                        height: 120,
                        //objectFit: "none",
                        //objectPosition: "center",
                      }}
                      image={data.lote.area.url || data.lote.area.imageDecode}
                      alt="Area Image"
                    />
                  </Card>
                </Box>
              </Grid>
              <div className="d-none d-lg-block">
                <Grid item xs={12} md={2} sx={{}}>
                  <SwapHorizIcon sx={{ mt: 9, ml: 1, fontSize: 50 }} />
                </Grid>
              </div>
            </Grid>
            <div className="d-lg-none">
              <Grid container spacing={1} sx={{ mt: 15, mb: -10 }}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg)', width: '100%' }}>
                    <Grid item xs={12} md={12}>
                      <SwapHorizIcon sx={{ ml: 2, fontSize: 60 }} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                        <Box sx={{ mt: 1 }}>
                            <Box sx={{ ml: 1, display: 'flex', mb: 2 }}>

                                <Stack spacing={0.5} sx={{ ml: 1 }}>
                                    <Typography variant="subtitle2" fontWeight={500}>Clique aqui para transferir o lote para a área desejada:</Typography>
                                </Stack>
                            </Box>
                            <DialogTransferArea data={data} setData={setData} setImage={setImage} />
                        </Box>
                    </Grid> */}
          {data.areaTranferId.image == '' ? (
            <>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ ml: 1, display: 'flex' }}>
                      {/* <AirlineStopsIcon /> */}
                      <Stack spacing={0.5} sx={{ ml: 1 }}>
                        <Typography variant="subtitle1" fontWeight={500}>
                          Locais de destino
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="5px">
                      <FormTransferStep2MoveBatchDialogTransferArea data={data} setData={setData} setImage={setImage} setLoading={setLoading} />
                    </Box>
                  </Grid>
                  {/* {filterArealist.map((item) =>
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="0px">
                                                <Card key={item.id} sx={{ maxWidth: 345, mb: 2, mt: 2, opacity: 0.6 }} disabled>
                                                    <CardMedia
                                                        component="img"
                                                        height="200"
                                                        image={item.imageDecode}
                                                        alt="imagem"
                                                    />

                                                    <CardContent>
                                                        <Grid container alignItems="center" spacing={2}>
                                                            <Grid item>

                                                                <Stack spacing={0.5}>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        <LocationOn sx={{ color: grey[500] }} /> {item.name}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item>
                                                                <img src={data.lote.icon} alt="Icon" style={{ height: "32px", marginLeft: "8px" }} />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        )} */}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3} sx={{}} style={{ textAlign: 'center' }}>
                  <Grid item xs={12} md={12}>
                    {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="0px"> */}
                    <Box sx={{ ml: 1, display: 'flex' }}>
                      <AirlineStopsIcon />
                      <Stack spacing={0.5} sx={{ ml: 1 }}>
                        <Typography variant="subtitle1" fontWeight={500}>
                          Destino atualizado
                        </Typography>
                      </Stack>
                    </Box>

                    <Card key={data.areaTranferId.id} sx={{ maxWidth: 345, mb: 2, mt: 1 }} disabled>
                      <CardActionArea>
                        {loading ? (
                          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                            <Loading />
                          </Box>
                        ) : (
                          <CardMedia component="img" height="140" image={image} alt="Área" />
                        )}
                        <CardContent>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <Stack spacing={0.5}>
                                <Typography variant="body2" color="text.secondary">
                                  <LocationOn sx={{ color: grey[500] }} /> {data.areaTranferId.name}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item>
                              <img src={data.lote.icon} alt="Icon" style={{ height: '32px', marginLeft: '8px' }} />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    {/* </Box> */}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="success" variant="outlined" onClick={handleInit}>
                      Refazer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <FormInputFooter
          data={data}
          setData={setData}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          isError={isError}
          handleClose={handleClose}
          returnError={returnError}
        />
      </Grid>
    </>
  );
}
