import { Avatar, Box, Button, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { green } from '@mui/material/colors';
import LogoNameWhite from '../../../assets/png/logo-name-white.png';
import FromProfile from './FromProfile';

export default function CardProfile() {
  // Recuperar o objeto userCurrent do localStorage
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  console.log('userCurrent', userCurrent);

  // Obter o nome e email do usuário
  const name = userCurrent.name || 'Usuário';
  const email = userCurrent.email || 'email@exemplo.com';
  const charName = name[0].toUpperCase(); // Primeiro caractere do nome

  return (
    <>
      <>
        <Card sx={{ m: 2 }}>
          <CardHeader
            sx={{}}
            avatar={
              <Avatar sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
                {charName}
              </Avatar>
            }
            title={name}
            subheader={`Email: ${email}`}
          />
          <Divider />
        </Card>

        <Card sx={{ m: 2 }}>
          <Container component="main" maxWidth="xl" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <FromProfile userCurrent={userCurrent} />
            </Paper>
          </Container>
        </Card>
        <Card sx={{ m: 2 }}>
          <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                  Maximizando a gestão de sua propriedade
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button color="success" variant="outlined" type="submit">
                  <img src="dist/image/logo-CPT-Softwares.png" width="100" alt="CPT Softwares" />
                </Button>
              </Box>
            </Paper>
          </Container>
        </Card>
      </>
    </>
  );
}
