import * as React from 'react'
import { Stack, Typography, Box, TextField, IconButton } from '@mui/material'
import { useState } from 'react'
import ColorPicker from './ColorPicker'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

export default function DescriptionCreation({ page, setPage }) {
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#ffffff')
  const [headerBodyBackgroundColor, setHeaderBodyBackgroundColor] = useState('#ffffff')
  const [headerTextColor, setHeaderTextColor] = useState('#000000')
  const [characteristics, setCharacteristics] = useState(page.characteristics)

  const handleBackgroundColorChange = (color) => {
    setHeaderBackgroundColor(color)
    setPage({
      ...page,
      backgroundColorTableDesciptionCreation: color, // Atualiza a cor de fundo no estado
    })
  }

  const handleBackgroundBodyColorChange = (color) => {
    setHeaderBodyBackgroundColor(color)
    setPage({
      ...page,
      bodyBackgroundColorTableDesciptionCreation: color, // Atualiza a cor de fundo no estado
    })
  }

  const handleTextColorChange = (color) => {
    setHeaderTextColor(color)
    setPage({
      ...page,
      textColorTableDescriptionCreation: color, // Atualiza a cor do texto no estado
    })
  }

  const handleChange = (event, index, fieldName) => {
    const newCharacteristics = [...characteristics]
    newCharacteristics[index][fieldName] = event.target.value
    setCharacteristics(newCharacteristics)
    setPage({
      ...page,
      characteristics: newCharacteristics, // Atualiza as características no estado
    })
  }

  const handleAddCharacteristic = () => {
    setCharacteristics([...characteristics, { title: '', description: '' }])
  }

  const handleDeleteCharacteristic = (index) => {
    const newCharacteristics = [...characteristics]
    newCharacteristics.splice(index, 1)
    setCharacteristics(newCharacteristics)
    setPage({
      ...page,
      characteristics: newCharacteristics, // Atualiza as características no estado
    })
  }

  const handleChangeTitleTable = (event) => {
    const { name, value } = event.target
    setPage({
      ...page,
      [name]: value, // Atualiza apenas o título no estado
    })
  }

  console.log(page)

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Insira informações relevantes para atrair o cliente.
        </Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 0.8 }} color="text.secondary">
            Cor do Texto:{''}
          </Typography>
          <ColorPicker value={headerTextColor} onChange={handleTextColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Cor do Fundo cabeçalho:
          </Typography>
          <ColorPicker value={headerBackgroundColor} onChange={handleBackgroundColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Cor do Fundo corpo:
          </Typography>
          <ColorPicker value={headerBodyBackgroundColor} onChange={handleBackgroundBodyColorChange} />
        </Stack>
        {/* <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 0.5 }} color="text.secondary">
            Cor do botão:
          </Typography>
          <ColorPicker value={page.buttonColorMainCreation} onChange={handleButtonColorChange} />
        </Stack> */}
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead sx={{ background: page.backgroundColorTableDesciptionCreation }}>
            <TableRow>
              <TableCell>
                {/* <Typography variant="h6">CARACTERÍSTICAS</Typography> */}
                <TextField
                  inputProps={{ style: { color: page.textColorTableDescriptionCreation } }}
                  value={page.titleTable}
                  variant="standard"
                  onChange={(event) => handleChangeTitleTable(event)}
                  fullWidth
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ background: page.bodyBackgroundColorTableDesciptionCreation }}>
            {characteristics.map((characteristic, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    inputProps={{ style: { color: page.textColorTableDescriptionCreation } }}
                    value={characteristic.title}
                    onChange={(event) => handleChange(event, index, 'title')}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    inputProps={{ style: { color: page.textColorTableDescriptionCreation } }}
                    value={characteristic.description}
                    onChange={(event) => handleChange(event, index, 'description')}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteCharacteristic(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton onClick={handleAddCharacteristic}>
        <AddIcon />
      </IconButton>

      {/* <Box sx={{ height: 100 }}></Box> */}
    </>
  )
}
