import React from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ErrorContent from "./ErrorContent";
import Footer from "../../components/Footer/Footer";

export default function Error() {
  return (
    <>
      <NavSide />
      <Nav />
      <NavBotton />
      <ErrorContent />
      <Footer />
    </>
  );
}
