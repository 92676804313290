import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonBase, List, Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStore from '../../../../../store/useStore';
import { Context } from '../../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function RedirectMap({ handleClose }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const usestore = useStore();
  // pegar o livestock current
  const reposLivestockList = usestore.reposLivestock.listLivestock;
  // console.log(reposLivestockList);

  reposLivestockList.sort((a, b) => b.id - a.id);
  const idFarm = reposLivestockList[0].id_property;

  const { setMenuContext } = React.useContext(Context);

  const handleButtonClick = (destino) => {
    handleClose();
    var criation = {
      label: reposLivestockList[0].name,
      id: reposLivestockList[0].id,
      id_property: reposLivestockList[0].id_property,
      tipo_repos: 'Criações',
      tipe: reposLivestockList[0].id_tipe,
      activity: reposLivestockList[0].tipo,
    };
    localStorage.setItem('creationHarvestRepos', JSON.stringify(criation));
    usestore.reposLivestock.changeLivestockRepos(criation);

    let button = '';
    if (destino === 'mapa') {
      button = 'Mapa';
    } else if (destino === 'production') {
      button = 'Produção';
    } else if (destino === 'activities') {
      button = 'Atividades';
    }
    localStorage.setItem('ButtonActive', button);

    setMenuContext(new Date().getTime());

    navigate(`/${destino}/${idFarm}/${criation.id}`);
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box>
        {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt="Imagem" src="/dist/image/map_livestock.png" style={{ maxWidth: '100%' }} />
                </ListItem>
                <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                        <IconButton aria-label="comment">
                            <ShareLocationIcon style={{ color: "#2e7d32" }} />
                        </IconButton>
                    }
                >
                    <ListItemText primary={`Dica: No mapa, ao clicar em ENTRADA, você será redirecionado para tela de atividades onde poderá registrar as atividades.`} />

                </ListItem> */}
      </Box>
      {/* <Typography paragraph variant="subtitile1" style={{ color: "#2e7d32" }} fontWeight={500}>
                Clique no botão Mapa.
            </Typography> */}
      {/* <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <ButtonBase
            component={Paper}
            elevation={2}
            sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }}
            onClick={() => handleButtonClick('production')}
            className={classes.button}
          >
            <Stack direction="column" alignItems="center" sx={{ mt: -2 }}>
              <SignpostIcon style={{ fontSize: 50, mb: 1 }} />
              <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                Planejamento da Meta da Produção
              </Typography>
            </Stack>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <ButtonBase
            component={Paper}
            elevation={2}
            sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }}
            onClick={() => handleButtonClick('activities')}
            className={classes.button}
          >
            <Stack direction="column" alignItems="center">
              <img style={{ maxHeight: 50 }} src="/dist/image/entrada_atividade.png" alt="image" class="img-fluid" />
              <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                Gerenciamento das Atividades de campo
              </Typography>
            </Stack>
          </ButtonBase>
        </Grid>
      </Grid> */}
      {/* <Typography variant="subtitle1" sx={{ mt: 4 }} >
                Acessando o Mapa, podemos também começar o planejamento ou  gerenciamento da sua criação.
            </Typography> */}
      <Typography variant="subtitle1" sx={{ mt: 0, mb: 2 }}>
        Clique no botão abaixo.
      </Typography>
      <ButtonBase
        component={Paper}
        elevation={2}
        sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }}
        onClick={() => handleButtonClick('activities')}
        className={classes.button}
      >
        <Stack direction="column" alignItems="center">
          <PendingActionsIcon />
          {/* <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" class="img-fluid" /> */}
          <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
            Atividades de Campo
          </Typography>
        </Stack>
      </ButtonBase>
    </List>
  );
}
