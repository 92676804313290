import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['A fazer', 'Em Progresso', 'Revisar', 'Concluída'];

export default function HorizontalNonLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    /* <div className="d-lg-none">Esconde em telas maiores que lg</div>
  <div className="d-none d-lg-block">Esconde em telas menores que lg</div> */

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} >
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            <div className="d-lg-none"></div>
                            <div className="d-none d-lg-block"> {label}</div>

                        </StepButton>
                    </Step>
                ))}
            </Stepper>

        </Box>
    );
}
