import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import api from '../../services/api'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'

function processData(activityMedicines) {
  return {
    id: activityMedicines.id,
    id_property: activityMedicines.id_property,
    id_user: activityMedicines.id_user,
    name_user: activityMedicines.name_user,
    id_livestock: activityMedicines.id_livestock,
    lotes: JSON.parse(activityMedicines.info),
    lote_products: JSON.parse(activityMedicines.lote_products || '[]'),
    // lote_products: activityMedicines.lote_products,
    animal_products: JSON.parse(activityMedicines.animal_products || '[]'),
    num_animals: activityMedicines.num_animals,
    num_females: activityMedicines.num_females,
    num_males: activityMedicines.num_males,
    total_animals: activityMedicines.total_animals,
    date_start: dayjs(activityMedicines.date_start).format('YYYY-MM-DD HH:mm:ss'),
    date_start_f: dayjs(activityMedicines.date_start).format('DD/MM/YYYY'),
    date_prev_end: dayjs(activityMedicines.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end_f: dayjs(activityMedicines.date_prev_end).format('DD/MM/YYYY'),
    date_end: activityMedicines.date_end ? dayjs(activityMedicines.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
    date_end_f: activityMedicines.date_end ? dayjs(activityMedicines.date_end).format('DD/MM/YYYY') : '',
    description: activityMedicines.description,
    isDeleted: activityMedicines.isDeleted,
    info: JSON.parse(activityMedicines.info),
    id_transaction: activityMedicines.id_transaction,
    info_transaction: activityMedicines.info_transaction ? JSON.parse(activityMedicines.info_transaction) : '',
    record: activityMedicines.record ? JSON.parse(activityMedicines.record) : '',
    type: 'activity',
    activity: activityMedicines.name || 'MEDICAMENTOS',
    name_activity: activityMedicines.name || 'MEDICAMENTOS',
    progress: activityMedicines.progress || 'CONCLUÍDA',
    planning: activityMedicines.planning || 'realized',
    tipo_repos: 'MEDICAMENTOS',
  }
}

// create the store
const useActivityMedicinesReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listLotes: [],
        listAnimals: [],
        listActivityMedicines: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!item) {
            item = { id_property: id_property }
          } else if (!item.id_property) {
            item.id_property = id_property
          }
          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityMedicines/list', item, header)
          // console.log('activityMedicines')
          // console.log(data)
          const data = await response.data
          const data_v = data._value || []

          let list_lotes = []
          let list_animals = []
          await set({
            listActivityMedicines: data_v.map((activityMedicines) => {
              list_lotes = list_lotes.concat(JSON.parse(activityMedicines.lote_products || '[]'))
              list_animals = list_animals.concat(JSON.parse(activityMedicines.animal_products || '[]'))
              // list_lotes = list_lotes.concat(activityMedicines.lote_products)
              // list_animals = list_animals.concat(activityMedicines.animal_products)
              return processData(activityMedicines)
            }),
          })

          set({
            listLotes: list_lotes,
            listAnimals: list_animals,
          })

          const lista = JSON.parse(localStorage.getItem('listActvs'))
          const lista_i = get().listActivityMedicines

          let array_aux = lista.filter((activity) => {
            if (!/MEDICAMENTOS/.test(activity.activity)) {
              return activity
            }
          })
          const res = array_aux.concat(lista_i)
          localStorage.setItem('listActvs', JSON.stringify(res))

          return data
        },
        // pega a(s) produção(ões) da listActivityMedicines de acordo com o [key e val] informados
        getActivityMedicinesStore: (key, val) => {
          return get().listActivityMedicines.filter((activityMedicines) => {
            if (activityMedicines[key] == val) {
              return activityMedicines
            }
          })
        },
        // Gera uma nova listActivityMedicines sem a activity informada
        delMedicinesStore: (id) => {
          set({
            listActivityMedicines: get().listActivityMedicines.filter((activity) => {
              if (activity.id != id) {
                return activity
              }
            }),
          })
        },
        // atualiza listActivityMedicines de acordo com id, key e val informados
        updateActivityMedicinesStore: (id, key, val) => {
          set({
            listActivityMedicines: get().listActivityMedicines.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val
                } else {
                  activity[key] = val
                }
              }
              return activity
            }),
          })
          return get().listActivityMedicines
        },
        // consulta os lançamentos do ano informado
        fetchFiltered: async (filters) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!filters) {
            filters = {}
          }
          if (!filters.filter) {
            filters.filter = true
          }
          if (!filters.id_property) {
            filters.id_property = id_property
          }
          // carrega dados do ano
          if (!filters.between) {
            filters.date = 'ap.date_start'
            filters.dateI = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss')
            filters.dateF = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss')
          }

          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityMedicines/list', filters, header)
          const data = await response.data
          const data_v = data._value || []
          // console.log('listActivityNutrition')
          // console.log(data_v)

          var listActivityNutrition = data_v.map((activityNutrition) => {
            return processData(activityNutrition)
          })

          return listActivityNutrition
        },
      }),
      { name: 'reposActivityMedicines' }
    )
  )
)

export default useActivityMedicinesReposStore
