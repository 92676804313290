import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/system";
import { toast } from "react-toastify";
import moment from "moment";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Paper } from "@mui/material";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import { ArrowUpward, ArrowDownward, Close, ExpandMore, Search, Female, Male, ChecklistRtl, Balance } from '@mui/icons-material';
import { H6, Span } from "../../components/Typography";
import Loading from "../../components/Loader/Loading";
import useStore from "../../store/useStore";
import api from '../../services/api';
import CardHeard from "./CardHeard";
import { useParams } from "react-router-dom";
import { expandAndFade } from "../../components/Animations/animationKeyFrames";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const returnArrow = (valor) => {
  if (valor >= 0) {
    return <ArrowUpward style={{ color: '#28a745' }} />;
  } else {
    return <ArrowDownward style={{ color: '#ff0000' }} />;
  }
};

const FullDialogCardLote = ({ lote }) => {
  const { id } = useParams();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lote_images, setLoteImages] = useState([]);

  const property_id = (localStorage.getItem("Property") || 1);
  lote.tipo_item = 'lote';
  lote.id_farm = property_id;

  // ------ inicicia os repos
  const usestore = useStore();
  // seta opções das Images
  const useImagesRepos = usestore.reposImages;
  const listAcitivy = JSON.parse(JSON.stringify(usestore.reposLivestock.getLivestockStore('id', id)[0]));

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function calcGrowth(index, tipo, arrow) {
    if (!tipo || !lote.weight_list[index + 1]) {
      return arrow ? "" : "-";
    }
    var last_grow = lote.weight_list[index + 1][tipo];
    var atual = lote.weight_list[index][tipo];
    var valor = atual - last_grow;

    if (!arrow) {
      return (valor == 0 ? '-' : valor.toFixed(2)) + ' ';
    } else {
      return valor == 0 ? '' : returnArrow(valor);
    }
  }
  function calculaGmd(index) {
    if (!lote.weight_list[index + 1]) {
      return "-";
    }
    let d1 = new Date(lote.weight_list[index].date);
    let d2 = new Date(lote.weight_list[index + 1].date);
    let dias = d1.getDate() - d2.getDate();

    let gmd_item = lote.weight_list[index].weight - lote.weight_list[index + 1].weight;
    let gmd_item_f = (gmd_item / (dias || 1)).toFixed(2);

    return gmd_item_f;
  }

  function getWeightsLote(index, tipo) {
    return (<>
      {lote.weight_list.length > 0 ?
        lote.weight_list.map((pesagem, index) => (
          <tr key={6 + index}>
            <td>{formatData(pesagem.date)}</td>
            <td>{pesagem.weight}</td>
            <td>{calcGrowth(index, 'weight')} {calcGrowth(index, 'weight', true)}</td>
            <td>{calculaGmd(index)}</td>
          </tr>
        )) :
        (<tr key={6 + index} style={{ textAlign: 'center' }}>
          <td colSpan={5}>Nenhuma Pesagem realizada</td>
        </tr>)
      }
    </>)
  }

  function getListAnimals(index, tipo) {
    return (<>
      {lote.list_animals.length > 0 ?
        lote.list_animals.map((row, index) => (
          <tr key={6 + index}>
            <td>{row.identifier}</td>
            <td>{row.sexo == 'Macho' ? <Male color="primary" /> : <Female color="secondary" />} {row.sexo.slice(0, 1)}</td>
            <td>{row.raca || '-'}</td>
            <td>{row.weight ? row.weight + " (" + row.weight_unit + ")" : '-'}</td>
            <td>{formatData(row.date_birth)}</td>
            <td>{row.idade}</td>
          </tr>
        )) :
        (<tr key={6 + index} style={{textAlign: 'center'}}>
          <td colSpan={6}>Nenhum Animal Identificado</td>
        </tr>)
      }
    </>)
  }

  function formatData(Inputdata) {
    if (!Inputdata) {
      return "";
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  const handleGetImages = async (img_key) => {
    const listImageRepos = useImagesRepos.getImageStore("lote", lote.id);
    const itemData = listImageRepos.filter((img) => {
      if (img_key && img.image != img_key) {
        // deleta a outra imagem do lote
        useImagesRepos.delImageStore(img.image);
        api.delete(`/upload/${img.image}/1`);
      } else if (img.isDeleted == 0) {
        return img.img;
      }
    });
    lote.image = itemData[0] ? itemData[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`;
    setLoteImages(lote.image);
  };
  const handleDelete = async () => {
    const listImageRepos = useImagesRepos.getImageStore("lote", lote.id);
    if (!listImageRepos[0]) {
      return;
    }
    const res = await api
      .delete(`/upload/${listImageRepos[0].image}/0`)
      .then((response) => {
        useImagesRepos.updateImageStore(listImageRepos[0].image, "isDeleted", 1);
        toast.success("Imagem movida para a lixeira com sucesso!");
        setTimeout(() => {
          handleGetImages();
        }, 20);
      })
      .catch(() => {
        toast.error("Erro ao mover a imagem para a lixeira!");
      });
  };

  useEffect(() => {
    if (open) {
      handleGetImages();
    }
    setIsLoading(true);
  }, [open]);

  function print(e) {
    var content = document.getElementById("root");
    content.classList.add("no-print");
    window.print();
    content.classList.remove('no-print');
  }

  return (
    <Box>
      <IconButton variant="contained" color="success" onClick={handleClickOpen} title="Ficha do Lote" sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
        <Search />
      </IconButton>
      {isLoading === false ? (<Loading />) : (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }} color="" className="no-print">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar">
                <Close />
              </IconButton>
              <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0"> Ficha do Lote{" "} </H6>
              <div className="no-print">
                <button onClick={print} id="btnPrint" className="btn btn-default float-right">
                  <i className="fas fa-print " /> Imprimir
                </button>
              </div>
            </Toolbar>
          </AppBar>

          <section className="content " id={"boxCardLote_" + lote.id}>
            <div className="container-fluid " style={{ maxWidth: 900 }}>
              <Card sx={{ display: 'block', textAlign: 'center' }}>
                <CardContent sx={{ flex: '1 0 auto' }} >
                  <Typography component="div" variant="h5"> Ficha do lote </Typography>
                </CardContent>
              </Card>

              <Container component="main" maxWidth="lg" sx={{}}>
                <Paper variant="" sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <CardHeard image_livestok={lote_images || lote.image_livestock} item={lote} callBack={handleGetImages} callBackHook={useImagesRepos} handleDelete={handleDelete} />
                    </Grid>
                  </Grid>
                </Paper>
              </Container>

              <Card sx={{ display: 'flex', width: "100%" }}>
                <div className="card-body table-responsive ">
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="40%" style={{ "width": "40%" }} />
                      <col colwidth="30%" style={{ "width": "30%" }} />
                      <col colwidth="30%" style={{ "width": "30%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={3} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Nome</th>
                        <th>Espécie</th>
                        <th>Fase de Manejo</th>
                      </tr>
                      <tr key={4}>
                        <td>{lote.name}</td>
                        <td>{lote.group_name}</td>
                        <td>{lote.fase_name}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={1} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Total animais</th>
                        <th>N.I.</th>
                        <th>Femeas</th>
                        <th>Machos</th>
                      </tr>
                      <tr key={2}>
                        <td>{lote.num_animais}</td>
                        <td>{lote.num_animais_ni || '-'}</td>
                        <td>{lote.num_femeas}</td>
                        <td>{lote.num_machos}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered ">
                    <colgroup>
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                      <col colwidth="25%" style={{ "width": "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr key={3} style={{ "backgroundColor": "#f4f4f4" }}>
                        <th>Data entrada</th>
                        <th>Peso total</th>
                        <th>Peso médio</th>
                        <th >
                          <Typography sx={{ animation: `${expandAndFade} 2s linear infinite` }}> <strong>Gmd médio</strong> </Typography>
                        </th>
                      </tr>
                      <tr key={4}>
                        <td>{lote.date_created_f}</td>
                        <td>{lote.weight_total ? lote.weight_total + " (" + lote.weight_unit + ")" : '-'}</td>
                        <td>{lote.weight ? lote.weight + " (" + lote.weight_unit + ")" : '-'}</td>
                        <td>{lote.gmd}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>

              <Divider sx={{ mt: 1, mb: 1, borderColor: '#2e7d32' }} />

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography sx={{ ml: 2.5 }} component="div" variant="h6"> 
                    <Balance color="success" sx={{ mr: 1 }} /> Pesagens: 
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="card-body table-responsive " style={{marginTop: '-25px'}} >
                    <table className="table table-bordered ">
                      <colgroup>
                        <col colwidth="25%" style={{ "width": "25%" }} />
                        <col colwidth="25%" style={{ "width": "25%" }} />
                        <col colwidth="25%" style={{ "width": "25%" }} />
                        <col colwidth="25%" style={{ "width": "25%" }} />
                      </colgroup>
                      <tbody>
                        <tr key={5} style={{ "backgroundColor": "#f4f4f4" }}>
                          <th>Data</th>
                          <th>Peso ({lote.weight_unit})</th>
                          <th>Crescimento ({lote.weight_unit})</th>
                          <th>GMD ({lote.weight_unit})</th>
                        </tr>
                        {getWeightsLote()}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </Accordion>

              {!lote.list_animals.length ? <></> : <>
                <Divider sx={{ mt: 1, mb: 1, borderColor: '#2e7d32' }} />

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography sx={{ ml: 2.5 }} component="div" variant="h6"> 
                      <ChecklistRtl color="success" sx={{ mr: 1 }} /> Animais Identificados: 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="card-body table-responsive " style={{marginTop: '-25px'}} >
                      <table className="table table-bordered ">
                        <colgroup>
                          <col colwidth="22%" style={{ "width": "22%" }} />
                          <col colwidth="09%" style={{ "width": "09%" }} />
                          <col colwidth="14%" style={{ "width": "14%" }} />
                          <col colwidth="14%" style={{ "width": "14%" }} />
                          <col colwidth="12%" style={{ "width": "12%" }} />
                          <col colwidth="29%" style={{ "width": "29%" }} />
                        </colgroup>
                        <tbody>
                          <tr key={5} style={{ "backgroundColor": "#f4f4f4" }}>
                            <th>Idenificação</th>
                            <th>Sexo</th>
                            <th>Raça</th>
                            <th>Peso Atual</th>
                            <th>Nascimento</th>
                            <th>Idade (Meses)</th>
                          </tr>
                          {getListAnimals()}
                        </tbody>
                      </table>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>}
            </div>
            <br />
            <br />
            <Divider sx={{ mt: 1, mb: 5, borderColor: '#2e7d32' }} />
          </section>

          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }} className="no-print">
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="contained" type="submit" onClick={handleClose}>
                  <Span sx={{ textTransform: "capitalize" }}>Fechar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Dialog>
      )}
    </Box>
  );
};

export default FullDialogCardLote;