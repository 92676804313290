import React, { useState, useEffect, useContext } from 'react'
import { ToolsMenuButtons, StyleBN } from './components/ToolsMenu'
// import { blue, brown, green, grey } from '@mui/material/colors'
//import { BottomNavigation, ListItem } from "@mui/material";
import BottomNavigation from '@mui/material/BottomNavigation'
import FloatingButtonGeneral from '../../components/Button/FloatingButtonGeneral'
import { Context } from '../Context/AuthContext'

export function StyleBNBotton() {
  return {
    bgcolor: '#FFF',
    //bgcolor: "green",
    '& .Mui-selected': {
      '& .MuiBottomNavigationAction-label': {
        fontSize: (theme) => theme.typography.caption,
        transition: 'none',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
      '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
        color: 'green', //theme => theme.palette.secondary.main
        //color: "white",
      },
    },
  }
}

export default function NavBotton({ tipo }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { menuContext } = useContext(Context)

  const activeButton = localStorage.getItem('ButtonActive')
  const [value, setValue] = useState(activeButton || 'Central')

  const handleChange = (event, newValue) => {
    //localStorage.setItem('ButtonActive', newValue);
    setValue(newValue)
  }
  const handleClose = (e) => {
    setAnchorEl(null)
    //localStorage.setItem('ButtonActive', e.currentTarget.name);
    setValue(e.currentTarget.name)
  }

  const tipoMenu = localStorage.getItem('navMenuActive') || 'selectProperty'
  const toolsMenuButtons = ToolsMenuButtons(tipoMenu, handleClose, 'botton')
  const [buttonsMenu, setButtonsMenu] = useState(toolsMenuButtons)
  useEffect(() => {
    // console.log('NavBotton - toolsMenuButtons')
    setButtonsMenu(toolsMenuButtons)
    let activeButton_n = localStorage.getItem('ButtonActive')
    if (value !== activeButton_n) {
      setValue(activeButton_n)
    }
  }, [value, menuContext])

  // const getColorButton = function () {
  //   var color = 'success'
  //   if (tipoMenu === 'selectProperty') {
  //     color = green['28A']
  //   } else if (tipoMenu === 'selectCreationHarvest') {
  //     color = green['10A']
  //   } else if (tipoMenu === 'selectAreaMap') {
  //     color = grey['2C2']
  //   }
  //   return color
  // }

  var class_nav = ''
  if (tipoMenu === 'selectProperty') {
    class_nav = window.innerWidth < 1100 ? 'navbar' : ''
  } else if (tipoMenu === 'selectCreationHarvest') {
    class_nav = window.innerWidth < 800 ? 'navbar' : ''
  } else if (tipoMenu === 'selectAreaMap') {
    class_nav = window.innerWidth < 500 ? 'navbar' : ''
  }
  const classesNav = ' main-header ' + class_nav + ' navbar-expand navbar-white navbar-light fixed-bottom overflow-auto shadow-lg '

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  return (
    <>
      <nav className={classesNav} style={{ padding: 0 }}>
        {tipo === 'user' ? (
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              handleChange(event, newValue)
            }}
            sx={StyleBN}
          >
            {buttonsMenu.getButtonPainel(tipo)}
            {buttonsMenu.geraButtonMeusDados(tipo)}
            {buttonsMenu.geraButtonGuiaRapido(tipo)}
            {buttonsMenu.geraButtonPropriedades(tipo)}
            {buttonsMenu.geraButtonSettings(tipo)}
            {userCurrent.permissions !== 'owner' ? <></> : buttonsMenu.geraButtonAssinatura(tipo)}
            {buttonsMenu.geraButtonImagens(tipo)}
            {buttonsMenu.geraButtonDocumentos(tipo)}
            {buttonsMenu.geraButtonChangePassword(tipo)}
            {buttonsMenu.geraButtonIndiceExperiencia(tipo)}
          </BottomNavigation>
        ) : (
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              handleChange(event, newValue)
            }}
            sx={StyleBNBotton}
          >
            {buttonsMenu.getButtonPainel()}
            {buttonsMenu.getButtonMap()}
            {buttonsMenu.getButtonArea()}
            {buttonsMenu.getButtonTeam()}
            {buttonsMenu.getButtonSolicitacoes()}
            {buttonsMenu.getButtonCreations()}
            {buttonsMenu.getButtonAnimaisCulturas()}
            {buttonsMenu.getButtonSafras()}
            {buttonsMenu.getButtonActivities()}
            {buttonsMenu.getButtonResources()}
            {buttonsMenu.getButtonProductionCost()}
            {/* {buttonsMenu.getButtonPlanning()} */}
            {buttonsMenu.getButtonProduction()}
            {buttonsMenu.getButtonProduct()}
            {buttonsMenu.getButtonInventory()}
            {buttonsMenu.geraButtonPatrimony()}
            {buttonsMenu.geraButtonBuy()}
            {buttonsMenu.geraButtonSales()}
            {buttonsMenu.getButtonFinancial()}
            {buttonsMenu.geraButtonIndicadores()}
            {buttonsMenu.geraButtonRelatorios()}
          </BottomNavigation>
        )}
      </nav>
      {/* <FloatingButtonGeneral /> */}
    </>
  )
}
