import React from "react";
import { Typography, Box, FormControlLabel, Checkbox, TextField, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 10px;
`;

const FormCostActivities = (data, setData) => {
  console.log("FormCostActivities");
  console.log(data);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    // setData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
  };

  return (
    <Box display="flex" alignItems="center" marginBottom="10px" sx={{ mb: 10 }}>
      {/* {icon} */}
      <Typography variant="h6" marginLeft="10px">
        {/*  {title} */}
      </Typography>
      <Box display="flex" flexDirection="column" marginLeft="20px"></Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="seedsCost"
            variant="standard"
            label="Custo de Sementes"
            type="number"
            required
            fullWidth
            value={data.seedsCost}
            onChange={handleChange}
            //error={formError.area && data.area === ""}
            //helperText={formError.area && data.area === "" ? "Por favor, preencha o campo obrigatório!" : "Estime a área da sua produção."}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="fertilizersAndPesticidesCost"
            variant="standard"
            label="Custo de Fertilizantes e Defensivos"
            type="number"
            required
            fullWidth
            value={data.fertilizersAndPesticidesCost}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" name="laborCost" label="Custo de Mão de Obra" type="number" required fullWidth value={data.laborCost} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" name="fuelCost" label="Custo de Combustível" type="number" required fullWidth value={data.fuelCost} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" name="electricityCost" label="Custo de Energia Elétrica" type="number" required fullWidth value={data.electricityCost} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            name="machineryMaintenanceCost"
            label="Custo de Manutenção de Máquinas"
            type="number"
            required
            fullWidth
            value={data.machineryMaintenanceCost}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" name="landRentCost" label="Custo de Aluguel de Terras" type="number" required fullWidth value={data.landRentCost} onChange={handleChange} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormCostActivities;
