import dayjs from 'dayjs';

let dataProperty = [];
let resposPropertyString = localStorage.getItem('reposProperty');

if (resposPropertyString) {
  let resposProperty = JSON.parse(resposPropertyString);
  dataProperty = resposProperty.state.listProperty[0];
  console.log('imageProperty');
  console.log(dataProperty);
} else {
  console.log('Nenhum dado encontrado em resposProperty');
}

let dataPatrimony = [];
let resposStockString = localStorage.getItem('reposPatrimony');

if (resposStockString) {
  let resposPatrimony = JSON.parse(resposStockString);
  dataPatrimony = resposPatrimony.state.listPatrimony;
  console.log('dataPatrimony');
  console.log(dataPatrimony);
} else {
  console.log('Nenhum dado encontrado em resposProperty');
}

//console.log(dataProperty);
//const base64Image = dataProperty.image;
//console.log(base64Image);

// function loadBase64Image(base64) {
//   return new Promise((resolve, reject) => {
//     const image = new Image();

//     // Evento disparado quando a imagem é carregada com sucesso
//     image.onload = () => {
//       resolve(image);
//     };

//     // Evento disparado em caso de erro ao carregar a imagem
//     image.onerror = () => {
//       reject(new Error("Falha ao carregar a imagem base64."));
//     };

//     // Define a string base64 como fonte da imagem
//     image.src = base64;
//   });
// }
// console.log(dataProperty);
// const base64Image = dataProperty.image;

// loadBase64Image(base64Image)
//   .then((image) => {
//     // A imagem foi carregada com sucesso
//     console.log("Imagem base64 carregada!", image);
//   })
//   .catch((error) => {
//     // Houve um erro ao carregar a imagem
//     console.error(error);
//   });

// Função para formatar lotes
function formatLotes(lotes) {
  return lotes.map((lote) => `${lote.name} (${lote.total_animals} animais)\n`);
}

function formatData(inputData) {
  return !inputData ? '' : dayjs(inputData).format('DD/MM/YYYY');
}
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

const listLocation = (locations) => {
  return locations.map((location) => `${location.name}(${location.quanty})`).join(', ');
};

// Atualizando livestockTableRows
const patrimonyTableRows = dataPatrimony.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    //{ text: item.total_animais || "Tipo não especificado" }, // Verifica se 'tipo' está disponível
    { text: item.name || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.group || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: item.type || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    //{ text: item.stock_quanty || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    //{ text: `total:${item.stock_quanty} \n${listLocation(item.location)}  ` || 'Data não especificada', fontSize: 7 },
    //{ text: `min: ${item.stock_quanty_min} \n max: ${item.stock_quanty_max}` || 'Data não especificada', fontSize: 7 }, // Converte para data
    //{ text: formatCurrency(item.stock_cost) || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    // ... outras propriedades conforme necessário
  ];
});
console.log('patrimonyTableRows');
console.log(patrimonyTableRows);

const lineBlank = [
  {
    text: '',
    alignment: 'center',
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
];

export const bodyListPatrimonyProperty = {
  content: [{ text: 'Patrimônios da propriedade', fontSize: 10, alignment: 'left', margin: [0, 4, 0, 0], color: 'green' }],
};

// // Adicionando a imagem se ela existir
// if (dataProperty) {
//   ListCreationHarvest.content.push({
//     alignment: "justify",
//     columns: [
//       // {
//       //   image: base64Image || "",
//       //   width: 100, // Ajuste a largura conforme necessário
//       //   alignment: "center",
//       // },
//       {
//         width: 10,
//         text: " ",
//       },
//       // {
//       //   width: "*",
//       //   text: `latitude: ${dataProperty.latitude}} \n\n longitude: ${dataProperty.longitude}`,
//       // },
//       // [`Propriedade: ${dataProperty.label}\n`, `Latitude: ${dataProperty.latitude} \n Longitude: ${dataProperty.longitude} \n`],
//       // [`Número de criações: ${dataProperty.total_livestocks} \n Total de animais: ${dataProperty.total_animais} \n `],
//       {
//         width: "*",
//         text: `${dataProperty.label}\n ${dataProperty.area} ${dataProperty.measure}\n Latitude: ${dataProperty.latitude} \n Longitude: ${dataProperty.longitude} \n`,
//         fontSize: 10,
//         margin: [4, 0, 0, 0],
//       },
//       {
//         width: "*",
//         text: `Número de criações: ${dataProperty.total_livestocks} \n Total de animais: ${dataProperty.total_animais} \n `,
//         fontSize: 10,
//         margin: [4, 0, 0, 0],
//       },
//     ],
//   });
// }

// adicionando a tabela
bodyListPatrimonyProperty.content.push(
  { text: '', fontSize: 10, bold: true, margin: [0, 20, 0, 0] },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [10, '*', '*', '*'],
      fillColor: '#eeeeee',
      body: [
        [
          { text: '#', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Nome do patrimônio', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Grupo', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Categoria', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          //{ text: 'Quant.', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          //{ text: 'Estoque', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          //{ text: 'Custo médio', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        ],
        ...patrimonyTableRows, // Linhas de dados da tabela
      ],
    },
    layout: 'lightHorizontalLines',
  }
);

// adicionando texto
bodyListPatrimonyProperty.content.push({
  width: '*',
  // text: `Número de produtos(insumos): ${dataProperty?.total_livestocks} \n Total de animais: ${dataProperty?.total_animais} \n `,
  text: `Número de patrimônios: ${patrimonyTableRows.length} `,
  fontSize: 10,
  color: '#808080',
  margin: [0, 10, 0, 0],
  alignment: 'right',
});

// // adicionando a tabela
// ListCreationHarvest.content.push(
//   { text: " ", style: "subheader" },
//   { text: "Lista", style: "subheader" },
//   {
//     style: "tableExample",
//     table: {
//       widths: [20, 40, "*", "*", 60, "*"],
//       body: [
//         ["#", "Animais", "Tipo", "Projeto(Criação)", "data", "Lote"], // Cabeçalhos da tabela
//         //{ text: "", italics: true, color: "gray" },
//         ...livestockTableRows, // Linhas de dados da tabela
//       ],
//     },
//     fontSize: 10,
//    // bold: true,
//   }
// );
