import * as React from 'react';
import { AppBar, Box, Button, Container, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from '@mui/material';
import { AddBox, Close } from '@mui/icons-material';
import MultiStepFormBuySales from './FormBuySales/MultiStepFormBuySales';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterBuysSalesDialog({ type }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color={'inherit'}
        sx={{ animation: `${expandAndFade} 2s linear infinite` }}
        style={{ color: '#FFF', backgroundColor: '#000', borderRadius: '10px', animation: `${expandAndFade} 2s linear infinite` }}
        startIcon={<AddBox />}
        onClick={handleClickOpen}
      >
        {type === 'sales' ? 'Pedido de Venda' : 'Pedido de Compra'}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box sx={{ width: '100%' }} id="focus_top_modal">
          <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
            <Toolbar>
              <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                {type === 'sales' ? 'Pedido de Venda' : 'Pedido de Compra'}
              </Typography>
              {/* <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography> */}
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <Close sx={{ color: '#000' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="lg" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <MultiStepFormBuySales type={type} handleClose={handleClose} property={''} setProperty={''} formError={true} setFormError={true} />
            </Paper>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}
