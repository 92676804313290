import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import TitleIcon from '@mui/icons-material/Title';
import { getIconComponent } from '../../../patrimony/components/getIconComponent';
import { useEffect } from 'react';
import { Context } from '../../../../components/Context/AuthContext';
import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { red } from '@mui/material/colors';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import Typewriter from '../../../../components/Typewriter/Typewriter';

// const type = [
//   {
//     value: 'ArmazemGalpao',
//     label: 'Armazém/Galpão',
//   },
//   {
//     value: 'BenfeitoriasSilos',
//     label: 'Benfeitorias/Silos',
//   },
//   {
//     value: 'Outros',
//     label: 'Outros',
//   },
// ]

export default function RegisterNewStorageLocations() {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [name, setName] = useState('');
  const [observation, setObservation] = useState('');

  const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  const [data, setData] = useState({
    name: '',
    group: '',
    //id_group: selectedGroup,
    model: '',
    type: '',
    manufacturer: '',
    manufacturing_year: '',
    lifespan: '',
    date_prev_end: formattedCurrentDate,
    value: '0,00',
    horimetro_current: 0,
    observation: '',
    image: '',
    // id_property: property_id
  });

  const handleChange = (event) => {
    // console.log('handleChangeInput')
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    // Limpa o erro relacionado ao campo que está sendo alterado
    setFormError((prevErrors) => ({
      ...prevErrors,
      [event.target.name]: false,
    }));
  };

  const { aux_add, setAuxAdd } = React.useContext(Context);
  var id_farm = localStorage.getItem('Property') || 1;

  const reposPatrimony = usePatrimonyReposStore((state) => state);
  //console.log(reposPatrimony.patrimonyGroup)
  //console.log(reposPatrimony.patrimonyType)

  const [patrimonyGroup, setPatrimonyGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [patrimonyType, setPatrimonyType] = useState([]);

  const handleChangeGroup = (event) => {
    // console.log(event.target.value)
    const selectedGroup = patrimonyGroup.find((group) => group.id === event.target.value);
    if (selectedGroup) {
      setSelectedGroup(selectedGroup);
      setData((prevState) => ({
        ...prevState,
        group: selectedGroup.name,
      }));
      // Limpa o erro relacionado ao campo que está sendo alterado
      setFormError((prevErrors) => ({
        ...prevErrors,
        ['selectedGroup']: false,
      }));
    }
  };

  //console.log(reposPatrimony?.patrimonyGroup)

  useEffect(() => {
    const filteredPatrimony = reposPatrimony?.patrimonyGroup?.filter((item) => item.id === 1 || item.id === 2);
    setPatrimonyGroup(filteredPatrimony);
    //setPatrimonyGroup(reposPatrimony?.patrimonyGroup)
    setPatrimonyType(reposPatrimony?.patrimonyType);
  }, [aux_add]);

  const filterTypesByGroup = (group, typesFromGroup) => {
    return typesFromGroup.filter((type) => {
      return type.patrimony_group_id === group.id;
    });
  };

  useEffect(() => {
    const filteredPatrimony = reposPatrimony?.patrimonyGroup?.filter((item) => item.id === 1 || item.id === 2);
    // console.log(filteredPatrimony)
    setPatrimonyGroup(filteredPatrimony);
    //setSelectedGroup(reposPatrimony?.patrimonyGroup[1])
    //setSelectedGroup(reposPatrimony?.patrimonyGroup[4])
  }, []);

  const filteredTypes = filterTypesByGroup(selectedGroup, patrimonyType);

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  // armazenar os arays de erros de cada variável desejada
  const [formError, setFormError] = useState({});

  const handleSubmit = async () => {
    // console.log('handleSubmit')
    // incializa o formulario de erros como vazia
    setFormError({});
    // incializa os erros
    let errors = {};
    // incializa o validador do submiit como true
    let formOk = true;

    if (!data.name) {
      errors.name = true;
      formOk = false;
    }

    if (!selectedGroup.id) {
      errors.selectedGroup = true;
      formOk = false;
    }

    if (!data.type) {
      errors.type = true;
      formOk = false;
    }

    // coleta os eeros
    setFormError(errors);
    // console.log(formOk)

    const selectedType = reposPatrimony.patrimonyType.filter((item) => item.name === data.type)[0];
    console.log(selectedType);
    const dataToSubmit = {
      id_property: Number(property_id),
      id_user: userCurrent.id,
      id_patrimony_grid: 1,
      id_patrimony_type: selectedType.id,
      patrimony_group_id: selectedGroup.id,
      name: data.name,
      patrimony_group: data.group,
      model: data.model,
      patrimony_type: data.type,
      manufacturer: data.manufacturer,
      manufacturing_year: data.manufacturing_year || 0,
      lifespan: data.lifespan || 0,
      date_buy: data.date_prev_end, // date_prev_end -> date_buy
      value: data.value === '0,00' ? 0 : data.value,
      horimetro_current: data.horimetro_current,
      observation: data.observation,
      image: data.image,
    };
    // console.log(data)
    // console.log(dataToSubmit)

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('patrimony/', dataToSubmit, header)
        .then((response) => {
          console.log('response.data');
          console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Patrimônio cadastrado com sucesso!');
            handleClose();
            // resetForm()
            // setUpdateComponent((prevCounter) => prevCounter + 1);
            reposPatrimony.fetch({ id_farm: id_farm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
                // handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar patrimônio, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
      setFormError(errors);
      // console.log(formError)
    }
  };

  //console.log(data)

  return (
    <>
      <Button variant="text" color="success" startIcon={<AddBoxIcon />} sx={{ fontSize: '0.75rem' }} onClick={handleClickOpen}>
        <Typography variant="button" sx={{ fontSize: '0.75rem' }}>
          Novo local de estoque
        </Typography>
      </Button>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle>Novo Local de Estoque</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
                        You can set my maximum width and whether to adapt or not.
                    </DialogContentText> */}
          <div>
            {/* <Typography variant="h5"></Typography> */}
            {/* <Typography variant="subtitle1">INFORMAÇÕES Essenciais</Typography> */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                {true ? (
                  <Typewriter text={`Crie um novo local de estoque para os seus produtos ou utlize o estoque padrão, "Galpão principal", já existente.`} variant={'subtitle1'} color={'#2e7d32'} />
                ) : (
                  <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                    {' '}
                    <ThreeDotsSpinner />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  focused
                  color="success"
                  variant="standard"
                  label="Nome do patrimônio (local do novo estoque)"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={handleChange}
                  error={formError.name}
                  FormHelperTextProps={{ style: { color: formError.name ? red[800] : '#2e7d32' } }}
                  helperText={formError.name ? 'Campo obrigatório' : 'Mínimo de 3 caracteres'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <TitleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField variant="standard" fullWidth color="success" label="Observação" name="observation" value={data.observation} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="group"
                  name="group"
                  label="Tipo"
                  select
                  focused
                  required
                  fullWidth
                  value={selectedGroup?.id || ''}
                  onChange={(e) => handleChangeGroup(e)}
                  variant="outlined"
                  color="success"
                  FormHelperTextProps={{ style: { color: formError.selectedGroup ? red[800] : '#2e7d32' } }}
                  helperText={formError.selectedGroup ? 'Campo obrigatório' : 'Defina o tipo de patrimônio'}
                >
                  <Typography sx={{ ml: 1, color: 'gray' }} variant="subtitle2">
                    Selecine o tipo de patrimônio
                  </Typography>
                  {patrimonyGroup.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {getIconComponent(group.icon)}
                      {group.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                {data.group === '' ? (
                  <TextField label="Tipo de patrimônio" select required fullWidth variant="outlined" helperText="Selecione o grupo do patrimônio primeiro" disabled></TextField>
                ) : (
                  <TextField
                    InputLabelProps={{
                      style: { color: '#2e7d32' },
                    }}
                    id="type"
                    name="type"
                    label={`Tipo de ${data.group}`}
                    select
                    focused
                    required
                    fullWidth
                    color="success"
                    value={data.type}
                    onChange={handleChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <IconButton>
                    //         <CheckBoxOutlineBlankIcon />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    variant="outlined"
                    FormHelperTextProps={{ style: { color: formError.type ? red[800] : '#2e7d32' } }}
                    helperText={formError.type ? 'Campo obrigatório' : 'Especifique o tipo'}
                  >
                    <Typography sx={{ ml: 1, color: 'gray' }} variant="subtitle2">
                      Selecine o tipo de patrimônio
                    </Typography>
                    {filteredTypes.map((type) => (
                      <MenuItem key={type.name_nf} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            </Grid>
          </div>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          ></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button variant="contained" color="success" onClick={handleSubmit} sx={{ marginRight: 2 }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
