import React, { useState, useEffect, useContext } from "react";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuMsn from "../DialogMessenger/forms/MenuMsn";
import MenuMsnMin from "../DialogMessenger/forms/MenuMsnMin";
import { green, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconCheckboxes from "./IconCheckboxes";
import GradingIcon from "@mui/icons-material/Grading";
import CardChat from "./CardChat";
import CardChatImage from "./CardChatImage";
import Loading from "../../../components/Loader/Loading";
import api from "../../../services/api";
import { Context } from "../../../components/Context/AuthContext";
import SidebarChatCompactGeneralCardEmpty from "./SidebarChatCompactGeneralCardEmpty";
import useStore from "../../../store/useStore";

export default function SidebarChatCompactGeneral({ isLoading, setIsLoading }) {
  const [msn, setMsn] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = useState(true);
  const property_id = (localStorage.getItem("Property") || 1);
  const { aux_add } = useContext(Context);

  // repos
  const usestore = useStore();
  const propertyData = usestore.reposProperty.getPropertyStore('id', property_id);
  const reposMessenger = usestore.reposMessenger;
  const reposUser = usestore.reposUser;

  const loadPosts = async () => {
    console.log("-------loadPosts------------:");
    const optionsEquipe = reposUser.listUser;
    const itemData = [];

    for (const info of reposMessenger.listMessenger) {
      if (info.isDeleted === 0 && info.id_property == property_id) {
        info.name_property = propertyData.name;
        info.name_receiver = optionsEquipe.filter(item => {
          if (item.id == info.id_receiver) {
            console.log(item.name);
            return item.name;
          }
        })
        //console.log('name_receiver');
        info.name_receiver = (info.name_receiver[0] ? info.name_receiver[0].name : '');
        //console.log(info.name_receiver);

        for (const iterator of optionsEquipe) {
          if (iterator.id === info.id_requester) {
            info.name = iterator.name
            const name = info.name
            //console.log(name)
            const charName = name[0].toUpperCase();
            //console.log(charName)
            info.charName = charName
          }
        }
        itemData.push(info);
      }
    }
    //console.log('lista dos cards')
    //console.log(itemData)

    //ordernar as data
    itemData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    setMsn(itemData);
    setIsLoadingCard(false)
    //setIsLoading(false)
  };

  useEffect(() => {
    console.log('refresh loadPosts');
    loadPosts();
    //console.log(msn)
  }, [aux_add , isLoadingCard]);

  return (
    <>
      {msn.length == 0 && <SidebarChatCompactGeneralCardEmpty />}
      <div className="container-fluid " style={{ maxWidth: 900 }}>
        <ul className="contacts-list ">
          {isLoadingCard === true && isLoading === true ? (
            <>
              <Loading />
            </>
          ) :
            (
              <>
                {/* <SpeechAccordion sx={{ mt: 3 }} /> */}
                {/* {msn.length === 0 && <span>Sem cards no momento</span> } */}

                {msn?.map((post) => (
                  <li>
                    <CardChat key={post.id} post={post} setIsLoading={setIsLoading} />
                  </li>
                ))}

              </>
            )}
          {/* <li>
          <CardChatImage />
        </li> */}
        </ul>
      </div>
    </>
  );
}
