import { Grid, Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../components/Loader/Loading";
import MessengerMapBottom from "./components/Messenger/MessengerMapBottom";
import MapsGoogleMap from "./MapsGoogleMap";
import LinearIndeterminate from "../../components/Loader/LoagingLinear";
import useStore from "../../store/useStore";

export default function MapsContent() {
  // // carregamento das credenciais
  // const { isLoaded, loadError } = useJsApiLoader({
  //   // googleMapsApiKey: process.env.local.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  //   googleMapsApiKey: "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU", // ,
  //   // ...otherOptions
  // });



  const [isLoadingMap, setIsLoadingMap] = useState(false);

  useEffect(() => {
    console.log('atualiza o mapa ')
    console.log(isLoadingMap)
    if (isLoadingMap === true) {
      setIsLoadingMap(false)
    }
  }, [isLoadingMap]);

  // instancia os repos


  return (
    //isLoadingMap
    <>
      {isLoadingMap === true ?
        (
          <div className=" m-0 p-0">
            <div className=""></div>
            <div className="">
              <div className="" style={{ height: "100%", position: "relative", width: "100%", left: 0, right: 0, bottom: 0, top: 0 }}>
                <LinearIndeterminate />
              </div>
              <div className="" style={{ height: "100%", position: "fixed", width: "100%", left: 0, right: 0, bottom: 0, top: 80, overflow: "hidden" }}>
                <Grid container spacing={1}   >
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={"100%"} height={800} />
                  </Grid>
                  <Grid item xs={12} >
                    <Skeleton variant="rectangular" width={"100%"} height={80} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ) :
        (
          <div className="content-wrapper m-0 p-0">
            <div className=""></div>
            <div className="content-fluid">
              <div className="" style={{ height: "100%", position: "fixed", width: "100%", left: 0, right: 0, bottom: 0, top: 89, overflow: "hidden" }}>
                <MapsGoogleMap isLoadingMap={isLoadingMap} setIsLoadingMap={setIsLoadingMap} />
                <MessengerMapBottom isLoadingMap={isLoadingMap} setIsLoadingMap={setIsLoadingMap} />
              </div>
            </div>
          </div>
        )}
    </>
  );
}

//https://react-google-maps-api-docs.netlify.app/
