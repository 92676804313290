import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Span } from '../../../../components/Typography';
import MenuPlan from '../../../signature/components/MenuPlan';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import AddActivities from '../../../activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function TablesCreationsCardProjectWorker({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  //cores:
  //#45bc82

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Equipe de campo
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontWeight={400} style={{ color: '#2e7d32' }}>
            "Liderar é inspirar o time a superar desafios e alcançar resultados excepcionais, sempre com foco na excelência e na eficiência."
          </Typography>
          <Card sx={{ m: 2, backgroundColor: '#217346' }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    {' '}
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }} style={{ color: 'white' }}>
                        Gerenciamento de projetos pecuários:
                      </Typography>
                    </Box> */}
                    <Box sx={{ minHeight: 80 }}>
                      <Typography style={{ color: 'white' }}>{isLoadingText && <Typewriter tam={80} minHeight={0} text="Para uma equipe de campo de excelência:" />}</Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    {/* <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" style={{ color: 'white' }}>
                        Gerenciamento de projetos pecuários:
                      </Typography>
                    </Box> */}
                    <Box sx={{ minHeight: 35 }}>
                      <Typography style={{ color: 'white' }}>{isLoadingText && <Typewriter tam={30} minHeight={0} text="Para uma equipe de campo de excelência:" />}</Typography>
                    </Box>
                  </div>
                  <List sx={{ width: '100%', bgcolor: '#217346' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        {/* <Avatar alt="fases" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar sx={{ bgcolor: 'white' }}>
                          <img src="/dist/img_fp/icon-button/todas_pecuarias.png" className="img-fluid" width="40" alt="Imagem responsiva"></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary="FASE: INÍCIO DO PROJETO"
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                              Início:
                            </Typography>
                            <span style={{ color: 'white' }}>{' Entender os objetivos e a importância do projeto.'}</span>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <EmojiEventsIcon />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <PendingActionsIcon style={{ fontSize: 24 }} />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <ListAltIcon style={{ fontSize: 24 }} />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: PLANEJAMENTO E EXECUÇÃO</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Meta:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Compreenda as metas claras e os resultados esperados para o projeto.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Caderno de campo:
                              </Typography>
                              <span style={{ color: 'white' }}>
                                {' Siga o cronograma detalhado das atividades diárias. Execute suas tarefas conforme designado, sabendo exatamente o que é esperado de você.'}
                              </span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Recursos:
                              </Typography>
                              <span style={{ color: 'white' }}>
                                {' Utilize os insumos e equipamentos fornecidos de maneira eficiente e conforme as instruções. Trabalhe em colaboração com sua equipe.'}
                              </span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Cooperação a equipe:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Siga as orientações do gerente, colabore com seus colegas e mantenha a motivação alta.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Implementação de práticas e tecnologias:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Utilize métodos e tecnologias recomendadas para maximizar a eficiência e a produção.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Supervisionar as atividades:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Garantir que as tarefas sejam realizadas conforme o planejado.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <DashboardIcon />
                        </Avatar>
                        <Avatar sx={{ mt: 1, bgcolor: '#37a96e' }}>
                          <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} fill={'white'} />
                        </Avatar>
                        <Avatar sx={{ mt: 2, bgcolor: '#37a96e' }}>
                          <ShoppingCartIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: MONITORAMENTO E CONTROLE</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Progresso da atividade:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Execute e reporte o progresso das atividades regularmente, bem como os problemas'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Monitoramento dos animais:
                              </Typography>
                              <span style={{ color: 'white' }}>
                                {' Verifique regularmente o estado de saúde dos animais e informe qualquer anomalia. Monitore e registre o desempenho dos animais.'}
                              </span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Corrigir desvios e ajustar planos:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Esteja pronto para ajustar sua abordagem conforme necessário, seguindo as diretrizes do gerente.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Relatar o progresso e desafios ao proprietário:
                              </Typography>
                              <span style={{ color: 'white' }}> {'  Informe qualquer problema ou progresso significativo ao gerente para ajustes necessários.'}</span>
                            </React.Fragment>
                            <br />
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Análise dos riscos:
                              </Typography>
                              <span style={{ color: 'white' }}> {' Esteja atento aos riscos e comunique qualquer preocupação ao gerente para ações corretivas.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#37a96e' }}>
                          <EmojiEventsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: 'white' }}
                        primary={<Box sx={{ mb: 2 }}>FASE: ENCERRAMENTO</Box>}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="white">
                                Avaliação:
                              </Typography>
                              <span style={{ color: 'white' }}>{' Participe da avaliação final do projeto, oferecendo feedback sobre o que funcionou e o que pode ser melhorado.'}</span>
                            </React.Fragment>
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
