import { useState } from 'react';
import { TextField, Box, Grid, Typography, InputAdornment, Stack, Card, CardContent, CardMedia, Button, AppBar, Toolbar } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import TitleIcon from '@mui/icons-material/Title';
import InputIcon from '@mui/icons-material/Input';
import { useEffect } from 'react';
import FormInputFooter from '../../activities/components/FormActivities/Input/FormInputFooter';
import ThreeDotsSpinner from '../../../components/Loader/ThreeDotsSpinner';
import useActivityInputReposStore from '../../../store/Activities/useActivityInputReposStore';
import { makeStyles } from '@mui/styles';
import Speech from '../../../components/Speech/Speech';
import Bot from '../../../components/Animations/Bot';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartFirstStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const [isloading, setIsloading] = useState(false);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (!data.nameBatch || data.nameBatch.trim() === "") {
    //     return true;
    // }
  };

  // para validar o input
  // const validateProjectName = (value) => {
  //     return value.trim().length > 0;
  // };

  const returnError = () => {
    return true;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  const text = 'Gostaria de desejar boas-vindas ao Fazenda Premium. Sou seu assistente pessoal e irei guiá-lo para você ter uma experiência agradável e de sucesso... clique em começar!';

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 4 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <Typography variant="h3" color="textPrimary" gutterBottom>
                  Bem vindo(a)
                </Typography>
                <Typography paragraph variant="h6" fontWeight={500}>
                  Vamos começar a administração da sua propriedade?
                </Typography>
                <Typography gutterBottom sx={{ mt: 2 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                  <Typewriter tam={60} text="Sou seu assistente pessoal e irei guiá-lo nesta jornada de sucesso. Vamos começar!!" />
                </Typography>
                <Grid container sx={{ mt: 4 }}>
                  <Grid item sm={6} xs={6}>
                    <Button
                      color="success"
                      sx={{
                        animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
                      }}
                      variant="contained"
                      type="submit"
                      onClick={!isError() ? handleNext : () => null}
                      disabled={isError()}
                    >
                      Começar
                    </Button>
                  </Grid>
                  <Grid item sm={6} xs={6} sx={{ textAlign: 'right', mt: -3 }}>
                    <Bot text={text} />
                  </Grid>
                </Grid>

                {/*  <Button
                                    variant="contained"
                                    color="primary"
                                    target="_blank"
                                    href="https://www.google.com/"
                                    disableElevation
                                >
                                    Começar
                                </Button> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <img src="/dist/image/start_entradadafazenda.jpg" style={{ height: 'auto', maxWidth: '100%', borderRadius: '10px' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
