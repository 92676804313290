import React from "react";
import { TailSpin } from "react-loader-spinner";

function Loading() {
  return (
    <div className="d-flex justify-content-center p-5">
      <TailSpin height="80" width="80" color="#4fa94d" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
    </div>
  );
}

export default Loading;

// https://www.youtube.com/watch?v=Id7GbGUOlFs
