import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ControlPanelCreationsContent from "./ControlPanelCreationsContent ";
import Footer from "../../components/Footer/Footer";

function ControlPanelCreations() {
  return (
    <>
      {/* <Nav /> */}
      <NavSide />
      <NavBotton />
      <ControlPanelCreationsContent />
      <Footer />
    </>
  );
}

export default ControlPanelCreations;
