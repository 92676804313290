import * as React from "react";
import Button from "@mui/material/Button";
import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { Span } from "../../../../../components/Typography";


export default function FormInputFooter({ data, setData, handleNext, handleBack, activeStep, steps, handleClose, isError, returnError }) {


  return (
    <>

      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>cancelar</Span>
            </Button>
          </Box>
          {data &&
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button color="success" variant="contained" type="submit" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
                {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
              </Button>
            </Box>
          }
        </Toolbar>
      </AppBar>

    </>
  );
}
