import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, InputAdornment, TextField, Typography, Button, Stack } from "@mui/material";
import { Call, ContactEmergency, DeleteForever, ExpandMore, Input, Mail, PhoneAndroid, } from "@mui/icons-material";

import PhoneTextField from '../../../../components/Fields/PhoneTextField';
// <PhoneTextField phone={data.phoneCell||''} handleChange={handleChange} format={name: 'phoneCell'}/>

const contact_default = {
  name: '',
  email: '',
  phoneCell: '',
  phoneCommercial: '',
  office: '', // responsibility
}

const AddNewContact = ({ contacts, setData }) => {
  const validEmail = (email) => {
    if (email !== '') {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        return true;
      }
    }
    return false;
  }

  const handleChange = (index, field, value) => {
    setData((prevState) => {
      const updatedContact = [...prevState.contacts];
      updatedContact[index][field] = value;
      return { ...prevState, contacts: updatedContact };
    });
    if (field === 'email') {
      // if (!validEmail(value)) {
      setData(prevState => ({ ...prevState, contact_err: validEmail(value) }));
      // }
    }
  };

  const handleAddContact = () => {
    setData((prevState) => ({ ...prevState, contacts: [...prevState.contacts, contact_default] }));
  };

  const handleDeleteContact = (index) => {
    setData((prevState) => ({ ...prevState, contacts: prevState.contacts.filter((_, i) => i !== index) }));
  };

  return (
    <Grid container spacing={2}>
      {contacts.map((contact, index) => (<>
        <Grid item xs={12} sm={12} key={'g_' + index}>
          <Accordion key={'ac_' + index}>
            <AccordionSummary key={'as_' + index} expandIcon={<ExpandMore />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`}>
              <Input />
              <Box sx={{ ml: 1, display: "flex" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}> Contato {index + 1}</Typography>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails key={'ad_' + index}>
              <Grid container spacing={2} sx={{mb: 2}}>
                <Grid item xs={6} sm={6}>
                  <TextField
                    name="name"
                    label="Pessoa de contato"
                    variant="standard"
                    color="success"
                    fullWidth
                    value={contact.name}
                    onChange={(e) => handleChange(index, "name", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <ContactEmergency />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={5}>
                  <TextField
                    name="email"
                    label="Email"
                    variant="standard"
                    color="success"
                    fullWidth
                    value={contact.email}
                    onChange={(e) => handleChange(index, "email", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Mail />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={validEmail(contact.email)}
                    helperText={validEmail(contact.email) ? "Adicione um email válido!" : 'Ex: cptSoftwares@cpt.com.br'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <PhoneTextField phone={contact.phoneCommercial} handleChange={(e) => handleChange(index, "phoneCommercial", e.target.value)} format={{
                    name: 'phoneCommercial',
                    label:"Telefone Comercial",
                    variant: 'standard',
                    icon: <Call />,
                  }}/>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <PhoneTextField phone={contact.phoneCell} handleChange={(e) => handleChange(index, "phoneCell", e.target.value)} 
                    format={{
                      name: 'phoneCell',
                      label:"Telefone Celular",
                      variant: 'standard',
                      icon: <PhoneAndroid />,
                    }}/>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    name="office"
                    label="Cargo"
                    variant="standard"
                    color="success"
                    fullWidth
                    value={contact.office}
                    onChange={(e) => handleChange(index, "office", e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton variant="contained" color="error" onClick={() => handleDeleteContact(index)} >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>))}
      <Button variant="contained" color="success" sx={{ ml: 2, mt: 1, fontSize: '12px' }} onClick={!contacts[contacts.length -1].name ? () => null : handleAddContact} disabled={contacts[contacts.length -1].name == ''}>
        Adicionar Outro Contato
      </Button>
    </Grid>
  );
};

export default AddNewContact;
