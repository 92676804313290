import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { Component } from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FilePreview from './FilePreview';
import api from '../../services/api';
import { toast } from 'react-toastify';

// https://www.youtube.com/watch?v=G5UZmvkLWSQ&t=36s
// lembrar da biblioteca yarn add react-dropzone para arrastar as imagens

class Upload extends Component {
  state = {
    selectedFile: null,
    item: this.props.item,
    callBack: this.props.callBack,
    callBackHook: this.props.callBackHook,
  };

  fileSlectedHandler = (event) => {
    console.log(event.target.files[0]);

    var uploadedFile = {
      file: event.target.files[0],
      id: 1,
      name: event.target.files[0].name,
      size: event.target.files[0].size,
      type: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };

    console.log(uploadedFile);

    this.setState({
      selectedFile: uploadedFile,
    });

    console.log(this.selectedFile);

    // this.setState({
    //   selectedFile: event.target.files[0],
    // });
  };

  close = () => {
    this.setState({
      selectedFile: null,
    });
  };

  // processar o upload
  fileUploadHandler = () => {
    console.log('fd');
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    //this.state.selectedFile.file.item = (this.props.item || null);
    if (this.state.item) {
      var tipo_item = this.state.item.tipo_item;
      if (/creation/.test(tipo_item)) {
        tipo_item = 'livestock';
      }

      console.log('passou');
      fd.append('item_type', tipo_item || '');
      fd.append('item_id', this.state.item.id || 0);
      fd.append('id_farm', this.state.item.id_farm || 1);
    } else {
      fd.append('id_farm', id_farm || 1);
    }
    fd.append('image', this.state.selectedFile.file, this.state.selectedFile.name);
    //console.log(fd);
    api
      .post('upload', fd, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          console.log(progress);

          var newUploadedFile = {
            file: this.state.selectedFile.file,
            id: this.state.selectedFile.file.id,
            name: this.state.selectedFile.name,
            size: this.state.selectedFile.size,
            type: this.state.selectedFile.type,
            preview: this.state.selectedFile.preview,
            progress: progress,
            uploaded: false,
            error: false,
            url: null,
          };

          this.setState({
            selectedFile: newUploadedFile,
          });
        },
      })
      .then((response) => {
        // this.setState((prevState) => {
        //   return { ...prevState, ...id: response.data._id, ...url: response.data.url };
        // });
        console.log('response');
        console.log(response.data);

        //const useImagesRepos = useListImagesStore((state) => state);

        // useImagesRepos.updateImageStore(id, 'isDeleted', 1);

        //var farm_id = localStorage.getItem('Property');

        if (response.data.isSuccess) {
          // instancia os repos
          //const usestore = useStore();
          // usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {});
          toast.success('Upload realizado com sucesso');
        }

        // chama função de retorno do modulo
        if (this.state.callBack) {
          // fecha o FilePreview
          this.setState({
            selectedFile: null,
          });
          toast.success('Imagem enviada com sucesso!');
          this.state.callBackHook.addImageStore({
            img: `https://cpt-soft.s3.us-east-1.amazonaws.com/${response.data._value.key_image}`,
            id_property: this.state.item.id_farm,
            title: this.state.selectedFile.name,
            image: response.data._value.key_image,
            size: this.state.selectedFile.size,
            tipo: this.state.item.tipo_item ? this.state.item.tipo_item : 'farm',
            id_item: this.state.item.id || 0,
            isDeleted: 0,
          });

          setTimeout(() => {
            // ativa função do módulo
            return this.props.callBack(response.data._value.key_image);
          }, 5);
        } else {
          console.log(response.data._value);
          localStorage.setItem('image', JSON.stringify(response.data._value.url));
          var newUploadedFile = {
            file: this.state.selectedFile.file,
            id: response.data._value.key_image,
            name: this.state.selectedFile.name,
            size: this.state.selectedFile.size,
            type: this.state.selectedFile.type,
            preview: this.state.selectedFile.preview,
            progress: this.state.selectedFile.progress,
            uploaded: true,
            error: false,
            url: response.data._value.url,
          };
          this.setState({
            selectedFile: newUploadedFile,
          });
        }
      })
      .catch(() => {
        // this.setState((prevState) => {
        //   return { ...prevState, ...error: true };
        // });
        toast.error('Erro ao salvar na Amazon');
        console.log('erro ao salvar na Amazon');
      });
  };

  handleDelete = async (id) => {
    // await api.delete(`posts/${id}`);
    if (!id) {
      id = this.state.selectedFile.id;
      //id = (this.state.selectedFile.url).replace(/.*.com\/(.*)/, '$1');
    }
    //const isDeleted = "1";
    //const res = await axios.delete(`http://localhost:5000/api/v1/upload/${id}/${isDeleted}`);
    const res = await api.delete(`/upload/${id}/1`);
    //console.log(res.data);
    //console.log(this.state.selectedFile);
    // this.setState({
    //   uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
    // });
    if (this.state.callBackHook) {
      this.state.callBackHook.delImageStore(id);
    }
    this.setState({
      selectedFile: null,
    });
  };

  render() {
    const { selectedFile } = this.state;
    const display_demo = JSON.parse(localStorage.getItem('display'));
    //console.log(selectedFile);

    console.log('selectedFile');
    console.log(selectedFile);

    return (
      <>
        {display_demo ? (
          <div></div>
        ) : (
          <div>
            {/* <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
            <Loading />
          </Box> */}
            <Box border="1px dashed gray" borderRadius="4px" padding="16px" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>Escolha um arquivo</Typography>
              <IconButton color="success" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={this.fileSlectedHandler} />
                <PhotoCamera />
              </IconButton>
            </Box>
            {!!selectedFile && <FilePreview file={selectedFile} onDelete={this.handleDelete} fileUploadHandler={this.fileUploadHandler} />}
            {!!selectedFile && (
              <>
                {!selectedFile.uploaded && (
                  <Button sx={{ mt: 1 }} variant="contained" onClick={this.fileUploadHandler} color="success" component="label">
                    {' '}
                    Enviar{' '}
                  </Button>
                )}
              </>
            )}
            {!!selectedFile && (
              <Button sx={{ mt: 1, ml: 2 }} onClick={this.close} color="success" component="label">
                {' '}
                limpar{' '}
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Upload;
