import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import api from '../../../../services/api'
import { Context } from '../../../../components/Context/AuthContext'

export default function ColorChips({ size, row_data, usestore }) {
  //console.log("ColorChips");
  //console.log(row_data);
  const status = row_data.progress

  let color
  let variant
  switch (status) {
    case 'A FAZER':
      color = 'success'
      variant = 'outlined'
      break
    case 'EM PROGRESSO':
      color = 'primary'
      break
    case 'CONCLUÍDA':
      color = 'success'
      break
    case 'REVISAR':
      color = 'default'
      variant = 'outlined'
      break
    case 'PLANEJAMENTO':
      color = 'success'
      break
    default:
      color = 'default'
      break
  }

  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(status)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedStatus(row_data.progress)
  }

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus)
  }

  const { aux_add, setAuxAdd } = useContext(Context)
  const { idFarm } = useParams()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))

  const handleUpdateProgress = async () => {
    const header = { header: { 'Content-Type': 'application/json' } }
    let data = {}
    let url = ''
    let reposActivity = {}

    if (row_data.activity == 'PLANTIO') {
      reposActivity = usestore.reposActivityPlanting

      let this_activity = JSON.parse(JSON.stringify(reposActivity.getActivityPlantingStore('id', row_data.id)[0]))
      this_activity.areas.map((area) => {
        const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0]
        area.id = optionsAreas.id
        area.area = optionsAreas.area
        area.measure = optionsAreas.measure

        area.product = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', area.id_product)[0]))
        area.product.location_selected = area.product.location.filter((local) => {
          if (local.id_patrimony == area.id_patrimony) {
            local.quant_utilizada = area.quant
            local.value = area.value
            return local
          }
        })[0]

        return area
      })

      data = {
        id_user: userCurrent.id,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: this_activity,
      }
      url = `/activityPlanting/update`
    } else if (row_data.activity == 'APLICAÇÃO') {
      reposActivity = usestore.reposActivityApplications

      let this_activity = JSON.parse(JSON.stringify(reposActivity.getActivityApplicationsStore('id', row_data.id)[0]))
      this_activity.areas.map((area) => {
        const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0]
        area.id = optionsAreas.id
        area.area = optionsAreas.area
        area.measure = optionsAreas.measure

        area.product = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', area.id_product)[0]))
        area.product.location_selected = area.product.location.filter((local) => {
          if (local.id_patrimony == area.id_patrimony) {
            local.quant_utilizada = area.quant
            local.value = area.value
            return local
          }
        })[0]

        return area
      })

      data = {
        id_user: userCurrent.id,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: this_activity,
      }
      url = `/activityApplications/update`
    } else if (row_data.activity == 'COLHEITA') {
      reposActivity = usestore.reposActivityHarvesting

      let this_activity = JSON.parse(JSON.stringify(reposActivity.getActivityHarvestingStore('id', row_data.id)[0]))
      this_activity.areas.map((area) => {
        const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0]
        area.id = optionsAreas.id
        area.area = optionsAreas.area
        area.measure = optionsAreas.measure

        let aux_prod
        area.products.map((product, index) => {
          aux_prod = JSON.parse(JSON.stringify(product))
          product = JSON.parse(JSON.stringify(usestore.reposProducts.getProductsStore('id', product.id)[0]))
          product.location_selected = aux_prod.location.filter((local) => {
            if (local.id_patrimony == product.id_location_selected) {
              return local
            }
          })[0]
          product.quant_colhida = aux_prod.quant_colhida
          return product
        })

        return area
      })

      data = {
        id_user: userCurrent.id,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: this_activity,
      }
      url = `/activityHarvesting/update`
    } else if (row_data.activity == 'PREPARAÇÃO DO SOLO') {
      reposActivity = usestore.reposActivitySoilPreparation
      const res = reposActivity.getActivitySoilPreparationStore('id', row_data.id)[0]
      data = {
        id: row_data.id,
        id_user: userCurrent.id,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: res.progress,
      }
      url = `/activitySoilPreparation/update`
    } else if (row_data.activity == 'MONITORING') {
      reposActivity = usestore.reposActivityMonitoring
      const res = reposActivity.getActivityMonitoringStore('id', row_data.id)[0]
      data = {
        id: row_data.id,
        id_user: userCurrent.id,
        progress: selectedStatus,
        oldProgress: row_data.progress,
        change_progress: true,
        activityData: res.progress,
      }
      url = `/activityMonitoring/update`
    }

    //console.log(data);
    const res = await api
      .put(url, data, header)
      .then((response) => {
        //console.log(response.data);
        if (response.data.isSuccess) {
          toast.success('Progresso da atividade atualizado com sucesso!')
          // atualizar products
          usestore.reposProducts.fetch()
          // atualizar o harvest
          usestore.reposHarvest.fetch({ id_farm: idFarm })
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1)
            setTimeout(() => {
              handleClose()
            }, 200)
          })
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.error('Erro em atualizar o progresso da atividade. Tente novamente mais tarde.')
        setTimeout(() => {
          handleClose()
        }, 200)
      })
  }

  return (
    <>
      <div>
        <Chip label={status} color={color} variant={variant} size={size} onClick={handleOpen} />
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
          <DialogContent>
            <Typography variant="subtitle1" sx={{ mt: 0, mb: 2 }}>
              Atualizar atividade:
            </Typography>
            <Grid container spacing={1}>
              <Grid item sx={{ ml: 1 }}>
                <Box sx={{ border: '0px solid #ccc', borderRadius: '4px' }}>
                  <Stack direction="column" spacing={1}>
                    <Chip label="A FAZER" color={selectedStatus === 'A FAZER' ? 'success' : 'default'} onClick={() => handleStatusChange('A FAZER')} />
                    <Chip label="EM PROGRESSO" color={selectedStatus === 'EM PROGRESSO' ? 'primary' : 'default'} onClick={() => handleStatusChange('EM PROGRESSO')} />
                    <Chip label="CONCLUÍDA" color={selectedStatus === 'CONCLUÍDA' ? 'success' : 'default'} onClick={() => handleStatusChange('CONCLUÍDA')} />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" color="success" onClick={handleClose}>
              Cancelar
            </Button>
            <Button size="small" variant="contained" color="success" onClick={handleUpdateProgress}>
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
