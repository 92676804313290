import { Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ListProduct from './ListProduct';
import ListProductInactive from './ListProductInactive';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import ProductGeneralCardEmpty from './ProductGeneralCardEmpty';
import useStore from '../../store/useStore';
import { useEffect } from 'react';
import { Context } from '../../components/Context/AuthContext';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ListProductsContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const usestore = useStore();
  const { aux_add } = React.useContext(Context);
  const [productData, setProductData] = React.useState([]);

  useEffect(() => {
    setProductData(usestore.reposProducts.listProducts);
  }, [aux_add]);

  // console.log(productData);
  // console.log(productData);
  return (
    <>
      {!productData.length ? (
        <ProductGeneralCardEmpty />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
            <Card sx={{ mt: 1 }}>
              <CardHeader
                title={
                  <>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex' }}>
                          {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                          <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                            <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                              <>
                                <strong>Registre de forma fácil seus produtos (insumos) agrícolas e pecuários e tenha visibilidade ampla dos produtos.</strong>
                              </>
                            </Typography>
                            {/* <Typography sx={{ mt: 2 }} fontWeight="500" variant="subtitle1">
                          <>
                            <strong>Registre os produtos necessários para a produção agrícola e pecuária no catálogo de produtos.</strong>
                          </>
                        </Typography> */}
                          </Stack>
                          {/* <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      </Stack> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} alignItems="center">
                        {true ? (
                          <Typewriter
                            text={`Clique em "CADASTRO DE PRODUTOS" na parte superior direita da tela, e cadastre os produtos que serão utlizandos na pecuária e agricultura.`}
                            variant={'subtitle1'}
                            color={'#2e7d32'}
                          />
                        ) : (
                          <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                            {' '}
                            <ThreeDotsSpinner />
                          </Box>
                        )}

                        {/* <Typography fontWeight="500" sx={{ mt: 2 }} variant="subtitle1">
                     
                          Tenha visibilidade ampla de cada um de seus produtos registrados no sistema.
                        </Typography> */}
                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                      <>
                        <span>Clique no botão CADASTRO DE PRODUTOS no lado direito da tela, e cadastre os produtos que serão utlizandos na pecuária e agricultura. </span>
                      </>
                    </Typography>
                  </Grid> */}

                      {/* <Grid item xs={12} md={8}>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography fontWeight="500" sx={{ mt: 2 }} variant="subtitle1">
                        Catálogo
                      </Typography>
                    </Box> 
                  </Grid> */}
                    </Grid>
                  </>
                }
              ></CardHeader>
              <Divider />
            </Card>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={
                  <Grid container direction="row" alignItems="center" sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
                    <PlaylistAddCheckIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Lista Ativa
                  </Grid>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <PlaylistRemoveIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Lista Inativa
                  </Grid>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <InventoryList /> */}
            <ListProduct />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListProductInactive />
          </TabPanel>
        </Box>
      )}
    </>
  );
}
