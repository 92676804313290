import * as React from 'react'
import Card from '@mui/material/Card'
import { CardActionArea, CardHeader, Stack, Typography, TextField, Box } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { useState } from 'react'
import ColorPicker from './ColorPicker'

export default function Header({ page, setPage }) {
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#ffffff')
  const [headerTextColor, setHeaderTextColor] = useState('#000000')
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')

  const handleBackgroundColorChange = (color) => {
    setHeaderBackgroundColor(color)
    setPage({
      ...page,
      backgroundColorHeader: color, // Atualiza apenas o endereço no estado
    })
  }

  const handleTextColorChange = (color) => {
    setHeaderTextColor(color)
    setPage({
      ...page,
      textColorHeader: color, // Atualiza apenas o endereço no estado
    })
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
    setPage({
      ...page,
      title: event.target.value, // Atualiza apenas o título no estado
    })
  }

  const handleAddressChange = (event) => {
    setAddress(event.target.value)
    setPage({
      ...page,
      address: event.target.value, // Atualiza apenas o endereço no estado
    })
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Insira o nome da sua fazenda e o endereço.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Defina a cor de fundo da página e a cor do texto.
        </Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" sx={{ mr: 1 }} color="text.secondary">
            Cor do Texto:{''}
          </Typography>
          <ColorPicker value={headerTextColor} onChange={handleTextColorChange} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Cor do Fundo:
          </Typography>
          <ColorPicker value={headerBackgroundColor} onChange={handleBackgroundColorChange} />
        </Stack>
      </Box>

      <Card>
        <CardActionArea>
          <CardHeader
            title={
              <TextField
                sx={{ ml: 2, width: 400 }}
                id="outlined-basic"
                variant="standard"
                type="text"
                color="success"
                value={page.title}
                onChange={handleTitleChange} // Chamada da função corrigida para o título
                inputProps={{ style: { color: headerTextColor } }}
                style={{ fontSize: '3rem', fontWeight: 700, backgroundColor: headerBackgroundColor }}
              />
            }
            subheader={
              <Stack spacing={0.5} sx={{ ml: -1 }}>
                <Typography variant="body2" color="text.secondary">
                  <LocationOn sx={{ color: 'grey.500', fontSize: '28px' }} />
                  <TextField
                    id="outlined-basic"
                    variant="standard"
                    sx={{ width: 600 }}
                    color="success"
                    type="text"
                    value={page.address}
                    onChange={handleAddressChange} // Chamada da função corrigida para o endereço
                    // /size="small"
                    inputProps={{ style: { color: headerTextColor } }}
                    style={{ fontWeight: 300, backgroundColor: headerBackgroundColor }}
                  />
                </Typography>
              </Stack>
            }
            // Estilize o cabeçalho com a cor de fundo selecionada
            sx={{ backgroundColor: headerBackgroundColor, height: 120 }}
          />
        </CardActionArea>
      </Card>
    </>
  )
}
