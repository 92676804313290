import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import TableProductionCost from './components/TableProductionCost';
import CustomizedMenusProductionCost from './components/CustomizedMenus/CustomizedMenusProductionCost';
// import CardProductionCost from './components/CardProductionCost';
// import ChartBarPCost from '../../components/Charts/ChartBarPCost';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

export default function ProductionCostContent() {
  const { idFarm, id } = useParams();
  const objText = {
    icon: <ShoppingCart style={{ fontSize: 35 }} />, // Substitua pelo ícone desejado
    title: 'Custos da Produção', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Com o projeto pecuário em andamento podemos registar todos os gastos com o projeto.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: [
      '1- Registrar um pedido de compra',
      '1- Registrar uma conta a pagar (outras despesas)',
      '1- Visualizar a listas de gastos com o projeto',
      '2- Visualizar a lista de insumos utilizandos',
      '3- Monitorar a Lista de atividades de campo',
    ], // Texto complementar ou final
    linkButton: ['openBuyRegistrarModal', 'openExpenseRegistrarModal', `/productioncost/${idFarm}/${id}`, `/resources/${idFarm}/${id}`, `/activities/${idFarm}/${id}`], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };
  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: -2 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <ShoppingCart style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Custos da Produção
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-start">
                <CustomizedMenusProductionCost />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                {/* <TutorialAddProject /> */}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        <Typography fontWeight="500" variant="h6">
                          Gerenciamento financeiro da produção
                          <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                            Visualize, revise e controle os custos associados a este projeto específico.
                            {/* Você pode editar, corrigir e inserir custos adicionais conforme necessário. */}
                          </Typography>
                          {/* <Box sx={{ mt: 2 }}>
                            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                              Lançamento de despesas relacionadas a compras: Registre um pedido de compra para, posteriormente, dar baixa no estoque e realizar a entrada no financeiro, ou registre uma
                              compra que já faça a baixa no estoque e o lançamento no financeiro de forma simultânea.
                            </Typography>
                            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                              Lançamento de despesas não relacionadas a compras: Registre a despesa para que o sistema gere o lançamento correspondente no financeiro.
                            </Typography>
                          </Box> */}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} alignItems="center">
                    {true ? (
                      <Typewriter
                        text={`Para registrar os custos financeiros para este projeto, clique no botão "Despesas" no canto superior direito.\n Selecione "Compras" para lançamentos relacionados a compras ou "Nova despesa" para outras depesas.`}
                        variant={'subtitle1'}
                        color={'#2e7d32'}
                      />
                    ) : (
                      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                        {' '}
                        <ThreeDotsSpinner />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              }
            ></CardHeader>
          </Card>
          {/* <CardProductionCost /> */}
        </div>
      </div>
      <div className="content mb-4">
        <div className="container-fluid ">
          <TableProductionCost />
        </div>
      </div>
    </div>
  );
}
