import { useState } from 'react';
import { TextField, Button, Box, Grid, Typography, InputAdornment, Stack, Autocomplete, Card, CardMedia, CardContent } from '@mui/material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import InputIcon from '@mui/icons-material/Input';
import { useEffect } from 'react';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import useActivityInputReposStore from '../../../../../store/Activities/useActivityInputReposStore';
import FormInputFooter from './FormInputFooter';
import DialogTransferArea from './FormTransferStep2MoveBatchDialogTransferArea';
import FormTransferStep1SelectRadioButton from './FormTransferStep1SelectRadioButton';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import useStore from '../../../../../store/useStore';
import { useParams } from 'react-router-dom';
import { LocationOn } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import MapView from './mapView/MapView';
import MapViewBatch from './mapViewBatch/MapViewBatch';
import FormTransferStep1SelectDialogTransferArea from './FormTransferStep1SelectDialogTransferArea';

export default function FormTransferStep1Select({ listBatch, data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);
  const usestore = useStore();
  //const { idFarm, id } = useParams();

  console.log(data);
  const handleChange = (event, val_input) => {
    console.log('handleChangeInput');
    console.log(val_input);
    setData((prevState) => ({ ...prevState, [event]: val_input }));
  };

  // const para validar o button continuar
  const isError = () => {
    // return (
    //     !data.lote || data.lote?.length == 0 || data.lote?.num_animals == 0
    // );
  };

  // aciona o botao retornar
  const returnError = () => {
    return true;
  };
  const color = '#2e7d32';

  const [image, setImage] = useState(null);

  console.log(data.opt_lotes);

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputIcon />
          <Box sx={{ ml: 1 }}>
            <Stack spacing={0.5}>
              <Typography variant="h6" fontWeight={500}>
                Transferência de Lotes de animais entre Áreas
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems="center" justifyContent="center">
        <Grid item sx={{ mt: 1, height: 120 }}>
          <img style={{ maxheight: 120 }} src="/dist/image/activity_transfer_animal.png" alt="image" className="img-fluid" />
        </Grid>
      </Grid>
      {/* <Grid container spacing={3} sx={{ mt: 3 }}>
                <Box sx={{ ml: 1, display: "flex" }}>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={700}>Como desejar transferir os seus animais?</Typography>
                    </Stack>
                </Box>
                <Grid container spacing={3} sx={{ mt: 0 }}>
                    <Box sx={{ mt: 1 }}>
                        <FormTransferStep1SelectRadioButton data={data} setData={setData} />
                    </Box>
                </Grid>
            </Grid> */}
      <Grid container sx={{ mt: 2 }}>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Selecione o lote de animais que deseja transferir</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid container justifyContent="center" sx={{}} spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutoAwesomeMotionIcon style={{ fontSize: 35 }} sx={{ mr: 1, mt: -3 }} />
                <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    name="lote"
                    color="success"
                    options={data.opt_lotes}
                    value={data.lote} // Defina um valor padrão, como `null`, para evitar valores indefinidos
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Card sx={{ display: 'flex', width: '100%' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                              <img src={option.icon} alt="Icon" style={{ height: '32px', marginLeft: '8px' }} />
                              <Stack spacing={0.5}>
                                <Typography fontWeight={700}>{option.label}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                  <LocationOn sx={{ color: grey[500] }} /> {option.area.name}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            sx={{
                              width: 120,
                              height: 120,
                              // objectFit: "none",
                              // objectPosition: "center",
                            }}
                            image={option.area.url || option.area.imageDecode}
                            alt="Area Image"
                          />
                        </Card>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} fullWidth required name="lote" color="success" label="Selecione o Lote" variant="standard" value={data.lote} />}
                    onChange={(e, value) => handleChange('lote', value)}
                    noOptionsText="Sem resultados"
                  />
                  <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                    <Typography variant="caption" dis0lay="block" gutterBottom sx={{ ml: 1, color: '#808080' }}>
                      Selecione o lote do qual deseja identificar os animais
                    </Typography>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container justifyContent="center" sx={{ mt: 0.5, mb: 2 }} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTransferStep1SelectDialogTransferArea listBatch={listBatch} data={data} setData={setData} container={handleClose} setImage={setImage} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2 }}>
                    Total de animais: <strong>{data.lote?.num_animals || 0}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2 }}>
                    Fase de Manejo: <strong>{data.lote?.fase_name || ''}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={6}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        </Grid>
                    </Grid> */}
        </Grid>

        <FormInputFooter
          data={data}
          setData={setData}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          isError={isError}
          handleClose={handleClose}
          returnError={returnError}
        />
      </Grid>
    </>
  );
}
