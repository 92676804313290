import React, { useState, useEffect } from "react";
import CardPainelHarvest from "./components/CardPainelHarvest";

export default function ControlPanelHarvestContent() {
  return (
    <div className="content-wrapper bg-white ">
      <section className="">
        <div className="">
          <CardPainelHarvest />
        </div>
      </section>
    </div>
  );
}
