let listHarvest = []

// Verifica se reposLivestock existe no localStorage
let reposHarvestString = localStorage.getItem('reposHarvest')
if (reposHarvestString) {
  // Converte a string JSON em um objeto JavaScript
  let reposHarvest = JSON.parse(reposHarvestString)
  listHarvest = reposHarvest.state.listHarvest
  console.log('listHarvest')
  console.log(listHarvest)
} else {
  console.log('Nenhum dado encontrado em reposLivestock')
}

let dataProperty = []

let resposPropertyString = localStorage.getItem('reposProperty')
//console.log(resposPropertyString);
if (resposPropertyString) {
  // Converte a string JSON em um objeto JavaScript
  let resposProperty = JSON.parse(resposPropertyString)
  dataProperty = resposProperty.state.listProperty[0]
  console.log('imageProperty')
  console.log(dataProperty)
} else {
  console.log('Nenhum dado encontrado em resposProperty')
}

//console.log(dataProperty);
//const base64Image = dataProperty.image;
//console.log(base64Image);

// function loadBase64Image(base64) {
//   return new Promise((resolve, reject) => {
//     const image = new Image();

//     // Evento disparado quando a imagem é carregada com sucesso
//     image.onload = () => {
//       resolve(image);
//     };

//     // Evento disparado em caso de erro ao carregar a imagem
//     image.onerror = () => {
//       reject(new Error("Falha ao carregar a imagem base64."));
//     };

//     // Define a string base64 como fonte da imagem
//     image.src = base64;
//   });
// }
// console.log(dataProperty);
// const base64Image = dataProperty.image;

// loadBase64Image(base64Image)
//   .then((image) => {
//     // A imagem foi carregada com sucesso
//     console.log("Imagem base64 carregada!", image);
//   })
//   .catch((error) => {
//     // Houve um erro ao carregar a imagem
//     console.error(error);
//   });

// Função para converter lotes em string
function formatAreas(areas) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return areas.map((area) => `${area.name} (${area.usedArea})\n`)
}

// function formatData(Inputdata) {
//   return !Inputdata ? "" : dayjs(Inputdata).format("DD/MM/YYYY");
// }

// Atualizando livestockTableRows
const HarvestTableRows = listHarvest.map((item, index) => {
  return [
    { text: (index + 1).toString() }, // Número incremental como ID
    { text: item.cultivation_name || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
    { text: item.name || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
    { text: `Início: ${item.data_inicial} \n Fim:    ${item.data_fim}` || 'Data não especificada', fontSize: 7 }, // Converte para data
    { text: formatAreas(item.areas) || 'Lote não especificado', fontSize: 8 }, // Converte lotes para string
    // ... outras propriedades conforme necessário
  ]
})

console.log(HarvestTableRows)

const lineBlank = [
  {
    text: '',
    alignment: 'center',
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
]

export const bodyListHarvestBW = {
  content: [{ text: ' ', style: 'subheader' }],
}

// adicionando a tabela
bodyListHarvestBW.content.push(
  { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
  { text: 'Lista', style: 'subheader' },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [10, '*', '*', '*', '*'],
      fillColor: '#eeeeee',
      body: [
        ['#', 'Tipo', 'Projeto(Safra)', 'data', 'Áreas'], // Cabeçalhos da tabela
        // [
        //   { text: '#', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        //   { text: 'Tipo', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        //   { text: 'Projeto(Safra)', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        //   { text: 'Data', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        //   { text: 'Áreas', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        // ],
        ...HarvestTableRows, // Linhas de dados da tabela
      ],
    },
    fontSize: 10,
  }
)

// adicionando texto
bodyListHarvestBW.content.push({
  width: '*',
  text: `Número de safras: ${dataProperty?.total_safras} \n Total de cultivares: ${dataProperty?.total_cultivation} \n `,
  fontSize: 10,
  color: '#808080',
  margin: [0, 10, 0, 0],
  alignment: 'right',
})
