import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import SignpostIcon from '@mui/icons-material/Signpost';
import FormGoalButtonPlanning from '../formGoalPlanning/FormGoalButtonPlanning';
import ChartBarP from '../../../components/Charts/ChartBarP';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FormGoalButtonTarget from '../formGoalTarget/FormGoalButtonTarget';
import TimelineHorizPhase from '../components/GraphPlan/TimelineHorizPhase';
import PlanningHarvestCost from '../components/CostProduction/PlanningHarvestCost';
import { useParams } from 'react-router-dom';
import useHarvestReposStore from '../../../store/useHarvestReposStore';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListAltIcon from '@mui/icons-material/ListAlt';
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore';
import FormGoalButtonTargetEdit from '../formGoalTarget/FormGoalButtonTargetEdit';
import { Context } from '../../../components/Context/AuthContext';
import { calcularDateProgressBarra } from '../../../components/Calculations/calcularDateProgressBarra';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ChartBarPlanning from '../../../components/Charts/ChartBarPlanning';
import FormGoalButtonPlanHarvest from '../formGoalTarget/FormGoalButtonPlanHarvest';
import { format } from 'date-fns';
import { useState } from 'react';
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore';
import CardMeatProductionAreaCost from './CardMeatProductionAreaCost';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardMeatProductionAreaProfitability from './CardMeatProductionAreaProfitability';
import FarmProfitabilityMarginCalculator from './CardMeatProductionAreaProfitability';
import CardProductionArea from './CardProductionArea';

export default function CardMeatProductionArea({ area, target_active, cost_active }) {
  // let area = button?.filter(area => area.active)

  const { id, idFarm } = useParams();

  console.log('area, target_active, cost_active');
  console.log(area, target_active, cost_active);

  // // instancia os repos
  // const reposHarvest = useHarvestReposStore((state) => state);
  // //Safra
  // const harvest = reposHarvest.getHarvestStore("id", id);
  // //console.log("listHarvests")
  // console.log(harvest[0])
  // console.log(harvest[0].id)

  // tenho o data harvest e a aŕea da meta de produção
  // o harvest tem todas as áreas vinculadas
  // o area tem a área do planejamento da meta
  console.log('area1');
  console.log(area);
  console.log(target_active);

  // instancia os repos planning se já estiver algum planejamento
  const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  //console.log("reposTargetPlanning");
  //console.log(reposTargetPlanning);
  //console.log(reposTargetPlanning.listPlanning);

  //filtando a lista dos planejamentos por safra
  const planTargetReposFilterHarvest = reposTargetPlanning.listPlanning.filter((item) => item?.id_harvest == id);
  //console.log(planTargetReposFilterHarvest);

  //filtando a aŕea selecionada
  // area.data.id = id area map
  //console.log(area.data.id);
  const planTargetReposFilterIdAreaMap = planTargetReposFilterHarvest.filter((item) => item?.id_areamap == area.data?.id);

  let date_start = '';
  let date_prev_end = '';

  if (target_active) {
    //console.log("true");
    //date_start = format(new Date(planRepos[0].date_start), "dd/MM/yyyy");
    //date_prev_end = format(new Date(planRepos[0].date_prev_end), "dd/MM/yyyy")
    date_start = format(new Date(target_active.date_start), 'dd/MM/yyyy');
    date_prev_end = format(new Date(target_active.date_prev_end), 'dd/MM/yyyy');
  }
  // selecionar o prohgresso da timeline do cultivo
  const valueProgress = calcularDateProgressBarra(date_start, date_prev_end);
  //console.log(valueProgress);

  const valueProductivity = target_active?.expectedYield / target_active?.area;
  //console.log(valueProductivity);

  // dados da meta de produção
  const [target, setTarget] = React.useState(target_active);
  // dados da produtividade
  const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2));
  useEffect(() => {
    setProductivity(valueProductivity.toFixed(2));
  }, [target]);

  ///CUSTO DE PRODUÇÃO
  //planejamento dos custos de produção
  // venda = lucro - custos
  const reposProductionCostHarvest = useHarvestTargetCostReposStore((state) => state);
  //console.log("reposProductionCostHarvest#");
  console.log(reposProductionCostHarvest);
  console.log(area);
  //filtando a lista dos custos de produção por safra
  const reposProductionCostFilterHarvest = reposProductionCostHarvest.listHarvestTargetCost.filter((item) => item.id_harvest == id);
  //const result = reposProductionCostFilterHarvest !== undefined ? reposProductionCostFilterHarvest : "Item não encontrado";
  console.log(reposProductionCostFilterHarvest);

  console.log('custo');
  console.log(area);
  console.log(target_active);

  //filtando a aŕea selecionada
  // area.data.id = id area map
  //console.log(area.data.id);
  let planProductionCosHarvestRepos = [];
  if (planTargetReposFilterIdAreaMap.length > 0) {
    planProductionCosHarvestRepos = reposProductionCostFilterHarvest.filter((item) => item.id_areamap == area.data.id);
  }
  //console.log(planProductionCosHarvestRepos);
  const resposproductionCost = planProductionCosHarvestRepos.length > 0 ? planProductionCosHarvestRepos[0] : {};
  console.log('resposproductionCost1');
  console.log(resposproductionCost);

  //console.log(target_active)
  //dados do custo de produção
  const [productionCost, setProductionCost] = useState(cost_active);
  console.log(productionCost);
  //console.log("plan");
  //console.log(plan);
  area.cost = productionCost;

  const { aux_add } = useContext(Context);

  useEffect(() => {
    //console.log("refresh ");
    setTarget(planTargetReposFilterIdAreaMap[0]);
    // setProductionCost(resposproductionCost);
  }, [aux_add]);

  // const planLivestockRepos = reposProductionCostHarvest.getPlanningStore("id", productionCost.id)[0];
  // console.log(planLivestockRepos);

  console.log('ver ');
  console.log(productionCost);
  console.log(target);
  console.log(target_active);

  return (
    <Box className="">
      <Box className="content-header" style={{ marginTop: 0 }}>
        <Box className="">
          <Grid container spacing={2} sx={{ mb: 1 }}></Grid>

          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex' }}>
                {/* <ListAltIcon style={{ fontSize: 35 }} /> */}
                <Stack spacing={0.5} sx={{ ml: 5.5, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    {/* Projeto: OP2023/00001 */}
                    Projeto:
                  </Typography>
                  <Box sx={{ flex: '1', flexDirection: 'column' }}>
                    {/* <Typography component="span">Gestão da Safra</Typography> */}
                    <Typography component="h3" variant="h6" sx={{ fontWeight: 'bold', color: 'success.main', textTransform: 'uppercase' }}>
                      {/* {area.dataHarvest.name} */}
                    </Typography>
                    {/* <Typography component="span">Cultura da safra: {area.dataHarvest.cultivation_name}</Typography> */}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Grid container spacing={2} sx={{ mt: 0 }}>
          {target && (
            <Grid item xs={12} md={12}>
              <Box mt={0}>
                <TimelineHorizPhase date_start={date_start} date_prev_end={date_prev_end} />
              </Box>
            </Grid>
          )}
        </Grid> */}

        <Grid container spacing={2} sx={{ mt: 0 }}>
          {!target_active ? (
            <Grid item xs={12} md={4} sx={{ mt: 5 }}>
              <Box height="110px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <FormGoalButtonTarget areaActive={area} planTargetReposFilterIdAreaMap={planTargetReposFilterIdAreaMap[0]} />
                </Box>
                <span>Clique no ícone e defina quanto deseja produzir</span>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              {/* <coluna 1 /> */}
              <Grid container spacing={2} sx={{ mt: -4 }}>
                <Grid item xs={12} md={12}>
                  <Box mt={2}>
                    <Grid container spacing={2} sx={{ mt: 0.3 }}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex' }}>
                          <Stack spacing={0.5}>
                            <Typography fontWeight="400" variant="h6">
                              Quantidade Esperada (Meta)
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>{target ? <FormGoalButtonTargetEdit target={target} area={area}/> : <></>}</Box> */}
                    </Grid>
                    <Box mt={2}>
                      <Card>
                        <CardContent sx={{ mt: -1, mb: 2 }}>
                          <Grid container spacing={2} sx={{ mb: 1.1 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="caption">O quanto deseja produzir:</Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>{!target_active ? '' : <FormGoalButtonTargetEdit target={target_active} area={area} />}</Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Grid container spacing={1}>
                                <Grid item sx={{ width: 50 }}>
                                  <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                                  {/* <Img alt="complex" src="/dist/img_fp/icon-button/icon-agricolaV1.png" /> */}
                                </Grid>

                                <Grid item xs>
                                  <Typography variant="caption">Produção Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    <strong>{target_active?.expectedYield}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}</span>
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="caption">Produtividade Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    {' '}
                                    <strong>{productivity}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}/ha</span>{' '}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
                {/* Prazo  */}
                <Grid item xs={12} md={6}>
                  <Box mt={2}>
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5}>
                        <Typography fontWeight="400" variant="h6">
                          Prazo Esperado
                        </Typography>
                      </Stack>
                    </Box>
                    <Box mt={2}>
                      <Card>
                        <CardContent sx={{ mt: -1 }}>
                          <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                            <Typography variant="caption" color="text.secondary" component="div">
                              {date_start}
                            </Typography>
                            <ChartBarP valueProgress={valueProgress?.porcentagem > 0 && valueProgress?.porcentagem < 101 ? valueProgress.porcentagem : 0} />
                            <Typography variant="caption" color="text.secondary" component="div">
                              {date_prev_end}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ width: '100%' }}>
                    <CardMedia
                      component="img"
                      height="210" // Ajuste a altura proporcionalmente à largura desejada
                      image={area.image}
                      alt="imagem"
                    />
                    <CardContent>
                      <Typography align="center" fontWeight={400} variant="body1">
                        {area.title} - {area.area} {area.measure}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*  <Grid item xs={12} md={8} sx={{ mt: 2 }}>
             <Box sx={{ mt: 4 }}>
              <Typography variant="h6" style={{ color: '#2e7d32' }} gutterBottom>
                Passo 1: Análise Macroeconômica
              </Typography>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Conheça a produtividade da safra desejada de acordo com os dados do mercado nacional
              </Typography>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Clicando em pesquisar, você será redirecionado para realizar a pesquisa da informação desejada.
              </Typography>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Clique aqui para calcular
                </AccordionSummary>
                <AccordionDetails>
                  <CardProductionArea target_active={target_active} />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Passo 2: Análise da Estimativa da Venda da sua Safra
              </Typography>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Baseado na sua meta esperada de produção, você saberia dizer quanto é a lucratividade da sua safra?
              </Typography>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Clique aqui para calcular a lucratividade da safra
                </AccordionSummary>
                <AccordionDetails>
                  <CardMeatProductionAreaProfitability target_active={target_active} />
                </AccordionDetails>
              </Accordion>
            </Box> */}
          {/* https://www.nagro.com.br/financas/margem-de-lucro-fazenda/ */}
          {/* <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Margem de lucro
              </Typography>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Você saberia calcular a margem de lucro da fazenda?
              </Typography>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Clique aqui para calcular a margem de lucro da fazenda e como potencializar a rentabilidade
                </AccordionSummary>
                <AccordionDetails>
                  <FarmProfitabilityMarginCalculator target_active={target_active} />
                </AccordionDetails>
              </Accordion>
            </Box> */}

          {/* <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Passo 2: Análise do Cálculo do Ponto de Equilíbrio Financeiro da sua safra
              </Typography>
              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                Você já se perguntou quanto precisa vender para cobrir todos os custos da sua safra?
              </Typography>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Clique aqui para calcular o Ponto de Equilíbrio Financeiro
                </AccordionSummary>
                <AccordionDetails>
                  <CardMeatProductionAreaCost target_active={target_active} />
                </AccordionDetails>
              </Accordion>
            </Box> 
          </Grid>*/}
        </Grid>
      </Box>
    </Box>
  );
}
