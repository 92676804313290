import { useEffect, useState } from "react";
import Loading from "../../../../components/Loader/Loading";
import CardWeather from "./CardWeather";
import usePropertyReposStore from "../../../../store/usePropertyReposStore";

let center = {
  lat: -20.9111284,
  lng: -42.7967557,
};

export const useFetchWeatherForecast = (idFarm) => {
  console.log(idFarm)
  const [lat, setLat] = useState([]);
  const [long, setLong] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [centerinit, setCenterinit] = useState({})

  const [apiData, setApiData] = useState({});

  const key = "7471bce1d62a872235fc4e0eadace8ac";
  const [apiUrl, setUrl] = useState('');
  //const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lang=pt_br&lat=${centerinit.lat}&lon=${centerinit.lng}&appid=${key}`;

  useEffect(() => {
    console.log('átualizou')
    console.log(centerinit)
    // fetchData();
    if (centerinit.lat !== undefined) {
      console.log('centerinit passou')
      fetchData();
      setUrl(`https://api.openweathermap.org/data/2.5/weather?lang=pt_br&lat=${centerinit.lat}&lon=${centerinit.lng}&appid=${key}`);
    }
  }, [centerinit])

  //  verificar se exite a latitude da propriedade
  const reposProperty = usePropertyReposStore(state => state);
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];
  useEffect(() => {
    console.log('setCenterinit');
    setCenterinit({
      lat: Number(property_data?.latitude || '-20.9230016'),
      lng: Number(property_data?.longitude || '-42.7960554'),
      address: property_data?.address || property_data?.address_area || "",
      zoom: Number(property_data?.zoom || '14'),
    });
  }, []);

  useEffect(() => {
    if (apiUrl != '') {
      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => setApiData(data));
    }
  }, [apiUrl]);

  // https://github.com/emersonleonardo/clima-hoje/blob/master/src/App.js
  // let getPosition = async (lat, long) => {
  //   await axios.get('http://api.openweathermap.org/data/2.5/weather', {
  //     params: {
  //       lat: lat,
  //       lon: long,
  //       appid: process.env.REACT_APP_OPEN_WHEATHER_KEY,
  //       lang: 'pt',
  //       units: 'metric'
  //     }
  //   })
  //   .then(function (res){
  //     setWeather(res.data);
  //   })
  // }

  const fetchData = async () => {
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   setLat(position.coords.latitude);
    //   setLong(position.coords.longitude);
    // });
    console.log("entrou no fetchData");
    console.log(centerinit);
    //await fetch(`${https://api.openweathermap.org/data/2.5}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${key}`)
    //await fetch(`${http://api.openweathermap.org/data/2.5}/forecast/?lat=${lat}&lon=${long}&units=metric&APPID=${key}`)

    await fetch(`https://api.openweathermap.org/data/2.5/forecast/?lang=pt_br&lat=${centerinit.lat}&lon=${centerinit.lng}&units=metric&APPID=${key}`)
      .then((res) => res.json())
      .then((result) => {
        setDataResult(result);
        console.log(result);
        if (result) {
          setLoadingFetch(false);
        }
      });
  };

  // if (loadingFetch) {
  //   return <Loading />;
  // }
  //https://www.freecodecamp.org/news/learn-react-by-building-a-weather-app/
  //https://www.youtube.com/watch?v=8RpkXP_4GA4
  return {
    dataWeatherForecast: dataResult,
    apiData: apiData,
    loadingWeatherForecast: loadingFetch,
  };
};
