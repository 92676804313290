import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { H6, Span } from '../../../../components/Typography';
import { cyan, green } from '@mui/material/colors';
import CardProfileModel from '../CardProfileModel';
import { Grid } from '@mui/material';
import SucessSaveUser from './SucessSaveUser';
import { toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EditModalFormTeamSave({ onSubmit, editUser }) {
    const [open, setOpen] = React.useState(false);
    console.log(editUser)

    const [loading, setLoading] = React.useState(true);

    const handleClickOpen = () => {
        if (!editUser.name) {
            toast.error('Nome é um campo obrigatório');
            return false;
        } else if (!editUser.farms) {
            toast.error('Propriedade é um campo obrigatório');
            return false;
        } else {
            setOpen(true);
        }
    };

    const handleCloseCancel = () => {
        console.log("cancelado")
        setOpen(false);
    };

    const handleSave = (e) => {
        e.preventDefault()
        setLoading(false)
        onSubmit(e)
        setTimeout(() => {
            setOpen(false);
        }, 1000);
        //setTimeout(() => {
        //    setLoading(true)
        //}, 3000);
    };

    return (
        <div>
            <Button color="success" variant="contained" type="submit" onClick={handleClickOpen}>
                {/* <SendIcon /> */}
                <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
            </Button>
            <BootstrapDialog open={open} onClose={handleCloseCancel} aria-labelledby="customized-dialog-title" >
                <BootstrapDialogTitle sx={{ color: green[500] }} id="customized-dialog-title" onClose={handleCloseCancel} color="success">
                    Salvando as mudanças
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Tem certeza que quer salvar as mudanças da edição do <strong>{editUser.name.toUpperCase()}</strong>?
                    </Typography>
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                        {loading ? <CardProfileModel editUser={editUser} /> : <SucessSaveUser />}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseCancel} style={{ color: "#8a8a8a" }} >
                        Cancelar
                    </Button>
                    <Button autoFocus onClick={handleSave} variant="contained" color="success">
                        Salvar Edição
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
}