import * as React from "react";
import { Avatar, Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import IconCheckboxes from "./IconCheckboxes";
import GradingIcon from "@mui/icons-material/Grading";

export default function CardChatImage() {
  return (
    <>
      <Card sx={{ mt: 1 }}>
        {/* <Card sx={{ maxWidth: 345 }}></Card> */}
        <CardHeader
          sx={{ m: -2, ml: -1 }}
          avatar={
            <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
              <img className=" " src="/dist/img/avatarLeo.png" style={{ width: 45 }} alt="User Avatar" />
            </Avatar>
          }
          title={
            <Typography variant="button">
              <strong>Problemas no cocho do piquete 3 com defeito</strong>
            </Typography>
          }
          subheader={
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              Piquete 1
            </Typography>
          }
        />
        <CardContent sx={{ m: -2, ml: -1 }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Deve-se arrumar o telhado do cocho para não deixar chover e cai água na comida dos animais
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton aria-label="add to favorites">
            <GradingIcon />
          </IconButton>
          <Typography sx={{ ml: "auto", fontSize: 12 }} color="text.secondary">
            10/12/2022
          </Typography>
          <IconCheckboxes />
        </CardActions>
      </Card>
    </>
  );
}
