import React, { useState, useEffect } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { DeleteForever, Female, Male } from "@mui/icons-material";
import { red } from '@mui/material/colors';

const TableWeights = ({ data, obj_fn, confirm }) => {
  const [changed, setChanged] = useState(new Date().getTime());

  useEffect(() => {
    console.log('refresh changed');
    // atualiza peso tabela
  }, [changed]);

  const removeRow = (dados_animal, index) => {
    data.table_confirm.splice(index, 1);
    let aux_table_confirm = data.table_confirm;

    let aux_weight_total = parseFloat(data.weight_total) - parseFloat(dados_animal.new_weight);
    let aux_weight_medio = aux_weight_total / ((data.num_animals-1) || 1);

    obj_fn.setData((prevState) => ({ ...prevState, weight_medio: (aux_weight_medio).toFixed(2) }));
    obj_fn.setData((prevState) => ({ ...prevState, weight_total: aux_weight_total }));

    obj_fn.setData((prevState) => ({ ...prevState, num_animals: (data.num_animals -1) }));
    if (dados_animal.sexo === "Macho") {
      obj_fn.setData((prevState) => ({ ...prevState, num_males: (data.num_males -1) }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, num_females: (data.num_females -1) }));
    }

    obj_fn.setData((prevState) => ({ ...prevState, table_confirm: aux_table_confirm }));

    const aux_lote = JSON.parse(JSON.stringify(data.lote));
    var aux_cont = 0;
    for (var p = 0, tamp = Object.keys(aux_lote).length; p < tamp; p++) {
      let lote_w = aux_lote[Object.keys(aux_lote)[p]];
      aux_cont = 0;
      aux_table_confirm.filter((animal) => {
        if (lote_w.id === animal.id_lote) {
          aux_cont ++;
        }
        return animal
      });
      if (aux_cont <= 0) {
        delete aux_lote[Object.keys(aux_lote)[p]];
      }
    }
    obj_fn.setData((prevState) => ({ ...prevState, lote: aux_lote }));

    setChanged(new Date().getTime());
  };

  const handleChange = (event) => {
    console.log("handleChange");
    let tar_list = Number(event.target.id.replace(/.*weight_(.*)/, '$1'));
    let new_weight = parseFloat(event.target.value || '0');

    if (new_weight !== data.table_confirm[tar_list].new_weight) {
      let aux_new_total = (data.weight_total - data.table_confirm[tar_list].new_weight) + new_weight;

      data.table_confirm[tar_list].new_weight = new_weight;
      let new_gmd = (parseFloat(new_weight) - parseFloat(data.table_confirm[tar_list].last_weight)) / (data.table_confirm[tar_list].last_weight_d_h || 1);

      data.table_confirm[tar_list].new_gmd = new_gmd.toFixed(2);
      let aux_weight_medio = aux_new_total / data.num_animals;

      obj_fn.setData((prevState) => ({ ...prevState, weight_medio: (aux_weight_medio).toFixed(2) }));
      obj_fn.setData((prevState) => ({ ...prevState, weight_total: aux_new_total }));

      setChanged(new Date().getTime());
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{backgroundColor: '#f4f4f4'}}>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }} align="left">#</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">Idenificador</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">Brinco</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">SISBOV</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">Nome</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center" title='Fêmea / Macho'>Sexo (F/M)</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">Peso Anterior ({data.unit})</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">Peso Atual ({data.unit})</TableCell>
              {!confirm ? <></> :<TableCell style={{ fontWeight: "bold" }} align="center"> </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.table_confirm.length === 0 ? (
              <TableRow key={"emptyRows"} style={{ height: 53 }} >
                <TableCell colSpan={13} > . . . Nenhuma Pesagem adicionada . . . </TableCell>
              </TableRow> ) :
            data.table_confirm.map((row, index) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{index+1}</TableCell>
                <TableCell align="center">{row.identifier}</TableCell>
                <TableCell align="center">{row.brinco}</TableCell>
                <TableCell align="center">{row.sisbov}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center" title={row.sexo}>{row.sexo === 'Macho' ? <Male color="primary" /> : <Female color="secondary" />} {row.sexo.slice(0,1)}</TableCell>
                <TableCell align="center">{row.last_weight}</TableCell>
                {!confirm ? <TableCell align="center">{row.new_weight}</TableCell> :
                  <TableCell align="right">
                    <TextField
                      key={"weight_" + index}
                      id={"weight_" + index}
                      name={"new_weight_" + index}
                      type="number"
                      color="success"
                      variant="standard"
                      fullWidth
                      value={row.new_weight}
                      onChange={handleChange}
                      inputProps={{
                        style: { textAlign: "right" },
                        inputMode: "decimal",
                        pattern: "[0-9]*",
                        maxLength: 12,
                        min: 0,
                        autoFocus: true,
                      }}
                    />
                  </TableCell>
                }
                {!confirm ? <></> :
                <TableCell align="center">
                  <IconButton aria-label="delete" onClick={() => removeRow(row, index)} title="Excluir Pesagem">
                    <DeleteForever sx={{ color: red[600] }} />{" "}
                  </IconButton>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableWeights;
