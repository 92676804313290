import React, { useState } from 'react'
import { TextField, Button, Typography, Container, MenuItem, Grid, Card, CardContent, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useEffect } from 'react'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { HelpOutline } from '@mui/icons-material' // Importando o ícone de interrogação

const UnitOptions = [
  { label: 'sacas de 30 kg', value: 'sacas de 30 kg' },
  { label: 'sacas de 40 kg', value: 'sacas de 40 kg' },
  { label: 'sacas de 50 kg', value: 'sacas de 50 kg' },
  { label: 'sacas de 60 kg', value: 'sacas de 60 kg' },
  { label: 'toneladas', value: 'toneladas' },
  { label: 'quilogramas', value: 'quilogramas' },
  { label: 'gramas', value: 'gramas' },
  { label: 'miligramas', value: 'miligramas' },
  { label: 'unidades', value: 'unidades' },
]

const CardProductionArea = ({ target_active }) => {
  console.log(target_active)
  const [custoPorHectare, setCustoPorHectare] = useState('')
  const [produtividade, setProdutividade] = useState('')
  const [unidadeProdutividade, setUnidadeProdutividade] = useState('')
  const [cotacaoMilho, setCotacaoMilho] = useState('')
  const [unidadeCotacaoMilho, setUnidadeCotacaoMilho] = useState('')
  const [numeroHectares, setNumeroHectares] = useState(1)
  const [numeroHectaresPropriedade, setNumeroHectaresPropriedade] = useState(1)
  const [receitaTotal, setReceitaTotal] = useState(0)
  const [lucroBruto, setLucroBruto] = useState(0)
  const [produtividadePropriedade, setProdutividadePropriedade] = useState(0)
  const [investimento, setInvestimento] = useState(0)
  const [newReceitaTotal, setNewReceitaTotal] = useState(0)
  const [newLucroBruto, setNewLucroBruto] = useState(0)
  const [margemLucro, setMargemLucro] = useState(0)
  const [precoMinimoPorSaca, setPrecoMinimoPorSaca] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  //outro
  // Ajustar as variáveis de estado para refletir os dados do Imea
  const [custoOperacionalEfetivo, setCustoOperacionalEfetivo] = useState('4480.01') // COE para a safra 2023/24
  const [produtividadeMedia, setProdutividadeMedia] = useState('103.84') // Produtividade média

  console.log(numeroHectares)

  const calcularIndicadores = () => {
    // Conversão de unidade para padronizar para quilogramas
    const fatorConversaoCotacaoMilho = converterParaQuilogramas(unidadeCotacaoMilho)
    console.log(fatorConversaoCotacaoMilho)
    console.log(parseFloat(cotacaoMilho))

    // Cálculo do custo total
    const custoTotal = parseFloat(custoPorHectare) * parseFloat(numeroHectares)
    console.log(custoTotal)

    /// Cálculo da receita total
    const receitaTotal = parseFloat(produtividade) * parseFloat(cotacaoMilho)
    console.log('receitaTotal')
    console.log(receitaTotal)
    const valorFormatadoReceita = receitaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    setReceitaTotal(valorFormatadoReceita)
    console.log(produtividade, cotacaoMilho)

    //const receitaMaxima = parseFloat(produtividadeMaxima) * (parseFloat(cotacaoMilho) / fatorConversaoCotacaoMilho)

    // Cálculo do lucro bruto
    const lucroBruto = receitaTotal - custoTotal
    console.log('lucroBruto')
    console.log(lucroBruto)
    const valorFormatadoLucro = lucroBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    setLucroBruto(valorFormatadoLucro)
    // const lucroBrutoMaximo = receitaMaxima - custoTotal

    // Cálculo da margem de lucro
    const margLucr = (lucroBruto / receitaTotal) * 100
    setMargemLucro(margLucr)

    //
    // Cálculo do preço mínimo por saca
    const precoMinSc = parseFloat(custoOperacionalEfetivo) / parseFloat(produtividadeMedia)
    setPrecoMinimoPorSaca(precoMinSc)

    // Exibição dos resultados
    // alert(`
    //   Produtividade: ${produtividade} ${unidadeProdutividade} / ha
    //   Margem de Lucro: ${margemLucro.toFixed(2)}%
    //   Receita Total: R$ ${receitaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //   Lucro Bruto: R$ ${lucroBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //   Para cobrir o Custo Operacional Efetivo, o preço mínimo por saca deve ser de: R$ ${precoMinimoPorSaca.toFixed(2)}
    // `)
  }

  const calcularProdutividade = () => {
    console.log(custoPorHectare)
    // Cálculo produtividade da Propriedade por hectare investido
    const produtPropriedade = parseFloat(numeroHectaresPropriedade) * parseFloat(produtividade)
    setProdutividadePropriedade(produtPropriedade)

    // investimento
    console.log(numeroHectaresPropriedade, custoPorHectare)
    const invest = (parseFloat(custoPorHectare.replace(',', '.')) * numeroHectaresPropriedade).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    console.log(invest)
    setInvestimento(invest)
    console.log(receitaTotal)
    //const receitaTotalconvertida = converterParaQuilogramas(unidadeCotacaoMilho)
    console.log(receitaTotal, numeroHectaresPropriedade)
    // Remover o separador de milhar e substituir a vírgula por ponto para converter em número
    const receitaTotalNumero = parseFloat(receitaTotal.replace('.', '').replace(',', '.'))

    const newReceTot = receitaTotalNumero * numeroHectaresPropriedade
    console.log(newReceTot)
    setNewReceitaTotal(newReceTot)

    //Lucro Bruto
    console.log(invest)
    const numeroSemPontos = invest.replace(/\./g, '') // Remover pontos
    const numeroSemVirgula = numeroSemPontos.replace(',', '.') // Substituir vírgula por ponto
    console.log(numeroSemVirgula)
    const newlucroBr = newReceTot - numeroSemVirgula
    console.log(newlucroBr)

    setNewLucroBruto(newlucroBr)

    // Exibição dos resultados
    //// Produtividade : ${produtividade} ${unidadeProdutividade} / ha
    handleOpenModal()
    // alert(`
    //   Produção esperada : ${produtividadePropriedade} ${unidadeProdutividade} / ha
    //   Investindo:  R$ ${investimento.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //   valor de venda da safra: R$ ${newReceitaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //   Lucro de: ${lucroBruto}
    // `)
  }

  const converterParaQuilogramas = (unidade) => {
    switch (unidade) {
      case 'sacas de 30 kg':
        return 30
      case 'sacas de 40 kg':
        return 40
      case 'sacas de 50 kg':
        return 50
      case 'sacas de 60 kg':
        return 60
      case 'toneladas':
        return 1000
      case 'quilogramas':
        return 1
      case 'gramas':
        return 0.001
      case 'miligramas':
        return 0.000001
      case 'unidades':
        return 1 // Ajuste conforme necessidade
      default:
        return 1 // Valor padrão
    }
  }

  console.log(target_active.cultivation_name)
  const currentYear = new Date().getFullYear()
  const cultivationName = target_active.cultivation_name

  const openSearchCustoPorHectare = () => {
    window.open(`https://www.google.com/search?q=qual+é+o+custo+por+hectare+da+cultura+${cultivationName}+em+${currentYear}`, '_blank')
  }

  const openSearchProdutividadeMinima = () => {
    window.open(`https://www.google.com/search?q=produtividade+média+nacional+estimada+em+sacas+por+hectare+para+${cultivationName}+em+${currentYear}`, '_blank')
  }

  const openSearchcotacaoMilho = () => {
    window.open('https://www.google.com/search?q=qual+a+cotação+do+milho+para+2024', '_blank')
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Análise do mercado em 1 hectare
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            label="Custo por Hectare"
            variant="outlined"
            value={custoPorHectare}
            onChange={(e) => setCustoPorHectare(e.target.value)}
            margin="normal"
            helperText={'Dica: Procure também pesquisar o custo na sua região'}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="secondary" onClick={openSearchCustoPorHectare}>
            Pesquisar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={5}>
          <TextField fullWidth label="Produtividade/unidade/hectare" variant="outlined" value={produtividade} onChange={(e) => setProdutividade(e.target.value)} margin="normal" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField select fullWidth value={unidadeProdutividade} onChange={(e) => setUnidadeProdutividade(e.target.value)} variant="outlined">
            {UnitOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="secondary" onClick={openSearchProdutividadeMinima}>
            Pesquisar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={5}>
          <TextField fullWidth label="Cotação do Milho" variant="outlined" value={cotacaoMilho} onChange={(e) => setCotacaoMilho(e.target.value)} margin="normal" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField select fullWidth value={unidadeCotacaoMilho} onChange={(e) => setUnidadeCotacaoMilho(e.target.value)} variant="outlined">
            {UnitOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="secondary" onClick={openSearchcotacaoMilho}>
            Pesquisar
          </Button>
        </Grid>
      </Grid>
      <TextField disabled fullWidth label="Número de Hectares" variant="outlined" value={numeroHectares} onChange={(e) => setNumeroHectares(e.target.value)} margin="normal" />
      <Button variant="contained" color="success" onClick={calcularIndicadores}>
        Calcular
      </Button>
      <Grid item xs={12} md={12}>
        <Card sx={{ mt: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Resultado da análise de mercado
            </Typography>
            <Grid container spacing={2} sx={{ mb: 1.1 }}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item sx={{ width: 50 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="caption">Produtividade Esperada</Typography>
                    <Typography variant="subtitle2">
                      <strong>{produtividade}</strong> <span style={{ fontSize: '0.8em' }}>{unidadeProdutividade} / ha</span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="caption">Margem de Lucro</Typography>
                    <Typography variant="subtitle2">
                      {' '}
                      {/* <strong>{data.sellingPricePerUnit}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}/ha</span>{' '} */}
                      <strong>{margemLucro.toFixed(2)}%</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item sx={{ width: 50 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="caption">Receita Total</Typography>

                    <Typography variant="subtitle2">
                      <strong>R$ ${receitaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </strong> <span style={{ fontSize: '0.8em' }}>(%)</span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="caption">Lucro Bruto</Typography>

                    <Typography variant="subtitle2">
                      <strong>R$ {lucroBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                Para cobrir o Custo Operacional Efetivo
                <Tooltip
                  title="O Custo Operacional Efetivo (COE) inclui todos os itens considerados variáveis ou gastos diretos representados pelo dispêndio em dinheiro, tais como insumos (fertilizantes, sementes e defensivos agrícolas), operação mecânica (diesel e manutenção preventiva), mão de obra, serviços terceirizados, comercialização agrícola, transporte, despesas financeiras, despesas com tributos de comercialização e despesas gerais."
                  arrow
                  placement="top"
                >
                  <IconButton size="small" sx={{ marginTop: '-10px' }}>
                    <HelpOutline fontSize="small" />
                  </IconButton>
                </Tooltip>
                , o preço mínimo por saca deve ser de: R$ {precoMinimoPorSaca.toFixed(2)}
              </Grid>
              {/* Adicione mais informações conforme necessário */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Container maxWidth="sm" sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Quanto hectares deseja investir?
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={9}>
            <TextField fullWidth label="Número de Hectares" variant="outlined" value={numeroHectaresPropriedade} onChange={(e) => setNumeroHectaresPropriedade(e.target.value)} margin="normal" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="contained" color="secondary" onClick={calcularProdutividade}>
              calcular
            </Button>
          </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Análise para sua meta de produção</DialogTitle>
          <DialogContent>
            <Grid item xs={12} md={12}>
              <Card sx={{ mt: 1, mb: 1 }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ mb: 1.1 }}>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={1}>
                        <Grid item sx={{ width: 50 }}>
                          <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="caption">Produtividade Esperada</Typography>
                          <Typography variant="subtitle2">
                            <strong>{produtividade}</strong> <span style={{ fontSize: '0.8em' }}>{unidadeProdutividade} / ha</span>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="caption">Investindo</Typography>
                          <Typography variant="subtitle2">
                            {' '}
                            {/* <strong>{data.sellingPricePerUnit}</strong> <span style={{ fontSize: '0.8em' }}>{target_active?.unitCost}/ha</span>{' '} */}
                            <strong>R$ {investimento.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={1}>
                        <Grid item sx={{ width: 50 }}>
                          <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="caption">valor de venda da safra</Typography>

                          <Typography variant="subtitle2">
                            <strong>R$ ${newReceitaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="caption">Lucro Bruto</Typography>

                          <Typography variant="subtitle2">
                            <strong>R$ {newLucroBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>
                      Para cobrir o Custo Operacional Efetivo
                      <Tooltip
                        title="O Custo Operacional Efetivo (COE) inclui todos os itens considerados variáveis ou gastos diretos representados pelo dispêndio em dinheiro, tais como insumos (fertilizantes, sementes e defensivos agrícolas), operação mecânica (diesel e manutenção preventiva), mão de obra, serviços terceirizados, comercialização agrícola, transporte, despesas financeiras, despesas com tributos de comercialização e despesas gerais."
                        arrow
                        placement="top"
                      >
                        <IconButton size="small" sx={{ marginTop: '-10px' }}>
                          <HelpOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      , o preço mínimo por saca deve ser de: R$ {precoMinimoPorSaca.toFixed(2)}
                    </Grid> */}
                    {/* Adicione mais informações conforme necessário */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  )
}

export default CardProductionArea
