import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, ButtonBase, IconButton, List, Paper, Stack, Typography } from '@mui/material';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';

import SpaIcon from '@mui/icons-material/Spa';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CreateDrawAreaList from './CreateDrawAreaList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TutorialAddAreaAccordion from './TutorialAddAreaAccordion';

export default function TutorialAddArea({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CropOriginalIcon sx={{ mr: 1 }} /> Criação das Áreas
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão das áreas de produção
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A criação de áreas no mapa da propriedade é uma ferramenta valiosa para o administradores ou gestor, pois organiza a propriedade, facilita os manejos, o controle e monitoramento do rebanho
            ou da cultura.
          </DialogContentText>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Veja a seguir como é fácil criar uma nova área:
            </Typography>
          </Box>
          <TutorialAddAreaAccordion />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
