import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { green } from '@mui/material/colors'
import { Accordion, Box, CardMedia, Grid, IconButton, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { Input, Map } from '@mui/icons-material'
import dayjs from 'dayjs'
import WallpaperIcon from '@mui/icons-material/Wallpaper'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ViewActivitiesMonitoring({ row, usestore }) {
  const [open, setOpen] = React.useState(false)
  // console.log(row)

  const activityMonitoring = usestore.reposActivityMonitoring.getActivityMonitoringStore('id', row.id)[0]
  // console.log(activityMonitoring)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <IconButton aria-label="Visualização" onClick={() => handleClickOpen()} title="Visualização">
        <FullscreenIcon sx={{ color: green[500] }} />
      </IconButton>
      <Dialog open={open} maxWidth={'md'} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Monitoramento'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Grid container sx={{ mb: 4, p: 2 }}>
            {/* dados gerais */}
            <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
              <Input />
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>Datas progresso do monitoramento</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
              <Grid item xs={4} sm={4}>
                <ListItem>
                  <ListItemText primary="Data de início" secondary={dayjs(activityMonitoring?.date_start).format('DD/MM/YYYY')} />
                </ListItem>
              </Grid>
              <Grid item xs={4} sm={4}>
                <ListItem>
                  <ListItemText primary="Data de término" secondary={dayjs(activityMonitoring?.date_prev_end).format('DD/MM/YYYY')} />
                </ListItem>
              </Grid>
              <Grid item xs={4} sm={4}>
                <ListItem>
                  <ListItemText primary="Progresso" secondary={activityMonitoring?.progress} />
                </ListItem>
              </Grid>
            </Grid>

            {/* Área(s) de plantio */}
            <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
              <Map />
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>
                    Área de monitoramento: <strong>{activityMonitoring?.monitoringName}</strong>{' '}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
              {activityMonitoring.areaTranferImage ? (
                <Grid item xs={12}>
                  <CardMedia component="img" sx={{ height: 450 }} image={activityMonitoring?.areaTranferImage} alt={activityMonitoring?.name} />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography fontWeight={700}>Imagem ausente</Typography>
                </Grid>
              )}
            </Grid>
            {activityMonitoring?.areaTranferImage ? (
              <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                  <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
                    <Grid item xs={4} sm={4}>
                      <ListItem>
                        <ListItemText primary="Raio:" secondary={`${activityMonitoring?.heatmapRadius} metros`} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <ListItem>
                        <ListItemText primary="Intensidade" secondary={activityMonitoring?.heatmapWeight} />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                  <div style={{ marginLeft: 'auto', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 10, borderRadius: 5 }}>
                    <Grid container spacing={1}>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, backgroundColor: 'rgba(0, 255, 0, 0.8)', marginRight: 5 }}></div>
                        <span style={{ fontSize: 12, color: 'black' }}>0 - Ausente</span>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 255, 0, 0.8)', marginRight: 5 }}></div>
                        <span style={{ fontSize: 12, color: 'black' }}>1 - Baixo</span>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 165, 0, 0.8)', marginRight: 5 }}></div>
                        <span style={{ fontSize: 12, color: 'black' }}>2 - Médio</span>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 105, 97, 0.8)', marginRight: 5 }}></div>
                        <span style={{ fontSize: 12, color: 'black' }}>3 - Alto</span>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, backgroundColor: 'rgba(255, 0, 0, 0.8)', marginRight: 5 }}></div>
                        <span style={{ fontSize: 12, color: 'black' }}>4 - Muito alto</span>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography fontWeight={700}></Typography>
              </Grid>
            )}
            <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
              <Grid item xs={12} md={6}>
                <Accordion>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item>
                      <WallpaperIcon sx={{ color: 'action.active', ml: 2 }} />
                    </Grid>
                    <Grid item>
                      <Typography sx={{ ml: -1 }}>Imagem</Typography>
                    </Grid>
                    <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                      <>{activityMonitoring?.image ? <CardMedia component="img" height="140" image={activityMonitoring?.image} alt="Área" /> : <Typography sx={{ ml: -1 }}>Sem imagem</Typography>}</>
                    </Grid>
                  </Grid>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={6}>
                <Accordion>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item>
                      <Map sx={{ color: 'action.active', ml: 2 }} />
                    </Grid>
                    <Grid item>
                      <Typography sx={{ ml: -1 }}>Área</Typography>
                    </Grid>
                    <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
                      <CardMedia component="img" height="140" image={activityMonitoring?.image_area} alt="Área" />
                    </Grid>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
