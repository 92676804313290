import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { Edit, LocationOn } from '@mui/icons-material'
import IEProgressBars from '../../indexExperience/components/IEProgressBars'
import { green, grey } from '@mui/material/colors'
import StepperIE from '../../indexExperience/components/StepperIE'
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PublicIcon from '@mui/icons-material/Public'
import GroupsIcon from '@mui/icons-material/Groups'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import CardPropertyProducts from '../../property/components/CardPropertyProducts'
import { Span } from '../../../components/Typography'
import MenuPlan from './MenuPlan'
import MapIcon from '@mui/icons-material/Map'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useNavigate } from 'react-router-dom'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import AppsIcon from '@mui/icons-material/Apps'

export default function AccessProprety({ listPropertyRepos }) {
  const navigate = useNavigate()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent
  console.log(user)
  const name = user.name
  const charName = name[0].toUpperCase()
  console.log(charName)

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }

  return (
    <>
      <Card>
        <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <AppsIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Passo 1: Painel Central</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Visualizar as informações da propriedade no painel da propriedade.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>
                  A Central de controle é o local ideal para gestores da pecuária obterem as principais informações da propriedade. Informações sobre as Criações, Safras, áreas da produção, clima,
                  acesso ao suporte.
                </Typography>
              </Stack>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/painel')}>
                  <Grid item sx={{ width: 50 }}>
                    <HomeWorkIcon />
                  </Grid>
                  <Typography fontWeight={700}>Clique aqui</Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <MapIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Passo 2: Áreas de Produção</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Organizar as áreas de produção da propriedade.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>
                  A criação de áreas no mapa da propriedade é uma ferramenta valiosa para o pecuarista, pois organiza a propriedade, facilita os manejos, o controle do rebanho e o monitoramento.
                </Typography>
              </Stack>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/areasmaps')}>
                  <Grid item sx={{ width: 50 }}>
                    <ListAltIcon />
                  </Grid>
                  <Typography fontWeight={700}>Clique aqui</Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <ListAltIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Passo 3: Equipe</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Registrar usuários no sistema.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>
                  Ao adicionar pessoas à sua equipe, você estabelece qual o perfil (função) dessa pessoa dentro da propriedade. O perfil de cada pessoa determinará quais informações ela tem permissão
                  ou não de acessar.
                </Typography>
              </Stack>
              <Grid container spacing={12} sx={{ mt: 1 }}>
                <Grid item xs={12} md={12}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                    <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/team')}>
                      <Grid item sx={{ width: 50 }}>
                        <ListAltIcon />
                      </Grid>
                      <Typography fontWeight={700}>Clique aqui</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <ListAltIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Passo 4: Solicitações</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Registrar tarefas, anotaçõese ou observações da propriedade.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>Em solicitações, você poderá registrar as solicitações, tarefas, anotações ou observações da propriedade e principalmente pertinentes as safras ou criações.</Typography>
              </Stack>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={12}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                    <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/messenger')}>
                      <Grid item sx={{ width: 50 }}>
                        <ListAltIcon />
                      </Grid>
                      <Typography fontWeight={700}>Clique aqui</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <ListAltIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Passo 5: Registrar o projeto</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Registrar o projeto de safra ou criação.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>
                  Através do registro da criação ou da safra, você verá como é fácil gerenciar todas as atividades de produção, avaliar os resultados com precisão e tomar decisões estratégicas para
                  atingir os objetivos.
                </Typography>
              </Stack>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                    <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/livestock')}>
                      <Grid item sx={{ width: 50 }}>
                        <ListAltIcon />
                      </Grid>
                      <Typography fontWeight={700}>Clique aqui (Criação)</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                    <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/harvest')}>
                      <Grid item sx={{ width: 50 }}>
                        <ListAltIcon />
                      </Grid>
                      <Typography fontWeight={700}>Clique aqui (Safra)</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2, display: 'flex' }}>
                <AirlineStopsIcon style={{ fontSize: 45 }} />
                <Stack spacing={0.5} sx={{ ml: 1 }}>
                  <Typography fontWeight={700}>Próximos passos</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <LocationOn sx={{ color: grey[500] }} /> Planejar e gerenciar as atividades de produção.
                  </Typography>
                </Stack>
              </Box>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                <Typography>Clique acima em "Projeto Criação" ou " Projeto Safra" para iniciar o planejamento e gerenciamento das atividades de produção.</Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

//https://aegro.com.br/termos-de-uso/
