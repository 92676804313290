import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { CircularProgress } from "@mui/material";
import ModalSendPassword from "./ModalSendPassword";
import LogoNameWhite from "../../../assets/png/logo-name-white.png";

export default function ForgotPasswordContent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const onSubmit = async (e) => {
    setLoading(true)
    const header = { header: { "Content-Type": "application/json" } };

    // console.log(email)
    //const res = await api.post("http://localhost:5000/api/v2/password/forgot", { email }, header);
    await api.post("password/forgot", { email }, header)
      .then((response) => {
        // aqui acessamos o corpo da resposta:
        // console.log(response.data)
        if (response.data.isSuccess) {
          //handleLogin();
          toast.success('Sucesso');
          handleOpen()
          // setTimeout(() => {
          //   navigate("/");
          // }, 5000);
          setLoading(false)
        }
      })
      .catch((error) => {
        // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
        console.log('error');
        toast.error(error.response.data);
      })


  };

  return (
    <>

      <div style={{ background: "#1db954" }} className=" login-page ">

        <div className="login-box">
          <div className="login-logo " style={{ width: "340px", height: "100px" }}>
            {/* <img style={{ height: 70 }} src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="CPT" className="brand-image center" />
                        <h5 className="font-weight-normal mt-3 text-white"  >CPT Fazenda Premium</h5> */}
            <img className="m-3" src={LogoNameWhite} style={{ width: "290px" }} alt="CPT" />

          </div>
          <div>

            <div className="card-body ">
              <h4 className="login-box-msg text-white">Esqueci a minha senha</h4>
              <p className="login-box-msg text-white small">Você esqueceu sua senha? Aqui você pode facilmente recuperar uma nova senha.</p>
              {/* <p className="login-box-msg text-white small">Encaminharemos um link para o seu e-mail para criar uma nova senha.
                Verifique a caixa de Spam caso não encontre o e-mail.</p> */}
              {/* temos um state: false e  de formulario null */}
              <form action="../../index3.html" method="post">
                <div className="input-group mb-3">
                  <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Digite seu e-mail" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>

                <div className=" text-center mt-2 mb-1">
                  <NavLink to="#">
                    <button type="submit" onClick={onSubmit} className="btn btn-success  btn-block">
                      {loading ? <CircularProgress sx={{ mb: -0.6 }} size="1.3rem" style={{ color: '#fff' }} /> : "Enviar link para o meu e-mail"}
                    </button>
                  </NavLink>
                </div>
                <p className="mb-1 mt-3  ">
                  <Link to="/" className=" text-white ">
                    Ir para o Login
                  </Link>
                </p>
              </form>
              <ModalSendPassword handleOpen={handleOpen} open={open} handleClose={handleClose} />
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.login-box */}
      </div>
    </>
  );
}

