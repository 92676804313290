import React, { Component } from 'react'
import Chart from 'react-apexcharts'

export default function ChartBarHActvSoul({ usedArea, areaTotal }) {
  const state = {
    options: {
      toolbar: {
        show: false,
        tools: {
          download: false, // <== line to add
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff'],
        },
        formatter: function (val, opt) {
          return val + ' hectares'
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      colors: ['#1691de', '#28a745'],
      xaxis: {
        categories: ['Área', 'Área Utilizada'],
        labels: {
          show: false,
        },
      },
    },
    series: [
      {
        data: [areaTotal, usedArea],
      },
    ],
  }

  return (
    <div className="app">
      <div className="row">
        <div style={{ width: '100%' }} className="bg-white w-full">
          <Chart options={state.options} series={state.series} type="bar" height="120" />
        </div>
      </div>
    </div>
  )
}
