import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { createTheme } from '@mui/material/styles';
// import { deDE } from '@mui/x-date-pickers/locales'
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { useState } from 'react';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ToolipBoostrap from '../../../../../components/Tooltip/ToolipBoostrap';
import FormInputStep4PeriodBasicDatePickerNowLivestockProgressRadioButton from './FormInputStep4PeriodBasicDatePickerNowLivestockProgressRadioButton';

// const theme = createTheme(
//     {
//         palette: {
//             primary: { main: '#1976d2' },
//         },
//     },
//     deDE, // use 'de' locale for UI texts (start, next month, ...)
// );

export default function FormInputStep4PeriodBasicDatePickerNowLivestock({ data, setData, setLoadindSteptTitle }) {
  // console.log("1-init-BasicDatePickerLivestock")
  const [showText, setShowText] = useState(false);

  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  const dateNow = new Date();
  //post.date = format(date, "MMMM do, yyyy H:mma");
  // const dateNow = format(date, "dd-MM-yyyy");
  const [value, setValue] = React.useState(dayjs(dateNow));
  //console.log(value)
  //const aYearFromNow = dateNow.getFullYear() + dateNow.getDate() + 1
  //console.log(aYearFromNow)
  var addOneday = dayjs(new Date()).add(1, 'day');
  //console.log(dayjs(addOneday))
  //console.log(dayjs(addOneday).format('DD-MM-YYYY'))
  //const aYearFromNow = dateNow.setFullYear(dateNow.getFullYear() + 1);
  //console.log(aYearFromNow)

  const [valueEnd, setValueEnd] = React.useState(addOneday);
  // console.log("valueEnd")
  // console.log(dayjs(valueEnd).format('DD/MM/YYYY'))

  // valor para inicializar o data
  useEffect(() => {
    setData((prevState) => ({ ...prevState, date_start: dayjs(value).format('YYYY-MM-DD HH:mm:ss') }));
    setData((prevState) => ({ ...prevState, date_prev_exit: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }));
  }, [value]);

  // console.log("data Fim")
  // console.log(data)
  // const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", "Sa": "sáb", "Do": "dom", }

  const longText =
    'Definir prazos é o caminho que direciona suas ações rumo ao sucesso na atividade pecuária, permitindo que você alcance metas, otimize recursos e garanta o progresso sustentável do seu rebanho. Com prazos definidos, você poderá monitorar o progresso da atividade.';
  const text = 'Importância de definir prazo para atividade.';

  return (
    <Grid container spacing={2}>
      <Grid container spacing={1} sx={{ mt: 4 }}>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 40 }}>
              {showText ? (
                <>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter tam={60} text="Com a data de hoje registrada, precisamos definir o progresso da atividade." />
                  </Typography>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 60 }}>
              {showText ? (
                <>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter tam={100} text="Com a data de hoje registrada, precisamos definir o progresso da atividade." />
                  </Typography>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {/* <Grid item xs={12} md={12} sx={{ my: 0, height: 125 }} >
                <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <div className="d-lg-none">
                        <Stack spacing={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <MobileDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Início*"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            const newValueEnd = dayjs(newValue).add(1, 'day');
                                            console.log("------")
                                            setValueEnd(newValueEnd);
                                        }}
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                        renderInput={(params) => <TextField {...params} color="success" />}
                                    />
                                </Grid>

                            </Grid>
                        </Stack>
                    </div>
                    <div className="d-none d-lg-block">
                        <Stack spacing={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Início*"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            const newValueEnd = dayjs(newValue).add(1, 'day');
                                            console.log("------")
                                            console.log(newValueEnd)
                                            setValueEnd(newValueEnd);
                                        }}
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                        renderInput={(params) => <TextField {...params} color="success"
                                            error={!value}
                                            helperText={!value && (<p id="NameLivestock" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                                        />}
                                    //shouldDisableDate={isFutureDate}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>

                </LocalizationProvider>
            </Grid> */}
      <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
        <FormInputStep4PeriodBasicDatePickerNowLivestockProgressRadioButton data={data} setData={setData} />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
        <ToolipBoostrap text={text} longText={longText} />
      </Grid>
    </Grid>
  );
}
