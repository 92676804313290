import { useContext, useEffect, useState } from "react";
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Typewriter from "../../../components/Typewriter/Typewriter";
import ChatPlan from "../components/FormPlan/ChatPlan";
import { Span } from "../../../components/Typography";
import IAIcon from "../../../components/Icons/chat/IAIcon";
import { toast } from "react-toastify";
import useStore from "../../../store/useStore";
import { format } from "date-fns";
import SuccessTarget from "./SuccessTarget/SuccessTarget";
import api from "../../../services/api";
import { Context } from "../../../components/Context/AuthContext";
import { useParams } from "react-router-dom";
import useHarvestTargetReposStore from "../../../store/useHarvestTargetReposStore";
import { green } from "@mui/material/colors";

const UnitOptions = [
  { label: "sc 30kg", value: "sc 30kg" },
  { label: "sc 40kg", value: "sc 40kg" },
  { label: "sc 50kg", value: "sc 50kg" },
  { label: "sc 60kg", value: "sc 60kg" },
  { label: "t", value: "t" },
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "mg", value: "mg" },
];

const ValueCost = [
  { label: "R$", value: "pt-BR" },
  { label: "$", value: "en-US" },
];

const MeasureOptions = [
  {
    value: "ha",
    label: "hectare (ha)",
  },
  {
    value: "m2",
    label: "metros (m²)",
  },
];

export default function FormGoalTargetEditCost({ handleClose, target, data, setData  }) {
  console.log("FormGoalTargetHarvestGeneral");
  console.log(target)
  console.log(data)
  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState('naoAnaliseCustos');

  const handleChangeRadio = (event) => {

    console.log(event.target.value)
    if (event.target.value == "naoAnaliseCustos") {
      console.log("teste")

      setData((prevData) => ({
        ...prevData,
        data_cost: {
          ...prevData.data_cost,
          "insert": false,
        },
      }))
    }

    setSelectedOption(event.target.value);
  };


  const { idFarm, id } = useParams();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);

  const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {
    console.log("teste")
    const { name, value } = event.target;
    console.log(name, value);

    // if (name == "saleUnitValue") {
    //   setData((prevData) => ({
    //     ...prevData,
    //     "saleUnitValue": value,
    //   }));
    // }




    if (name == "percentageProfit" || name == "saleUnitValue") {

      setData((prevData) => ({
        ...prevData,
        data_cost: {
          ...prevData.data_cost,
          "insert": true,
        },
      }))

      setData((prevData) => ({
        ...prevData,
        data_cost: {
          ...prevData.data_cost,
          [name]: value,
        },
      }))
    }
  };

  // if (name == "percentageProfit") {
  //   //setData((prevData) => ({...prevData,[name]: value}));
  //   //data.data_cost.percentageProfit = value
  //   //data.data_cost.unitSale = data.unitCost
  //   //data.data_cost.valueSale = data.valueCost
  //   setData((prevData) => ({
  //     ...prevData,
  //     "percentageProfit": value,
  //   }));
  // }
  // setData((prevData) => ({
  //   ...prevData,
  //   [name]: value,
  // }));


  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (selectedOption == "naoAnaliseCustos") {
      return false;
    }
    // if (!data.startDate || data.startDate.trim() === "") {
    //   return true;
    // }
    if (!data.data_cost.saleUnitValue || data.data_cost.saleUnitValue.trim() === "") {
      return true;
    }
    if (!data.data_cost.percentageProfit || data.data_cost.percentageProfit.trim() === "") {
      return true;
    }
    // if (!data.data_cost.cost || data.data_cost.cost.trim() === "") {
    //   return true;
    // }
    return false;
  };

  //console.log(isError)


  const returnError = () => {
    return false;
  };
  const color = "#2e7d32";

  console.log(data)
  //console.log(data.areaSelection.name)
  
  return (
    <>
      {/* {isLoading && <SuccessTarget handleClose={handleClose} />} */}
            <Grid container sx={{ mt: 0, mb: 1 }}>
              <Grid container sx={{ mt: 0, mb: 3 }} >
                <Grid item xs={12} md={12} alignItems="center" >
                  <Typography style={{ color: "#2e7d32" }}>
                  Vamos realizar a edição do custo da produção
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={8}>
                  <TextField
                    disabled
                    name="expectedProduction"
                    variant="filled"
                    color="success"
                    focused
                    label="Produção Total Esperada"
                    type="number"
                    required
                    fullWidth
                    value={data.data_cost.expectedProduction}
                    onChange={handleChange}
                    error={formError.expectedProduction || data.data_cost.expectedProduction === ""}
                    helperText={formError.expectedProduction && data.data_cost.expectedProduction === "" ? "Por favor, preencha o campo obrigatório!" : "Estime o tamanho da sua produção."}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                      <Select disabled name="unit" fullWidth label="Unidade" value={data.data_cost.unit} onChange={handleChange}>
                        {UnitOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid> */}
                {/* <Grid item xs={12} sm={12}>
                  <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center", mt: 0.5 }} variant="subtitile1" component="div">
                    <strong>Qual o valor de venda para data desejada?</strong>
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12} sm={2}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                      <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                      <Select name="valueSale" fullWidth label="Moeda" value={data.data_cost.valueSale} onChange={handleChange}>
                        {ValueCost.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    name="saleUnitValue"
                    variant="filled"
                    color="success"
                    focused
                    label="Valor esperado de venda por unidade"
                    type="number"
                    required
                    fullWidth
                    value={data.data_cost.saleUnitValue}
                    onChange={handleChange}
                    error={formError.saleUnitValue || data.data_cost.saleUnitValue == ""}
                    helperText={formError.saleUnitValue && data.data_cost.saleUnitValue === "" ? "Por favor, preencha o campo obrigatório!" : "Estime o valor desejado de venda."}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={4}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                      <Select name="unitSale" fullWidth label="Unidade" value={data.data_cost.unitSale} onChange={handleChange}>
                        {UnitOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center", mt: 0.5 }} variant="subtitile1" component="div">
                    <strong>Qual o valor do lucro desejado sobre a venda em porcentagem?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    //id="percentageProfit"
                    label="Porcentagem de lucro"
                    type="number"
                    name="percentageProfit"
                    value={data.data_cost.percentageProfit}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment sx={{ mt: 3 }} position="end">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={8}>
                  <TextField
                    disabled
                    name="area"
                    variant="standard"
                    label="Área"
                    type="number"
                    required
                    fullWidth
                    value={data.area}
                    onChange={handleChange}
                    error={formError.area && data.area === ""}
                    helperText={formError.area && data.area === "" ? "Por favor, preencha o campo obrigatório!" : "Estime a área da sua produção."}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={2}>
                  <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Medida</InputLabel>
                      <Select disabled autoFocus name="measure" fullWidth label="Unidade" value={data.measure} onChange={handleChange}>
                        {MeasureOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid> */}
              </Grid>
              {/* <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                Ex.: Produção de 2.000 sacas de 60 kg em uma área de 50 hectares de cultivo.
              </Typography>
            </Grid> */}
              {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              Gerar Planejamento
            </Button> */}
            
            </Box>
          
        
      {/* <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=77"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=2"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
// banco:
//https://www.cepea.esalq.usp.br/br/consultas-ao-banco-de-dados-do-site.aspx
//https://cepea.esalq.usp.br/br/widget.aspx
// site com grafico
//https://web.graodireto.com.br/#/quotation
//https://www.graodireto.com.br/#who-buys
