import { useEffect, useState } from "react";
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton, CardMedia } from "@mui/material";
import Typewriter from "../../../../components/Typewriter/Typewriter";
import ChatPlan from "./ChatPlan";
import { Span } from "../../../../components/Typography";
import IAIcon from "../../../../components/Icons/chat/IAIcon";
import FormPlanFooter from "./FormPlanFooter";
import FormCostActivities from "./FormCostActivities";
import sendQuestionReceiveAnswer from "../../../chat/sendQuestionReceiveAnswer";
import ThreeDotsSpinner from "../../../../components/Loader/ThreeDotsSpinner";
import extractValueEstimate from "../../../chat/Questions/extractValueEstimate";
import IAIconWhite from "../../../../components/Icons/chat/IAIconWhite";

export default function FirstStepFormConfirmEstimative({ data, setData, formError, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const [open, setOpen] = useState(false);

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (!data.startDate || data.startDate.trim() === "") {
    //   return true;
    // }
    // if (!data.expectedYield || data.expectedYield.trim() === "") {
    //   return true;
    // }
    // if (!data.area || data.area.trim() === "") {
    //   return true;
    // }
    return false;
  };

  const returnError = () => {
    return false;
  };
  const color = "#2e7d32";

  const [prompt, setPrompt] = useState("");
  const [responseVariable, setResponseVariable] = useState("");
  const [loading, setLoading] = useState(false);



  const handleClick = async () => {

  }

  const handleQuerySubmit = async () => {
    console.log("handleQuerySubmit");
    setLoading(true);
    const temp = `O custo da saca de ${data.cultivation_name} está hoje no valor de R$ ${data.cost} reais /${data.unitCost}. Qual valor estimando do lucro em reais de uma safra de ${data.cultivation_name} de ${data.electricityCost} ${data.unit} numa área de ${data.area} ${data.measure}.Incie a frase com: O lucro estimado da safra seria de R$... e no final esclareça os principais custos para chegar a esse valor, exemplificando com os valores  `;
    setPrompt("");
    console.log(temp)
    const response = await sendQuestionReceiveAnswer(temp);
    //const response = "";

    console.log(response);
    setPrompt(response);
    const valueEstimate = extractValueEstimate(response);
    console.log(valueEstimate);
    const formattedNumber = (valueEstimate / 100).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    console.log()
    setResponseVariable(formattedNumber);
    setLoading(false);
  };

  console.log("fim");
  console.log(prompt);
  //title={data.activitiesPlan[0].title} icon={data.activitiesPlan[0].icon} operations={data.activitiesPlan[0].operations} setData={setData}
  return (
    <>
      <Container maxWidth="sm">
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleOpenChat}>
              <IAIconWhite size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}
          <Grid container sx={{ mt: 1, mb: 6 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }}>
              <Typography style={{ color: "#2e7d32" }}>
                {data && <Typewriter tam={0} minHeight={0} text={`Ótimo!!! Deseja que eu estime o lucro da safra de ${data.electricityCost} ${data.unit} numa área de ${data.area} ${data.measure} com base nos dados existentes na internet?`} />}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Button variant="contained" color="success" fullWidth onClick={handleQuerySubmit}>
                  Sim
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="success" fullWidth onClick={handleClick}>
                  Não
                </Button>
              </Grid>
            </Grid>
          </div>
          <Box>
            {loading ? (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1, ml: 3 }}>
                {/* <CardMedia component="img" src="/dist/image/chatgptimage.png" alt="" sx={{ width: 40, height: 40, marginRight: 1 }} /> */}
                <Box sx={{ flex: "none", width: 40, height: 40, marginRight: 1 }}>
                  <IAIcon size={32} />
                </Box>
                <ThreeDotsSpinner />
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1, ml: 3 }}>
                {/* <CardMedia component="img" src="/dist/image/chatgptimage.png" alt="" sx={{ width: 40, height: 40, marginRight: 1 }} /> */}
                <Box sx={{ flex: "none", width: 40, height: 40, marginRight: 1 }}>
                  <IAIcon size={32} />
                </Box>
                <div>{prompt}</div>
              </Box>
            )}
            {loading == false && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                  ml: 3,
                  border: "1px solid gray",
                  borderRadius: "4px",
                  padding: "8px",
                }}
              >

                <div>Valor estimado: {responseVariable}</div>
              </Box>
            )}
            {data && (
              <FormPlanFooter
                data={data}
                setData={setData}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps}
                isError={isError}
                handleClose={handleClose}
                returnError={returnError}
              />
            )}
          </Box>
        </Paper>
        {loading == false && (
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={10} sm={10}>
              <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2 }}>
                Clique em salvar para aprimorarmos a estimava de lucro.
              </Typography>
            </Grid>
          </Grid>
        )}
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
    </>
  );
}
