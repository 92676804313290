import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CardProfile from './components/CardProfile';
import ProfileOwner from './components/ProfileText/ProfileOwner';
import ProfileManager from './components/ProfileText/ProfileManager';
import ProfileOperacional from './components/ProfileText/ProfileOperacional';
import RegisterUserProfileDialog from './components/RegisterUser/RegisterUserProfileDialog';
import ConsultUserProfileDraggableDialog from './components/ConsultUserProfile/ConsultUserProfileDraggableDialog';
import Loading from '../../components/Loader/Loading';
import GroupsIcon from '@mui/icons-material/Groups';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AttributionIcon from '@mui/icons-material/Attribution';
import FirstAcessTeam from './components/Tutorial/FirstAcessTeam';
import useStore from '../../store/useStore';
import { Context } from '../../components/Context/AuthContext';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import TutorialAcessTeam from './components/Tutorial/TutorialAcessTeam';
/* MODULOS
  1 users
  2 property
  3 propertyarea
  4 areamap
  5 upload
  6 messenger
  7 roles
  8 permissions

ROLES
  1 proprietario
  2 gerente
  3 financeiro
  4 operacional
  5 consultor
  6 agronomo
  7 veterinario
  8 estoque
*/

export default function TeamContent() {
  const { aux_add } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(true);

  const usestore = useStore();
  //const data = usestore.reposUser.listUser;
  const isLoading = false;

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    setData(usestore.reposUser.listUser);
  }, [loading, aux_add]);

  function getCardRole(array, role, setLoading) {
    const array_card = array.filter((user) => user.id_role == role) || [];
    return array_card.map((user) => (
      <>
        <div>
          <CardProfile key={user.id} edit_user={user} setLoading={setLoading} />
        </div>
      </>
    ));
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="content-wrapper bg-white">
        <div className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={4} md={6}>
                <Box sx={{ display: 'flex' }}>
                  <GroupsIcon sx={{ fontSize: 35 }} color="text.secondary" />
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                    <Typography fontWeight="500" variant="h6">
                      Equipe
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                      Registro&nbsp;da&nbsp;equipe&nbsp;de&nbsp;trabalho&nbsp;da&nbsp;propriedade
                    </Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={8} md={4}>
                <RegisterUserProfileDialog setLoading={setLoading} />
              </Grid>
              <Grid item xs={2} md={2}>
                <Box display="flex" justifyContent="flex-end">
                  <TutorialAcessTeam />
                </Box>
              </Grid>

              <Grid item xs={12} md={12} alignItems="center">
                <Card sx={{ p: 3 }}>
                  {true ? (
                    <Typewriter text={`Clique em "ADICONAR EQUIPE", e registre a equipe de trabalho da propriedade.`} variant={'subtitle1'} color={'#2e7d32'} />
                  ) : (
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                      {' '}
                      <ThreeDotsSpinner />
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        <>{data.length == 1 && <FirstAcessTeam />}</>
        <div className="content ">
          <div className="container-fluid">
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={8}>
                <Card>
                  <Divider />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <CardContent>
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Proprietário
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 1, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Gerente
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 2, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Operacional
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 4, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                    </CardContent>
                  )}
                </Card>
                <Card>
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex' }}>
                        <SupervisorAccountIcon style={{ fontSize: 30 }} color="text.secondary" />
                        <Stack spacing={0.5} sx={{ ml: 1 }}>
                          <Typography fontWeight="500" variant="h6">
                            Equipe de Escritório
                          </Typography>
                        </Stack>
                      </Box>
                    }
                  />
                  <Divider />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <CardContent>
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Financeiro
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 3, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Estoque
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 8, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                    </CardContent>
                  )}
                </Card>
                <Card>
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex' }}>
                        <AttributionIcon style={{ fontSize: 30 }} color="text.secondary" />
                        <Stack spacing={0.5} sx={{ ml: 1 }}>
                          <Typography fontWeight="500" variant="h6">
                            Equipe Complementar
                          </Typography>
                        </Stack>
                      </Box>
                    }
                  />
                  <Divider />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <CardContent>
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Veterinário
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 7, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Agrônomo
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 6, setLoading)}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container>
                        <Typography fontWeight="400" variant="subtitle1" component="h2">
                          Consultor
                        </Typography>
                        <Grid container justifyContent="center" spacing={1}>
                          {getCardRole(data, 5, setLoading)}
                        </Grid>
                      </Grid>
                    </CardContent>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardHeader
                    action={
                      <div>
                        <ConsultUserProfileDraggableDialog />
                      </div>
                    }
                    title="Perfil"
                  />
                  <Divider />
                  <CardContent>
                    <div className="">
                      <ProfileOwner />
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Administração completa do sistema (Gerente, financeiro, operacional)
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Responsável pelas definições de planejamento com metas definidas
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora todas as propriedades
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla a assinatura
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Delimita o mapa da propriedade
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Organiza a equipe, adicionando pessoas e definindo perfis
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Coordenda os esforços, comunica e excluí as solicitaçoes/tarefas
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora todas as criação
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora todas as safras
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o estoque
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o banco de imagens
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o patrimônio da propriedade
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza todos os relatórios e indicadores
                      </Box>
                    </div>
                    <div className="mt-3">
                      <ProfileManager />
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Gerencia o mapa da propriedade, organizando as criações e as safras
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controle e monitora, as informações gerais e as metas predefinidas no painel de controle
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controle e monitora todas atividades no painel de controle
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Organiza a equipe, adicionando pessoas e definindo perfis
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Gerencia total de uma ou mais criações
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Gerencia total de uma ou mais safras
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Coordenda os esforços, comunicando e acompanhando o andamento das as solicitaçoes/tarefas
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora a produção
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o estoque
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza os relatórios: Planejamento de Atividades, Realização de Atividades, Solicitações.
                      </Box>
                    </div>
                    <div className="mt-3">
                      <Typography fontWeight="500" variant="subtitle1" component="h2">
                        Financeiro: nível tático
                      </Typography>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza o mapa da propriedade (as criações, as safras, as solicitações/tarefas e a previsão do tempo)
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza as informações gerais, criaçãos/safras atuais e as metas predefinidas no painel de controle
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza a equipe de trabalho
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora as contas a pagar e receber e o fluxo de caixa
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o custo de produção
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o estoque (entradas e saídas)
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora a produção e a venda
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Controla e monitora o patrimônio da propriedade
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiz os indicadores: Cultivares, Produtividade e Produção.
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiz os relatórios: Insumos, Contas, Parcelas, Movimentações de estoque, Estoque, Silos e Patrimônio.
                      </Box>
                    </div>
                    <div className="mt-3 ">
                      <ProfileOperacional />
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza o mapa da propriedade (as criações, as safras, as solicitações/tarefas e a previsão do tempo)
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza as informações gerais, criaçãos/safras atuais e as metas predefinidas no painel de controle
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza a equipe de trabalho
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza as criações
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza as safras
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza, comunica e acompanha o andamento das solicitaçoes/tarefas
                      </Box>
                      <Box sx={{ fontSize: 12 }}>
                        <CheckIcon style={{ fontSize: 18 }} /> Visualiza os relatórios: Planejamento de Atividades, Realização de Atividades, Solicitações.
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
