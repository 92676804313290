import React from "react";

const IAIconWhite = ({ size }) => {
  const iconStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: size,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.4)", // Adicionando sombra
    height: size,
    fontSize: Math.floor(size * 0.4),
    textDecoration: "underline",
  };

  const underlineStyle = {
    textDecorationColor: "white",
  };

  return (
    <div style={iconStyle}>
      <span style={underlineStyle}>
        <strong>IA</strong>
      </span>
    </div>
  );
};

export default IAIconWhite;
