import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Assignment } from '@mui/icons-material'
import WidgetsIcon from '@mui/icons-material/Widgets'
import api from '../../../../services/api'
import useStore from '../../../../store/useStore'
import { Context } from '../../../../components/Context/AuthContext'

export default function QuantityStock({ open, handleClose, dataStock, setDataStock }) {
  // console.log('QuantityStockxxxx')
  // console.log(dataStock)

  const handleChange = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    setDataStock((prevData) => ({
      ...prevData,
      [name]: Number(value),
    }))
  }

  // useEffect(() => {
  //   setDataStock((prevData) => ({
  //     ...prevData,
  //     id_product: dataStock.id,
  //     value_cost: editStockProduct.stock_cost,
  //     id_account: editStockProduct.id_account,
  //   }))
  // }, [editStockProduct])

  // const validate = () => {
  //   let valid = true
  //   console.log(dataStock.value_cost)
  //   if (dataStock.value_cost == '0,00') {
  //     valid = false
  //   }
  //   if (dataStock.quanty == 0 || dataStock.quanty == '') {
  //     valid = false
  //   }
  //   return valid
  // }

  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  const property_id = localStorage.getItem('Property') || 1

  const handleSubmit = async () => {
    // console.log('handleSubmit -StockEntry')
    //let formOk = validate() || false

    // console.log(dataStock)

    if (true) {
      const updateQuantityStock = {
        id_property: dataStock.id_property,
        id_product: dataStock.id_product,
        quanty_min: dataStock.quanty_min,
        quanty_max: dataStock.quanty_max,
      }
      const header = { header: { 'Content-Type': 'application/json' } }
      const res = await api
        .put('inventory/updatecontrolquantity', updateQuantityStock, header)
        .then((response) => {
          // console.log('response.dataxxx')
          // console.log(response.data)
          // console.log(response.data.isSuccess)
          if (response.data.isSuccess) {
            toast.success('Controle de quantidade de estoque atualizado')

            //resetForm()
            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao atualizar o estoque, tente novamente mais tarde!')
        })
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!')
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle>Alerta de quantidade no estoque</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContentText>
              {/* Aqui você pode adicionar as informações do pedido */}
              {/* Exemplo: Pedido Número: {selectedRow?.id} */}
            </DialogContentText>
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* NOME */}
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <WidgetsIcon sx={{ fontSize: 20, mr: 1 }} color="text.secondary" />
                    <Stack spacing={0.5}>{dataStock?.name && <Typography fontWeight={700}>{dataStock?.name.toUpperCase()}</Typography>}</Stack>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <TextField
                    required
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    id="quanty_min"
                    name="quanty_min"
                    label={'Estoque mínimo'}
                    fullWidth
                    autoComplete="qnt_disponivel"
                    variant="outlined"
                    color="success"
                    value={dataStock.quanty_min}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Assignment />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    required
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    id="quanty_max"
                    name="quanty_max"
                    label={'Estoque máximo'}
                    fullWidth
                    autoComplete="estoque_max"
                    variant="outlined"
                    color="success"
                    value={dataStock.quanty_max}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Assignment />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
      {/* Adicione botões de ação se necessário */}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={handleSubmit} variant="contained" color="success">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
