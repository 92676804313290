import {
    TextField,
    Grid,
    FormHelperText
} from '@mui/material';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import ProfileSelectCheckbox from "./ProfileSelectCheckbox"
import * as Yup from 'yup';
import FarmSelect from './FarmSelect';
import { useEffect, useState } from 'react';

const PersonalInfo = (props) => {
    const { formik } = props;
    const { values } = formik;
    const [erro_farm, setFarmError] = useState(formik.errors.farm);

    useEffect(() => {
        //console.log(formik);
    }, [erro_farm]);

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sx={{ mt: -5 }} >
                <Alert severity="success"><AlertTitle>Estamos quase lá...</AlertTitle></Alert>
                {/* <Typography variant="subtitle1" >
                    <strong>Complete abaixo o seu nome e o perfil desejado</strong>
                </Typography> */}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }} >
                    Selecione a(s) fazenda(s) que deseja incluir o <strong>{values.name}</strong>:
                </Typography>
                <FarmSelect formik={formik} setFarmError={setFarmError} />
                {formik.errors.farm && (
                    <FormHelperText error>
                        {formik.errors.farm}
                    </FormHelperText>
                )}
            </Grid>
            <Grid item xs={12} >
                <Typography variant="subtitle1" >
                    <strong>Selecione um perfil</strong>
                </Typography>
                <ProfileSelectCheckbox formik={formik} />
                {formik.errors.id_role && (
                    <FormHelperText error>
                        {formik.errors.id_role}
                    </FormHelperText>
                )}
            </Grid>
            {/* <Grid
                item
                xs={6}
            >
                <TextField
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastNamel)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
            </Grid> */}
            {/* <Grid
                item
                xs={12}
            >
                <TextField
                    name="phone"
                    label="Phone Number"
                    variant="outlined"
                    type="phone"
                    fullWidth
                    size="small"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                />
            </Grid> */}
            {/* <Grid
                item
                xs={12}
            >
                <TextField
                    name="residence"
                    label="Residence"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.residence}
                    onChange={formik.handleChange}
                    error={formik.touched.residence && Boolean(formik.errors.residence)}
                    helperText={formik.touched.residence && formik.errors.residence}
                />
            </Grid> */}
        </Grid >
    )
}

export default PersonalInfo