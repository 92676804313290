import React, { useEffect, useRef, useState } from 'react';
import { Box, CardMedia, Divider, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ChartBarHActvSoul from '../../../../../components/Charts/ChartBarHActvSoul';

export default function FormSoilPreparationStep2({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 2000);
    return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
  }, []); // Executa apenas uma vez, após o primeiro render

  // const para validar o button continuar
  const isError = () => {
    // Verifica se há áreas e se os valores de área usada são válidos
    return data.areas.length === 0 || data.areas.some((area) => area.usedArea > area.area);
  };

  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  const opt_areas = {
    multiple: true,
    name: 'areas',
    label: 'Áreas da Safra',
    values: optionsAreas,
    field: 'areas',
    variant: 'standard',
    helperText: 'Selecione a(s) área(s) para o preparo do solo',
    listHeight: 225,
  };
  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.product) {
          area.product = null;
        }
        return area;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handleChangeInput = (e, area, isPercentage) => {
    const value = parseFloat(e.target.value);
    if (isNaN(value) || value < 0) return; // Evita valores inválidos

    const usedArea = isPercentage ? Math.min((value * area.area) / 100, area.area) : Math.min(value, area.area);

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => (area_prev.id === area.id ? { ...area_prev, usedArea } : area_prev)),
    }));
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) do preparo do solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.areas} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                  <Typography variant="subtitle1" display="block" gutterBottom>
                    Defina a área que será utlizada para o preparo do solo:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                  {showText ? (
                    <Typewriter
                      text={`Por padrão, definimos a área total como a quantidade de área (${area.measure}) onde o preparo do solo será realizado.`}
                      variant={'subtitle1'}
                      color={'#2e7d32'}
                    />
                  ) : (
                    <ThreeDotsSpinner />
                  )}
                </Grid>

                <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 260 }} image={area.url} alt={area.name} />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ ml: 1.5 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Área: <strong>{area.name}</strong>
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        <i className="fa fa-map text-success">
                          {' '}
                          Totalidade: {area.area} ({area.measure})
                        </i>
                      </Typography>
                      <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          <TextField
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-hectares'}
                            name={area.name}
                            label={'Área utilizada (ha):'}
                            variant="standard"
                            size="small"
                            value={area?.areas_used ? area?.areas_used[0].areaUsed : area?.area}
                            // value={area.usedArea ? area.usedArea : area.area}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{area.measure}</InputAdornment>,
                            }}
                            onChange={(e) => handleChangeInput(e, area, false)}
                            // helperText={area.usedArea > area.area ? 'O valor não pode ser maior que a área total.' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          <TextField
                            // disabled
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-percentage'}
                            name={area.name}
                            label={'Área utilizada (%):'}
                            variant="standard"
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={area.usedArea ? (area.usedArea / area.area) * 100 : 100}
                            onChange={(e) => handleChangeInput(e, area, true)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                          <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                  <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: '#2e7d32' }}>
                    Clique abaixo em continuar
                  </Typography>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
