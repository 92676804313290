import { SvgIcon } from '@mui/material';
import React from 'react';

export default function NubankIconBg(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 954.12 526.41">
            <path d="M172.23,50.21C202.4,18.52,242.43,0,289.44,0c91.6,0,152.33,66.88,164,167.06,3.77,32.54,3.73,78.41,3.69,131.54V514.2h-126V367s-.25-126-1-149.54C326.71,114.9,266,50.46,172.22,50.39c-28.29,29.89-43.45,66.44-45.69,122.32-.3
        ,7.79-.23,35.42-.12,71.83,0,18.86.11,40.09.12,62.13.1,96,0,207.55,0,207.55H.57V287.67c0-7.77-.14-15.59-.28-23.45C0,248.4-.29,232.47.57,216.7c1.42-26.3,6-52.16,18.24-76.17,28.1-55,85.65-90.45,147-90.45,2.15,0,4.29,.05,6.41,.13Z" fill="#fff" />
            <path d="M953.55,309.71c.85-15.77.57-31.71.28-47.52-.14-7.86-.28-15.68-.28-23.45V12.19h-126s-.1,111.59,0,207.55c0,22,.07,43.26.12,62.13.1,36.41.18,64,.12,71.83-2.24,55.88-17.4,92.43-45.69,122.32C688.13,476,627.41,411.51,624,309c-.77-23.55-1.09-82-1.09-149.66V12.09l-125.87.12v199q0,8.38,0,16.57c0,53.13-.07,99,3.69,131.53,11.6,100.19,72.33,167.07,163.93,167.07,47,0,87-18.52,117.21-50.22q3.18.13,6.41.13c61.36,0,118.91-35.42,147-90.44,12.28-24,16.83-49.87,18.25-76.17Z" fill="#fff" />
        </SvgIcon>

    );
}



