import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { Span } from "../../../components/Typography";
import FormGoalPlanning from "./FormGoalPlanning";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormGoalButtonPlanning() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="success" startIcon={<i className="fa fa-trophy text-success mr-1" />} onClick={handleClickOpen}>
        Adiconar&nbsp;Meta
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Definição de Meta para a criação
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <FormGoalPlanning handleClose={handleClose} />
          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="contained" type="submit" onClick={handleClose}>
                  <Span>Enviar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Dialog>
    </div>
  );
}
