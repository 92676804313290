import { Button } from '@mui/material';
import { DrawingManager, GoogleMap, InfoWindow, Marker, Polygon } from '@react-google-maps/api'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LoadScriptOnlyIfNeeded from '../../../../LoadScript/LoadScriptOnlyIfNeeded'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Loading from '../../../../components/Loader/Loading';
import { decode as base64_decode, encode as base64_encode } from "base-64";
import usePropertyReposStore from '../../../../store/usePropertyReposStore';
const libraries = ["places", "drawing"];
const apiKey = "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU";

export const MapFormObservationEdit = ({ message }) => {
  const [isLoading, setLoading] = useState(true);
  const [centerInit, setcenterInit] = useState({});

  //  verificar se exite a latitude da propriedade
  const reposProperty = usePropertyReposStore(state => state);
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];
  useEffect(() => {
    setcenterInit({
      lat: Number(property_data?.latitude || '-20.9230016'),
      lng: Number(property_data?.longitude || '-42.7960554'),
      address: property_data?.address || property_data?.address_area || "",
      zoom: Number(property_data?.zoom || '14'),
    });
    setLoading(false);
  }, []);

  const [zoom, setZoom] = useState(14)

  let decoded = ""
  if (message.coordinates_decod != "") {
    decoded = message.coordinates_decod;
  } else if (message.coordinates != "") {
    decoded = JSON.parse(base64_decode(message.coordinates));
  }

  const [marker, setMarker] = useState(decoded)
  //console.log('click no mapa')

  //console.log(marker)
  localStorage.setItem('marker', JSON.stringify(marker))

  const [selected, setSelected] = useState(null)

  const mapRef = useRef(null)
  const handleLoad = map => (mapRef.current = map)

  const [drawingModeEnabled, setDrawingModeEnabled] = useState(true)

  const onLoad = drawingManager => {
    console.log(drawingManager)
  }

  const onLoadMarker = (marker) => {
    console.log("marker: ", marker);
  };

  const onClick = (onClick) => {
    console.log("onClick: ", onClick);
  };

  const id = ''

  const handleMarkerClick = (e) => {
    id = e;
    console.log(id);
  }

  const onMarkerComplete = marker => {
    console.log("marker: ", marker);
  }

  const optionsDraw = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled === true ? 'polygon' : '',
    },
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  return (
    <>
      {!isLoading ? (
        <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
          <GoogleMap
            mapContainerStyle={{
              height: "50vh",
              width: "100vw",
            }}
            center={centerInit}
            zoom={Number(zoom)}
            onLoad={handleLoad}
            ref={mapRef}
            onClick={e =>
              setMarker({
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                time: new Date()
              })
            }
          >
            {marker && marker != "" && <Marker
              key={marker.time}
              onLoad={onLoadMarker}
              position={{ lat: marker.lat, lng: marker.lng }}
              // icon={{
              //   url: "/dist/image/iconLocation.png",
              //   scaledSize: new window.google.maps.Size(40, 40),
              //   //origin: new window.google.maps.Point(0, 0),
              //   //anchor: new window.google.maps.Point(20, 20),
              // }}
              // icon={<PersonPinIcon />}
              onClick={() => {
                setSelected(marker)
              }}
            >
              {selected ? (
                <InfoWindow
                  position={{ lat: selected.lat, lng: selected.lng }}
                  //clickable={true}
                  onCloseClick={() => {
                    setSelected(null)
                  }}
                >
                  <div>
                    <h6>Solicitação</h6>
                  </div>
                </InfoWindow>) : null}
            </Marker>
            }
          </GoogleMap>
        </LoadScriptOnlyIfNeeded>) : <Loading />}
      {/* <GoogleMap
        onLoad={handleLoad}
        mapTypeId='satellite'
        mapContainerStyle={{
          height: '350px',
          width: '100%',
        }}
        options={{
          streetViewControl: true,
          fullscreenControl: false,
        }}
        zoom={zoom}
        center={{
          lat,
          lng,
        }}
      >
        <Polygon
          onClick={e =>
            setPositionObservation({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            })
          }
          visible={true}
          draggable={false}
          editable={false}
          paths={paths}
          options={{
            fillColor: 'green',
            fillOpacity: 0.7,
            strokeColor: '#53f444',
            strokeWeight: 2,
            zIndex: 1,
          }}
        />
        {Object.keys(positionObservation).length !== 0 && (
          <button
            onClick={() => setPositionObservation({})}
            className='absolute border right-2 top-2 p-1 rounded bg-white'
            type='button'
          >
            Remover marcação
          </button>
        )}
        <Marker position={Object.keys(positionObservation).length === 0 ? null : positionObservation} />
      </GoogleMap> */}
    </>
  )
}
