import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';

// create the store
const useBuysReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listBuy: [],
        fetch: async (item) => {
          const header = { header: { "Content-Type": "application/json" } };
          var id_property = (localStorage.getItem("Property") || 1);
          var ano_atual = (new Date()).getFullYear();

          if (!item) {
            item = { 'id_property': id_property, 'ano': ano_atual };
          } else {
            if (!item.id_property) {
              item.id_property = id_property;
            }
            if (!item.ano) {
              item.ano = ano_atual;
            }
          }
          const response = await api.post("buy/list", (item), header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          await set({
            listBuy:
              data_map.map((buy) => ({
                id: buy.id,
                id_property: buy.id_property,
                id_person: buy.id_person,
                person: buy.person,
                value: buy.value,
                status: buy.status,
                id_trans_entry: buy.id_trans_entry,
                date_entry: dayjs(buy.date_entry).format('YYYY-MM-DD HH:mm:ss'),
                date_entry_f: dayjs(buy.date_entry).format('DD/MM/YYYY'),
                id_trans_pay: buy.id_trans_pay,
                date_payment: buy.date_payment ? dayjs(buy.date_payment).format('YYYY-MM-DD HH:mm:ss') : '',
                date_payment_f: buy.date_payment ? dayjs(buy.date_payment).format('DD/MM/YYYY') : '',
                id_trans_inv: buy.id_trans_inv,
                date_inventory: buy.date_inventory ? dayjs(buy.date_inventory).format('YYYY-MM-DD HH:mm:ss') : '',
                date_inventory_f: buy.date_inventory ? dayjs(buy.date_inventory).format('DD/MM/YYYY') : '',
                description: buy.description,
                isDeleted: buy.isDeleted,
                info_entry: JSON.parse(buy.info_entry || '{}'),
                info_pay: JSON.parse(buy.info_pay || '{}'),
                info_inv: JSON.parse(buy.info_inv || '{}'),
                record_entry: JSON.parse(buy.record_entry || '{}'),
                record_pay: JSON.parse(buy.record_pay || '{}'),
                record_inv: JSON.parse(buy.record_inv || '{}'),
                products: JSON.parse(buy.products || '[]'),
                products_value: buy.products_value,
                lotes: JSON.parse(buy.lotes || '[]'),
                lotes_value: buy.lotes_value,
                animals: JSON.parse(buy.animals || '[]'),
                animals_value: buy.animals_value,
                patrimony: JSON.parse(buy.patrimony || '[]'),
                services: JSON.parse(buy.services || '[]'),
              }))
          })

          return data;
        },
        // pega a(s) buy(s) da listBuy de acordo com o [key e val] informados
        getBuyStore: (key, val) => {
          return get().listBuy.filter((buy) => {
            return (buy[key] === val);
          })
        },
        getCompostBuyStore: (keys) => {
          return get().listPerson.filter((buy) => {
            let count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (buy[keys[i][0]] === keys[i][1]) {
                count++;
              }
            }
            return (count === keys.length);
          })
        },
        // Adiciona uma nova buy à listBuy
        addBuyStore: (buy) => {
          set(state => ({
            listBuy: [...state.listBuy, buy]
          }))
        },
        // Gera uma nova listBuy sem a buy informada
        delBuyStore: (id) => {
          set({
            listBuy:
              get().listBuy.filter((buy) => {
                if (buy.id !== id) {
                  return buy;
                }
              })
          })
        },
        // atualiza listBuy de acordo com id, key e val informados
        updateBuyStore: (id, key, val) => {
          set({
            listBuy:
              get().listBuy.map((buy) => {
                if (buy.id === id) {
                  if (key === 'all') { // atualiza todos os dados
                    buy = val;
                  } else {
                    buy[key] = val;
                  }
                }
                return buy;
              })
          })
        },
      }),
      { name: "reposBuys" }
    )
  )
);

export default useBuysReposStore;