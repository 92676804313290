import create from "zustand"
import { persist, devtools } from "zustand/middleware"
import api from '../../services/api'

// create the store
const useCostProductionReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listCostProduction: [],
        listCostProductionLivestock: [],
        listCostProductionHarvest: [],
        fetch: async (item) => {
          const header = { header: { "Content-Type": "application/json" } }
          var id_property = (localStorage.getItem("Property") || 1)

          if (!item) {
            item = { 'id_property': id_property }
          }
          if (!item.id_property) {
            item.id_property = id_property
          }
          let data_map = [[], []]

          const response = await api.post("reports/costProduction", (item), header)
            .then((response) => {
              const data = response.data
              data_map = (data.isSuccess ? data._value : [[], []])
            })
            .catch((error) => {
              console.log("error: reports/costProduction")
              console.log(error)
            })

          let all_lists = ([].concat(data_map[0], data_map[1]))
          await set({
            listCostProduction:
              all_lists.map((cost) => {
                return {
                  id_property: cost.id_property,
                  id_projeto: cost.id_projeto,
                  name_projeto: cost.name_projeto,
                  tipo_projeto: cost.tipo_projeto,
                  data_mes: Number(cost.data_mes),
                  valor_mes: cost.valor_mes,
                  custos: JSON.parse(cost.custos || '[]'),
                }
              })
          })
          await set({
            listCostProductionHarvest:
              data_map[0].map((cost) => {
                return {
                  id_property: cost.id_property,
                  id_projeto: cost.id_projeto,
                  name_projeto: cost.name_projeto,
                  tipo_projeto: cost.tipo_projeto,
                  data_mes: Number(cost.data_mes),
                  valor_mes: cost.valor_mes,
                  custos: JSON.parse(cost.custos || '[]'),
                }
              })
          })
          await set({
            listCostProductionLivestock:
              data_map[1].map((cost) => {
                return {
                  id_property: cost.id_property,
                  id_projeto: cost.id_projeto,
                  name_projeto: cost.name_projeto,
                  tipo_projeto: cost.tipo_projeto,
                  data_mes: Number(cost.data_mes),
                  valor_mes: cost.valor_mes,
                  custos: JSON.parse(cost.custos || '[]'),
                }
              })
          })

          return data_map
        },
        // retorna a lista desejada
        getList: (list) => {
          var lista
          if (list == 'livestock') {
            lista = get().listCostProductionLivestock
          } else if (list == 'harvest') {
            lista = get().listCostProductionHarvest
          } else {
            lista = get().listCostProduction
          }

          return lista
        },
        // pega a(s) transação(ões) da lista de acordo com o [key e val] informados
        getCostProductionStore: (list, key, val) => {
          const lista = get().getList(list)

          return lista.filter((cost) => {
            if (cost[key] == val) {
              return cost
            }
          })
        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex1: [['data_diaria', 1], ['saldo', '0']] -> data_diaria = 1 e saldo = 0
        getCompostCostProductionStore: (list, keys) => {
          const lista = get().getList(list)

          return lista.filter((cost) => {
            let count = 0
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (cost[keys[i][0]] == keys[i][1]) {
                count++
              }
            }
            if (count == keys.length) {
              return cost
            }
          })
        },
      }),
      { name: "reposCostProduction" }
    )
  )
)

export default useCostProductionReposStore