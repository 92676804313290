// Função para formatar de números para valor monetario
export const formatValue = (value, str) => {
  var aux_value = value

  if (typeof value == 'string') {
    aux_value = parseFloat(value)
  } else if (!value) {
    aux_value = 0
  }
  var aux_val = aux_value.toFixed(2)
  var aux_val_f1 = aux_val.replace('.', ',')

  return (str ? 'R$ ' : '') + aux_val_f1
}