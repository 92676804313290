import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { green, grey } from '@mui/material/colors'

export default function GenericModal({ setOpen, obj_modal, open }) {
  const handleSair = () => {
    setOpen(false)
    if (obj_modal.handleClose()) {
      obj_modal.handleClose()
    }
  }
  const handleCancel = () => {
    setOpen(false)
  }
  // Types: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | string
  // const objModal = {}
  const objModal = {
    maxWidth: obj_modal.width || 'sm',
    title: obj_modal.title || 'Confirmar',
    color_title: obj_modal.color_title || green[700],
    content: obj_modal.content || (<></>),
    color_close: obj_modal.color_close || grey[700],
  }
  // console.log('GenericModal')
  // console.log(open)

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={objModal?.maxWidth} open={open} onClose={handleCancel}>
        <DialogTitle sx={{ color: objModal?.color_title }}>{objModal?.title}</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
            }}
          >
            {objModal.content}
          </Box>
        </DialogContent>
        <DialogActions sx={{ mt: '-25px', mr: '10px' }}>
          <Button onClick={handleSair} color="success" variant="outlined" sx={{ mr: '35px' }}>
            Sair
          </Button>
          <Button onClick={handleCancel} variant="outlined" sx={{ color: objModal?.color_close, borderColor: objModal?.color_close }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
