import * as React from 'react'
import { Box, Card, CardMedia, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Input, Map, Notes } from '@mui/icons-material';
import dayjs from 'dayjs';
import FooterModal from '../../../../../components/Footer/FooterModal';

export default function FormHarvestingStep2({ data, obj_fn }) {
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas e progresso da colheita</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Área(s) de colheita */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) para colheita</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12}>
                <Card key={'C1_' + area.id} sx={{ display: 'flex', padding: 1 }} id={'card_a_' + area.id}>
                  <Grid item xs={4} sm={4} sx={{ ml: 1.5, alignItems: 'center' }}>
                    <CardMedia
                      key={'CM_' + area.id}
                      component="img"
                      image={area.url}
                      alt={area.name}
                      title={area.name}
                      sx={{ ml: '20%', width: 150, height: 50, transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.5, 1.5, 1)' } }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Área: {area.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} sx={{ ml: 1.5 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      <i className="fa fa-map text-success">
                        {' '}
                        {area.area} ({area.measure})
                      </i>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Área colhida: {area.usedArea} ha
                    </Typography>
                  </Grid>
                </Card>
                <Card key={'C2_' + area.id} sx={{ display: 'flex', padding: 1 }} id={'card_a_' + area.id}>
                  {area.products.map((product, index) => (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Produto da colheita" secondary={product?.name} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <ListItem>
                            <ListItemText primary="Quantidade colhida" secondary={product?.quant_colhida + ' (' + product?.unit_sigla + ')'} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Local para estoque (Destino)" secondary={product?.location_selected?.name} />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Card>
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrições */}
        <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral da colheita
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
