import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Avatar, Box, ButtonBase, ListItemAvatar, Paper, Typography, Stack } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SpaIcon from '@mui/icons-material/Spa';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function RedirectProjectList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClickLivestock = () => {
    navigate('/livestock');
  };
  const handleButtonClickHarvest = () => {
    navigate('/harvest');
  };
  //style={{ color: "#2e7d32" }}

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <div className="d-lg-none">
        <Box>
          <Typography gutterBottom sx={{ mt: 0, mb: 1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
            <Typewriter tam={70} text="Criado a área de produção (Piquete, Talhão...) é hora de seguir para o passo 3." />
          </Typography>
        </Box>
      </div>
      <div className="d-none d-lg-block">
        <Box>
          <Typography gutterBottom sx={{ mt: 0, mb: 1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
            <Typewriter tam={40} text="Criado a área de produção (Piquete, Talhão...) é hora de seguir para o passo 3." />
          </Typography>
        </Box>
      </div>
      <ListItem
        key={null}
        disableGutters
        secondaryAction={
          <IconButton aria-label="comment">
            <CropOriginalIcon style={{ color: '#2e7d32' }} />
          </IconButton>
        }
      >
        <ListItemText primary={<strong>Passo 3:</strong>} secondary="Registrar sua primeira safra ou criação." />
      </ListItem>
      <Typography paragraph variant="subtitile1" fontWeight={500} sx={{ mt: 1, mb: 2 }}>
        Clique no botão criações para criarmos o projeto para os seus animais ou safras para o projeto da sua cultura.
      </Typography>
      <Box style={{ display: 'flex' }}>
        <ButtonBase
          component={Paper}
          elevation={2}
          sx={{ p: 2, borderRadius: 1, margin: '0 10px', animation: `${expandAndFade} 2s linear infinite` }}
          onClick={() => handleButtonClickLivestock()}
          className={classes.button}
        >
          <Stack direction="column" alignItems="center">
            <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              Criações
            </Typography>
          </Stack>
        </ButtonBase>
        <ButtonBase
          component={Paper}
          elevation={2}
          sx={{ p: 2, borderRadius: 1, margin: '0 10px', animation: `${expandAndFade} 2s linear infinite` }}
          onClick={() => handleButtonClickHarvest()}
          className={classes.button}
        >
          <Stack direction="column" alignItems="center">
            <SpaIcon />
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              Safras
            </Typography>
          </Stack>
        </ButtonBase>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography paragraph variant="subtitile2" fontWeight={300}>
          Caso queira inserir novas áreas, clique em fechar e depois clique no botão "CRIAR ÁREAS".
        </Typography>
      </Box>
    </List>
  );
}
