import { Autocomplete, Box, TextField } from "@mui/material";
{
  /* <Grid item xs={12} sm={12} className="d-lg-none">Esconde em telas maiores que lg</Grid>
<Grid item className="d-none d-lg-block">Esconde em telas menores que lg</Grid> */
}

function SelectProperty({ value, options, setValue, widthx }) {
  var option_autocomplete = options.map((repos) => ({ label: repos.name, id: repos.id }));
  return (
    <>
      <Box className="d-lg-none">
        <Autocomplete
          disableClearable
          freeSolo
          size="small"
          filterSelectedOptions
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={option_autocomplete}
          onChange={(event, newValue) => {
            setValue(newValue, "selectProperty");
          }}
          sx={{ width: widthx }}
          id="selectProperty"
          name="selectProperty"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Propriedade"
              color="success"
              placeholder="Fazendas"
              focused
              InputProps={{
                ...params.InputProps,
                startAdornment: <strong> </strong>,
              }}
            />
          )}
          noOptionsText="Sem resultados"
        />
      </Box>
      <Box className="d-none d-lg-block">
        <Autocomplete
          disableClearable
          freeSolo
          size="small"
          filterSelectedOptions
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={option_autocomplete}
          onChange={(event, newValue) => {
            setValue(newValue, "selectProperty");
          }}
          sx={{ width: 250 }}
          id="selectProperty"
          name="selectProperty"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Propriedade"
              color="success"
              placeholder="Fazendas"
              focused
              InputProps={{
                ...params.InputProps,
                startAdornment: <strong> </strong>,
              }}
            />
          )}
          noOptionsText="Sem resultados"
        />
      </Box>
    </>
  );
}

export default SelectProperty;
