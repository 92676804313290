import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Drawer,
  FormControl,
  FormControlLabel,
  // FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  // OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import { Assignment, ExpandMore, Input, Notes } from '@mui/icons-material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';
import { toast } from 'react-toastify';
import AdjustIcon from '@mui/icons-material/Adjust'; // Ícone padrão para opções
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Novo ícone para adicionar
// import CurrencyTextField from '../../../../components/Fields/CurrencyTextField';
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal';
import FooterModalControlled from '../../../../components/Footer/FooterModalControlled';
// import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore';
// import { format } from 'date-fns';
import TitleIcon from '@mui/icons-material/Title';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ImageUploadComponent from '../../../../components/Fields/ImageUploadComponent';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GenericValueField from '../../../../components/Fields/GenericValueField';

export default function FormProductStep3({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  // const [category, setCategory] = useState(!obj_fn.filters ? obj_fn.usestore.reposProducts.listCategory : obj_fn.usestore.reposProducts.getCategoryStoreMultiple(obj_fn.filters));
  // const [unit, setUnit] = useState(obj_fn.usestore.reposProducts.listUnits);
  // const [packaging, setPackaging] = useState(obj_fn.usestore.reposProducts.listPackaging);
  const category = !obj_fn.filters ? obj_fn.usestore.reposProducts.listCategory : obj_fn.usestore.reposProducts.getCategoryStoreMultiple(obj_fn.filters);
  const unit = obj_fn.usestore.reposProducts.listUnits;
  const packaging = obj_fn.usestore.reposProducts.listPackaging;

  // console.log(category);
  // console.log(unit);

  const { aux_add } = React.useContext(Context);

  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  useEffect(() => {
    obj_fn.setData((prevState) => ({ ...prevState, activeList: false }));
  }, []);

  const handleChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));

    if (field === 'valor_produto') {
      obj_fn.setData((prevState) => ({ ...prevState, custo_medio: value }));
    }

    if (field === 'typePerson') {
      if (value === 'Jurídica') {
        obj_fn.setData((prevState) => ({ ...prevState, document_type: 'CNPJ' }));
      } else if (value === 'Física') {
        obj_fn.setData((prevState) => ({ ...prevState, document_type: 'CPF' }));
      }
    }
  };

  // cadastro rapido:
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const [selectedValue, setSelectedValue] = useState({ id: 0, name: '' });
  // const [selectedValue, setSelectedValue] = useState({
  //   id: 33,
  //   name: 'Outros',
  // });

  // Organizar a lista de categorias em ordem alfabética
  const categorySorted = category.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Convertendo para maiúsculas para garantir uma comparação sem distinção entre maiúsculas e minúsculas
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0; // Retorna 0 se os nomes forem iguais
  });

  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  const categoryWithNewOption = [
    { id: 'new', name: 'Adicionar nova categoria' }, // Item especial para abrir o modal
    ...categorySorted,
  ];

  // console.log(categoryWithNewOption);
  //Ajustar o Manipulador onChange:erificar se o item especial foi selecionado

  const handleCategoryChange = (event, value) => {
    // console.log('handleCategoryChange');
    // console.log(value);
    if (value) {
      const selectedCategory = value;
      // console.log(selectedCategory);
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_category: selectedCategory.id,
        category_name: selectedCategory.name,
      }));
    }
  };

  const handleUnitChange = (event, value) => {
    // Verifica se o evento é um evento de Autocomplete ou um evento de input tradicional
    // console.log(event.target, value);
    if (value !== null && value !== undefined) {
      // Tratamento para o Autocomplete, assumindo que newValue é o objeto selecionado
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_unit: value.id,
        unit_name: value.name,
        unit_sigla: value.sigla,
      }));
    } else {
      // Tratamento para o Autocomplete
      // Supondo que newValue seja o objeto selecionado e você deseja atualizar o estado com o id do objeto
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_unit: '',
      }));
    }
  };

  const handlePackagingChange = (event, value) => {
    // Verifica se o evento é um evento de Autocomplete ou um evento de input tradicional
    // console.log(event.target, value);
    if (value !== null && value !== undefined) {
      // Tratamento para o Autocomplete, assumindo que newValue é o objeto selecionado
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_packaging: value.id,
        packaging_name: value.name,
      }));
    } else {
      // Tratamento para o Autocomplete
      // Supondo que newValue seja o objeto selecionado e você deseja atualizar o estado com o id do objeto
      obj_fn.setData((prevState) => ({
        ...prevState,
        packaging: '',
      }));
    }
  };

  // options da lista de patrimonio:
  const property_id = Number(localStorage.getItem('Property')) || 1;
  // console.log('reposPatrimony ');
  // const reposPatrimony = usePatrimonyReposStore((state) => state);
  //console.log(reposPatrimony)
  // console.log(reposPatrimony.listPatrimony);

  const reposPatrimonyFilter = obj_fn.usestore.reposPatrimony.listPatrimony.filter((item) => {
    return (item.group === 'Unidade Armazenadora' || item.group === 'Estruturas e Construções') && item.id_property === property_id;
  });
  // console.log(reposPatrimonyFilter);

  // const [listStockLocation, setListStockLocation] = useState(reposPatrimonyFilter);
  // console.log(listStockLocation);

  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  const patrimonyWithNewOption = [
    { id: 'new', name: 'Adicionar novo patrimônio' }, // Item especial para abrir o modal
    ...reposPatrimonyFilter,
    // ...listStockLocation,
  ];

  // cadastro rapido location:
  const [dialogLocationOpen, setDialogLocationOpen] = useState(false);

  const toggleDialogLocation = () => {
    setDialogLocationOpen(!dialogLocationOpen);
  };

  // const initValue = {
  //   id: 1,
  //   name: 'Galpão principal',
  //   id_patrimony_type: 3,
  //   id_property: 1,
  //   patrimony_group_icon: 'WarehouseIcon',
  //   patrimony_group_id: 2,
  //   tipo_repos: 'Patrimony',
  //   type: 'Armazém/Galpão',
  // }
  //console.log(initValue)

  const [selectedValueLocation, setSelectedValueLocation] = useState(reposPatrimonyFilter[0]);
  //const [showAddNewLocation, setShowAddNewLocation] = useState(true)

  // console.log('setSelectedValueLocation');
  // console.log(selectedValueLocation);

  const handleChangePatrimony = (event, value) => {
    // console.log('handleChangePatrimony');
    // console.log(value);
    if (value) {
      const setSelectedValueLocation = value;
      // console.log(setSelectedValueLocation);
      obj_fn.setData((prevState) => ({
        ...prevState,
        location: setSelectedValueLocation.id,
      }));
    }
  };

  // const handleAddNewLocation = () => {
  //   setSelectedValueLocation(null);
  //   //setShowAddNewLocation(false)
  //   // Aqui você pode colocar a lógica para adicionar um novo local
  //   // console.log('Adicionar novo local');
  // };

  // const para validar o button continuar
  const isError = () => {
    let iserro = false;
    if (!data.name || !data.id_unit || !data.id_category || data.description.length >= 500 || data.observacoes.length >= 500) {
      iserro = true;
    }

    return iserro;
  };

  const returnError = () => {
    return false;
  };

  // const fileSlectedHandler = (event) => {
  //   console.log(event.target.files[0])

  //   var uploadedFile = {
  //     file: event.target.files[0],
  //     id: 1,
  //     name: event.target.files[0].name,
  //     size: event.target.files[0].size,
  //     type: event.target.files[0].type,
  //     preview: URL.createObjectURL(event.target.files[0]),
  //     progress: 0,
  //     uploaded: false,
  //     error: false,
  //     url: null,
  //   }
  //   //console.log(uploadedFile)
  // }

  // console.log(' fimm');
  // console.log(data);

  useEffect(() => {
    if (selectedValue?.id !== 0) {
      setSelectedValue({
        id: data.id_category,
        name: data.category_name,
      });
    }
  }, [aux_add]);

  // useEffect(() => {
  // console.log(selectedValueLocation);
  // if (selectedValueLocation.id !== 0) {
  //   setSelectedValueLocation({
  //     group: data.group,
  //     id: data.id,
  //     id_patrimony_grid: undefined,
  //     id_patrimony_type: data.id_patrimony_type,
  //     id_property: data.id_property,
  //     name: data.name,
  //     patrimony_group_icon: data.patrimony_group_icon,
  //     patrimony_group_id: data.patrimony_group_id,
  //     type: data.type,
  //   })
  // }
  // }, [aux_add]);

  //console.log('setSelectedValueLocation')
  //console.log(selectedValueLocation)

  // TODO: LIMITAR OBSERVAÇÕES E DESCRIÇÃO A 500 CATACTERES

  // imagem
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageDecode, setImageDecode] = useState(data.image);

  const handleImageUpload = (base64Image) => {
    setLoadingImage(true); // Inicia o indicador de carregamento
    obj_fn.setData((prevData) => ({
      ...prevData,
      image: base64Image,
    }));
    setImageDecode(base64Image);
    setTimeout(() => {
      setLoadingImage(false); // Finaliza o indicador de carregamento após o processamento da imagem
    }, 1000);
  };

  const handleDeleteImage = () => {
    setImageDecode(null); // Isso remove a imagem do estado
    obj_fn.setData((prevData) => ({ ...prevData, image: null }));
  };

  // console.log('fim');
  // console.log(data);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  const handleChangeNameProduct = (event, flag) => {
    event.preventDefault();
    const { name, value } = event.target;
    // console.log('handleChangeInput');
    // console.log(name);
    // console.log(value);

    //recebe o estado anterior como um parâmetro e retorna o novo estado
    obj_fn.setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(data);

  return (
    <>
      <>
        <div className="d-lg-none">
          <Grid item xs={12} md={12} alignItems="center" style={{ height: 120 }} sx={{ mt: -4, mb: 4 }}>
            <Box display="flex" alignItems="center">
              {!showTextFase ? (
                <ThreeDotsSpinner />
              ) : (
                <Typewriter
                  text="Registre os produtos mais usados na propriedade para automatizar os processos futuros de compra e venda, proporcionando eficiência e praticidade."
                  variant={'subtitle1'}
                  color={'#2e7d32'}
                />
              )}
            </Box>
          </Grid>
        </div>

        <div className="d-none d-lg-block">
          <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }} sx={{ mt: -4, mb: 4 }}>
            <Box display="flex" alignItems="center">
              {!showTextFase ? (
                <ThreeDotsSpinner />
              ) : (
                <Typewriter
                  text="Registre os produtos mais usados na propriedade para automatizar os processos futuros de compra e venda, proporcionando eficiência e praticidade."
                  variant={'subtitle1'}
                  color={'#2e7d32'}
                />
              )}
            </Box>
          </Grid>
        </div>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Input sx={{ mr: 1 }} />
                Informações do produto
              </Typography>
            </Stack>
          </Box>
        </Grid>

        {/* Informações gerais */}
        <Grid container spacing={4} sx={{ mb: 2, mt: 2 }}>
          {/* NOME */}
          <Grid item xs={12} sm={2}>
            <div>
              <Card>
                <CardContent>
                  <Grid container spacing={4} sx={{}}>
                    <Grid item xs={12} sm={12}>
                      <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                        {data.category_name ? data.category_name.toUpperCase() : 'PRODUTO'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                        <strong>{data.name ? data.name.toUpperCase() : 'Nome do produto'}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                      <Typography variant="body2" style={{ textAlign: 'center' }}>
                        {data.packaging_name === 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume} ${data?.unit_sigla}`}
                      </Typography>
                      <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                        <strong>{data.brand ? data.brand : 'marca'}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={4} sx={{ mb: 2 }}>
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  focused
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="name"
                  name="name"
                  label={'Nome do produto'}
                  fullWidth
                  //autoComplete="name"
                  variant="standard"
                  color="success"
                  value={data.name}
                  onChange={(e) => handleChangeNameProduct(e, true)}
                  //onBlur={(e) => handleChangeNameProduct(e, true)}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <IconButton>
                  //         <Assignment />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  //error={!data.name || data.name.length < 5}
                  FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                  helperText={'* Mínimo de 3 caracteres'}
                />
              </Grid>

              {/* CATEGORIA */}
              <Grid item xs={6} md={6}>
                <Autocomplete
                  name="category_name"
                  onChange={(event, newValue) => {
                    if (newValue && newValue.id === 'new') {
                      // Abre o diálogo para adicionar um novo tipo de patrimônio
                      toggleDialog(true);
                    } else {
                      // console.log(event, newValue);
                      setSelectedValue(newValue); // Atualize para usar setSelectedValue
                      handleCategoryChange(event, newValue);
                    }
                  }}
                  //onChange={handleCategoryChange}
                  disableCloseOnSelect={false}
                  filterSelectedOptions
                  color="success"
                  id={category.id}
                  value={selectedValue} // Certifique-se de que selectedValue é atualizado corretamente
                  multiple={false}
                  options={categoryWithNewOption}
                  ListboxProps={{ style: { maxHeight: 225 } }}
                  isOptionEqualToValue={(option, val) => option.id === val.id || (data.id_category && data.id_category?.id === option.id)}
                  // value={data.id_category}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    // <li {...props}>
                    //   <Adjust color="success" sx={{ mr: 1, width: '15px' }} /> {option.name}
                    // </li>
                    <li
                      {...props}
                      style={
                        option.id === 'new'
                          ? {
                              backgroundColor: '#f0f0f0',
                              color: '#007bff',
                              fontWeight: 'bold',
                            }
                          : {}
                      }
                    >
                      {option.id === 'new' ? (
                        <>
                          <AddCircleOutlineIcon
                            style={{
                              color: '#2e7d32',
                              marginRight: '8px',
                              fontSize: '15px',
                            }}
                          />
                          <Typography
                            variant="subtitle2"
                            component="div"
                            style={{
                              color: '#2e7d32',
                            }}
                          >
                            {option.name}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                            {option.name}
                            <Typography variant="body2" sx={{ marginLeft: 'auto', color: '#d3d3d3' }}>
                              ({option.group_category})
                            </Typography>
                          </Stack>
                        </>
                      )}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      // required
                      variant={'outlined'}
                      label={'Categoria'}
                      color="success"
                      InputLabelProps={{ style: { color: '#2e7d32' } }}
                      // error={data.id_category === 0}
                      FormHelperTextProps={{ style: { color: '#2e7d32' } }} // Definindo a cor do helperText como verde
                      helperText={categoryWithNewOption.length <= 1 ? 'Erro de conexão com o servidor. Não foi possível exibir as opções de categoria.' : 'Selecione o tipo de produto, sua categoria.'}
                      focused
                      fullWidth
                    />
                  )}
                  noOptionsText="Sem resultados"
                />
              </Grid>
              {/* embalagem */}
              {data?.id_category && (
                <>
                  <Grid item xs={12} md={5.5}>
                    <Autocomplete
                      onChange={(event, newValue) => handlePackagingChange(event, newValue)}
                      id="id_packaging"
                      options={packaging}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={packaging.find((option) => option.id === data.id_packaging) || null}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Unidade/Embalagem"
                          variant="outlined"
                          color="success"
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          FormHelperTextProps={{ style: { color: '#2e7d32' } }}
                          helperText={
                            packaging.length === 0 ? (
                              'Erro de conexão com o servidor. Não foi possível exibir as opções de unidade.'
                            ) : (
                              <>
                                {`O registro do produto ${data.name} será em unidade ou embalagem?`}
                                <Tooltip
                                  title={
                                    `Unidade: Selecione 'unidade' se você estiver registrando a quantidade de itens individuais, como uma única peça ou item." \n` +
                                    `Embalagens: Selecione 'embalagem' se você estiver registrando a quantidade de itens que estão agrupados ou vendidos juntos em uma única embalagem.".\n`
                                  }
                                >
                                  <HelpOutlineIcon sx={{ color: '#808080' }} />
                                </Tooltip>
                              </>
                            )
                          }
                          fullWidth
                        />
                      )}
                      ListboxProps={{ style: { maxHeight: 225 } }}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>
                  {/* volume */}
                  {data.packaging_name !== 'Unidade' && (
                    <Grid item xs={6} sm={2.5}>
                      {/* <TextField
                    required
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    id="volume_pkg"
                    name="volume_pkg"
                    label={'Volume da embalagem'}
                    fullWidth
                    autoComplete="Volume"
                    variant="standard"
                    color="success"
                    value={data.volume}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Assignment />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // error={!data.name || data.name.length < 5}
                    FormHelperTextProps={{ style: { color: '#2e7d32' } }}
                    helperText={'Volume'}
                  /> */}
                      <GenericValueField
                        handleChange={handleChange}
                        options={{
                          // required: true,
                          id: 'volume_pkg',
                          name: 'volume_pkg',
                          label: 'Volume da embalagem',
                          value: data.volume_pkg,
                          height: '36px',
                          helperText: 'Volume do produto presente na embalagem',
                          variant: 'standard',
                          endAdornment: true,
                          prefix: <Assignment />,
                          inputlabelprops: { style: { color: '#2e7d32' } },
                          formhelpertextprops: { style: { color: '#2e7d32' } },
                        }}
                      />
                    </Grid>
                  )}
                  {/* UNIDADE */}
                  <Grid item xs={6} md={data.packaging_name === 'Unidade' ? 4 : 4}>
                    <Autocomplete
                      onChange={(event, newValue) => handleUnitChange(event, newValue)}
                      id="id_unit"
                      options={unit}
                      getOptionLabel={(option) => `${option.name} (${option.sigla})`}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={unit.find((option) => option.id === data.id_unit) || null}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Medida"
                          variant="standard"
                          color="success"
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          FormHelperTextProps={{ style: { color: '#2e7d32' } }}
                          helperText={
                            unit.length === 0 ? (
                              'Erro de conexão com o servidor. Não foi possível exibir as opções de unidade.'
                            ) : (
                              <>
                                {`Selecione a unidade de medida padrão para o seu produto ${data.name}`}
                                {/* <Tooltip
                          title={
                            `Sólidos (comprimidos ou pílulas), usa-se "unidades (un)" ` +
                            ` Sólidos (graos, sementes, ingredientes) , poderíamos usar "quilos (kg)".\n` +
                            `Para líquidos,Líquidos, "mililitros
                        (ml)" ou "litros (L)" poderiam ser mais apropriados.`
                          }
                        >
                          <HelpOutlineIcon sx={{ color: '#808080' }} />
                        </Tooltip> */}
                              </>
                            )
                          }
                          fullWidth
                        />
                      )}
                      ListboxProps={{ style: { maxHeight: 225 } }}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {data?.id_category && (
            <>
              <Grid item xs={12} sm={3}>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    {!imageDecode ? (
                      <ImageUploadComponent onImageUpload={handleImageUpload} maxWidthOrHeight={'150'} setData={obj_fn.setData} type={'produto'} />
                    ) : (
                      <Card>
                        <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                          <CardHeader
                            avatar={<Avatar aria-label="image" src={`data:image/jpeg;base64,${imageDecode}`}></Avatar>}
                            title={data?.name || ''}
                            subheader={data?.group || ''}
                            action={
                              <IconButton onClick={handleDeleteImage} aria-label="delete image">
                                <DeleteForeverIcon />{' '}
                              </IconButton>
                            }
                          />
                        </Box>
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  //InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="brand"
                  name="brand"
                  label={'Marca'}
                  fullWidth
                  // autoComplete="codigo"
                  variant="outlined"
                  color="success"
                  value={data.brand}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextareaAutosize
                  minRows={3}
                  maxRows={10}
                  name="description"
                  placeholder="Descrição do produto (limite de 500 caracteres)."
                  value={data.description}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  // style={{ width: "100%", padding: "8px", border: "2px solid red" }}
                  style={data.description.length >= 500 ? { width: '100%', padding: '8px', border: '2px solid red' } : { width: '100%', padding: '8px' }}
                />
              </Grid>

              {/* Fotos */}
              {/* <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Foto</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={12} alignItems="center">
                {true ? (
                  <Box display="flex" alignItems="center">
                    <Typewriter text="A seguir insira a quantidade de estoque do produto" variant={'subtitle1'} color={'#2e7d32'} />
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <ThreeDotsSpinner />
                  </Box>
                )}
              </Grid>
              <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}> Estoque</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={2}>
                          <div>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                              <strong>
                                {data.stock_quanty} {data.packaging_name}s
                              </strong>
                            </Typography>
                            <Card>
                              <CardContent>
                                <Grid container spacing={4} sx={{}}>
                                  <Grid item xs={12} sm={12}>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                                      {data.category_name ? data.category_name.toUpperCase() : 'PRODUTO'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                                      <strong>{data.name ? data.name.toUpperCase() : 'Nome do produto'}</strong>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                                      {data.packaging_name === 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume_pkg} ${data?.unit_sigla}`}
                                    </Typography>
                                    <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                                      <strong>{data.brand ? data.brand : 'marca'}</strong>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={10} sx={{ mt: 3 }}>
                          <Grid container spacing={4} sx={{}}>
                            <Grid item xs={12} sm={12}>
                              <Autocomplete
                                name="location"
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.id === 'new') {
                                    // Abre o diálogo para adicionar um novo tipo de patrimônio
                                    toggleDialogLocation(true);
                                  } else {
                                    // console.log(event, newValue);
                                    setSelectedValueLocation(newValue); // Atualize para usar setSelectedValue
                                    handleChangePatrimony(true, newValue);
                                  }
                                }}
                                disableCloseOnSelect={false}
                                filterSelectedOptions
                                //color="success"
                                value={selectedValueLocation}
                                multiple={false}
                                options={patrimonyWithNewOption}
                                ListboxProps={{ style: { maxHeight: 225 } }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                  <MenuItem {...props}>
                                    {option.id === 'new' ? (
                                      <>
                                        <AddCircleOutlineIcon style={{ color: '#2e7d32', marginRight: '8px', fontSize: '15px' }} />
                                        <Typography variant="subtitle2" component="div" style={{ color: '#2e7d32' }}>
                                          Adicionar novo local
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                                        {option.name}
                                      </>
                                    )}
                                  </MenuItem>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Local de armazenamento"
                                    color="success"
                                    //InputLabelProps={{ style: { color: '#2e7d32' } }}
                                    helperText="Defina o local de armazenamento do produto"
                                    //focused
                                    fullWidth
                                  />
                                )}
                                noOptionsText="Sem resultados"
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'stock_quanty',
                                  name: 'stock_quanty',
                                  label: 'Quantidade disponível',
                                  value: data.stock_quanty,
                                  variant: 'outlined',
                                  startAdornment: true,
                                  prefix: <Assignment />,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} md={4}>
                              {/* <CurrencyTextField
                                //disabled
                                value={data.stock_cost}
                                label="Custo médio unitário do produto"
                                name="stock_cost"
                                onChange={handleChange}
                                helperText="Defina o valor de compra do produto"
                              /> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'stock_cost',
                                  name: 'stock_cost',
                                  label: 'Custo médio unitário',
                                  value: data.stock_cost,
                                  variant: 'outlined',
                                  helperText: 'Defina o valor de compra do produto',
                                  endAdornment: true,
                                  prefix: ' (R$) ',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <GenericValueField
                                handleChange={() => {}}
                                options={{
                                  id: 'stock_total_',
                                  name: 'stock_total',
                                  label: <span style={{ color: '#2e7d32' }}>Valor Total</span>,
                                  value: (data.stock_quanty * data.stock_cost).toFixed(2),
                                  variant: 'standard',
                                  height: '35px',
                                  helperText: <span style={{ color: '#2e7d32' }}>Valor total do produto</span>,
                                  endAdornment: true,
                                  prefix: ' (R$) ',
                                  readOnly: true, // Impede a edição direta
                                  disabled: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              {/* <TextField
                            //required
                            //InputLabelProps={{ style: { color: '#2e7d32' } }}
                            id="stock_quanty_min"
                            name="stock_quanty_min"
                            label={'Estoque mínimo'}
                            fullWidth
                            autoComplete="stock_quanty_min"
                            variant="outlined"
                            color="success"
                            value={data.stock_quanty_min}
                            onChange={handleChange}
                            onBlur={(e) => handleChange(e, true)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <Assignment />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          /> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'stock_quanty_min',
                                  name: 'stock_quanty_min',
                                  label: 'Estoque mínimo',
                                  value: data.stock_quanty_min,
                                  variant: 'outlined',
                                  startAdornment: true,
                                  prefix: <Assignment />,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              {/* <TextField
                            //required
                            //InputLabelProps={{ style: { color: '#2e7d32' } }}
                            id="stock_quanty_max"
                            name="stock_quanty_max"
                            label={'Estoque máximo'}
                            fullWidth
                            autoComplete="stock_quanty_max"
                            variant="outlined"
                            color="success"
                            value={data.estoque_max}
                            onChange={handleChange}
                            onBlur={(e) => handleChange(e, true)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <Assignment />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          /> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'stock_quanty_max',
                                  name: 'stock_quanty_max',
                                  label: 'Estoque máximo',
                                  value: data.stock_quanty_max,
                                  variant: 'outlined',
                                  startAdornment: true,
                                  prefix: <Assignment />,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              {/* Código de barra */}
              <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Código de barra</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            //required
                            //InputLabelProps={{ style: { color: '#2e7d32' } }}
                            id="cod_barra"
                            name="cod_barra"
                            label={'Código de barra'}
                            fullWidth
                            autoComplete="codigo"
                            variant="outlined"
                            color="success"
                            value={data.cod_barra}
                            onChange={handleChange}
                            onBlur={(e) => handleChange(e, true)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <Assignment />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={!data.name || data.name.length < 5}
                            //helperText={"* Mínimo de 5 caracteres"}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              {/* DESCRIÇÃO DO PRODUTO */}
              {/* <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
            <Grid item xs={12} sm={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Notes />
                  <Box sx={{ ml: 1, display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}>
                        {" "}
                        Descrição do produto
                      </Typography>
                    </Stack>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        minRows={3} // Customize the initial number of rows
                        maxRows={10} // Customize the maximum number of rows
                        name="description"
                        placeholder="Descrição do produto"
                        value={data.description}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e, true)}
                        style={{ width: "100%", padding: "8px" }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid> */}

              <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}> Observações gerais</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <TextareaAutosize
                            minRows={3}
                            maxRows={10}
                            name="observacoes"
                            placeholder="Observações Gerais (limite de 500 caracteres)."
                            value={data.observacoes}
                            onChange={handleChange}
                            onBlur={(e) => handleChange(e, true)}
                            style={
                              data.observacoes.length >= 500
                                ? {
                                    width: '100%',
                                    padding: '8px',
                                    border: '2px solid red',
                                  }
                                : { width: '100%', padding: '8px' }
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              {/* Pesos e Medidas */}
              <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                      <Notes />
                      <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight={700}>Pesos e Dimensões</Typography>
                        </Stack>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={3} sm={3}>
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <img src="/dist/image/caixa_medida.jpg" alt="Descrição da imagem" style={{ maxWidth: '60%', maxHeight: '60%' }} />
                          </Box>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                          <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="altura"
                              color="success"
                              endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              value={data.altura}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Altura</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'altura',
                                  name: 'altura',
                                  label: 'Altura',
                                  value: data.altura,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: 'cm',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="largura"
                              color="success"
                              endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              value={data.largura}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Largura</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'largura',
                                  name: 'largura',
                                  label: 'Largura',
                                  value: data.largura,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: 'cm',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="profundidade"
                              color="success"
                              endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              value={data.profundidade}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Profundidade</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'profundidade',
                                  name: 'profundidade',
                                  label: 'Profundidade',
                                  value: data.profundidade,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: 'cm',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="volume"
                              color="success"
                              endAdornment={<InputAdornment position="end"></InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              value={data.volume}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Volume</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'volume',
                                  name: 'volume',
                                  label: 'Volume',
                                  value: data.volume,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: '',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="peso_liquido"
                              color="success"
                              endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              value={data.peso_liquido}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Peso Líquido</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'peso_liquido',
                                  name: 'peso_liquido',
                                  label: 'Peso Líquido',
                                  value: data.peso_liquido,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: 'kg',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              name="peso_bruto"
                              color="success"
                              endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              alue={data.peso_bruto}
                              onChange={handleChange}
                            />
                            <FormHelperText id="outlined-weight-helper-text">Peso Bruto</FormHelperText>
                          </FormControl> */}
                              <GenericValueField
                                handleChange={handleChange}
                                options={{
                                  // required: true,
                                  id: 'peso_bruto',
                                  name: 'peso_bruto',
                                  label: 'Peso Bruto',
                                  value: data.peso_bruto,
                                  variant: 'outlined',
                                  endAdornment: true,
                                  prefix: 'kg',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              {/* Informações fiscais */}
              {/* <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={{ ml: 1, display: "flex" }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}><Input sx={{ mr: 1 }} /> Informações fiscais</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ style: { color: "#2e7d32" } }}
                      id="corporateReason"
                      name="corporateReason"
                      label="Razão social"
                      fullWidth
                      autoComplete="corporateReason"
                      variant="standard"
                      color="success"
                      value={data.corporateReason}
                      onChange={handleChange}
                      onBlur={(e) => handleChange(e, true)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <FormatAlignJustify />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText="Insira a Razão Social"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Optante pelo simples?</FormLabel>
                      <RadioGroup
                        aria-label="Optante pelo simples?"
                        name="simples_nacional"
                        value={data.simples_nacional}
                        onChange={handleChange}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value="nao" control={<Radio color="success" />} label="Não" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value="sim" control={<Radio color="success" />} label="Sim" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ style: { color: "#2e7d32" } }}
                      id="stateregistration"
                      name="stateregistration"
                      label="Inscrição estadual"
                      fullWidth
                      autoComplete="stateregistration"
                      variant="standard"
                      color="success"
                      value={data.stateregistration}
                      onChange={handleChange}
                      onBlur={(e) => handleChange(e, true)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <FormatAlignJustify />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={"Insira a Inscrição Estadual"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ style: { color: "#2e7d32" } }}
                      id="municipalRegistration"
                      name="municipalRegistration"
                      label="Inscrição municipal"
                      fullWidth
                      autoComplete="municipalRegistration"
                      variant="standard"
                      color="success"
                      value={data.municipalRegistration}
                      onChange={handleChange}
                      onBlur={(e) => handleChange(e, true)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <FormatAlignJustify />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={"Insira a Inscrição Municipal"}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid> */}
            </>
          )}
        </Grid>
      </>

      <FooterModalControlled data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      <NewSupplierDialog usestore={obj_fn.usestore} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} toggleDialog={toggleDialog} handleCategoryChange={handleCategoryChange} />
      <AddPatrimonyDialog
        usestore={obj_fn.usestore}
        dialogOpen={dialogLocationOpen}
        setDialogOpen={setDialogLocationOpen}
        toggleDialog={toggleDialogLocation}
        setSelectedValueLocation={setSelectedValueLocation}
        handleChangePatrimony={handleChangePatrimony}
      />
    </>
  );
}

function AddPatrimonyDialog({ usestore, dialogOpen, setDialogOpen, toggleDialog, setSelectedValueLocation, handleChangePatrimony }) {
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const { aux_add, setAuxAdd } = React.useContext(Context);

  var id_farm = localStorage.getItem('Property') || 1;

  // const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  // const [data, setData] = useState({
  //   name: '',
  //   group: '',
  //   //id_group: selectedGroup,
  //   model: '',
  //   type: '',
  //   manufacturer: '',
  //   manufacturing_year: '',
  //   lifespan: '',
  //   date_prev_end: formattedCurrentDate,
  //   value: '0,00',
  //   horimetro_current: 0,
  //   observation: '',
  //   image: '',
  //   // id_property: property_id
  // })

  const [data, setData] = useState({
    name: '',
    id_patrimony_type: '',
    name_patrimony_type: '',
    id_patrimony_group: '',
    name_patrimony_group: '',
  });

  const handleChangeName = (event) => {
    event.preventDefault();
    // console.log('handleChangeInput');
    // console.log(event.target);

    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  // const reposPatrimony = usePatrimonyReposStore((state) => state);
  const reposPatrimony = usestore.reposPatrimony;
  // console.log(reposPatrimony.patrimonyGroup);
  // console.log(reposPatrimony.patrimonyType);

  const filteredPatrimony = reposPatrimony?.patrimonyGroup?.filter((item) => {
    // item.id === 1 || item.id === 2
    return item.name === 'Unidade Armazenadora' || item.name === 'Estruturas e Construções';
  });
  // console.log(filteredPatrimony);
  // console.log(reposPatrimony.patrimonyType);

  const list = [];
  for (const group of filteredPatrimony) {
    // console.log(group);
    const items = reposPatrimony.patrimonyType.filter((type) => type.patrimony_group_id === group.id);
    list.push({ group: group.name, items: items }); // Adicionando o campo 'group' com o nome do grupo
  }

  // console.log(list);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleChangeSelect = (event) => {
    const selectedItem = event.target.value;
    let currentName = data.name; // Armazena o valor atual de data.name
    const selectedItemData = list.reduce(
      (acc, group) => {
        const foundItem = group.items.find((item) => item.name === selectedItem);
        if (foundItem) {
          acc.id_patrimony_type = foundItem.id;
          acc.name_patrimony_type = foundItem.name;
          acc.id_patrimony_group = foundItem.patrimony_group_id;
          acc.name_patrimony_group = group.group;
          acc.name = currentName; // Usa o valor atual de data.name
        }
        return acc;
      },
      { id_patrimony_type: '', name_patrimony_type: '', id_patrimony_group: '', name_patrimony_group: '', name: currentName }
    );

    setSelectedItem(selectedItem);
    setData(selectedItemData);
  };

  // useEffect(() => {
  //   const filteredPatrimony = reposPatrimony?.patrimonyGroup?.filter((item) => item.id === 1 || item.id === 2)
  //   console.log(filteredPatrimony)
  //   setPatrimonyGroup(filteredPatrimony)
  //   //setSelectedGroup(reposPatrimony?.patrimonyGroup[1])
  //   //setSelectedGroup(reposPatrimony?.patrimonyGroup[4])
  // }, [])

  const validatePatrimony = (data_sub) => {
    var valid = true;
    var msg = 'Por favor, preencha os campos obrigatórios!';

    if (!data_sub.id_patrimony_type) {
      valid = false;
      msg = 'Selecione um tipo para seu patrimônio';
    } else if (!data_sub.name) {
      valid = false;
      msg = 'Informe o nome do patrimônio';
    } else if (data_sub.name.length < 5) {
      valid = false;
      msg = 'Nome do patrimônio deve ter no mínimo 5 letras';
    } else {
      var aux_name = reposPatrimony.getPatrimonyStore('name', data_sub.name || '');
      if (aux_name.length > 0) {
        valid = false;
        msg = 'Já existe um patrimônio com o nome informado';
      }
    }
    return [valid, msg];
  };

  const handleSubmit = async () => {
    // const selectedType = reposPatrimony.patrimonyType.filter((item) => item.name === data.type)[0];
    // console.log(selectedType);
    const dataToSubmit = {
      id_property: Number(property_id),
      id_user: userCurrent.id,
      id_patrimony_grid: 1,
      id_patrimony_type: data.id_patrimony_type,
      patrimony_group_id: data.id_patrimony_group,
      name: data.name,
      patrimony_group: data.name_patrimony_group,
      model: '',
      patrimony_type: data.name_patrimony_type,
      manufacturer: '',
      manufacturing_year: 0,
      lifespan: 0,
      date_buy: null, // date_prev_end -> date_buy
      value: 0,
      horimetro_current: 0,
      observation: '',
      image: '',
    };
    //console.log(data)
    // console.log(dataToSubmit);

    let formOk = validatePatrimony(dataToSubmit);

    if (formOk[0]) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api
        .post('patrimony/', dataToSubmit, header)
        .then((response) => {
          // console.log('response.data-patrimony');
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Patrimônio cadastrado com sucesso!');
            // const newItemProperty = {
            //   id: response.data._value[1].insertId,
            //   name: data.name,
            // }

            const newData = {
              id: response.data._value.insertId,
              name: data.name,
              id_patrimony_type: data.id_patrimony_type,
              name_patrimony_type: data.name_patrimony_type,
              id_patrimony_group: data.id_patrimony_group,
              name_patrimony_group: data.name_patrimony_group,
            };

            // console.log(newData);

            setSelectedValueLocation(newData);
            handleChangePatrimony(true, newData);
            // resetForm()
            // setUpdateComponent((prevCounter) => prevCounter + 1);
            reposPatrimony.fetch({ id_farm: id_farm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
                setDialogOpen(false);
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar patrimônio, tente novamente mais tarde!');
        });
    } else {
      toast.error(formOk[1]);
    }
  };

  // console.log('data');
  // console.log(data);

  const handleClose = () => {
    //setOpen(false)
  };

  const content = () => (
    <Box
      sx={{ width: 300 }}
      role="presentation"
      // onClick={() => toggleDialog(false)}
      //onKeyDown={() => toggleDialog(false)}
    >
      <Container component="main" maxWidth="sm" sx={{ mb: 0, zIndex: 100 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 }, m: 2 }}>
          <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Cadastro rápido:</Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>

        <div>
          {/* <Typography variant="h5"></Typography> */}
          {/* <Typography variant="subtitle1">INFORMAÇÕES Essenciais</Typography> */}

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                fullWidth
                color="success"
                variant="standard"
                label="Nome do patrimônio"
                name="name"
                id="name"
                value={data.name}
                onChange={handleChangeName}
                //onBlur={(e) => handleChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <TitleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText="Local do novo estoque"
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <TextField
                variant="standard"
                fullWidth
                color="success"
                label="Observação"
                name="observation"
                value={data.observation}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Escolha o tipo de patrimônio</FormLabel>
                <RadioGroup value={selectedItem} onChange={handleChangeSelect}>
                  {list.map((group, index) => (
                    <div key={index}>
                      <Typography variant="body2" color="text.secondary">
                        {group.group}
                      </Typography>
                      {group.items.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={item.name}
                          control={<Radio color="success" />}
                          label={
                            <Typography variant="body2" color="text.secondary">
                              {item.name}
                            </Typography>
                          }
                          name="patrimonyType"
                        />
                      ))}
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Defina o tipo de patrimônio</FormLabel>
                <FormGroup>
                  {patrimonyGroup.map((group) => (
                    <FormControlLabel
                      key={group.id}
                      control={
                        <Checkbox
                          checked={selectedGroup && selectedGroup.id === group.id}
                          onChange={(e) => handleChangeGroup(e, group)}
                          name={group.name}
                          color="success"
                        />
                      }
                      label={group.name}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>Defina o tipo do patrimônio</FormHelperText>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              {data.group === '' ? (
                <TextField
                  label="Tipo de patrimônio"
                  select
                  required
                  fullWidth
                  variant="outlined"
                  helperText="Selecione o grupo do patrimônio primeiro"
                  disabled
                ></TextField>
              ) : (
                <TextField
                  InputLabelProps={{
                    style: { color: '#2e7d32' },
                  }}
                  id="type"
                  name="type"
                  label={`Tipo de ${data.group}`}
                  select
                  required
                  fullWidth
                  color="success"
                  value={data.type}
                  onChange={handleChange}
                  //onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                 
                          <CheckBoxOutlineBlankIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  helperText="Defina o tipo do patrimônio"
                >
                  <Typography sx={{ ml: 1, color: 'gray' }} variant="subtitle2">
                    Selecine o tipo de patrimônio
                  </Typography>
                  
                </TextField>
              )}
            </Grid> */}
          </Grid>
        </div>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        ></Box>
        <>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button variant="contained" color="success" onClick={handleSubmit} sx={{ marginRight: 2 }}>
            Salvar
          </Button>
        </>
      </Container>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={dialogOpen}
      onClose={() => toggleDialog(false)}
      sx={{ zIndex: 1400 }} // Ajuste do zIndex para sobrepor Dialog
    >
      {content()}
    </Drawer>
  );
}

const groupCategoryToAccountId = {
  Animal: 71,
  Nutrição: 72,
  Medicamentos: 73,
  Exames: 74,
  Sementes: 77,
  Fertilizantes: 78,
  Defensivos: 79,
  Outros: 80,
};

function NewSupplierDialog({ usestore, dialogOpen, setDialogOpen, toggleDialog, handleCategoryChange }) {
  // var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  // const property_id = localStorage.getItem('Property') || 1;
  const { aux_add, setAuxAdd } = React.useContext(Context);

  var id_farm = localStorage.getItem('Property') || 1;

  const [data, setData] = useState({
    name: '',
    icon: '',
    group_category: '',
    description: '',
    id_account: 70,
  });

  const handleChangeGroupCategory = (e) => {
    // console.log('handleChangeGroupCategory');
    // console.log(e.target);
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
      id_account: groupCategoryToAccountId[value], // Atualiza o id_account conforme o group_category selecionado
    }));
    // handleChange()
  };

  const handleChangeCategory = (e, blur) => {
    // console.log('handleChangeCategory');
    // console.log(e.target);
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: blur ? value.trim() : value,
      icon: `${value}.png`,
    }));
    // handleChange()
  };

  const validateCategory = (data_sub) => {
    var valid = true;
    var msg = 'Por favor, preencha os campos obrigatórios!';

    if (!data_sub.group_category) {
      valid = false;
      msg = 'Informe o grupo da categoria';
    } else if (!data_sub.name) {
      valid = false;
      msg = 'Informe a nome da categoria';
    } else {
      var aux_name = usestore.reposProducts.getCategoryStore('name', data_sub.name || '');
      if (aux_name.length > 0) {
        valid = false;
        msg = 'Ja existe uma categoria com o nome informado';
      }
    }
    return [valid, msg];
  };

  const handleSubmit = async () => {
    // console.log('handleSubmit');
    let formOk = validateCategory(data);
    // let formOk = true;
    // console.log(data);
    if (formOk[0]) {
      const header = { header: { 'Content-Type': 'application/json' } };
      // console.log(data);
      await api
        .post('/productCategory/category', data, header)
        .then((response) => {
          // console.log('response.data');
          // console.log(response.data);
          if (response.data.isSuccess) {
            const newItemCategory = {
              id: response.data._value[1].insertId,
              name: data.name,
            };

            toast.success('Categoria cadastrada com sucesso!');
            handleCategoryChange(true, newItemCategory);
            usestore.reposProducts.fetchCategory({ id_property: id_farm }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                setDialogOpen(false);
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao cadastrar produto, tente novamente mais tarde!');
        });
    } else {
      toast.error(formOk[1]);
    }
  };

  // console.log(data);

  const content = () => (
    <div id="drawer-container">
      <Box
        sx={{ width: 300 }}
        role="presentation"
        // onClick={() => toggleDialog(false)}
        //onKeyDown={() => toggleDialog(false)}
      >
        <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 }, m: 2 }}>
            <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>Cadastro rápido:</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0, width: 250 }}>
              {/* Informações gerais */}
              <Grid item xs={12} sm={12} sx={{ mt: 4 }}>
                <FormControl fullWidth>
                  <InputLabel id="group-category-label">Grupo de Categoria</InputLabel>
                  <Select
                    labelId="group-category-label"
                    id="group-category"
                    name="group_category"
                    value={data.group_category}
                    onChange={handleChangeGroupCategory}
                    MenuProps={{ container: () => document.getElementById('drawer-container') }}
                  >
                    {['Animal', 'Nutrição', 'Medicamentos', 'Exames', 'Sementes', 'Fertilizantes', 'Defensivos', 'Outros'].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                  <TextField
                    // required
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    id="name"
                    name="name"
                    label={'Incluir nova categoria'}
                    fullWidth
                    autoComplete="name"
                    variant="standard"
                    color="success"
                    value={data.name}
                    onChange={handleChangeCategory}
                    onBlur={(e) => handleChangeCategory(e, true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Assignment />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // error={!data.name || data.name.length < 5}
                    // helperText={'* Mínimo de 5 caracteres'}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={() => {
                      setDialogOpen(false);
                    }}
                    color="success"
                    sx={{ mr: 1 }}
                  >
                    Cancelar
                  </Button>
                  <Button onClick={handleSubmit} variant="contained" color="success">
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      open={dialogOpen}
      onClose={() => toggleDialog(false)}
      sx={{ zIndex: 1400 }} // Ajuste do zIndex para sobrepor Dialog
    >
      {content()}
    </Drawer>
  );
}
