import { useRef, useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  Typography,
  InputAdornment,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Button,
  AppBar,
  Toolbar,
  ListItemText,
  IconButton,
  ListItem,
  List,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Span } from '../../../components/Typography';
import Typewriter from '../../../components/Typewriter/Typewriter';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartTermsOfAgreementStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const [accepted, setAccepted] = useState(false);
  const [open, setOpen] = useState(false);
  const endOfPageRef = useRef(null); // Crie o ref

  const handleAcceptChange = (event) => {
    setAccepted(event.target.checked);
    // Verifica se o checkbox foi marcado
    if (event.target.checked && endOfPageRef.current) {
      // Rola suavemente até o elemento no final da página
      endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (accepted === false) {
      return true;
    }
    // if (!data.city || data.city.trim() === "") {
    //     return true;
    // }
    return false;
  };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  return (
    <>
      <Grid container sx={{ mb: 0 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 3 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 1 }}>
            <Typography gutterBottom sx={{ mt: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
              <Typewriter tam={30} text=" Por favor, leia atentamente o contrato a seguir." />
            </Typography>

            <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
              Para continuar, marque a opção de aceitação dos termos e, em seguida, clique no botão 'Continuar'.
            </Typography>
            <FormControlLabel control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="acceptTerms" color="success" />} label="Eu aceito os termos e condições do contrato" />

            {/* Contrato com barra de rolagem */}
            <Box
              sx={{
                height: 400,
                overflow: 'auto',
                border: '1px solid #ccc',
                padding: 2,
                borderRadius: 2,
                mb: 2,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography variant="body1" paragraph>
                CONTRATO DE LICENÇA DE USO DE SOFTWARE - FAZENDA PREMIUM
              </Typography>
              <Typography variant="body2" paragraph>
                Este contrato de licença de uso de software é celebrado entre o usuário e a empresa Fazenda Premium, que concede ao usuário o direito não exclusivo de uso do software de gestão
                agrícola denominado Fazenda Premium, sujeito aos termos e condições aqui estabelecidos.
              </Typography>
              <Typography variant="body2" paragraph>
                1. Concessão de Licença: A empresa concede ao usuário o direito de uso pessoal e intransferível do software, conforme as funcionalidades descritas na documentação. O usuário se
                compromete a utilizar o software estritamente para as finalidades previstas.
              </Typography>
              <Typography variant="body2" paragraph>
                2. Restrições: O usuário não poderá copiar, modificar, distribuir, vender ou transferir o software a terceiros sem a autorização expressa da empresa.
              </Typography>
              <Typography variant="body2" paragraph>
                3. Atualizações: A empresa poderá fornecer atualizações do software periodicamente, as quais o usuário concorda em instalar para garantir o bom funcionamento do sistema.
              </Typography>
              <Typography variant="body2" paragraph>
                4. Limitação de Responsabilidade: A empresa não se responsabiliza por danos decorrentes do uso incorreto do software, incluindo perda de dados, interrupções de serviço ou outros
                problemas técnicos.
              </Typography>
              <Typography variant="body2" paragraph>
                Ao clicar em "Aceito os Termos", o usuário concorda em cumprir todas as disposições deste contrato.
              </Typography>
            </Box>

            {/* Link para a Política de Privacidade */}
            <Typography variant="body2" paragraph>
              Ao continuar, você também concorda com nossa{' '}
              <Link href="#" onClick={handleClickOpen}>
                Política de Privacidade
              </Link>
              .
            </Typography>

            {/* Termo de Aceitação */}
            <FormControlLabel control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="acceptTerms" color="success" />} label="Eu aceito os termos e condições do contrato" />
          </Grid>

          {accepted == true && (
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Clique em continuar...
              </Typography>
            </Grid>
          )}
          <Box sx={{ mb: 4 }}></Box>
          <div ref={endOfPageRef} style={{ height: '40px' }}>
            ->
          </div>
        </Grid>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            {data && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                  Retornar
                </Button>
                {/* O botão Confirmar e Salvar, leva para tela successo */}
                <Button
                  sx={{
                    animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
                  }}
                  color="success"
                  variant="contained"
                  type="submit"
                  onClick={!isError() ? handleNext : () => null}
                  disabled={isError()}
                >
                  {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>

        {/* Modal da Política de Privacidade */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Política de Privacidade</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body2" gutterBottom>
              Nossa Política de Privacidade explica como coletamos, usamos e protegemos suas informações pessoais ao usar o software Fazenda Premium. Garantimos que suas informações são tratadas com
              segurança e confidencialidade.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Coletamos dados para melhorar a experiência do usuário e o funcionamento do sistema, incluindo dados de uso e preferências. Seus dados nunca serão compartilhados com terceiros sem seu
              consentimento.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento, conforme previsto na legislação aplicável.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
