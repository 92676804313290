import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';

// create the store
const useDocFilesReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listDocFiles: [],
        // Instacia a listImages ||| item: {id: '', tipo: '', id_farm: ''}
        fetch: async (item) => {
          if (!item) {
            return [];
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('uploadFile/list', item || {}, header);
          const data = await response.data;
          console.log('dockFile');
          console.log(data);
          await set({
            listDocFiles: data._value.map((file) => ({
              // img: `https://cpt-soft.s3.us-east-1.amazonaws.com/${image.key_image}`,
              img: file.url,
              id_property: file.id_property,
              title: file.name,
              file: file.key_file,
              size: file.size,
              folder: file.type,
              type: file.type,
              fileExtension: file.fileExtension,
              id_item: file.id,
              isDeleted: file.isDeleted,
              date: file.created_at,
            })),
          });
          return data;
        },
        // pega a(s) imagem(ns) da listImages de acordo com o tipo e/ou id informados
        getImageStore: (tipo, id) => {
          return get().listDocFiles.filter((img) => {
            if (img.tipo == tipo) {
              if (!id || img.id_item == id) {
                return img;
              }
            }
          });
        },
        // Adiciona uma nova imagem à listImages
        addImageStore: (file) => {
          set((state) => ({
            listDocFiles: [...state.listDocFiles, file],
          }));
        },
        // Gera uma nova listImages sem a imagem informada
        delImageStore: (key_file) => {
          set({
            listDocFiles: get().listDocFiles.filter((img) => {
              if (img.file != key_file) {
                return img;
              }
            }),
          });
        },
        // atualiza listImages de acordo com o key_image, key e val informados
        updateImageStore: (key_file, key, val) => {
          set({
            listDocFiles: get().listDocFiles.map((img) => {
              if (img.file === key_file) {
                img[key] = val;
              }
              return img;
            }),
          });
        },
      }),
      { name: 'reposDocFiles' }
    )
  )
);

export default useDocFilesReposStore;
