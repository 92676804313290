import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Button, Box, Skeleton, Fab, Grid, Stack, SwipeableDrawer, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CardMsnMap from "./CardMsnMap";
import GroupAvatarsMaps from "./GroupAvatarsMaps";
import Loading from "../../../../components/Loader/Loading";
import useStore from "../../../../store/useStore";
import { Context } from "../../../../components/Context/AuthContext";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

function MessengerMapBottom(props) {
  const { window, setIsLoadingMap } = props;
  const [open, setOpen] = useState(false);
  const [msn, setMsn] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const property_id = (localStorage.getItem("Property") || 1);
  const { aux_add } = useContext(Context);

  const [maxh, setMaxH] = useState('54');
  const [bar_position, setBarPosition] = useState('absolute');
  const toggleDrawer = (newOpen) => () => {
    if (newOpen) {
      setMaxH('65%');
      setBarPosition('relative');
    } else {
      setBarPosition('absolute');
      setMaxH('54px');
    }
    setOpen(newOpen);
  };

  // repos
  const usestore = useStore();
  const reposMessenger = usestore.reposMessenger;
  const reposUser = usestore.reposUser;

  const loadPosts = async () => {
    console.log("-------loadPosts------------:");
    const optionsEquipe = reposUser.listUser;
    
    const itemData = [];
    for (const info of reposMessenger.listMessenger) {
      if (info.isDeleted === 0 && info.status === "0" && info.id_property == property_id) {
        //console.log('optionsEquipe')
        for (const iterator of optionsEquipe) {
          if (iterator.id === info.id_requester) {
            info.name = iterator.name;
            const name = info.name;
            //console.log(name)
            const charName = name[0].toUpperCase();
            //console.log(charName)
            info.charName = charName;
          }
        }
        itemData.push(info);
      }
    }
    setMsn(itemData);
    setIsLoading(false);
  };

  useEffect(() => {
    loadPosts();
  }, [aux_add, isLoading, property_id]);

  const container = window !== undefined ? () => window().document.body : undefined;

  // const getValZoom = (newOpen) => () => {
  function getValZoom() {
    let val_zoom = Number(localStorage.getItem("setting_zoom") || '1');
    /*if (val_zoom >= 1) {
      drawerBleeding = 56;
    } else if (val_zoom == 0.9) {
      drawerBleeding = 105;
    } else if (val_zoom == 0.8) {
      drawerBleeding = 208;
    } else if (val_zoom == 0.7) {
      drawerBleeding = 335;
    } else if (val_zoom == 0.6) {
      drawerBleeding = 510;
    }*/
    let height_percent = 75;
  
    if (val_zoom == 1.4) {
      height_percent = 104.5;
    } else if (val_zoom == 1.3) {
      height_percent = 97;
    } else if (val_zoom == 1.2) {
      height_percent = 89.5;
    } else if (val_zoom == 1.1) {
      height_percent = 82;
    } else if (val_zoom == 1) {
      height_percent = 75;
    } else if (val_zoom == 0.9) {
      height_percent = 67.4;
    } else if (val_zoom == 0.8) {
      height_percent = 60;
    } else if (val_zoom == 0.7) {
      height_percent = 52.5;
    }
    return height_percent;
  };

  const btnOpenClose = function() {
    return (
      <Box Box sx={{ "& > :not(style)": { m: 1 } }} onClick={toggleDrawer(open ? false : true)}>
        <Fab variant="extended" 
          sx={{ 
            width: 30,
            height: 30,
            borderRadius: 25,
            position: "absolute",
            top: -3,
            left: "calc(50% - 20px)",
            pointerEvents: 'all',
          }} size="medium" color="default" >
          {!open ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon /> }
        </Fab>
      </Box>
    )
  }
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiDrawer-paperAnchorBottom": {
            // height: `calc(50% - ${drawerBleeding}px)`,
            //height: `calc(${height_percent}% - ${drawerBleeding}px)`,
            minHeight: getValZoom() + '%',
            maxHeight: maxh,
            //overflow: "visible",
            transform: 'none !important', //translateY(54px);
          },
        }}
      />

      {/*btnOpenClose()*/}

      <SwipeableDrawer
        container={container}
        id="ajustHeigth"
        anchor="bottom"
        open={open}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        cancelable={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: bar_position, //"absolute",
            //top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            bottom: 0,
            height: '54px',
            right: 0,
            left: 0,
          }}
        >
          {/* <Puller /> */}
          {btnOpenClose()}
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography sx={{ p: 2, color: "h6" }}>{msn.length} Solicitações</Typography>
            </Grid>
            <Grid item xs={4} sx={{ p: 1, mt: 1, ml: "auto" }}>
              {msn &&
                <GroupAvatarsMaps msn={msn} isLoading={isLoading} />
              }
            </Grid>
          </Grid>
        </StyledBox>

        <StyledBox sx={{ px: 2, pb: 2, height: "100%" }} >
          <div className="content " id="div_CardMsnMap">
            <div className="container-fluid">
              <div className="row">
                {isLoading === true ? (
                  <>
                    <Loading />
                  </>
                ) : (
                  msn?.map((post, index) => (
                    <div key={index} className="ml-4 mb-4">
                      <CardMsnMap key={index} post={post} setIsLoading={setIsLoading} isLoading={isLoading} setIsLoadingMap={setIsLoadingMap} />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

MessengerMapBottom.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MessengerMapBottom;
