import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField, Grid, Typography, Stack } from '@mui/material';

/* Exemple:
  let obj_date = {
    label: 'Data inicial do saldo',
    field_data: 'balance_date',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    error_date: false,
    disabled: false,
  }
*/
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' };

export default function SingleDateField({ data, obj_date }) {
  const [value, setValue] = useState(dayjs(data[obj_date.field_data]));

  const isErrorDate = () => {
    let returne = false;
    if (!value.$D) {
      returne = true;
    } else if (obj_date.min && dayjs(value).isBefore(dayjs(obj_date.min), 'day')) {
      returne = true;
    } else if (obj_date.max && dayjs(value).isAfter(dayjs(obj_date.max), 'day')) {
      returne = true;
    }
    //if (data.error_date != returne) {
    //  obj_date.setData(prevState => ({ ...prevState, ['error_date']: returne }));
    //}
    return returne;
  };

  const error_date = isErrorDate();
  const color = isErrorDate() ? 'error' : 'success';

  useEffect(() => {
    //console.log(obj_date);
    //console.log(data[obj_date.field_data]);
    //console.log(value);
    if (!dayjs(value).isSame(data[obj_date.field_data])) {
      setValue(data[obj_date.field_data]);
    }
  }, [data[obj_date.field_data]]);

  return (
    <>
      <Grid container style={{ maxWidth: obj_date.maxWidth || '100%' }}>
        <Grid item xs={12} md={12} sx={{ my: 3, height: 125 }}>
          <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="d-lg-none">
              <Stack>
                <MobileDatePicker
                  //views={['day', 'month', 'year']}
                  label={obj_date.label || 'Data'}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    obj_date.handleChange(newValue, obj_date.id);
                  }}
                  adapterLocale="pt"
                  inputFormat="DD/MM/YYYY"
                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                  minDate={obj_date.min || null}
                  maxDate={obj_date.max || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color={color}
                      error={!value.$D || error_date}
                      helperText={
                        !value.$D ||
                        (error_date && (
                          <p id="data_field_valid" className="form-text text-danger is-invalid">
                            Por favor, insira uma data válida.
                          </p>
                        ))
                      }
                    />
                  )}
                  disabled={obj_date.disabled}
                />
              </Stack>
            </div>
            <div className="d-none d-lg-block">
              <Stack>
                <DesktopDatePicker
                  //views={['day', 'month', 'year']}
                  label={obj_date.label || 'Data'}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    obj_date.handleChange(newValue, obj_date.id);
                  }}
                  adapterLocale="pt"
                  inputFormat="DD/MM/YYYY"
                  dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                  minDate={obj_date.min || null}
                  maxDate={obj_date.max || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color={color}
                      error={!value.$D || error_date}
                      helperText={
                        !value.$D ||
                        (error_date && (
                          <p id="data_field_valid" className="form-text text-danger is-invalid">
                            Por favor, insira uma data válida.
                          </p>
                        ))
                      }
                    />
                  )}
                  disabled={obj_date.disabled}
                />
              </Stack>
            </div>
            <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: color }}>
              {!value.$D || error_date ? 'Por favor, insira uma data válida.' : obj_date.text_helper}
            </Typography>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
}
