import * as React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import PercentTextField from '../../../../components/Fields/PercentTextField';
import { HighlightOff } from '@mui/icons-material';
import './FormTableItens.css';
import { formatValue } from '../../../../utils/functions';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
// function ccyFormat(num) {
//   var aux_num = num || 0;
//   if (typeof aux_num === 'string') {
//     aux_num = parseFloat(num);
//   }
//   return aux_num.toFixed(2);
// }

// calcula o valor de porcentagem sobre um valor
// function calcPorcent(percent, value) {
//   var aux_value = value || 0;
//   if (typeof value === 'string') {
//     aux_value = parseFloat(value);
//   }
//   var aux_val = aux_value * (parseFloat(percent||'0')/100);

//   return aux_val.toFixed(2);
// }

export default function FormTableItens({ data, obj_fn, visualize }) {
  const handleChangeDiscount = (event, blur) => {
    var value = Number(event.target.value);
    //name: 'type_' + index + "_" + type.id,
    var idx = event.target.name.split('_');
    var type = idx[0];
    var position = idx[1];
    // var item_id = idx[2]

    let arr_itens = [];
    if (type === 'products') {
      arr_itens = JSON.parse(JSON.stringify(data.products));
    } else if (type === 'lotes') {
      arr_itens = JSON.parse(JSON.stringify(data.lotes));
    } else {
      arr_itens = JSON.parse(JSON.stringify(data.animals));
    }

    // porcentagem do valor do produto
    var val_item = parseFloat(arr_itens[position].value);
    var discount = (val_item * parseFloat(value)) / 100;
    var last_discount = (val_item * arr_itens[position].discount) / 100;
    var value_aux = val_item - discount;

    arr_itens[position].discount = value;
    var aux_value = data.value + last_discount - val_item + value_aux;
    var aux_value_end = data.value_end + last_discount - val_item + value_aux;

    obj_fn.setData((prevState) => ({ ...prevState, value: aux_value }));
    obj_fn.setData((prevState) => ({ ...prevState, value_end: aux_value_end }));

    arr_itens[position].value_end = value_aux;
    var value_type = type + '_value';
    var aux_value_end_type = data[value_type] + last_discount - val_item + value_aux;

    obj_fn.setData((prevState) => ({ ...prevState, [value_type]: aux_value_end_type }));
    obj_fn.setData((prevState) => ({ ...prevState, [type]: arr_itens }));
  };

  const handleRemove = (index, type) => {
    var aux_rmv = [];
    var aux_value = data.value;
    var aux_value_end = data.value_end;

    if (type === 'products') {
      aux_rmv = JSON.parse(JSON.stringify(data.products));
    } else if (type === 'lotes') {
      aux_rmv = JSON.parse(JSON.stringify(data.lotes));
    } else {
      aux_rmv = JSON.parse(JSON.stringify(data.animals));
    }

    var val_fim = 0;
    var aux_val = 0;
    for (var i = 0, tam = aux_rmv.length; i < tam; i++) {
      aux_val = parseFloat(aux_rmv[i].value);
      aux_val -= (aux_val * parseFloat(aux_rmv[i].discount)) / 100;

      if (index === i) {
        aux_value -= aux_val;
        aux_value_end -= aux_val;
      } else {
        val_fim += aux_val;
      }
      aux_rmv[i].value_end = aux_val;
    }
    aux_rmv.splice(index, 1);

    obj_fn.setData((prevState) => ({ ...prevState, value: aux_value }));
    obj_fn.setData((prevState) => ({ ...prevState, value_end: aux_value_end }));

    var value_type = type + '_value';
    obj_fn.setData((prevState) => ({ ...prevState, [value_type]: val_fim }));
    obj_fn.setData((prevState) => ({ ...prevState, [type]: aux_rmv }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label={'carrinho de ' + data.operationType} className="table-itens">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  <LocalGroceryStoreIcon sx={{ mr: 0.5 }} />
                  <strong>Carrinho de {data.operationType}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {!data.products || data.products.length === 0 ? (
            <></>
          ) : (
            <>
              <Table sx={{ minWidth: 700 }} aria-label={'lista de produtos'} className="table-itens">
                <TableHead>
                  <TableRow>
                    {visualize ? (
                      <></>
                    ) : (
                      <>
                        <TableCell title="Remover" align="center">
                          <strong>X</strong>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <strong>Produto</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Unidade</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Quantidade</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Valor</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Desconto</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Subtotal</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.products.map((prod, index) => (
                    <TableRow key={'p_' + index}>
                      {visualize ? (
                        <></>
                      ) : (
                        <>
                          <TableCell title="Remover" align="center">
                            <IconButton variant="contained" color="error" onClick={() => handleRemove(index, 'products')}>
                              <HighlightOff />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                      <TableCell>{prod.name}</TableCell>
                      <TableCell align="center">{data.packaging_unit === 'unidade' || prod.packaging_sigla ? prod.packaging_sigla : prod.unit_sigla}</TableCell>
                      <TableCell align="right">{formatValue(prod.quant)}</TableCell>
                      <TableCell align="right">{formatValue(prod.value)}</TableCell>
                      <TableCell align="right" width={'150px'}>
                        {visualize ? (
                          <>{prod.discount} %</>
                        ) : (
                          <>
                            <PercentTextField
                              handleChange={handleChangeDiscount}
                              options={{
                                required: true,
                                label: '',
                                name: 'products_' + index + '_' + prod.id,
                                id: index + '_' + prod.id,
                                color: 'success',
                                value: prod.discount,
                                variant: 'outlined',
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">{formatValue(prod.value_end)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          {!data.lotes || data.lotes.length === 0 ? (
            <></>
          ) : (
            <>
              <Table sx={{ minWidth: 700 }} aria-label={'lista de lotes'} className="table-itens">
                <TableHead>
                  <TableRow>
                    {visualize ? (
                      <></>
                    ) : (
                      <>
                        <TableCell title="Remover" align="center">
                          <strong>X</strong>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <strong>Lote</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Qtd. Animais</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Peso Médio</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Valor</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Desconto</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Subtotal</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.lotes.map((lote, index) => (
                    <TableRow key={'l_' + index}>
                      {visualize ? (
                        <></>
                      ) : (
                        <>
                          <TableCell title="Remover" align="center">
                            <IconButton variant="contained" color="error" onClick={() => handleRemove(index, 'lotes')}>
                              <HighlightOff />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                      <TableCell>{lote.name}</TableCell>
                      <TableCell align="right">{lote.quant}</TableCell>
                      <TableCell align="right">{lote.peso}</TableCell>
                      <TableCell align="right">{formatValue(lote.value)}</TableCell>
                      <TableCell align="right" width={'150px'}>
                        {visualize ? (
                          <>{lote.discount} %</>
                        ) : (
                          <>
                            <PercentTextField
                              handleChange={handleChangeDiscount}
                              options={{
                                required: true,
                                label: '',
                                name: 'lotes_' + index + '_' + lote.id,
                                id: index + '_' + lote.id,
                                color: 'success',
                                value: lote.discount,
                                variant: 'outlined',
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">{formatValue(lote.value_end)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          {!data.animals || data.animals.length === 0 ? (
            <></>
          ) : (
            <>
              <Table sx={{ minWidth: 700 }} aria-label={'lista de animais'} className="table-itens">
                <TableHead>
                  <TableRow>
                    {visualize ? (
                      <></>
                    ) : (
                      <>
                        <TableCell title="Remover" align="center">
                          <strong>X</strong>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <strong>Animal</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Lote</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Sexo</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Peso</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Valor</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Desconto</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Subtotal</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.animals.map((animal, index) => (
                    <TableRow key={'a_' + index}>
                      {visualize ? (
                        <></>
                      ) : (
                        <>
                          <TableCell title="Remover" align="center">
                            <IconButton variant="contained" color="error" onClick={() => handleRemove(index, 'animals')}>
                              <HighlightOff />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                      <TableCell>{animal.nome}</TableCell>
                      <TableCell>{animal.lote}</TableCell>
                      <TableCell>{animal.sexo}</TableCell>
                      <TableCell align="right">{animal.peso}</TableCell>
                      <TableCell align="right">{formatValue(animal.value)}</TableCell>
                      <TableCell align="right" width={'150px'}>
                        {visualize ? (
                          <>{animal.discount} %</>
                        ) : (
                          <>
                            <PercentTextField
                              handleChange={handleChangeDiscount}
                              options={{
                                required: true,
                                label: '',
                                name: 'animals_' + index + '_' + animal.id,
                                id: index + '_' + animal.id,
                                color: 'success',
                                value: animal.discount,
                                variant: 'outlined',
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">{formatValue(animal.value_end)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} rowSpan={4} />
                <TableCell align="right">
                  <strong>Total</strong>
                </TableCell>
                <TableCell align="right">{formatValue(data.products_value + data.lotes_value + data.animals_value, true)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
