import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useActivityProductionReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listLotes: [],
        listAnimals: [],
        listActivityProduction: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem("Property") || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("activityProduction/list", item, header);
          const data = await response.data;

          let list_lotes = [];
          let list_animals = [];
          await set({
            listActivityProduction: data._value.map((activityProduction) => {
              list_lotes = list_lotes.concat( JSON.parse(activityProduction.lote_products) );
              list_animals = list_animals.concat( JSON.parse(activityProduction.animal_products) );
              return {
                id: activityProduction.id,
                id_property: activityProduction.id_property,
                id_user: activityProduction.id_user,
                name_user: activityProduction.name_user,
                id_livestock: activityProduction.id_livestock,
                lotes: JSON.parse(activityProduction.info),
                lote_products: JSON.parse(activityProduction.lote_products),
                animal_products: JSON.parse(activityProduction.animal_products),

                date_start: dayjs(activityProduction.date_start).format("YYYY-MM-DD HH:mm:ss"),
                date_start_f: dayjs(activityProduction.date_start).format("DD/MM/YYYY"),
                date_prev_end: dayjs(activityProduction.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
                date_prev_end_f: dayjs(activityProduction.date_prev_end).format("DD/MM/YYYY"),
                date_end: activityProduction.date_end ? dayjs(activityProduction.date_end).format("YYYY-MM-DD HH:mm:ss") : "",
                date_end_f: activityProduction.date_end ? dayjs(activityProduction.date_end).format("DD/MM/YYYY") : "",
                description: activityProduction.description,
                isDeleted: activityProduction.isDeleted,
                info: JSON.parse(activityProduction.info),
                id_transaction: activityProduction.id_transaction,
                info_transaction: activityProduction.info_transaction ? JSON.parse(activityProduction.info_transaction) : '',
                record: activityProduction.record ? JSON.parse(activityProduction.record) : '',

                type: "activity",
                activity: activityProduction.name,
                name_activity: activityProduction.name,
                progress: activityProduction.progress || "CONCLUÍDA",
                planning: activityProduction.planning || 'realized',
                tipo_repos: "PRODUÇÃO",
              }
            })
          });

          set({
            listLotes: list_lotes,
            listAnimals: list_animals,
          });

          const lista = JSON.parse(localStorage.getItem("listActvs"));
          const lista_i = get().listActivityProduction;

          let array_aux = lista.filter((activity) => {
            if (!/PRODUÇÃO.*/.test(activity.activity)) {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem("listActvs", JSON.stringify(res));

          return data;
        },
        // pega a(s) produção(ões) da listActivityProduction de acordo com o [key e val] informados
        getActivityProductionStore: (key, val) => {
          return get().listActivityProduction.filter((activityProduction) => {
            if (activityProduction[key] == val) {
              return activityProduction;
            }
          });
        },
        // Gera uma nova listActivityProduction sem a activity informada
        delProductionStore: (id) => {
          set({
            listActivityProduction: get().listActivityProduction.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
        // atualiza listActivityProduction de acordo com id, key e val informados
        updateActivityProductionStore: (id, key, val) => {
          set({
            listActivityProduction: get().listActivityProduction.map((activity) => {
              if (activity.id === id) {
                if (key == "all") {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          });
          return get().listActivityProduction;
        },
      }),
      { name: "reposActivityProduction" }
    )
  )
);

export default useActivityProductionReposStore;
