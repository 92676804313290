import { useEffect, useState } from "react";
import Draggable from 'react-draggable';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AppBar, Divider, Toolbar, TextField, Container } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import { blue, green, red, yellow } from "@mui/material/colors";
import { createTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import LoopIcon from "@mui/icons-material/Loop";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { deDE } from '@mui/x-date-pickers/locales'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Span } from "../../../../components/Typography";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

const theme = createTheme(
  { palette: { primary: { main: '#1976d2' } } },
  deDE, // use 'de' locale for UI texts (start, next month, ...)
);

export default function FormEditSituation({ data, setData, handleSituation, openSituation, handleClose }) {
  const [date_start, setDateStart] = useState(new Date(data.date_start));
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    if (openSituation) {
      setData(prevState => ({ ...prevState, date: dayjs(value).format('YYYY-MM-DD HH:mm:ss') }));
    }
  }, [openSituation]);
  const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", "Sa": "sáb", "Do": "dom", }

  return ( <>
    <Dialog open={openSituation} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} 
      sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", maxWidth: "650px" } } }}>
      <DialogTitle>
        <div className="d-lg-none">
          <Toolbar sx={{ height: 50 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text="Escolha a nova situação do projeto" variant={"title"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <CloseIcon /> </IconButton>
          </Toolbar>
        </div>
        <div className="d-none d-lg-block">
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typewriter text="Escolha a nova situação do projeto" variant={"h6"} color={"#2e7d32"} />
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <CloseIcon /> </IconButton>
          </Toolbar>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ mb: 8, textAlign: "center" }}>
        <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
          <Grid container spacing={3}  sx={{ mb: 3 }}>
            <CalendarMonthIcon />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Data de alteração do projeto</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Grid container spacing={2} sx={{ my: 1 }}>
              <Grid item xs={12} sm={12} >
                <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                  <div className="d-lg-none">
                    <Stack spacing={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <MobileDatePicker
                            label="Data de alteração"
                            value={value}
                            minDate={date_start}
                            maxDate={new Date()}
                            onChange={(newValue) => {
                              setData(prevState => ({ ...prevState, date: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                              setValue(newValue);
                            }}
                            adapterLocale="pt"
                            inputFormat="DD/MM/YYYY"
                            dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                            renderInput={(params) => <TextField {...params} color="success"
                              error={!value}
                              helperText={!value && (<p id="NameHarvest" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                            />}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </div>
                  <div className="d-none d-lg-block">
                    <Stack spacing={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DesktopDatePicker
                            label="Data de alteração"
                            value={value}
                            minDate={date_start}
                            maxDate={new Date()}
                            onChange={(newValue) => {
                              setValue(newValue);
                              setData(prevState => ({ ...prevState, date: dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') }));
                            }}
                            adapterLocale="pt"
                            inputFormat="DD/MM/YYYY"
                            dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                            renderInput={(params) => <TextField {...params} color="success"
                              error={!value}
                              helperText={!value && (<p id="NameHarvest" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                            />}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </div>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
          <LoopIcon />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Situação</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} alignItems="center" sx={{ my: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={3} md={4} display={data.situation != 'ativa' ? true : 'none'} style={{padding: '20px', minHeight: "10vh", minWidth: "19vh"}}>
                <Box title="Finalizar" display="flex" justifyContent="center" alignItems="center" >
                  <Button variant="outlined" color="success" style={{ minHeight: "8vh", minWidth: "17vh" }} onClick={(e) => handleSituation('ativa')}>
                    <DoneAllIcon sx={{ mr: 1, color: green[500] }} />
                    <Typography sx={{ mt: 1, color: green[800] }} gutterBottom variant="subtitle1" component="div"> Ativa </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={4} display={data.situation != 'finalizada' ? true : 'none'} style={{padding: '20px', minHeight: "10vh", minWidth: "19vh"}}>
                <Box title="Finalizar" display="flex" justifyContent="center" alignItems="center" >
                  <Button variant="outlined" color="success" style={{ minHeight: "8vh", minWidth: "17vh" }} onClick={(e) => handleSituation('finalizada')}>
                    <DoneAllIcon sx={{ mr: 1, color: green[500] }} />
                    <Typography sx={{ mt: 1, color: blue[800] }} gutterBottom variant="subtitle1" component="div"> Finalizada </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={4} display={data.situation != 'inativa' ? true : 'none'} style={{padding: '20px', minHeight: "10vh", minWidth: "19vh"}}>
                <Box title="Inativar" display="flex" justifyContent="center" alignItems="center" >
                  <Button variant="outlined" color="warning" style={{ minHeight: "8vh", minWidth: "17vh" }} onClick={(e) => handleSituation('inativa')}>
                    <RemoveDoneIcon sx={{ mr: 1, color: yellow[700] }} />
                    <Typography sx={{ mt: 1, color: yellow[800] }} gutterBottom variant="subtitle1" component="div"> Inativa </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={4} display={data.situation != 'excluida' ? true : 'none'} style={{padding: '20px', minHeight: "10vh", minWidth: "19vh"}}>
                <Box title="Excluir" display="flex" justifyContent="center" alignItems="center" >
                  <Button variant="outlined" color="error" style={{ minHeight: "8vh", minWidth: "17vh" }} onClick={(e) => handleSituation('excluida')}>
                    <DeleteForeverIcon sx={{ mr: 1, color: red[500] }} />
                    <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div"> Lixeira </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>fechar</Span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Dialog>
  </> );
}
