import React, { useState } from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import AreasContent from "./SpecificAreaContent";
import Footer from "../../components/Footer/Footer";

export default function SpecificArea() {
  const [data, setData] = useState([]);
  return (
    <>
      <NavSide />
      <Nav />
      <NavBotton />
      <AreasContent />
      <Footer />
    </>
  );
}
