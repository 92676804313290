import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, Divider, AccordionSummary, Box, Card, CardMedia, Grid, ListItem, ListItemText, Stack, Typography, List } from '@mui/material'
import { AutoAwesomeMotion, ExpandMore, Female, Input, Male, Notes } from '@mui/icons-material'
import dayjs from 'dayjs'
import FooterModal from '../../../../../components/Footer/FooterModal'
import foodsImage from '../../../../../assets/jpg/alimentos.jpg'
import TableNutritionAnimals from './TableNutritionAnimals'

export default function FormNutritionStep2({ data, obj_fn }) {
  const [errorr, setError] = useState(false)

  // const para validar o button continuar
  const isError = () => {
    return false
  }

  const returnError = () => {
    return false
  }

  const calcQuantidade = (lote, total) => {
    let aux_total = 0
    lote.list_animals.map((animal) => {
      if (lote.table_selected[animal.id]) {
        aux_total += animal.nutrition
      }
      return animal
    })
    return total ? aux_total : (aux_total / (lote.num_selecteds || 1)).toFixed(2)
  }

  const [image_livestok, setLivestokImage] = useState('/dist/img_fp/rebanho.jpg')

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas e progresso da nutrição</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3, ml: -5 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Lote(s) a serem medicados */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <AutoAwesomeMotion />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lotes para manejo Nutricional</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.lotes?.map((lote, index) => (
            <>
              <Card sx={{ p: 2 }}>
                <>
                  <Grid container spacing={4} sx={{ mt: 1, mb: 1, ml: '-15px', p: 2 }}>
                    <Card sx={{ display: 'flex', padding: 1, width: '100%', overflow: 'auto', border: '2px solid #2e7d32' }} id={'card_lot_' + lote.id}>
                      {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                      <Grid item justifyContent="left" xs={12} sm={12} sx={{ mr: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} container direction="column" alignItems="flex-start">
                            <Grid item container alignItems="center">
                              <Grid item>
                                <AutoAwesomeMotion style={{ color: '#2e7d32' }} sx={{ fontSize: 18 }} />
                              </Grid>
                              <Grid item sx={{ ml: 1 }} style={{ color: '#2e7d32' }}>
                                {lote.name}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CardMedia
                              component="img"
                              //height="300"
                              //style={{ width: "100%" }} height="100%"
                              image={image_livestok}
                              alt="Criação"
                              sx={{
                                borderRadius: '16px', // Ajuste o valor conforme necessário para o chanfrado desejado
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          </Grid>

                          {/* <Grid item xs={12} md={4} container alignItems="center"></Grid> */}
                          <Grid item xs={12} sm={6}>
                            <Grid item container alignItems="center">
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> O lote tem</strong> <span style={{ fontSize: '0.8em' }}>{lote?.total_animals || 0} </span>
                                  <strong>animais</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Não identificados:</strong> <span style={{ fontSize: '0.8em' }}> {lote?.num_animals || 0}</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Fêmeas:</strong>{' '}
                                  <span style={{ fontSize: '0.8em' }}>
                                    {' '}
                                    <Female color="secondary" /> {lote?.num_femeas || 0}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Machos:</strong>{' '}
                                  <span style={{ fontSize: '0.8em' }}>
                                    {' '}
                                    <Male color="primary" /> {lote?.num_machos || 0}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ mt: 3 }}>
                                <strong> Fase de Manejo:</strong> <span style={{ fontSize: '0.8em' }}>{lote?.fase_name || ''}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </>

                <Accordion defaultExpanded={true} sx={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="lote-products-content" id={'acordion_' + lote?.id + '_' + index}>
                    <Typography fontWeight={700}>
                      <AutoAwesomeMotion sx={{ mr: 1 }} /> Manejo nutricional no {lote.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ mb: '-24px' }}>
                    <Grid container spacing={4} sx={{ mt: -3, mb: 4 }}>
                      <Grid item xs={12} md={4}>
                        <CardMedia
                          component="img"
                          //height="300"
                          //style={{ width: "100%" }} height="100%"
                          image={foodsImage}
                          alt="Criação"
                          sx={{
                            borderRadius: '16px', // Ajuste o valor conforme necessário para o chanfrado desejado
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Grid container spacing={4} sx={{ mt: -6, mb: 1 }}>
                          <Grid item xs={6} sm={6}>
                            <ListItem>
                              <ListItemText primary="Produto (Nutrição)" secondary={lote?.product?.name} />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6} sm={6} style={{ paddingLeft: obj_fn.isMobile ? 1 : 4 }}>
                            <ListItem>
                              <ListItemText primary="Qtd. media /Ca" secondary={calcQuantidade(lote) + ' (' + lote?.product?.unit_sigla + ')'} />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6} sm={6} style={{ paddingTop: obj_fn.isMobile ? 1 : 4 }}>
                            <ListItem>
                              <ListItemText primary="Local de estoque (Retirada)" secondary={lote?.product?.location_selected?.name} />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6} sm={6} style={{ paddingLeft: obj_fn.isMobile ? 1 : 4, paddingTop: obj_fn.isMobile ? 1 : 4 }}>
                            <ListItem>
                              <ListItemText primary="Quantidade total" secondary={calcQuantidade(lote, true) + ' (' + lote?.product?.unit_sigla + ')'} />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} sx={{ mt: 1, mb: 1, ml: '-15px' }}>
                      <Card sx={{ display: 'flex', padding: 1, width: '100%', overflow: 'auto' }} id={'card_lot_' + lote.id}>
                        {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                        {/* <Grid item justifyContent="left" xs={12} sm={3} sx={{ mr: 1 }}>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Total de animais:
                          <br />
                          {lote?.total_animals || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fase de Manejo:
                          <br />
                          {lote?.fase_name || ''}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fêmeas:
                          <br />
                          <Female color="secondary" /> {lote?.num_femeas || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Machos:
                          <br />
                          <Male color="primary" /> {lote?.num_machos || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }} title={'Não identificados'}>
                          Não identif.:
                          <br />
                          {lote?.num_animals || 0}
                        </Typography>
                      </Grid> */}

                        <Grid item xs={12} sm={12}>
                          <TableNutritionAnimals data_l={lote} obj_fn={obj_fn} setError={setError} />
                        </Grid>
                      </Card>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Card>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Divider />
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrições */}
        {/* <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral do manejo sanitário
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid> */}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  )
}
