import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Guide from './Guide';
import GuideTabsProject from './GuideTabsProjectLivestock';
import GuideTabsProperty from './GuideTabsProperty';
import FirstAccess from './ FirstAccess';
import GuideTabsProjectLivestock from './GuideTabsProjectLivestock';
import GuideTabsProjectHarvest from './GuideTabsProjectHarvest';
import Understanding from './Understanding';
import UserArea from './UserArea';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GuideTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Primeiro Acesso" {...a11yProps(0)} />
          <Tab label="Entendendo o Fazenda Premium" {...a11yProps(1)} />
          <Tab label="Tour na Propriedade" {...a11yProps(2)} />
          {/* <Tab label="Projeto Criação" {...a11yProps(3)} />
          <Tab label="Projeto Safra" {...a11yProps(4)} /> */}
          <Tab label="Área do usuário" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FirstAccess />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Understanding />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GuideTabsProperty />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <GuideTabsProjectLivestock />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <GuideTabsProjectHarvest />
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <UserArea />
      </TabPanel>
    </Box>
  );
}
