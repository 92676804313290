import * as React from "react";
import json from '../../exemplo_roles_permission.json'


function createData(permission, name, view, edit, trash) {
    return { permission, name, view, edit, trash };
}


export default function RowsPermissionsEdit(roleEdit) {



    const role = roleEdit

    console.log(role)
    console.log(json)
    console.log(json[role])
    //console.log(json[role].property)

    // Em cada módulo contruo variáveis para serem inseridas na criação das linhas
    const permissionProperty = 'property'
    const nameProperty = 'Mapa propriedade'
    const viewProperty = json[role].property.view
    const editProperty = json[role].property.edit
    const trashProperty = json[role].property.delete

    const permissionUsers = 'users'
    const nameUsers = 'Usuários'
    const viewUsers = json[role].users.view
    const editUsers = json[role].users.edit
    const trashUsers = json[role].users.delete

    const permissionMessenger = 'messenger'
    const nameMessenger = 'Mensagens'
    const viewMessenger = json[role].messenger.view
    const editMessenger = json[role].messenger.edit
    const trashMessenger = json[role].messenger.delete

    const permissionUpload = 'upload'
    const nameUpload = 'Upload'
    const viewUpload = json[role].upload.view
    const editUpload = json[role].upload.edit
    const trashUpload = json[role].upload.delete


    // cria o array de cada módulo
    const rowsEdit = [
        createData(permissionProperty, nameProperty, viewProperty, editProperty, trashProperty),
        createData(permissionUsers, nameUsers, viewUsers, editUsers, trashUsers),
        createData(permissionMessenger, nameMessenger, viewMessenger, editMessenger, trashMessenger),
        createData(permissionUpload, nameUpload, viewUpload, editUpload, trashUpload),
    ];

    return rowsEdit

};
