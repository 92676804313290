import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField, Button } from '@mui/material';
import { useEffect } from 'react';

//const TAX_RATE = 0.07

function ccyFormat(num) {
  // Garantir que num seja um número válido
  const value = Number(num);
  if (isNaN(value)) {
    return 'R$ 0,00'; // Valor padrão quando a entrada não é um número válido
  }
  // Retornar o valor formatado como moeda com duas casas decimais
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

// function priceRow(qtd, unit) {
//   return qtd * unit;
// }
// function createRow(desc, qtd, unit) {
//   const price = priceRow(qtd, unit);
//   return { desc, qtd, unit, price };
// }
function createRow(desc, qtd, price, value, unit) {
  return { desc, qtd, value, price, unit};
}

function subtotal(items) {
  return items.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
}

function quantityTotal(items) {
  return items.map(({ qtd }) => qtd).reduce((sum, i) => sum + i, 0);
}

export default function MaintenanceCostCalculator({ data, obj_fn }) {
  function getNameAccount(id_acc) {
    if (!id_acc) {
      return '';
    }
    const account = obj_fn.usestore.reposAccount.getAccountStore('id', id_acc)[0];
    return account.name;
  }
  // const [rows, setRows] = useState([]);
  // const [desc, setDesc] = useState('');
  // const [qtd, setQty] = useState('');
  // const [unit, setUnit] = useState('');
  const [valueTotal, setInvoiceTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  // const handleAddRow = () => {
  //   const newRow = createRow(desc, parseFloat(qtd), parseFloat(unit));
  //   setRows([...rows, newRow]);
  //   setDesc('');
  //   setQty('');
  //   setUnit('');
  // };

  const servicesRows = !data.services ? [] :
  data.services.map((service) => 
    createRow(
      getNameAccount(service.subcategory || service.category),
      1,
      parseFloat(service.value),
      parseFloat(service.value),
      ''
    )
  );
  const productsRows = !data.products ? [] :
    data.products.map((product) => 
      createRow(
        product.product.name,
        parseFloat(product.location_selected.quant_utilizada),
        parseFloat(product.product.stock_cost),
        parseFloat(product.location_selected.value),
        product.product.unit_sigla,
      )
    );

  const invoiceSubtotal = subtotal([...servicesRows, ...productsRows]);
  const totalProductsAndServicesQuantity = quantityTotal([...servicesRows, ...productsRows]);
  //const invoiceTaxes = TAX_RATE * invoiceSubtotal
  //const valueTotal = invoiceTaxes + invoiceSubtotal
  useEffect(() => {
    setInvoiceTotal(invoiceSubtotal);
    setTotalQuantity(totalProductsAndServicesQuantity);
  }, [invoiceSubtotal, totalProductsAndServicesQuantity]);

  useEffect(() => {
    obj_fn.setData((prevState) => {
      if (prevState.value !== valueTotal || prevState.totalQuantity !== totalQuantity) {
        return {
          ...prevState,
          value: valueTotal,
          value_end: valueTotal,
          totalQuantity: totalQuantity,
        };
      }
      return prevState;
    });
  }, [valueTotal, totalQuantity]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Detalhes
            </TableCell>
            <TableCell align="right">Preço</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Descrição</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Qtd.</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Valor Unitário</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Total</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...servicesRows, ...productsRows].map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <strong style={{ color: '#2e7d32' }}>{row.desc || ''}</strong>
              </TableCell>
              <TableCell align="right">{(row.qtd || '0') + (!row.unit ? '' : " (" + row.unit + ")")}</TableCell>
              <TableCell align="right">{ccyFormat(row.price || 0)}</TableCell>
              <TableCell align="right">{ccyFormat(row.value)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            {/* <TableCell colSpan={2}>Subtotal</TableCell> */}
            {/* <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell> */}
          </TableRow>
          {/* <TableRow>
            <TableCell>Imposto</TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell colSpan={2}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">
              {' '}
              <strong style={{ color: '#2e7d32' }}>{ccyFormat(valueTotal)}</strong>{' '}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* <div style={{ display: 'flex', gap: '16px', padding: '16px' }}>
        <TextField label="Descrição" value={desc} onChange={(e) => setDesc(e.target.value)} />
        <TextField label="Quantidade" type="number" value={qtd} onChange={(e) => setQty(e.target.value)} />
        <TextField label="Valor Unitário" type="number" value={unit} onChange={(e) => setUnit(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleAddRow}>
          Adicionar
        </Button>
      </div> */}
    </TableContainer>
  );
}
