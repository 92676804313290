import React, { useState, useEffect, useContext } from "react";
import SendIcon from "@mui/icons-material/Send";
import "./ChatContent.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Box, Typography, TextField, Button, IconButton, Avatar, CardMedia } from "@mui/material";
import api from "../../services/api";
import ThreeDotsSpinner from "../../components/Loader/ThreeDotsSpinner";
import IAIcon from "../../components/Icons/chat/IAIcon";

export default function ChatContentModal() {
  const [prompt, setPrompt] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(conversation);
  }, [conversation]);

  const handleQuerySubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setConversation((prevData) => [
      ...prevData,
      {
        type: "query",
        text: prompt,
      },
    ]);
    const temp = prompt;
    setPrompt("");
    // enviamos a pergunta na variável temp
    // obtemos a resposta do servidor aqui
    getResponseFromServer(temp);
  };

  const headers = {
    header: {
      "Content-Type": "application/json",
    },
  };

  //api.defaults.headers.common['token'] = localStorage.getItem("token");
  const getResponseFromServer = async (temp) => {
    try {
      // const response = await fetch("http://localhost:5001/api/v1/chat/", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "token": localStorage.getItem("token")
      //   },
      //   body: JSON.stringify({ prompt: temp }),
      // });
      const response = await api.post("http://localhost:5001/api/v1/chat/", { prompt: temp }, headers);
      console.log(response);
      //const responseData = await response.json();
      const responseData = await response.data;
      setConversation((prevData) => [
        ...prevData,
        {
          type: "response",
          text: responseData.res,
        },
      ]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setConversation((prevData) => [
        ...prevData,
        {
          type: "response",
          text: "Opss.. Algo deu errado. Tente novamente.",
        },
      ]);
      setLoading(false);
    }
  };
  //src="dist/image/chatgpt-icon.png"
  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 0, }}>
      <Box sx={{ mt: 2, mb: 10, flexGrow: 1, overflowY: "auto" }}>
        {conversation.length === 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2, ml: 1 }}>
            {/* <CardMedia component="img" src="/dist/image/chatgptimage.png" alt="" sx={{ width: 40, height: 40, marginRight: 1 }} /> */}
            <Box sx={{ flex: "none", width: 40, height: 40, marginRight: 1 }}>
              <IAIcon size={32} />
            </Box>
            <Typography variant="body1">Pergunte-me qualquer coisa ....</Typography>
          </Box>
        ) : null}
        {conversation.map((obj, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", mt: 1, ml: 1 }}>
            {/* {obj.type === "query" ? <AccountBoxIcon sx={{ fontSize: 40 }} /> : <img src="dist/image/chatgpt-icon.png" height="32" alt="" />} */}
            {obj.type === "query" ? <AccountBoxIcon sx={{ fontSize: 40 }} /> : <Box sx={{ flex: "none", width: 40, height: 40, marginRight: 1 }}>
              <IAIcon size={32} />
            </Box>}
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1">{obj.text}</Typography>
            </Box>
          </Box>
        ))}
        {loading ? (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1, ml: 1 }}>
            {/* <CardMedia component="img" src="/dist/image/chatgptimage.png" alt="" sx={{ width: 40, height: 40, marginRight: 1 }} /> */}
            <Box sx={{ flex: "none", width: 40, height: 40, marginRight: 1 }}>
              <IAIcon size={32} />
            </Box>
            <ThreeDotsSpinner />
          </Box>
        ) : null}
      </Box>
      <form onSubmit={handleQuerySubmit} sx={{ mt: 2, flexShrink: 0 }} style={{ display: "flex", alignItems: "center" }}>
        <TextField
          type="text"
          required
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          variant="outlined"
          fullWidth
          // sx={{
          //   ml: 1,
          //   "& .MuiOutlinedInput-root": {
          //     backgroundColor: "#10a37f",
          //   },
          //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //     borderColor: "#006644",
          //   },
          //   "& .MuiInputBase-input": {
          //     color: "white",
          //   },
          // }}
          sx={{
            ml: 1,
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#000",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#808080",
            },
            "& .MuiInputBase-input": {
              color: "white",
            },
          }}
          placeholder="Digite sua pergunta..."
          InputProps={{
            endAdornment: (
              <IconButton type="submit" disabled={!prompt || loading} variant="contained">
                <SendIcon sx={{ color: "white" }} />
              </IconButton>
            ),
          }}
        />
      </form>
    </Box>
  );
}
