import { Grid, TextField, FormHelperText, Button, Box, Typography, Alert, Stack, AlertTitle, Autocomplete } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import ProfileSelectRadioTeam from "./ProfileSelectRadioTeam";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditModalFormTeamDelete from "./EditModalFormTeamDelete";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useStore from "../../../../store/useStore";
import { Context } from "../../../../components/Context/AuthContext";
import PhoneTextField from "../../../../components/Fields/PhoneTextField";

const EditFormTeam = ({ editUser, setEditUser, id_role, handleClose, setLoading }) => {
  const usestore = useStore();

  const formik = useFormik({
    initialValues: editUser,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Nome é um campo obrigatório'),
      //email: Yup.string().required('Email é um campo obrigatório').email('Email inválido'),
      farms: Yup.string().required('Propriedade é um campo obrigatório'),
      id_role: Yup.string().required('Selecione um perfil para o novo membro da equipe')
    })
  });
  const handleChangeInput = (e) => {
    //e.persist();
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    /*if (!e.target.value) {
      formik.errors[e.target.name] = "Nome é um campo obrigatório";
    } else {
      delete formik.errors[e.target.name];
    }*/
  };
  const handleChangePhone = (e) => {
    formik.handleChange(e);
    handleChangeInput(e);
  };
  const handleChangeFarm = (e, newValue) => {
    e.preventDefault();
    var farms_id = newValue.map((option) => option.id);
    setEditUser({ ...editUser, 'farms': farms_id.join() });
    setValueFarm(newValue);
    if (farms_id.length == 0) {
      formik.errors.farm = "Propriedade é um campo obrigatório";
    } else if (farms_id.length > 0 && formik.errors.farm) {
      delete formik.errors.farm;
    }
  };
  editUser.farm_list = editUser.farms;
  const listPropertyRepos = usestore.reposProperty.listProperty;
  const options = listPropertyRepos.filter(repos => editUser.farm_list.some(farm => (farm == repos.id) ) );
  const [value_farm, setValueFarm] = useState(options);
  if (!options.length) {
    formik.errors.farm = "Propriedade é um campo obrigatório";
  }

  const { aux_add, setAuxAdd } = useContext(Context);

  const handleChangeDelete = async (e) => {
    e.persist();
    e.preventDefault();

    const header = { header: { "Content-Type": "application/json" } };
    const res = await api.post("/users/deleteUser", editUser, header)
      .then((response) => {
        console.log(response.data);
        if (response.data.isSuccess) {
          toast.success('Usuário excluído do sistema');
          setLoading(true);
          usestore.reposUser.delUserStore(editUser.id);
          setTimeout(() => {
            setAuxAdd(aux_add + 1);
            handleClose();
          }, 200);
        }
      })
      .catch((error) => {
        toast.error('Erro ao deletar o membro da equipe, Tente novamente mais tarde!\n Caso o erro persista, contate o suporte.');
      })
  };

  return ( <>
    <Grid container spacing={2} >
      <Grid item xs={12} sx={{ mt: 0 }} >
        <Typography variant="subtitle1" >
          <strong>Aqui você poderá editar os dados do membro de sua equipe.</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <TextField
          name="name"
          label="Nome"
          variant="outlined"
          size='small'
          fullWidth
          value={editUser.name}
          //onChange={handleChangeInput}
          onChange={e => { formik.handleChange(e); handleChangeInput(e) }}
        />
        {formik.errors.name && (
          <FormHelperText error>
            {formik.errors.name}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} >
        <TextField
          required
          disabled
          name="email"
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          size="small"
          value={editUser.email}
          onChange={handleChangeInput}
        />
      </Grid>
      <Grid item xs={12} >
        <PhoneTextField phone={editUser.phone} handleChange={handleChangePhone} />
      </Grid>
      <Grid item xs={12} >
        <TextField
          required
          disabled
          name="password"
          label="Senha com no mínimo 6 dígitos"
          variant="outlined"
          size='small'
          type="password"
          fullWidth
          value={editUser.password}
          onChange={handleChangeInput}
        />
      </Grid>
      <Grid item xs={12} >
        <Autocomplete
          multiple
          id="box_farm_user"
          name="farms"
          options={listPropertyRepos}
          getOptionLabel={(option) => option.label}
          value={ value_farm }
          isOptionEqualToValue={(option, value) => option.label == value.label}
          filterSelectedOptions
          //onChange = {(event, newFarm) => handleChangeFarm(event, newFarm)}
          onChange={(e, newFarm) => { formik.handleChange(e); handleChangeFarm(e, newFarm) }}
          renderInput={(params) => <TextField
            {...params}
            variant="outlined"
            label="Propriedade"
            color="success"
            focused
            placeholder="Fazendas"
          />}
          noOptionsText="Sem resultados"
        />
        {formik.errors.farm && (
          <FormHelperText error>
            {formik.errors.farm}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} >
        <ProfileSelectRadioTeam editUser={editUser} id_role={id_role} />
      </Grid>
      <Grid item xs={12} >
        <Typography variant="subtitle1" >
          <strong>Excluir usuário</strong>
        </Typography>
        <Box sx={{ flexGrow: 1, textAlign: "left" }}>
          <EditModalFormTeamDelete editUser={editUser} handleChangeDelete={handleChangeDelete} setLoading={setLoading} />
        </Box>
      </Grid>
    </Grid>
  </>)
}

export default EditFormTeam