import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CardProperty from './components/CardProperty';
import PropertyButton from './components/form/PropertyButton';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';

export default function PropertyContent() {
  return (
    <div className="content-wrapper bg-white">
      <section className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={0} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h5" component="div">
                Suas propriedades
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                Lista&nbsp;de&nbsp;Informações&nbsp;das&nbsp;propriedades&nbsp;no Fazenda Premium.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <PropertyButton />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
              {true ? (
                <Typewriter text={`Clicando no botão "editar", para ser encaminhado para a atualização dos dados da sua propriedade.`} variant={'subtitle1'} color={'#2e7d32'} />
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <CardProperty />
          </Grid>
        </div>
      </section>
    </div>
  );
}
