import React from "react";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";
import LivestockListContent from "./LivestockListContent";
import Footer from "../../components/Footer/Footer";

// livestock list é a lista de animais
export default function LivestockList() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <LivestockListContent />
      <Footer />
    </>
  );
}
