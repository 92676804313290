import * as React from 'react';
import { Box, Card, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { LocalShipping } from '@mui/icons-material';
import PropTypes from 'prop-types';
// import SalesInventoryContent from './SalesInventoryContent';
// import TableBuySalesOrder from '../inventory/TableBuySalesOrder';
import TablesPerson from '../person/components/outros/TablesPerson';
import TutorialStockProducts from '../buy/components/tutorial/TutorialStockProducts';
import RegisterBuysSalesDialog from '../financial/components/FormMain/RegisterBuysSalesDialog';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';
import Typewriter from '../../components/Typewriter/Typewriter';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';
import ListIcon from '@mui/icons-material/List';
import { PlaylistAddCheck } from '@mui/icons-material';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import { useState } from 'react';
import { useEffect } from 'react';
import TableSaleOrder from './TableSalesOrder';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`sale-tabpanel-${index}`} aria-labelledby={`tabela: ${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, type) {
  return {
    id: `tabela-vendas-${index}`,
    'aria-controls': `Tabela: ${type}`,
  };
}

export default function SalesContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const objText = {
    icon: <LocalShipping sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Vendas', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Realize a solicitação de venda, acompanhe o rastreamento, controle a baixa do estoque e gerencie o lançamento no financeiro.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar clientes', '2- Pedido de Venda', '3- Visualizar os pedidos em aberto', '4- Visualizar os pedidos recebidos', '5- Gerenciar o estoque', '6- Gerenciar o finaceiro'], // Texto complementar ou final
    linkButton: ['openPeopleRegistrarModal', 'openSalesRegistrarModal', '/sales', '/sales', '/inventory', '/financial'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  const [showTypewriter, setShowTypewriter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypewriter(true);
    }, 6500);

    return () => clearTimeout(timer); // Limpeza do timer quando o componente é desmontado
  }, []);

  const [showTypewriterStart, setShowTypewriterStart] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypewriterStart(true);
    }, 5500);

    return () => clearTimeout(timer); // Limpeza do timer quando o componente é desmontado
  }, []);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={5} md={6}>
              <Box sx={{ display: 'flex' }}>
                <LocalShipping sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Vendas
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Gerencia&nbsp;as vendas&nbsp;da&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <RegisterBuysSalesDialog type={'sales'} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialStockProducts />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <div className="d-lg-none">
              <Box sx={{ minHeight: 35 }}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={80} minHeight={0} text="Realize o pedido de venda e acompanhe o rastreamento até a entrega final de maneira simples e eficiente." />
                </Typography>
              </Box>
            </div>

            <div className="d-none d-lg-block">
              <Box sx={{ minHeight: 35 }}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={20} minHeight={0} text="Realize o pedido de venda e acompanhe o rastreamento até a entrega final de maneira simples e eficiente." />
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <Card sx={{ p: 3 }}>
              {showTypewriter ? (
                <Typewriter text={`Clique em "PEDIDO DE VENDA" para registrar a solicitação de venda.`} variant={'subtitle1'} color={'#2e7d32'} />
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  {showTypewriterStart && <ThreeDotsSpinner />}
                </Box>
              )}
            </Card>
          </Grid>
        </div>
      </div>

      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="Tabelas de Venda">
                {/* <Tab label="Pedidos em aberto" {...a11yProps(0, 'Pedidos em aberto')} />
                <Tab label="Pedidos enviados" {...a11yProps(1, 'Pedidos enviados')} /> */}
                <Tab
                  label={
                    <Grid container direction="row" alignItems="center" sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
                      <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />
                      Pedidos em aberto
                    </Grid>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <Grid container direction="row" alignItems="center" sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
                      <PlaylistAddCheck sx={{ mr: 0.5, mb: 0.2 }} />
                      Pedidos recebidos
                    </Grid>
                  }
                  {...a11yProps(1)}
                />
                <Tab label="Clientes" {...a11yProps(2, 'Clientes')} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {/* <TableBuySalesOrder type={'income'} /> */}
              <TableSaleOrder type={'Aberta'} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* <SalesInventoryContent /> */}
              <TableSaleOrder type={'Finalizada'} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TablesPerson tab_active={['Cliente', 'Ativo']} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
