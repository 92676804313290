import "./ChangeMapyType.css"
import mapToogle from '../../../../assets/png/Google_Maps_icon_.png'
import { Button } from "@mui/material"

const ChangeMapyType = ({ handleMapToggle }) => {
    //const { handleMapToggle } = props

    const handleMapToggle1 = () => {
        console.log('teste2')
    }

    var style = {minWidth: "50px" };
    if (window.innerWidth < 400) {
      style = {width: '3rem', height: '3rem'};
    }
    return (
        <div className="changeMapTypeContainer">
            <Button color="success" onClick={handleMapToggle}>
                <img className="containerToggle" src={mapToogle} alt="Google maps" style={style} />
            </Button>
        </div>
    )
}

export default ChangeMapyType;