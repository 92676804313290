import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="main-footer no-print">
      <strong className="h6">
        <small>
          Copyright © 2011-2023{" "}
          <Link to="https://www.cptsoftwares.com.br/" className="text-success" target="_blank" rel="noreferrer">
            CTP Softwares
          </Link>
          .
        </small>
      </strong>
      <small>Todos direitos reservados.</small>
      <div className="float-right d-none d-sm-inline-block">
        <small>
          <b>Version</b> 1.0
        </small>
      </div>
    </footer>
  );
}
