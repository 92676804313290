import { Link, NavLink } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import MenuMsn from "../../MessengerCreations/DialogMessenger/forms/MenuMsn";
import { Alert, Stack } from "@mui/material";
import CardTimeLine from "./CardTimeLine";


export default function CardTimeLineMessengerAgricultutal() {
  //https://fontawesome.com/v4/icon/flask
  const date = new Date()
  return (
    <>
      <section className="">
        <div className="container-fluid " style={{ maxWidth: 900 }}>
          <div className="content-header">
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error">Página em contrução</Alert>
            </Stack>
          </div>
          <div className="row" >
            <div className="col-md-12">
              <div className="timeline">
                <div className="time-label">
                  <span className="text-secondary">01 dez. 2022</span>
                </div>
                {/* card01 */}

                <div>
                  <i className="fa fa-truck bg-success" />
                  <div className="timeline-item">
                    <CardTimeLine />
                  </div>
                </div>
                <div>

                </div>
                {/* fim do card01 */}
                <div className="time-label">
                  <span className="text-secondary">19 Jan. 2022</span>
                </div>
                <div>
                  <i className="fa fa-leaf bg-secondary" />
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock " /> 2 dias
                    </span>
                    <h3 className="timeline-header no-border">
                      <small className="text-muted ">Miguel</small>
                    </h3>
                    <div className="timeline-body">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-start align-items-center  ">
                            <p className="d-flex flex-column text-left">
                              <span className="font-weight-bold">
                                <i className="fa fa-check text-secondary" /> Plantio
                              </span>
                              <small className="text-muted ml-3">
                                <a className="text-secondary">Talhão 01: Safra PLANTAÇÃO DE MILHO 22/23</a>
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-end align-items-center  ">
                            <i className="image">
                              <img className="img-rounded elevation-1" src="/dist/image/plantio.jpeg" width={80} style={{ filter: `grayscale(100%)` }} />
                            </i>
                            <MenuMsn />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card01 */}
                <div className="time-label">
                  <span className="text-secondary">1 Jan. 2021</span>
                </div>
                <div>
                  <i className="fa fa-flask bg-secondary" />
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" /> 12:05
                    </span>
                    <h3 className="timeline-header no-border">
                      <small className="text-muted ">Leonardo Solar</small>
                    </h3>
                    <div className="timeline-body">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-start align-items-center  ">
                            <p className="d-flex flex-column text-left">
                              <span className="font-weight-bold text-secondary">
                                <i className="fa fa-arrow-right text-secondary" /> Análise do solo
                              </span>
                              <small className="text-muted ml-3">
                                <a className="text-secondary">Talhão 01: Safra PLANTAÇÃO DE MILHO 22/23</a>
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-end align-items-center  ">
                            <i className="image">
                              <img className="img-rounded elevation-1" src="/dist/image/analisesolo.png" width="80" style={{ filter: `grayscale(100%)` }}></img>
                            </i>
                            <MenuMsn />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* fim do card01 */}

                <div>
                  <i className="fas fa-clock bg-gray" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}


const Menu = () => {

  return (
    <div className="nav-item dropdown ">
      <NavLink to="#" className="nav-link" data-toggle="dropdown" role="button">
        < MoreVertIcon sx={{ color: "#808080" }} />
        {/* <Avatar sx={{ width: 32, height: 32, bgcolor: "#07bc0c" }}></Avatar> */}
      </NavLink>
      <div className="dropdown-menu  dropdown-menu-right">
        <Link to="#" className="dropdown-item">
          <i className="fas fa-edit mr-2" /> Editar
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item">
          <i className="fas fa-check mr-2" /> Resolvido
        </Link>
        <Link to="#" className="dropdown-item">
          <i className="fas fa-exclamation-triangle mr-2" /> Não Resolvido
        </Link>
        <Link to="#" className="dropdown-item">
          <i className="fas fa-circle mr-2" /> Parado
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item">
          <i className="fas fa-times mr-2" /> Excluir
        </Link>
        {/* <Link to="#" className="dropdown-item dropdown-footer">
                  Fechar modal
              </Link> */}
      </div>
    </div>
  )
}