import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TutoriaActivitiesInputAccordion from './TutoriaActivitiesInputAccordion';
import TutoriaActivitiesEssentialAccordion from './TutoriaActivitiesEssentialAccordion';
import TutoriaActivitiesFinancialAccordion from './TutoriaActivitiesFinancialAccordion';
import TutoriaActivitiesReproductiveAccordion from './TutoriaActivitiesReproductiveAccordion';
import TutoriaActivitiesMarketingAccordion from './TutoriaActivitiesMarketingAccordion';
import TutoriaActivitiesOtherAccordion from './TutoriaActivitiesOtherAccordion';

export default function TutorialaddActivitiesAccordion() {

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}>Atividades de Entrada dos Animais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesInputAccordion />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}>Atividades Essenciais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesEssentialAccordion />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}> Atividades Financeiras</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesFinancialAccordion />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}> Atividades Reprodutivas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesReproductiveAccordion />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}> Atividades de Marketing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesMarketingAccordion />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={700} style={{ color: "#2e7d32" }}> Outras Atividades</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoriaActivitiesOtherAccordion />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Registrar a realização de uma atividade</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Caso já tenha o projeto da criação criado, é hora de inciarmos o gerenciamento das criações através dos registros das atividades.
            </Typography>
            <div style={{ display: 'flex' }}>
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, margin: '0 10px' }}
                onClick={() => handleButtonClickLivestock()}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Atividades
                  </Typography>
                </Stack>
              </ButtonBase>
            </div>
          </List>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </div>
  );
}