import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Guide from './Guide';
import { Card, CardMedia, Container, Paper } from '@mui/material';
import AccessProprety from './AccessProprety';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GuideTabsProperty() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>

            <Box sx={{ ml: 1, display: "flex" }}>

                <Typography fontWeight={700}>Barra de navegação: nível propriedade</Typography>

            </Box>
            <Box sx={{ textAlign: "center", }}>
                <Card sx={{ p: 2, overflow: "auto" }}>
                    <img
                        sx={{ width: "100%" }}
                        src="/dist/image/bar_property.png"
                        title="green iguana"
                    />
                </Card>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
                <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Fluxo de trabalho" {...a11yProps(0)} />
                    {/* <Tab label="Mapa" {...a11yProps(1)} />
                    <Tab label="Central" {...a11yProps(2)} />
                    <Tab label="Equipe" {...a11yProps(3)} />
                    <Tab label="solicitações" {...a11yProps(4)} />
                    <Tab label="Criações" {...a11yProps(5)} />
                    <Tab label="Safras" {...a11yProps(6)} /> */}

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AccessProprety />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
            </TabPanel>
            <TabPanel value={value} index={2}>
            </TabPanel>
            <TabPanel value={value} index={3}>
            </TabPanel>
            <TabPanel value={value} index={4}>
            </TabPanel> */}

        </Box>
    );
}
