import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Avatar, Box, ListItemAvatar } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import PersonIcon from '@mui/icons-material/Person';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import GroupIcon from '@mui/icons-material/Group';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

export default function FirstAcessTeamList() {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box>
        <ListItem
          key={'passo_1'}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <PersonIcon style={{ color: '#2e7d32' }} />
            </span>
          }
        >
          <ListItemText primary={`Passo 1: Para registrar um usuário no sistema, clique no botão verde "ADICIONAR EQUIPE" localizado no canto superior.`} />
        </ListItem>
        {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt="Imagem" src="/dist/image/map_search.png" style={{ maxWidth: '100%' }} />
                </ListItem> */}
      </Box>
      <ListItem
        key={'passo_2'}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <PersonIcon style={{ color: '#2e7d32' }} />
          </span>
        }
      >
        <ListItemText primary={`Passo 2: Defina um nome, um e-mail e uma senha segura.`} />
      </ListItem>
      <ListItem
        key={'passo_3'}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <HomeWorkIcon />
          </span>
        }
      >
        <ListItemText primary={`Passo 3: Selecione a(s) fazenda(s) que deseja incluir o novo usuário.`} />
      </ListItem>
      <ListItem
        key={'passo_4'}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <SwitchAccountIcon />
          </span>
        }
      >
        <ListItemText primary={`Passo 4: Selecione o perfil que o usuário terá, onde cada perfil tem certas permissões para acessar todo ou somente partes do sistema. `} />
      </ListItem>
      <ListItem
        key={'passo_5'}
        disableGutters
        secondaryAction={
          <span aria-label="comment">
            <SaveAltIcon />
          </span>
        }
      >
        <ListItemText primary={`Passo 5: Em seguida, confirme os seus dados e clique em enviar.`} />
      </ListItem>
    </List>
  );
}
