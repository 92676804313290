
import Success from './Sucess'

const SucessInfo = ({ handleClose, formik }) => {

    const { values } = formik;
    console.log(values)

    if (values.id_role == 0) {
        values.id_role = 4
    }

    setTimeout(() => {
        handleClose()
    }, 2500);

    return (
        <>
            <Success />

        </>
    )
}

export default SucessInfo