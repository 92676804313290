import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from "date-fns";
import { Box, Grid, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const theme = createTheme(
    { palette: { primary: { main: '#1976d2' } } },
    deDE, // use 'de' locale for UI texts (start, next month, ...)
);

export default function EditBasicDatePickerLivestock({ data, setData }) {
    const [date_start, setValue] = useState(dayjs(data.date_start));
    const [dateEnd, setValueEnd] = useState(dayjs(data.date_prev_end));

    // se os valores de dateStar e dateEnd forem adicionados
    useEffect(() => {
        const newValueStart = dayjs(date_start).year();
        const newValueEnd = dayjs(dateEnd).year();
        setData(prevState => ({ ...prevState, "date_start": newValueStart }));
        setData(prevState => ({ ...prevState, "dateEnd": newValueEnd }));

        // console.log(dayjs(date_start).format('YYYY-MM-DD HH:mm:ss'));
        // console.log(dayjs(dateEnd).format('YYYY-MM-DD HH:mm:ss'));

        setData(prevState => ({ ...prevState, date_start: dayjs(date_start).format('YYYY-MM-DD HH:mm:ss') }));
        setData(prevState => ({ ...prevState, date_prev_end: dayjs(dateEnd).format('YYYY-MM-DD HH:mm:ss') }));
    }, [date_start, dateEnd])

    /*useEffect(() => {
        console.info('EditBasicDatePickerLivestock');
    }, [data])*/

    const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", "Sa": "sáb", "Do": "dom", }

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={12} sx={{ my: 2, height: 125 }} >
                <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <div className="d-lg-none">
                        <Stack spacing={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <MobileDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Início*"
                                        value={date_start}
                                        maxDate={new Date()}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            console.log(dayjs(newValue).format('YYYY-MM-DD HH:mm:ss'));
                                            const newValueEnd = dayjs(newValue).add(1, 'year');
                                            setValueEnd(newValueEnd);
                                        }}
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                                        renderInput={(params) => <TextField {...params} color="success" />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MobileDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Término*"
                                        value={dateEnd}
                                        minDate={date_start}
                                        onChange={(newValue) => { setValueEnd(newValue); }}
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                                        renderInput={(params) => <TextField {...params} color="success" />}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <div className="d-none d-lg-block">
                        <Stack spacing={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Início*"
                                        value={date_start}
                                        maxDate={new Date()}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            console.log(dayjs(newValue).format('YYYY-MM-DD HH:mm:ss'));
                                            const newValueEnd = dayjs(newValue).add(1, 'year');
                                            setValueEnd(newValueEnd);
                                        }}
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                                        renderInput={(params) => <TextField {...params} color="success"
                                            error={!date_start}
                                            helperText={!date_start && (<p id="date_start" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        //views={['day', 'month', 'year']}
                                        label="Data de Término*"
                                        value={dateEnd}
                                        minDate={date_start}
                                        onChange={(newValue) => { setValueEnd(newValue); }}
                                        color="success"
                                        adapterLocale="pt"
                                        inputFormat="DD/MM/YYYY"
                                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                                        renderInput={(params) => <TextField {...params}
                                            color="success"
                                            error={!dateEnd}
                                            helperText={!dateEnd && (<p id="dateEnd" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 2, color: "#808080" }}>
                        Por padrão, o sistema considera 1 ano como a data de início e término da atividade.
                        <br />
                        Caso preferir, você pode alterar as 2 datas.
                    </Typography>
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
}