// Fora do escopo do componente
export function createNotificationHarvest(harvest) {
  // Substituir os textos no objeto property
  // console.log('createNotificationHarvest1');
  // console.log(harvest);
  const statusMapping = {
    label: 'Nome do projeto pecuário',
    areas: 'Quantidade de áreas de produção',
  };

  const formattedStatus = Object.entries(harvest.status).reduce((acc, [key, value]) => {
    acc[statusMapping[key]] = value;
    return acc;
  }, {});

  // Separar registros completos e incompletos
  const complete = [];
  const incomplete = [];

  Object.entries(formattedStatus).forEach(([key, value]) => {
    if (value === 'Ok') {
      complete.push(key);
    } else {
      incomplete.push(key);
    }
  });

  const titleText = 'Inicie também a sua gestão agrícola.';
  const completeText = complete.length > 0 ? complete.join(', ') : '';
  const incompleteText = incomplete.length > 0 ? incomplete.join(', ') : 'Nenhum registro incompleto';

  //const titleNotification = `Para inciar é necessário: ${incompleteText}.`;
  const titleNotification = `É muito fácil.`;
  const titleAction = `\n Por favor, clique aqui e em seguida você será redirecionado para tela de criação dos projetos agrícolas.`;

  const title = `${titleText}\n${completeText}\n${titleNotification}\n${titleAction}`;

  const notificationHarvest = {
    group: 'Geral',
    type: 'info',
    title: 'Informação',
    text: title,
    link: '/harvest',
  };

  return { notificationHarvest, hasIncompleteHarvest: incomplete.length > 0 };
}
