import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TablesActivitiesCreations from '../ListActivities/TablesActivitiesCreations';
import BoardTypes from '../../../../components/Boards/BoardsActivities/BoardTypes';
import BoardProgress from '../../../../components/Boards/BoardsActivities/BoardProgress';
import BoardTimeLine from '../../../../components/Boards/BoardsActivities/BoardTimeLine';
import BoardTimeLineGraph from '../../../../components/Boards/BoardsActivities/BoardTimeLineGraph';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, mt: 1, ml: 0, mr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, type) {
  return {
    id: `tabela_atividades_campo-${index}`,
    'aria-controls': `Tabela: ${type}`,
  };
}

export default function CardActivitiesCreations({ isLoadingActivities, setIsLoadingActivities }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          // textColor="success"
          TabIndicatorProps={{ style: { background: 'green' } }}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Tabelas Atividades de Campo"
          sx={{
            '.Mui-selected': {
              color: '#2e7d32 !important',
              fontWeight: 'bold',
            },
          }}
        >
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Lista
              </Grid>
            }
            {...a11yProps(0, 'Lista')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Cronograma
              </Grid>
            }
            {...a11yProps(1, 'Cronograma')}
          />

          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListAltIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Tipos
              </Grid>
            }
            {...a11yProps(2, 'Tipos')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ViewModuleIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Progresso
              </Grid>
            }
            {...a11yProps(3, 'Progresso')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ViewModuleIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Tempo
              </Grid>
            }
            {...a11yProps(4, 'Tempo')}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <DeleteForeverIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Lixeira
              </Grid>
            }
            {...a11yProps(5, 'Lixeira')}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TablesActivitiesCreations isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} table_active={'realized'} status={'edit'} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BoardTimeLineGraph type={'livestock'} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BoardTypes />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BoardProgress />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BoardTimeLine />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TablesActivitiesCreations isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} table_active={'realized'} status={'trash'} />
      </TabPanel>
    </Box>
  );
}
