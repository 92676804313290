import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SignpostIcon from '@mui/icons-material/Signpost';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export default function GoalBadge() {
  return (
    <Stack direction="row" spacing={2} style={{ verticalAlign: 'midle' }}>
      <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" >
        <SignpostIcon style={{ color: '#FFF', fontSize: 46 }} />
      </StyledBadge>
      <span style={{ position: 'absolute', fontSize: '8.5px', marginTop: '8.5px', marginLeft: '9px' }}><strong>Planos</strong></span>
      <span style={{ position: 'absolute', fontSize: '8.5px', marginTop: '23px', marginLeft: '12px' }}><strong>Ações</strong></span>
    </Stack>
  );
  /*return (
    <Stack direction="row" spacing={2}>
      <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" >
        <SignpostIcon style={{ color: '#fff', fontSize: 40 }}/>
      </StyledBadge>
    </Stack>
  );*/
}