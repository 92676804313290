import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, Typography } from '@mui/material';
import FirstAcessTeamList from './FirstAcessTeamList';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import GroupsIcon from '@mui/icons-material/Groups';

export default function FirstAcessTeam() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <GroupsIcon sx={{ mr: 1 }} /> Equipe de trabalho
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão de pessoas
          </Typography>
        </DialogTitle>

        <DialogTitle id="alert-dialog-title">
          <div className="d-lg-none">
            <Box>
              <Typography gutterBottom sx={{ mt: 1, mb: -1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={80} text="É importante um administrador ou gestor estruturar toda a sua equipe. Veja quais são os 4 passos para registrar um integrante da equipe no software." />
              </Typography>
            </Box>
          </div>

          <div className="d-none d-lg-block">
            <Box>
              <Typography gutterBottom sx={{ mt: 1, mb: -1 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="É importante estruturar toda a sua equipe. Veja quais são os 4 passos para registrar um integrante da equipe no software." />
              </Typography>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
          <FirstAcessTeamList />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} onClick={handleClose} color="success" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
