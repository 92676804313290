import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from "date-fns";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    deDE, // use 'de' locale for UI texts (start, next month, ...)
);

export default function BasicDatePicker() {
    const date = new Date()
    //post.date = format(date, "MMMM do, yyyy H:mma");
    const dateNow = format(date, "dd-MM-yyyy");
    const [value, setValue] = React.useState(dayjs(date));

    /* <div className="d-lg-none">Esconde em telas maiores que lg</div>
<div className="d-none d-lg-block">Esconde em telas menores que lg</div> */

    localStorage.setItem('date', JSON.stringify(value))
    const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", "Sa": "sáb", "Do": "dom", }

    return (
        <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="d-lg-none">
                <Stack spacing={3}>

                    <MobileDatePicker
                        //views={['day', 'month', 'year']}
                        label="data mobile"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        adapterLocale="pt"
                        inputFormat="DD/MM/YYYY"
                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                        renderInput={(params) => <TextField {...params} />}

                    />

                </Stack>
            </div>
            <div className="d-none d-lg-block">
                <Stack spacing={3}>


                    <DesktopDatePicker
                        //views={['day', 'month', 'year']}
                        label="data desktop"
                        value={value}
                        //minDate={dayjs('2017-01-01')}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}

                        inputFormat="DD/MM/YYYY"
                        dayOfWeekFormatter={(day) => `${weekdays[day]}` }
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />

                    {/* <DatePicker
                    disableFuture
                    label="Responsive"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                /> */}

                </Stack>
            </div>
        </LocalizationProvider>
    );
}