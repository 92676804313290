import { useState, useEffect } from "react";
import Loading from "../../components/Loader/Loading";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import MessengerContent from "./MessengerContent";
import Footer from "../../components/Footer/Footer";

function Messenger() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('atualiza')
    console.log(isLoading)
    setIsLoading(false)
  }, [isLoading]);

  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      {isLoading == true ? <Loading /> :
        <MessengerContent isLoading={isLoading} setIsLoading={setIsLoading} />
      }
      <Footer />
    </>
  );
}

export default Messenger;
