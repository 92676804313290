import React from 'react';
import MapDraw from './components/MapDraw';

export default function MapsAreasDrawContent() {
  //paths && paths.length >1
  console.log('MapsAreasDrawContent');
  // position: 'absolute' começou a dar erro

  const numberHeight = '94vh';

  return (
    <>
      <div>
        <div style={{ position: '', minHeight: '100vh', overflow: 'hidden' }}>
          <MapDraw numberHeight={numberHeight} />
        </div>
      </div>
    </>
  );
}
