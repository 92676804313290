import { SvgIcon } from "@mui/material";
import { IconBeefCattle } from "./unit/IconBeefCattle";
import { IconMilkCow } from "./unit/IconMilkCow";
import { IconGeneral } from "./unit/IconGeneral";
import { IconPork } from "./unit/IconPork";
import { IconChicken } from "./unit/IconChicken";
import { IconFish } from "./unit/IconFish";


// icon com as categorias
// tabela livestock_category
export default function IconsLivestock({ type, widthStyles, fill }, props) {
  //width={200} height={200} style={{ width: "100%", height: "auto" }}
  // console.log("type-IconsLivestock");
  // console.log(type);
  //{ name: "batch", value: true, title: "Novo Lote", icon: <CropOriginalIcon style={{ fontSize: 50 }} /> },
  switch (type) {
    case "Bovinocultura de Corte":
      return <IconBeefCattle widthStyles={widthStyles} fill={fill} />;
    case "Bovinocultura de Leite":
      return <IconMilkCow widthStyles={widthStyles} />;
    case "Suinocultura":
      return <IconPork widthStyles={widthStyles} />;
    case "Avicultura de Corte":
      return <IconChicken widthStyles={widthStyles} />;
    case "Piscicultura":
      return <IconFish widthStyles={widthStyles} />;
    default:
      //case "Geral":
      return <IconGeneral widthStyles={widthStyles} fill={fill} />;
    //default:
    //  console.log("Desculpe, estamos sem nenhuma " + type + ".");
    //  return <p>ICON</p>;
  }
}

// tabela livestock_category
// 1	1	Apicultura
// 2	11	Aquicultura
// 3	2	Avicultura de Corte
// 4	2	Avicultura de Postura
// 5	3	Bovinocultura de Corte
// 6	3	Bovinocultura de Leite
// 7	5	Caprinocultura de Corte
// 8	5	Caprinocultura de Leite
// 9	4	Carcinocultura
// 10	6	Cunicultura
// 11	7	Equinocultura
// 12	9	Piscicultura
// 13	10	Suinocultura
// 14	8	Ovinocultura de Corte
// 15	8	Ovinocultura de Lã
// 16	8	Ovinocultura de Leite
// 17	11	Outras (corte)
// 18	11	Outras (produção)
// 19	3	Trabalho Bovino
// 20	7	Trabalho Equino
