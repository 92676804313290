import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import api from '../../services/api'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'

// create the store
const useActivityInputReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityInput: [],
        asyncList: [],
        loading: true,
        //ActivityInputRepos: { label: "", id: 0, id_property: 0, tipo: 'ENTRADA DE ANIMAIS' },
        fetch: async (item) => {
          // Pego o id current da proriedade
          console.log('fetch:useActivityInputReposStore  ')
          var farm_id = localStorage.getItem('Property') || 1
          if (!item) {
            item = { id_farm: farm_id }
          } else if (!item.id_farm) {
            item.id_farm = farm_id
          }
          const header = { header: { 'Content-Type': 'application/json' } }
          // listo as atividade de entrada
          // const response = await api.post("activityInput/list", (item), header);
          const response = await api.get('activityInput/list', header)

          //resposta.data - for of
          // if(date_prev_exit > new Date()){
          //update na rota e passar id
          // resposta do post  val = response.data
          // this.updateLivestockStore: (id, 'all', val)
          // }
          const data = await response.data
          await set({
            listActivityInput: data._value.map((activityInput) => ({
              id: activityInput.id,
              id_user: activityInput.id_user,
              user_name: activityInput.user_name,
              id_property: activityInput.id_property,
              id_livestock: activityInput.id_livestock,
              id_group: activityInput.id_group,
              id_areamap: activityInput.id_areamap,
              areamap_use: activityInput.areamap_use,
              type_activity: activityInput.type_activity,
              name_activity: activityInput.name_activity,
              stage: activityInput.stage,
              nameArea: activityInput.nameArea,
              nameBatch: activityInput.nameBatch,
              numberAnimals: activityInput.numberAnimals,
              date_start: dayjs(activityInput.date_start).format('YYYY-MM-DD HH:mm:ss'),
              date_start_f: dayjs(activityInput.date_start).format('DD/MM/YYYY'),
              date_prev_exit: dayjs(activityInput.date_prev_exit).format('YYYY-MM-DD HH:mm:ss'),
              date_prev_end_f: dayjs(activityInput.date_prev_end).format('DD/MM/YYYY'),
              isDeleted: activityInput.isDeleted,
              progress: activityInput.progress,
              planning: activityInput.planning,
              tipo_repos: 'ENTRADA DE ANIMAIS',
              activity: 'ENTRADA',
              type: 'activity',
            })),
          })
          //set({ asyncList: await response.json(), loading: false })
          //set({ asyncList: await response.data, loading: false })
          // set({
          //   ActivityInputRepos: (!data || data._value.length == 0 ? { label: "", id: 0, id_property: 0, tipo_repos: 'ENTRADA DE ANIMAIS' } : { label: data._value[0].name_activity, id: data._value[0].id, id_property: data._value[0].id_property, tipo_repos: 'ENTRADA DE ANIMAIS' })
          // })
          const lista = JSON.parse(localStorage.getItem('listActvs'))
          const lista_i = get().listActivityInput

          let array_aux = lista.filter((activity) => {
            if (activity.activity != 'ENTRADA') {
              return activity
            }
          })
          const res = array_aux.concat(lista_i)
          localStorage.setItem('listActvs', JSON.stringify(res))

          return data
        },
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getActivityInputStore: (key, val) => {
          return get().listActivityInput.filter((activityInput) => {
            if (activityInput[key] == val) {
              return activityInput
            }
          })
        },

        // listLivestock: [],
        // livestockRepos: { label: "", id: 0, id_property: 0, tipo: 'Criações' },
        // fetch: async (item) => {
        //   var farm_id = (localStorage.getItem("Property") || 1);

        //   if (!item) {
        //     item = { 'id_farm': farm_id };
        //   } else if (!item.id_farm) {
        //     item.id_farm = farm_id;
        //   }
        //   const header = { header: { "Content-Type": "application/json" } };
        //   const response = await api.post("livestock/list", (item), header);
        //   const data = await response.data;
        //   set({
        //     listLivestock:
        //       data._value.map((livestock) => ({
        //         id: livestock.id,
        //         id_property: livestock.id_property,
        //         id_tipe: livestock.id_tipe,
        //         tipo: livestock.tipo,
        //         name: livestock.name,
        //         label: livestock.name,
        //         date_start: dayjs(livestock.date_start).format('YYYY-MM-DD HH:mm:ss'),
        //         date_prev_end: dayjs(livestock.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
        //         date_end: (livestock.date_end ? dayjs(livestock.date_end).format('YYYY-MM-DD HH:mm:ss') : ''),
        //         data_inicial: dayjs(livestock.date_start).format('DD/MM/YYYY'),
        //         data_fim: dayjs(livestock.date_end || livestock.date_prev_end).format('DD/MM/YYYY'),
        //         situation: livestock.situation,
        //         observation: livestock.observation,
        //         isDeleted: livestock.isDeleted,
        //         animais: livestock.animais,
        //         lotes: livestock.lotes,
        //         areas: JSON.parse(livestock.areas),
        //         images: [],
        //         tipo_repos: 'Criações'
        //       }))
        //   })
        //   set({
        //     livestockRepos: (!data || data._value.length == 0 ? { label: "", id: 0, id_property: 0, tipo_repos: 'Criações' } : { label: data._value[0].name, id: data._value[0].id, id_property: data._value[0].id_property, tipo_repos: 'Criações' })
        //   })
        //   return data;
        // },
        // changeLivestockRepos: (livestockRepos) => set((state) => ({ livestockRepos })),
        // // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        // getLivestockStore: (key, val) => {
        //   return get().listLivestock.filter((livestock) => {
        //     if (livestock[key] == val) {
        //       return livestock;
        //     }
        //   })
        // },
        // // Adiciona uma nova livestock à listLivestock
        addLivestockStore: (livestock) => {
          set((state) => ({
            listLivestock: [...state.listLivestock, livestock],
          }))
        },
        getActivityStore: (key, val) => {
          return get().listActivityInput.filter((activity) => {
            if (activity[key] == val) {
              return activity
            }
          })
        },
        // // Gera uma nova listLivestock sem a livestock informada
        // delLivestockStore: (id) => {
        //   set({
        //     listLivestock:
        //       get().listLivestock.filter((livestock) => {
        //         if (livestock.id != id) {
        //           return livestock;
        //         }
        //       })
        //   })
        // },
        // // atualiza listLivestock de acordo com id, key e val informados
        // updateLivestockStore: (id, key, val) => {
        //   set({
        //     listLivestock:
        //       get().listLivestock.map((livestock) => {
        //         if (livestock.id === id) {
        //           if (key == 'all') { // atualiza todos os dados
        //             livestock = val;
        //           } else {
        //             livestock[key] = val;
        //           }
        //         }
        //         return livestock;
        //       })
        //   })
        //   return get().listLivestock;
        // },
      }),

      { name: 'reposActivityInput' }
    )
  )
)

export default useActivityInputReposStore
