import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

export default function TutorialReports({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  //handleClickOpen()

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountTreeIcon sx={{ mr: 1 }} /> Relatórios
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Administração da Propriedade
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Os relatórios proporcionam uma visão detalhada e organizada da gestão da propriedade, das criações e das safras, facilitando a análise e a tomada de decisões.
            </Typography>
          </Box>
          <List component="div" disablePadding>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography paragraph variant="subtitile2">
                A nível de propriedade:
              </Typography>
            </Box>
            <ListItem>
              <Typography>Lista das Criações e Safras.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Contas a pagar.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Custos agrupados por categoria e recursos.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produção, custos e lucro da propriedade</Typography>
            </ListItem>
            <ListItem>
              <Typography>Itens em estoque com quantidades e valores.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Patrimônios da propriedade.</Typography>
            </ListItem>

            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography paragraph variant="subtitile2">
                A nível de criações:
              </Typography>
            </Box>
            <ListItem>
              <Typography>Lista das Criações.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Atividades e recursos das Criações.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Lotes e contas das Criações.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Custos de produção e rentabilidade das Criações.</Typography>
            </ListItem>

            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography paragraph variant="subtitile2">
                A nível de safras::
              </Typography>
            </Box>
            <ListItem>
              <Typography>Lista das safras ativas com áreas e cultivares.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Atividades realizadas com insumos, máquinas e equipe.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Quantidade de recursos utilizados nas safras.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Contas a pagar das safras.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produção, custos e lucro da safra.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Movimentações de estoque das safras.</Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
