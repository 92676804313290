import React from "react";
import MapId from "./MapId";

export default function AreaMapContent() {
  return (
    <div className="">
      <div className="">
        {/* Restante do conteúdo */}
        <div className="">
          <MapId />
        </div>
      </div>
    </div>
  );
}
