import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
//import "./Typewriter.css";

const Typewriter = ({ text, variant, color, tam, minHeight }) => {
  const [displayText, setDisplayText] = useState('')
  const [showCursor, setShowCursor] = useState(true)

  useEffect(() => {
    if (typeof text === 'string') {
      // Verifique se 'text' é uma string
      let i = 0
      const interval = setInterval(() => {
        setDisplayText(text.substring(0, i))
        i++
        if (i > text.length) clearInterval(interval)
      }, 50)
      return () => clearInterval(interval)
    } else {
      console.error('Text is not defined or not a string:', text)
    }
  }, [text])

  useEffect(() => {
    // const cursorInterval = setInterval(() => {
      // setShowCursor((prev) => !prev)
    // }, 500)
    // return () => clearInterval(cursorInterval)
    setTimeout(() => {
      setShowCursor((prev) => !prev)
    }, 500);
  }, [])
  const newMinHeight = minHeight || 30

  // transforma o texto em "componente" aceitando tags de quebra de linha e estilos
  const getText = (text) => {
    let text_exibe = text.split('\n')
    return (
      <>
        {text_exibe.map((text, index) => (
          <span key={index}>
            {text} {index < text_exibe.length ? <br /> : <></>}
          </span>
        ))}
      </>
    )
  }

  return (
    <Box style={{ height: tam }}>
      <Box display="flex" alignItems="center" minHeight={newMinHeight} maxWidth="100%">
        <Typography variant={variant || 'subtitle1'} style={{ color: color || '' }}>
          {getText(displayText)}
        </Typography>
        {/* <Typography
          variant="h6"
          className="cursor"
          sx={{
            visibility: showCursor ? "visible" : "hidden",
            border: "4px solid green",
            height: 20,
          }}
        ></Typography> */}
      </Box>
    </Box>
  )
}

export default Typewriter
