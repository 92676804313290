import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Loop } from '@mui/icons-material';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';

export default function RestoreActivitiesCreations({ row, usestore }) {
  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context);

  const { idFarm } = useParams();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const handleClick = async () => {
    const header = { header: { 'Content-Type': 'application/json' } };
    const status = 'edit';
    let url = '';
    let reposActivity = {};

    if (row.activity === 'ENTRADA') {
      url = `/activityInput/delete/${row.id}/${status}`;
      reposActivity = usestore.reposActivityInput;
    } else if (row.activity === 'IDENTIFICAÇÃO') {
      url = `/activityIdentify/delete/${row.id}/${status}`;
      reposActivity = usestore.reposActivityIdentify;
    } else if (row.activity === 'PESAGEM') {
      url = `/activityWeight/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityWeight;
    } else if (row.activity === 'MEDICAMENTOS') {
      url = `/activityMedicines/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityMedicines;
    } else if (row.activity === 'NUTRICAO') {
      url = `/activityNutrition/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityNutrition;
    } else if (row.activity === 'TRANSFERENCIA') {
      url = `/activityTransfer/delete/${row.id}/${status}?param1=${row.nameBatch}&param2=${row.progress}`;
      reposActivity = usestore.reposActivityTransferBatch;
    } else if (row.activity === 'PRODUÇÃO') {
      url = `/activityProduction/delete/${row.id}/${status}?param1=${idFarm}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityProduction;
    }

    await api.delete(url, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          toast.success(`Atividade restaurada com sucesso!`)
          // atualiza o repos
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
          })
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error(`Falha ao restaurar atividade, tente novamente mais tarde!`);
      })
  }

  return (
    <>
      <IconButton color="info" aria-label="Restaurar" onClick={handleClick} title="Restaurar">
        <Loop />
      </IconButton>
    </>
  )
}
