import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { Edit, LocationOn } from '@mui/icons-material'
import IEProgressBars from '../../indexExperience/components/IEProgressBars'
import { green, grey } from '@mui/material/colors'
import StepperIE from '../../indexExperience/components/StepperIE'
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PublicIcon from '@mui/icons-material/Public'
import GroupsIcon from '@mui/icons-material/Groups'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import CardPropertyProducts from '../../property/components/CardPropertyProducts'
import { Span } from '../../../components/Typography'
import MenuPlan from './MenuPlan'
import MapIcon from '@mui/icons-material/Map'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useNavigate } from 'react-router-dom'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'

export default function FirstAccess({ listPropertyRepos }) {
  const navigate = useNavigate()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent
  console.log(user)
  const name = user.name
  const charName = name[0].toUpperCase()
  console.log(charName)

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ ml: 1, display: 'flex' }}>{/* <Typography fontWeight={700}>Produção por área</Typography> */}</Box>

        <Card>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={12} sx={{ m: 2 }}>
              <Typography fontWeight={700}>Configurações iniciais</Typography>
              <Typography>No primeiro acesso ao Fazenda Premium é necessário seguir 4 passos:</Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ m: 0 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Clique aqui e veja o resumo ilustrativo das configurações iniciais</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card sx={{ p: 2, overflow: 'auto' }}>
                      <img sx={{ width: '100%' }} src="/dist/image/firstAcess.png" title="atividades" />
                    </Card>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} sx={{ m: 0 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <PublicIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Passo 1 e 2</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Criar uma propriedade (Registrar o nome e a localização da fazenda)
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} md={6}>
                    <Card>
                      {/* <Box sx={{ p: 2, display: "flex" }}>
                        <PublicIcon style={{ fontSize: 45 }} />
                        <Stack spacing={0.5} sx={{ ml: 1 }}>
                          <Typography fontWeight={700}>Passo 1</Typography>
                          <Typography variant="body2" color="text.secondary">
                            <LocationOn sx={{ color: grey[500] }} /> Registrar o nome e a localização da fazenda
                          </Typography>
                        </Stack>
                      </Box>
                      <Divider /> */}
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                        <Typography>
                          Neste primeiro passo você irá cadastrar o nome da Fazenda e registrar a localização da fazenda no Google Maps. Clique abaixo para ser redirecionado para área do mapa da
                          propriedade. No mapa, pesquise a localização da sua propriedade. Depois clique no botão verde "ÁREA", no canto superior direito da tela e insira o nome da propriedade, área
                          total e clique em atualizar.
                        </Typography>
                      </Stack>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                        <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/mapa')}>
                          <Grid item sx={{ width: 50 }}>
                            <HomeWorkIcon />
                          </Grid>
                          <Typography fontWeight={700}>Clique aqui</Typography>
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <MapIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Passo 3</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Desenhar as áreas de produção no Google Maps.
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                        <Typography>
                          Neste segundo passo você irá registrar as áreas de produção da sua propriedade. Clique abaixo para ser redirecionado para tela "Área". Em Áreas, clique no botão verde "ÁREA",
                          no canto superior direito da tela para desenhar a sua área.
                        </Typography>
                      </Stack>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                        <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/areasmaps')}>
                          <Grid item sx={{ width: 50 }}>
                            <ListAltIcon />
                          </Grid>
                          <Typography fontWeight={700}>Clique aqui</Typography>
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <ListAltIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Passo 4</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Criar a sua primeira safra ou criação.
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <Divider />
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                        <Typography>
                          Agora é hora de registrar a sua criação ou safra. Com a criação ou safra criada, você começará a planejar e a gerenciar as atividades de produção. Clique abaixo para ser
                          redirecionado para tela "Criação" o "Safra".
                        </Typography>
                      </Stack>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={6}>
                          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                            <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/livestock')}>
                              <Grid item sx={{ width: 50 }}>
                                <ListAltIcon />
                              </Grid>
                              <Typography fontWeight={700}>Clique aqui (Criação)</Typography>
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="4px" padding="16px">
                            <Button color="success" aria-label="upload picture" component="label" onClick={() => navigate('/harvest')}>
                              <Grid item sx={{ width: 50 }}>
                                <ListAltIcon />
                              </Grid>
                              <Typography fontWeight={700}>Clique aqui (Safra)</Typography>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card>
                <Box sx={{ p: 2, display: 'flex' }}>
                  <AirlineStopsIcon style={{ fontSize: 45 }} />
                  <Stack spacing={0.5} sx={{ ml: 1 }}>
                    <Typography fontWeight={700}>Próximos passos</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <LocationOn sx={{ color: grey[500] }} /> Planejar e gerenciar as atividades de produção.
                    </Typography>
                  </Stack>
                </Box>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                  <Typography>
                    Com o "Projeto Criação" ou " Projeto Safra" criado, podemos planejar a meta de produção, ÍCONE PRODUÇÃO, ou começar a realizas as atividades de produção, ÍCONE ATIVIDADES.{' '}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  )
}

//https://aegro.com.br/termos-de-uso/
