import FullScreenDialog from "./FullScreenDialog";
import { Box, styled } from "@mui/system";
import { Breadcrumb } from "../Breadcrumb";
import SimpleCard from "../SimpleCard";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

export default function Dialog() {
  return (
    <Container>
      <SimpleCard title="full-screen dialog">
        <FullScreenDialog />
      </SimpleCard>
    </Container>
  );
}
