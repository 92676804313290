import { useContext, useState } from "react";
import { Box, Container, Paper } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import useStore from "../../../../../store/useStore";
import { Context } from "../../../../../components/Context/AuthContext";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import FormBatchStep1 from "./FormBatchStep1";
import FormBatchStep2 from "./FormBatchStep2";
import FormBatchStep3 from "./FormBatchStep3";
import SuccessBatch from "./SuccessBatch";

export default function MultiStepFormBatch({ handleClose }) {
  // console.log("MultiStepFormBatch");
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const livestockRepos = usestore.reposLivestock.getLivestockStore("id", usestore.reposLivestock.livestockRepos.id)[0];

  const getListAreasLivestock = () => {
    const listAreas_aux = JSON.parse(JSON.stringify(livestockRepos.areas || []));
    const listAreas = listAreas_aux.map((area) => {
      var area_repos = usestore.reposAreas.getAreaMapStore("id", area.id)[0];
      area.imageDecode = area_repos.imageDecode;
      area.area = area_repos.area;
      return area;
    });
    return listAreas;
  };

  const new_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_livestock: livestockRepos.id,

    id_group: livestockRepos.id_group,
    id_tipe: livestockRepos.tipe,
    areas_used: getListAreasLivestock(),

    contador: usestore.reposLivestock.listLotes.length,
    // dados do lote (obrigatorios)
    stage: "",
    nameBatch: "",
    date_entry: new_date,
    area: {},

    name_activity: "NOVO LOTE",
    date_start: new_date,
    date_prev_end: new_date,
    isDeleted: "",
    progress: "CONCLUÍDA",
    planning: "realized",
    observation: "",
  });

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState((new Date().getTime()));
  const handleNext = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["FormBatchStep1", "FormBatchStep2", "FormBatchStep3"];

  const onSubmit = async () => {
    let formOk = true;

    if (!data.area || !data.area.id) {
      formOk = false;
      toast.error("Por favor, escolha uma área para colocar o lote!");
    }

    if (formOk) {
      if (data.date_start === data.date_prev_end) {
        data.progress = "CONCLUÍDA";
        data.date_end = data.date_start;
      }
      const areas_bkp = JSON.parse(JSON.stringify(data.area));
      data.area_f = { id: areas_bkp.id, area: areas_bkp.area, usedArea: areas_bkp.usedArea || areas_bkp.area };

      const header = { header: { "Content-Type": "application/json" } };
      await api.post("/activityBatch/save", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Áreas vinculadas com sucesso!");
            usestore.reposLivestock.fetch({ 'id_farm': property_id });
            usestore.reposProperty.fetch();
            usestore.reposActivityBatch.fetch({ 'id_property': property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao vincular as áreas, tente novamente mais tarde!");
        });
      //data.area = areas_bkp;
    }
  };

  //chamada dos componentes
  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      usestore: usestore,
    };
    switch (step) {
      case 0: // Identificação do lote
        return <FormBatchStep1 data={data} obj_fn={obj_fn} />;
      case 1: // Localização do lote
        return <FormBatchStep2 data={data} obj_fn={obj_fn} />;
      case 2: // confirmação dos dados
        return <FormBatchStep3 data={data} obj_fn={obj_fn} />;
      case 3:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessBatch handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return "Não existente";
    }
  };

  return ( <>
    <Box sx={{ width: "100%" }}>
      {getStepContent(activeStep)}
    </Box>
  </> );
}
