import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { NavLink, useParams } from 'react-router-dom';
import { CardHeader, Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import ChartBar from '../Charts/ChartBar';
import { calcularDateProgressBarra } from '../Calculations/calcularDateProgressBarra';
import IconsLivestockActivities from '../Icons/IconsLivestockActivities';
import Typewriter from '../Typewriter/Typewriter';
import ThreeDotsSpinner from '../Loader/ThreeDotsSpinner';
import useStore from '../../store/useStore';

export default function CardPeriod({ item }) {
  const { idFarm, id } = useParams();
  const usestore = useStore();
  const reposUser = usestore.reposUser;
  console.log(reposUser.userRepos);
  console.log(reposUser.userRepos);

  const date_start = dayjs(item?.date_start || '').format('DD/MM/YYYY');
  const date_prev_end = dayjs(item?.date_prev_end || '').format('DD/MM/YYYY');
  const valueProgress = calcularDateProgressBarra(date_start, date_prev_end);
  console.log(item);

  return (
    <>
      <Card>
        <CardHeader
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title="Projeto"
          subheader={item.label}
        />
        <CardContent sx={{ mt: 0 }}>
          <Stack alignItems="center">
            <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
              <Typography variant="caption" color="text.secondary" component="div">
                {date_start}
              </Typography>
              <ChartBar valueProgress={valueProgress.porcentagem || 0} />
              <Typography variant="caption" color="text.secondary" component="div">
                {date_prev_end}
              </Typography>
            </Stack>
          </Stack>
          <Box className="container-fluid">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <NavLink sx={{ ml: 3 }} to={{ pathname: '/harvest/' + idFarm + '/' + id }} className="small-box-footer text-success">
                    Grupo: {item.group_name}
                  </NavLink>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
