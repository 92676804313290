import React, { useState } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Stack, List, ListItem } from '@mui/material';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Header from './Header';
import FormIndividualMarketingFooter from './FormIndividualMarketingFooter';
import ModalLandpage from './ModalLandpage';
import MainCreation from './MainCreation';
import DescriptionCreation from './DescriptionCreation';
import ColorPicker from './ColorPicker';
import FormImageMarketing from './FormImageMarketing';
import FormMarketingHeader from './FormMarketingHeader';
import CheckIcon from '@mui/icons-material/Check';

export default function FormIndividualMarketingtStep1({ data, obj_fn, page, setPage }) {
  //1-texto

  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // Valida o button continuar
  const isError = () => {
    return false;
  };
  // Valida o button retornar
  const returnError = () => {
    return true;
  };
  function formatData(Inputdata) {
    if (!Inputdata) {
      return '';
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, ['new_weight']: event }));
  // };

  //const img_pesagem = '/dist/image/pesagem_bovino.png';

  const [backgroundPageColor, setBackgroundPageColor] = useState('#ffffff');

  //handleBackgroundPageColorChange
  // const handleBackgroundPageColorChange = (color) => {
  //   setBackgroundPageColor(color);
  //   setPage({
  //     ...page,
  //     backgroundPageColor: color, // Atualiza apenas o endereço no estado
  //   });
  // };

  const text_title = 'Bem vindo ao marketing da sua criação';
  const txt_msn = 'Iremos aqui produzir uma imagem para ser usada como marketing para a sua propriedade';

  return (
    <>
      <FormMarketingHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} height={'60px'} />
      <Grid container sx={{ mb: 5 }}>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={12} sx={{}}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5} sx={{ mt: -0.5 }}>
                  <Typography variant="h6" fontWeight={500}>
                    <RequestPageIcon color="success" sx={{ mr: 1, width: '32px', height: '32px' }} /> Criação da imagem:
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{}}>
            <Grid item xs={12} md={12} sx={{ mt: 4 }}>
              <Typography variant="body1">Para a criação da imagem iremos seguir 5 passos:</Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{}}>
              <List>
                <ListItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">Criação do cabeçalho</Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">Inserção da imagem da criação</Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">A descrição da oferta</Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">A descrições adicionais</Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">E por último, definir a cor de fundo da página</Typography>
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={12} sx={{}}>
            <Box sx={{ border: '1px dashed black', textAlign: 'center', padding: '20px' }}>
              <Typography variant="body1">Cabeçalho</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sx={{}}>
            <Box sx={{ border: '1px dashed black', textAlign: 'center', padding: '20px' }}>
              <Typography variant="body1">Imagem da criação</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sx={{}}>
            <Box sx={{ border: '1px dashed black', textAlign: 'center', padding: '20px' }}>
              <Typography variant="body1">Descrição da oferta</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{}}>
            <Box sx={{ border: '1px dashed black', textAlign: 'center', padding: '20px' }}>
              <Typography variant="body1">Descrições adicionais</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 4 }}>
            <Typography variant="body1">Para facilitar o design, iniciremos com um modelo já pronto. Clique em card:</Typography>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <ModalLandpage page={page} setPage={setPage} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 4 }}>
            <Typography variant="body1">Para inciar, clique em continuar.</Typography>
          </Grid>
        </Grid>
      </Grid>

      <FormIndividualMarketingFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
