import React, { useState } from 'react'
import { Grid, Box, Typography, Card, CardContent, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Chart from 'react-apexcharts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typewriter from '../../../components/Typewriter/Typewriter'

const CardMeatProductionAreaCost = ({ target_active }) => {
  console.log('CardMeatProductionAreaCost')
  console.log(target_active)
  const [data, setData] = useState({
    sellingPrice: target_active.cost, // Preço de venda por unidade
    variableCost: 0.5 * target_active.cost, // Custos e despesas variáveis por unidade
    fixedCost: 60000, // Custos e despesas fixas totais
    fixedCostMonthly: 5000,
    fixedMonths: 12,
  })

  const [breakEvenUnits, setBreakEvenUnits] = useState(null)
  const [breakEvenSales, setBreakEvenSales] = useState(null)
  const [salesSeries, setSalesSeries] = useState([])
  const [costSeries, setCostSeries] = useState([])
  const [breakEvenSeries, setBreakEvenSeries] = useState([])

  const calculateBreakEvenPoint = () => {
    const { sellingPrice, variableCost, fixedCost } = data
    const breakEven = fixedCost / (sellingPrice - variableCost) // Ponto de equilíbrio em unidades
    const sales = breakEven * sellingPrice // Ponto de equilíbrio em valor de vendas
    setBreakEvenUnits(breakEven)
    setBreakEvenSales(sales)

    // Calcula os valores das séries de dados para as retas de vendas, custos e ponto de equilíbrio
    const salesData = []
    const costData = []
    const breakEvenData = []
    const minX = Math.max(0, Math.floor(breakEven) - 30)
    const maxX = Math.ceil(breakEven) + 30
    const step = 5 // Ajuste o passo para agrupar as unidades
    for (let i = minX; i <= maxX; i += step) {
      salesData.push(i * sellingPrice)
      costData.push(i * variableCost + fixedCost)
      breakEvenData.push(i === breakEven ? sales : null) // Ponto de equilíbrio terá o mesmo valor para todas as unidades vendidas até o ponto de equilíbrio
    }
    setSalesSeries(salesData)
    setCostSeries(costData)
    setBreakEvenSeries(breakEvenData)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    // Atualizar os campos fixos diretamente
    if (name === 'fixedCostMonthly' || name === 'fixedMonths') {
      const fixedCostMonthly = name === 'fixedCostMonthly' ? parseFloat(value) : data.fixedCostMonthly
      const fixedMonths = name === 'fixedMonths' ? parseFloat(value) : data.fixedMonths

      const fixedCost = fixedCostMonthly * fixedMonths
      setData({ ...data, [name]: parseFloat(value), fixedCost })
    } else if (name === 'fixedCost') {
      const fixedMonths = name === 'fixedMonths' ? parseFloat(value) : data.fixedMonths
      const fixedCost = name === 'fixedCost' ? parseFloat(value) : data.fixedCost

      const fixedCostMonthly = fixedCost / fixedMonths

      setData({ ...data, [name]: parseFloat(value), fixedCostMonthly })
    } else {
      setData({ ...data, [name]: parseFloat(value) })
    }
  }

  // Configurações do gráfico
  const options = {
    chart: {
      id: 'break-even-chart',
      type: 'line',
    },
    xaxis: {
      categories: Array.from({ length: 13 }, (_, i) => (i - 6) * 5), // Categorias representando intervalos de 5 unidades
      title: {
        text: 'Unidades',
      },
    },
    yaxis: {
      title: {
        text: 'Valor (R$)',
      },
    },
  }

  const series = [
    {
      name: 'Custo Total',
      data: costSeries,
    },
    {
      name: 'Receita Total',
      data: salesSeries,
    },
    {
      name: 'Ponto de Equilíbrio',
      data: breakEvenSeries,
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box mt={2}>
          <Card>
            <CardContent>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Como simulador do ponto de equilíbrio?
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} sx={{}}>
                    {/* <Grid item xs={12} md={12}>
                      <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                        Use o simulador do ponto de equilíbrio abaixo para calcular o ponto de equilíbrio ,indicando o preço de venda, o custo fixo e o custo unitário variável:
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2} sx={{}}>
                        <Grid item xs={12} md={4}>
                          <Card>
                            <CardContent>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Passo 1: Preço de Venda por Unidade
                              </Typography>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Defina o preço de venda por unidade do seu produto
                              </Typography>
                              <Typography sx={{ mt: 4 }} variant="body1" gutterBottom>
                                Exemplo: Se uma unidade (como uma saca de 60kg) é vendida por R$ 60,00, insira esse valor no campo abaixo.
                              </Typography>
                            </CardContent>
                            <CardContent>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Preço de venda por unidade: R$ 60,00
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Card>
                            <CardContent sx={{}}>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Passo 2: Custos e Despesas Fixas Mensais (R$)
                              </Typography>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Inclua todos os custos e despesas fixas mensais da sua empresa, como aluguel, salários, manutenção, entre outros.
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                Exemplo: Se os custos fixos mensais somam R$ 5.000,00, insira esse valor no campo abaixo.c
                              </Typography>
                            </CardContent>
                            <CardContent>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Custo e Despesas Fixas Mensais: R$ 5.000,00
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Card>
                            <CardContent sx={{}}>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Passo 3: Custo Unitário Variável
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                Exemplo: Se o custo unitário variável de cada saca de milho é de R$ 30,00, insira esse valor no campo abaixo.
                              </Typography>
                            </CardContent>
                            <CardContent sx={{}}>
                              <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                                Custo Unitário Variável: R$ 30,00
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Card>
                <CardContent>
                  <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                    Estime o preço de venda por unidade::
                  </Typography>
                  <TextField
                    fullWidth
                    label="Preço de Venda (R$) por Unidade "
                    type="number"
                    name="sellingPrice"
                    value={data.sellingPrice}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    //helperText={`Por exemplo: 1 unidade(saca de 60kg) tem o valor de 60.00 reais.`}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1, mb: 1 }}>
                <CardContent>
                  <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                    Estime os custos fixos mensais:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Custo e Despesas Fixas Mensais (R$)"
                        type="number"
                        name="fixedCostMonthly"
                        value={data.fixedCostMonthly}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={1} alignItems="center" display="flex" justifyContent="center">
                      <Typography variant="h6" align="center" display="block">
                        x
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField fullWidth label="Quant. de Meses" type="number" name="fixedMonths" value={data.fixedMonths} onChange={handleChange} variant="outlined" margin="normal" />
                    </Grid>
                    <Grid item xs={12} md={1} alignItems="center" display="flex" justifyContent="center">
                      <Typography variant="h6" align="center" display="block">
                        =
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Custo e Despesas Fixas totais (R$)"
                        type="number"
                        name="fixedCost"
                        value={data.fixedCost}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        //helperText={`por exemplo, incluindo aluguel, salários, manutenção, etc.`}
                      />
                    </Grid>
                  </Grid>
                  <Typography style={{ color: '#2e7d32' }} variant="body1" gutterBottom>
                    Estime os custos variáveis por unidade:
                  </Typography>
                  <TextField
                    fullWidth
                    label="Custo e Despesas Variáveis por Unidade (R$)"
                    type="number"
                    name="variableCost"
                    value={data.variableCost}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    // helperText={`por exemplo, incluindo custos de produção, embalagem, transporte, etc.`}
                  />
                </CardContent>
              </Card>
              {/* <TextField fullWidth label="Custo e Despesas Fixas totais (R$)" type="number" name="fixedCost" value={data.fixedCost} onChange={handleChange} variant="outlined" margin="normal" /> */}
              <Button variant="contained" color="primary" onClick={calculateBreakEvenPoint}>
                Calcular Ponto de Equilíbrio
              </Button>
              <Box sx={{ mt: 2 }}>
                {breakEvenUnits !== null && (
                  <Typography variant="body1" gutterBottom>
                    Ponto de Equilíbrio em Unidades: {breakEvenUnits.toFixed(2)}
                  </Typography>
                )}
                {breakEvenSales !== null && (
                  <Typography variant="body1" gutterBottom>
                    Ponto de Equilíbrio em Valor de Vendas (R$): {breakEvenSales.toFixed(2)}
                  </Typography>
                )}
                {breakEvenUnits !== null && (
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter
                      tam={80}
                      minHeight={0}
                      text={`Portanto, para cobrir todos os custos e despesas, sua empresa precisaria vender aproximadamente ${breakEvenUnits.toFixed(2)} unidades.`}
                    />
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box mt={2}>
          <Card>
            <CardContent>
              <Chart options={options} series={series} type="line" height={300} />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}
export default CardMeatProductionAreaCost
