import { SvgIcon } from "@mui/material";

// icon do bovino de leite
export const IconMilkCow = ({ widthStyles }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} style={{ width: widthStyles, height: "auto", transform: 'scaleX(-1)' }} viewBox="0 0 920.000000 834.000000" preserveAspectRatio="xMidYMid meet">
        <path
          transform="translate(0.000000,834.000000) scale(0.100000,-0.100000) "
          d="M2040 6435 c0 -19 5 -35 10 -35 6 0 10 -11 10 -25 0 -14 5 -25 10
            -25 6 0 10 -6 10 -13 0 -7 9 -23 21 -36 37 -40 20 -51 -81 -51 -53 0 -90 4
            -90 10 0 6 -15 10 -34 10 -19 0 -38 5 -41 10 -3 6 -14 10 -24 10 -21 0 -107
            46 -135 72 -11 10 -23 18 -28 18 -23 0 8 -87 44 -122 28 -28 104 -78 118 -78
            5 0 10 -4 10 -10 0 -5 9 -10 21 -10 11 0 27 -6 35 -14 17 -17 19 -78 3 -109
            -14 -28 -101 -110 -137 -128 -49 -25 -112 -85 -129 -122 -27 -58 -33 -76 -43
            -131 -15 -77 -31 -101 -137 -211 -48 -51 -93 -98 -98 -106 -6 -8 -24 -32 -40
            -54 -36 -48 -90 -101 -126 -122 -33 -20 -69 -85 -69 -124 0 -39 52 -129 75
            -129 3 0 15 -9 27 -20 12 -11 28 -20 35 -20 7 0 13 -4 13 -8 0 -5 19 -18 42
            -28 52 -23 165 -23 225 1 38 15 45 15 90 0 30 -11 71 -16 108 -14 102 5 169 1
            173 -10 2 -6 17 -11 33 -11 16 0 29 -4 29 -10 0 -6 35 -10 84 -10 47 0 88 5
            91 10 3 6 19 10 35 10 22 0 40 -12 72 -47 24 -25 53 -57 66 -69 12 -13 22 -25
            22 -28 0 -7 73 -129 85 -142 26 -29 71 -89 85 -113 8 -14 30 -53 49 -86 18
            -33 36 -62 40 -65 7 -6 20 -29 64 -110 16 -30 38 -68 47 -85 35 -59 90 -167
            90 -176 0 -5 16 -44 36 -87 20 -42 41 -88 47 -102 7 -14 18 -31 25 -38 6 -7
            12 -18 12 -23 0 -16 109 -127 155 -159 59 -41 114 -70 130 -70 8 0 15 -4 15
            -9 0 -5 16 -12 36 -16 64 -12 74 -23 74 -87 0 -31 4 -59 9 -62 5 -3 9 -21 9
            -40 0 -34 16 -124 33 -186 27 -98 30 -229 8 -367 -6 -37 -15 -93 -19 -123 -4
            -30 -11 -71 -15 -90 -3 -19 -7 -41 -7 -47 -1 -7 -5 -13 -10 -13 -4 0 -8 -9 -8
            -20 0 -11 -4 -20 -8 -20 -5 0 -15 -12 -23 -27 -8 -15 -28 -40 -44 -57 -67 -68
            -145 -187 -145 -221 0 -30 -11 -28 174 -30 170 -2 189 4 180 59 -3 17 -11 41
            -17 54 -9 20 -7 26 16 47 14 14 34 25 43 25 21 0 43 29 44 57 0 12 4 25 10 28
            6 3 10 19 10 35 0 15 4 30 10 32 5 2 12 74 14 163 5 143 18 266 48 435 5 30
            12 82 14 115 3 33 10 64 15 70 5 5 9 24 9 42 0 22 5 33 15 33 15 0 20 -16 28
            -80 3 -19 8 -48 11 -65 12 -57 28 -140 33 -170 4 -29 15 -60 34 -97 5 -10 9
            -25 9 -33 0 -8 5 -15 10 -15 6 0 10 -8 10 -17 0 -10 7 -27 15 -37 8 -11 15
            -28 15 -38 0 -10 5 -18 10 -18 6 0 10 -11 10 -24 0 -14 5 -28 10 -31 6 -3 10
            -52 10 -111 0 -63 -4 -104 -10 -104 -5 0 -10 -17 -10 -38 0 -21 -4 -42 -8 -47
            -4 -6 -16 -35 -26 -66 -10 -31 -31 -72 -47 -90 -16 -19 -29 -38 -29 -43 0 -5
            -8 -16 -18 -25 -10 -9 -25 -28 -35 -43 -47 -75 5 -110 138 -94 17 2 49 4 71 5
            31 1 49 8 71 28 28 27 71 113 56 113 -4 0 -2 5 5 12 7 7 12 39 12 74 0 35 4
            66 10 69 5 3 10 14 10 23 0 9 7 30 15 46 8 15 15 35 15 42 0 8 5 23 12 33 9
            15 9 25 0 46 -7 14 -12 38 -12 54 0 15 -3 31 -7 35 -8 8 -10 15 -23 116 -10
            71 -6 185 9 275 3 22 5 99 2 170 -6 217 -5 269 8 273 6 2 11 12 11 23 0 10 5
            19 10 19 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 10 10 23 0 12 7 31 15 41 15
            20 1 20 325 1 30 -1 145 -6 255 -10 109 -3 203 -8 206 -11 4 -2 44 -5 89 -6
            44 -1 83 -5 86 -10 3 -4 34 -8 69 -8 35 0 67 -4 70 -10 4 -6 70 -10 165 -10
            95 0 161 4 165 10 3 6 24 10 45 10 21 0 42 4 45 10 3 6 17 10 31 10 13 0 58
            16 100 35 41 19 80 35 85 35 5 0 9 4 9 8 0 5 12 15 28 23 15 8 29 16 32 19 9
            9 63 40 110 63 25 12 47 25 48 30 2 4 21 7 43 7 35 0 39 -3 39 -24 0 -14 5
            -28 10 -31 6 -3 10 -15 10 -26 0 -23 58 -84 89 -94 12 -3 21 -11 21 -16 0 -5
            5 -9 10 -9 24 0 80 -75 80 -108 0 -10 5 -23 11 -29 8 -8 8 -13 0 -17 -17 -11
            -13 -95 5 -113 13 -14 19 -14 42 -2 41 21 52 35 53 66 2 77 74 120 121 71 20
            -20 23 -36 26 -113 4 -100 6 -105 31 -105 21 0 52 34 72 78 18 42 39 41 40 0
            0 -18 3 -49 5 -68 2 -19 -1 -78 -7 -130 -6 -52 -11 -101 -12 -107 0 -7 -4 -13
            -9 -13 -4 0 -8 -15 -8 -34 0 -19 -4 -38 -10 -41 -5 -3 -10 -17 -10 -31 0 -13
            -4 -24 -10 -24 -5 0 -10 -11 -10 -25 0 -14 -4 -33 -9 -43 -5 -9 -21 -51 -36
            -92 -39 -107 -49 -130 -57 -130 -5 0 -8 -6 -8 -13 0 -7 -11 -35 -23 -63 -13
            -27 -30 -64 -38 -82 -8 -17 -17 -32 -21 -32 -5 0 -8 -7 -8 -15 0 -8 -4 -15
            -10 -15 -5 0 -10 -5 -10 -11 0 -17 -52 -64 -116 -105 -119 -76 -120 -105 -4
            -118 120 -13 186 -20 191 -18 4 0 18 3 32 6 59 10 99 60 100 123 1 45 24 70
            76 79 45 8 91 58 91 99 0 8 5 15 10 15 6 0 10 11 10 24 0 14 4 27 9 30 5 4 12
            29 16 56 4 27 11 52 16 56 5 3 9 21 9 40 0 19 5 34 10 34 6 0 10 15 10 34 0
            19 4 37 9 40 5 3 11 20 14 38 15 88 25 127 46 178 34 83 65 145 74 148 4 2 7
            12 7 22 0 9 7 23 15 30 8 7 15 17 15 22 0 5 10 20 23 33 12 13 40 44 62 69 22
            25 48 46 58 46 22 0 47 -23 47 -43 0 -7 3 -17 8 -21 4 -4 7 -11 7 -14 1 -4 7
            -25 15 -47 32 -96 45 -314 25 -445 -3 -25 -8 -70 -10 -100 -2 -30 -9 -89 -15
            -130 -6 -41 -17 -120 -24 -175 -14 -109 -24 -145 -37 -145 -5 0 -9 -7 -9 -16
            0 -9 -8 -24 -18 -33 -9 -9 -39 -40 -65 -70 -67 -76 -71 -75 210 -97 68 -6 72
            -5 100 23 15 15 34 38 41 50 13 25 17 119 4 127 -15 9 -7 73 13 99 14 20 23
            24 36 16 31 -16 65 -10 76 14 11 23 10 53 -8 312 -19 256 -15 320 32 530 47
            209 25 413 -62 577 -5 10 -9 25 -9 33 0 8 -4 15 -9 15 -5 0 -12 13 -16 30 -4
            16 -11 32 -16 35 -5 4 -9 15 -9 26 0 10 -3 19 -8 19 -4 0 -13 15 -20 33 -7 17
            -18 40 -23 49 -5 10 -9 27 -9 37 0 11 -4 22 -9 25 -7 5 -21 77 -22 111 0 38
            15 137 22 141 5 3 9 20 9 38 0 18 3 36 7 40 4 4 9 28 10 54 3 55 24 132 35
            132 12 0 10 -215 -2 -242 -5 -13 -13 -64 -17 -113 -9 -98 -5 -122 41 -227 9
            -20 16 -41 16 -47 0 -6 5 -11 10 -11 6 0 10 -7 10 -15 0 -17 18 -52 37 -73 18
            -19 32 21 40 112 7 83 -8 166 -37 206 -11 16 -20 35 -20 44 0 9 -5 16 -11 16
            -8 0 -9 25 -5 83 4 45 11 154 16 242 5 88 14 196 20 240 5 44 11 87 11 95 1
            22 17 131 23 160 12 58 29 234 30 320 2 96 -32 281 -55 304 -5 6 -9 18 -9 28
            0 10 -4 18 -10 18 -5 0 -10 9 -10 20 0 11 -4 20 -8 20 -4 0 -16 14 -27 32 -29
            50 -76 92 -135 122 -30 16 -63 32 -72 37 -10 5 -29 9 -42 9 -13 0 -26 4 -28 9
            -3 8 -157 23 -218 21 -246 -7 -367 -13 -375 -21 -5 -5 -20 -9 -34 -9 -13 0
            -38 -4 -55 -10 -166 -53 -260 -80 -277 -80 -10 0 -19 -4 -19 -10 0 -5 -13 -10
            -29 -10 -17 0 -33 -4 -36 -10 -3 -5 -19 -10 -35 -10 -16 0 -31 -4 -34 -9 -15
            -24 -688 -31 -971 -10 -214 16 -478 41 -675 65 -25 3 -81 7 -125 9 -44 2 -105
            6 -135 9 -213 20 -674 28 -840 14 -147 -13 -352 -16 -416 -7 -33 4 -80 10
            -105 13 -24 3 -57 7 -74 10 -16 3 -54 9 -83 12 -29 4 -55 11 -58 15 -3 5 -20
            9 -39 9 -19 0 -37 5 -40 10 -3 6 -17 10 -31 10 -13 0 -24 5 -24 10 0 6 -9 10
            -20 10 -11 0 -39 12 -62 26 -77 49 -118 102 -118 154 0 48 -12 62 -46 58 -27
            -3 -29 0 -32 33 -3 38 -25 66 -123 151 -109 95 -125 109 -166 141 -23 17 -43
            38 -43 45 0 6 -4 12 -10 12 -5 0 -10 -16 -10 -35z"
        />
      </SvgIcon>
    </>
  );
};
