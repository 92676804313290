import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../services/api";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import dayjs from "dayjs";
import "dayjs/locale/pt";

// create the store
const useMessengerReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listMessenger: [],
        fetch: async (item) => {
          /*var farm_id = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_farm': farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("messenger/posts", (item), header);
          const data = await response.data;*/
          const response = await api.get("/messenger/posts");
          const data = await response.data._value;
          await set({
            listMessenger: data.map((post) => ({
              id: post.id,
              id_harvest: post.id_harvest,
              id_livestock: post.id_livestock,
              id_property: post.id_property,
              id_receiver: post.id_receiver,
              id_requester: post.id_requester,
              area: post.area,
              coordinates: post.coordinates || "",
              coordinates_decod: post.coordinates ? JSON.parse(base64_decode(post.coordinates)) : "",
              created_at: post.created_at,
              date: post.date,
              date_f: dayjs(post.date).format("DD/MM/YYYY"),
              description: post.description,
              isDeleted: post.isDeleted,
              key_image: post.key_image,
              status: post.status,
              title: post.title,
              zoom: post.zoom,
              type: "msn",
            })),
          });

          const list = JSON.parse(localStorage.getItem("listMsn"));
          const list_i = get().listMessenger;
          const result = list.concat(list_i);
          localStorage.setItem("listMsn", JSON.stringify(result));

          return data;
        },
        // pega a(s) harvest(s) da listMessenger de acordo com o [key e val] informados
        getMessengerStore: (key, val) => {
          return get().listMessenger.filter((harvest) => {
            if (harvest[key] == val) {
              return harvest;
            }
          });
        },
        // Adiciona uma nova harvest à listMessenger
        addMessengerStore: (harvest) => {
          set((state) => ({
            listMessenger: [...state.listMessenger, harvest],
          }));
        },
        // Gera uma nova listMessenger sem a harvest informada
        delMessengerStore: (id) => {
          set({
            listMessenger: get().listMessenger.filter((harvest) => {
              if (harvest.id != id) {
                return harvest;
              }
            }),
          });
        },
        // atualiza listMessenger de acordo com id, key e val informados
        updateMessengerStore: (id, key, val) => {
          set({
            listMessenger: get().listMessenger.map((harvest) => {
              if (harvest.id === id) {
                if (key == "all") {
                  // atualiza todos os dados
                  harvest = val;
                } else {
                  harvest[key] = val;
                }
              }
              return harvest;
            }),
          });
          return get().listMessenger;
        },
      }),
      { name: "reposMessenger" }
    )
  )
);

export default useMessengerReposStore;
