import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { ToolsMenuButtons } from "./components/ToolsMenu";
import { green, grey } from "@mui/material/colors";
import { Context } from "../Context/AuthContext";
import { Groups, Settings } from "@mui/icons-material";
// import { ButtonBase, Grid, Paper, Typography, Stack, Box } from "@mui/material";

export default function NavSide() {
  const aux_creationHarvestRepos = localStorage.getItem('creationHarvestRepos');
  const creationHarvestRepos = aux_creationHarvestRepos ? JSON.parse(aux_creationHarvestRepos) : { label: '', id: false, id_property: 0, tipo_repos: '' };

  // const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClose = () => {
    // setAnchorEl(null);
    // fecha sideBar
    if (window.jQuery('body').hasClass('sidebar-open')) {
      window.jQuery('body').removeClass('sidebar-open');
    }
    if (window.innerWidth < 400) {
      window.jQuery('body').addClass('sidebar-collapse sidebar-closed');
    } else {
      window.jQuery('body').addClass('sidebar-collapse');
    }
  };

  const { menuContext } = React.useContext(Context);
  //const { id } = useParams();
  useEffect(() => {
    // console.log("NavSide - toolsMenuButtons");
    if (window.jQuery('body').hasClass('sidebar-open')) {
      window.jQuery('body').removeClass('sidebar-open');
    }
  }, [menuContext])

  const buttonsMenuProperty = ToolsMenuButtons('selectProperty', handleClose, 'link');
  const buttonsMenuCreationHarvest = ToolsMenuButtons('selectCreationHarvest', handleClose, 'link');
  //const buttonsMenuAreaMap = ToolsMenuButtons('selectAreaMap', handleClose, 'link');

  const optionsProperty = Object.keys(buttonsMenuProperty).filter((item) => {
    var valid = false;
    if (item !== 'geraButtonGeral') {
      if (((buttonsMenuProperty[item])()).props.children) {
        valid = true;
      }
    }
    return valid;
  });
  const optionsCreationHarvest = Object.keys(buttonsMenuCreationHarvest).filter((item) => {
    var valid = false;
    if (item !== 'geraButtonGeral') {
      if (((buttonsMenuCreationHarvest[item])()).props.children) {
        valid = true;
      }
    }
    return valid;
  });
  /*const optionsAreaMap = Object.keys(buttonsMenuAreaMap).filter((item) => {
    if (item !== 'geraButtonGeral') {
      if (((buttonsMenuAreaMap[item])()).props.children) {
        return item
      }
    }
  });*/

  const montaSideOptions = function (optionMenu, buttonFunction, key) {
    return (
      <li key={key} className="nav-item ">
        {optionMenu[buttonFunction]()}
      </li>
    );
  };

  const handleAcordion = function (e) {
    if (creationHarvestRepos.id > 0) {
      if (window.jQuery(e.currentTarget).hasClass('menu-open')) {
        window.jQuery(e.currentTarget).removeClass('menu-open');
      } else {
        window.jQuery(e.currentTarget).addClass('menu-open');
      }
    }
  };

  return (
    <aside className="main-sidebar sidebar-light-success elevation-1 " style={{height: '100%'}}>
      <NavLink to="/" className="brand-link" style={{marginTop: '0.8rem'}}>
        <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image  " style={{ opacity: ".8" }} />
        <span className="brand-text font-weight-light">Fazenda Premium</span>
      </NavLink>
      <div className="sidebar ">
        <div className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
            <li key="adm" className="nav-item " onClick={(e) => handleAcordion(e)}>
              <a href="#" className="nav-link active" style={{ backgroundColor: grey['2C2'] }} >
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p> Propriedade <i className="right fas fa-angle-left"></i> </p>
              </a>
              <ul className="nav nav-treeview">
                {optionsProperty.map((buttonFunction, index) => (montaSideOptions(buttonsMenuProperty, buttonFunction, ("P" + index))))}
              </ul>
            </li>
            <li key="geren" className="nav-item " onClick={(e) => handleAcordion(e)} disabled={true}>
              <a href="#" className="nav-link active" style={{ backgroundColor: !creationHarvestRepos.id ? grey['400'] : green['28A'] }} >
                <i className="nav-icon fas fa-chart-pie"></i>
                <p> Criações/Safras <i className="right fas fa-angle-left"></i> </p>
              </a>
              <ul className="nav nav-treeview">
                {optionsCreationHarvest.map((buttonFunction, index) => (montaSideOptions(buttonsMenuCreationHarvest, buttonFunction, ("C" + index))))}
              </ul>
            </li>
            {/*<li key="oprac" className="nav-item " onClick={(e) => handleAcordion(e)}>
              <a href="#" className="nav-link active" style={{ backgroundColor: green['10A'] }} >
                <i className="nav-icon fas fa-edit"></i>
                <p> Operacional <i className="right fas fa-angle-left"></i> </p>
              </a>
              <ul className="nav nav-treeview">
                {optionsAreaMap.map((buttonFunction, index) => (montaSideOptions(buttonsMenuAreaMap, buttonFunction, ("A" + index))))}
              </ul>
            </li>*/}
            <li key="suprt" className="nav-item">
              <NavLink to="/suport" className="nav-link">
                <i className="far  nav-icon"> <HelpCenterIcon />{" "} </i>
                <p>Suporte</p>
              </NavLink>
            </li>
            <li key="person" className="nav-item">
              <NavLink to="/person" className="nav-link">
                <i className="far  nav-icon"> <Groups />{" "} </i>
                <p>Pessoas</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="mt-2" style={{position: "absolute", bottom: 5}}>
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
            <li key="config" className="nav-item " onClick={() => (navigate("/settingsuser"))} >
              <a href="#" className="nav-link active" style={{ backgroundColor: grey[100] }} >
                <Settings sx={{color: "black"}}/>
                <p style={{color: "black", marginLeft: 3}}> Configurações  </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </aside>
  );
}
