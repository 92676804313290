import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, ButtonBase, List, Paper, Stack, Typography } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import TutorialStockAccordion from './TutorialStockAccordion';
import WidgetsIcon from '@mui/icons-material/Widgets';

export default function TutorialStockProducts({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  return (
    <div>
      <Button variant="text" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </Button>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WidgetsIcon sx={{ mr: 1 }} /> Produtos
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão do estoque
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mt: 2, mb: 2 }} id="alert-dialog-description">
            O catálogo de produtos é o local onde você insere e visualiza os produtos (insumos) necessários para sua produção agrícola e pecuária.
          </DialogContentText>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Veja a seguir como é fácil inserir insumos no catálogo de produtos:
            </Typography>
          </Box>
          <TutorialStockAccordion />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
