import React from "react";
import Footer from "../../components/Footer/Footer";
import HarvestListContent from "./HarvestListContent";
// import Nav from "../../components/Nav/Nav";
// import NavSide from "../../components/Nav/NavSide";
// import NavBotton from "../../components/Nav/NavBotton";

export default function HarvestList() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <HarvestListContent />
      <Footer />
    </>
  );
}
