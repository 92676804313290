import * as React from "react";
import { toast } from "react-toastify";
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Divider, Container, Grid, Toolbar, Typography } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import { Close, DeleteForever } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import api from "../../../../services/api";
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Span } from "../../../../components/Typography";
import { Context } from "../../../../components/Context/AuthContext";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ModalDeleteBill({ bill, open, setOpen, handleClose, obj_fn, show_btn }) {
  const { aux_add, setAuxAdd } = React.useContext(Context);

  const handleDelete = async () => {
    // console.log('handleDelete: ');
    if (bill.id !== obj_fn.isOpen) {
      return;
    }
    // console.log(bill);
    const header = { header: { "Content-Type": "application/json" } };
    var url = "/finances/del_bill";
    if (!bill.id_transaction) {
      url = "/finances/del_payment";
    }
    
    await api.post(url, bill, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error("Erro ao deletar o Lançamento!");
        }
        toast.success(`Lançamento excluido com sucesso!`);
        obj_fn.usestore.reposFinances.fetch();
        obj_fn.usestore.reposFinances.fetchT();
        obj_fn.usestore.reposBill.fetch().then((res) => {
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            handleClose();
          }, 200);
          setTimeout(() => {
            obj_fn.usestore.reposSales.fetch();
            obj_fn.usestore.reposBuys.fetch();
            obj_fn.usestore.reposProperty.fetch();
            obj_fn.usestore.reposCashFlow.fetch();
            obj_fn.usestore.reposCostProduction.fetch();
          }, 400);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao deletar o lançamento!");
      });
  };

  return (
    <div>
      {!show_btn ? <></> :
        <IconButton aria-label="Excluir" onClick={() => setOpen(true)} title="Excluir">
          <DeleteForever sx={{ color: red[600] }} />{" "}
        </IconButton>
      }

      <Dialog open={open && bill?.id === obj_fn.isOpen} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"title"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"h6"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ mb: 6, mt: -3, textAlign: "center" }}>
          <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} sm={12} sx={{ mb: 1, color: red[700] }}>
                <Typography variant="h5" component="div">
                  Deseja excluir definitivamento o lançamento:
                </Typography>
                <Typography variant="h6" component="div">
                  {bill.description}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography variant="body1" component="div">
                  Esta ação irá deletar todas as parcelas envolvendo este lançamento.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>Cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button variant="outlined" color="error" onClick={handleDelete}>
                <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div">Deletar </Typography>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
}