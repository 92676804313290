import * as React from 'react'
// import { useNavigate } from 'react-router-dom'
// import { makeStyles } from '@mui/styles'
import InventoryMov from '../../../inventory/components/InventoryMov'

// const useStyles = makeStyles((theme) => ({
//   button: {
//     width: '100%',
//     height: '100%',
//   },
// }))

export default function ButtonListStockAccordion() {
  // const classes = useStyles()
  // const navigate = useNavigate()

  return (
    <div>
      <InventoryMov />
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            1-Cadastro de Produtos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
           <Typography variant="subtitle2">
            O Cadastro de Produtos (Insumos) é uma funcionalidade que permite a inclusão sistemática de todos os produtos ou insumos dentro do sistema de controle de estoque.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            Este cadastro detalhado é crucial para o gerenciamento efetivo pois oferece uma visão clara do que está disponível, em que quantidade e em que condições.
          </Typography> 
         
        </AccordionDetails>
      </Accordion> */}

      {/* <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" fontWeight={500}>
          Formalizado o pedido de compra, você terá posse de um sistema eficiente e gerenciamento preciso e automatizado das entradas e saídas de produtos. Agorá basta monitorar...
        </Typography>
      </Box> */}
    </div>
  )
}
