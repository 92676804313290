import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import WidgetsIcon from '@mui/icons-material/Widgets'
import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore'
import { Context } from '../../../../components/Context/AuthContext'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import api from '../../../../services/api'
import useStore from '../../../../store/useStore'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import { red } from '@mui/material/colors'

export default function MoveStock({ data, dataStock, setDataStock, open, handleClose }) {
  // console.log('xxxxxxxxxx')
  // console.log('')
  // console.log('')
  // console.log('MoveStock')
  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  //var userCurrent = JSON.parse(localStorage.getItem('userCurrent'))

  // console.log('1-dataStock:produto com a localização(location )')
  // console.log(dataStock.name)
  // console.log(dataStock)

  const property_id = localStorage.getItem('Property') || 1

  const reposPatrimony = usePatrimonyReposStore((state) => state)
  //console.log(reposPatrimony)
  //console.log(reposPatrimony.listPatrimony)

  const reposPatrimonyFilter = reposPatrimony.listPatrimony.filter((item) => item.id_property == property_id)
  //console.log(reposPatrimonyFilter)

  const [dataStockList, setDataStockList] = useState([])
  const [listStockLocation, setListStockLocation] = useState(reposPatrimonyFilter)

  // console.log('Lista de patrimonios da propridade')
  // console.log(listStockLocation)

  let listStockLocationFilter = []

  const filterLovation = () => {
    if (data.id_product_location_array) {
      // console.log('string com vírgula ')
      let array = data.id_product_location_array ? data.id_product_location_array.split(',').map(Number) : []
      //console.log(array)
      //
      listStockLocationFilter = reposPatrimonyFilter.filter((item) => array.includes(item.id))
      //console.log(listStockLocationFilter)
      // preciso agora do conteúdo da lista de patrimonyLocation com ids product_location
      let ListDataLocation = reposPatrimony.patrimonyLocation.filter((item) => array.includes(item.id))
      //console.log(ListDataLocation)
      setDataStockList(ListDataLocation)
      let filteredArray = []
      // preciso obter os patrimonios filtrados
      for (const [index, item_array] of ListDataLocation.entries()) {
        // console.log(item_array)
        const item = reposPatrimony.listPatrimony.find((item) => item.id === item_array.id_patrimony)
        filteredArray.push(item)
      }
      setListStockLocation(filteredArray)
    }
  }
  useEffect(() => {
    filterLovation()
  }, [])

  // console.log('Array: filtro da localização do produto x')
  // console.log('patrominios')
  // console.log(dataStockList)
  // console.log(listStockLocation)

  let finalObject = {
    id_product: dataStock.id_product,
    productName: dataStock.name,
    location: dataStockList,
  }

  // Exibindo o objeto final
  // console.log('finalObject')
  // console.log(finalObject)

  // pega-se o id prodcut_location = somente está tabela será atualizada
  const [origem, setOrigem] = useState(null) // Estado para armazenar o local de origem selecionado
  const [destino, setDestino] = useState(null) // Estado para armazenar o local de destino selecionado
  //const [quantidade, setQuantidade] = useState('') // Estado para armazenar a quantidade a ser transferida

  const [loading, setLoading] = useState(false)

  const [formError, setFormError] = useState({})

  const handleChange = (event) => {
    const { name, value } = event.target
    //console.log(name, value)
    setDataStock((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    // Limpa o erro relacionado ao campo que está sendo alterado
    setFormError((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }))
  }

  const handleReposUpdate = (event) => {
    // atualizar o produto
    usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposProducts')
    })
    // atualizar as finanças
    usestore.reposFinances.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances')
    })
    // atualizar a transação
    usestore.reposFinances.fetchT({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances - transactions')
    })
    // atualizar as atividades
    usestore.reposPatrimony.fetch({ id_farm: property_id }).then((res) => {
      // console.log('reposPatrimony - ')
      setAuxAdd(aux_add + 1)
      setTimeout(() => {
        handleClose()
      }, 200)
    })
  }

  const handleSubmit = async () => {
    console.log('handleSubmit')
    setFormError({})
    let errors = {}
    let formOk = true

    // console.log(origem)
    // console.log(destino)
    // console.log(dataStock)

    if (!dataStock.transferQuanty || dataStock.transferQuanty == 0) {
      errors.transferQuanty = true
      formOk = false
    }

    if (!origem) {
      errors.origem = true
      formOk = false
    }

    setFormError(errors)

    const updateLocation = {
      // update
      id_location_origem: origem.id,
      id_location_destino: destino.id,
      quanty: Number(dataStock.transferQuanty),
      // transaction
      id_property: dataStock.id_property,
      id_user: dataStock.id_user,
      id_account: data.id_account,
      id_product: dataStock.id_product,
      stock_cost: parseFloat(data.stock_cost),
      name: data.name,
      id_unit: data.id_unit,
      unit_name: data.unit_name,
      quanty_max: dataStock.quanty_max,
      quanty_min: dataStock.quanty_min,
      id_product_location_array: dataStock.id_product_location_array,
      stock_quanty_array: dataStock.stock_quanty_array,
      name_patrimony_origin: origem.name,
      name_patrimony_destiny: destino.name,
    }
    // console.log('updateLocation')
    // console.log(updateLocation)

    if (true) {
      const header = { header: { 'Content-Type': 'application/json' } }
      const res = await api
        .post('inventory/updateTransferProdcutLocation', updateLocation, header)
        .then((response) => {
          // console.log('response.dataxxx')
          // console.log(response.data)
          // console.log(response.data.isSuccess)
          if (response.data.isSuccess) {
            toast.success('Controle de quantidade de estoque atualizado')
            setLoading(true)
            //resetForm()
            handleReposUpdate()
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao atualizar o estoque, tente novamente mais tarde!')
        })
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!')
      setFormError(errors)
      console.log(formError)
    }
  }

  // console.log(finalObject?.location)
  // console.log(origem)

  const [locationTarget, setLocationTarget] = useState(origem ? finalObject?.location.filter((item) => item.id !== origem.id) : finalObject?.location)

  useEffect(() => {
    if (origem) {
      setLocationTarget(origem ? finalObject?.location.filter((item) => item.id !== origem.id) : finalObject?.location)
    }
  }, [origem])

  //console.log(finalObject.location)

  //console.log('locationTarget')
  //console.log(locationTarget)

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle>Movimentar produtos do estoque</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContentText></DialogContentText>
            <Grid container sx={{ mb: 0, mt: 0 }}>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                {/* NOME */}
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <WidgetsIcon sx={{ fontSize: 20, mr: 1 }} color="text.secondary" />
                    <Stack spacing={0.5}>{dataStock?.name && <Typography fontWeight={700}>{dataStock?.name.toUpperCase()}</Typography>}</Stack>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <div>
                  {/* Seleção do local de origem */}

                  <Grid container spacing={2} sx={{ mb: 2, height: '180px' }}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formError.origem ? <span style={{ color: red[600] }}>Defina a o local de origem e destino</span> : 'Local de Origem: Escolha o local que deseja retirar o produto'}
                      </Typography>
                    </Grid>
                    {finalObject.location.map(
                      (local) =>
                        local.quantity != 0 && (
                          // true && (
                          <>
                            <Grid item xs={12} sm={6} key={local.id_location}>
                              <Card
                                onClick={() => setOrigem(local)}
                                sx={{ p: 1 }}
                                style={{
                                  cursor: 'pointer',
                                  border: origem && origem.id === local.id ? '2px solid gray' : '1px solid #ccc',
                                  borderRadius: '5px',
                                }}
                              >
                                <CardContent>
                                  <Grid container direction="row" alignItems="center">
                                    <WarehouseIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                    <Typography sx={{ color: origem && origem.id_location === local.id_location ? '#353839' : '#808080' }} variant="subtitle1">
                                      {local.name}
                                    </Typography>
                                  </Grid>
                                  <Grid container direction="row" alignItems="center">
                                    <WidgetsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                    <Typography sx={{ color: '#808080' }} variant="body2">
                                      Estoque: {local.quantity}
                                    </Typography>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </>
                        )
                    )}
                  </Grid>
                  {/* Seleção do local de destino */}
                  {loading ? (
                    <Grid container spacing={2} sx={{ mb: 2, height: '180px' }}>
                      <>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Local de Destino: Escolha o local que deseja movimentar o produto
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Card
                            sx={{ p: 1 }}
                            style={{
                              cursor: 'pointer',
                              //border: destino && destino.id_location === local.id_location ? '2px solid green' : '1px solid #ccc',
                              borderRadius: '5px',
                            }}
                          >
                            <CardContent>
                              <Typography
                                //sx={{ color: destino && destino.id_location === local.id_location ? '#2e7d32' : '#808080' }}
                                variant="subtitle1"
                              >
                                Transferindo ...
                              </Typography>
                              <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} sm={12}>
                                  <LinearProgress color="success" />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    </Grid>
                  ) : (
                    <Grid container spacing={2} sx={{ mb: 2, height: '180px' }}>
                      {origem && (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              Local de Destino: Escolha o local que deseja transferir
                            </Typography>
                          </Grid>
                          {locationTarget?.map((local) => (
                            <Grid item xs={12} sm={6} key={local.id_location}>
                              <Card
                                onClick={() => setDestino(local)}
                                sx={{ p: 1 }}
                                style={{
                                  cursor: 'pointer',
                                  border: destino && destino.id === local.id ? '2px solid green' : '1px solid #ccc',
                                  borderRadius: '5px',
                                }}
                              >
                                <CardContent>
                                  <Grid container direction="row" alignItems="center">
                                    <WarehouseIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                    <Typography sx={{ color: origem && origem.id_location === local.id_location ? '#353839' : '#808080' }} variant="subtitle1">
                                      {local.name}
                                    </Typography>
                                  </Grid>
                                  <Grid container direction="row" alignItems="center">
                                    <WidgetsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                    <Typography sx={{ color: '#808080' }} variant="body2">
                                      Estoque: {local.quantity}
                                    </Typography>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  )}

                  <Grid container spacing={2} sx={{ mb: 2, mt: 1, height: '120px' }}>
                    <Grid item xs={12} sm={12} sx={{ mb: -2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Transferência:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <CardContent>
                        <Typography sx={{ color: '#353839' }} variant="subtitle2">
                          Origem:
                        </Typography>
                        <Typography sx={{ color: '#353839' }} variant="body2">
                          {origem?.name} ({origem?.quantity})
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <ExitToAppIcon />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <CardContent>
                        <Typography sx={{ color: '#2e7d32' }} variant="subtitle2">
                          Destino:
                        </Typography>
                        <Typography sx={{ color: '#2e7d32' }} variant="body2">
                          {destino?.name} ({destino?.quantity})
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                  {loading ? (
                    <Typography variant="body2" gutterBottom sx={{ mb: 3 }}>
                      {`Transferindo... `}
                    </Typography>
                  ) : (
                    <Typography variant="body2" gutterBottom sx={{ mb: 3 }}>
                      {`Transferindo ${dataStock.transferQuanty || 0} unidades do(a) ${origem?.name || 'origem'} para o(a) ${destino?.name || 'destino'}`}
                    </Typography>
                  )}

                  <TextField
                    label="Quantidade"
                    variant="outlined"
                    name="transferQuanty"
                    focused
                    value={dataStock.transferQuanty}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    // /size="small"
                    color="success"
                    sx={{ mb: 2 }}
                    error={formError.transferQuanty}
                    FormHelperTextProps={{ style: { color: formError.transferQuanty ? red[800] : '#2e7d32' } }}
                    helperText={formError.transferQuanty ? 'Defina a quantidade da transferência' : 'Defina a quantidade desejada'}
                  />
                </div>

                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      label="Descrição"
                      minRows={3}
                      maxRows={10}
                      name="description"
                      placeholder="Descrição do produto (limite de 500 caracteres)."
                      value={dataStock.description}
                      onChange={(e) => handleChange(e)}
                      style={{ width: '100%', padding: '8px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
      {/* Adicione botões de ação se necessário */}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="success" onClick={handleSubmit}>
          Transferir
        </Button>
      </DialogActions>
    </Dialog>
  )
}
