import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { TextField, InputAdornment, IconButton } from "@mui/material";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#0) 00000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

//https://stackoverflow.com/questions/45758998/how-can-i-mask-my-material-ui-textfield
export default function PhoneTextField({ phone, handleChange, format }) {
  const formatField = {
    variant: format?.variant || 'outlined',
    size: format?.size || 'small',
    color: format?.color || 'success',
    name: format?.name || 'phone',
    label: format?.label || 'Telefone',
    icon: format?.icon || false,
  }

  return (
    <TextField
      fullWidth
      label={formatField.label}
      name={formatField.name}
      value={phone || ''}
      variant={formatField.variant}
      size={formatField.size}
      color={formatField.color}
      onChange={handleChange}
      InputProps={{
        inputComponent: TextMaskCustom,
        startAdornment: (!formatField.icon ? <></> :
          <InputAdornment position="start">
            <IconButton>
              {formatField.icon}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}