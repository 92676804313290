import * as React from "react";
import { Avatar, Badge, Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import IconCheckboxes from "./IconCheckboxes";
import FullScreenDialogEdit from "../DialogMessenger/FullScreenDialogEdit";
import MenuCard from "./MenuCard";
import MenuCardTrash from "./MenuCardTrash";
import GradingIcon from "@mui/icons-material/Grading";
import { useState } from "react";
import moment from "moment";

export default function CardChatTrash({ post, setIsLoading }) {
  console.log('post-CardChat');
  //console.log(post);
  // console.log(post.id);

  const [isLoadingDate, setIsLoadingDate,] = useState(false)
  const [date, setDate] = useState(post?.date)

  function formatDate() {
    var formatDate = moment(post.date).format('DD/MM/YYYY');
    console.log(formatDate)
    setDate(formatDate)
  }

  React.useEffect(() => {
    formatDate()
    setIsLoadingDate(true)
  }, [isLoadingDate])

  //['Resolver'->0, 'Resolvido'->1, 'Não Resolvido'->2, 'Parado'->3];

  const status = { 0: "Resolver", 1: "Resolvido", 2: "Parado" }
  const statuscolor = { 0: "primary", 1: "success", 2: "default" }


  // para user current
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent;
  console.log(user)


  const name = user.name;
  const charName = name[0].toUpperCase();
  console.log(charName);

  return (
    <>
      <Card sx={{ mt: 1 }}>
        {/* <Card sx={{ maxWidth: 345 }}></Card> */}
        <CardHeader
          sx={{ m: -2, ml: -1 }}
          avatar={
            <Avatar sx={{ bgcolor: green[500] }} style={{ filter: `grayscale(100%)` }} aria-label="recipe">
              {charName}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MenuCardTrash id={post.id} setIsLoading={setIsLoading} />
            </IconButton>
          }
          title={
            <Typography variant="button">
              <strong>{post.title}</strong>
            </Typography>
          }
        // subheader={
        //   <Typography sx={{ fontSize: 12 }} color="text.secondary">
        //     Piquete 1
        //   </Typography>
        // }
        />
        <CardContent sx={{ m: -2, ml: -1 }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {post.description}
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton aria-label="add to favorites">
            {/* <Button variant="contained" size="small" disabled>
              <GradingIcon />
            </Button> */}
          </IconButton>
          {post.status && statuscolor &&
            <Badge sx={{ ml: "auto", mr: 5 }} badgeContent={status[post.status]} color={statuscolor[post.status]} />
          }
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {isLoadingDate && date}
          </Typography>

        </CardActions>
      </Card>
    </>
  );
}
