import React, { useState } from 'react';
import { Box, ButtonBase, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { AccountBalance, Payments } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import CategoryIcon from '@mui/icons-material/Category';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '120px',
  },
}));

export default function FormProductChoiseStep1({ data, obj_fn }) {
  // console.log('FormProductChoiseStep1')
  const classes = useStyles();
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  //1-texto para acionar o botao radio
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
    // exibe os botões após começar a escrever
    setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, 400);
  }, 500);

  // const para validar o button continuar
  // const isError = () => {
  //   if (data.operationType === '') {
  //     return true
  //   }
  //   return false
  // }

  // const returnError = () => {
  //   return true
  // }

  const geraButtonGeral = function (obj) {
    const iconStyle = {
      fontSize: 50,
      color: obj.name === data.operationName ? 'black' : 'lightgray', // Altere a cor do ícone com base no estado do botão
      filter: obj.name === data.operationName ? 'brightness(100%)' : 'brightness(70%)', // Ajuste o brilho do ícone
    };

    const handleChangeOperation = (e, title, name) => {
      // console.log('handleChangeOperation')
      // console.log(title, name)
      if (name === 'quick') {
        obj_fn.handleNext();
      } else {
        obj_fn.handleNext2();
      }
    };

    return (
      <>
        {isLoadingSkeleton ? (
          <>
            <Grid item xs={6} md={6} key={obj.name}>
              <Stack direction="column" alignItems="center">
                <Skeleton variant="rectangular" width="100%" height={120} />
              </Stack>
            </Grid>
          </>
        ) : (
          <Grid item xs={6} md={6} key={obj.name}>
            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{
                p: 2,
                borderRadius: 1,
                border: obj.name === data.operationName ? '2px solid #2e7d3280' : obj.border,
              }}
              onClick={(e) => handleChangeOperation(e, obj.title, obj.name)}
              className={classes.button}
            >
              <Stack direction="column" alignItems="center">
                <Box height={50}>
                  {React.cloneElement(obj.icon, {
                    style: iconStyle,
                  })}{' '}
                  {/* Aplique o estilo ao ícone */}
                </Box>
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  {obj.title}
                </Typography>
                <Typography align="center" sx={{ mt: 0 }} fontWeight={400} variant="caption">
                  {obj.outherName}
                </Typography>
              </Stack>
            </ButtonBase>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <CategoryIcon />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Registro de produtos </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 0 }}>
          <Typography style={{ color: '#2e7d32' }}>
            {!showText ? <ThreeDotsSpinner /> : <Typewriter text="Por favor, escolha a melhor forma de registar seus produtos." variant={'subtitle1'} color={'#2e7d32'} />}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 1, mb: 2 }}>
          <Typography variant="body2">
            Os produtos registrados aqui <strong>não são automaticamente lançados no financeiro</strong>. Para que sejam contabilizados financeiramente, é necessário inserir uma compra ou uma despesa.
          </Typography>
        </Grid>

        {/* <Box
          sx={{
            ml: 1,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Gestão financeira: </Typography>
          </Stack>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Gerenciar o dinheiro previsto para entrar ou sair do caixa. Utilizados para lançamentos financeiros que não são de origem de compras.
          </Typography>
        </Box> */}

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2, mt: 1 }}>
          {geraButtonGeral({
            title: 'Rápida',
            name: 'quick',
            outherName: 'Registro essencial',
            icon: <AccountBalance style={{ fontSize: 50 }} />,
          })}
          {geraButtonGeral({
            title: 'Completa',
            name: 'complete',
            outherName: 'Registro mais completo',
            icon: <Payments style={{ fontSize: 50 }} />,
          })}
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <Typography variant="body2">
            <strong>Cadastro Rápido</strong> de Produtos (Insumos) envolve cadastrar o nome do produto, sua unidade de medida, quantidade, valor, categoria. de controle de estoque.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 1 }} variant="body2">
            <strong>Cadastro Completo</strong> de Produtos (Insumos) envolve informações adicionais como registro do fornecedor do produto, locais de armazenamento, foto, estoque, pesos e dimensões...
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
