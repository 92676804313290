import { Avatar, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import AvatarFp from '../../components/Avatar/AvatarFp';
import SimpleTable from '../../components/Tables/SimpleTable';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import MenuPlan from './components/MenuPlan';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LogoNameWhite from '../../assets/png/logo-name-white.png';
import CardPlan from './components/CardPlan';
import useStore from '../../store/useStore';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function SignatureContent() {
  // Recuperar o objeto userCurrent do localStorage
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  console.log('userCurrent', userCurrent);

  // Obter o nome e email do usuário
  const name = userCurrent.name || 'Usuário';
  const email = userCurrent.email || 'email@exemplo.com';
  const charName = name[0].toUpperCase(); // Primeiro caractere do nome

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  const [farm, setFarm] = useState([]);

  const usestore = useStore();
  const listPropertyRepos = usestore.reposProperty.listProperty;

  return (
    <>
      <div className="content-wrapper bg-white">
        <section className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom variant="h5" component="div">
                  Responsável pela assinatura:
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                  Tenha&nbsp;contato&nbsp;direto&nbsp;com&nbsp;uma consultora.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardPlan listPropertyRepos={listPropertyRepos} />
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
      {/*
      <div className="content-wrapper bg-white">
        <section className="content">

          <div className="invoice p-3 mb-3">
            <div className="row">

              <div className="col-12" style={{ background: "#07bc0c" }}>
                <h4>
                  <i>
                    <img src={LogoNameWhite} width="200"></img>
                  </i>
                  <small className="float-right">Date da: 2/10/2014</small> 
                </h4>
              </div>
            </div>
            <div className="row invoice-info">
              <div className="col-sm-4 invoice-col mt-4">
                <p><strong>Usuário: {user.name}</strong></p>
                <p>Licença do software: {listPropertyRepos.length} Fazenda(s)</p>
                {listPropertyRepos.map((item) => (
                  <>
                    <p>{item.label}</p>
                  </>
                ))}
              </div>
            </div>

          </div>

           <div className="invoice p-2 mb-3">
          <div className="row">
            <div className="col-12">
              <h4>
                <PersonIcon /> Conta
                <h5><small className="float-right">Assinatura: 2/10/2014</small></h5>
              </h4>
            </div>
          </div>
          <div className="row invoice-info">
            <div className="col-sm-4 invoice-col ml-4">
              <strong>Usuário: {user.nome}</strong><br />
              <br />
              E-mail da assinatura<br />
              <a href="#" className="btn btn-sm btn-primary">
                <i className="fas fa-user" />
              </a>
              <a href="#" className="btn btn-sm bg-success">
                <i className="fas fa-comments mr-1" />
                {user.email}
              </a>
            </div>
          </div>
        </div> */}

      {/* <Typography Typography gutterBottom variant="h5" component="div" >
            Sua(s) propriedade(s)
          </Typography >

          {
            listPropertyRepos.map((item) => (
              <>


                <Card sx={{ m: 2 }}>
                   <Card sx={{ maxWidth: 345 }}></Card> 

                  <CardHeader
                    sx={{ m: 2, ml: -1 }}
                    avatar={
                      <Box >
                        < HomeWorkIcon />
                      </Box>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <img src="dist/image/logo-CPT-Softwares.png" width="100"></img>
                      </IconButton>
                    }
                    title={item.label}
                  //subheader="Dezembro 01, 2022"
                  />

                  <CardContent>

                    <div className="invoice p-3 mb-3">
                       <div className="row">
                      <div className="col-12">
                        <h4>
                          <i>
                            <img src="dist/image/logo-CPT-Softwares.png" width="200"></img>
                          </i>
                           <small className="float-right">Date da: 2/10/2014</small> 
                        </h4>
                      </div>
                    </div> 
                      <div className="row invoice-info">
                        <div className="col-sm-4 invoice-col">

                          <address>
                            <strong>E-mail cadastrado</strong><br />
                            Email: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="a2cbccc4cde2c3cecfc3d1c3c7c7c6d1d6d7c6cbcd8cc1cdcf">{user.email}</a>
                          </address>
                        </div>

                        <div className="col-sm-4 invoice-col">
                          Renovação
                          <address>
                            <strong>Anual</strong><br />

                          </address>
                        </div>
                         <div className="col-sm-4 invoice-col">
                        <b>number #007612</b><br />
                        <br />
                        <b>Expiração:</b> 2/22/2014<br />
                        <b>conta:</b> 968
                      </div> 
                      </div>
                    </div>


                  </CardContent>

                </Card>

              </>
            ))
          } 

        </section >
      </div >*/}
    </>
  );
}
