import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Checkmark } from 'react-checkmark';
import { AppBar, Box, Button, ButtonBase, Container, Paper, Stack, Toolbar, Typography } from '@mui/material';
import Typewriter from '../Typewriter/Typewriter';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

/* exemple:
data = {
  distination: "financial#bankaccount",
  name: "Conta Bancária",
  text_success: "Acesse suas Contas Financeiras para verificar suas contas bancárias cadastradas.",
  distination_title: "Contas Financeiras",
  image: "/dist/image/entrada.png",
}
*/
export default function SuccessDialog({ handleClose, data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = (destination) => {
    handleClose();
    navigate(`/${destination}`);
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              {data.name} cadastrada(o) com sucesso!
            </Typography>
            <div className="d-lg-none">
              <Box sx={{ flexGrow: 1 }}>
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={130} text={data.text_success} />
                </Typography>
              </Box>
            </div>
            <div className="d-none d-lg-block">
              <Box sx={{ flexGrow: 1, mb: 2 }}>
                <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                  <Typewriter tam={80} text={data.text_success} />
                </Typography>
              </Box>
            </div>
            {/* Primeiro Botão - Atividades */}
            <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => handleButtonClick(data.distination)} className={classes.button}>
              <Stack direction="column" alignItems="center">
                {data.image ? <img style={{ maxHeight: 100 }} src={data.image} className="img-fluid" alt={data.name}/> : data.icon}
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  {data.distination_title}
                </Typography>
              </Stack>
            </ButtonBase>
            {/* Renderiza o segundo botão apenas se os dados estiverem disponíveis */}
            {data.distinationCost && data.distination_title_Cost && (
              <ButtonBase
                component={Paper}
                elevation={2}
                sx={{ p: 2, borderRadius: 1, mt: 2 }} // Adiciona um espaço entre os botões
                onClick={() => handleButtonClick(data.distinationCost)}
                className={classes.button}
              >
                <Stack direction="column" alignItems="center">
                  {data.image ? <img style={{ maxHeight: 100 }} src={data.image} className="img-fluid" alt={data.name}/> : data.icon}
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    {data.distination_title_Cost}
                  </Typography>
                </Stack>
              </ButtonBase>
            )}
          </div>
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              Fechar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
