import ChartBarHGroup from "../../../components/Charts/ChartBarHGroup";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useHarvestTargetCostReposStore from "../../../store/useHarvestTargetCostReposStore";
import useHarvestTargetReposStore from "../../../store/useHarvestTargetReposStore";
import { useParams } from "react-router-dom";

export default function CardCostHarvest({planHarvestActiveRepos}) {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);

  };


  const [values, setValues] = useState(planHarvestActiveRepos)

  useEffect(() => {
    setValues(planHarvestActiveRepos)
  }, [])
  

  return (
    <>
      <div>
        {values &&
          <Card>
            <CardContent>
              <Grid container spacing={2} sx={{ mb: 1.1 }}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo esperado
                      </Typography>
                      <Typography variant="body2" component="div">
                        {values.totalProductionCost}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" component="div">
                        {/* <strong>{values?.productionCost}</strong> <span style={{ fontSize: "0.8em" }}>{values?.unitSale}</span>{" "} */}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption" color="text.secondary" component="div">
                        Custo Realizado
                      </Typography>
                      <Typography variant="body2" component="div">
                        R$ 0
                      </Typography>
                      <Typography variant="caption" color="text.secondary" component="div">
                        ( 0 /saco de 60kg)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        }
      </div>
      <div className="border-top ">
        <Box sx={{ height: accordionExpanded ? 600 : 'auto', mb: 2 }}>
          <Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Gráfico: Custo Esperado x Custo Realizado</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChartBarHGroup />
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    </>
  );
}
