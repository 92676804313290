import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import { Assignment, ExpandMore, Input, Notes } from '@mui/icons-material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../components/Footer/FooterModal';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
// import CurrencyTextField from '../../../../components/Fields/CurrencyTextField';
// import api from '../../../../services/api';
import AdjustIcon from '@mui/icons-material/Adjust'; // Ícone padrão para opções
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Novo ícone para adicionar
import usePatrimonyReposStore from '../../../../store/usePatrimonyReposStore';
import ImageUploadComponent from '../../../../components/Fields/ImageUploadComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GenericValueField from '../../../../components/Fields/GenericValueField';

export default function UpdateFormProductStep1({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  const [category, setCategory] = useState(obj_fn.usestore.reposProducts.listCategory);
  const [unit, setUnit] = useState(obj_fn.usestore.reposProducts.listUnits);
  const [packaging, setPackaging] = useState(obj_fn.usestore.reposProducts.listPackaging);
  // console.log('UpdateFormProductStep1 ');
  // console.log(data);
  // console.log(obj_fn.usestore.reposProducts.listUnits);

  // useEffect(() => {
  //   //setCategory(data)
  //   //setUnit(obj_fn.usestore.reposProducts.listUnits)
  //   // const fetchUnitData = async () => {
  //   //   try {
  //   //     const res = await api.get('products/unit/list')
  //   //     setUnit(res.data._value)
  //   //   } catch (error) {
  //   //     console.error('Erro ao buscar dados das unidades:', error)
  //   //   }
  //   // }

  //   // fetchUnitData()
  // }, [])

  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const handleChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    // console.log(field, value);

    obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));

    if (field === 'id_unit') {
      const selectedUnit = unit?.find((option) => option.id === parseInt(value));
      const unitName = selectedUnit ? selectedUnit.name : '';
      obj_fn.setData((prevState) => ({ ...prevState, unit_name: unitName }));
    }

    // if (field === 'stock_cost') {
    //   const cost = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    //   obj_fn.setData((prevState) => ({ ...prevState, [field]: cost }))
    // }

    //.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),

    // if (field == "typePerson") {
    //   if (value == "Jurídica") {
    //     obj_fn.setData((prevState) => ({
    //       ...prevState,
    //       document_type: "CNPJ",
    //     }));
    //   } else if (value == "Física") {
    //     obj_fn.setData((prevState) => ({ ...prevState, document_type: "CPF" }));
    //   }
    // }
  };

  // Função para lidar com a alteração de valores nos campos de estoque
  const handleStockChange = (event, index) => {
    // console.log(event);
    const newQuantities = [...data.stock_quanty_array.split(',')]; // Cria uma cópia do array de quantidades
    newQuantities[index] = event.target.value; // Atualiza o valor correspondente ao índice
    const newStockQuantyArray = newQuantities.join(','); // Atualiza o array de quantidades como uma string

    // Atualiza o estado com o novo array de quantidades
    obj_fn.setData((prevState) => ({
      ...prevState,
      stock_quanty_array: newStockQuantyArray,
    }));
  };

  const handleUnitChange = (event, value) => {
    // Verifica se o evento é um evento de Autocomplete ou um evento de input tradicional
    // console.log(event.target, value);
    if (value !== null && value !== undefined) {
      // Tratamento para o Autocomplete, assumindo que newValue é o objeto selecionado
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_unit: value.id,
      }));
    } else {
      // Tratamento para o Autocomplete
      // Supondo que newValue seja o objeto selecionado e você deseja atualizar o estado com o id do objeto
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_unit: '',
      }));
    }
  };

  const handlePackagingChange = (event, value) => {
    // Verifica se o evento é um evento de Autocomplete ou um evento de input tradicional
    // console.log(event.target, value);
    if (value !== null && value !== undefined) {
      // Tratamento para o Autocomplete, assumindo que newValue é o objeto selecionado
      obj_fn.setData((prevState) => ({
        ...prevState,
        id_packaging: value.id,
        packaging_name: value.name,
      }));
    } else {
      // Tratamento para o Autocomplete
      // Supondo que newValue seja o objeto selecionado e você deseja atualizar o estado com o id do objeto
      obj_fn.setData((prevState) => ({
        ...prevState,
        packaging: '',
      }));
    }
  };

  // cadastro rapido:
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const testes = category.find((option) => option.id === data.id_category);
  // console.log(testes);

  const [selectedValue, setSelectedValue] = useState({
    id: data.id_category,
    name: category.find((option) => option.id === data.id_category).name,
  });

  // Organizar a lista de categorias em ordem alfabética
  const categorySorted = category.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Convertendo para maiúsculas para garantir uma comparação sem distinção entre maiúsculas e minúsculas
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0; // Retorna 0 se os nomes forem iguais
  });

  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  const categoryWithNewOption = [
    { id: 'new', name: 'Adicionar nova categoria' }, // Item especial para abrir o modal
    ...categorySorted,
  ];

  // console.log(categoryWithNewOption);
  // cadastro rapido:

  const handleCategoryChange = (event, value) => {
    if (value) {
      const selectedCategory = value;

      obj_fn.setData((prevState) => ({
        ...prevState,
        id_category: selectedCategory.id,
        category_name: selectedCategory.name,
      }));
    }
  };

  // const para validar o button continuar
  const isError = () => {
    let valid = false;
    if (!data.name || data.name.length < 5 || data.id_unit === 0 || data.id_category === 0 || data.description.length >= 500 || data.observacoes.length >= 500) {
      valid = true;
    }

    return valid;
  };

  const returnError = () => {
    return true;
  };

  const fileSlectedHandler = (event) => {
    // console.log(event.target.files[0]);

    var uploadedFile = {
      file: event.target.files[0],
      id: 1,
      name: event.target.files[0].name,
      size: event.target.files[0].size,
      type: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };

    // console.log(uploadedFile);

    // this.setState({
    //   selectedFile: uploadedFile,
    // });

    // console.log(this.selectedFile);

    // this.setState({
    //   selectedFile: event.target.files[0],
    // });
  };

  // console.log(data);

  // TODO: LIMITAR OBSERVAÇÕES E DESCRIÇÃO A 500 CATACTERES

  // options da lista de patrimonio:

  const property_id = localStorage.getItem('Property') || 1;
  // console.log('reposPatrimony ');
  const reposPatrimony = usePatrimonyReposStore((state) => state);
  //console.log(reposPatrimony)
  // console.log(reposPatrimony.listPatrimony);

  const reposPatrimonyFilter = reposPatrimony.listPatrimony.filter((item) => item.id_property == property_id);
  // console.log(reposPatrimonyFilter);

  const [listStockLocation, setListStockLocation] = useState(reposPatrimonyFilter);

  // console.log(listStockLocation);

  //adicionar um item à sua lista de opções que represente a ação de abrir o modal.
  const patrimonyWithNewOption = [
    { id: 'new', name: 'Adicionar novo patrimônio' }, // Item especial para abrir o modal
    ...listStockLocation,
  ];

  const location = listStockLocation.filter((item) => item.id == data.location)[0];
  // console.log(location);
  const [selectedValueLocation, setSelectedValueLocation] = useState(location);
  //const [showAddNewLocation, setShowAddNewLocation] = useState(true)

  // console.log('setSelectedValueLocation');
  // console.log(selectedValueLocation);

  const handleChangePatrimony = (event, value) => {
    // console.log('handleChangePatrimony');
    // console.log(value);
    if (value) {
      const setSelectedValueLocation = value;
      // console.log(setSelectedValueLocation);
      obj_fn.setData((prevState) => ({
        ...prevState,
        location: setSelectedValueLocation.id,
      }));
    }
  };

  // imagem
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageDecode, setImageDecode] = useState(null);

  const handleImageUpload = (base64Image) => {
    setLoadingImage(true); // Inicia o indicador de carregamento
    obj_fn.setData((prevData) => ({
      ...prevData,
      image: base64Image,
    }));

    setImageDecode(base64Image);
    setTimeout(() => {
      setLoadingImage(false); // Finaliza o indicador de carregamento após o processamento da imagem
    }, 1000);
  };

  const handleDeleteImage = () => {
    // console.log(data);
    setImageDecode(null); // Isso remove a imagem do estado
    obj_fn.setData((prevState) => ({ ...prevState, ['url']: null }));
    obj_fn.setData((prevState) => ({ ...prevState, ['image']: null }));
  };

  // console.log('fim ');
  // console.log(data);

  // location
  let product_location_array = [];
  let stockQuantities = [];
  if (typeof data.stock_quanty_array === 'string') {
    // Divide id_product_location_array em um array de quantidades correspondentes às localizações
    product_location_array = data.id_product_location_array ? data.id_product_location_array.split(',').map(Number) : [];
    // console.log(product_location_array);
    //[1, 2]

    // Divide stock_quanty_array em um array de quantidades correspondentes às localizações
    stockQuantities = data.stock_quanty_array ? data.stock_quanty_array.split(',').map(Number) : [];
    // console.log(stockQuantities);
    //[5, 10]
  }

  console.log(data);

  return (
    <>
      <div className="d-lg-none">
        <Grid item xs={12} md={12} alignItems="center" style={{ height: 120 }} sx={{ mt: -4, mb: 4 }}>
          <Box display="flex" alignItems="center">
            {!showTextFase ? (
              <ThreeDotsSpinner />
            ) : (
              <Typewriter
                text="Registre os produtos mais usados na propriedade para automatizar os processos futuros de compra e venda, proporcionando eficiência e praticidade."
                variant={'subtitle1'}
                color={'#2e7d32'}
              />
            )}
          </Box>
        </Grid>
      </div>

      <div className="d-none d-lg-block">
        <Grid item xs={12} md={12} alignItems="center" style={{ height: 60 }} sx={{ mt: -4, mb: 4 }}>
          <Box display="flex" alignItems="center">
            {!showTextFase ? (
              <ThreeDotsSpinner />
            ) : (
              <Typewriter
                text="Registre os produtos mais usados na propriedade para automatizar os processos futuros de compra e venda, proporcionando eficiência e praticidade."
                variant={'subtitle1'}
                color={'#2e7d32'}
              />
            )}
          </Box>
        </Grid>
      </div>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>
              <Input sx={{ mr: 1 }} />
              Informações do produto
            </Typography>
          </Stack>
        </Box>
      </Grid>

      {/* Informações gerais */}
      <Grid container spacing={4} sx={{ mb: 2 }}>
        {/* NOME */}
        <Grid item xs={12} sm={2}>
          <div>
            <Card>
              <CardContent>
                <Grid container spacing={4} sx={{}}>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                      {data.category_name ? data.category_name.toUpperCase() : 'PRODUTO'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                      <strong>{data.name ? data.name.toUpperCase() : 'Nome do produto'}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                      {data.packaging_name == 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume_pkg} ${data?.unit_sigla}`}
                    </Typography>
                    <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                      <strong>{data.brand ? data.brand : 'marca'}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                InputLabelProps={{ style: { color: '#2e7d32' } }}
                id="name"
                name="name"
                label={'Nome do produto'}
                fullWidth
                autoComplete="name"
                variant="standard"
                color="success"
                value={data.name}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, true)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Assignment />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!data.name || data.name.length < 5}
                helperText={'* Mínimo de 5 caracteres'}
              />
            </Grid>
            {/* CATEGORIA */}
            <Grid item xs={6} md={6}>
              <Autocomplete
                // required
                name="category_name"
                onChange={(event, newValue) => {
                  if (newValue && newValue.id === 'new') {
                    // Abre o diálogo para adicionar um novo tipo de patrimônio
                    toggleDialog(true);
                  } else {
                    // console.log(event, newValue);
                    setSelectedValue(newValue); // Atualize para usar setSelectedValue
                    handleCategoryChange(event, newValue);
                  }
                }}
                //onChange={handleCategoryChange}
                disableCloseOnSelect={false}
                filterSelectedOptions
                color="success"
                id={category.id}
                value={selectedValue} // Certifique-se de que selectedValue é atualizado corretamente
                multiple={false}
                options={categoryWithNewOption}
                ListboxProps={{ style: { maxHeight: 225 } }}
                isOptionEqualToValue={(option, val) => option.id === val.id || (data.id_category && data.id_category?.id == option.id)}
                // value={data.id_category}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  // <li {...props}>
                  //   <Adjust color="success" sx={{ mr: 1, width: '15px' }} /> {option.name}
                  // </li>
                  <li
                    {...props}
                    style={
                      option.id === 'new'
                        ? {
                            backgroundColor: '#f0f0f0',
                            color: '#007bff',
                            fontWeight: 'bold',
                          }
                        : {}
                    }
                  >
                    {option.id === 'new' ? (
                      <>
                        <AddCircleOutlineIcon
                          style={{
                            color: '#2e7d32',
                            marginRight: '8px',
                            fontSize: '15px',
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{
                            color: '#2e7d32',
                          }}
                        >
                          {option.name}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <AdjustIcon color="success" sx={{ mr: 1, width: '15px' }} />
                        {option.name}
                      </>
                    )}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    // required
                    variant={'outlined'}
                    label={'Categoria'}
                    color="success"
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    // error={data.id_category === 0}
                    helperText={categoryWithNewOption.length <= 1 ? 'Erro de conexão com o servidor. Não foi possível exibir as opções de categoria.' : 'Selecione a categoria'}
                    focused
                    fullWidth
                  />
                )}
                noOptionsText="Sem resultados"
              />
            </Grid>
            {/* embalagem */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, newValue) => handlePackagingChange(event, newValue)}
                id="id_packaging"
                options={packaging}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={packaging.find((option) => option.id === data.id_packaging) || null}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Unidade/Embalagem"
                    variant="outlined"
                    color="success"
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    helperText={
                      packaging.length === 0 ? (
                        'Erro de conexão com o servidor. Não foi possível exibir as opções de unidade.'
                      ) : (
                        <>
                          {`O registro do produto ${data.name} será em unidade ou embalagem?`}
                          <Tooltip title={`unidade: " \n` + `Embalagens".\n`}>
                            <HelpOutlineIcon sx={{ color: '#808080' }} />
                          </Tooltip>
                        </>
                      )
                    }
                    fullWidth
                  />
                )}
                ListboxProps={{ style: { maxHeight: 225 } }}
                noOptionsText="Sem resultados"
              />
            </Grid>
            {/* volume */}
            {data.packaging_name !== 'Unidade' && (
              <Grid item xs={6} sm={2}>
                <TextField
                  required
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="volume"
                  name="volume"
                  label={'Volume da embalagem'}
                  fullWidth
                  autoComplete="Volume"
                  variant="standard"
                  color="success"
                  value={data.volume_pkg}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Assignment />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // error={!data.name || data.name.length < 5}
                  helperText={'Volume'}
                />
              </Grid>
            )}
            {/* UNIDADE */}
            <Grid item xs={6} md={data.packaging_name == 'Unidade' ? 4 : 4}>
              <Autocomplete
                onChange={(event, newValue) => handleUnitChange(event, newValue)}
                id="id_unit"
                options={unit}
                getOptionLabel={(option) => `${option.name} (${option.sigla})`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={unit.find((option) => option.id === data.id_unit) || null}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Medida"
                    variant="standard"
                    color="success"
                    InputLabelProps={{ style: { color: '#2e7d32' } }}
                    helperText={
                      unit.length === 0 ? (
                        'Erro de conexão com o servidor. Não foi possível exibir as opções de unidade.'
                      ) : (
                        <>
                          {`Selecione a unidade de medida padrão para o seu produto ${data.name}`}
                          {/* <Tooltip
                          title={
                            `Sólidos (comprimidos ou pílulas), usa-se "unidades (un)" ` +
                            ` Sólidos (graos, sementes, ingredientes) , poderíamos usar "quilos (kg)".\n` +
                            `Para líquidos,Líquidos, "mililitros
                        (ml)" ou "litros (L)" poderiam ser mais apropriados.`
                          }
                        >
                          <HelpOutlineIcon sx={{ color: '#808080' }} />
                        </Tooltip> */}
                        </>
                      )
                    }
                    fullWidth
                  />
                )}
                ListboxProps={{ style: { maxHeight: 225 } }}
                noOptionsText="Sem resultados"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* VALOR DO PRODUTO */}
        {/* <Grid item xs={6} md={6}>
            <CurrencyTextField
              value={data.valor_produto}
              label="Valor unitário do Produto"
              name="valor_produto"
              onChange={handleChange}
              helperText={`Defina o valor do produto ${data.name}`}
            />
          </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            id="brand"
            name="brand"
            label={'Marca'}
            fullWidth
            // autoComplete="codigo"
            variant="outlined"
            color="success"
            value={data.brand}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Assignment />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextareaAutosize
            minRows={3}
            maxRows={10}
            name="description"
            placeholder="Descrição do produto (limite de 500 caracteres)."
            value={data.description}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            // style={{ width: "100%", padding: "8px", border: "2px solid red" }}
            style={data.description.length >= 500 ? { width: '100%', padding: '8px', border: '2px solid red' } : { width: '100%', padding: '8px' }}
          />
        </Grid>

        {/* Fotos */}
        <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
          <Grid item xs={12} sm={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Notes />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Foto</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    {!data.url && !data.image ? (
                      <ImageUploadComponent onImageUpload={handleImageUpload} maxWidthOrHeight={'150'} setData={obj_fn.setData} type={'produto'} />
                    ) : (
                      <Card>
                        {data.file ? (
                          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                            <CardHeader
                              avatar={<Avatar aria-label="image" src={`data:image/jpeg;base64,${imageDecode}`}></Avatar>}
                              title={data?.name || ''}
                              subheader={data?.group || ''}
                              action={
                                <IconButton onClick={handleDeleteImage} aria-label="delete image">
                                  <DeleteForeverIcon />{' '}
                                </IconButton>
                              }
                            />
                          </Box>
                        ) : (
                          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                            <CardHeader
                              // avatar={<Avatar aria-label="image" src={`data:image/jpeg;base64,${imageDecode}`}></Avatar>}
                              avatar={<Avatar aria-label="image" src={data.url}></Avatar>}
                              title={data?.name || ''}
                              subheader={data?.group || ''}
                              action={
                                <IconButton onClick={handleDeleteImage} aria-label="delete image">
                                  <DeleteForeverIcon />{' '}
                                </IconButton>
                              }
                            />
                          </Box>
                        )}
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
          <Grid item xs={12} sm={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Notes />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}> Estoque</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex' }}>
                  {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5, mb: 3 }}>
                    {/* <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
              <>
                <strong>Controle tudo que entra e sai do estoque</strong>
              </>
            </Typography> */}
                    <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                      <>
                        <strong>Controle do estoque (balanço): </strong>
                        <span>
                          Qualquer diferença entre o inventário físico e os registros do sistema deve ser investigado e ajustada nos campos abaixo. Ao modificar a quantidade disponível e o custo
                          médio, os dados do sistema se atualizaram de acordo com aqui definido.
                        </span>
                      </>
                    </Typography>
                  </Stack>
                </Box>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={2}>
                    <div>
                      <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                        <strong>
                          {data.stock_quanty} {data.packaging_name}s
                        </strong>
                      </Typography>
                      <Card>
                        <CardContent>
                          <Grid container spacing={4} sx={{}}>
                            <Grid item xs={12} sm={12}>
                              <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                                {data.category_name ? data.category_name.toUpperCase() : 'PRODUTO'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                                <strong>{data.name ? data.name.toUpperCase() : 'Nome do produto'}</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                              <Typography variant="body2" style={{ textAlign: 'center' }}>
                                {data.packaging_name == 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume_pkg} ${data?.unit_sigla}`}
                              </Typography>
                              <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                                <strong>{data.brand ? data.brand : 'marca'}</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ mt: 3 }}>
                    <Grid container spacing={4} sx={{}}>
                      {/* <Grid item xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          label="Quantidade Total"
                          variant="outlined"
                          value={data.stock_quanty}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid> */}

                      {/* Mostrar a quantidade para cada localização */}
                      {data.locationData.map((loc, index) => (
                        <Grid item xs={12} key={loc.id}>
                          <TextField
                            fullWidth
                            label={`Quantidade em ${loc.name}`}
                            variant="outlined"
                            value={data?.stock_quanty_array?.split(',')[index]}
                            onChange={(event) => handleStockChange(event, index)} // Chama a função handleStockChange com o índice correspondente
                            InputProps={{
                              readOnly: false, // Definir readOnly como false para permitir a edição do campo
                            }}
                          />
                        </Grid>
                      ))}

                      <Grid item xs={6} sm={6}>
                        <TextField
                          disabled
                          required
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          id="stock_quanty"
                          name="stock_quanty"
                          label={'Quantidade disponível'}
                          fullWidth
                          autoComplete="stock_quanty"
                          variant="outlined"
                          color="success"
                          value={data.stock_quanty}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e, true)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <Assignment />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        {/* <CurrencyTextField
                          //disabled
                          value={data.stock_cost}
                          label="Custo médio unitário do produto"
                          name="stock_cost"
                          onChange={handleChange}
                          helperText="Defina o valor de compra do produto"
                        /> */}
                        <GenericValueField
                          handleChange={handleChange}
                          options={{
                            required: true,
                            id: 'stock_cost',
                            name: 'stock_cost',
                            label: 'Custo médio unitário do produto',
                            value: data.stock_cost,
                            helperText: 'Defina o valor de compra do produto',
                            endAdornment: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          id="stock_quanty_min"
                          name="stock_quanty_min"
                          label={'Estoque mínimo'}
                          fullWidth
                          autoComplete="stock_quanty_min"
                          variant="outlined"
                          color="success"
                          value={data.stock_quanty_min}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e, true)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <Assignment />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          InputLabelProps={{ style: { color: '#2e7d32' } }}
                          id="stock_quanty_max"
                          name="stock_quanty_max"
                          label={'Estoque máximo'}
                          fullWidth
                          autoComplete="stock_quanty_max"
                          variant="outlined"
                          color="success"
                          value={data.stock_quanty_max}
                          onChange={handleChange}
                          onBlur={(e) => handleChange(e, true)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <Assignment />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Código de barra */}
        <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
          <Grid item xs={12} sm={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Notes />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Código de barra</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      InputLabelProps={{ style: { color: '#2e7d32' } }}
                      id="cod_barra"
                      name="cod_barra"
                      label={'Código de barra'}
                      fullWidth
                      autoComplete="codigo"
                      variant="outlined"
                      color="success"
                      value={data.cod_barra}
                      onChange={handleChange}
                      onBlur={(e) => handleChange(e, true)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Assignment />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // error={!data.name || data.name.length < 5}
                      //helperText={"* Mínimo de 5 caracteres"}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
          <Grid item xs={12} sm={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Notes />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}> Observações gerais</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={10}
                      name="observacoes"
                      placeholder="Observações Gerais (limite de 500 caracteres)."
                      value={data.observacoes}
                      onChange={handleChange}
                      onBlur={(e) => handleChange(e, true)}
                      style={
                        data.observacoes.length >= 500
                          ? {
                              width: '100%',
                              padding: '8px',
                              border: '2px solid red',
                            }
                          : { width: '100%', padding: '8px' }
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Pesos e Medidas */}
        <Grid container spacing={3} sx={{ mb: 2, mt: 1, ml: 1 }}>
          <Grid item xs={12} sm={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Notes />
                <Box sx={{ ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>Pesos e Dimensões</Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={3} sm={3}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <img src="/dist/image/caixa_medida.jpg" alt="Descrição da imagem" style={{ maxWidth: '60%', maxHeight: '60%' }} />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="altura"
                            endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={data.altura}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Altura</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="largura"
                            endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={data.largura}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Largura</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="profundidade"
                            endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={data.profundidade}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Profundidade</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="volume"
                            endAdornment={<InputAdornment position="end"></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={data.volume}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Volume</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="peso_liquido"
                            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={data.peso_liquido}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Peso Líquido</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            name="peso_bruto"
                            endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            alue={data.peso_bruto}
                            onChange={handleChange}
                          />
                          <FormHelperText id="outlined-weight-helper-text">Peso Bruto</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
