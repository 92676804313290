import React from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import SignpostIcon from "@mui/icons-material/Signpost";
import CreationsTabsPlanning from "./components/CreationsTabsPlanning";
import FormGoalButtonPlanning from "./formGoalPlanning/FormGoalButtonPlanning";

export default function PlanningLivestockContent() {
  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
                <SignpostIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Planejamento da Criação
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <FormGoalButtonPlanning />
              </Box>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            {/* <PlanningLivestockContentchoice /> */}
            {/* <CreationsTabsPlanning /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
