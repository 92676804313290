import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardContent, CardHeader, Chip, Divider, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import IEProgressBars from './IEProgressBars';
import { green } from '@mui/material/colors';
import StepperIE from './StepperIE';
import CircularStaticIE from './CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { useEffect } from 'react';
import { useState } from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import IconsLivestockActivities from '../../../components/Icons/IconsLivestockActivities';
import SpaIcon from '@mui/icons-material/Spa';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LightTooltip from '../../../components/Tooltip/LightTooltip';
import propertyProgress from './propertyProgress';
import propertySettingsProgress from './propertySettingsProgress';
import livestockProgress from './livestockProgress';
import harvestProgress from './harvestProgress';

export default function CardIRProperty({ bar, newData, property, livestock, harvest }) {
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  console.log('userCurrent');
  console.log(userCurrent);
  const user = userCurrent;
  console.log(user);
  const name = user.name;
  const charName = name[0].toUpperCase();
  console.log(charName);

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  console.log(newData);

  // porcentagem de participação de cada módulo
  //CircularStaticIE
  // Calcular a porcentagem de participação de cada módulo
  const module = [{ stock: newData[0]?.porcentagem }, { financial: '0' }, { livestock: newData[1]?.porcentagem }, { harvest: '0' }];
  console.log(module);
  function value_percentage(modules) {
    // Inicializa o valor total como 0
    let totalPercentage = 0;
    const numModules = modules.length;

    // Itera sobre cada objeto no array
    for (const item of modules) {
      // Itera sobre as chaves do objeto
      for (const key in item) {
        // Obtém a porcentagem da chave atual
        const percentage = parseFloat(item[key]);
        // Adiciona a porcentagem ao total
        totalPercentage += percentage;
      }
    }

    // Calcula a média das porcentagens
    const averagePercentage = totalPercentage / numModules;

    return averagePercentage;
  }

  const [total, setTotal] = useState(0);

  useEffect(() => {
    // Chama a função com o array de módulos
    setTotal(value_percentage(module));
  }, [newData]);

  console.log('Porcentagem média de participação1:', total);

  console.log(newData);

  return (
    <Card>
      <Divider />
      <CardContent>
        <Grid>
          <Grid xs={12}>{/* <IEProgressBars bar={bar} /> */}</Grid>
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Administração da Propriedade</Typography>
              </AccordionSummary>

              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <SettingsSuggestIcon widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome da propriedade(${propertySettingsProgress(property).status.label}) \n Localização no Mapa(${
                        propertySettingsProgress(property).status.address
                      }) \n Área total da propriedade(${propertySettingsProgress(property).status.area})`}
                    >
                      <strong className="ml-1 ">Configurações inicias</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[0]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}

              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <MapsHomeWorkIcon widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome da propriedade(${propertyProgress(property).status.label}) \n Localização no Mapa(${propertySettingsProgress(property).status.address}) Área total da propriedade(${
                        propertyProgress(property).status.area
                      }) \n  Telefone(${propertyProgress(property).status.tel}) \n  Imagem(${propertyProgress(property).status.image})`}
                    >
                      <strong className="ml-1 ">Propriedade</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[1]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}

              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <IconsLivestockActivities widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome do projeto(${livestockProgress(livestock).status.label}) \n Quantidade de áreas(${livestockProgress(livestock).status.areas}) 
                      Animais(${livestockProgress(livestock).status.animais}) \n `}
                    >
                      <strong className="ml-1 ">Criações</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[2]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}

              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <SpaIcon style={{ fontSize: 30 }} />
                    <LightTooltip title={`Nome do projeto(${harvestProgress(harvest).status.label}) \n Quantidade de áreas(${harvestProgress(harvest).status.areas})`}>
                      <strong className="ml-1 ">Safras</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[3]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}
              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <MapIcon style={{ fontSize: 30 }} /> <strong className="ml-1 ">Áreas</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[4]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}
              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <WidgetsIcon style={{ fontSize: 30 }} /> <strong className="ml-1 ">Estoque</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[5]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <MapsHomeWorkIcon style={{ fontSize: 30 }} /> <strong className="ml-1 ">Patrimônio</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[6]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>
              {/* <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <PublicIcon style={{ fontSize: 30 }} /> <strong className="ml-1 ">Mapa</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars bar={bar} />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <GroupsIcon style={{ fontSize: 30 }} /> <strong className="ml-1">Equipe</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars bar={bar} />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <ConnectWithoutContactIcon style={{ fontSize: 20 }} /> <strong className="ml-1">Solicitações</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars bar={bar} />
                  </Grid>
                </Grid>
              </AccordionDetails> */}
            </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
