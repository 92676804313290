import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

// create the store
const usePatrimonyMaintenanceReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPatrimonyMaintenance: [],
        patrimonyMaintenanceRepos: {
          label: '',
          id: 0,
          id_property: 0,
          tipo: 'Patrimony',
          name: '',
        },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: farm_id };
          }
          if (!item.id_property) {
            item.id_property = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('/patrimony/maintenance/list', item, header);
          // console.log('patrimony/maintenance/list*****************');
          const data = await response.data;
          const data_v = data?._value || [];
          // console.log('maintenance1');
          // console.log(data_v[0].services);
          await set({
            listPatrimonyMaintenance: data_v.map((row) => ({
              id: row.id,
              id_user: row.id_user,
              name_user: row.name_user,
              id_property: row.id_property,
              id_patrimony: row.id_patrimony,
              date_start: dayjs(row.date_start).format('YYYY-MM-DD HH:mm:ss'),
              date_prev_end: dayjs(row.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
              date_end: dayjs(row.date_end).format('YYYY-MM-DD HH:mm:ss'),
              date_start_f: dayjs(row.date_start).format('DD/MM/YYYY'),
              date_prev_end_f: dayjs(row.date_prev_end).format('DD/MM/YYYY'),
              date_end_f: dayjs(row.date_end).format('DD/MM/YYYY'),
              value: row.value,
              isDeleted: row.isDeleted,
              progress: row.progress,
              description: row.description,
              id_trans_pay: row.id_trans_pay,
              record_pay: typeof row.record_pay === 'string' ? JSON.parse(row.record_pay || '{}') : row.record_pay || {},
              info_pay: typeof row.info_pay === 'string' ? JSON.parse(row.info_pay || '{}') : row.info_pay || {},
              date_payment: row.date_payment ? dayjs(row.date_payment).format('YYYY-MM-DD HH:mm:ss') : '',
              id_trans_inv: row.id_trans_inv,
              record_inv: typeof row.record_inv === 'string' ? JSON.parse(row.record_inv || '{}') : row.record_inv || {},
              info_inv: typeof row.info_inv === 'string' ? JSON.parse(row.info_inv || '{}') : row.info_inv || {},
              date_inventory: row.date_inventory ? dayjs(row.date_inventory).format('YYYY-MM-DD HH:mm:ss') : '',
              products: typeof row.products === 'string' ? JSON.parse(row.products || '[]') : row.products || [],
              services: typeof row.services === 'string' ? JSON.parse(row.services || '[]') : row.services || [],
            })),
          });

          return data;
        },
        // pega as manutenções de acordo com o [key e val] informados
        getPatrimonyMaintenanceStore: (key, val) => {
          return get().listPatrimonyMaintenance.filter((maintenance) => {
            return maintenance[key] === val;
          });
        },

        // Adiciona uma nova
        addPatrimonyMaintenanceStore: (patrimony) => {
          set((state) => ({
            listPatrimonyMaintenance: [...state.listPatrimonyMaintenance, patrimony],
          }));
        },
        // Gera uma nova
        delPatrimonyMaintenanceStore: (id) => {
          set({
            listPatrimonyMaintenance: get().listPatrimonyMaintenance.filter((patrimony) => {
              return patrimony.id !== id;
            }),
          });
        },
        // atualiza listLivestock de acordo com id, key e val informados
        updatePatrimonyMaintenanceStore: (id, key, val) => {
          set({
            listPatrimonyMaintenance: get().listPatrimonyMaintenance.map((patrimony) => {
              if (patrimony.id === id) {
                if (key === 'all') {
                  // atualiza todos os dados
                  patrimony = val;
                } else {
                  patrimony[key] = val;
                }
              }
              return patrimony;
            }),
          });
          return get().listPatrimonyMaintenance;
        },
      }),
      { name: 'reposPatrimonyMaintenance' }
    )
  )
);

export default usePatrimonyMaintenanceReposStore;
