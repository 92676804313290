
import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Switch, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import IconCheckboxes from "../../Messenger/components/IconCheckboxes";
import FullScreenDialogEdit from "../../Messenger/DialogMessenger/FullScreenDialogEdit";
import MenuCard from "../../Messenger/components/MenuCard";
import { format } from "date-fns";
import moment from 'moment'
import Badge from '@mui/material/Badge';
import Loading from "../../../components/Loader/Loading";
import { Edit, LocationOn, PollTwoTone } from "@mui/icons-material";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NotesIcon from '@mui/icons-material/Notes';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditProfileDraggableDialog from "./EditPermission/EditProfileDraggableDialog";
import RolePermission from "./RolePermission";

export default function CardProfile({ edit_user, setLoading }) {
  //console.log("<CardProfile/>")

  const [checked, setChecked] = useState(true);
  //const [isActive, setIsActive] = useState(true);
  const switchHandler = (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked)
  };

  // converter id_role para o nome da role
  const role = RolePermission(edit_user.id_role)

  return (
    <>
      <div >
        {false ? <Loading /> :
          // <Card sx={{ width: 350, height: 180, p: 1, m: 1 }}>
          <Card sx={{ width: 350, height: 100, p: 1, m: 1 }}>
            <CardHeader
              sx={{ ml: -1 }}
              avatar={
                <Avatar variant="rounded" sx={{ bgcolor: green[500] }} aria-label="recipe">
                  {edit_user.name[0].toUpperCase()}
                </Avatar>
              }
              action={
                <span sx={{ ml: 1 }} aria-label="settings">
                  <EditProfileDraggableDialog edit_user={edit_user} setLoading={setLoading} />
                </span>
              }
              title={
                <Typography variant="button">
                  <strong>{edit_user.name}</strong>
                </Typography>
              }
              subheader={
                <Stack spacing={0.5} >
                  <Typography variant="body2" color="text.secondary">
                    <HowToRegIcon sx={{ fontSize: 18, color: grey[500] }} /> {role}
                  </Typography>
                  <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    <MailOutlineIcon sx={{ fontSize: 18 }} /> {edit_user.email}
                  </Typography>
                </Stack>
              }
            />
            {/* <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
              <Chip onClick={() => alert('You clicked the Joy Chip!')} >Active account</Chip> 
               <Typography variant="body2" sx={{ ml: 1 }}>conta ativa</Typography> 
              {checked ? <Chip variant="outlined" label="conta ativa" color="success" >Conta inativa</Chip> : <Chip label="conta inativa" >Conta inativa</Chip>}
              <Switch
                color="success"
                checked={checked}
                onChange={switchHandler}
              />

            </Stack> */}
          </Card>
        }
      </div>

    </>
  );
}