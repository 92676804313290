import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { green } from "@mui/material/colors";

function createData(name, view, edit, trash) {
  return { name, view, edit, trash };
}

const rows = [
  createData('Informações Gerais', 1, 0, 0),
  createData('Criações', 1, 0, 0),
  createData('Safras', 1, 0, 0),
  createData('Clima', 1, 0, 0),
];

export default function TableProfileCentral() {
  return (
    <TableContainer component={Paper}  >
      <Table sx={{ minWidth: 650, p: 0, m: 0 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Funcionalidade</TableCell>
            <TableCell align="right"><VisibilityIcon fontSize="small" color="action" /></TableCell>
            <TableCell align="right"><EditIcon fontSize="small" color="action" /></TableCell>
            <TableCell align="right"><DeleteForeverIcon fontSize="small" color="action" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.view ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
              <TableCell align="right">{row.edit ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
              <TableCell align="right">{row.trash ? <CheckCircleOutlineIcon style={{ color: green[500] }} /> : <HighlightOffIcon color="disabled" />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}