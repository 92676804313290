import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";

import { green } from "@mui/material/colors";
import { useState } from "react";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import api from "../../services/api";
import useStore from "../../store/useStore";
import { useContext } from "react";
import { Context } from "../../components/Context/AuthContext";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterCostCenterDialogModalGroupCategory({ conta, categories }) {
  const [open, setOpen] = useState(false);
  // const [selectedRadio, setSelectedRadio] = useState("Categoria Mãe");
  console.log(conta)
  //console.log(categories);

  const usestore = useStore();
  const reposAccount = usestore.reposAccount
  var farm_id = localStorage.getItem("Property");
  const { aux_add, setAuxAdd } = useContext(Context);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [categoryName, setCategoryName] = useState("");


  const handleCategoryNameChange = (event) => {
    console.log(event.target.value);

    setCategoryName(event.target.value); // Si deseas limpiar el input después de agregar la categoría.
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handleSave")
    // Aqui você pode adicionar a lógica para salvar a categoria no grupo selecionado.
    // categoryName contém o nome da categoria e selectedGroup contém o grupo selecionado.
    // groupedCategories pode ser usado para obter informações sobre os grupos existentes.
    // Por exemplo, você pode adicionar categoryName a groupedCategories[selectedGroup].
    const newCategory = {
      id: categories.length + 15,
      id_property: 1,
      name: categoryName,
      nature: conta == "expense" ? "CREDORA" : "DEVEDORA",
      mother: "RECEBER",
      info: JSON.stringify(["centro_custo", "", "category"]),
      status: ["centro_custo", "default", "category"],
      subcategories: [],
    };
    //setCategories([...categories, newCategory]);
    //console.log(categories);

    if (true) {
      const header = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {

        const res = await api.post("/finances/account", newCategory, header).then((response) => {
          console.log("response.data");
          console.log(response.data);
          if (response.data.isSuccess) {
            //setIsLoading(true);
            // console.log(isLoading)
            // setIsLoading(true)
            toast.success("Grupo de Categoria criada com sucesso");

            reposAccount.fetch({ 'id_farm': farm_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setCategoryName("")
              //handleClose();
              //navigate("/areasmaps");

            });

            // setTimeout(() => {
            //   handleClose();
            // }, 2000);
          } else {
            console.log(response)
          }
        }).catch((error) => {
          console.log("error");
          //console.log(error);
          toast.error(error.response.data);
        });

        //const response = await api.post("/finances/account", newCategory, header);
        //console.log(response);
        //console.log(response.response)

        // if (response.response.data.isSuccess) {
        //   //setIsLoading(true);
        //   // console.log(isLoading)
        //   // setIsLoading(true)
        //   reposAccount.fetch({ 'id_farm': farm_id }).then((res) => {
        //     setAuxAdd(aux_add + 1);
        //     //handleClose();
        //     //navigate("/areasmaps");

        //   });

        //   // setTimeout(() => {
        //   //   handleClose();
        //   // }, 2000);
        // } else {
        //   toast.error("Erro na requisição: " + response.response.data);
        // }
      } catch (error) {
        console.error("Erro na requisição:", error);
        toast.error("Falha na comunicação com o servidor");
      }

      // Após salvar, você pode fechar o diálogo.
      setOpen(false);
    }
  }

  return (
    <div>
      <Button variant="outlined" color={conta == "expense" ? "error" : "success"} onClick={handleClickOpen}>
        Grupo de Categoria
      </Button>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        {/* <DialogTitle>Cadastrar Grupo de Categoria</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom variant="h6" component="div">
                Grupo de Categoria
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: -2, mb: 2, borderColor: green[700] }} />
          <Grid container spacing={0} sx={{ mb: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom component="div">
                Crie uma grupo de categorias para organizar as suas categorias (centro de custos).
              </Typography>
            </Grid>
          </Grid>
          <TextField color="success" label="Nome do Grupo da Categoria" variant="outlined" fullWidth value={categoryName} onChange={handleCategoryNameChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Cancelar
          </Button>
          <Button onClick={handleSave} variant="contained" color="success">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
