import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Fab} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "../../mapsAreasDraw/components/MapDraw.css";
import { GoogleMap } from "@react-google-maps/api";
import DrawingCmp from "../../mapsAreasDraw/components/DrawingCmp";
import LoadScriptOnlyIfNeeded from "../../../LoadScript/LoadScriptOnlyIfNeeded";
import usePropertyReposStore from "../../../store/usePropertyReposStore";

const libraries = ["drawing"];
const apiKey = "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU";

export default function DrawArea({ path, setPath }) {
  const [isLoading, setIsLoading] = useState(false);
  const [map, setMap] = useState();

  // verificar se exite a latitude da propriedade
  const reposProperty = usePropertyReposStore(state => state);
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];

  const center = {
    lat: Number(property_data?.latitude || '-20.9230016'),
    lng: Number(property_data?.longitude || '-42.7960554'),
    address: property_data?.address || property_data?.address_area || "",
    zoom: Number(property_data?.zoom || '14'),
  };

  //Referência do mapa:onload do google para obter a referência do mapa
  // prop onLoad: Essa função onLoad recebe um parâmetro padrão que é a instância atual do mapa .
  // map: google.maps.Map
  const onLoad = (map) => {
    mapRef.current = map;
    setMap(map);
  };
  const mapRef = useRef(null);

  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  const noDraw = () => {
    setState(function set(prevState) {
      return Object.assign({}, prevState, {
        drawingMode: "maker"
      });
    });
  };

  // cria um array (paths) com lat e long de cada ponto
  // guarda o array na variável path (setPath)
  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(polygon) {
      const polyArray = polygon.getPath().getArray();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      setPath(paths);
      //Para remover o controle das ferramentas de desenho do objeto map:
      noDraw();
      polygon.setMap(null);
    }
  );

  // definindo referências para instanciar o poligono e listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  //chama setPath para editar novos paths
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  const onLoadPoly = useCallback(
    (polygon) => {
      if (isLoading) {
        return false;
      }
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(path.addListener("set_at", onEdit), path.addListener("insert_at", onEdit), path.addListener("remove_at", onEdit));
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const [drawingModeEnabled, setDrawingModeEnabled] = useState(true)
  const handleDrawingMode = () => {
    setDrawingModeEnabled(!drawingModeEnabled)
  }

  const [isEdit, SetIsEdit] = useState(true);

  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const page_height = (window.innerHeight - (iOS ? 220 : 195)) + "px";
  const page_width = (window.innerWidth - (iOS ? 80 : 380)) + "px";

  return ( <>
    <LoadScriptOnlyIfNeeded id="script-loader" googleMapsApiKey={apiKey} libraries={libraries}>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Fab sx={{ position: "fixed", bottom: "75px", right: "2%", backgroundColor: "#fff" }} color="neutral" variant="extended" size="small" onClick={() => setPath([])} aria-label="add">
          <RemoveCircleOutlineIcon sx={{ mr: 1 }} /> {iOS ? 'Rm. Pontos' : 'Remover Pontos'}
        </Fab>
      </Box>
      <GoogleMap
        id="mapDrawArea"
        mapContainerStyle={{
          height: page_height,
          width: page_width,
        }}
        center={center}
        zoom={center.zoom}
        ref={mapRef}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
        }}
      >
        <DrawingCmp 
          onLoad={onLoad} 
          isLoading={isLoading} 
          setIsLoading={setIsLoading} 
          onPolygonComplete={onPolygonComplete} 
          path={path} 
          setPath={setPath} 
          drawingModeEnabled={drawingModeEnabled} 
          onLoadPoly={onLoadPoly} 
          onUnmount={onUnmount} 
          isEdit={isEdit}
          noDrawControl={true}
        />
      </GoogleMap>
    </LoadScriptOnlyIfNeeded>
  </> );
}