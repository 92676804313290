import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BancoDoBrasilIcon from '../../../../components/Icons/bank/BancoDoBrasilIcon';
import BanckItauIcon from '../../../../components/Icons/bank/BanckItauIcon';
import BradescoIcon from '../../../../components/Icons/bank/BradescoIcon';
import NubankIcon from '../../../../components/Icons/bank/NubankIcon';
import NubankIconBg from '../../../../components/Icons/bank/NubankIconBg';
import BradescoIconBg from '../../../../components/Icons/bank/BradescoIconBg';
import CaixaEconomicaIcon from '../../../../components/Icons/bank/CaixaEconomicaIcon';
import SantanderIcon from '../../../../components/Icons/bank/SantanderIcon';
import SicoobIcon from '../../../../components/Icons/bank/SicoobIcon';
import BancoInterIcon from '../../../../components/Icons/bank/BancoInterIcon';

const BASE_URL = 'http://localhost:3004';

function ChatSidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [listBank, setListBank] = useState([]);
  const [itemDeleted, setItemDeleted] = useState(false);

  const fetchData = async () => {
    try {
      //const response = await axios.get(`${BASE_URL}/chats`);
      const response = {
        data: [
          //{ bank: "Caixa", icon: <HomeWorkIcon style={{ fontSize: "1.4rem" }} />, background: "#e5ebe9" },
          { bank: 'Caixa da Propriedade', icon: <HomeWorkIcon style={{ fontSize: '1.4rem', color: '#fff' }} />, color: '#fff', background: '#2e7d32' },
          { bank: 'Banco do Brasil', icon: <BancoDoBrasilIcon style={{ fontSize: '1.4rem' }} />, background: '#f9e900' },
          { bank: 'Itaú', icon: <BanckItauIcon style={{ fontSize: '1.4rem' }} />, background: '#ec7600' },
          { bank: 'Bradesco', icon: <BradescoIconBg style={{ fontSize: '1.4rem' }} />, color: '#fff', background: '#CC092F' },
          { bank: 'nubank', icon: <NubankIconBg style={{ fontSize: '1.4rem' }} />, color: '#fff', background: '#820ad1' },
          { bank: 'Caixa Econômica F.', icon: <CaixaEconomicaIcon style={{ fontSize: '1.4rem' }} />, background: '#fff' },
          { label: 'Banco Inter', bank: 'bancointer', icon: <BancoInterIcon style={{ fontSize: '1.4rem' }} />, background: '#fff' },
          { bank: 'Santander', icon: <SantanderIcon style={{ fontSize: '1.4rem' }} />, background: '#fff' },
          { label: 'Sicoob', bank: 'sicoob', icon: <SicoobIcon style={{ fontSize: '1.4rem' }} />, color: '#018f05', background: '#fff' },
        ],
      };

      setListBank(response.data);
    } catch (error) {
      console.log(`Erro ao recuperar os dados.\n${error}`);
    }
  };

  const handleDeleteChat = async (id) => {
    alert(id);
    try {
      await axios.delete(`${BASE_URL}/chats/${id}`);
      console.log('CHAT EXCLUÍDO COM SUCESSO');
      setItemDeleted(true);
    } catch (error) {
      console.log('Erro ao excluir chat.');
    }
  };

  useEffect(() => {
    fetchData();
    setItemDeleted(false);
  }, [itemDeleted]);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={isOpen ? 'sidebar-open' : 'sidebar-closed'}>
      <div className="icon-button-text">
        {/* <IconButton
          onClick={handleToggleSidebar}
          className="open-close-icon-button"
        >
          <MenuIcon sx={{ color: "#000" }} />
        </IconButton> */}
        <IconButton onClick={handleToggleSidebar} className="open-close-icon-button">
          {/* {isOpen ? <KeyboardDoubleArrowLeftIcon sx={{ color: '#000' }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: '#000' }} />} */}
        </IconButton>
        {isOpen && (
          <Typography className="historico-text" sx={{ color: '#000', fontWeight: '600' }}>
            Contas Bancárias
          </Typography>
        )}
      </div>
      <Box sx={{ mt: 2 }}>
        <List>
          {listBank.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                background: item.background,
                color: item.color,
                margin: '0.3rem auto',
                width: '90%',
                borderRadius: '5px',
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                  {/* <AccountBalanceIcon
                  style={{ fontSize: "1.2rem", color: "#000" }}
                /> */}
                </ListItemIcon>
                {isOpen && (
                  <ListItemText
                    className="historico-title"
                    primary={
                      <div className="flex-container">
                        <div style={{ width: '80%', fontWeight: 600 }}>{item.bank}</div>
                        <div style={{ width: '20%' }}>
                          <Link>
                            <Button onClick={() => handleDeleteChat(item.id)} color="secondary"></Button>
                          </Link>
                        </div>
                      </div>
                    }
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
}

export default ChatSidebar;
