import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop, Fade } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '80%',
    //maxWidth: '95%',
    //maxHeight: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,

    //p: 4,
};

export default function ModalImg({ uploadImage, imageDefault }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [image, setImage] = useState(uploadImage)

    console.log('image')
    console.log(imageDefault)
    console.log(uploadImage)

    useEffect(() => {
        console.log(window.location.origin)
    }, []);

    // $grid-breakpoints: (
    //     // Tela extra small (muito pequena, no português)
    //     xs: 0,
    //     // Tela small (pequena, no português)
    //     sm: 576px,
    //     // Tela média
    //     md: 768px,
    //     // Tela large (grande, no português)
    //     lg: 992px,
    //     // Tela extra large (muito grande, no português)
    //     xl: 1200px
    //   );

    //   $container-max-widths: (
    //     sm: 540px,
    //     md: 720px,
    //     lg: 960px,
    //     xl: 1140px
    //   );

    return (
        <div>
            <Button onClick={handleOpen}><ZoomInIcon style={{ color: '#fff' }} /></Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >


                <Fade in={open}>
                    <Box sx={style}>

                        {/* <Box component="img" src={uploadImage || imageDefault} /> */}
                        {/* <img src={uploadImage || imageDefault} class="img-fluid" alt="Imagem responsiva"></img> */}
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            //minHeight="100vh"
                            component="img"
                            sx={{
                                //width: '75%',
                                width: 'auto',
                                // maxHeight: { xs: 350, md: 'auto' },
                                maxWidth: {
                                    xs: 350, md: 720, lg: 960, xl: 1140
                                },
                            }
                            }
                            alt="image"
                            src={uploadImage || imageDefault}
                        />

                    </Box>







                </Fade>


            </Modal>
        </div>
    );
}