import * as React from "react";
import { toast } from "react-toastify";
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Divider, Container, Grid, Toolbar, Typography } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import { Close, DeleteForever } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import Typewriter from '../../../components/Typewriter/Typewriter';
import { Span } from "../../../components/Typography";
import api from "../../../services/api";
import dayjs from 'dayjs';
import { Context } from "../../../components/Context/AuthContext";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Paper {...props} />
    </Draggable>
  );
}

const getValue = (list, type) => {
  var valor = 0;
  list[type].map((item) => {
    valor += item.value_end;
    return item;
  });
  return valor;
}
export default function TableSaleDelete({ row, open, setOpen, handleClose, obj_fn, show_btn }) {
  const { aux_add, setAuxAdd } = React.useContext(Context);

  const handleDelete = async () => {
    // console.log('handleDelete: ');
    if (row.id !== obj_fn.isOpen) {
      return;
    }
    let sale = obj_fn.usestore.reposSales.getSaleStore('id_trans_pay', row.item.id_transaction)[0];

    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"))
    let aux_person = null;
    if (sale.id_person) {
      aux_person = obj_fn.usestore.reposPerson.getCompostPersonStore([
        ['id', sale.id_person], ['type', 'Fornecedor']
      ])[0]
    }
    if (sale.id_trans_pay) {
      sale.arr_installment = obj_fn.usestore.reposBill.getBillStore('id_transaction', sale.id_trans_pay);
    }

    var data = {
      id: sale.id,
      id_user: sale.info_entry?.id_user || userCurrent.id,
      id_property: sale.id_property,
      operationType: "Compra",
      operationName: "Compra",
  
      //step1
      //  Informações do lançamento
      date_release: dayjs(sale.date_entry),
      date_release_f: dayjs(sale.date_entry).format("YYYY-MM-DD HH:mm:ss"),
      date_release_fc: dayjs(sale.date_entry).format("DD/MM/YYYY"),
      date_delivery: dayjs(sale.date_payment),
      date_delivery_f: dayjs(sale.date_payment).format("YYYY-MM-DD HH:mm:ss"),
      date_delivery_fc: dayjs(sale.date_payment).format("DD/MM/YYYY"),
      supplier: sale.id_person,
      client: null,
      person: aux_person,
      //  produtos adicionados
      products: sale.products,
      product_selected: null,
      products_value: getValue(sale, 'products'),
      packaging_unit: 'medida',
  
      //  lotes adicionados
      lotes: sale.lotes,
      lote_selected: null,
      lotes_value: getValue(sale, 'lotes'),
      //  animais adicionados
      animals: sale.animals,
      animal_selected: null,
      animals_value: getValue(sale, 'animals'),
  
      //  patrimonios adicionados
      // patrimonys: [],
      // patrimony_selected: null,
      // patrimonys_value: 0,
  
      //  serviços adicionados
      // services: [],
      // service_selected: null,
      // services_value: 0,
  
      //  Vincular com e descrição
      projects: [],
      projects_apport: sale.info_entry?.aport || [],
      description: sale.description,
  
      //step2
      //  Condições pagamento
      dueDate: dayjs(sale.date_payment),
      dueDate_f: dayjs(sale.date_payment).format("YYYY-MM-DD HH:mm:ss"),
      dueDate_fc: dayjs(sale.date_payment).format("DD/MM/YYYY"),
  
      installment: sale.info_entry?.installment || 'a_vista',
      installmentInterval: 30,
      formPayment: sale.info_entry?.formPayment,
      bankAccount: sale.info_entry?.id_bank,
      payed: false,
      //  Previsão de pagamento
      fee: sale.info_entry?.fee || 0,
      penalty: sale.info_entry?.penalty || 0,
      discount: sale.info_entry?.discount || 0,
      value: sale.value,
      value_end: sale.value,
      //  Parcelas do pagamento
      arr_installment: sale.arr_installment || [],
  
      error_date: false,

      id_trans_entry: sale.id_trans_entry || null,
      id_trans_pay: sale.id_trans_pay || null,
      date_payment: sale.date_payment || null,
      id_trans_inv: sale.id_trans_inv || null,
      date_inventory: sale.date_inventory || null,
    };
    const header = { header: { "Content-Type": "application/json" } };
    
    await api.post("/sales/delete", data, header)
      .then((response) => {
        if (!response.data.isSuccess) {
          return toast.error("Erro ao deletar a Venda!");
        }
        toast.success(`Venda excluida com sucesso!`);
        obj_fn.usestore.reposBill.fetch();
        obj_fn.usestore.reposFinances.fetch();
        obj_fn.usestore.reposFinances.fetchT();
        obj_fn.usestore.reposSales.fetch().then((res) => {
          setAuxAdd(aux_add + 1);
          setTimeout(() => {
            handleClose();
          }, 200);
          setTimeout(() => {
            obj_fn.usestore.reposProperty.fetch();
            obj_fn.usestore.reposCashFlow.fetch();
            obj_fn.usestore.reposCostProduction.fetch();
            obj_fn.usestore.reposLivestock.fetch();
            obj_fn.usestore.reposActivityWeight.fetch();
            obj_fn.usestore.reposProducts.fetch();
          }, 400);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao deletar a Venda!");
      });
  }

  return (
    <div>
      {!show_btn ? <></> :
        <IconButton aria-label="Excluir" onClick={() => setOpen(true)} title="Excluir">
          <DeleteForever sx={{ color: red[600] }} />{" "}
        </IconButton>
      }

      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"title"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={"Esta ação é irrevercível!"} variant={"h6"} color={red[800]} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"> <Close /> </IconButton>
            </Toolbar>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ mb: 6, mt: -3, textAlign: "center" }}>
          <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} sm={12} sx={{ mb: 1, color: red[700] }}>
                <Typography variant="h5" component="div">
                  Deseja excluir definitivamento o lançamento:
                </Typography>
                <Typography variant="h6" component="div">
                  {row?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography variant="body1" component="div">
                  Esta ação irá deletar todas as parcelas envolvendo este lançamento.
                </Typography>
                <Typography variant="body1" component="div">
                  Os itens removidos do estoque serão reintegrados.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>Cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Button variant="outlined" color="error" onClick={handleDelete}>
                <Typography sx={{ mt: 1, color: red[800] }} gutterBottom variant="subtitle1" component="div">Deletar </Typography>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
}
