import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { Box, CircularProgress, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { base64ToBlob } from '../Image/base64ToBlob';

export default function ImageUploadComponent({ onImageUpload, maxWidthOrHeight, setData, type }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const handleImageUpload = async (event) => {
    setLoadingImage(true);
    const file = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: maxWidthOrHeight,
      //maxWidthOrHeight: 1200,
      //maxWidthOrHeight: 150, // Alterado para 150px para redimensionar a imagem
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      handleImageUploadServer(compressedFile);
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
    } finally {
      setLoadingImage(false);
    }
  };

  const handleImageUploadServer = async (file) => {
    setData((prevState) => ({
      ...prevState,
      file: file,
    }));

    const reader = new FileReader();
    reader.onloadend = (event) => {
      console.log('reader.result');
      const compressedImageData = event.target.result;
      const originalImageSize = Math.round((reader.result.length * 3) / 4); // Tamanho aproximado em bytes
      console.log('Tamanho da imagem original:', originalImageSize);

      const base64String = reader.result.split(',')[1];
      setSelectedImage(base64String);
      if (onImageUpload) {
        onImageUpload(base64String);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
      {loadingImage ? (
        <CircularProgress />
      ) : (
        <>
          <IconButton color="success" aria-label="upload picture" component="label">
            <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
            <PhotoCamera />
          </IconButton>
          <span>Clique aqui para inserir a imagem do seu {type || "patrimônio"}</span>
        </>
      )}
    </Box>
  );
}
