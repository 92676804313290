import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Fab, FormControl, FormControlLabel, Menu, Radio, RadioGroup, Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import dayjs from "dayjs";
import { Context } from "../../Context/AuthContext";
import useStore from "../../../store/useStore";
import LoadActivityListLivestock from "../../LoadActivityList/LoadActivityListLivestock";
import LoadActivityListHarvest from "../../LoadActivityList/LoadActivityListHarvest";
import CardChatKanban from "../CardChatKanban";

const filters_l = {
  "hoje": "Hoje",
  "7_dias": "Últimos 7 dias",
  "15_dias": "Últimos 15 dias",
  "30_dias": "Últimos 30 dias",
  "90_dias": "últimos 90 dias",
  "este_ano": "Este ano",
}
// Custom date comparison function considering both date and time
function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1 - date2;
};

export default function BoardTimeLine({ type }) {
  const { aux_add } = useContext(Context);
  const { idFarm, id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsF, setRowsF] = useState([]);

  const [filter, setFilter] = useState('hoje');

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();

  const usestore = useStore();

  const handleRows = () => {
    let filters = {
      'table_active': "realized",
      'status': "edit",
    }

    let rows_atv;
    if (type == 'harvest') {
      const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
      rows_atv = loadActivityListHarvest.loadList(currentHarvest, filters);
    } else {
      const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
      rows_atv = loadActivityListLivestock.loadList(currentLivestok, filters);
    }

    setTimeout(() => {
      setRows(rows_atv);
    }, 200);
  };

  const handlefilter = (new_f) => {
    setIsLoadingTable(true);
    let new_filter = '';
    if (new_f == 'filter') {
      new_filter = (filter || 'hoje');
    } else {
      new_filter = new_f;
    }
    if (new_f != 'filter' && new_filter == filter) {
      return;
    }

    let date_i = dayjs().format("YYYY-MM-DD HH:mm:ss");
    let date_f = dayjs().format("YYYY-MM-DD HH:mm:ss"); // hoje

    switch (new_filter) {
      case '7_dias':
        date_i = dayjs().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss");
        break;
      case '15_dias':
        date_i = dayjs().subtract(15, "day").format("YYYY-MM-DD HH:mm:ss");
        break;
      case '30_dias':
        date_i = dayjs().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss");
        break;
      case '90_dias':
        date_i = dayjs().subtract(90, "day").format("YYYY-MM-DD HH:mm:ss");
        break;
      case 'este_ano':
        date_i = dayjs().startOf('year');
        break;
      default:
        // hoje
        break;
    }

    //post.startDate, post.endDate
    const rows_lv = rows.filter( (item) => {
      if (dayjs(item.startDate_nf).isBetween(date_i, date_f, 'day') || dayjs(item.endDate_nf).isBetween(date_i, date_f, 'day')) {
        return item;
      }
    });

    rows_lv.sort((a, b) => compareDates(b.startDate_nf, a.startDate_nf));
    //rows_lv.sort((a, b) => a.id - b.id);

    setRowsF(rows_lv);
    setFilter(new_filter);

    setTimeout(() => {
      setIsLoadingTable(false);
      setAnchorEl(null);
    }, 250);
    setTimeout(() => {
      setIsLoadingTable(false);
    }, 500);
  };

  useEffect(() => {
    // console.log('refresh BoardTimeLine');
    handleRows();
  }, [aux_add]);

  useEffect(() => {
    // console.log('handlefilter BoardTimeLine');
    handlefilter('filter');
  }, [rows, filter]);

  const mountCalendarFilter = (tipo) => {
    return ( <>
      <Fab sx={{ backgroundColor: "#fff", color: '#555' }} onClick={handleClick} aria-label="add" title="Filtrar por data">
        <CalendarMonth sx={{ color: "#555", mb: '3px' }} />
      </Fab>
      <Menu
        id="filter_rows"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            width: 175,
            maxWidth: '100%',
            ml: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: 1,
            },
            "&:before": {
              content: '""',
              display: "none",
              position: "absolute",
              top: 20,
              right: 202,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "right" }}
      >
        <Box sx={{ ml: 2 }}>
          <CalendarMonth />  Filtrar por data
        </Box>
        <Divider sx={{ mt: 1, mb: 0, borderColor: '#388e3c' }}/>
        <FormControl>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={filter} name="filter" >
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="hoje" onChange={(e) => { handlefilter('hoje')} } label="Hoje" />
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="7_dias" onChange={(e) => { handlefilter('7_dias')} } label="Últimos 7 dias" />
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="15_dias" onChange={(e) => { handlefilter('15_dias')} } label="Últimos 15 dias" />
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="30_dias" onChange={(e) => { handlefilter('30_dias')} } label="Últimos 30 dias" />
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="90_dias" onChange={(e) => { handlefilter('90_dias')} } label="últimos 90 dias" />
            <FormControlLabel control={<Radio color="success"/>} style={{marginLeft: 0, marginBottom: 0}} value="este_ano" onChange={(e) => { handlefilter('este_ano')} } label="Este ano" />
          </RadioGroup>
        </FormControl>
      </Menu>
    </> );
  };

  return ( <>
    <Box display="flex" justifyContent="center" alignItems="center" >
      <div className="col-lg-6 mt-2">
        <div className=" ">
          <div className=" border-0">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="card-title">Histórico de Atividades</h3>
                <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 2 }}>
                  Período listado: {filters_l[filter]}
                </Typography>
              </div>
              <div>
                { mountCalendarFilter() }
              </div>
            </div>
          </div>
          <div className="">
            <div className="row "></div>
          </div>
          <div className="">
            <div className="">
              <section className=" ">
                <div className="">
                  {/* <div className="row" style={{ height: 500, overflowY: "scroll" }}> */}
                  <div className="row" >
                    <div className="col-md-12">
                      <div className="timeline">
                        {rowsF.length > 0 ? 
                        rowsF?.map((post) => ( <>
                          <div className="time-label">
                            <span className="text-secondary">{post.startDate}</span>
                          </div>
                          <div className="card-body">
                            <CardChatKanban post={post} type={'tipos'} isLoading={isLoadingTable}/>
                          </div>
                        </> ))
                        : (<>
                          <div>
                            <span style={{padding: '15px'}}>
                              <i className="fas fa-clock bg-gray" style={{padding: '10px', borderRadius: '10px'}}/>
                            </span>
                            <span className="time-label">
                              Nenhuma atividade realizada no período de <strong>"{filters_l[filter]}"</strong>
                            </span>
                          </div>
                        </>)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </> );
}
