import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppForm from "./AppForm";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { Autocomplete, Box, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { Grid } from "react-loader-spinner";
import { Span } from "../../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";

import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Link, NavLink } from "react-router-dom";
import SplitButton from "./SplitButton";
import MenuMsn from "./MenuMsn";
import { useState } from "react";
import api from "../../../../services/api";
import AppFormEdit from "./AppFormEdit";
import { useEffect } from "react";
import { format } from "date-fns";
import moment from "moment";
const location = [{ label: "Fazenda CPT", id: 1 }];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardMsnEdit({ message, setMessage, post, setIsLoading }) {
  //const [expanded, setExpanded] = React.useState(false);

  // const options = ["Solcitação", "Não Resolvido", "Resolvido", "Aguardando", "Urgente"];
  // const value = options[0];

  // const optionsMsn = ["Inserir", "Enviar uma imagem", "Mensagem de texto", "..."];
  // const valueMsn = optionsMsn[0];

  // const optionsPessoa = ["Definir", "Leonardo", "Pedro", "..."];
  // const valuePessoa = optionsPessoa[0];

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  console.log("CardMsnEditxxxxxxxxxxxxxxxxxxxxxxxxxx")
  console.log(message)
  console.log(post)

  const imageDefault = "/dist/image/solicitacao_default.jpg"
  const uploadImage = message.key_image
  const imageDelete = "/dist/image/imagedelete.png"


  const handleImgError = e => {
    // inserir uma imagem com texto excluido
    e.target.src = imageDelete
  }


  // para user current
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent;
  console.log(user)

  console.log(user.name);

  const name = user.name;

  //const name = message.id_requester
  const charNameCurrent = name[0].toUpperCase();
  console.log(charNameCurrent);

  const charNamePost = post.char

  const date = new Date()
  //post.date = format(date, "MMMM do, yyyy H:mma");
  var datePost = moment(message.date).format('DD/MM/YYYY');
  const dateNow = format(date, "dd-MM-yyyy");


  return (
    <Card>
      {/* <Card sx={{ maxWidth: 345 }}></Card> */}
      <CardHeader
        sx={{ m: -1, ml: -1 }}
        avatar={
          <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
            {charNameCurrent}
          </Avatar>
        }

        title={user?.name}
        subheader={dateNow}
      />
      <div style={{ position: "relative" }}>
        <CardMedia component="img" height="125" image={uploadImage || imageDefault} onError={handleImgError} alt="Imagem" />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.54)",
            color: "white",
            padding: "63px",
          }}
        >
          {/* <Typography variant="h5">Lizard</Typography>
                    <Typography variant="body2">Subtitle</Typography> */}
        </Box>
        {/* <div style={{ position: "absolute", color: "white", bottom: 10, left: "50%", transform: "translateX(-50%)", }}> Some text</div> */}
        <div style={{ position: "absolute", color: "white", bottom: 10, left: "5%" }}>
          {/* <Typography variant="caption" style={{ opacity: 0.7 }}>
            Bovino 22/23
          </Typography> */}
          {/* <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
            {charNamePost}
          </Avatar> */}
          <Typography variant="h6">{message.title}</Typography>
          {datePost && <Typography variant="body2">Solicitação: {datePost}</Typography>}
        </div>
        <div style={{ position: "absolute", color: "white", bottom: 10, right: "2%" }}>
          {/* <Fab variant="extended" color="success" size="small">
                        <Typography variant="button">Resolver</Typography>
                    </Fab> */}
          <SplitButton message={message} />
        </div>
      </div>
      {/* <img className="img-rounded elevation-1" src="/dist/image/bebedouro.png" width={80} style={{ filter: `grayscale(100%)` }} /> */}
      {message && <AppFormEdit message={message} setMessage={setMessage} />}
    </Card>
  );
}
