import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack } from '@mui/material';
import React from 'react';
import { Typography } from '@mui/material';
import ImageLisTabs from './components/ImageLisTabs';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Upload from '../../components/Upload/Upload';
import useListImagesStore from '../../store/useListImagesStore';
import useStore from '../../store/useStore';
import { useState } from 'react';
import UploadFunction from '../../components/Upload/UploadFunction';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageListContent() {
  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  var farm_id = localStorage.getItem('Property');

  //const usestore = useStore();
  //usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {});
  // const usestore = useStore();

  // const refreshImages = () => {
  //   usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {});
  // }

  // refatorando:
  const [item, setItem] = useState({
    id: 1,
    tipo_item: 'album',
    id_farm: 1,
  });

  const callBack = (imageKey) => {
    console.log('Callback received image key:', imageKey);
  };

  const callBackHook = {
    addImageStore: (image) => {
      console.log('Adding image to store:', image);
    },
    delImageStore: (id) => {
      console.log('Deleting image from store with id:', id);
    },
  };

  console.log('UploadFunction1');

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography gutterBottom variant="h5" component="div">
                Banco de imagens
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                Lista&nbsp;de&nbsp;imagens&nbsp;da&nbsp;propriedade, criações e safras
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Stack spacing={0.5}>
                <Button color="success" variant="contained" onClick={handleClickOpen}>
                  <AutoStoriesIcon />
                  <Typography sx={{ ml: 1, mt: 1, color: 'white' }} gutterBottom>
                    Upload de imagens
                  </Typography>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} alignItems="center">
              {true ? (
                <Typewriter text={`Clique em "UPLOAD DE IMAGENS" para adicionar imagens no Fazenda Premium.`} variant={'subtitle1'} color={'#2e7d32'} />
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="container-fluid">
        <ImageLisTabs />
      </div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Insira imagem da sua criação'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {/* <Upload /> */}
              <UploadFunction item={item} callBack={callBack} callBackHook={callBackHook} />
            </Box>
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
