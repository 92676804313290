import React, { useState } from 'react'
import { TextField, Box, Grid, Typography, Stack, Autocomplete } from '@mui/material'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'

import FormIdentifyHeader from './FormIdentifyHeader'
import FormIdentifyFooter from './FormIdentifyFooter'

export default function FormIdentification1({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false)
  setTimeout(() => {
    setShowTextFase(true)
  }, 500)

  const handleChange = (event, val_input) => {
    // console.log('handleChange lote')

    var date_birth_aux = obj_fn.getDateBirth(val_input)
    var weight_aux = obj_fn.getWeight(val_input)

    obj_fn.setData((prevState) => ({ ...prevState, [event]: val_input }))
    obj_fn.setData((prevState) => ({ ...prevState, date_birth: date_birth_aux }))
    obj_fn.setData((prevState) => ({ ...prevState, weight: weight_aux }))
  }

  // const para validar o button continuar
  const isError = () => {
    return !data.lote || data.lote?.length  === 0 || data.lote?.num_animals  === 0
  }

  // aciona o botao retornar
  const returnError = () => {
    return true
  }

  const text_title = 'Passo 1: Lote de animais'
  const txt_msn = 'Selecione o lote desejado para a identificação dos animais.'

  return (
    <>
      <FormIdentifyHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} />

      <Grid container sx={{ mb: 0 }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid container justifyContent="center" sx={{}} spacing={2}>
            <Grid item xs={6} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutoAwesomeMotionIcon style={{ fontSize: 35 }} sx={{ mr: 1, mt: -3 }} />
                <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    name="lote"
                    options={data.opt_lotes}
                    value={data.lote}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <span style={{ marginRight: '8px' }}>
                            <AutoAwesomeMotionIcon />{' '}
                          </span>
                          {option.label}
                        </Box>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} fullWidth required name="lote" color="success" label="Selecione o Lote" variant="standard" value={data.lote} />}
                    onChange={(e, value) => handleChange('lote', value)}
                    noOptionsText="Sem resultados"
                  />
                  <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                    <Typography variant="caption" display="block" gutterBottom sx={{ ml: 1, color: '#808080' }}>
                      Selecione o lote do qual deseja identificar os animais
                    </Typography>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={2}>
            <Grid item xs={3} md={3}>
              <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                Total de animais a identificar:
                <br />
                {data.lote?.num_animals || 0}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2 }}>
                Fase de Manejo:
                <br />
                {data.lote?.fase_name || ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <FormIdentifyFooter
          data={data}
          setData={obj_fn.setData}
          handleNext={obj_fn.handleNext}
          handleBack={obj_fn.handleBack}
          activeStep={obj_fn.activeStep}
          steps={obj_fn.steps}
          isError={isError}
          handleClose={obj_fn.handleClose}
          returnError={returnError}
        />
      </Grid>
    </>
  )
}
