import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconHarvesting = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 247.000000 170.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,170.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M1330 1668 c-36 -4 -204 -34 -375 -66 l-310 -57 -22 -95 c-13 -60
-22 -131 -22 -193 -1 -91 1 -100 24 -122 l24 -25 396 0 395 0 0 285 0 285 -22
-1 c-13 -1 -52 -6 -88 -11z"
          />
          <path
            d="M1487 1673 c-4 -3 -7 -24 -7 -45 l0 -38 305 0 c168 0 305 3 305 8 0
16 -33 53 -60 67 -24 12 -77 15 -283 15 -139 0 -257 -3 -260 -7z"
          />
          <path
            d="M1480 1225 l0 -315 85 0 c80 0 85 1 90 23 75 318 135 582 135 593 0
11 -29 14 -155 14 l-155 0 0 -315z"
          />
          <path
            d="M1840 1537 c0 -2 -31 -137 -70 -302 -38 -165 -70 -305 -70 -312 0 -9
17 -13 55 -13 49 0 59 4 94 35 21 19 41 45 44 58 2 12 32 135 66 274 33 138
61 254 61 257 0 3 -40 6 -90 6 -49 0 -90 -1 -90 -3z"
          />
          <path
            d="M120 1298 c-11 -27 -90 -354 -90 -374 0 -12 111 -14 705 -14 l705 0
0 80 0 80 -405 0 -405 0 -32 29 c-32 28 -33 32 -38 123 l-5 93 -213 3 -212 2
-10 -22z"
          />
          <path
            d="M17 863 c-16 -16 -5 -80 25 -139 l32 -64 401 0 401 0 49 61 c31 39
73 76 112 99 l62 37 -61 7 c-81 8 -1013 8 -1021 -1z"
          />
          <path
            d="M1497 842 c-16 -17 -14 -20 27 -51 45 -34 89 -89 120 -148 l17 -33
102 0 102 0 -125 125 -125 125 -51 0 c-37 0 -56 -5 -67 -18z"
          />
          <path
            d="M1155 827 c-152 -51 -245 -155 -276 -310 -23 -112 19 -236 111 -327
124 -125 284 -149 447 -70 72 36 134 100 172 180 23 48 26 68 26 155 0 89 -3
107 -29 162 -35 77 -114 154 -193 190 -69 32 -193 41 -258 20z m200 -156 c50
-22 109 -86 125 -136 7 -20 10 -65 8 -101 -5 -78 -40 -134 -113 -179 -41 -26
-58 -30 -114 -30 -200 1 -308 220 -186 378 59 78 189 110 280 68z"
          />
          <path
            d="M1161 648 c-84 -41 -132 -142 -112 -234 26 -116 146 -192 260 -164
162 39 216 246 94 362 -63 60 -162 74 -242 36z m173 -67 c42 -28 66 -70 66
-119 0 -140 -157 -201 -249 -97 -17 19 -33 50 -36 69 -9 47 24 119 67 146 43
26 113 26 152 1z"
          />
          <path
            d="M1189 557 c-86 -58 -64 -182 39 -218 71 -24 152 37 152 114 -1 51
-17 82 -56 108 -39 26 -93 24 -135 -4z"
          />
          <path
            d="M496 586 c90 -42 155 -126 170 -223 l7 -43 89 0 c82 0 89 2 83 18
-15 37 -16 167 -2 220 l14 52 -206 0 -206 0 51 -24z"
          />
          <path
            d="M310 561 c-48 -15 -87 -40 -120 -77 -44 -51 -60 -93 -60 -162 0 -159
149 -278 301 -242 144 35 230 201 174 336 -46 110 -184 178 -295 145z m127
-110 c97 -45 111 -163 28 -241 -23 -21 -36 -25 -90 -25 -54 0 -67 4 -90 25
-82 76 -70 187 25 237 52 28 72 28 127 4z"
          />
          <path
            d="M291 410 c-77 -78 -33 -197 77 -208 44 -4 52 -1 85 28 60 52 62 128
5 182 -25 23 -39 28 -83 28 -46 0 -58 -4 -84 -30z m134 -35 c40 -39 29 -106
-20 -125 -30 -11 -82 5 -95 30 -27 50 11 120 65 120 14 0 37 -11 50 -25z"
          />
          <path
            d="M1710 382 l0 -188 29 -46 c46 -72 75 -79 316 -76 l200 3 3 69 3 70
-178 178 -178 178 -97 0 -98 0 0 -188z"
          />
        </g>
      </SvgIcon>
    </>
  );
};
