// Fora do escopo do componente
export function createNotificationLivestockCreation(livestock) {
  // Substituir os textos no objeto property
  // console.log('createNotificationLivestockCreation1');
  // console.log(livestock);
  const statusMapping = {
    label: 'Nome do projeto pecuário',
    areas: 'Quantidade de áreas de produção',
    animais: 'Quantidade de animais',
  };

  const formattedStatus = Object.entries(livestock.status).reduce((acc, [key, value]) => {
    acc[statusMapping[key]] = value;
    return acc;
  }, {});

  // Separar registros completos e incompletos
  const complete = [];
  const incomplete = [];

  Object.entries(formattedStatus).forEach(([key, value]) => {
    if (value === 'Ok') {
      complete.push(key);
    } else {
      incomplete.push(key);
    }
  });

  const titleText = 'Para iniciar a sua gestão pecuária ';
  const completeText = complete.length > 0 ? complete.join(', ') : '';
  const incompleteText = incomplete.length > 0 ? incomplete.join(', ') : 'Nenhum registro incompleto';

  // const titleNotification = `Para inciar é necessário: ${incompleteText}.`;
  const titleNotification = `é simples e rápido.`;
  const titleAction = `\n Por favor, clique aqui e em seguida você será redirecionado para tela de criação dos porjetos pecuários.`;

  const title = `${titleText}\n${completeText}\n${titleNotification}\n${titleAction}`;

  const notificationLivestock = {
    group: 'Geral',
    type: 'info',
    title: 'Informação',
    text: title,
    link: '/livestock',
  };

  return { notificationLivestock, hasIncompleteLivestock: incomplete.length > 0 };
}
