import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CreateDrawAreaList from './CreateDrawAreaList'
import { Box, ButtonBase, List, Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities'
import SpaIcon from '@mui/icons-material/Spa'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}))

export default function TutorialAddAreaAccordion() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClickLivestock = () => {
    navigate('/livestock')
  }
  const handleButtonClickHarvest = () => {
    navigate('/harvest')
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
            Desenhar uma nova área
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateDrawAreaList />
          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography paragraph variant="subtitile1" fontWeight={500}>
          Áreas organizadas... Agora podemos criar e organizar o projeto dos animais ou culturas.
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Registrar uma nova safra ou criação. </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Com a área criada, clique no botão criações para registrar uma nova criação e inserir seus animais ou safras para registrar e inserir uma nova cultura.
            </Typography>
            <div style={{ display: 'flex' }}>
              <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1, margin: '0 10px' }} onClick={() => handleButtonClickLivestock()} className={classes.button}>
                <Stack direction="column" alignItems="center">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Criações
                  </Typography>
                </Stack>
              </ButtonBase>
              <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1, margin: '0 10px' }} onClick={() => handleButtonClickHarvest()} className={classes.button}>
                <Stack direction="column" alignItems="center">
                  <SpaIcon />
                  <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                    Safras
                  </Typography>
                </Stack>
              </ButtonBase>
            </div>
            <Box sx={{ mt: 2 }}>
              <Typography paragraph variant="subtitile1" fontWeight={500}>
                Caso queira inserir novas áreas, clique em fechar e depois em "CRIAR ÁREAS".
              </Typography>
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </div>
  )
}
