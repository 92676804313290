import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { Span } from '../../Typography';
import MenuPlan from '../../../pages/signature/components/MenuPlan';
import Typewriter from '../../Typewriter/Typewriter';
import AddActivities from '../../../pages/activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';

export default function NotificationsAlertCardEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
              <Box sx={{ textAlign: 'center' }}>
                <Grid container xs={12} md={12} sx={{ mt: 5, mb: 4 }} item alignItems="center" justifyContent="center">
                  <img src="/dist/image/assinatura.png" name=""></img>
                </Grid>
                {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                  <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                    Informações, notificações e alertas
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Acompanhe todas as atividades e mudanças na sua fazenda através de notificações e alertas em tempo real.
                  </Typography>
                </Grid>
                <Grid container xs={12} md={12} sx={{ m: 1, mt: 2 }} item alignItems="center" justifyContent="center">
                  <Typography sx={{ fontSize: 22, color: '#2e7d32' }} gutterBottom>
                    <strong>Avisos inteligentes vazio.</strong>
                  </Typography>
                </Grid>
                {/* <Box sx={{ textAlign: 'center' }}>
                  <Button color="success" variant="outlined" type="submit" sx={{ mt: 1, mb: 2 }}>
                    <Span sx={{ textTransform: 'capitalize' }}>Ativar Avisos inteligentes</Span>
                  </Button>
                </Box> */}
              </Box>
            </Paper>
          </Container>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
