import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { checkv1 } from './archive/index';
import { useEffect } from 'react';
import { Box } from '@mui/material';

const CheckvHelp = () => {
  const [animationOptions, setAnimationOptions] = useState({});

  useEffect(() => {
    setAnimationOptions({
      animationData: checkv1,
      loop: false,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      // Controla a velocidade da animação
      // Quanto menor o valor, mais lenta será a animação
      speed: 0.5,
    });
  }, []);

  return (
    <Box>
      <Lottie options={animationOptions} height={150} width={200} />
    </Box>
  );
};

export default CheckvHelp;
