import React from "react";
var Fakerator = require("fakerator");

export default function AnimalListName({ count, complete, letraIniit, letraEnd, sexo, list }) {
  const fakerator = Fakerator("pt-BR");
  // const fakeratorEnglish = Fakerator("en-US");
  let namePt;
  // var nameEn;

  let letra_ini = (letraIniit || 'A');
  let letra_fin = (letraEnd || 'Z');

  if (letra_ini > letra_fin) {
    letra_ini = letraEnd;
    letra_fin = letraIniit;
  }

  const namesStartingWith = [];
  while (namesStartingWith.length < count) {
    // const name = fakerator.names.firstNameM();
    // const name = fakerator.names.nameM();
    // console.log(name);
    // if (name >= letraIniit && name <= letraEnd && !namesStartingWith.includes(name)) {
    //   namesStartingWith.push(name);
    // }
    if (complete) {
      if (sexo == "Fêmea") {
        namePt = fakerator.names.nameF();
        // nameEn = fakeratorEnglish.names.nameF();
      } else {
        namePt = fakerator.names.nameM();
        // nameEn = fakeratorEnglish.names.nameM();
      }
    } else {
      if (sexo == "Fêmea") {
        namePt = fakerator.names.firstNameF();
        // nameEn = fakeratorEnglish.names.firstNameF();
      } else {
        namePt = fakerator.names.firstNameM();
        // nameEn = fakeratorEnglish.names.firstNameM();
      }
    }
    //console.log(namePt);
    //console.log(nameEn);
    // confere a primeira letra
    var namePt_f = namePt.slice(0,1);
    //if (namePt >= letraIniit && namePt <= letraEnd && !namesStartingWith.includes(namePt)) {
    if (namePt_f >= letra_ini && namePt_f <= letra_fin && !namesStartingWith.includes(namePt)) {
      namesStartingWith.push(namePt);
    }
  }

  // Função para ordenar os nomes de forma alfabética
  const sortNames = (names) => {
    return names.sort((a, b) => a.localeCompare(b));
  };

  const sortedNames = sortNames(namesStartingWith);

  if (list) {
    return sortedNames;
  } else {
    return (
      <div className="App-header">
        <p key={"letras"}>De: {letraIniit} ... Até: {letraEnd}: </p>
        {sortedNames.map((name, index) => (
          <p key={index}>
            {name}
          </p>
        ))}
      </div> 
    );
  }
}
