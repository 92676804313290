import { SvgIcon } from "@mui/material";
//Converter Para SVG
//https://www.img2go.com/pt/converter-para-svg
//viewBox: Define a caixa de visualização do ícone SVG. Pode ser um valor como "0 0 24 24".

// icon do bovino de corte
export const IconSoilPreparation = ({ widthStyles, fill }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 187.000000 163.000000" style={{ width: widthStyles, height: "auto" }} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,163.000000) scale(0.100000,-0.100000)" fill={fill ? fill : "#000000"} stroke="none">
          <path
            d="M596 1525 c-64 -23 -146 -118 -146 -170 0 -12 -16 -15 -95 -15 -87 0
-96 -2 -109 -22 -8 -13 -12 -35 -9 -50 l5 -28 439 0 439 0 0 39 c0 22 -6 43
-12 48 -7 4 -53 10 -101 13 l-89 5 -13 38 c-42 123 -182 187 -309 142z m154
-87 c40 -21 90 -81 78 -94 -3 -2 -71 -3 -152 -2 l-146 3 18 35 c21 38 89 80
131 80 15 0 47 -10 71 -22z"
          />
          <path
            d="M1333 1280 c-12 -5 -25 -21 -29 -36 -4 -15 -4 -147 1 -293 4 -146 7
-279 7 -296 -3 -130 8 -175 43 -175 33 0 55 38 55 96 0 30 -5 65 -11 76 -8 14
-8 23 -1 27 12 7 29 522 19 563 -5 19 -46 52 -60 47 -1 0 -12 -4 -24 -9z"
          />
          <path
            d="M236 1188 c-3 -18 -7 -85 -10 -148 -3 -63 -15 -176 -27 -250 -29
-177 -31 -390 -6 -472 20 -66 67 -125 114 -144 23 -10 81 -14 196 -14 157 0
164 1 184 22 11 13 26 39 32 59 14 43 45 74 88 89 19 7 33 20 37 35 3 12 22
37 41 54 30 26 44 31 87 31 48 0 54 -3 95 -45 33 -35 50 -45 74 -45 l31 0 -7
115 c-5 80 -4 120 4 129 8 10 5 58 -14 187 -13 96 -28 231 -31 302 l-7 127
-438 0 -438 0 -5 -32z m269 -218 c80 -16 131 -44 155 -83 22 -35 26 -87 10
-118 -10 -17 -16 -13 -69 44 -33 34 -62 59 -66 55 -9 -9 103 -133 119 -133 6
0 19 -24 30 -53 17 -47 21 -51 30 -35 6 10 11 41 11 69 1 59 21 95 68 125 29
18 42 19 127 13 52 -3 98 -8 102 -10 27 -17 -72 -158 -133 -189 -32 -17 -115
-20 -124 -5 -3 6 -10 10 -14 10 -16 0 -53 -104 -59 -162 -9 -99 -21 -64 -19
55 2 91 -1 118 -16 145 -15 27 -21 31 -37 22 -47 -25 -116 0 -151 57 -22 35
-69 199 -61 208 3 3 13 3 21 1 9 -3 43 -10 76 -16z"
          />
          <path
            d="M1202 600 c-22 -9 -22 -12 -17 -122 8 -147 13 -188 29 -217 l14 -24
11 22 c40 74 166 81 223 12 43 -51 60 -9 68 169 4 80 4 149 0 154 -5 4 -29 11
-54 13 l-46 6 0 -52 c0 -88 -63 -129 -115 -76 -17 16 -25 35 -25 59 0 19 -3
41 -6 50 -7 18 -45 20 -82 6z"
          />
          <path
            d="M894 396 c-19 -19 -34 -39 -34 -45 0 -6 16 -17 35 -25 19 -8 35 -20
35 -25 0 -14 -13 -14 -27 0 -18 18 -87 15 -116 -4 -15 -9 -34 -31 -42 -47
l-16 -30 31 0 c16 0 30 -4 30 -10 0 -5 -12 -10 -26 -10 -29 0 -94 -58 -94 -84
0 -14 51 -16 535 -16 484 0 535 2 535 16 0 21 -63 84 -85 84 -12 0 -15 5 -10
18 29 68 -8 142 -72 142 -14 0 -22 -14 -34 -59 -15 -59 -15 -60 8 -70 13 -6
23 -15 23 -21 0 -13 -10 -12 -40 5 -14 8 -35 14 -47 15 -12 0 -27 9 -33 20
-40 74 -184 57 -204 -25 -5 -19 -14 -23 -58 -28 -29 -2 -55 -3 -58 0 -8 8 25
23 53 23 27 0 27 1 13 28 -7 15 -17 42 -21 61 -7 32 -8 33 -46 28 -36 -5 -41
-2 -54 23 -23 44 -63 70 -107 70 -32 0 -47 -7 -74 -34z"
          />
        </g>
      </SvgIcon>
    </>
  );
};
