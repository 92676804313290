import dayjs from 'dayjs';

let listLivestock = [];

// Verifica se reposLivestock existe no localStorage
let reposLivestockString = localStorage.getItem('reposLivestock');
if (reposLivestockString) {
  try {
    // Converte a string JSON em um objeto JavaScript
    let reposLivestock = JSON.parse(reposLivestockString);
    listLivestock = reposLivestock.state.listLivestock || []; // Garante que sempre tenha um array
    console.log('listLivestock carregado com sucesso:', listLivestock);
  } catch (error) {
    console.error('Erro ao processar dados do localStorage:', error);
    listLivestock = [];
  }
} else {
  console.log('Nenhum dado encontrado em reposLivestock');
}

console.log(listLivestock);

let dataProperty = [];

let resposPropertyString = localStorage.getItem('reposProperty');
//console.log(resposPropertyString);
if (resposPropertyString) {
  try {
    // Converte a string JSON em um objeto JavaScript
    let resposProperty = JSON.parse(resposPropertyString);
    dataProperty = resposProperty.state.listProperty[0] || {};
    console.log('Dados da propriedade carregados:', dataProperty);
  } catch (error) {
    console.error('Erro ao processar dados da propriedade:', error);
    dataProperty = {};
  }
} else {
  console.log('Nenhum dado encontrado em resposProperty');
}

console.log(dataProperty);

// Função para converter lotes em string
function formatLotes(lotes) {
  //return lotes.map((lote) => `${lote.name}(${lote.total_animals})\n`).join(", ");
  return lotes.map((lote) => `${lote.name} (${lote.total_animals} animais)\n`);
}

function formatData(Inputdata) {
  return !Inputdata ? '' : dayjs(Inputdata).format('DD/MM/YYYY');
}

// Atualizando livestockTableRows com validação
const livestockTableRows =
  listLivestock.length > 0
    ? listLivestock.map((item, index) => {
        return [
          { text: (index + 1).toString() }, // Número incremental como ID
          { text: item.total_animais || 'Animais não especificados' }, // Verifica se 'total_animais' está disponível
          { text: item.tipo || 'Tipo não especificado', fontSize: 10, bold: true }, // Verifica se 'tipo' está disponível
          { text: item.name || 'Nome não especificado', fontSize: 8 }, // Verifica se 'name' está disponível
          { text: `Início: ${formatData(item.data_inicial)} \n Fim: ${formatData(item.data_fim)}`, fontSize: 7 }, // Converte para data
          { text: formatLotes(item.lotes) || 'Lote não especificado', fontSize: 8 }, // Converte lotes para string
        ];
      })
    : [[{ text: 'Nenhum dado disponível', colSpan: 6, alignment: 'center' }]];

console.log('livestockTableRows:', livestockTableRows);

const lineBlank = [
  {
    text: '',
    alignment: 'center',
    fontSize: 5,
    colSpan: 0,
  },
  {},
  {},
];

export const bodyListCreation = {
  content: [{ text: 'Lista das Criações', fontSize: 10, alignment: 'left', margin: [0, 4, 0, 0], color: 'green' }],
};

// adicionando a tabela
// bodyListCreation.content.push(
//   { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
//   { text: 'Lista', style: 'subheader' },
//   {
//     style: 'tableExample',
//     table: {
//       headerRows: 1,
//       widths: [20, 40, '*', '*', 60, '*'],
//       fillColor: '#eeeeee',
//       body: [
//         ['#', 'Animais', 'Tipo', 'Projeto(Criação)', 'data', 'Lote'], // Cabeçalhos da tabela
//         //{ text: "", italics: true, color: "gray" },
//         ...livestockTableRows, // Linhas de dados da tabela
//       ],
//     },
//     fontSize: 10,
//     // bold: true,
//   }
// )

// adicionando a tabela
bodyListCreation.content.push(
  { text: '', fontSize: 14, bold: true, margin: [0, 20, 0, 0] },
  {
    style: 'tableExample',
    table: {
      headerRows: 1,
      widths: [10, '*', '*', '*', '*', '*'],
      fillColor: '#eeeeee',
      body: [
        [
          { text: '#', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Animais', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Tipo', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Projeto(Criação)', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Data', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
          { text: 'Lote', style: 'tableHeader', fillColor: '#42ab49', color: 'white' },
        ],
        ...livestockTableRows, // Linhas de dados da tabela
      ],
    },
    layout: 'lightHorizontalLines',
  }
);

// adicionando texto
bodyListCreation.content.push({
  width: '*',
  text: `Número de criações: ${dataProperty?.total_livestocks} \n Total de animais: ${dataProperty?.total_animais} \n `,
  fontSize: 10,
  color: '#808080',
  margin: [0, 10, 0, 0],
  alignment: 'right',
});

// adicionando texto
// bodyListCreation.content.push({
//   width: '*',
//   text: `Número de safras: ${dataProperty?.total_livestocks} \n Total de cultivares: ${dataProperty?.total_animais} \n `,
//   fontSize: 10,
//   color: '#808080',
//   margin: [0, 10, 0, 0],
//   alignment: 'right',
// })
