import React, { useState, useEffect, useContext } from "react";
import SendIcon from "@mui/icons-material/Send";
import "./ChatContent.css";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ThreeDotsSpinner from "../../components/Loader/ThreeDotsSpinner";
import api from "../../services/api";


export default function ChatContent() {

  const [prompt, setPrompt] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(conversation);
  }, [conversation]);

  const handleQuerySubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setConversation((prevData) => [
      ...prevData,
      {
        type: "query",
        text: prompt,
      },
    ]);
    const temp = prompt;
    setPrompt("");
    // enviamos a pergunta na variável temp
    // obtemos a resposta do servidor aqui
    getResponseFromServer(temp);
  };

  const headers = {
    header: {
      "Content-Type": "application/json",
    },
  };

  //api.defaults.headers.common['token'] = localStorage.getItem("token");
  const getResponseFromServer = async (temp) => {
    try {
      // const response = await fetch("http://localhost:5001/api/v1/chat/", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "token": localStorage.getItem("token")
      //   },
      //   body: JSON.stringify({ prompt: temp }),
      // });
      const response = await api.post("http://localhost:5001/api/v1/chat/", { prompt: temp }, headers);
      console.log(response)
      //const responseData = await response.json();
      const responseData = await response.data;
      setConversation((prevData) => [
        ...prevData,
        {
          type: "response",
          text: responseData.res,
        },
      ]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setConversation((prevData) => [
        ...prevData,
        {
          type: "response",
          text: "Opss.. Algo deu errado. Tente novamente.",
        },
      ]);
      setLoading(false);
    }
  };
  //src="dist/image/chatgpt-icon.png"
  return (
    <div className="content-wrapper ">
      <div className="content-header" style={{ marginTop: 60 }}></div>
      <div className="">
        <div className="">
          <div className="">
            <div className="app-container">
              <h6>I.A. Fazenda Premium</h6>
              <div className="conversation-container">
                {conversation.length === 0 ? (
                  <div className="conversation-reponse query-container">
                    <img src="dist/image/chatgpt-icon.png" alt="" />
                    <p>Pergunte-me qualquer coisa ....</p>
                  </div>
                ) : (
                  <></>
                )}
                {conversation.map((obj, index) =>
                  obj.type === "query" ? (
                    <div key={index} className="conversation-query query-container">
                      <AccountBoxIcon />
                      <p>{obj.text}</p>
                    </div>
                  ) : (
                    <div key={index} className="conversation-reponse query-container">
                      <img src="dist/image/chatgpt-icon.png" alt="" />
                      <p>{obj.text}</p>
                    </div>
                  )
                )}
                {loading ? (
                  <div className="conversation-reponse query-container">
                    <img src="dist/image/chatgpt-icon.png" alt="" />
                    <ThreeDotsSpinner />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <form className="app-input-container" onSubmit={handleQuerySubmit}>
                <input
                  type="text"
                  required
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
                <button type="submit" disabled={!prompt || loading}>
                  <SendIcon />
                </button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div >

  );
};

//   return (
//     <div className="content-wrapper bg-white">
//       <div className="content-header" style={{ marginTop: 60 }}></div>
//       <div className="content">
//         <div className="container-fluid">
//           <div className="card">


//           </div>
//         </div>
//       </div>
//     </div >
//   );
// }
